<!-- START HEADER-->
<header class="header" app-md-header></header>
<!-- END HEADER-->
<!-- START SIDEBAR-->
<nav class="page-sidebar" id="sidebar" app-md-sidebar></nav>
<!-- END SIDEBAR-->

<!-- START PAGE CONTENT-->
<div class="content-wrapper">
    <router-outlet></router-outlet>
    <!-- <div app-banner></div> -->
    <footer class="page-footer" app-md-footer></footer>
</div>
<ngx-spinner type="ball-atom">Loading...</ngx-spinner>