import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AuctionService } from '../../service/auction.service';
import { MainService } from '../../service/main.service';
import readXlsxFile from 'read-excel-file'
import { ExcelService } from '../../service/file-export.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {

  constructor(private spinner : NgxSpinnerService,private aucSer:AuctionService,private portalmain: MainService,private excel: ExcelService) { }

  purposeList = [
    {'code':'auction',value:'Auction'},
    // {'code':'f',value:'auction'},
  ]
  enterTandC:any =''

  inputMode = true

  inputModeChange(val:any){
    this.inputMode = val
  }

  purpose = ''
  tAndcList:any = []

  modeOfferAction(){}
  reset(){}
  async open_list(val:any){
    await this.getList()
    this.showList = val
  }


  tempList:any = []
  fileSelected(event:any){
    readXlsxFile(event.files[0]).then((rows:any) => {
      for (let index = 1; index < rows.length; index++) {
        const element:any = rows[index];
        if(element[1])
           this.tempList.push(element[1])
      }
    })
  }

  fileInput:any
  user_id = -1
  b_acct_id = -1
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    console.log(this.ebillUser)
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id =this.ebillUser.user_id
    await this.getList()
  }
  addTermCondition(){
    if(this.inputMode){
      if(this.enterTandC){
        this.tAndcList.push(this.enterTandC)
        this.enterTandC = ''
      }
    }else{
      this.tAndcList = this.tempList
      this.tempList = []
      this.fileInput = undefined
    }
  }


  async submit(){
    if(!this.purpose){
     Swal.fire('Info','Select Purpose','info')
     return
    }
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id
    obj['rules'] = this.tAndcList
    obj['purpose'] = this.purpose
    console.log(obj)
    this.spinner.show()
    let resp = await this.aucSer.createTermAndCondition(obj)
    if(!resp['error']){
      this.tAndcList = []
      this.spinner.hide()
      Swal.fire("Success","Submitted Successfully",'success')
    }else{
      this.spinner.hide()
      Swal.fire('Error','Server Error','error')
    }

  }

  async getList(){
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    let resp = await this.aucSer.getTermAndCondition(obj)
    if(!resp['error']){
      this.tAndcListDB = resp['data']
      this.spinner.hide()
      // Swal.fire("Success","Submitted Successfully",'success')
    }else{
      this.spinner.hide()
      Swal.fire('Error','Server Error','error')
    }
  }


  deleteTandC(i:any){
    this.tAndcList.splice(i,1)
   }

   ebillUser:any
   showList = true
   tAndcListDB:any = []
  //  async deleteTandCDB(id,i){
  //    let obj :any= {}
  //    obj['b_acct_id'] = this.b_acct_id
  //    obj['id'] = id
  //    let resp = await this.aucSer.delTermAndCondition(obj)
  //    if(!resp['error']){
  //      this.tAndcListDB.splice(i,1)
  //      Swal.fire("Success","Deleted Successfully",'success')
  //    }else{
  //      Swal.fire('Error','Server Error','error')
  //    }
  //  }
Obj:any
  async deleteTandCDB(element:any) {
    console.log(element)
    this.Obj = Object.assign({}, element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete()
      }
    })
  }
  async finaldelete() {
    this.Obj['b_acct_id'] = this.b_acct_id;
    console.log(this.Obj);
    this.spinner.show()
    let resp = await this.aucSer.delTermAndCondition(this.Obj)
    console.log(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getList()
      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }


  }


  export() {
    let ExpData :any= [{

      'one': 'S.No.',
      'two' :  'Terms & Conditions'


    },
    ]
    var exp = []
    // for (var i = 0; i < ExpData.length; i++) {
      var obj : any= new Object();
      obj['S.No.'] = ExpData['one'],
      obj['Terms & Conditions'] = ExpData['two']



      exp.push(obj);
    // }
    this.excel.exportAsExcelFile(exp, 'Term_and_Condotion')
  }
}
