import { ViewChild, Component, OnInit } from '@angular/core';
import { SettingService } from '../../service/setting.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService as md_mainService } from '../../../md/service/main.service';

import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';
import { EditorServiceService } from '../../service/editor-service.service'

@Component({
  selector: 'app-support-letters',
  templateUrl: './support-letters.component.html',
  styleUrls: ['./support-letters.component.css']
})
export class SupportLettersComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private settingService: SettingService,
    public mainService: md_mainService,

    private editorServiceService: EditorServiceService) { }


  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  ebillUser:any;
  b_acct_id:any;
  current_user:any;


  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.current_user = this.ebillUser.party_name;
    await this.getorderData();
    await this.getCodeValue_obj();
    await this.getAllEmployees();
    await this.getCurrentRole();
    await this.getSupportLetter();
    await this.getCurrentUsers();
  }


  dataSource: any;
  // dataSource = [{ 'co_ord_id': 2020 / 1, 'branch_name': 'Hazattganj', 'dep_name': 'police', 'officer_name': 'Prakash sir', 'req_by': 'Uttam Sir' }]
  displayedColumns = ['Sr.No', 'order_id', 'dep_name', 'branch_name', 'officer_name', 'letter_sub', 'req_by', 'action']



  list_flag: boolean = true;
  c_flag: boolean = false;
  u_flag: boolean = false;
  appli_flag: boolean = false;
  update_button: boolean = false;
  submit_button: boolean = false;
  list_act = 'active'
  c_act = 'deactive'
  u_act = 'active'
  appli_act = 'deactive'
  depdata:any = {}
  zoneWiseUser:any = []
  async list() {
    this.list_flag = true;
    this.c_flag = false;
    this.u_flag = false;
    this.appli_flag = false;
    this.submit_button = false;
    this.update_button = false;
    this.list_act = 'active'
    this.c_act = 'deactive'
    this.u_act = 'deactive'
    this.appli_act = 'deactive'
    await this.getSupportLetter();

  }
  create() {
    this.depdata = {}
    this.list_flag = false;
    this.u_flag = false;
    this.c_flag = true;
    this.appli_flag = false;
    this.submit_button = true;
    this.update_button = false;
    this.list_act = 'deactive'
    this.c_act = 'active'
    this.u_act = 'deactive'
    this.appli_act = 'deactive'
  }

  async back() {
    this.list_flag = false;
    this.u_flag = false;
    this.c_flag = true;
    this.appli_flag = false;
    // this.submit_button = false;
    // this.update_button = false;
    this.list_act = 'deactive'
    this.c_act = 'active'
    this.u_act = 'deactive'
    this.appli_act = 'deactive'
  }

  applicationSc() {

    if (!this.depdata['order_id'] || !this.depdata['dep_name'] || !this.depdata['branch_name'] || !this.depdata['officer_name']
      || !this.depdata['letter_sub'] || !this.depdata['req_by']) {
      swal.fire('Warning', 'Please Fill Mandatory Field', 'warning');
      return;
    }

    this.list_flag = false;
    this.u_flag = false;
    this.c_flag = false;
    this.appli_flag = true;
    this.list_act = 'deactive'
    this.c_act = 'deactive'
    this.u_act = 'deactive'
    this.appli_act = 'active'

    this.depdata['requested_by'] = this.userObj[this.depdata['req_by']]
    this.depdata['req_role_desc'] = this.allUsersObj[this.depdata['req_by']]['role_name']
    this.editorServiceService.to_data = this.depdata;
    // console.log("depdat flag : ", this.depdata)
  }

  codeValueTechObj:any = {}
  codeValueObj = {}
  codeValueShowObj = {}
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  async getCodeValue_obj() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.codeValueTechObj = this.codeValueObj;
      console.log(this.codeValueTechObj, 'uuuu')
      this.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }
  orderData = []

  officer_name:any = []
  async getorderData() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.settingService.getOrderData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.orderData = resp['data']
    } else {
      swal.fire('Error', 'Error Occurred While Getting Department', 'error');
    }
  }
  async getOfficerName(branch_name:any) {
    let obj:any = {}
    this.officer_name = []
    obj['b_acct_id'] = this.b_acct_id;
    obj['branch_name'] = branch_name;
    let resp = await this.settingService.getOfficerName(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("officer data : ", resp['data'])
      this.officer_name = resp['data'];
    } else {
      swal.fire('Error', 'Error Occurred While Getting Department', 'error');
    }
  }
  a:any
  async open_update(element:any , a:any) {
    this.list_flag = false;
    this.c_flag = false;
    this.submit_button = false;
    this.update_button = true;
    this.u_flag = true;
    this.list_act = 'deactive'
    this.c_act = 'deactive'
    this.u_act = 'active'
    this.depdata = element;
    this.depdata['req_by'] = Number(element['req_by'])


    await this.getZoneWiseUser();
    await this.getOfficerName(this.depdata['branch_name'])
    console.log("open update :", this.depdata)
  }


  allSearchableEmp = []
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr = []
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ICMS'
    var resp = await this.mainService.getuserformodulerolemapping(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;

      for (let i = 0; i < arr.length; i++) {
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
        this.userObj[arr[i]['user_id']] = arr[i]['emp_name']
      }
      this.allSearchableEmp = arr
      console.log("all searchable employee : ",this.allSearchableEmp)
    } else {
      this.spinner.hide()
    }
  }

  userObj:any = {}

  current_role = []
  async getCurrentRole() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ICMS';
    console.log(obj)
    var resp = await this.mainService.getCurrentMdRole(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.current_role = resp['data']
    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }


  allUsersObj :any= []
  async getCurrentUsers() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'ICMS'
    console.log(obj)
    let resp = await this.mainService.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log("currents Users : ", resp.data)
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.current_role.length; j++) {
          if (resp['data'][i]['role_cd'] != "ICMS_EMP") {
            if (this.current_role[j]['role_cd'] == resp['data'][i]['role_cd']) {
              if (this.allUsersObj[resp['data'][i]['user_id']] == undefined) {
                resp['data'][i]['role_name'] = this.current_role[j]['role_name']
                this.allUsersObj[resp['data'][i]['user_id']] = resp['data'][i]
              } else {
                this.allUsersObj[resp['data'][i]['user_id']]['role_name'] = this.allUsersObj[resp['data'][i]['user_id']]['role_name'] + " , " + this.current_role[j]['role_name']
              }
            }
          }
        }
      }
      console.log(this.allUsersObj)

    } else {
      this.spinner.hide()
      swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }


  async getZoneWiseUser() {
    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['order_id'] = this.depdata['order_id'];
    let resp = await this.settingService.getZoneWiseUser(obj);
    if (resp['error'] == false) {


      this.zoneWiseUser = []
      this.allSearchableEmp.map((x:any) => {
        resp['data'].map((y:any) => {
          if (x['user_id'] == y['user_id']) {
           x['role_desc'] = this.allUsersObj[x['user_id']]['role_name']
            this.zoneWiseUser.push(x);
          }
        })
      })
      this.spinner.hide();
      console.log("getZoneWiseUser : ", this.zoneWiseUser)
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error Occurred While Getting User', 'error');
    }
  }

  supportData = []
  async getSupportLetter() {
    this.zoneWiseUser = []
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.settingService.getSupportLetter(JSON.stringify(obj));
    if (resp['error'] == false) {

      // this.supportData = resp.data;
      this.dataSource = new MatTableDataSource(resp.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator1;
      console.log("support Data : ", this.supportData)
    } else {
      swal.fire('Error', 'Error Occurred While Getting User', 'error');
    }
  }


  async createSupportLetter() {
    this.depdata['b_acct_id'] = this.b_acct_id;
    this.depdata['create_user_id'] = this.ebillUser.user_id;
    this.depdata['appli_data'] = JSON.stringify(this.editorServiceService.to_data);
    console.log(this.depdata)
    let resp = await this.settingService.createSupportLetter(this.depdata);
    if (resp['error'] == false) {
      await this.getSupportLetter();
      swal.fire('Successfully', 'Support letter created successfully', 'success');
      this.depdata = {}
      this.submit_button = false;
      this.update_button = false;
      this.list();
    } else {
      swal.fire('Error', 'Error While Creating Support letter', 'error');
    }
  }



  download(data:any) {

    /*************************************First method to print pdf*************************************** */
    //  // console.log(data)
    //  let letter = JSON.parse(data.appli_data);
    //  // let a = document.body.appendChild(document.createElement("a"));
    //  // a.download = "Support Letter.pdf";
    //  let letter_data = letter['header'] + letter['body'] + letter['footer'];
    //  // console.log(letter_data)
    //  // a.href = "data:text/html," + letter_data;
    //  // a.click()


    /*************************************Second method to print pdf*************************************** */

    // console.log(data)
    let letter = JSON.parse(data.appli_data);
    let letter_data = letter['header'] + letter['body'] + letter['footer'];
    let mywindow = window.open();
    mywindow!.document.write(letter_data);
    mywindow!.document.close(); // necessary for IE >= 10
    mywindow!.focus(); // necessary for IE >= 10*/
    mywindow!.print();
    mywindow!.close();

    /*************************************Third method to print pdf*************************************** */

    // // var data = document.getElementById('pdfDownload');
    // html2canvas(letter_data).then(canvas => {
    //   // Few necessary setting options
    //   var imgWidth =1300;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   const contentDataURL = canvas.toDataURL()
    //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   pdf.save('new-file.pdf');
    // });
  }


  async update() {
    this.depdata['b_acct_id'] = this.b_acct_id;
    this.depdata['create_user_id'] = this.ebillUser.user_id;
    this.depdata['appli_data'] = JSON.stringify(this.editorServiceService.to_data);
    console.log(this.depdata)
    let resp = await this.settingService.updateSupportLetter(this.depdata);
    if (resp['error'] == false) {
      await this.getSupportLetter();
      swal.fire('Successfully', 'Support Letter Updated Successfully', 'success');
      this.depdata = {}
      this.submit_button = false;
      this.update_button = false;
      this.list();
    } else {
      swal.fire('Error', 'Error While Updating Support letter', 'error');
    }
  }
}
