<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Balance Sheet </div>
                </div>
                <div class="tab-pane fade show active" id="tab-1">
                    <br>

                    <div class="ibox-body">
                        <br>
                        <form name="form" (ngSubmit)="f.form.valid && allBalanceSheet()" #f="ngForm">

                            <div class="row">
                                <div class="col-3 text-center">
                                    <h6> Select Year : </h6>
                                </div>
                                <!-- <div class="col-3 text-center">
                                <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                    (change)="setFinYear()" [multiple]="false" placeholder="Select Year"
                                    [(ngModel)]="selectedFinYear" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year">
                                </ng-select>
                            </div> -->
                                <div class="col-3">
                                    <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                        (change)="setFinYear()" [multiple]="false" placeholder="Select Year"
                                        [(ngModel)]="selectedFinYear" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year"
                                        #fin_year="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && fin_year.invalid }" required>
                                    </ng-select>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && fin_year.invalid" class="error-message">
                                        <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->


                                </div>

                                <div class="col-3 text-center">
                                    <h6> Strat Date : {{mainObj['start_date']}} </h6>
                                </div>

                                <div class="col-3 text-center">
                                    <h6> End Date : {{mainObj['end_date']}} </h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button type="submit" class="btn btn-primary" >Submit</button>&nbsp;
                                    <!-- <button class="btn btn-primary" (click)="allBalanceSheet()">Submit</button> -->
                                </div>
                            </div>
                            <br>
                        </form>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print2()">Print</button>
                                &nbsp;&nbsp;<button class="btn btn-success" (click)="excel()">Excel</button>
                            </div>
                        </div>
                        <br>
                        <div>
                            <table class="table table-striped card">
                                <tbody>
                                    <tr style="background-color:#188bff;color: #fff;">
                                        <th *ngFor="let col of displayedColumns" scope="col">{{col}}</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table class="table table-border">
                                                <tr style="background-color:#1589ff;color: #fff;">
                                                    <th *ngFor="let header of innerTableHeader"> {{header}} </th>
                                                </tr>
                                                <ng-container *ngFor="let lib of closing_lib">
                                                    <tr>
                                                        <td>{{lib['opening'] |number:'1.1-3'}}</td>
                                                        <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">
                                                                    <b>{{lib['lvl4_value']}}</b>
                                                                    <b *ngIf="lib['lvl5_code']!=null">-</b>
                                                                    {{lib['lvl5_value']}}
                                                                </h5>
                                                            </b>
                                                        </td>
                                                        <!-- <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">

                                                                    <b>{{lib['lvl4_code']}}</b>

                                                                    <b *ngIf="lib['lvl5_code']!=null">-</b>
                                                                    {{lib['lvl5_code']}}
                                                                </h5>
                                                            </b>
                                                        </td> -->
                                                        <!-- <td></td> -->
                                                        <td> <label class="text-primary"> {{lib['txn_amt']
                                                                |number:'1.1-3'}} </label></td>
                                                        <!-- <td *ngIf="lib['txn_amt'] < 0"> <label class="text-primary" > {{ (-1*lib['txn_amt'] |number:'1.1-3')}} </label></td> -->
                                                    </tr>
                                                </ng-container>
                                                <tr *ngIf="exp_total < inc_total">
                                                    <td></td>
                                                    <td> <b>
                                                            <h5 style="padding: 0px 20px;">
                                                                <b>Excess of Income Over Expenditure</b>
                                                            </h5>
                                                        </b>
                                                    </td>
                                                    <!-- <td></td>
                                                    <td></td> -->
                                                    <td>{{(inc_total-exp_total) | number:'1.1-3'}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b> {{lib_op_bal}} </b> </td>
                                                    <td class="text-center"> <b>TOTAL</b> </td>
                                                    <td> <b>{{(lib_cl_bal + lib_prop) | number:'1.3-3'}}</b> </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <!-- Following is for Assets --------Start------------------------------- -->
                                            <table class="table text-left">
                                                <tr style="background-color:#1589ff;color: #fff;">
                                                    <th *ngFor="let header of innerTableHeader">{{header}}</th>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> <b>
                                                            <h5 style="padding: 0px 20px;">
                                                                <b>Closing Stock-</b> Raw Stock
                                                            </h5>
                                                        </b>
                                                    </td>
                                                    <!-- <td></td>
                                                    <td></td> -->
                                                    <td> {{dataObj['curr_row_m'] | number:'1.1-3'}} </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> <b>
                                                            <h5 style="padding: 0px 20px;">
                                                                <b>Closing Stock-</b> In Progress Stock
                                                            </h5>
                                                        </b>
                                                    </td>
                                                    <!-- <td></td>
                                                    <td></td> -->
                                                    <td> {{dataObj['curr_work_p'] | number:'1.1-3'}} </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> <b>
                                                            <h5 style="padding: 0px 20px;">
                                                                <b>Closing Stock-</b> Finished Stock
                                                            </h5>
                                                        </b>
                                                    </td>
                                                    <!-- <td></td>
                                                    <td></td> -->
                                                    <td> {{dataObj['curr_finished_st'] |number:'1.1-3' }} </td>
                                                </tr>
                                                <ng-container *ngFor="let lib of closing_asset">
                                                    <tr>
                                                        <td>{{lib['opening'] | number:'1.1-3'}}</td>
                                                        <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">
                                                                    <b>{{lib['lvl4_value']}}</b>
                                                                    <b *ngIf="lib['lvl5_code']!=null">-</b>
                                                                    {{lib['lvl5_value']}}
                                                                </h5>
                                                            </b>
                                                        </td>
                                                        <!-- <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">

                                                                    <b>{{lib['lvl4_code']}}</b>

                                                                    <b *ngIf="lib['lvl5_code']!=null">-</b>
                                                                    {{lib['lvl5_code']}}
                                                                </h5>
                                                            </b>
                                                        </td> -->
                                                        <!-- <td></td> -->
                                                        <!-- <td> <label class="text-primary"> {{lib['txn_amt'] | number:'1.1-3'}} </label></td> -->
                                                        <td> <label class="text-primary"> {{(lib['txn_amt'] |
                                                                number:'1.1-3')}} </label></td>
                                                    </tr>
                                                    <!-- </tr> -->
                                                </ng-container>
                                                <tr *ngIf="exp_total > inc_total">
                                                    <td></td>
                                                    <td> <b>
                                                            <h5 style="padding: 0px 20px;">
                                                                <b>Excess of Expenditure Over Income </b>
                                                            </h5>
                                                        </b>
                                                    </td>
                                                    <!-- <td></td> -->
                                                    <!-- <td></td> -->
                                                    <td>{{ (exp_total-inc_total) | number:'1.1-3'}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{{ass_op_bal}} </b></td>
                                                    <td class="text-center"> <b>TOTAL</b> </td>
                                                    <td> <b>{{(ass_cl_bal + ass_prop + dataObj['curr_total']) |
                                                            number:'1.1-3'}} </b> </td>
                                                </tr>
                                            </table>
                                            <!-- Following is for Assets --------End-------------- -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>


<!-- <div>

    <ng-container *ngFor="let item of lvl4_data_lib">
    <tr>
        <td> </td>
        <td><b>
                <h5 class="text-primary" style="padding: 0px 20px;">
                    {{allCoaObj[item]}}</h5>
            </b></td>
        <td><b>
                <h5 class="text-primary" style="padding: 0px 20px;">
                    {{item}}
                </h5>
            </b></td>
        <td></td>
        <td> <label class="text-primary"> {{objLib0[item]}} </label>
        </td>
    </tr>
    <tr *ngFor="let item5 of obbLib1[item]">
        <td>{{lib_op_bal[item][item5.lvl5_cd]}}</td>
        <td>{{allCoaObj[item5.lvl5_cd]}}</td>
        <td>{{item5.lvl5_cd}}</td>
        <td> {{lib_op_bal[item][item5.lvl5_cd] + item5.lvl5_value}}
        </td>
        <td> </td>
    </tr>
</ng-container>


<ng-container *ngFor="let item of lvl4_data">
    <tr>
        <td> </td>
        <td><b>
                <h5 class="text-primary" style="padding: 0px 20px;">
                    {{allCoaObj[item]}}</h5>
            </b></td>
        <td><b>
                <h5 class="text-primary" style="padding: 0px 20px;">
                    {{item}}
                </h5>
            </b></td>
        <td></td>
        <td class> <label class="text-primary"> {{obj0[item]}} </label>
        </td>
    </tr>
    <tr *ngFor="let item5 of obb1[item]">
        <td>{{ass_op_bal[item][item5.lvl5_cd]}}</td>

        <td>{{allCoaObj[item5.lvl5_cd]}}</td>
        <td style="padding: 0px 20px;">{{item5.lvl5_cd}}</td>
        <td>{{ass_op_bal[item][item5.lvl5_cd] + item5.lvl5_value}} </td>
        <td> </td>
    </tr>
</ng-container>

</div> -->
