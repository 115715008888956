import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner"
import { EstablishService } from '../../service/establish.service';
import { AllEmpService } from '../../service/all-emp.service';
import { SettingService } from '../../service/setting.service';
import { PayrollService } from '../../service/payroll.service';
import { DataAccessService } from '../../service/data-access.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { RoleService as MD_RoleService } from '../../../md/service/role.service';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { UserService as MD_UserService } from '../../../md/service/user.service';
import { MatStepper } from '@angular/material/stepper';


import { min } from 'moment';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.css']
})
// @ViewChild('stepper') stepper: MatHorizontalStepper;
export class EstablishmentComponent implements OnInit {
  ob: any;

  constructor(private dataAccessService: DataAccessService, private payrollService: PayrollService, private settingService: SettingService, private allEmpService: AllEmpService, private MD_UserService: MD_UserService, private MD_mainService: MD_mainService, private MD_RoleService: MD_RoleService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private estNewService: EstablishService, private _formBuilder: FormBuilder) { }
  personalInfoObj:any = {};
  erpUser:any;
  b_acct_id:any;
  ele:any
  first:any;
  second:any
  third:any
  fourth:any
  isLinear:any
  allEmplyees:any = [];
  updateServiceObj :any= {}
  updateOrgObj:any = {}

  addselectEmpObj :any= {};
  newallEmplyees :any= []
  arr_id:any;
  emp_id:any;
  flag :any= false;
  delArrcode:any = [];


  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;


  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }




  displayedColumns:any = ['emp_id', 'emp_name', 'establishment_type_code', 'designation_group_code', 'designation_code', 'cadre_code', 'class_code', 'pay_commission_code', 'grade_pay_code', 'pay_scale_code', 'level_code', 'basic_pay', 'retirement_age', 'emp_status_code', 'order_id', 'inc_month', 'joining_type_code', 'joining_date', 'notice_period', 'effective_timestamp'];
  datasource:any;

  addEstObj :any= {};

  Allleave:any = [];
  AllFixPay:any = [];
  // AllFixPay1 = []
  allArr :any= [];
  empIdToName :any= {};

  errorFlag :any= false;
  updateObj :any= {}
  PRINT_flag1:any;
  PRINT_flag2:any;
  latestLeaveArr :any= [];
  basic_pay:any;
  allRules :any= [];
  allMatrix:any = [];
  matrixObj :any= {};
  allSalRule :any= [];
  allLeaveRule:any = []
  dateToday:any = "0000-00-00";
  baseArr:any = [];
  grade_pay :any= [];
  pay_scale:any = [];
  level :any= []
  variablepayObj :any= {};
  promotionArr:any = [];
  objectToSend :any= {};
  basicObj :any= {};
  basicToLastEffectiveDateObj :any= {};
  test :any= {}
  ts:any = [{ code: "MORNING", value: "MORNING" }, { code: "AFTERNOON", value: "AFTERNOON" }];
  allPayRule :any= [];
  allSepPayRule:any = [];
  allPayNonSepRule:any = []
  end_dt :any= "2030-08-19";
  start_dt:any
  state :any= { true: 'Yes', false: 'No' }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getDate();

    await this.getAllRuleInfo();
    await this.getAllEmployees();
    await this.getAllPayMatrix();
    await this.getAllHRAMatrix();
    await this.getAllGISMatrix();
    await this.getAllCCAMatrix();
    await this.getAllCityMatrix();

    await this.getAllPRBMatrix();
    await this.getAllWFMatrix();


    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.allEmplyees[i]['le_id'] == this.erpUser.user_id) {
        this.arr_id = this.allEmplyees[i]['arr_id']
      }
    }
    await this.changeEmployee();
    // await this.getEmployeeTimeSheet();
    // await this.getAllPayRules();
    // await this.getAllLeaveRules();
    // await this.getAllBasic();
  }
  allPRBMatrix:any = []
  async getAllPRBMatrix() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getpbrMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allPRBMatrix = resp.data;

      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all fields list', 'error')
      // this.snackBar.open("Error while getting  all fields list", 'Error', {
      //   duration: 5000
      // });
    }

  }
  allwfMatrix:any = []
  async getAllWFMatrix() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getwfMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allwfMatrix = resp.data;

      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all fields list', 'error')
      // this.snackBar.open("Error while getting  all fields list", 'Error', {
      //   duration: 5000
      // });
    }
  }




  //Reference Data
  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data;
      console.log(this.dateToday)
    } else {

    }
  }



  allRuleCurrentlyActive :any= []
  fixPay :any= {};
  VarPay :any= {};
  async getAllRuleInfo() {
    var temp_allSepPayRule = [];
    this.allPayNonSepRule = [];
    this.allRuleCurrentlyActive = [];
    this.fixPay = {};
    this.VarPay = {};
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = this.dateToday;
    obj['status'] = ['ACTIVE']
    var resp = await this.settingService.getAllSalaryCD(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allPayRule = resp.data;
      for (let i = 0; i < this.allPayRule.length; i++) {
        if (this.allPayRule[i]['valid_from'] <= this.dateToday && this.dateToday < this.allPayRule[i]['valid_upto']) {
          this.allRuleCurrentlyActive.push(this.allPayRule[i])

          if (this.allPayRule[i]['is_spe_allowd'] == 1) {
            this.VarPay[this.allPayRule[i]['component_code']] = true
            temp_allSepPayRule.push(this.allPayRule[i])
          } else {
            this.fixPay[this.allPayRule[i]['component_code']] = true
            this.allPayNonSepRule.push(this.allPayRule[i])
          }
        }
      }
      this.allSepPayRule = temp_allSepPayRule
      console.log(this.allSepPayRule)
      console.log(this.allPayNonSepRule)
      console.log(this.fixPay)
      console.log(this.VarPay)
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all salary component list ', 'error')
      // this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  objPayComp :any= {};
  objPayCompData :any= {};
  fixPayData :any= [];

  objPayCompVar:any = {};
  objPayCompDataVar :any= {};

  async applyRuleOnSepComp() {
    this.objPayCompVar = {};
    this.objPayCompDataVar = {};

    for (let i = 0; i < this.allRuleCurrentlyActive.length; i++) {

      var amt :any= 0;
      var pay_code:any = this.allRuleCurrentlyActive[i].pay_code;
      if (this.allRuleCurrentlyActive[i].rate_type == 'CAL') {
        console.log(this.allRuleCurrentlyActive[i].function_name)
        amt = await  this.allRuleCurrentlyActive[i].function_name();
        console.log(amt)
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-CAL', is_change: true };

      }
      else if (this.allRuleCurrentlyActive[i].rate_type == 'FIX') {
        amt = this.allRuleCurrentlyActive[i].amount;
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-FIX', is_change: false };

      } else if (this.allRuleCurrentlyActive[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allRuleCurrentlyActive[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (this.objPayCompVar[arr[j]] == undefined) {
            this.objPayCompVar[arr[j]] = 0;
          }
          amt += this.objPayCompVar[arr[j]];

        }
        amt = parseFloat((amt * this.allRuleCurrentlyActive[i].amount / 100).toFixed(2));
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-PERCENTAGE', is_change: true };


      }
    }


    console.log(this.objPayCompVar)
    console.log(this.objPayCompDataVar)
  }


  setVariablePay() {

    console.log(this.allVarComp)
    for (let i = 0; i < this.allVarComp.length; i++) {
      if (this.fixPay[this.allVarComp[i]['pay_comp']] || this.VarPay[this.allVarComp[i]['pay_comp']]) {
        if (this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['is_change'] && this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['amt'] != this.allVarComp[i]['rate']) {
          let ob :any= {};
          ob['b_acct_id'] = this.b_acct_id;
          ob['arr_id'] = this.arr_id;
          ob['eff_from'] = this.addEstObj['start_dt'];
          ob['effective_end_dt'] = this.allVarComp[i]['valid_upto']
          ob['status'] = 'ACTIVE';
          ob['create_user_id'] = this.erpUser.user_id;
          ob['component_status_code'] = 'ACTIVE';
          ob['pay_comp'] = this.allVarComp[i]['pay_comp'];
          ob['rate'] = this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['amt'];
          ob['pay_code'] = this.allVarComp[i].pay_code;
          ob['curr_cd'] = 'INR';
          ob['rate_unit'] = 'MNTH';
          ob['status'] = true;
          console.log(ob)
          this.AllFixPay.push(ob);
        }
      }
    }
  }
  matStepperNext = false
  step1 = true;
  async applyRule() {

    if (this.addEstObj['class_code'] == null || this.addEstObj['class_code'] == undefined || this.addEstObj['class_code'] == '' ||
      this.addEstObj['cadre_code'] == null || this.addEstObj['cadre_code'] == undefined || this.addEstObj['cadre_code'] == '' ||
      this.addEstObj['designation_code'] == null || this.addEstObj['designation_code'] == undefined || this.addEstObj['designation_code'] == '' ||
      this.addEstObj['designation_group_code'] == null || this.addEstObj['designation_group_code'] == undefined || this.addEstObj['designation_group_code'] == '' ||
      this.addEstObj['retirement_age'] == null || this.addEstObj['retirement_age'] == undefined || this.addEstObj['retirement_age'] == '' ||
      this.addEstObj['inc_month'] == null || this.addEstObj['inc_month'] == undefined || this.addEstObj['inc_month'] == '' ||
      this.addEstObj['promotion_type_code'] == null || this.addEstObj['promotion_type_code'] == undefined || this.addEstObj['promotion_type_code'] == '' ||
      // this.test['notice_period'] == null || this.test['notice_period'] == undefined || this.test['notice_period'] == '' ||
      this.addEstObj['notice_period'] === null || this.addEstObj['notice_period'] === undefined || this.addEstObj['notice_period'] === '' ||

      this.addEstObj['establishment_type_code'] == null || this.addEstObj['establishment_type_code'] == undefined || this.addEstObj['establishment_type_code'] == '' ||
      this.addEstObj['pay_commission_code'] == null || this.addEstObj['pay_commission_code'] == undefined || this.addEstObj['pay_commission_code'] == '' ||
      this.addEstObj['pay_scale_code'] == null || this.addEstObj['pay_scale_code'] == undefined || this.addEstObj['pay_scale_code'] == '' ||
      this.addEstObj['grade_pay_code'] == null || this.addEstObj['grade_pay_code'] == undefined || this.addEstObj['grade_pay_code'] == '' ||
      this.addEstObj['level_code'] == null || this.addEstObj['level_code'] == undefined || this.addEstObj['level_code'] == '' ||
      this.addEstObj['basic_pay'] == null || this.addEstObj['basic_pay'] == undefined || this.addEstObj['basic_pay'] == '' ||
      this.addEstObj['conv_code'] == null || this.addEstObj['conv_code'] == undefined || this.addEstObj['conv_code'] == '' ||
      this.addEstObj['conv_amt']=== null ||this.addEstObj['conv_amt'] === undefined || this.addEstObj['conv_amt'] === '' ||
      this.addEstObj['uniform_ind'] == null || this.addEstObj['uniform_ind'] == undefined || this.addEstObj['uniform_ind'] == '' ||
      this.addEstObj['work_city'] == null || this.addEstObj['work_city'] == undefined || this.addEstObj['work_city'] == '' ||

      this.addEstObj['start_dt'] == null || this.addEstObj['start_dt'] == undefined || this.addEstObj['start_dt'] == '')
       {

//      this.addEstObj['start_dt'] == null || this.addEstObj['start_dt'] == undefined || this.addEstObj['start_dt'] == '') {

      swal.fire('Warning', " * Field  Required...!",'warning')
      // this.step1=false
      return;
    }
    // this.addEstObj['notice_period'] = this.test['notice_period']
    console.log("this si hte obj ", this.addEstObj)
    // this.stepper1.next();

    var obj:any = new Object()
    obj['emp_id'] = this.addEstObj['emp_id']
    obj['b_acct_id'] = this.b_acct_id;
    var resp1 = await this.estNewService.getNewEstabishment(JSON.stringify(obj));
    console.log(resp1)
    if (resp1.data.length == 0) {
      // if(resp1['data']==true){

      this.PRINT_flag1 = true
      this.PRINT_flag2 = false
      await this.getAllLeaveRules()

      //}
    }
    else {
      this.PRINT_flag1 = false
      this.PRINT_flag2 = true
      await this.getAllLeaveRules()
      this.latestLeaveArr = resp1.data;

      //console.log( this.latestLeaveArr['leave_code'].sort)
    }
    this.objPayComp = {};
    this.objPayCompData = {};

    for (let i = 0; i < this.allPayNonSepRule.length; i++) {

      var amt = 0;
      var pay_code = this.allPayNonSepRule[i].pay_code;
      if (this.allPayNonSepRule[i].rate_type == 'CAL') {
        console.log(this.allPayNonSepRule[i].function_name)
        amt = await this.allPayNonSepRule[i].function_name();
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };

      }
      else if (this.allPayNonSepRule[i].rate_type == 'FIX') {
        amt = this.allPayNonSepRule[i].amount;
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };

      } else if (this.allPayNonSepRule[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allPayNonSepRule[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (this.objPayComp[arr[j]] == undefined) {
            this.objPayComp[arr[j]] = 0;
          }
          amt += this.objPayComp[arr[j]];

        }
        amt = parseFloat((amt * this.allPayNonSepRule[i].amount / 100).toFixed(2));
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };


      }
      // this.stepper1.next();
    }

    await this.applyRuleOnSepComp();
    this.AllFixPay = []
    // this.AllFixPay1 = []
    var comp = Object.keys(this.objPayComp);
    for (var i = 0; i < comp.length; i++) {
      var ob:any = {};
      ob['b_acct_id'] = this.b_acct_id;
      ob['arr_id'] = this.arr_id;
      ob['eff_from'] = this.addEstObj['start_dt'];
      ob['effective_end_dt'] = "2038-01-01"
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_comp'] = comp[i];
      ob['rate'] = this.objPayCompData[comp[i]].amt;
      ob['pay_code'] = this.objPayCompData[comp[i]].pay_code;
      ob['curr_cd'] = 'INR';
      ob['rate_unit'] = 'MNTH';
      // if (comp[i] == 'CONV') {
      //   // ob['rate'] = this.test['conv']
      //   ob['rate'] = this.addEstObj['conv_amt']
      // }
      console.log(ob);
      // this.ob.push(this.test['conv'])
      if (this.prviousCompStatus[comp[i]] != undefined) {
        ob['status'] = this.prviousCompStatus[comp[i]]
      } else {
        ob['status'] = true;
      }

      if (ob['rate'] != 0) {
        this.AllFixPay.push(ob);
        // this.AllFixPay1.push(ob);
      }

    }

    this.AllFixPay = this.sortData(this.AllFixPay, 'pay_comp');
    console.log(this.AllFixPay)
    this.stepper1.next();
  }
  async nextStep2() {
    this.stepper1.next();
  }
  amount = 0;
  amount_disable = true;
  sep_component_code:any;

  sortData(arr:any, key:any) {

    return arr.sort((a:any, b:any) => (a[key] > b[key]) ? 1 : (b[key] > a[key]) ? -1 : 0)
  }

  //Actual Get
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';
    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data
      this.allEmplyees = arr;
      this.newallEmplyees = []
      for (let i = 0; i < resp.data.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, resp.data[i]);
        obj['emp_name'] = obj['emp_id'] + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      for (var i = 0; i < this.newallEmplyees.length; i++) {
        this.empIdToName[this.newallEmplyees[i].emp_id] = this.newallEmplyees[i].joining_date;
      }
      console.log(this.allEmplyees)
      console.log(this.newallEmplyees);
      this.newallEmplyees = await this.dataAccessService.filterEmployeeData(this.newallEmplyees)
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }


  }

  async getAllArrangementOfParty() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;

    var resp = await this.estNewService.getEstablishementInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allArr = resp.data;
      this.spinner.hide()
      for (let i = 0; i < this.allArr.length; i++) {
        this.allArr[i]['tempjoining_date'] = this.mainService.dateformatchange(this.allArr[i]['joining_date'])

      }


      this.datasource = new MatTableDataSource(this.allArr)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire('Error', ' Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }

  }

  allSalComp :any= [];
  allVarComp :any= [];
  prevAllSalComp:any = [];
  async getEmpolyeeEffectiveArrangemt(arr_id:any) {
    this.spinner.show()

    var obj:any = new Object()
    obj['status'] = false;
    obj['data'] = [];


    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = arr_id;

    var resp = await this.estNewService.getCurrentEstablishementInfo(JSON.stringify(obj));
    console.log(obj)
    console.log(resp.data)
    if (resp['error'] == false) {
      if (resp.data.length > 0) {
        obj['status'] = true;
        obj['data'] = resp.data[0]
      }
      this.spinner.hide()

    } else {
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
      this.spinner.hide()
    }

    var resp1 = await this.estNewService.getArrangmentSalComponent(JSON.stringify(obj));
    console.log(resp1);
    this.allSalComp = [];
    this.allVarComp = [];
    this.prevAllSalComp = [];
    if (resp1['error'] == false) {
      this.prevAllSalComp = resp1.data;

      console.log(this.prevAllSalComp);
      for (let i = 0; i < this.prevAllSalComp.length; i++) {

        if ((new Date(this.prevAllSalComp[i]['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(this.prevAllSalComp[i]['valid_upto']))) {

          if (this.fixPay[this.prevAllSalComp[i]['pay_comp']]) {
            this.allSalComp.push(this.prevAllSalComp[i])
          } else {
            this.allVarComp.push(this.prevAllSalComp[i])
          }
        }
      }
      this.allSalComp = await this.sortData(this.allSalComp, 'pay_comp')
      this.allVarComp = await this.sortData(this.allVarComp, 'pay_comp')

    } else {
      console.log("Error in sal getting...")
    }


    var resp2 = await this.estNewService.getFixPayStatus(JSON.stringify(obj));
    console.log(resp2);
    if (resp2['error'] == false) {
      for (let i = 0; i < this.prevAllSalComp.length; i++) {
        this.prevAllSalComp[i]['status'] = true;
        for (let j = 0; j < resp2.data.length; j++) {
          if (this.prevAllSalComp[i]['pay_comp'] == resp2.data[j]['pay_comp_code'] && resp2.data[j]['status'] == 0) {
            this.prevAllSalComp[i]['status'] = false;
          }
        }
      }
      this.prviousCompStatus = {}
      for (let j = 0; j < resp2.data.length; j++) {
        this.prviousCompStatus[resp2.data[j]['pay_comp_code']] = true;
        if (resp2.data[j]['status'] == 0) {
          this.prviousCompStatus[resp2.data[j]['pay_comp_code']] = false;
        }
      }
    } else {
      console.log("Error in sal getting...")
    }


    console.log(this.prviousCompStatus)
    return obj
  }

  prviousCompStatus :any= {}
  //tab 2
  setJoiningData() {
    if(   this.addEstObj['order_id']==undefined ||    this.addEstObj['order_id']==null  ||  this.addEstObj['order_id']==''
    || this.addEstObj['joining_time']==undefined ||  this.addEstObj['joining_time']==null || this.addEstObj['joining_time']==''
    || this.addEstObj['joining_date']==undefined ||  this.addEstObj['joining_date']==null || this.addEstObj['joining_date']==''
    || this.addEstObj['joining_service_date']==undefined  || this.addEstObj['joining_service_date']==null || this.addEstObj['joining_service_date']==''
    || this.addEstObj['joining_type_code']==undefined ||  this.addEstObj['joining_type_code']==null || this.addEstObj['joining_type_code']==''   )
 {
  swal.fire("Warning", "* Field  required", 'warning');
  return
 }

    console.log(this.baseArr1, "hgghgggg")
    console.log(this.addEstObj, "addEstObj")
    var startdt;
    if (this.addEstObj['joining_time'] == 'MORNING') {
      startdt = this.addEstObj['joining_date'].split('T')[0];
    } else {

      startdt = this.addOneDay(this.addEstObj['joining_date'].split('T')[0]);
    }
    this.addEstObj['old_start_dt'] = startdt;
    this.addEstObj['start_dt'] = startdt;
    this.addEstObj['end_dt'] = this.end_dt
    this.stepper1.next();
  }

  joined = true;
  async changeEmployee() {

    if (this.arr_id == null || this.arr_id == undefined) {
      this.refres();
      return;
    }
    await this.getAllArrangementOfParty();

    for (var i = 0; i < this.allEmplyees.length; i++) {
      if (this.allEmplyees[i].arr_id == this.arr_id) {
        this.addEstObj = this.allEmplyees[i];
      }
    }
    console.log(this.addEstObj, "shweta this.addEstObj--")
    var st = await this.getEmpolyeeEffectiveArrangemt(this.arr_id);
    if (st['status'] == true) {
      this.addEstObj = Object.assign({}, st['data']);
      console.log(this.addEstObj)
      if (this.addEstObj['order_id'] == null || this.addEstObj['order_id'] == undefined) {
        this.joined = true;
        this.addEstObj['joining_date'] = this.dateToday.split(' ')[0]
        // this.addEstObj['joining_date'] = this.dateToday
        // this.setJoiningData();
      } else {
        this.joined = false;
        console.log()
        this.addEstObj['start_dt'] = this.dateToday.split(' ')[0];
        console.log(this.addEstObj['start_dt'])
        this.addEstObj['end_dt'] = this.end_dt
      }
      // return;
    }


    // this.updateObj = Object.assign(this.addEstObj);
    this.baseArr = [];
    if (this.matrixObj[this.addEstObj['pay_scale_code'] + this.addEstObj['grade_pay_code'] + this.addEstObj['level_code']] != undefined) {
      this.baseArr = this.matrixObj[this.addEstObj['pay_scale_code'] + this.addEstObj['grade_pay_code'] + this.addEstObj['level_code']];
    }
    var grade_pay_code = parseInt(this.addEstObj['grade_pay_code']);
    var level_code = this.addEstObj['level_code'];
    var basic_pay = this.addEstObj['basic_pay']
    var conv_amt = this.addEstObj['conv_amt']
    var pay_scale_code = this.addEstObj['pay_scale_code']
    await this.changepayband(this.addEstObj['pay_commission_code'])
    this.addEstObj['pay_scale_code'] = pay_scale_code

    await this.changeGradePay1(this.addEstObj['pay_scale_code']);
    this.addEstObj['grade_pay_code'] = grade_pay_code;

    await this.changelevel(this.addEstObj['grade_pay_code']);
    this.addEstObj['level_code'] = level_code

    await this.changeGradePay(this.addEstObj['level_code'])

    console.log(basic_pay, "basic_pay--")

    this.addEstObj['basic_pay'] = basic_pay
    // this.baseArr1.map(x => {
    //   if (basic_pay === x['basic_pay']) {
    //     this.addEstObj['basic_pay'] =  x['index_num']//.toString()
    //   }
    // })


    // conv_amt
    console.log(this.addEstObj['basic_pay'], "this.addEstObj['basic_pay']--")
    // this.addEstObj['conv_amt'] = this.objPayComp['CONV']
    if (this.addEstObj['uniform_ind'] != null && this.addEstObj['uniform_ind'] != undefined) {
      this.addEstObj['uniform_ind'] = this.addEstObj['uniform_ind'].toString()
      this.addEstObj['inc_month'] = this.addEstObj['inc_month'].toString()
      this.addEstObj['retirement_age'] = this.addEstObj['retirement_age'].toString()
    }
  }

  async convamt() {
    // console.log(this.test['conv'])

    // this.objPayCompVar = {};
    // this.objPayCompDataVar = {};
    //
    // for (let i = 0; i < this.allRuleCurrentlyActive.length; i++) {

    // var amt = 0;
    // var pay_code = this.allRuleCurrentlyActive[i].pay_code;
    // if ((this.allRuleCurrentlyActive[i].rate_type == 'CAL')&&(this.allRuleCurrentlyActive[i].function_name == 'getConv')) {
    //console.log(this.allRuleCurrentlyActive[i].function_name)
    // amt = await this[this.allRuleCurrentlyActive[i].function_name]();
    // console.log(amt)
    // var obj = Object.assign({}, this.addEstObj);
    //  if (obj['conv_code'] == 'CYCLE') {
    //   var amount = amt
    //   this.addEstObj['conv_amt']= amount
    //   console.log(amount)
    //  }
    // else if (obj['conv_code'] == 'MOTORCYCLE') {
    //   var amount = amt
    //   this.addEstObj['conv_amt']= amount
    //   console.log(amount)
    //  }
    //  else if (obj['conv_code'] == 'CAR') {
    //   var amount = amt
    //   this.addEstObj['conv_amt']= amount
    //   console.log(amount)
    //  }
    //  else if(obj['conv_code']=='NA')
    //  {
    //   this.addEstObj['conv_amt']= 'NA'
    //  }
    // this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
    // this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-CAL', is_change: true };

    // }
    // }

    // var obj = Object.assign({}, this.addEstObj);
    // var amount = 0;

    // if (obj['conv_code'] == 'CYCLE') {
    //   amount = 300
    //   this.addEstObj['conv_amt']= amount
    // }
    // if (obj['conv_code'] == 'MOTORCYCLE') {
    //   amount = 700
    //   this.addEstObj['conv_amt']= amount
    // }
    // if (obj['conv_code'] == 'CAR') {
    //   amount = 1000
    //   this.addEstObj['conv_amt']= amount
    // }
    // return amount;
  }

  pay_scale1 :any= []
  changepayband(pay_com:any) {
    this.addEstObj['pay_scale_code'] = null
    this.addEstObj['grade_pay_code'] = null;
    this.addEstObj['level_code'] = null;
    this.addEstObj['basic_pay'] = null;
    this.pay_scale1 = [];
    this.grade_pay1 = [];
    this.grade_pay2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    this.level1 = [];
    this.level2 = [];
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (pay_com == this.allMatrix[i]['pay_commission']) {
        if (!this.pay_scale1.includes(this.allMatrix[i]['pay_band']))
          this.pay_scale1.push(this.allMatrix[i]['pay_band']);
      }
    }
  }



  async addNewLeaveEstabishment() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['leave_info'] = this.allLeaveRule;
    obj['emp_id'] = this.addEstObj['emp_id'];
    obj['create_user_id'] = this.erpUser.user_id;
    var resp = await this.estNewService.addNewleaveEstabishment(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      swal.fire('Success',' Add Leave Successfully','success')
      // this.snackBar.open("Add Leave Successfully", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while adding leave ', 'error')
      // this.snackBar.open("Error while adding leave", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async allDone() {
    // await this.updateLeave()

    if (this.addEstObj['start_dt'] < this.addEstObj['joining_date']) {
      swal.fire("Warning", "Salary Start Date should not less then joining Date of Employee.....", 'warning')
      return;
    }
    if (this.addEstObj['joining_service_date'] > this.addEstObj['joining_date']) {
      swal.fire("Warning", "Joining Service Date should not greater than joining Date of Employee.....", 'warning')
      return;
    }
    this.spinner.show()
    await this.setVariablePay();
    var empInfo:any = new Object();
    empInfo['b_acct_id'] = this.b_acct_id;
    empInfo['arr_id'] = this.arr_id;
    var resp = await this.estNewService.getEmployeePersonalInfo(JSON.stringify(empInfo));
    if (resp['error'] == false) {
      this.personalInfoObj = resp.data[0];
    } else {
      swal.fire('Error', 'Error while getting employee date of birth', 'error');
    }
    var dt = this.addSixtyYears(parseInt(this.personalInfoObj['emp_dob']), this.addEstObj['retirement_age']);
    this.addEstObj['retirement_date'] = dt;

    this.objectToSend = {};
    // this.addEstObj['conv_amt']=this.test['conv']
    this.objectToSend['b_acct_id'] = this.b_acct_id;
    this.addEstObj['emp_status_code'] = 'ACTIVE';
    this.objectToSend['establishment_info'] = this.addEstObj;
    this.objectToSend['fixed_pay_amount'] = this.AllFixPay;
    //  this.objectToSend['leave_info'] = this.Allleave;
    //   this.objectToSend['variable_pay'] = this.variablepayObj;
    //   this.objectToSend['promotion'] = this.promotionArr;

    this.objectToSend['eff_from'] = this.addEstObj['start_dt']

    console.log(this.objectToSend)
    var resp = await this.estNewService.addNewEstabishment(this.objectToSend);
    if (resp['error'] == false) {
      await this.addNewLeaveEstabishment()
      setTimeout(async () => {
        await this.getAllEmployees();
        await this.getAllArrangementOfParty();
        this.updateServiceObj = {};
        await this.open_list()
        swal.fire("Success", "Employee Activated", 'success')
        this.spinner.hide();
      }, 1000);

    } else {
      this.spinner.hide()
      swal.fire("Error", "Error occurred", 'error')
    }


  }


  //////////////************************************************************************ */
  refres() {
    this.arr_id = null;
    this.addEstObj = {};
    this.AllFixPay = [];
    this.datasource = new MatTableDataSource([])
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  addSixtyYears(dt:any, age:any) {
    dt = new Date(dt);
    age = parseInt(age)
    var date = new Date(dt.setFullYear(dt.getFullYear() + age))
    var returmDate = ""
    var mnth;
    var day;
    if (date.getDate() == 1) {
      date = new Date(date.setDate(date.getDate() - 1))
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      returmDate = [date.getFullYear(), mnth, day].join("-");
    } else {
      mnth = ("0" + (date.getMonth() + 2)).slice(-2)
      date = new Date([date.getFullYear(), mnth, "01"].join("-"));
      date = new Date(date.setDate(date.getDate() - 1))
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      returmDate = [date.getFullYear(), mnth, day].join("-");
    }

    return returmDate;
  }
  addOneDay(dt:any) {
    var tomorrow = new Date(dt);

    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];

  }
  add_years(dt:any, n:any) {
    n = parseInt(n)
    dt = new Date(dt);
    return new Date(dt.setFullYear(dt.getFullYear() + n));
  }
  convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  ///princy code


  grade_pay2:any = []
  grade_pay1:any = [];

  unique(value:any, index:any, self:any) {
    return self.indexOf(value) === index
  }

  grade_pay_code:any;
  changeGradePay1(pay_scale:any) {
    this.addEstObj['grade_pay_code'] = null;
    this.addEstObj['level_code'] = null;
    this.addEstObj['basic_pay'] = null;
    this.updateObj['grade_pay_code'] = null;
    this.updateObj['level_code'] = null;
    this.updateObj['basic_pay'] = null;
    this.grade_pay1 = [];
    this.grade_pay2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    this.level1 = [];
    this.level2 = [];

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (pay_scale == this.allMatrix[i]['pay_band']) {
        this.grade_pay2.push(this.allMatrix[i]['grade_pay_code']);
      }
    }

    this.grade_pay1 = this.grade_pay2.filter(this.unique)


  }
  level1:any = []
  level2:any = []
  changelevel(grade_pay:any) {
    this.addEstObj['level_code'] = null;
    this.addEstObj['basic_pay'] = null;
    this.updateObj['level_code'] = null;
    this.updateObj['basic_pay'] = null;
    this.level1 = [];
    this.level2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    var pay_scale = this.addEstObj['pay_scale_code']



    for (let i = 0; i < this.allMatrix.length; i++) {

      if ((grade_pay == this.allMatrix[i]['grade_pay_code']) && (pay_scale == this.allMatrix[i]['pay_band'])) {
        this.level2.push(this.allMatrix[i]['level_code']);
      }
    }

    this.level1 = this.level2.filter(this.unique)


  }
  baseArr1 :any= [];
  baseArr2:any = [];
  changeGradePay(level:any) {

    this.addEstObj['basic_pay'] = null;
    this.updateObj['basic_pay'] = null;

    this.baseArr1 = [];
    this.baseArr2 = [];

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (level == this.allMatrix[i]['level_code']) {
        this.baseArr2.push(this.allMatrix[i]);
      }


    }
    console.log(this.allMatrix)
    console.log(this.baseArr2)
    this.baseArr1 = this.baseArr2
    console.log(this.baseArr1, "JJJ")
  }

  //changes and add speciacl comp

  // SepobjPayComp;
  SepobjPayCompData:any
  async changeSepComp() {
    this.amount_disable = true;
    this.SepobjPayCompData = {};
    // var obj = {};
    // var obj1 = {};

    for (let i = 0; i < this.allSepPayRule.length; i++) {
      console.log(this.allSepPayRule[i]['component_code'] == this.sep_component_code)

      if (this.allSepPayRule[i]['component_code'] == this.sep_component_code) {
        console.log(this.sep_component_code)
        var amt :any= 0;
        var pay_code = this.allSepPayRule[i].pay_code;
        // var effective_dt = eff_dt;
        if (this.allSepPayRule[i].rate_type == 'CAL') {
          console.log(this.allSepPayRule[i].function_name)
          amt = await this.allSepPayRule[i].function_name();
          console.log(amt)
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        }
        else if (this.allSepPayRule[i].rate_type == 'FIX' && this.allSepPayRule[i].fix_is_var == 1) {
          amt = 0;
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };
          this.amount_disable = false;

        }
        else if (this.allSepPayRule[i].rate_type == 'FIX' && this.allSepPayRule[i].fix_is_var != 1) {
          amt = this.allSepPayRule[i].amount;
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        } else if (this.allSepPayRule[i].rate_type == 'PERCENTAGE') {
          amt = 0;
          var arr = this.allSepPayRule[i].dependent_component.split(',');
          console.log(arr)
          for (var j = 0; j < arr.length; j++) {
            console.log(this.objPayComp[arr[j]])
            if (this.objPayComp[arr[j]] == undefined) {
              this.objPayComp[arr[j]] = 0;
            }
            amt += this.objPayComp[arr[j]];
            console.log(amt)
          }
          amt = parseFloat((amt * this.allSepPayRule[i].amount / 100).toFixed(2));
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        }
      }

      // var eff_dt = this.allSepPayRule[i].effective_dt.split('T')[0];


      // else {
      // if (this.allSalRule[i].upper_limit >= this.addEstObj['grade_pay_code'] && this.allSalRule[i].lower_limit <= this.addEstObj['grade_pay_code']) {
      //   obj[this.allSalRule[i].component_code] = { amt: this.allSalRule[i].amount, pay_code: this.allSalRule[i].pay_code };
      //   obj1[this.allSalRule[i].component_code] = this.allSalRule[i].amount;;
      // }
      // }

    }

    console.log(this.SepobjPayCompData)
    var comp = Object.keys(this.SepobjPayCompData);
    this.amount = this.SepobjPayCompData[comp[0]]['amt']


  }
  saveSepComp() {
    var comp = Object.keys(this.SepobjPayCompData);
    this.SepobjPayCompData[comp[0]]['amt'] = this.amount
    this.objPayCompData[comp[0]] = this.SepobjPayCompData[comp[0]]
    this.objPayComp[comp[0]] = this.SepobjPayCompData[comp[0]]['amt']
    console.log(this.objPayComp);
    console.log(this.objPayCompData);
    this.SepobjPayCompData = {};
    this.AllFixPay = []
    console.log(this.objPayComp);
    console.log(this.objPayCompData);
    var comp = Object.keys(this.objPayComp);
    for (var i = 0; i < comp.length; i++) {
      var ob:any = {} ;
      ob['b_acct_id'] = this.b_acct_id;
      ob['emp_id'] = this.arr_id;
      ob['effective_start_dt'] = this.addEstObj['start_dt'];
      ob['effective_end_dt'] = "2038-01-01"
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_component_code'] = comp[i];
      ob['pay_component_amt'] = this.objPayCompData[comp[i]].amt;
      ob['pay_code'] = this.objPayCompData[comp[i]].pay_code;
      ob['type'] = this.objPayCompData[comp[i]].type;
      if (ob['pay_component_amt'] != 0) {
        this.AllFixPay.push(ob);
      }
    }

    console.log(this.AllFixPay)
  }


  /////*********************meta data******************************************************************************

  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    if (this.arr_id == null || this.arr_id == undefined || this.arr_id == "") {
      swal.fire('Warning', "Please Select One Employee", 'warning')
      return;
    }
    console.log("this si hte updateEstObj--", this.updateObj, this.joined);
    console.log("this si hte addEstObj--", this.addEstObj, this.joined);

    this.test['notice_period'] = this.addEstObj['notice_period'];
    // this.test['conv']=this.addEstObj[]

    // this.test['notice_period'] = this.addEstObj['notice_period'];

    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
    // this.test['notice_period'] = this.addEstObj['notice_period'];
  }

  ///************************get pay matrix *****************************8*/
  BasicPayObj = {};


  async getAllPayMatrix() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getMatrix(JSON.stringify(obj));
    var grade_pay_obj :any= {};
    var lvlArr:any = [];
    var gradePayArr:any = []
    var payScaleArr:any = []

    var pay_band_obj:any = {};
    var lvl_obj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allMatrix = resp.data;
      for (var i = 0; i < this.allMatrix.length; i++) {
        if (grade_pay_obj[this.allMatrix[i].grade_pay_code] == undefined) {
          gradePayArr.push({ code: this.allMatrix[i].grade_pay_code, value: this.allMatrix[i].grade_pay_code });
          grade_pay_obj[this.allMatrix[i].grade_pay_code] = '1';
        }
        if (pay_band_obj[this.allMatrix[i].pay_band] == undefined) {
          payScaleArr.push({ code: this.allMatrix[i].pay_band, value: this.allMatrix[i].pay_band });
          pay_band_obj[this.allMatrix[i].pay_band] = '1';
        }
        if (lvl_obj[this.allMatrix[i].level_code] == undefined) {
          lvlArr.push({ code: this.allMatrix[i].level_code, value: this.allMatrix[i].level_code });
          lvl_obj[this.allMatrix[i].level_code] = '1';
        }

        if (this.matrixObj[this.allMatrix[i].grade_pay_code] == undefined) {
          this.matrixObj[this.allMatrix[i].grade_pay_code] = []
        }
        this.matrixObj[this.allMatrix[i].grade_pay_code].push({ code: this.allMatrix[i].index_num.toString(), value: this.allMatrix[i].basic_pay.toString() });
        // this.BasicPayObj[this.allMatrix[i]['pay_band'].toString() +"-"+ this.allMatrix[i]['grade_pay_code'].toString() +"-"+ this.allMatrix[i]['level_code'].toString() +"-"+ this.allMatrix[i]['index_num'].toString()] = this.allMatrix[i]['basic_pay']

      }
      this.level = lvlArr;
      this.grade_pay = gradePayArr;
      this.pay_scale = payScaleArr;
    } else {
      this.spinner.hide()
      swal.fire('Error', `${resp.data}`, 'error')
      // this.snackBar.open(resp.data, 'Error', {
      //   duration: 5000
      // });
    }
  }

  ///************************get HRA matrix *****************************8*/

  allHRAMatrix:any = []
  async getAllHRAMatrix() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getHRAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allHRAMatrix = resp.data;
      console.log(this.allHRAMatrix)

    } else {

    }
  }

  ///************************get GIS matrix *****************************8*/

  allGISMatrix :any= []
  async getAllGISMatrix() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getGISMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allGISMatrix = resp.data;
      console.log(this.allGISMatrix)
    } else {

    }
  }

  ///************************get CCA matrix *****************************8*/

  allCCAMatrix:any = []
  async getAllCCAMatrix() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';

    var resp = await this.estNewService.getCCAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allCCAMatrix = resp.data;
    } else {
    }
  }
  allCityMatrix = [];
  allCityMatrixObj = [];
  async getAllCityMatrix() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getCityMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allCityMatrix = resp.data;
      for (let i = 0; i < this.allCityMatrix.length; i++) {
        this.allCityMatrixObj[this.allCityMatrix[i]['city']] = this.allCityMatrix[i]['city_group']
      }

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all City Matrix ', 'error')
      // this.snackBar.open("Error while getting  all City Matrix", 'Error', {
      //   duration: 5000
      // });
    }

    console.log(this.allCityMatrixObj)
  }

  //***********************************pre define function****************************** */
  async getbasic() {
    var obj = Object.assign({}, this.addEstObj);
    var amount = 0;
    for (let i = 0; i < this.allMatrix.length; i++) {
      if ((obj['pay_scale_code'].toString() == this.allMatrix[i]['pay_band'].toString()) && (obj['grade_pay_code'].toString() == this.allMatrix[i]['grade_pay_code'].toString())
        && (obj['level_code'].toString() == this.allMatrix[i]['level_code'].toString()) && (obj['basic_pay'].toString() == this.allMatrix[i]['index_num'].toString())) {
        amount = this.allMatrix[i]['basic_pay'];
      }
    }
    return amount;
  }

  async gethra() {
    var obj = Object.assign({}, this.addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allHRAMatrix.length; i++) {
      if ((this.allHRAMatrix[i].level_code.toString() == obj['level_code'].toString() && this.allHRAMatrix[i].city_group == this.allCityMatrixObj[obj['work_city']])) {
        amount = this.allHRAMatrix[i].amount
      }
    }
    return amount;
  }
  async getCCA() {
    var obj = Object.assign({}, this.addEstObj);
    console.log(obj)

    var amount = 0;
    console.log(this.allCCAMatrix)
    for (let i = 0; i < this.allCCAMatrix.length; i++) {
      if ((this.allCCAMatrix[i].upper_limit >= obj['grade_pay_code'] && this.allCCAMatrix[i].lower_limit <= obj['grade_pay_code'] && this.allCCAMatrix[i].city_cd == obj['work_city'])) {
        amount = this.allCCAMatrix[i].amount
      }
    }
    return amount;
  }
  async getDepAllow() {
    var amount = 0;
    var obj = Object.assign({}, this.addEstObj);

    console.log(obj)
    if (obj['joining_type_code'] == 'DEPUTATION') {
      var basic = await this.getbasic();

      if (obj['work_city'] == obj['dep_work_city']) {
        amount = basic * 5 / 100;
        if (amount > 1500) {
          amount = 1500;
        }
      } else {
        amount = basic * 10 / 100;
        if (amount > 3000) {
          amount = 3000;
        }
      }
    }

    console.log(amount)
    return amount;

  }
  getMed() {
    var amount = 0;
    var obj = Object.assign({}, this.addEstObj);
    console.log(this.mainService.codeValueTechObj['HR0053'], "hhhgghgh")
    if (obj['joining_type_code'] != 'DEPUTATION') {
      amount = this.mainService.codeValueTechObj['HR0053'][0]['value']
      // 200;
    }
    return amount;

  }

  getGIS() {
    var obj = Object.assign({}, this.addEstObj);
    var amount = 0;
    for (let i = 0; i < this.allGISMatrix.length; i++) {
      if ((this.allGISMatrix[i].upper_limit >= obj['grade_pay_code'] && this.allGISMatrix[i].lower_limit <= obj['grade_pay_code'])) {
        amount = this.allGISMatrix[i].amount
      }
    }
    return amount;
  }

  getConv() {
    var obj = Object.assign({}, this.addEstObj);
    var amount = 0;
    amount = this.addEstObj['conv_amt']
    // if (obj['conv_code'] == 'CYCLE') {
    //   amount = this.addEstObj['conv_amt']
    // }
    // if (obj['conv_code'] == 'MOTORCYCLE') {
    //   amount = this.addEstObj['conv_amt']
    // }
    // if (obj['conv_code'] == 'CAR') {
    //   amount = this.addEstObj['conv_amt']
    // }

    return amount;
  }

  getWA() {
    var obj = Object.assign({}, this.addEstObj);
    var amount = 0;
    console.log(this.mainService.codeValueTechObj['HR0054'], "----hhhgghgh")

    if (obj['class_code'] == 'IV' || (obj['designation_code']).toLowerCase() == ('DRIVER').toLowerCase()) {
      amount = this.mainService.codeValueTechObj['HR0054'][0]['value']
      // 100
    }

    return amount;
  }
  getHVDTAllow() {
    var obj = Object.assign({}, this.addEstObj);
    var amount = 0;

    if ((obj['designation_code']).toLowerCase() == ('DRIVER').toLowerCase()) {
      amount = 1200
    }

    return amount;
  }

  async getPr_Bhatta_Rule() {
    var obj = Object.assign({}, this.addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allPRBMatrix.length; i++) {
      if (this.allPRBMatrix[i].grade_pay_code == obj['grade_pay_code'].toString()) {
        amount = this.allPRBMatrix[i].pbr_pay
      }
    }
    return amount;
  }
  async getWF() {
    var obj = Object.assign({}, this.addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allwfMatrix.length; i++) {
      if (this.allwfMatrix[i].class_code.toString() == obj['class_code']) {
        amount = this.allwfMatrix[i].wf_pay
      }
    }
    return amount;
  }


  //***********************************pre define function****************************** */

  //************************meta data******************************************** */



  // allTimeSheet = []
  // async getEmployeeTimeSheet() {
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['shift_code'] = 'MORN';
  //   var resp = await this.payrollService.getAllEmpTimeSheet(obj);
  //   if (resp['error'] == false) {
  //     this.allTimeSheet = resp.data;
  //     console.log(this.allTimeSheet)
  //   } else {
  //     this.snackBar.open("Error while getting  all time sheet list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }



  // async insertAttendence(arr_id) {

  //   var obj:any = new Object();
  //   obj['shift_code'] = 'MORN'
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['arr_id'] = arr_id;

  //   // for(let i=0;i<this.allTimeSheet.length;i++){
  //   //   var ob=Object.assign({},this.allTimeSheet);
  //   //   ob['arr_id']=arr_id;
  //   //   ob['is_work_expected']=1;
  //   //   ob['did_employee_work']=1;
  //   //   ob['should_be_paid']=1;
  //   //   obj['data'].push(ob)
  //   // }
  //   console.log(obj)

  //   var resp = await this.payrollService.insertAttendenceSheet(obj);
  //   if (resp['error'] == false) {
  //     console.log("Attendence Inserted..................")
  //   } else {

  //   }


  // }

  async getAllBasic() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = this.dateToday.split(' ')[0];
    var resp = await this.estNewService.getPartyFixedPay(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      var dt = resp['data'];
      for (var i = 0; i < dt.length; i++) {
        if (dt[i]['pay_component_code'] == 'BASIC') {
          this.basicObj[dt[i]['emp_id']] = dt[i]['pay_component_amt']
          this.basicToLastEffectiveDateObj[dt[i]['emp_id']] = dt[i]['effective_start_dt']
        }
      }

      console.log(this.basicToLastEffectiveDateObj)
    } else {
      this.spinner.hide()
      swal.fire("Error", "Error while getting basic pay", 'error')
    }
  }

  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }

  async getAllLeaveRules() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getAllRules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allLeaveRule = resp.data;
    } else {

      swal.fire('Error', 'Error while getting  all Leave Rule list ', 'error')
      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async getAllPayRules() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = '2090-10-10';
    obj['status'] = ['ACTIVE']
    var resp = await this.estNewService.getComponentDefinition(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allSalRule = resp.data;

    } else {
      this.spinner.hide()
      swal.fire('Error', ' Error while getting  all salary component list', 'error')
      // this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  //tab 5
  AllFixPayOnUpdate :any= []
  async changeEmployeeOnEstUpdate() {

    var st = await this.getEmpolyeeEffectiveArrangemt(this.arr_id);
    if (st['status'] == true) {
      this.addEstObj = st['data'];
      console.log(st);

    } else {
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Warning...', 'Please Add Establishments for this employee', 'warning');
      return;
    }

    this.updateObj = Object.assign({}, this.addEstObj);

    var emp_id = this.updateObj['emp_id'];
    this.updateObj['basic_pay1'] = this.basicObj[emp_id];
    this.baseArr = [];
    if (this.matrixObj[this.updateObj['grade_pay_code']] != undefined) {
      this.baseArr = this.matrixObj[this.updateObj['grade_pay_code']];
    }
    console.log(this.updateObj);

    var grade_pay_code = parseInt(this.updateObj['grade_pay_code']);
    var level_code = parseInt(this.updateObj['level_code']);
    var basic_pay = this.updateObj['basic_pay']

    await this.changeGradePay1(this.updateObj['pay_scale_code']);
    this.updateObj['grade_pay_code'] = grade_pay_code;

    await this.changelevel(this.updateObj['grade_pay_code']);
    this.updateObj['level_code'] = level_code

    await this.changeGradePay(this.updateObj['level_code'])
    this.updateObj['basic_pay'] = basic_pay

    if (this.updateObj['uniform_ind'] != null && this.updateObj['uniform_ind'] != undefined) {
      this.updateObj['uniform_ind'] = this.updateObj['uniform_ind'].toString()
    }

  }
  // async updateLeave(){
  //   console.log(this.delArrcode);
  //   let oldLeaveArr = [];
  //   let updateLeaveArr = [];
  //   let deleteLeaveArr = [];
  //      for(let i=0;i<this.latestLeaveArr.length;i++)
  //      {
  //        for(let j=0;j<this.allLeaveRule.length;j++){
  //          if(this.latestLeaveArr[i]['leave_code'] == this.allLeaveRule[j]['leave_code'] && this.latestLeaveArr[i]['num_of_leaves'] == this.allLeaveRule[j]['num_of_leaves']){
  //             oldLeaveArr.push(this.latestLeaveArr[i])
  //             break;
  //          }
  //          else if(this.latestLeaveArr[i]['leave_code'] != this.allLeaveRule[j]['leave_code'] ){
  //            deleteLeaveArr.push(this.allLeaveRule[j])
  //            break;
  //          }
  //          else{
  //            updateLeaveArr.push(this.latestLeaveArr[i])
  //            break;
  //          }
  //        }
  //      }
  // }

  async delete(ob:any, i:any) {
    this.spinner.show();
    var obj:any = new Object();
    obj["id"] = ob.id;
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.settingService.deleteLeaveRuleInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllLeaveRules()
      this.allLeaveRule.splice(i, 1)
      swal.fire('Success','Leave Rule Delete Successfully! ','success')
      // this.snackBar.open("Leave Rule Delete Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while deleting Leave Rule ', 'error')
      // this.snackBar.open("Error while deleting Leave Rule ", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async saveArangmentOnUpdate() {

    if (this.basicToLastEffectiveDateObj[this.updateObj['emp_id']] > this.updateObj['start_dt']) {
      swal.fire('Warning...', 'Please select start correct date for this employee', 'warning');
      return;
    }
    this.AllFixPayOnUpdate = [];

    //*****************************************Fixed Pay Calculation */

    var obj:any = {};
    var obj1 :any= {};
    obj['BASIC'] = { amt: parseFloat(this.updateObj['basic_pay']), pay_code: 'PAY' };
    obj1['BASIC'] = parseFloat(this.updateObj['basic_pay']);

    for (var i = 0; i < this.allSalRule.length; i++) {

      var eff_dt = this.allSalRule[i].effective_dt.split('T')[0];

      var amt = 0;
      var pay_code = this.allSalRule[i].pay_code;
      var effective_dt = eff_dt;
      if (this.allSalRule[i].rate_type == 'FIX') {
        amt = this.allSalRule[i].amount;
        obj1[this.allSalRule[i].component_code] = amt;
        obj[this.allSalRule[i].component_code] = { amt: amt, pay_code: this.allSalRule[i].pay_code };

      } else if (this.allSalRule[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allSalRule[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (obj1[arr[j]] == undefined) {
            obj1[arr[j]] = 0;
          }
          amt += obj1[arr[j]];

        }
        amt = parseFloat((amt * this.allSalRule[i].amount / 100).toFixed(2));
        obj1[this.allSalRule[i].component_code] = amt;
        obj[this.allSalRule[i].component_code] = { amt: amt, pay_code: this.allSalRule[i].pay_code };


      } else {
        if (this.allSalRule[i].upper_limit >= this.addEstObj['grade_pay_code'] && this.allSalRule[i].lower_limit <= this.addEstObj['grade_pay_code']) {
          obj[this.allSalRule[i].component_code] = { amt: this.allSalRule[i].amount, pay_code: this.allSalRule[i].pay_code };
          obj1[this.allSalRule[i].component_code] = this.allSalRule[i].amount;;

        }
      }


    }
    this.AllFixPayOnUpdate = [];

    var comp = Object.keys(obj);
    for (var i = 0; i < comp.length; i++) {
      var ob:any = {} ;
      ob['b_acct_id'] = this.b_acct_id;
      ob['emp_id'] = this.arr_id;
      ob['effective_start_dt'] = this.updateObj['start_dt'];
      ob['effective_end_dt'] = "2090-10-10";
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_component_code'] = comp[i];
      if (ob['pay_component_code'] == 'NPS') {
        ob['pay_component_amt'] = Math.round(obj[comp[i]].amt);

      } else {
        ob['pay_component_amt'] = obj[comp[i]].amt;

      }
      ob['pay_code'] = obj[comp[i]].pay_code;
      this.AllFixPayOnUpdate.push(ob);
    }

    for (var i = 0; i < this.AllFixPayOnUpdate.length; i++) {
      for (var j = i + 1; j < this.AllFixPayOnUpdate.length; j++) {
        if (this.AllFixPayOnUpdate[i].pay_component_code == this.AllFixPayOnUpdate[j].pay_component_code) {
          this.AllFixPayOnUpdate[i].effective_end_dt = this.AllFixPayOnUpdate[j].effective_start_dt;
        }
      }
    }

  }

  deleteFixPayOnUpdate(i:any) {
    this.AllFixPayOnUpdate.splice(i, 1);
  }

  async updateArangment() {
    this.spinner.show()
    this.updateObj['b_acct_id'] = this.b_acct_id;
    this.updateObj['create_user_id'] = this.erpUser.user_id;
    this.updateObj['emp_status_code'] = "ACTIVE";
    this.updateObj['fixed_pay_amount'] = { b_acct_id: this.b_acct_id, fixed_pay_info: this.AllFixPayOnUpdate, end_dt: '2090-10-10' };
    var resp_arr = await this.estNewService.updateEstablishmentInfo(this.updateObj);

    if (resp_arr['error'] == false) {


      setTimeout(async () => {
        await this.getAllArrangementOfParty();
        this.updateObj = {};
        this.AllFixPayOnUpdate = [];

        $('.nav-tabs a[href="#tab-1"]').tab('show');
        swal.fire("Success", "... Info Updated!", 'success');
        this.spinner.hide();
      }, 1000);

    } else {
      this.spinner.hide()
      swal.fire('Error', `${resp_arr.data}`, 'error')
      // this.snackBar.open(resp_arr.data, 'Error', {
      //   duration: 5000
      // });
    }
  }


  //tab 4
  async changeEmployeeOnOrg() {

    var st = await this.getEmpolyeeEffectiveArrangemt(this.arr_id);
    if (st['status'] == true) {
      this.addEstObj = st['data'];
    } else {
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Warning...', 'Please Add Establishments for this employee', 'warning');
      return;
    }

    this.updateOrgObj = Object.assign({}, this.addEstObj);
    this.updateOrgObj['inc_month'] = this.updateOrgObj['inc_month'].toString()
    this.updateOrgObj['retirement_age'] = this.updateOrgObj['retirement_age'].toString();

  }

  async updateOrgInfo() {
    this.spinner.show()
    this.updateOrgObj['b_acct_id'] = this.b_acct_id;
    this.updateOrgObj['create_user_id'] = this.erpUser.user_id;
    var resp_arr = await this.estNewService.updateOrgInfo(this.updateOrgObj);

    if (resp_arr['error'] == false) {
      setTimeout(async () => {
        await this.getAllArrangementOfParty();
        this.updateOrgObj = {};
        $('.nav-tabs a[href="#tab-1"]').tab('show');
        swal.fire("Success", "... Info Updated!", 'success');
        this.spinner.hide();
      }, 1000);

    } else {
      this.spinner.hide()
      swal.fire('Error', `${resp_arr.data}`, 'error')
      // this.snackBar.open(resp_arr.data, 'Error', {
      //   duration: 5000
      // });
    }
  }



  //tab 3
  async changeEmployeeOnService() {

    var st = await this.getEmpolyeeEffectiveArrangemt(this.arr_id);
    if (st['status'] == true) {
      this.addEstObj = st['data'];
    } else {
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Warning...', 'Please Add Establishments for this employee', 'warning');
      return;

    }

    this.updateServiceObj = Object.assign(this.addEstObj);
  }

  async updateServiceInfo() {
    this.spinner.show()
    this.updateServiceObj['b_acct_id'] = this.b_acct_id;
    this.updateServiceObj['create_user_id'] = this.erpUser.user_id;
    var resp_arr = await this.estNewService.updateServiceInfo(this.updateServiceObj);

    if (resp_arr['error'] == false) {

      setTimeout(async () => {
        await this.getAllArrangementOfParty();
        this.updateServiceObj = {};
        $('.nav-tabs a[href="#tab-1"]').tab('show');
        swal.fire("Success", "... Info Updated!", 'success');
        this.spinner.hide();
      }, 1000);

    } else {
      this.spinner.hide()
      swal.fire('Error', `${resp_arr.data}`, 'error')
      // this.snackBar.open(resp_arr.data, 'Error', {
      //   duration: 5000
      // });
    }
  }

  // Calculation and Save..........................

  async saveArangment() {
    this.promotionArr = [];
    this.Allleave = [];
    this.AllFixPay = [];
    var empInfo:any = new Object();
    empInfo['b_acct_id'] = this.b_acct_id;
    empInfo['emp_id'] = this.arr_id;
    var resp = await this.estNewService.getEmployeePersonalInfo(JSON.stringify(empInfo));
    if (resp['error'] == false) {
      this.personalInfoObj = resp.data[0];
    } else {
      swal.fire('Error', 'Error while getting employee date of birth', 'error');
    }

    //*****************************************Fixed Pay Calculation */

    var salArr:any = [];
    var obj:any = {};
    var obj1 :any= {};
    obj['BASIC'] = { amt: parseFloat(this.addEstObj['basic_pay']), pay_code: 'PAY' };
    obj1['BASIC'] = parseFloat(this.addEstObj['basic_pay']);

    // if (this.addEstObj['joining_type_code'] == 'DEPUTATION') {
    //   var mn = 0;
    // if (this.personalInfoObj['emp_local_addr_dist'] == 'LKO') {
    //   mn = obj1['BASIC'] * 5 / 100;
    //   if (mn > 1500) {
    //     mn = 1500;
    //   }
    // } else {
    //   mn = obj1['BASIC'] * 10 / 100;
    //   if (mn > 3000) {
    //     mn = 3000;
    //   }
    // }
    // mn = obj1['BASIC'] * 10 / 100;
    // if (mn > 3000) {
    //   mn = 3000;
    // }

    // obj['DEP'] = { amt: parseFloat(mn.toFixed(2)), pay_code: 'PAY' };
    // obj1['DEP'] = parseFloat(mn.toFixed(2));

    // } else {
    //   obj['MED'] = { amt: 300, pay_code: 'PAY' };
    //   obj1['MED'] = 300;
    // }


    // if (this.addEstObj['class_code'] == 'IV' && this.addEstObj['conv_code'] == 'CYCLE') {

    //   obj['CONV'] = { amt: 350, pay_code: 'PAY' };
    //   obj1['CONV'] = 350;

    // }
    // if (this.addEstObj['class_code'] == 'III' && this.addEstObj['conv_code'] == 'MOTORCYCLE') {

    //   obj['CONV'] = { amt: 800, pay_code: 'PAY' };
    //   obj1['CONV'] = 800;

    // }
    // if ((this.addEstObj['class_code'] == 'I' || this.addEstObj['class_code'] == 'II') && this.addEstObj['conv_code'] == 'CAR') {

    //   obj['CONV'] = { amt: 1600, pay_code: 'PAY' };
    //   obj1['CONV'] = 1600;

    // }


    for (var i = 0; i < this.allSalRule.length; i++) {

      var eff_dt = this.allSalRule[i].effective_dt.split('T')[0];

      var amt = 0;
      var pay_code = this.allSalRule[i].pay_code;
      var effective_dt = eff_dt;
      if (this.allSalRule[i].rate_type == 'FIX') {
        amt = this.allSalRule[i].amount;
        obj1[this.allSalRule[i].component_code] = amt;
        obj[this.allSalRule[i].component_code] = { amt: amt, pay_code: this.allSalRule[i].pay_code };

      } else if (this.allSalRule[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allSalRule[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (obj1[arr[j]] == undefined) {
            obj1[arr[j]] = 0;
          }
          amt += obj1[arr[j]];

        }
        amt = parseFloat((amt * this.allSalRule[i].amount / 100).toFixed(2));
        obj1[this.allSalRule[i].component_code] = amt;
        obj[this.allSalRule[i].component_code] = { amt: amt, pay_code: this.allSalRule[i].pay_code };


      } else {
        if (this.allSalRule[i].upper_limit >= this.addEstObj['grade_pay_code'] && this.allSalRule[i].lower_limit <= this.addEstObj['grade_pay_code']) {
          obj[this.allSalRule[i].component_code] = { amt: this.allSalRule[i].amount, pay_code: this.allSalRule[i].pay_code };
          obj1[this.allSalRule[i].component_code] = this.allSalRule[i].amount;;

        }
      }


    }
    this.AllFixPay = [];
    // if (this.addEstObj['class_code'] == 'IV' && this.addEstObj['uniform_ind'] == '1') {

    //   obj['WA'] = { amt: 50, pay_code: 'PAY' };
    //   obj1['WA'] = 50;

    // } else {
    //   obj['WA'] = { amt: 0, pay_code: 'PAY' };
    //   obj1['WA'] = 0;
    // }
    var comp:any = Object.keys(obj);
    for (var i = 0; i < comp.length; i++) {
      var ob:any = {} ;
      ob['b_acct_id'] = this.b_acct_id;
      ob['emp_id'] = this.arr_id;
      ob['effective_start_dt'] = this.addEstObj['start_dt'];
      ob['effective_end_dt'] = "2090-10-10"
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_component_code'] = comp[i];
      if (ob['pay_component_code'] == 'NPS') {
        ob['pay_component_amt'] = Math.round(obj[comp[i]].amt);

      } else {
        ob['pay_component_amt'] = obj[comp[i]].amt;

      }
      ob['pay_code'] = obj[comp[i]].pay_code;
      this.AllFixPay.push(ob);
    }

    for (var i = 0; i < this.AllFixPay.length; i++) {
      for (var j = i + 1; j < this.AllFixPay.length; j++) {
        if (this.AllFixPay[i].pay_component_code == this.AllFixPay[j].pay_component_code) {
          this.AllFixPay[i].effective_end_dt = this.AllFixPay[j].effective_start_dt;
        }
      }
    }

    //*************************************Leave Calculation */
    this.Allleave = [];
    var x = this.dateToday.split('-');
    for (var i = 0; i < this.allLeaveRule.length; i++) {
      var ob:any = {}
      ob['emp_id'] = this.arr_id;
      ob['leave_code'] = this.allLeaveRule[i].leave_code;
      ob['leave_rate'] = this.allLeaveRule[i].leave_rate;
      ob['is_leave_carry_fwd'] = this.allLeaveRule[i].is_leave_carry_fwd;
      ob['renew_ind_on_year_change'] = this.allLeaveRule[i].renew_ind_on_year_change;
      ob['year'] = parseInt(x[0]);
      ob['month'] = parseInt(x[1]);
      ob['num_of_leaves'] = this.allLeaveRule[i].num_of_leaves;
      ob['leaves_remaining'] = this.allLeaveRule[i].num_of_leaves;
      ob['adjust_remaining_leaves'] = 0;
      ob['carry_forward_leaves'] = 0;
      ob['create_user_id'] = this.erpUser.user_id;
      this.Allleave.push(ob);


    }
    //***************************************Arrear Calculation */
    var obj:any = {};

    for (var i = 0; i < this.AllFixPay.length; i++) {
      obj[this.AllFixPay[i].pay_component_code] = this.AllFixPay[i];
    }
    var arrear = 0;
    var keys = Object.keys(obj);
    for (var i = 0; i < keys.length; i++) {
      if (obj[keys[i]].pay_code == 'PAY') {
        arrear = arrear + obj[keys[i]].pay_component_amt;
      }
    }

    var Difference_In_Time = new Date(this.dateToday).getTime() - new Date(this.addEstObj['start_dt']).getTime();

    var num_of_days = Difference_In_Time / (1000 * 3600 * 24);
    arrear = arrear * num_of_days / 30;
    this.variablepayObj['pay_component_amt'] = 0;
    var year = this.addEstObj['start_dt'].split('-');
    this.variablepayObj['b_acct_id'] = this.b_acct_id;
    this.variablepayObj['emp_id'] = this.arr_id;
    this.variablepayObj['pay_status_code'] = 'ACTIVE';
    this.variablepayObj['pay_component_code'] = 'JOINING ARREAR';
    if (arrear != undefined && arrear > 0) {
      this.variablepayObj['pay_component_amt'] = parseFloat(arrear.toFixed(2));

    }
    this.variablepayObj['pay_code'] = 'PAY';
    this.variablepayObj['pay_type'] = 'ARREAR';
    this.variablepayObj['create_user_id'] = this.erpUser.user_id;
    this.variablepayObj['pay_status_code'] = 'ACTIVE';
    this.variablepayObj['fin_year'] = year[0];
    this.variablepayObj['month'] = year[1];
    //*******************************Promotion Calculation */

    if (this.addEstObj['promotion_type_code'] != undefined && this.addEstObj['promotion_type_code'] != null && this.addEstObj['promotion_type_code'] != 'NA') {
      var x = this.addEstObj['promotion_type_code'].split('-');
      for (var i = 0; i < x.length; i++) {
        var dt :any= this.convert(this.add_years(this.addEstObj['joining_service_date'], x[i]));
        var ob2 :any= new Object();
        ob2['grade_pay_code'] = '0'
        ob2['emp_id'] = this.addEstObj['emp_id'];
        ob2['promotion_status'] = 'SCHEDULED'
        ob2['promotion_type_code'] = 'ACP'
        ob2['order_id'] = this.addEstObj['order_id']
        ob2['promotion_interval'] = x[i];
        ob2['promotion_date'] = dt;
        ob2['create_user_id'] = this.erpUser.user_id;
        ob2['promotion_effective_dt'] = dt;
        ob2['level_code'] = '0'
        ob2['basic_pay'] = '0'
        ob2['pay_scale_code'] = '0'
        this.promotionArr.push(ob2);

      }

    }





    //******************************************Retirement Date Calculation */

    var dt:any = this.addSixtyYears(parseInt(this.personalInfoObj['emp_dob']), this.addEstObj['retirement_age']);
    this.addEstObj['retirement_date'] = dt;







  }

  deleteFixPay(i:any) {
    this.AllFixPay.splice(i, 1);
  }

  deleteLeave(ele:any, i:any) {
    this.allLeaveRule.splice(i, 1)
    this.delArrcode.push(ele.leave_code)
  }
  getYear(date:any) {
    if (date == null || date == undefined) {
      return "";
    }
    var datear1 = date.split('T')[0]
    var datearr = datear1.split("-")
    // return datearr[2]+'/'+datearr[1]+'/'+datearr[0]
    return datearr[0];
  }
}
