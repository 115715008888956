<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Journals</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Posted Journals</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-announcement"></i>
                                Processed Journals
                            </a>
                        </li>
                        <!--<li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refressadd()"><i
                                    class="ti-announcement"></i> Update
                                Business Event</a>
                        </li> -->

                    </ul>
                    <!-- <span style="color:red">*</span> -->
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                        <!--     <div class="row">
                                <div class="col-4 text-left">
                                    <h6>Enter the input limit:</h6>
                                </div>
                                <div class="col-4 text-left">
                                    <input class="form-control" type="text" [(ngModel)]="obj['jrnl_limit']"
                                        name="jrnl_limit">
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-success"
                                            (click)='getAllUnPostedJournalInfo()'>List</button>
                                    </div>
                                </div>
                            </div> -->


                            <div class=" table-container ">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>

                                </div>

                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource1"  matSort #sortCol2="matSort" >

                                        <ng-container matColumnDef="jrnl_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Journal Reference
                                                    #</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.jrnl_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ar_actvty_tcd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Journal Type</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.ar_actvty_lcl_num }} - {{ element.ar_actvty_tcd }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cr_amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Credited Amount</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.CREDIT_AMOUNT }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="db_amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Debited Amount</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.DEBIT_AMOUNT }} </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="ld_jrnl_entry_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Desc</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.jrnl_desc }} </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="prep_id">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Preparer</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.prep_id }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="create_timestamp">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Post Timestamp</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.create_timestamp }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Status</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.ld_jrnl_lfcycl_st_cd }} </td>
                                        </ng-container>




                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <button (click)="view(element)" class="btn btn-info">View</button>
                                                <!--   <button class="btn btn-primary" (click)="approveJV(element)" *ngIf="obj['status']=='UNPOSTED'">Approve</button>
                                                <button class="btn btn-danger" *ngIf="obj['status']=='UNDER APPROVAL'" (click)="reject(element)">Reject</button>
                                                <button *ngIf="element.status=='APPROVED'" class="btn btn-outline-info" (click)="post(element)">Post</button> -->
                                                <!--  <button (click)="show(element)" class="btn btn-success">Print</button> -->
                                                <!-- <button (click)="delete(element)" *ngIf="obj['status']=='UNPOSTED' || obj['status']=='APPROVED' || obj['status']=='REJECTED'" class="btn btn-danger">Delete</button> -->
                                                <!-- <button (click)="schedule(element)" *ngIf="obj['status']=='UNPOSTED'" class="btn btn-info">Schedule</button> -->
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedcolumns1;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedcolumns1;">
                                        </tr>
                                    </table>
                                    <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>

                        </div>

                        <div class="tab-pane fade" id="tab-2">
                            <br>


                            <div class=" table-container ">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                                placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>

                                </div>

                                <div class="example-container">
                                    <table mat-table [dataSource]="datasource" matSort #sortCol1="matSort">

                                        <ng-container matColumnDef="jrnl_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Journal Reference
                                                    #</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.jrnl_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ar_actvty_tcd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Journal Type</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.ar_actvty_lcl_num }} - {{ element.ar_actvty_tcd }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="cr_amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Credited Amount</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.CREDIT_AMOUNT }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="db_amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Debited Amount</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.DEBIT_AMOUNT }} </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="ld_jrnl_entry_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Desc</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.jrnl_desc }} </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="prep_id">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Preparer</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.prep_id }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="create_timestamp">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Post Timestamp</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.create_timestamp }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Status</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.ld_jrnl_lfcycl_st_cd }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <button (click)="view(element)" class="btn btn-info">View</button>&nbsp;
                                                <button class="btn btn-primary" (click)="approveJV(element)"
                                                    *ngIf="element.ld_jrnl_lfcycl_st_cd=='PROCESSED'">Approve</button>&nbsp;
                                                <button class="btn btn-danger" *ngIf="element.ld_jrnl_lfcycl_st_cd=='PROCESSED'"
                                                    (click)="reject(element)">Reject</button>&nbsp;
                                                <button *ngIf="element.ld_jrnl_lfcycl_st_cd=='APPROVED'" class="btn btn-outline-info"
                                                    (click)="post(element)">Post</button>&nbsp;
                                                <!--  <button (click)="show(element)" class="btn btn-success">Print</button> -->
                                                <!-- <button (click)="delete(element)" *ngIf="obj['status']=='UNPOSTED' || obj['status']=='APPROVED' || obj['status']=='REJECTED'" class="btn btn-danger">Delete</button> -->
                                                <!-- <button (click)="schedule(element)" *ngIf="obj['status']=='UNPOSTED'" class="btn btn-info">Schedule</button> -->
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="max-width: 80%; margin-left:10%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">JV Full Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Ref. Voucher :</h6>
                    </div>
                    <div class="col-3"> {{view_header['jrnl_id']}}</div>
                    <div class="col-3 text-right"> Ref. Voucher Date :</div>
                    <div class="col-3">{{view_header['ref_dt']}}</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Particulars :</h6>
                    </div>
                    <div class="col-3">{{view_header['jrnl_desc']}}</div>
                    <div class="col-3 text-right">
                        <h6>Jv Status :</h6>
                    </div>
                    <div class="col-3">{{view_header['status']}}</div>
                </div>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Chart Of Account</th>
                            <th>Particulars</th>
                            <th style="text-align: right;">Debit</th>
                            <th style="text-align: right;">Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of new_data">
                            <td>{{i['chart_acct']}}</td>
                            <td>{{i['ld_jrnl_ln_desc']}}</td>
                            <td style="text-align: right;">{{i['db'] | number : '1.2-2'}}</td>
                            <td style="text-align: right;">{{i['cr'] | number : '1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
