import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrackingLayoutComponent } from './tracking-layout.component';
import { RoleGuardService } from '../../role-guard.service';
import { TrackingDashboardComponent } from '../tracking-dashboard/tracking-dashboard.component';
import { TrackingAdministrationComponent } from '../tracking-administration/tracking-administration.component';
import { TrackingRoleComponent } from '../tracking-administration/tracking-role/tracking-role.component';
import { TrackingUserComponent } from '../tracking-administration/tracking-user/tracking-user.component';
import { RoleAuthorizationComponent } from '../tracking-administration/role-authorization/role-authorization.component';
import { TrackingWorkflowComponent } from '../tracking-administration/tracking-workflow/tracking-workflow.component';
import { TrackingSettingComponent } from '../tracking-setting/tracking-setting.component';
import { FieldComponent } from '../tracking-setting/field/field.component';
import { CodeValueComponent } from '../tracking-setting/code-value/code-value.component';
import { BoundryMapComponent } from '../tracking-setting/boundry-map/boundry-map.component';
import { HierachyComponent } from '../tracking-setting/hierachy/hierachy.component';
import { TrackingHelpComponent } from '../tracking-help/tracking-help.component';
import { EmpTrackingComponent } from '../emp-tracking/emp-tracking.component';
import { SelfEmpTrackingComponent } from '../self-emp-tracking/self-emp-tracking.component';


const routes: Routes = [
  {
    path: "tracking-index",
    component: TrackingDashboardComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R1' }
  },
  {
    path: "emp-tracking",
    component: EmpTrackingComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R6' }
  },
  {
    path: "self-emp-tracking",
    component: SelfEmpTrackingComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R15' }
  },
 
  {
    "path": "administration",
    "component": TrackingAdministrationComponent,
    "children": [

      {
        path: "roles",
        component: TrackingRoleComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R4' }
      },
      {
        path: "users",
        component: TrackingUserComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R4' }
      },

      {
        path: "role-authorization",
        component: RoleAuthorizationComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R4' }
      },

      {
        path: "workflow",
        component: TrackingWorkflowComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R4' }
      },

    ]
  },

  {

    "path": "tracking-setting",
    "component": TrackingSettingComponent,
    "children": [
      {
        path: "code-value",
        component: CodeValueComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R3' }
      },
      {
        path: "field",
        component: FieldComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R3' }
      },
    
      {
        path: "boundry-map",
        component: BoundryMapComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R3' }
      },
      {
        path: "hierachy",
        component: HierachyComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R3' }

      }
      
    ]

  },




  {
    "path": "help",
    "component": TrackingHelpComponent,
    "children": [
    //   {
    //     path: "faq",
    //     component: TRACKINGFaqComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R8' }
    //   },
    //   {
    //     path: "user-manual",
    //     component: TRACKINGUserManualComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R8' }
    //   },
    //   {
    //     path: "tutorial",
    //     component: TRACKINGTutorialComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R8' }
    //   },
    //   {
    //     path: "online-support",
    //     component: TRACKINGOnlineSupportComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'TRACKING-R8' }
    //   },
    ]
  }




];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TRACKINGLayoutRoutingModule { }
