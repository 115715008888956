<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Undertaking -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_under_flag" class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()" ><i
                                    class="ti-bar-chart" ></i>
                                <!-- Undertaking List -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_under_flag" class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i> <!-- Create Undertaking -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="u_under_flag" class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i class="ti-settings"></i>
                                <!-- Update Undertaking -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking4']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_under_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Undertaking</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Document Type : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking5']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="Doc_type" bindLabel="doc_type"
                                    (change)="changeList()"
                                         bindValue="doc_type" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking27']}}" [(ngModel)]="Obj['doc_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Role : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking6']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="allRoles" bindLabel="role_name"
                                         bindValue="role_cd" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking28']}}" [(ngModel)]="Obj['role_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" (change)="changeList()" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                         
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- S.No -->
                                            {{mainService.allLableShowObj[mainService.language_cd+'undertaking7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{i+1 }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="doc_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!--  Document Type -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element['doc_type']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="fields">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <!-- Field -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element['field_desc']}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking11']}}</button>
                                        </td>
                                    </ng-container>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="c_under_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()"> Undertaking List</button>
                            </div>
                            <br>

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Document Type : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking12']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="Doc_type" bindLabel="doc_type"
                                    (change)="Change_doc()"
                                         bindValue="doc_type" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking29']}}" [(ngModel)]="Obj['doc_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Role : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking13']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="allRoles" bindLabel="role_name"
                                         bindValue="role_cd" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking30']}}" [(ngModel)]="Obj['role_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row" *ngIf="Obj['doc_type']!='EMB'">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Field : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking14']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="bill_amt" bindLabel="value"
                                        bindValue="key" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking31']}}" [(ngModel)]="Obj['fields']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-info" (click)="addRow()"><i class="fa fa-plus " aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <table style="width: 100%;" class="table table-bordered">
                                <tr>
                                    <th class="r"><!-- S.No -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking15']}}</th>
                                    <th class="r"><!-- Description -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking16']}}</th>
                                    <th class="r"><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking17']}}</th>
                                </tr>
                                <tr *ngFor="let ob of data; let i = index">
                                
                                    <td class="r">
                                        <h6>{{i+1}}</h6>
                                    </td>
                                    <td class="r">
                                            <textarea type="text" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking32']}}" class="form-control" [(ngModel)]="ob['desc']"></textarea>
                                
                                    </td>
                                    <td class="r">
                                        <button class="btn btn-danger"  (click)="deleteRow(i)"><i class="fa fa-trash " aria-hidden="true"></i></button>
                                    </td>
                                </tr>

                            </table>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()"><!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking18']}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="u_under_flag" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()"> Undertaking List</button>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Document Type : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking19']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="Doc_type" bindLabel="doc_type" disabled
                                    (change)="Change_doc()"
                                         bindValue="doc_type" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking33']}}" [(ngModel)]="updateObj['doc_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Role : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking20']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="allRoles" bindLabel="role_name" disabled
                                         bindValue="role_cd" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking34']}}" [(ngModel)]="updateObj['role_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row"  *ngIf="Obj['doc_type']!='EMB'">
                                <div class="col-3"></div>
                                <div class="col-3"><h6><!-- Select Field : -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking21']}}</h6></div>
                                <div class="col-3">
                                    <ng-select [items]="bill_amt" bindLabel="value" 
                                        bindValue="key" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'undertaking35']}}" disabled [(ngModel)]="updateObj['fields']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-info" (click)="addRow()"><i class="fa fa-plus " aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <table style="width: 100%;" class="table table-bordered">
                                <tr>
                                    <th class="r"><!-- S.No -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking22']}}</th>
                                    <th class="r"><!-- Description -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking23']}}</th>
                                    <th class="r"><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking24']}}</th>
                                </tr>
                                <tr *ngFor="let ob of data; let i = index">
                                
                                    <td class="r">
                                        <h6>{{i+1}}</h6>
                                    </td>
                                    <td class="r">
                                            <textarea type="text" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'undertaking36']}}" class="form-control" [(ngModel)]="ob['desc']"></textarea>
                                
                                    </td>
                                    <td class="r">
                                        <button class="btn btn-danger" (click)="deleteRow(i)"><i class="fa fa-trash " aria-hidden="true"></i></button>
                                    </td>
                                </tr>

                            </table>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking25']}}</button>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom"><!-- Loading.... -->{{mainService.allLableShowObj[mainService.language_cd+'undertaking26']}}</ngx-spinner>
