<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">MANAGE Nominee / Partner </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click)="refressadd()" data-toggle="tab"><i class="ti-bar-chart"></i> All
                                Nominee / Partner List</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refressadd()"><i class="ti-settings"></i> Create
                                Nominee</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refressadd()"><i class="ti-announcement"></i> Update
                                Nominee</a>
                        </li> -->
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">



                            <div class=" table-container ">
                               <br>
                                <div class="row mt-2">
                                    <div class="col-2"></div>
                                    <div class="col-3 text-right">
                                      <h6 class="pt-2">Select Scheme / Offer :*</h6>
                                    </div>
                                    <div class="col-3 text-left">
                                      <ng-select [items]="allOffer" bindLabel="offer_cd" bindValue="offer_cd" [multiple]="false"
                                        [(ngModel)]="obj['offer_cd']" [closeOnSelect]="true" placeholder="Select Scheme / Offer"
                                        (change)="getRequestList()">
                                      </ng-select>
                                    </div>
                                  </div>
                                  <br>
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 text-center">

                                    </div>

                                </div>

                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                        <ng-container matColumnDef="request_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Request ID</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.request_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="offer_cd">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Offer Cd</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.offer_cd}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="party_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Party ID</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.party_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="party_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Party Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ partyObj[element.party_id] }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="property_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Property Type</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.property_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="applied_date">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Applied Date</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.applied_date }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Status</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.status }} </td>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="nominee_email">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>Nominee Email</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.nominee_email }} </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b>Action</b></th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button class="btn btn-outline-primary" (click)="openUpdate(element,i)">View Details</button>
                                                <button class="btn btn-outline-success" (click)="openUpdate(element,i)">Approve</button>
                                                <button class="btn btn-outline-danger" (click)="openUpdate(element,i)">Reject</button>
                                                <!-- <button class="btn btn-outline-primary" (click)="openUpdate(element,i)">Update</button> -->


                                            </td>

                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
