import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class LitigationService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }




  async getlist(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/litigation/getFirst' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getassignzone(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getassignzone' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  //shweta
  async getcausezonedata(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/causelist/getcausezonedata' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async getcause(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/causelist/getcause' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

 async getActName(obj:any){
  const resp = await this.http.post(this.httpUrl + '/litigation/getActVlue', obj).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
  }
    return

 }

async getVpname(obj2:any){

  const resp = await this.http.post(this.httpUrl + '/litigation/getVpName', obj2).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
  }
  return

}

  async getData(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getNoticeData',obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async insertnotice(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertnotice',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async upNotice(obj:any){
    const resp= await this.http.post<any>(this.httpUrl + '/litigation/upNotice',obj).toPromise().then(res=>{
      return res;
    })
    return resp;
  }

  async UpdateNotice(obj:any) {
    const resp=await this.http.post(this.httpUrl + '/litigation/updateNotice', obj).toPromise().then(res=> {
      return res
    });
    return resp;

  }
  async inserttotalnotice(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/inserttotalnoticeavi',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertpen(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertpen',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getNotice(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/getNoticeDetail',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async forWord(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/frdSend',obj).toPromise().then(res => {
      return res;
    });
    return resp;

   }
  async insertTotalnotice1(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertTotalnoticeaviuat',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

async selNot_h_f(obj:any) {
  const resp= await this.http.post<any>(this.httpUrl + '/litigation/check',obj).toPromise().then(res =>{
   return res;
  });
  return resp
}

// not in use
  async inserthearing(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/inserthearing',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertDefendant(obj:any) {
    console.log("this si service",obj);
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertdefendant',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async checkDefDetail(obj:any)
  {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/checkDefDtl',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // get data for the last hearing
 async uph(obj:any) {
  const resp = await this.http.post<any>(this.httpUrl + '/litigation/uPH',obj).toPromise().then(res => {
    return res;
  });
  return resp;
 }

  async getHearing(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getHearing',obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getorderuploaddata(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getorderuploaddata',obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }return
  }
  async getorderiddata(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getorderiddata',obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }return
  }

  async getnote_sheet(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getnote_sheet',obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }return
  }
  async  UpdateDefData(obj:any) {
    console.log("this is tss--------",obj)
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/updateDefDetail',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async submitReportSub(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/uploadReport', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async submitReportSub1(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/penalityuploadreport', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async submitcourtorder(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/submitcourtorder', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async insertcasenotesheet(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertcasenotesheet',obj).toPromise().then(res => {
      return res;
    });
    return resp;
}

async insertDigital(obj:any) {
  const resp = await this.http.post<any>(this.httpUrl + '/litigation/insertDigitalsign',obj).toPromise().then(res => {
    return res;
  });
  return resp;
  }



async updatecasestatus(obj:any) {
  const resp = await this.http.post<any>(this.httpUrl + '/litigation/updatecasestatus',obj).toPromise().then(res => {
    return res;
  });
  return resp;
  }


async  getview_document(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getview_document', obj, { responseType: 'blob' }).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}


async  getalldocument(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getviewdocumentfiles', obj, { responseType: 'blob' }).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}

async  getNoticeDeliveredDocs(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getnoticedelivereddocs', obj, { responseType: 'blob' }).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}

async  getndreport(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getndreport', obj).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}

async getempinfo(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getempinfo', obj).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}

async  getNoticeDeliveredDocstype(obj:any) {
  const resp = await this.http.post(this.httpUrl+'/litigation/getNoticeDeliveredDocstype', obj).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
}return
}


async getalldoc(obj:any) {
  const resp = await this.http.post(this.httpUrl + '/litigation/getdocumentfiles' , obj).toPromise().then(res => {
    return res;
  });
  if (resp) {
    return resp;
  }return
}


  async getUploadedFiles(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/getUploadedFiles', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getallcasedetails(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/getallcasedetails', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getUploadedFileData(obj:any) {
    const resp = await this.http.post(this.httpUrl+'/litigation/getUploadedFileData', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
  }return
  }
  async  getUPLOADEDSIGNATUREDATA(obj:any) {
    const resp = await this.http.post(this.httpUrl+'/litigation/getUPLOADEDSIGNATUREDATA', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
  }return
  }
  async getUploadDocument(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/litigation/getUpoadDocument' , obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }return
  }


  async  getUploadedDocumentData(obj:any) {
    const resp = await this.http.post(this.httpUrl+'/litigation/getUploadedDocumentData', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
  }return
  }

 async getDefDetail(obj:any) {
  console.log("this si the service", obj);
  const resp= await this.http.post(this.httpUrl + '/litigation/fingDefDetail',obj).toPromise().then( res =>{
    return res;
  })
   return resp;
}




    async  getCauseList(obj:any) {
      const resp = await this.http.post(this.httpUrl+'/causelist/getCauseList', obj).toPromise().then(res => {
        return res;
      });
      if (resp) {
        return resp;
    }return
    }
    async  getZone(obj:any) {
      const resp = await this.http.post<any>(this.httpUrl + '/litigation/getzone',obj).toPromise().then(res =>{
        return res;
      });
      return resp;
  }
  async  getid(obj1:any){
    const resp = await this.http.post<any>(this.httpUrl + '/litigation/getid',obj1).toPromise().then(res =>{
      return res;
    });
    return resp;
}
    async updateevidancesList(obj:any) {
      const res = await this.http.put<any>(this.httpUrl + '/litigation/updateevidancesList', obj).toPromise().then(res => {
        return res;
      });
      return res;
    }

    async updateorderid(obj2:any) {
      const res = await this.http.put<any>(this.httpUrl + '/litigation/updateorderid', obj2).toPromise().then(res => {
        return res;
      });
      return res;
    }

    /*********Deepankar Workflow Service start */
    async forwardInsNotice(obj:any) {
      const res = await this.http.post<any>(this.httpUrl + '/litigation/forwardInsNotice', obj).toPromise().then(res => {
        return res;
      });
      return res;
    }
    async getNoticeWorkflow(obj:any) {
      const res = await this.http.post<any>(this.httpUrl + '/litigation/getNoticeWorkflow', obj).toPromise().then(res => {
        return res;
      });
      return res;
    }
    async createCase(obj:any) {
      const res = await this.http.post<any>(this.httpUrl + '/litigation/createCase', obj).toPromise().then(res => {
        return res;
      });
      return res;
    }
    async getzonecd(obj:any) {
      const res = await this.http.get<any>(this.httpUrl + '/litigation/getzonecd'+ obj).toPromise().then(res => {
        return res;
      });
      return res;
    }
    async getsection(obj:any) {
      const res = await this.http.get<any>(this.httpUrl + '/litigation/getsection'+ obj).toPromise().then(res => {
        return res;
      });
      return res;
    }

 async aviSection(obj:any) {
  const res = await this.http.post(this.httpUrl  + '/litigation/aviCheckSection', obj).toPromise().then(res => {
    return res;
  });
  return res;;
 }

  async insertAssi(obj:any)
  {
    const res = await this.http.post(this.httpUrl  + '/litigation/insertArrangement', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

    /*********end */



}
