
import { Component, OnInit } from '@angular/core';
import {MainService} from '../../service/main.service';
import {ProfileService} from '../../service/profile.service';
import {Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import {AuthenticationService} from '../../../authentication.service'

@Component({
  selector: '[app-tracking-sidebar]',
  templateUrl: './tracking-sidebar.component.html',
  styleUrls: ['./tracking-sidebar.component.css']
})
export class TrackingSidebarComponent implements OnInit{

  user_id:any;
  imgURL:any;
  erpUser:any;
  accInfo:any;
  hidden:any = true;
  assigned_compoment:any = []
  sidebar:any = new Object();
  //allLableShowObjHR={}
  userInfo={emp_name:'Ram Prasad',designation:'Accountant'}




  constructor(public auth:AuthenticationService, private router:Router,private spinner:NgxSpinnerService,private profileService: ProfileService,private sanitizer: DomSanitizer, public mainService: MainService) {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.user_id = this.erpUser.user_id;
    this.assigned_compoment = this.erpUser.assigned_component;

    this.sidebar['dashbord'] = !this.assigned_compoment.includes('TRACKING-R1')
    //Complaints Start
    this.sidebar['administration'] = !this.assigned_compoment.includes('TRACKING-R2')
    this.sidebar['master_data'] = !this.assigned_compoment.includes('TRACKING-R3')
    this.sidebar['security'] = !this.assigned_compoment.includes('TRACKING-R4')
    this.sidebar['help'] = !this.assigned_compoment.includes('TRACKING-R5')
    this.sidebar['emp_tracting'] = !this.assigned_compoment.includes('TRACKING-R6')
    this.sidebar['self_tracting'] = !this.assigned_compoment.includes('TRACKING-R15')


    this.sidebar['role'] = !this.assigned_compoment.includes('TRACKING-R7')
    this.sidebar['user'] = !this.assigned_compoment.includes('TRACKING-R8')
    this.sidebar['data_assignment'] = !this.assigned_compoment.includes('TRACKING-R9')
    this.sidebar['workflow'] = !this.assigned_compoment.includes('TRACKING-R10')
    this.sidebar['field'] = !this.assigned_compoment.includes('TRACKING-R11')
    this.sidebar['code_value'] = !this.assigned_compoment.includes('TRACKING-R12')
    this.sidebar['boundry_map'] = !this.assigned_compoment.includes('TRACKING-R13')
    this.sidebar['hierarchy'] = !this.assigned_compoment.includes('TRACKING-R14')





  }

  async ngOnInit() {
    // this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')));
    this.user_id = this.erpUser.user_id;
    if(this.erpUser.is_admin==0){
      this.hidden=true;
    }else{
      this.hidden=false
    }
    this.imgURL = './assets/img/admin-avatar.png';
    await this.getUserImage();
    // await this.getUserInfo();
    await this.getAccImage();
    // await this.getAccInfo();
  }

  home() {
    this.router.navigate(['/index'])
  }





  async getUserImage(){
    const res = await this.profileService.getImage(this.user_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.profileImageUrl=this.imgURL;

    }
  }
  async getUserInfo(){
    this.spinner.show()
    var resp= await this.profileService.getUserProfileInfo(this.user_id);
    if(resp['error']==false){
      this.spinner.hide()

      this.userInfo=resp.data[0];
    }else{

      this.spinner.hide()
    }
  }
  async getAccImage() {
    const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.accountImageUrl = this.imgURL;

    }
  }
  async getAccInfo() {
    this.spinner.show()
    var resp = await this.profileService.getAccProfileInfo(this.erpUser.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.accInfo = resp.data[0];
      this.mainService.accInfo = this.accInfo;
    } else {
      this.spinner.hide()

    }

  }

  refreshCol() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }

  }

}


