<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Ledger Report</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i> All
                                Assets Ledger </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Liabilities Ledger</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-bar-chart"></i>
                                All Equity</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Income Ledger</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-5" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Expense Ledger </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade  show active" id="tab-1">
                            <br>
                            <form name="form1" (ngSubmit)="f.form.valid && show3()" #f="ngForm">

                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Chart Of Account :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="assets" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false" placeholder="Select Chart Of Account"
                                            [(ngModel)]="obj_ass['c_acc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="chart_of_acc"
                                            #chart_of_acc="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && chart_of_acc.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="chart_of_acc.dirty && chart_of_acc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && chart_of_acc.invalid" class="error-message">
                                            <div *ngIf="chart_of_acc.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Party :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Party" [(ngModel)]="obj_ass['le_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="party" #party="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && party.invalid }" required>
                                        </ng-select>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="party.dirty && party.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && party.invalid" class="error-message">
                                            <div *ngIf="party.errors?.['required']">Please Select Party
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>

                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_ass['from_date']"
                                            name="from_dt" #from_dt="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && from_dt.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="from_dt.dirty && from_dt.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && from_dt.invalid" class="error-message">
                                            <div *ngIf="from_dt.errors?.['required']">Please Fill From Date </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div><br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-4">

                                        <input type="date" class="form-control" [(ngModel)]="obj_ass['to_date']"
                                            name="to_dt" #to_dt="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && to_dt.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="to_dt.dirty && to_dt.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && to_dt.invalid" class="error-message">
                                            <div *ngIf="to_dt.errors?.['required']">Please Fill To Date </div>
                                        </div>

                                        <!-- {{from_dt.value + " AND "+" TO Date "+ to_dt.value}} -->
                                        <div *ngIf="from_dt.value > to_dt.value" class="error-message">
                                            Please Select Correct Date
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div><br>

                                <br>

                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="show3()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>
                                        <button class="btn btn-success d-none" id="tab1" type="reset">Reset</button>
                                    </div>
                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class="text-right">
                                <button class="btn btn-primary" (click)="print3()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export3()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div id="a">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        Assets ({{obj_ass['acc_desc']}})
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj_ass['from_date']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj_ass['to_date']}}</div>
                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res3">
                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db'] | number: '1.2-2'}}</td>
                                            <td style="text-align:right">{{item['cr'] | number: '1.2-2'}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <form name="form" (ngSubmit)="form2.form.valid && show2()" #form2="ngForm">


                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Chart Of Account :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="liability" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false" placeholder="Select Chart Of Account"
                                            [(ngModel)]="obj_lia['c_acc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="ch_acc"
                                            #ch_acc="ngModel"
                                            [ngClass]="{'is-invalid':form2.submitted && ch_acc.invalid}" required>
                                        </ng-select>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="ch_acc.dirty && ch_acc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="form2.submitted && ch_acc.invalid" class="error-message">
                                            <div *ngIf="ch_acc.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->


                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Party :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Party" [(ngModel)]="obj_lia['le_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="party2" #party2="ngModel"
                                            [ngClass]="{ 'is-invalid': form2.submitted && party2.invalid }" required>
                                        </ng-select>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="party2.dirty && party2.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="form2.submitted && party2.invalid" class="error-message">
                                            <div *ngIf="party2.errors?.['required']">Please Select Party
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_lia['from_date']"
                                            name="from_dt2" #from_dt2="ngModel"
                                            [ngClass]="{ 'is-invalid': form2.submitted && from_dt2.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="from_dt2.dirty && from_dt2.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="form2.submitted && from_dt2.invalid" class="error-message">
                                            <div *ngIf="from_dt2.errors?.['required']">Please Fill From Date </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->



                                    </div>
                                </div><br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_lia['to_date']"
                                            name="to_dt2" #to_dt2="ngModel"
                                            [ngClass]="{ 'is-invalid': form2.submitted && to_dt2.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="to_dt2.dirty && to_dt2.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="form2.submitted && to_dt2.invalid" class="error-message">
                                            <div *ngIf="to_dt2.errors?.['required']">Please Fill To Date </div>
                                        </div>


                                        <div *ngIf="from_dt2.value > to_dt2.value" class="error-message">
                                            Please Select Correct Date
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="show2()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>
                                        <button class="btn btn-success d-none" id="tab2" type="reset">Reset</button>
                                    </div>

                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class=" text-right">
                                <button class="btn btn-primary" (click)="print2()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export2()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div id="l">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        Liabilities ({{obj_lia['acc_desc']}})
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj_lia['from_date']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj_lia['to_date']}}</div>
                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res2">
                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db'] | number: '1.2-2'}}</td>
                                            <td style="text-align:right">{{item['cr'] | number: '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">

                            <br>
                            <form name="form3" (ngSubmit)="f3.form.valid && show()" #f3="ngForm">

                                <div class="row" style="margin-left: 2%;margin-right: 2;">

                                    <div class="col-4 text-left">
                                        <h6>Select Chart Of Account :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="equity" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false" placeholder="Select Chart Of Account"
                                            [(ngModel)]="obj['c_acc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="chart_of_acc3"
                                            #chart_of_acc3="ngModel"
                                            [ngClass]="{ 'is-invalid': f3.submitted && chart_of_acc3.invalid }"
                                            required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="chart_of_acc3.dirty && chart_of_acc3.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && chart_of_acc3.invalid" class="error-message">
                                            <div *ngIf="chart_of_acc3.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Party :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Party" [(ngModel)]="obj['le_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="party31" #party31="ngModel"
                                            [ngClass]="{ 'is-invalid': f3.submitted && party31.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="party31.dirty && party31.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && party31.invalid" class="error-message">
                                            <div *ngIf="party31.errors?.['required']">Please Select Party
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj['from_date']"
                                            name="from_dt3" #from_dt3="ngModel"
                                            [ngClass]="{ 'is-invalid': f3.submitted && from_dt3.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="from_dt3.dirty && from_dt3.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && from_dt3.invalid" class="error-message">
                                            <div *ngIf="from_dt3.errors?.['required']">Please Fill From Date </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div><br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj['to_date']"
                                            name="to_dt3" #to_dt3="ngModel"
                                            [ngClass]="{ 'is-invalid': f3.submitted && to_dt3.invalid }" required>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="to_dt3.dirty && to_dt3.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f3.submitted && to_dt3.invalid" class="error-message">
                                            <div *ngIf="to_dt3.errors?.['required']">Please Fill To Date </div>
                                        </div>

                                        <div *ngIf="from_dt3.value > to_dt3.value" class="error-message">
                                            Please Select Correct Date
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="show()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>
                                        <button class="btn btn-success d-none" id="tab3" type="reset">Reset</button>
                                    </div>

                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class=" text-right">
                                <button class="btn btn-primary" (click)="print1()">Print</button> &nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export1()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div id="e">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        Equity ({{obj['acc_desc']}})
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj['from_date']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-3 text-right">{{obj['to_date']}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2 text-center">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-2">{{obj['to_date']}}</div>

                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res">

                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db'] | number: '1.2-2'}}</td>
                                            <td style="text-align:right">{{item['cr'] | number: '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <form name="form4" (ngSubmit)="f4.form.valid && show5()" #f4="ngForm">

                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <!-- <div class="col-3 text-left">
                                    <h6>Select :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="first_dropdown" bindLabel="lvl1_value" bindValue="lvl1_code" [multiple]="false" placeholder="Select.." [(ngModel)]="obj_rev['balcnce']" (change)="change_c_acc5()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="lvl1_code">
                                    </ng-select>
                                </div> -->
                                    <div class="col-4 text-left">
                                        <h6>Select Chart Of Account :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="income" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false" placeholder="Select Chart Of Account"
                                            [(ngModel)]="obj_rev['c_acc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="chart_of_acc4"
                                            #chart_of_acc4="ngModel"
                                            [ngClass]="{ 'is-invalid': f4.submitted && chart_of_acc4.invalid }"
                                            required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="chart_of_acc4.dirty && chart_of_acc4.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f4.submitted && chart_of_acc4.invalid" class="error-message">
                                            <div *ngIf="chart_of_acc4.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Party :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Party" [(ngModel)]="obj_rev['le_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="party4" #party4="ngModel"
                                            [ngClass]="{ 'is-invalid': f4.submitted && party4.invalid }" required>
                                        </ng-select>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="party4.dirty && party4.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f4.submitted && party4.invalid" class="error-message">
                                            <div *ngIf="party4.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>

                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_rev['from_date']"
                                            name="from_dt4" #from_dt4="ngModel"
                                            [ngClass]="{ 'is-invalid': f4.submitted && from_dt4.invalid }" required>
                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="from_dt4.dirty && from_dt4.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f4.submitted && from_dt4.invalid" class="error-message">
                                            <div *ngIf="from_dt4.errors?.['required']">Please Fill From Date </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div><br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_rev['to_date']"
                                            name="to_dt4" #to_dt4="ngModel"
                                            [ngClass]="{ 'is-invalid': f4.submitted && to_dt4.invalid }" required>



                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="to_dt4.dirty && to_dt4.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f4.submitted && to_dt4.invalid" class="error-message">
                                            <div *ngIf="to_dt4.errors?.['required']">Please Fill To Date </div>
                                        </div>


                                        <div class="error-message">
                                            <div *ngIf="from_dt4.value > to_dt4.value">Please Select Correct Date
                                            </div>
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="show5()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>
                                        <button class="btn btn-success d-none" id="tab4" type="reset">Reset</button>
                                    </div>

                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class="text-right">
                                <button class="btn btn-primary" (click)="print5()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export5()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        Income ({{obj_rev['acc_desc']}})
                                    </div>
                                </div><br>
                                <div class="row">
                                    <!-- <div class="col-2 text-right"> -->
                                    <!-- <h6>Financial Year :</h6> -->
                                    <!-- </div> -->
                                    <!-- <div class="col-2">{{fin_obj[obj_rev['fin_year']] }} -->
                                    <!-- </div> -->
                                    <div class="col-3 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-3 text-center ">{{obj_rev['from_date']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-3 center">{{obj_rev['to_date']}}</div>

                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res5">

                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db'] | number: '1.2-2'}}</td>
                                            <td style="text-align:right">{{item['cr'] | number: '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-5">
                            <br>
                            <form name="form4" (ngSubmit)="f5.form.valid && show4()" #f5="ngForm">

                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Chart Of Account :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="expense" bindLabel="leaf_value" bindValue="leaf_code"
                                            [multiple]="false" placeholder="Select Chart Of Account"
                                            [(ngModel)]="obj_exp['c_acc']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="chart_of_acc5"
                                            #chart_of_acc5="ngModel"
                                            [ngClass]="{ 'is-invalid': f5.submitted && chart_of_acc5.invalid }"
                                            required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="chart_of_acc5.dirty && chart_of_acc5.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f5.submitted && chart_of_acc5.invalid" class="error-message">
                                            <div *ngIf="chart_of_acc5.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>Select Party :</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="le_id"
                                            [multiple]="false" placeholder="Select Party" [(ngModel)]="obj_exp['le_id']"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="party5" #party5="ngModel"
                                            [ngClass]="{ 'is-invalid': f5.submitted && party5.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="party5.dirty && party5.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f5.submitted && party5.invalid" class="error-message">
                                            <div *ngIf="party5.errors?.['required']">Please Select Chart Of Account
                                            </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>

                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_exp['from_date']"
                                            name="from_dt5" #from_dt5="ngModel"
                                            [ngClass]="{ 'is-invalid': f5.submitted && from_dt5.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="from_dt5.dirty && from_dt5.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f5.submitted && from_dt5.invalid" class="error-message">
                                            <div *ngIf="from_dt5.errors?.['required']">Please Fill From Date </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div><br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-4 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="obj_exp['to_date']"
                                            name="to_dt5" #to_dt5="ngModel"
                                            [ngClass]="{ 'is-invalid': f5.submitted && to_dt5.invalid }" required>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.border_green]="to_dt5.dirty && to_dt5.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f5.submitted && to_dt5.invalid" class="error-message">
                                            <div *ngIf="to_dt5.errors?.['required']">Please Fill To Date </div>
                                        </div>


                                        <div class="error-message">
                                            <div *ngIf="from_dt5.value > to_dt5.value">Please Select Correct Date</div>
                                        </div>
                                        <!--------------------------------------------- validation End ------------------- -->
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-primary" (click)="show4()">Submit</button> -->
                                        <button class="btn btn-primary" type="submit">Submit</button>
                                        <button class="btn btn-success d-none" id="tab5" type="reset">Reset</button>
                                    </div>
                                </div>
                            </form>
                            <br>
                            <hr>
                            <div class="text-right">
                                <button class="btn btn-primary" (click)="print4()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export4()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>{{acount_name}}</h6>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        Expence ({{obj_exp['acc_desc']}})
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-3 text-left">
                                        <h6>From :</h6>
                                    </div>
                                    <div class="col-3 text-center">{{obj_exp['from_date']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>To :</h6>
                                    </div>
                                    <div class="col-3 center">{{obj_exp['to_date']}}</div>

                                </div>
                                <br>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res4">
                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db'] | number: '1.2-2'}}</td>
                                            <td style="text-align:right">{{item['cr'] | number: '1.2-2'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
