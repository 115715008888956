import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AllotmentService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl =  this.main.httpUrl+ "/property/allotment";
  }
  async getAllAllotment(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getAllAllotment' + obj).toPromise().then(res => {

      return res
    });
    return resp
  }
  async getAllInstallment(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getInstallment' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async approveAllotment(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/approveAllotment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async createAllotment(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/createAllotment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // /sendEventForTestingProperty
  async sendEventForTestingProperty(obj:any){
    const resp = await this.http.post<any>( this.main.httpUrl + '/admin/paymentGateway/sentEventForEMI', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async updateAllotment(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/updateAllotment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getDataForAllotment(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getDataForAllotment' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async cancelAllotment(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/cancelAllotment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async partyEmi(obj:any){
    const resp = await  this.http.post<any>(this.main.httpUrl + "/property/partyemi/generateEmi",obj).toPromise().then(
      res=>{
        return res;
      }
    );
    return resp;
  }

  // this.main.httpUrl + "/interface/property/allotment
  // for allotment letter
  async getallallotment(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + "/interface/property/allotment/getallotment" + obj).toPromise().then(res => {
      //console.log(res)
      return res
    });
    return resp
  }
  async getallotmentInfo(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + "/interface/property/allotment/allotmentInfo" + obj).toPromise().then(res => {
      //console.log(res)
      return res
    });
    return resp
  }
  async propertyFeature(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + "/interface/property/allotment/propertyFeature", obj).toPromise().then(res => {
      //console.log(res)
      return res
    });
    return resp
  }
  async offer_costing(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + "/property/offers/getCostingByOffer" + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
