<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Section Manage</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="refresh()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create Section
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-7 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Map Section</button>
                                </div>


                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            id

                                        </th>
                                        <!--ID-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{i+1}}</td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="section_map_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Id

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.section_map_id }} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="notice_act_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Notice Act


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.notice_act_cd }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="section_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Section


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.section_cd }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="delete(element)"
                                                class="btn btn-outline-danger">Delete</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="create_flag">
                            <div class="card">
                                <div style="margin: 5%;">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Open
                                                List</button>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;">Select Notice Act : <span style="color: red;">
                                                    *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="codeValueTechObj['ICMS003']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select Notice Act"
                                                [(ngModel)]="Obj['notice_act_cd']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select notice act -->
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;"> Select Section : <span
                                                    style="color: red;">*</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="codeValueTechObj['ICMS004']" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder="Select Section "
                                                [(ngModel)]="Obj['section_cd']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <!--Select section -->
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>


                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>Loading....</ngx-spinner>
