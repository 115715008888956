import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class ClosingStockService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl
  }
  async getAllClosingStock(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/account/closingStock/getAllClosingStock' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


   async getClosingStockByID(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/account/closingStock/getClosingStockByID' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async createClosingStock(obj:any){
    const resp = this.http.post<any>(this.httpUrl + '/account/closingStock/createClosingStock' ,obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async updateClosingStock(obj:any){
    const resp = this.http.put<any>(this.httpUrl + '/account/closingStock/updateClosingStock', obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }


  async deleteClosingStock(obj:any){
    const resp = this.http.delete<any>(this.httpUrl + '/account/closingStock/deleteClosingStock' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }


  async changeCloasingStockStatus(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/account/closingStock/changeCloasingStockStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
