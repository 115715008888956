import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MainService } from '../service/main.service';
import { RoleService } from '../service/role.service';
//oc
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//ec

declare var $: any;
@Component({
  selector: 'app-md-create-role',
  templateUrl: './md-create-role.component.html',
  styleUrls: ['./md-create-role.component.css']
})
export class MdCreateRoleComponent implements OnInit {

  displayedColumns = ['role_cd', 'role_name', 'role_desc', 'role_type', 'res', 'action'];
  type_data = []
  party = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  b_acct_id = 0;
  erpUser:any;
  le_type = []
  allCodeValue = [];
  checkbox:any;
  checkBoxValue: any = false;
  constructor(private roleService: RoleService, private spinner: NgxSpinnerService, public toastr: ToastrService, public mainService: MainService) { }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  role_type = [{ code: 'Account Role', value: 'Administration Role' }, { code: 'Module  Admin', value: 'Module Role' }]
  data = [{ id: 1, acct_short_name: 'AC', acct_full_name: 'Account', name: "suraj kumar", email: "suraj@infiniseasoft.com", phone_no: "9984904063" }]
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id
    await this.getAllModules()
    await this.getCurrentResource()
    await this.getcurrentroleswithresources()
    this.Obj['check'] = true
  }
  check_flag = false
  resource2:any = []
  set_flag() {
    this.resource2 = []
    console.log(this.all_modules)
    this.check_flag = false
    this.Obj['check'] = true
    this.Obj['res'] = null
    this.checkBoxValue = false
    console.log(this.Obj)
    if (this.Obj['role_type'] == 'Account Role') {
      this.Obj['module_cd'] = 'AA'
      this.check_flag = true
      this.resource2 = this.resource
    } else if (this.Obj['role_type'] == 'Module  Admin') {
      this.Obj['module_cd'] = null
      this.check_flag = true
    }
  }
  set_res() {
    this.resource2 = []
    console.log(this.Obj)
    let dummy:any = []
    for (let i = 0; i < this.resource.length; i++) {
      if (this.Obj['module_cd'] == this.resource[i]['module_cd']) {
        dummy.push(this.resource[i])
      }
    }
    this.resource2 = dummy
    this.Obj['res'] = null
  }
  show() {
    console.log(this.Obj)
  }
  checkCheckBoxvalue() {
    let dummy:any = []
    this.Obj['res'] = null
    if (this.checkBoxValue == false) {
      for (let i = 0; i < this.resource2.length; i++) {
        dummy.push(this.resource2[i]['resource_cd'])
      }
      this.Obj['res'] = dummy
    } else {
      this.Obj['res'] = null
    }
    console.log(this.checkBoxValue, this.Obj)
  }

  async getcurrentroleswithresources() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'AA'
    console.log(obj)
    var resp = await this.roleService.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp, 'getcurrentroleswithresources:dtls');
      let dummy = []
      for (let i = 0; i < resp['data'].length; i++) {
        // if (resp['data'][i]['role_type'] == 'Account Role' || resp['data'][i]['role_type'] == 'Module  Admin' || resp['data'][i]['role_type'] == 'Account Admin') {
        dummy.push(resp['data'][i])
        // }
      }
      this.datasource = new MatTableDataSource(dummy);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }

  all_modules = []
  async getAllModules() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.roleService.AllModules(JSON.stringify(obj));
    console.log(resp, 'modules');
    if (resp['error'] == false) {
      this.spinner.hide()
      this.all_modules = resp['data']
      console.log(resp, 'all modules');
      // this.datasource = new MatTableDataSource(resp.data);
      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting modules", 'error');
    }
  }
  resource = []
  async getCurrentResource() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.roleService.getMdResource(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      let dummy = []
      for (let i = 0; i < resp['data'].length; i++) {
        // if (resp['data'][i]['module_cd'] == 'MD') {
        resp['data'][i]['desc'] = resp['data'][i]['module_cd'] + " - " + resp['data'][i]['resource_desc']
        dummy.push(resp['data'][i])
        // }
      }
      // this.resource = dummy
      this.resource2 = dummy
      console.log(this.resource, 'rrrrrrrrrrrrrrrrrr')
    } else {
      Swal.fire("Error", "..Error while getting  resource", 'error');
    }
  }


  viewObj = {}
  viewDetails(element:any) {
    this.viewObj = Object.assign({}, element)
    console.log(element)
    $('#B').modal('show');
  }

  changeparty() {
    for (let i = 0; i < this.party.length; i++) {
      if (this.party[i]['party_id'] == this.Obj['party_id']) {
        this.Obj['party_leagal_name'] = this.party[i]['party_leagal_name']
      }
    }
  }

  async refresh() {
    // await this.getCurrentRole()
    this.Obj = {}
  }

  back() {
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    this.Obj = {};
  }
  async delete(element:any) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any) {
    let obj:any = {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.roleService.deleteRole(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getcurrentroleswithresources()
      Swal.fire('Success', resp['data'], 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }
  async open_update(element:any) {
    console.log(element)
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    // await this.set_flag()
    if (element['resource_cd']) {
      this.Obj['res'] = element['resource_cd'].split(',')
    }
    // if (this.Obj['role_type'] == 'Account Role') {
    //   this.resource2 = this.resource
    // } else {
    //   let dummy = []
    //   for (let i = 0; i < this.resource.length; i++) {
    //     if (this.Obj['module_cd'] == this.resource[i]['module_cd']) {
    //       dummy.push(this.resource[i])
    //     }
    //   }
    //   this.resource2 = dummy
    // }
    // this.resource2=this.resource
    if (this.Obj['res'].length == this.resource2.length) {
      this.check_flag = true
    }
    console.log(this.Obj['res'].length, this.resource2.length)
    console.log(this.Obj, this.resource2, this.resource)
    // this.check_flag=true
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');
    this.spinner.hide();
  }

  async update() {
    // this.Obj['role_type'] = "Account Role"
    // this.Obj['module_cd'] = 'AA'
    if (!this.Obj['role_cd']) {
      Swal.fire("Error", 'Please Enter Role Code', 'error')
    } else if (!this.Obj['role_name']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    } else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    } else if (!this.Obj['role_type']) {
      Swal.fire('Error', 'Please Select Role Type', 'error')
    }
    else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    }
    else {
      let dummy = []
      for (let i = 0; i < this.resource2.length; i++) {
        for (let j = 0; j < this.Obj['res'].length; j++) {
          if (this.Obj['res'][j] == this.resource2[i]['resource_cd']) {
            dummy.push(this.resource2[i])
          }
        }
      }
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'GENERATED'
      this.Obj['user_id'] = this.erpUser.le_id
      this.Obj['data'] = dummy
      console.log(this.Obj)
      this.spinner.show()
      var resp = await this.roleService.updateRole(this.Obj);
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(() => {
          this.spinner.hide()
          Swal.fire('Success...', 'Updated Successfully', 'success')
          this.getcurrentroleswithresources()
        }, 500);
      } else {
        this.spinner.hide()
        // Swal.fire('Error', 'Error While Creating', 'error')
        if (resp['data'] == "Duplicate entry") {
          Swal.fire('Error...', 'Duplicate Entry', 'error')
        } else {
          Swal.fire('Error', 'Error While Creating', 'error')
        }
      }
    }
  }

  async submit() {
    this.Obj['role_type'] = "Account Role"
    this.Obj['module_cd'] = 'AA'
    if (!this.Obj['role_cd']) {
      Swal.fire("Error", 'Please Enter Role Code', 'error')
    } else if (!this.Obj['role_name']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    } else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    } else if (!this.Obj['role_type']) {
      Swal.fire('Error', 'Please Select Role Type', 'error')
    }
    else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    }
    else {
      let dummy = []
      for (let i = 0; i < this.resource2.length; i++) {
        for (let j = 0; j < this.Obj['res'].length; j++) {
          if (this.Obj['res'][j] == this.resource2[i]['resource_cd']) {
            dummy.push(this.resource2[i])
          }
        }
      }
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'GENERATED'
      this.Obj['user_id'] = this.erpUser.le_id
      this.Obj['data'] = dummy
      console.log(this.Obj)
      this.spinner.show()
      var resp = await this.roleService.createMdRole(this.Obj);
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(() => {
          this.spinner.hide()
          Swal.fire('Success...', 'Created Successfully', 'success')
          this.getcurrentroleswithresources()
        }, 500);
      } else {
        this.spinner.hide()
        // Swal.fire('Error', 'Error While Creating', 'error')
        if (resp['data'] == "Duplicate entry") {
          Swal.fire('Error...', 'Duplicate Entry', 'error')
        } else {
          Swal.fire('Error', 'Error While Creating', 'error')
        }
      }
    }
  }


  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      return true
    }
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
