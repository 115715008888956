import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { VmsHierService } from "./vms-hier.service";
import { MasterDataService } from "../service/master-data.service";
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService, private vmsHierService: VmsHierService,
    private masterDataService: MasterDataService, private spinner: NgxSpinnerService) {
    this.httpUrl = this.main.httpUrl;
  }



  WorkOrderNumberToWOrkIDObj:any = {};
  async getWorkOrderTOWorkIdInfo(b_acct_id:any) {
    this.spinner.show()
    var obj:any = new Object();
    obj["b_acct_id"] = b_acct_id;
    this.WorkOrderNumberToWOrkIDObj = {};
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    // console.log(resp, "getWorkInfo");
    // console.log(resp);
    if (resp["error"] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        this.WorkOrderNumberToWOrkIDObj["VMSWORK" + resp.data[i]["id"]] =
          resp.data[i]["id"];
      }
      // this.spinner.hide()
    }else{
      this.spinner.hide()
    }
    // console.log(this.WorkOrderNumberToWOrkIDObj);
  }


  assignedWork :any= {};
  async getAssignedWorkInfo(b_acct_id:any, user_id:any) {
    this.spinner.show()
    var obj :any= new Object();
    obj["b_acct_id"] = b_acct_id;
    obj["user_id"] = user_id;
    var resp = await this.vmsHierService.getAllVmsdataAssigned(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.assignedWork = {};
      let work = await this.vmsHierService.getprojectHierarchy(obj);
      let node = work["data"];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp["data"][i]["user_id"] == user_id) {
          let level = 0;
          for (let j = 0; j < node.length; j++) {
            if (node[j]["leaf_cd"] == resp.data[i]["node_cd"]) {
              for (let k = 1; k < 8; k++) {
                if (node[j]["lvl" + k + "_cd"] == resp.data[i]["node_cd"]) {
                  level = k;
                }
              }
            }
          }
          for (let j = 0; j < node.length; j++) {
            if (node[j]["is_leaf"] == 1 && node[j]["lvl" + level + "_cd"] == resp.data[i]["node_cd"]) {
              if (this.WorkOrderNumberToWOrkIDObj[node[j]["leaf_user_cd"]] != undefined) {
                this.assignedWork[this.WorkOrderNumberToWOrkIDObj[node[j]["leaf_user_cd"]]] = this.WorkOrderNumberToWOrkIDObj[node[j]["leaf_user_cd"]];
              }
            }
          }
        }
        // this.spinner.hide()
      }
    }else{
      this.spinner.hide()
    }
    // console.log(this.assignedWork);
  }
}
