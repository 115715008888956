//import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AllEmpService } from '../../hrms/service/all-emp.service';
import { DataAccessService } from '../service/data-access.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EstablishmentService } from '../service/establishment.service';
import { FileUploader } from 'ng2-file-upload';
import { JoiningService } from '../service/joining.service';
import { RoleService as MD_RoleService } from '../../md/service/role.service';
import { MainService as MD_mainService } from '../../md/service/main.service';
import { MainService } from '../service/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { PayrollService } from '../service/payroll.service';
import { ProfileService } from '../../portal/service/profile.service';
import { Router } from '@angular/router';
import { MainService as SA_mainService } from '../../admin/service/main.service';
import { ScriptLoaderService } from '../../_services/script-loader.service';
import Swal from 'sweetalert2';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import { MainService as portalMainService } from '../../portal/service/main.service';
import { EstablishService } from '../service/establish.service';
import { SalaryService } from '../service/salary.service';
declare var $: any;




@Component({
  selector: 'app-myinfo',
  templateUrl: './myinfo.component.html',
  styleUrls: ['./myinfo.component.css']
})
export class MyinfoComponent implements OnInit {

  constructor(private salaryService: SalaryService, private estNewService: EstablishService, private MD_mainService: MD_mainService, private dataAccessService: DataAccessService, private joiningservice: JoiningService, private establishmentService: EstablishmentService, private MD_RoleService: MD_RoleService, public mainService: MainService, private sanitizer: DomSanitizer, private allEmpService: AllEmpService, private spinner: NgxSpinnerService, private SA_mainService: SA_mainService, private router: Router, public PO_mainService: portalMainService, private _script: ScriptLoaderService, private profileService: ProfileService, private snackBar: MatSnackBar, private payableService: PayrollService,) { }
  erpUser: any
  b_acct_id: any
  httpUrl: any
  uploader: any
  imgURL: any
  allEmplyees: any = []
  userInfoObj: any = {}
  monthObj: any = { '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
  monthObjShort: any = { '1': 'JAN', '2': 'FEB', '3': 'MAR', '4': 'APR', '5': 'MAY', '6': 'JUN', '7': 'JUL', '8': 'AUG', '9': 'SEP', '10': 'OCT', '11': 'NOV', '12': 'DEC' }
  monthEnd: any = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }
  le_id: any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser['b_acct_id']
    this.httpUrl = this.profileService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'pimage' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.imgURL = this.PO_mainService.profileImageUrl;
    // maindiatory
    console.log("This is the rep user data ", this.erpUser)



    await this.getCurrentLegalEntity();
    await this.getListOfAllEmployee();


    // not mendiatory
    await this.getAllAccountModule();

    await this.getAllSystemRole();
    await this.AllEmployeeAssigedPost();
    await this.getEmployeeFunctionArea()
    await this.getprev();
    await this.getcurr();
    await this.getAllCurrentArrangements()
    await this.ind();
    // this.spinner.hide();
    await this.getcurrentroleswithresources();

    // await this.calcsalarycurr();
    //  await this. calcsalaryprev();
    // await this.itreport();

  }
  roles: any;
  roleObj: any = {};
  async getcurrentroleswithresources() {
    // this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.MD_mainService.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.roles = null;

      for (let i = 0; i < resp.data.length; i++) {
        this.roleObj[resp.data[i]['role_cd']] = resp.data[i]['role_name']
      }
      var role_cd = this.erpUser['role_cd'];

      for (let i = 0; i < role_cd.length; i++) {
        if (i == 0) {
          this.roles = this.roleObj[role_cd[i]]
        } else {
          this.roles = this.roles + " , " + this.roleObj[role_cd[i]]
        }
      }
      this.roles = '( ' + this.roles + ' )'
      // this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  async getImage() {


    const res = await this.profileService.getImage(this.erpUser.user_id);
    console.log(res);
  }
  async getCurrentLegalEntity() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['contact_email'] = this.erpUser['contact_email']
    obj['phone_no'] = this.erpUser['phone_no']
    var resp = await this.SA_mainService.getCurrentLegalEntity(JSON.stringify(obj));
    console.log(resp, 'current legal entity');
    if (resp['error'] == false) {
      console.log(resp);
      this.userInfoObj = Object.assign({}, resp['data'][0]);
      this.userInfoObj['party_dob'] = this.userInfoObj['party_dob'].split('T')[0]
      this.le_id = this.userInfoObj['le_id']
      //  this.getEmpID(this.userInfoObj['le_id'])
    } else {
      Swal.fire(this.PO_mainService.allLableShowObj[this.PO_mainService.language_cd + 'PROFILE24'], this.PO_mainService.allLableShowObj[this.PO_mainService.language_cd + 'PROFILE25'], 'error');
    }
  }
  empObj: any = {}
  async getListOfAllEmployee() {
    console.log("All Emoplyee")
    // this.spinner.show();
    this.allEmplyees = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allEmplyees = resp.data
      console.log(this.allEmplyees)

      //  console.log(this.allEmplyees)
      for (let i = 0; i < this.allEmplyees.length; i++) {
        if (this.allEmplyees[i]['le_id'] == this.le_id) {
          this.empObj = this.allEmplyees[i];
          this.emp_id = this.allEmplyees[i]['emp_id']
          this.arr_id = this.allEmplyees[i]['arr_id']
          break;
        }
      }
      console.log(this.empObj)

      // this.spinner.hide()
    } else {
      this.spinner.hide();

    }
  }
  async modalOpen1() {
    //   this.showArr = []

    // await this.getCurrentLegalEntity();
    //   this.showArr = this.allIncomplete
    $('#myModal1').modal('show');
  }
  modalOpen2() {
    //   this.showArr = []

    //   this.showArr = this.allIncomplete
    $('#myModal2').modal('show');
    //     console.log(this.empObj['emp_id'])
  }
  modalOpen3() {
    //   this.showArr = []

    // this.showArr = this.allIncomplete
    this.empObj['module_cd'] = this.empIdAssignedModule[this.le_id]
    this.empObj['cc'] = this.empIdPost[this.arr_id]
    console.log(this.empObj['cc'])

    $('#myModal3').modal('show');
  }

  allRole: any = []
  allModuleRole: any = []
  moduleToRole: any = {};
  roleToModule: any = {};

  async getAllSystemRole() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_RoleService.getCurrentMdrole(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 1) {
          this.allModuleRole.push(resp.data[i])
          this.moduleToRole[resp.data[i]['module_cd']] = resp.data[i]['role_cd']
          this.roleToModule[resp.data[i]['role_cd']] = resp.data[i]['module_cd']

        }
      }
    } else {
      // swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.allModuleRole)
    console.log(this.roleToModule)
  }
  empIdAssignedModule: any = {};
  allEmpAssignedModule: any = []
  arr_id: any
  //   moduleObj = {}
  async getEmployeeFunctionArea() {
    console.log("All Emp Function Area")
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.joiningservice.getEmployeeFunctionArea(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpAssignedModule = resp.data;
      this.empIdAssignedModule = {};

      var data = resp.data;

      for (let i = 0; i < data.length; i++) {
        if (data[i]['le_id'] == this.le_id) {
          this.arr_id = data[i]['arr_id']
        }
        //    console.log(this.arr_id)
        if (data[i]['role_cd'] == null || data[i]['role_cd'] == undefined) {

        } else {
          var dd = data[i]['role_cd'].split(",");
          console.log(dd)
          for (let j = 0; j < dd.length; j++) {
            //  console.log(data[i]['le_id'])
            //  console.log(this.roleToModule[dd[j]])
            //  console.log(this.empIdAssignedModule[data[i]['arr_id']])
            if (this.empIdAssignedModule[data[i]['le_id']] == undefined) {
              //   console.log(this.empIdAssignedModule[data[i]['le_id']])
              //   console.log(this.moduleObj[this.roleToModule[dd[j]]])
              this.empIdAssignedModule[data[i]['le_id']] = this.moduleObj[this.roleToModule[dd[j]]]
              //   console.log(this.empIdAssignedModule[data[i]['arr_id']])
              //   console.log(this.moduleObj[this.roleToModule[dd[j]]])
            } else {
              this.empIdAssignedModule[data[i]['le_id']] = this.empIdAssignedModule[data[i]['le_id']] + "," + this.moduleObj[this.roleToModule[dd[j]]]
              //  console.log(this.empIdAssignedModule[data[i]['le_id']])
              //   console.log(this.moduleObj[this.roleToModule[dd[j]]])  }
            }
          }
        }

      }

    } else {
      // swal.fire('Error', 'Some Error Occured', 'error')
    }

    console.log(this.empIdAssignedModule)
    this.empObj['module_cd'] = this.empIdAssignedModule[this.le_id]

    console.log(this.empObj['module_cd'])

  }
  allModule: any = [];
  moduleObj: any = {}
  async getAllAccountModule() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
      console.log(this.moduleObj)
    } else {
      // swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  empIdPost: any = {};
  allEmpPost: any = []
  async AllEmployeeAssigedPost() {
    console.log("All Emp Posting")
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getCurrentlyAssignedPost(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allEmpPost = resp.data;
      this.empIdPost = {};

      var data = resp.data;
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) == 1) {
          this.empIdPost[data[i]['arr_id']] = data[i]['module_cd'] + "-" + data[i]['cost_center']
        }
      }
      for (let i = 0; i < data.length; i++) {
        if ((data[i]['posting_type']) != 1) {
          this.empIdPost[data[i]['arr_id']] = this.empIdPost[data[i]['arr_id']] + ",(" + data[i]['module_cd']/*  + "-" + data[i]['cost_center_desc'] */ + ")"
        }
      }
    } else {

    }

    console.log(this.empIdPost)
  }
  getLeap(year: any) {
    //var year = this.attendanceObj['year'];
    var leap = false;
    if (year % 4 == 0) {
      if (year % 100 == 0) {
        // year is divisible by 400, hence the year is a leap year
        if (year % 400 == 0)
          leap = true;
        else
          leap = false;
      }
      else
        leap = true;
    }
    else
      leap = false;
    if (leap == true) {
      this.monthEnd[2] = 29;
    }


  }
  getStartAndEndDate(month: any, fin_year: any) {
    let date_period: any = { start_date: null, end_date: null }
    var year;
    if (month == '1' || month == '2' || month == '3') {
      year = parseInt(fin_year) + 1;
    } else {
      year = parseInt(fin_year);
    }
    this.getLeap(year);
    if (parseInt(month) > 9) {
      date_period.start_date = year + "-" + month + "-01"

    } else {
      date_period.start_date = year + "-0" + month + "-01"
    }
    if (parseInt(month) > 9) {
      date_period.end_date = year + "-" + month + "-" + this.monthEnd[month]

    } else {
      date_period.end_date = year + "-0" + month + "-" + this.monthEnd[month]
    }
    console.log(date_period.start_date, date_period.end_date);
    return date_period;
  }
  group_concat(data: any, group_key: any, concatKey: any) {

    let result: any = {}

    for (let i = 0; i < data.length; i++) {
      let key = ""
      let temp: any = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data: any = Object.values(result);
    for (let i = 0; i < ret_data.length; i++) {
      ret_data[i]['rate'] = Math.round(ret_data[i]['rate'])
    }
    return ret_data
  }
  group_concatprev(data: any, group_key: any, concatKey: any) {

    let result: any = {}

    for (let i = 0; i < data.length; i++) {
      let key: any = ""
      let temp: any = {}
      for (let j = 0; j < group_key.length; j++) {
        key += "_" + data[i][group_key[j]]
        temp[group_key[j]] = data[i][group_key[j]]
      }

      if (result[key] == undefined) {
        result[key] = temp
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] = data[i][concatKey[j]]
        }

      } else {
        for (let j = 0; j < concatKey.length; j++) {
          result[key][concatKey[j]] += data[i][concatKey[j]]
          // result[key][concatKey[j]] += "+" + data[i][concatKey[j]]
        }
      }


    }
    var ret_data: any = Object.values(result);
    for (let i = 0; i < ret_data.length; i++) {
      // ret_data[i]['pay_component_amt'] = parseFloat(ret_data[i]['pay_component_amt'].toFixed(2))
      ret_data[i]['pay_component_amt'] = parseFloat(ret_data[i]['pay_component_amt'].toFixed())

    }
    return ret_data
  }
  data: any = []
  curr_arr_id: any
  curr_month: any;
  curr_year: any;
  emp_arr: any = []
  emp_id: any
  dateToday: any
  //Reference Data
  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data.split(" ")[0];
      console.log(this.dateToday)
    } else {

    }
  }
  Obj: any = {}
  empArr: any = []
  allPresentDaysObj: any = {};
  async getlvl() {
    let obj1: any = {}
    obj1['module_cd'] = 'HR'
    obj1['node_cd'] = this.Obj['node_cd'];
    obj1['b_acct_id'] = this.b_acct_id
    obj1['status'] = 'ACTIVE'
    let resp1 = await this.payableService.getlvl2(JSON.stringify(obj1));
    if (resp1['error'] == false) {
      this.Obj['lvl2_cd'] = resp1['data'][0]['lvl2_cd']
    }
  }
  emp: any = []

  async getemparr() {

    let obj12: any = new Object();
    obj12['b_acct_id'] = this.b_acct_id;
    obj12['emp_arr'] = this.emp
    obj12['year'] = this.curr_year
    obj12['month'] = this.curr_month

    var resp12 = await this.payableService.getPresentDays(obj12);
    if (resp12['error'] == false) {
      this.empArr = [];
      this.allPresentDaysObj = {};
      this.empArr = resp12.data;
      for (let i = 0; i < this.empArr.length; i++) {
        for (let j = 0; j < this.allEmplyees.length; j++) {
          if (this.empArr[i]['emp_id'] == this.allEmplyees[j]['emp_id']) {
            this.empArr[i]['arr_id'] = this.allEmplyees[j]['arr_id'];
            break;
          }
        }
      }
      // for(let i=0;i<empArr.length;i++)
      // {
      //   this.allPresentDaysObj[empArr[i]['emp_id']] = empArr[i]['present_days'];
      // }

    }
  }
  calenderRule: any

  async calcsalarycurr() {
    // this.spinner.show()
    var sal_obj: any = new Object();
    for (let i = 0; i < this.allEmplyees.length; i++) {
      console.log(this.le_id)
      this.emp_arr = [];
      if (this.allEmplyees[i]['le_id'] == this.le_id) {
        this.curr_arr_id = this.allEmplyees[i]['arr_id']
        this.emp_id = this.allEmplyees[i]['emp_id']
        this.emp.push(this.emp_id)
        this.emp_arr.push(this.allEmplyees[i]['arr_id'])
        sal_obj['ind_emp_id'] = this.emp_arr
        this.Obj['node_cd'] = this.allEmplyees[i]['node_code']
      }
    }

    await this.getcurr();
    await this.getDate()
    await this.getemparr()

    sal_obj['b_acct_id'] = this.b_acct_id;
    let date_range = await this.getStartAndEndDate(this.curr_month, this.curr_year)
    sal_obj['start_dt'] = date_range.start_date;
    sal_obj['end_dt'] = date_range.end_date;
    sal_obj['accrual_date'] = this.dateToday;
    sal_obj['empArr'] = this.empArr;
    await this.getlvl()
    var obj1: any = new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['node_cd2'] = this.Obj['lvl2_cd'];
    var resp1 = await this.estNewService.getCalenderRule(JSON.stringify(obj1));
    // this.calenderRule = resp.data;
    if (resp1['error'] == false) {
      sal_obj['calenderRule'] = resp1.data;
    }
    sal_obj['month'] = this.curr_month

    console.log(sal_obj)



    var resp = await this.payableService.getSalaryOfEmployees(sal_obj);
    if (resp['error'] == false) {
      // this.spinner.hide()
      console.log(resp.data);
      this.data = resp.data
      if (this.data.length < 1) {
        Swal.fire('warning', 'please,complete your establishment first', 'warning')
      }
      this.data = this.group_concat(this.data, ['arr_id', 'pay_comp', 'pay_code'], ['rate']);
      console.table(this.data)

    }
  }
  arr: any = []
  arr1: any = []
  info: any = {}
  allEmplyees1: any = []
  async openview() {
    this.spinner.show()
    await this.calcsalarycurr();

    this.arr = []
    this.allEmplyees1 = []
    this.arr1 = []
    this.info = {}
    console.log(this.curr_arr_id)
    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.allEmplyees[i]['arr_id'] == this.curr_arr_id) {
        this.allEmplyees1.push(this.allEmplyees[i])
      }
    }
    console.log(this.allEmplyees1)
    this.info['emp_id'] = this.allEmplyees1[0]['emp_id']
    console.log(this.allEmplyees1[0]['emp_id'])
    this.info['emp_name'] = this.allEmplyees1[0]['emp_name']
    this.info['emp_phone_no'] = this.allEmplyees1[0]['emp_phone_no']
    this.info['emp_pan_no'] = this.allEmplyees1[0]['emp_pan_no']
    this.info['emp_adhar_no'] = this.allEmplyees1[0]['emp_adhar_no']
    this.info['bank_code'] = this.allEmplyees1[0]['bank_code']
    this.info['ifsc_code'] = this.allEmplyees1[0]['ifsc_code']
    this.info['pf_acct_no'] = this.allEmplyees1[0]['pf_acct_no']
    this.info['acct_no'] = this.allEmplyees1[0]['acct_no']
    var combined_arr = this.group_concat(this.data, ['arr_id', 'pay_code'], ['rate'])
    console.table(combined_arr)
    for (let i = 0; i < combined_arr.length; i++) {

      if (combined_arr[i]['arr_id'] == this.curr_arr_id) {
        if (combined_arr[i]['pay_code'] == 'PAY')


          this.info['Total_payble'] = Math.round(combined_arr[i]['rate'])
        // combined_arr[i]['rate'].toFixed(2)
        else

          this.info['Total_Deductions'] = Math.round(combined_arr[i]['rate'])
        // combined_arr[i]['rate'].toFixed(2)
      }
      this.info['Net_Payble'] = this.info['Total_payble'] - this.info['Total_Deductions']

    }

    console.log(this.info)

    for (let i = 0; i < this.data.length; i++) {


      if (this.data[i]['pay_code'] == "PAY") {



        this.arr.push(this.data[i])
      }
      else {

        this.arr1.push(this.data[i])
      }
    }
    console.log(this.monthObj[this.curr_month])


    console.log(this.arr)
    console.log(this.arr1)
    this.spinner.hide()

    $('#Z').modal('show');
  }
  dataprev: any = []

  prev_month: any;
  prev_year: any;
  async calcsalaryprev() {
    // this.spinner.show()
    var sal_obj: any = new Object();
    this.dataprev = [];
    for (let i = 0; i < this.allEmplyees.length; i++) {

      if (this.allEmplyees[i]['le_id'] == this.le_id) {


        this.emp_arr.push(this.allEmplyees[i]['arr_id'])
        sal_obj['ind_emp_id'] = this.emp_arr
      }
    }
    await this.getprev()

    let date_range: any = await this.getStartAndEndDate(this.prev_month, this.prev_year)
    sal_obj['start_dt'] = date_range.start_date;
    sal_obj['end_dt'] = date_range.end_date;
    sal_obj['b_acct_id'] = this.b_acct_id;
    var resp1 = await this.payableService.getBillForArrear(sal_obj);
    if (resp1['error'] == false) {
      // this.spinner.hide()
      console.log(resp1.data);
      this.dataprev = resp1.data;
      this.dataprev = this.group_concatprev(this.dataprev, ['arr_id', 'pay_component_code', 'pay_code'], ['pay_component_amt']);
      console.log(this.dataprev);


    }



  }
  prevpay: any = []
  prevded: any = []
  info1: any = {}
  async openpreviousbill() {
    await this.calcsalaryprev()

    this.prevpay = []
    this.prevded = []
    this.info1 = {}
    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.allEmplyees[i]['arr_id'] == this.arr_id) {
        this.allEmplyees1.push(this.allEmplyees[i])
      }
    }
    this.info['emp_id'] = this.allEmplyees1[0]['emp_id']
    console.log(this.allEmplyees1[0]['emp_id'])
    this.info['emp_name'] = this.allEmplyees1[0]['emp_name']
    this.info['emp_phone_no'] = this.allEmplyees1[0]['emp_phone_no']
    this.info['emp_pan_no'] = this.allEmplyees1[0]['emp_pan_no']
    this.info['emp_adhar_no'] = this.allEmplyees1[0]['emp_adhar_no']
    this.info['bank_code'] = this.allEmplyees1[0]['bank_code']
    this.info['ifsc_code'] = this.allEmplyees1[0]['ifsc_code']
    this.info['pf_acct_no'] = this.allEmplyees1[0]['pf_acct_no']
    this.info['acct_no'] = this.allEmplyees1[0]['acct_no']
    var combined_arr = this.group_concatprev(this.dataprev, ['arr_id', 'pay_code'], ['pay_component_amt'])
    console.table(combined_arr)
    for (let i = 0; i < combined_arr.length; i++) {

      if (combined_arr[i]['arr_id'] == this.arr_id) {
        if (combined_arr[i]['pay_code'] == 'PAY')

          this.info1['Total_payble'] = Math.round(combined_arr[i]['pay_component_amt'])

        // this.info1['Total_payble'] = combined_arr[i]['pay_component_amt'].toFixed(2)
        else

          this.info1['Total_Deductions'] = Math.round(combined_arr[i]['pay_component_amt'])
        //  combined_arr[i]['pay_component_amt'].toFixed(2)
      }
      this.info1['Net_Payble'] = this.info1['Total_payble'] - this.info1['Total_Deductions']

    }

    console.log(this.info1)

    for (let i = 0; i < this.dataprev.length; i++) {


      if (this.dataprev[i]['pay_code'] == "PAY") {

        this.prevpay.push(this.dataprev[i])

      }
      else {

        this.prevded.push(this.dataprev[i])
      }
    }
    console.log(this.monthObj[this.prev_month])


    console.log(this.prevpay)
    console.log(this.prevded)
    $('#Z1').modal('show');
  }
  pay_comp: any = []
  obj2: any = []
  all_component: any
  itreportdata1: any
  itreportdata: any = []
  itreportobj: any = {}
  totalobj: any = {}
  // { 'BASIC': 0.00, 'CCA': 0.00, 'CONV': 0.00, 'CPF/GPF Ded': 0.00, 'Comp Alw': 0.00, 'Comp. Loan': 0.00, 'Cons. Loan': 0.00, 'DA': 0.00, 'DEP-ALLOW': 0.00, 'Fest Adv': 0.00, 'GIS': 0.00, 'H RENT': 0.00, 'HB Adv': 0.00, 'HDFC': 0.00, 'HRA': 0.00, 'HVDT Allow': 0.00, 'I. Tax': 0.00, 'MED': 0.00, 'Misc Ded.': 0.00, 'NPS': 0.00, 'Other Adv': 0.00, 'Other Ded': 0.00, 'PF Advance': 0.00, 'Per Pay': 0.00, 'Res Alw': 0.00, 'Veh Loan': 0.00, 'WA': 0.00, 'totalpay': 0.00, 'totalded': 0.00, 'net_pay': 0.00, 'PF': 0.00 }
  async itreport() {
    //  await this.curr_year;
    this.spinner.show()
    var obb: any = new Object()
    obb['b_acct_id'] = this.b_acct_id
    obb['emp_id'] = this.emp_id;
    obb['fin_year'] = this.curr_year;
    var resp = await this.payableService.getItReport(JSON.stringify(obb))
    if (resp['error'] == false) {
      this.spinner.hide()

      this.itreportdata = resp.data
      this.itreportdata1 = resp.data

      this.itreportdata = this.group_concatprev(this.itreportdata, ['emp_id', 'pay_component_code', 'pay_code', 'month', 'fin_year'], ['pay_component_amt', 'num_of_days']);
      console.table(this.itreportdata)
      var all_pay_component_code = []
      for (let i = 0; i < this.itreportdata.length; i++) {
        all_pay_component_code.push(this.itreportdata[i]['pay_component_code']);
      }


      //   console.log(all_emp_id)
      this.pay_comp = Array.from(new Set(all_pay_component_code));


      this.itreportobj = {}
      // await this.getPersonalInfo();
      var obj: any = new Object();
      obj['b_acct_id'] = this.b_acct_id;

      // this.spinner.show();

      var resp1 = await this.payableService.getAllComponent(JSON.stringify(obj));
      if (resp1['error'] == false) {
        this.all_component = resp1.data;
        console.log(this.all_component)

        for (let i = 0; i < this.all_component.length; i++) {
          //  this.obj2[i]['component_code']=this.all_component[i]['component_code']
          //  this.obj2[i]['component_code']=0.00;
          // this.obj2.push(this.all_component[i]['component_code'])
          this.totalobj[this.all_component[i]['component_code']] = 0.00
          this.obj2[this.all_component[i]['component_code']] = 0.00
        }
      } else {
        return;
      }
      console.log(this.obj2)

      for (let i = 0; i < this.itreportdata.length; i++) {
        //  this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']]=0.00
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] == undefined) {
          console.log(this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']] = {}
        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] == undefined) {

          for (let j = 0; j < this.all_component.length; j++) {
            this.obj2[this.all_component[j]['component_code']] = 0.00
            //this.totalobj[this.all_component[i]['component_code']]=0.00
            //component[this.all_component[i]['component_code']]=0.00
          }
          let comp = Object.assign({}, this.obj2);

          //    this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']={'BASIC':0.00,'DA':0.00,'DEP':0.00,'HRA':0.00,'MA':0.00,'VA':0.00,'WA':0.00,'miscpay':[],'LIC1':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,NPS:0.00,GIS:0.00,IT:0.00,HRR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:[],total:0.00,net:0.00};
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'] = comp
          //  { 'BASIC': 0.00, 'CCA': 0.00, 'CONV': 0.00, 'CPF/GPF Ded': 0.00, 'Comp Alw': 0.00, 'Comp. Loan': 0.00, 'Cons. Loan': 0.00, 'DA': 0.00, 'DEP-ALLOW': 0.00, 'Fest Adv': 0.00, 'GIS': 0.00, 'H RENT': 0.00, 'HB Adv': 0.00, 'HDFC': 0.00, 'HRA': 0.00, 'HVDT Allow': 0.00, 'I. Tax': 0.00, 'MED': 0.00, 'Misc Ded.': 0.00, 'NPS': 0.00, 'Other Adv': 0.00, 'Other Ded': 0.00, 'PF Advance': 0.00, 'Per Pay': 0.00, 'Res Alw': 0.00, 'Veh Loan': 0.00, 'WA': 0.00, 'totalpay': 0.00, 'totalded': 0.00, 'net_pay': 0.00, 'PF': 0.00 };
          //  this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']=this.obj2
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] = 0.00
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['fin_year'] = this.itreportdata[i]['fin_year']
          console.log(this.itreportdata[i]['fin_year'])

        }
        if (this.itreportdata[i]['pay_code'] == 'PAY') {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalpay'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.totalobj[this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])


        } else {
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['totalded'] += parseInt(this.itreportdata[i]['pay_component_amt'])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['net_pay'] -= parseInt(this.itreportdata[i]['pay_component_amt'])
          this.totalobj[this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])

        }
        if (this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] != undefined) {
          console.log(3)
          console.log(this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']])
          this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']] += parseInt(this.itreportdata[i]['pay_component_amt'])
          console.log(this.itreportobj[this.itreportdata[i]['month'] + '-' + this.itreportdata[i]['fin_year']]['total'][this.itreportdata[i]['pay_component_code']])
        } else {
          if (this.itreportdata[i]['pay_code'] == 'PAY') {
            // this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']['pay_component_code']=this.itreportdata[i]['pay_component_amt']
            //  this.totalobj['miscpay'] +=this.itreportdata[i]['pay_component_amt']
          }
          else {
            // this.totalobj['miscded'] +=this.itreportdata[i]['pay_component_amt']

            // this.itreportobj[this.itreportdata[i]['month']+'-'+this.itreportdata[i]['fin_year']]['total']['pay_component_code']=this.itreportdata[i]['pay_component_amt']

          }

        }
        this.itreportobj = this.itreportobj
      }
      console.log(this.itreportobj)
    }

    else {
      this.spinner.hide()
      Swal.fire('Error..', 'Error while getting ITR', 'error')
    }
    //  console.log(this.itreportobj,this.itreportdata)
    // this.print1()
  }

  list: any = []
  arrsal: any = []
  arrsalfin: any = []
  arrsal1: any = []

  async print1() {
    this.spinner.show()
    await this.getcurr();
    //var txt = "VARANASASI DEVELOPMENT AUTHORITY(VDA)   Officers/THIRD/FOURTH Category EMPLOYEES STATEMENT FOR THE MONTH OF June,2020   PIRNT DATE: 2020-10-10"
    await this.itreport()

    this.info = {}
    console.log(this.curr_arr_id)
    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.allEmplyees[i]['arr_id'] == this.arr_id) {
        this.allEmplyees1.push(this.allEmplyees[i])
      }
    }
    console.log(this.allEmplyees1)
    this.info['emp_id'] = this.allEmplyees1[0]['emp_id']
    console.log(this.allEmplyees1[0]['emp_id'])
    this.info['emp_name'] = this.allEmplyees1[0]['emp_name']
    this.info['emp_phone_no'] = this.allEmplyees1[0]['emp_phone_no']
    this.info['emp_pan_no'] = this.allEmplyees1[0]['emp_pan_no']
    this.info['emp_adhar_no'] = this.allEmplyees1[0]['emp_adhar_no']
    this.info['bank_code'] = this.allEmplyees1[0]['bank_code']
    this.info['ifsc_code'] = this.allEmplyees1[0]['ifsc_code']
    this.info['pf_acct_no'] = this.allEmplyees1[0]['pf_acct_no']
    this.info['acct_no'] = this.allEmplyees1[0]['acct_no']

    var count = 0;
    this.list = ['Emp\nDetail'];
    let data_keys = [];

    let width = ['auto'];
    //   width.push('200')
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        this.list.push(this.all_component[i]['component_code'])
        data_keys.push(this.all_component[i]['component_code'])

      }
    }
    this.list.push('total\npay');

    //    data_keys.push('gross_pay');

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        this.list.push(this.all_component[i]['component_code']);
        data_keys.push(this.all_component[i]['component_code'])

      }
    }
    this.list.push('Total\nDed');
    //  data_keys.push('total');

    this.list.push('Net.\nSal.');

    // data_keys.push('net');

    var totalamtpay = 0
    count = 0;
    var totalamtded = 0
    var totalamtnet = 0
    var itarr = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] //Object.keys(this.itreportobj)
    var fin = this.curr_year
    // itarr[i]+'-'+fin
    for (let i = 0; i < itarr.length; i++) {
      if (itarr[i] == 3 && i == 0) {
        fin = this.curr_year - 1
      } else {
        fin = this.curr_year
      }
      if (this.itreportobj[itarr[i] + '-' + fin] == undefined || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.curr_year && i != 0)) {
        continue;
      }

      this.arrsal = []
      if (itarr[i] == 1 || itarr[i] == 2 || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.curr_year)) {
        var str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1)
        // arr.push(this.monthObj[itarr[i]] +'-'+(Number(this.itreportobj[itarr[i]+'-'+fin]['fin_year'])+ 1))
        this.arrsal.push(str)
      } else {
        str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']))
        // arr.push(this.monthObj[itarr[i]] +'-'+this.itreportobj[itarr[i]+'-'+fin]['fin_year'])
        this.arrsal.push(str)
      }
      //////////////////////////////////////////princy//////////////////////////////////////////

      for (let j = 1; j < this.list.length; j++) {
        console.log(this.list[j])
        //  arr.push(this.itreportobj[itarr[i]+'-'+fin][list[i]])
        // console.log(arr)
        //   console.log(this.itreportobj[list[i]])
        console.log(this.itreportobj[itarr[i] + '-' + fin]['total'][this.list[j]])
        // this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]=this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]
        if (this.list[j] == 'total\npay') {
          this.arrsal.push(this.itreportobj[itarr[i] + '-' + fin]['totalpay'])
          totalamtpay += this.itreportobj[itarr[i] + '-' + fin]['totalpay']
        }
        else if (this.list[j] == 'Total\nDed') {
          this.arrsal.push(this.itreportobj[itarr[i] + '-' + fin]['totalded'])
          totalamtded += this.itreportobj[itarr[i] + '-' + fin]['totalded']
        }
        else if (this.list[j] == 'Net.\nSal.') {
          this.arrsal.push(this.itreportobj[itarr[i] + '-' + fin]['net_pay'])
          totalamtnet += this.itreportobj[itarr[i] + '-' + fin]['net_pay']
        }
        else {


          this.arrsal.push(this.itreportobj[itarr[i] + '-' + fin]['total'][this.list[j]])

        }

      }

      this.arrsal1[count] = this.arrsal
      count++;

    }

    this.arrsalfin = [];
    this.arrsalfin.push('Final\nTotal')
    for (let j = 1; j < this.list.length; j++) {
      if (this.list[j] == 'total\npay') {
        this.arrsalfin.push(totalamtpay)
      }
      else if (this.list[j] == 'Total\nDed') {
        this.arrsalfin.push(totalamtded)
      }
      else if (this.list[j] == 'Net.\nSal.') {
        this.arrsalfin.push(totalamtnet)
      }

      else {

        this.arrsalfin.push(this.totalobj[this.list[j]])
      }


    }


    console.log(this.arrsal1);
    console.log(this.arrsalfin)

    $('#Z3').modal('show');


  }
  print1annual() {
    this.spinner.show()
    //var txt = "VARANASASI DEVELOPMENT AUTHORITY(VDA)   Officers/THIRD/FOURTH Category EMPLOYEES STATEMENT FOR THE MONTH OF June,2020   PIRNT DATE: 2020-10-10"
    var txt: any = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd: any = {
      pageSize: 'A2',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40], fontSize: 10, bold: true };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 60, 10, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };
    // console.log(dd)
    // dd.content.push("\n\n");
    var head = {
      columns: [

        {},
        {
          width: '*',
          align: 'right',
          text: 'Annual Statement of Employee ' + this.mainService.codeValueShowObj['HR0023'][this.curr_year],
          bold: true
        }

        , {}

      ],

    }
    dd.content.push(head);
    var sign1 = {
      columns: [
        {
          width: '*',
          text: 'Employee Name :',
          bold: true
        },

        {
          width: '*',
          align: 'left',
          text: this.personalinfo1[0]['emp_name'],
          bold: true
        },
        {
          width: '*',
          text: 'PAN NUMBER :',
          bold: true
        },
        {
          width: '*',
          align: 'left',
          text: this.personalinfo1[0]['emp_pan_no'],
          bold: true
        }

        //   width: '*',
        //   text: 'Designation :',
        //   bold: true
        // },{
        //   width: '*',
        //   align:'left',
        //     text:this.empidtodesignation[this.personalInfoObj1['emp_id']],
        //     bold: true
        // }


      ],

    }
    dd.content.push(sign1);
    var count = 0;
    let list = ['Emp\nDetail'];
    let data_keys = [];

    let width = ['auto'];
    //   width.push('200')
    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'PAY') {
        list.push(this.all_component[i]['component_code'])
        data_keys.push(this.all_component[i]['component_code'])
        width.push('auto')
      }
    }
    list.push('total\npay');
    width.push('auto');
    //    data_keys.push('gross_pay');

    for (let i = 0; i < this.all_component.length; i++) {
      if (this.all_component[i]['pay_code'] == 'DED') {
        list.push(this.all_component[i]['component_code']);
        data_keys.push(this.all_component[i]['component_code'])

        width.push('auto')
      }
    }
    list.push('Total\nDed');
    //  data_keys.push('total');
    width.push('auto');
    list.push('Net.\nSal.');
    width.push('auto');
    // data_keys.push('net');

    console.log(width)



    var tbl: any = {

      layout: 'lightHorizontalLines',
      fontSize: 8,
      table: {

        headerRows: 1,
        //   widths: [200,'auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'],
        widths: [],
        body: [
          // [ 'Month', 'Basic\nPay','HRA' ,'CCA','DEP\n Allow','MED','Per\npay','CONV','WA','HVDVT\nAllow','Comp\nAlw','Res\nAlw','DA','Total','LIC','PF','NPS','GIS','CPF\nGPF\nDed','Veh\nLoan','HB\nAdv','Fest\nAdv','I.\nTax','Other\nDed','HDFC','H\nRent','Misc\nDed','Cons.\nLoan','Other\nAdv','Total\nDed','Net.\nSal.' ]

          []

        ]
      }
    };
    for (let i = 0; i < list.length; i++) {
      tbl.table.body[0].push(list[i]);
      console.log(list[i])
      tbl.table.widths.push('auto')
    }
    dd.content.push(tbl);
    //  console.log(tbl1)
    console.log(dd.content)
    var totalamtpay = 0
    var totalamtded = 0
    var totalamtnet = 0
    var itarr = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] //Object.keys(this.itreportobj)
    var fin = this.curr_year
    // itarr[i]+'-'+fin
    for (let i = 0; i < itarr.length; i++) {
      if (itarr[i] == 3 && i == 0) {
        fin = this.curr_year - 1
      } else {
        fin = this.curr_year
      }
      if (this.itreportobj[itarr[i] + '-' + fin] == undefined || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.curr_year && i != 0)) {
        continue;
      }

      var arr = []
      if (itarr[i] == 1 || itarr[i] == 2 || (itarr[i] == 3 && (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1) == this.curr_year)) {
        var str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']) + 1)
        // arr.push(this.monthObj[itarr[i]] +'-'+(Number(this.itreportobj[itarr[i]+'-'+fin]['fin_year'])+ 1))
        arr.push(str)
      } else {
        str = this.monthObj[itarr[i]] + "\n" + (Number(this.itreportobj[itarr[i] + '-' + fin]['fin_year']))
        // arr.push(this.monthObj[itarr[i]] +'-'+this.itreportobj[itarr[i]+'-'+fin]['fin_year'])
        arr.push(str)
      }
      //////////////////////////////////////////princy//////////////////////////////////////////



      for (let j = 1; j < list.length; j++) {
        console.log(list[j])
        //  arr.push(this.itreportobj[itarr[i]+'-'+fin][list[i]])
        // console.log(arr)
        //   console.log(this.itreportobj[list[i]])
        console.log(this.itreportobj[itarr[i] + '-' + fin]['total'][list[j]])
        // this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]=this.itreportobj[itarr[i]+'-'+fin]['total'][list[j]]
        if (list[j] == 'total\npay') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['totalpay'])
          totalamtpay += this.itreportobj[itarr[i] + '-' + fin]['totalpay']
        }
        else if (list[j] == 'Total\nDed') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['totalded'])
          totalamtded += this.itreportobj[itarr[i] + '-' + fin]['totalded']
        }
        else if (list[j] == 'Net.\nSal.') {
          arr.push(this.itreportobj[itarr[i] + '-' + fin]['net_pay'])
          totalamtnet += this.itreportobj[itarr[i] + '-' + fin]['net_pay']
        }
        else {


          arr.push(this.itreportobj[itarr[i] + '-' + fin]['total'][list[j]])

        }








      }





      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['BASIC'])
      //  console.log(this.itreportobj[itarr[i]+'-'+fin]['total']['BASIC'])
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HRA']);
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CCA']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['DEP-ALLOW']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['MED']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Per Pay']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CONV']);
      //   console.log(this.itreportobj[itarr[i]+'-'+fin]['total']['CONV'])
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['WA']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HVDT Allow']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Comp Alw']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['DA']);

      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['WA']);
      //   var miscpay = this.itreportobj[itarr[i]+'-'+fin]['total']['miscpay'];
      //    var str  = '0'
      //   for (let j = 0; j < miscpay.length; j++) {
      //    if(j == 0){
      //     str = miscpay[j]
      //    }else{
      //     str = str +'/n'+miscpay[j]
      //    }

      //   }
      // arr.push(str)
      //       arr.push(this.itreportobj[itarr[i]+'-'+fin]['totalpay'])
      // totalamtpay += this.itreportobj[itarr[i]+'-'+fin]['totalpay']
      //       var amt1 = this.itreportobj[itarr[i]+'-'+fin]['total']['LIC1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC3']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC4']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC5']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC6']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['LIC7']
      //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
      //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
      //   arr.push(amt1);
      // arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['PF']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['NPS']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['GIS']);
      // //  arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['GIS']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['CPF/GPF Ded']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Veh Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HB Adv']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Fest Adv'])
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['I. Tax']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Other Ded']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['HDFC']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['H RENT']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Misc Ded.']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Comp. Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Cons. Loan']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Other Adv']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['Total Ded']);


      //   var amt
      //   amt = this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV3'];
      //   //var bldstr=this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV2']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['BLDADV3']
      //   arr.push(amt);
      //   amt = this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV2'];
      //   //var pfstr=this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV1']+"\n"+this.itreportobj[itarr[i]+'-'+fin]['total']['PFADV2']
      //   arr.push(amt);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['BADV']);
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['total']['EWF']);
      // var miscded = this.itreportobj[itarr[i]+'-'+fin]['total']['miscded'];
      // var str1  = '0'
      // for (let j = 0; j < miscded.length; j++) {
      //  if(j == 0){
      //   str1 = miscded[j]
      //  }else{
      //   str1 = str1 +'/n'+miscded[j]
      //  }

      // }
      // arr.push(str1)
      //   arr.push(this.itreportobj[itarr[i]+'-'+fin]['net_pay'])
      //   //arr.push(itarr[i]+'-'+fin)
      //   totalamtnet += this.itreportobj[itarr[i]+'-'+fin]['net_pay']

      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    var arr = []
    arr.push('Final\nTotal')
    for (let j = 1; j < list.length; j++) {
      if (list[j] == 'total\npay') {
        arr.push(totalamtpay)
      }
      else if (list[j] == 'Total\nDed') {
        arr.push(totalamtded)
      }
      else if (list[j] == 'Net.\nSal.') {
        arr.push(totalamtnet)
      }

      else {

        arr.push(this.totalobj[list[j]])
      }
      // arr.push(this.totalobj['HRA'])
      // arr.push(this.totalobj['CCA'])
      //  arr.push(this.totalobj['DEP-ALLOW']);
      //  arr.push(this.totalobj['MED']);
      //  arr.push(this.totalobj['Per Pay']);
      //  arr.push(this.totalobj['CONV']);
      //  arr.push(this.totalobj['WA']);
      //  arr.push(this.totalobj['HVDT Allow']);
      //  arr.push(this.totalobj['Comp Alw']);
      //  arr.push(this.totalobj['DA']);

      //    var miscpay = this.totalobj['miscpay'];


      //  arr.push(miscpay)
      // arr.push(totalamtpay)
      //  var amt1 = this.totalobj['LIC1']+"\n"+this.totalobj['LIC2']+"\n"+this.totalobj['LIC3']+"\n"+this.totalobj['LIC4']+"\n"+this.totalobj['LIC5']+"\n"+this.totalobj['LIC6']+"\n"+this.totalobj['LIC7']
      //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
      //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
      //  arr.push(amt1);
      //  arr.push(this.totalobj['PF']);
      //    arr.push(this.totalobj['NPS']);
      //    arr.push(this.totalobj['GIS']);
      //    arr.push(this.totalobj['IT']);
      //    arr.push(this.totalobj['HRR']);
      //    arr.push(this.totalobj['VD']);
      //    arr.push(this.totalobj['VADV'])
      //    var amt
      //    amt = this.totalobj['BLDADV1']+"\n"+this.totalobj['BLDADV2']+"\n"+this.totalobj['BLDADV3'];
      //var bldstr=this.totalobj['BLDADV1']+"\n"+this.totalobj['BLDADV2']+"\n"+this.totalobj['BLDADV3']
      // arr.push(amt);
      // amt = this.totalobj['PFADV']+"\n"+this.totalobj['PFADV1']+"\n"+this.totalobj['PFADV2'];
      //var pfstr=this.totalobj['PFADV']+"\n"+this.totalobj['PFADV1']+"\n"+this.totalobj['PFADV2']
      //    arr.push(amt);
      //    arr.push(this.totalobj['BADV']);
      //    arr.push(this.totalobj['EWF']);
      //  var miscded = this.totalobj['miscded'];

      //  arr.push(miscded)
      //  arr.push(totalamtnet)
      //   arr.push(itarr[i])
    }

    dd.content[dd.content.length - 1].table.body.push(arr);
    console.log(dd)
    /*  console.log(dd)
      dd.content.push("\n\n");
      var sign1={
       columns: [
         {
           width: '*',
           text: 'PREPARED BY:',
           bold: true
         },

         {
           width: '*',
           text: 'CHECKED BY:' ,
           bold: true
         },
         {
           width: '*',
           text: 'SIGNED BY:',
           bold: true
         }


       ],

     } */
    /*   dd.content.push("\n\n\n");
      dd.content.push(sign1);
      dd.content.push("\n\n");
      dd.content.push({text:"CERTIFIED:",bold: true})
      dd.content.push("\n\n"); */
    /*  dd.content.push({text:"1. That I have satisfied myself that all the salaries included in the bills drawn in the month of "+this.monthObj[month]+"/"+fin_year+" [the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned."})
     dd.content.push("\n"); */
    /*   dd.content.push({text:"2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month."})
      dd.content.push("\n");

      dd.content.push({text:"3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions."})
      dd.content.push("\n");

      dd.content.push({text:"4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them."})
      dd.content.push("\n");

      dd.content.push({text:"5. That the bill has been checked with the sanctioned in the scale register."})
      dd.content.push("\n");

      dd.content.push({text:"Date :                                                    Signature Of Drawing Officer:"})
      dd.content.push("\n");

      dd.content.push({text:"Pay Rs. ....................................."})
   */
    this.spinner.hide()
    pdfMake.createPdf(dd).download(this.personalinfo1[0]['emp_name'] + '-' + this.curr_year);
  }

  allArr: any = []
  allEmplyees_new: any = []

  async getAllCurrentArrangements() {
    // this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.payableService.getAllCurrentArrangements(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allArr = resp['data'];
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.allArr[i]);
        obj['emp_name'] = obj['emp_id'] + " - " + obj['emp_name'];
        this.allEmplyees_new.push(obj)
      }

      this.allArr = await this.dataAccessService.filterEmployeeData(this.allArr)
      this.allEmplyees_new = await this.dataAccessService.filterEmployeeData(this.allEmplyees_new)

    } else {
      this.spinner.hide()

    }
  }
  oneclickslip: any = []
  lines: any = []
  selectedArr: any = []
  async buildoneclickslip(name: any) {
    this.spinner.show();
    console.log("pri")
    console.log(this.personalinfo1)
    for (var i = 0; i < this.personalinfo1.length; i++) {
      this.payableArr = [];
      this.dedArr = [];
      this.payable = 0;
      this.deduction = 0;
      this.total = 0;
      var obj: any = new Object();
      obj = this.personalinfo1[0]
      console.log(this.allArr)
      for (var j = 0; j < this.allArr.length; j++) {
        if (this.allArr[j].emp_id == this.emp_id) {
          this.selectedArr = this.allArr[j];
        }
      }

      console.log(this.salarySlipArroneclick)
      var data = this.group_concatprev(this.salarySlipArroneclick, ['arr_id', 'pay_component_code', 'pay_code'], ['pay_component_amt', 'num_of_days']);
      console.table(data)


      this.salarySlipArroneclick = data;


      console.log(this.allArr)
      for (var k = 0; k < this.salarySlipArroneclick.length; k++) {
        if (this.salarySlipArroneclick[k].arr_id == obj['arr_id']) {
          if (this.salarySlipArroneclick[k].pay_code == 'PAY') {
            this.payableArr.push(this.salarySlipArroneclick[k]);
            this.payable += this.salarySlipArroneclick[k].pay_component_amt;
          } else if ((this.salarySlipArroneclick[k].pay_code == 'DED')) {
            this.dedArr.push(this.salarySlipArroneclick[k]);
            this.deduction += this.salarySlipArroneclick[k].pay_component_amt;
          }
        }
      }
      console.log(this.payableArr)
      console.log(this.dedArr)
      if (this.payableArr.length > this.dedArr.length) {
        console.log("1")
        this.lines = this.payableArr;
      } else {
        console.log("2")
        this.lines = this.dedArr;
      }
      this.total = parseFloat((this.payable - this.deduction).toFixed(2));
      console.log(this.total)
      console.log(this.dedArr)

      obj['emp_id'] = this.emp_id
      if (this.lines.length > 0) {
        var Obj: any = new Object();
        Obj['personalInfoObj'] = obj
        Obj['selectedArr'] = this.selectedArr
        Obj['payableArr'] = this.payableArr
        Obj['payable'] = this.payable
        Obj['dedArr'] = this.dedArr
        Obj['deduction'] = this.deduction
        Obj['lines'] = this.lines
        Obj['total'] = this.total
        this.oneclickslip.push(Obj)
        //  console.log(this.dedArr)
        //  console.log()
      }
    }
    this.print2(name)
    this.spinner.hide();
  }

  async buildoneclickslipcurr() {
    this.spinner.show();
    for (var i = 0; i < this.personalinfo1.length; i++) {
      this.payableArr = [];
      this.dedArr = [];
      this.payable = 0;
      this.deduction = 0;
      this.total = 0;
      var obj: any = new Object();
      obj = this.personalinfo1[i]
      console.log(this.allArr)
      for (var j = 0; j < this.allArr.length; j++) {
        if (this.allArr[j].emp_id == obj['emp_id']) {
          this.selectedArr = this.allArr[j];
        }
      }

      // console.log(this.salarySlipArroneclick)
      //  var data = this.group_concat(this.salarySlipArroneclick, ['arr_id', 'pay_component_code', 'pay_code'], ['pay_component_amt','num_of_days']);
      // console.table(data)


      this.salarySlipArroneclick = this.data;


      console.log(this.allArr)
      for (var k = 0; k < this.salarySlipArroneclick.length; k++) {
        if (this.salarySlipArroneclick[k].arr_id == obj['arr_id']) {
          if (this.salarySlipArroneclick[k].pay_code == 'PAY') {
            this.payableArr.push(this.salarySlipArroneclick[k]);
            this.payable += this.salarySlipArroneclick[k].rate;
          } else if ((this.salarySlipArroneclick[k].pay_code == 'DED')) {
            this.dedArr.push(this.salarySlipArroneclick[k]);
            this.deduction += this.salarySlipArroneclick[k].rate;
          }
        }
      }
      console.log(this.payableArr)
      console.log(this.dedArr)
      if (this.payableArr.length > this.dedArr.length) {
        console.log("1")
        this.lines = this.payableArr;
      } else {
        console.log("2")
        this.lines = this.dedArr;
      }
      this.total = parseFloat((this.payable - this.deduction).toFixed(2));
      console.log(this.total)
      console.log(this.dedArr)

      obj['emp_id'] = obj['emp_id']
      if (this.lines.length > 0) {
        var Obj: any = new Object();
        Obj['personalInfoObj'] = obj
        Obj['selectedArr'] = this.selectedArr
        Obj['payableArr'] = this.payableArr
        Obj['payable'] = this.payable
        Obj['dedArr'] = this.dedArr
        Obj['deduction'] = this.deduction
        Obj['lines'] = this.lines
        Obj['total'] = this.total
        this.oneclickslip.push(Obj)
        //  console.log(this.dedArr)
        //  console.log()
      }
    }
    console.log(this.oneclickslip)
    this.print2curr()
    this.spinner.hide();
  }
  async print2(name: any) {


    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    for (var l = 0; l < this.oneclickslip.length; l++) {
      var personalInfoObj = this.oneclickslip[l]['personalInfoObj']
      var selectedArr = this.oneclickslip[l]['selectedArr']
      var payable = this.oneclickslip[l]['payable']
      var dedArr = this.oneclickslip[l]['dedArr']
      var deduction = this.oneclickslip[l]['deduction']
      var lines = this.oneclickslip[l]['lines']
      var total = this.oneclickslip[l]['total']
      var payableArr = this.oneclickslip[l]['payableArr']
      console.log(payableArr)
      console.log(dedArr)
      var header0 = {
        columns: [
          {
            width: '*',
            text: 'Salary Slip for ' + this.mainService.codeValueShowObj['HR0024'][this.prev_month] + "/" + this.mainService.codeValueShowObj['HR0023'][this.prev_year],
            bold: true,
            alignment: 'center'
          }
        ],

      }

      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Employee ID :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_id']
          },
          {

            width: '*',
            text: 'Employee Name :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_name']
          }
        ],

      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'Employee Phone Number :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['emp_phone_no']
          },
          {
            width: '*',
            text: 'Employee Pan Number :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_pan_no']
          }
        ],

      }
      var header3 = {
        columns: [
          {
            width: '*',
            text: 'Designation :',
            bold: true

          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0011'][selectedArr['designation_code']]
          },
          {
            width: '*',
            text: 'Cadre :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0013'][selectedArr['cadre_code']]
          }
        ],

      }
      var header4 = {
        columns: [
          {
            width: '*',
            text: 'Class :',
            bold: true

          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0014'][selectedArr['class_code']]
          },
          {
            width: '*',
            text: 'Account No. :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['acct_no']
          }
        ],

      }
      var header5 = {
        columns: [
          {
            width: '*',
            text: 'IFSC Code :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['ifsc_code']
          },
          {
            width: '*',
            text: 'Bank :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['bank_code']
          }
        ],

      }
      if (personalInfoObj['pf_acct_no'] == 0) {
        personalInfoObj['pf_acct_no'] = "NA"
      }
      var header6 = {
        columns: [
          {
            width: '*',
            text: 'PF Account Number :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['pf_acct_no']
          },
          {
            width: '*',
            text: '',
            bold: true
          },
          {
            width: '*',
            text: ''
          }
        ],

      }
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
      dd.content.push(header0);

      dd.content.push({ text: " " });
      dd.content.push(header1);

      dd.content.push(header2);

      dd.content.push(header3);
      dd.content.push(header4);
      dd.content.push(header5);
      dd.content.push(header6);
      dd.content.push({ text: " " });

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


      var header8 = {
        columns: [
          {
            width: '*',
            text: 'PAYABLES',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'AMOUNT',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'DEDUCTIONS',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'AMOUNT',
            bold: true,
            alignment: 'left'
          }
        ],


      }
      dd.content.push(header8);
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
      dd.content.push({ text: " " });
      var objSort: any = {};
      var objSort1: any = {};
      console.log(this.payableArr)
      for (let i = 0; i < this.payableArr.length; i++) {

        objSort['component_code'] = this.payableArr[i]['pay_component_code']

      }
      console.log(objSort)
      for (let i = 0; i < this.dedArr.length; i++) {

        objSort1['component_code'] = this.dedArr[i]['pay_component_code']

      }
      console.log(objSort1)


      // var objSort1={'GIS':{}}
      var sortArr: any = [];
      var sortArr1: any = [];
      for (var i = 0; i < payableArr.length; i++) {
        if (objSort[payableArr[i]['pay_component_code']] != undefined) {
          objSort[payableArr[i]['pay_component_code']] = payableArr[i];
        } else {
          objSort[payableArr[i]['pay_component_code']] = payableArr[i];

        }
      }
      for (var i = 0; i < dedArr.length; i++) {
        if (objSort1[dedArr[i]['pay_component_code']] != undefined) {
          objSort1[dedArr[i]['pay_component_code']] = dedArr[i];
        } else {
          objSort1[dedArr[i]['pay_component_code']] = dedArr[i];

        }
      }
      var keys = Object.keys(objSort);
      var keys1 = Object.keys(objSort1);
      console.log(payableArr)

      for (var i = 0; i < keys.length; i++) {
        if (objSort[keys[i]]['pay_component_code'] != undefined && objSort[keys[i]]['pay_component_amt'] != 0) {
          sortArr.push(objSort[keys[i]]);

        }
      }
      for (var i = 0; i < keys1.length; i++) {
        if (objSort1[keys1[i]]['pay_component_code'] != undefined && objSort1[keys1[i]]['pay_component_amt'] != 0) {
          sortArr1.push(objSort1[keys1[i]]);

        }
      }
      payableArr = sortArr;
      dedArr = sortArr1
      console.log(dedArr)
      console.log(lines)
      for (var i = 0; i < lines.length; i++) {


        var objRow: any = { columns: [], }


        var text_temp: any = '';
        console.log(payableArr)
        if (i < payableArr.length) {
          text_temp = payableArr[i]['pay_component_code']
          console.log(text_temp)
          objRow['columns'].push({
            width: '*',
            text: text_temp,
            alignment: 'left',
          })
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_amtpay = '';
        if (i < payableArr.length) {
          pay_component_amtpay = payableArr[i]['pay_component_amt'].toFixed(2);
          objRow['columns'].push({
            width: '*',
            text: pay_component_amtpay,
            alignment: 'left',
          })
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_codeded = '';
        console.log(dedArr)
        if (i < dedArr.length) {
          pay_component_codeded = dedArr[i]['pay_component_code'];
          console.log(pay_component_codeded)
          objRow['columns'].push({
            width: '*',
            text: pay_component_codeded,
            alignment: 'left',
          })
          console.log(objRow['columns'])
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_amtded;
        if (i < dedArr.length) {
          pay_component_amtded = dedArr[i]['pay_component_amt'].toFixed(2);
          objRow['columns'].push({
            width: '*',
            text: pay_component_amtded,
            alignment: 'left',
          })
          console.log(pay_component_amtded)
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }





        dd.content.push(objRow);
        console.log(objRow)
        dd.content.push({ text: " " });
      }
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });


      var totalObjRow = {
        columns: [

          {
            width: '*',
            text: 'Total Payable : Rs. ',
            bold: true

          },
          {
            width: '*',
            text: payable.toFixed(2),
          },
          {
            width: '*',
            text: 'Total Deductions : Rs.',
            bold: true
          },
          {
            width: '*',
            text: deduction.toFixed(2)
          },


        ],

      }
      dd.content.push(totalObjRow);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });
      var totalObjRow = {
        columns: [

          {
            width: '*',
            text: '',
            bold: true

          },
          {
            width: '*',
            text: '',
          },
          {
            width: '*',
            text: 'Net Payable : Rs.',
            bold: true
          },
          {
            width: '*',
            text: total.toFixed(2)
          },


        ],

      }
      dd.content.push(totalObjRow);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });
      dd.content.push({ text: " " });
      dd.content.push({ text: " " });
      var sig = {
        columns: [

          {
            width: '*',
            text: "Note : This is an electronically generated document and does not require signature",
            bold: true
          }


        ],

      }
      dd.content.push(sig);
      if (l <= this.oneclickslip.length - 2) {
        dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }], pageBreak: 'before' });
      }
    }
    pdfMake.createPdf(dd).download(name + '.pdf');

  }
  async print2curr() {


    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    for (var l = 0; l < this.oneclickslip.length; l++) {
      var personalInfoObj: any = this.oneclickslip[l]['personalInfoObj']
      var selectedArr: any = this.oneclickslip[l]['selectedArr']
      var payable: any = this.oneclickslip[l]['payable']
      var dedArr: any = this.oneclickslip[l]['dedArr']
      var deduction: any = this.oneclickslip[l]['deduction']
      var lines: any = this.oneclickslip[l]['lines']
      var total: any = this.oneclickslip[l]['total']
      var payableArr: any = this.oneclickslip[l]['payableArr']
      console.log(payableArr)
      console.log(dedArr)
      var header0 = {
        columns: [
          {
            width: '*',
            text: 'Salary  for ' + this.mainService.codeValueShowObj['HR0024'][this.curr_month] + "/" + this.mainService.codeValueShowObj['HR0023'][this.curr_year],
            bold: true,
            alignment: 'center'
          }
        ],

      }

      var header1 = {
        columns: [
          {
            width: '*',
            text: 'Employee ID :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_id']
          },
          {

            width: '*',
            text: 'Employee Name :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_name']
          }
        ],

      }
      var header2 = {
        columns: [
          {
            width: '*',
            text: 'Employee Phone Number :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['emp_phone_no']
          },
          {
            width: '*',
            text: 'Employee Pan Number :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['emp_pan_no']
          }
        ],

      }
      var header3 = {
        columns: [
          {
            width: '*',
            text: 'Designation :',
            bold: true

          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0011'][selectedArr['designation_code']]
          },
          {
            width: '*',
            text: 'Cadre :',
            bold: true
          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0013'][selectedArr['cadre_code']]
          }
        ],

      }
      var header4 = {
        columns: [
          {
            width: '*',
            text: 'Class :',
            bold: true

          },
          {
            width: '*',
            text: this.mainService.codeValueShowObj['HR0014'][selectedArr['class_code']]
          },
          {
            width: '*',
            text: 'Account No. :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['acct_no']
          }
        ],

      }
      var header5 = {
        columns: [
          {
            width: '*',
            text: 'IFSC Code :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['ifsc_code']
          },
          {
            width: '*',
            text: 'Bank :',
            bold: true
          },
          {
            width: '*',
            text: personalInfoObj['bank_code']
          }
        ],

      }
      if (personalInfoObj['pf_acct_no'] == 0) {
        personalInfoObj['pf_acct_no'] = "NA"
      }
      var header6 = {
        columns: [
          {
            width: '*',
            text: 'PF Account Number :',
            bold: true

          },
          {
            width: '*',
            text: personalInfoObj['pf_acct_no']
          },
          {
            width: '*',
            text: '',
            bold: true
          },
          {
            width: '*',
            text: ''
          }
        ],

      }
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
      dd.content.push(header0);

      dd.content.push({ text: " " });
      dd.content.push(header1);

      dd.content.push(header2);

      dd.content.push(header3);
      dd.content.push(header4);
      dd.content.push(header5);
      dd.content.push(header6);
      dd.content.push({ text: " " });

      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


      var header8 = {
        columns: [
          {
            width: '*',
            text: 'PAYABLES',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'AMOUNT',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'DEDUCTIONS',
            bold: true,
            alignment: 'left'
          },
          {
            width: '*',
            text: 'AMOUNT',
            bold: true,
            alignment: 'left'
          }
        ],


      }
      dd.content.push(header8);
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
      dd.content.push({ text: " " });
      var objSort: any = {};
      var objSort1: any = {};
      console.log(this.payableArr)
      for (let i = 0; i < this.payableArr.length; i++) {

        objSort['component_code'] = this.payableArr[i]['pay_comp']

      }
      console.log(objSort)
      for (let i = 0; i < this.dedArr.length; i++) {

        objSort1['component_code'] = this.dedArr[i]['pay_comp']

      }
      console.log(objSort1)


      // var objSort1={'GIS':{}}
      var sortArr = [];
      var sortArr1 = [];
      for (var i = 0; i < payableArr.length; i++) {
        if (objSort[payableArr[i]['pay_comp']] != undefined) {
          objSort[payableArr[i]['pay_comp']] = payableArr[i];
        } else {
          objSort[payableArr[i]['pay_comp']] = payableArr[i];

        }
      }
      for (var i = 0; i < dedArr.length; i++) {
        if (objSort1[dedArr[i]['pay_comp']] != undefined) {
          objSort1[dedArr[i]['pay_comp']] = dedArr[i];
        } else {
          objSort1[dedArr[i]['pay_comp']] = dedArr[i];

        }
      }
      var keys = Object.keys(objSort);
      var keys1 = Object.keys(objSort1);
      console.log(payableArr)

      for (var i = 0; i < keys.length; i++) {
        if (objSort[keys[i]]['pay_comp'] != undefined && objSort[keys[i]]['rate'] != 0) {
          sortArr.push(objSort[keys[i]]);

        }
      }
      for (var i = 0; i < keys1.length; i++) {
        if (objSort1[keys1[i]]['pay_comp'] != undefined && objSort1[keys1[i]]['rate'] != 0) {
          sortArr1.push(objSort1[keys1[i]]);

        }
      }
      payableArr = sortArr;
      dedArr = sortArr1
      console.log(dedArr)
      console.log(lines)
      for (var i = 0; i < lines.length; i++) {


        var objRow: any = { columns: [], }


        var text_temp = '';
        console.log(payableArr)
        if (i < payableArr.length) {
          text_temp = payableArr[i]['pay_comp']
          console.log(text_temp)
          objRow['columns'].push({
            width: '*',
            text: text_temp,
            alignment: 'left',
          })
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_amtpay = '';
        if (i < payableArr.length) {
          pay_component_amtpay = payableArr[i]['rate']
          objRow['columns'].push({
            width: '*',
            text: pay_component_amtpay,
            alignment: 'left',
          })
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_codeded = '';
        console.log(dedArr)
        if (i < dedArr.length) {
          pay_component_codeded = dedArr[i]['pay_comp'];
          console.log(pay_component_codeded)
          objRow['columns'].push({
            width: '*',
            text: pay_component_codeded,
            alignment: 'left',
          })
          console.log(objRow['columns'])
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }

        var pay_component_amtded;
        if (i < dedArr.length) {
          pay_component_amtded = dedArr[i]['rate']
          objRow['columns'].push({
            width: '*',
            text: pay_component_amtded,
            alignment: 'left',
          })
          console.log(pay_component_amtded)
        } else {
          objRow['columns'].push({
            width: '*',
            text: "",
            alignment: 'left',
          })
        }





        dd.content.push(objRow);
        console.log(objRow)
        dd.content.push({ text: " " });
      }
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });


      var totalObjRow = {
        columns: [

          {
            width: '*',
            text: 'Total Payable : Rs. ',
            bold: true

          },
          {
            width: '*',
            text: payable.toFixed(2),
          },
          {
            width: '*',
            text: 'Total Deductions : Rs.',
            bold: true
          },
          {
            width: '*',
            text: deduction.toFixed(2)
          },


        ],

      }
      dd.content.push(totalObjRow);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });
      var totalObjRow = {
        columns: [

          {
            width: '*',
            text: '',
            bold: true

          },
          {
            width: '*',
            text: '',
          },
          {
            width: '*',
            text: 'Net Payable : Rs.',
            bold: true
          },
          {
            width: '*',
            text: total.toFixed(2)
          },


        ],

      }
      dd.content.push(totalObjRow);
      dd.content.push({ text: " " });
      dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 2 }] });
      dd.content.push({ text: " " });
      dd.content.push({ text: " " });
      dd.content.push({ text: " " });
      var sig = {
        columns: [

          {
            width: '*',
            text: "Note : This is an electronically generated document and does not require signature",
            bold: true
          }


        ],

      }
      dd.content.push(sig);
      if (l <= this.oneclickslip.length - 2) {
        dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }], pageBreak: 'before' });
      }
    }
    pdfMake.createPdf(dd).download('currentsalary.pdf');

  }
  payableArr: any = []
  dedArr: any = []
  payable: any;
  deduction: any;
  total: any;
  salarySlipArroneclick: any = []
  async getPaySlip() {
    this.oneclickslip = [];
    this.payableArr = [];
    this.dedArr = [];
    this.payable = 0;
    this.deduction = 0;
    this.total = 0;
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.emp_id
    obj['fin_year'] = this.prev_year
    obj['month'] = this.prev_month

    console.log(obj)

    this.spinner.show();
    var resp = await this.payableService.getSalarySlip(JSON.stringify(obj));
    if (resp['error'] == false) {

      this.salarySlipArroneclick = resp.data;
      console.log(this.salarySlipArroneclick)
      this.buildoneclickslip("payslip")
      //  this.ind(this.emp_id);

      /*  this.buildSlip();
       this.getArr();
       this.getPersonalInfo(); */
      //this.getBankInfo();
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }
  personalinfo = []
  personalinfo1 = []
  async ind() {
    this.personalinfo1 = []
    console.log("princy")
    var Obj: any = new Object();
    Obj['b_acct_id'] = this.b_acct_id;
    Obj['emp_id'] = this.emp_id;
    this.spinner.show();
    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(Obj));
    if (resp['error'] == false) {
      this.personalinfo = resp.data;
      console.log(this.personalinfo)
      console.log(this.emp_id)
      for (let i = 0; i < this.personalinfo.length; i++) {
        if (this.personalinfo[i]['emp_id'] == this.emp_id) {
          this.personalinfo1.push(this.personalinfo[i])
        }
      }
      console.log(this.personalinfo1)

    }
  }
  async getprev() {
    const d = moment(new Date());
    var day = d.subtract(1, 'months').format('YYYY-MM-DD')
    var month_prev = new Date(day).getMonth()
    this.prev_month = month_prev + 1;
    this.prev_year = new Date(day).getFullYear()
  }
  async getcurr() {
    const d = new Date();
    let month = d.getMonth();
    this.curr_month = month + 1
    this.curr_year = d.getFullYear();
  }
}
