import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MainService } from '../../service/main.service';
import { SettingService } from '../../service/setting.service';
import Swal from 'sweetalert2';
import { EstablishmentService } from '../../service/establishment.service'
import { FormBuilder, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
declare var $: any
@Component({
  selector: 'app-suspension',
  templateUrl: './suspension.component.html',
  styleUrls: ['./suspension.component.css']
})
export class SuspensionComponent implements OnInit {


  constructor(public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService) { }

  erpUser: any;
  b_acct_id: any;
  allPayDetails: any;
  payArr: any = [];
  dedArr: any = [];
  payObj: any = {};
  dedObj: any = {};
  payUpdtArr: any = [];
  dedUpdtArr: any = [];
  payUpdtObj: any = {};
  dedUpdtObj: any = {};
  payRestObj: any = {};
  dedRestObj: any = {};
  payCount: any = 0;
  dedCount: any = 0;
  payCountRest: any = 0;
  dedCountRest: any = 0;
  payCountReinst: any = 0;
  dedCountReinst: any = 0;
  inputPayArr: any = [];
  inputDedArr: any = [];
  inputPayUpdtArr: any = [];
  inputDedUpdtArr: any = [];
  inputPayStatArr: any = [];
  inputDedStatArr: any = [];
  inputPayReinstArr: any = [];
  inputDedReinstArr: any = [];
  payStatArr: any = [];
  dedStatArr: any = [];
  inputPayRestArr: any = [];
  inputDedRestArr: any = [];
  payRestArr: any = [];
  dedRestArr: any = [];
  payReinstArr: any = [];
  dedReinstArr: any = [];
  allPayCompo: any = {};
  allEmplyees: any = [];
  selectEmpObj: any = {};
  suspensionObj: any = {};
  suspensionNewObj: any = {};
  suspensionUpdtObj: any = {};
  suspensionUpdtNewObj: any = {};
  suspensionStatObj: any = {};
  suspensionRestObj: any = {};
  suspensionReinstObj: any = {};
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['emp_id', 'emp_name', 'order_id', 'suspension_start_dt', 'action'];
  datasource: any;
  empIdToName: any = {};
  arrearArray: any = [{ value: "YES" }, { value: "NO" }]
  allActiveEmplyees: any = []
  newallEmplyees: any = []
  amountObj: any = {}
  allSuspensionArray: any = []
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllActiveEmployees();
    //  await this.getAllEmployees();

    await this.getAllSuspended();
    let currDate = new Date().toISOString().slice(0, 10);
    this.suspensionObj['suspension_start_dt'] = currDate;
  }

  //deepankar
  async getFixedPayDetails() {
    var obj: any = new Object();
    this.payArr = []
    this.dedArr = []
    this.payCount = 0;
    this.dedCount = 0
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.suspensionObj['emp_id']
    for (let i = 0; i < this.allActiveEmplyees.length; i++) {
      if (this.suspensionObj['emp_id'] == this.allActiveEmplyees[i]['emp_id']) {
        obj['arr_id'] = this.allActiveEmplyees[i]['arr_id']
      }
    }
    var resp = await this.settingService.getFixedPayDetails(obj);
    if (resp['error'] == false) {
      this.allPayDetails = []
      this.dedArr = []
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payArr.push(this.allPayDetails[i])
          this.payCount++;
        }
        else {
          this.dedArr.push(this.allPayDetails[i])
          this.dedCount++;
        }
      }
      for (let i = 0; i < this.payCount; i++) {
        this.payObj[i] = 0;
      }
      for (let i = 0; i < this.dedCount; i++) {
        this.dedObj[i] = 0;
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
  }

  async getEmpSuspension() {
    var obj: any = new Object();
    this.payUpdtArr = [];
    this.dedUpdtArr = [];
    this.payCount = 0
    this.dedCount = 0
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.suspensionUpdtObj['emp_id']

    var resp = await this.establishmentService.getEmpSuspension(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPayDetails = []
      this.dedUpdtArr = []
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payUpdtArr.push(this.allPayDetails[i])
          this.payCount++;
        }
        else {
          this.dedUpdtArr.push(this.allPayDetails[i])
          this.dedCount++;
        }
      }
      for (let i = 0; i < this.payCount; i++) {
        this.payUpdtObj[i] = this.payUpdtArr[i]['fraction_per'];
      }
      for (let i = 0; i < this.dedCount; i++) {
        this.dedUpdtObj[i] = this.dedUpdtArr[i]['fraction_per'];
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
  }
  async getEmpSuspensionRestructure() {
    this.payRestArr = [];
    this.dedRestArr = [];
    this.payCountRest = 0;
    this.dedCountRest = 0;
    let currDate = new Date().toISOString().slice(0, 10);
    this.suspensionRestObj['restructure_start_dt'] = currDate;
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.suspensionRestObj['emp_id']

    var resp = await this.establishmentService.getEmpSuspension(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.payRestArr = []
      this.dedRestArr = []
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payRestArr.push(this.allPayDetails[i])
          this.payCountRest++;
        }
        else {
          this.dedRestArr.push(this.allPayDetails[i])
          this.dedCountRest++;
        }
      }
      for (let i = 0; i < this.payCountRest; i++) {
        this.payRestObj[i] = this.payRestArr[i]['fix_rate'];
      }
      for (let i = 0; i < this.dedCountRest; i++) {
        this.dedRestObj[i] = this.dedRestArr[i]['fix_rate'];
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
  }
  //    async getEmpSuspensionReinstate(){
  //     let currDate = new Date().toISOString().slice(0, 10);
  //    this.suspensionReinstObj['reinstate_start_dt'] = currDate;
  //    var obj= new Object();
  //    obj['b_acct_id'] = this.b_acct_id;
  //    obj['emp_id']= this.suspensionReinstObj['emp_id']

  //    var resp = await this.establishmentService.getEmpSuspension(JSON.stringify(obj));
  //    if(resp['error'] == false){
  //      this.allPayDetails = resp.data;
  //      for(let i=0;i<this.allPayDetails.length;i++)
  //      {
  //        if(this.allPayDetails[i]['pay_code'] == 'PAY'){
  //          this.payReinstArr.push(this.allPayDetails[i])
  //          this.payCountReinst++;
  //        }
  //        else{
  //          this.dedReinstArr.push(this.allPayDetails[i])
  //          this.dedCountReinst++;
  //        }
  //      }
  //    }
  //  else{
  //    this.spinner.hide()
  //    Swal.fire('Error','Error while getting employee salary','error')
  //    }
  //   }
  async getEmpSuspensionStatus() {
    var obj :any  = new  Object();
    this.payCount = 0;
    this.dedCount = 0;
    this.payStatArr = [];
    this.dedStatArr = [];
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.suspensionStatObj['emp_id']

    var resp = await this.establishmentService.getEmpSuspension(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPayDetails = []
      this.dedStatArr = []
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payStatArr.push(this.allPayDetails[i])
          this.payCount++;
        }
        else {
          this.dedStatArr.push(this.allPayDetails[i])
          this.dedCount++;
        }
      }
      console.log("getEmpSuspensionStatus :- ", this.payStatArr, this.dedStatArr)
      return true;
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
      return false;
    }
  }
  async changeSuspensionStatus() {
    this.inputPayStatArr = [];
    this.inputDedStatArr = [];
    for (let i = 0; i < this.payCount; i++) {
      this.inputPayStatArr.push({
        arr_id: this.payStatArr[i]['arr_id'],
        pay_id: this.payStatArr[i]['pay_id'],
        update_user_id: this.erpUser.user_id,
      })
    }
    for (let i = 0; i < this.dedCount; i++) {
      this.inputDedStatArr.push({
        arr_id: this.dedStatArr[i]['arr_id'],
        pay_id: this.dedStatArr[i]['pay_id'],
        update_user_id: this.erpUser.user_id,
      })
    }
    this.suspensionStatObj['b_acct_id'] = this.b_acct_id;
    this.suspensionStatObj['pay'] = this.inputPayStatArr;
    this.suspensionStatObj['ded'] = this.inputDedStatArr;
    this.suspensionStatObj['arr_id'] = this.payStatArr[0]['arr_id']

    var resp = await this.establishmentService.changeSuspensionStatus(this.suspensionStatObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      return true;
    } else {
      this.spinner.hide();
      return false;
    }
  }
  //deepankar
  async getAllSuspended() {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['ineligible_party_status_codes'] = ['TT'];
    var resp = await this.establishmentService.getAllsuspensions(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allSuspensionArray = []
      this.spinner.hide();
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['suspension_status'] == 'ACTIVE') {
          this.allSuspensionArray.push(resp.data[i])
        }
      }
      for (let i = 0; i < this.allSuspensionArray.length; i++) {
        for (let j = 0; j < this.allEmplyees.length; j++) {
          if (this.allSuspensionArray[i]['emp_id'] == this.allEmplyees[j]['emp_id']) {
            this.allSuspensionArray[i]['emp_name'] = this.allEmplyees[j]['emp_name']
          }
        }
      }

      this.datasource = new MatTableDataSource(this.allSuspensionArray)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting All suspensions ', 'error')
      // this.snackBar.open("Error while getting All suspensions", 'Error', {
      //   duration: 5000
      // });
    }
  }

  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  async getAllActiveEmployees() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ['ACTIVE', 'INACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    this.allActiveEmplyees = []

    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      for (let i = 0; i < this.allEmplyees.length; i++) {
        this.empIdToName[this.allEmplyees[i].emp_id] = this.allEmplyees[i].emp_id;
        this.empIdToName[this.allEmplyees[i].emp_name] = this.allEmplyees[i].emp_name;
        if (this.allEmplyees[i].emp_status_code == 'ACTIVE') {
          if (this.allEmplyees[i].employee_current_type_code != 'SUSPENDED') {
            this.allActiveEmplyees.push(this.allEmplyees[i])
          }
        }
      }

      this.newallEmplyees = []
      for (let i = 0; i < this.allActiveEmplyees.length; i++) {
        var obj :any  = new  Object();
        obj = Object.assign({}, this.allActiveEmplyees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }
    } else {
      swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async submitSuspension() {
    //deepankar
    if (this.suspensionObj['emp_id'] == null || this.suspensionObj['emp_id'] == undefined || this.suspensionObj['emp_id'] == '') {
      Swal.fire("Error", 'Employee Name can not be null', 'error')
      return
    }
    if (this.suspensionObj['order_id'] == null || this.suspensionObj['order_id'] == undefined || this.suspensionObj['order_id'] == '') {
      Swal.fire("Error", 'Order can not be null', 'error')
      return
    }
    if (this.suspensionObj['suspension_start_dt'] == null || this.suspensionObj['suspension_start_dt'] == undefined || this.suspensionObj['suspension_start_dt'] == '') {
      Swal.fire("Error", 'Suspension Start Date can not be null', 'error')
      return
    }
    if (this.suspensionObj['suspension_note'] == null || this.suspensionObj['suspension_note'] == undefined || this.suspensionObj['suspension_note'] == '') {
      Swal.fire("Error", 'Suspension Note can not be null', 'error')
      return
    }
    for (let i = 0; i < this.payCount; i++) {
      if (this.payObj[i] <= 100 && this.payObj[i] >= 0) {
        if (this.payObj[i] == 0 || this.payObj[i] == null || this.payObj[i] == undefined) {
          if (i != this.payArr.length) {
            this.inputPayArr.push({
              pay_component_code: this.payArr[i]['pay_component_code'],
              arr_id: this.payArr[i]['arr_id'],
              pay_id: this.payArr[i]['pay_id'],
              pay_code: this.payArr[i]['pay_code'],
              fraction_per: this.payObj[i],
              old_rate: this.payArr[i]['rate'],
              new_rate: this.payArr[i]['rate'],
              create_user_id: this.erpUser.user_id,
              employee_current_type_code: 'SUSPENDED',
              emp_id: this.suspensionObj['emp_id'],
              order_id: this.suspensionObj['order_id'],
              suspension_start_dt: this.suspensionObj['suspension_start_dt'],
              suspension_note: this.suspensionObj['suspension_note'],
            });
          }
        }
        else if (this.payObj[i] == 100) {
          if (i != this.payArr.length) {

            this.inputPayArr.push({
              pay_component_code: this.payArr[i]['pay_component_code'],
              arr_id: this.payArr[i]['arr_id'],
              pay_id: this.payArr[i]['pay_id'],
              pay_code: this.payArr[i]['pay_code'],
              fraction_per: this.payObj[i],
              old_rate: this.payArr[i]['rate'],
              new_rate: 0,
              create_user_id: this.erpUser.user_id,
              employee_current_type_code: 'SUSPENDED',
              emp_id: this.suspensionObj['emp_id'],
              order_id: this.suspensionObj['order_id'],
              suspension_start_dt: this.suspensionObj['suspension_start_dt'],
              suspension_note: this.suspensionObj['suspension_note'],
            });
          }
        }
        else {
          if (i != this.payArr.length) {
            this.inputPayArr.push({
              pay_component_code: this.payArr[i]['pay_component_code'],
              arr_id: this.payArr[i]['arr_id'],
              pay_id: this.payArr[i]['pay_id'],
              pay_code: this.payArr[i]['pay_code'],
              fraction_per: this.payObj[i],
              old_rate: this.payArr[i]['rate'],
              new_rate: ((this.payArr[i]['rate'] * this.payObj[i]) / 100).toFixed(2),
              create_user_id: this.erpUser.user_id,
              employee_current_type_code: 'SUSPENDED',
              emp_id: this.suspensionObj['emp_id'],
              order_id: this.suspensionObj['order_id'],
              suspension_start_dt: this.suspensionObj['suspension_start_dt'],
              suspension_note: this.suspensionObj['suspension_note'],
            });
          }

        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    for (let i = 0; i < this.dedCount; i++) {
      if (this.payObj[i] <= 100 && this.payObj[i] >= 0) {
        if (this.dedObj[i] == 0 || this.dedObj[i] == null || this.dedObj[i] == undefined) {
          this.inputDedArr.push({
            pay_component_code: this.dedArr[i]['pay_component_code'],
            arr_id: this.dedArr[i]['arr_id'],
            pay_id: this.dedArr[i]['pay_id'],
            pay_code: this.dedArr[i]['pay_code'],
            fraction_per: this.dedObj[i],
            old_rate: this.dedArr[i]['rate'],
            new_rate: this.dedArr[i]['rate'],
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionObj['emp_id'],
            order_id: this.suspensionObj['order_id'],
            suspension_start_dt: this.suspensionObj['suspension_start_dt'],
            suspension_note: this.suspensionObj['suspension_note'],
          });
        }
        else if (this.dedObj[i] == 100) {
          this.inputDedArr.push({
            pay_component_code: this.dedArr[i]['pay_component_code'],
            arr_id: this.dedArr[i]['arr_id'],
            pay_id: this.dedArr[i]['pay_id'],
            pay_code: this.dedArr[i]['pay_code'],
            fraction_per: this.dedObj[i],
            old_rate: this.dedArr[i]['rate'],
            new_rate: 0,
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionObj['emp_id'],
            order_id: this.suspensionObj['order_id'],
            suspension_start_dt: this.suspensionObj['suspension_start_dt'],
            suspension_note: this.suspensionObj['suspension_note'],
          });
        }
        else {
          this.inputDedArr.push({
            pay_component_code: this.dedArr[i]['pay_component_code'],
            arr_id: this.dedArr[i]['arr_id'],
            pay_id: this.dedArr[i]['pay_id'],
            pay_code: this.dedArr[i]['pay_code'],
            fraction_per: this.dedObj[i],
            old_rate: this.dedArr[i]['rate'],
            new_rate: ((this.dedArr[i]['rate'] * this.dedObj[i]) / 100).toFixed(2),
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionObj['emp_id'],
            order_id: this.suspensionObj['order_id'],
            suspension_start_dt: this.suspensionObj['suspension_start_dt'],
            suspension_note: this.suspensionObj['suspension_note'],
          });
        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    //deepankar
    this.spinner.show()
    this.suspensionNewObj['b_acct_id'] = this.b_acct_id;
    this.suspensionNewObj['pay'] = this.inputPayArr;
    this.suspensionNewObj['ded'] = this.inputDedArr;
    // this.suspensionObj['b_acct_id'] = this.b_acct_id;
    // this.suspensionObj['create_user_id'] = this.erpUser.user_id;
    // this.suspensionObj['employee_current_type_code'] = 'SUSPENDED'
    var resp = await this.establishmentService.suspendEmployee(this.suspensionNewObj);
    if (resp['error'] == false) {
      await this.getAllSuspended();
      this.spinner.hide();
      this.suspensionObj = {};
      this.payArr = [];
      this.dedArr = [];
      this.payCount = 0
      this.dedCount = 0
      this.getAllActiveEmployees()
      // this.snackBar.open("Suspension  Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Suspension  Added Successfully!", 'success');
    } else {
      this.spinner.hide();
      // this.snackBar.open("Error while Adding Suspension  Of Employee", 'Error', {
      //   duration: 5000
      // });
      if (resp["errorsusp"] == true) {
        Swal.fire("Error", `This employee is not suspence before the joining date (${resp['estbtDate']}) !`, 'error');
        return;
      }

      Swal.fire("Error", "Error while Adding Suspension  Of Employee", 'error');
    }
  }

  /* For Update */
  openUpdate( element:any ) {
    this.suspensionUpdtObj = Object.assign({}, element);
    //this.suspensionUpdtObj['old_fraction_per'] =element['fraction_per'];
    this.getEmpSuspension();
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }
  /*For View */
  openDetails( element:any ) {
    //this.suspensionUpdtObj = Object.assign({}, element);
    //this.getEmpSuspension();
    //$('.nav-tabs a[href="#tab-5"]').tab('show');
  }
  /* For Reinstate*/
  async openReinstate( element:any ) {
    this.suspensionStatObj = Object.assign({}, element);
    this.suspensionRestObj = Object.assign({}, element);
    this.suspensionReinstObj = Object.assign({}, element);
    let status = await this.getEmpSuspensionStatus();
    // let status = await this.changeSuspensionStatus();
    console.log("state -", status);
    if (status == true) {
      swal.fire({
        title: 'Do you want to restructure the Salary components?If click cancel button then it will reinstate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        if (result.value) {
          await this.changeSuspensionStatus();
          await this.getEmpSuspensionRestructure();
          $('.nav-tabs a[href="#tab-4"]').tab('show');
        }
        else {
          // this.getEmpSuspensionReinstate();
          // this.reinstate();

        }
      })
    }
    else {
      swal.fire("warning", "Not change status", "error");
    }
  }
  /*For Terminate */
  terminate( element:any ) {
    // this.suspensionUpdtObj = Object.assign({}, element);
    //this.getEmpSuspension();
  }

  async updateSuspension() {
    if (this.suspensionUpdtObj['updation_remark'] == null || this.suspensionUpdtObj['updation_remark'] == undefined || this.suspensionUpdtObj['updation_remark'] == '') {
      Swal.fire("Error", 'Updation Remark can not be null', 'error')
      return
    }
    for (let i = 0; i < this.payCount; i++) {
      if (this.payUpdtObj[i] <= 100 && this.payUpdtObj[i] >= 0) {
        if (this.payUpdtObj[i] == 0 || this.payUpdtObj[i] == null || this.payUpdtObj[i] == undefined) {
          this.inputPayUpdtArr.push({
            pay_component_code: this.payUpdtArr[i]['pay_component_code'],
            arr_id: this.payUpdtArr[i]['arr_id'],
            pay_id: this.payUpdtArr[i]['pay_id'],
            pay_code: this.payUpdtArr[i]['pay_code'],
            fraction_per: this.payUpdtObj[i],
            old_rate: this.payUpdtArr[i]['fix_rate'],
            new_rate: this.payUpdtArr[i]['fix_rate'],
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.payUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
        else if (this.payUpdtObj[i] == 100) {
          this.inputPayUpdtArr.push({
            pay_component_code: this.payUpdtArr[i]['pay_component_code'],
            arr_id: this.payUpdtArr[i]['arr_id'],
            pay_id: this.payUpdtArr[i]['pay_id'],
            pay_code: this.payUpdtArr[i]['pay_code'],
            fraction_per: this.payUpdtObj[i],
            old_rate: this.payUpdtArr[i]['fix_rate'],
            new_rate: 0,
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.payUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
        else {
          this.inputPayUpdtArr.push({
            pay_component_code: this.payUpdtArr[i]['pay_component_code'],
            arr_id: this.payUpdtArr[i]['arr_id'],
            pay_id: this.payUpdtArr[i]['pay_id'],
            pay_code: this.payUpdtArr[i]['pay_code'],
            fraction_per: this.payUpdtObj[i],
            old_rate: this.payUpdtArr[i]['fix_rate'],
            new_rate: ((this.payUpdtArr[i]['fix_rate'] * this.payUpdtObj[i]) / 100).toFixed(2),
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.payUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    for (let i = 0; i < this.dedCount; i++) {
      if (this.payUpdtObj[i] <= 100 && this.payUpdtObj[i] >= 0) {
        if (this.dedUpdtObj[i] == 0 || this.dedUpdtObj[i] == null || this.dedUpdtObj[i] == undefined) {
          this.inputDedUpdtArr.push({
            pay_component_code: this.dedUpdtArr[i]['pay_component_code'],
            arr_id: this.dedUpdtArr[i]['arr_id'],
            pay_id: this.dedUpdtArr[i]['pay_id'],
            pay_code: this.dedUpdtArr[i]['pay_code'],
            fraction_per: this.dedUpdtObj[i],
            old_rate: this.dedUpdtArr[i]['fix_rate'],
            new_rate: this.dedUpdtArr[i]['fix_rate'],
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.dedUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
        else if (this.dedUpdtObj[i] == 100) {
          this.inputDedUpdtArr.push({
            pay_component_code: this.dedUpdtArr[i]['pay_component_code'],
            arr_id: this.dedUpdtArr[i]['arr_id'],
            pay_id: this.dedUpdtArr[i]['pay_id'],
            pay_code: this.dedUpdtArr[i]['pay_code'],
            fraction_per: this.dedUpdtObj[i],
            old_rate: this.dedUpdtArr[i]['fix_rate'],
            new_rate: 0,
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.dedUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
        else {
          this.inputDedUpdtArr.push({
            pay_component_code: this.dedUpdtArr[i]['pay_component_code'],
            arr_id: this.dedUpdtArr[i]['arr_id'],
            pay_id: this.dedUpdtArr[i]['pay_id'],
            pay_code: this.dedUpdtArr[i]['pay_code'],
            fraction_per: this.dedUpdtObj[i],
            old_rate: this.dedUpdtArr[i]['fix_rate'],
            new_rate: ((this.dedUpdtArr[i]['fix_rate'] * this.dedUpdtObj[i]) / 100).toFixed(2),
            update_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.dedUpdtArr[i]['emp_id'],
            order_id: this.suspensionUpdtObj['order_id'],
            suspension_start_dt: this.suspensionUpdtObj['suspension_start_dt'],
            suspension_note: this.suspensionUpdtObj['suspension_note'],
            updation_remark: this.suspensionUpdtObj['updation_remark'],
          });
        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    this.suspensionUpdtObj['b_acct_id'] = this.b_acct_id;
    this.suspensionUpdtObj['pay'] = this.inputPayUpdtArr;
    this.suspensionUpdtObj['ded'] = this.inputDedUpdtArr;
    this.suspensionUpdtObj['arr_id'] = this.payUpdtArr[0]['arr_id']
    //this.suspensionUpdtObj['b_acct_id'] = this.b_acct_id;
    //this.suspensionUpdtObj['update_user_id'] = this.erpUser.user_id;
    // this.suspensionObj['emp_status_code'] = 'ACTIVE';
    // this.suspensionObj['employee_current_type_code'] = 'SUSPENDED'
    this.spinner.show()
    //var arr=[]
    // if( this.suspensionObj['arrear']=='YES'){

    //   await this.check()
    //var ob1=new Object
    //ob1['b_acct_id']=this.b_acct_id
    //ob1['emp_id']=this.suspensionObj['emp_id']
    //ob1['data']= [this.amountObj]
    //ob1['create_user_id'] = this.erpUser.user_id;
    //var resp1 = await this.establishmentService.addarrayVariablePay(this.suspensionUpdtNewObj);
    // if (resp1['error'] == false) {

    // } else {
    //   this.snackBar.open("Error while Adding Arrear of Employee", 'Error', {
    //     duration: 5000
    //   });
    // }
    // }


    var resp = await this.establishmentService.updatesuspension(this.suspensionUpdtObj);
    if (resp['error'] == false) {
      this.suspensionUpdtObj = {};
      this.payUpdtArr = [];
      this.dedUpdtArr = [];
      this.payCount = 0
      this.dedCount = 0
      await this.getAllSuspended();
      this.spinner.hide();
      // this.snackBar.open("Suspension Updated Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Suspension Updated Successfully!", 'success')
    } else {
      this.spinner.hide();
      if (resp['errorsusp_update'] == true) {
        swal.fire("Warning", ` You can update Suspension only after the Suspension-Start-Date (${resp['susp_st_dt']})!`, 'warning')
        return;
      }
      // this.snackBar.open("Error while Updating Suspension  Of Employee", 'Error', {
      //   duration: 5000
      // });
      swal.fire('Error', "Error while Updating Suspension  Of Employee", 'error')
    }
  }

  async restructure() {
    this.inputPayRestArr = [];
    this.inputDedRestArr = [];
    for (let i = 0; i < this.payCountRest; i++) {
      this.inputPayRestArr.push({
        pay_component_code: this.payRestArr[i]['pay_component_code'],
        arr_id: this.payRestArr[i]['arr_id'],
        pay_id: this.payRestArr[i]['pay_id'],
        pay_code: this.payRestArr[i]['pay_code'],
        fix_rate: this.payRestArr[i]['fix_rate'],
        rest_rate: this.payRestObj[i],
        update_user_id: this.erpUser.user_id,
        employee_current_type_code: 'RESTRUCTURED',
        emp_id: this.payRestArr[i]['emp_id'],
        restructure_start_dt: this.suspensionRestObj['restructure_start_dt'],
      });
    }
    for (let i = 0; i < this.dedCountRest; i++) {
      this.inputDedRestArr.push({
        pay_component_code: this.dedRestArr[i]['pay_component_code'],
        arr_id: this.dedRestArr[i]['arr_id'],
        pay_id: this.dedRestArr[i]['pay_id'],
        pay_code: this.dedRestArr[i]['pay_code'],
        fix_rate: this.dedRestArr[i]['fix_rate'],
        rest_rate: this.payRestObj[i],
        update_user_id: this.erpUser.user_id,
        employee_current_type_code: 'RESTRUCTURED',
        emp_id: this.dedRestArr[i]['emp_id'],
        restructure_start_dt: this.suspensionRestObj['restructure_start_dt'],
      });
    }
    this.suspensionRestObj['b_acct_id'] = this.b_acct_id;
    this.suspensionRestObj['pay'] = this.inputPayRestArr;
    this.suspensionRestObj['ded'] = this.inputDedRestArr;
    this.suspensionRestObj['arr_id'] = this.payRestArr[0]['arr_id']
    this.spinner.show()
    var resp = await this.establishmentService.restructure(this.suspensionRestObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.suspensionRestObj = {};
      this.payRestArr = [];
      this.dedRestArr = [];
      // this.snackBar.open("Restructure Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Restructure Successfully!", 'success');
      await this.getAllSuspended();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
    } else {
      this.spinner.hide();
      // this.snackBar.open("Error while Updating Restructure  Of Employee", 'Error', {
      //   duration: 5000
      // });
      if (resp['restructure_update'] == true) {
        swal.fire("Warning", `You can restructure this user after this date (${resp['restr_st_dt']}) !`, 'warning');
        return;
      }

      swal.fire("Error", "Error while Updating Restructure  Of Employee", 'error');
    }
  }

  async reinstate() {
    let currDate = new Date().toISOString().slice(0, 10);
    this.suspensionReinstObj['reinstate_start_dt'] = currDate;
    var obj :any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.suspensionReinstObj['emp_id']

    var resp = await this.establishmentService.getEmpSuspension(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payReinstArr.push(this.allPayDetails[i])
          this.payCountReinst++;
        }
        else {
          this.dedReinstArr.push(this.allPayDetails[i])
          this.dedCountReinst++;
        }
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
    for (let i = 0; i < this.payCountReinst; i++) {
      this.inputPayReinstArr.push({
        pay_component_code: this.payReinstArr[i]['pay_component_code'],
        arr_id: this.payReinstArr[i]['arr_id'],
        pay_id: this.payReinstArr[i]['pay_id'],
        pay_code: this.payReinstArr[i]['pay_code'],
        fix_rate: this.payReinstArr[i]['fix_rate'],
        update_user_id: this.erpUser.user_id,
        employee_current_type_code: 'REINSTATED',
        emp_id: this.payReinstArr[i]['emp_id'],
        reinstate_start_dt: this.suspensionReinstObj['reinstate_start_dt'],
      });
    }
    for (let i = 0; i < this.dedCountReinst; i++) {
      this.inputDedReinstArr.push({
        pay_component_code: this.dedReinstArr[i]['pay_component_code'],
        arr_id: this.dedReinstArr[i]['arr_id'],
        pay_id: this.dedReinstArr[i]['pay_id'],
        pay_code: this.dedReinstArr[i]['pay_code'],
        fix_rate: this.dedReinstArr[i]['fix_rate'],
        update_user_id: this.erpUser.user_id,
        employee_current_type_code: 'REINSTATED',
        emp_id: this.dedReinstArr[i]['emp_id'],
        reinstate_start_dt: this.suspensionReinstObj['reinstate_start_dt'],
      });
    }
    this.suspensionReinstObj['b_acct_id'] = this.b_acct_id;
    this.suspensionReinstObj['pay'] = this.inputPayReinstArr;
    this.suspensionReinstObj['ded'] = this.inputDedReinstArr;
    this.suspensionReinstObj['arr_id'] = this.payReinstArr[0]['arr_id']
    this.spinner.show()
    var resp = await this.establishmentService.reinstate(this.suspensionReinstObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      Swal.fire('Success', 'Reinstate Successfully! ', 'success')
      // this.snackBar.open("Reinstate Successfully!", 'Success', {
      //   duration: 5000
      // });
      await this.getAllSuspended();
    } else {
      this.spinner.hide();
      if (resp['reinstate'] == true) {
        swal.fire("Warning", `You can reinstate this user after this date (${resp['reinstateDate']}) !`, 'warning');
        return;
      }


      // this.snackBar.open("Error while Updating Reinstate  Of Employee", 'Error', {
      //   duration: 5000
      // });
      swal.fire("Error", 'Error while Updating Reinstate  Of Employee', 'error');
    }
  }

  convert(str:any ) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return new Date([date.getFullYear(), mnth, "01"].join("-"));
  }
  add_months(dt:any , n:any ) {

    return new Date(dt.setMonth(dt.getMonth() + n));
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async check() {
    this.suspensionObj['b_acct_id'] = this.b_acct_id;
    this.suspensionObj['update_user_id'] = this.erpUser.user_id;
    this.suspensionObj['emp_status_code'] = 'INACTIVE';
    this.suspensionObj['employee_current_type_code'] = 'SUSPENDED'
    this.spinner.show()
    var arr = []
    if (this.suspensionObj['arrear'] == 'YES') {

      var startDate = new Date(this.convert(this.suspensionObj['suspension_start_dt']))
      var endDate = new Date(this.convert(this.suspensionObj['suspension_end_dt']))

      while (startDate <= endDate) {
        var obj :any  = new  Object()
        if ((startDate.getMonth() + 1) < 4) {
          obj['year'] = startDate.getFullYear() - 1
          obj['month'] = startDate.getMonth() + 1
        } else {
          obj['year'] = startDate.getFullYear()
          obj['month'] = startDate.getMonth() + 1
        }
        arr.push(obj)
        startDate = new Date(this.add_months(startDate, 1))
      }

      var obj :any  = new  Object
      obj['b_acct_id'] = this.b_acct_id
      obj['emp_id'] = this.suspensionObj['emp_id']
      obj['data'] = arr
      var resp = await this.establishmentService.getbillforSuspension(JSON.stringify(obj));
      var basicDaArr = []
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['pay_component_code'] == 'BASIC' || resp['data'][i]['pay_component_code'] == 'DA') {
          basicDaArr.push(resp['data'][i])
        }
      }
      startDate = new Date(this.suspensionObj['suspension_start_dt'])

      endDate = new Date(this.suspensionObj['suspension_end_dt'])
      var variablePayArr = []
      var arrearPercent = this.suspensionObj['fraction_per'] - this.suspensionObj['old_fraction_per']
      var startDateFinYear
      var endDateFinYear

      if ((startDate.getMonth() + 1) < 4) {
        startDateFinYear = startDate.getFullYear() - 1
      } else {
        startDateFinYear = startDate.getFullYear()
      }
      if ((endDate.getMonth() + 1) < 4) {
        endDateFinYear = endDate.getFullYear() - 1
      } else {
        endDateFinYear = endDate.getFullYear()
      }
      for (let i = 0; i < basicDaArr.length; i++) {
        var ob:any  = new Object
        if (basicDaArr[i]['fin_year'] == startDateFinYear && (basicDaArr[i]['month'] == (startDate.getMonth() + 1)) && basicDaArr[i]['fin_year'] == endDateFinYear && (basicDaArr[i]['month'] == (endDate.getMonth() + 1))) {

          let timeDiff = endDate.getTime() - startDate.getTime();
          let days = timeDiff / (1000 * 60 * 60 * 24)
          ob['fin_year'] = basicDaArr[i]['fin_year']
          ob['month'] = basicDaArr[i]['month']
          ob['pay_component_code'] = 'SUSPENSION ARREAR'
          ob['pay_code'] = basicDaArr[i]['pay_component_code']
          ob['pay_status_code'] = 'ACTIVE'
          ob['emp_id'] = basicDaArr[i]['emp_id']
          ob['pay_component_amt'] = ((((basicDaArr[i]['pay_component_amt'] * days) / 31) * arrearPercent) / 100).toFixed(2)
          variablePayArr.push(ob)
        }
        else if (basicDaArr[i]['fin_year'] == startDateFinYear && (basicDaArr[i]['month'] == (startDate.getMonth() + 1))) {

          let days = 31 - startDate.getDate()
          // ob['fin_year']=basicDaArr[i]['fin_year']
          // ob['month']=basicDaArr[i]['month']
          // ob['pay_component_code']='SUSPENSION ARREAR'
          // ob['pay_code']=basicDaArr[i]['pay_component_code']
          // ob['pay_status_code']='ACTIVE'
          // ob['emp_id']=basicDaArr[i]['emp_id']
          ob['pay_component_amt'] = ((((basicDaArr[i]['pay_component_amt'] * days) / 31) * arrearPercent) / 100)
          variablePayArr.push(ob)
        }
        else if (basicDaArr[i]['fin_year'] == endDateFinYear && (basicDaArr[i]['month'] == (endDate.getMonth() + 1))) {

          let days = endDate.getDate()
          // ob['fin_year']=basicDaArr[i]['fin_year']
          // ob['month']=basicDaArr[i]['month']
          // ob['pay_component_code']='SUSPENSION ARREAR'
          // ob['pay_code']=basicDaArr[i]['pay_component_code']
          // ob['pay_status_code']='ACTIVE'
          // ob['emp_id']=basicDaArr[i]['emp_id']
          ob['pay_component_amt'] = ((((basicDaArr[i]['pay_component_amt'] * days) / 31) * arrearPercent) / 100)
          variablePayArr.push(ob)
        } else {
          // ob['fin_year']=basicDaArr[i]['fin_year']
          // ob['month']=basicDaArr[i]['month']
          // ob['pay_component_code']='SUSPENSION ARREAR'
          // ob['pay_code']=basicDaArr[i]['pay_component_code']
          // ob['pay_status_code']='ACTIVE'
          // ob['emp_id']=basicDaArr[i]['emp_id']
          ob['pay_component_amt'] = ((basicDaArr[i]['pay_component_amt'] * arrearPercent) / 100)
          variablePayArr.push(ob)
        }
      }
      this.amountObj = new Object
      this.amountObj['fin_year'] = endDateFinYear
      this.amountObj['month'] = startDate.getMonth() + 1
      this.amountObj['pay_component_code'] = 'SUSPENSION ARREAR'
      this.amountObj['pay_code'] = 'PAY'
      this.amountObj['pay_status_code'] = 'ACTIVE'
      this.amountObj['emp_id'] = this.suspensionObj['emp_id']
      this.amountObj['pay_component_amt'] = 0

      for (let i = 0; i < variablePayArr.length; i++) {
        this.amountObj['pay_component_amt'] = this.amountObj['pay_component_amt'] + variablePayArr[i]['pay_component_amt']
      }
      this.amountObj['pay_component_amt'] = this.amountObj['pay_component_amt'].toFixed(2)
      //   var ob1=new Object
      //   ob1['b_acct_id']=this.b_acct_id
      //   ob1['emp_id']=this.suspensionObj['emp_id']
      //   ob1['data']= variablePayArr
      //   ob1['create_user_id'] = this.erpUser.user_id;

      //    var resp1 = await this.establishmentService.addarrayVariablePay(ob1);
      //   if (resp1['error'] == false) {

      //   } else {
      //     this.snackBar.open("Error while Adding Arrear of Employee", 'Error', {
      //       duration: 5000
      //     });
      //   }
    }

    if (this.suspensionObj['arrear'] == 'NO') {
      this.amountObj['pay_component_amt'] = 0
    }
    this.spinner.hide()

  }
  // async withdraw(element){

  //   var obj=new Object;
  //   obj['b_acct_id']=this.b_acct_id
  //   obj['update_user_id'] = this.erpUser.user_id;
  //   obj['emp_status_code'] = 'ACTIVE';
  //   obj['employee_current_type_code'] = ''
  //   obj['suspension_status'] = 'WITHDRAW'
  //   obj['id'] = element['id']
  //   obj['emp_id'] = element['emp_id']
  // this.spinner.show()


  //   var resp = await this.establishmentService.suspensionWithdraw(obj);
  //   if (resp['error'] == false) {
  //     await this.getAllSuspended();
  //     this.spinner.hide();
  //     this.snackBar.open("Suspension Withdraw Successfully!", 'Success', {
  //       duration: 5000
  //     });
  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Some error Occured", 'Error', {
  //       duration: 5000
  //     });
  //   }
  //  }


}
