

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { AdministrationService } from '../../service/administration.service';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { VmsHierarchyService } from '../../service/vms-hierarchy.service';
import { VmsCommonService } from '../../service/vms-common.service';
@Component({
  selector: 'app-data-assignment',
  templateUrl: './data-assignment.component.html',
  styleUrls: ['./data-assignment.component.css']
})
export class DataAssignmentComponent implements OnInit {
  ebillUser:any ;
  b_acct_id:any ;
  Obj :any = {}
  ActiveNode:any  = [];
  planing_arr:any  = [{}]
  allRoles:any  = [];
  allComp:any ;
  selectedComp:any  = [];
  dataSource:any ;
  data :any = [{}]
  level1:any  = []
  level2:any  = []
  level3 :any = []
  level4:any  = []
  level5:any  = []
  level6:any  = []
  level7:any  = []
  role_name:any ;
  role_id:any ;
  list_flag = true;
  create_flag = false;
  displayedColumns: string[] = ['node_cd', 'node_desc', 'name', 'mobile', 'status', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(
    private commonS: VmsCommonService, private hierarchyS: VmsHierarchyService,
    private mainServiceMD: mainService_MD, private adminstration_S: AdministrationService, private spinner: NgxSpinnerService) { }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getproject()
    await this.getAllEmployees()
    await this.getList()
    // await this.getAllHeirarchy()
  }
  open_create(){
    this.create_flag = true;
    this.list_flag = false;
    this.refresh();
  }
  open_list(){
    this.list_flag  = true;
    this.create_flag = false;
  }
  refresh() {
    this.data = []
    this.data.push({})
    this.Obj = {}
  }
  projecthier = []
  projhierobj = {}
  projhierobjtype = {}
  projhierobjtypecd = {}
  project_hier = []
  async getproject() {
     let obj:any = new   Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'VMS'
    let resp = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    console.log(this.data)
    for (let i = 0; i < resp.data.length; i++) {
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'VMS-root'

      }
    }
    console.log(this.data)

    this.project_hier = resp['data']
    let arr:any  = []
    let duumy = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level1 = duumy
    console.log(this.project_hier, this.level1, 'klklklklkllllllllllllllllklklklk')
  }
  allEmplyees_new = []
  allEmployees = []
  allSearchableEmp = []
  userObj:any  = {}
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr = []
    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'VMS'
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

  allHier = []
  async add_dropdown(data:any , index:any ) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.Obj)
    }
    console.log(this.Obj)
  }
  leaf_level = 0
  async remove_dropdown(data:any , index:any ) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    await this.set_Path_desc(9)
  }
  async set_Path_desc(index:any ) {
    if (index == 0) {
      this.Obj['lvl1_value'] = this.level1[0]['lvl1_value']
    }
    let dummy = []
    for (let i = 0; i <= 7; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        // this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.leaf_level = i
        this.Obj['node_cd'] = this.Obj['lvl' + i + '_cd']
        dummy.push(this.Obj['lvl' + i + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy.join('-->')
    console.log(this.Obj)
  }


  async getList() {
    this.spinner.show();
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.adminstration_S.getVmsAssigneddata2(JSON.stringify(obj));
    console.log(resp, resp, 'get list')
    if (resp['error'] == false) {
      this.spinner.hide();
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['name'] = this.userObj[resp['data'][i]['user_id']]['emp_name']
        resp['data'][i]['mobile'] = this.userObj[resp['data'][i]['user_id']]['emp_phone_no']
        for (let j = 0; j < this.project_hier.length; j++) {
          if (this.project_hier[j]['leaf_cd'] == resp['data'][i]['node_cd']) {
            let ob:any  = {}
            ob = Object.assign({}, this.project_hier[j])
            let dummy = []
            for (let k = 0; k <= 7; k++) {
              if (ob['lvl' + k + '_cd']) {
                dummy.push(ob['lvl' + k + '_value'])
              }
            }
            resp['data'][i]['desc'] = dummy.join('-->')
          }

        }
      }
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Some Error Occured', 'error');
    }

  }


  async inactive(element:any ) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Inactive it!'
    }).then((result) => {
      if (result.value) {
        this.finalInactive(element)
      }
    })
  }
  async finalInactive(element:any ) {

    this.spinner.show();
    var obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.ebillUser.user_id;
    obj['status'] = 'INACTIVE';
    console.log(obj)
    var resp = await this.adminstration_S.updateDataAssignment(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getList();
      Swal.fire("Success", "... Node InActive", 'success');

    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Some Error Occured', 'error');
    }

  }

  async submit() {
    console.log(this.Obj)
    if (this.data.length < 2) {
      Swal.fire('info..', 'Please Select The Node Atleast Two Level', 'info')
      return
    }
    if (this.Obj['node_cd'] && this.Obj['user_id']) {
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['create_user_id'] = this.ebillUser.user_id;
      this.Obj['status'] = 'ACTIVE';
      console.log(this.Obj)
      var resp = await this.adminstration_S.insertDataAssignment(this.Obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getList();
        this.data = []
        this.Obj = Object.assign({}, {})
        Swal.fire("Success..", "Created Successfully", 'success');
        this.create_flag = false;
        this.list_flag = true;
        // $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      }
      else {
        this.spinner.hide();
        Swal.fire('Error...', 'Error While Create A New Node..', 'error');
      }
    } else {
      Swal.fire('Warning...', 'Please Select All Fields', 'error')
    }
  }


  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
