<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Field Code-Value Manage -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf=" l_code_flag" class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                <!-- All Fields Code-Value List -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a  *ngIf=" c_code_flag" class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                class="fa fa-plus-circle" aria-hidden="true"></i> <!-- Create Field Code-Value -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a  *ngIf=" u_code_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i> <!-- Update Field
                                Code-Value -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE4']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf=" l_code_flag" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Field Code-Value </button>
                            </div>



                            <div class="row">
                                <div class="col-3" style="margin-left:2%">
                                    <h6><!-- Select Field --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE5']}}:</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allFields" bindLabel="field_business_name" (change)="changeField()" bindValue="field_code" [multiple]="false" placeholder="Select Field" [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>

                            <hr>
                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- ID --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <!-- Code --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <!-- Value -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.value }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE9']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" *ngIf="element['code']!='Root' && element['code']!='Work'" (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE10']}}</button>
                                            <button class="btn btn-danger" *ngIf="element['code']!='Root' && element['code']!='Work'" (click)="deleteSwal(element)"><!-- Delete -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE11']}}</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                    
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                               
                            </div>


                        </div>
                        <div *ngIf=" c_code_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Code-Value List</button>
                            </div>

                            <div class="row">
                                <div class="col-3" style="margin-left:2%">
                                    <h6> <!-- Select Field -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE4']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allFields" bindLabel="field_business_name" (change)="changeField()" bindValue="field_code" [multiple]="false" placeholder="Select Field" [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:2%">
                                    <h6> <!-- Field Code  --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE12']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="obj['code']" onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Code" />

                                    <!-- <input type="text" placeholder="Enter Field Code" class="form-control" [(ngModel)]="obj['code']"> -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:2%">
                                    <h6> <!-- Field Value -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE13']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control"  [(ngModel)]="obj['value']"   onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Value" /> -->
                                    <input type="text" placeholder="Enter Field Value" class="form-control" [(ngModel)]="obj['value']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()"><!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE14']}}</button>

                                </div>
                            </div>
                        </div>

                        <div *ngIf=" u_code_flag" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Code-Value List</button>
                            </div>
                            <div class="row">
                                <div class="col-3" style="margin-left:2%">
                                    <h6> <!-- Select Field -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE4']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allFields" bindLabel="field_business_name" (change)="changeField()" bindValue="field_code" [multiple]="false" placeholder="Select Field" [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:2%">
                                    <h6><!-- Field Code -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE12']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="obj['code']" onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Code" />

                                    <!-- <input type="text" placeholder="Enter Field Code" class="form-control" [(ngModel)]="obj['code']"> -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:2%">
                                    <h6> <!-- Field Value  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE13']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control"  [(ngModel)]="obj['value']"   onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Value" /> -->

                                    <input type="text" placeholder="Enter Field Value" class="form-control" [(ngModel)]="obj['value']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-CODEVALUE10']}}</button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>