import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { SettingService } from '../../../service/setting.service'
import { MainService } from '../../../service/main.service';
import { LedgerService } from '../../../service/ledger.service'
import Swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ExcelService } from '../../../service/file-export.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-gst-report',
  templateUrl: './gst-report.component.html',
  styleUrls: ['./gst-report.component.css']
})
export class GstReportComponent implements OnInit {

  constructor(private ChartOfAccountService: ChartOfAccountService, public mainService: MainService, private excl: ExcelService, private ledgerService: LedgerService, private spinner: NgxSpinnerService, private settingService: SettingService) { }
  erpUser:any;
  b_acct_id:any;
  allParty:any = []
  obj :any= {}

  acount_name = ''
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.acount_name = this.mainService.accInfo['account_name']
    await this.getrepresentative();
    await this.getparty();

  }

  reperesentative:any = {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }
  all_party :any= [];
  partyNameObj :any= {};
  partyGISTNObj :any= {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      this.partyNameObj = {};
      this.partyGISTNObj = {};

      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.partyNameObj[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
        this.partyGISTNObj[resp['data'][i]['le_id']] = resp['data'][i]['party_gstin_no']

      }


      this.all_party = [{ le_id: null, party_leagal_name: 'All' }];
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
      this.obj['party_type']
    }
  }
  refresh() {
    document.getElementById('output')!.click();
    document.getElementById('output2')!.click();
    this.reportdata = []
    this.obj = new Object();
  }
  reportdata:any = []

  check_validation() {
    let flag: boolean = true;
    if (!this.obj['from_dt']) {
      Swal.fire("Opps..", "Please Select From Date", 'info');
      this.spinner.hide();
      flag = false;
      return flag;
    }
    if (!this.obj['to_dt']) {
      Swal.fire("Opps..", "Please Select To Date", 'info');
      this.spinner.hide();
      flag = false;
      return flag;
    }
    return flag;
  }

  async getreportoutput() {
    this.obj['b_acct_id'] = this.b_acct_id;
    if (this.obj['from_dt'] > this.obj['to_dt']) {
      return;
    }
    var resp = await this.ledgerService.getGstOutputReport(JSON.stringify(this.obj))
    if (resp['error'] == false) {
      var arr:any = resp['data']
      let ob:any = new Object();
      this.reportdata = []
      for (let i = 0; i < arr.length; i++) {
        if (ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']] == undefined) {
          ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']] = [arr[i]]
        } else {
          ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']].push(arr[i])
        }
      }

      let tt = Object.keys(ob);
      tt.map(x => {


        let gst_line:any = new Object();
        gst_line['gstin_no'] = this.partyGISTNObj[ob[x][0]['cp_num_in_src']]
        gst_line['party_name'] = ob[x][0]['cp_nm']
        gst_line['amount'] = ob[x][0]['ev_grp_amt']
        gst_line['cgst'] = 0
        gst_line['sgst'] = 0
        gst_line['igst'] = 0;

        ob[x].map((y:any)=> {
          if (y['ev_ln_id'] == 'IGSTOUTPUT') {
            gst_line['igst'] = y['ev_ln_amt']
          } else if (y['ev_ln_id'] == 'CGSTOUTPUT') {
            gst_line['cgst'] = y['ev_ln_amt']
          } else if (y['ev_ln_id'] == 'SGSTOUTPUT') {
            gst_line['sgst'] = y['ev_ln_amt']
          }
        })
        this.reportdata.push(gst_line)
      })

    }
  }
  async getGstInputReport() {
    if (this.obj['from_dt'] > this.obj['to_dt']) {
      return;
    }
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getGstInputReport(JSON.stringify(this.obj))
    if (resp['error'] == false) {
      var arr = resp['data']
      let ob:any = new Object();
      this.reportdata = []
      for (let i = 0; i < arr.length; i++) {
        if (ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']] == undefined) {
          ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']] = [arr[i]]
        } else {
          ob[arr[i]['ev_grp_lcl_unq_num_in_src'] + arr[i]['ev_grp_tcd']].push(arr[i])
        }
      }

      let tt = Object.keys(ob);
      tt.map(x => {


        let gst_line:any = new Object();
        gst_line['gstin_no'] = this.partyGISTNObj[ob[x][0]['cp_num_in_src']]
        gst_line['party_name'] = ob[x][0]['cp_nm']
        gst_line['amount'] = ob[x][0]['ev_grp_amt']
        gst_line['cgst'] = 0
        gst_line['sgst'] = 0
        gst_line['igst'] = 0;

        ob[x].map((y:any) => {
          if (y['ev_ln_id'] == 'IGSTINPUT') {
            gst_line['igst'] = y['ev_ln_amt']
          } else if (y['ev_ln_id'] == 'CGSTINPUT') {
            gst_line['cgst'] = y['ev_ln_amt']
          } else if (y['ev_ln_id'] == 'SGSTINPUT') {
            gst_line['sgst'] = y['ev_ln_amt']
          }
        })
        this.reportdata.push(gst_line)
      })
      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire("ERROR", 'Error While Getting Gst Input Report', 'error');
    }
  }

  export() {
    var exp = []
    for (var i = 0; i < this.reportdata.length; i++) {
      var obj:any = new Object();
      obj['SNO'] = i + 1;
      obj['GSTIN'] = this.reportdata[i]['gstin_no']
      obj['Party Name'] = this.reportdata[i]['party_name']
      obj['Taxable Amount'] = this.reportdata[i]['amount']
      obj['CGST'] = this.reportdata[i]['cgst']
      obj['SGST'] = this.reportdata[i]['sgst']
      obj['IGST'] = this.reportdata[i]['igst']

      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'gst_output_report')
  }
  export1() {
    var exp = []
    for (var i = 0; i < this.reportdata.length; i++) {
      var obj:any = new Object();
      obj['SNO'] = i + 1;
      obj['GSTIN'] = this.reportdata[i]['gstin_no']
      obj['Party Name'] = this.reportdata[i]['party_name']
      obj['Taxable Amount'] = this.reportdata[i]['amount']
      obj['CGST'] = this.reportdata[i]['cgst']
      obj['SGST'] = this.reportdata[i]['sgst']
      obj['IGST'] = this.reportdata[i]['igst']

      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'gst_input_report')
  }
  print1() {

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + " GST OUTPUT REPORT";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var header2 =
      [
        {

          text: 'From Date :',
          bold: true,
          colSpan: 2, alignment: 'center'

        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['from_dt']), alignment: 'center'
        },
        {

          text: 'To Date :',
          bold: true, colSpan: 2, alignment: 'center'
        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['to_dt']), colSpan: 2, alignment: 'center'
        }, {}
      ]

    var tbl = {

      fontSize: 10,
      table: {

        headerRows: 2,
        widths: ['*', '*', '*', '*', '*', '*', '*'],


        body: [header2,
          ['SNO', 'GSTIN', 'Party Name',
            { text: 'Taxable Amount', alignment: 'center' },
            { text: 'CGST', alignment: 'center' },
            { text: 'SGST', alignment: 'center' },
            { text: 'IGST', alignment: 'center' }]


        ]
      }
    };
    var cgst = 0
    var sgst = 0
    var igst = 0
    var amount = 0
    dd.content.push(tbl);
    console.table(this.reportdata)

    for (var i = 0; i < this.reportdata.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(this.reportdata[i]['gstin_no']);
      arr.push(this.reportdata[i]['party_name']);
      arr.push({ text: this.reportdata[i]['amount'].toFixed(2), alignment: 'right' });
      arr.push({ text: this.reportdata[i]['cgst'].toFixed(2), alignment: 'right' });
      cgst = cgst + this.reportdata[i]['cgst']
      sgst = sgst + this.reportdata[i]['sgst']
      igst = igst + this.reportdata[i]['igst']
      amount = amount + this.reportdata[i]['amount']
      arr.push({ text: this.reportdata[i]['sgst'].toFixed(2), alignment: 'right' })
      arr.push({ text: this.reportdata[i]['igst'].toFixed(2), alignment: 'right' })




      dd.content[dd.content.length - 1].table.body.push(arr);

    }

    var totalObjRow =
      ['', '', 'Total : Rs.', amount.toFixed(2), cgst.toFixed(2), sgst.toFixed(2), igst.toFixed(2)]


    dd.content[dd.content.length - 1].table.body.push(totalObjRow);




    pdfMake.createPdf(dd).download("GST-Output-Report");
  }
  print() {

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + " GST INPUT REPORT";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var header2 =
      [
        {

          text: 'From Date :',
          bold: true,
          colSpan: 2, alignment: 'center'

        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['from_dt']), alignment: 'center'
        },
        {

          text: 'To Date :',
          bold: true, colSpan: 2, alignment: 'center'
        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['to_dt']), colSpan: 2, alignment: 'center'
        }, {}
      ]

    var tbl = {

      fontSize: 10,
      table: {

        headerRows: 2,
        widths: ['*', '*', '*', '*', '*', '*', '*'],
        body: [header2,
          ['SNO', 'GSTIN', 'Party Name', { text: 'Taxable Amount', alignment: 'center' }, { text: 'CGST', alignment: 'center' }, { text: 'SGST', alignment: 'center' }, { text: 'IGST', alignment: 'center' }]


        ]
      }
    };
    dd.content.push(tbl);
    var cgst = 0
    var sgst = 0
    var igst = 0
    var amount = 0;
    for (var i = 0; i < this.reportdata.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(this.reportdata[i]['gstin_no']);
      arr.push(this.reportdata[i]['party_name']);
      arr.push({ text: this.reportdata[i]['amount'].toFixed(2), alignment: 'right' });
      arr.push({ text: this.reportdata[i]['cgst'].toFixed(2), alignment: 'right' });
      arr.push({ text: this.reportdata[i]['sgst'].toFixed(2), alignment: 'right' })
      arr.push({ text: this.reportdata[i]['igst'].toFixed(2), alignment: 'right' })
      cgst = cgst + this.reportdata[i]['cgst']
      sgst = sgst + this.reportdata[i]['sgst']
      igst = igst + this.reportdata[i]['igst']
      amount = amount + this.reportdata[i]['amount']


      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var totalObjRow =
      ['', '', 'Total : Rs.', amount.toFixed(2), cgst.toFixed(2), sgst.toFixed(2), igst.toFixed(2)]


    dd.content[dd.content.length - 1].table.body.push(totalObjRow);


    pdfMake.createPdf(dd).download("GST-Input-Report");
  }
}
