import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatStepper } from '@angular/material/stepper';
import { MatPaginator  } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../authentication.service';
import Swal from 'sweetalert2';
import { LandInputService } from '../../service/land-input.service';
import { LeaseService } from '../../service/lease.service';
import { MainService } from '../../service/main.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import * as pdfMake from "pdfmake/build/pdfmake";
declare var $: any;
@Component({
  selector: 'app-lease-property',
  templateUrl: './lease-property.component.html',
  styleUrls: ['./lease-property.component.css']
})
export class LeasePropertyComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  leaf_level: any;

  datasource:any;
  displayedColumns = ['plot_no', 'lease_type', 'description', 'bigha', 'biswa', 'biswansi', 'kachwansi', 'in_sqf', 'area', 'action'];
  ebillUser:any
  b_acct_id:any
  isLinear:any = false;
  list_flag:any = true
  create_flag:any = false
  update_flag:any = false
  first:any = true;
  second:any = true;
  third:any = true;
  Obj:any = {}
  data:any = [{}]
  planing_arr:any = [{}]
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  httpUrl:any;

  constructor(private spinner: NgxSpinnerService,
    private leasesrvice: LeaseService,
    private propHierS: PropHierarchyService,
    private commonS: PropCommonSService,
    public auth: AuthenticationService,
    public mainService: MainService,
    private landInput_S: LandInputService,
    private hierarchyS: PropHierarchyService,
    public toastr: ToastrService,
    private sanitizer: DomSanitizer

  ) { }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getpropertygroup()
    await this.open_list()
    await this.getFeatures()
    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  async open_list() {
    this.Obj = {}
    this.data = []
    this.data = [{}]
    this.planing_arr = []
    this.planing_arr = [{}]
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getAllProperty()
  }
  Obj2:any = {}
  async open_update(data:any) {
    if (!this.Obj['property_lease_group_id']) {
      Swal.fire('Error', 'First ** Select Plan Layout', 'error')
    } else {
      console.log(data)

      this.list_flag = false
      this.create_flag = false
      this.Obj = Object.assign({}, data)
      this.Obj2 = Object.assign({}, data)
      console.log(this.Obj)
      console.log(this.Obj2)
      await this.getAllLevls()
      await this.setPreDefinedHierrarchy()
      this.Obj['unit'] = this.Obj2['unit']
      this.Obj['area'] = this.Obj2['area']
      this.Obj['assigned_property_number'] = this.Obj2['assigned_property_number']
      this.Obj['description'] = this.Obj2['description']
      this.Obj['property_lease_group_id'] = this.Obj2['property_lease_group_id']
      this.Obj['property_id'] = this.Obj2['property_id']
      this.Obj['property_type'] = this.Obj2['property_type']
      this.Obj['hier_ident_code2'] = this.Obj2['hier_ident_code2']
      this.Obj['feature_type'] = data['feature_type']
      console.log(data['feature_id'], JSON.parse(data['feature_id']))
      this.Obj['feature_id_arr'] = JSON.parse(data['feature_id'])
      console.log(this.Obj, 'obj')
      // await this.getAllpropertyimagesObj()
      // await this.getViewOfAllImage()
      // this.getVideos()

      await this.getAllFeaturesDataForUpdate()
      $('.nav-tabs a[href="#tab-7-3"]').tab('show')

      this.update_flag = true
    }
  }

  first_list:any = []
  async getAllHier() {
    this.first_list = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, 'list data')
      this.first_list = resp['data']
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  allHier:any = []
  hier_node_obj:any = {}
  async getAllLevls() {
    var obj :any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    console.log(obj)
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)

    } else {
    }
  }
  async getAllLevls2() {
    var obj :any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }
  data_list:any = []
  async setPreDefinedHierrarchy() {
    this.data_list = []
    let data :any= {}
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (this.allPlan_layout[i]['property_lease_group_id'] == this.Obj['property_lease_group_id']) {
        data = Object.assign({}, this.allPlan_layout[i])
        this.Obj['hier_type_id'] = data['hier_type_id']
        await this.getAllLevls()
        await this.getpropertyGroupdata(this.Obj['property_lease_group_id'])
      }
    }
    let ob = this.hier_node_obj[data['prop_hier_id']]
    console.log(data, this.allPlan_layout, this.hier_node_obj, this.allHier, ob)
    let dummy2 = []
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        dummy2.push(ob['lvl' + j + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        this.data_list.push(ob)
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }

      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  existing_heirarchy:any = []
  planaing_hierarchy:any = []
  acquisition_hierarchy:any = []
  async getAllHeirarchy() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.hierarchyS.getHierarchyData(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy = resp['data']
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        this.existing_heirarchy[i]['desc'] = this.existing_heirarchy[i]['hier_ident_code'] + ' - ' + this.existing_heirarchy[i]['hier_buss_name']
      }
      let acq = []
      let planing = []
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        if (this.existing_heirarchy[i]['hier_type'] == "Acquisition") {
          acq.push(this.existing_heirarchy[i])
        }
        if (this.existing_heirarchy[i]['hier_type'] == "Planing") {
          planing.push(this.existing_heirarchy[i])
        }
      }
      this.planaing_hierarchy = planing
      this.acquisition_hierarchy = acq

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  HierarchyWithLocationInfo = []
  async getHierarchyWithLocationInfo() {
    this.spinner.show()
    let obj:any = {}
    // console.log(item)
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code2']
    var resp = await this.hierarchyS.getHierarchyWithLocationInfo(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.HierarchyWithLocationInfo = resp['data']
      // this.features[index]['arr'] = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  existing_heirarchy_nodes = []
  async getAllpropHierarchynodes() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code']
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy:any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  planing_arr2 :any= [{}]
  async getAllpropHierarchynodes2() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code2'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code2']
    obj['status'] = 'ACTIVE'
    //console.log(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy:any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr2[0]['existing_heirarchy_nodes'] = dummy
      await this.getHierarchyWithLocationInfo()
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  allPlan_layout = []
  async getpropertygroup() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "LEASELAYOUT"
    // console.log(obj)
    var resp = await this.leasesrvice.getpropertygroup(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']

      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Lease Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']
      }
      console.log(resp['data'], 'fetched data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  allProp = []
  async getAllProperty() {
    this.allProp = []
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_lease_group_id'] = this.Obj['property_lease_group_id']
    // console.log(obj)
    var resp = await this.leasesrvice.getpropertiesData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == false) {
      this.allProp = resp['data']
      this.datasource = new MatTableDataSource(this.allProp);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  old_table_data = []
  async getpropertyGroupdata(group_id:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_lease_group_id'] = group_id
    obj['status'] = 'ACTIVE'
    console.log(obj)
    var resp = await this.leasesrvice.getpropertyGroupdata(JSON.stringify(obj));
    console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.old_table_data = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  aleratMsg() {
    Swal.fire('Information', "can't change !!!", 'info')
  }

  async getAllFeaturesDataForUpdate() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_id'] = this.Obj['property_id']
    console.log(obj)
    var resp = await this.leasesrvice.getpropertiesWithFeature(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.data = resp['data']
      console.log(resp['data'], 'fetched data')

      for (let i = 0; i < this.data.length; i++) {
        let obj:any = {}
        this.data[i]['feature_desc'] = this.data[i]['feature_desc']
        this.data[i]['feature_code'] = this.data[i]['feature_code']
        this.data[i]['feature_id'] = this.data[i]['feature_id']
        this.setRowValues2(this.data[i], i)
        console.log(this.data[i], this.HierarchyWithLocationInfo, 'data')
      }
      if (this.data.length == 0) {
        this.data = [{}]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  f_arr:any = []
  addRow(data:any, i:any) {
    this.data.push({})
  }
  removeRow(data:any, i:any) {

    console.log(data, i)

     this.f_arr = this.f_arr.filter((element:any) => {
      return element.feature !== data['feature_id'];
    });

    console.log(this.f_arr)



    if (this.data.length > 1) {
      this.data.splice(i, 1)
    }
  }
  setRowValues(data:any, index:any) {
    // console.log(data, index)
    // for(let i = 0; i<this.f_arr.length; i ++){
    //   console.log(this.f_arr[i]['feature_id'])
    //   console.log(data)

    //   if (this.f_arr[i]['feature_id'] == data['feature_id']) {
    //     // console.log(1)
    //     Swal.fire('allready selected', 'select other option', 'warning')

    //     return
    //   }

    // }
    this.f_arr.push(data)
    // console.log(this.f_arr)

        console.log(this.features)


    for (let i = 0; i < this.features.length; i++) {
      if (data['feature_id'] == this.features[i]['feature_id']) {
        data['feature_code'] = this.features[i]['feature_code']
        data['feature_unit'] = this.features[i]['feature_unit']
        data['feature_desc'] = this.features[i]['feature_desc']
        data['feature_type'] = this.features[i]['feature_type']
      }
    }
  }
  setRowValues2(data:any, index:any) {
    console.log(data, index)
    for (let i = 0; i < this.features.length; i++) {
      if (data['feature_id'] == this.features[i]['feature_id']) {
        data['feature_code'] = this.features[i]['feature_code']
        data['feature_unit'] = this.features[i]['feature_unit']
        data['feature_desc'] = this.features[i]['feature_desc']
        data['feature_type'] = this.features[i]['feature_type']
      }
    }
  }

  propObj:any = {}
  feature_list:any = []
  features:any = [{}]
  async getFeatures() {

    this.spinner.show()
    this.feature_list = []
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.Obj['feature_type'] = 'Property'
    console.log(this.Obj)
    var resp = await this.leasesrvice.getFeatures(JSON.stringify(this.Obj));
    if (resp['error'] == false) {
      console.log(resp['data'], 'list')
      this.feature_list=resp['data']
      console.log(this.feature_list)
      this.features = this.feature_list
      // let dup = []
      // for (let i = 0; i < resp['data'].length; i++) {
      //   if (resp['data'][i]['feature_type'] == 'Property') {
      //     let basic=
      //     this.feature_list.push(resp['data'][i])
      //   }
      // }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async print1() {
    this.spinner.show()
    let data:any = []
    data = this.allProp
    var txt:any = this.ebillUser['account_name'] + '(' + this.ebillUser['account_short_name'] + ')'
    var dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'All Properties',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var header1 = {
      columns: [
        {
          width: '*',
          text: '	Architecture Plan:',
          bold: true
        },

        {
          width: '*',
          text: data[0]['leaf_value'],
          bold: true
        },
        {
          width: '*',
          text: ' ',
          bold: true
        },

        {
          width: '*',
          text: ''
        }

      ],
    }

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',

      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ["10%", '*', '*', '*', '10%'],

        body: [
          [{ text: 'Sr No', bold: true }, { text: 'Property type', bold: true }, { text: 'Property Number', bold: true }, { text: 'Area', bold: true }, { text: 'Unit', bold: true }]
       ],
      }
    };

    // console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(data[i]['property_type']);
      arr.push(data[i]['assigned_property_number']);
      arr.push(data[i]['area']);
      // arr.push(data[i]['description']);
      arr.push("Sqm");
      // arr.push(this.mainService.dateFormatChange(data[i].applied_date));
      // arr.push(data[i]['application_amount']);
      // arr.push(data[i]['arr_status_code']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("all-lease-properties");
  }

  setSelectPropertyDetails() {
    for (let i = 0; i < this.old_table_data.length; i++) {
      if (this.Obj['assigned_property_number'] == this.old_table_data[i]['assigned_property_number']) {
        console.log(this.old_table_data[i])
        this.Obj['area'] = this.old_table_data[i]['area']
        this.Obj['unit'] = this.old_table_data[i]['unit']
        this.Obj['property_type'] = this.old_table_data[i]['property_type']
        this.Obj['description'] = this.Obj['assigned_property_number'] + ' - ' + this.old_table_data[i]['property_type']
      } else {
        if (!this.Obj['assigned_property_number']) {
          this.Obj['description'] = null
          this.Obj['area'] = null
          this.Obj['unit'] = null
          this.Obj['property_type'] = null
        }
      }
    }
  }

  async update() {
    if (!this.Obj['property_lease_group_id']) {
      Swal.fire('Error', 'Please Selcet Plan Layout', 'error')
    } else if (!this.Obj['assigned_property_number']) {
      Swal.fire('Error', 'Please Selcet Property Number', 'error')
    }
    else if (!this.Obj['description']) {
      Swal.fire('Error', 'Please Enter Description ', 'error')
    } else {
      console.log(this.Obj, this.data)
      let data_to_send = []
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['b_acct_id'] = this.ebillUser.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      for (let i = 0; i < this.data.length; i++) {
        let obj:any = {}
        obj['feature_id'] = this.data[i]['feature_id']
        obj['feature_code'] = this.data[i]['feature_code']
        obj['feature_type'] = this.data[i]['feature_type']
        obj['feature_desc'] = this.data[i]['feature_desc']
        obj['life_cycle_status'] = 'ACTIVE'
        data_to_send.push(obj)
      }
      this.Obj['data'] = data_to_send
      this.Obj['feature_id_arr'] = JSON.stringify(this.Obj['feature_id_arr'])
      console.log(this.Obj)
      this.spinner.show()
      var resp = await this.leasesrvice.updateproperties(this.Obj)
      console.log(resp)
      if (resp['error'] == false) {
        Swal.fire('Success', 'Updated Successfully', 'success')
        this.spinner.hide()
        this.open_list()
        await this.getAllProperty()
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Updating', 'error')
      }
    }

  }

  delete(e:any){

  }
}
