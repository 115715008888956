import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class ComplainServiceService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async getComplainData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/complain/getComplaintData' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

  async allAssignComplain(obj:any) {
    console.log(obj);
    const resp = await this.http.get(this.httpUrl + '/complain/allAssignComplain' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
       return

  }


  async getUploaddocumentrData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/complain/getUpoadDocument' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getUploaddocumentrimg(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/upload/getComplainPhoto', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async feedback(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/upload/getfeedback_img', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async insertassigncomplain(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/complain/assignComplaindata', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async getinspectionData(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/inspection/getInspectionData' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async insertinspectiondesc(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.post(this.httpUrl + '/inspection/insert_inspection_detail', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getfeedbackdetails(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/complain/getfeedbackdetails' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }
  async getfeedbackdocuments(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/complain/getfeedbackdocument' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getuserinfo(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/complain/userLogin' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getuserinfoByzone(obj:any) {
    console.log(obj, "from service")
    const resp = await this.http.get(this.httpUrl + '/complain/getuserbyzone' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }





  async getinspectionUploaddocumentrData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/inspection/getinspectionDocument' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getinspectionDescription(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/inspection/get_inspection_description' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async getInspectionData(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + "/inspection/getAllInspectionData", obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
}

