import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
declare var $: any
import swal from 'sweetalert2';
import { MainService } from '../../service/main.service';


@Component({
  selector: 'app-cca-matrix',
  templateUrl: './cca-matrix.component.html',
  styleUrls: ['./cca-matrix.component.css']
})
export class CcaMatrixComponent implements OnInit {


  constructor(public mainService:MainService,private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any ;
  b_acct_id:any ;
  errorMsg :any = ''

  allMatrix:any  = [];
  ccaMatrix :any = {};


  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }

  displayedColumns:any  = ['id', 'city_cd','lower_limit','upper_limit','effective_dt','amount', 'action'];
  datasource:any ;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllCCAMatrix();
  }

  open_update(element:any ) {
    this.errorMsg = ''
    this.ccaMatrix = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.ccaMatrix = {};
    this.errorMsg = ''
  }

  async getAllCCAMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';

    var resp = await this.settingService.getCCAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allMatrix = resp.data;
      this.datasource = new MatTableDataSource(this.allMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  All CCA Matrix ', 'error')
      // this.snackBar.open("Error while getting  All CCA Matrix", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async save() {
    this.errorMsg = '';
    console.log(this.ccaMatrix)
    if (
      this.ccaMatrix['city_cd'] == "" || this.ccaMatrix['city_cd'] == undefined
      || this.ccaMatrix['lower_limit'] == "" || this.ccaMatrix['lower_limit'] == undefined
      || this.ccaMatrix['upper_limit'] == "" || this.ccaMatrix['upper_limit'] == undefined
      || this.ccaMatrix['effective_dt'] == "" || this.ccaMatrix['effective_dt'] == undefined
      || this.ccaMatrix['amount'] == "" || this.ccaMatrix['amount'] == undefined
    ) {
      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  async finalsave() {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    this.ccaMatrix['create_user_id'] = this.erpUser.user_id;
    this.ccaMatrix['b_acct_id'] = this.b_acct_id;
    obj['matrix_data'] = [this.ccaMatrix];
    var resp = await this.settingService.addCCAMatrix(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCCAMatrix();
      swal.fire("Success", "Matrix Added Successfully!",'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Adding Matrix!",'error');

    }
  }

async update(){
  this.errorMsg = ''
  if (
    this.ccaMatrix['city_cd'] == "" || this.ccaMatrix['city_cd'] == undefined
    || this.ccaMatrix['lower_limit'] == "" || this.ccaMatrix['lower_limit'] == undefined
    || this.ccaMatrix['upper_limit'] == "" || this.ccaMatrix['upper_limit'] == undefined
    || this.ccaMatrix['effective_dt'] == "" || this.ccaMatrix['effective_dt'] == undefined
    || this.ccaMatrix['amount'] == "" || this.ccaMatrix['amount'] == undefined

  ) {

    this.errorMsg = "* fields required."
  }

  else {

    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.value) {
        this.finalupdate()
      }
    })


  }
}


  async finalupdate() {
    this.spinner.show();
    this.ccaMatrix['update_user_id'] = this.erpUser.user_id;
    this.ccaMatrix['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateCCAMatrix(this.ccaMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCCAMatrix();
      // this.snackBar.open("Matrix Update Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Update Successfully!",'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating Matrix!",'error');

    }
  }

  async delete(element:any ) {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['id']=element.id;
    obj['b_acct_id']=this.b_acct_id;
    console.log(obj)
    var resp = await this.settingService.deleteCCAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCCAMatrix();
      swal.fire("Success", "Matrix Delete Successfully!",'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Deleting Matrix!",'error');
    }
  }



  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
