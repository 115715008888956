import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { HierarchyService } from '../../service/hierarchy.service';
import { MainService } from '../../service/main.service';

import Swal from 'sweetalert2';

import * as  pdfMake from  "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts"
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any
@Component({
  selector: 'app-deductiion-mapping',
  templateUrl: './deductiion-mapping.component.html',
  styleUrls: ['./deductiion-mapping.component.css']
})

export class DeductiionMappingComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;


  list = ['Sr_n', 'ded_code', 'ded_type', 'amount', 'ded_date', 'gov_rule', 'act_cd', 'bud_cd', 'action'];
  list2 = ['Sr_n', 'ded_code', 'ded_date', 'gov_rule', 'act_cd', 'bud_cd', 'action'];
  ded_type = [{ code: 'fixed', value: 'FIXED' }, { code: 'percentage', value: 'PERCENTAGE' }]

  datasource:any;
  datasource1:any;
  constructor(public mainService: MainService, private hierarchyService: HierarchyService,
    private router: Router, private spinner: NgxSpinnerService, private settingService: SettingService) { }

  Obj:any = {}
  erpUser:any;
  b_acct_id:any;
  User_ded:any = []
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllBudget();
    await this.getAllActivity();

    // await this.getAllProject();
    // await this.getAllProduct();

    await this.getList();
    await this.getSystemList();

  }


  async submit() {
    if (!this.budgetObj[this.Obj['bud_cd']] || !this.activityObj[this.Obj['act_cd']]) return;
    this.spinner.show()
    let obj:any = {}
    obj = this.Obj
    obj['b_acct_id'] = this.b_acct_id
    obj['create_user_id'] = this.erpUser.user_id
    var resp = await this.settingService.createDeductionMapping(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getList()
      Swal.fire('Successfully', resp.data, 'success')
      document.getElementById('subreset')!.click();
    }
    else {
      this.spinner.hide()
      Swal.fire('ERROR', resp.data, 'error')
    }
  }
  async getList() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getDeductionMappingList(this.b_acct_id);
    if (resp['error'] == false) {
      let allded:any = []
      resp['data'].map((x:any) => {
        if (x['ded_owner'] != 'SYSTEM') {
          allded.push(x);
        }
      })
      this.datasource = new MatTableDataSource(allded)
      this.datasource.paginator = this.paginator1;
      this.datasource.sort = this.sortCol1;
      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire("Error", "...Error while getting list!", 'error');


    }
  }

  async getSystemList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getSystemDeductionMappingList(this.b_acct_id);
    if (resp['error'] == false) {
      this.datasource1 = new MatTableDataSource(resp['data'])
      this.datasource1.paginator = this.paginator2;
      this.datasource1.sort = this.sortCol2;
      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire("Error", "...Error while getting User list!", 'error');


    }
  }

  refresh() {
    this.Obj = {}
    this.getList();
    this.getSystemList();

  }
  async open_update(e:any) {
    this.hier_fg = false
    this.refresh();
    this.Obj = e
    $('.nav-tabs a[href="#tab-5"]').tab('show')
  }
  async open_update_sys(e:any) {
    this.refresh();
    this.Obj = e
    $('.nav-tabs a[href="#tab-4"]').tab('show')
  }
  hier_fg = false;
  async update(tab:any) {
    this.spinner.show()
    let obj:any = {}
    obj = this.Obj
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.erpUser.user_id
    if (!this.activityObj[this.Obj['act_cd']] || !this.budgetObj[this.Obj['bud_cd']]) this.hier_fg = true;
    var resp = await this.settingService.updateDeductionMapping(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.getList();
      this.getSystemList();
      Swal.fire('Success','Successfully Updated', 'success')
      if(tab == 'tab_1'){
        $('.nav-tabs a[href="#tab-1"]').tab('show')
      }else{
        $('.nav-tabs a[href="#tab-2"]').tab('show')

      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Some Error Occured While Updating', '', 'error')
    }
  }
  async delete(e:any) {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = e['id']
    var resp = await this.settingService.deleteDeductionMappingRow(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getList()
      Swal.fire('Successfully Deleted', '', 'success')
      $('.nav-tabs a[href="#tab-1"]').tab('show')
    }
    else {
      this.spinner.hide()
      Swal.fire('Some Error Occured While Deleting', '', 'error')
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }

  //***************************************** */
  //******************************************************************************************************************* */
  budgetObj:any = {}
  hierobj :any= { budget: {}, activity: {}, product: {}, project: {} }
  allBudgetHier:any
  async getAllBudget() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'bud_hier';
    var resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allBudgetHier = resp.data;

      for (let i = 0; i < this.allBudgetHier.length; i++) {
        console.log(this.allBudgetHier[i])
        let str = ""
        for (let j = 1; j < 8; j++) {
          if (this.allBudgetHier[i]['lvl' + j + "_cd"] != null && this.allBudgetHier[i]['lvl' + j + "_cd"] != "") {
            str += this.allBudgetHier[i]['lvl' + j + "_value"] + " > "

          }
        }

        str = str.substring(0, str.length - 2)
        this.allBudgetHier[i]['desc'] = str;
        this.budgetObj[this.allBudgetHier[i]['leaf_cd']] = str
        this.hierobj['budget'][this.allBudgetHier[i]['leaf_cd']] = this.allBudgetHier[i]

      }
    } else {
      this.spinner.hide()

    }
  }

  allProjectHier:any
  projectObj:any = {}
  async getAllProject() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'proj_hier';
    var resp = await this.hierarchyService.getProjectHierarchy(obj);
    if (resp['error'] == false) {
      this.allProjectHier = resp.data;
      for (let i = 0; i < this.allProjectHier.length; i++) {
        console.log(this.allProjectHier[i])
        let str = ""
        for (let j = 1; j < 8; j++) {
          if (this.allProjectHier[i]['lvl' + j + "_cd"] != null && this.allProjectHier[i]['lvl' + j + "_cd"] != "") {
            str += this.allProjectHier[i]['lvl' + j + "_value"] + " > "

          }
        }

        str = str.substring(0, str.length - 2)
        this.allProjectHier[i]['desc'] = str;
        this.projectObj[this.allProjectHier[i]['leaf_cd']] = str
        this.hierobj['project'][this.allProjectHier[i]['leaf_cd']] = this.allProjectHier[i]

      }
    } else {
    }
  }
  allActivityHier:any
  activityObj:any = {}
  async getAllActivity() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'activity_hier';
    var resp = await this.hierarchyService.getActivityHierarchy(obj);
    if (resp['error'] == false) {
      this.allActivityHier = resp.data;
      for (let i = 0; i < this.allActivityHier.length; i++) {
        console.log(this.allActivityHier[i])
        let str = ""
        for (let j = 1; j < 8; j++) {
          if (this.allActivityHier[i]['lvl' + j + "_cd"] != null && this.allActivityHier[i]['lvl' + j + "_cd"] != "") {
            str += this.allActivityHier[i]['lvl' + j + "_value"] + " > "

          }
        }

        str = str.substring(0, str.length - 2)
        this.allActivityHier[i]['desc'] = str;
        this.activityObj[this.allActivityHier[i]['leaf_cd']] = str
        this.hierobj['activity'][this.allActivityHier[i]['leaf_cd']] = this.allActivityHier[i]

      }
    } else {
    }
  }
  allProductHier:any
  productObj:any = {}
  async getAllProduct() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'prod_hier';
    var resp = await this.hierarchyService.getProductHierarchy(obj);
    if (resp['error'] == false) {
      this.allProductHier = resp.data;
      for (let i = 0; i < this.allProductHier.length; i++) {
        console.log(this.allProductHier[i])
        let str = ""
        for (let j = 1; j < 8; j++) {
          if (this.allProductHier[i]['lvl' + j + "_cd"] != null && this.allProductHier[i]['lvl' + j + "_cd"] != "") {
            str += this.allProductHier[i]['lvl' + j + "_value"] + " > "

          }
        }

        str = str.substring(0, str.length - 2)
        this.allProductHier[i]['desc'] = str;
        this.productObj[this.allProductHier[i]['leaf_cd']] = str
        this.hierobj['product'][this.allProductHier[i]['leaf_cd']] = this.allProductHier[i]

      }
    } else {
    }
  }


  level1:any = [];
  level2:any = [];
  level3:any = [];
  level4:any = [];
  level5:any = [];
  level6:any = [];
  level7:any = [];
  Hier :any= [];
  hier_type:any;
  HierSelectObj :any= {}
  selectObj:any = {}
  HierSelectobj:any

  async Select(type:any) {
    $('#select').modal('show');
    this.hier_type = type;
    let ob = {}

    if (type == 'budget') {
      this.Hier = this.allBudgetHier;
      if (this.selectObj['bud_cd'] != null && this.selectObj['bud_cd'] != '') {

        ob = this.hierobj['budget'][this.selectObj['bud_cd']]

        console.log(ob)

      }
    } else if (type == 'activity') {
      this.Hier = this.allActivityHier;
      if (this.selectObj['act_cd'] != null && this.selectObj['act_cd'] != '') {

        ob = this.hierobj['activity'][this.selectObj['act_cd']]

        console.log(ob)

      }
    }
    this.HierSelectObj = {}
    console.log(this.HierSelectObj)
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob)
      await this.onChangeLvl1()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl2()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl3()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl4()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl5()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl6()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl7()


    }
  }

  async selectCreate(type:any) {
    $('#selectCreate').modal('show');
    this.hier_type = type;
    let ob = {}
    if (type == 'budget') {
      this.Hier = this.allBudgetHier;
      if (this.Obj['bud_cd'] != null && this.Obj['bud_cd'] != '') {

        ob = this.hierobj['budget'][this.Obj['bud_cd']]



      }
    } else if (type == 'activity') {
      this.Hier = this.allActivityHier;
      if (this.Obj['act_cd'] != null && this.Obj['act_cd'] != '') {
        ob = this.hierobj['activity'][this.Obj['act_cd']]
      }

    }
    this.HierSelectObj = {}
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob)
      await this.onChangeLvl1()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl2()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl3()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl4()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl5()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl6()
      this.HierSelectObj = Object.assign({}, ob)

      await this.onChangeLvl7()


    }

  }
  async getLevel1() {
    this.level1 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl1_cd'] == this.Hier[i]['leaf_cd']) {
        let ob:any = new Object();
        ob['lvl1_cd'] = this.Hier[i]['lvl1_cd']
        ob['lvl1_value'] = this.Hier[i]['lvl1_value']
        ob['is_leaf'] = this.Hier[i]['is_leaf']

        this.level1.push(ob)
      }
    }

    this.level2 = []
    this.level3 = []
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []
    console.log(this.level1)
  }

  async onChangeLvl1() {
    if (this.HierSelectObj['lvl1_cd'] == '' || this.HierSelectObj['lvl1_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]['lvl1_cd'] == this.HierSelectObj['lvl1_cd']) {
        this.HierSelectObj['lvl1_value'] = this.level1[i]['lvl1_value']
        if (this.level1[i]['is_leaf'] == 1) {
          console.log(this.level1[i])
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level2 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl1_cd'] == this.HierSelectObj['lvl1_cd'] && this.Hier[i]['lvl2_cd'] != null) {
        if (this.Hier[i]['lvl2_cd'] == this.Hier[i]['leaf_cd']) {
          let ob:any = new Object();
          ob['lvl2_cd'] = this.Hier[i]['lvl2_cd']
          ob['lvl2_value'] = this.Hier[i]['lvl2_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level2.push(ob)
        }
      }

    }
    this.level3 = []
    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 2; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null
    }


  }
  async onChangeLvl2() {
    if (this.HierSelectObj['lvl2_cd'] == '' || this.HierSelectObj['lvl2_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]['lvl2_cd'] == this.HierSelectObj['lvl2_cd']) {
        this.HierSelectObj['lvl2_value'] = this.level2[i]['lvl2_value']
        if (this.level2[i]['is_leaf'] == 1) {
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level3 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl2_cd'] == this.HierSelectObj['lvl2_cd'] && this.Hier[i]['lvl3_cd'] != null) {
        if (this.Hier[i]['lvl3_cd'] == this.Hier[i]['leaf_cd']) {

          let ob :any= new Object()
          ob['lvl3_cd'] = this.Hier[i]['lvl3_cd']
          ob['lvl3_value'] = this.Hier[i]['lvl3_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level3.push(ob)
        }
      }
    }

    this.level4 = []
    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 3; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null

    }



  }

  async onChangeLvl3() {
    if (this.HierSelectObj['lvl3_cd'] == '' || this.HierSelectObj['lvl3_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]['lvl3_cd'] == this.HierSelectObj['lvl3_cd']) {
        this.HierSelectObj['lvl3_value'] = this.level3[i]['lvl3_value']
        if (this.level3[i]['is_leaf'] == 1) {
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level4 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl3_cd'] == this.HierSelectObj['lvl3_cd'] && this.Hier[i]['lvl4_cd'] != null) {
        if (this.Hier[i]['lvl4_cd'] == this.Hier[i]['leaf_cd']) {

          let ob:any = new Object()
          ob['lvl4_cd'] = this.Hier[i]['lvl4_cd']
          ob['lvl4_value'] = this.Hier[i]['lvl4_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level4.push(ob)
        }
      }

    }

    this.level5 = []
    this.level6 = []
    this.level7 = []

    for (let i = 4; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null

    }


  }

  async onChangeLvl4() {
    if (this.HierSelectObj['lvl4_cd'] == '' || this.HierSelectObj['lvl4_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]['lvl4_cd'] == this.HierSelectObj['lvl4_cd']) {
        this.HierSelectObj['lvl4_value'] = this.level4[i]['lvl4_value']
        if (this.level4[i]['is_leaf'] == 1) {
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level5 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl4_cd'] == this.HierSelectObj['lvl4_cd'] && this.Hier[i]['lvl5_cd'] != null) {
        if (this.Hier[i]['lvl5_cd'] == this.Hier[i]['leaf_cd']) {

          let ob :any= new Object()
          ob['lvl5_cd'] = this.Hier[i]['lvl5_cd']
          ob['lvl5_value'] = this.Hier[i]['lvl5_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level5.push(ob)
        }
      }

    }
    this.level6 = []
    this.level7 = []

    for (let i = 5; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null

    }


  }

  async onChangeLvl5() {
    if (this.HierSelectObj['lvl5_cd'] == '' || this.HierSelectObj['lvl5_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]['lvl5_cd'] == this.HierSelectObj['lvl5_cd']) {
        this.HierSelectObj['lvl5_value'] = this.level5[i]['lvl5_value']
        if (this.level5[i]['is_leaf'] == 1) {
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level6 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl5_cd'] == this.HierSelectObj['lvl5_cd'] && this.Hier[i]['lvl6_cd'] != null) {
        if (this.Hier[i]['lvl6_cd'] == this.Hier[i]['leaf_cd']) {

          let ob:any = new Object()
          ob['lvl6_cd'] = this.Hier[i]['lvl6_cd']
          ob['lvl6_value'] = this.Hier[i]['lvl6_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level6.push(ob)
        }
      }

    }
    this.level7 = []

    for (let i = 6; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null

    }




  }

  async onChangeLvl6() {
    if (this.HierSelectObj['lvl7_cd'] == '' || this.HierSelectObj['lvl7_cd'] == null) {
      this.HierSelectObj['leaf_cd'] = ""
      this.HierSelectObj['leaf_value'] = ""
    }
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]['lvl6_cd'] == this.HierSelectObj['lvl6_cd']) {
        this.HierSelectObj['lvl6_value'] = this.level6[i]['lvl6_value']
        if (this.level6[i]['is_leaf'] == 1) {
          await this.makingLeafValues()
        } else {
          this.HierSelectObj['leaf_cd'] = ""
          this.HierSelectObj['leaf_value'] = ""
        }
      }
    }
    let temp = []
    this.level7 = []
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]['lvl6_cd'] == this.HierSelectObj['lvl6_cd'] && this.Hier[i]['lvl7_cd'] != null) {
        if (this.Hier[i]['lvl7_cd'] == this.Hier[i]['leaf_cd']) {

          let ob:any = new Object()
          ob['lvl7_cd'] = this.Hier[i]['lvl7_cd']
          ob['lvl7_value'] = this.Hier[i]['lvl7_value']
          ob['is_leaf'] = this.Hier[i]['is_leaf']

          this.level7.push(ob)
        }
      }

    }

    for (let i = 7; i < 8; i++) {
      this.HierSelectObj['lvl' + i + '_cd'] = null
      this.HierSelectObj['lvl' + i + '_value'] = null

    }


  }

  async onChangeLvl7() {

    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]['lvl7_cd'] == this.HierSelectObj['lvl7_cd']) {
        this.HierSelectObj['lvl7_value'] = this.level7[i]['lvl7_value']
      }
    }

    await this.makingLeafValues()



  }

  async makingLeafValues() {

    if (this.HierSelectObj['lvl7_cd'] != undefined && this.HierSelectObj['lvl7_cd'] != '' && this.HierSelectObj['lvl7_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl7_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl7_value']
    } else if (this.HierSelectObj['lvl6_cd'] != undefined && this.HierSelectObj['lvl6_cd'] != '' && this.HierSelectObj['lvl6_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl6_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl6_value']
    } else if (this.HierSelectObj['lvl5_cd'] != undefined && this.HierSelectObj['lvl5_cd'] != '' && this.HierSelectObj['lvl5_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl5_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl5_value']
    } else if (this.HierSelectObj['lvl4_cd'] != undefined && this.HierSelectObj['lvl4_cd'] != '' && this.HierSelectObj['lvl4_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl4_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl4_value']
    } else if (this.HierSelectObj['lvl3_cd'] != undefined && this.HierSelectObj['lvl3_cd'] != '' && this.HierSelectObj['lvl3_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl3_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl3_value']
    } else if (this.HierSelectObj['lvl2_cd'] != undefined && this.HierSelectObj['lvl2_cd'] != '' && this.HierSelectObj['lvl2_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl2_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl2_value']
    } else if (this.HierSelectObj['lvl1_cd'] != undefined && this.HierSelectObj['lvl1_cd'] != '' && this.HierSelectObj['lvl1_cd'] != null) {
      this.HierSelectObj['leaf_cd'] = this.HierSelectObj['lvl1_cd']
      this.HierSelectObj['leaf_value'] = this.HierSelectObj['lvl1_value']
    }

  }
  async SubmitSelectedListHier() {
    console.log(this.HierSelectObj)
    console.log(this.hier_type)
    let levelnum = 0
    for (let i = 1; i < 8; i++) {
      if (this.HierSelectObj['lvl' + i + "_cd"] != null && this.HierSelectObj['lvl' + i + "_cd"] != '') {
        levelnum = i
      }
    }
    if (this.hier_type == 'budget') {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (this.allBudgetHier[i]['leaf_cd'] == this.HierSelectObj['lvl' + levelnum + '_cd']) {
          this.selectObj['bud_desc'] = this.allBudgetHier[i]['desc'];
          this.selectObj['bud_cd'] = this.allBudgetHier[i]['leaf_cd'];
          this.selectObj['bud_lvl'] = this.allBudgetHier[i]['level'];
          this.selectObj['bud_obj'] = this.allBudgetHier[i]
        }
      }
    } else if (this.hier_type == 'activity') {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (this.allActivityHier[i]['leaf_cd'] == this.HierSelectObj['lvl' + levelnum + '_cd']) {
          this.selectObj['act_desc'] = this.allActivityHier[i]['desc'];
          this.selectObj['act_cd'] = this.allActivityHier[i]['leaf_cd'];
          this.selectObj['act_lvl'] = this.allActivityHier[i]['level'];
          this.selectObj['act_obj'] = this.allActivityHier[i]

        }
      }
    } else if (this.hier_type == 'project') {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (this.allProjectHier[i]['leaf_cd'] == this.HierSelectObj['lvl' + levelnum + '_cd']) {
          this.selectObj['proj_desc'] = this.allProjectHier[i]['desc'];
          this.selectObj['proj_cd'] = this.allProjectHier[i]['leaf_cd'];
          this.selectObj['proj_lvl'] = this.allProjectHier[i]['level'];
          this.selectObj['proj_obj'] = this.allProjectHier[i]

        }
      }
    } else if (this.hier_type == 'product') {
      for (let i = 0; i < this.allProductHier.length; i++) {
        if (this.allProductHier[i]['leaf_cd'] == this.HierSelectObj['lvl' + levelnum + '_cd']) {
          this.selectObj['prod_cd'] = this.allProductHier[i]['leaf_cd'];
          this.selectObj['prod_desc'] = this.allProductHier[i]['desc'];
          this.selectObj['prod_lvl'] = this.allProductHier[i]['level'];
          this.selectObj['prod_obj'] = this.allProductHier[i]

        }
      }
    }

    $('#select').modal('hide');


  }
  leaf_msg = false;
  SubmitListHierforCreate() {
    if (this.HierSelectObj['leaf_cd'] == "" || this.HierSelectObj['leaf_cd'] == null) {
      // Swal.fire("Error", "Please select till leaf.")
      this.leaf_msg = true;
      return;
    }
    if (this.hier_type == 'budget') {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (this.allBudgetHier[i]['leaf_cd'] == this.HierSelectObj['leaf_cd']) {
          this.Obj['bud_desc'] = this.allBudgetHier[i]['desc'];
          this.Obj['bud_cd'] = this.allBudgetHier[i]['leaf_cd'];
          this.Obj['bud_lvl'] = this.allBudgetHier[i]['level'];
          this.Obj['bud_obj'] = this.allBudgetHier[i]
        }
      }
      console.log(this.Obj)
    } else if (this.hier_type == 'activity') {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (this.allActivityHier[i]['leaf_cd'] == this.HierSelectObj['leaf_cd']) {
          this.Obj['act_desc'] = this.allActivityHier[i]['desc'];
          this.Obj['act_cd'] = this.allActivityHier[i]['leaf_cd'];
          this.Obj['act_lvl'] = this.allActivityHier[i]['level'];
          this.Obj['act_obj'] = this.allActivityHier[i]

        }
      }
    }
    document.getElementById('resetbtn')!.click();
    $('#selectCreate').modal('hide');


  }

}
