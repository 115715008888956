
import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../md/service/main.service';

@Component({
  selector: 'app-tracking-workflow',
  templateUrl: './tracking-workflow.component.html',
  styleUrls: ['./tracking-workflow.component.css']
})
export class TrackingWorkflowComponent implements OnInit {

  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='TRACKING'
  }

}


