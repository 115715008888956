
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingService } from '../../service/setting.service';

declare var $: any;
@Component({
  selector: 'app-icms-section',
  templateUrl: './icms-section.component.html',
  styleUrls: ['./icms-section.component.css']
})
export class IcmsSectionComponent implements OnInit {

  displayedColumns: string[] = ['id','notice_act_cd', 'section_cd', 'action'];
  users:any = [];
  datasource:any;
  user_id:any;
  Obj :any= {};
  b_acct_id:any;
  ebillUser:any;

  constructor(private spinner: NgxSpinnerService, public toastr: ToastrService, private sanitizer: DomSanitizer, private settingService: SettingService) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    await this.getSectionMap()
    await this.getCodeValue_obj()
    // await this.getcurrentroleswithresources()
  }
  list_flag = true
  create_flag = false
  open_create() {

    this.list_flag = false
    this.create_flag = true

  }
  async open_list() {
    this.list_flag = true
    this.create_flag = false
    await this.getSectionMap()
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  refresh() {
    this.Obj = {}
    this.data = []
  }



  codeValueTechObj:any = {}
  codeValueObj :any= {}
  codeValueShowObj:any = {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj :any= {}
    var codeValueShowTempObj :any= {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.codeValueTechObj = this.codeValueObj;
      console.log(this.codeValueTechObj, 'uuuu')
      this.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }


  async getSectionMap() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id

    var resp = await this.settingService.getSectionMap(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      // this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];
    }

  }



  data = []

  async submit() {
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    this.spinner.show()
    var resp = await this.settingService.sectionMap(this.Obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      Swal.fire('info', resp['data'], 'info')
      await this.open_list()
      await this.refresh()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Creating', 'error')
    }
  }

  async delete(element:any) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any) {
    let obj :any= {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.settingService.deleteSectionMap(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getSectionMap()
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }

}

