import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
declare var $: any
import swal from 'sweetalert2';
import { EstablishService } from '../../service/establish.service';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-welfare-fund',
  templateUrl: './welfare-fund.component.html',
  styleUrls: ['./welfare-fund.component.css']
})
export class WelfareFundComponent implements OnInit {

  constructor(public mainService: MainService, private estNewService: EstablishService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  errorMsg :any= ''
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  displayedColumns = ['id', 'class_code', 'wf_pay', 'action'];
  datasource:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllwfMatrix()
  }
  refresh() {

  }
  wfMatrix:any = {}
  list_flag:any = true;
  create_flag :any= false;
  update_flag :any= false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.wfMatrix = {}
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }
  async save() {
    this.errorMsg = ''
    // console.log(' this is the selected obj --',this.wfMatrix['pay_commission'])
    if (

      this.wfMatrix['class_code'] == "" || this.wfMatrix['class_code'] == undefined

      || this.wfMatrix['wf_pay'] == "" || this.wfMatrix['wf_pay'] == undefined

    ) {

      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you Sure?',
        text: 'Do You Want To Add',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  async finalsave() {
    this.wfMatrix["b_acct_id"] = this.b_acct_id
    this.wfMatrix["user_id"] = this.erpUser.user_id;
    var resp = await this.settingService.addwfMatrix(this.wfMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      // await this.getAllMatrix();
      // this.snackBar.open("Matrix Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Added Successfully!", 'success');
      this.wfMatrix = {}
      await this.getAllwfMatrix()
      this.spinner.hide();
      this.open_list()

    } else {
      if (resp['data'] = "Dublicate Matrix values") {
        swal.fire("Sorry", "Dublicate  Matrix Value Added", 'error');
        this.spinner.hide();
        this.wfMatrix = {}
      }
      else {
        this.spinner.hide();

        swal.fire("Sorry", "..Error while Adding Matrix!", 'error');

      }

    }
  }

  allwfMatrix :any= []
  async getAllwfMatrix() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getwfMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allwfMatrix = resp.data;
      this.datasource = new MatTableDataSource(this.allwfMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }

  async delete(element:any) {
    var obj:any = Object.assign({}, element)
    swal.fire({
      title: 'Are you Sure?',
      // text: 'Do You Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(obj)
      }
    })
  }
  async finaldelete(obj1:any) {
    console.log(obj1, "jj")
    var obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    obj["wf_matrix_id"] = obj1['wf_matrix_id']
    console.log(obj)
    var resp = await this.settingService.deletewfMatrix(obj);
    if (resp['error'] == false) {


      this.spinner.hide();
      swal.fire('Success', 'Successfully Deleted', 'success')
      await this.getAllwfMatrix()
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }

  async open_update(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.wfMatrix = Object.assign({}, element);
    console.log(this.wfMatrix)
    $('.nav-tabs a[href="#tab-3"]').tab('show')

    // await this.changeGradePay1(this.pbrMatrix['pay_band'])
    // await this.changeGradePay1(this.pbrMatrix['grade_pay_code'])
  }
  async update() {

    this.spinner.show();
    this.wfMatrix['user_id'] = this.erpUser.user_id;
    this.wfMatrix['b_acct_id'] = this.b_acct_id;
    console.log(this.wfMatrix)
    var resp = await this.settingService.updatewfMatrix(this.wfMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllwfMatrix();
      // this.snackBar.open("Matrix Update Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Update Successfully!", 'success');
      this.open_list()

    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating Matrix!", 'error');

    }

  }
}
