import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { PaymentService } from '../service/payment.service';
import { TenderService } from '../service/tender.service';

@Component({
  selector: 'app-emd-refund',
  templateUrl: './emd-refund.component.html',
  styleUrls: ['./emd-refund.component.css']
})
export class EmdRefundComponent implements OnInit {
  erpUser:any
  b_acct_id:any
  user_id:any

  constructor(private paymentService: PaymentService, private spinner: NgxSpinnerService) { }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any))
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getApprovedTender();
  }

  displayedColumns = ['Sr.No', 'txn_id', 'pay_for', 'amt', 'pay_mode', 'pay_date', 'bank_ref_no', 'challan_no', 'pay_status', 'action']
  displayedColumns2 = ['#', 'Vendor Id', 'Vendor Name', 'Payment Type', 'EMD Amount', 'Bank Name', 'IFSC', 'Acc.No', 'Assigned Status', 'Action']
  dataSource: any = []

  selectedObj :any= new Object()
  list: boolean = true;
  details: boolean = false;
  refund_flag: boolean = false;
  refund_tab: boolean = false;
  backtoList() {
    this.dataSource = []


    if (this.refund_tab) {
      this.list = false;
      this.details = false;
      this.refund_flag = true;
    } else {
      this.listOfRefund = []
      this.selectedObj = new Object()
      this.list = true;
      this.details = false;
      this.refund_flag = false;
    }
    this.refund_tab = false
  }

  openRefund() {
    this.selectedObj = new Object()
    this.list = false;
    this.details = false;
    this.refund_flag = true;
    this.dataSource = []
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  tender_data = []
  async getApprovedTender() {
    let  obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp:any = await this.paymentService.getApprovedTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.tender_data = resp['data'];
      this.tender_data.map((x:any) => {
        x['desc'] = x['nic_tender_id'] + ' - ' + x['work_name']
      })
    } else {
      Swal.fire('ERROR', 'Error While Getting Tender Information', 'error');
    }
  }



  async listOfRefundedVendor() {
    this.spinner.show()
    let  obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = this.selectedObj['tender_id'];
    let resp:any = await this.paymentService.listOfRefundedVendor(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp['data'])
      this.dataSource = new MatTableDataSource(resp['data'])
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
    } else {
      this.spinner.hide()
      this.dataSource = []
      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }
  }

  listOfRefund = []


  async getListForRefund() {
    this.spinner.show()
    let  obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = this.selectedObj['tender_id'];
    let resp:any = await this.paymentService.getListForRefund(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.listOfRefund = resp['data'];
      console.log(resp['data'])
      this.listOfRefund.map((x:any) => {
        x['select_flag'] = false
      })
    } else {
      this.spinner.hide()
      this.listOfRefund = []

      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }
  }


  async submitRefund() {
    console.log(this.listOfRefund)
    let data :any= [];
    let  obj:any = {}
    this.listOfRefund.map(x => {
      if (x['select_flag'] == true) {
        data.push({ 'vendor_id': x['vendor_id'], 'tender_id': x['tender_id'], 'arr_id': x['arr_id'], 'txn_id': x['txn_id'], 'ststus': 'REFUNDED' })
      }
    })
    if (data.length == 0) {
      Swal.fire('ERROR', 'Please Select Vendor For Refund', 'error');
      return;
    }
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.erpUser.user_id;
    obj['data'] = data;
    obj['status'] = 'REFUNDED'
    console.log('data to send', data);
    let resp:any = await this.paymentService.submitRefund(obj)
    if (resp['error'] == false) {
      Swal.fire('SUCCESS', 'Amount Successfully Refunded', 'success')
      data = [];
      this.backtoList();
    } else {
      Swal.fire('ERROR', 'Error While Refunding Amount', 'error');

    }
  }

  vendor_details :any = [];
  payment_details  :any= []
  b:any
  async viewDetails(element:any, falg:any) {
    this.spinner.show()
    let  obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = element.tender_id;
    obj['vendor_id'] = element.vendor_id;
    obj['arr_id'] = element.arr_id;

    let resp:any = await this.paymentService.viewDetails(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.vendor_details = resp['data'][0];
      this.payment_details = resp['data'];
      console.log(this.payment_details)

      this.details = true;
      this.refund_flag = false;
      this.list = false;

      if (falg == 'refund_tab') {
        this.refund_tab = true;
      }
      // else {
      //   this.refund_tab = false;
      // }

    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
    }
  }
}
