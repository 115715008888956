import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../authentication.service';
import { MainService as EmbMainService } from './../../emb/service/main.service'
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  //header data;
  info1 = { name: 'UNKNOWN', designation_cd: 'UNKNOWN' }
  info2 = { name: 'UNKNOWN', designation_cd: 'UNKNOWN' }










  Info1ImageUrl = './assets/img/admin-avatar.png';
  Info2ImageUrl = './assets/img/admin-avatar.png';

  //httpUrl="http://localhost:3001";
  //httpUrl="http://139.59.61.84:3001";
  //httpUrl="http://143.110.244.179:3000";
  //httpUrl="https://vdaerp.pcstech.com:3000"
  httpUrl = this.auth.httpUrl;
  // httpUrl="https://vdaerp.pcstech.com:3002";
  //httpUrl="http://139.59.61.84:3000";
  count = 0
  tasks = 0;
  emp_id:any;
  accInfo:any = { account_name: 'ACCOUNT NAME' };
  componentCode = {};
  codeValueTechObj:any = {};
  codeValueShowObj:any = {}
  httpUrl1 = ""
  personalInfoObj = {};

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private auth: AuthenticationService, private MainService: EmbMainService) {
    this.httpUrl1 = this.httpUrl + "/hr/setting";
  }



  //*****************************************************Profile Image********************************************** */
  imgURL_Profile: any;
  profileImageUrl = '../../../assets/img/admin-avatar.png';

  async getUserImage(user_id:any) {
    const res:any = await this.getImage(user_id);
    console.log(res)
    if (res) {
      let checkImageType=res['type']
      if(checkImageType[0]=='image'){
        this.profileImageUrl = '../../../assets/img/admin-avatar.png';
        const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        this.imgURL_Profile = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        this.profileImageUrl = this.imgURL_Profile;
      }else{
        this.profileImageUrl = '../../../assets/img/admin-avatar.png';
      }
     
    } else {
      this.profileImageUrl = '../../../assets/img/admin-avatar.png';
    }
  }


  async getImage(user_id:any) {
 
    const obj:any = new Object();
    obj['user_id'] = user_id;
    const resp = await this.http.post(this.httpUrl + '/portal/getprofileimage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
   
//im
    // if (resp) {
    //   return resp;
    // }
    
      return resp;
  }


  //*****************************************************Profile Image********************************************** */



  //*****************************************************Account Image********************************************** */


  imgURL_account:any;
  // accountImageUrl = './assets/img/account.png';
  accountImageUrl = '../../../assets/img/account.png'


  async getAccImage(b_acct_id:any) {
    const res = await this.getAccImageApi(b_acct_id);
    if (res) {
      let checkImageType=res['type'].split('/')
      console.log(checkImageType)
      if(checkImageType[0]=='image'){
      // this.accountImageUrl = './assets/img/account.png';
      this.  accountImageUrl = '../../../assets/img/account.png'
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL_account = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.accountImageUrl = this.imgURL_account;
      console.log("Get Image")
      }else{
        console.log("Not Get Image");
        this.accountImageUrl = '../../../assets/img/account.png'
      }
      
    } else {
      console.log("Not Get Image");
      this.accountImageUrl = '../../../assets/img/account.png'
    }
  }

  async getAccImageApi(b_acct_id:any): Promise<any> {
    const obj:any = new Object();
    obj['b_acct_id'] = b_acct_id;
    const resp = await this.http.post(this.httpUrl + '/portal/getAccountImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
  }
  //*****************************************************Account Image********************************************** */

  ///********************************************Account Info******************************************************* */
  async getAccountInfo(b_acct_id:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = b_acct_id;
    console.log(obj)
     let resp = await this.getaccountmodule(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.accInfo = resp['data'][0]
    } else {

    }
  }

  async getaccountmodule(obj:any): Promise<any> {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getaccountmodule' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  ///********************************************Account Info******************************************************* */



  //***************************************************** Language ********************************************** */


  allLanguage = []
  language_cd:any
  async getAllLanguage() {
    var obj = new Object();
    var resp = await this.getAllLanguages(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allLanguage = resp.data;
      this.language_cd = resp.data[0]['language_cd']
    } else {

    }
  }
  allLableShowObj:any = {}
  allLabel = []
  async getAllLabel() {
    let obj:any = new Object();
    obj['module_cd'] = 'MD'
    var resp = await this.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allLabel = resp.data;

       obj = new Object();
      for (let i = 0; i < resp.data.length; i++) {
        obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
      }

      console.log(obj)

      this.allLableShowObj = obj;

    } else {

    }
  }
  //***************************************************** Language ********************************************** */


  //***************************************************** Header Info ********************************************** */


  async getHeaderInfo(b_acct_id:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = b_acct_id;
    var resp = await this.getheaderinfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp.data);

      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['info_no'] == 1) {
          this.info1 = resp.data[i];
          console.log(this.info1)
          await this.getHeaderInfoImage(resp.data[i]['info_no'], resp.data[i]['upload_file_name'], b_acct_id)
        }
        if (resp.data[i]['info_no'] == 2) {
          this.info2 = resp.data[i]
          await this.getHeaderInfoImage(resp.data[i]['info_no'], resp.data[i]['upload_file_name'], b_acct_id)

        }
      }
    } else {

    }
  }

  imgURL:any
  async getHeaderInfoImage(info_no:any, filename:any, b_acct_id:any) {
    let ob:any = new Object();
    ob['b_acct_id'] = b_acct_id;
    ob['info_no'] = info_no;
    ob['filename'] = filename;
  
    if (filename == null || filename == undefined) {

    } else {
      const res = await this.getheaderImage(ob);
      if (res) {
        let checkImageType=res['type'].split('/')
        if(checkImageType[0]=='image'){
        const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        if (info_no == 1) {
          this.Info1ImageUrl = this.imgURL;
        }
        if (info_no == 2) {
          this.Info2ImageUrl = this.imgURL;
        }
      }
    }
    }

  }
  
   notification:any = []

   async JE_AE_Notification(data:any) {

/**********************************OLD Code************************************************** */
// data.map(x => {
//   let d1 = moment(x['comp_assign_date'])
//   let complain_date = moment(d1).add(3, 'days')['_d'];
//   let today = moment()['_d']
//   if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
//     this.notification.push(x);
//   }
// })
/**********************************OLD Code************************************************** */



    data.map((x:any) => {
      let d1:any =new  Date(x['comp_assign_date'])
      let complain_date= d1.setDate(d1.getDate() + 3)
      let today=new Date()
      if (today.getMilliseconds() > complain_date.getMilliseconds() && x['status'] == "UNDER-INSPECTION") {
        this.notification.push(x);
      }
    })
  }


  async Zonal_notification(data:any) {
   /*-------------------old code---------------------------*/
    // this.notification=[]
    // data.map(x => {
    //   let d1:any = moment(x['comp_assign_date'])
    //   let complain_date = moment(d1).add(5, 'days')['_d'];
    //   let today = moment()['_d']
    //   if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
    //     this.notification.push(x);
    //   }
    // })
     /*-------------------old code---------------------------*/
    this.notification=[]
    data.map((x:any) => {
      let d1:any =new  Date(x['comp_assign_date'])
      let complain_date= d1.setDate(d1.getDate() + 5)
      let today=new Date()
      if (today.getMilliseconds() > complain_date.getMilliseconds() && x['status'] == "UNDER-INSPECTION") {
        this.notification.push(x);
      }
    })
  }


  icms_notification_data:any;
  async getNotification(b_acct_id:any, user_id:any) {
     this.notification = []
    let noti_count;
    let erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    let obj:any = {}
    obj['b_acct_id'] = b_acct_id;
    obj['user_id'] = user_id;
    let resp = await this.getNotificationTask(JSON.stringify(obj));
   
    if (resp['error'] == false) {
      // resp['data'].map(x => {
      //   let d1 = moment(x['comp_assign_date'])
      //   let complain_date = moment(d1).add(3, 'days')['_d'];
      //   let today = moment()['_d']
      //   if (today > complain_date && x['status'] == "UNDER-INSPECTION") {
      //     notification.push(x);
      //   }
      // })

      if (erpUser.role_cd.includes('ICMS_JE') || erpUser.role_cd.includes('ICMS_AE')) {
        await this.JE_AE_Notification(resp['data'])
      }

      if (erpUser.role_cd.includes('ICMS_ZO')) {
        await this.Zonal_notification(resp['data'])
      }

      noti_count = this.notification.length;

    } else {
      console.log("'Error Occurred While Getting Pending Task'")
      // swal.fire('ERROR', 'Error Occurred While Getting Pending Task', 'error');
    }
  
    return {"noti_count":noti_count,"noti_data":this.notification};

  }


  async getMessageCount(b_acct_id:any, user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = b_acct_id
    obj['user_id'] = user_id
    obj["status"] = 'PENDING'
    let resp = await this.getAllTaskOfUser(JSON.stringify(obj))
    if (resp['error'] == false) {
      console.log("Message Count  : ", resp['data']);
      this.count = resp['data'].length;
      return resp.data;
    }else{
      console.log("Message Not Get ")
    }
  }

  //***************************************************** Header Info ********************************************** */




  //set header detail
  async sendMsg(mobile:any, message:any) {
    return true;
    //console.log(otp + "@123")
    var str = "https://2factor.in/API/R1/?module=TRANS_SMS&apikey=f5b33455-838a-11ea-9fa5-0200cd936042&to=" + mobile + "&from=EMBINF&templatename=EMB+Approval&var1=" + message
    // var str = "https://2factor.in/API/V1/f5b33455-838a-11ea-9fa5-0200cd936042/SMS/" + mobile + "/" + otp + "/ERPUPDA"
    const resp = this.http.get<any>(str).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getembxrefappr(obj:any) {
    const resp = this.http.get<any>(this.httpUrl + '/emb/info/emb/getxrefembxrefappr' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  // Avinash Mishra
  // async rejectExtraWork(obj) {
  //   const resp = this.http.get<any>(this.httpUrl + '/emb/info/emb/rejectExtraWorkStatus' + obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  async changestatus(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/emb/info/emb/changestatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  
  // Avinash Mishra
  async rejectExtraWork(obj:any): Promise<any> {
    const resp = await this.http.put<any>(this.httpUrl + '/emb/info/emb/rejectExtraWorkStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getcomponentLabelvalueswithLanguage(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getcomponentLabelvalueswithLanguage' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getAllLanguages(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getAllLanguages' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getCodeValue(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/codeValue/getCodeValues' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllUsers(acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getallusers' + acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllAssignedComponents(acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/md/resource/getresourcebyrolecd' + acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getWorkflows(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/workflow/getWorkflows' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getWorkflowloguserforicms(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/workflow/getWorkflowloguserforicms' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getUsersForModuleWorkflow(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/workflow/getUsersForModuleWorkflow' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getAllTaskOfUser(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/workflowlog/getAllTaskOfUser' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getVenderName(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/party/getUserName' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getundertakingsWithWorkflow(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/undertakings/getundertakingWithWorkflow' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getWorkflowlog(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/workflowlog/getWorkflowlog' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async insertTask(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/metadata/workflowlog/insertTask', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updatetask(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/metadata/workflowlog/updatetask', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updateWorkflowDocumentStatus(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/metadata/workflow/updateWorkflowDocumentStatus', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getGenCbById(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/emb/info/cb/getGenCbById' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //getMostLatestWorkflowlog
  async getMostLatestWorkflowlog(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/workflowlog/getMostLatestWorkflowlog' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  //header Info
  async getheaderinfo(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/headerInfo/getheaderinfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateheaderinfo(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/metadata/headerInfo/updateheaderinfo', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getheaderImage(obj:any) {
    const resp = await this.http.post(this.httpUrl + '/metadata/headerInfo/getheaderImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
     
      return resp;
   
  }
  async createevent(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/accounts/eventgroup/addEventGroup', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getcurrentlegalentity(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getcurrentlegalentity' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getNotificationTask(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/icms/task/getPendingTask' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
}
