import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }


  /*************** */
  async getDocInfo(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/vms/upload/getDocInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async getAllrepresentative(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/party/getAllrepresentative' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;

  }
  async getAllparties(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/party/getAllparties' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;

  }

  //work
  async updateProjectcode(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/vms/work/updateProjectcode', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async viewDoc(obj:any) {

    const resp = await this.http.post(this.httpUrl + '/vms/upload/viewDoc', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
  }
  return
}

  async getWorkInfo(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/vms/work/getWorkInfo' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async createWorkInfo(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/vms/work/createWorkInfo', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async addModulesHierarchy(obj:any) {
    const res = await this.http.post<any>(this.main.httpUrl + '/accounts/hierarchy/addModulesHierarchy', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async deleteWorkInfo(obj:any) {
    const res = await this.http.delete<any>(this.httpUrl + '/vms/work/deleteWorkInfo' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updateWorkInfo(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/vms/work/updateWorkInfo', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updateModulesHierarchy(obj:any) {
    const res = await this.http.put<any>(this.main.httpUrl + '/accounts/hierarchy/updateModulesHierarchy', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

}
