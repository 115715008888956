<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Zone</div>
                    <!--Geographic Location -->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"   data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                Zone location list</a>
                            <!-- Geographic Location List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"   data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Geographic Location</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Update Geographic Location</a>
                            <!-- Update Geographic Location-->
                        </li>



                    </ul>
                    <br>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        <!-- Filter  -->
                                    </mat-form-field>
                                </div>
                                <div class="col-4">

                                </div>
                                <div class="col-4 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>

                            </div>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            id

                                        </th>
                                        <!--ID-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{i+1}}</td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="loc_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Location Type

                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.location }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="zone_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Zone Name

                                        </th>
                                        <!--Short Name-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.zone_cd }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="zone_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Zone code
                                        </th>
                                        <!--Unit-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.zone_id}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <!-- <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location13']}}</button> -->
                                            <!--Update-->

                                            <!-- <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location14']}}</button> -->
                                            <!--Delete-->
                                            <button class="btn btn-outline-success" (click)="view_on_map(element)">View
                                                on Map</button>
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element , element2)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="create_flag">
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first">
                                    <!--Identification-->

                                    <br>
                                    <div class="row">
                                        <div class="col-1"></div>
                                        <!-- <div class="row"> -->
                                        <div class="col-4 text-center">
                                            <h6 style="margin-left: 2%;">
                                                Select Zone <span style="color:red">*</span>:
                                            </h6>
                                        </div>
                                        <div class="col-4 text-left">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0056']" bindLabel="code"
                                                bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="Obj['zone']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                        <!-- </div> -->

                                    </div> <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" matStepperNext>Next</button>
                                            <!--Next-->
                                        </div>
                                    </div>



                                </mat-step>
                                <mat-step [stepControl]="second" label="Geography">
                                    <!--Geography-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="card">







                                                <div>
                                                    <br>
                                                    <div class="row">
                                                        <div class="col-3 text-right">
                                                            <h6>Choose File
                                                            </h6>
                                                            <!--Choose File :-->
                                                        </div>
                                                        <div class="col-3">
                                                            <input type="file" style="display: inline-block;"
                                                                (change)="onFileChange($event)"
                                                                placeholder="Upload file" accept=".xls">
                                                            <!--Upload file-->
                                                        </div>

                                                        <div class="col-2"></div>
                                                        <!-- <div class="col-3">
                                                            {{auth.allLableShowObjProp[auth.language_cd+'Location108']}}
                                                        </div> -->
                                                        <!--Download format :-->
                                                        <div class="col-2 text-right">
                                                            <button class="btn btn-outline-info btn-sm btn-block"
                                                                (click)="export()">
                                                                <!-- {{auth.allLableShowObjProp[auth.language_cd+'Location109']}} -->
                                                                Download Excel Format
                                                            </button>
                                                            <!--Download-->

                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>

                                                <br>

                                                <div class="row mt-3 justify-content-center">

                                                    <div class="col-2">
                                                        <button class="btn btn-outline-primary btn-sm btn btn-block"
                                                            (click)="modalOpen(1)">View On Map</button>
                                                        <!--View On Map-->
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-danger" matStepperPrevious>
                                                Back

                                            </button> &nbsp;&nbsp;
                                            <!--Back-->

                                            <button class="btn btn-outline-primary" (click)="submit()"
                                                matStepperNext>Submit</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>


                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    Location
                </h4>

                <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
              <google-map height="800px" width="1150px" [center]="center" [zoom]= 20 (mapClick)="addMarker($event)">
                <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
                  [options]="markerOptions">
                </map-marker>
                <map-polygon [paths]="vertices"></map-polygon>

              </google-map>



              <!-- oc  -->

                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [zoomControl]="false">

                     // not used
                    <agm-circle [latitude]="26.8522034" [longitude]="80.9556009" [radius]="5000" [fillColor]="red"></agm-circle>
                    //not

                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon>
                </agm-map> -->
                <!-- ec -->

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">
                        Close
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg mt-0" style="max-width:95%;">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    Location
                </h4>

                <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
              <google-map height="800px" width="1150px" [center]="center" [zoom]= 20 (mapClick)="addMarker($event)">
                <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition"
                  [options]="markerOptions">
                </map-marker>
                <map-polygon [paths]="vertices"></map-polygon>

              </google-map>
              <!-- oc  -->
                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [zoomControl]="true">

                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon>
                </agm-map> -->
                <!-- ec  -->

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">
                        close

                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
