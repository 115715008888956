<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer1']}}<!-- Manage Transfer --> </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer2']}}<!-- All Employee Transfer List --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer3']}}<!-- Employee Transfer --> </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer4']}}"> <!-- Filter -->
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer5']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.tempid }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer6']}}<!-- Employee Name -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{employeeObj[element.emp_id]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="transfer_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer7']}} Effective Timestamp -->
                                               Transfer Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.transfer_date}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer8']}}<!-- Order ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="revoke_transfer(element)">Revoke
                                                Transfer</button>&nbsp;&nbsp;
                                            <!-- <button class="btn btn-success" (click)="rejoin_transfer()">Rejoin
                                                Employee</button> -->
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer9']}}<!-- Select Employee --> :<span style="color: red;">*</span>

                                </div>
                                <div class="col-4">

                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name"
                                    bindValue="emp_id" [multiple]="false" placeholder=""
                                    [(ngModel)]="selectEmpObj" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" (click)="changeTransfer()" name="emp_id">
                                </ng-select>


                                </div>
                            </div>
                            <br>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer8']}}<!-- Order ID --> :
                                </div>
                                <div class="col-4">
                                    <input *ngIf="mainService.codeValueTechObj['order_id']==undefined" onkeypress="return /[0-9]/i.test(event.key)" class="form-control" [(ngModel)]="transfernObj['order_id']">
                                    <select *ngIf="mainService.codeValueTechObj['order_id']!=undefined" class="form-control" [(ngModel)]="transfernObj['order_id']">
                                    <option *ngFor="let ob of mainService.codeValueTechObj['order_id']"
                                        [ngValue]="ob['code']">{{ob['value']}}</option>
                                </select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">



                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer14']}}<!-- Transfer Date --> <span style="color: red;">*</span>


                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="transfernObj['date_of_transfer']">

                                </div>
                            </div>
                            <br>
                            <div class="row" >
                                <div class="col-12 text-center" *ngIf="this.submitFlag">
                                    <button class="btn btn-primary" (click)="submitTransfer()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer11']}}
                                        <!-- Submit -->
                                    </button>

                                </div>
                            </div>
                            <div class="row" >
                                <div class="col-12 text-center" *ngIf="this.popFlagTransfer">
                                    <button class="btn btn-danger" (click)="submitTransfer1()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer13']}}
                                        <!-- Transfer-->
                                     </button>

                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Transfer12']}}<!-- Loading -->....</ngx-spinner>

<div class="modal" id="revoke">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> Transfer Employee Detail Changes:</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">

                    <div class="col-6 text-right">
                        Employee Name:
                    </div>
                    <div class="col-3 text-left">
                       <input class="form-control"  [(ngModel)]="revokeObj['emp_name']" >
                       <!-- {{revokeObj['emp_name']}} -->
                    </div>

                </div>
                <br>
                <div class="row">

                    <div class="col-6 text-right">
                        Employee ID:
                    </div>
                    <div class="col-3 text-left">
                        <input class="form-control" [(ngModel)]="revokeObj['emp_id']" disabled>
                        <!-- {{revokeObj['emp_id']}} -->
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-6 text-right">
                        Enter Order Id:
                    </div>
                    <div class="col-3 text-left">
                        <input type="number" class="form-control" [(ngModel)]="revokeObj['order_id']">
                    </div>
                </div>

                <br>
                <div class="row">
                    <div class="col-6 text-right">
                        Revoked Date:<span style="color: red;">*</span>
                    </div>
                    <div class="col-3 text-left">
                        <input type="date" class="form-control" [(ngModel)]="revokeObj['revoked_date']">
                    </div>
                </div>

                <br>
                <h5>Before Transfer Revoke..</h5>

                <table class="table">
                    <thead>
                        <tr>
                            <th width="50%">Component Code</th>
                            <th>Amount</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of old_fix_pay">
                            <td>{{item['pay_component_code']}}</td>
                            <td>{{item['pay_component_amt']}}</td>
                            <td>{{item['effective_start_dt']}}</td>
                            <td>{{item['effective_end_dt']}}</td>
                        </tr>
                    </tbody>
                </table>
                <hr>
                <h5>After Transfer Revoke..</h5>
                <br>
                <table class="table">
                    <thead>
                        <tr>
                            <th width="50%">Component Code</th>
                            <th>Amount</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of new_fix_pay">
                            <td>{{item['pay_component_code']}}</td>
                            <td>{{item['pay_component_amt']}}</td>
                            <td>{{item['effective_start_dt']}}</td>
                            <td>{{item['effective_end_dt']}}</td>
                        </tr>
                    </tbody>
                </table>
                <hr>

                <div class="row">
                    <div class="col-12 text-center">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="SubmitRevokeTransfer()">Submit</button>&nbsp;

                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
