import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from "../service/main.service";
import { ChallanService } from "../service/challan.service";
import { LedgerService } from "../service/ledger.service";
import { EventsService } from "../service/events.service";
import { SettingService } from "../service/setting.service";
import { RuleService } from "../service/rule.service";
import { RuleProcessService } from "../service/rule-process.service";
import { BillService } from "../service/bill.service";
import swal from "sweetalert2";
import { BudgetService } from "../service/budget.service";
import { HierarchyService } from "../service/hierarchy.service";
import { WorkService } from "../service/work.service";
import { DataControlService } from "../service/data-control.service";
import { CreateEventService } from "../service/create-event.service";
import * as pdfMake from "pdfmake/build/pdfmake";
declare var $: any;
@Component({
  selector: "app-challan",
  templateUrl: "./challan.component.html",
  styleUrls: ["./challan.component.css"],
})
export class ChallanComponent implements OnInit {
  displayedColumns = [
    "id",
    "party_id",
    "party_name",
    "amount",
    "purpose",
    "demand_id",
    "status",
    "action",
  ];
  datasource:any;
  displayedColumns1 = ["event_code", "event_desc", "action"];
  dataSource1:any;

  constructor(
    private createEventService: CreateEventService,
    private workS: WorkService,
    private hierarchyService: HierarchyService,
    private ruleService: RuleService,
    private ruleProcessService: RuleProcessService,
    private billService: BillService,
    private settingService: SettingService,
    private eventsService: EventsService,
    private budgetService: BudgetService,
    private ledgerService: LedgerService,
    private challanService: ChallanService,
    public mainService: MainService,
    private spinner: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private dataControlService: DataControlService
  ) { }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild("paginator1", { static: false }) paginator1!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;
  erpUser:any;
  b_acct_id:any;
  AllDummyChallan:any = [];
  AllPaidChallan:any = [];
  challantype:any = [{ challantypes: "Paid" }, { challantypes: "Dummy" }];
  typechallan:any = "";
  obj :any= {};
  dummy_challan_no:any;
  paid_challan_no:any;
  challan:any = [];

  paid_obj :any= {};
  dummy_obj:any = {};
  payobj :any= [];
  paid_flag:any = 0;
  dummy_flag:any = 0;
  allAccountInfo :any= [];
  fin_year:any;
  RecivalbeAccount :any= [{ account_code: "", account_amount: 0 }];
  date:any;

  allProjectHier :any= [];
  allProductHier :any= [];
  allActivityHier :any= [];
  allBudgetHier :any= [];

  partyArr:any = [];
  partyArrObj :any= {};
  challanRows:any = [];
  challanObj :any= {};
  totalAmt :any= 0;
  totalGst:any = 0;
  netTotal :any= 0;

  gstType:any = [
    { code: "IGST", value: "IGST" },
    { code: "CGST/SGST", value: "CGST/SGST" },
  ];

  account :any= [];

  bankObj:any = { bank: null, branch: null, account_num: null, ifsc_code: null };
  Obj:any = {};

  allEvents :any= [];
  allHSNAccounts:any = [];
  systemDate:any;
  orgShortName :any= "";
  selectObj :any= {};
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data;
    this.orgShortName = await this.mainService.accInfo["account_short_name"];
    this.spinner.show();

    await this.getDedType();
    await this.getDedData();

    await this.getAllWork();
    await this.getrepresentative();
    await this.getparty();

    await this.getAllBankAccountInfo();

    await this.getHSNAccount();
    await this.getActiveFinYear();

    await this.getAllBudget();
    await this.getAllProject();
    await this.getAllProduct();
    await this.getAllActivity();

    await this.getChallanInfo();

    this.spinner.hide();
  }

  hsnObj:any = {};
  async getHSNAccount() {
    var resp = await this.settingService.getHSNAccounts(this.b_acct_id);
    if (resp["error"] == false) {
      this.allHSNAccounts = resp.data;
      for (let i = 0; i < this.allHSNAccounts.length; i++) {
        this.hsnObj[this.allHSNAccounts[i]["hsn_code"]] =
          this.allHSNAccounts[i]["hsn_desc"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all HSN list!", "error");
    }
  }
  allWork: any = [];
  assingedWork:any = []
  async getAllWork() {
    let assigned_work_ids: any = [];
    assigned_work_ids = await this.dataControlService.getWork();
    console.log("Assigned work id is : ", assigned_work_ids)
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp :any= await this.workS.getWorkList(obj);
    if (resp["error"] == false) {
      this.allWork = resp.data;
      this.allWork.map((x:any) => {
        if (assigned_work_ids.includes(x['work_id'])) {
          this.assingedWork.push(x)
        }
      })
      this.allWork = this.assingedWork;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all sal list!", "error");
    }
  }
  //****************************************************************************************** */
  reperesentative :any= {};
  async getrepresentative() {
    var resp :any= await this.settingService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    }
  }
  all_party :any= [];
  async getparty() {
    var resp :any= await this.settingService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["party_type"] == "ORG") {
          resp["data"][i]["party_leagal_name"] =
            resp["data"][i]["party_leagal_name"] +
            " ( " +
            this.reperesentative[resp["data"][i]["rep_le_id"]] +
            ")";
        }
      }
      this.all_party = resp["data"];
      this.obj["party_type"];
    }
  }

  setPartyDetails(party_le_id:any) {
    console.log(party_le_id);
    for (let i = 0; i < this.all_party.length; i++) {
      if (party_le_id == this.all_party[i]["le_id"]) {
        this.challanObj["party_id"] = party_le_id;
        this.challanObj["party_name"] = this.all_party[i]["party_leagal_name"];
        this.challanObj["party_email"] = this.all_party[i]["party_email"];
        this.challanObj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.challanObj["party_pan_no"] = this.all_party[i]["party_pan_no"];
        this.challanObj["party_adhar_no"] = "NA";
        this.challanObj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.challanObj["party_address"] = "NA";
        break;
      }
    }
  }

  level1 :any= [];
  level2 :any= [];
  level3 :any= [];
  level4 :any= [];
  level5:any = [];
  level6:any = [];
  level7:any = [];
  Chartobj :any= {};
  Hier :any= [];
  type:any;
  budgetObj:any = {};
  hierobj :any= { budget: {}, activity: {}, product: {}, project: {} };
  async getAllBudget() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    var resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allBudgetHier = resp.data;

      for (let i = 0; i < this.allBudgetHier.length; i++) {
        console.log(this.allBudgetHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allBudgetHier[i]["lvl" + j + "_cd"] != null &&
            this.allBudgetHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allBudgetHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allBudgetHier[i]["desc"] = str;
        this.budgetObj[this.allBudgetHier[i]["leaf_cd"]] = str;
        this.hierobj["budget"][this.allBudgetHier[i]["leaf_cd"]] =
          this.allBudgetHier[i];
      }
    } else {
      this.spinner.hide();
    }
  }

  projectObj:any = {};
  projectLeafUserObj:any = {};

  async getAllProject() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "proj_hier";
    var resp = await this.hierarchyService.getProjectHierarchy(obj);
    if (resp["error"] == false) {
      this.allProjectHier = resp.data;
      for (let i = 0; i < this.allProjectHier.length; i++) {
        console.log(this.allProjectHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProjectHier[i]["lvl" + j + "_cd"] != null &&
            this.allProjectHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProjectHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProjectHier[i]["desc"] = str;
        this.projectObj[this.allProjectHier[i]["leaf_cd"]] = str;
        this.projectLeafUserObj[this.allProjectHier[i]["leaf_user_cd"]] =
          this.allProjectHier[i];
        this.hierobj["project"][this.allProjectHier[i]["leaf_cd"]] =
          this.allProjectHier[i];
      }
    } else {
    }
  }
  activityObj:any = {};
  async getAllActivity() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "activity_hier";
    var resp = await this.hierarchyService.getActivityHierarchy(obj);
    if (resp["error"] == false) {
      this.allActivityHier = resp.data;
      for (let i = 0; i < this.allActivityHier.length; i++) {
        // console.log(this.allActivityHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allActivityHier[i]["lvl" + j + "_cd"] != null &&
            this.allActivityHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allActivityHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allActivityHier[i]["desc"] = str;
        this.activityObj[this.allActivityHier[i]["leaf_cd"]] = str;
        this.hierobj["activity"][this.allActivityHier[i]["leaf_cd"]] =
          this.allActivityHier[i];
      }
    } else {
    }
  }
  productObj:any = {};
  async getAllProduct() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    var resp = await this.hierarchyService.getProductHierarchy(obj);
    if (resp["error"] == false) {
      this.allProductHier = resp.data;
      for (let i = 0; i < this.allProductHier.length; i++) {
        console.log(this.allProductHier[i]);
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProductHier[i]["lvl" + j + "_cd"] != null &&
            this.allProductHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProductHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProductHier[i]["desc"] = str;
        this.productObj[this.allProductHier[i]["leaf_cd"]] = str;
        this.hierobj["product"][this.allProductHier[i]["leaf_cd"]] =
          this.allProductHier[i];
      }
    } else {
    }
  }

  hier_type:any;
  HierSelectObj:any = {};
  HierSelectobj:any;

  async Select(type:any) {
    $("#select").modal("show");
    this.hier_type = type;
    let ob:any = {};

    if (type == "budget") {
      this.Hier = this.allBudgetHier;
      if (this.selectObj["bud_cd"] != null && this.selectObj["bud_cd"] != "") {
        ob = this.hierobj["budget"][this.selectObj["bud_cd"]];
      }
    } else if (type == "activity") {
      this.Hier = this.allActivityHier;
      if (this.selectObj["act_cd"] != null && this.selectObj["act_cd"] != "") {
        ob = this.hierobj["activity"][this.selectObj["act_cd"]];
      }
    }
    this.HierSelectObj = {};
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob);
      await this.onChangeLvl1();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl2();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl3();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl4();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl5();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl6();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl7();
    }
  }

  async selectCreate(type:any) {
    $("#selectCreate").modal("show");
    this.hier_type = type;
    let ob = {};
    if (type == "budget") {
      this.Hier = this.allBudgetHier;
      if (this.obj["bud_cd"] != null && this.obj["bud_cd"] != "") {
        ob = this.hierobj["budget"][this.obj["bud_cd"]];
      }
    } else if (type == "activity") {
      this.Hier = this.allActivityHier;
      if (this.obj["act_cd"] != null && this.obj["act_cd"] != "") {
        ob = this.hierobj["activity"][this.obj["act_cd"]];
      }
    } else if (type == "product") {
      this.Hier = this.allProductHier;
      if (this.obj["prod_cd"] != null && this.obj["prod_cd"] != "") {
        ob = this.hierobj["product"][this.obj["prod_cd"]];
      }
    } else if (type == "project") {
      this.Hier = this.allProjectHier;
      if (this.obj["proj_cd"] != null && this.obj["proj_cd"] != "") {
        ob = this.hierobj["project"][this.obj["proj_cd"]];
      }
    }

    this.HierSelectObj = {};
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob);
      await this.onChangeLvl1();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl2();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl3();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl4();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl5();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl6();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl7();
    }
  }
  async getLevel1() {
    this.level1 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]["lvl1_cd"] == this.Hier[i]["leaf_cd"]) {
        let ob:any = new Object();
        ob["lvl1_cd"] = this.Hier[i]["lvl1_cd"];
        ob["lvl1_value"] = this.Hier[i]["lvl1_value"];
        ob["is_leaf"] = this.Hier[i]["is_leaf"];

        this.level1.push(ob);
      }
    }

    this.level2 = [];
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];
  }

  async onChangeLvl1() {
    if (
      this.HierSelectObj["lvl1_cd"] == "" ||
      this.HierSelectObj["lvl1_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"]) {
        this.HierSelectObj["lvl1_value"] = this.level1[i]["lvl1_value"];
        if (this.level1[i]["is_leaf"] == 1) {
          console.log(this.level1[i]);
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    this.level2 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"] &&
        this.Hier[i]["lvl2_cd"] != null
      ) {
        if (this.Hier[i]["lvl2_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl2_cd"] = this.Hier[i]["lvl2_cd"];
          ob["lvl2_value"] = this.Hier[i]["lvl2_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level2.push(ob);
        }
      }
    }
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 2; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }
  async onChangeLvl2() {
    if (
      this.HierSelectObj["lvl2_cd"] == "" ||
      this.HierSelectObj["lvl2_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"]) {
        this.HierSelectObj["lvl2_value"] = this.level2[i]["lvl2_value"];
        if (this.level2[i]["is_leaf"] == 1) {
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    let temp = [];
    this.level3 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"] &&
        this.Hier[i]["lvl3_cd"] != null
      ) {
        if (this.Hier[i]["lvl3_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl3_cd"] = this.Hier[i]["lvl3_cd"];
          ob["lvl3_value"] = this.Hier[i]["lvl3_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level3.push(ob);
        }
      }
    }

    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 3; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl3() {
    if (
      this.HierSelectObj["lvl3_cd"] == "" ||
      this.HierSelectObj["lvl3_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"]) {
        this.HierSelectObj["lvl3_value"] = this.level3[i]["lvl3_value"];
        if (this.level3[i]["is_leaf"] == 1) {
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    let temp = [];
    this.level4 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"] &&
        this.Hier[i]["lvl4_cd"] != null
      ) {
        if (this.Hier[i]["lvl4_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl4_cd"] = this.Hier[i]["lvl4_cd"];
          ob["lvl4_value"] = this.Hier[i]["lvl4_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level4.push(ob);
        }
      }
    }

    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 4; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl4() {
    if (
      this.HierSelectObj["lvl4_cd"] == "" ||
      this.HierSelectObj["lvl4_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"]) {
        this.HierSelectObj["lvl4_value"] = this.level4[i]["lvl4_value"];
        if (this.level4[i]["is_leaf"] == 1) {
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    let temp = [];
    this.level5 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"] &&
        this.Hier[i]["lvl5_cd"] != null
      ) {
        if (this.Hier[i]["lvl5_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl5_cd"] = this.Hier[i]["lvl5_cd"];
          ob["lvl5_value"] = this.Hier[i]["lvl5_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level5.push(ob);
        }
      }
    }
    this.level6 = [];
    this.level7 = [];

    for (let i = 5; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl5() {
    if (
      this.HierSelectObj["lvl5_cd"] == "" ||
      this.HierSelectObj["lvl5_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"]) {
        this.HierSelectObj["lvl5_value"] = this.level5[i]["lvl5_value"];
        if (this.level5[i]["is_leaf"] == 1) {
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    let temp = [];
    this.level6 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"] &&
        this.Hier[i]["lvl6_cd"] != null
      ) {
        if (this.Hier[i]["lvl6_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl6_cd"] = this.Hier[i]["lvl6_cd"];
          ob["lvl6_value"] = this.Hier[i]["lvl6_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level6.push(ob);
        }
      }
    }
    this.level7 = [];

    for (let i = 6; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl6() {
    if (
      this.HierSelectObj["lvl7_cd"] == "" ||
      this.HierSelectObj["lvl7_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"]) {
        this.HierSelectObj["lvl6_value"] = this.level6[i]["lvl6_value"];
        if (this.level6[i]["is_leaf"] == 1) {
          await this.makingLeafValues();
        } else {
          this.HierSelectObj["leaf_cd"] = "";
          this.HierSelectObj["leaf_value"] = "";
        }
      }
    }
    let temp = [];
    this.level7 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"] &&
        this.Hier[i]["lvl7_cd"] != null
      ) {
        if (this.Hier[i]["lvl7_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl7_cd"] = this.Hier[i]["lvl7_cd"];
          ob["lvl7_value"] = this.Hier[i]["lvl7_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level7.push(ob);
        }
      }
    }

    for (let i = 7; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl7() {
    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]["lvl7_cd"] == this.HierSelectObj["lvl7_cd"]) {
        this.HierSelectObj["lvl7_value"] = this.level7[i]["lvl7_value"];
      }
    }

    await this.makingLeafValues();
  }

  async makingLeafValues() {
    this.leaf_flag=false
    if (
      this.HierSelectObj["lvl7_cd"] != undefined &&
      this.HierSelectObj["lvl7_cd"] != "" &&
      this.HierSelectObj["lvl7_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl7_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl7_value"];
    } else if (
      this.HierSelectObj["lvl6_cd"] != undefined &&
      this.HierSelectObj["lvl6_cd"] != "" &&
      this.HierSelectObj["lvl6_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl6_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl6_value"];
    } else if (
      this.HierSelectObj["lvl5_cd"] != undefined &&
      this.HierSelectObj["lvl5_cd"] != "" &&
      this.HierSelectObj["lvl5_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl5_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl5_value"];
    } else if (
      this.HierSelectObj["lvl4_cd"] != undefined &&
      this.HierSelectObj["lvl4_cd"] != "" &&
      this.HierSelectObj["lvl4_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl4_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl4_value"];
    } else if (
      this.HierSelectObj["lvl3_cd"] != undefined &&
      this.HierSelectObj["lvl3_cd"] != "" &&
      this.HierSelectObj["lvl3_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl3_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl3_value"];
    } else if (
      this.HierSelectObj["lvl2_cd"] != undefined &&
      this.HierSelectObj["lvl2_cd"] != "" &&
      this.HierSelectObj["lvl2_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl2_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl2_value"];
    } else if (
      this.HierSelectObj["lvl1_cd"] != undefined &&
      this.HierSelectObj["lvl1_cd"] != "" &&
      this.HierSelectObj["lvl1_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl1_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl1_value"];
    }
  }
  async SubmitSelectedListHier() {
    console.log(this.HierSelectObj);
    console.log(this.hier_type);
    let levelnum = 0;
    for (let i = 1; i < 8; i++) {
      if (
        this.HierSelectObj["lvl" + i + "_cd"] != null &&
        this.HierSelectObj["lvl" + i + "_cd"] != ""
      ) {
        levelnum = i;
      }
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (
          this.allBudgetHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.selectObj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.selectObj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.selectObj["bud_obj"] = this.allBudgetHier[i];
        }
      }
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["act_desc"] = this.allActivityHier[i]["desc"];
          this.selectObj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.selectObj["act_lvl"] = this.allActivityHier[i]["level"];
          this.selectObj["act_obj"] = this.allActivityHier[i];
        }
      }
    } else if (this.hier_type == "project") {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (
          this.allProjectHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["proj_desc"] = this.allProjectHier[i]["desc"];
          this.selectObj["proj_cd"] = this.allProjectHier[i]["leaf_cd"];
          this.selectObj["proj_lvl"] = this.allProjectHier[i]["level"];
          this.selectObj["proj_obj"] = this.allProjectHier[i];
        }
      }
    } else if (this.hier_type == "product") {
      for (let i = 0; i < this.allProductHier.length; i++) {
        if (
          this.allProductHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["prod_cd"] = this.allProductHier[i]["leaf_cd"];
          this.selectObj["prod_desc"] = this.allProductHier[i]["desc"];
          this.selectObj["prod_lvl"] = this.allProductHier[i]["level"];
          this.selectObj["prod_obj"] = this.allProductHier[i];
        }
      }
    }

    $("#select").modal("hide");
  }
leaf_flag=false;
  async SubmitListHierforCreate() {
    if (
      this.HierSelectObj["leaf_cd"] == "" ||
      this.HierSelectObj["leaf_cd"] == null
    ) {
      // swal.fire("Error", "Please select till leaf.");
      this.leaf_flag=true;
      return;
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (this.allBudgetHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]) {
          this.obj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.obj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.obj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.obj["bud_obj"] = this.allBudgetHier[i];
        }
      }
      console.log(this.obj);
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["act_desc"] = this.allActivityHier[i]["desc"];
          this.obj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.obj["act_lvl"] = this.allActivityHier[i]["level"];
          this.obj["act_obj"] = this.allActivityHier[i];
        }
      }
    } else if (this.hier_type == "project") {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (
          this.allProjectHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["proj_desc"] = this.allProjectHier[i]["desc"];
          this.obj["proj_cd"] = this.allProjectHier[i]["leaf_cd"];
          this.obj["proj_lvl"] = this.allProjectHier[i]["level"];
          this.obj["proj_obj"] = this.allProjectHier[i];
        }
      }
    }
    await this.setEventAndPartyField(this.obj["proj_cd"]);

    $("#selectCreate").modal("hide");
  }

  setEventAndPartyField(proj_cd:any) {
    this.challanObj["act_cd"] = "";
    this.challanObj["proj_cd"] = proj_cd;
    this.challanObj["bud_cd"] = "";
    this.challanObj["prod_cd"] = "";

    this.challanObj["party_id"] = null;
    this.challanObj["party_name"] = null;
    this.challanObj["party_email"] = null;
    this.challanObj["party_phone_no"] = null;
    this.challanObj["party_pan_no"] = null;
    this.challanObj["party_adhar_no"] = null;
    this.challanObj["party_gstin_no"] = null;
    this.challanObj["party_address"] = null;
    this.challanObj["arr_id"] = null;
    this.challanObj["work_id"] = null;
    console.log(this.challanObj);

    if (this.obj["proj_obj"]["module_cd"] == "PROPERTY") {
      let party = this.getPropertyPartyDetail(
        this.obj["proj_obj"]["leaf_user_cd"]
      );

      this.challanObj["act_cd"] = party["activity_cd"];
      this.challanObj["bud_cd"] = party["budget_cd"];
      this.challanObj["prod_cd"] = party["product_cd"];
      this.challanObj["party_id"] = party["party_id"];
      this.challanObj["party_name"] = party["party_name"];
      this.challanObj["party_email"] = party["party_email"];
      this.challanObj["party_phone_no"] = party["party_phone_no"];
      this.challanObj["party_pan_no"] = party["party_pan_no"];
      this.challanObj["party_adhar_no"] = party["party_adhar_no"];
      this.challanObj["party_gstin_no"] = "NA";
      this.challanObj["party_address"] = party["party_permanent_addr_line"];
      this.challanObj["arr_id"] = party["arr_id"];
      this.challanObj["work_id"] = party["arr_id"];
    } else if (this.obj["proj_obj"]["module_cd"] == "ACCOUNT") {
      this.allWork.map((x:any) => {
        if (
          "ACCOUNTWORK" + x["work_id"] ==
          this.obj["proj_obj"]["leaf_user_cd"]
        ) {
          this.challanObj["act_cd"] = x["act_cd"];
          this.challanObj["prod_cd"] = x["prod_cd"];
          this.challanObj["bud_cd"] = x["bud_cd"];
          this.challanObj["arr_id"] = x["work_id"];
          this.challanObj["work_id"] = x["work_id"];

          this.setPartyDetails(x["party_le_id"]);
        }
      });
    } else {
      swal.fire(
        "Warning",
        "..Please Select Correct or valid Project that is exist in Account Or Property Module...!",
        "warning"
      );
    }
  }

  getPropertyPartyDetail(leaf_user_cd:any) {
    let obj = new Object();
    return (obj = {
      property_type: "HIG",
      arr_id: "1",
      assigned_property_number: "P165",
      life_cycle_status: "ALLOTED",
      party_id: 500170,
      arr_type_code: "ALLOTED",
      party_name: "UTTAM SHUKLA",
      party_email: "uttam shukla@ pcsmcpl.com",
      party_phone_no: 8840753570,
      party_dob: "1995-08-30",
      party_permanent_addr_line: "Engineering Chauraha Lucknow",
      party_pan_no: "AAAA5252S",
      party_adhar_no: "715826824522",
      party_bank_name: null,
      party_branch_name: null,
      party_acct_no: null,
      party_ifsc_code: null,
      project_cd: 322,
      budget_cd: "APF",
      product_cd: "Plots",
      activity_cd: "FIN-RES",
      booklet_activity_cd: "FIN-RES",
      booklet_budget_cd: "BKF",
      booklet_product_cd: "AB-PS",
      booklet_gst_budget_node_cd: "BKF",
      booklet_gst_activity_node_cd: "FIN-RES",
    });
  }

  async getActiveFinYear() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length == 0) {
        swal.fire("Warning", "..Active Financial Year Not set!", "warning");
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      swal.fire("Error", "..Error while getting active  fin year!", "error");
    }
  }

  async changeStatus(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "APPROVED";
    obj["id"] = [element.id];
    obj["update_user_id"] = this.erpUser.user_id;

    this.spinner.show();
    var resp = await this.challanService.updateChallanStatus(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.getChallanInfo();
      swal.fire("Success", "...Challan APPROVED Successfuly!", "success");
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while APPROVED Challan!!", "error");
    }
  }
  dedTypeObj:any = {};
  //**********************cgst igst sgst******************************* */
  async getDedType() {
    var dedArr:any = [];
    if (this.mainService.codeValueTechObj["ACC0057"] != undefined) {
      this.mainService.codeValueTechObj["ACC0057"].map((x:any) => {
        if (
          !(x["code"] == "CGSTOUTPUT" || x["code"] == "SGSTOUTPUT" || x["code"] == "IGSTOUTPUT"
            || x["code"] == "CGSTINPUT" || x["code"] == "SGSTINPUT" || x["code"] == "IGSTINPUT")
        )
          this.allUserDeduction.push(x);
      });
      dedArr = this.allUserDeduction;
    }
    this.dedTypeObj = new Object();
    for (let i = 0; i < dedArr.length; i++) {
      this.dedTypeObj[dedArr[i]["code"]] = dedArr[i]["value"];
    }
  }
  dedDataArr:any = [];
  dedtoActivity:any = {};
  dedtoBudeget:any = {};
  allUserDeduction:any = [];
  allDeduction :any= [];
  async getDedData() {
    var resp = await this.settingService.getDeductionMappingList(
      this.b_acct_id
    );
    if (resp["error"] == false) {
      this.allDeduction = resp.data;
      var dd = Object.keys(this.dedTypeObj);
      for (let i = 0; i < dd.length; i++) {
        if (!(dd[i] == "IGSTOUTPUT" || dd[i] == "SGSTOUTPUT" || dd[i] == "CGSTOUTPUT" || dd[i] == "IGSTINPUT" || dd[i] == "SGSTINPUT" || dd[i] == "CGSTINPUT"))
          this.dedDataArr[dd[i]] = [];
      }

      for (let j = 0; j < this.allDeduction.length; j++) {
        this.dedtoActivity[this.allDeduction[j]["ded_code"] + this.allDeduction[j]["gov_rule"]] =
          this.allDeduction[j]["act_cd"];
        this.dedtoBudeget[this.allDeduction[j]["ded_code"] + this.allDeduction[j]["gov_rule"]] =
          this.allDeduction[j]["bud_cd"];
        if (this.allDeduction[j]["ded_owner"] != "SYSTEM") {
          this.dedDataArr[this.allDeduction[j]["ded_code"]].push(this.allDeduction[j]);

        }
      }
      console.log(this.dedtoActivity);
      console.log(this.dedtoBudeget);
    } else {
      swal.fire("Error", "...Error while getting list!", "error");
    }
  }
  obj1:any = {};


  countBill(element:any) {
    let count = 1;
    this.allChallan.map(x => {
      if (element['work_id'] == x['work_id']) {  // && x['status'] == 'APPROVED' ) || x['status'] == 'PAID'
        count++;
      }
    })
    return count;
  }
  postpixAdd(i:any) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  async payment_received(element:any) {
    let countBill = this.countBill(element)
    let event = [];
    this.obj1 = Object.assign({}, element);
    var obj :any= JSON.parse(element.data);
    this.challanRows = obj["arr"];
    this.obj1 = obj["arr"];
    this.obj1 = obj["obj"];
    this.obj1["id"] = element.id;
    this.obj1["challan_source"] = element["challan_source"];
    this.obj1["status"] = element["status"];
    this.obj1["challan_generate_date"] = element["challan_generate_date"];
    this.obj1["amount"] = element["amount"];

    await this.check();

    let total_net:any = new Object();
    total_net["proj_cd"] = this.obj1["proj_cd"];
    total_net["bud_cd"] = this.obj1["bud_cd"];
    total_net["prod_cd"] = this.obj1["prod_cd"];
    total_net["act_cd"] = this.obj1["act_cd"];
    total_net["amt"] = this.totalAmt;
    total_net["net_amount"] = this.netTotal;
    total_net["gross_amount"] = this.totalAmt;
    total_net["party_id"] = this.obj1["party_id"];
    total_net["challan_generate_date"] = this.obj1["challan_generate_date"];
    total_net["challan_id"] = this.obj1["id"];
    total_net["demand_id"] = this.obj1["demand_id"];
    total_net["arr_id"] = this.obj1["arr_id"];
    total_net["event_cd"] = element.id;

    if (total_net["amt"] != 0) {
      event.push(total_net);
    }

    let total_cgst:any = new Object();
    total_cgst["proj_cd"] = this.obj1["proj_cd"];
    total_cgst["bud_cd"] = this.dedtoBudeget["CGSTOUTPUTNA"];
    total_cgst["prod_cd"] = this.obj1["prod_cd"];
    total_cgst["act_cd"] = this.dedtoActivity["CGSTOUTPUTNA"];
    total_cgst["amt"] = this.cgstAmount;
    total_cgst["net_amount"] = this.netTotal;
    total_cgst["gross_amount"] = this.totalAmt;
    total_cgst["party_id"] = this.obj1["party_id"];
    total_cgst["challan_generate_date"] = this.obj1["challan_generate_date"];
    total_cgst["challan_id"] = this.obj1["id"];
    total_cgst["demand_id"] = this.obj1["demand_id"];
    total_cgst["arr_id"] = this.obj1["arr_id"];
    total_cgst["event_cd"] = "CGSTOUTPUT";

    if (total_cgst["amt"] != 0) {
      event.push(total_cgst);
    }

    let total_sget:any = new Object();
    total_sget["proj_cd"] = this.obj1["proj_cd"];
    total_sget["bud_cd"] = this.dedtoBudeget["SGSTOUTPUTNA"];
    total_sget["prod_cd"] = this.obj1["prod_cd"];
    total_sget["act_cd"] = this.dedtoActivity["SGSTOUTPUTNA"];
    total_sget["amt"] = this.sgstAmount;
    total_sget["net_amount"] = this.netTotal;
    total_sget["gross_amount"] = this.totalAmt;
    total_sget["party_id"] = this.obj1["party_id"];
    total_sget["challan_generate_date"] = this.obj1["challan_generate_date"];
    total_sget["challan_id"] = this.obj1["id"];
    total_sget["demand_id"] = this.obj1["demand_id"];
    total_sget["arr_id"] = this.obj1["arr_id"];
    total_sget["event_cd"] = "SGSTOUTPUT";

    if (total_sget["amt"] != 0) {
      event.push(total_sget);
    }

    let total_igst:any = new Object();
    total_igst["proj_cd"] = this.obj1["proj_cd"];
    total_igst["bud_cd"] = this.dedtoBudeget["IGSTOUTPUTNA"];
    total_igst["prod_cd"] = this.obj1["prod_cd"];
    total_igst["act_cd"] = this.dedtoActivity["IGSTOUTPUTNA"];
    total_igst["amt"] = this.igstAmount;
    total_igst["net_amount"] = this.netTotal;
    total_igst["gross_amount"] = this.totalAmt;
    total_igst["party_id"] = this.obj1["party_id"];
    total_igst["challan_generate_date"] = this.obj1["challan_generate_date"];
    total_igst["challan_id"] = this.obj1["id"];
    total_igst["demand_id"] = this.obj1["demand_id"];
    total_igst["arr_id"] = this.obj1["arr_id"];
    total_igst["event_cd"] = "IGSTOUTPUT";
    if (total_igst["amt"] != 0) {
      event.push(total_igst);
    }

    if (event.length == 0) {
      swal.fire("Warning", "All Event are with 0 amount...", "warning");
      return;
    }
    let event_code_data = [];
    // for (let p = 0; p < event.length; p++) {
    //   event_code_data.push({
    //     act_value: this.hierobj["activity"][event[p]["act_cd"]]["leaf_value"],
    //     bud_value: this.hierobj["budget"][event[p]["bud_cd"]]["leaf_value"],
    //     prod_value: this.hierobj["product"][event[p]["prod_cd"]]["leaf_value"],
    //     proj_value: this.hierobj["project"][event[p]["proj_cd"]]["leaf_value"],
    //     act_cd: event[p]["act_cd"],
    //     bud_cd: event[p]["bud_cd"],
    //     prod_cd: event[p]["prod_cd"],
    //     proj_cd: event[p]["proj_cd"],
    //   });
    // }
    for (let p = 0; p < event.length; p++) {

      // if (this.hierobj["activity"][event[p]["act_cd"]] == undefined
      //   || this.hierobj["budget"][event[p]["bud_cd"]] == undefined
      //   || this.hierobj["product"][event[p]["prod_cd"]] == undefined
      //   || this.hierobj["project"][event[p]["proj_cd"]] == undefined) {
      //   Swal.fire("error", 'Please check Deduction Budget and Activity Mapping', 'error');
      //   this.spinner.hide();
      //   return;
      // }

      if (this.hierobj["activity"][event[p]["act_cd"]] == undefined) {
        swal.fire("error", 'Please check Activity Mapping ', 'error');
        this.spinner.hide();
        return;
      } else if (this.hierobj["budget"][event[p]["bud_cd"]]["leaf_value"] == undefined) {
        swal.fire("error", 'Please check Budget Mapping ', 'error');
        this.spinner.hide();
        return;
      } else if (this.hierobj["product"][event[p]["prod_cd"]]["leaf_value"] == undefined) {
        swal.fire("error", 'Please check Product Mapping ', 'error');
        this.spinner.hide();
      } else if (this.hierobj["project"][event[p]["proj_cd"]]["leaf_value"] == undefined) {
        swal.fire("error", 'Please check Project Mapping ', 'error');
        this.spinner.hide();
        return;
      }
      event_code_data.push({
        act_value: this.hierobj["activity"][event[p]["act_cd"]]["leaf_value"],
        bud_value: this.hierobj["budget"][event[p]["bud_cd"]]["leaf_value"],
        prod_value: this.hierobj["product"][event[p]["prod_cd"]]["leaf_value"],
        proj_value: this.hierobj["project"][event[p]["proj_cd"]]["leaf_value"],
        act_cd: event[p]["act_cd"],
        bud_cd: event[p]["bud_cd"],
        prod_cd: event[p]["prod_cd"],
        proj_cd: event[p]["proj_cd"],
      });



    }
    console.log(event_code_data);
    let objdata :any= new Object();

    objdata["b_acct_id"] = this.b_acct_id;
    objdata["data"] = event_code_data;
    console.log(objdata);
    let datae = await this.createEventService.getMultipleEventForModule(
      objdata
    );
    console.log(datae);

    let event_group_data = [];
    for (let i = 0; i < event.length; i++) {
      let obj :any= new Object();
      console.log(event[i]);
      obj["evt_grp_dt"] = new Date().toISOString().split("T")[0];
      obj["bus_event_type"] = "CHALLAN";
      obj["demand_id"] = event[i]["demand_id"];
      obj["party_id"] = event[i]["party_id"];
      let ev_cd =
        event[i]["act_cd"] +
        event[i]["bud_cd"] +
        event[i]["prod_cd"] +
        event[i]["proj_cd"];
      obj["ev_ln_dist_bus_ev_cd"] = datae["data"][ev_cd]["event_code"];
      obj["event_code"] = datae["data"][ev_cd]["event_code"];
      obj["event_id"] = event[i]["challan_id"];
      obj["event_ln_id"] = event[i]["challan_id"];
      obj["bank_acct_num"] = null;
      obj["event_desc"] = datae["data"][ev_cd]["event_desc"];
      obj["txn_amt"] = Number(event[i]["amt"].toFixed(2));
      obj["invoice_id"] = 0;
      obj["create_user_id"] = this.erpUser["user_id"];
      obj["arr_num"] = event[i]["arr_id"];
      obj["ar_num_in_src"] = event[i]["arr_id"];
      obj["ar_instr_cd"] = event[i]["arr_id"];
      obj["cp_num_in_src"] = event[i]["party_id"];
      obj["cp_nm"] = this.obj1["party_name"];
      obj["ev_grp_amt"] = event[i]["gross_amount"];
      obj["ev_grp_cts"] = event[i]["challan_generate_date"].split("T")[0];
      obj["ev_grp_ets"] = new Date().toISOString().split("T")[0];
      obj["ev_grp_id"] = event[i]["challan_id"];
      obj["ev_grp_lcl_unq_num_in_src"] = event[i]["challan_id"];
      obj["ev_grp_src_cd"] = "ACCOUNT";
      obj["ev_grp_tcd"] = "CHALLAN";
      obj["ev_ln_amt"] = Number(event[i]["amt"].toFixed(2));
      obj["ev_ln_cmnt"] = "NA";
      obj["ev_ln_dist_aff_cc_cd"] = 0;
      obj["ev_ln_dist_bdgt_cd"] = event[i]["bud_cd"];
      obj["ev_ln_dist_cc_cd"] =
        this.hierobj["project"][event[i]["proj_cd"]]["lvl2_cd"]; //cost center
      obj["ev_ln_dist_pd_cd"] = event[i]["prod_cd"];
      obj["ev_ln_dist_prj_cd"] = event[i]["proj_cd"];
      obj["ev_ln_num"] = event[i]["challan_id"];
      obj["ev_ln_org_cd"] = this.erpUser["account_short_name"];
      obj["ev_ln_sku_desc"] = 0;
      obj["ev_ln_sku_tcd"] = 0;
      obj["ev_ln_bndl_qty"] = 0;
      obj["ev_ln_calc_total_item_qty"] = 0;
      obj["ev_ln_dist_amt"] = Number(event[i]["amt"].toFixed(2));
      obj["ev_ln_dist_curr_cd"] = "INR";
      obj["ev_ln_dist_num"] = 0;
      obj["ev_ln_dist_rt"] = 0;
      obj["ev_ln_dscnt_amt"] = 0;
      obj["ev_ln_dscnt_rt"] = 0;
      obj["ev_ln_eff_calc_item_rate"] = 0;
      obj["ev_ln_item_per_pkt_qty"] = 0;
      obj["ev_ln_item_rt"] = 0;
      obj["ev_ln_manual_override_item_rt"] = 0;
      obj["ev_ln_pkt_per_bndl_qty"] = 0;
      obj["ev_ln_tax_incl_rt"] = 0;
      obj["pmt_txn_id"] = 0;
      obj["ev_ln_dist_id"] = 0;
      obj["ev_ln_dist_tcd"] = "DOCUMENT LINE";
      obj["ev_ln_id"] = event[i]["event_cd"];
      obj["ev_ln_tcd"] = "DOCUMENT LINE";
      obj["pmt_cp_ar_src_cd"] = 0;
      obj["pmt_cp_ar_num_in_src"] = 0;
      obj["pmt_cp_ar_instr_cd"] = 0;
      obj["pmt_cp_num_in_src"] = 0;
      obj["pmt_cp_ev_grp_id"] = 0;
      obj["pmt_cp_ev_grp_src_cd"] = 0;
      obj["pmt_cp_ev_grp_tcd"] = 0;
      obj["pmt_cp_ev_grp_lcl_unq_num_in_src"] = 0;
      obj["pmt_cp_nm"] = 0;
      obj["pmt_rp_bnk_acct_id"] = 0;
      obj["pmt_cp_bnk_acct_id"] = 0;
      obj["pmt_cp_txn_id"] = 0;

      event_group_data.push(Object.assign({}, obj));
    }

    console.log(event_group_data);
    let eventgroupobj:any = new Object();

    let netPayable = Number(event[0]["net_amount"].toFixed(0));
    eventgroupobj["is_rec_or_pay"] = "RECEIVABLE";
    eventgroupobj["local_doc_no"] = event[0]["challan_id"];
    // eventgroupobj["local_doc_desc"] =
    //   "1st Challan of " +
    //   this.obj1["party_name"] +
    //   " for " +
    //   this.obj1["purpose"];
    eventgroupobj["local_doc_desc"] = this.postpixAdd(countBill) + " challan of " + this.obj1["party_name"] + " for " + this.obj1["purpose"];
    eventgroupobj["module_cd"] = "ACCOUNT";
    eventgroupobj["module_doc_type"] = "CHALLAN";
    eventgroupobj["status"] = "APPROVED";
    eventgroupobj["total_amt"] = netPayable;
    eventgroupobj["doc_type"] = "CHALLAN";
    eventgroupobj["data"] = event_group_data;
    eventgroupobj["create_user_id"] = this.erpUser["user_id"];
    eventgroupobj["b_acct_id"] = this.erpUser["b_acct_id"];
    eventgroupobj["req_data"] = JSON.stringify({
      bill_id: event[0]["challan_id"],
      node_cd: event[0]["arr_id"],
    });
    console.log(eventgroupobj);
    this.spinner.hide();
    let resp1:any = await this.createEventService.createevent(eventgroupobj);
    if (resp1["error"] == false) {
      var obj11:any = new Object();
      obj11["b_acct_id"] = this.b_acct_id;
      obj11["status"] = "PAID";
      obj11["id"] = [element.id];
      obj11["update_user_id"] = this.erpUser.user_id;
      this.spinner.show();
      var resp = await this.challanService.updateChallanStatus(obj11);
      if (resp["error"] == false) {
        this.spinner.hide();
        this.getChallanInfo();
        swal.fire("Success", "...Challan PAID Successfuly!", "success");
      } else {
        this.spinner.hide();
        swal.fire("Error", "...Error while paid Challan!!", "error");
      }
    } else {
      swal.fire("Error", "...Error while paid Challan!!", "error");
    }
  }

  eventObj = {};

  async getAllBankAccountInfo() {
    var resp = await this.challanService.getAccountInfo(this.b_acct_id);
    if (resp["error"] == false) {
      this.allAccountInfo = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while getting all account Type!", "error");
    }
  }

  changeParty() {
    for (let i = 0; i < this.partyArr.length; i++) {
      if (this.challanObj["party_id"] == this.partyArr[i]["party_id"]) {
        this.challanObj["party_name"] = this.partyArr[i]["party_name"];
        this.challanObj["party_email"] = this.partyArr[i]["email"];
        this.challanObj["party_phone_no"] = this.partyArr[i]["phone_no"];
        this.challanObj["party_address"] =
          this.partyArr[i]["addr_line_1"] +
          "," +
          this.partyArr[i]["addr_line_2"] +
          "," +
          this.partyArr[i]["city"] +
          "," +
          this.partyArr[i]["zip"];
      }
    }
  }

  changeHSN(i:any) {
    this.amt_flag=false;
    for (let j = 0; j < this.allHSNAccounts.length; j++) {
      if (
        this.allHSNAccounts[j]["hsn_code"] == this.challanRows[i]["hsn_code"]
      ) {
        this.challanRows[i]["igst"] = this.allHSNAccounts[j]["igst"];
      }
    }
  }

  changeGSTType(i:any) {
    this.amt_flag=false;
    if (this.challanRows[i]["gst_type"] == "IGST") {
      this.challanRows[i]["gst_amount"] =
        (parseInt(this.challanRows[i]["amount"]) *
          this.challanRows[i]["igst"]) /
        100;
      this.challanRows[i]["igst_amount"] =
        (parseInt(this.challanRows[i]["amount"]) *
          this.challanRows[i]["igst"]) /
        100;
      this.challanRows[i]["cgst_amount"] = 0;
      this.challanRows[i]["sgst_amount"] = 0;
    } else {
      this.challanRows[i]["gst_amount"] =
        (parseInt(this.challanRows[i]["amount"]) *
          this.challanRows[i]["igst"]) /
        100;
      this.challanRows[i]["igst_amount"] = 0;
      this.challanRows[i]["cgst_amount"] =
        (parseInt(this.challanRows[i]["amount"]) *
          this.challanRows[i]["igst"]) /
        200;
      this.challanRows[i]["sgst_amount"] =
        (parseInt(this.challanRows[i]["amount"]) *
          this.challanRows[i]["igst"]) /
        200;
    }
  }
  SubmitSelectedEvnetHier() { }


  addRow() {
    this.challanRows.push({
      amount: 0,
      gst_amount: 0,
      igst: 0,
      total_amount: 0,
      cgst_amount: 0,
      sgst_amount: 0,
      igst_amount: 0,
    });
  }

  deleteRow(i:any) {
    this.challanRows.splice(i, 1);
  }

  refresh() {
    this.challanRows = [];
    this.challanObj = new Object();
    this.totalAmt = 0;
    this.totalGst = 0;
    this.netTotal = 0;
    this.addRow();
    document.getElementById('subreset')!.click();
  }

  igstAmount = 0;
  cgstAmount = 0;
  sgstAmount = 0;
  async check() {
    this.totalAmt = 0;
    this.totalGst = 0;
    this.netTotal = 0;
    this.igstAmount = 0;
    this.cgstAmount = 0;
    this.sgstAmount = 0;
    let obj = new Object();
    for (let i = 0; i < this.challanRows.length; i++) {
      this.challanRows[i]["total_amount"] =
        this.challanRows[i]["amount"] + this.challanRows[i]["gst_amount"];
      this.totalAmt = this.totalAmt + this.challanRows[i]["amount"];
      this.totalGst = this.totalGst + this.challanRows[i]["gst_amount"];
      this.netTotal = this.netTotal + this.challanRows[i]["total_amount"];
      this.igstAmount = this.igstAmount + this.challanRows[i]["igst_amount"];
      this.cgstAmount = this.cgstAmount + this.challanRows[i]["cgst_amount"];
      this.sgstAmount = this.sgstAmount + this.challanRows[i]["sgst_amount"];
    }
  }

  changeBank() {
    for (let i = 0; i < this.allAccountInfo.length; i++) {
      if (this.challanObj["account_id"] == this.allAccountInfo[i]["id"]) {
        this.challanObj["bank_code"] = this.allAccountInfo[i]["bank_code"];
        this.challanObj["branch_code"] = this.allAccountInfo[i]["branch_code"];
        this.challanObj["bank_acct_no"] =
          this.allAccountInfo[i]["bank_acct_no"];
        this.challanObj["ifsc_code"] = this.allAccountInfo[i]["ifsc_code"];
      }
    }
  }


  amt_flag = false;

  async validation() {
    let fg: boolean = true;

    // if (this.challanRows.length == 0) {
    //   // swal.fire("warring", "Challan can not be submit without any amount", 'warning');
    //   this.amt_flag = true;
    //   return fg = false;
    // }

    let flag1 = false;
    this.challanRows.map((x:any) => {
      if (x.amount == 0 || x.amount == '' || x.payable_amount == 0) {
        flag1 = true;
      }
    })


    if (flag1) {
      this.spinner.hide();
      // swal.fire("warring", "Challan can not be submit without any amount", 'warning');
      this.amt_flag=true;
      return fg = false;
    }
     if (!this.budgetObj[this.challanObj['bud_cd']]) {
      // swal.fire("warring", "Please Select Project ", 'warning');
      return fg = false;
    }
    return fg;
  }

  async createChallan() {
    let val = await this.validation();
    console.log(val)

    if (! await this.validation()) {
      return;
    }

    this.amt_flag=false;

    this.check();
    this.spinner.show();
    this.challanObj["amount"] = this.netTotal;
    var obj1 :any= new Object();
    obj1["obj"] = Object.assign(this.challanObj);
    obj1["arr"] = Object.assign(this.challanRows);
    var obj = Object.assign(this.challanObj);
    obj["b_acct_id"] = this.b_acct_id;
    obj["create_user_id"] = this.erpUser.user_id;
    obj["data"] = JSON.stringify(obj1);
    obj["challan_source"] = "ACC";
    obj["status"] = "GENERATED";

    var resp = await this.challanService.createChallan(obj);
    if (resp["error"] == false) {
      await this.getChallanInfo();

      this.spinner.hide();
      this.amt_flag=false;
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      document.getElementById('subreset')!.click();
      swal.fire("Success", "...Challan Generated Successfuly!", "success");

      this.refresh()
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while generating challan!", "error");
    }
  }

  open_update(element:any) {
    var obj = JSON.parse(element.data);
    this.challanRows = obj["arr"];
    this.challanObj = obj["obj"];
    this.challanObj["id"] = element.id;
    this.challanObj["challan_source"] = element["challan_source"];
    this.challanObj["status"] = element["status"];
    this.challanObj["challan_generate_date"] = element["challan_generate_date"];
    this.challanObj["amount"] = element["amount"];

    this.check();
    $('.nav-tabs a[href="#tab-3"]').tab("show");
  }

  allChallan = [];
  async getChallanInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.challanService.getChallanInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allChallan = resp.data;
      console.log(this.allChallan)
      console.log(this.allWork)
      let challan:any = [];
      for (let i = 0; i < this.allChallan.length; i++) {
        for (let j = 0; j < this.allWork.length; j++) {
          if (this.allChallan[i]['work_id'] == this.allWork[j]['work_id']) {
            challan.push(this.allChallan[i]);
          }
        }
      }
      this.allChallan = challan;
      console.log(this.allChallan)
      this.datasource = new MatTableDataSource(this.allChallan);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while getting challan!", "error");
    }
  }

  async updateChallan() {

    this.amt_flag=false;
    if (!await this.validation()) {
      return;
    }
    this.spinner.show();
    this.check();
    this.challanObj["amount"] = this.netTotal;
    var obj1:any = new Object();
    obj1["obj"] = Object.assign(this.challanObj);
    obj1["arr"] = Object.assign(this.challanRows);

    var obj = Object.assign(this.challanObj);
    obj["b_acct_id"] = this.b_acct_id;
    obj["update_user_id"] = this.erpUser.user_id;
    obj["data"] = JSON.stringify(obj1);

    var resp = await this.challanService.updateChallan(obj);
    if (resp["error"] == false) {
      await this.getChallanInfo();

      this.spinner.hide();
      swal.fire("Success", "...Challan Updated Successfuly!", "success");
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while updating challan!", "error");
    }
  }

  async deleteChallan(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = element["id"];
    this.spinner.show();
    var resp = await this.challanService.deleteChallan(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.dummy_obj = {};
      this.RecivalbeAccount = [];
      await this.getChallanInfo();
      this.spinner.hide();
      swal.fire("Success", "...Challan Deleted Successfuly!", "success");
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while deleting challan!", "error");
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  async print(element:any) {
    var obj = JSON.parse(element.data);
    this.challanRows = obj["arr"];
    this.challanObj = obj["obj"];
    this.challanObj["id"] = element.id;
    this.challanObj["challan_source"] = element["challan_source"];
    this.challanObj["status"] = element["status"];
    this.challanObj["challan_generate_date"] = element["challan_generate_date"];
    this.challanObj["amount"] = element["amount"];
    await this.check();
    await this.print1();
  }
  async print1() {
    var txt =
      this.mainService.accInfo["account_name"] +
      "(" +
      this.mainService.accInfo["account_short_name"] +
      ")";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt + "  Page No. - " + currentPage,
          alignment: "left",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        var obj1 = {
          text: "CONTINGENT BILL",
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj1);
        return arr;
      },
      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    var header_copy1 = {
      columns: [
        {
          width: "*",
          text:
            this.mainService.accInfo["account_name"] +
            "-" +
            this.mainService.accInfo["account_short_name"] +
            "'s Copy:",
          bold: true,
          alignment: "left",
        },
      ],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Demand Id :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["demand_id"],
        },
        {
          width: "*",
          text: "Print Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.systemDate),
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "Challan Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(
            this.challanObj["challan_generate_date"]
          ),
        },
        {
          width: "*",
          text: "Challan No :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["id"],
        },
      ],
    };
    var header3 = {
      columns: [
        {
          width: "*",
          text: "Bank Name :",
          bold: true,
        },
        {
          width: "*",
          text:
            this.challanObj["bank_code"] +
            " - " +
            this.challanObj["branch_code"] +
            " - " +
            this.challanObj["bank_acct_no"] +
            " - " +
            this.challanObj["ifsc_code"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    var header4 = {
      columns: [
        {
          width: "*",
          text: "Applicant Name: ",
        },
        {
          width: "*",
          text: this.challanObj["party_name"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header5 = {
      columns: [
        {
          width: "*",
          text: "Mobile Number: ",
        },
        {
          width: "*",
          text: this.challanObj["party_phone_no"],
        },
        {
          width: "*",
          text: "Email: ",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["party_email"],
        },
      ],
    };

    var header6 = {
      columns: [
        {
          width: "*",
          text: "Address: ",
        },
        {
          width: "*",
          text: this.challanObj["party_address"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header7 = {
      columns: [
        {
          width: "*",
          text: "Purpose: ",
        },
        {
          width: "*",
          text: this.challanObj["purpose"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header_copy1);
    //print_data.push(header_copy1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header1);
    //print_data.push(header1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header2);
    //print_data.push(header2)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header3);
    //print_data.push(header3)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header4);
    //print_data.push(header4)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header5);
    //print_data.push(header5)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header6);
    //print_data.push(header6)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header7);
    //print_data.push(header7)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var header8 = {
      columns: [
        {
          width: "*",
          text: "HEAD DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "HSN",
          bold: true,
        },
        {
          width: "*",
          text: "RATE",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "CGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "SGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "IGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(header8);
    //print_data.push(header8)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    for (var i = 0; i < this.challanRows.length; i++) {
      var objRow = {
        columns: [
          {
            width: "*",
            text: this.challanRows[i]["event_desc"],
            bold: true,
          },
          {
            width: "*",
            text: this.hsnObj[this.challanRows[i]["hsn_code"]],
          },
          {
            width: "*",
            text: this.challanRows[i]["igst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["cgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["sgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["igst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["total_amount"],
            bold: true,
            alignment: "right",
          },
        ],
      };

      dd.content.push(objRow);
      //print_data.push(objRow)

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })

      dd.content.push({
        canvas: [
          { type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 },
        ],
      });
      //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })
    }

    var totalObjRow = {
      columns: [
        {
          width: "*",
          text: "TOTAL",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: this.totalAmt.toFixed(2),
          bold: true,
          alignment: "right",
        },

        {
          width: "*",
          text: this.cgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.sgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.igstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.netTotal.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(totalObjRow);
    //print_data.push(totalObjRow)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var sig = {
      columns: [
        {
          width: "*",
          text: "Applicant's Signature",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "Receiver's Signature",
          bold: true,
        },
      ],
    };
    dd.content.push(sig);
    //print_data.push(sig)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    //Main Office copy

    var header_copy1 = {
      columns: [
        {
          width: "*",
          text: "Bank Copy:",
          bold: true,
          alignment: "left",
        },
      ],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Demand Id :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["demand_id"],
        },
        {
          width: "*",
          text: "Print Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.systemDate),
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "Challan Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(
            this.challanObj["challan_generate_date"]
          ),
        },
        {
          width: "*",
          text: "Challan No :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["id"],
        },
      ],
    };
    var header3 = {
      columns: [
        {
          width: "*",
          text: "Bank Name :",
          bold: true,
        },
        {
          width: "*",
          text:
            this.challanObj["bank_code"] +
            " - " +
            this.challanObj["branch_code"] +
            " - " +
            this.challanObj["bank_acct_no"] +
            " - " +
            this.challanObj["ifsc_code"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    var header4 = {
      columns: [
        {
          width: "*",
          text: "Applicant Name: ",
        },
        {
          width: "*",
          text: this.challanObj["party_name"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header5 = {
      columns: [
        {
          width: "*",
          text: "Mobile Number: ",
        },
        {
          width: "*",
          text: this.challanObj["party_phone_no"],
        },
        {
          width: "*",
          text: "Email: ",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["party_email"],
        },
      ],
    };

    var header6 = {
      columns: [
        {
          width: "*",
          text: "Address: ",
        },
        {
          width: "*",
          text: this.challanObj["party_address"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header7 = {
      columns: [
        {
          width: "*",
          text: "Purpose: ",
        },
        {
          width: "*",
          text: this.challanObj["purpose"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
      pageBreak: "before",
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header_copy1);
    //print_data.push(header_copy1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header1);
    //print_data.push(header1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header2);
    //print_data.push(header2)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header3);
    //print_data.push(header3)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header4);
    //print_data.push(header4)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header5);
    //print_data.push(header5)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header6);
    //print_data.push(header6)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header7);
    //print_data.push(header7)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var header8 = {
      columns: [
        {
          width: "*",
          text: "HEAD DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "HSN",
          bold: true,
        },
        {
          width: "*",
          text: "RATE",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "CGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "SGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "IGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
          alignment: "right",
        },
      ],
    };

    dd.content.push(header8);
    //print_data.push(header8)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    for (var i = 0; i < this.challanRows.length; i++) {
      var objRow1 = {
        columns: [
          {
            width: "*",
            text: this.challanRows[i]["event_desc"],
            bold: true,
          },
          {
            width: "*",
            text: this.hsnObj[this.challanRows[i]["hsn_code"]],
          },
          {
            width: "*",
            text: this.challanRows[i]["igst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["cgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["sgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["igst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["total_amount"],
            bold: true,
            alignment: "right",
          },
        ],
      };

      dd.content.push(objRow1);
      //print_data.push(objRow)

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })

      dd.content.push({
        canvas: [
          { type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 },
        ],
      });
      //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })
    }

    var totalObjRow = {
      columns: [
        {
          width: "*",
          text: "TOTAL",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: this.totalAmt.toFixed(2),
          bold: true,
          alignment: "right",
        },

        {
          width: "*",
          text: this.cgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.sgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.igstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.netTotal.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(totalObjRow);
    //print_data.push(totalObjRow)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var sig = {
      columns: [
        {
          width: "*",
          text: "Applicant's Signature",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "Receiver's Signature",
          bold: true,
        },
      ],
    };
    dd.content.push(sig);
    //print_data.push(sig)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " });

    //Receiver copy

    var header_copy1 = {
      columns: [
        {
          width: "*",
          text:
            this.mainService.accInfo["account_name"] + "-" + "Receiver's Copy:",
          bold: true,
          alignment: "left",
        },
      ],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text: "Demand Id :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["demand_id"],
        },
        {
          width: "*",
          text: "Print Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.systemDate),
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "Challan Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(
            this.challanObj["challan_generate_date"]
          ),
        },
        {
          width: "*",
          text: "Challan No :",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["id"],
        },
      ],
    };
    var header3 = {
      columns: [
        {
          width: "*",
          text: "Bank Name :",
          bold: true,
        },
        {
          width: "*",
          text:
            this.challanObj["bank_code"] +
            " - " +
            this.challanObj["branch_code"] +
            " - " +
            this.challanObj["bank_acct_no"] +
            " - " +
            this.challanObj["ifsc_code"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    var header4 = {
      columns: [
        {
          width: "*",
          text: "Applicant Name: ",
        },
        {
          width: "*",
          text: this.challanObj["party_name"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header5 = {
      columns: [
        {
          width: "*",
          text: "Mobile Number: ",
        },
        {
          width: "*",
          text: this.challanObj["party_phone_no"],
        },
        {
          width: "*",
          text: "Email: ",
          bold: true,
        },
        {
          width: "*",
          text: this.challanObj["party_email"],
        },
      ],
    };

    var header6 = {
      columns: [
        {
          width: "*",
          text: "Address: ",
        },
        {
          width: "*",
          text: this.challanObj["party_address"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };

    var header7 = {
      columns: [
        {
          width: "*",
          text: "Purpose: ",
        },
        {
          width: "*",
          text: this.challanObj["purpose"],
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
      pageBreak: "before",
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header_copy1);
    //print_data.push(header_copy1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header1);
    //print_data.push(header1)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header2);
    //print_data.push(header2)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header3);
    //print_data.push(header3)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header4);
    //print_data.push(header4)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header5);
    //print_data.push(header5)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header6);
    //print_data.push(header6)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push(header7);
    //print_data.push(header7)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var header8 = {
      columns: [
        {
          width: "*",
          text: "HEAD DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "HSN",
          bold: true,
        },
        {
          width: "*",
          text: "RATE",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "CGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "SGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "IGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(header8);
    //print_data.push(header8)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    for (var i = 0; i < this.challanRows.length; i++) {
      var objRow5 = {
        columns: [
          {
            width: "*",
            text: this.challanRows[i]["event_desc"],
            bold: true,
          },
          {
            width: "*",
            text: this.hsnObj[this.challanRows[i]["hsn_code"]],
            alignment: "center",
          },
          {
            width: "*",
            text: this.challanRows[i]["igst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["cgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["sgst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["igst_amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.challanRows[i]["total_amount"],
            bold: true,
            alignment: "right",
          },
        ],
      };

      dd.content.push(objRow5);
      //print_data.push(objRow)

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })

      dd.content.push({
        canvas: [
          { type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 },
        ],
      });
      //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }] })

      dd.content.push({ text: " " });
      //print_data.push({ text: " " })
    }

    var totalObjRow = {
      columns: [
        {
          width: "*",
          text: "TOTAL",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: this.totalAmt.toFixed(2),
          bold: true,
          alignment: "right",
        },

        {
          width: "*",
          text: this.cgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.sgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.igstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.netTotal.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(totalObjRow);
    //print_data.push(totalObjRow)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    //print_data.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }] })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    var sig = {
      columns: [
        {
          width: "*",
          text: "Applicant's Signature",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },
        {
          width: "*",
          text: "Receiver's Signature",
          bold: true,
        },
      ],
    };
    dd.content.push(sig);
    //print_data.push(sig)

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    dd.content.push({ text: " " });
    //print_data.push({ text: " " })

    pdfMake.createPdf(dd).download("Challan.pdf");
  }





  //*********************Unused Code..........................************************ */

  //******************************************************************************** */
  /* var paid = [];
    var processed = [];
    var generated = [];

    for (let i = 0; i < this.allChallan.length; i++) {
      if (this.allChallan[i]['select'] == true) {
        if (this.allChallan[i]['status'] == 'GENERATED') {
          generated.push(this.allChallan[i]);
        } else if (this.allChallan[i]['status'] == 'PROCESSED') {
          processed.push(this.allChallan[i]);
        } else if (this.allChallan[i]['status'] == 'PAID') {
          paid.push(this.allChallan[i]);
        }
      }
    }

    if (paid.length != 0 || processed.length != 0) {
      swal.fire("Error", "...In the Selected Challan " + paid.length + " Of PAID Challan And " + processed.length + " Of PROCESSED Challan!!", 'error');
      return;
    } else {
      var id = [];
      for (let i = 0; i < generated.length; i++) {
        id.push(generated[i]['id']);
      }
      var obj:any = new Object();
      obj['b_acct_id'] = this.b_acct_id;
      obj['status'] = 'PAID';
      obj['id'] = [element.id];
      obj['update_user_id'] = this.erpUser.user_id;

      this.spinner.show();
      var resp = await this.challanService.updateChallanStatus(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        this.getChallanInfo();
        swal.fire("Success", "...Challan PAID Successfuly!", 'success');
      } else {
        this.spinner.hide();
        swal.fire("Error", "...Error while paid Challan!!", 'error');

      }

    } */

  //*******************************************************************************************
  // allRule = [];
  // async getAllRuleList() {
  //   var resp = await this.ruleService.getAllRules(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.allRule = resp.data;
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting  all rule list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  // async process() {
  //   var paid = [];
  //   var processed = [];
  //   var generated = [];

  //   for (let i = 0; i < this.allChallan.length; i++) {
  //     if (this.allChallan[i]['select'] == true) {
  //       if (this.allChallan[i]['status'] == 'GENERATED') {
  //         generated.push(this.allChallan[i]);
  //       } else if (this.allChallan[i]['status'] == 'PROCESSED') {
  //         processed.push(this.allChallan[i]);
  //       } else if (this.allChallan[i]['status'] == 'PAID') {
  //         paid.push(this.allChallan[i]);
  //       }
  //     }
  //   }

  //   if (generated.length != 0 || processed.length != 0) {
  //     swal.fire("Error", "...In the Selected Challan " + generated.length + " Of Generated Challan And " + processed.length + " Of PROCESSED Challan!!", 'error');
  //     return;
  //   } else {

  //     var data = await this.processPaidChallanData(paid);
  //     var processed_data = await this.ruleProcessService.startProcessing(data, [], this.systemDate, this.fin_year, this.orgShortName);
  //     if (processed_data['event'].length > 0) {
  //       swal.fire("Some Events does not have rule to be processed", '', 'info');
  //     } else {
  //       var id = [];
  //       for (let i = 0; i < paid.length; i++) {
  //         id.push(paid[i]['id']);
  //       }
  //       var obj:any = new Object();
  //       obj['b_acct_id'] = this.b_acct_id;
  //       obj['status'] = 'PROCESSED';
  //       obj['id'] = id;
  //       obj['jrnl'] = processed_data['jrnl'];
  //       obj['update_user_id'] = this.erpUser.user_id;
  //       this.spinner.show();
  //       var resp = await this.challanService.insertProcessedChallanData(obj);
  //       if (resp['error'] == false) {
  //         this.spinner.hide();
  //         this.getChallanInfo();
  //         swal.fire("Success", "...Challan PRCCESSED Successfuly!", 'success');
  //       } else {
  //         this.spinner.hide();
  //         swal.fire("Error", "Some Events does not have rule!!", 'error');

  //       }
  //     }

  //   }
  // }

  // processPaidChallanData(paid) {
  //   var temp = [];

  //   for (let i = 0; i < paid.length; i++) {
  //     var challanRows = [];
  //     var challanObj = new Object();

  //     var obj = JSON.parse(paid[i]['data'])
  //     challanRows = obj['arr'];
  //     challanObj = obj['obj'];
  //     for (let j = 0; j < challanRows.length; j++) {

  //       var obj_temp1 = new Object();
  //       obj_temp1['evt_grp_dt'] = paid[i]['challan_generate_date']
  //       obj_temp1['bus_event_type'] = 'CHALLAN';
  //       obj_temp1['demand_id'] = challanObj['demand_id'];
  //       obj_temp1['party_id'] = challanObj['party_id'];
  //       obj_temp1['event_code'] = challanRows[j]['event_code'];
  //       obj_temp1['event_id'] = paid[i]['id'];
  //       obj_temp1['event_ln_id'] = j + 1;
  //       obj_temp1['bank_acct_num'] = challanRows[j]['bank_acct_no'];
  //       obj_temp1['ev_desc'] = challanRows[j]['event_desc'];
  //       obj_temp1['event_desc'] = challanObj['purpose'];
  //       obj_temp1['txn_amt'] = challanRows[j]['amount'];
  //       obj_temp1['create_user_id'] = this.erpUser.user_id;
  //       obj_temp1['arr_num'] = challanObj['party_id'];
  //       obj_temp1['party_name'] = challanObj['party_name'];
  //       if (challanRows[j]['cgst_amount'] != 0) {
  //         var obj_temp2 = new Object();
  //         obj_temp2['evt_grp_dt'] = paid[i]['challan_generate_date']
  //         obj_temp2['bus_event_type'] = 'CHALLAN';
  //         obj_temp2['demand_id'] = challanObj['demand_id'];
  //         obj_temp2['party_id'] = challanObj['party_id'];
  //         obj_temp2['event_code'] = "CGSTOUTPUT";
  //         obj_temp2['event_id'] = paid[i]['id'];
  //         obj_temp2['event_ln_id'] = j + 1;
  //         obj_temp2['bank_acct_num'] = challanRows[j]['bank_acct_no'];
  //         obj_temp2['ev_desc'] = 'CGSTOUTPUT'
  //         obj_temp2['event_desc'] = challanObj['purpose'];
  //         obj_temp2['txn_amt'] = challanRows[j]['cgst_amount'];
  //         obj_temp2['create_user_id'] = this.erpUser.user_id;
  //         obj_temp2['arr_num'] = challanObj['party_id'];
  //         obj_temp2['party_name'] = challanObj['party_name'];
  //         temp.push(obj_temp2);
  //       }
  //       if (challanRows[j]['sgst_amount'] != 0) {
  //         var obj_temp3 = new Object();
  //         obj_temp3['evt_grp_dt'] = paid[i]['challan_generate_date']
  //         obj_temp3['bus_event_type'] = 'CHALLAN';
  //         obj_temp3['demand_id'] = challanObj['demand_id'];
  //         obj_temp3['party_id'] = challanObj['party_id'];
  //         obj_temp3['event_code'] = "SGSTOUTPUT";
  //         obj_temp3['event_id'] = paid[i]['id'];
  //         obj_temp3['event_ln_id'] = j + 1;
  //         obj_temp3['bank_acct_num'] = challanRows[j]['bank_acct_no'];
  //         obj_temp3['ev_desc'] = 'SGSTOUTPUT'
  //         obj_temp3['event_desc'] = challanObj['purpose'];
  //         obj_temp3['txn_amt'] = challanRows[j]['sgst_amount'];
  //         obj_temp3['create_user_id'] = this.erpUser.user_id;
  //         obj_temp3['arr_num'] = challanObj['party_id'];
  //         obj_temp3['party_name'] = challanObj['party_name'];
  //         temp.push(obj_temp3);
  //       }
  //       if (challanRows[j]['igst_amount'] != 0) {
  //         var obj_temp4 = new Object();
  //         obj_temp4['evt_grp_dt'] = paid[i]['challan_generate_date']
  //         obj_temp4['bus_event_type'] = 'CHALLAN';
  //         obj_temp4['demand_id'] = challanObj['demand_id'];
  //         obj_temp4['party_id'] = challanObj['party_id'];
  //         obj_temp4['event_code'] = "IGSTOUTPUT";
  //         obj_temp4['event_id'] = paid[i]['id'];
  //         obj_temp4['event_ln_id'] = j + 1;
  //         obj_temp4['bank_acct_num'] = challanRows[j]['bank_acct_no'];
  //         obj_temp4['event_desc'] = challanObj['purpose'];
  //         obj_temp4['txn_amt'] = challanRows[j]['igst_amount'];
  //         obj_temp4['ev_desc'] = 'IGSTOUTPUT'
  //         obj_temp4['create_user_id'] = this.erpUser.user_id;
  //         obj_temp4['arr_num'] = challanObj['party_id'];
  //         obj_temp4['party_name'] = challanObj['party_name'];
  //         temp.push(obj_temp4);
  //       }

  //       temp.push(obj_temp1);

  //     }
  //   }

  //   return temp;
  // }


  // async getIp() {
  //   this.spinner.show();

  //   var resp = await this.challanService.getPartyInfo(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.partyArr = resp.data;
  //     this.partyArrObj = new Object
  //     for (let i = 0; i < this.partyArr.length; i++) {
  //       this.partyArrObj[this.partyArr[i]['party_id']] = this.partyArr[i]['party_name']
  //     }

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     this.snackBar.open("Error occured while getting Schemes", 'Error', {
  //       duration: 5000,
  //     });
  //   }
  // }

  //Unused code...***********************************************************************


  ////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////

  // async FilteredEvents() {
  //   this.selectObj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.eventsService.getFilteredEvents(this.selectObj);
  //   if (resp['error'] == false) {
  //     this.allEvents = resp.data;
  //     for (let i = 0; i < this.allEvents.length; i++) {
  //       this.eventObj[this.allEvents[i]['event_code']] = this.allEvents[i]['event_desc']
  //     }

  //     this.dataSource1 = new MatTableDataSource(resp.data);
  //     this.dataSource1.sort = this.sortCol2;
  //     this.dataSource1.paginator = this.paginator1;
  //   } else {
  //   }
  // }

  // select(element:any) {
  //   this.challanRows[this.index]['event_code'] = element['event_code'];
  //   this.challanRows[this.index]['event_desc'] = element['event_desc'];
  //   $('#myModal').modal('hide');
  // }

  // changeProject() {
  //   for (let i = 0; i < this.allProject.length; i++) {
  //     if (this.selectObj['proj_cd'] == this.allProject[i]['code']) {
  //       this.selectObj['proj_desc'] = this.allProject[i]['desc'];
  //       this.selectObj['proj_lvl'] = this.allProject[i]['level'];
  //     }
  //   }
  // }

  // changeProduct() {
  //   for (let i = 0; i < this.allProduct.length; i++) {
  //     if (this.selectObj['prod_cd'] == this.allProduct[i]['code']) {
  //       this.selectObj['prod_desc'] = this.allProduct[i]['desc'];
  //       this.selectObj['prod_lvl'] = this.allProduct[i]['level'];
  //     }
  //   }
  // }

  // changeActivity() {
  //   for (let i = 0; i < this.allActivity.length; i++) {
  //     if (this.selectObj['act_cd'] == this.allActivity[i]['code']) {
  //       this.selectObj['act_desc'] = this.allActivity[i]['desc'];
  //       this.selectObj['act_lvl'] = this.allActivity[i]['level'];
  //     }
  //   }
  // }

  // changeBudget() {
  //   for (let i = 0; i < this.allBudget.length; i++) {
  //     if (this.selectObj['bud_cd'] == this.allBudget[i]['code']) {
  //       this.selectObj['bud_desc'] = this.allBudget[i]['desc'];
  //       this.selectObj['bud_lvl'] = this.allBudget[i]['level'];
  //     }
  //   }
  // }

  // setData() {

  //   this.allBudget = [];
  //   var temp = [];
  //   for (let i = 0; i < this.allBudgetHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj['code'] = this.allBudgetHier[i]['lvl' + j + "_cd"]
  //       obj['value'] = this.allBudgetHier[i]['lvl' + j + "_value"]
  //       obj['level'] = j
  //       obj['desc'] = this.allBudgetHier[i]['lvl' + j + "_cd"] + " - " + this.allBudgetHier[i]['lvl' + j + "_value"] + " - " + 'Level ' + j;
  //       if ((temp.indexOf(this.allBudgetHier[i]['lvl' + j + "_cd"]) < 0) && this.allBudgetHier[i]['lvl' + j + "_cd"] != null) {
  //         this.allBudget.push(obj);
  //         temp.push(this.allBudgetHier[i]['lvl' + j + "_cd"])
  //       }
  //     }

  //     var obj:any = new Object();
  //     obj['code'] = this.allBudgetHier[i]['leaf_cd']
  //     obj['value'] = this.allBudgetHier[i]['leaf_value']
  //     obj['level'] = 'L'
  //     obj['desc'] = this.allBudgetHier[i]['leaf_cd'] + " - " + this.allBudgetHier[i]['leaf_value'] + " - Leaf";
  //     var p = temp.indexOf(this.allBudgetHier[i]['leaf_cd'])
  //     this.allBudget.splice(p, 1)
  //     this.allBudget.push(obj)
  //   }

  //   temp = []
  //   this.allProduct = [];
  //   for (let i = 0; i < this.allProductHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj['code'] = this.allProductHier[i]['lvl' + j + "_cd"]
  //       obj['value'] = this.allProductHier[i]['lvl' + j + "_value"]
  //       obj['level'] = j
  //       obj['desc'] = this.allProductHier[i]['lvl' + j + "_cd"] + " - " + this.allProductHier[i]['lvl' + j + "_value"] + " - " + 'Level ' + j;
  //       if ((temp.indexOf(this.allProductHier[i]['lvl' + j + "_cd"]) < 0) && this.allProductHier[i]['lvl' + j + "_cd"] != null) {
  //         this.allProduct.push(obj);
  //         temp.push(this.allProductHier[i]['lvl' + j + "_cd"])
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj['code'] = this.allProductHier[i]['leaf_cd']
  //     obj['value'] = this.allProductHier[i]['leaf_value']
  //     obj['level'] = 'L'
  //     obj['desc'] = this.allProductHier[i]['leaf_cd'] + " - " + this.allProductHier[i]['leaf_value'] + " - Leaf";
  //     var p = temp.indexOf(this.allProductHier[i]['leaf_cd'])
  //     this.allProduct.splice(p, 1)
  //     this.allProduct.push(obj);
  //   }

  //   temp = [];
  //   this.allProject = [];
  //   for (let i = 0; i < this.allProjectHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj['code'] = this.allProjectHier[i]['lvl' + j + "_cd"]
  //       obj['value'] = this.allProjectHier[i]['lvl' + j + "_value"]
  //       obj['level'] = j
  //       obj['desc'] = this.allProjectHier[i]['lvl' + j + "_cd"] + " - " + this.allProjectHier[i]['lvl' + j + "_value"] + " - " + 'Level ' + j;
  //       if ((temp.indexOf(this.allProjectHier[i]['lvl' + j + "_cd"]) < 0) && this.allProjectHier[i]['lvl' + j + "_cd"] != null) {
  //         this.allProject.push(obj);
  //         temp.push(this.allProjectHier[i]['lvl' + j + "_cd"])
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj['code'] = this.allProjectHier[i]['leaf_cd']
  //     obj['value'] = this.allProjectHier[i]['leaf_value']
  //     obj['level'] = 'L'
  //     obj['desc'] = this.allProjectHier[i]['leaf_cd'] + " - " + this.allProjectHier[i]['leaf_value'] + " - Leaf";
  //     var p = temp.indexOf(this.allProjectHier[i]['leaf_cd'])
  //     this.allProject.splice(p, 1)
  //     this.allProject.push(obj);

  //   }

  //   temp = [];
  //   this.allActivity = [];
  //   for (let i = 0; i < this.allActivityHier.length; i++) {
  //     for (let j = 1; j <= 7; j++) {
  //       var obj:any = new Object();
  //       obj['code'] = this.allActivityHier[i]['lvl' + j + "_cd"]
  //       obj['value'] = this.allActivityHier[i]['lvl' + j + "_value"]
  //       obj['level'] = j
  //       obj['desc'] = this.allActivityHier[i]['lvl' + j + "_cd"] + " - " + this.allActivityHier[i]['lvl' + j + "_value"] + " - " + 'Level ' + j;
  //       if ((temp.indexOf(this.allActivityHier[i]['lvl' + j + "_cd"]) < 0) && this.allActivityHier[i]['lvl' + j + "_cd"] != null) {
  //         this.allActivity.push(obj);
  //         temp.push(this.allActivityHier[i]['lvl' + j + "_cd"])
  //       }
  //     }
  //     var obj:any = new Object();
  //     obj['code'] = this.allActivityHier[i]['leaf_cd']
  //     obj['value'] = this.allActivityHier[i]['leaf_value']
  //     obj['level'] = 'L'
  //     obj['desc'] = this.allActivityHier[i]['leaf_cd'] + " - " + this.allActivityHier[i]['leaf_value'] + " - Leaf";
  //     var p = temp.indexOf(this.allActivityHier[i]['leaf_cd'])
  //     this.allActivity.splice(p, 1)
  //     this.allActivity.push(obj)

  //   }

  // }
  // index;
  // open_event_popup(i) {
  //   this.index = i;
  //   $('#myModal').modal('show');

  // }

}
