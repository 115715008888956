<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Allotment Preparation</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">

                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2"  (click)="reset()" data-toggle="tab"><i class="ti-settings"></i>
                               Alloted Applicant List</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3"  (click)="reset()" data-toggle="tab"><i class="ti-settings"></i>
                               Un-Alloted Applicant List</a>
                        </li> -->
                    </ul>
                    <br>
                    <div class="tab-content">
                        <!-- <p id="rcorners2">Rounded corners!</p>        -->

                        <div class="tab-pane fade show active" id="tab-2">
                            <div id="rcorners2">
                                <div class="row">
                                     <div class="col-4 text-right">
                                        <h6>Select Offer :</h6>
                                    </div>

                                    <div class="col-4">
                                        <ng-select [items]="allOffer" bindLabel="desc" bindValue="offer_cd"
                                            [multiple]="false" placeholder=" Select Offer"
                                            [(ngModel)]="obj1['offer_cd']" (change)="getStatusApplications()"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        <h6>Status:</h6>
                                    </div>
                                    <div class="col-4 ">
                                        <ng-select [items]="uniqueStatus" bindLabel="arr_status_code" bindValue="arr_status_code"
                                            [multiple]="false"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Applicant19']}}"
                                            [(ngModel)]="obj1['arrStatus']"[selectableGroup]="true" (change)="getCatStatus()"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>
                                </div>
                                  <br>
                                <div class="row">

                                    <div class="col-4 text-right">
                                        <h6>Select Category : </h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="uniqueCat" bindLabel="party_quota" bindValue="party_quota"
                                            [multiple]="false" placeholder="SelectC Category"
                                            [(ngModel)]="obj1['catstatus']" [selectableGroup]="true"
                                            (change)="getCatStatus()" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Applicant21']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button (click)="printResult()" class="btn btn-outline-success">Download</button>
                                </div>

                                <div class="col-6">
                                </div>

                                <div class="col-2">
                                    <!-- <button class="btn btn-outline-info"
                                        (click)="print2()">{{auth.allLableShowObjProp[auth.language_cd+'Applicant22']}}</button> -->

                                </div>

                            </div>
                            <div id="p" [hidden]="true">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img [src]="mainService.accountImageUrl" class="img-circle" width="90px" />

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h4>{{mainService.accInfo['account_name']}}
                                            ({{mainService.accInfo['account_short_name']}})
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>Applicant List for {{subSchemeObj[obj1['sub_scheme_code']]}},
                                            {{schemeObj[obj1['scheme_code']]}} </h6>
                                    </div>
                                </div>
                                <table class="table" style="border: black;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>APPLICANT ID</th>
                                            <th>APPLICANT NAME
                                            </th>
                                            <th>APPLICATION DATE
                                            </th>
                                            <th>CHALLAN NO
                                            </th>
                                            <th>APPLICATION AMOUNT
                                            </th>
                                            <th>PROPERTY NO
                                            </th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of statusDataArr">
                                            <td>
                                                <h6>{{ob['party_id']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['party_name']}}</h6>
                                            </td>
                                            <td>
                                                <h6 *ngIf="obj.applied_date">
                                                    {{mainService.dateFormatChange(obj.applied_date)}}
                                                </h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['application_challan_no']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['application_amount']}}</h6>
                                            </td>
                                            <td>
                                                <h6></h6>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort"
                                    style="width: 100%">

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant23']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant24']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="applied_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant25']}}
                                            </b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateFormatChange(element.applied_date)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="application_challan_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant27']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.application_challan_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="application_amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant26']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.application_amount }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_quota">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Category
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_quota }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="arr_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant28']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.arr_status_code }} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <div id="rcorners2">
                                <div class="row">
                                    <div class="col-4 text-right">
                                        <h6> Status:</h6>
                                    </div>
                                    <div class="col-4 ">
                                        <ng-select [items]="statusArr12" bindLabel="code" bindValue="value"
                                        [multiple]="false"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Applicant19']}}"
                                        disabled [(ngModel)]="obj1['status12']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    </div>
                                </div>
                               <br>
                                <div class="row">
                                     <div class="col-4 text-right">
                                        <h6>Select Offer :</h6>
                                    </div>

                                    <div class="col-4">
                                        <ng-select [items]="allOffer" bindLabel="desc" bindValue="offer_cd"
                                            [multiple]="false" placeholder=" Select Offer"
                                            [(ngModel)]="obj1['offer_cd']" (change)="getUnallotedApplications()"
                                            [selectableGroup]="true" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">

                                    <div class="col-4 text-right">
                                        <h6>Select Category : </h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="uniqueCat" bindLabel="party_quota" bindValue="party_quota"
                                            [multiple]="false" placeholder="SelectC Category"
                                            [(ngModel)]="obj1['catstatus']" [selectableGroup]="true"
                                            (change)="getUnCatStatus()" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true" name="field_name">
                                        </ng-select>
                                    </div>
                                </div>

                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Applicant21']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button (click)="printUnAlloted()" class="btn btn-outline-success">Download</button>
                                </div>

                                <div class="col-6">
                                </div>

                                <div class="col-2">
                                    <!-- <button class="btn btn-outline-info"
                                        (click)="print2()">{{auth.allLableShowObjProp[auth.language_cd+'Applicant22']}}</button> -->

                                </div>

                            </div>
                            <div id="p" [hidden]="true">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img [src]="mainService.accountImageUrl" class="img-circle" width="90px" />

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h4>{{mainService.accInfo['account_name']}}
                                            ({{mainService.accInfo['account_short_name']}})
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>Applicant List for {{subSchemeObj[obj1['sub_scheme_code']]}},
                                            {{schemeObj[obj1['scheme_code']]}} </h6>
                                    </div>
                                </div>
                                <table class="table" style="border: black;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>APPLICANT ID</th>
                                            <th>APPLICANT NAME
                                            </th>
                                            <th>APPLICATION DATE
                                            </th>
                                            <th>CHALLAN NO
                                            </th>
                                            <th>APPLICATION AMOUNT
                                            </th>
                                            <th>PROPERTY NO
                                            </th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of statusDataArr">
                                            <td>
                                                <h6>{{ob['party_id']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['party_name']}}</h6>
                                            </td>
                                            <td>
                                                <h6 *ngIf="obj.applied_date">
                                                    {{mainService.dateFormatChange(obj.applied_date)}}
                                                </h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['application_challan_no']}}</h6>
                                            </td>
                                            <td>
                                                <h6>{{ob['application_amount']}}</h6>
                                            </td>
                                            <td>
                                                <h6></h6>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort"
                                    style="width: 100%">

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant23']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant24']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="applied_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant25']}}
                                            </b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.dateFormatChange(element.applied_date)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="application_challan_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant27']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.application_challan_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="application_amount">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant26']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.application_amount }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="arr_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant28']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.arr_status_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_quota">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Category
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_quota }} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>

                        </div>




                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Applicant44']}}">
                                    </mat-form-field>
                                </div>


                            </div>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource2" matSort #sortCol3="matSort"
                                    style="width: 100%">

                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant45']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.party_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_applicant_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant46']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.co_applicant_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_applicant_father_or_husband_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b> {{auth.allLableShowObjProp[auth.language_cd+'Applicant47']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_father_or_husband_name }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="co_applicant_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant48']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_email }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_applicant_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant49']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_phone_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_applicant_adhar_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant50']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_adhar_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="co_applicant_annual_income">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant51']}}
                                            </b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_annual_income }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="co_applicant_religion">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant52']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.co_applicant_religion }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="co_applicant_photo_file_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant53']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.co_applicant_photo_file_name!=null"
                                                class="btn btn-outline-primary" type="button"
                                                (click)="viewCoApplicantFile(element,element.co_applicant_photo_file_name)">{{auth.allLableShowObjProp[auth.language_cd+'Applicant40']}}</button>
                                            <p style='color: red;' *ngIf=" element.co_applicant_photo_file_name==null">
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant39']}}</p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="co_applicant_sig_file_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Applicant54']}}
                                            </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.co_applicant_sig_file_name!=null"
                                                class="btn btn-outline-primary" type="button"
                                                (click)="viewCoApplicantFile2(element,element.co_applicant_sig_file_name)">{{auth.allLableShowObjProp[auth.language_cd+'Applicant40']}}</button>

                                            <p style='color: red;' *ngIf=" element.co_applicant_sig_file_name==null">
                                                {{auth.allLableShowObjProp[auth.language_cd+'Applicant39']}}</p>

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator2 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                            <br>
                            <div style="background:white;text-align: center; ">
                                <img [src]="imgURL" width="300px"
                                    style="max-width: 75%;padding-left: 3px;padding-top: 2px" />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>
    <p style="color: white"> {{auth.allLableShowObjProp[auth.language_cd+'Applicant55']}} </p>
</ngx-spinner>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">Uploded Documents</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <table style="width: 100%;" class="table table-bordered">
                    <thead style="background-color: blue ; color: white;">
                        <tr>
                            <th>S No.</th>
                            <th>Document Name</th>
                            <th>Sction</th>
                            <!-- <th>Role Name</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ob of allUploadedDoc;let i=index">
                            <td>{{i+1}}</td>
                            <td>{{ob['doc_type']}}</td>
                            <td>
                                <button (click)="view_doc(ob)" style="width: 80px; border-radius: 10px;"
                                    class="btn btn-success">View</button>
                            </td>

                        </tr>
                    </tbody>

                </table>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">Reason Of Action</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div style="overflow-x: scroll;">
                    <h4 class="modal-title" style="font: bold; ">Please Give The Reason</h4>
                    <br>
                    <input type="text" placeholder="Enter Reason" [(ngModel)]="Obj['reason']" class="form-control">
                    <br>
                    <!-- <div class="btn btn-outline-primary" data-dismiss="modal" style="float: right;"> Submit Reason</div> -->
                    <button type="button" class="btn btn-primary"
                        (click)='reject(globleElement["element"],globleElement["i"])' data-dismiss="modal"
                        style="float: right;"> Submit Reason</button>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">Reason Of Action</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div style="overflow-x: scroll;">
                    <h4 class="modal-title" style="font: bold; ">Please Give The Reason</h4>
                    <br>
                    <input type="text" placeholder="Enter Reason" [(ngModel)]="Obj['reason']" class="form-control">
                    <br>
                    <!-- <div class="btn btn-outline-primary" data-dismiss="modal" style="float: right;"> Submit Reason</div> -->
                    <button type="button" class="btn btn-primary"
                        (click)='refill(globleElement["element"],globleElement["i"])' data-dismiss="modal"
                        style="float: right;"> Submit Reason</button>

                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
