import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RoleGuardService } from "../../role-guard.service";
import { AdministrationComponent } from "../administration/administration.component";
import { CreateRoleComponent } from "../administration/authorization/create-role/create-role.component";
import { DataAssignmentComponent } from "../administration/authorization/data-assignment/data-assignment.component";
import { EmbHierarchyComponent } from "../administration/authorization/emb-hierarchy/emb-hierarchy.component";
import { UsersComponent } from "../administration/authorization/users/users.component";
import { BudComponent } from "../administration/master-data/bud/bud.component";
import { CodeValueComponent } from "../administration/master-data/code-value/code-value.component";
import { DeductionComponent } from "../administration/master-data/deduction/deduction.component";
import { DefApprovalComponent } from "../administration/master-data/def-approval/def-approval.component";
import { FieldComponent } from "../administration/master-data/field/field.component";
import { GeometryComponent } from "../administration/master-data/geometry/geometry.component";
import { RolePrintComponent } from "../administration/master-data/role-print/role-print.component";
import { UndertakingComponent } from "../administration/master-data/undertaking/undertaking.component";
import { WorkInfoComponent } from "../administration/master-data/work-info/work-info.component";
import { CbComponent } from "../cb/cb.component";
import { CompletionComponent } from "../completion/completion.component";
import { CreateEmbComponent } from "../create-emb/create-emb.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { DevComponent } from "../dev/dev.component";
import { EbillCreateComponent } from "../ebill-create/ebill-create.component";
import { DocComponent } from "../help/doc/doc.component";
import { FaqComponent } from "../help/faq/faq.component";
import { HelpComponent } from "../help/help.component";
import { OnlineSupportComponent } from "../help/online-support/online-support.component";
import { VideosComponent } from "../help/videos/videos.component";
import { ReportsComponent } from "../reports/reports.component";
import { TenderCreateComponent } from "../tender-create/tender-create.component";
import { PartyComponent as EmbPartyComponent } from "../administration/master-data/party/party.component";
import { DepricatedComponent } from '../depricated/depricated.component';
import { ItemListComponent } from '../depricated/item-list/item-list.component';
import {AdministrationComponent as depAdministration} from '../depricated/administration/administration.component';
import { EstimationComponent } from '../depricated/estimation/estimation.component';
import { EngMasterDataComponent } from '../new-eng/administration/eng-master-data/eng-master-data.component';
import { EngSorItemsComponent } from '../depricated/item-list/eng-sor-items/eng-sor-items.component';
import { EstimateComponent } from '../depricated/estimation/estimate/estimate.component';

import { MorthComponent } from '../depricated/item-list/morth/morth.component';
import { DsrComponent } from '../depricated/item-list/dsr/dsr.component';
import { InceptionComponent } from '../depricated/estimation/inception/inception.component';
import {MasterDataComponent as DepMasterDataComponent} from '../../emb/depricated/administration/master-data/master-data.component'
import {SorComponent} from '../depricated/item-list/sor/sor.component'
const routes: Routes = [
  {
    path: "emb-index",
    component: DashboardComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "EMB-R1" },
  },
  {
    path: "create-emb",
    component: CreateEmbComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "EMB-R3" },
  },
  {
    path: "ebill-create",
    component: EbillCreateComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "EBILL-R4" },
  },
  {
    path: "create-tender",
    component: TenderCreateComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "BOQ-R2" },
  },
  {
    path: "cb",
    component: CbComponent,
  },
  {
    path: "reports",
    component: ReportsComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "EMBREPORT-R20" },
  },
  {
    path: "deviation",
    component: DevComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "DEVIATION-R5" },
  },
  {
    path: "completion",
    component: CompletionComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "Completion-R6" },
  },
  {
    path: "administration",
    component: AdministrationComponent,
    children: [
      //master data

      {
        path: "fields",
        component: FieldComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "TERM-R7" },
      },
      {
        path: "code-value",
        component: CodeValueComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "CODE-VALUE-R8" },
      },
      {
        path: "party",
        component: EmbPartyComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Vendors-R9" },
      },
      {
        path: "work",
        component: WorkInfoComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Work-R11" },
      },
      {
        path: "deduction",
        component: DeductionComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Deduction-R12" },
      },
      {
        path: "geometry",
        component: GeometryComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Geometry-R13" },
      },
      {
        path: "bud",
        component: BudComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Budget-R19" },
      },
      { path: "undertacking", component: UndertakingComponent },
      { path: "def-Appr", component: DefApprovalComponent },
      { path: "role-print", component: RolePrintComponent },

      //setting
      {
        path: "data-assignment",
        component: DataAssignmentComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Authorization-R16" },
      },
      {
        path: "create-role",
        component: CreateRoleComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Users-R14" },
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Roles-R15" },
      },
      {
        path: "emb-hier",
        component: EmbHierarchyComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "EMBHIER-R22" },
      },
    ],
  },
  {
    path: "depricated",
    component: DepricatedComponent,
    children: [
      //master data

      {
        path: "inceptioin",
        component: InceptionComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Inception-R20" },

      },
      {
        path: "Estimate",
        component: EstimateComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Estimate-R21" },

      },
      {
        path: "sor",
        component: SorComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "sor-R24" },

      },
      {
        path: "dsr",
        component: DsrComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "dsr-R25" },

      },
      {
        path: "morth",
        component: MorthComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "morth-R26" },
      },
      {
        path: "dep_master_data",
        component: DepMasterDataComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "Estimation-Master-data-R22" },
      },
    ],
  },

  {
    path: "help",
    component: HelpComponent,
    children: [
      { path: "doc", component: DocComponent },
      { path: "faq", component: FaqComponent },
      { path: "videos", component: VideosComponent },
      { path: "online-support", component: OnlineSupportComponent },
    ],
  },
  {path: "eng-master-data",component: EngMasterDataComponent },
  {path: "eng-sor-items",component: EngSorItemsComponent },
  {path: "dsr",component: DsrComponent },
  {path: "morth",component: MorthComponent },
  {path: "estimate",component: EstimateComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmbLayoutsRoutingModule {}
