import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AllotmentLetterConfigurationService {
  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/application";
  }

  async getAllAllotmentLetterData(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/property/allotmentletter/getAllAllotmentLetter' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getAllChildAllotmentLetterData(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/property/allotmentletter/getAllChildAllotmentLetter' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  async uploadImage(obj:any){
    const resp = this.http.post<any>(this.httpUrl + '/uploadImage', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async uploadImage2(obj:any){
    const resp = this.http.post<any>(this.httpUrl + '/uploadImage', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async insertAllotmentLetterData(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/property/allotmentletter/insertAllotmentLetterData', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async insertAllotmentChildLetterData(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/property/allotmentletter/insertAllotmentChildLetterData', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updateAllotmentLetterData(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/allotmentletter/updateAllotmentLetterData', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async upadteAllotmentChildLetterData(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/allotmentletter/upadteAllotmentChildLetterData', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getUploadedFileofparty(obj:any){

    const resp = await this.http.post(this.main.httpUrl + '/property/allotmentletter/getImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }else{
      return
    }
  }




  // *********************************************** Public portal config*************************************************************




  async submit(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/property/allotmentletter/insertimagelink' , obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getImageDetails(obj:any){
    const resp = this.http.get<any>(this.main.httpUrl + '/property/allotmentletter/getimagedtl' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteLink(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/property/allotmentletter/deletelinkdtl' , obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updateLink(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/allotmentletter/updatelink' , obj).toPromise().then(res => {
      return res
    });
    return resp
  }

}
