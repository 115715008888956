<div class="page-content fade-in-up">
    <div class="card">
        <br>
        <div class="row">
            <div style="display:inline-block;vertical-align:top;">
                <div class="col-12 text-left">
                    <img [src]="imgURL" class="img-circle" width="150px" height="150px"
                        style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);" />
                </div>
            </div>
            <div style="display:inline-block;vertical-align:bottom;">
                <br>
                <br>
                <br>

                <h1 class="font-strong m-b-10 m-t-10" style="color:lightseagreen"> Hii,{{userInfoObj['party_name']}}
                </h1>
                <div class="m-b-20 text-muted text-center scale-up-hor-center" >
                   <span class="scale-up-hor-center"><h6 style="color:maroon;">{{roles}}</h6></span> 
                </div>
                <!-- <div class="scale-up-hor-center">
                    <span style="background-color: red;">( This is the classs cheeck data {{roles}})</span> 

                </div> -->

            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-success color-white widget-stat" style="height: 150px; border-radius: 5px; ">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{incompleteCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="modalOpen1()">VIEW</button>
                        <br>
                        <br>
                        <div class="m-b-5"><b>Primary Details</b></div><i class="fa fa-users widget-stat-icon"></i>
                        <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-warning color-white widget-stat" style="height: 150px;border-radius: 5px;">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{leftCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="modalOpen3()">VIEW</button>

                        <br>
                        <br>
                        <div class="m-b-5"><b>Assigned Functional Area</b></div><i
                            class="fa fa-tasks widget-stat-icon"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-info color-white widget-stat" style="height: 150px;border-radius: 5px;">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{activeCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="modalOpen2()">VIEW</button>
                        <br>
                        <br>
                        <div class="m-b-5"><b>Other Details</b></div><i class="fa fa-users widget-stat-icon"></i>
                        <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-success color-white widget-stat" style="height: 150px; border-radius: 5px; ">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{incompleteCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="openview()"
                            style="margin:5px;">VIEW</button>

                        <br>
                        <br>
                        <div class="m-b-5"><b>Current Month Salary</b></div><i class="fa fa-usd widget-stat-icon"></i>
                        <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-warning color-white widget-stat" style="height: 150px;border-radius: 5px;">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{leftCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="openpreviousbill()"
                            style="margin:5px;">VIEW</button>

                        <br>
                        <br>
                        <div class="m-b-5"><b>Previous Month Salary</b></div><i class="fa fa-usd widget-stat-icon"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ibox bg-info color-white widget-stat" style="height: 150px;border-radius: 5px;">
                    <div class="ibox-body">
                        <!-- <h2 class="m-b-5 font-strong">{{activeCount}}</h2> -->
                        <button class="btn btn-primary" style="border-radius: 5px;" (click)="print1()"
                            style="margin:5px;">VIEW</button>

                        <br>
                        <br>
                        <div class="m-b-5"><b>Annual Salary Ledger</b></div><i class="fa fa-usd widget-stat-icon"></i>
                        <!-- <div><i class="fa fa-level-up m-r-5"></i><small></small></div> -->
                    </div>
                </div>
            </div>

        </div>


    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="color: midnightblue;">Primary Details</h4>
                <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>


                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Party ID:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{userInfoObj['le_id']}}

                    </div>

                </div>

                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Employee ID:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_id']}}

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6> Employee Name:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{userInfoObj['party_name']}}

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6> Email:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{userInfoObj['contact_email']}}


                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Contact:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{userInfoObj['phone_no']}}


                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Date of Birth</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{userInfoObj['party_dob']}}


                    </div>
                </div>
                <br>



                <div class="modal-footer">

                    <button type="button" class="btn btn-danger" data-dismiss="modal">CANCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="color: midnightblue;"> Other Details</h4>
                <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>




                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Father's Name:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_father_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Mother's Name:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_mother_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Adhar No:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_adhar_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Employee's PAN:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_pan_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent Address Line 1:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_line1']}}
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent Address Line 2:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_line2']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent City:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_city']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent Address District:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_dist']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent Address State:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_state']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Permanent Address Pin:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_permanent_addr_pin_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local Address Line 1:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_line1']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local Address Line 2:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_line2']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local City:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_city']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local Address District:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_dist']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local Address State:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_state']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Local Address Pin </h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_local_addr_pin_code']}}
                    </div>
                </div>
                <br>




                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Marital Status:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['marital_status']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Husband Name If Applicable:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_husband_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Religeon:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_religeon']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            Nationality:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{empObj['emp_nationality']}}
                    </div>
                </div>


            </div>

            <br>



            <div class="modal-footer">

                <button type="button" class="btn btn-danger" data-dismiss="modal">CANCEL</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="color: midnightblue;"> Functional Area</h4>
                <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
            </div>

            <!-- Modal body -->
            <div class="modal-body">

                <br>


                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Assigned Functional Area:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{this.empObj['module_cd']}}

                    </div>

                </div>

                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Assigned Cost Center Area:</h6>
                    </div>
                    <div class="col-5 text-left">
                        {{this.empObj['cc']}}

                    </div>

                </div>

                <br>


                <div class="modal-footer">

                    <button type="button" class="btn btn-danger" data-dismiss="modal">CANCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="text-align: center; color: darkblue;">Salary Details for Month
                    {{monthObj[curr_month] + " Year " + curr_year}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">

                <br>
                <div class="row">
                    <div class="col-11 text-right"> <button class="btn btn-outline-primary"
                            (click)="buildoneclickslipcurr()">
                            Download
                        </button></div>

                </div>
                <br>

                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee ID</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_id']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Phone no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_phone_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Pan no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_pan_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Adhar no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_adhar_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Bank Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['bank_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Account No.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['acct_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Ifsc Code</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['ifsc_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr  style="background-color: #011831; color: white; width: 50%;">

                                <th class="text-center" style="width: 20%;">Payable Component</th>
                                <th class="text-center" style="width: 20%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of arr; index as i">

                                <td style="width: 20%;">
                                    {{fd['pay_comp']}}
                                </td>
                                <td style="width: 20%;">
                                    {{fd['rate']}}
                                </td>



                            </tr>




                        </tbody>

                    </table>
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr  style="background-color: #011831; color: white; width: 50%;">

                                <th class="text-center" style="width: 20%;">Deduction Component</th>
                                <th class="text-center" style="width: 20%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of arr1; index as i" style=" width: 50%;">

                                <td style="width: 20%;height:5px;">
                                    <div style="height: 5px;">{{fd['pay_comp']}}</div>
                                </td>
                                <td style="width: 20%; height:5px;">
                                    <div style="height: 5px;">{{fd['rate']}}</div>
                                </td>



                            </tr>




                        </tbody>

                    </table>
                </div>

                <hr>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Total Payble Amount:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['Total_payble']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Total Deductions:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['Total_Deductions']}}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 text-right">
                        <h6>
                            Net Payble:</h6>
                    </div>
                    <div class="col-6">
                        {{this.info['Net_Payble']}}
                    </div>


                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Z1" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="text-align: center; color: darkblue;">Salary Details for Month
                    {{monthObj[prev_month] + " Year " + prev_year}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">
                <br>
                <div class="row">
                    <div class="col-11 text-right"> <button class="btn btn-outline-primary" (click)="getPaySlip()">
                            Download
                        </button></div>
                </div>

                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee ID</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_id']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Phone no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_phone_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Pan no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_pan_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Adhar no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_adhar_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Bank Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['bank_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Account No.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['acct_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Ifsc Code</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['ifsc_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr class="text-center" style="background-color: #011831; color: white; width: 50%;">

                                <th class="text-center" style="width: 20%;">Payable Component</th>
                                <th class="text-center" style="width: 20%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of prevpay; index as i"  style=" width: 50%;">

                                <td style="width: 20%;">
                                    {{fd['pay_component_code']}}
                                </td>
                                <td style="width: 20%;">
                                    {{fd['pay_component_amt']}}
                                </td>



                            </tr>




                        </tbody>

                    </table>
                    <table class="table table-striped"
                        style="width: 40%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr  style="background-color: #011831; color: white; width: 50%;">

                                <th class="text-center" style="width: 10%;">Deduction Component</th>
                                <th class="text-center" style="width: 10%;">Amount</th>




                            </tr>

                        </thead>
                        <tbody>

                            <tr *ngFor="let fd of prevded; index as i">

                                <td style="width: 20%;height:5px;">
                                    <div style="height: 5px;">{{fd['pay_component_code']}}</div>
                                </td>
                                <td style="width: 20%; height:5px;">
                                    <div style="height: 5px;">{{fd['pay_component_amt']}}</div>
                                </td>



                            </tr>




                        </tbody>

                    </table>
                </div>

                <hr>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Total Payble Amount:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info1['Total_payble']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Total Deductions:</h6>
                    </div>
                    <div class="col-3">
                        {{this.info1['Total_Deductions']}}
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 text-right">
                        <h6>
                            Net Payble:</h6>
                    </div>
                    <div class="col-6">
                        {{this.info1['Net_Payble']}}
                    </div>


                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade right" style="overflow-y:scroll;scroll-margin-block: 1;" id="Z3" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header" style="width: 147%;">
                <h5 class="modal-title" style="text-align: center; color: darkblue;">Annual Salary Ledger
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>
            <div class="modal-body">
                <br>
                <div class="row">

                    <div class="col-11 text-right"> <button class="btn btn-outline-primary" (click)="print1annual()">
                            Download
                        </button></div>
                </div>
                <br>

                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee ID</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_id']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_name']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Phone no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_phone_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Employee Pan no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_pan_no']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Employee Adhar no.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['emp_adhar_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Bank Name</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['bank_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>
                            Account No.</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['acct_no']}}
                    </div>
                    <div class="col-3 text-right">
                        <h6>
                            Ifsc Code</h6>
                    </div>
                    <div class="col-3">
                        {{this.info['ifsc_code']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <table class="table table-striped"
                        style="margin-left: 2%;margin-right:2%; padding:2%;overflow-y:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <th *ngFor="let col of list; let i= index" style="background-color: #011831; color: white;">
                                {{col}}</th>

                        </thead>
                        <tbody>

                            <tr *ngFor="let ob of arrsal1">

                                <td *ngFor="let col of list; let i= index">
                                    {{ob[i]}}
                                </td>

                            </tr>
                            <tr>

                                <td *ngFor="let ob of arrsalfin; let i=index">
                                    {{ob}}
                                </td>

                            </tr>




                        </tbody>

                    </table>

                </div>

                <hr>



                <div class="modal-footer" style="width: 147%;">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>
