<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'Contra1']}}
                        <!--Contra Voucher-->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Contra2']}}
                                <!---- All Contra Voucher List-->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Contra3']}}
                                <!--Create Contra Voucher-->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'Contra4']}}
                                <!---- Update Contra Voucher-->
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'Contra5']}}">
                            </mat-form-field>
                            <!--Filter-->

                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="voucher_no">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Contra6']}}
                                            <!--Voucher No-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element ">
                                            {{ element.voucher_no }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="voucher_dt">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Contra7']}}
                                            <!--Voucher Date-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            <!-- {{ element.voucher_date }}  -->
                                            <p *ngIf="element.voucher_date">
                                                {{mainService.dateFormatChange(element.voucher_date )}}</p>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="remark">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Contra8']}}
                                            <!--Remark-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.remark }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Contra9']}}
                                            <!--Status-->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ element.status }} </td>
                                    </ng-container>





                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'Contra10']}}
                                            <!--Action-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index">

                                            <button type="button" class="btn btn-primary"
                                                *ngIf="element.status=='GENERATED'"
                                                (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra11']}}
                                                <!--Update-->
                                            </button>&nbsp;&nbsp;
                                            <button type="button" *ngIf="element.status=='GENERATED'"
                                                class="btn btn-danger"
                                                (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra12']}}
                                                <!--Delete-->
                                            </button>&nbsp;&nbsp;

                                            <button type="button" class="btn btn-info"
                                                (click)="print1(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra13']}}
                                                <!--Print-->
                                            </button>&nbsp;&nbsp;
                                            <button type="button" *ngIf="element.status=='GENERATED'"
                                                class="btn btn-success" (click)="changeContraStatus(element)"> APPROVE
                                            </button>&nbsp;&nbsp;
                                            <button type="button" *ngIf="element.status=='APPROVED'"
                                                class="btn btn-primary" (click)="process(element)"> Process
                                            </button>&nbsp;&nbsp;

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'Contra17']}}
                                        <!--Enter Remark -->:
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <textarea class="form-control" [(ngModel)]="contraObj['remark']"> </textarea>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'Contra6']}}
                                        <!---- Voucher No-->. :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" disabled [(ngModel)]="contraObj['voucher_no']"
                                        placeholder="System Generated">
                                </div>
                                <div class="col-3 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Contra7']}}
                                        <!---- Voucher Date--> :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <!-- <input class="form-control" disabled [(ngModel)]="contraObj['voucher_date']"> -->
                                    <p *ngIf="contraObj['voucher_date']">
                                        {{mainService.dateFormatChange(contraObj['voucher_date'])}}</p>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" type="button" (click)="addRow()"><i
                                            class="fa fa-plus" aria-hidden="true"></i></button>

                                </div>
                            </div>
                            <br>
                            <!-- <form name="form" (ngSubmit)="f.form.valid && submit(f.form)" #f="ngForm" novalidate> -->
                            <form name="form" #f="ngForm" novalidate>
                                <table class="mat-elevation-z8">
                                    <thead>
                                        <tr>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra18']}}
                                                <!--Sr. No-->.
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra24']}}
                                                <!--Type-->
                                            </th>
                                            <th style="width: 15%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra19']}}
                                                <!--Bank-->
                                            </th>
                                            <th style="width: 25%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra20']}}
                                                <!--Description-->
                                            </th>
                                            <th style="width: 10%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra21']}}
                                                <!--DR-->
                                            </th>
                                            <th style="width: 10%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra22']}}
                                                <!--CR-->
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra10']}}
                                                <!--Action-->
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of contraObj['data']; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <ng-select [items]="type" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    (change)="selectedType()" #bank_type="ngModel" name="bank_type{{i}}"
                                                    [ngClass]="{ 'is-invalid': f.submitted && bank_type.invalid }"
                                                    required>

                                                </ng-select>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && bank_type.invalid" class="error-message">
                                                    <div *ngIf="bank_type.errors?.['required']">Please Select Bank /
                                                        Cash
                                                        Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="bank_type.dirty && bank_type.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->


                                            </td>
                                            <td *ngIf="contraObj['data'][i]['type'] !='CASH' ">

                                                <ng-select [items]="allBankAccounts" bindLabel="desc"
                                                    bindValue="relation" [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['bank_id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="bank_type{{i}}" #bank="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && bank.invalid }" required>
                                                </ng-select>



                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && bank.invalid" class="error-message">
                                                    <div *ngIf="bank.errors?.['required']">Please Select Bank Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="bank.dirty && bank.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->
                                            </td>
                                            <td *ngIf="contraObj['data'][i]['type'] !='BANK' ">

                                                <ng-select [items]="cashMappedCOA" bindLabel="des"
                                                    bindValue="chart_of_account" [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['bank_id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="chart_of_acc" #cash="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && cash.invalid }" required>
                                                </ng-select>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && cash.invalid" class="error-message">
                                                    <div *ngIf="cash.errors?.['required']">Please Select Cash Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="cash.dirty && cash.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->


                                            </td>

                                            <td>
                                                <textarea class="form-control"
                                                    [(ngModel)]="contraObj['data'][i]['desc']" name="desc{{i}}"
                                                    #desc="ngModel" rows="1"
                                                    [ngClass]="{ 'is-invalid': f.submitted && desc.invalid }" required>
                                                </textarea>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && desc.invalid" class="error-message">
                                                    <div *ngIf="desc.errors?.['required']">Please Write description</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="desc.dirty && desc.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->

                                            </td>
                                            <td>
                                                <input class="form-control" type="number"
                                                    [(ngModel)]="contraObj['data'][i]['dr']" name="dr_amt{{i}}"
                                                    #dr_amt="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && dr_amt.invalid }"
                                                    required>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && dr_amt.invalid" class="error-message">
                                                    <div *ngIf="dr_amt.errors?.['required']">Please Enter Dr Amount </div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="dr_amt.dirty && dr_amt.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->

                                            </td>

                                            <td>
                                                <input class="form-control" type="number"
                                                    [(ngModel)]="contraObj['data'][i]['cr']" name="cr_amt{{i}}"
                                                    #cr_amt="ngModel"
                                                    [ngClass]="{ 'is-invalid': f.submitted && cr_amt.invalid }"
                                                    required>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f.submitted && cr_amt.invalid" class="error-message">
                                                    <div *ngIf="cr_amt.errors?.['required']">Please Enter Cr Amount </div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="cr_amt.dirty && cr_amt.valid">
                                                </div>

                                                <div *ngIf="f.submitted && dr_amt.value > 0 && cr_amt.value > 0"
                                                    class="error-message">

                                                    <!-- Please Fill Either Dr OR Cr Amount Not Both -->
                                                    Fill Dr OR Cr
                                                </div>

                                                <div *ngIf=" f.submitted && dr_amt.value == cr_amt.value"
                                                    class="error-message">
                                                    Fill Dr OR Cr
                                                </div>

                                                <!---------------- validation end-----------------------------  -->



                                            </td>
                                            <td>
                                                <button class="btn btn-danger" type="button" (click)="deleteRow(i)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row" *ngIf="no_line">
                                    <br>
                                    <div class="col-12 text-center error-message">
                                       {{message}}
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <!-- <button class="btn btn-primary" (click)="submit(f.form)">{{mainService.allLableShowObj[mainService.language_cd+'Contra23']}} </button> -->
                                        <button class="btn btn-primary" type="button"
                                        (click)="submit(f.form)">{{mainService.allLableShowObj[mainService.language_cd+'Contra23']}}
                                        </button>

                                        <button class="btn btn-outline-success d-none" type="reset" id="reset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="tab-pane fade" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'Contra17']}}
                                        <!--Enter Remark-->:
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <textarea class="form-control" [(ngModel)]="contraObj['remark']"> </textarea>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Contra6']}}
                                        <!--Voucher No-->. :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input class="form-control" disabled [(ngModel)]="contraObj['voucher_no']">
                                </div>
                                <div class="col-3 text-right">
                                    <h6> {{mainService.allLableShowObj[mainService.language_cd+'Contra7']}}
                                        <!--Voucher Date--> :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <p *ngIf="contraObj['voucher_date']">
                                        {{mainService.dateFormatChange(contraObj['voucher_date'])}}</p>

                                    <!-- <input class="form-control" disabled [(ngModel)]="contraObj['voucher_date']"> -->

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button type="button" class="btn btn-primary" (click)="addRow()"><i
                                            class="fa fa-plus" aria-hidden="true"></i></button>


                                </div>
                            </div>
                            <br>
                            <form name="form2" (ngSubmit)="f2.form.valid && update(f2.form)" #f2="ngForm" novalidate>
                                <table class="mat-elevation-z8">
                                    <thead>
                                        <tr>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra18']}}
                                                <!--Sr. No-->.
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra24']}}
                                                <!--Type-->
                                            </th>
                                            <th style="width: 15%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra19']}}
                                                <!--Bank-->
                                            </th>
                                            <th style="width: 25%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra20']}}
                                                <!--Description-->
                                            </th>
                                            <th style="width: 10%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra21']}}
                                                <!--DR-->
                                            </th>
                                            <th style="width: 10%;">
                                                {{mainService.allLableShowObj[mainService.language_cd+'Contra22']}}
                                                <!--CR-->
                                            </th>
                                            <th>{{mainService.allLableShowObj[mainService.language_cd+'Contra10']}}
                                                <!--Action-->
                                            </th>
                                        </tr>
                                    </thead>
                                    <!-- <tbody>
                                    <tr *ngFor="let ob of contraObj['data']; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <ng-select [items]="type" bindLabel="value" bindValue="code"
                                                [multiple]="false" placeholder=""
                                                [(ngModel)]="contraObj['data'][i]['type']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select [items]="allChartOfAccount" bindLabel="leaf_value"
                                                bindValue="leaf_code" [multiple]="false" placeholder=""
                                                [(ngModel)]="contraObj['data'][i]['bank_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <textarea class="form-control" [(ngModel)]="contraObj['data'][i]['desc']"
                                                placeholder=""></textarea>

                                        </td>
                                        <td>
                                            <input class="form-control" type="number"
                                                [(ngModel)]="contraObj['data'][i]['dr']">

                                        </td>

                                        <td>
                                            <input class="form-control" type="number"
                                                [(ngModel)]="contraObj['data'][i]['cr']">

                                        </td>
                                        <td>
                                            <button class="btn btn-danger" (click)="deleteRow(i)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                </tbody> -->
                                    <tbody>
                                        <tr *ngFor="let ob of contraObj['data']; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>
                                                <ng-select [items]="type" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['type']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    (change)="selectedType()" #bank_type="ngModel" name="bank_type{{i}}"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && bank_type.invalid }"
                                                    required>

                                                </ng-select>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && bank_type.invalid" class="error-message">
                                                    <div *ngIf="bank_type.errors?.['required']">Please Select Bank /
                                                        Cash
                                                        Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="bank_type.dirty && bank_type.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->


                                            </td>
                                            <td *ngIf="contraObj['data'][i]['type'] !='CASH' ">

                                                <ng-select [items]="allBankAccounts" bindLabel="desc"
                                                    bindValue="relation" [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['bank_id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="bank_type{{i}}" #bank="ngModel"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && bank.invalid }" required>
                                                </ng-select>



                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && bank.invalid" class="error-message">
                                                    <div *ngIf="bank.errors?.['required']">Please Select Bank Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="bank.dirty && bank.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->
                                            </td>
                                            <td *ngIf="contraObj['data'][i]['type'] !='BANK' ">

                                                <ng-select [items]="cashMappedCOA" bindLabel="des"
                                                    bindValue="chart_of_account" [multiple]="false" placeholder=""
                                                    [(ngModel)]="contraObj['data'][i]['bank_id']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="chart_of_acc" #cash="ngModel"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && cash.invalid }" required>
                                                </ng-select>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && cash.invalid" class="error-message">
                                                    <div *ngIf="cash.errors?.['required']">Please Select Cash Account</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.selection-box]="cash.dirty && cash.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->


                                            </td>

                                            <td>
                                                <textarea class="form-control"
                                                    [(ngModel)]="contraObj['data'][i]['desc']" name="desc{{i}}"
                                                    #desc="ngModel"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && desc.invalid }" required>
                                            </textarea>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && desc.invalid" class="error-message">
                                                    <div *ngIf="desc.errors?.['required']">Please Write description</div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="desc.dirty && desc.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->

                                            </td>
                                            <td>
                                                <input class="form-control" type="number"
                                                    [(ngModel)]="contraObj['data'][i]['dr']" name="dr_amt{{i}}"
                                                    #dr_amt="ngModel"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && dr_amt.invalid }"
                                                    required>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && dr_amt.invalid" class="error-message">
                                                    <div *ngIf="dr_amt.errors?.['required']">Please Enter Dr Amount </div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="dr_amt.dirty && dr_amt.valid">
                                                </div>
                                                <!---------------- validation end-----------------------------  -->

                                            </td>

                                            <td>
                                                <input class="form-control" type="number"
                                                    [(ngModel)]="contraObj['data'][i]['cr']" name="cr_amt{{i}}"
                                                    #cr_amt="ngModel"
                                                    [ngClass]="{ 'is-invalid': f2.submitted && cr_amt.invalid }"
                                                    required>


                                                <!---------------- validation start-----------------------------  -->

                                                <div *ngIf="f2.submitted && cr_amt.invalid" class="error-message">
                                                    <div *ngIf="cr_amt.errors?.['required']">Please Enter Cr Amount </div>
                                                </div>

                                                <!-- border color green -->
                                                <div [class.border_green]="cr_amt.dirty && cr_amt.valid">
                                                </div>

                                                <div *ngIf="f.submitted && dr_amt.value > 0 && cr_amt.value > 0"
                                                    class="error-message">

                                                    <!-- Please Fill Either Dr OR Cr Amount Not Both -->
                                                    Fill Dr OR Cr
                                                </div>

                                                <div *ngIf=" f2.submitted && dr_amt.value == cr_amt.value"
                                                    class="error-message">
                                                    Fill Dr OR Cr
                                                </div>

                                                <!---------------- validation end-----------------------------  -->



                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger" (click)="deleteRow(i)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>

                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" type="submit"> Update
                                        </button>
                                        <!--{{mainService.allLableShowObj[mainService.language_cd+'Contra11']}}-->
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
