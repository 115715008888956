import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdvanceService } from '../../service/advance.service';
import { SaleService } from '../../service/sale.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-refund-request',
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.css']
})
export class RefundRequestComponent implements OnInit {
  obj: any={}
  b_acct_id: any;
  allOffer: any;
  offer_code_to_value: any;
  ebillUser: any;
  user_id: any;
  pro_type: any;
  pro_category: any;
  details_flag:boolean = false;
  details_flag_tab2:boolean = false;
  restore_flag:boolean = false;
  search_flag:boolean = true;
  search_flag_tab2:boolean = true;
  details_obj: any;
  restore_obj: any;
  input_flag:boolean = true;

  OfferModeList:any = [
    {code:1,value:"Auction"},
    {value:'FCFS/Lottery',code:2}
  ]
  OfferMode:any
  modeOfferAction(){

  }





    yes_no_flag = [
        { id: true, name: 'Yes' },
    ];

    installments_frequency = [
        { id: 1, name: 'Monthly' },
        { id: 2, name: 'Quarterly' },
    ];

  constructor(private spinner : NgxSpinnerService, private sale_service: SaleService, private adv_ser : AdvanceService) { }

  ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id = this.ebillUser.le_id;
    console.log(this.b_acct_id);
    // this.getofferdata()
  }
  displayedColumns: string[] = ['select', 'party_id', 'property_category', 'property_type','assigned_property_number','allotment_id',  'party_name', 'notice_no', 'status', 'Action'];


  dataSource: any;
  dataSource2: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter_tab2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }


  async getofferdata() {
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id
    console.log(this.b_acct_id)
    console.log(this.obj)

    var resp = await this.sale_service.getAllOffers(this.obj);
    console.log(resp)

      if (resp['error'] == false) {
        this.allOffer = resp['data']
        console.log(resp['data'], 'fetched data')
        let dummy = []

        console.log(this.allOffer, " before loop ")

        for (let i = 0; i < this.allOffer.length; i++) {
          this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
          this.allOffer[i]['offer_name'] = this.allOffer[i]['offer_cd'] + '-' + this.allOffer[i]['offer_name']
          dummy.push(this.allOffer[i])
          console.log(this.allOffer[i]['offer_cd'])
        }
        console.log(this.allOffer, " after loop ")
       console.log(dummy, "dummy test")
      }
   this.spinner.hide()
 }
 async getpropertytype(){


  // let obj :any= {}
   this.obj['b_acct_id'] = this.b_acct_id
   this.obj['offer_cd'] =this.obj['offer_cd']
   console.log(this.obj)
   var resp: any = await this.adv_ser.getpropertytype(JSON.stringify(this.obj));
   //console.log(resp)
   if (resp['error'] == false) {
     this.pro_type = resp['data']
     console.log(this.pro_type, 'fetched property typedata')
   }
   this.getpropertyCategory()
}

async getpropertyCategory(){
  this.obj['b_acct_id'] = this.b_acct_id
  this.obj['offer_cd'] =this.obj['offer_cd']
  console.log(this.obj)
  var resp: any = await this.adv_ser.getpropertyCategory(JSON.stringify(this.obj));
  if (resp['error'] == false) {
    this.pro_category = resp['data']
    console.log(this.pro_type, 'fetched property typedata')
  }

}

async getSeizedDetails(){

  if(this.obj['offer_cd']===undefined || this.obj['offer_cd']===null){
    Swal.fire('select any Offer', 'Offer not selected', 'info')
    return;
  }


  this.obj['b_acct_id'] = this.b_acct_id
  console.log(this.obj)
  var resp: any = await this.adv_ser.getSeizedDetails(JSON.stringify(this.obj));
  console.log(resp)
  if (resp['error'] == false) {
           this.dataSource = new MatTableDataSource(resp['data']);
           this.dataSource.sort = this.sort.toArray()[0];
           this.dataSource.paginator = this.paginator.toArray()[0]; ``

  }else{
    // this.dataSource = null
  }

}

details_function(element:any){
  this.details_obj= element
  this.restore_obj= element
  this.search_flag=false
  this.details_flag=true
  this.restore_flag= false
}

details_function_tab2(element:any){
  this.details_obj= element
  this.restore_obj= element
  this.search_flag_tab2=false
  this.details_flag_tab2=true

}


restore_function(element:any){
  this.restore_obj= element
  this.search_flag=false
  this.details_flag=false
  this.restore_flag= true

}
close(){
  this.search_flag= true
  this.details_flag=false
  this.restore_flag= false
 }

 close_tab2(){
  this.search_flag_tab2= true
  this.details_flag_tab2=false
 }


 async restore(){

   if(this.obj['restoration_date'] == null ){
    Swal.fire('Info', "Date not Selected", 'info')
    return
   }

   this.obj['b_acct_id'] = this.b_acct_id;
   this.obj['user_id'] = this.user_id
   this.obj['party_id'] =this.restore_obj['party_id']
   this.obj['arr_id'] =this.restore_obj['arr_id']
   this.obj['offer_cd'] = this.restore_obj['offer_cd']
   this.obj['status'] = 'RESTORED'
   this.obj['life_cycle_status'] = 'ACTIVE'

   //
   this.obj['property_id'] = this.restore_obj['alloted_property_id']
 console.log(this.obj)
   var resp: any = await this.adv_ser.restoreProperty(this.obj);
   if (resp['error'] == false) {
    Swal.fire('Property Restored', resp['data'], "success")
    this.getSeizedDetails()
      }else{
        Swal.fire('Info', resp['data'], 'info')
      }
}

 async AllRestoredproperty(){

  if(this.obj['offer_cd']===undefined || this.obj['offer_cd']===null){
    Swal.fire('select any Offer', 'Offer not selected', 'info')
    return;
  }
  this.obj['b_acct_id'] = this.b_acct_id

  console.log(this.obj)
  var resp : any = await this.adv_ser.AllRestoredproperty(JSON.stringify(this.obj));
  console.log(resp)
  if (resp['error'] == false) {
           this.dataSource2 = new MatTableDataSource(resp['data']);
           this.dataSource2.sort = this.sort.toArray()[0];
           this.dataSource2.paginator = this.paginator.toArray()[0];

  }else{
    this.dataSource2 = null
  }

}





}
