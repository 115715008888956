
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EmdSettingService } from '../../service/emd-setting.service';
import {MainService} from '../../service/main.service'
import {AuthenticationService} from '../../../authentication.service'
declare var $: any

@Component({
  selector: 'app-code-value',
  templateUrl: './code-value.component.html',
  styleUrls: ['./code-value.component.css']
})
export class CodeValueComponent implements OnInit {

  constructor(public auth:AuthenticationService,public mainService: MainService, private settingService: EmdSettingService, private router: Router, private spinner: NgxSpinnerService) { }
  erpUser:any;
  b_acct_id:any;


  allFields:any = [];
  obj:any = {};
  selectField:any;
  allCodeValue:any=[];
  selectedCodeValue:any=[]
  codeValueObj:any={};
  codeValueShowObj:any ={};

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  displayedColumns = ['id', 'code', 'value', 'action'];
  datasource:any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFields();
    await this.getCodeValue();

  }
  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
 async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getAllFields();
    await this.getCodeValue();
    //await this.getAllHeirarchy()
    //await this.getpropertygroup()
    //await this.getAllOffers()
  }


  async getCodeValue(){
    this.spinner.show();

    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    if (resp['error'] == false) {
      this.allCodeValue = resp.data;
      this.changeField();

      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire("Oops", "...Error while getting all values!",'error');

    }
  }
  open_update(element:any) {
    this.obj = Object.assign({}, element);
    this.selectField= element.field_code;
    $('.nav-tabs a[href="#tab-3"]').tab('show')
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
  }

  refresh() {
    this.obj = {};
  }


  async changeField() {
    this.selectedCodeValue =[];
    for(var i=0;i<this.allCodeValue.length;i++){
      if(this.selectField == this.allCodeValue[i].field_code){
        this.selectedCodeValue.push(this.allCodeValue[i])
      }
    }
    this.datasource = new MatTableDataSource(this.selectedCodeValue)
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];
  }

  async getAllFields() {
    var obj:any= new Object();
    obj['domain_code'] = 'EMD';
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show();

    var resp = await this.settingService.getFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFields = resp.data;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire("Oops", "...Error while getting all fields!",'error');

    }
  }

  async save() {
    this.spinner.show();
    this.obj['create_user_id'] = this.erpUser.user_id;
    this.obj['field_code'] = this.selectField;
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.insertCodeValue(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.open_list()
      await this.getCodeValue();
      await this.getCodeValueForService();
      await this.getCodeValue_obj()
      swal.fire("Success", "...code value added!",'success');
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while adding code value!",'error');

    }
  }
  async getCodeValue_obj(){
    this.spinner.show()
     var resp = await this.settingService.getCodeValue(this.b_acct_id);
     var codeValueTempObj:any={}
     var codeValueShowTempObj:any={};
     if(resp['error']==false){
       this.spinner.hide()
       for(var i=0;i<resp.data.length;i++){
         if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
           codeValueTempObj[resp.data[i]['field_code']] = [];
           codeValueShowTempObj[resp.data[i]['field_code']] ={}
         }
         codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
         codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
       }
       this.codeValueObj = codeValueTempObj;
       this.codeValueShowObj = codeValueShowTempObj;
       this.mainService.codeValueTechObj = this.codeValueObj;
       this.mainService.codeValueShowObj = this.codeValueShowObj;
     }else{
       this.spinner.hide()
     }
   }

  async update() {
    this.spinner.show();
    this.obj['update_user_id'] = this.erpUser.user_id;
    this.obj['field_code'] = this.selectField;
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateCodeValues(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.open_list()
      await this.getCodeValue();
      await this.getCodeValueForService();
      await this.getCodeValue_obj()
      swal.fire("Success", "...code value updated!",'success');

    } else {
      this.spinner.hide();
      swal.fire("Oops", "...Error while updating code value!",'error');

    }
  }
  async getCodeValueForService(){
    this.spinner.show();

    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any={}
    var codeValueShowTempObj:any={};
    if(resp['error']==false){
      for(var i=0;i<resp.data.length;i++){
        if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] ={}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
      this.spinner.hide();

    }else{
      this.spinner.hide();

    }

  }
  async delete(element:any){

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id;

    this.spinner.show();
    var resp = await this.settingService.deleteCodeValue(JSON.stringify(obj));

    if(resp['error']==false){
      await this.getCodeValue();
      await this.getCodeValueForService();
      this.spinner.hide();
      swal.fire("Success", "...code value Deleted!",'success');
    }else{
      this.spinner.hide();
      swal.fire("Oops", "...code value not deleted!",'error');
    }
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
