<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage Employee Joining -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining1']}}
                    </div>
                </div>
                <div class="ibox-body">

                    <div class="ibox-body">
                        <ul class="nav nav-tabs tabs-line">
                            <li class="nav-item">
                                <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                    <!-- Employee Joining Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining2']}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1">
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first" label="Employee Personal Info">
                                    <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                        <div class="col-12 text-center">
                                            {{errorMsg}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6 style="margin-left: 2%;">
                                                <!-- Enter Email/Mobile Number  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining3']}}
                                                <span style="color: red;">*</span> :</h6>
                                        </div>
                                        <div class="col-4">
                                            <input type="email" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining3']}}" class="form-control" [(ngModel)]="joiningObj['credential']">
                                        </div>
                                        <div class="rcol-4" *ngIf="show_org_msg" style="color: red;">
                                            <!-- This number is already occupied by any organization -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining4']}}
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="search()" class="btn btn-primary">
                                                <!-- Search -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining5']}}</button>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining6']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="text" [(ngModel)]="joiningObj['emp_id']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining7']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="text" *ngIf="disable_flag==true" disabled [(ngModel)]="joiningObj['emp_name']">
                                            <input class="form-control" type="text" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_name']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row" *ngIf="disable_flag==false">
                                        <div class="col-4 text-right">
                                            <!-- Nick Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining8']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="text" [(ngModel)]="joiningObj['nick_name']">
                                        </div>

                                    </div>
                                    <br *ngIf="disable_flag==false">
                                    <div class="row" *ngIf="disable_flag==false">
                                        <div class="col-4 text-right">
                                            <!-- Password -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining9']}}<span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="password" [(ngModel)]="joiningObj['ident_verify_secret']">
                                        </div>
                                    </div>
                                    <br *ngIf="disable_flag==false">
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employee Phone Number -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining10']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input size="10" class="form-control" *ngIf="disable_flag==true" disabled [(ngModel)]="joiningObj['emp_phone_no']">
                                            <input size="10" class="form-control" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_phone_no']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!--  Employee Email -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining11']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="email" *ngIf="disable_flag==true" disabled [(ngModel)]="joiningObj['emp_email']">
                                            <input class="form-control" type="email" *ngIf="disable_flag==false" [(ngModel)]="joiningObj['emp_email']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Father's Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining12']}}<span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="text" [(ngModel)]="joiningObj['emp_father_name']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employee DOB -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining13']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" *ngIf="disable_flag==false" type="date" [(ngModel)]="joiningObj['emp_dob']">
                                            <input class="form-control" *ngIf="disable_flag==true" type="date" disabled [(ngModel)]="joiningObj['emp_dob']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>Next</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second" label="Employee Joining Detail">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Joining Type  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining14']}}<span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0007']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="joiningObj['joining_type_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Joining of Service Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining15']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="date" [(ngModel)]="joiningObj['joining_service_date']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Joining Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining16']}}
                                            <span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="date" [(ngModel)]="joiningObj['joining_date']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Joining Order ID  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining17']}}<span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="number" [(ngModel)]="joiningObj['joining_order_id']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Joining Time -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining18']}}<span style="color: red;">*</span> :
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="ts" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="joiningObj['joining_time']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>Back</button> &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary" matStepperNext>Next</button>
                                            <!--Next-->


                                        </div>
                                    </div>



                                </mat-step>
                                <mat-step [stepControl]="third" label="Employee Posting Details">
                                    <br>

                                    <br>
                                    <div class="col-11 text-right">
                                        <button class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                                aria-hidden="true"></i></button>
                                    </div>
                                    <br>
                                    <br>
                                    <!--------------princy------------------------>

                                    <div *ngFor="let ln of AllCCRow; let i = index;">


                                        <div class="row">
                                            <div class="col-2 text-right">
                                                Select Module <span style="color: red;">*</span>:
                                            </div>
                                            <div class="col-2">
                                                <ng-select [items]="allModule" (change)="ChangeModule(ln['module_cd'],i)" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="" [(ngModel)]="ln['module_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="module_cd">
                                                </ng-select>
                                            </div>



                                            <div class="col-2 text-right">
                                                Select CC<span style="color: red;">*</span> :
                                            </div>
                                            <div class="col-2">
                                                <ng-select [items]="cost_center" bindLabel="cost_center_desc" bindValue="cost_center_code" [multiple]="false" placeholder="" [(ngModel)]="ln['cost_center']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                            <div class="col-2">
                                                <input type="checkbox" id="v1" name="v1" value="Bike" [(ngModel)]="ln.is_primary">
                                                <label for="v1"> Is Primary</label><br>
                                            </div>
                                            <div class="col-2">
                                                <button class="btn btn-danger " (click)="deleteRow(i)"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></button>
                                            </div>
                                        </div>

                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-outline-danger" matStepperPrevious>Back</button> &nbsp;&nbsp;

                                            <!--------------princy------------------------>
                                            <button class="btn btn-primary" (click)="addJoining()">
                                                <!-- ADD -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining19']}}</button>
                                        </div>
                                    </div>
                                </mat-step>

                                <!-- </div> -->
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>