<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online1']}}</div><!--Online Support-->
                </div>
                <div class="ibox-body">
                    <br>
                    <br>
                    <div class="container">
                        <form id="contact" action="" method="post">
                            <h3>{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online2']}}</h3><!--Quick Contact-->
                            <h4>{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online3']}}</h4><!--Contact us today, and get reply with in 24 hours!-->
                            <fieldset>
                                <input placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online4']}}" type="text" tabindex="1" [(ngModel)]="Subject" name="subject" required autofocus>
                            </fieldset><!--Subject-->

                            <fieldset>
                                <textarea placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online5']}}" tabindex="5" [(ngModel)]="Message" name="meg" required></textarea>
                            </fieldset><!--Type your Message Here....-->
                            <fieldset>
                                <button name="submit" type="submit" id="contact-submit" class="float-right" (click)="Submit()">{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online6']}}</button><!--Submit-->
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'Hrms_online7']}}</ngx-spinner><!--Loading....-->