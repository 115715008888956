import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../../emb/service/user.service';
import { FileUploader } from 'ng2-file-upload';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
declare var $: any;
@Component({
  selector: 'app-hrms-online-support',
  templateUrl: './hrms-online-support.component.html',
  styleUrls: ['./hrms-online-support.component.css']
})
export class HrmsOnlineSupportComponent implements OnInit {


  user_id:any ;
  user_email:any ;
  ebillUser:any ;
  b_acct_id:any ;
  name:any ;




  constructor(private router: Router, private spinner: NgxSpinnerService,
    private userService: UserService, private profileService: ProfileService,
    private sanitizer: DomSanitizer, public mainService: MainService) {
  }

  Message:any ;
  Subject:any ;


  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;

  }
  Submit() {
    console.log(this.Subject);
    console.log(this.Message);
  }


}
