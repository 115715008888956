<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                <div class="ibox-title">
              Extra Work Management
                </div>
            </div>
            <div class="ibox-body">
                <ul class="nav nav-tabs tabs-line">

                    <li class="nav-item">
                        <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-settings"></i>
                           Extra Work List </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                            Create Extra Work</a>
                    </li>
                </ul>



                <div class="tab-content">
 <div class="tab-pane fade  show active" id="tab-1">
                        <br>

                    <div class="row">
               <div class="col-6">
                    <mat-form-field style="margin-left: 2%">
                         <input matInput (keyup)="applyFilter1($any($event.target).value)"
                       placeholder="Filter">
                     </mat-form-field>
                      </div>
                       </div>

    <div class=" example-container">
        <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort">
            <ng-container matColumnDef="work_id">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Work Id

                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{ element['work_id'] }}</td>
            </ng-container>

            <ng-container matColumnDef="emp_name">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    Name

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.emp_name }} </td>
            </ng-container>


            <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Purpose

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.purpose }} </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Start Date

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ mainService.dateformatchange(element.start_date)}} </td>
            </ng-container>
            <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>End Date

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ mainService.dateformatchange(element.end_date)}} </td>
            </ng-container>
            <ng-container matColumnDef="start_time">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Start Time

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <!-- {{ mainService.dateformatchange(element.start_time)}} -->
                   <input disabled type="time"  [(ngModel)]="element.start_time"> </td>
            </ng-container>
            <ng-container matColumnDef="end_time">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>End Time

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <!-- {{ mainService.dateformatchange(element.end_time)}} -->
                    <input disabled type="time"  [(ngModel)]="element.end_time">  </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Status

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.work_status_code}}

            </ng-container>

            <!-- <ng-container matColumnDef="print">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Download

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <button class="btn btn-primary"
                        (click)="getAllBill(element,'bill')">Bill</button> -->
                    <!-- <button class="btn btn-primary" (click)="getAllBill(element,'summary')">Summary</button> -->
                <!-- </td>
            </ng-container> -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <!-- <button *ngIf="element.bill_status_code == 'GENERATED'" class="btn btn-info"
                        (click)="statusChange(element)">APPROVED</button> -->
                    <button *ngIf="element.work_status_code == 'GENERATED'" class="btn btn-info"
                        (click)="Appr(element)">Send TO Approval</button>
                    <button *ngIf="element.work_status_code != 'GENERATED'" class="btn btn-info"
                        disabled>Send TO Approval</button>

                    <!-- <button *ngIf="element.work_status_code == 'APPROVED'" class="btn btn-info"
                        (click)="sendTOAccount(element)">sendTOAccount</button>
                    <button -->
                        <!-- *ngIf="element.bill_status_code == 'GENERATED' || element.bill_status_code == 'REJECTED'"
                        class="btn btn-danger" (click)="deleteApproval(element)">Delete</button>
                    <button *ngIf="element.bill_status_code != 'GENERATED' "
                        class="btn btn-primary" (click)="status(element)">View Status</button> -->


                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
            </tr>
        </table>
        <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
        </mat-paginator>
    </div>
 </div>

                    <div class="tab-pane fade " id="tab-2">

                        <div class="row">
                            <div class="col-5 text-right">
                                <h6 style="margin-right: 10%;">
                                    Select Node : <span style="color:red">*</span>
                                </h6>
                            </div>
                            <div class="col-4 text-left margin-left 10%">
                                <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                    Employee Node</button>
                            </div>


                            </div>
                            <br>
                            <div class="row">
                            <div class="col-5 text-right">
                                <h6 style="margin-right: 10%;">
                                    Selected Node Path:<span style="color: red;">*</span>
                                </h6>
                            </div>
                            <div class="col-4 text-left margin-left: 10%">
                               <input class="form-control" [(ngModel)]="Obj['path_desc']" disabled>
                               <!-- {{Obj['path_desc']}} -->
                            </div>
                        </div>
 <br>
 <div class="row">
    <div class="col-5 text-right">
    <span style="margin-right: 10%;"> Selected Employee :<span style="color: red;">*</span> </span>

    </div>
    <div class="col-4">
        <!-- <ng-select [items]="newallEmplyees" bindLabel="emp_name"
             (change)="changeEmployee()" bindValue="arr_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="arr_id"
        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
    </ng-select> -->
      <ng-select disabled [items]="allEmplyees_new" bindLabel="emp_name"
                                        bindValue="arr_id" [multiple]="true" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="false" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
    </div> </div>
 <br>
<div class="row">
    <div class="col-5 text-right">
    <span style="margin-right: 10%">Date :<span style="color: red;">*</span></span>   </div>
<div class="col-2 text-left margin-left:10%">
   From - &nbsp; &nbsp; <input class="form-control" type="date"  [(ngModel)]="fromDate">
</div>
<div class="col-2 text-left margin-left:10%">
   To - &nbsp; &nbsp; <input class="form-control" type="date" [(ngModel)]="toDate">
</div>
</div>
<br>
                      <div class="row">
                         <div class="col-5 text-right">
                         <span style="margin-right: 10%"> Time :<span style="color: red;">*</span></span>   </div>
                    <div class="col-2 text-left margin-left:10%">
                        From - &nbsp; &nbsp; <input class="form-control" type="time"  [(ngModel)]="fromTime">

                    </div>
                    <div class="col-2 text-left margin-left:10%">
                        To - &nbsp; &nbsp; <input class="form-control" type="time" [(ngModel)]="toTime">
                    </div>
                 </div>
 <br>
              <div class="row">
                        <div class="col-5 text-right">
                            <span style="margin-right: 10%"> Purpose :<span style="color: red;">*</span> </span>
                        </div>
                    <div class="col-4 text-left margin-left:10%">
                        <textarea class="form-control rounded-0" rows="3" [(ngModel)]="purpose"></textarea>
                    </div>
                </div>
<br>
                    <div class="row">
                        <div class="col-5 text-right">
                            <span style="margin-right: 10%"> Remark :<span style="color: red;">*</span> </span>
                        </div>
                    <div class="col-4 text-left margin-left:10%">
                        <textarea class="form-control rounded-0"  rows="3" [(ngModel)]="remark"></textarea>
                         </div>
                    </div>
<br>
                    <div class="row">
                        <!-- <div class="col-6 text-center"> -->
                        <div class="col-12 text-center">
                            <button class="btn btn-primary" (click)="submit()" >Submit </button>
                             </div>
                             <!-- <div class="col-6 text-left margin-left:2rem">
                                <button class="btn btn-primary" (click)="apro()" > Send To Approve</button>
                             </div> -->
                          </div>
<br>

                </div>

              </div>
            </div>
        </div>
    </div>

</div>
</div>
<ngx-spinner>Loading....</ngx-spinner>



<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false"
                         placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                          (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>

   <!-- aproval data  -->
   <div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>


                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit1

                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false &&  ApprovalUserService.approvalflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit2

                        </button>
                    </div>
                </div>
                <br>
                <div *ngIf="ApprovalUserService.vendorflag==false  && ApprovalUserService.approvalflag==true"
                class="row">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" data-dismiss="modal" (click)="approvedocment()">Approve3
                    </button>
                </div>
            </div>







                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <!-- <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div> -->
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
