import { Component, OnInit } from '@angular/core';


declare var $: any

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.css']
})
export class AccountsPartyComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {

  }

}
