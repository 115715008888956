<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="col-12 text-right">
                        <h6> {{current_user}}</h6>
                    </div>
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item {{list_act}}">
                            <a class="nav-link" *ngIf="list_flag" (click)="list()" href="#tab-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                Support Letters
                            </a>
                        </li>


                        <li class="nav-item " *ngIf="c_flag || u_flag">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-line-chart"></i>
                                Support Letter
                            </a>
                        </li>


                        <li class="nav-item" *ngIf="appli_flag">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="fa fa-line-chart"></i>
                                Support Letter Drafting
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <br>
                        <div class="col-4">
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                        </div>
                        <div *ngIf="list_flag" class="active" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="create()">Add new Letter</button>
                                </div>
                            </div>
                            <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort>


                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef> Serial Number </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef> Order ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.order_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="dep_name">
                                        <th mat-header-cell *matHeaderCellDef>Department</th>
                                        <td mat-cell *matCellDef="let element"> {{element.dep_name}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="branch_name">
                                        <th mat-header-cell *matHeaderCellDef> Branch Office </th>
                                        <td mat-cell *matCellDef="let element"> {{element.branch_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="officer_name">
                                        <th mat-header-cell *matHeaderCellDef> Officer Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.officer_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="letter_sub">
                                        <th mat-header-cell *matHeaderCellDef> Subject of Letter </th>
                                        <td mat-cell *matCellDef="let element"> {{element.letter_sub}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="req_by">
                                        <th mat-header-cell *matHeaderCellDef>Requested By</th>
                                        <td mat-cell *matCellDef="let element"> {{ userObj[element.req_by]}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">
                                            <button class="btn btn-outline-warning"
                                                (click)="open_update(element,'update')">
                                                Update</button>
                                            <button class="btn btn-outline-primary"
                                                (click)="download(element)">Download</button>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>



                        </div>


                        <div *ngIf="c_flag || u_flag" class="active" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-info" (click)="list()">Back To List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Order ID : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <ng-select [items]="orderData" bindLabel="co_ord_id" bindValue="co_ord_id"
                                        [multiple]="false" placeholder="Select Order" [(ngModel)]="depdata['order_id']"
                                        (change)="getZoneWiseUser()" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Select Department : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <ng-select [items]="codeValueTechObj['ICMS005']" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder="Select Department"
                                        [(ngModel)]="depdata['dep_name']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Select Branch Office : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <ng-select [items]="codeValueTechObj['ICMS012']" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder="Select Branch Office"
                                        [(ngModel)]="depdata['branch_name']"
                                        (change)="getOfficerName(depdata['branch_name'])" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"> </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Select Concerned Officer : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <ng-select [items]="officer_name" bindLabel="officer_name" bindValue="officer_name"
                                        [multiple]="false" placeholder="Select Concerned Officer"
                                        [(ngModel)]="depdata['officer_name']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Subject of Letter : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <!-- <input type="text" placeholder="Subject of Letter for which letter to be drafted"
                                        class="form-control"> -->
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"
                                        [(ngModel)]="depdata['letter_sub']"></textarea>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                            <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Letter Description : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <textarea class="form-control" id="exampleFormControlTextarea1"
                                        placeholder="Content of the Letter which need to print" rows="2"></textarea>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Requested By : <span class="text-danger">*</span></div>
                                <div class="col-3">
                                    <ng-select [items]="zoneWiseUser" bindLabel="desc" bindValue="user_id"
                                        [multiple]="false" placeholder="Select User" [(ngModel)]="depdata['req_by']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true">
                                    </ng-select>

                                </div>
                                <div class="col-3">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-danger" (click)="list()">Cancel</button>
                                    &nbsp;&nbsp;
                                    <button class="btn btn-outline-info" (click)="applicationSc()">Next</button>


                                </div>
                            </div>
                        </div>
                        <div *ngIf="appli_flag" class="active" id="tab-3">

                            <!-- <h1>Application Letter </h1> -->
                            <app-letter-editor></app-letter-editor>


                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-success text-center" (click)="back()">Back</button>
                                    &nbsp;&nbsp;
                                    <button class="btn btn-outline-info text-center" *ngIf="submit_button"
                                        (click)="createSupportLetter()">Submit</button>

                                    <button class="btn btn-outline-info" *ngIf="update_button"
                                        (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
