<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage Reappointments -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                <!-- All Employee Reappointments List -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"(click)="refresh()" ><i
                                    class="ti-settings"></i>
                                <!-- Employee Reappointments  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment3']}}
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">



                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment5']}}
                                        :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'	HR-Reappointment5']}}"
                                        [(ngModel)]="updateObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" (change)="getAllCurrentArrangements()"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>

                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                            <br>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="arr_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{this.mainService.accInfo['account_short_name']}}
                                            {{getNumberFormat(element['emp_id'])}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="effective_timestamp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Effective Timestamp -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment8']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <p *ngIf="element['effective_timestamp']">
                                                {{mainService.dateformatchange(element.effective_timestamp.split('
                                                ')[0])}} </p>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Order ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    <h6><!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment5']}} : <span style="color: red;">*</span></h6>

                                </div>
                                <!-- <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'	HR-Reappointment5']}}"
                                        [(ngModel)]="updateObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" (change)="getAllCurrentArrangements()"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div> -->
                                <div class="col-4">
                                    <!-- <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id"
                                        [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'	HR-Reappointment5']}}"
                                        [(ngModel)]="updateObj['emp_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" (change)="getAllCurrentArrangements()"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div> -->

                                <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder=""  [(ngModel)]="updateObj['emp_id']"
                                (change)="getAllCurrentArrangements()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_id">
                                </ng-select>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <!-- Order ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment9']}}:
                                </div>
                                <div class="col-3">


                                    <input *ngIf="mainService.codeValueTechObj['order_id']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['order_id']">
                                    <select *ngIf="mainService.codeValueTechObj['order_id']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['order_id']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['order_id']"
                                            [ngValue]="ob['code']">{{ob['value']}}</option>
                                    </select>
                                </div>
                                <div class="col-3 text-right">
                                    <!-- Calculation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment10']}}:
                                </div>
                                <div class="col-3">
                                    <!-- <input *ngIf="mainService.codeValueTechObj['basic_pay']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['basic_pay']">
                                    <select *ngIf="mainService.codeValueTechObj['basic_pay']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['basic_pay']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['basic_pay']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="baseArr" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="updateObj['basic_pay']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <!-- Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment11']}}:
                                </div>
                                <div class="col-3">


                                    <!-- <input *ngIf="mainService.codeValueTechObj['designation_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['designation_code']">
                                    <select *ngIf="mainService.codeValueTechObj['designation_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['designation_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['designation_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0011']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['designation_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <!-- Retirement Age -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment12']}}:
                                </div>
                                <div class="col-3">



                                    <!--  <input *ngIf="mainService.codeValueTechObj['retirement_age']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['retirement_age']"> -->
                                    <!-- <select *ngIf="mainService.codeValueTechObj['leave_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['retirement_age']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['retirement_age']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select>  -->
                                    <!-- <ng-select [items]="mainService.codeValueTechObj['HR0012']" bindLabel="value" bindValue="code" [multiple]="false"
                                     placeholder="" [(ngModel)]="updateObj['retirement_age']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0012']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['retirement_age']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <!-- Service Type -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment13']}}:
                                </div>
                                <div class="col-3">
                                    <!-- <input *ngIf="mainService.codeValueTechObj['establishment_type_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['establishment_type_code']">
                                    <select *ngIf="mainService.codeValueTechObj['establishment_type_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['establishment_type_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['establishment_type_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0015']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['establishment_type_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                                <div class="col-3 text-right">
                                    <!-- Cadre -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment14']}}
                                    :
                                </div>
                                <div class="col-3">


                                    <!-- <input *ngIf="mainService.codeValueTechObj['cadre_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['cadre_code']">
                                    <select *ngIf="mainService.codeValueTechObj['cadre_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['cadre_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['cadre_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0013']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['cadre_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <!-- Class -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment15']}}
                                    :</div>
                                <div class="col-3">
                                    <!-- <input *ngIf="mainService.codeValueTechObj['class_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['class_code']">
                                    <select *ngIf="mainService.codeValueTechObj['class_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['class_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['class_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0014']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['class_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                                <div class="col-3 text-right">
                                    <!-- Pay Commission -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment16']}}
                                    :</div>
                                <div class="col-3">
                                    <!-- <input *ngIf="mainService.codeValueTechObj['pay_commission_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['pay_commission_code']">
                                    <select *ngIf="mainService.codeValueTechObj['pay_commission_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['pay_commission_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['pay_commission_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="mainService.codeValueTechObj['HR0016']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateObj['pay_commission_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-3 text-right">
                                    <!-- Pay Scale -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment17']}}:
                                </div>
                                <div class="col-3">


                                    <!-- <input *ngIf="mainService.codeValueTechObj['pay_scale_code']==undefined"
                                        class="form-control" [(ngModel)]="updateObj['pay_scale_code']">
                                    <select *ngIf="mainService.codeValueTechObj['pay_scale_code']!=undefined"
                                        class="form-control" [(ngModel)]="updateObj['pay_scale_code']">
                                        <option *ngFor="let ob of mainService.codeValueTechObj['pay_scale_code']"
                                            [(ngValue)]="ob['code']">{{ob['value']}}</option>
                                    </select> -->
                                    <ng-select [items]="pay_scale" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="updateObj['pay_scale_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                                <div class="col-3 text-right">
                                    <!-- Grade Pay -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment18']}}:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="grade_pay" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="updateObj['grade_pay_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">

                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="submitReappointment()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Reappointment19']}}</button>

                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
