<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Employee Complaint Register  -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- Employee Complaint Detail List -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" (click)="addEmp()" data-toggle="tab"><i class="fa fa-plus-circle"></i> <!-- Add Employee Complaint Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint3']}}
                                </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" (click)="refrace()"   data-toggle="tab"><i class="ti-settings"></i> <!-- Update Employee Complaint Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint4']}}
                                </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-settings"></i> <!-- Suspend After Complaint -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint24']}}
                                </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Complaint ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Complaint Type -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0035'][element.complaint_type_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Complaint Desc -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complaint_desc}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="complaint_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Complaint Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.complaint_dt)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint25']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <!--Employee name-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint26']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdToName[element.emp_name] }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="complaint_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Complaint Status -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complaint_status}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button *ngIf="element.complaint_status != 'CLOSED' && element.complaint_status != 'FORWARD' && element.complaint_status != 'SUSPENDED' && element.complaint_status != 'APPROVED' " class="btn btn-warning" (click)="openUpdate(element)"><!-- UPDATE -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint11']}}</button>

                                           <!-- <button *ngIf="element.complaint_status == 'REGISTERED'" class="btn btn-danger" (click)="changeStatus(element)"> ENQUIRY {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint23']}}</button>-->
                                            <button *ngIf="element.complaint_status == 'REGISTERED'" class="btn btn-danger" (click)="open_popup(element)"> <!--Process--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint27']}}</button>
                                            <h6 *ngIf="element.complaint_status == 'FORWARD'"  >Forward To {{element.forward_to}}</h6>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint12']}}:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" (change)="checkEmployee()" [multiple]="false" placeholder="" [(ngModel)]="complaintObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complaint Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint13']}}:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                   <ng-select [items]="mainService.codeValueTechObj['HR0035']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="complaintObj['complaint_type_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Compliant Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint15']}}:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="complaintObj['complaint_dt']">
                               </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                     <!--Nature of Complaint : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint28']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                   <ng-select [items]="mainService.codeValueTechObj['HR0053']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="complaintObj['nature_of_complaint']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complianer Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint29']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="complaintObj['complainer_name']">
                               </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complianer Mobile No : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint30']}}<span style="color: red;">*</span>
                                </div>
                                <!-- <div class="col-4">
                                    <input class="form-control" type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    maxlength = "10"
                                 [(ngModel)]="complaintObj['complainer_mob_no']">

                               </div> -->
                                <!-- <div class="col-4">
                                    <input class="form-control" type="number" oninput="chekMo(this.value)"

                                 [(ngModel)]="complaintObj['complainer_mob_no']">

                               </div> -->
                               <div class="col-4">
                                <!-- <input class="form-control" [(ngModel)]="bankaccObj['acct_no']"> -->
                                <input class="form-control" type="number"  #ngInput1 (ngModelChange)="chekMo(ngInput1.value)" [(ngModel)]="complaintObj['complainer_mob_no']"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength = "10"> <br>


                                <ng-container *ngIf="mobile_no_validation==false; else elseTemplate"  style="color: red">
                                    <span style="color: red"> <i>Mobile no must be 10 digit long <span> {{mo_no_len}}</span></i>  </span>
                                 </ng-container>
                                            <ng-template #elseTemplate  style="color: green">
                                                         <span style="color: green"> <i>Your Mo.No. is {{mo_no_len}} digit long </i></span>
                                             </ng-template>



                            </div>
                               </div>
                              <!-- </div> -->
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complaint Description : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint14']}}:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                              <textarea style="width: 100%;" id="desc" name="desc"  onkeyup="this.value=this.value.replace(/[^a-zA-Z ]/g,'');" [(ngModel)]="complaintObj['complaint_desc']"  ></textarea>
                             </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                     <!--Support Document Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint31']}}<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                   <ng-select [items]="mainService.codeValueTechObj['HR0054']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="complaintObj['support_doc_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!-- Upload Document :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint32']}}
                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                        class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)" id="docUpload">
                                </div>

                            </div>
                            <br>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitComplainMessage()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint16']}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Employee Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint17']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control"  [(ngModel)]="updateComplaintObj['emp_name']" disabled>
                            </div>
                           </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complaint Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint18']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0035']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="updateComplaintObj['complaint_type_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                               </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Compliant Date : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint20']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="updateComplaintObj['complaint_dt']" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Nature of Complaint : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint28']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                   <ng-select [items]="mainService.codeValueTechObj['HR0053']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="updateComplaintObj['nature_of_complaint']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Complianer Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint29']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="updateComplaintObj['complainer_name']">
                               </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Complianer Mobile No : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint30']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    maxlength = "10" [(ngModel)]="updateComplaintObj['complainer_mob_no']">
                               </div>
                              </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                   <!--  Complaint Description : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint19']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                              <textarea style="width: 100%;" id="desc" name="desc" onkeyup="this.value=this.value.replace(/[^a-zA-Z ]/g,'');"    [(ngModel)]="updateComplaintObj['complaint_desc']"></textarea>
                               </div>
                               </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                     <!-- Support Document Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint31']}}
                                </div>
                                <div class="col-4">
                                   <ng-select [items]="mainService.codeValueTechObj['HR0054']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="updateComplaintObj['support_doc_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                              </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                  <!--  Upload Document : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint32']}}
                                </div>
                                <div class="col-4 text-center">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                        class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)" id="uploadFile">
                                </div>

                            </div>
                            <br>



                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateComplaint()"><!-- UPDATE COMPLAINT -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint21']}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint12']}}
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="newallEmplyees" (change)="getFixedPayDetails()" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="suspensionCompObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input class="form-control" type="text" [(ngModel)]="suspensionCompObj['emp_name']" disabled>
                                </div>
                            </div>
                            <br>

                            <hr>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint34']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionCompObj['order_id']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Start Date : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint35']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionCompObj['suspension_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Note : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint36']}}
                                </div>
                                <div class="col-4">
                                    <textarea placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code32']}}" type="text" class="form-control" [(ngModel)]="suspensionCompObj['suspension_note']"></textarea>
                                </div>
                            </div>
                            <br>
                            <br>
                               <div class="row">
                                <h6 class="text-center"><!--Payment Fractions-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint38']}}</h6>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">

                                     <table>
                                         <tr>
                                         <th><!--Pay-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint39']}}</th>
                                         <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint40']}}</th>
                                         </tr>
                                         <tr *ngFor="let pay of payCompArr;let i=index">
                                            <td>{{pay.pay_component_code}}</td>
                                            <td><input class="form-control" type="number" [(ngModel)]="payCompObj[i]"></td>
                                         </tr>
                                     </table>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                     <table>
                                     <tr>
                                    <th><!--Deduction-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint41']}}</th>
                                    <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint40']}}</th>
                                     </tr>
                                     <tr *ngFor="let ded of dedCompArr;let j=index">
                                        <td>{{ded.pay_component_code}}</td>
                                        <td><input class="form-control" type="number" [(ngModel)]="dedCompObj[j]" ></td>
                                     </tr>
                                    </table>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitCompSuspension()">
                                        <!-- Submit -->     {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint16']}}
                                    </button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner><!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint22']}}</ngx-spinner>


<!-- FOR OPEN PROCESS DIALOG BOX-->
<div class="modal" id="PROCESS">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                 <h4 class="modal-title"><!--Complaint Processing--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint42']}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Forward the Complaint to the Next Level :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint43']}}</h6>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary" (click)="open_forward()"><!--Forward -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint44']}}</button>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Approve Complaint & Create Enquiry :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint45']}}</h6>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-secondary" (click)="approve()"><!--Approve-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint46']}}</button>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Suspend the Employee :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint42']}}</h6>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-danger" (click)="open_suspend()"><!--Suspend -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint48']}}</button>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Close  Complaint :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint49']}}</h6>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-danger" (click)="open_close()"><!--Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint50']}}</button>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">

                </div>

            </div>
        </div>
    </div>
</div>

<!-- FOR OPEN FORWARD DIALOG BOX-->
<div class="modal" id="FORWARD">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"><!--Forwarding Complaint--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint51']}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Complaint ID :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint5']}}</h6>
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="forObj['id']" disabled>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Select Module/Department :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint52']}} <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="allModule" (change)="selectedModule(forObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="" [(ngModel)]="forObj['module_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Select Role : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint53']}} <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="current_role" (change)="selectedRole(forObj['role_desc'],forObj['module_cd'])" bindLabel="role_name" bindValue="role_desc" [multiple]="false" placeholder="" [(ngModel)]="forObj['role_desc']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Select User :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint54']}} <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-3">
                        <ng-select [items]="allModuleWiseUser" (change)="selectUser(forObj['user_id'])" bindLabel="party_name" bindValue="user_id" [multiple]="false" placeholder="" [(ngModel)]="forObj['user_id']"  [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name">
                        </ng-select>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                <div class="col-3">
                    <h6 style="margin-left: 2%;"><!--Note :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint55']}} <span style="color: red;">*</span></h6>
                </div>
                <div class="col-3">
                    <textarea style="width: 100%;" id="desc" name="desc" [(ngModel)]="forObj['note']"></textarea>
                </div>

                <div class="col-3"></div>
            </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="forwardComplaint()" ><!--Forward -->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint44']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- FOR OPEN CLOSE DIALOG BOX-->
<div class="modal" id="CLOSE">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"><!-- Close Complaint--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint49']}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!--Complaint ID :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint5']}}</h6>
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="closeObj['id']" class="form-control" disabled>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3">
                        <h6 style="margin-left: 2%;"><!-- Complaint Description :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint56']}}</h6>
                    </div>
                    <div class="col-3">
                        <textarea style="width: 100%;" [(ngModel)]="closeObj['complaint_desc']"  id="desc" name="desc" disabled></textarea>
                    </div>

                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                <div class="col-3">
                    <h6 style="margin-left: 2%;"><!-- Closing Note :--> {{mainService.allLableShowObjHR[mainService.language_cd+'Complaint57']}}</h6>
                </div>
                <div class="col-3">
                    <textarea style="width: 100%;" [(ngModel)]="closeObj['closing_note']" id="desc" name="desc"></textarea>
                </div>

                <div class="col-3"></div>
            </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="popup_cancel()"><!--Cancel-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint58']}}</button>
                    <button type="button" class="btn btn-primary" (click)="closeComplaint()"><!--Submit-->{{mainService.allLableShowObjHR[mainService.language_cd+'Complaint16']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
