<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold1']}}<!-- Salary Hold Report --> </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold1']}}<!-- Salary Hold Report --></a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold2']}}<!-- Select Status --> :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="status" bindLabel="id" bindValue="id" (change)="getAllStopSalary()"
                                        [multiple]="false" placeholder="" [(ngModel)]="status_s" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>

                            <!-- <div class="row">
                                <div class="col-12  text-center">
                                    <button class="btn btn-primary" (click)="getAllStopSalary()">SUBMIT</button> &nbsp;&nbsp;

                                </div>
                            </div> -->

                            <br>
                            <div class="row">
                                <div class="col-12  text-right">
                                    <button class="btn btn-primary" (click)="print()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold3']}}<!-- Download PDF -->
                                    </button> &nbsp;&nbsp;
                                    <button class="btn btn-primary" (click)="export()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold4']}}<!-- Download Excel -->
                                    </button> &nbsp;&nbsp;

                                </div>
                            </div>
                            <div class=" example-container">
                                <div class="row">
                                    <div class="col-6 text-left">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold5']}}"> <!-- Filter -->
                                </mat-form-field>
                            </div>
                        </div>
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold7']}}<!-- S No -->.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{i+1}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold8']}}<!-- Employee ID -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{mainService.accInfo['account_short_name']}}{{getNumberFormat(element.emp_id)}}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold6']}}<!-- Employee Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold9']}}<!-- Designation -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="stop_month">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold10']}}<!-- Stop Month -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            {{element.stop_month}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="stop_fin_year">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold11']}}<!-- Stop Financial Year -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            {{element.stop_fin_year}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_month">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold12']}}<!-- Start Month -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            {{element.start_month}}

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_fin_year">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold13']}}<!-- Start Financial Year -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            {{element.start_fin_year}}

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold14']}}<!-- Status -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold15']}}<!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="viewPaidStatus(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold16']}}<!-- View Paid Details -->
                                            </button>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title"> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold17']}}<!-- Paid Salary Details --> :</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-3 text-right"><h6>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold8']}}<!-- Employee ID --> :</h6></div>
                    <div class="col-3"> {{view_data_header['emp_id']}}</div>
                    <div class="col-3 text-right"><h6>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold6']}}<!-- Employee Name --> :</h6></div>
                    <div class="col-3">{{view_data_header['emp_name']}}</div>
                </div>
                <br>

                <div class="">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold7']}}<!-- S No -->.</th>
                                <th>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold18']}}<!-- Month --></th>
                                <th>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold19']}}<!-- Year --></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let item of view_data;let i=index">
                                <td>{{i+1}}</td>
                                <td>{{this.mainService.codeValueShowObj['HR0024'][item['month']]}}</td>
                                <!-- <td>{{item['fin_year']}}</td> -->
                                <td>{{this.mainService.codeValueShowObj['HR0023'][item['fin_year']]}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold20']}}<!-- Close --></button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-SalaryHold21']}}<!-- Loading -->....</ngx-spinner>
