import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../md/service/main.service';

@Component({
  selector: 'app-hr-workflow',
  templateUrl: './hr-workflow.component.html',
  styleUrls: ['./hr-workflow.component.css']
})
export class HrWorkflowComponent implements OnInit {
  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='HR'
  }


}
