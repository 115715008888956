<div class="page-content fade-in-up" *ngIf="dashboard">

    <div class="ibox">
        <div class="ibox-head">
            <div class="ibox-title">
                Welcome , {{erpUser.party_name}}
            </div>
        </div>
        <div>

            <!-- <h6>Complaint</h6> -->
            <div class="row">
                <div class="col-5">
                    <hr class="hrline" />
                </div>
                <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                        Complaint
                    </h6>
                </div>
                <div class="col-5">
                    <hr class="hrline" />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardpurple color-white widget-stat" > -->
                    <div class="ibox color-white widget-stat" style="background-color: #e59704;">
                        <div class="ibox-body">


                            <h2 class="m-b-5 font-strong">{{complaint_number}}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">Total Complaints </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getallzonedetailList()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardgreen color-white widget-stat"> -->
                    <div class="ibox color-white widget-stat" style="background-color:#27a844 ;">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{total_assign}}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8"> Complaints Under Inspection </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getassignedList()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardRed color-white widget-stat"> -->
                    <div class="ibox color-white widget-stat" style="background-color: #5e9ea0;">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{total_completed}}</h2>
                            <div class="row d-flex">
                                <div class="col-7">
                                    <div class="m-b-8">Total Inspections Completed </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getcomplaintcompletedList()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>


                        </div>
                    </div>
                </div>

            </div>
            <!-- <h6>E-Court</h6>   -->
            <div class="row">
                <div class="col-5">
                    <hr class="hrline" />
                </div>
                <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                        E-Court
                    </h6>
                </div>
                <div class="col-5">
                    <hr class="hrline" />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="ibox cardblue  color-white widget-stat">
                        <div class="ibox-body">


                            <h2 class="m-b-5 font-strong">{{total_case}}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">Total Cases</div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getAllCasesDtls()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardYello color-white widget-stat"> -->
                    <div class="ibox  color-white widget-stat" style="background-color: #ea783c;">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{total_notice}}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">Total Notices </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getnoticesent()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ibox cardblck color-white widget-stat">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{total_case_in_progress}}</h2>
                            <div class="row d-flex">
                                <div class="col-7">
                                    <div class="m-b-8">Case In Progress </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="CaseInProgress()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>


                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <!-- <h6>Court Order</h6>   -->

            <div class="row">
                <div class="col-5">
                    <hr class="hrline" />
                </div>
                <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                        Court Order
                    </h6>
                </div>
                <div class="col-5">
                    <hr class="hrline" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardredYello  color-white widget-stat" > -->
                    <div class="ibox  color-white widget-stat" style="background-color:green;">
                        <div class="ibox-body">


                            <h2 class="m-b-5 font-strong">{{demolition}}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">Demolition Orders </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getDemolitionorder()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="ibox cardSky color-white widget-stat">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{compunding }}</h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">Compounding Orders </div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getCompundingorder()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- <div class="ibox cardredblack color-white widget-stat"> -->
                    <div class="ibox color-white widget-stat" style="background-color:#cd8703 ;">
                        <div class="ibox-body">
                            <h2 class="m-b-5 font-strong">{{self_demolition}}</h2>
                            <div class="row d-flex">
                                <div class="col-7">
                                    <div class="m-b-8">Self Demolition </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0"
                                        (click)="getSelfDemolitionorder()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>


                        </div>
                    </div>
                </div>
            </div>
            <!-- ////////attdendence -->
            <div class="row">
                <div class="col-5">
                    <hr class="hrline" />
                </div>
                <div class="col-2 text-center">
                    <h6 id="h" style="color: brown; min-height: 20px; margin-top:5px;">
                        Attendence
                    </h6>
                </div>
                <div class="col-5">
                    <hr class="hrline" />
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="ibox cardblue  color-white widget-stat">
                        <div class="ibox-body">

                            <h2 class="m-b-5 font-strong">{{attendence}}</h2>
                            <h2 class="m-b-5 font-strong"></h2>
                            <div class="row d-flex">
                                <div class="col-6">
                                    <div class="m-b-8">User Attendance</div>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-primary view-offer ml-0" (click)="user_attendence()"> View </button>
                                </div>
                            </div>
                            <i class="fa fa-list widget-stat-icon"></i>

                        </div>
                    </div>
                </div>

            </div> -->

            <!-- <hr> -->
            <div *ngIf="b_acct_id==92">
                <div class="row">
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px">
                            Tracking
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="ibox cardyelloBlue  color-white widget-stat">
                            <div class="ibox-body">


                                <h2 class="m-b-5 font-strong"></h2>
                                <div class="row d-flex">
                                    <div class="col-6">
                                        <div class="m-b-8">Total user </div>
                                    </div>
                                    <div class="col-2"></div>
                                    <div class="col-4">
                                        <button type="button" class="btn btn-primary view-offer ml-0"
                                            (click)="getuser()"> View </button>
                                    </div>
                                </div>
                                <i class="fa fa-list widget-stat-icon"></i>

                            </div>
                        </div>
                    </div>

                    <!-- <button type="button" class="btn btn-primary view-offer ml-0" (click)="getuser()" > View </button>  -->
                    <!-- <div class="col-lg-4 col-md-6">
            <div class="ibox bg-warning color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_assign}}</h2>
                    <div class="row d-flex">
                        <div class="col-6">
                            <div class="m-b-8">Total Assigned Complaints  </div>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4">


                        </div>
                       </div>
                  <i class="fa fa-list widget-stat-icon"></i>

                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="ibox bg-success color-white widget-stat">
                <div class="ibox-body">
                    <h2 class="m-b-5 font-strong">{{total_completed}}</h2>
                    <div class="row d-flex">
                        <div class="col-7">
                            <div class="m-b-8">Total Inspections Completed  </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-4">
                            <button type="button" class="btn btn-primary view-offer ml-0"  > View </button>
                        </div>
                       </div>
                    <i class="fa fa-inr widget-stat-icon"></i>


                </div>
            </div>
        </div> -->
                </div>
            </div>
        </div>

    </div>

</div>


<!-- map tracking code -->
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg mt-0" style="max-width: 95% ; ">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    <b>Total complaint Number : &nbsp;{{complaint_length}}</b>
                </h4>

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
            <!-- oc  -->

                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [zoomControl]="true">

                    <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)"
                        [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable">

                        <agm-info-window>
                            <strong>Label:{{m.label}}</strong><br />
                            <strong>Complaint id:{{m.Complaint}}</strong><br />
                            <strong>Complaint Type:{{m.compliantype}}</strong><br />
                            <strong> Property Address:{{m.Address}}</strong><br />
                            <strong>Latitude:{{m.lat}}</strong><br />
                            <strong>Longitude:{{m.lng}}</strong><br />
                        </agm-info-window>

                    </agm-marker>

                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon>
                </agm-map> -->

            <!-- ec      -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal grad4" id="myModal3">
    <div class="modal-dialog modal-lg mt-0" style="max-width:95%;">
        <div class="modal-content">


            <div class="modal-header grad4" style="color: white;">
                <h4 class="modal-title" style="font: bold;">
                    {{trackedusername}}
                </h4>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-3 text-right">
                            <h6>Choose Date :</h6>
                        </div>
                        <div class="col-6">
                            <input type="date" [(ngModel)]="Obj['date']" class="form-control">
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-success" (click)="getusercordinates()"> Search</button>
                        </div>
                    </div>
                </div>

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>


            <div class="modal-body">
              <!-- oc  -->
                <!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
                    [mapTypeControl]='true' [zoomControl]="true" [fullscreenControl]='true'> -->

              <!-- ec        -->
                    <!-- <agm-polygon [paths]="paths2" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                </agm-polygon> -->

                    <!-- <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#07b57a'">
                    <agm-polyline-point
                    *ngFor="let coordinate of latlng; let i=index"
                    [latitude]="coordinate[0]"
                    [longitude]="coordinate[1]">

                </agm-polyline-point>
                </agm-polyline> -->
                    <!-- {{dummy2 | json}} -->

                    <!-- oc  -->
                    <!-- <agm-marker *ngFor="let m of start; let i = index" (markerClick)="clickedMarker(m.label, i)"
                        [markerDraggable]="m.draggable" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label">
                        <agm-info-window>
                            <strong>Location:{{m.location}}</strong><br />
                            <strong>Latitude:{{m.lat}}</strong><br />
                            <strong>Longitude:{{m.lng}}</strong><br />
                            <strong>Date:{{m.currentdate}}</strong><br />
                            <strong>Time:{{m.currenttime}}</strong><br />
                        </agm-info-window>

                    </agm-marker> -->

                    <!-- ec  -->

                    <!-- oc  -->

                    <!-- <agm-marker *ngFor="let m of end; let i = index" (markerClick)="clickedMarker(m.label, i)"
                        [markerDraggable]="m.draggable" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label">

                        <agm-info-window>
                            <strong>Location:{{m.location}}</strong><br />
                            <strong>Latitude:{{m.lat}}</strong><br />
                            <strong>Longitude:{{m.lng}}</strong><br />
                            <strong>Date:{{m.currentdate}}</strong><br />
                            <strong>Time:{{m.currenttime}}</strong><br />
                        </agm-info-window>
                    </agm-marker> -->

                    <!-- ec  -->

                    <!-- oc  -->

                    <!-- <ng-container *ngFor="let item of dummy2">
                        <agm-polyline [editable]="true" [strokeWeight]="3" [strokeColor]="'#ff0000'">
                            <agm-polyline-point *ngFor="let data of item " [latitude]="data.latitude"
                                [longitude]="data.longitude">
                            </agm-polyline-point>
                        </agm-polyline>
                    </ng-container>
                    <agm-polygon [paths]="paths" [clickable]="false" [visible]="true" (polyClick)="onPolyClick($event)">
                    </agm-polygon> -->
                    <!-- ec  -->


                <!-- </agm-map> -->



            </div>
        </div>
    </div>
</div>
<div *ngIf="complaint">
    <div class="page-content fade-in-up">
        <div class="row">
            <div class="col-12">
                <div class="ibox">
                    <div class="ibox-head">
                        <div class="ibox-title">Details</div>
                        <!--Geographic Location -->
                        <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                    </div>
                    <div class="ibox-body">
                        <ul class="nav nav-tabs tabs-line">
                            <li class="nav-item" *ngIf="header">
                                <a class="nav-link active" (click)="getallzonedetailList()" data-toggle="tab"><i
                                        class="fa fa-line-chart"></i>
                                    Complaint List</a>
                                <!-- Geographic Location List-->
                            </li>
                            <li class="nav-item" *ngIf="header">
                                <a class="nav-link" (click)="getLocationList()" data-toggle="tab"><i
                                        class="fa fa-plus-circle" aria-hidden="true"> </i> Complaint On Map
                                </a>
                                <!-- Create Geographic Location-->
                            </li>


                        </ul>
                        <br>
                        <div class="tab-content">


                            <div *ngIf="showTable">

                                <div class="row" *ngIf="Attendnce">
                                    <div class="col-3"></div>
                                    <div class="col-3">
                                      <h6>Select Date :</h6>
                                    </div>
                                    <div class="col-3">
                                      <input type="date" class="form-control" [(ngModel)]="current_date">
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <div class="row mt-5" *ngIf="Attendnce">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-outline-success" (click)="search_datewiese()">Search</button>
                                    </div>
                                     </div>
                                <div class="row mt-2">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="Filter">
                                            <!-- Filter  -->
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="col-4 text-right">
                                            <button class="btn btn-outline-success" (click)="print()" *ngIf="pdf">Print</button>&nbsp;
                                            <button (click)="dashboard1()"
                                            class="btn btn-outline-primary">Dashboard</button>
                                    </div>

                                </div>
                                <div class=" example-container">
                                    <table mat-table [dataSource]="datasource" matSort>
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                                id

                                            </th>
                                            <!--ID-->
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                                class="right">
                                                {{i+1}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="emp_name"
                                            *ngIf="displayedColumns.includes('emp_name')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                User Name
                                            </th>
                                            <!--Location
                                                Type-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.emp_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="location_type"
                                            *ngIf="displayedColumns.includes('location_type')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Location Type

                                            </th>`
                                            <!--Location
                                                Type-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.location_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="emp_phone_no"
                                            *ngIf="displayedColumns.includes('emp_phone_no')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Location Type

                                            </th>
                                            <!--Location
                                                Type-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.emp_phone_no }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="zone_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Zone Name

                                            </th>
                                            <!--Short Name-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.zone_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="case_id">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                case_id

                                            </th>
                                            <!--Short Name-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.case_id }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="notice_id_f">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Notice Id

                                            </th>
                                            <!--Short Name-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.notice_id_f }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="section">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Section

                                            </th>
                                            <!--Short Name-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.section }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="zone_cd_value"
                                            *ngIf="displayedColumns.includes('zone_cd_value')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Zone code
                                            </th>

                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.zone_cd_value }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="case_status"
                                            *ngIf="displayedColumns.includes('case_status')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Case Status
                                            </th>

                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.case_status }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="complain_app_id"
                                            *ngIf="displayedColumns.includes('complain_app_id')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Complaint ID
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.complain_app_id }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="complain_date"
                                            *ngIf="displayedColumns.includes('complain_date')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Complaint Date
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.complain_date | date}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="complain_from"
                                            *ngIf="displayedColumns.includes('complain_from')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Complaint Form
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.complain_from }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="complian_type"
                                            *ngIf="displayedColumns.includes('complian_type')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Complaint Form
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.complian_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="property_address"
                                            *ngIf="displayedColumns.includes('property_address')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Property Address
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.property_address }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status" *ngIf="displayedColumns.includes('status')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Status
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.status }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="case_id_f"
                                            *ngIf="displayedColumns.includes('case_id_f')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Case id
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.case_id_f }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="co_ord_id"
                                            *ngIf="displayedColumns.includes('co_ord_id')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Court Order id
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.co_ord_id }} </td>
                                        </ng-container>



                                        <ng-container matColumnDef="co_ord_date"
                                            *ngIf="displayedColumns.includes('co_ord_date')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Court Order date
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.co_ord_date }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="owner_name"
                                            *ngIf="displayedColumns.includes('owner_name')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Dedender Name
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.owner_name }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="owner_mobile"
                                            *ngIf="displayedColumns.includes('owner_mobile')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Dedender Mobile No
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.owner_mobile }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="order_status"
                                            *ngIf="displayedColumns.includes('order_status')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Status
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.order_status }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="user_id"
                                        *ngIf="displayedColumns.includes('user_id')">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            User Id
                                        </th>
                                        <!--Unit-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.user_id}} </td>
                                    </ng-container>

                                        <ng-container matColumnDef="attendance_date"
                                            *ngIf="displayedColumns.includes('attendance_date')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Attendence Date
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.attendance_date | date}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="attendance_time"
                                            *ngIf="displayedColumns.includes('attendance_time')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Attendence Time
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.attendance_time}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="life_cycle_status"
                                            *ngIf="displayedColumns.includes('life_cycle_status')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                status
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.life_cycle_status}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="assign_user"
                                            *ngIf="displayedColumns.includes('assign_user')">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Complaint Assigned
                                            </th>
                                            <!--Unit-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.assign_user}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Action</th>
                                            <!--Action-->
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <!-- <button class="btn btn-outline-primary"
                                                    (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location13']}}</button> -->
                                                <!--Update-->

                                                <!-- <button class="btn btn-outline-danger"
                                                    (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'Location14']}}</button> -->
                                                <!--Delete-->
                                                <button class="btn btn-outline-success" *ngIf="element.location_type"
                                                    (click)="view_on_map(element)">View on Map</button>
                                                <button class="btn btn-outline-success" *ngIf="element.emp_name"
                                                    (click)="track_on_map(element)">track</button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>


                        </div>
                        <br>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
