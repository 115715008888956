import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RoleGuardService } from "../../role-guard.service";
import { AccFaqComponent } from "../account-help/acc-faq/acc-faq.component";
import { AccOnlineSupportComponent } from "../account-help/acc-online-support/acc-online-support.component";
import { AccTutorialComponent } from "../account-help/acc-tutorial/acc-tutorial.component";
import { AccUserManualComponent } from "../account-help/acc-user-manual/acc-user-manual.component";
import { AccountHelpComponent } from "../account-help/account-help.component";
import { AccountDefApprovalComponent } from "../accounts-administration/account-def-approval/account-def-approval.component";
import { AccountsAdministrationComponent } from "../accounts-administration/Accounts-administration.component";
import { AccountsDataAssignmentComponent } from "../accounts-administration/Accounts-data-assignment/Accounts-data-assignment.component";
import { AccountsDataAuthComponent } from "../accounts-administration/Accounts-data-auth/Accounts-data-auth.component";
import { AccountsHierarchyComponent } from "../accounts-administration/Accounts-hierarchy/Accounts-hierarchy.component";
import { AccountsRolesComponent } from "../accounts-administration/Accounts-roles/Accounts-roles.component";
import { AccountsUsersComponent } from "../accounts-administration/Accounts-users/Accounts-users.component";
import { AccountsDashComponent } from "../accounts-dash/accounts-dash.component";
import { AdviceComponent } from "../advice/advice.component";
import { BillComponent } from "../bill/bill.component";
import { BpComponent } from "../bp/bp.component";
import { ProductComponent } from "../budget/product.component";
import { ChallanComponent } from "../challan/challan.component";
import { ClosingStockComponent } from "../closing-stock/closing-stock.component";
import { CostCenterComponent } from "../cost-center/cost-center.component";
import { DemandComponent } from "../demand/demand.component";
import { DepartmentBillComponent } from "../department-bill/department-bill.component";
import { DepartmentChallansComponent } from "../department-challans/department-challans.component";
import { EventGroupComponent } from "../event-group/event-group.component";
import { AccJournalComponent } from "../ledger/acc-journal/acc-journal.component";
import { AccRuleComponent } from "../ledger/acc-rule/acc-rule.component";
import { ActivityHierComponent } from "../ledger/activity-hier/activity-hier.component";
import { BudHierComponent } from "../ledger/bud-hier/bud-hier.component";
import { CharOfAccountComponent } from "../ledger/char-of-account/char-of-account.component";
import { EventsComponent } from "../ledger/events/events.component";
import { JvComponent } from "../ledger/jv/jv.component";
import { LedgerComponent } from "../ledger/ledger.component";
import { OrgHierComponent } from "../ledger/org-hier/org-hier.component";
import { ProdHierComponent } from "../ledger/prod-hier/prod-hier.component";
import { ProjHierComponent } from "../ledger/proj-hier/proj-hier.component";
import { AdhocReportComponent } from "../ledger/report/adhoc-report/adhoc-report.component";
import { AllDedReportComponent } from "../ledger/report/all-ded-report/all-ded-report.component";
import { ArrListingComponent } from "../ledger/report/arr-listing/arr-listing.component";
import { BalanceSheetReportComponent } from "../ledger/report/balance-sheet-report/balance-sheet-report.component";
import { BankReportComponent } from "../ledger/report/bank-report/bank-report.component";
import { CogsReportComponent } from "../ledger/report/cogs-report/cogs-report.component";
import { FixAssestRegComponent } from "../ledger/report/fix-assest-reg/fix-assest-reg.component";
import { FixedAssetsMappingComponent } from "../ledger/report/fixed-assets-mapping/fixed-assets-mapping.component";
import { GstReportComponent } from "../ledger/report/gst-report/gst-report.component";
import { IncomeExpenseReportComponent } from "../ledger/report/income-expense-report/income-expense-report.component";
import { JrnlListingComponent } from "../ledger/report/jrnl-listing/jrnl-listing.component";
import { LedgerReportComponent } from "../ledger/report/ledger-report/ledger-report.component";
import { PartyReportComponent } from "../ledger/report/party-report/party-report.component";
import { ReportComponent } from "../ledger/report/report.component";
import { TdsGstReportComponent } from "../ledger/report/tds-gst-report/tds-gst-report.component";
import { TdsComponent } from "../ledger/report/tds/tds.component";
import { TrialBalanceComponent } from "../ledger/report/trial-balance/trial-balance.component";
import { AccCodeValueComponent } from "../setting/acc-code-value/acc-code-value.component";
import { AccFieldsComponent } from "../setting/acc-fields/acc-fields.component";
import { AccGstComponent } from "../setting/acc-gst/acc-gst.component";
import { AccSalComponent } from "../setting/acc-sal/acc-sal.component";
import { AccountInfoComponent } from "../setting/account-info/account-info.component";
import { ChartOfAccMappingComponent } from "../setting/chart-of-acc-mapping/chart-of-acc-mapping.component";
import { DeductiionMappingComponent } from "../setting/deductiion-mapping/deductiion-mapping.component";
import { EventLayoutsComponent } from "../setting/event-layouts/event-layouts.component";
import { FinYearComponent } from "../setting/fin-year/fin-year.component";
import { IpComponent } from "../setting/ip/ip.component";
import { JournalComponent } from "../setting/journal/journal.component";
import { AccountsPartyComponent } from "../setting/party/party.component";
import { ProjectBankAccComponent } from "../setting/project-bank-acc/project-bank-acc.component";
import { SalComponent } from "../setting/sal/sal.component";
import { AccountsSettingComponent } from "../setting/setting.component";
import { WorkComponent } from "../setting/work/work.component";
import { BankAccountComponent } from "../tresuary/bank-account/bank-account.component";
import { ContraComponent } from "../tresuary/contra/contra.component";
import { GatewayMIDConfigurationComponent } from "../tresuary/gateway-mid-configuration/gateway-mid-configuration.component";
import { TresuaryComponent } from "../tresuary/tresuary.component";
import {ReceiptsPaymentComponent} from '../ledger/report/receipts-payment/receipts-payment.component'
import { StoreKeeperRegisterComponent } from '../ledger/report/store-keeper-register/store-keeper-register.component';
const routes: Routes = [

  {
    path: 'accounts-index',
    component: AccountsDashComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R1" },
  },
  {
    path: "bill",
    component: BillComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R33" },
  },
  {
    path: "dep-bill",
    component: DepartmentBillComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R34" },
  },
  {
    path: "dep-challan",
    component: DepartmentChallansComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R38" },
  },
  {
    path: "demand",
    component: DemandComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R4" },
  },
  {
    path: "budget",
    component: ProductComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R6" },
  },
  {
    path: "challan",
    component: ChallanComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R37" },
  },
  {
    path: "bank-payment",
    component: BpComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R35" },
  },
  {
    path: "advice",
    component: AdviceComponent,
    canActivate: [RoleGuardService],
    data: { ComponentCode: "ACCOUNT-R36" },
  },
  {
    path: "cost-center",
    component: CostCenterComponent,
    data: { ComponentCode: "ACCOUNT-R14" },
  },
  {
    path: "closing-stock",
    component: ClosingStockComponent,
    data: { ComponentCode: "ACCOUNT-R42" },
  },
  {
    path: "ledger",
    component: LedgerComponent,
    children: [
      {
        path: "jrnl",
        component: AccJournalComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R6" },
      },
      {
        path: "jv",
        component: JvComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R43" },
      },

      {
        path: "rule",
        component: AccRuleComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R16" },
      },
      {
        path: "coa",
        component: CharOfAccountComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R22" },
      },
      {
        path: "activity-hier",
        component: ActivityHierComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R19" },
      },
      {
        path: "bud-hier",
        component: BudHierComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R18" },
      },
      {
        path: "events",
        component: EventsComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R15" },
      },
      {
        path: "prod-hier",
        component: ProdHierComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R21" },
      },
      {
        path: "proj-hier",
        component: ProjHierComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R20" },
      },
      {
        path: "org-hier",
        component: OrgHierComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R13" },
      },
      {
        path: "report",
        component: ReportComponent,
        children: [
          {
            path: "trail-balance",
            component: TrialBalanceComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R44" },
          },
          {
            path: "tds",
            component: TdsComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R50" },
          },
          {
            path: "cogs-report",
            component: CogsReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R55" },
          },
          {
            path: "tds-gst",
            component: TdsGstReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R49" },
          },
          {
            path: "ledger-report",
            component: LedgerReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R46" },
          },
          {
            path: "adhoc-report",
            component: AdhocReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R58" },
          },
          {
            path: "party-report",
            component: PartyReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R2" },
          },
          {
            path: "arr-report",
            component: ArrListingComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R2" },
          },
          {
            path: "jrnl-report",
            component: JrnlListingComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R2" },
          },
          {
            path: "gst",
            component: GstReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R48" },
          },
          {
            path: "bank-report",
            component: BankReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R47" },
          },
          {
            path: "all-ded",
            component: AllDedReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R51" },
          },
          {
            path: "balance-sheet-report",
            component: BalanceSheetReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R52" },
          },
          {
            path: "income-expence-report",
            component: IncomeExpenseReportComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R53" },
          },
          {
            path: "fix-asset",
            component: FixAssestRegComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R54" },
          },
          {
            path: "receipts-payment",
            component: ReceiptsPaymentComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R56" },
          },
          {
            path: "store-keeper-register",
            component: StoreKeeperRegisterComponent,
            canActivate: [RoleGuardService],
            data: { ComponentCode: "ACCOUNT-R57" },
          },
        ],
      },
    ],
  },
  {
    path: "event-group",
    component: EventGroupComponent,
  },
  {
    path: "tresuary",
    component: TresuaryComponent,
    children: [
      {
        path: "contra",
        component: ContraComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R39" },
      },
      {
        path: "bank-account",
        component: BankAccountComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R40" },
      },
      {
        path: "gateway-mid-configuration",
        component: GatewayMIDConfigurationComponent,
        data: { ComponentCode: "ACCOUNT-R41" },
      },
    ],
  },
  {
    path: "setting",
    component: AccountsSettingComponent,
    children: [
      {
        path: "party",
        component: AccountsPartyComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R59" },
      },
      {
        path: "work",
        component: WorkComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R27" },
      },
      {
        path: "fixed-assets-mapping",
        component: FixedAssetsMappingComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R29" },
      },
      {
        path: "deduction-mapping",
        component: DeductiionMappingComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R30" },
      },
      {
        path: "project-bank",
        component: ProjectBankAccComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R28" },
      },
      {
        path: "acc-sal",
        component: AccSalComponent,
      },
      {
        path: "acc-mapping",
        component: ChartOfAccMappingComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R32" },
      },

      {
        path: "fields",
        component: AccFieldsComponent,
      },
      {
        path: "code-value",
        component: AccCodeValueComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R11" },
      },
      {
        path: "account-info",
        component: AccountInfoComponent,
      },
      {
        path: "event-layout",
        component: EventLayoutsComponent,
      },
      {
        path: "fin-year",
        component: FinYearComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R12" },
      },
      {
        path: "jrnl",
        component: JournalComponent,
      },
      {
        path: "ip",
        component: IpComponent,
      },
      {
        path: "sal",
        component: SalComponent,
      },
      {
        path: "hsn",
        component: AccGstComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R31" },
      },
    ],
  },
  {
    path: "administration",
    component: AccountsAdministrationComponent,
    children: [
      {
        path: "roles",
        component: AccountsRolesComponent,
      },
      {
        path: "users",
        component: AccountsUsersComponent,
        data: { ComponentCode: "ACCOUNT-R24" },
      },

      {
        path: "dataAuth",
        component: AccountsDataAuthComponent,
        data: { ComponentCode: "ACCOUNT-R25" },
      },
      {
        path: "hierarchy",
        component: AccountsHierarchyComponent,
        data: { ComponentCode: "ACCOUNT-R24" },
      },
      {
        path: "data-assignment",
        component: AccountsDataAssignmentComponent,
        data: { ComponentCode: "ACCOUNT-R24" },
      },
      {
        path: "account-appr",
        component: AccountDefApprovalComponent,
        data: { ComponentCode: "ACCOUNT-R26" },
      },
    ],
  },
  {
    path: "help",
    component: AccountHelpComponent,
    children: [
      {
        path: "doc",
        component: AccUserManualComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R9" },
      },
      {
        path: "faq",
        component: AccFaqComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R9" },
      },
      {
        path: "videos",
        component: AccTutorialComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R9" },
      },
      {
        path: "online-support",
        component: AccOnlineSupportComponent,
        canActivate: [RoleGuardService],
        data: { ComponentCode: "ACCOUNT-R9" },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountsLayoutsRoutingModule {}
