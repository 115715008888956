<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Property Costing</div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>Property Costing List</a>
                            <!--Property List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Property Costing </a>
                            <!--Create Property-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Update Property Costing </a>
                        </li>
                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list_flag">

                            <br>

                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Select Location Hierarchy :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                        [selectableGroup]="true" (change)="getAllLevls()"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        [disabled] = true
                                        >
                                     </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngFor="let item of data,let i=index">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Level {{i+1}} :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="i==data.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                    {{auth.allLableShowObjProp[auth.language_cd+'Land
                                        Input11']}} <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="planaing_hierarchy" bindLabel="desc" bindValue="hier_ident_code"
                                        [multiple]="false"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                        (change)="getAllpropHierarchynodes()" [(ngModel)]="Obj['hier_ident_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12">

                                </div>
                            </div>


                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6>
                                        {{auth.allLableShowObjProp[auth.language_cd+'Land
                                        Input14']}} <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="node_desc"
                                        bindValue="node_cd" [multiple]="false"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input15']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="i==planing_arr.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" class="btn btn-outline-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                            <br>
                            <hr>
                            <br>

                            <div class="row">
                                <div class="col-3"> </div>
                                <div class="col-3"> </div>
                                <div class="col-3 text-center">
                                    <button class="btn btn-outline-primary" (click)="fetchlist()">Fetch List</button>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-outline-primary" (click)="open_create()">Create</button>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property5']}}">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div> -->
                            </div>
                            <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            ID
                                        </th>
                                        <!--Property Number-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Property type-->
                                            Property Type


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Rate

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['pro_rate']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="no_of_prop">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Rate Unit

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['unit']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.life_cycle_status }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="effective_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Effective Date</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.effective_date | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'property11']}}</button>
                                            <!--Update-->
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'property12']}}</button>
                                            <!--Delete-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns_1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="create_flag">
                            <div style="margin: 2%;">
                                <div class="card">
                                    <div class="row" style="margin: 2%;">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Go To
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Location Hierarchy :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                [selectableGroup]="true" (change)="getAllLevls()"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                                [disabled] = true
                                                >
                                             </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" *ngFor="let item of data,let i=index">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Level {{i+1}} :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3" *ngIf="i==data.length-1">
                                            <button (click)="add_dropdown(item,i)"   class="btn btn-outline-primary"><i
                                                    class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            &nbsp;&nbsp;
                                            <button (click)="remove_dropdown(item,i)"   *ngIf="i!=0" class="btn btn-outline-danger">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <!-- <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>
                                                 {{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input11']}} <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="planaing_hierarchy" bindLabel="desc"
                                                bindValue="hier_ident_code" [multiple]="false"
                                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input13']}}"
                                                (change)="getAllpropHierarchynodes()"
                                                [(ngModel)]="Obj['hier_ident_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>

                                    </div>
                                    <br>



                                    <div class="row" *ngFor="let item of planing_arr,let i =index">
                                        <div class="col-3"></div>
                                        <div class="col-3" *ngIf="i==0">
                                            <h6>
                                                 {{auth.allLableShowObjProp[auth.language_cd+'Land
                                                Input14']}} <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3" *ngIf="i!=0"></div>
                                        <div class="col-3">
                                            <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="node_desc"
                                                bindValue="node_cd" [multiple]="false"
                                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Land Input15']}}"
                                                [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                                (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true">
                                            </ng-select>
                                        </div>
                                        <div class="col-3" *ngIf="i==planing_arr.length-1">
                                            <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i
                                                    class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            &nbsp;&nbsp;
                                            <button (click)="remove_dropdown(item,i)" class="btn btn-outline-danger">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                        </div>
                                        <div class="col-3"></div>
                                    </div> -->
                                    <br>
                                    <div class="row">

                                        <div class="col-3"></div>
                                        <div class="col-6 text-center">

                                            <button (click)="fetchPropertyType()"
                                                class="btn btn-outline-primary">Fetch</button>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Property Type : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="propertyTypeData" bindLabel="property_type"
                                                bindValue="property_type" [multiple]="false"
                                                placeholder="Select Property Type" [(ngModel)]="Obj['property_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Enter Rate :<span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" placeholder="Enter Rate"
                                                [(ngModel)]="Obj['pro_rate']" name="" id="">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Effective Date :<span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" class="form-control" placeholder="Enter Rate"
                                                [(ngModel)]="Obj['effective_date']" name="" id="">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Rate Unit : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select Rate Unit" [(ngModel)]="Obj['unit']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" [disabled] = true>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="submit()" class="btn btn-outline-primary">Submit</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="update_flag">
                            <div style="margin: 2%;">
                                <div class="card">
                                    <div class="row" style="margin: 2%;">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Go To
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Location Hierarchy :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                [selectableGroup]="true" (change)="getAllLevls()"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                             </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" *ngFor="let item of data,let i=index">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Level {{i+1}} :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select *ngIf="i==0" disabled [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                            <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                                [multiple]="false" disabled placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3" *ngIf="i==data.length-1">
                                            <button (click)="add_dropdown(item,i)" disabled  class="btn btn-outline-primary"><i
                                                    class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            &nbsp;&nbsp;
                                            <button (click)="remove_dropdown(item,i)" disabled  *ngIf="i!=0" class="btn btn-outline-danger">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">

                                        <div class="col-3"></div>
                                        <div class="col-6 text-center">

                                            <button (click)="fetchPropertyType()"
                                                class="btn btn-outline-primary">Fetch</button>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Property Type : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="propertyTypeData" bindLabel="property_type"
                                                bindValue="property_type" [multiple]="false"
                                                placeholder="Select Property Type" [(ngModel)]="Obj['property_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Enter Rate : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" placeholder="Enter Rate"
                                                [(ngModel)]="Obj['pro_rate']" name="" id="">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Effective Date :<span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" class="form-control" placeholder="Enter Rate"
                                                [(ngModel)]="Obj['effective_date']" name="" id="">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6> Select Rate Unit : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0021']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                placeholder="Select Rate Unit" [(ngModel)]="Obj['unit']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                [closeOnSelect]="true" name="chart_of_account0" [disabled] = true>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="update()" class="btn btn-outline-primary">Update</button>
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'property82']}}</ngx-spinner>
<div class="modal fade bd-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="background-color: white;">
            <div class="modal-header">
                <h6 class="modal-title">Property Information :</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <table class="table table-bordered" style="width: 80%; margin: 10%;">
                <thead>
                    <tr>
                        <th>Property Type</th>
                        <th>Property Number</th>
                        <th>Area</th>
                        <th>Unit</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of property_group_check_data;let i =index">
                        <td>{{item['property_type']}}</td>
                        <td>{{item['property_no']}}</td>
                        <td>{{item['area']}}</td>
                        <td>{{item['unit']}}</td>

                    </tr>

                </tbody>
            </table>
        </div>
        <div class="modal-footer" style="background-color: white;">
            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
        </div>
    </div>
</div>
<ngx-spinner>Lodaing...</ngx-spinner>
