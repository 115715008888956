<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                       Employee Leave List
                    </div>
                </div>

                <div class="ibox-body">
                    <div class="tab-pane fade show active" id="tab-1">

                        <br>

                        <!-- // - herarchy ----- -->


                        <div class="row">
                            <div class="col-5 text-right">
                                <h6 style="margin-right: 10%;">
                                    Select Node : <span style="color:red">*</span>
                                </h6>
                            </div>
                            <div class="col-4 text-left margin-left 10%">
                                <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                    Employee Node</button>
                            </div>


                            </div>
                            <br>
                            <div class="row">
                            <div class="col-5 text-right">
                                <h6 style="margin-right: 10%;">
                                    Selected Node Path:<span style="color: red;">*</span>
                                </h6>
                            </div>
                            <div class="col-4 text-left margin-left: 10%">
                               <input class="form-control" [(ngModel)]="Obj['path_desc']" disabled>
                               <!-- {{Obj['path_desc']}} -->
                            </div>
                        </div>
 <br>
 <div class="row">
    <div class="col-5 text-right">
    <span style="margin-right: 10%;"> Selected Employee :<span style="color: red;">*</span> </span>

    </div>
    <div class="col-4">

      <ng-select disabled [items]="allEmplyees_new" bindLabel="emp_name"
                                        bindValue="arr_id" [multiple]="true" placeholder="" [(ngModel)]="ind_emp_id"
                                        [selectableGroup]="false" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
    </div> </div>
  <br>
                        <!-- //----- herarchy end ------- -->
                        <div class="row">
                            <div class="col-5 text-right" >
                                <!-- Calendar Year : -->

                             <span style="margin-right: 10%;">Calendar Year :<span
                                    style="color:red;">*</span></span>
                            </div>
                            <div class="col-4">

                                <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value"
                                    bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="searchObj['year']"
                                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-5 text-right">
                                <!-- Month : -->
                                <span style="margin-right: 10%;"> Month :
                              <span
                                    style="color:red;">*</span>  </span>
                            </div>
                            <div class="col-4">
                                <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                    bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="searchObj['month']"
                                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                            </div>



                        </div>
                        <br>

                        <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="getAllLeaveList()">
                                   Submit
                                </button>
                            </div>
                        </div>
                        <br>
                        <hr>
                        <br>

                        <mat-form-field style="margin-left: 2%">
                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                placeholder="Filter">
                        </mat-form-field>

                        <div class=" example-container">
                            <table mat-table [dataSource]="datasource" matSort>
                                <ng-container matColumnDef="s.n">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                        <!-- Employee ID -->
                                        S.No.
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{i+1}}</td>
                                </ng-container>
                                <ng-container matColumnDef="emp_name">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                      Employee Name
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.name}}</td>
                                </ng-container>
                                <ng-container matColumnDef="t_of_lev">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                     Type Of Leave
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.leave_code}}</td>
                                </ng-container>

                                <ng-container matColumnDef="month">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                   Month
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.month}}</td>
                                </ng-container>
                                <ng-container matColumnDef="taken_lev">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                    No Of Leave Taken
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.no_of_leave_taken}}</td>
                                </ng-container>
                                <ng-container matColumnDef="app_lev">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                   Approved Leave
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.approved_leave}}</td>
                                </ng-container>
                                <ng-container matColumnDef="all_lev">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                   Allowed Leave
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.allowed_leave}}</td>
                                </ng-container>
                                <ng-container matColumnDef="all_by_dy">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                        Allowed by Director
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.allByDir}}</td>
                                </ng-container>
                                <ng-container matColumnDef="rem_lev">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                   Remaining Leave
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.leave_remaining}}</td>
                                </ng-container>
                                <ng-container matColumnDef="dis_alw_elv">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                        Disallowed Leaves
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                        class="right">
                                        {{element.disall_leave}}</td>
                                </ng-container>



                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>
                                        <!-- Action -->
                                       Action
                                    </th>
                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        <button class="btn btn-primary" (click)="approve(element)"  [disabled]="element.disall_leave==undefined || element.disall_leave==null" >
                                        <!-- data-toggle="modal" data-target="#view-reason"
                                        data-backdrop="false" [disabled]="element.disall_leave<=0" > -->

                                           Approve
                                        </button>

                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </div>


                    </div>



                    </div>
                    </div>
                    </div>
                    </div>
                    <ngx-spinner>Loading....</ngx-spinner>



<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false"
                         placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                          (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="modal" id="leaveListForAllow">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">


    <div class="modal-body">
        <mat-form-field style="margin-left: 2%">
            <input matInput (keyup)="applyFilter2($any($event.target).value)"
                placeholder="Filter">
        </mat-form-field>
    <div class=" example-container">
        <table mat-table [dataSource]="datasource2" matSort>
            <ng-container matColumnDef="s.n">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                    <!-- Employee ID -->
                    S.No.
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{i+1}}</td>
            </ng-container>

            <ng-container matColumnDef="taken_lev">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                 NO Of Taken Leave
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.num_of_leaves}}</td>
            </ng-container>
            <ng-container matColumnDef="dis_allw_leave">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                 NO Of Disallowed Leave
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.num_of_dis_allowed_leave}}</td>
            </ng-container>
            <ng-container matColumnDef="allw_lev">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                 NO Of Allowed Leave
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.all_admin}}</td>
            </ng-container>

            <ng-container matColumnDef="from_date">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                From Date
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.from_date}}</td>
            </ng-container>
            <ng-container matColumnDef="to_date">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                To Date
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.to_date}}</td>
            </ng-container>
            <ng-container matColumnDef="reasion">
                <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
              Reasion
                </th>
                <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                    class="right">
                    {{element.leave_reason}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-center" mat-sort-header>
                    <!-- Action -->
                   Action
                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    <!-- <button class="btn btn-outline-warning"  (click)="allowLeave(element)"
                    data-toggle="modal" data-target="#view-reason"
                    data-backdrop="false" [disabled]="element.num_of_dis_allowed_leave<=0" >
                       Allow
                    </button> -->

                    <button [ngClass]="element.num_of_dis_allowed_leave<=0 ? 'btn btn-outline-dark' :' btn btn-outline-warning' " (click)="allowLeave(element)"
                    data-toggle="modal" data-target="#view-reason"
                    data-backdrop="false" [disabled]="element.num_of_dis_allowed_leave<=0" >
                      <ng-container *ngIf="element.num_of_dis_allowed_leave<=0; else elseTemplate">
                         Approved
                      </ng-container>
                      <ng-template #elseTemplate>
                        Allow
                      </ng-template>
                      </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
            </tr>
        </table>
        <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    </div>
    <div class="modal-footer " style="justify-content: center;">
        <!-- <button type="button" class="btn btn-primary" (click)="allowLeaveByDir()" >Allow</button> -->
        <button type="button" class="btn btn-danger" data-dismiss="modal">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves24']}}</button>
    </div>
    </div>
    </div>
</div>

<div class="modal"  id="viewreason">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}}</h4>
            </div>


            <div class="modal-body">

                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;"><b>Leave Type:</b></label>
                    <div class="col-lg-1">
                        {{leave_code}}
                    </div>
                    <label class="col-lg-2 control-label" style="font-size: 15px ;"><b>No Of Leaves:</b></label>
                    <div class="col-lg-1">
                        {{ disallowLeave}}
                    </div>
                    <label class="col-lg-2 control-label" style="font-size: 15px ;"><b>{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves16']}}:</b></label>
                    <div class="col-lg-4">
                        {{leave_reason}}
                    </div>

                </div>
               <div class="row">
                <label class="col-lg-5 control-label" style="font-size: 15px ;"><b>No Of Leave You Want To Allow: <span style="color: brown;">* </span></b></label>
                <!-- <input  #leaveAllowed (ngModelChange)="checkValue(leaveAllowed.value)" [(ngModel)]="allowLev"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type="number"
                maxlength="6"
                placeholder="-Type Hear-"
                accept="number">  -->

                <input
                 [(ngModel)]="allowLev"
                (ngModelChange)="checkValue()"
                oninput="javascript: if (this.value.length > this.maxLength) this.value='';"
                type="number"
                [maxLength]='disallowLeave'
                placeholder="-Type Hear-"

              />
                <!-- <input
               [(ngModel)]="allowLev"
                (ngModelChange)="checkValue()"
                placeholder="-Type Hear-"
              type="number"
              /> -->






               </div>
            </div>


            <div class="modal-footer " style="justify-content: center;">
                <button type="button" class="btn btn-primary" (click)="allowLeaveByDir()" >Allow</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal">{{mainService.allLableShowObjHR[mainService.language_cd+'employee_leaves24']}}</button>
            </div>
        </div>
    </div>
</div>
