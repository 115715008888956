import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { PaymentService } from '../service/payment.service';
import { TenderService } from '../service/tender.service';

import { ApprovalUserService } from '../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../emb/service/main.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-assign-tender',
  templateUrl: './assign-tender.component.html',
  styleUrls: ['./assign-tender.component.css']
})
export class AssignTenderComponent implements OnInit {

  constructor(private paymentService: PaymentService, private tenderService: TenderService, public ApprovalUserService: ApprovalUserService,
    private spinner: NgxSpinnerService, private mainserviceEmb: mainserviceEmb, private toastr: ToastrService) { }
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();


  b_acct_id: any;
  erpUser:any;
  user_id: any
  assignmentObj:any = new Object()
  async ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any))
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getAllAssignment();
  }

  displayedColumns = ['Sr.No', 'tender_id', 'tender_ref_no', 'work_name', 'bid_opening_date', 'vendor_id', 'vender_name', 'sale_end_date', 'status', 'action']

  dataSource: any = []

  list: boolean = true;
  create: boolean = false;
  update_flag: boolean = false;
  async backtoList() {
    await this.getAllAssignment();
    this.list = true;
    this.create = false;
    this.update_flag = false;
  }

  openAssignment() {
    this.assignmentObj = new Object();
    this.list = false;
    this.create = true;
    this.update_flag = false;
    this.tender_id = 0;
  }

  async openUpdate(element:any) {
    this.list = false;
    this.create = false;
    this.update_flag = true;
    this.assignmentObj = element;
    this.tender_id = element.tender_id
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  tender_data:any = []

  assignment_data = []
  async getAllAssignment() {
    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp :any= await this.tenderService.getAssignedTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log("resp", resp['data'])
      this.spinner.hide()
      resp['data'][1].map((x :any)=> {
        x['desc'] = x['tender_id'] + ' - ' + x['work_name']
      })
      this.tender_data = resp['data'][1]
      this.assignment_data = []
      this.assignment_data = resp['data'][0];
      this.dataSource = new MatTableDataSource(resp['data'][0]);
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.dataSource.sort = this.sort.toArray()[0];
      console.log(this.tender_data)
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Getting Tender Information', 'error');
    }
  }

  // assigned_data = []
  vendor_data = []
  tender_id = 0;
  vendorObj:any = {}
  async getDetails() {


    this.spinner.show()
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    if (this.tender_id) {
      obj['tender_id'] = this.tender_id;
      let resp:any = await this.tenderService.getdetails(JSON.stringify(obj));
      if (resp['error'] == false) {
        this.spinner.hide()
        this.vendor_data = resp['data'];
        this.vendor_data.map(x => {
          this.vendorObj[x['vendor_id']] = x['vendor_name']
        })


        this.tender_data.map((x:any) => {
          if (x['tender_id'] == this.tender_id) {
            this.assignmentObj = x;
          }
        })

      } else {
        this.spinner.hide()
        Swal.fire('ERROR', 'Some Erorr Occurred While Getting Data', 'error');
      }
    }
  }

  isError: boolean = false;
  async validation() {
    this.assignment_data.map(x => {
      if (x['tender_id'] == this.assignmentObj['tender_id'] && x['vendor_id'] == this.assignmentObj['vendor_id']) {
        this.isError = true;
        // error_data = x;
      }
    })
    if (this.isError) {
      Swal.fire('INFO', `${this.vendorObj[this.assignmentObj['vendor_id']]} is already assign to ${this.assignmentObj['work_name']}`, 'info');
      this.spinner.hide()
      return;
    }

  }


  async submit() {
    this.spinner.show()

    if (this.isError) {
      return;
    }

    this.assignmentObj['user_id'] = this.erpUser.user_id;
    this.assignmentObj['b_acct_id'] = this.b_acct_id;
    this.assignmentObj['life_cycle_status'] = 'ACTIVE'
    console.log(this.assignmentObj);
    let resp:any = await this.tenderService.insertAssignTender(this.assignmentObj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.getAllAssignment();
      this.backtoList();
      Swal.fire('SUCCESS', 'Assigned Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Assigning Tender', 'error');
    }

  }

  async update() {
    this.spinner.show()
    this.assignmentObj['user_id'] = this.erpUser.user_id;
    this.assignmentObj['b_acct_id'] = this.b_acct_id;
    this.assignmentObj['life_cycle_status'] = 'ACTIVE'
    console.log(this.assignmentObj);
    let resp :any= await this.tenderService.updateAssignTender(this.assignmentObj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.getAllAssignment();
      this.backtoList();
      Swal.fire('SUCCESS', 'Assigned Successfully', 'success')
    } else {
      Swal.fire('ERROR', 'Error While Assigning Tender', 'error');
    }
  }

  async deleteAssign(element:any) {
    this.spinner.show()
    let obj :any= {}
    obj = element;
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    let resp :any= await this.tenderService.deleteAssignTender(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.getAllAssignment();
      this.backtoList();
      this.spinner.hide()
      Swal.fire('SUCCESS', 'Assigned Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'Error While Assigning Tender', 'error');
    }
  }

  /**************************************************************Approval Code Start******************************************* */
  undertakings:any
  salBill_apprdata:any
  async Appr(element:any) {
    $('#workflowmodel').modal('show');

    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for Tender : ' + element['id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'ASSIGN TENDER'
    this.salBill_apprdata['node_cd'] = element['project_cd']
    this.salBill_apprdata['module_cd'] = 'EMD'
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, 'ASSIGN TENDER', 'EMD');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.salBill_apprdata)
  }


  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      obb['user_id'] = this.erpUser.user_id
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'ASSIGN TENDER'
      obb['doc_local_no'] = this.salBill_apprdata['id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'EMD'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['user_name'] = this.erpUser.party_name;
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangeAssignStatus(this.salBill_apprdata['id'], 'UNDERAPPROVAL')
        await this.getAllAssignment();
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          if (this.message_flag == true) {
          }

        } else {
          if (this.message_flag == true) await this.getcurrentlegalentity(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
      }
    } else {
      this.spinner.hide();
    }
  }
  message_flag = false

  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'ASSIGN TENDER'
      obb['doc_local_no'] = this.salBill_apprdata['id']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['user_name'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['emp_name']
      obb['module_cd'] = 'EMD'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  async ChangeAssignStatus(data :any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp:any = await this.tenderService.ChangeAssignStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async getcurrentlegalentity(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp :any= await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    let resp :any= await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['id'], user_name)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  statusArr1:any = [];
  selectedEle:any;
  userRoles:any = []

  async viewStatus(element:any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['doc_type'] = 'ASSIGN TENDER'
    obj['module_cd'] = 'EMD'
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'ASSIGN TENDER'
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'EMD';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr1 = resp.data
      console.log(this.statusArr1);
      $('#workflowStatus').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  workflowuser = []
  rolecdtolevelobj = {}
  selectedTender = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMD'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }



  }
  /**************************************************************Approval Code End******************************************* */


}
