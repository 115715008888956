<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">TOUR LIST</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                TOUR LIST</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>




                            </div>




                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort style="width: 100%"
                                    class="text-center">

                                    <ng-container matColumnDef="s_no">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>S.No</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element let i=index ">
                                            {{element.new_index}}
                                            <!-- {{i+1}}  -->
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="le_id">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>User Id</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.le_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> <b>
                                                Name</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.emp_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="trip_name">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> <b>
                                                Trip Name</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.trip_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="start_trip_date">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Start Trip Date</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.start_date | date :'yyyy-MM-dd'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="end_trip_date">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>End Trip Date</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.end_date |date :'yyyy-MM-dd'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="no_of_member">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>No of Member</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.no_of_member}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="applied_date">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Applied Date</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.applied_date | date: 'yyyy-MM-dd' }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Status</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.life_cycle_status }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="text-center">

                                            <button class="btn btn-outline-success"
                                                *ngIf="element.life_cycle_status=='PENDING'"
                                                (click)="approve(element)">Approve</button>&nbsp;

                                            <button class="btn btn-outline-danger"
                                                *ngIf="element.life_cycle_status=='PENDING'"
                                                (click)="cancel(element)">Reject</button>&nbsp;
                                            <button class="btn btn-outline-primary"
                                                (click)="view(element)">View</button>&nbsp;

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10, 20,30]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<!-- modal -->
<div class="modal" id="myModal">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">
                    TOUR DETAILS
                </h4>
            </div>


            <div class="modal-body">

                <div class="row">
                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                            USER DETAIL:
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                </div>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-2 text-right">
                        <h6> ID:</h6>
                    </div>
                    <div class="col-2 text-left">
                        <h6> {{userObj['le_id']}}</h6>
                    </div>
                    <div class="col-3 text-right">
                        <h6>Leader Name :</h6>
                    </div>
                    <div class="col-4 text-left">
                        <h6>{{userObj['emp_name']}}</h6>
                    </div>

                </div>
                <!-- <div class="row a"> -->
                <br>
                <div class="row">
                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                    <div class="col-3 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                            TOUR MEMBER DETAIL:
                        </h6>
                    </div>
                    <div class="col-4">
                        <hr class="hrline">
                    </div>
                </div>
                <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                        <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>
                </div>









                <div class="example-container">
                    <table mat-table [dataSource]="datasource1" matSort style="width: 100%" class="text-center"
                        #sortCol1="matSort">

                        <ng-container matColumnDef="s_no">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                <b>S.No</b>
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element let i=index ">
                                {{i+1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="le_id">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                <b>User Id</b>
                            </th>
                            <td mat-cell flex-stretch *matCellDef="let element">
                                {{element.le_id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="emp_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef> <b>
                                    Name</b> </th>
                            <td mat-cell flex-stretch *matCellDef="let element">
                                {{element.emp_name}} </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                        </tr>
                    </table>
                    <!-- <mat-paginator #paginator1 [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator> -->
                </div>
                <!-- </div> -->
            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
