import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../service/main.service';
import { LandInputService } from '../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from '../service/document.service';
declare var $: any;
import { AuthenticationService } from '../../authentication.service';
import { ExcelService } from '../service/file-export.service';
import { MatStepper } from '@angular/material/stepper';
import { LocationService } from '../service/location.service';
import { PropCommonSService } from '../service/prop-common-s.service';
import { SettingService } from '../service/setting.service';
import { MainService as AdminMaimService } from '../../admin/service/main.service'
import { GatewayMidConfigService } from '../../accounts/service/gateway-mid-config.service';
import { SaleService } from '../service/sale.service';
import { BookletPurchaseService } from '../service/booklet-purchase.service';
import * as moment from 'moment';

@Component({
  selector: 'app-offline-booklet-purchase',
  templateUrl: './offline-booklet-purchase.component.html',
  styleUrls: ['./offline-booklet-purchase.component.css']
})
export class OfflineBookletPurchaseComponent implements OnInit {
  datasource: any;
  isLinear: any = false;
  first: any = true;
  selectedFile: any = null;
  second: any = true;
  radio : any= true;
  displayedColumns = ['group_id', 'hier_ident_code', 'life_cycle_status', 'hierarchy', 'count', 'total_area', 'unit', 'action'];
  GISArr: any = []
  third: any = true;
  fourth: any = true;
  work_id: any;
  BoqTable: any = [];
  total_sum: any = 0;
  imgURL: any;
  Obj: any = {}
  docArr: any = [{}]
  enableStep3: any;
  b_acct_id: any;
  ebillUser: any;
  planing_arr: any = [{}]
  acp_arr: any = [{}]
  public imagePath: any;
  httpUrl: any;
  uploader: any;
  uploader2: any;
  property_type: any = {}
  choosen_file: any = false
  choosen_file_uploaded: any = false
  outer_acq_row = [{
    dropDown: [{
      existing_heirarchy_nodes: []
    }], data: [{}]
  }]
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  location_idet_stdr = {}
  location_type_obj = {}
  defected_flag: any;
  format: any = []
  exp_file : any= []
  // exp = [{}]
  constructor(private spinner: NgxSpinnerService, private bookletPurchaseService: BookletPurchaseService, private sale_service: SaleService, private GatewayMidConfig: GatewayMidConfigService, private adminMainService: AdminMaimService, private settingService: SettingService, private propHierS: PropHierarchyService, private commonS: PropCommonSService, private locationS: LocationService, private excel: ExcelService, public auth: AuthenticationService, private docService: DocumentService, public mainService: MainService, private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService) { }
  allProjects = []
  async ngOnInit() {
    this.defected_flag = false
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;

    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.uploader2 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader2.onAfterAddingFile = (file: any) => { file.withCredentials = false; };

    this.Obj = {}
    await this.getConfigureData()
    await this.getGatwayList()
    await this.getAllOffers()
    await this.getCurrentLegalEntity()
    await this.getAllUsedParty_Id()
    await this.getPartyMobile_used()

  }
  current_LE = []
  ind_current_LE = []
  org_current_LE = []
  le_obj = {}
  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    this.spinner.show()
    var resp = await this.mainService.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp, 'legal Entity');
      this.current_LE = resp['data']
      for (let i = 0; i < this.current_LE.length; i++) {
        this.le_obj[this.current_LE[i]['phone_no']] = this.current_LE[i]
        if (this.current_LE[i]['party_type'] == 'IND') {
          this.ind_current_LE.push(this.current_LE[i])
        } else {
          this.org_current_LE.push(this.current_LE[i])
        }
      }
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }
  allOffer = []
  async getAllOffers() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'

    console.log(obj)
    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allOffer = resp['data']

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  codeValueObj = {}
  codeValueShowObj = {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp : any= await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj: any = {}
    var codeValueShowTempObj : any= {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }

  admindata = []
  async getGatwayList() {
    var obj: any = new Object();
    obj['b_acct_id'] = 0
    var resp = await this.adminMainService.getPaymentGatwayData(JSON.stringify(obj));
    console.log(resp, 'gatway list')
    if (resp['error'] == false) {
      this.admindata = resp['data'];
      for (let i = 0; i < this.admindata.length; i++) {
        if (this.admindata[i]['life_cycle_status'] != 'ACTIVE') {
          this.admindata.splice(i, 1)
        }
      }
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }
  filtered_gatWayList = []
  changeAccount() {
    this.filtered_gatWayList = []
    let dummmy: any = []
    for (let i = 0; i < this.gatWayList.length; i++) {
      if (this.gatWayList[i]['gateway_id'] == this.Obj['gateway_id']) {
        this.gatWayList[i]['desc'] = this.gatWayList[i]['account_no'] + ' - ' + this.gatWayList[i]['gateway_name']
        dummmy.push(this.gatWayList[i])
      }
    }
    this.Obj['account_no'] = null
    this.filtered_gatWayList = dummmy
  }
  gatWayList: any = []
  acc_obj: any = {}
  all_accounts = []
  async getConfigureData() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(this.Obj)
    var resp = await this.GatewayMidConfig.getGatewayConfigureData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.gatWayList = resp['data']
      for (let i = 0; i < this.gatWayList.length; i++) {
        this.acc_obj[this.gatWayList[i]['account_no']] = this.gatWayList[i]
      }
      console.log(resp, 'getConfigureData', this.acc_obj)

    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }

  list_flag = true
  create_flag = true
  update_flag = true

  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
  async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.Obj = {}

    this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
  }
  remove() {
    if (this.outer_acq_row.length > 1) {
      this.outer_acq_row.pop()
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  exportData() {


    this.excel.exportAsExcelFile(this.exp_file, 'Defected_Booklet')
  }
  export() {
    let ExpData: any = [{
      'one': 'Booklet Number',
      'two': 'Contact Number',
      'three': 'Party Email',
      'four': 'Party Name',
      'five': 'Father or Husband Name',
      'six': 'Address',
      'seven': 'Booklet amount',
      'eight': "Bank Account Number",
      'nine': "Purchase Date",

    },
    ]
    var exp: any = []
    for (var i = 0; i < ExpData.length; i++) {
      var obj: any = new Object();
      obj['1'] = ExpData[i]['one']
      obj['2'] = ExpData[i]['two']
      obj['3'] = ExpData[i]['three']
      obj['4'] = ExpData[i]['four']
      obj['5'] = ExpData[i]['five']
      obj['6'] = ExpData[i]['six']
      obj['7'] = ExpData[i]['seven']
      obj['8'] = ExpData[i]['eight']
      obj['9'] = ExpData[i]['nine']
      obj['10'] = ExpData[i]['ten']
      exp.push(obj);
    }
    this.format.push(exp)
    this.excel.exportAsExcelFile(exp, 'Offline_Booklet')
  }
  onFileUpload(event: any, files: any) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.choosen_file = true
  }
  imagePath2: any;
  imgURL2: any;
  selectedFile2: any;
  onFileUpload2(event: any, files: any) {
    this.selectedFile2 = null
    this.imagePath2 = null
    this.imgURL2 = null
    console.log(event, files)
    this.selectedFile2 = <File>event.target.files[0];
    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath2 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL2 = reader.result;
    };
    this.choosen_file = true
  }

  allPlan_layout = []
  async getpropertygroup() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "PLANLAYOUT"
    console.log(obj)
    var resp = await this.landInput_S.getpropertygroup(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  used_party_id: any = []
  async getAllUsedParty_Id() {
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id

    console.log(obj)
    var resp = await this.bookletPurchaseService.gatAllUsedPartyID(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      let data: any = resp['data']
      for (let i = 0; i < data.length; i++) {
        this.used_party_id.push(data[i]['party_id'])
      }
      console.log(this.used_party_id, 'used Party id')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  getPartyMobile_arr: any = []
  usedEmail_arr: any = []
  async getPartyMobile_used() {
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id

    console.log(obj)
    var resp = await this.bookletPurchaseService.getPartyMobile_used(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      let data = resp['data']
      for (let i = 0; i < data.length; i++) {
        this.getPartyMobile_arr.push(data[i]['party_phone_no'])
        this.usedEmail_arr.push(data[i]['party_email'])
      }
      console.log(this.getPartyMobile_arr, 'used Party id')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async submit() {
    await this.getAllUsedParty_Id()
    await this.getPartyMobile_used()
    console.log(this.getPartyMobile_arr, this.usedEmail_arr)
    if (!this.Obj['offer_cd'] || !this.Obj['gateway_id'] || !this.Obj['account_no']) {
      Swal.fire('Inforamtion..', 'Please Select All * Fields', 'info')
    } else if (this.uploaded_file_data.length == 0) {
      Swal.fire('Inforamtion...', 'Please Upload The File', 'info')
    } else {
      let condition_satisfied_data: any = []
      let condition_not_satisfied_data: any = []
      for (let i = 0; i < this.uploaded_file_data.length; i++) {
        this.uploaded_file_data[i]['offer_cd'] = this.Obj['offer_cd']
        if (!this.validate(this.uploaded_file_data[i]['party_email'])) {
          this.uploaded_file_data[i]['reasion'] = '  Email Is Not Valid'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (this.getPartyMobile_arr.includes(this.uploaded_file_data[i]['party_phone_no'])) {
          this.uploaded_file_data[i]['reasion'] = 'Mobile Number Is Already Exists In The System'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (this.usedEmail_arr.includes(this.uploaded_file_data[i]['party_email'])) {
          this.uploaded_file_data[i]['reasion'] = 'Email Is Already Exists In The System'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (!this.validatePhoneNumber(this.uploaded_file_data[i]['party_phone_no'])) {
          this.uploaded_file_data[i]['reasion'] = 'Mobile is Not Valid'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (this.used_party_id.includes(this.uploaded_file_data[i]['booklet_no'])) {
          this.uploaded_file_data[i]['reasion'] = 'This Booklet Number Is Already Exists In The System'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (this.Obj['account_no'] != this.uploaded_file_data[i]['account_no']) {
          this.uploaded_file_data[i]['reasion'] = 'Account Number Is Not Configured In System'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else if (!this.isDDMMYYYY(this.uploaded_file_data[i]['purchase_date'])) {
          this.uploaded_file_data[i]['reasion'] = 'Date Should Be in formate DD/MM/YYYY'
          condition_not_satisfied_data.push(this.uploaded_file_data[i])
        }
        else {
          condition_satisfied_data.push(this.uploaded_file_data[i])
        }
      }
      if (condition_not_satisfied_data.length > 0) {
        this.defected_flag = true;
        var exp = []
        exp.push(this.format)
        for (var i = 0; i < condition_not_satisfied_data.length; i++) {
          var obj: any = new Object();
          obj['1'] = condition_not_satisfied_data[i]['booklet_no']
          obj['2'] = condition_not_satisfied_data[i]['party_phone_no']
          obj['3'] = condition_not_satisfied_data[i]['party_email']
          obj['4'] = condition_not_satisfied_data[i]['party_name']
          obj['5'] = condition_not_satisfied_data[i]['party_father_or_husband_name']
          obj['6'] = condition_not_satisfied_data[i]['party_permanent_addr_line']
          obj['7'] = condition_not_satisfied_data[i]['booklet_amount']
          obj['8'] = condition_not_satisfied_data[i]['account_no']
          obj['9'] = condition_not_satisfied_data[i]['purchase_date']
          obj['10'] = this.uploaded_file_data[i]['reasion']
          exp.push(obj)
        }
        console.log(exp)
        this.toastr.error("There are some deftected records which are not accepted by software,which has been downloaded");
        this.excel.exportAsExcelFile(exp, 'Defected_booklet_records')
      }
      // this.spinner.show()
      for (let i = 0; i < condition_satisfied_data.length; i++) {
        condition_satisfied_data[i]['le_id'] = await this.createLegalEntity(condition_satisfied_data[i])
        // if(!condition_satisfied_data[i]['le_id']){
        //   condition_satisfied_data[i]['reason']='Either Email or Mobile is Already Exis'
        // }
      }
      console.log(condition_not_satisfied_data, condition_satisfied_data, this.Obj, this.acc_obj)
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['data'] = condition_satisfied_data
      this.Obj['data2'] = condition_not_satisfied_data
      this.Obj['user_id'] = this.ebillUser['user_id']
      console.log(this.Obj)
      if (this.Obj['data'].length > 0) {
        this.spinner.show()
        var resp = await this.bookletPurchaseService.createOfflineBooklet(this.Obj)
        console.log(resp)
        if (resp['error'] == false) {
          this.spinner.hide()
          Swal.fire('Success', 'Created Successfully', 'success')
          this.Obj = {}
          this.setData([])
        } else {
          this.spinner.hide()
          Swal.fire('Error', 'Some Error Occured While Creating', 'error')
        }
      } else {
        Swal.fire('Information...', 'There Is No Any Record Which Is Correct', 'info')
      }

    }
  }
  isDDMMYYYY(str: any) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (!(date_regex.test(str))) {
      return false;
    }
    return true
  }
  async createLegalEntity(item: any) {
    let flag = false
    console.log(this.le_obj, item)
    for (let i = 0; i < this.current_LE.length; i++) {
      if (item['party_phone_no'] == this.current_LE[i]['phone_no']) {
        flag = false
        console.log(this.current_LE[i])
        return this.current_LE[i]['le_id']
      } else if (item['party_email'] == this.current_LE[i]['contact_email']) {
        flag = false
        console.log(this.current_LE[i])
        return this.current_LE[i]['le_id']
      } else {
        flag = true
      }
    }
    let obj : any= {}
    if (flag == true) {
      obj['contact_email'] = item['party_email']
      obj['contact_phone'] = item['party_phone_no']
      obj['b_acct_id'] = this.b_acct_id
      obj['party_type'] = 'IND'
      obj['valid_upto'] = '2037-12-01'
      let resp = await this.mainService.createLegalEntity(obj)
      console.log(resp)
      if (resp['error'] == false) {
        return resp['data']
      }
    }
  }
  validate(email: any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // console.log(reg.test(email))
    return reg.test(email)


  }
  validatePhoneNumber(input_str: any) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    console.log(input_str)
    return re.test(input_str);
  }





  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = []
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data)
      this.setData(data);
      // Data will be logged in viewarray format containing objects
    };
  }
  uploaded_file_data: any = []

  async setData(data: any) {
    console.log(data, 'uploaded data')
    this.uploaded_file_data = []
    console.log(data)
    let dummy = []
    let unique_arr = []
    let unique_arr2 = []
    this.format = data[0]
    for (let i = 1; i < data.length; i++) {
      let obj : any= {}
      obj['party_name'] = data[i]['4']
      obj['party_email'] = data[i]['3']
      obj['party_phone_no'] = data[i]['2']
      obj['party_father_or_husband_name'] = data[i]['5']
      obj['party_permanent_addr_line'] = data[i]['6']
      obj['account_no'] = data[i]['8']
      obj['booklet_amount'] = data[i]['7']
      obj['booklet_no'] = data[i]['1']
      obj['purchase_date'] = data[i]['9']
      obj['le_id'] = 0
      obj['mode'] = 'Offline'
      obj['reasion'] = data[i]['10']
      dummy.push(obj)
    }
    this.uploaded_file_data = dummy
    console.log(this.uploaded_file_data, 'uploaded_file_data')
  }

  fileURL2: any;
  // async view2(data) {
  //   this.spinner.show();
  //   let obj : any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['doc_id'] = this.Obj['csv_doc_id']
  //   obj['doc_name'] = this.Obj['csv_file_name']
  //   console.log(obj)
  //   var res = await this.docService.view_Doc(obj);
  //   console.log(res)
  //   if (res) {
  //     var docname = this.Obj['csv_file_name']
  //     var ext = docname.split('.');
  //     if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg' || ext[1].toLowerCase() == 'xlsx') {
  //       const unsafeImageUrl = window.URL.createObjectURL(res);
  //       this.fileURL2 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
  //     } else {
  //       let file = new Blob([res], { type: 'application/pdf/image' });
  //       var fileURL2 = URL.createObjectURL(file);
  //       window.open(fileURL2);
  //     }
  //     this.spinner.hide();
  //   }
  // }
  async view2() {
    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.Obj['csv_doc_id']
    obj['doc_name'] = this.Obj['csv_file_name']
    this.spinner.show()
    if (this.Obj['csv_file_name'] != undefined) {
      var res = await this.docService.view_Doc(obj);
      if (res) {
        // const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
        // this.fileURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        var docname = this.Obj['csv_file_name']
        var ext = docname.split('.');

        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          // const unsafeImageUrl = window.URL.createObjectURL(res);
          // this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
          // window.open(this.fileURL );
          let file = new Blob([res], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          this.spinner.hide()
          window.open(fileURL, '_blank');

        } else if (ext[1].toLowerCase() == 'xlsx') {

          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {

          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else {

          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);


          window.open(fileURL);
          this.spinner.hide()
        }

      } else {
        this.spinner.hide()

      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'No document Uploaded !', 'error');

    }
  }
  async upload2() {
    // let index = i
    if (!this.Obj['csv_doc_type']) {
      Swal.fire('Error', 'Please Select All Fields', 'error')
    } else {
      this.spinner.show();
      const formData = new FormData();
      console.log(this.selectedFile2)
      console.log(this.selectedFile2.name)
      formData.append('file', this.selectedFile2, this.selectedFile2.name);
      let obj: any = new Object();
      obj['b_acct_id'] = this.b_acct_id;
      obj['doc_name'] = this.uploader2.queue[0].some.name;
      obj['doc_formate'] = 'xlsx'
      obj['doc_type'] = this.Obj['csv_doc_type']
      obj['user_id'] = this.ebillUser.user_id
      this.Obj['csv_file_name'] = obj['doc_name']
      this.spinner.show()
      console.log(obj)
      const params = JSON.stringify(obj);
      this.uploader2.queue[0].url = this.httpUrl + '/property/PropertyDoc/uploadDoc' + params;
      this.uploader2.queue[0].upload();
      this.uploader2.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        var resp = JSON.parse(response);
        console.log(resp)
        if (resp.error == false) {
          console.log(resp, 'resp after success')
          this.uploader2.queue = []
          this.selectedFile2 = null
          this.imagePath2 = null
          this.imgURL2 = null
          this.spinner.hide();
          this.choosen_file = true
          this.Obj['csv_doc_id'] = resp['data']
          this.uploader2.queue = []
          Swal.fire('Success', 'File Uploaded Successfully!!', 'success');
          this.selectedFile2 = null;
        } else {
          this.spinner.hide()
          Swal.fire('Error', resp.data);
        }
      }
    }

  }
}
