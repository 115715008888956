
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../../emb/service/user.service';
import { FileUploader } from 'ng2-file-upload';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
declare var $: any;
function readBase64(file:any): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {

      resolve(reader.result);
    }, false);

    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);

    reader.readAsDataURL(file);
  });
  return future;
}
@Component({
  selector: 'app-icms-user-manual',
  templateUrl: './icms-user-manual.component.html',
  styleUrls: ['./icms-user-manual.component.css']
})
export class IcmsUserManualComponent implements OnInit {

  user_id:any;
  user_email:any;
  ebillUser:any;
  b_acct_id:any;
  name:any;

  pdfURL:any;
  imageBlobUrl:any;
  selectedFile: any = null;
  isUpload:any;
  public imagePath:any;
  httpUrl:any;
  uploader:any;
  dataSource:any;
  dataSource1:any;
  dataSource2:any;

  selectFileType:any
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  @ViewChild('paginator2', { static: true }) paginator2!: MatPaginator;
  @ViewChild('sortCol3', { static: true }) sortCol3!: MatSort;
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;

  constructor(private router: Router, private spinner: NgxSpinnerService,
    private userService: UserService, private profileService: ProfileService,
    private sanitizer: DomSanitizer, public mainService: MainService) {
    this.selectFileType = null
  }

  Message:any;
  Subject:any;
  displayedColumns_for_DOC = ['s_no', 'name', 'doc_name', 'action']



  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;


    this.httpUrl = this.mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };

    this.name = this.ebillUser.name;
    await this.getAllDOC();
  }

  async view_doc(element:any) {
    this.spinner.show();
    var obj = Object.assign({}, element);
    var res:any = await this.userService.gethelpDocument(obj);
    let file = new Blob([res], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    this.spinner.hide();
    window.open(fileURL);

  }



  async getAllDOC() {
    var obj :any= new Object();
    obj['type'] = "DOC";
    var resp = await this.userService.getHelpData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp.data);
      let dummy = []
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['module_cd'] == "ICMS") {
          dummy.push(resp['data'][i])
        }
      }
      this.dataSource1 = new MatTableDataSource(dummy);
      this.dataSource1.sort = this.sortCol2;
      this.dataSource1.paginator = this.paginator1;
    } else {

    }
  }



  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
}
