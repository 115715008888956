
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { VmsSettingService } from '../../service/vms-setting.service';
import Swal from 'sweetalert2';
declare var $: any
@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {


  constructor(private settingService: VmsSettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any ;
  b_acct_id:any ;


  allFields:any  = [];
  field :any = {};


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  displayedColumns = ['field_id', 'field_business_name','field_code', 'action'];
  datasource:any ;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFields();
  }
  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
 async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    await this.getAllFields();
    //await this.getCodeValue();

  }

  open_update(element:any ) {
    this.field = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
  }

  refresh() {
    this.field = {};
  }

  async getAllFields() {
    var obj :any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['domain_code'] = 'VMS';
    this.spinner.show();

    var resp = await this.settingService.getFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allFields = resp.data;
      this.datasource = new MatTableDataSource(this.allFields)
      this.datasource.paginator = this.paginator.toArray()[0];;
      this.datasource.sort = this.sort.toArray()[0];;
      this.spinner.hide();

    }else {
      this.spinner.hide();
      Swal.fire("Error while getting  all fields list", 'Error')
    }
     }
  async save() {
    this.spinner.show();

    this.field['field_logical_id'] =0;
    this.field['b_acct_id'] = this.b_acct_id;
    this.field['datatype_code'] = 'DTY1'
    this.field['is_code_value_present'] =1;
    this.field['is_code_values_present'] = 0;
    this.field['is_hierarchy_present'] = 0;
    this.field['domain_code'] = 'VMS';
    this.field['create_user_id'] = this.erpUser.user_id;
    this.field['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.createFields(this.field);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.open_list();
      await this.getAllFields();
      Swal.fire('Success', 'Added Successfully', 'success')

    }else {
      this.spinner.hide();
      Swal.fire("Error while Adding Field", 'Error')
    }
  }



  async update() {
    this.spinner.show();
    this.field['update_user_id'] = this.erpUser.user_id;
    this.field['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateFields(this.field);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.open_list();
      await this.getAllFields();
     Swal.fire('Success', 'updated Successfully', 'success')
    } else {
      this.spinner.hide();
      Swal.fire("Error while updating Field!", 'Error')
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
