import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tresuary',
  templateUrl: './tresuary.component.html',
  styleUrls: ['./tresuary.component.css']
})
export class TresuaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
