import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
// import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../service/main.service';
import { AllEmpService } from '../../hrms/service/all-emp.service';
import { MainService as Portal_MainService } from '../../portal/service/main.service';
import { FileUploader } from 'ng2-file-upload';

declare var $: any;
@Component({
  selector: 'app-account-config',
  templateUrl: './account-config.component.html',
  styleUrls: ['./account-config.component.css']
})
export class AccountConfigComponent implements OnInit {


  displayedColumns: string[] = ['designation_cd', 'name', 'description', 'info_no', 'upload_file_name', 'action'];
  email:any;
  password:any;
  confirm_pass:any;
  users = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  FirstName:any;
  LastName:any;
  User_Email:any;

  b_acct_id:any;
  erpUser:any;

  constructor(private spinner: NgxSpinnerService,public mainService:MainService, private allEmpService: AllEmpService, private portal_mainService: Portal_MainService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  httpUrl:any
  uploader:any
  imgURL:any;
  selectedFile :any = null;
  isUpload:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.imgURL='./assets/img/admin-avatar.png';
    this.httpUrl = this.portal_mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    await this.getHeaderInfo();
  }

  async getHeaderInfo() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.portal_mainService.getheaderinfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.datasource = new MatTableDataSource(resp.data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {

    }
  }

  async open_update(element:any) {
    console.log(element);
    this.Obj = Object.assign({}, element);
    if(element['upload_file_name']!=null){
      await this.getImgView(element);
    }
    $('.nav-tabs a[href="#tab-7-2"]').tab('show');
  }

  imagePath:any
  onFileUpload(event:any, files:any) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

  }

  resetUpload(){
    this.isUpload=null;
    this.selectedFile=null;
    this.imagePath=null;
    this.imgURL='./assets/img/admin-avatar.png';
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
  }

  async Upload() {
    this.spinner.show()
    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['filename'] = this.uploader.queue[0].some.name;
    obj['info_no'] = this.Obj['info_no'];
    console.log(obj);

    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = await this.httpUrl + '/metadata/headerInfo/uploadheaderFile' + params;
    await this.uploader.queue[0].upload();
     this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
       console.log(response)
       var resp=JSON.parse(response)
         console.log(resp)

      if (resp['error']==false) {
        setTimeout(async ()=>{
        await this.resetUpload();
        await this.getHeaderInfo();
        await this.portal_mainService.getHeaderInfo(this.b_acct_id);
        $('.nav-tabs a[href="#tab-7-1"]').tab('show');
        console.log("Image Uploaded!!!");
        this.spinner.hide();
        Swal.fire('Success..', 'Uploaded Successfully', 'success')
        }, 1000);
      } else {
        this.spinner.hide();
      }
    };
  }

  async update() {

    this.spinner.show();
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.Obj['user_id'] = this.erpUser['user_id']
    var resp = await this.portal_mainService.updateheaderinfo(this.Obj);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      setTimeout(async ()=>{
        await this.getHeaderInfo();
        await this.portal_mainService.getHeaderInfo(this.b_acct_id);
        $('.nav-tabs a[href="#tab-7-1"]').tab('show');
        this.spinner.hide();
        Swal.fire('Success...', 'Updated Successfully', 'success');
      }, 1000);

    } else {
      this.spinner.hide();
      Swal.fire("Error", resp.data, 'error');
    }

  }



  async getImgView(element:any) {

    if (element['upload_file_name'] == null || element['upload_file_name'] == undefined) {
      Swal.fire("Error", "Image Not Uploaded..!", 'error');
      return;
    }


    this.spinner.show();
    var obj = Object.assign({}, element);
    obj['b_acct_id'] = this.b_acct_id;

    obj['filename'] = element['upload_file_name']
    var res = await this.portal_mainService.getheaderImage(obj);
    if (res) {
      var docname = obj["filename"];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {

        let file = new Blob([res], { type: 'image/png' });
        var fileURL = window.URL.createObjectURL(file);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileURL);
        this.spinner.hide();
        // window.open(fileURL, '_blank');

      } else if (ext[1].toLowerCase() == 'xlsx') {

        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        // window.open(fileURL);

      } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {

        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        // window.open(fileURL);

      } else {

        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        // window.open(fileURL);
      }

    } else {
      this.spinner.hide();
    }

  }

  async view(element:any) {

    if (element['upload_file_name'] == null || element['upload_file_name'] == undefined) {
      Swal.fire("Error", "Image Not Uploaded..!", 'error');
      return;
    }


    this.spinner.show();
    var obj = Object.assign({}, element);
    obj['b_acct_id'] = this.b_acct_id;

    obj['filename'] = element['upload_file_name']
    var res = await this.portal_mainService.getheaderImage(obj);
    if (res) {
      var docname = obj["filename"];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {

        let file = new Blob([res], { type: 'image/png' });
        var fileURL = window.URL.createObjectURL(file);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileURL);
        this.spinner.hide();
        window.open(fileURL, '_blank');

      } else if (ext[1].toLowerCase() == 'xlsx') {

        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        window.open(fileURL);

      } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {

        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        window.open(fileURL);

      } else {

        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        this.spinner.hide();
        window.open(fileURL);
      }

    } else {
      this.spinner.hide();
    }

  }

  applyFilter(filterValue: any) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  refresh() {

  }



}
