<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">
            <!-- LPC Report  -->
            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC1']}}
          </div>
        </div>
        <div class="ibox-body">
          <div class="row">
            <div class="col-4 text-right">
              <!-- Select Employee : -->
              {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC5']}} :<span style="color: red;">*</span>
            </div>
            <div class="col-4">
              <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false"
                placeholder="" [(ngModel)]="selectObj['emp_id']" [selectableGroup]="true" (click)="deciablePrint()"
                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" (change)="changeTransfer()">
              </ng-select>

            </div>

          </div>
          <br>
          <div class="row">
            <div class="col-4 text-right">
              <!-- Govt Order --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC10']}} :<span
                style="color: red;">*</span>
            </div>
            <div class="col-4">

              <input class="form-control" type="text" [(ngModel)]="this.selectObj['reason_of_retirement']">
            </div>

          </div>

          <br>
          <div class="row">
            <div class="col-4 text-right">
              <!-- Transfer Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC11']}} :<span
                style="color: red;">*</span>
            </div>
            <div class="col-4">

              <input class="form-control" type="date" [(ngModel)]="selectObj['retirement_date']">


            </div>

          </div>
          <br>
          <div class="row">
            <div class="col-4 text-right">
              <!-- Posting Point -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC8']}} :<span
                style="color: red;">*</span>
            </div>
            <div class="col-4">

              <input class="form-control" type="text" [(ngModel)]="selectObj['posting_point']">


            </div>

          </div>
          <br>


          <!-- <div class="row">
        <div class="col-12 text-center">
            <button class="btn btn-primary" (click)="getLpctransfer()">
                SUBMIT
                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC9']}}</button>
        </div>
    </div>
    <br> -->
          <div class="row">
            <div class="col-12 text-center">
              <button class="btn btn-primary" (click)="print2()">PRINT</button>
            </div>
          </div>
          <br>
          <!-- <div class="row" >
        <div class="col-12 text-center">
            <button class="btn btn-primary" (click)="print()">PRINT1</button>
        </div>
    </div> -->
          <br>
          <hr>
          <!--
    <div class="row">
        <div class="col-12 text-center">
            <h6>
                Please Check the Leaves of the this Employee
            </h6>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-3 r1 text-center">
            <h6>S.No</h6>
        </div>
        <div class="col-3 r1 text-center">
            <h6>Leave Type</h6>
        </div>
        <div class="col-3 r1 text-right">
            <h6>Number of Leaves</h6>
        </div>


        <div class="col-3 r1 text-center">
            <h6>Delete</h6>
        </div>
    </div>

    <div class="row" *ngFor="let ob of Allleave; let i = index">
        <div class="col-3 r1 text-center">
            {{i+1}}
        </div>
        <div class="col-3 r1 text-center">
            {{ob.leave_code}}
        </div>
        <div class="col-3 r1 text-right">
            <input class="form-control" [(ngModel)]="Allleave[i]['num_of_leaves']">

        </div>


        <div class="col-3 r1 text-center">
            <button class="btn btn-danger" (click)="deleteLeave(i)">Delete</button>
        </div>
    </div>
    <br>
    <hr>
    <br>

                                         -->

        </div>
      </div>
    </div>
  </div>
</div>
