
<nav class="navbar navbar-expand-lg navbar-light bg-primary container-width">
    <!-- module image and name-->
    <div style="margin: 0px 0px 7px 17px;" class="litle-screen">
        <img [src]="portal_main.accountImageUrl" class="img-circle img-width" style="max-width: 51px;" />
    </div>
    <div style="overflow: hidden;padding: 0px;margin: auto;" class="litle-screen text-center bg-primary">
        <div class="module-font font-weight-bold text-white">{{portal_main.accInfo['account_name']}}</div>
        <div class="module-font font-weight-bold text-white">Online Management System</div>
    </div>
<!-- end -->
    <button class="navbar-toggler font-weight-bold text-white b-mar" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="litle-screen-bg-color navbar-nav mr-0 bg-primary" style="width: 100%;">
            <!--Chief Minister image and name-->
            <span class="full-screen">&nbsp;&nbsp;</span>
            <li class="nav-item form-inline d-fllex">
                <div id="carbonads">
                    <div class="carbon-wrap text-center">
                        <img [src]="portal_main.Info2ImageUrl" class="rounded-circle img-width" alt=""
                            style="max-width: 60px;" />
                    </div>
                    <div calss="justify-content-center align-items-center">
                        <div class="font-weight-bold">{{portal_main.info2['name']}}</div>
                        <small>{{portal_main.info2['designation_cd']}}</small>
                    </div>
                </div>
                <!-- end -->

                <!--Voice Chairman image and name-->
            </li><span class="full-screen">&nbsp;&nbsp;&nbsp;</span>
            <li class="nav-item form-inline">

                <div id="carbonads">
                    <div class="carbon-wrap">
                        <img [src]="portal_main.Info1ImageUrl" class="rounded-circle img-width" alt=""
                            style="max-width: 60px;" />
                    </div>
                    <div>
                        <div class="font-weight-bold"> {{portal_main.info1['name']}}</div>
                        <small>{{portal_main.info1['designation_cd']}}</small>
                    </div>
                </div>
            </li>
            <!-- Select Language for litile screen -->
            <li class="nav-item text-center form-inline my-2 my-lg-0  litle-screen" style="padding: 0px 5px 0px 0px;">
                <div class="select-language">
                    <ng-select [multiple]="false" placeholder="Select Language" [items]="auth.allLanguage"
                        bindLabel="language_desc" bindValue="language_cd" [selectableGroup]="true"
                        [selectableGroupAsModel]="false" aria-placeholder="Select Language Code" [closeOnSelect]="true"
                        name="language_cd" [(ngModel)]="portal_main.language_cd" (change)="changeLanguage()"
                        style="width:100%">
                    </ng-select>
                </div>
            </li>

            <li class="d-flex litle-screen task-log-mar form-inline">
                <div class="litle-screen">
                    <span class="text-white font-weight-bold">Your Task&nbsp;:-</span>&nbsp;
                    <a class="nav-item nav-link-edit text-dark" [routerLink]="['/task']">{{message_count}}</a>
                </div>
             <br>
             <br>
                <!-- NOTIFICATION FOR LITILE SCREEN -->
                <div class="litle-screen">
                    <span class="text-white font-weight-bold">Notification&nbsp;:-</span>&nbsp;
                    <a class="nav-item nav-link-edit text-dark" [routerLink]="['/notification']">{{noti_count}}</a>
                </div>
                <!-- logout profile for litile screen-->
                <div class="d-flex  form-inline litile-screen ml-auto">
                    <a class="nav-link dropdown-toggle litle-screen link mr-sm-2" data-toggle="dropdown">
                        <img [src]="portal_main.profileImageUrl" class="module-img" />
                        <span class="font-weight-bold text-white">{{this.erpUser['party_name']}}</span><i
                            class="fa fa-angle-down m-l-5 font-weight-bold text-white"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right litle-screen my-2 my-sm-0">
                        <a class="dropdown-item " [routerLink]="['/profile']" routerLinkActive="router-link-active"><i
                                class="fa fa-user"></i>Profile</a>
                        <a [hidden]="hidden" class="dropdown-item" [routerLink]="['/acc']"
                            routerLinkActive="router-link-active"><i class="fa fa-user-plus"></i>Account</a>
                        <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link-active"><i
                                class="fa fa-power-off"></i>Logout</a>
                    </ul>
                </div>
            </li>
            <!-- module image and name -->
            <li class="nav-item navbar-nav  mx-auto">
                <div class="full-screen font-color d-felx form-inline text-center">
                    <div>
                    <img [src]="portal_main.accountImageUrl" class="img-circle module-img" style="float:left;" />
                </div>
                    <div>
                        <div class="module-font font-weight-bold text-white" style="overflow: auto;max-width: 397px;">
                            {{portal_main.accInfo['account_name']}}
                        </div>
                        <div class="module-font font-weight-bold text-white">Online Management System</div>
                    </div>
                </div>
            </li>
            <!--Language-->
            <li class="nav-item d-flex text-center form-inline my-2 my-lg-0 select-language r-font-s ml-0 full-screen">
                <div class="text-center form-inline my-2 my-lg-0 font-weight-bold text-white h-lang-h">
                    {{mainService.allLableShowObj[portal_main.language_cd+'PROFILE1']}} -:&nbsp;
                </div>
                <div class="full-screen">
                    <ng-select [multiple]="false" placeholder="Select Language" [items]="auth.allLanguage"
                        bindLabel="language_desc" bindValue="language_cd" [selectableGroup]="true"
                        [selectableGroupAsModel]="false" aria-placeholder="Select Language Code" [closeOnSelect]="true"
                        name="language_cd" [(ngModel)]="portal_main.language_cd" (change)="changeLanguage()"
                        style="width:100%">
                    </ng-select>
                </div>
                <!-- NOTIFICATION  -->
                <div class="dropdown dropdown-inbox text-center form-inline my-2 my-lg-0 nav-item full-screen">
                    <a class="nav-link dropdown-toggle my-2 my-lg-0 text-center form-inline" data-toggle="dropdown"><i
                            class="fa fa-envelope-o font-weight-bold text-white"></i>
                        <SUP class="badge badge-primary  ">{{message_count}}</SUP>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media">
                        <li class="dropdown-menu-header">
                            <div>
                                <span><strong>{{message_count}} New</strong> Messages</span>
                                <a class="pull-right" [routerLink]="['/task']"
                                    routerLinkActive="router-link-active">view
                                    all</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- WORKFLOW OF COMPLAINT -->
                <div class="dropdown dropdown-inbox text-center form-inline my-2 my-lg-0 nav-item full-screen">
                    <a class="nav-link dropdown-toggle my-2 my-lg-0 text-center form-inline" data-toggle="dropdown"><i
                            class="fa fa-bell-o font-weight-bold text-white"></i>
                        <SUP class="badge badge-primary  ">{{noti_count}}</SUP>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media">
                        <li class="dropdown-menu-header">
                            <div>
                                <span><strong>{{noti_count}} New</strong> Messages</span>
                                <a class="pull-right" [routerLink]="['/notification']"
                                    routerLinkActive="router-link-active">view
                                    all</a>
                            </div>
                        </li>
                    </ul>
                </div>

                <!--Profile Logout-->
                <div class="nav-item full-screen">
                    <a class="nav-link dropdown-toggle link nav-item " data-toggle="dropdown">
                        <img [src]="portal_main.profileImageUrl" class="module-img" />
                        <span class=" r-font-s font-weight-bold text-white">{{this.erpUser['party_name']}}</span><i
                            class="fa fa-angle-down m-l-5 font-weight-bold text-white"></i></a>
                    <ul class="dropdown-menu dropdown-menu-right ">
                        <a class="dropdown-item " [routerLink]="['/profile']" routerLinkActive="router-link-active"><i
                                class="fa fa-user"></i>Profile</a>
                        <a [hidden]="hidden" class="dropdown-item" [routerLink]="['/acc']"
                            routerLinkActive="router-link-active"><i class="fa fa-user-plus"></i>Account</a>
                        <a class="dropdown-item" [routerLink]="['/login']" routerLinkActive="router-link-active"><i
                                class="fa fa-power-off"></i>Logout</a>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</nav>