import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
    providedIn: 'root'
  })

  export class AdvanceService {
    httpUrl;
  httpUrl1;
  httpUrl3;

    constructor(private http: HttpClient, private main: MainService) {
      this.httpUrl = this.main.httpUrl + "/property/defaulter_party";
      this.httpUrl1 = this.main.httpUrl + "/property/seized_property";
      this.httpUrl3 = this.main.httpUrl + "/property/possession";
    }

    async getdefaulterdata(obj:any) {
    }

    async getpropertytype(obj:any) {
      // /getAllPropertyType
      const resp = this.http.get(this.httpUrl + '/getAllPropertyType' +obj).toPromise().then(res => {
        return res;
      });
      return resp;

    }
      //get all by offer
   async  getAllDefaulter(obj:any) {
        // console.log(obj, "from services 8-3-22")
        const resp = this.http.get(this.httpUrl + '/getAllDefaulter' +obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }

      //sending data

      async createGenerateNotice(obj:any) {
        const resp = await this.http.post(this.httpUrl + '/createGenerateNotice', obj).toPromise().then(res => {
          return res;
        });
        if (resp) {
          return resp;
        }else{
          return
        }

      }

      //geting emi data
      async  getEmiDataForNotice(obj:any) {
        // console.log(obj, "emi data notice")
        const resp = this.http.get(this.httpUrl + '/dataForCancellation' +obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }

      // for cancelltion data

      async  getCancellationParty(obj:any) {
        // console.log(obj, "from services 8-3-22")
        const resp = this.http.get(this.httpUrl + '/getAvailablePartyForCencelltion' +obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }

      async  AvailabledForCancellation(obj:any) {
        const resp = this.http.get(this.httpUrl + '/AvailabledForCancellation' +obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }
//


      async  updateNoticeStatus(obj:any) {
        // console.log(obj, "from services 8-3-22")
        const resp = this.http.put(this.httpUrl + '/updateNoticeStatus',obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }

      //
      async  CancelAllotment(obj:any) {
         console.log(obj, "from services 14-3-22")
        const resp = await this.http.put<any>(this.httpUrl + '/cancelAllotment',obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }
      //

      async getpropertyCategory(obj:any) {
        const resp = this.http.get(this.httpUrl + '/getPropertyCategory' +obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }

      async getSeizedDetails(obj:any) {

        const resp = this.http.get(this.httpUrl1 + '/getRestorationReqData' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }

      //RESTORE THE restore Property

      async restoreProperty(obj:any) {
        const resp = await this.http.post(this.httpUrl1 + '/createRestoration', obj).toPromise().then(res => {
          return res;
        });
        if (resp) {
          return resp;
        }else{
          return
        }

      }

      async createPossessionRule(obj:any) {
        const resp = await this.http.post(this.httpUrl3 + '/createPassessionRule', obj).toPromise().then(res => {
          return res;
        });
        if (resp) {
          return resp;
        }else{
          return
        }

      }

      async getAllPossessionRule(obj:any) {
        const resp = this.http.get(this.httpUrl3 + '/getAllPossessionRule' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }
      async inactivePossessionRule(obj:any){
        const resp = await this.http.put<any>(this.httpUrl3 + '/inactivePossessionRule', obj).toPromise().then(res => {
          return res;
        });
        return resp;
      }

      async getPossessionData(obj:any){

        console.log(obj,"from service")
        const resp = await this.http.get(this.httpUrl3 + '/getPossessionData' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }

      async getAllPossessionRequest(obj:any){

        console.log(obj,"from service")
        const resp = await this.http.get(this.httpUrl3 + '/getPossessionReqData' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }

      // get all the restored property

      async AllRestoredproperty(obj:any) {
        console.log(obj,"from service")

        const resp = await this.http.get(this.httpUrl1 + '/getSeizedDetails' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }

      //get all Authorities

      async getAllAuthorities(obj:any) {
        console.log(obj,"from service")

        const resp = await this.http.get(this.httpUrl3 + '/allAssignUser' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }
// create posseesion
     async GeneratePossession(obj:any){

      const resp = await this.http.post(this.httpUrl3 + '/createpossession', obj).toPromise().then(res => {
        return res;
      });
      if (resp) {
        return resp;
      }else{
        return
      }

      }

      // aproved possession

      async getApprovedPossessionData(obj:any){

        console.log(obj,"from service")

        const resp = await this.http.get(this.httpUrl3 + '/getApprovedPossessionData' + obj).toPromise().then(res => {
          return res;
        });
        return resp;

      }















  }
