
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../../emb/service/user.service';
import { FileUploader } from 'ng2-file-upload';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
declare var $: any;
function readBase64(file:any): Promise<any> {
  var reader = new FileReader();
  var future = new Promise((resolve, reject) => {
    reader.addEventListener("load", function () {

      resolve(reader.result);
    }, false);

    reader.addEventListener("error", function (event) {
      reject(event);
    }, false);

    reader.readAsDataURL(file);
  });
  return future;
}
@Component({
  selector: 'app-icms-faq',
  templateUrl: './icms-faq.component.html',
  styleUrls: ['./icms-faq.component.css']
})
export class IcmsFaqComponent implements OnInit {


  user_id:any;
  user_email:any;
  ebillUser:any;
  b_acct_id:any;
  name:any;


  dataSource:any;


  selectFileType:any
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  @ViewChild('paginator2', { static: true }) paginator2!: MatPaginator;
  @ViewChild('sortCol3', { static: true }) sortCol3!: MatSort;
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;



  constructor(private router: Router, private spinner: NgxSpinnerService,
    private userService: UserService, private profileService: ProfileService,
    private sanitizer: DomSanitizer, public mainService: MainService) {
    this.selectFileType = null
  }

  Message:any;
  Subject:any;
  displayedColumns_for_FAQ = ['s_no', 'question', 'answer']
  panelOpenState1:any

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.name = this.ebillUser.name;
    await this.getAllFAQ();

  }
  AllFAQ:any=[];
  async  getAllFAQ() {
    var obj:any = new Object();
    obj['type'] = "FAQ";
    var resp = await this.userService.getHelpData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp.data);
      let dummy = []
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['module_cd'] == 'ICMS') {
          dummy.push(resp['data'][i])
        }
      }
      this.AllFAQ=dummy
      this.dataSource = new MatTableDataSource(dummy);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {

    }
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
