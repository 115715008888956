import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async getpaymentgatewayData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/payment_gateway/getpaymentdetails' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getpaymentgatewayDetails(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/payment_gateway/getpaymentgateway/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async submitgatewaydetails(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/payment_gateway/payment_gateway' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deletegatewaydetails(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.put(this.httpUrl + '/payment_gateway/inACTIVEgateway' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



}
