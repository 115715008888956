<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'BOQ1']}} </div>
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_tender_flag" class="nav-link active" (click)="reset()" href="#tab-7-1"
                                data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>{{mainService.allLableShowObj[mainService.language_cd+'BOQ2']}}
                                <!-- BOQ List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_tender_flag" class="nav-link active" (click)="reset()" href="#tab-7-2"
                                data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"> </i>
                                {{mainService.allLableShowObj[mainService.language_cd+'BOQ3']}}
                                <!--  Create BOQ  -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_tender_epc_flag" class="nav-link active" (click)="reset()" href="#tab-7-6"
                                data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"> </i>
                                 Upload BOQ EPC Mode
                                <!--  upload BOQ EPC c:\Users\PCS\AppData\Local\Programs\Microsoft VS Code\resources\app\out\vs\code\electron-browser\workbench\workbench.html -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="uu_tender_flag" class="nav-link active" (click)="reset()" href="#tab-7-3"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                {{mainService.allLableShowObj[mainService.language_cd+'BOQ5']}}
                                <!--  Update Units -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="ur_tender_flag" class="nav-link active" (click)="reset()" href="#tab-7-4"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                {{mainService.allLableShowObj[mainService.language_cd+'BOQ4']}}
                                <!--  Update Rate -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="ul_tender_flag " class="nav-link active" (click)="reset()" href="#tab-7-5"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                {{mainService.allLableShowObj[mainService.language_cd+'BOQ6']}}
                                <!-- Update Paid Last Quantity -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_paid_last_payment" class="nav-link active"  href="#tab-7-6"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                Update Paid Last Payment
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_tender_flag">
                            <br>
                            <div style="text-align: right;">
                                <!-- <button class=" btn btn-outline-primary" (click)="gotocrete()">Create BOQ </button> -->
                                <button class=" btn btn-outline-primary"  style="margin-right:0.5%" (click)="gotocrete()">Create BOQ </button>
                                <button class=" btn btn-outline-primary" (click)="gotoCreateEpcBOQ()">Upload BOQ EPC Mode </button>

                            </div>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    Select Project :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" (change)="changeproject()" [(ngModel)]="selected_proj" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div> <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}
                                    <!-- Select Work --> :
                                    <!-- (change)="getAllBOQ()" [(ngModel)]="work_id" [selectableGroup]="true" -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="workArr" bindLabel="desc" bindValue="id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}"
                                       
                                        (change)="changeWorkOnList()" [(ngModel)]="work_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <hr>


                            <div class="row">
                                <div class="col-3">Tender Id:</div>
                                <div class="col-3"> {{ BOQInfo["tender_id"]}}</div>
                                <div class="col-3">Party Info:</div>
                                <div class="col-3">{{partyObj[BOQInfo["party_id"]]}}</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3">Work Order Number:</div>
                                <div class="col-3">{{ BOQInfo["work_order_no"]}}</div>
                                <div class="col-3">Work order Name:</div>
                                <div class="col-3">{{ BOQInfo['work_order_name']}}</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3">Tender NIC ID:</div>
                                <div class="col-3">{{BOQInfo["tender_nic_id"]}}</div>
                                <div class="col-3">Tender Ref. Number:</div>
                                <div class="col-3">{{BOQInfo["tender_ref_no"]}}</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3">Over Under</div>
                                <div class="col-3"> {{ mainService.codeValueShowObj['EMB008'][BOQInfo["over_under"]]}}
                                </div>
                                <div class="col-3">Over Under Rate:</div>
                                <div class="col-3">{{BOQInfo['over_under_per']}}</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3">Amount :</div>
                                <div class="col-3">{{BOQInfo['amount']}}</div>
                                <div class="col-3">Status:</div>
                                <div class="col-3">{{BOQInfo['status']}}</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="open_update_unit(BOQInfo)">Update Unit
                                        <!-- Update Rate -->
                                    </button>&nbsp;
                                    <button class="btn btn-primary"
                                        (click)="open_update_rate(BOQInfo)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ34']}}
                                        <!-- Update Rate -->
                                    </button>&nbsp;
                                    <button class="btn btn-primary"
                                        (click)="open_updateAllreadyMeasured(BOQInfo)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ35']}}
                                        <!-- Update  Paid Last Quantity -->
                                    </button>&nbsp;
                                    <button class="btn btn-success"
                                        (click)="print(BOQInfo)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ36']}}
                                        <!-- Print -->
                                    </button>&nbsp;
                                    <button class="btn btn-success" *ngIf="pay_sch_flag"
                                    (click)="printPaymentSchedule(payschudeinfo)">Print Payment Schedule
                                    <!-- printPaymentSchedule -->
                                </button>&nbsp;
                                    <button class="btn btn-success" 
                                    (click)="open_updateAllreadyPayment(BOQInfo['tender_id'])">Update Paid Last Payment
                                    <!-- updatePaidLastPartment -->
                                </button>&nbsp;
                                    <button class="btn btn-danger"
                                        (click)="delete(BOQInfo)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ37']}}
                                        <!-- Delete -->
                                    </button>
                                </div>
                            </div>
                            <br>
                           

                        </div>
                        <div *ngIf="c_tender_flag || c_tender_epc_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">BOQ List </button>


                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <mat-step [stepControl]="first"
                                    label="{{mainService.allLableShowObj[mainService.language_cd+'BOQ20']}}">
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}
                                                <!-- Select work --> :
                                            </h6>
                                        </div>
                                        <div class="col-3" *ngIf="c_tender_flag">
                                            <ng-select [items]="filtered_work_arr" bindLabel="desc" bindValue="id"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}"
                                                (change)="changeWork()" [(ngModel)]="obj['work_id']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="work_order_id">
                                            </ng-select>
                                        </div>
                                        <div class="col-3" *ngIf="c_tender_epc_flag">
                                            <ng-select [items]="filtered_work_arr_for_epc" bindLabel="desc" bindValue="id"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}"
                                                (change)="changeWork()" [(ngModel)]="obj['work_id']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="work_order_id">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ76']}} :
                                                <!-- Select work -->
                                            </h6>
                                        </div>

                                        <div class="col-9">
                                            {{obj['path_desc']}}
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ21']}}
                                                <!-- Budget Name --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['budget_name']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ12']}}
                                                <!-- Work Order Name -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['work_order_name']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ22']}}
                                                <!-- Enter E-Tender ID -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['tender_nic_id']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ14']}}:</h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['tender_ref_no']}}
                                        </div>
                                    </div>
                                    <br>

                                    <hr>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ10']}}
                                                <!-- Vendor Name --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['party_leagal_name']}}
                                            <!--  <ng-select [items]="partyArr" bindLabel="desc" bindValue="id"
                                                [multiple]="false" placeholder="Select Party" (change)="changeParty()"
                                                [(ngModel)]="obj['party_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_id">
                                            </ng-select> -->
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ23']}}
                                                <!-- Party GST Number --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['party_gstin_no']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ24']}}
                                                <!-- Party Phone Number --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['party_phone_no']}}
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ25']}}
                                                <!-- Party Email -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['party_email']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ15']}}
                                                <!-- Over Under -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="mainService.codeValueTechObj['EMB008']"
                                                bindLabel="value" bindValue="code" [multiple]="false"
                                                (change)="changeoverunder()"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ58']}}"
                                                [(ngModel)]="obj['over_under']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="over_under">
                                                <!-- Select Over Under -->
                                            </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ16']}}
                                                <!-- Over Under Rate(%) -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control"
                                                *ngIf="obj['over_under'] == 'ATPR'" [(ngModel)]="obj['over_under_rate']"
                                                name="over_under" disabled>
                                            <input type="number" class="form-control"
                                                *ngIf="obj['over_under'] != 'ATPR'" [(ngModel)]="obj['over_under_rate']"
                                                name="over_under">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ26']}}
                                                <!-- Enter BOQ Amount -->:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" [(ngModel)]="obj['total_amount']"
                                                name="over_under">
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ27']}}
                                                <!-- Enter EMB Number --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <!-- <ng-select [items]="dropDown" bindLabel="id" bindValue="value" 
                                            [multiple]="false" placeholder="Select emb" [(ngModel)]="obj['emb_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="over_under">
                                            </ng-select> -->
                                            <input type="text" disabled class="form-control" [(ngModel)]="obj['already_emb']"
                                                name="already_emb">

                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ28']}}
                                                <!-- Enter Discount (%) --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" class="form-control" [(ngModel)]="obj['discount']"
                                                name="discount">
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ29']}}
                                                <!-- Choose E-Tender BOQ File --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="file" style="display: inline-block;"
                                                (change)="onFileChange($event)"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ62']}}"
                                                accept=".xls"> <!-- Upload file -->
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6> {{mainService.allLableShowObj[mainService.language_cd+'BOQ30']}}
                                                <!-- OR -->
                                            </h6>
                                        </div>


                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ31']}}
                                                <!-- Choose Custom BOQ File(.xlsx file only) --> :
                                            </h6>
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ32']}}
                                                <!-- Download format --> :
                                            </h6>
                                            <button class="btn btn-link"
                                                (click)="export()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ33']}}
                                                <!-- Download -->
                                            </button>
                                        </div>
                                        <div class="col-3">
                                            <input type="file" style="display: inline-block;"
                                                (change)="onFileChange1($event)"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ62']}}"
                                                accept=".xlsx"> <!-- Upload file -->
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>{{mainService.allLableShowObj[mainService.language_cd+'BOQ38']}}</button>
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{mainService.allLableShowObj[mainService.language_cd+'BOQ55']}}">
                                    <!-- Complete and Review -->
                                    <br>
                                    <div class="row">

                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ39']}}
                                                <!-- Work --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['work_desc']}}

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ40']}}
                                                <!-- Total BOQ Amount Without Taxes --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">

                                            {{total_sum}}
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <input type="checkbox" class="form-control"
                                                [(ngModel)]="obj['labour_cess_flag']" name="over_under"  >
                                        </div>
                                        <div class="col-3">

                                            <h6>Labour Cess :</h6>
                                        </div>
                                        <div class="col-3 text-right" *ngIf="obj['labour_cess_flag']==true else  dis_labour_cess">
                                            <input type="number" class="form-control" [(ngModel)]="obj['labour_cess']"
                                                name="over_under" #lob_cess="ngModel">
                                        </div>
                                             <!-- <div>{{lob_cess.touched}}</div> -->
                                            <ng-template  #dis_labour_cess>
                                                <div class="col-3 text-right">
                                                <input type="number" class="form-control" [(ngModel)]="obj['labour_cess']"
                                                    name="over_under" disabled #lob_cess="ngModel">
                                                </div>
                                               </ng-template>

                                    </div>
                                    <hr>

                                    <div class="row">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ57']}}">
                                                <!-- Filter -->
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="" style="overflow-x: scroll;">

                                        <table mat-table [dataSource]="datasource2" matSort
                                            class="table table-bordered">

                                            <ng-container matColumnDef="s_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ41']}}
                                                    <!-- S No. -->


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['s_no']}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="line_group_desc">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ42']}}
                                                    <!-- Line Group Description -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input data-toggle="tooltip" data-placement="top"
                                                        title="{{element['line_group_desc']}}" disabled
                                                        [(ngModel)]="element['line_group_desc']" class="form-control">
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="line_desc">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ43']}}
                                                    <!-- Line Description -->


                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right" style="margin-left: 3%;">
                                                    <input data-toggle="tooltip" data-placement="top"
                                                        title="{{element['line_desc']}}" disabled
                                                        [(ngModel)]="element['line_desc']" class="form-control">
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="item_code_or_make">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ44']}}
                                                    <!-- Item Code/make -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['item_code_or_make']}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="quantity">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ45']}}
                                                    <!-- Quantity -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['quantity']}}
                                            </ng-container>

                                            <ng-container matColumnDef="unit">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ46']}}
                                                    <!-- Unit -->

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index;"
                                                    class="right">
                                                    {{element['unit']}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="rate">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ47']}}
                                                    <!-- Rate -->

                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element ;let i = index"
                                                    class="right">
                                                    {{element['rate']}}
                                                </td>
                                            </ng-container>




                                            <ng-container matColumnDef="curr">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ48']}}
                                                    <!-- Curr -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['curr']}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="lime_amt_without_tax">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ49']}}
                                                    <!-- Line Amt without taxes -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['lime_amt_without_tax']}}

                                            </ng-container>

                                            <ng-container matColumnDef="line_amt_in_word">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ50']}}
                                                    <!--  Line Amount in Words -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input type="text" class="form-control"
                                                        (change)="checkPartRate(element,i)"
                                                        [(ngModel)]="element['line_amt_in_word']" disabled>

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="already_measured">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    <!-- Already Measured -->
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ51']}}
                                                    <!-- Paid Last Quantity -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="element['already_measured']">

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="part_rate">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ52']}}
                                                    <!--  Last Paid Rate -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element ;let i = index"
                                                    class="right">
                                                    <!-- {{element['rate']}} -->
                                                    <input type="number" class="form-control"
                                                        [(ngModel)]="element['part_rate']">

                                                </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="line_amt_in_word">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Calculated Bill
                                                    Quantity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="element['ebill_quantity']" disabled>
                                                </td>
                                            </ng-container> -->
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                            </tr>
                                        </table>
                                    </div>

                                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                                    <!--  <div style="overflow: scroll;">
                                        <table style="overflow-x: auto;overflow-y: auto;margin-bottom: 10px;" class="table table-bordered">
                                            <tr>
                                                <th *ngFor="let data1 of TableHeader" style="min-width:100px;">
                                                    <h6 *ngIf="data1 != 'Line Group Description' &&  data1 !='Line Description' && data1 != 'Line Amount in Words'" style="width: 100px;">{{data1}}</h6>
                                                    <h6 *ngIf="data1 == 'Line Group Description' ||  data1=='Line Description' || data1== 'Line Amount in Words'" style="width: 250px;">{{data1}}</h6>
                                                </th>

                                            </tr>
                                            <br>
                                            <tr *ngFor="let table_data_line of table_data; let i = index;">
                                                <td *ngFor="let data of TableFields ; let j= index;">
                                                    <input type="text" class="form-control" placeholder="" [(ngModel)]="table_data_line[data]" *ngIf="data != 'line_group_desc' && data != 'line_desc' && data !='line_amt_in_word'" />


                                                    <textarea *ngIf="data=='line_group_desc' || data == 'line_desc' || data=='line_amt_in_word'" style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="table_data_line[data]" class="form-control"></textarea>

                                                </td>
                                            </tr>
                                        </table>
                                    </div> -->
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>{{mainService.allLableShowObj[mainService.language_cd+'BOQ53']}}
                                                <!-- Back -->
                                            </button> &nbsp;&nbsp;
                                            <button mat-button mat-raised-button class="btn btn-primary" matStepperNext
                                                *ngIf="obj['total_amount']==total_sum.toFixed(2)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ38']}}</button>
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                (click)="check()"
                                                *ngIf="obj['total_amount']!=total_sum.toFixed(2)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ38']}}</button>

                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third"
                                label="Payment Schedule" *ngIf="c_tender_epc_flag">
                                <!-- Payment Schedule -->
                                <br>
                                <div class="row">
                                    <div class="col-6 text-right">
                                        <h6>
                                            Download Custom Payment Schedule File(.xlsx file only) :
                                        </h6>
                                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ32']}}
                                            <!-- Download format --> :
                                        </h6>
                                        <button class="btn btn-link"
                                            (click)="export1()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ33']}}
                                            <!-- Download -->
                                        </button>
                                    </div>
                                    <div class="col-3">
                                        <input type="file" style="display: inline-block;"
                                            (change)="onFileChange2($event)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ62']}}"
                                            accept=".xlsx"> <!-- Upload file -->
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter3($any($event.target).value)"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report5']}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div>
                                    <table mat-table [dataSource]="datasource3" matSort #sort3="matSort"
                                            class="table table-bordered">

                                            <ng-container matColumnDef="s_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ41']}}
                                                    <!-- S No. -->
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{i+1}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Description">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Description
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                   {{element['payment_line_desc']}}  
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Individual">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Individual %
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right" style="margin-left: 3%;">
                                                     {{element['payment_line_per']}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Cumulative">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Cumulative %
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right" style="margin-left: 3%;">
                                                     {{element['cum_per']}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Paid_last">
                                                <th mat-header-cell *matHeaderCellDef class="right" id="h"
                                                    mat-sort-header>
                                                    Paid Last %age
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element;let i = index"
                                                    class="right">
                                                    {{element['payment_last_paid_per']}} </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                                            </tr>
                                        </table>
                                        <mat-paginator #paginator3 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                        </mat-paginator>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-danger"
                                        matStepperPrevious>{{mainService.allLableShowObj[mainService.language_cd+'BOQ53']}}
                                        <!-- Back -->
                                    </button> &nbsp;&nbsp;
                                        <button mat-button mat-raised-button class="btn btn-primary"
                                            matStepperNext>{{mainService.allLableShowObj[mainService.language_cd+'BOQ38']}}</button>
                                    </div>
                                </div>
                                <br>
                            </mat-step>
                                <mat-step [stepControl]="fourth"
                                    label="{{mainService.allLableShowObj[mainService.language_cd+'BOQ56']}}">
                                    <!-- Done -->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ39']}}
                                                <!-- Work --> :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            {{obj['work_desc']}}
                                        </div>

                                    </div>
                                    <br>

                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-danger"
                                            matStepperPrevious>{{mainService.allLableShowObj[mainService.language_cd+'BOQ53']}}
                                            <!-- Back -->
                                        </button> &nbsp;&nbsp;
                                        <button class="btn btn-success"
                                            (click)="submit()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ54']}}
                                            <!-- SUBMIT -->
                                        </button>&nbsp;&nbsp;
                                        <!-- <button class="btn btn-warning" (click)="reset()">RESET</button>&nbsp;&nbsp; -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="uu_tender_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">BOQ List </button>


                            </div>
                            <!--  <div class="row">
                                <div class="col-4 text-right">
                                    Select Project :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" (change)="changeproject()" [(ngModel)]="selected_proj" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div> <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}
                                    <!-- Select Work --> :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="workArr" bindLabel="desc" bindValue="id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ7']}}"
                                        (change)="changeWorkInTab3()" [(ngModel)]="work_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                        <!-- Select Work -->
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">{{mainService.allLableShowObj[mainService.language_cd+'BOQ41']}}
                                            <!-- S No. -->
                                        </th>
                                        <th scope="col">{{mainService.allLableShowObj[mainService.language_cd+'BOQ43']}}
                                            <!-- Line Description -->
                                        </th>

                                        <th scope="col">{{mainService.allLableShowObj[mainService.language_cd+'BOQ59']}}
                                            <!-- Current Unit -->
                                        </th>
                                        <th scope="col">{{mainService.allLableShowObj[mainService.language_cd+'BOQ60']}}
                                            <!-- New Unit -->
                                        </th>
                                        <th scope="col">{{mainService.allLableShowObj[mainService.language_cd+'BOQ19']}}
                                            <!-- Action -->
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ob of changeUnit;let j=index">
                                        <td>{{ob['sno']}}</td>
                                        <td>
                                            <div class="showonhover">
                                                <input type="text" class="form-control" [(ngModel)]="ob['item_desc']"
                                                    disabled name="" id="">
                                                <span class="hovertext">
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ43']}}
                                                                <!-- Line Description --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{ob['item_desc']}}</p>
                                                        </div>
                                                    </div>

                                                </span>
                                            </div>
                                        </td>
                                        <td>{{ob['unit']}}</td>
                                        <td>
                                            <ng-select [items]="allGeo" bindLabel="unit" bindValue="unit"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ63']}}"
                                                [(ngModel)]="ob['new_unit']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="work_order_id">
                                                <!-- Select Unit -->
                                            </ng-select>
                                        </td>
                                        <td>
                                            <button class="btn btn-success"
                                                (click)="updateUnitSwal(ob,j)">{{mainService.allLableShowObj[mainService.language_cd+'BOQ61']}}
                                                <!-- Update -->
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="ur_tender_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">BOQ List </button>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ15']}}
                                        <!-- Over Under -->:
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB008']" bindLabel="value"
                                        bindValue="code" [multiple]="false" (change)="changeoverunderupdate()"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ58']}}"
                                        [(ngModel)]="updateobj['over_under']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="over_under">
                                        <!-- Select Over Under -->
                                    </ng-select>
                                </div>
                            </div> <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ16']}}
                                        <!-- Over Under Rate -->(%):
                                    </h6>

                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" *ngIf="updateobj['over_under'] == 'ATPR'"
                                        [(ngModel)]="updateobj['over_under_rate']" name="over_under_rate" disabled>
                                    <input type="number" class="form-control" *ngIf="updateobj['over_under'] != 'ATPR'"
                                        [(ngModel)]="updateobj['over_under_rate']" name="over_under_rate">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ27']}}
                                        <!-- Enter EMB Number --> :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="updateobj['already_emb']"
                                        name="already_emb">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ28']}}
                                        <!-- Enter Discount --> :
                                    </h6>

                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="updateobj['discount']"
                                        name="discount">

                                </div>
                            </div>
                            <br>
                            <div class="col-12 text-center">
                                <button class="btn btn-success"
                                    (click)="UpdateRate()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ61']}}
                                    <!-- Update -->
                                </button>&nbsp;&nbsp;
                            </div>
                        </div>
                        <div *ngIf="ul_tender_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">BOQ List </button>


                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter5($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ57']}}">
                                        <!-- Filter -->
                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource5" matSort class="table table-bordered">

                                    <ng-container matColumnDef="sn">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ41']}}
                                            <!-- S No. -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['sno']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="line_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ43']}}
                                            <!-- Line Description -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">

                                            <div class="showonhover">
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="element['item_desc']" disabled name="" id="">
                                                <span class="hovertext">
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ43']}}
                                                                <!-- Line Description --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['item_desc']}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ46']}}
                                                                <!-- Unit --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['unit']}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ67']}}
                                                                <!-- BOQ Quantity --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['eff_quantity']}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ68']}}
                                                                <!-- BOQ Rate --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['rate']}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ68']}}
                                                                <!-- BOQ Amount --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['line_amt']}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>RemainServiceing Quantity :</h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['eff_quantity'] }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-3 text-left">
                                                            <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ70']}}
                                                                <!-- Already Measured Quantity --> :
                                                            </h6>
                                                        </div>
                                                        <div class="col-9 text-left">
                                                            <p>{{element['already_measured']}}</p>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>

                                        </td>
                                    </ng-container>






                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ47']}}
                                            <!-- Rate -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            {{element['rate']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="new_meas">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ51']}}
                                            <!-- Paid Last Quantity -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <input type="number" [(ngModel)]='element["new_already_measured"]'
                                                class="form-control" name="" id="">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="part_rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ52']}}
                                            <!-- Last Paid Rate -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <input type="number" [(ngModel)]='element["part_rate"]' class="form-control"
                                                name="" id="">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" id="h" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'BOQ19']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index" class="right">
                                            <button (click)="updateAllreadyMeasured(element)" class="btn btn-primary">
                                                {{mainService.allLableShowObj[mainService.language_cd+'BOQ61']}}
                                                <!-- Update -->
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns5;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="u_paid_last_payment">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">BOQ List </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>Pre GST(If applicable..):</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["pre_gst"]'  class="form-control" name="pre_gst">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>With Held Temporary:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["temp_withheld"]'  class="form-control" name="temp_withheld">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>With Held Permanent:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["per_withheld"]'  class="form-control" name="per_withheld">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>CGST:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["cgst"]'  class="form-control" name="cgst">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>SGST:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["sgst"]'  class="form-control" name="sgst">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>IGST:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["igst"]'  class="form-control" name="igst">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>Add Security:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["add_security"]'  class="form-control" name="add_security">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6>WITH HELD AMOUNT:</h6>
                                </div>
                                <div class="col-3 text-left">
                                    <input type="number" [(ngModel)]='lastPayment["withheldamount"]'  class="form-control" name="withheldamount">
                                </div>
                            </div>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="addDeduction()" class="btn btn-primary">
                                        Add
                                    </button>
                                </div>
                            </div>
                            <table style="width: 100%;">
                                <tr>
                                    <th><h5>S No.</h5></th>
                                    <th><h5>Select Deduction</h5></th>
                                    <th><h5>Enter Amount</h5></th>
                                    <th><h5>Action</h5></th>
                                </tr>
                                <br>
                                <tr *ngFor="let ob of lastDedArr;let i=index">
                                    <td>{{i}}</td>
                                    <td>
                            
                                        <ng-select [items]="allDed" bindLabel="ded_name" bindValue="deduction_id" [multiple]="false"
                                            placeholder="Select Deduction" [(ngModel)]="ob['deduction_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="deduction_id">
                                        </ng-select>
                            
                                    </td>
                                    <td> <input type="number" [(ngModel)]='ob["amt"]' class="form-control" name="amt">
                                    </td>
                                    <td> <button (click)="deleteDeduction(i)" class="btn btn-danger">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            
                            </table>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)="submitPaymentAndDeduction()" class="btn btn-success">
                                        Add
                                    </button>
                                </div>
                            </div>
                           

                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">{{mainService.allLableShowObj[mainService.language_cd+'BOQ71']}}
    <!-- Loading -->....
</ngx-spinner>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObj[mainService.language_cd+'BOQ75']}}
                    <!-- About This -->
                </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <p>
                    Tender is the final bid selected after evaluation on UP Government eprocurement site @
                    etender.up.nic.in. After the evaluation is completed and a bid is finalized then the details of the
                    selected bid are available for download from this site in two documents
                    - (a) BOQ in xls format and (b) Tender documents as pdf. Both of these must be downloaded and
                    uploaded below to create the tender details.
                </p>





            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObj[mainService.language_cd+'BOQ18']}}
                    <!-- Status -->
                </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"
                       data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'BOQ66']}}
                        <!-- Close -->
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">{{mainService.allLableShowObj[mainService.language_cd+'BOQ72']}}
                    <!-- Approval -->
                </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row">
                    <div class="col-4 text-right">
                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ73']}}
                            <!-- Select Forwarded To --> :
                        </h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="userRoles" bindLabel="desc" bindValue="user_id" [multiple]="false"
                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ64']}}"
                            [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="work_order_id">
                            <!-- Select User -->
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>{{mainService.allLableShowObj[mainService.language_cd+'BOQ74']}}
                            <!-- Remark --> :
                        </h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'BOQ65  ']}}"
                            class="form-control"></textarea> <!-- enter work order name -->

                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"
                            (click)="saveappr()">{{mainService.allLableShowObj[mainService.language_cd+'BOQ54']}}
                            <!-- Submit -->

                        </button>
                    </div>
                </div>
                <br>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"
                       data-bs-dismiss="modal">{{mainService.allLableShowObj[mainService.language_cd+'BOQ66']}}
                        <!-- Close -->
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>