import { UserService } from '../service/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DeviationService } from '../service/deviation.service'
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from '../service/main.service';
import { EbillService } from '../service/ebill.service';
import { MatStepper } from '@angular/material/stepper';
import { MasterDataService } from '../service/master-data.service';
import { EbillRunningBillPrintService } from '../common _services/ebill-running-bill-print.service';
import { EmbPrintService } from '../common _services/emb-print.service';
import { TenderService } from '../service/tender.service'
import { EmbService } from '../service/emb.service'
declare var $: any;
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { ReferenceDataService } from '../service/reference-data.service'
import { ApproveService } from '../service/approve.service';
import { FilterService } from '../service/filter.service';
import { FileUploader } from 'ng2-file-upload';
import { CommonDataService } from '../common _services/common-data.service';
import { ApprovalUserService } from '../../portal/service/approval-user.service';
import { MainService as Portalmain } from '../../portal/service/main.service';
import { WorkFlowService } from '../../portal/service/work-flow.service';
import { EmbHierService } from '../service/emb-hier.service';
import {PrintEpcRunningBillService} from '../common _services/print-epc-running-bill.service'
@Component({
  selector: 'app-ebill-create',
  templateUrl: './ebill-create.component.html',
  styleUrls: ['./ebill-create.component.css']
})

export class EbillCreateComponent implements OnInit {
  displayedColumns = ['id', 'bill_no', 'bill_desc', 'bill_date', 'bill_amt', 'status', 'action'];
  displayedColumns1 = ['upquan', 'prevquan', 'crrbillquan', 's_no', 'line_desc', 'epr', 'uptodateamt', 'prevamt', 'amt'];
  displayedColumns3 = ['s_no', 'payment_line_desc', 'last_paid_per', 'upto_date_per', 'remaining_quantity','fraction', 'to_pay_per', 'amount', 'remark'];

  displayedColumns11 = ['s_no', 'line_desc', 'eq', 'epr'];
  displayedColumns21 = ['s_no', 'line_desc', 'bill_no', 'quantity', 'paid_quantity', 'part_rate', 'amount'];
  displayedColumns12 = ['s_no', 'line_desc', 'eq', 'epr', 'amt', 'action'];
  finalBill = [{ value: 1, code: 'YES' }, { value: 0, code: 'NO' }]
  selectObj:any = { 'work_order_id': '' }
  isLinear = false;
  first:any = true;
  second:any = true;
  third:any = true;
  fourth:any = true;
  fifth:any = true;
  deductionData = []
  data = []
  gstarr = [{ value: 'SGST/CGST' }, { value: 'IGST' }]
  datasource: any;
  datasource1: any;
  datasource3: any;
  datasource11: any;
  datasource21:  any;
  datasource12:any;
  part_data:any = []
  systemDate:any;
  b_acct_id:any;
  ebillUser:any
  obj:any = {}
  dedTypeArr:any = []
  pro:any
  work_order_id: any;
  constructor(private portalmain: Portalmain, private embHierService:EmbHierService,public WorkFlowService: WorkFlowService,
    public ApprovalUserService: ApprovalUserService, private CommonDataService: CommonDataService,
    public filterService: FilterService, private approveService: ApproveService,
    private embService: EmbService, private DeviationService: DeviationService,
    private ebillService: EbillService, private referenceDataS: ReferenceDataService,
    private tenderService: TenderService, private masterDataService: MasterDataService,
    private userService: UserService, private spinner: NgxSpinnerService,
    public toastr: ToastrService,private embPrintService:EmbPrintService,
    private sanitizer: DomSanitizer, public mainService: MainService,
    private ebillRunningBillPrintService: EbillRunningBillPrintService,
    ) { }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  @ViewChild(MatSort, { static:false}) sort!: MatSort;
  @ViewChild(MatStepper, { static: false }) stepper!: MatStepper;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  idtovalue:any = {0:'NO',1:'YES'};

  @ViewChild('paginator3', { static: false }) paginator3!: MatPaginator;
  @ViewChild('sortCol3', { static: false }) sortCol3!: MatSort;
  // idtovalue = { 0: 'NO', 1: 'YES' }

  @ViewChild('paginator11', { static: false }) paginator11!: MatPaginator;
  @ViewChild('sortCol21', { static: false }) sortCol21!: MatSort;


  @ViewChild(MatPaginator, { static: false }) paginator21!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sortCol31!: MatSort;

  @ViewChild('paginator12', { static: false }) paginator12!: MatPaginator;
  @ViewChild('sortCol12', { static: false }) sortCol12!: MatSort;

  party:any = []
  partyObj:any = {}
  role_cd = '';
  name = '';
  httpUrl:any;
  uploader1:any;
  uploader2:any;
  uploader3:any;
  uploader4:any;
  apprflag = false
  vendorflag = false
  reassignflag = false

  create_flag:boolean=false
  create_epc_flag:boolean=false
  update_flag:boolean=false
  list_flag:boolean=true
  createtab(){
    this.create_flag=true;
    this.list_flag=false
    this.create_epc_flag=false;
    this.update_flag=false
    this.refresh()
  }
  createEpctab(){
    this.create_flag=false;
    this.create_epc_flag=true;
    this.list_flag=false
    this.update_flag=false
    // this.refresh()
    this.changeWorkEpc();
  }

  async listbtab(){
    this.datasource=null
    this.selectObj['work_order_no']=null
   this.work_order_id=null
    this.list_flag=true;
    this.create_flag=false;
    this.update_flag=false;
    this.create_epc_flag=false;
    await this.getWorkflowlog()
    await this.getundertakings()
    await this.getAllHeirNodeList()
  }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.role_cd = this.ebillUser.role_cd;
    // console.log(this.role_cd)
    this.name = this.ebillUser.name;

    this.httpUrl = this.mainService.httpUrl;
    //await this.getAllRule()
    if (this.level1['role_cd'] == this.role_cd) {
      this.apprflag = true
    }

    console.log(this.zoneusers)
    this.uploader1 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };


    this.uploader2 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader2.onAfterAddingFile = (file:any) => { file.withCredentials = false; };

    this.uploader3 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader3.onAfterAddingFile = (file:any) => { file.withCredentials = false; };

    this.uploader4 = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader4.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    await this.getAllEmbdataAssigned()
    await this.getAllHeadRecord();
    await this.buildProjectArray()
    await this.getSystemDate();
    await this.getAllWorkInfo();
    await this.getparty()
    await this.getAllDed();
    for (let i = 0; i < this.assigned_data.length; i++) {
      if( this.assigned_data[i]['user_id'] == this.ebillUser['user_id'] && this.assigned_data[i]['status'] == "ACTIVE") this.reassignflag = true


      console.log(this.assigned_data[i])
    }
    this.changeWork();
    this.obj['total_data_amount'] = 0
    this.obj['total_ded_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['sgst_amount'] = 0
    this.obj['add_with_held'] = 0
    this.obj['add_security'] = 0
    this.obj['total_payable'] = 0
    this.obj['part_rate_total'] = 0
    this.obj['showTotal'] = 0;
    this.obj['subTotal'] = 0;
    this.obj['subTotal1'] = 0;
    this.obj['pre_gst'] = 0;
    this.obj['pre_gst_amt'] = 0;
    this.obj['final_amount'] = 0;
    this.obj['with_held_amt'] = 0;
    this.obj['deduction'] = [];
    this.obj['temp_withheld'] = 0
    this.obj['per_withheld'] = 0
    this.obj['rel_temp_withheld'] = 0
    this.obj['amt_after_withheld'] = 0
    await this.getactivity()
    await this.getWorkflowlog()
    await this.getundertakings()
    await this.getAllHeirNodeList()
  }

  async changeWorkEpc(){
    this.upload_work_id = this.selectObj['work_order_no'];
    // set path desc
    let planing_path = ''
    for (let i = 0; i < this.listWork_epc.length; i++) {
      if (this.listWork_epc[i]['id'] == this.selectObj['work_order_no']) {
        this.obj['node_cd'] = this.listWork_epc[i]['node_cd'];
      }
    }
    this.path_desc = null
    if (!this.selectObj['work_order_no']) {
      this.path_desc = null
      this.obj['node_cd'] = null
    }
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.obj['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        planing_path = this.existing_heirarchy_nodes[i]['path']
      }
    }
    let dum = []
    dum = planing_path.split('|')
    let dummy2:any = []
    if (dum.length > 0) {
      for (let i = 0; i < dum.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.path_desc = dummy2.join(' --> ')
    console.log(this.obj, 'path desc')


    await this.getTenderID();
    this.obj['total_data_amount'] = 0
    this.obj['total_ded_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['sgst_amount'] = 0
    this.obj['part_rate_total'] = 0
    this.obj['add_with_held'] = 0
    this.obj['add_security'] = 0
    this.obj['total_payable'] = 0
    this.obj['showTotal'] = 0;
    this.obj['subTotal'] = 0;
    this.obj['subTotal1'] = 0;
    this.obj['pre_gst'] = 0;
    this.obj['pre_gst_amt'] = 0;
    this.obj['final_amount'] = 0;
    this.obj['with_held_amt'] = 0;
    this.obj['deduction'] = [];
    this.obj['temp_withheld'] = 0
    this.obj['per_withheld'] = 0
    this.obj['rel_temp_withheld'] = 0
    this.obj['amt_after_withheld'] = 0;
    await this.getBoqItems();
    await this.getBillInfo();
    await this.getPaymentSchedule();
    await this.getpreviousbillquantity();
    await this.getlastbillrateOnPayment();
    await this.getPaymentScheduleForBill();
    await this.checkForPreGST();
  }

  boqItemNew:any = [];
  async getPaymentSchedule() {
    console.log(this.selectedTender['tender_id']);
    let obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    let resp = await this.ebillService.getPaymentSchedule(JSON.stringify(obj))
    if (resp['error'] == false) {
      console.log(resp.data)
      this.boqItemNew = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting Payment Schedule data", "error");
    }
  }

  async getlastbillrateOnPayment() {
    this.spinner.show();
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'], bill_no: this.obj['prev_bill_no'] }
    var resp = await this.ebillService.getlastbillrate(JSON.stringify(obj));
    console.log(this.boqItems)
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {
        for (let i = 0; i < resp['data'].length; i++) {
          this.prevbillpartrate[resp['data'][i]['sno']] = resp['data'][i]['rate']
        }
      } else {
        for (let i = 0; i < this.boqItemNew.length; i++) {
          this.prevbillpartrate[this.boqItemNew[i]['sno']] = this.boqItemNew[i]['file_eff_rate'] / 100
          this.prevbillquantity[this.boqItemNew[i]['sno']] = 0
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting Previous Bill rate  data", "error");
    }
  }




  async getPaymentScheduleForBill() {


    console.log(this.prevbillpartrate);
    console.log(this.prevbillquantity);
    this.embData=[];
    let total = 0;
    let overundertotal = 0
    let overunderpertotal = 0


    var objPay = {}
    for (let j = 0; j < this.boqItemNew.length; j++) {

      if (this.prevbillpartrate[this.boqItemNew[j]['sno']] == undefined || this.prevbillpartrate[this.boqItemNew[j]['sno']] == null) {
        this.prevbillpartrate[this.boqItemNew[j]['sno']] = this.boqItemNew[j]['file_eff_rate'] / 100
      }


      if (this.prevbillquantity[this.boqItemNew[j]['sno']] == undefined || this.prevbillquantity[this.boqItemNew[j]['sno']] == null) {
        this.prevbillquantity[this.boqItemNew[j]['sno']] = 0
      }

      let objPay:any = {}
      this.overunderobj[this.boqItemNew[j]['sno']] = { over_under_per: this.boqItems[0]['over_under_per'], over_under: this.boqItems[0]['over_under'] }
      objPay['payment_line_desc'] = this.boqItemNew[j]['payment_line_desc']
      objPay['sno'] = this.boqItemNew[j]['sno']
      objPay['eff_rate'] = this.boqItemNew[j]['file_eff_rate'] / 100
      objPay['file_eff_rate'] = this.boqItemNew[j]['file_eff_rate']
      objPay['payment_line_per'] = this.boqItemNew[j]['payment_line_per']
      objPay['payment_last_paid_per'] = this.boqItemNew[j]['payment_last_paid_per']
      // objPay['rate'] = this.prevbillpartrate[this.boqItems[j]['sno']]
      objPay['rate'] = objPay['eff_rate'];
      // objPay['quantity'] = Number((this.boqItemNew[j]['payment_line_per'] - Number(this.prevbillquantity[this.boqItemNew[j]['sno']]) - Number(this.boqItemNew[j]['payment_last_paid_per'])).toFixed(3));
      objPay['quantity'] = 0
      objPay['remaining_quantity'] = Number((this.boqItemNew[j]['payment_line_per'] - Number(this.prevbillquantity[this.boqItemNew[j]['sno']]) - Number(this.boqItemNew[j]['payment_last_paid_per'])).toFixed(3));
      objPay['upto_date_per'] = Number((this.boqItemNew[j]['payment_line_per']).toFixed(3));
      objPay['last_paid_per'] = Number((Number(this.prevbillquantity[this.boqItemNew[j]['sno']]) - Number(this.boqItemNew[j]['payment_last_paid_per'])).toFixed(3));
      objPay['is_pre_gst'] = this.boqItems[0]['is_pre_gst'];
      objPay['amount'] = Number((objPay['quantity'] * objPay['eff_rate']).toFixed(2));
      objPay['remark'] = 'NA';
      total = total + objPay['amount']
      if (this.boqItems[0]['over_under'] == 'LS') {
        overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
      }
      if (this.boqItems[0]['over_under'] == 'EXC') {
        overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

      } if (this.boqItems[0]['over_under'] == 'ATPR') {
        overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

      }
      overundertotal = overundertotal + objPay['amount']
      this.embData.push(objPay);
    }

    this.obj['total_bill_amt'] = Number(total.toFixed(2))
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    if (this.selectedTender['over_under'] == 'LS') {
      this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
    }
    if (this.selectedTender['over_under'] == 'EXC') {
      this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

    } if (this.selectedTender['over_under'] == 'ATPR') {
      this.obj['showTotal'] = Number((overundertotal).toFixed(2))
    }
    this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    this.obj['labuor_cess'] = 0
    this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
    this.obj['overunderamt'] = this.obj['showTotal']
    if (this.selectedTender['discount'] != 0) {
      this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
    }
    this.obj['subtotal1'] = this.obj['showTotal'];
    if (this.selectedTender['labour_cess_flag'] == 1) this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal']) / 100).toFixed(0))

    this.spinner.hide();
    // this.Pregst()
    console.log(this.embData)
    this.datasource3 = new MatTableDataSource(this.embData);
    this.datasource3.sort = this.sort;
    this.datasource3.paginator = this.paginator3;
  }


  ///acthier
open_activitypopup(){
 // this.planing_arr = []
  //this.planing_arr = []
  if(this.planing_arr.length == 0){
    this.down({},-1)
  }

  $('#ACTHIER').modal('show');
}
  setHierarchyNode(item:any, i:any) {
    console.log(item,i);
   item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]

    let flag = false
    let dummy2 = []
    //console.log(this.planing_arr, this.existing_heirarchy_nodes, item, i)
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])
    }
   /*  let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            flag = true
            item['level'] = this.existing_heirarchy_nodes[j]['level']
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    if (flag == false) {
      this.Obj['node_cd'] = null
    } */
    this.obj['path_desc'] = dummy2.join(' --> ')
  }
  planing_arr:any = []
  projecthier:any = []
  projhierobj:any = {}
  projhierobjtype:any = {}
  projhierobjtypecd:any = {}
  projhierobjisleaf:any = {}
  async getactivity(){
    let obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    obj['table_name']='activity_hier';
    let resp = await this.embHierService.getactHierarchy(obj)
    if (resp['error'] == false) {
     // this.allcostcenters = []
      console.log(resp['data'])
      this.projecthier = resp['data']
       for (let i = 0; i < resp['data'].length; i++) {
         for (let j = 1; j < 8; j++) {
         this.projhierobj[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_value']
         this.projhierobjtype[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_node_type']
         this.projhierobjtypecd[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_cd']
         this.projhierobjisleaf[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['is_leaf']

         }
       /*  if(resp['data'][i]['cc_func']=="EMB")
        {
          let obb = Object.assign({},resp['data'][i])
          obb['node_cd'] = obb['cc_code']
          obb['desc'] = obb['cc_code'] +'-'+obb['cc_name']
          obb['node_desc'] = obb['desc']
          this.allcostcenters.push(obb)

        } */

      }
     // this.allcostcenters = [...resp['data']]
      //console.log(this.allcostcenters)
      // this.planing_arr[1] = {existing_heirarchy_nodes:[...this.allcostcenters]}
      console.log(this.planing_arr);



    }else{
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  submit_act(){
    if (this.planing_arr[this.planing_arr.length - 1]['is_leaf']==0) {
      Swal.fire('Error', 'You Have Not Selected Leaf At The Last Level', 'error')

    } else {
      $('#ACTHIER').modal('hide');
    }
  }


  down(item:any,k:any){
    console.log(this.planing_arr)
    let patharr = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      console.log(this.planing_arr[i])

     patharr.push( this.projhierobj[this.planing_arr[i]['hierarchy_type']])


    }
    this.obj['path_desc'] = patharr.join("-->")
    this.planing_arr.push({level:k+2,existing_heirarchy_nodes : []})
    let arr:any= []
    for (let i = 0; i < this.projecthier.length; i++) {
      if(!arr.includes(this.projecthier[i]['lvl'+(k+2)+'_cd']) && this.projecthier[i]['lvl'+(k+2)+'_cd'] != null){
        if(this.projecthier[i]['lvl'+(k+1)+'_cd'] == item['hierarchy_type'] || k == -1){
          this.planing_arr[k+1]['existing_heirarchy_nodes'].push({node_cd:this.projecthier[i]['lvl'+(k+2)+'_cd'],desc:this.projecthier[i]['lvl'+(k+2)+'_value'],is_leaf:this.projecthier[i]['is_leaf']})
          arr.push(this.projecthier[i]['lvl'+(k+2)+'_cd'])

        }

      }
    /*   if(this.projecthier[i]['lvl'+(k+1)+'_user_cd'] == item['node_cd']){

      } */

    }
  }
  remove(item:any,i:any){
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arr.length; i++) {
        console.log(this.planing_arr[i])

       patharr.push( this.projhierobj[this.planing_arr[i]['hierarchy_type']])


      }
      this.obj['path_desc'] = patharr.join("-->")
    }

  }
//message
message_flag = false
  async getle(user_id:any){
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
let resp = await this.mainService.getcurrentlegalentity(JSON.stringify(obj))
console.log( resp['data'])
if (resp['error'] == false) {
  //await this.updatepending(this.tender_apprdata)
  await this.sendmessage(resp['data'][0]['phone_no'],resp['data'][0]['party_name']
  )
  this.spinner.hide();
  this.message_flag = false
  //this.toastr.success("Approval Forwarded Successfully!!")
} else {
  this.spinner.hide();

  Swal.fire("Error", resp['data'], 'error');

}
  }
  async sendmessage(mobile:any,user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainService.sendMsg(mobile, this.tender_apprdata['doc_type'] , this.tender_apprdata['doc_local_no'],user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }
  // async sendmessage(mobile){
  //  console.log(mobile,this.tender_apprdata['doc_type']+' Number '+this.tender_apprdata['doc_local_no'])
  //   let resp = await this.mainService.sendMsg(mobile,this.tender_apprdata['doc_type']+' Number '+this.tender_apprdata['doc_local_no'])
  //   if (resp['error'] == false) {

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();

  //     Swal.fire("Error", resp['data'], 'error');

  //   }
  // }
  //Reassign access
  assigned_data = []
  async getAllEmbdataAssigned() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.embHierService.getAllEmbdataAssigned(JSON.stringify(obj));
    console.log(resp, 'all data')
    this.assigned_data = resp['data']
    if (resp['error'] == false) {
    }
  }


  existing_heirarchy_nodes = []
  async getAllHeirNodeList() {
    this.spinner.show();
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.embHierService.getAllEmbHierarchy(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp['data']);
      this.existing_heirarchy_nodes = resp['data']

    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While EMB HIER', 'error')
    }
  }
  //udertakings************************************//
  async getundertakings() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    obj['doc_type'] = 'EBILL'
    obj['role_cd'] = this.level1['role_cd']
    console.log(obj)
    var resp = await this.mainService.getundertakingsWithWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp['data'].length > 0) {
        this.undertakings = JSON.parse(resp['data'][0]['data'])
      }
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }
  //workflow*********************************************//
  level_for_approval:any = []
  workflow_level1:any = {}
  async getWorkflowlog() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'EBILL'
    var resp = await this.mainService.getWorkflows(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      if(resp['data'].length!=0){
        this.workflow_level1 = resp['data'][0]
        this.level_for_approval = resp['data']
        var currentuser_role = this.ebillUser.role_cd
        for (let i = 0; i < currentuser_role.length; i++) {
          if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
            this.apprflag = true
          }
        }
        this.level1 = this.workflow_level1
      }

      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }
  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.selectedTender['work_id']
    console.log(obj, this.selectedTender)
    var resp = await this.mainService.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();


      obb['user_id'] = this.ebillUser.user_id

      obb['user_name'] = this.ebillUser.party_name;
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'EBILL'
      obb['doc_local_no'] = this.tender_apprdata['id']
      obb['doc_desc'] = this.tender_apprdata['bill_desc']
      obb['role_cd'] = this.level1['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.selectedTender['work_id']
      obb['level'] = this.level1['level']
      obb['undertaking'] = JSON.stringify(this.undertakings)
      obb['branch'] = this.level1['branch']
      obb['field_value'] = this.tender_apprdata['bill_amt']
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.updatestatus(this.tender_apprdata['id'])
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
           if(this.message_flag == true) await this.getle(this.selectedTender['party_id'])
         } else {
           if(this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

         }
        this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();

     // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
          Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
          return
        }
        obb['user_id'] = -1
        obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }


      if (obb["user_id"] == -1) {
        let userData:any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'EBILL'
      obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
      obb['doc_desc'] = this.tender_apprdata['bill_desc']
     // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.tender_apprdata['node_cd']
      if(this.ApprovalUserService.vendorflag == true){
        obb['level'] = this.tender_apprdata['level']
        obb['branch'] = this.tender_apprdata['branch']
      }
      else{
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = this.tender_apprdata['bill_amt']
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  // ********************************************re assign start************************************///
  venderid:any
  async getdocumentstatus(element:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_local_no'] = element['id']
    obj['doc_type'] = 'EBILL'
    obj['module_cd'] = 'EMB'
    obj['status'] = 'PENDING'
    var resp = await this.portalmain.getWorkflowlog(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {

      if (resp['data'].length > 0) {
        return resp['data'][0]
      }
    }
  }
  async reassign(element:any) {
    /*  await this.getzoneusers()
     await this.getapprdata(element)
     await this.getpendingdata(element)
     await this.getAllRuleForReAssign("EBILL");
     await this.getparty() */
    this.tender_apprdata = await this.getdocumentstatus(element)
    this.pending_apprarr = []
    console.log(this.tender_apprdata)
    this.pending_apprarr.push(this.tender_apprdata)
    /* this.tender_apprdata = Object.assign({}, element)
    this.tender_apprdata['level'] = 1
    this.tender_apprdata['branch'] =1
    this.tender_apprdata['doc_local_no'] = this.tender_apprdata['id']
    this.tender_apprdata['doc_type'] = 'EBILL'//this.tender_apprdata['id']
    this.tender_apprdata['node_cd'] = this.selectedTender['work_id']
    this.tender_apprdata['module_cd'] = 'EMB' */
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'EBILL', 'EMB')
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.tender_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.tender_apprdata)
    this.venderid = this.selectedTender['party_id']
    this.appr_remark = ''
    this.forwarded_user_id = null
    $('#x').modal('show');
  }

  async rovokedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        obb['user_id'] = -1
        if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
          Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
          return
        }
        obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = this.tender_apprdata['doc_type']//'EBILL'
      obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
      obb['doc_desc'] = this.tender_apprdata['doc_desc']

      obb['module_cd'] = this.tender_apprdata['module_cd'] //'EMB'
      obb['node_cd'] = this.tender_apprdata['node_cd']
      if(this.ApprovalUserService.vendorflag == true){
        obb['level'] = this.tender_apprdata['level']
        obb['branch'] = this.tender_apprdata['branch']
      }
      else{
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = this.tender_apprdata['field_value']
      var resp = await this.portalmain.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingrevoked()
        // await this.underapprovalapproval(this.tender_apprdata)
        //  await this.getAllTaskOfUser()
        // await this.updatestatus(this.tender_apprdata['id'])
        this.spinner.hide();
        Swal.fire("Success", "Approval Forwarded Successfully!!", 'success');

      } else {
        this.spinner.hide();
        Swal.fire("Error", resp['data'], 'error');

      }
    } else {
      Swal.fire("Error", "Please Select Any User..!", 'error');

      this.spinner.hide();
    }
  }
  async pendingdelete() {
    this.spinner.show()

    var obb:any = new Object();



    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['remark'] = this.appr_remark
    obb['status'] = 'EBILL DELETED'
    obb['id'] = this.tender_apprdata['id']


    var resp = await this.portalmain.updatetask(obb)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }

  }
  undertakings:any = []
  async pendingrevoked() {
    this.spinner.show()

    var obb:any = new Object();



    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['remark'] = this.appr_remark
    obb['status'] = 'REVOKED'
    obb['id'] = this.tender_apprdata['id']


    var resp = await this.portalmain.updatetask(obb)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }

  }
  async approvedocment() {
    this.spinner.show()

    var obb:any = new Object();



    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['module_cd'] = this.tender_apprdata['module_cd']
    obb['status'] = 'APPROVED'
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']


    var resp = await this.portalmain.updateWorkflowDocumentStatus(obb)
    if (resp['error'] == false) {
      await this.pendingapproval()
      this.spinner.hide();
      Swal.fire("Success", "Approved Successfully!!", 'success');
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }

  }
  async saveapprpending() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.flag == true) {
      var obb:any = new Object();

      if (this.forwarded_user_id == null && this.flag == true) {
        obb['user_id'] = -1
        obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.forwarded_user_id
      }

      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = this.tender_apprdata['doc_type']
      obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
      obb['doc_desc'] = this.tender_apprdata['doc_desc']
      obb['role_cd'] = this.rolebyid[this.forwarded_user_id]
      obb['zone_cd'] = this.CommonDataService.ProjectCodeToZoneCodeObj[this.selectObj['project_cd']]
      obb['flag'] = this.tender_apprdata['flag'] + 1
      var resp = await this.approveService.insertForAppr(obb)
      if (resp['error'] == false) {
        await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async Approveswal() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.approvedocment()
      }
    })
  }

  async approve(element:any) {
    this.spinner.show();
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['flag'] = this.tender_apprdata['flag']
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
    obb['user_id'] = this.ebillUser.user_id
    obb['role_cd'] = this.tender_apprdata['role_cd']
    obb['status'] = 'APPROVED'
    obb['remark'] = this.appr_remark
    var resp = await this.approveService.updateappr(obb)
    if (resp['error'] == false) {
      await this.updatestatuspending(this.tender_apprdata['doc_local_no'])
      this.toastr.success("Approved Successfully", "Success")
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }


  async updatestatuspending(id:any) {
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = id
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'APPROVED'
    var resp = await this.ebillService.updateebillStatus(obb)
    if (resp['error'] == false) {
      await this.getAllEBILL()
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }


  async updatepending(id:any) {
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['flag'] = this.tender_apprdata['flag']
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
    if (this.tender_apprdata['user_id'] != -1) {
      obb['user_id'] = this.tender_apprdata['user_id']

    }
    obb['role_cd'] = this.tender_apprdata['role_cd']
    obb['status'] = 'REVOKED'
    obb['remark'] = this.appr_remark
    obb['vendor_id'] = this.tender_apprdata['vendor_id']
    var resp = await this.approveService.updateappr(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  pending_apprarr:any = []
  async getpendingdata(element:any) {
    var obj:any = new Object();
    this.pending_apprarr = []
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = "EBILL";
    var resp = await this.approveService.getPendingApprbydoclocalno(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.tender_apprdata = Object.assign({}, resp['data'][0]);
      this.pending_apprarr.push(resp.data[0])
    } else {
      this.toastr.error(resp['data'])
    }
  }


  ApprArrdata:any = []
  async getapprdata(element:any) {
    var obj:any = new Object();
    this.selectedEle = Object.assign({}, element);

    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = "EBILL";
    var resp = await this.approveService.getApprbydoclocalno(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.ApprArrdata = resp.data
    } else {
      this.toastr.error(resp['data'])
    }
  }


  level1re:any = {}
  workuserre:any = []
  allDocTypere:any = []
  allApprovalre:any = []
  rolebyidre:any = {}
  flag:any = false
  rejectuser:any = []
  approveflag:any = false
  useridtoname:any = {}
  async getAllRuleForReAssign(doc_type:any) {
    this.allDocTypere = [];
    this.spinner.show()
    var resp = await this.referenceDataS.getApprovalLevels(JSON.stringify({ b_acct_id: this.b_acct_id }));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allApprovalre = resp['data'];
      var level2:any = {}
      this.rejectuser = []
      console.log(this.ApprArrdata)
      console.log(this.zoneusers)
      console.log(this.allApprovalre)

      for (let i = 0; i < this.zoneusers.length; i++) {
        this.zoneusers[i]['desc'] = this.zoneusers[i]['user_id'] + '-' + this.zoneusers[i]['name'] + '-' + this.zoneusers[i]['role_cd']
        this.useridtoname[this.zoneusers[i]['user_id']] = this.zoneusers[i]['name']
      }
      let vendorrevokedflag = false
      for (let i = 0; i < this.ApprArrdata.length; i++) {
        if (this.ApprArrdata[i].user_id == -1) {
          vendorrevokedflag = true
        }
        for (let j = 0; j < this.zoneusers.length; j++) {
          if (this.ApprArrdata[i].user_id == this.zoneusers[j]['user_id'] && this.ApprArrdata[i].role_cd == this.zoneusers[j]['role_cd']) {
            this.rejectuser.push(this.zoneusers[j])
            this.zoneusers.splice(j, 1);
            break
          }
        }
      }

      console.log(this.zoneusers)



      for (let i = 0; i < this.rejectuser.length; i++) {
        if (this.rejectuser[i].user_id == this.ebillUser.user_id && this.rejectuser[i].role_cd == this.tender_apprdata['role_cd']) {
          this.rejectuser.splice(i, 1);
        }
      }



      for (var i = 0; i < this.allApprovalre.length; i++) {
        if (this.allApprovalre[i]['doc_type'] == doc_type) {

          if (this.allApprovalre[i]['sent_to_vendor'] == 1) {
            this.level1re = Object.assign({}, this.allApprovalre[i])
          }
          if (this.allApprovalre[i]['role_cd'] == this.tender_apprdata['role_cd']) {
            level2 = Object.assign({}, this.allApprovalre[i])

          }
          if (this.tender_apprdata['user_id'] == -1) {
            if (this.allApprovalre[i]['sent_to_vendor'] == 1) {
              level2 = Object.assign({}, this.allApprovalre[i])
            }
          }
          this.allDocTypere.push(this.allApprovalre[i])
        }
      }
      console.log(level2, this.tender_apprdata)
      var arr = []
      for (let j = 0; j < this.allDocTypere.length; j++) {
        for (let i = 0; i < this.zoneusers.length; i++) {
          if (this.allDocTypere[j]['role_cd'] == this.zoneusers[i]['role_cd'] && this.allDocTypere[j]['level_of_approval'] >= level2['level_of_approval']) {
            arr.push(this.zoneusers[i])
          }
        }
        if (arr.length > 0) {
          break;
        }
      }

      this.workuserre = arr
      for (let i = 0; i < this.zoneusers.length; i++) {
        if (this.level1re['role_cd'] == this.zoneusers[i]['role_cd']) {
          this.flag = false
          break;
        }
        if ((this.zoneusers.length - 1) == i && this.level1re['role_cd'] == this.tender_apprdata['role_cd'] && vendorrevokedflag == false) {
          this.flag = true
        }
      }
      for (let i = 0; i < this.workuserre.length; i++) {
        if (this.workuserre[i]['role_cd'] != undefined) {
          this.rolebyid[this.workuserre[i]['user_id']] = this.workuserre[i]['role_cd']
        }

      }

      this.approveflag = false
      if (this.workuserre.length == 0) {
        this.approveflag = true
      }

      console.log(this.workuserre)

    } else {
      this.spinner.hide()
    }
  }
  dtlworkflow:any = []
  async seeworkflow(element:any) {

    this.tender_apprdata = Object.assign({}, element);

    this.tender_apprdata['level'] = 1
    this.tender_apprdata['branch'] = 1
    this.tender_apprdata['doc_local_no'] = this.tender_apprdata['id']
    this.tender_apprdata['doc_type'] = 'EBILL'//this.tender_apprdata['id']
    this.tender_apprdata['node_cd'] = this.selectedTender['work_id']
    this.tender_apprdata['module_cd'] = 'EMB'
    await this.WorkFlowService.getWorkflowlog(this.ebillUser, 'EBILL', 'EMB')
    // await this.ApprovalUserService.getWorkflowlogbydocno(this.ebillUser,this.tender_apprdata)
    await this.WorkFlowService.getWorkflowloguser(this.ebillUser, this.tender_apprdata)
    console.log(this.WorkFlowService.level_for_approval)
    this.dtlworkflow = this.WorkFlowService.level_for_approval
    for (let i = 0; i < this.dtlworkflow.length; i++) {
      if (this.dtlworkflow[i]['data'] != null) {
        let data = JSON.parse(this.dtlworkflow[i]['data'])
        let dummy=[]
        for (let j = 0; j < data.length; j++) {
          let ob;
          ob=data[j]['selected_field']+'( '+data[j]['selected_oprater']+'  -  '+data[j]['value']+ ')'
          dummy.push(ob)
        }
        this.dtlworkflow[i]['data_desc']=dummy.join()
      }
    }
    $('#Z').modal('show');
  }




  // ********************************************re assign end************************************///

  //users
  allApproval:any = []
  allDocType:any = []
  level1:any = {}
  workuser:any = []

  // ********************************************Approval User Start************************************///


  async getAllRule() {
    this.allDocType = [];
    this.spinner.show()
    var resp = await this.referenceDataS.getApprovalLevels(JSON.stringify({ b_acct_id: this.b_acct_id }));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allApproval = resp['data'];
      var obj:any = {};
      var level2:any = {}
      for (let i = 0; i < this.zoneusers.length; i++) {
        this.zoneusers[i]['desc'] = this.zoneusers[i]['user_id'] + '-' + this.zoneusers[i]['name'] + '-' + this.zoneusers[i]['role_cd']

      }
      for (var i = 0; i < this.allApproval.length; i++) {
        if (this.allApproval[i]['doc_type'] == 'EBILL') {
          if (this.allApproval[i]['level_of_approval'] == 1) {
            this.level1 = Object.assign({}, this.allApproval[i])
          }
          if (this.allApproval[i]['level_of_approval'] == 2) {
            level2 = Object.assign({}, this.allApproval[i])
          }
          this.allDocType.push(this.allApproval[i])
        }
      }
      var arr = []
      for (let i = 0; i < this.zoneusers.length; i++) {
        if (this.level1['role_cd'] == this.zoneusers[i]['role_cd'] && this.ebillUser.user_id != this.zoneusers[i]['user_id']) {
          arr.push(this.zoneusers[i])
        } else if (this.ebillUser.user_id == this.zoneusers[i]['user_id'] && this.ebillUser.role_cd != this.zoneusers[i]['role_cd']) {
          if (this.level1['role_cd'] == this.zoneusers[i]['role_cd']) {
            arr.push(this.zoneusers[i])
          }
        }

      }
      this.vendorflag = false
      if (arr.length == 0) {
        if (this.level1['sent_to_vendor'] == 1) {
          this.vendorflag = true
        }
        for (let i = 0; i < this.zoneusers.length; i++) {
          if (level2['role_cd'] == this.zoneusers[i]['role_cd']) {
            arr.push(this.zoneusers[i])
          }/* else if (this.ebillUser.user_id == this.zoneusers[i]['user_id'] && this.ebillUser.role_cd != this.zoneusers[i]['role_cd']) {
          if (level2['role_cd'] == this.zoneusers[i]['role_cd']) {
            arr.push(this.zoneusers[i])
          }
        } */

        }
      }

      this.workuser = arr
      for (let i = 0; i < this.workuser.length; i++) {
        if (this.workuser[i]['role_cd'] != undefined) {
          this.rolebyid[this.workuser[i]['user_id']] = this.workuser[i]['role_cd']
        }


      }
      console.log(this.allDocType)

    } else {
      this.spinner.hide()
    }
  }
  zoneusers:any = []
  rolebyid:any = {}
  async getzoneusers() {

    var Obj = Object()
    Obj['b_acct_id'] = this.b_acct_id
    Obj['zone_cd'] = this.CommonDataService.ProjectCodeToZoneCodeObj[this.selectObj['project_cd']]
    Obj['work_id'] = this.selectObj['work_order_no']
    Obj['proj_cd'] = this.selectObj['project_cd']
    var resp = await this.userService.getAllWorkRelatedUser(JSON.stringify(Obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      this.zoneusers = resp['data']
    } else {
      this.spinner.hide();
    }
  }


  // ********************************************Approval User End************************************///


  ///************************************************Upload Document start********************************************** */

  selectedFile1 :any = null;
  public imagePath1:any;
  onFileUpload1(event:any, files:any) {
    this.selectedFile1 = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath1 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };
  }

  selectedFile2 :any = null;
  public imagePath2:any;
  onFileUpload2(event:any, files:any) {
    this.selectedFile2 = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath2 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };
  }

  selectedFile3 :any = null;
  public imagePath3:any;
  onFileUpload3(event:any, files:any) {
    this.selectedFile3 = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath3 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };
  }

  selectedFile4: any = null;
  public imagePath4:any;
  onFileUpload4(event:any, files:any) {
    this.selectedFile4 = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath4 = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };
  }


  async upload1(bill_no:any, work_id:any) {

    if (this.selectedFile1 == null) {
      console.log("TESTING REPORT File Not Selected For Upload....!!")
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile1, this.selectedFile1.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_name'] = this.uploader1.queue[0].some.name;
    obj['doc_type'] = 'TESTINGREPORT';
    obj['doc_local_no'] = bill_no;
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['work_id'] = work_id;
    obj['related_to'] = 'EBILL'
    obj['remark'] = ''
    this.spinner.show()
    const params = JSON.stringify(obj);
    this.uploader1.queue[0].url = this.httpUrl + '/info/upload/uploadDoc' + params;
    this.uploader1.queue[0].upload();
    this.uploader1.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response);
      if (resp.error == false) {
        this.spinner.hide();
        console.log('Success', 'TESTING REPORT Uploaded Successfully!!', 'success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }

  async upload2(bill_no:any, work_id:any) {

    if (this.selectedFile2 == null) {
      console.log("PHOTOGRAPH File Not Selected For Upload....!!")
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile2, this.selectedFile2.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_name'] = this.uploader2.queue[0].some.name;
    obj['doc_type'] = 'PHOTOGRAPH';
    obj['doc_local_no'] = bill_no;
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['work_id'] = work_id;
    obj['related_to'] = 'EBILL'
    obj['remark'] = ''
    this.spinner.show()
    const params = JSON.stringify(obj);
    this.uploader2.queue[0].url = this.httpUrl + '/info/upload/uploadDoc' + params;
    this.uploader2.queue[0].upload();
    this.uploader2.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response);
      if (resp.error == false) {
        this.spinner.hide();
        console.log('Success', 'PHOTOGRAPH Uploaded Successfully!!', 'success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }

  async upload3(bill_no:any, work_id:any) {

    if (this.selectedFile3 == null) {
      console.log("ROYALTIDETAIL File Not Selected For Upload....!!")
      return;
    }

    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile3, this.selectedFile3.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_name'] = this.uploader3.queue[0].some.name;
    obj['doc_type'] = 'ROYALTIDETAIL';
    obj['doc_local_no'] = bill_no;
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['work_id'] = work_id;
    obj['related_to'] = 'EBILL'
    obj['remark'] = ''
    this.spinner.show()
    const params = JSON.stringify(obj);
    this.uploader3.queue[0].url = this.httpUrl + '/info/upload/uploadDoc' + params;
    this.uploader3.queue[0].upload();
    this.uploader3.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response);
      if (resp.error == false) {
        this.spinner.hide();
        console.log('Success', 'ROYALTIDETAIL Uploaded Successfully!!', 'success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }


  async upload4(bill_no:any, work_id:any) {

    if (this.selectedFile4 == null) {
      console.log("OTHERDOCUMENT File Not Selected For Upload....!!")
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append('image', this.selectedFile4, this.selectedFile4.name);
    const obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_name'] = this.uploader4.queue[0].some.name;
    obj['doc_type'] = 'OTHERDOCUMENT';
    obj['doc_local_no'] = bill_no;
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['work_id'] = work_id;
    obj['related_to'] = 'EBILL'
    obj['remark'] = ''
    this.spinner.show()
    const params = JSON.stringify(obj);
    this.uploader4.queue[0].url = this.httpUrl + '/info/upload/uploadDoc' + params;
    this.uploader4.queue[0].upload();
    this.uploader4.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response);
      if (resp.error == false) {
        this.spinner.hide();
        console.log('Success', 'OTHERDOCUMENT Uploaded Successfully!!', 'success');
      } else {
        this.spinner.hide();
        // Swal.fire('Error', resp.data,'error');
      }
    };
  }

  async view_doc(element:any, doc_type:any) {
    console.log(element['id']);
    console.log(this.selectObj['work_order_no'])
    await this.getuploaddocument(this.selectObj['work_order_no'], element['id'], doc_type);
  }

  uploadDocObj:any = {}

  async getuploaddocument(work_id:any, bill_no:any, doc_type:any) {
    let ob:any = new Object;
    ob['b_acct_id'] = this.b_acct_id
    ob['doc_type'] = [doc_type]
    ob['related_to'] = 'EBILL'
    ob['work_id'] = work_id;
    ob['doc_local_no'] = bill_no;
    console.log(ob)
    var resp = await this.masterDataService.getDocInfo(JSON.stringify(ob));
    if (resp['error'] == false) {
      console.log(resp.data);
      if (resp.data.length > 0) {
        this.uploadDocObj = resp.data[0]
        await this.view(this.uploadDocObj['id'], work_id)
      } else {
        console.log("No Document Found.......");
        Swal.fire("Warning", "Document Not Uploaded.....!", "warning");
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while getting Upload Documents!', 'error');
    }
  }

  imgURL:any;
  async view(id:any, work_id:any) {
    console.log(id, work_id)
    var obj:any = new Object();
    obj['id'] = id
    obj['b_acct_id'] = this.b_acct_id
    obj["work_id"] = work_id
    obj["doc_name"] = this.uploadDocObj['doc_name']
    this.spinner.show()
    if (obj["doc_name"] != undefined) {
      const res = await this.masterDataService.viewDoc(obj);
      if (res) {
        var docname = obj["doc_name"];
        var ext = docname.split('.');
        if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
          let file = new Blob([res], { type: 'image/png' });
          var fileURL = window.URL.createObjectURL(file);
          this.imgURL = this.sanitizer.bypassSecurityTrustUrl(fileURL);
          this.spinner.hide()
          window.open(fileURL, '_blank');
        } else if (ext[1].toLowerCase() == 'xlsx') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
          let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        } else {
          let file = new Blob([res], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.spinner.hide()
        }
      } else {
        this.spinner.hide()
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'No document Uploaded !', 'error');
    }
  }
  ///************************************************Upload Document End********************************************** */


  ///************************************************Budget Check Start********************************************** */

  available_amount = 0;
  async checkForBudget(bud_code:any, total_payable_amount:any) {
    var flag = false;
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bud_cd'] = bud_code;
    console.log(obj);


    var resp = await this.referenceDataS.getAllExpenseOnBud(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.available_amount = 0;
     /*  if (resp['data'][0]['bill_expense_amount'] != null) {
        this.available_amount = resp['data'][0]['bud_amt'] - resp['data'][0]['expense_amount'] - resp['data'][0]['bill_expense_amount']
      } else {
        this.available_amount = resp['data'][0]['bud_amt'] - resp['data'][0]['expense_amount']
      } */
      console.log(this.available_amount);

     /*  if (this.available_amount >= total_payable_amount) {
        flag = true;
      } */
flag= true
      console.log(total_payable_amount);

    } else {
      this.toastr.error(resp['data'])
    }
    return flag;
  }


  ///************************************************Budget Check End********************************************** */

  refresh() {
    this.obj = {}
    this.embData = []
    this.datasource1 = new MatTableDataSource([]);
    this.datasource = new MatTableDataSource([]);
    this.selectObj = { work_order_id: '' }
    this.stepper.selectedIndex = 0;
    this.changeWork()
  }



  changeWithHeld() {
    this.obj['total_payable'] = Number((this.obj['bill_with_gst_amt'] + this.obj['add_with_held'] + this.obj['add_security']).toFixed(0));
  }


  async forwardToVendor() {
    var obb:any = new Object()
    obb['user_id'] = -1
    obb['remark'] = this.appr_remark
    obb['b_acct_id'] = this.b_acct_id
    obb['forwarded_by'] = this.ebillUser.user_id
    obb['status'] = 'PENDING'
    obb['doc_type'] = 'EBILL'
    obb['doc_local_no'] = this.tender_apprdata['id']
    obb['doc_desc'] = this.tender_apprdata['bill_desc']
    obb['vendor_id'] = this.selectedTender['party_id']
    obb['flag'] = 2
    obb['zone_cd'] = this.ebillUser.zone_cd
    await this.vendorsaveappr()
    var resp = await this.approveService.insertForAppr(obb)
    if (resp['error'] == false) {
      await this.updatestatus(this.tender_apprdata['id'])
      // await this.getAllApprovalStatus();
      this.spinner.hide();
      this.toastr.success("Approval Added Successfully!!")
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  async vendorsaveappr() {
    var obb:any = new Object()
    obb['user_id'] = this.ebillUser.user_id
    obb['remark'] = ""
    obb['b_acct_id'] = this.b_acct_id
    obb['forwarded_by'] = this.ebillUser.user_id
    obb['forward_msg'] = this.appr_remark;
    obb['status'] = 'APPROVED'
    obb['doc_type'] = 'EBILL'
    obb['doc_local_no'] = this.tender_apprdata['id']
    obb['doc_desc'] = this.tender_apprdata['bill_desc']
    obb['role_cd'] = this.ebillUser.role_cd
    obb['zone_cd'] = this.ebillUser.zone_cd
    obb['flag'] = 1;


    if (this.undertakings == null) {
      obb['undertakings'] = JSON.stringify({ data: this.undertakings })
    } else {
      obb['undertakings'] = JSON.stringify({ bill_amt: Number((this.billInfo['bill_amt'] + this.billInfo['cgst'] + this.billInfo['sgst'] + this.billInfo['igst']).toFixed(0)), data: this.undertakings })
    }


    var resp = await this.approveService.insertForAppr(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  ///************************************************Project-Work Head Start********************************************** */


  assignedWorks:any = {}
  /* async getAllHeadRecord() {
    this.spinner.show()
    var obj = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.ebillUser.user_id
    obj['zone_cd'] = this.ebillUser.zone_cd
    obj['access'] = this.ebillUser.access
    obj['role_cd'] = this.ebillUser.role_cd
    var resp = await this.tenderService.getHeadRecord(JSON.stringify(obj));

    if (resp['error'] == false) {
      await this.filterService.FilterDataFunction(resp['data']);
      for (var i = 0; i < resp['data'].length; i++) {
        this.assignedWorks[resp['data'][i]['work_id']] = 1;
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error While Getting All Head Data', 'error')
    }
  } */
  async getAllHeadRecord() {
    this.spinner.show();
    var work_ids:any = []
    work_ids = Object.values(this.CommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
      console.log(this.assignedWorks)
    }
  }
  ///************************************************Project-Work Head End********************************************** */





  ///************************************************View Status Start********************************************** */

  statusArr:any = [];
  selectedEle:any;
  async viewStatus(element:any) {
    this.selectedEle = element;
    var obj:any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'EBILL';
    obj['module_cd'] = 'EMB'
    var resp = await this.mainService.getWorkflowlog(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.getWorkflowloguser()
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = this.partyObj[resp['data'][i]['vendor_id']]
          resp.data[i]['role_cd'] = "Contractor"
          resp.data[i]['user_id'] = resp['data'][i]['vendor_id'];
          resp.data[i]['is_vendor'] = true
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            console.log(this.workflowuser[j])
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }

        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr = resp.data
      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }


  certificate() {
    var txt = "LUCKNOW DEVELOPMENT AUTHORITY" + "(" + "LDA" + ") , LUCKNOW\n" + "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };




    var header1 = {
      columns: [
        {
          width: '*',
          text: 'APPROVAL LOG FOR RUNNING BILL FOR FILE/EMB NO - ' + this.selectedTender['already_emb'],
          bold: true,
          alignment: 'center'
        }

      ],

    };

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    var tbl:any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 375],
        body: [
        ]
      }
    };

    var workObj = this.workObj[this.selectedTender['work_id']];
    var partyObj = this.partyObj[this.selectedTender['party_id']];
    var arr = [];
    arr.push({ text: "Name Of Contractor ", bold: true });
    arr.push(partyObj);
    tbl.table.body.push(arr);
    arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj['work_order_name']);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Bill No ", bold: true });
    arr.push(this.selectedEle['bill_no']);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Budget Head ", bold: true });
    arr.push(workObj['budget_cd']);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Budget Name ", bold: true });
    arr.push(workObj['budget_name']);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Estimate No ", bold: true });
    arr.push('');
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Work Order No ", bold: true });
    arr.push(workObj['work_order_no']);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Agreement Dated ", bold: true });
    arr.push(workObj['agr_dt']);
    tbl.table.body.push(arr);
    dd.content.push(tbl);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var tbl1 = {
      fontSize: 15,
      table: {
        headerRows: 1,
        widths: [70, 124, 124, 124, 144, 125],
        body: [
          ["User ID", "User Name", "Designation", "Action", "Date", "Remark"]

        ],
      }
    };
    for (var i = 0; i < this.statusArr.length; i++) {
      var arr = [];
      if (this.statusArr[i]['is_vendor'] == true) {
        arr.push("V" + this.statusArr[i]['user_id']);
      } else {
        arr.push(this.statusArr[i]['user_id']);
      }
      arr.push(this.statusArr[i]['user_name']);
      if (this.statusArr[i]['role_cd'] != 'Contractor') {
        arr.push(this.mainService.roleCodeToRoleDescription[this.statusArr[i]['role_cd']]);
      } else {
        arr.push(this.statusArr[i]['role_cd']);
      }
      arr.push(this.statusArr[i]['status']);
      arr.push(this.statusArr[i]['timestamp']);
      arr.push(this.statusArr[i]['remark']);
      tbl1.table.body.push(arr);
    }
    dd.content.push(tbl1);
    pdfMake.createPdf(dd).download('running-bill-cert' + '.pdf');
  }

  ///************************************************View Status End********************************************** */


  async buildProjectArray() {
    this.allProject = this.mainService.codeValueTechObj['EMB001']
  }

  async getSystemDate() {
    console.log("ddd")
    var resp = await this.ebillService.systemDate();
    if (resp['error'] == false) {
      this.systemDate = resp['data']
    } else {
      Swal.fire("Error", "Not able to get system Date", "error");
    }
  }

  ///************************************************All Party Info Start********************************************** */


  partyIdObj:any = {};
  async getparty() {
    this.spinner.show();
    var resp = await this.masterDataService.getparty(this.b_acct_id);
    if (resp['error'] == false) {
      this.party = resp.data;
      this.partyObj = new Object
      for (let i = 0; i < this.party.length; i++) {
        this.partyObj[this.party[i]['rep_le_id']] = this.party[i]['party_leagal_name']
        this.partyIdObj[this.party[i]['rep_le_id']] = this.party[i]['party_id']
      }
      this.spinner.hide()
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting Vendor Info", "error")
    }

  }


  ///************************************************All Party Info End********************************************** */


  ///************************************************Filter WOrk On Project Start********************************************** */
  all_listWork:any=[];
  listWork:any = [];
  listWork_epc:any=[];
  changeWork() {
    this.listWork = Object.assign([], [])
    this.listWork_epc = Object.assign([], [])
    this.all_listWork = Object.assign([], [])
    for (let j = 0; j < this.workArr.length; j++) {

      this.all_listWork.push(this.workArr[j])
      /*  if (this.selectObj['project_cd'] == this.workArr[j]['project_cd']) {

       } */
      //  this.listWork.push(this.workArr[j])
       if (this.workArr[j]['emb_mode'] == 'GEN') {
         this.listWork.push(this.workArr[j])
       } else {
         this.listWork_epc.push(this.workArr[j])
       }
    }

  }
  ///************************************************Filter WOrk On Project  End********************************************** */


  ///************************************************All Work Info Start********************************************** */


  workArr:any = [];
  workObj:any = {}
  async getAllWorkInfo() {
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = [];
      var temp = resp['data'];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]['id']] != undefined) {
          dt.push(temp[i])
        }
      }

      this.workArr = dt;
      this.workObj = new Object()
      for (let i = 0; i < this.workArr.length; i++) {
        this.workArr[i]['desc'] = this.workArr[i]['work_order_no'] + " - " + this.workArr[i]['work_order_name'];
        this.workObj[this.workArr[i]['id']] = this.workArr[i]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting work info");
    }
  }


  ///************************************************All Work Info End********************************************** */


  allProject = []


  async getAllEBILL() {
    await this.getTenderID()
    await this.getBillInfo()
    // await this.getWorkflowloguser()
  }


  upload_work_id:any;


  ///************************************************changeWorkInTab2 Start********************************************** */
 path_desc:any=''
  async changeWorkInTab2() {

    this.upload_work_id = this.selectObj['work_order_no'];
    // set path desc
    let planing_path = ''
    for (let i = 0; i < this.listWork.length; i++) {
      if (this.listWork[i]['id'] == this.selectObj['work_order_no']) {
        this.obj['node_cd'] = this.listWork[i]['node_cd'];
      }
    }
    this.path_desc = null
    if (!this.selectObj['work_order_no']) {
      this.path_desc = null
      this.obj['node_cd'] = null
    }
    //set works`s path description
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (this.obj['node_cd'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        // console.log()
        planing_path = this.existing_heirarchy_nodes[i]['path']
      }
    }
    let dum = []
    dum = planing_path.split('|')
    let dummy2:any = []
    if (dum.length > 0) {
      for (let i = 0; i < dum.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dum[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.path_desc = dummy2.join(' --> ')
    console.log(this.obj, 'path desc')


    //
    await this.getTenderID();
    this.obj['total_data_amount'] = 0
    this.obj['total_ded_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['sgst_amount'] = 0
    this.obj['part_rate_total'] = 0
    this.obj['add_with_held'] = 0
    this.obj['add_security'] = 0
    this.obj['total_payable'] = 0
    this.obj['showTotal'] = 0;
    this.obj['subTotal'] = 0;
    this.obj['subTotal1'] = 0;
    this.obj['pre_gst'] = 0;
    this.obj['pre_gst_amt'] = 0;
    this.obj['final_amount'] = 0;
    this.obj['with_held_amt'] = 0;
    this.obj['deduction'] = [];
    this.obj['temp_withheld'] = 0
    this.obj['per_withheld'] = 0
    this.obj['rel_temp_withheld'] = 0
    this.obj['amt_after_withheld'] = 0
    await this.getBoqItems()
    await this.getBillInfo()
    if(this.lastbill['status'] == 'UNDERAPPROVAL' || this.lastbill['status'] == 'GENERATED'){
      Swal.fire("Warning", "Please First Approve Last Bill", "warning");
      return;
   }
  await this.getdevemb()
    await this.getpreviousbillquantity();
    await this.getlastbillrate();
    await this.getEmbForBill();
    await this.getPartRate();
    await this.checkForPreGST()
  }
  dev_emb:any = {}
  async getdevemb(){
    let obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'], emb_no: this.lastbill['emb_no'] }

    let resp = await this.ebillService.getdevemb(JSON.stringify(obj))
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {
        for (let i = 0; i < resp['data'].length; i++) {
          if(resp['data'][i]['pay_quantity'] != null){
          this.dev_emb[resp['data'][i]['sno']] = resp['data'][i]['pay_quantity']
          }else{
            this.dev_emb[resp['data'][i]['sno']] = 0
          }
        }
      } else {
        for (let i = 0; i < this.boqItems.length; i++) {
          this.dev_emb[this.boqItems[i]['sno']] = 0 // this.boqItems[i]['part_rate']
          //this.prevbillquantity[this.boqItems[i]['sno']] = 0
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting Previous Emb Quantity  data", "error");
    }
  }
  async getlastbillrate() {
    this.spinner.show();
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'], bill_no: this.obj['prev_bill_no'] }
    var resp = await this.ebillService.getlastbillrate(JSON.stringify(obj));
    console.log(this.boqItems)
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {
        for (let i = 0; i < resp['data'].length; i++) {
          this.prevbillpartrate[resp['data'][i]['sno']] = resp['data'][i]['rate']
        }
      } else {
        for (let i = 0; i < this.boqItems.length; i++) {
          this.prevbillpartrate[this.boqItems[i]['sno']] = this.boqItems[i]['part_rate']
          this.prevbillquantity[this.boqItems[i]['sno']] = 0
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting Previous Bill rate  data", "error");
    }
  }
  boqItems:any = [];
  boqObj:any = {};
  async getBoqItems() {
    this.spinner.show();
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }

    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.boqItems = resp['data'];
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqObj[this.boqItems[i]['sno']] = this.boqItems[i];
        this.overunderobj[this.boqItems[i]['sno']] = { over_under_per: this.boqItems[i]['over_under_per'], over_under: this.boqItems[i]['over_under'] }

      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Oops", "Error while getting Boq Items");

    }
  }

  ///************************************************changeWorkInTab2 Start********************************************** */

  //used in running-bill



  prevbillquantity:any = {}
  async getpreviousbillquantity() {
    this.spinner.show();
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    var resp = await this.ebillService.getquantityofprevbill(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.prevbillquantity[resp['data'][i]['sno']] = resp['data'][i]['quantity']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting Previous Bill Quantity  data", "error");
    }
  }
  part_rate_arr:any = []
  async getPartRate() {
    this.spinner.show();
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    var resp = await this.ebillService.getPartRate(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      var part_rate = resp['data']['part_rate'];
      var part_rate_group = resp['data']['part_rate_group'];
      for (var i = 0; i < part_rate.length; i++) {
        part_rate[i]['already_rate'] = part_rate[i]['rate'];
        part_rate[i]['new_rate'] = 0;
        part_rate[i]['amount'] = 0;
        for (var j = 0; j < part_rate_group.length; j++) {
          if (part_rate[i]['id'] == part_rate_group[j]['part_rate_id']) {
            part_rate[i]['already_rate'] += part_rate_group[j]['rate'];
          }
        }
        part_rate[i]['new_rate'] = part_rate[i]['eff_rate'] - part_rate[i]['already_rate'];
        this.partRateChange(0, part_rate[i])
      }
      this.part_rate_arr = part_rate;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while getting part rate data", "error");
    }
  }

  partRateChange(j:any, element:any) {
    this.obj['part_rate_total'] = 0;
    for (var i = 0; i < this.part_rate_arr.length; i++) {
      this.part_rate_arr[i]['amount'] = Number((this.part_rate_arr[i]['new_rate'] * this.part_rate_arr[i]['quantity']).toFixed(2))
      this.obj['part_rate_total'] += Number((this.part_rate_arr[i]['new_rate'] * this.part_rate_arr[i]['quantity']).toFixed(2))
    }
    this.obj['part_rate_total'] = Number(this.obj['part_rate_total'].toFixed(2));
    this.changepart(element)
  }

  changepart(element:any) {
    if (this.obj['showpartratetotal'] == undefined) {
      this.obj['showpartratetotal'] = this.obj['showTotal']
      this.obj['showpartratetotal1'] = this.obj['subtotal1']
      this.obj['overunderamtpart'] = this.obj['overunderamt']
    }
    this.obj['overunderamt'] = 0
    this.obj['subtotal1'] = 0
    this.obj['showTotal'] = 0
    this.obj['total_bill_amt'] = 0;
    this.obj['subtotal'] = 0
    var partarr = []
    for (let i = 0; i < this.embData.length; i++) {
      partarr.push(Object.assign(this.embData[i]))

    }
    for (let i = 0; i < this.part_rate_arr.length; i++) {
      this.part_rate_arr[i]['amount'] = Number((this.part_rate_arr[i]['quantity'] * this.part_rate_arr[i]['new_rate']).toFixed(2))
      partarr.push(this.part_rate_arr[i])
    }

    var overunderpertotal = 0
    for (let i = 0; i < partarr.length; i++) {

      this.obj['subtotal'] = this.obj['subtotal'] + Number((partarr[i]['amount']).toFixed(2))
      if (this.overunderobj[partarr[i]['sno']]['over_under_per'] != -1) {
        this.obj['total_bill_amt'] = Number((this.obj['total_bill_amt'] + Number((partarr[i]['amount']).toFixed(2))).toFixed(2))
        if (this.overunderobj[partarr[i]['sno']]['over_under'] == 'LS') {
          overunderpertotal = Number((overunderpertotal - (Number((Number((partarr[i]['amount']).toFixed(2)) * this.overunderobj[partarr[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
        }
        if (this.overunderobj[partarr[i]['sno']]['over_under'] == 'EXC') {
          overunderpertotal = Number((overunderpertotal + (Number((Number((partarr[i]['amount']).toFixed(2)) * this.overunderobj[partarr[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
        } if (this.overunderobj[partarr[i]['sno']]['over_under'] == 'ATPR') {
          overunderpertotal = Number((overunderpertotal + (Number((Number((partarr[i]['amount']).toFixed(2)) * this.overunderobj[partarr[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

        }
      } else {
        if (this.selectedTender['over_under'] == 'LS') {
          overunderpertotal = Number((overunderpertotal - (Number((Number((partarr[i]['amount']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

        }
        if (this.selectedTender['over_under'] == 'EXC') {
          overunderpertotal = Number((overunderpertotal + (Number((Number((partarr[i]['amount']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

        } if (this.selectedTender['over_under'] == 'ATPR') {
          overunderpertotal = Number((overunderpertotal).toFixed(2))
        }

      }
    }
    this.obj['overunderamt'] = this.obj['subtotal'] + overunderpertotal
    this.obj['subtotal1'] = Number((this.obj['overunderamt'] - ((Number((Number((this.obj['overunderamt']).toFixed(2))).toFixed(2)) * this.selectedTender['discount'] / 100))).toFixed(2))


    this.obj['showTotal'] = this.obj['subtotal1']
    this.Pregst()
    this.changetemp_withheld()
  }

  work_emb_mode='GEN';

  allBOQData:any = [];
  selectedTender:any = {}
  async getTenderID() {
    this.spinner.show();
    let obj:any = {}
    obj['work_id'] = this.selectObj['work_order_no']
    this.all_listWork.map((x:any)=>{
      if(x['id']==obj['work_id']){
        this.work_emb_mode=x['emb_mode']
      }
    })
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    console.log(obj)
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    console.log(resp.data)
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp['data'].length > 0) {
        this.selectedTender = resp['data'][0];
      } else {
        this.selectedTender = {}
      }
      this.obj = Object.assign({}, this.selectedTender);
      console.log(this.selectedTender)
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Tender Info", "error");
    }
  }

  billInfo:any = []
  lastbill:any = {}
  prevbillpartrate:any = {}
  async getBillInfo() {
    this.lastbill = {}
    let obj:any = {}
    if (this.selectedTender['tender_id'] == null || this.selectedTender['tender_id'] == undefined) {
      this.datasource = new MatTableDataSource([]);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      return;
    }
    obj['tender_id'] = this.selectedTender['tender_id']
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    console.log(obj)
    this.spinner.show();
    var resp = await this.ebillService.getBill(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.billInfo = resp['data'];
      if(resp['data'][0] != undefined) this.lastbill = resp['data'][0]
      this.obj['bill_amt'] = 0;
      for (var i = 0; i < this.billInfo.length; i++) {
        this.obj['bill_amt'] += this.billInfo[i]['bill_amt'];
      }
      this.obj['remaining_amt'] = this.selectedTender['amount'] - this.obj['bill_amt'];
      if (this.billInfo.length > 0) {
        this.obj['prev_bill_no'] = this.billInfo[0]['bill_no'];

        for (let j = 0; j < this.billInfo[0].length; j++) {
          this.prevbillpartrate[this.billInfo[0][j]['sno']] = this.billInfo[0][j]['rate']

        }
        if (this.obj['prev_bill_no'] != null) {
          this.obj['prev_bill_no'] = this.billInfo[0]['bill_no'];
        } else {
          this.obj['prev_bill_no'] = 0
        }
      } else {
        this.obj['prev_bill_no'] = 0
      }
      let temp = this.obj['prev_bill_no'] + 1;
      this.tt = temp;
      this.obj['bill_desc'] = temp + " bill of " + this.partyObj[this.obj['party_id']] + " for " + this.obj['work_order_name'];
      this.spinner.hide();


      console.log(resp.data)
     resp.data.map((x:any) => {
      x['tot_gst']=x['bill_amt']+x['cgst']+x['sgst']+x['igst']

      x['tot_pay']=x['tot_gst']+x['add_with_held']+x['add_security']
      x['net_pay']=x['tot_pay']-x['ded_amt']-x['labuor_cess']
     })


      this.datasource = new MatTableDataSource(resp['data']);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }
    else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting Bill Info", "error");
    }

    console.log("End getBillInfo()")

  }

  ChangeEBillNumber() {
    this.obj['bill_desc'] = this.e_bill_no + " bill of " + this.partyObj[this.obj['party_id']] + " for " + this.obj['work_order_name'];
  }


  e_bill_no = 1;
  tt = 0;
  embData:any = [];
  max_emb_no = 0;
  overunderobj:any = {}
  pregstobb:any = {}
//   async getEmbForBill() {
//     let obj = {}
//     this.max_emb_no = 0;
//     this.embData = []
//     this.spinner.show()
//     obj['tender_id'] = this.selectedTender['tender_id']
//     obj['b_acct_id'] = this.ebillUser.b_acct_id;
//     if (this.billInfo.length > 0) {
//       let emb_no = 0;
//       for (var i = 0; i < this.billInfo.length; i++) {
//         if (emb_no < this.billInfo[i]['emb_no']) {
//           emb_no = this.billInfo[i]['emb_no'];
//         }
//       }
//       obj['emb_no'] = emb_no;
//     } else {
//       obj['emb_no'] = 0
//     }
//     var resp = await this.embService.getEmbForBill(JSON.stringify(obj));
//     let total = 0
//     let overundertotal = 0
//     let overunderpertotal = 0
//     if (resp['error'] == false) {
//       console.log(this.prevbillpartrate)
//       var objPay = {}
//       console.log(this.prevbillquantity,resp['data'])
//       for (let j = 0; j < this.boqItems.length; j++) {
//         var objPay = {}
//         this.overunderobj[this.boqItems[j]['sno']] = { over_under_per: this.boqItems[j]['over_under_per'], over_under: this.boqItems[j]['over_under'] }
//         objPay['line_desc'] = this.boqItems[j]['item_desc']
//         objPay['sno'] = this.boqItems[j]['sno']
//         objPay['unit'] = this.boqItems[j]['unit']
//         objPay['eff_rate'] = this.boqItems[j]['eff_rate']
//         objPay['eff_quantity'] = this.boqItems[j]['eff_quantity']
//         objPay['already_measured'] = this.boqItems[j]['already_measured']
//         if (this.prevbillpartrate[this.boqItems[j]['sno']] == undefined || this.prevbillpartrate[this.boqItems[j]['sno']] == null) {
//           this.prevbillpartrate[this.boqItems[j]['sno']] = this.boqItems[j]['part_rate']
//         }
//         // if(this.prevbillpartrate[this.boqItems[j]['sno']] > this.boqItems[j]['part_rate']){
//         objPay['rate'] = this.prevbillpartrate[this.boqItems[j]['sno']]
//         /*  }else{
//            objPay['rate'] = this.boqItems[j]['part_rate']
//          } */

//         objPay['eff_amt'] = this.boqItems[j]['eff_amt']
//         objPay['quantity'] = 0;
//         objPay['amount'] = 0;
//         objPay['is_pre_gst'] = this.boqItems[j]['is_pre_gst'];
//         this.pregstobb[this.boqItems[j]['sno']] = this.boqItems[j]['is_pre_gst'];
//         for (let i = 0; i < resp['data'].length; i++) {
//           if (this.max_emb_no < resp['data'][i]['emb_no']) {
//             this.max_emb_no = resp['data'][i]['emb_no'];
//           }
//           if (this.boqItems[j]['sno'] == resp['data'][i]['sno']) {
//             // objPay['rate'] = resp['data'][i]['rate']
//             objPay['quantity'] += resp['data'][i]['quantity']



//           }
//         }

//         //objPay['quantity'] =  objPay['quantity'] + this.dev_emb[this.boqItems[j]['sno']]
//  objPay['quantity'] = Number((objPay['quantity'] + this.dev_emb[this.boqItems[j]['sno']]).toFixed(3))
//         if (this.prevbillquantity[this.boqItems[j]['sno']] == undefined || this.prevbillquantity[this.boqItems[j]['sno']] == null) {
//           this.prevbillquantity[this.boqItems[j]['sno']] = 0
//         }
//         if(this.boqItems[j]['eff_quantity'] >= 0){
//         if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity'] && this.boqItems[j]['over_under_per'] == -1) {

//           objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['prevquantity'] = Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//           //objPay['quantity'] += resp['data'][i]['quantity']
//           //total = total + resp['data'][i]['amount'];
//           total = total + objPay['amount'];
//         } else if (this.boqItems[j]['over_under_per'] == -1) {
//           objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//           objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//           objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));
//           total = total + objPay['amount'];

//         } else {
//           if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity']) {
//             objPay['uptodatequantity'] = objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//             objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//             objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))

//             //objPay['quantity'] += resp['data'][i]['quantity']
//             if (this.boqItems[j]['over_under'] == 'LS') {
//               overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
//             }
//             if (this.boqItems[j]['over_under'] == 'EXC') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             } if (this.boqItems[j]['over_under'] == 'ATPR') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             }
//           } else {
//             objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//             objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//             objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//             objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));

//             if (this.boqItems[j]['over_under'] == 'LS') {
//               overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
//             }
//             if (this.boqItems[j]['over_under'] == 'EXC') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             } if (this.boqItems[j]['over_under'] == 'ATPR') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             }
//           }




//         }
//       }else{
//         objPay['quantity'] = (-1)*objPay['quantity']
//         this.prevbillquantity[this.boqItems[j]['sno']] = (-1)*this.prevbillquantity[this.boqItems[j]['sno']]
//         objPay['eff_quantity'] = (-1)*objPay['eff_quantity']
//         this.boqItems[j]['already_measured'] = (-1)*this.boqItems[j]['already_measured']
//         if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity'] && this.boqItems[j]['over_under_per'] == -1) {

//           objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['prevquantity'] = Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//           //objPay['quantity'] += resp['data'][i]['quantity']
//           //total = total + resp['data'][i]['amount'];
//           total = total + objPay['amount'];
//         } else if (this.boqItems[j]['over_under_per'] == -1) {
//           objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//           objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//           objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//           objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//           objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));
//           total = total + objPay['amount'];

//         } else {
//           if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity']) {
//             objPay['uptodatequantity'] = objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//             objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//             objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//             //objPay['quantity'] += resp['data'][i]['quantity']
//             if (this.boqItems[j]['over_under'] == 'LS') {
//               overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
//             }
//             if (this.boqItems[j]['over_under'] == 'EXC') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             } if (this.boqItems[j]['over_under'] == 'ATPR') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             }
//           } else {
//             objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
//             objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

//             objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
//             objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
//             objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));

//             if (this.boqItems[j]['over_under'] == 'LS') {
//               overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
//             }
//             if (this.boqItems[j]['over_under'] == 'EXC') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             } if (this.boqItems[j]['over_under'] == 'ATPR') {
//               overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//             }
//           }




//         }
//         objPay['quantity'] = (-1)*objPay['quantity']
//         objPay['uptodatequantity'] = (-1)* objPay['uptodatequantity']
//         objPay['prevquantity'] = (-1)* objPay['prevquantity']
//         objPay['uptodateamount'] = (-1)*objPay['uptodateamount']
//         objPay['prevbillamount']  = (-1)* objPay['prevbillamount']
//         objPay['amount'] = (-1)* objPay['amount']
//         this.prevbillquantity[this.boqItems[j]['sno']] = (-1)*this.prevbillquantity[this.boqItems[j]['sno']]
//         objPay['eff_quantity'] = (-1)*objPay['eff_quantity']
//         this.boqItems[j]['already_measured'] = (-1)*this.boqItems[j]['already_measured']
//       }
//         overundertotal = overundertotal + objPay['amount']


//         this.embData.push(objPay);
//       }

//       this.obj['total_bill_amt'] = Number(total.toFixed(2));
//       this.obj['subtotal'] = this.obj['total_bill_amt'];
//       if (this.selectedTender['over_under'] == 'LS') {
//         this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
//       }
//       if (this.selectedTender['over_under'] == 'EXC') {
//         this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

//       } if (this.selectedTender['over_under'] == 'ATPR') {
//         this.obj['showTotal'] = Number((overundertotal).toFixed(2))
//       }
//       this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
//       this.obj['subtotal'] = this.obj['total_bill_amt'];
//       this.obj['labuor_cess'] = 0
//       this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
//       this.obj['overunderamt'] = this.obj['showTotal']
//       if (this.selectedTender['discount'] != 0) {
//         this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
//       }
//       this.obj['subtotal1'] = this.obj['showTotal'];
//       if(this.selectedTender['labour_cess_flag'] == 1)  this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal'] ) / 100 ).toFixed(0))

//       //this.obj['showTotal'] = this.obj['total_bill_amt'];
//       this.spinner.hide();
//       this.Pregst()
//       console.log(this.embData)
//       this.datasource1 = new MatTableDataSource(this.embData);
//       this.datasource1.sort = this.sort;
//       this.datasource1.paginator = this.paginator1;

//       // -------------part rate

//       let dummy = resp['data']
//       for (let i = 0; i < dummy.length; i++) {
//         for (let j = 0; j < this.boqItems.length; j++) {
//           if (dummy[i]['rate'] < this.boqItems[j]['rate'] && this.boqItems[j]['sno'] == dummy['sno']) {
//             dummy[i]['rate'] = this.boqItems[j]['rate'] - dummy[i]['rate']
//             this.part_data.push(dummy[i])
//           }
//         }
//       }
//       /* this.datasource2 = new MatTableDataSource(this.part_data);
//       this.datasource2.sort = this.sortCol3;
//       this.datasource2.paginator = this.paginator2; */
//       // -------------part rate
//     }
//     else {
//       this.spinner.hide();
//       Swal.fire("Error", "Error while getting EMB Items", "error");
//     }
//   }

async getEmbForBill() {
  let obj:any = {}
  this.max_emb_no = 0;
  this.embData = []
  this.spinner.show()
  obj['tender_id'] = this.selectedTender['tender_id']
  obj['b_acct_id'] = this.ebillUser.b_acct_id;
  if (this.billInfo.length > 0) {
    let emb_no = 0;
    for (var i = 0; i < this.billInfo.length; i++) {
      if (emb_no < this.billInfo[i]['emb_no']) {
        emb_no = this.billInfo[i]['emb_no'];
      }
    }
    obj['emb_no'] = emb_no;
  } else {
    obj['emb_no'] = 0
  }
  console.log(obj['emb_no'],"obj")
  var resp = await this.embService.getEmbForBill(JSON.stringify(obj));
  let total = 0
  let overundertotal = 0
  let overunderpertotal = 0
  if (resp['error'] == false) {
    console.log(this.prevbillpartrate)
    var objPay = {}
    console.log(this.prevbillquantity, resp['data'])
    for (let j = 0; j < this.boqItems.length; j++) {
      let objPay:any = {}
      this.overunderobj[this.boqItems[j]['sno']] = { over_under_per: this.boqItems[j]['over_under_per'], over_under: this.boqItems[j]['over_under'] }
      objPay['line_desc'] = this.boqItems[j]['item_desc']
      objPay['sno'] = this.boqItems[j]['sno']
      objPay['unit'] = this.boqItems[j]['unit']
      objPay['eff_rate'] = this.boqItems[j]['eff_rate']
      objPay['eff_quantity'] = this.boqItems[j]['eff_quantity']
      objPay['already_measured'] = this.boqItems[j]['already_measured']
      if (this.prevbillpartrate[this.boqItems[j]['sno']] == undefined || this.prevbillpartrate[this.boqItems[j]['sno']] == null) {
        this.prevbillpartrate[this.boqItems[j]['sno']] = this.boqItems[j]['part_rate']
      }
      // if(this.prevbillpartrate[this.boqItems[j]['sno']] > this.boqItems[j]['part_rate']){
      objPay['rate'] = this.prevbillpartrate[this.boqItems[j]['sno']]
      /*  }else{
         objPay['rate'] = this.boqItems[j]['part_rate']
       } */
      objPay['eff_amt'] = this.boqItems[j]['eff_amt']
      objPay['quantity'] = 0;
      objPay['amount'] = 0;
      objPay['is_pre_gst'] = this.boqItems[j]['is_pre_gst'];
      this.pregstobb[this.boqItems[j]['sno']] = this.boqItems[j]['is_pre_gst'];
      for (let i = 0; i < resp['data'].length; i++) {
        if (this.max_emb_no < resp['data'][i]['emb_no']) {
          this.max_emb_no = resp['data'][i]['emb_no'];
        }
        if (this.boqItems[j]['sno'] == resp['data'][i]['sno']) {
          // objPay['rate'] = resp['data'][i]['rate']
          objPay['quantity'] += resp['data'][i]['quantity']
        }
      }
      console.log(objPay['quantity'])
      console.log(this.dev_emb[this.boqItems[j]['sno']])
        // if((this.dev_emb[this.boqItems[j]['sno']]).toFixed(3) == - 0.001) this.dev_emb[this.boqItems[j]['sno']] = 0
        objPay['quantity'] = Number((objPay['quantity'] + this.dev_emb[this.boqItems[j]['sno']]).toFixed(3))
        console.log(Number(objPay['quantity']), this.prevbillquantity[this.boqItems[j]['sno']])
        console.log((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])))

        if (this.prevbillquantity[this.boqItems[j]['sno']] == undefined || this.prevbillquantity[this.boqItems[j]['sno']] == null) {
          this.prevbillquantity[this.boqItems[j]['sno']] = 0
        }
        if (this.boqItems[j]['eff_quantity'] + this.boqItems[j]['already_measured'] >= 0) {
          if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity'] && this.boqItems[j]['over_under_per'] == -1) {

            objPay['uptodatequantity'] = Number((Number(objPay['quantity']) + Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))
            objPay['prevquantity'] = Number((Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))
            objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
            objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
            objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
            //objPay['quantity'] += resp['data'][i]['quantity']
              //total = total + resp['data'][i]['amount'];
              total = total + objPay['amount'];
            } else if (this.boqItems[j]['over_under_per'] == -1) {
              objPay['uptodatequantity'] = Number((objPay['eff_quantity'] + this.boqItems[j]['already_measured']).toFixed(3)) //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
              objPay['prevquantity'] = Number((this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
              objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));
              total = total + objPay['amount'];
            } else {
              if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity']) {
                console.log((objPay['quantity'] + this.boqItems[j]['already_measured'] + Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3), objPay['quantity'], this.boqItems[j]['already_measured'], (this.prevbillquantity[this.boqItems[j]['sno']]))
                objPay['uptodatequantity'] = Number((objPay['quantity'] + this.boqItems[j]['already_measured'] + Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))
                objPay['prevquantity'] = Number((this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))

                objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
                //objPay['quantity'] += resp['data'][i]['quantity']
                console.log(objPay)
                if (this.boqItems[j]['over_under'] == 'LS') {
                  overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
                }
                if (this.boqItems[j]['over_under'] == 'EXC') {
                  overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
                } if (this.boqItems[j]['over_under'] == 'ATPR') {
                  overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
                }
              } else {
                objPay['uptodatequantity'] = Number((objPay['eff_quantity'] + this.boqItems[j]['already_measured']).toFixed(3)) //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
                objPay['prevquantity'] = Number((this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))

                objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
                objPay['quantity'] = Number((Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3))).toFixed(3));

                if (this.boqItems[j]['over_under'] == 'LS') {
                  overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
                }
                if (this.boqItems[j]['over_under'] == 'EXC') {
                  overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

                } if (this.boqItems[j]['over_under'] == 'ATPR') {
                  overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

                }
              } }
            } else {
              objPay['quantity'] = (-1) * objPay['quantity']
              this.prevbillquantity[this.boqItems[j]['sno']] = (-1) * this.prevbillquantity[this.boqItems[j]['sno']]
              objPay['eff_quantity'] = (-1) * objPay['eff_quantity']
              this.boqItems[j]['already_measured'] = (-1) * this.boqItems[j]['already_measured']
              if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity'] && this.boqItems[j]['over_under_per'] == -1) {

                objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
                objPay['prevquantity'] = Number(this.boqItems[j]['already_measured']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])
                objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
                objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
                 //total = total + resp['data'][i]['amount'];
            total = total - objPay['amount'];
          } else if (this.boqItems[j]['over_under_per'] == -1) {
            objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
            objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

            objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
            objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
            objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
            objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));
            total = total - objPay['amount'];
          } else {
            if ((Number(objPay['quantity']) + Number(this.prevbillquantity[this.boqItems[j]['sno']])) <= objPay['eff_quantity']) {
              objPay['uptodatequantity'] = objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
              objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
              //objPay['quantity'] += resp['data'][i]['quantity']
              if (this.boqItems[j]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.boqItems[j]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              } if (this.boqItems[j]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              }
            } else {
              objPay['uptodatequantity'] = objPay['eff_quantity'] + this.boqItems[j]['already_measured'] //objPay['quantity'] + this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])
              objPay['prevquantity'] = this.boqItems[j]['already_measured'] + (this.prevbillquantity[this.boqItems[j]['sno']])

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[this.boqItems[j]['sno']]).toFixed(2))).toFixed(2))
              objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[this.boqItems[j]['sno']])).toFixed(3));

              if (this.boqItems[j]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.boqItems[j]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              } if (this.boqItems[j]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.boqItems[j]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              }
            }
          }
          objPay['quantity'] = (-1) * objPay['quantity']
          objPay['uptodatequantity'] = (-1) * objPay['uptodatequantity']
          objPay['prevquantity'] = (-1) * objPay['prevquantity']
          objPay['uptodateamount'] = (-1) * objPay['uptodateamount']
          objPay['prevbillamount'] = (-1) * objPay['prevbillamount']
          objPay['amount'] = (-1) * objPay['amount']
          this.prevbillquantity[this.boqItems[j]['sno']] = (-1) * this.prevbillquantity[this.boqItems[j]['sno']]
          objPay['eff_quantity'] = (-1) * objPay['eff_quantity']
          this.boqItems[j]['already_measured'] = (-1) * this.boqItems[j]['already_measured']
        }  overundertotal = overundertotal + objPay['amount']


        this.embData.push(objPay);
      }
      this.obj['total_bill_amt'] = Number(total.toFixed(2));
      this.obj['subtotal'] = this.obj['total_bill_amt'];
      if (this.selectedTender['over_under'] == 'LS') {
        this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
      }
      if (this.selectedTender['over_under'] == 'EXC') {
        this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
      } if (this.selectedTender['over_under'] == 'ATPR') {
        this.obj['showTotal'] = Number((overundertotal).toFixed(2))
      }
      this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
      this.obj['subtotal'] = this.obj['total_bill_amt'];
      this.obj['labuor_cess'] = 0
      this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
      this.obj['overunderamt'] = this.obj['showTotal']
      if (this.selectedTender['discount'] != 0) {
        this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
      }
      this.obj['subtotal1'] = this.obj['showTotal'];
      if (this.selectedTender['labour_cess_flag'] == 1) this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal']) / 100).toFixed(0))

      //this.obj['showTotal'] = this.obj['total_bill_amt'];
      this.spinner.hide();
      this.Pregst()
      console.log(this.embData)
      this.datasource1 = new MatTableDataSource(this.embData);
      this.datasource1.sort = this.sort;
      this.datasource1.paginator = this.paginator1;
      // -------------part rate
      let dummy:any = resp['data']
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.boqItems.length; j++) {
          if (dummy[i]['rate'] < this.boqItems[j]['rate'] && this.boqItems[j]['sno'] == dummy['sno']) {
            dummy[i]['rate'] = this.boqItems[j]['rate'] - dummy[i]['rate']
            this.part_data.push(dummy[i])
          }
        }
      }
      /* this.datasource2 = new MatTableDataSource(this.part_data);
      this.datasource2.sort = this.sortCol3;
      this.datasource2.paginator = this.paginator2; */
      // -------------part rate
    }
    else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting EMB Items", "error");
    }
  }




  // changeRate(element) {
  //   let total = 0
  //   let overundertotal = 0
  //   let overunderpertotal = 0
  //   var arr = []
  //   for (let i = 0; i < this.embData.length; i++) {
  //     var objPay = Object.assign({}, this.embData[i])


  //     if (this.prevbillpartrate[objPay['sno']] > objPay['rate'] && objPay['prevquantity'] != 0) {
  //       Swal.fire("Warning", "Rate Should be Greater than Previous Part Rate", "warning");
  //       objPay['rate'] = this.prevbillpartrate[objPay['sno']]
  //     }
  //     console.log(objPay['eff_rate'])
  //     console.log(objPay['rate'])
  //     if (objPay['eff_rate'] < objPay['rate']) {
  //       Swal.fire("Warning", "Rate Should be Less than and Equals To Work Order Rate", "warning");
  //       objPay['rate'] = this.prevbillpartrate[objPay['sno']]
  //     }

  //     if(objPay['eff_quantity'] >= 0){
  //     if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity'] && this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
  //       objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
  //       objPay['prevquantity'] = Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
  //       objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //       objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //       objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //       //objPay['quantity'] += resp['data'][i]['quantity']
  //       //total = total + resp['data'][i]['amount'];
  //       total = total + objPay['amount'];
  //     } else if (this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
  //       objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured'])//objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //       objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //       objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //       objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //       objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //       objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
  //       total = total + objPay['amount'];

  //     } else {
  //       if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity']) {
  //         objPay['uptodatequantity'] = objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //         objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //         objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //         objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //         objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //         //objPay['quantity'] += resp['data'][i]['quantity']
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
  //           overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         }
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         }
  //       } else {
  //         objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured']) //objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //         objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //         objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //         objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //         objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //         objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
  //           overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         }
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         }
  //       }




  //     }
  //   }else{
  //     objPay['quantity'] = (-1)*objPay['quantity']
  //     this.prevbillquantity[objPay['sno']] = (-1)*this.prevbillquantity[objPay['sno']]
  //     objPay['eff_quantity'] = (-1)*objPay['eff_quantity']
  //     objPay['already_measured'] = (-1)*objPay['already_measured']
  //     if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity'] && this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
  //       objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
  //       objPay['prevquantity'] = Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
  //       objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //       objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //       objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //       //objPay['quantity'] += resp['data'][i]['quantity']
  //       //total = total + resp['data'][i]['amount'];
  //       total = total + objPay['amount'];
  //     } else if (this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
  //       objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured'])//objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //       objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //       objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //       objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //       objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //       objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
  //       total = total + objPay['amount'];

  //     } else {
  //       if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity']) {
  //         objPay['uptodatequantity'] = objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //         objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //         objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //         objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //         objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //         //objPay['quantity'] += resp['data'][i]['quantity']
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
  //           overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         }
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         }
  //       } else {
  //         objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured']) //objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
  //         objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

  //         objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
  //         objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
  //         objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
  //         objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
  //           overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         }
  //         if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
  //           overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //         }
  //       }




  //     }
  //     objPay['uptodatequantity'] = (-1)* objPay['uptodatequantity']
  //       objPay['prevquantity'] = (-1)* objPay['prevquantity']
  //       objPay['uptodateamount'] = (-1)*objPay['uptodateamount']
  //       objPay['prevbillamount']  = (-1)* objPay['prevbillamount']
  //       objPay['amount'] = (-1)* objPay['amount']



  //     objPay['quantity'] = (-1)*objPay['quantity']
  //     this.prevbillquantity[objPay['sno']] = (-1)*this.prevbillquantity[objPay['sno']]
  //     objPay['eff_quantity'] = (-1)*objPay['eff_quantity']
  //     objPay['already_measured'] = (-1)*objPay['already_measured']
  //   }
  //     overundertotal = overundertotal + objPay['amount']
  //     arr.push(objPay)
  //   }

  //   this.obj['total_bill_amt'] = Number(total.toFixed(2));
  //   this.obj['subtotal'] = this.obj['total_bill_amt'];

  //   if (this.selectedTender['over_under'] == 'LS') {
  //     console.log(this.selectedTender, this.obj, this.obj['subtotal'], total)
  //     this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //   }
  //   if (this.selectedTender['over_under'] == 'EXC') {
  //     this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

  //   } if (this.selectedTender['over_under'] == 'ATPR') {
  //     this.obj['showTotal'] = Number((overundertotal).toFixed(2))
  //   }
  //   console.log(this.obj['showTotal'])
  //   this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
  //   this.obj['subtotal'] = this.obj['total_bill_amt'];

  //   this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
  //   this.obj['overunderamt'] = this.obj['showTotal']
  //   if (this.selectedTender['discount'] != 0) {
  //     this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
  //   }
  //   this.obj['subtotal1'] = this.obj['showTotal'];
  //   if(this.selectedTender['labour_cess_flag'] == 1)  this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal'] ) / 100 ).toFixed(0))

  //   this.changetemp_withheld()

  //   this.embData = arr
  //   this.Pregst
  //   this.datasource1 = new MatTableDataSource(this.embData);
  //   this.datasource1.sort = this.sort;
  //   this.datasource1.paginator = this.paginator1;
  // }
  changeRate(element:any) {
    let total = 0
    let overundertotal = 0
    let overunderpertotal = 0
    var arr = []
    for (let i = 0; i < this.embData.length; i++) {
      var objPay = Object.assign({}, this.embData[i])
      if (this.prevbillpartrate[objPay['sno']] > objPay['rate'] && objPay['prevquantity'] != 0) {
        Swal.fire("Warning", "Rate Should be Greater than Previous Part Rate", "warning");
        objPay['rate'] = this.prevbillpartrate[objPay['sno']]
      }
      console.log(objPay['eff_rate'])
      console.log(objPay['rate'])
      if (objPay['eff_rate'] < objPay['rate']) {
        Swal.fire("Warning", "Rate Should be Less than and Equals To Work Order Rate", "warning");
        objPay['rate'] = this.prevbillpartrate[objPay['sno']]
      }
      if (objPay['eff_quantity'] >= 0) {
        if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity'] && this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
          objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
          objPay['prevquantity'] = Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
          objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
          objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
          objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
          //objPay['quantity'] += resp['data'][i]['quantity']
            //total = total + resp['data'][i]['amount'];
            total = total + objPay['amount'];
          } else if (this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
            objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured'])//objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
            objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

            objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
            objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
            objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
            objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
            total = total + objPay['amount'];
          } else {
            if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity']) {
              objPay['uptodatequantity'] = objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
              objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
              //objPay['quantity'] += resp['data'][i]['quantity']
              if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              }
            } else {
              objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured']) //objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
              objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
              objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
              if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number((objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
            }

        }
      } else {
        objPay['quantity'] = (-1) * objPay['quantity']
        this.prevbillquantity[objPay['sno']] = (-1) * this.prevbillquantity[objPay['sno']]
        objPay['eff_quantity'] = (-1) * objPay['eff_quantity']
        objPay['already_measured'] = (-1) * objPay['already_measured']
        if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity'] && this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
          objPay['uptodatequantity'] = Number(objPay['quantity']) + Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
          objPay['prevquantity'] = Number(objPay['already_measured']) + Number(this.prevbillquantity[objPay['sno']])
          objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
          objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
          objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
          //objPay['quantity'] += resp['data'][i]['quantity']
            //total = total + resp['data'][i]['amount'];
            total = total - objPay['amount'];
          } else if (this.overunderobj[objPay['sno']]['over_under_per'] == -1) {
            objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured'])//objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
            objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

            objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
            objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
            objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
            objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
            total = total - objPay['amount'];

          } else {
            if ((Number(objPay['quantity']) + Number(this.prevbillquantity[objPay['sno']])) <= objPay['eff_quantity']) {
              objPay['uptodatequantity'] = objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
              objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
              //objPay['quantity'] += resp['data'][i]['quantity']
              if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              }
            } else {
              objPay['uptodatequantity'] = objPay['eff_quantity'] + Number(objPay['already_measured']) //objPay['quantity'] + objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])
              objPay['prevquantity'] = objPay['already_measured'] + (this.prevbillquantity[objPay['sno']])

              objPay['uptodateamount'] = Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2))
              objPay['prevbillamount'] = Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))
              objPay['amount'] = Number((Number((objPay['uptodatequantity'] * objPay['rate']).toFixed(2)) - Number((objPay['prevquantity'] * this.prevbillpartrate[objPay['sno']]).toFixed(2))).toFixed(2))
              objPay['quantity'] = Number((objPay['eff_quantity'] - Number(this.prevbillquantity[objPay['sno']])).toFixed(3));
              if (this.overunderobj[objPay['sno']]['over_under'] == 'LS') {
                overunderpertotal = Number((overunderpertotal - (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
              }
              if (this.overunderobj[objPay['sno']]['over_under'] == 'EXC') {
                overunderpertotal = Number((overunderpertotal + (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              } if (this.overunderobj[objPay['sno']]['over_under'] == 'ATPR') {
                overunderpertotal = Number((overunderpertotal + (Number((Number(((-1) * objPay['amount']).toFixed(2)) * this.overunderobj[objPay['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

              }
            }
          }
          objPay['uptodatequantity'] = (-1) * objPay['uptodatequantity']
          objPay['prevquantity'] = (-1) * objPay['prevquantity']
          objPay['uptodateamount'] = (-1) * objPay['uptodateamount']
          objPay['prevbillamount'] = (-1) * objPay['prevbillamount']
          objPay['amount'] = (-1) * objPay['amount']



          objPay['quantity'] = (-1) * objPay['quantity']
          this.prevbillquantity[objPay['sno']] = (-1) * this.prevbillquantity[objPay['sno']]
          objPay['eff_quantity'] = (-1) * objPay['eff_quantity']
          objPay['already_measured'] = (-1) * objPay['already_measured']

        }
          overundertotal = overundertotal + objPay['amount']
      arr.push(objPay)
    }
    this.obj['total_bill_amt'] = Number(total.toFixed(2));
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    if (this.selectedTender['over_under'] == 'LS') {
      console.log(this.selectedTender, this.obj, this.obj['subtotal'], total)
      this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
    }
    if (this.selectedTender['over_under'] == 'EXC') {
      this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
    } if (this.selectedTender['over_under'] == 'ATPR') {
      this.obj['showTotal'] = Number((overundertotal).toFixed(2))
    }
    console.log(this.obj['showTotal'])
    this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
    this.obj['overunderamt'] = this.obj['showTotal']
    if (this.selectedTender['discount'] != 0) {
      this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
    }
    this.obj['subtotal1'] = this.obj['showTotal'];
    if (this.selectedTender['labour_cess_flag'] == 1) this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal']) / 100).toFixed(0))
    this.changetemp_withheld()
    this.embData = arr
    this.Pregst
    this.datasource1 = new MatTableDataSource(this.embData);
    this.datasource1.sort = this.sort;
    this.datasource1.paginator = this.paginator1;
  }

  changePreGST() {
    this.obj['showTotal'] = Number((this.obj['amt_after_withheld'] - (this.obj['amt_after_withheld'] * this.obj['pre_gst'] / (100 + this.obj['pre_gst']))).toFixed(2))
    // this.obj['showTotal'] = Number((this.obj['subtotal1'] - (this.obj['total_pregst'] * this.obj['pre_gst'] / (100 + this.obj['pre_gst']))).toFixed(2))
    this.obj['pre_gst_amt'] = Number((this.obj['amt_after_withheld'] * this.obj['pre_gst'] / (100 + this.obj['pre_gst'])).toFixed(2))
  }
  changePreGSTamt() {
    this.obj['showTotal'] = Number((this.obj['amt_after_withheld'] - this.obj['pre_gst_amt']).toFixed(2))
    // this.obj['showTotal'] = Number((this.obj['subtotal1'] - (this.obj['total_pregst'] * this.obj['pre_gst'] / (100 + this.obj['pre_gst']))).toFixed(2))
    // this.obj['pre_gst_amt'] = Number((this.obj['amt_after_withheld'] * this.obj['pre_gst'] / (100 + this.obj['pre_gst'])).toFixed(2))
  }
  allEbills = []


  check() {

    if (this.obj['allowance_igst_percentage'] == 0 || this.obj['allowance_igst_percentage'] == null) {
      if (this.obj['allowance_cgst_percentage'] != null) {
        this.obj['cgst_amount'] = (this.obj['showTotal'] * this.obj['allowance_cgst_percentage']) / 100
        this.obj['cgst_amount'] = Number(this.obj['cgst_amount'].toFixed(2));
      } else {
        this.obj['cgst_amount'] = 0

      }
      if (this.obj['allowance_sgst_percentage'] != null) {
        // this.obj['sgst_amount']=this.obj['allowance_sgst_percentage']*this.obj['total_bill_amt']
        this.obj['sgst_amount'] = (this.obj['showTotal'] * this.obj['allowance_sgst_percentage']) / 100
        this.obj['sgst_amount'] = Number(this.obj['sgst_amount'].toFixed(2))
      } else {
        this.obj['sgst_amount'] = 0
      }
      this.obj['igst_amount'] = 0;
      this.obj['allowance_igst_percentage'] = 0;

    } else {
      if (this.obj['allowance_igst_percentage'] != null) {
        // this.obj['sgst_amount']=this.obj['allowance_sgst_percentage']*this.obj['total_bill_amt']
        this.obj['igst_amount'] = (this.obj['showTotal'] * this.obj['allowance_igst_percentage']) / 100
        this.obj['igst_amount'] = Number(this.obj['igst_amount'].toFixed(2))
      } else {
        this.obj['igst_amount'] = 0
      }
      this.obj['cgst_amount'] = 0;
      this.obj['sgst_amount'] = 0;
      this.obj['allowance_cgst_percentage'] = 0;
      this.obj['allowance_sgst_percentage'] = 0;

    }




    //let dedAmt = this.obj['total_ded_amount']
    this.obj['bill_with_gst_amt'] = Number((Number(this.obj['showTotal']) + Number(this.obj['cgst_amount']) + Number(this.obj['sgst_amount']) + Number(this.obj['igst_amount'])).toFixed(2));
    this.obj['total_payable'] = this.obj['bill_with_gst_amt'];
    this.changeWithHeld()
    // this.obj['bill_amount'] = Number(amt.toFixed(2));
    // this.obj['actual_amount'] = this.obj['total_bill_amt'];
    // this.obj['final_amount'] = Number(this.obj['sgst_amount']) + Number(this.obj['cgst_amount']) + Number(this.obj['total_bill_amt'])
    //this.obj['final_amount'] = this.obj['final_amount'].toFixed(2)
  }
  checkgstamt() {

    if (this.obj['allowance_igst_percentage'] == 0 || this.obj['allowance_igst_percentage'] == null || this.obj['igst_amount'] == 0 || this.obj['igst_amount'] == null) {


      this.obj['igst_amount'] = 0;
      this.obj['allowance_igst_percentage'] = 0;

    } else {

      this.obj['cgst_amount'] = 0;
      this.obj['sgst_amount'] = 0;
      this.obj['allowance_cgst_percentage'] = 0;
      this.obj['allowance_sgst_percentage'] = 0;

    }




    //let dedAmt = this.obj['total_ded_amount']
    this.obj['bill_with_gst_amt'] = Number((Number(this.obj['showTotal']) + Number(this.obj['cgst_amount']) + Number(this.obj['sgst_amount']) + Number(this.obj['igst_amount'])).toFixed(2));
    this.obj['total_payable'] = this.obj['bill_with_gst_amt'];
    this.changeWithHeld()
    // this.obj['bill_amount'] = Number(amt.toFixed(2));
    // this.obj['actual_amount'] = this.obj['total_bill_amt'];
    // this.obj['final_amount'] = Number(this.obj['sgst_amount']) + Number(this.obj['cgst_amount']) + Number(this.obj['total_bill_amt'])
    //this.obj['final_amount'] = this.obj['final_amount'].toFixed(2)
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }
  applyFilter3(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }

  dedObj:any = {}

  async getAllDed() {
    this.spinner.show();

    var resp = await this.ebillService.getded(this.b_acct_id);
    if (resp['error'] == false) {
      this.dedTypeArr = resp.data;
      console.log(this.dedTypeArr)
      for (var i = 0; i < this.dedTypeArr.length; i++) {
        this.dedObj[this.dedTypeArr[i]['deduction_id']] = this.dedTypeArr[i];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting all deduction list", "error");
    }
  }

  addDefaultDed() {
    this.obj['total_ded_amount'] = 0;
    this.obj['with_held_amt'] = 0
    this.obj['deduction'] = [];
    console.log(this.selectedTender)
    if(this.selectedTender['labour_cess_flag'] == 1 && this.selectedTender['labour_cess_flag'] != null){
    this.dedObj[-1] = {}
    this.dedObj[-1]['ded_name'] = 'LABOUR CESS'
    this.obj['deduction'].push({
      id: -1,
      bill_id: -1,
      amt: Number(this.obj['labuor_cess'].toFixed(0)),
      apply_on: 'B',
      rate: this.selectedTender['labour_cess'],
      type: 'PERCENTAGE ',
      deduction_id: -1,
      sno: -1
    })
    this.obj['total_ded_amount'] += Number(this.obj['labuor_cess'].toFixed(0));
  }
    for (var i = 0; i < this.dedTypeArr.length; i++) {
      if (this.dedTypeArr[i]['ded_type'] == 'FIXED') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number(this.dedTypeArr[i]['ded_amt'].toFixed(0)),
          apply_on: '',
          rate: 0.00,
          type: 'FIXED',
          deduction_id: this.dedTypeArr[i]['deduction_id'],
          sno: -1
        })
        this.obj['total_ded_amount'] += Number(this.dedTypeArr[i]['ded_amt'].toFixed(0));
      } else if (this.dedTypeArr[i]['ded_type'] == 'PERCENTAGE') {
        if (this.dedTypeArr[i]['apply_on'] == 'B') {
          this.obj['deduction'].push({
            id: -1,
            bill_id: -1,
            amt: Number((this.obj['showTotal'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0)),
            apply_on: 'B',
            rate: this.dedTypeArr[i]['ded_amt'],
            type: 'PERCENTAGE',
            deduction_id: this.dedTypeArr[i]['deduction_id'],
            sno: -1
          })
          this.obj['total_ded_amount'] += Number((this.obj['showTotal'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0));

        } else if (this.dedTypeArr[i]['apply_on'] == 'L') {
          for (var j = 0; j < this.embData.length; j++) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.embData[j]['amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.dedTypeArr[i]['ded_amt'],
              type: 'PERCENTAGE',
              deduction_id: this.dedTypeArr[i]['deduction_id'],
              sno: this.embData[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.embData[j]['amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0));
          }
          for (var j = 0; j < this.part_rate_arr.length; j++) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.part_rate_arr[j]['amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.dedTypeArr[i]['ded_amt'],
              type: 'PERCENTAGE',
              deduction_id: this.dedTypeArr[i]['deduction_id'],
              sno: this.part_rate_arr[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.part_rate_arr[j]['amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0));
          }

        } else if (this.dedTypeArr[i]['apply_on'] == 'CGST') {
          this.obj['deduction'].push({
            id: -1,
            bill_id: -1,
            amt: Number((this.obj['cgst_amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0)),
            apply_on: 'CGST',
            rate: this.dedTypeArr[i]['ded_amt'],
            type: 'PERCENTAGE',
            deduction_id: this.dedTypeArr[i]['deduction_id'],
            sno: -1
          })
          this.obj['total_ded_amount'] += Number((this.obj['cgst_amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0))
        } else if (this.dedTypeArr[i]['apply_on'] == 'SGST') {
          this.obj['deduction'].push({
            id: -1,
            bill_id: -1,
            amt: Number((this.obj['sgst_amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0)),
            apply_on: 'SGST',
            deduction_id: this.dedTypeArr[i]['deduction_id'],
            rate: this.dedTypeArr[i]['ded_amt'],
            type: 'PERCENTAGE',
            sno: -1
          })
          this.obj['total_ded_amount'] += Number((this.obj['sgst_amount'] * this.dedTypeArr[i]['ded_amt'] / 100).toFixed(0))

        }
      }
    }
    this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(0))
  }
  deleteDeduction(i:any) {

    this.obj['total_ded_amount'] -= this.obj['deduction'][i]['amt'];
    this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(0))
    this.obj['deduction'].splice(i, 1);

  }
  newDed:any = {};
  addDeductionRow() {
    $('#ded').modal('show')
  }
  addDeductionRowInUpdate() {
    $('#ded1').modal('show')

  }
  changeDed() {
    for (var i = 0; i < this.dedTypeArr.length; i++) {
      if (this.dedTypeArr[i]['deduction_id'] == this.newDed['deduction_id']) {
        this.newDed['type'] = this.dedTypeArr[i]['ded_type'];
        this.newDed['rate'] = this.dedTypeArr[i]['ded_amt'];
        this.newDed['apply_on'] = this.dedTypeArr[i]['apply_on'];
      }
    }
  }
  saveDed() {
    if (this.newDed['type'] == 'FIXED') {
      this.obj['deduction'].push({
        id: -1,
        bill_id: -1,
        amt: this.newDed['rate'],
        apply_on: '',
        rate: 0.00,
        type: 'FIXED',
        deduction_id: this.newDed['deduction_id'],
        sno: -1
      })
      this.obj['total_ded_amount'] += Number(this.newDed['rate'].toFixed(0));
    } else if (this.newDed['type'] == 'PERCENTAGE') {
      if (this.newDed['apply_on'] == 'B') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['showTotal'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'B',
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          deduction_id: this.newDed['deduction_id'],
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['showTotal'] * this.newDed['rate'] / 100).toFixed(0));

      } else if (this.newDed['apply_on'] == 'L') {
        for (var j = 0; j < this.embData.length; j++) {
          if (this.embData[j]['sno'] == this.newDed['sno']) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.embData[j]['amount'] * this.newDed['rate'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.newDed['rate'],
              type: 'PERCENTAGE',
              deduction_id: this.newDed['deduction_id'],
              sno: this.embData[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.embData[j]['amount'] * this.newDed['rate'] / 100).toFixed(0));
          }
        }
        for (var j = 0; j < this.part_rate_arr.length; j++) {
          if (this.part_rate_arr[j]['sno'] == this.newDed['sno']) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.part_rate_arr[j]['amount'] * this.newDed['rate'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.newDed['rate'],
              type: 'PERCENTAGE',
              deduction_id: this.newDed['deduction_id'],
              sno: this.part_rate_arr[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.part_rate_arr[j]['amount'] * this.newDed['rate'] / 100).toFixed(0));
          }
        }

      } else if (this.newDed['apply_on'] == 'CGST') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['cgst_amount'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'CGST',
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          deduction_id: this.newDed['deduction_id'],
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['cgst_amount'] * this.newDed['rate'] / 100).toFixed(0))
      } else if (this.newDed['apply_on'] == 'SGST') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['sgst_amount'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'SGST',
          deduction_id: this.newDed['deduction_id'],
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['sgst_amount'] * this.newDed['rate'] / 100).toFixed(0))

      }
    }
    this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(0))

  }
  saveDedInUpdate() {
    if (this.newDed['type'] == 'FIXED') {
      this.obj['deduction'].push({
        id: -1,
        bill_id: -1,
        amt: this.newDed['rate'],
        apply_on: '',
        rate: 0.00,
        type: 'FIXED',
        deduction_id: this.newDed['deduction_id'],
        sno: -1
      })
      this.obj['total_ded_amount'] += Number(this.newDed['rate'].toFixed(0));
    } else if (this.newDed['type'] == 'PERCENTAGE') {
      if (this.newDed['apply_on'] == 'B') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['g_total'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'B',
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          deduction_id: this.newDed['deduction_id'],
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['showTotal'] * this.newDed['rate'] / 100).toFixed(0));

      } else if (this.newDed['apply_on'] == 'L') {
        for (var j = 0; j < this.embData.length; j++) {
          if (this.embData[j]['sno'] == this.newDed['sno']) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.embData[j]['amount'] * this.newDed['rate'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.newDed['rate'],
              type: 'PERCENTAGE',
              deduction_id: this.newDed['deduction_id'],
              sno: this.embData[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.embData[j]['amount'] * this.newDed['rate'] / 100).toFixed(0));
          }
        }
        for (var j = 0; j < this.part_rate_arr.length; j++) {
          if (this.part_rate_arr[j]['sno'] == this.newDed['sno']) {
            this.obj['deduction'].push({
              id: -1,
              bill_id: -1,
              amt: Number((this.part_rate_arr[j]['amount'] * this.newDed['rate'] / 100).toFixed(0)),
              apply_on: 'L',
              rate: this.newDed['rate'],
              type: 'PERCENTAGE',
              deduction_id: this.newDed['deduction_id'],
              sno: this.part_rate_arr[j]['sno']
            })
            this.obj['total_ded_amount'] += Number((this.part_rate_arr[j]['amount'] * this.newDed['rate'] / 100).toFixed(0));
          }
        }

      } else if (this.newDed['apply_on'] == 'CGST') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['cgst_amount'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'CGST',
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          deduction_id: this.newDed['deduction_id'],
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['cgst_amount'] * this.newDed['rate'] / 100).toFixed(0))
      } else if (this.newDed['apply_on'] == 'SGST') {
        this.obj['deduction'].push({
          id: -1,
          bill_id: -1,
          amt: Number((this.obj['sgst_amount'] * this.newDed['rate'] / 100).toFixed(0)),
          apply_on: 'SGST',
          deduction_id: this.newDed['deduction_id'],
          rate: this.newDed['rate'],
          type: 'PERCENTAGE',
          sno: -1
        })
        this.obj['total_ded_amount'] += Number((this.obj['sgst_amount'] * this.newDed['rate'] / 100).toFixed(0))

      }
    }
    this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(0))

  }

  async submitBill() {
    if(this.tt==1&& (this.obj['path_desc']==null||this.obj['path_desc']==''||this.obj['path_desc']==undefined))
    {
      Swal.fire('Error', 'Select Activity Hierrachy', 'error')
      return;
    }

    let obj:any = {
      b_acct_id: this.b_acct_id,

      bill_no: this.obj['prev_bill_no'] + 1,
      tender_id: this.selectedTender['tender_id'],
      bill_dt: '',
      bill_desc: this.obj['bill_desc'],
      pre_gst: this.obj['pre_gst'],
      cgst_per: this.obj['allowance_cgst_percentage'],
      sgst_per: this.obj['allowance_sgst_percentage'],
      igst_per: this.obj['allowance_igst_percentage'],
      add_with_held: this.obj['add_with_held'],
      add_security: this.obj['add_security'],
      temp_withheld: this.obj['temp_withheld'],
      per_withheld: this.obj['per_withheld'],
      rel_temp_withheld: this.obj['rel_temp_withheld'],
      per_withheld_remark: this.obj['per_withheld_remark'],
      temp_withheld_remark: this.obj['temp_withheld_remark'],
      final_ind: this.obj['is_final_bill'],
      pre_gst_amt: this.obj['pre_gst_amt'],
      bill_amt: this.obj['showTotal'],
      is_sent: 0,
      status: 'GENERATED',
      create_user_id: this.ebillUser['user_id'],
      data: [],
      ded: [],
      withheldamount: this.obj['with_held_amt'],
      cgst: Number(this.obj['cgst_amount'].toFixed(0)),
      sgst: Number(this.obj['sgst_amount'].toFixed(0)),
      igst: Number(this.obj['igst_amount'].toFixed(0)),
      withheldremanrk: this.obj['final_remark'],
      emb_no: this.max_emb_no,
      ded_amt: this.obj['total_ded_amount'],
      gst_data: JSON.stringify(this.obj['gstarr']),
      taxable_amt: this.obj['taxable_amt'],
      exempted_amt: this.obj['ex_amt'],
      labuor_cess:this.obj['labuor_cess']

    }
    if (obj['per_withheld_remark'] == null || obj['per_withheld_remark'] == undefined) {
      obj['per_withheld_remark'] = "NA"
    }
    if (obj['temp_withheld_remark'] == null || obj['temp_withheld_remark'] == undefined) {
      obj['temp_withheld_remark'] = "NA"
    }
    if (obj['final_remark'] == null || obj['final_remark'] == undefined) {
      obj['final_remark'] = "NA"
    }


    if (this.e_bill_no > 1) {
      obj['bill_no'] = this.e_bill_no
    }
    for (var i = 0; i < this.embData.length; i++) {

      let ob:any = {
        bill_no: this.obj['prev_bill_no'] + 1,
        tender_id: this.selectedTender['tender_id'],
        quantity: this.embData[i]['quantity'],
        sno: this.embData[i]['sno'],
        rate: this.embData[i]['rate'],
        remark: this.embData[i]['remark'],
        is_part: 0,
        eff_rate: this.embData[i]['eff_rate'],
      }
      /* if (ob['rate'] < ob['eff_rate']) {
        ob['is_part'] = 1;
      } */
      ob['part_rate_id'] = -1;
      obj['data'].push(ob);
    }
    for (var i = 0; i < this.part_rate_arr.length; i++) {
      var ob:any = {
        bill_no: this.obj['prev_bill_no'] + 1,
        tender_id: this.selectedTender['tender_id'],
        quantity: this.part_rate_arr[i]['quantity'],
        sno: this.part_rate_arr[i]['sno'],
        rate: this.part_rate_arr[i]['new_rate'],
        remark: this.part_rate_arr[i]['remark'],
        is_part: 0,
        eff_rate: this.part_rate_arr[i]['eff_rate'],
      }
      ob['part_rate_id'] = this.part_rate_arr[i]['id'];
      obj['data'].push(ob);
    }
    for (var i = 0; i < this.obj['deduction'].length; i++) {
      var ob1 = {
        amt: this.obj['deduction'][i]['amt'],//Amount Calculated
        apply_on: this.obj['deduction'][i]['apply_on'],//LINE,CGST,SGST,BILL AMT
        rate: this.obj['deduction'][i]['rate'],//6.5
        type: this.obj['deduction'][i]['type'],//FIXED/PERCEBTAGE
        sno: this.obj['deduction'][i]['sno'],//ITEM NO
        deduction_id: this.obj['deduction'][i]['deduction_id'],//DEDUCTIN  ID
      }
      if(this.obj['deduction'][i]['amt'] == null || this.obj['deduction'][i]['amt'] == undefined){
        this.stepper.selectedIndex = 3
        return
      }
      obj['ded'].push(ob1);
    }

    if (!(await this.checkForBudget(this.workObj[this.selectedTender['work_id']]['budget_cd'], this.obj['total_payable']))) {
      Swal.fire('Warning...', 'Bill Amount is exceed to budget Amount! \n  Available Amount:' + this.available_amount, 'warning');
      return;
    }

    var resp = await this.ebillService.createebill(obj);
    if (resp['error'] == false) {
      console.log(resp.data);
      console.log(this.upload_work_id);
     if(this.tt==1) await this.addactivity()
      await this.getAllEBILL();
      await this.upload1(resp.data, this.upload_work_id);
      await this.upload2(resp.data, this.upload_work_id);
      await this.upload3(resp.data, this.upload_work_id);
      await this.upload4(resp.data, this.upload_work_id);
      Swal.fire("Success", "Bill Added", "success");
      $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    } else {
      Swal.fire("Oops", "Error Occurred while adding Bill", "error");
    }

  }
  async addactivity(){
    let obb:any = new Object()
    obb['b_acct_id'] = this.b_acct_id
    obb['id'] = this.selectedTender['work_id']
 obb['activity_cd'] = this.planing_arr[this.planing_arr.length-1]['hierarchy_type']
 console.log(obb)
 var resp = await this.ebillService.addactivity(obb)
 if (resp['error'] == false) {
} else {
  Swal.fire("Oops", "Error Occurred while adding Bill", "error");
}
  }
  async print_cb(element:any) {

    await this.getDeductions(element);
    var obb:any = new Object();
    obb['b_acct_id'] = this.b_acct_id
    obb['work_id'] = this.selectedTender['work_id']
    obb['table_name']='bud_hier';
    var resp = await this.embHierService.getBudgetHierarchy(obb)
    console.log(resp)
    var bud = resp['data'][resp['data'].map(function(e:any){ return e.leaf_cd }).indexOf(this.selectedTender['budget_cd'])]

    var account_name=this.ebillUser['account_name'];
    var account_short_name=this.ebillUser['account_short_name'];
    var txt = account_name + "(" +account_short_name+ ") \n";
   // var txt ='' //"LUCKNOW DEVELOPMENT ATUHORITY" + "(" + "LDA" + ") , LUCKNOW "
    var txt1 = ''//"VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var txt2 = '' //this.name.toString() + '(' + this.role_cd.toString() + ')  ';
    var dd:any = {
      pageSize: 'A3',
      footer: function (currentPage:any, pageCount:any) {
        var arr1 = []
        var obb1 = { text: txt2 + currentPage.toString() + ' of ' + pageCount, alignment: 'center', margin: [72, 40], bold: true, style: 'header' }
        arr1.push(obb1)
        return arr1
      },
      header: function (currentPage:any, pageCount:any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true, style: 'header' };
        arr.push(obj);

        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var partyObj = this.partyObj[this.selectedTender['party_id']];
    var obj1 = { text: txt1, alignment: 'center', bold: true, style: 'header' };
    dd.content.push(obj1);

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'CB FOR RUNNING BILL FOR FILE/EMB NO - ' + this.selectedTender['already_emb'],
          bold: true,
          alignment: 'center'
        }

      ],

    }
    var header2 = {
      columns: [
        {
          width: '*',
          text: 'C.B. No. :',
          bold: true

        },
        {
          width: '*',
          text: element['acc_id']
        },
        {
          width: '*',
          text: 'PRINT DATE :',
          bold: true
        },
        {
          width: '*',
          text: this.mainService.dateformatchange(element['bill_dt'])
        }
      ],

    }
    var header3 = {
      columns: [
        {
          width: '*',
          text: 'Current Bill :',
          bold: true

        },
        {
          width: '*',
          text: element.bill_no + " For Work ID.:" + this.selectedTender['work_id']
        },
        {
          width: '*',
          text: 'Contractor :',
          bold: true

        },
        {
          width: '*',
          text: partyObj,
          //bold: true

        }

      ],

    }
 //   var arr = [];
  //   arr.push({ text: "Name Of Contractor ", bold: true });
  //   arr.push(partyObj);
  //   tbl.table.body.push(arr);
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });


    dd.content.push(header2);

    dd.content.push(header3);

    dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });

    var header8 = {
      columns: [
        {
          width: '*',
          text: 'Bill Description',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Description of Charge/Number/Date of Authority for all charges requiring special',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Amount',
          bold: true,
          alignment: 'right'
        }

      ],


    }
    dd.content.push({ text: " " });
    dd.content.push(header8);

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    var header9 = {
      columns: [
        {
          width: '*',
          text: element.bill_desc,
          bold: false,
          alignment: 'center'
        },
        {
          width: '*',
          text: this.selectedTender['work_id'] + " - " + this.workObj[this.selectedTender['work_id']]['work_order_name'],
          bold: false,
          alignment: 'center'
        },
        {
          width: '*',
          text: element['bill_amt'].toFixed(0),
          bold: false,
          alignment: 'right'
        }

      ],


    }
    var cgstHeader = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'center'
        },
        {
          width: '*',
          text: "CGST",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['cgst'].toFixed(0),
          bold: false,
          alignment: 'right'
        }

      ],


    }
    var sgstHeader = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: "SGST",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['sgst'].toFixed(0),

          bold: false,
          alignment: 'right'
        }

      ],


    }
    var igstHeader = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: "IGST",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['igst'].toFixed(0),

          bold: false,
          alignment: 'right'
        }

      ],


    }
    var addwithheld = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: "Add With Held",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['add_with_held'].toFixed(0),

          bold: false,
          alignment: 'right'
        }

      ],


    }
    var addsecurity = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: "Add Security",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['add_security'].toFixed(0),

          bold: false,
          alignment: 'right'
        }

      ],


    }
    if( element['labuor_cess'] == null ||  element['labuor_cess'] == undefined)  element['labuor_cess'] = 0
    let labour_cess = {
      columns: [
        {
          width: '*',
          text: "",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: "Add @ "+this.selectedTender['labour_cess']+" % Labour Cess",
          bold: false,
          alignment: 'left'
        },
        {
          width: '*',
          text: element['labuor_cess'].toFixed(0),

          bold: false,
          alignment: 'right'
        }

      ],


    }
    dd.content.push({ text: " " });

    dd.content.push(header9);
    dd.content.push({ text: " " });
    dd.content.push(cgstHeader);
    dd.content.push({ text: " " });
    dd.content.push(sgstHeader);
    dd.content.push({ text: " " });
    dd.content.push(igstHeader);
    dd.content.push({ text: " " });
    dd.content.push(addwithheld);
    dd.content.push({ text: " " });
    dd.content.push(addsecurity);
    dd.content.push({ text: " " });
    if(this.selectedTender['labour_cess_flag'] == 1) dd.content.push(labour_cess);
    if(this.selectedTender['labour_cess_flag'] == 1)  dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });
    var totalPayable = Number((Number(element['bill_amt'].toFixed(0)) + Number(element['cgst'].toFixed(0)) + Number(element['sgst'].toFixed(0))
      + Number(element['igst'].toFixed(0)) + Number(element['add_with_held'].toFixed(0)) + Number(element['add_security'].toFixed(0))).toFixed(0));
    dd.content.push({ text: "Total Payable : " + (totalPayable + Number(element['labuor_cess'].toFixed(0))).toFixed(0), bold: true, alignment: 'right' });

    dd.content.push({ text: " " });

    dd.content.push({ text: "Deductions : ", bold: true });
    dd.content.push({ text: " " });
    this.dedObj[-1] = {}
    this.dedObj[-1]['ded_name'] = 'LABOUR CESS'
    var tbl:any = {

      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*'],

        body: [
          [{ text: 'SR NO.', alignment: 'left', bold: true },
          { text: 'Dedution Name', alignment: 'left', bold: true },
          { text: 'Amount', alignment: 'right', bold: true }]
        ]
      }
    };
    var detdata:any = this.allDed
    var ttl = 0;
    for (var i = 0; i < detdata.length; i++) {
      var arr = [];
      arr.push({ text: i + 1 });
      if (detdata[i]['type'] == 'FIXED') {
        arr.push({ text: this.dedObj[detdata[i]['deduction_id']]['ded_name'] + "(FIXED)", alignment: 'left' });

      } else {
        arr.push({ text: this.dedObj[detdata[i]['deduction_id']]['ded_name'] + "@" + detdata[i]['rate'], alignment: 'left' });

      }
      arr.push({ text: detdata[i]['amt'].toFixed(0), alignment: 'right' });
      ttl = ttl + Number(detdata[i]['amt'].toFixed(0))
      tbl.table.body.push(arr)
    }
    dd.content.push(tbl)
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
    dd.content.push({ text: " " });



    dd.content.push({ text: "Total Deductions : " + ttl.toFixed(0), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });
    dd.content.push({ text: "With Held Amount : " + element.withheldamount.toFixed(0), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });
    var netPayable = 0;
    var netPayable = Number(element.bill_amt.toFixed(0)) + Number(element.cgst) + Number(element.sgst) + Number(element['igst']) + Number(element.add_with_held) + Number(element.add_security) - Number(element.withheldamount) - Number(ttl.toFixed(0));
    dd.content.push({ text: "Net Payable : " + (netPayable +  Number(element['labuor_cess'].toFixed(0))).toFixed(0), bold: true, alignment: 'right' });
    dd.content.push({ text: " " });


    var header81 = {
      columns: [
        {
          width: '*',
          text: 'Budget Head :',
          bold: true,
          alignment: 'right'
        },
        {
          width: '*',
          text: bud['bud_cd'],
          bold: true,
          alignment: 'left'
        },
        {
          width: '*',
          text: 'Total :',
          bold: true,
          alignment: 'right'
        },
        {
          width: '*',
          text: bud['remaining_amount'].toFixed(2),
          bold: true,
          alignment: 'left'
        },
        {
          width: '*',
          text: 'Balance :',
          bold: true,
          alignment: 'right'
        },
        {
          width: '*',
          text: (bud['remaining_amount'] - bud['expense_amount']).toFixed(2),
          bold: true,
          alignment: 'left'
        }, {}, {}, {}, {}

      ],


    }
    dd.content.push({ text: " " });
    dd.content.push(header81);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    await this.getroleprint()
    var amt_word = this.toWords(totalPayable.toFixed(2));
    let rolearr = JSON.parse(this.printrole['role'])
    console.log(rolearr,JSON.parse(this.printrole['role']))
    let printdesc = this.printrole['desc']
     printdesc  = printdesc.replace("$",totalPayable.toFixed(2) + " (" + amt_word + " only)");
    console.log(printdesc.replace("$",totalPayable.toFixed(2) + " (" + amt_word + " only)"))
    var header11:any = {
      columns: [



      ],


    }
    for (let i = 0; i < rolearr.length; i++) {

      header11['columns'].push( {
        width: '*',
        text: rolearr[i],
        bold: true,
        alignment: 'center'
      })
      if(header11['columns'].length == 4){
        console.log(Object.assign({},header11))
        dd.content.push(Object.assign({},header11));
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: printdesc, fontSize: 15,alignment: 'center' });
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        header11['columns'] = []
        if(i == rolearr.length-1){
          break
        }
      }
      if(i == rolearr.length-1){
        console.log(header11)
        dd.content.push(Object.assign({},header11));
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: printdesc, fontSize: 15 ,alignment: 'center'});
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        dd.content.push({ text: " " });
        header11['columns'] = []
      }

    }

    var header10 = {
      columns: [
        {
          width: '*',
          text: "Accounts Clerck",
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'Actt.',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'AAO/AO/C.A.',
          bold: true,
          alignment: 'center'
        },
        {
          width: '*',
          text: 'F.C.',
          bold: true,
          alignment: 'center'
        }

      ],


    }


    var header12 = {
      columns: [
        {
          width: '*',
          text: "Jt. Secy /Add. Secy / Secretary",
          bold: true,
          alignment: 'right'
        }
      ],
    }

    pdfMake.createPdf(dd).download('bill' + '.pdf');


  }
  printrole:any = {}
  async getroleprint(){
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id

    console.log(obj)
    var resp:any = await this.mainService.getroleprint(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()

      var data=resp['data'][0];

      this.printrole = data
      console.log(data);

    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  allDed = [];
  async getDeductions(element:any) {
    var ob = { b_acct_id: this.b_acct_id, bill_id: element['id'] }
    var resp = await this.ebillService.getALLDed(JSON.stringify(ob));
    if (resp['error'] == false) {
      this.allDed = resp['data'];
    } else {
      Swal.fire("Error", "Error while getting deductions", "error");
    }
  }

  async delete(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete1(element)
      }
    })
  }
  async finaldelete1(element:any) {
    this.spinner.show()
    var obj = { b_acct_id: this.b_acct_id, bill_no: element['bill_no'], tender_id: this.selectedTender['tender_id'], id: element['id'] };
    var resp = await this.ebillService.deleteebill(JSON.stringify(obj));
    if (resp['error'] == false) {
      if(element['status'] != 'GENERATED'){
        this.tender_apprdata = await this.getdocumentstatus(element)
        await this.pendingdelete()

      }
      await this.getAllEBILL();
      this.spinner.hide()
      Swal.fire("Success", "Bill Deleted", "success");

    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while deleting bill", "error");
    }

  }
  runningBill:any = [];
  async print_running_bill(element:any) {
    await this.ebillRunningBillPrintService.print_running_bill(element, this.selectedTender, this.selectObj['project_cd'])
  }

  alreadypaid:any = {};




  async getPreviousBillItems(element:any) {
    this.spinner.show()
    var obj = {
      b_acct_id: this.b_acct_id,
      bill_no: element['bill_no'],
      tender_id: this.selectedTender['tender_id'],
      id: element['id']
    };

    var resp = await this.ebillService.getLastBill(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = resp['data'];
      this.obj['prev_bill_no'] = element['bill_no'] - 1
      await this.getlastbillrate();
      await this.getpreviousbillquantity()
      console.log(dt)
      for (let i = 0; i < this.runningBill.length; i++) {
        this.alreadypaid[this.runningBill[i]['sno']] = this.runningBill[i]['already_measured']

      }
      var partarr = []
      for (var i = 0; i < dt.length; i++) {
        for (var j = 0; j < this.runningBill.length; j++) {
          if (this.runningBill[j]['currbillamt'] == undefined) {
            this.runningBill[j]['currbillamt'] = 0
            this.runningBill[j]['prevbillamt'] = 0
            this.runningBill[j]['uptodatebillamt'] = 0
          }
          if (dt[i]['sno'] == this.runningBill[j]['sno']) {
            if (dt[i]['bill_no'] < element['bill_no']) {
              this.runningBill[j]['prevbillamt'] += dt[i]['quantity'] * this.prevbillpartrate[dt[i]['sno']];
              if (dt[i]['is_part'] == 2) {
                this.runningBill[j]['already_measured'] -= dt[i]['quantity'];
              } else {
                if (dt[i]['part_rate_id'] == -1) {
                  this.runningBill[j]['billPrev'] += dt[i]['quantity'];
                }

              }

            } else {
              this.runningBill[j]['currbillrate'] = Number(dt[i]['rate'])
              this.runningBill[j]['currbillamt'] += Number(dt[i]['quantity']) * Number(dt[i]['rate']);
              if (dt[i]['part_rate_id'] == -1) {
                this.runningBill[j]['billCurrent'] += dt[i]['quantity'];

              }
            }
            this.runningBill[j]['uptodatebillamt'] += dt[i]['quantity'] * dt[i]['rate'];
            this.runningBill[j]['billRate'] = dt[i]['rate'];
          }

        }
      }
      for (let i = 0; i < this.runningBill.length; i++) {



        if (this.runningBill[i]['eff_rate'] > this.runningBill[i]['currbillrate']) {
          this.runningBill[i]['eff_rate'] = this.runningBill[i]['currbillrate'] + '/' + this.runningBill[i]['eff_rate']
        }
        if (this.prevbillpartrate[this.runningBill[i]['sno']] != this.runningBill[i]['currbillrate'] && Number((this.runningBill[i]['already_measured'] + this.runningBill[i]['billPrev']).toFixed(2)) != 0) {
          this.runningBill[i]['eff_rate'] = this.runningBill[i]['eff_rate'] + ' ( Prev Rate is ' + this.prevbillpartrate[this.runningBill[i]['sno']] + ' )'
        }


        this.runningBill[i]['prevbillamt'] = Number((this.runningBill[i]['already_measured'] + this.runningBill[i]['billPrev']).toFixed(3)) * this.prevbillpartrate[this.runningBill[i]['sno']]
        this.runningBill[i]['uptodatebillamt'] = Number((this.runningBill[i]['already_measured'] + this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']).toFixed(3)) * this.runningBill[i]['currbillrate'];
        this.runningBill[i]['currbillamt'] = this.runningBill[i]['uptodatebillamt'] - this.runningBill[i]['prevbillamt']

      }
      this.spinner.hide();


    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while deleting bill", "error");
    }
  }

  toWords(s:any) {
    var th = ['', 'thousand', 'million', 'billion', 'trillion'];

    var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;
    for (var i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == '1') {
          str += tn[Number(n[i + 1])] + ' ';
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + ' ';
          sk = 1;
        }
      } else if (n[i] != 0) {
        str += dg[n[i]] + ' ';
        if ((x - i) % 3 == 0) str += 'hundred ';
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk) str += th[(x - i - 1) / 3] + ' ';
        sk = 0;
      }
    }
    if (x != s.length) {
      var y = s.length;
      str += 'point ';
      for (let i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
    }
    return str.replace(/\s+/g, ' ');
  }

  changeDedAmt() {
    this.obj['total_ded_amount'] = 0;
    for (var i = 0; i < this.obj['deduction'].length; i++) {
      this.obj['total_ded_amount'] += this.obj['deduction'][i]['amt'];

    }
    this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(2));
    this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))

    //this.update

  }
  tender_apprdata:any = {}
  async Appr(element:any) {

    this.tender_apprdata = Object.assign({}, element)
    this.tender_apprdata['level'] = 1
    this.tender_apprdata['branch'] = 1
    this.tender_apprdata['doc_local_no'] = this.tender_apprdata['id']
    this.tender_apprdata['doc_type'] = 'EBILL'//this.tender_apprdata['id']
    this.tender_apprdata['node_cd'] = this.selectedTender['work_id']
    this.tender_apprdata['module_cd'] = 'EMB'
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'EBILL', 'EMB')
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.tender_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.tender_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#myModal2').modal('show');
  }

  async saveappr() {
    var obb:any = new Object()
    obb['user_id'] = this.ebillUser.user_id
    obb['remark'] = ""
    obb['b_acct_id'] = this.b_acct_id
    obb['forwarded_by'] = this.ebillUser.user_id
    obb['forward_msg'] = this.appr_remark;
    obb['status'] = 'APPROVED'
    obb['doc_type'] = 'EBILL'
    obb['doc_local_no'] = this.tender_apprdata['id']
    obb['doc_desc'] = this.tender_apprdata['bill_desc']
    obb['role_cd'] = this.ebillUser.role_cd
    obb['zone_cd'] = this.ebillUser.zone_cd
    obb['flag'] = 1;



    if (this.undertakings == null) {
      obb['undertakings'] = JSON.stringify({ data: this.undertakings })
    } else {
      obb['undertakings'] = JSON.stringify({ bill_amt: Number((this.billInfo['bill_amt'] + this.billInfo['cgst'] + this.billInfo['sgst'] + this.billInfo['igst']).toFixed(0)), data: this.undertakings })
    }


    var resp = await this.approveService.insertForAppr(obb)
    if (resp['error'] == false) {
      await this.saveapprPENDING()
      await this.updatestatus(this.tender_apprdata['id'])
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }
  async saveapprPENDING() {
    var obb:any = new Object()
    obb['user_id'] = this.forwarded_user_id
    obb['remark'] = ""
    obb['b_acct_id'] = this.b_acct_id
    obb['forwarded_by'] = this.ebillUser.user_id
    obb['forward_msg'] = this.appr_remark;

    obb['status'] = 'PENDING'
    obb['doc_type'] = 'EBILL'
    obb['doc_local_no'] = this.tender_apprdata['id']
    obb['doc_desc'] = this.tender_apprdata['bill_desc']
    obb['role_cd'] = this.rolebyid[this.forwarded_user_id]
    obb['zone_cd'] = this.ebillUser.zone_cd
    obb['flag'] = 2
    var resp = await this.approveService.insertForAppr(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
      this.toastr.success("Approval Added Successfully!!")
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }
  async updatestatus(id:any) {
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = id
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UNDERAPPROVAL'
    var resp = await this.ebillService.updateebillStatus(obb)
    if (resp['error'] == false) {
      await this.getAllEBILL()
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  appr_remark:any
  forwarded_user_id:any
  userRoles = [];
  role_name = []
  async getUsersRoles() {


    var resp = await this.userService.getUsersRoles(JSON.stringify({ b_acct_id: this.b_acct_id, zone_cd: this.ebillUser['zone_cd'] }));
    var obj = {};


    if (resp['error'] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        dt[i]['desc'] = dt[i]['user_id'] + " - " + dt[i]['name'] + " - " + dt[i]['role_cd'];

      }
      this.userRoles = dt
    } else {
      Swal.fire("Oops", "Error while getting all users", "error");
    }
  }

  async open_update(element:any) {
    this.update_flag=true
    this.list_flag=false
    this.create_flag=false
    console.log(element);
    await this.getDeductions(element)
    this.dedObj[-1] = {}
    this.dedObj[-1]['ded_name'] = 'LABOUR CESS'
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');
    this.obj['deduction'] = this.allDed
    this.obj['showTotal'] = (Number(element['bill_amt'] + element['pre_gst_amt'] + element['temp_withheld'] + element['per_withheld'] - element['rel_temp_withheld']).toFixed(2))
    this.obj['amt_after_withheld'] = (Number(element['bill_amt'] + element['pre_gst_amt']).toFixed(2))
    this.obj['labuor_cess'] = element['labuor_cess']
    this.obj['cgst_amount'] = element['cgst']
    this.obj['sgst_amount'] = element['sgst']
    this.obj['total_ded_amount'] = element['ded_amt']
    this.obj['pre_gst'] = element['pre_gst']
    this.obj['pre_gst_amt'] = element['pre_gst_amt']
    this.obj['cgst_per'] = element['cgst_per']
    this.obj['sgst_per'] = element['sgst_per']
    this.obj['igst_per'] = element['igst_per']
    this.obj['igst_amount'] = element['igst']
    this.obj['add_with_held'] = element['add_with_held']
    this.obj['add_security'] = element['add_security']
    this.obj['over_under_per'] = this.selectedTender['over_under_per']
    this.obj['bill_with_gst_amt'] = element['bill_amt'] + element['cgst'] + element['sgst'] + element['igst']
    this.obj['bill_id'] = element.id
    this.obj['id'] = element.id;
    this.obj['withheldamount'] = element.withheldamount;
    this.obj['withheldremanrk'] = element['withheldremanrk'];
    this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))
    this.obj['temp_withheld'] = element['temp_withheld']
    this.obj['per_withheld'] = element['per_withheld']
    this.obj['rel_temp_withheld'] = element['rel_temp_withheld']
    this.obj['per_withheld_remark'] = element['per_withheld_remark']
    this.obj['temp_withheld_remark'] = element['temp_withheld_remark']
    this.obj['ex_amt'] = element['exempted_amt']
    this.obj['taxable_amt'] = element['taxable_amt']
    this.obj['gstarr'] = JSON.parse(element['gst_data'])
    await this.Pregstupdate()
    await this.changeupdate()
    await this.checkForPreGST()
  }

  async changeupdate() {
    this.obj['amt_after_withheld'] = Number((this.obj['showTotal'] - this.obj['temp_withheld'] - this.obj['per_withheld'] + this.obj['rel_temp_withheld']).toFixed(2))
    this.obj['pre_gst_amt'] = Number(((this.obj['pre_gst'] * this.obj['amt_after_withheld']) / (100 + this.obj['pre_gst'])).toFixed(2))
    this.obj['g_total'] = Number((this.obj['showTotal'] - this.obj['pre_gst_amt'] - this.obj['temp_withheld'] - this.obj['per_withheld'] + this.obj['rel_temp_withheld']).toFixed(2))
    if(this.selectedTender['labour_cess_flag'] == 1){
      this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['g_total']  ) / 100 ).toFixed(0))
      for (let i = 0; i < this.obj['deduction'].length; i++) {
        this.obj['total_ded_amount'] =0
        if(this.obj['deduction'][i]['deduction_id'] == -1){
          this.obj['deduction'][i]['amt'] =this.obj['labuor_cess']
        }
        this.obj['total_ded_amount'] += this.obj['deduction'][i]['amt']

      }
      this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(2));
      this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))

    }

    // this.obj['cgst_amount'] = Number(((this.obj['cgst_per'] * this.obj['g_total']) / 100).toFixed(2))
    // this.obj['sgst_amount'] = Number(((this.obj['sgst_per'] * this.obj['g_total']) / 100).toFixed(2))
    // this.obj['igst_amount'] = Number(((this.obj['igst_per'] * this.obj['g_total']) / 100).toFixed(2))
    this.obj['total_pay'] = Number((this.obj['g_total'] + this.obj['cgst_amount'] + this.obj['sgst_amount'] + this.obj['igst_amount'] + this.obj['add_with_held'] + this.obj['add_security']).toFixed(2))
    this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))
    this.changeexemtedamtupdate()
  }

  async changeupdateamt() {
    this.obj['amt_after_withheld'] = Number((this.obj['showTotal'] - this.obj['temp_withheld'] - this.obj['per_withheld'] + this.obj['rel_temp_withheld']).toFixed(2))
    // this.obj['pre_gst_amt'] = Number(((this.obj['pre_gst'] * this.obj['amt_after_withheld']) / (100 + this.obj['pre_gst'])).toFixed(2))
    this.obj['g_total'] = Number((this.obj['showTotal'] - this.obj['pre_gst_amt'] - this.obj['temp_withheld'] - this.obj['per_withheld'] + this.obj['rel_temp_withheld']).toFixed(2))
    if(this.selectedTender['labour_cess_flag'] == 1){
      this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['g_total']  ) / 100 ).toFixed(0))
      for (let i = 0; i < this.obj['deduction'].length; i++) {
        this.obj['total_ded_amount'] =0
        if(this.obj['deduction'][i]['deduction_id'] == -1){
          this.obj['deduction'][i]['amt'] =this.obj['labuor_cess']
        }
        this.obj['total_ded_amount'] += this.obj['deduction'][i]['amt']

      }
      this.obj['total_ded_amount'] = Number(this.obj['total_ded_amount'].toFixed(2));
      this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))

    }
    /*  this.obj['cgst_amount'] = Number(((this.obj['cgst_per'] * this.obj['g_total']) / 100).toFixed(2))
     this.obj['sgst_amount'] = Number(((this.obj['sgst_per'] * this.obj['g_total']) / 100).toFixed(2))
     this.obj['igst_amount'] = Number(((this.obj['igst_per'] * this.obj['g_total']) / 100).toFixed(2)) */
    this.obj['total_pay'] = Number((this.obj['g_total'] + this.obj['cgst_amount'] + this.obj['sgst_amount'] + this.obj['igst_amount'] + this.obj['add_with_held'] + this.obj['add_security']).toFixed(2))
    this.obj['net_pay'] = Number((this.obj['total_pay'] - this.obj['total_ded_amount'] - this.obj['withheldamount']).toFixed(2))
    this.changeexemtedamtupdate()
  }

  checkForAllGST(cgst:any, sgst:any, igst:any, cgstamt:any, sgstamt:any, igstamt:any) {

    var flag = false;
    if ((igst != 0 && cgst != 0) || (igst != 0 && sgst != 0) || (igstamt != 0 && sgstamt != 0) || (igstamt != 0 && cgstamt != 0)) {
      flag = true;
    }

    return flag;

  }

  async update() {
    for (let i = 0; i < this.obj['deduction'].length; i++) {
      if(this.obj['deduction'][i]['amt'] == null || this.obj['deduction'][i]['amt'] == undefined){
        Swal.fire("Oops", "Deduction Amount Cannot be Null", "error");
        return
      }

    }
    var obj:any = new Object()
    obj['ded'] = this.obj['deduction']
    obj['bill_amt'] = Number(this.obj['g_total'].toFixed(2))
    obj['ded_amt'] = this.obj['total_ded_amount']
    obj['bill_id'] = this.obj['bill_id']
    obj['id'] = this.obj['id']
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.ebillUser.user_id
    obj['pre_gst'] = this.obj['pre_gst']
    obj['pre_gst_amt'] = this.obj['pre_gst_amt']
    obj['cgst_per'] = this.obj['cgst_per']
    obj['sgst_per'] = this.obj['sgst_per']
    obj['igst_per'] = this.obj['igst_per'];
    obj['add_with_held'] = this.obj['add_with_held']
    obj['add_security'] = this.obj['add_security']
    obj['cgst'] = Number(this.obj['cgst_amount'].toFixed(0))
    obj['sgst'] = Number(this.obj['sgst_amount'].toFixed(0))
    obj['igst'] = Number(this.obj['igst_amount'].toFixed(0))
    obj['withheldamount'] = this.obj['withheldamount'];
    obj['withheldremanrk'] = this.obj['withheldremanrk'];
    obj['temp_withheld'] = this.obj['temp_withheld']
    obj['per_withheld'] = this.obj['per_withheld']
    obj['rel_temp_withheld'] = this.obj['rel_temp_withheld']
    obj['per_withheld_remark'] = this.obj['per_withheld_remark']
    obj['temp_withheld_remark'] = this.obj['temp_withheld_remark']
    obj['gst_data'] = JSON.stringify(this.obj['gstarr'])
    obj['exempted_amt'] = this.obj['ex_amt']
    obj['taxable_amt'] = this.obj['taxable_amt']
    obj['labuor_cess'] = this.obj['labuor_cess']
    console.log(obj)
    var resp = await this.ebillService.updatededuction(obj);
    if (resp['error'] == false) {
      await this.getAllEBILL();
      Swal.fire("Success", "Bill Updated", "success");
      $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    } else {
      Swal.fire("Oops", "Error Occurred while adding Bill", "error");
    }
  }

  Pregst() {

    this.obj['total_pregst'] = 0
    var overunderpertotal = 0
    for (let i = 0; i < this.embData.length; i++) {
      if (this.embData[i]['is_pre_gst'] == 1) {
        if (this.overunderobj[this.embData[i]['sno']]['over_under_per'] != -1) {

          if (this.overunderobj[this.embData[i]['sno']]['over_under'] == 'LS') {
            overunderpertotal = Number((overunderpertotal + this.embData[i]['amount'] - (Number((Number((this.embData[i]['amount']).toFixed(2)) * this.overunderobj[this.embData[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
            // this.obj['overunderamt'] = Number((this.obj['overunderamtpart'] + Number((this.embData[i]['amount']).toFixed(2)) -(Number((Number((this.embData[i]['amount']).toFixed(2)) * this.overunderobj[this.embData[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

          }
          if (this.overunderobj[this.embData[i]['sno']]['over_under'] == 'EXC') {
            overunderpertotal = Number((overunderpertotal + this.embData[i]['amount'] + (Number((Number((this.embData[i]['amount']).toFixed(2)) * this.overunderobj[this.embData[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
            // this.obj['overunderamt'] =  Number((this.obj['overunderamtpart'] + Number((this.embData[i]['amount']).toFixed(2))  +(Number((Number((this.embData[i]['amount']).toFixed(2)) * this.overunderobj[this.embData[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
          } if (this.overunderobj[this.embData[i]['sno']]['over_under'] == 'ATPR') {
            overunderpertotal = Number((overunderpertotal + this.embData[i]['amount'] + (Number((Number((this.embData[i]['amount']).toFixed(2)) * this.overunderobj[this.embData[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

          }
        } else {
          this.obj['total_pregst'] = Number((this.obj['total_pregst'] + this.embData[i]['amount']).toFixed(2))
        }
      }

    }
    for (let i = 0; i < this.part_rate_arr.length; i++) {

      if (this.pregstobb[this.part_rate_arr[i]['sno']] == 1) {
        this.obj['total_pregst'] = Number((this.obj['total_pregst'] + Number((this.part_rate_arr[i].new_rate * this.part_rate_arr[i].quantity).toFixed(2))).toFixed(2))

      }
    }
    if (this.selectedTender['over_under'] == 'LS') {
      this.obj['total_pregst'] = Number((this.obj['total_pregst'] - (Number((this.obj['total_pregst'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
    }
    if (this.selectedTender['over_under'] == 'EXC') {
      this.obj['total_pregst'] = Number((this.obj['total_pregst'] + (Number((this.obj['total_pregst'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

    }
    this.obj['total_pregst'] = this.obj['total_pregst'] + overunderpertotal
    //this.Pregstupdate()
    //this.changePreGST()
    this.changetemp_withheld()
  }

  async Pregstupdate() {

    this.obj['total_pregst'] = 0
    if (this.obj['pre_gst'] != 0) {
      this.obj['total_pregst'] = Number(((this.obj['pre_gst_amt'] * (100 + this.obj['pre_gst'])) / this.obj['pre_gst']).toFixed(2))

    }


  }

  async getpreviouspartrate() {
    await this.getTenderID()
    let obj:any = {}
    obj['tender_id'] = this.selectedTender['tender_id']
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['is_part'] = 2;
    var resp = await this.ebillService.getPrevRate(JSON.stringify(obj))
    if (resp['error'] == false) {
      await this.getBoqItems()
      this.datasource12 = new MatTableDataSource(resp['data']);
      this.datasource12.sort = this.sortCol12;
      this.datasource12.paginator = this.paginator12;
    } else {
      Swal.fire("Oops", "Error Occurred while getting Boq", "error");
    }
  }

  Addpreviouspartrate() {
    $('#A1').modal('show');

  }

  selectedElement:any = {}
  changeitem() {
    // this.selectedElement = {}
    for (let i = 0; i < this.boqItems.length; i++) {
      if (this.boqItems[i]['sno'] == this.selectedElement['sno']) {
        this.selectedElement = this.boqItems[i]
      }

    }
    this.selectedElement['bill_rate'] = 0
    this.selectedElement['bill_quantity'] = 0
  }

  changerate() {
    this.selectedElement['bill_amt'] = this.selectedElement['bill_quantity'] * this.selectedElement['bill_rate']

  }

  changeQuantity() {
    this.selectedElement['bill_amt'] = this.selectedElement['bill_quantity'] * this.selectedElement['bill_rate']
  }

  changePaymentFraction(element:any) {
    let to_pay=0
    let err_flag=false;
    try {
      to_pay = eval(element['fraction'])
    } catch (err) {
      console.log(err);
      err_flag=true;
    }

    if(!err_flag){
      element['quantity']=to_pay;
      this.changePaymentPer(element);
    }
  }
  changePaymentPer(element:any) {

    console.log(element);
    let total = 0;
    let overundertotal = 0
    let overunderpertotal = 0

    var objPay = {};
    let temp = []
    for (let j = 0; j < this.embData.length; j++) {

      if (this.embData[j]['sno'] == element['sno']) {
        if (this.embData[j]['quantity'] > this.embData[j]['remaining_quantity']) {
          this.embData[j]['quantity'] = this.embData[j]['remaining_quantity'];
          Swal.fire("warning", "You Can not enter more then the remaing %", 'warning')
        }
        this.embData[j]['amount'] = Number((this.embData[j]['quantity'] * this.embData[j]['eff_rate']).toFixed(2));
      }

      total = total + Number(this.embData[j]['amount'])
      if (this.boqItems[0]['over_under'] == 'LS') {
        overunderpertotal = Number((overunderpertotal - (Number((Number((this.embData[j]['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
      }
      if (this.boqItems[0]['over_under'] == 'EXC') {
        overunderpertotal = Number((overunderpertotal + (Number((Number((this.embData[j]['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

      } if (this.boqItems[0]['over_under'] == 'ATPR') {
        overunderpertotal = Number((overunderpertotal + (Number((Number((this.embData[j]['amount']).toFixed(2)) * this.boqItems[0]['over_under_per'] / 100).toFixed(2)))).toFixed(2))

      }
      overundertotal = overundertotal + this.embData[j]['amount']

      temp.push(this.embData[j]);
    }

    this.embData = temp;

    this.obj['total_bill_amt'] = Number(total.toFixed(2))
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    if (this.selectedTender['over_under'] == 'LS') {
      this.obj['showTotal'] = Number((overundertotal - (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))
    }
    if (this.selectedTender['over_under'] == 'EXC') {
      this.obj['showTotal'] = Number((overundertotal + (Number((this.obj['subtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2)))).toFixed(2))

    } if (this.selectedTender['over_under'] == 'ATPR') {
      this.obj['showTotal'] = Number((overundertotal).toFixed(2))
    }
    this.obj['total_bill_amt'] = Number(overundertotal.toFixed(2));
    this.obj['subtotal'] = this.obj['total_bill_amt'];
    this.obj['labuor_cess'] = 0
    this.obj['showTotal'] = Number((this.obj['showTotal'] + overunderpertotal).toFixed(2))
    this.obj['overunderamt'] = this.obj['showTotal']
    if (this.selectedTender['discount'] != 0) {
      this.obj['showTotal'] = Number((this.obj['showTotal'] - ((this.obj['showTotal'] * this.selectedTender['discount'] / 100))).toFixed(2))
    }
    this.obj['subtotal1'] = this.obj['showTotal'];
    if (this.selectedTender['labour_cess_flag'] == 1) this.obj['labuor_cess'] = Number(((this.selectedTender['labour_cess'] * this.obj['showTotal']) / 100).toFixed(0))

    this.spinner.hide();
    console.log(this.embData)
    // this.Pregst()
    console.log(this.embData)
    this.datasource3 = new MatTableDataSource(this.embData);
    this.datasource3.sort = this.sort;
    this.datasource3.paginator = this.paginator3;

  }

  async savepreviouspartrate() {
    this.selectedElement['b_acct_id'] = this.ebillUser.b_acct_id;
    this.selectedElement['is_part'] = 2
    this.selectedElement['rate'] = this.selectedElement['bill_rate']
    this.selectedElement['quantity'] = this.selectedElement['bill_quantity']
    this.selectedElement['part_rate_id'] = -1
    this.selectedElement['bill_no'] = 0
    this.selectedElement['create_user_id'] = this.ebillUser.user_id
    var resp = await this.ebillService.insertPrevRate(this.selectedElement)
    if (resp['error'] == false) {
      await this.getpreviouspartrate()
      Swal.fire("Success", "Part Rate Added Sucessfully", "success");
    } else {
      Swal.fire("Oops", "Error Occurred while getting Boq", "error");
    }
  }

  open_updateprevious(element:any) {
    this.selectedElement = {}
    for (let i = 0; i < this.boqItems.length; i++) {
      if (this.boqItems[i]['sno'] == element['sno']) {
        this.selectedElement = this.boqItems[i]
      }

    }
    this.selectedElement['id'] = element['id']
    this.selectedElement['bill_rate'] = element['rate']
    this.selectedElement['bill_quantity'] = element['quantity']
    this.changeQuantity()
    $('#A2').modal('show');
  }

  async updatepreviouspartrate() {
    this.selectedElement['b_acct_id'] = this.ebillUser.b_acct_id;
    this.selectedElement['is_part'] = 2
    this.selectedElement['rate'] = this.selectedElement['bill_rate']
    this.selectedElement['quantity'] = this.selectedElement['bill_quantity']
    this.selectedElement['part_rate_id'] = -1
    this.selectedElement['bill_no'] = 0
    this.selectedElement['update_user_id'] = this.ebillUser.user_id
    var resp = await this.ebillService.updatePrevRate(this.selectedElement)
    if (resp['error'] == false) {
      await this.getpreviouspartrate()
      Swal.fire("Success", "Part Rate Updated Sucessfully", "success");
    } else {
      Swal.fire("Oops", "Error Occurred while Updating", "error");
    }
  }

  async deleteitem(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }


  async print_emb_for_bill(element:any){
    this.spinner.show();
    await this.embPrintService.print1(element['emb_no'],this.selectObj['work_order_no'])
    this.spinner.hide();
  }


  async finaldelete(element:any) {
    var obb = Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id;
    obb['id'] = element['id']
    var resp = await this.ebillService.deletePrevRate(JSON.stringify(obb))
    if (resp['error'] == false) {
      await this.getpreviouspartrate()
      Swal.fire("Success", "Part Rate Deleted Sucessfully", "success");
    } else {
      Swal.fire("Oops", "Error Occurred while Deleting", "error");
    }
  }

  changetemp_withheld() {
    this.obj['amt_after_withheld'] = this.obj['subtotal1'] - this.obj['temp_withheld'] - this.obj['per_withheld'] + this.obj['rel_temp_withheld']
    this.changePreGST()
    // this.changePreGSTamt()
  }


  ded_pre_gst_flag = false;
  checkForPreGST() {
    //check for sanction date
    console.log(this.workObj[this.selectedTender['work_id']]);
    // var s_date = null;

    var s_date = this.workObj[this.selectedTender['work_id']]['sanction_date'];
    let gst_type = this.workObj[this.selectedTender['work_id']]['gst_type1'];
    var x = new Date(s_date);
    var y = new Date('2017-07-01');
    if (x < y || gst_type=="EXCLUDING" ) {
      this.ded_pre_gst_flag = true;
    } else {
      this.ded_pre_gst_flag = false;
    }

    console.log(this.ded_pre_gst_flag)
    //check for sanction date
  }


  changeexemtedamt() {
    var amt = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      amt = amt + this.obj['gstarr'][i]['tax_amt']

    }
    if ((this.obj['showTotal'] - amt) < this.obj['ex_amt']) {
      Swal.fire("Oops", "Please Enter Exemted Amount Less Than and Equal To Total Bill Amount", "warning");
      this.obj['ex_amt'] = this.obj['showTotal'] - this.obj['taxable_amt']
      return
    }


    this.obj['taxable_amt'] = this.obj['showTotal'] - this.obj['ex_amt']
  }


  changeexemtedamtupdate() {
    var amt = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      amt = amt + Number((this.obj['gstarr'][i]['tax_amt']).toFixed(2))

    }
    if ((this.obj['g_total'] - Number((amt).toFixed(2))) < this.obj['ex_amt']) {
      Swal.fire("Oops", "Please Enter Exemted Amount Less Than and Equal To Total Bill Amount", "warning");
      this.obj['ex_amt'] = this.obj['g_total'] - this.obj['taxable_amt']
      return
    }


    this.obj['taxable_amt'] = this.obj['g_total'] - this.obj['ex_amt']
  }


  gstflag = 0
  changegsttype() {
    if (this.obj['gst_type'] == 'SGST/CGST') {
      this.gstflag = 1
      this.obj['igst_table'] = 0
      this.obj['sgst_table'] = 0
      this.obj['cgst_table'] = 0

    } else if (this.obj['gst_type'] == 'IGST') {
      this.gstflag = 2
      this.obj['igst_table'] = 0
      this.obj['sgst_table'] = 0
      this.obj['cgst_table'] = 0
    }

  }
  changegstper() {
    if (this.gstflag == 1) {
      this.obj['sgst_table'] = Number(((this.obj['sgst_per_table'] * Number(this.obj['tax_amt'])) / 100).toFixed(2))
      this.obj['cgst_table'] = Number(((this.obj['cgst_per_table'] * Number(this.obj['tax_amt'])) / 100).toFixed(2))

    } else if (this.gstflag == 2) {
      this.obj['igst_table'] = Number(((this.obj['igst_per_table'] * Number(this.obj['tax_amt'])) / 100).toFixed(2))

    }
  }
  changetaxamt() {
    var amt = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      amt = amt + this.obj['gstarr'][i]['tax_amt']

    }
    console.log((Number((this.obj['taxable_amt']).toFixed(2)) - amt))
    if ((((Number((this.obj['taxable_amt']).toFixed(2)) - amt)).toFixed()) < this.obj['tax_amt']) {
      Swal.fire("Oops", "Please Enter  Amount Less Than and Equal To Taxable Amount", "warning");
      this.obj['tax_amt'] = 0
      this.obj['gst_type'] = ''
      this.obj['cgst_per_table'] = 0
      this.obj['cgst_table'] = 0
      this.obj['sgst_per_table'] = 0
      this.obj['sgst_table'] = 0
      this.obj['igst_per_table'] = 0
      this.obj['igst_table'] = 0
      this.gstflag = 0
      return
    }

  }

  step3() {
    if (this.obj['ex_amt'] == undefined) {
      this.obj['ex_amt'] = 0
    }
    this.obj['gstarr'] = []
    this.changeexemtedamt()
    if (this.obj['cgst_amount'] == undefined) {
      this.obj['cgst_amount'] = 0
    }
    if (this.obj['igst_amount'] == undefined) {
      this.obj['igst_amount'] = 0
    }
    if (this.obj['sgst_amount'] == undefined) {
      this.obj['sgst_amount'] = 0
    }
    this.obj['total_payable'] = Number((Number(this.obj['showTotal']) + Number(this.obj['cgst_amount']) + Number(this.obj['sgst_amount']) + Number(this.obj['igst_amount'])).toFixed(2))
    //this.obj['bill_with_gst_amt']  = this.obj['total_payable']
    this.gstamount()
  }

  addLine() {
    this.obj['gstarr'].push({ tax_amt: this.obj['tax_amt'], gst_type: this.obj['gst_type'], cgst_per_table: this.obj['cgst_per_table'], cgst_table: this.obj['cgst_table'], sgst_per_table: this.obj['sgst_per_table'], sgst_table: this.obj['sgst_table'], igst_per_table: this.obj['igst_per_table'], igst_table: this.obj['igst_table'], gst_amt: Number((this.obj['igst_table'] + this.obj['cgst_table'] + this.obj['sgst_table']).toFixed(2)) })
    this.obj['tax_amt'] = 0
    this.obj['gst_type'] = ''
    this.obj['cgst_per_table'] = 0
    this.obj['cgst_table'] = 0
    this.obj['sgst_per_table'] = 0
    this.obj['sgst_table'] = 0
    this.obj['igst_per_table'] = 0
    this.obj['igst_table'] = 0
    this.gstflag = 0
    this.gstamount()
  }
  addLineupdate() {
    this.obj['gstarr'].push({ tax_amt: this.obj['tax_amt'], gst_type: this.obj['gst_type'], cgst_per_table: this.obj['cgst_per_table'], cgst_table: this.obj['cgst_table'], sgst_per_table: this.obj['sgst_per_table'], sgst_table: this.obj['sgst_table'], igst_per_table: this.obj['igst_per_table'], igst_table: this.obj['igst_table'], gst_amt: Number((this.obj['igst_table'] + this.obj['cgst_table'] + this.obj['sgst_table']).toFixed(2)) })
    this.obj['tax_amt'] = 0
    this.obj['gst_type'] = ''
    this.obj['cgst_per_table'] = 0
    this.obj['cgst_table'] = 0
    this.obj['sgst_per_table'] = 0
    this.obj['sgst_table'] = 0
    this.obj['igst_per_table'] = 0
    this.obj['igst_table'] = 0
    this.gstflag = 0
    this.obj['sgst_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['igst_amount'] = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      this.obj['sgst_amount'] = this.obj['sgst_amount'] + this.obj['gstarr'][i]['sgst_table']
      this.obj['cgst_amount'] = this.obj['cgst_amount'] + this.obj['gstarr'][i]['cgst_table']
      this.obj['igst_amount'] = this.obj['igst_amount'] + this.obj['gstarr'][i]['igst_table']
    }
    this.changeupdate()
  }
  deleteLine(i:any) {
    this.obj['gstarr'].splice(i, 1)
    this.gstamount()
  }
  deleteLineupdate(i:any) {
    this.obj['gstarr'].splice(i, 1)
    this.obj['sgst_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['igst_amount'] = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      this.obj['sgst_amount'] = this.obj['sgst_amount'] + this.obj['gstarr'][i]['sgst_table']
      this.obj['cgst_amount'] = this.obj['cgst_amount'] + this.obj['gstarr'][i]['cgst_table']
      this.obj['igst_amount'] = this.obj['igst_amount'] + this.obj['gstarr'][i]['igst_table']
    }
    this.changeupdate()
  }
  gstamount() {
    this.obj['sgst_amount'] = 0
    this.obj['cgst_amount'] = 0
    this.obj['igst_amount'] = 0
    for (let i = 0; i < this.obj['gstarr'].length; i++) {
      this.obj['sgst_amount'] = this.obj['sgst_amount'] + this.obj['gstarr'][i]['sgst_table']
      this.obj['cgst_amount'] = this.obj['cgst_amount'] + this.obj['gstarr'][i]['cgst_table']
      this.obj['igst_amount'] = this.obj['igst_amount'] + this.obj['gstarr'][i]['igst_table']
    }
    this.obj['bill_with_gst_amt'] = Number((Number(this.obj['showTotal']) + Number(this.obj['cgst_amount']) + Number(this.obj['sgst_amount']) + Number(this.obj['igst_amount'])).toFixed(2));
    this.obj['total_payable'] = this.obj['bill_with_gst_amt'];
    this.changeWithHeld
  }

  changeQuan(element:any){

  }






  ////**************************UnUsed Code*******************************************************************************//


  // AllWorkHeadList = []
  // async getAllWork() {
  //   this.spinner.show()
  //   var resp = await this.referenceDataS.getWorkHead(JSON.stringify(this.b_acct_id));
  //   if (resp['error'] == false) {
  //     this.AllWorkHeadList = resp.data;
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire("Error", resp['data'], 'error');
  //   }
  // }


  // async getAllApprovalStatus() {
  //   let obj = {};
  //   obj['b_acct_id'] = this.ebillUser.b_acct_id;
  //   obj['status'] = ["UNDERAPPROVAL"];
  //   obj['designation_cd'] = this.ebillUser.role_cd;
  //   var resp = await this.approveService.getApprovalStatus(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.mainService.taskcount = resp.data.length;
  //   }
  //   else {
  //   }
  // }

  ///************************************************Send To Account(Not In Used) Start********************************************** */


  // sendBillResp = {}
  // async sendToAcount(e) {
  //   this.obj = Object.assign({}, e)
  //   await this.check();
  //   let billObj = Object.assign({}, this.obj)
  //   var obj = {
  //     "budgetCd": "203",
  //     "budgetCode": "40100100525",
  //     "currBillNo": "2",
  //     "cvb_bill_amount": "100",
  //     "cvb_bill_date": "2020-09-03",
  //     "cvb_bill_desciption": "1 running bill",
  //     "cvb_cgst": "10",
  //     "cvb_sgst": "10",
  //     "cvb_igst": "0",
  //     "deductionDataList": [],
  //     "testing": "Y",
  //     "testing_description": "pcs EMB Test",
  //     "netPayAmt": "90",
  //     "totalAmount": "100",
  //     "vendorCode": "217",
  //     "workOrderNo": "23",
  //     "cvb_total": "120",
  //     "userId": "12",
  //     "vch_bill_type": "R"
  //   }
  //   obj['cvb_bill_desciption'] = e['bill_desc']
  //   obj['budgetCd'] = "203"
  //   for (let i = 0; i < this.workArr.length; i++) {
  //     if (this.selectedTender['work_id'] == this.workArr[i]['id']) {
  //       obj['budgetCd'] = this.workArr[i]['budget_cd']
  //       obj['totalAmount'] = this.workArr[i]['work_order_value']
  //       var party_id = this.workArr[i]['party_id']
  //     }

  //   }
  //   var date = e['create_timestamp'].split("T")


  //   obj['cvb_bill_amount'] = billObj['bill_amt'];
  //   obj['cvb_bill_date'] = date[0] //"2020-10-19"
  //   obj['currBillNo'] = billObj['bill_no'];
  //   obj['cvb_bill_desciption'] = billObj['bill_desc'];
  //   obj['cvb_cgst'] = billObj['cgst'];
  //   obj['cvb_sgst'] = billObj['sgst'];
  //   obj['netPayAmt'] = (billObj['bill_amt'] + Number(obj['cvb_cgst']) + Number(obj['cvb_sgst']) - billObj['withheldamount'] - billObj['ded_amt']).toFixed(2);

  //   obj['vendorCode'] = this.partyIdObj[party_id] //"217";
  //   obj['workOrderNo'] = this.selectedTender['work_id']
  //   obj['cvb_total'] = obj['cvb_bill_amount'] + obj['cvb_cgst'] + obj['cvb_sgst'];
  //   obj['userId'] = e['create_user_id'] //"12";
  //   var obb = new Object();
  //   obb['b_acct_id'] = this.b_acct_id
  //   obb['bill_id'] = e['id']
  //   var arr = await this.ebillService.getALLDed(JSON.stringify(obb))
  //   var detdata = arr['data'];
  //   var ttl = 0;
  //   for (var i = 0; i < detdata.length; i++) {

  //     obj['deductionDataList'].push({ "deductionId": detdata[i]['deduction_id'], "deductAmount": detdata[i]['amt'].toFixed(2) })
  //   }
  //   var resp = await this.ebillService.sendEbillToAccount(obj);
  //   this.sendBillResp = resp
  //   if (this.sendBillResp['status'] == 'success') {
  //     this.spinner.hide()
  //     await this.updateGlobalId(e['id'])
  //     await this.changeWorkInTab2();
  //     this.toastr.successToastr("Successfully Send To Account ")
  //   }
  //   else {
  //     this.spinner.hide()
  //     this.toastr.errorToastr("Error While Sending To Account");
  //   }
  // }



  // async updateGlobalId(data) {
  //   this.spinner.show()
  //   let obj2 = {}
  //   obj2['b_acct_id'] = this.b_acct_id;
  //   obj2['update_user_id'] = this.ebillUser.user_id;
  //   obj2['global_bill_no'] = this.sendBillResp['bill_id']
  //   obj2['id'] = data
  //   var resp = await this.ebillService.updateGlobalBillID(obj2);
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     this.toastr.errorToastr("Error Occured While Updating Global ID")
  //   }
  // }



  ///************************************************Send To Account(Not In Used) ENd********************************************** */


  // approvalHierForEBILL = [];
  // async getAllApprovalHier() {
  //   this.spinner.show();
  //   let obj = {};
  //   obj['b_acct_id'] = this.ebillUser.b_acct_id;
  //   var resp = await this.approveService.getApprovalHier(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //     this.approvalHierForEBILL = []
  //     var temp = resp.data;

  //     for (let i = 0; i < temp.length; i++) {
  //       if ('EBILL' == temp[i]['doc_type']) {
  //         this.approvalHierForEBILL.push(temp[i])
  //       }
  //     }
  //   }
  //   else {
  //     this.spinner.hide()
  //   }
  // }
  // getApprovalData(doc_local_no) {
  //   var arr = [];
  //   for (let i = 0; i < this.approvalHierForEBILL.length; i++) {
  //     var obj = new Object();
  //     obj['level_of_approval'] = this.approvalHierForEBILL[i]['level_of_approval'];
  //     obj['order_of_approval'] = this.approvalHierForEBILL[i]['order_of_approval'];
  //     obj['doc_type'] = 'EBILL'
  //     obj['doc_desc'] = 'EBILL'
  //     if (this.approvalHierForEBILL[i]['order_of_approval'] == 'F') {
  //       obj['status'] = 'UNDERAPPROVAL'
  //     } else {
  //       obj['status'] = 'PENDING'
  //     }
  //     obj['doc_local_no'] = doc_local_no
  //     obj['create_user_id'] = this.ebillUser.user_id;
  //     arr.push(obj)
  //   }

  //   return arr;
  // }
  // async insertForApproval(doc_local_no) {
  //   var obj = new Object();
  //   obj['b_acct_id'] = this.ebillUser['b_acct_id'];
  //   obj['data'] = this.getApprovalData(doc_local_no);
  //   var resp = await this.approveService.insertForApproval(obj);
  //   if (resp['error'] == false) {
  //   } else {
  //     this.toastr.errorToastr(resp['data'])
  //   }
  // }






  // async getAllProjectInfo() {
  //   var obj = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.masterDataService.getProjectInfo(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.allProject = resp.data;
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     this.toastr.errorToastr(resp['data']);
  //   }
  // }


  // async print1(element) {
  //   await this.getBoqItems();
  //   for (var i = 0; i < this.boqItems.length; i++) {
  //     this.runningBill[i] = Object.assign(this.boqItems[i])
  //     this.runningBill[i]['billRate'] = 0;
  //     this.runningBill[i]['billCurrent'] = 0;
  //     this.runningBill[i]['billPrev'] = 0;
  //   }

  //   await this.getPreviousBillItems(element);

  //   var txt = "LUCKNOW DEVELOPMENT AUTHORITY" + "(" + "LDA" + ") , LUCKNOW\n" + "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
  //   var dd = {
  //     pageSize: 'A3',
  //     header: function (currentPage, pageCount) {
  //       var arr = []
  //       var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
  //       arr.push(obj);
  //       return arr;
  //     },
  //     pageOrientation: 'portrait',
  //     pageMargins: [40, 60, 40, 60],
  //     content: [

  //     ]
  //   };




  //   var header1 = {
  //     columns: [
  //       {
  //         width: '*',
  //         text: 'RUNNING BILL FOR FILE/EMB NO - ' + this.selectedTender['already_emb'],
  //         bold: true,
  //         alignment: 'center'
  //       }

  //     ],

  //   }


  //   var tbl = {

  //     //layout: 'lightHorizontalLines',
  //     fontSize: 10,
  //     table: {

  //       headerRows: 0,
  //       widths: [370, 375],

  //       body: [



  //       ]
  //     }
  //   };

  //   var workObj = this.workObj[this.selectedTender['work_id']];
  //   var partyObj = this.partyObj[this.selectedTender['party_id']];

  //   var arr = [];
  //   arr.push({ text: "Name Of Contractor ", bold: true });
  //   arr.push(partyObj);
  //   tbl.table.body.push(arr);
  //   var arr = [];
  //   arr.push({ text: "Name Of Work ", bold: true });
  //   arr.push(workObj['work_order_name']);
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Bill No ", bold: true });
  //   arr.push(element['bill_no']);
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Budget Head ", bold: true });
  //   arr.push(workObj['budget_cd']);
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Budget Name ", bold: true });
  //   arr.push(workObj['budget_name']);
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Estimate No ", bold: true });
  //   arr.push('');
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Work Order No ", bold: true });
  //   arr.push(workObj['work_order_no']);
  //   tbl.table.body.push(arr);

  //   var arr = [];
  //   arr.push({ text: "Agreement Dated ", bold: true });
  //   arr.push(workObj['agr_dt']);
  //   tbl.table.body.push(arr);




  //   dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });


  //   dd.content.push({ text: " " });
  //   dd.content.push(header1);
  //   dd.content.push({ text: " " });
  //   dd.content.push(tbl);


  //   // dd.content.push(header2);

  //   // dd.content.push(header3);

  //   dd.content.push({ text: " " });
  //   dd.content.push({ text: " " });

  //   // dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.5 }] });
  //   var tbl1 = {

  //     // layout: 'lightHorizontalLines',
  //     fontSize: 10,
  //     table: {
  //       headerRows: 3,
  //       widths: [40, 40, 40, 250, 50, 50, 70, 70, 70],
  //       body: [
  //         [{ rowSpan: 3, text: 'upto date Qty' }, { rowSpan: 3, text: 'Paid Last Qty', alignment: 'Right' }, { rowSpan: 3, text: 'To Pay Qty ', alignment: 'right' }, { rowSpan: 3, text: 'Items', alignment: 'center' }, { rowSpan: 3, text: 'Rate', alignment: 'right' }, { text: 'Per', rowSpan: 3 }, { text: 'AMOUNT', alignment: 'center', colSpan: 3 }, {}, {}],
  //         ['', {}, {}, {}, {}, '', { text: 'Upto Date Amount', alignment: 'center' }, { text: 'Paid Last Amount', alignment: 'center' }, { text: 'To pay Amount', alignment: 'center' }],
  //         ['', {}, {}, {}, {}, '', { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }, { text: 'Rs.', alignment: 'center' }]

  //       ],
  //     }
  //   };
  //   dd.content.push(tbl1);
  //   console.log(this.runningBill)
  //   var printObj = { uptodatetotal: 0, lastpaidtotal: 0, topaytotal: 0, c1Over: 0, c2Over: 0, c3Over: 0, c1AfterOver: 0, c2AfterOver: 0, c3AfterOver: 0, c1PreGst: 0, c2PreGst: 0, c3PreGst: 0, c1GTotal: 0, c2GTotal: 0, c3GTotal: 0, c1CGST: 0, c2CGST: 0, c3CGST: 0, c1SGST: 0, c2SGST: 0, c3SGST: 0, c1WithHeld: 0, c2WithHeld: 0, c3WithHeld: 0, c1Sec: 0, c2Sec: 0, c3Sec: 0, c1AfterGST: 0, c2AfterGST: 0, c3AfterGST: 0, c1Net: 0, c2Net: 0, c3Net: 0, c1Ded: 0, c2Ded: 0, c3Ded: 0 }
  //   for (var i = 0; i < this.runningBill.length; i++) {
  //     if (this.runningBill[i]['currbillamt'] != 0 || this.runningBill[i]['uptodatebillamt'] != 0) {

  //       var arr = []
  //       arr.push((this.alreadypaid[this.runningBill[i]['sno']] + this.runningBill[i]['billCurrent'] + this.runningBill[i]['billPrev']).toFixed(3));
  //       arr.push({ text: (this.alreadypaid[this.runningBill[i]['sno']] + this.runningBill[i]['billPrev']).toFixed(3), alignment: 'right' });
  //       arr.push((this.runningBill[i]['billCurrent']).toFixed(3));
  //       arr.push({ text: '(' + this.runningBill[i]['sno'] + ') ' + this.runningBill[i]['item_desc'], alignment: 'left' });//this.runningBill[i]['quantity']
  //       arr.push({ text: this.runningBill[i]['eff_rate'], alignment: 'right' });//
  //       arr.push({ text: this.runningBill[i]['unit'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
  //       arr.push({ text: Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)), alignment: 'right' });
  //       printObj['uptodatetotal'] = printObj['uptodatetotal'] + Number((Number(this.runningBill[i]['uptodatebillamt']).toFixed(2)))
  //       arr.push({ text: Number((this.runningBill[i]['prevbillamt']).toFixed(2)), alignment: 'right' });
  //       printObj['lastpaidtotal'] = printObj['lastpaidtotal'] + Number((this.runningBill[i]['prevbillamt']).toFixed(2))
  //       arr.push({ text: (this.runningBill[i]['currbillamt']).toFixed(2), alignment: 'right' });
  //       printObj['topaytotal'] = printObj['topaytotal'] + Number((this.runningBill[i]['currbillamt']).toFixed(2))
  //       // arr.push({ text: (this.runningBill[i]['billRate'] * this.runningBill[i]['billCurrent']).toFixed(2), alignment: 'right' });
  //       dd.content[dd.content.length - 1].table.body.push(arr);
  //     }
  //   }
  //   var arrtotal = []
  //   arrtotal.push('')
  //   arrtotal.push('')
  //   arrtotal.push('')
  //   arrtotal.push({ text: 'Total', alignment: 'right', bold: true, colSpan: 3 })
  //   arrtotal.push('')
  //   arrtotal.push('')

  //   arrtotal.push({ text: Number(printObj['uptodatetotal'].toFixed(2)), alignment: 'right' })
  //   arrtotal.push({ text: Number(printObj['lastpaidtotal'].toFixed(2)), alignment: 'right' })
  //   arrtotal.push({ text: Number(printObj['topaytotal'].toFixed(2)), alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arrtotal);
  //   var TOTALoverunderpertotalcurrentbill = 0
  //   var TOTALoverunderpertotallastbill = 0
  //   var TOTALoverunderpertotaluptodatebill = 0
  //   var updatetotal = printObj['uptodatetotal']
  //   var lastpaidtotal = printObj['lastpaidtotal']
  //   var topaytotal = printObj['topaytotal']
  //   printObj['uptodatetotal'] = 0
  //   printObj['lastpaidtotal'] = 0
  //   printObj['topaytotal'] = 0
  //   var finaloverunder = new Object()
  //   finaloverunder['LS'] = new Object()
  //   finaloverunder['ATPR'] = new Object()
  //   finaloverunder['EXC'] = new Object()

  //   for (let i = 0; i < this.runningBill.length; i++) {
  //     var overunderpertotalcurrentbill = 0
  //     var overunderpertotallastbill = 0
  //     var overunderpertotaluptodatebill = 0
  //     if (this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] == -1) {
  //       printObj['uptodatetotal'] = printObj['uptodatetotal'] + Number((this.runningBill[i]['uptodatebillamt']).toFixed(2))
  //       printObj['lastpaidtotal'] = printObj['lastpaidtotal'] + Number((this.runningBill[i]['prevbillamt']).toFixed(2))
  //       printObj['topaytotal'] = printObj['topaytotal'] + Number((this.runningBill[i]['currbillamt']).toFixed(2))
  //     } else {
  //       if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'LS') {
  //         if (finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
  //           finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
  //           finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
  //           finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
  //           finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
  //         }

  //         overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         TOTALoverunderpertotalcurrentbill = TOTALoverunderpertotalcurrentbill - overunderpertotalcurrentbill
  //         TOTALoverunderpertotallastbill = TOTALoverunderpertotallastbill - overunderpertotallastbill
  //         TOTALoverunderpertotaluptodatebill = TOTALoverunderpertotaluptodatebill - overunderpertotaluptodatebill
  //         finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
  //         finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
  //         finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['LS'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill

  //       }
  //       if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'EXC') {
  //         if (finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
  //           finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
  //           finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
  //           finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
  //           finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
  //         }

  //         overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         TOTALoverunderpertotalcurrentbill = TOTALoverunderpertotalcurrentbill + overunderpertotalcurrentbill
  //         TOTALoverunderpertotallastbill = TOTALoverunderpertotallastbill + overunderpertotallastbill
  //         TOTALoverunderpertotaluptodatebill = TOTALoverunderpertotaluptodatebill + overunderpertotaluptodatebill
  //         finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
  //         finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
  //         finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['EXC'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill


  //       } if (this.overunderobj[this.runningBill[i]['sno']]['over_under'] == 'ATPR') {
  //         if (finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] == undefined) {
  //           finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']] = new Object()
  //           finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = 0
  //           finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = 0
  //           finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = 0
  //         }
  //         overunderpertotalcurrentbill = Number(((Number((Number((this.runningBill[i]['currbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotallastbill = Number(((Number((Number((this.runningBill[i]['prevbillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         overunderpertotaluptodatebill = Number(((Number((Number((this.runningBill[i]['uptodatebillamt']).toFixed(2)) * this.overunderobj[this.runningBill[i]['sno']]['over_under_per'] / 100).toFixed(2)))).toFixed(2))
  //         finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotalcurrentbill'] + overunderpertotalcurrentbill
  //         finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotallastbill'] + overunderpertotallastbill
  //         finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] = finaloverunder['ATPR'][this.overunderobj[this.runningBill[i]['sno']]['over_under_per']]['overunderpertotaluptodatebill'] + overunderpertotaluptodatebill


  //       }
  //     }

  //   }
  //   var lsarr = Object.keys(finaloverunder['LS'])
  //   var excarr = Object.keys(finaloverunder['EXC'])
  //   var atpararr = Object.keys(finaloverunder['ATPR'])
  //   for (let i = 0; i < lsarr.length; i++) {
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: 'Sub @ ' + lsarr[i] + ' % ', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /* ctotal.push({ text: finaloverunder['LS'][lsarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })
  //     ctotal.push({ text:finaloverunder['LS'][lsarr[i]]['overunderpertotallastbill'].toFixed(2), alignment: 'right' }) */
  //     ctotal.push({ text: finaloverunder['LS'][lsarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);

  //   }
  //   for (let i = 0; i < excarr.length; i++) {
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: 'Add @ ' + excarr[i] + ' % ', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /* ctotal.push({ text: finaloverunder['EXC'][excarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })
  //     ctotal.push({ text:finaloverunder['EXC'][excarr[i]]['overunderpertotallastbill'].toFixed(2), alignment: 'right' }) */
  //     ctotal.push({ text: finaloverunder['EXC'][excarr[i]]['overunderpertotalcurrentbill'].toFixed(2), alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);

  //   }
  //   if (atpararr.length > 0) {
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: ' At Par @ ', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /*  ctotal.push({ text: overunderpertotalcurrentbill.toFixed(2), alignment: 'right' })
  //      ctotal.push({ text:overunderpertotallastbill.toFixed(2), alignment: 'right' }) */
  //     ctotal.push({ text: 0, alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);

  //   }
  //   if (this.selectedTender['over_under'] == 'EXC') {
  //     printObj['c1Over'] = Number((printObj['uptodatetotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c2Over'] = Number((printObj['lastpaidtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c3Over'] = Number((printObj['topaytotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c1AfterOver'] = Number((updatetotal + printObj['c1Over']).toFixed(2));
  //     printObj['c2AfterOver'] = Number((lastpaidtotal + printObj['c2Over']).toFixed(2));
  //     printObj['c3AfterOver'] = Number((topaytotal + printObj['c3Over']).toFixed(2));
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: 'Add @ ' + this.selectedTender['over_under_per'] + ' %', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /*  ctotal.push({ text: printObj['c1Over'], alignment: 'right' })
  //      ctotal.push({ text: printObj['c2Over'], alignment: 'right' }) */
  //     ctotal.push({ text: printObj['c3Over'], alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);



  //   } else if (this.selectedTender['over_under'] == 'LS') {
  //     printObj['c1Over'] = Number((printObj['uptodatetotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c2Over'] = Number((printObj['lastpaidtotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c3Over'] = Number((printObj['topaytotal'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
  //     printObj['c1AfterOver'] = Number((updatetotal - printObj['c1Over']).toFixed(2));
  //     printObj['c2AfterOver'] = Number((lastpaidtotal - printObj['c2Over']).toFixed(2));
  //     printObj['c3AfterOver'] = Number((topaytotal - printObj['c3Over']).toFixed(2));
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: 'Sub @ ' + this.selectedTender['over_under_per'] + ' %', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /*  ctotal.push({ text: printObj['c1Over'], alignment: 'right' })
  //      ctotal.push({ text: printObj['c2Over'], alignment: 'right' }) */
  //     ctotal.push({ text: printObj['c3Over'], alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);

  //   } else {
  //     printObj['c1Over'] = 0;
  //     printObj['c2Over'] = 0;
  //     printObj['c3Over'] = 0;
  //     printObj['c1AfterOver'] = Number((updatetotal - printObj['c1Over']).toFixed(2));
  //     printObj['c2AfterOver'] = Number((lastpaidtotal - printObj['c2Over']).toFixed(2));
  //     printObj['c3AfterOver'] = Number((topaytotal - printObj['c3Over']).toFixed(2));
  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: ' At Par @ ', alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /*  ctotal.push({ text: 0, alignment: 'right', })
  //      ctotal.push({ text: 0, alignment: 'right' }) */
  //     ctotal.push({ text: 0, alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);

  //   }
  //   printObj['c1AfterOver'] = Number((printObj['c1AfterOver'] + TOTALoverunderpertotaluptodatebill).toFixed(2));
  //   printObj['c2AfterOver'] = Number((printObj['c2AfterOver'] + TOTALoverunderpertotallastbill).toFixed(2));
  //   printObj['c3AfterOver'] = Number((printObj['c3AfterOver'] + TOTALoverunderpertotalcurrentbill).toFixed(2));


  //   var ctotal = []
  //   ctotal.push('')
  //   ctotal.push('')
  //   ctotal.push('')
  //   ctotal.push({ text: ' Amount After Over Under ', alignment: 'right', bold: true, colSpan: 3 })
  //   ctotal.push('')
  //   ctotal.push('')
  //   ctotal.push('')
  //   ctotal.push('')
  //   /*  ctotal.push({ text: Number((printObj['c1AfterOver']  * this.selectedTender['discount'] / 100).toFixed(2)), alignment: 'right', })
  //    ctotal.push({ text: Number((printObj['c2AfterOver'] * this.selectedTender['discount'] / 100).toFixed(2)), alignment: 'right' }) */
  //   ctotal.push({ text: Number((printObj['c3AfterOver']).toFixed(2)), alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(ctotal);





  //   if (this.selectedTender['discount'] != 0) {

  //     var ctotal = []
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push({ text: ' Discount @ ' + this.selectedTender['discount'], alignment: 'right', bold: true, colSpan: 3 })
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     ctotal.push('')
  //     /*  ctotal.push({ text: Number((printObj['c1AfterOver']  * this.selectedTender['discount'] / 100).toFixed(2)), alignment: 'right', })
  //      ctotal.push({ text: Number((printObj['c2AfterOver'] * this.selectedTender['discount'] / 100).toFixed(2)), alignment: 'right' }) */
  //     ctotal.push({ text: Number((printObj['c3AfterOver'] * this.selectedTender['discount'] / 100).toFixed(2)), alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(ctotal);
  //     printObj['c1AfterOver'] = Number((printObj['c1AfterOver'] - ((printObj['c1AfterOver'] * this.selectedTender['discount'] / 100))).toFixed(2))
  //     printObj['c2AfterOver'] = Number((printObj['c2AfterOver'] - ((printObj['c2AfterOver'] * this.selectedTender['discount'] / 100))).toFixed(2))
  //     printObj['c3AfterOver'] = Number((printObj['c3AfterOver'] - ((printObj['c3AfterOver'] * this.selectedTender['discount'] / 100))).toFixed(2))
  //   }

  //   var total1 = []
  //   total1.push('')
  //   total1.push('')
  //   total1.push('')
  //   total1.push({ text: 'Sub Total', alignment: 'right', bold: true, colSpan: 3 });
  //   total1.push('')
  //   total1.push('');
  //   total1.push('')
  //   total1.push('');
  //   /* total1.push({ text: printObj['c1AfterOver'], alignment: 'right' })
  //   total1.push({ text: printObj['c2AfterOver'], alignment: 'right' }) */
  //   total1.push({ text: printObj['c3AfterOver'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(total1);
  //   var total1 = []
  //   total1.push('')
  //   total1.push('')
  //   total1.push('')
  //   total1.push({ text: 'Temporary With Held\n Remark :' + element['temp_withheld_remark'], alignment: 'right', bold: true, colSpan: 3 });
  //   total1.push('')
  //   total1.push('');
  //   total1.push('')
  //   total1.push('');
  //   /* total1.push({ text: printObj['c1AfterOver'], alignment: 'right' })
  //   total1.push({ text: printObj['c2AfterOver'], alignment: 'right' }) */
  //   total1.push({ text: element['temp_withheld'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(total1);
  //   var total1 = []
  //   total1.push('')
  //   total1.push('')
  //   total1.push('')
  //   total1.push({ text: 'Permanent Deduction \n Remark :' + element['per_withheld_remark'], alignment: 'right', bold: true, colSpan: 3 });
  //   total1.push('')
  //   total1.push('');
  //   total1.push('')
  //   total1.push('');
  //   /* total1.push({ text: printObj['c1AfterOver'], alignment: 'right' })
  //   total1.push({ text: printObj['c2AfterOver'], alignment: 'right' }) */
  //   total1.push({ text: element['per_withheld'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(total1);
  //   var total1 = []
  //   total1.push('')
  //   total1.push('')
  //   total1.push('')
  //   total1.push({ text: 'Release Previous Temporary Withheld', alignment: 'right', bold: true, colSpan: 3 });
  //   total1.push('')
  //   total1.push('');
  //   total1.push('')
  //   total1.push('');
  //   /* total1.push({ text: printObj['c1AfterOver'], alignment: 'right' })
  //   total1.push({ text: printObj['c2AfterOver'], alignment: 'right' }) */
  //   total1.push({ text: element['rel_temp_withheld'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(total1);
  //   printObj['c1PreGst'] = element['pre_gst_amt'] //Number(((printObj['c1AfterOver'] * element['pre_gst']) / (100 + element['pre_gst'])).toFixed(2))
  //   printObj['c2PreGst'] = element['pre_gst_amt'] //Number(((printObj['c2AfterOver'] * element['pre_gst']) / (100 + element['pre_gst'])).toFixed(2))

  //   printObj['c3PreGst'] = element['pre_gst_amt'] //Number(((printObj['c3AfterOver'] * element['pre_gst']) / (100 + element['pre_gst'])).toFixed(2))
  //   printObj['c1GTotal'] = Number((printObj['c1AfterOver'] - printObj['c1PreGst']).toFixed(2))
  //   printObj['c2GTotal'] = Number((printObj['c2AfterOver'] - printObj['c2PreGst']).toFixed(2))
  //   printObj['c3GTotal'] = Number((printObj['c3AfterOver'] - printObj['c3PreGst'] + element['rel_temp_withheld'] - element['per_withheld'] - element['temp_withheld']).toFixed(2))

  //   var arr = []
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')

  //   arr.push({ text: 'Deduct Pre GST @ ' + element['pre_gst'] + " %", alignment: 'right', bold: true, colSpan: 3 })
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   /*  arr.push({ text: printObj['c1PreGst'], alignment: 'right' })
  //    arr.push({ text: printObj['c2PreGst'], alignment: 'right' }) */
  //   arr.push({ text: printObj['c3PreGst'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arr);
  //   arr = []
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')

  //   arr.push({ text: 'G. Total ', alignment: 'right', bold: true, colSpan: 3 })
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   printObj['c3GTotal'] = Number((printObj['c3GTotal']).toFixed(0))
  //   /* arr.push({ text: printObj['c1GTotal'], alignment: 'right' })
  //   arr.push({ text: printObj['c2GTotal'], alignment: 'right' }) */
  //   arr.push({ text: printObj['c3GTotal'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arr);
  //   printObj['c1CGST'] = Number((printObj['c1GTotal'] * element['cgst_per'] / 100).toFixed(0));
  //   printObj['c2CGST'] = Number((printObj['c2GTotal'] * element['cgst_per'] / 100).toFixed(0));

  //   printObj['c3CGST'] = Number((printObj['c3GTotal'] * element['cgst_per'] / 100).toFixed(0));
  //   printObj['c1SGST'] = Number((printObj['c1GTotal'] * element['sgst_per'] / 100).toFixed(0));
  //   printObj['c2SGST'] = Number((printObj['c2GTotal'] * element['sgst_per'] / 100).toFixed(0));

  //   printObj['c3SGST'] = Number((printObj['c3GTotal'] * element['sgst_per'] / 100).toFixed(0));
  //   printObj['c1IGST'] = Number((printObj['c1GTotal'] * element['igst_per'] / 100).toFixed(0));
  //   printObj['c2IGST'] = Number((printObj['c2GTotal'] * element['igst_per'] / 100).toFixed(0));
  //   printObj['c3IGST'] = Number((printObj['c3GTotal'] * element['igst_per'] / 100).toFixed(0));
  //   printObj['c1WithHeld'] = element['add_with_held'];
  //   printObj['c2WithHeld'] = 0;
  //   printObj['c3WithHeld'] = element['add_with_held'];
  //   printObj['c1Sec'] = element['add_security'];
  //   printObj['c2Sec'] = 0;
  //   printObj['c3Sec'] = element['add_security'];

  //   printObj['c1AfterGST'] = Number((printObj['c1GTotal'] + printObj['c1CGST'] + printObj['c1SGST'] + printObj['c1IGST'] + printObj['c1Sec'] + printObj['c1WithHeld']).toFixed(2));
  //   printObj['c2AfterGST'] = Number((printObj['c2GTotal'] + printObj['c2CGST'] + printObj['c2SGST'] + printObj['c2IGST'] + printObj['c2Sec'] + printObj['c2WithHeld']).toFixed(2));
  //   printObj['c3AfterGST'] = Number((printObj['c3GTotal'] + element['cgst'] + element['sgst'] + element['igst'] + printObj['c3Sec'] + printObj['c3WithHeld']).toFixed(2));
  //   var data = JSON.parse(element['gst_data'])
  //   for (let o = 0; o < data.length; o++) {


  //     if (data[o]['cgst_table'] > 0) {
  //       arr = [];
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')

  //       arr.push({ text: 'CGST on ' + data[o]['tax_amt'] + ' @ ' + data[o]['cgst_per_table'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       /* arr.push({ text: printObj['c1CGST'], alignment: 'right' })
  //       arr.push({ text: printObj['c2CGST'], alignment: 'right' }) */
  //       arr.push({ text: data[o]['cgst_table'], alignment: 'right' })
  //       dd.content[dd.content.length - 1].table.body.push(arr);

  //       arr = [];
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')

  //       arr.push({ text: 'SGST on ' + data[o]['tax_amt'] + ' @ ' + data[o]['sgst_per_table'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       /* arr.push({ text: printObj['c1SGST'], alignment: 'right' })
  //       arr.push({ text: printObj['c2SGST'], alignment: 'right' }) */
  //       arr.push({ text: data[o]['sgst_table'], alignment: 'right' })
  //       dd.content[dd.content.length - 1].table.body.push(arr);
  //     } else {
  //       arr = [];
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')

  //       arr.push({ text: 'IGST on ' + data[o]['tax_amt'] + ' @ ' + data[o]['igst_per_table'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       arr.push('')
  //       /* arr.push({ text: printObj['c1IGST'], alignment: 'right' })
  //       arr.push({ text: printObj['c2IGST'], alignment: 'right' }) */
  //       arr.push({ text: data[o]['igst_table'], alignment: 'right' })
  //       dd.content[dd.content.length - 1].table.body.push(arr);
  //     }
  //   }
  //   if (data.length == 0) {
  //     arr = [];
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')

  //     arr.push({ text: 'CGST @ ' + element['cgst_per'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     /* arr.push({ text: printObj['c1CGST'], alignment: 'right' })
  //     arr.push({ text: printObj['c2CGST'], alignment: 'right' }) */
  //     arr.push({ text: printObj['c3CGST'], alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(arr);

  //     arr = [];
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')

  //     arr.push({ text: 'SGST @ ' + element['sgst_per'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     /* arr.push({ text: printObj['c1SGST'], alignment: 'right' })
  //     arr.push({ text: printObj['c2SGST'], alignment: 'right' }) */
  //     arr.push({ text: printObj['c3SGST'], alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(arr);
  //     arr = [];
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')

  //     arr.push({ text: 'IGST @ ' + element['igst_per'] + "%", alignment: 'right', bold: true, colSpan: 3 })
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     arr.push('')
  //     /* arr.push({ text: printObj['c1IGST'], alignment: 'right' })
  //     arr.push({ text: printObj['c2IGST'], alignment: 'right' }) */
  //     arr.push({ text: printObj['c3IGST'], alignment: 'right' })
  //     dd.content[dd.content.length - 1].table.body.push(arr)
  //   }
  //   arr = [];
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')

  //   arr.push({ text: "Add Previous With Held", alignment: 'right', bold: true, colSpan: 3 })
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   /* arr.push({ text: printObj['c1WithHeld'], alignment: 'right' })
  //   arr.push({ text: printObj['c2WithHeld'], alignment: 'right' }) */
  //   arr.push({ text: printObj['c3WithHeld'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arr);



  //   arr = [];
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')

  //   arr.push({ text: "Add Previous With Held Security", alignment: 'right', bold: true, colSpan: 3 })
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   /*  arr.push({ text: printObj['c1Sec'], alignment: 'right' })
  //    arr.push({ text: printObj['c2Sec'], alignment: 'right' }) */
  //   arr.push({ text: printObj['c3Sec'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arr);




  //   arr = [];
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')

  //   arr.push({ text: "Total Payable", alignment: 'right', bold: true, colSpan: 3 })
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   arr.push('')
  //   /*  arr.push({ text: printObj['c1AfterGST'], alignment: 'right' })
  //    arr.push({ text: printObj['c2AfterGST'], alignment: 'right' }) */
  //   arr.push({ text: printObj['c3AfterGST'].toFixed(0), alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(arr);
  //   await this.getDeductions(element)
  //   await this.getAllDed()
  //   var ded = 0
  //   for (let j = 0; j < this.allDed.length; j++) {
  //     if (this.allDed[j]['type'] == 'FIXED') {
  //       var total3 = []
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       total3.push({ text: this.dedObj[this.allDed[j]['deduction_id']]['ded_name'], alignment: 'right', bold: true, colSpan: 3 })
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       /*  total3.push({ text: (this.allDed[j]['amt']).toFixed(2), alignment: 'right' })
  //        total3.push({ text: 0, alignment: 'right'}) */
  //       total3.push({ text: (this.allDed[j]['amt']).toFixed(0), alignment: 'right' })
  //       printObj['c1Ded'] += this.allDed[j]['amt']
  //       printObj['c3Ded'] += this.allDed[j]['amt']
  //       dd.content[dd.content.length - 1].table.body.push(total3);
  //     } else {
  //       // var FirstDed=Number((printObj['c1GTotal']*this.allDed[j]['rate'] / 100).toFixed(2))
  //       // var SecondDed=Number((printObj['c2GTotal']*this.allDed[j]['rate'] / 100).toFixed(2))
  //       // var ThirdDed=Number((printObj['c3GTotal']*this.allDed[j]['rate'] / 100).toFixed(2))



  //       var FirstDed = Number((this.allDed[j]['amt']).toFixed(2))
  //       var SecondDed = 0
  //       var ThirdDed = Number((this.allDed[j]['amt']).toFixed(2))

  //       var total3 = []
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       total3.push({ text: 'Less ' + this.dedObj[this.allDed[j]['deduction_id']]['ded_name'] + ' @' + this.allDed[j]['rate'] + ' %', alignment: 'right', bold: true, colSpan: 3 })
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       total3.push('')
  //       /* total3.push({ text: FirstDed, alignment: 'right' })
  //       total3.push({ text: SecondDed, alignment: 'right' }) */
  //       total3.push({ text: ThirdDed, alignment: 'right' })
  //       dd.content[dd.content.length - 1].table.body.push(total3);

  //       printObj['c1Ded'] += FirstDed
  //       printObj['c2Ded'] += SecondDed
  //       printObj['c3Ded'] += ThirdDed
  //     }

  //   }
  //   printObj['c1Net'] = Number((printObj['c1AfterGST'] - printObj['c1Ded']).toFixed(2))
  //   printObj['c2Net'] = Number((printObj['c2AfterGST'] - printObj['c2Ded']).toFixed(2))
  //   printObj['c3Net'] = Number((printObj['c3AfterGST'] - printObj['c3Ded'] - element['withheldamount']).toFixed(2))

  //   var withHeld = []


  //   withHeld.push('')
  //   withHeld.push('')
  //   withHeld.push('')

  //   withHeld.push({ text: 'With Held Amount', alignment: 'right', bold: true, colSpan: 3 })
  //   withHeld.push('')
  //   withHeld.push('')
  //   withHeld.push('')
  //   withHeld.push('')
  //   /*  total6.push({ text: printObj['c1Net'], alignment: 'right' })
  //    total6.push({ text: printObj['c2Net'], alignment: 'right' }) */
  //   withHeld.push({ text: element['withheldamount'], alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(withHeld);


  //   var total6 = []
  //   total6.push('')
  //   total6.push('')
  //   total6.push('')

  //   total6.push({ text: 'Total Net Payable', alignment: 'right', bold: true, colSpan: 3 })
  //   total6.push('')
  //   total6.push('')
  //   total6.push('')
  //   total6.push('')
  //   /*  total6.push({ text: printObj['c1Net'], alignment: 'right' })
  //    total6.push({ text: printObj['c2Net'], alignment: 'right' }) */
  //   total6.push({ text: Number(printObj['c3Net'].toFixed(0)), alignment: 'right' })
  //   dd.content[dd.content.length - 1].table.body.push(total6);
  //   dd.content.push({ text: " " });

  //   dd.content.push({ text: " " });

  //   dd.content.push({ text: " " });

  //   dd.content.push({ text: " " });

  //   dd.content.push({ text: " " });

  //   // var header10 = {
  //   //   columns: [
  //   //     {
  //   //       width: '*',
  //   //       text: "Account Clerk",
  //   //       bold: true,
  //   //       alignment: 'center'
  //   //     },
  //   //     {
  //   //       width: '*',
  //   //       text: 'Adhishashi Abhiyanta(v)',
  //   //       bold: true,
  //   //       alignment: 'center'
  //   //     },
  //   //     {
  //   //       width: '*',
  //   //       text: 'Public Work Clerk',
  //   //       bold: true,
  //   //       alignment: 'center'
  //   //     },
  //   //     {
  //   //       width: '*',
  //   //       text: 'Contractor',
  //   //       bold: true,
  //   //       alignment: 'center'
  //   //     }

  //   //   ],


  //   // }
  //   // dd.content.push(header10);

  //   pdfMake.createPdf(dd).download('running-bill' + '.pdf');


  // }
}






