import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ChartOfAccountService } from "../../../service/chart-of-account.service";
import { LedgerService } from "../../../service/ledger.service";
import { MainService } from "../../../service/main.service";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as  pdfMake from  "pdfmake/build/pdfmake";
import { NgxSpinnerService } from "ngx-spinner";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import Swal from "sweetalert2";
import { Agent } from "http";
import * as fs from 'file-saver';
@Component({
  selector: "app-balance-sheet-report",
  templateUrl: "./balance-sheet-report.component.html",
  styleUrls: ["./balance-sheet-report.component.css"],
})
export class BalanceSheetReportComponent implements OnInit {
  erpUser:any;
  b_acct_id:any;
  allData:any;

  displayedColumns = ["Liabilities & Equities	", "Assets"];
  // innerTableHeader = ["Figure at the end of previous year", "Chart Of Account", "Sch No.", " ", "Figure at the end of reporting year"];
  innerTableHeader:any = ["Figure at the end of previous year", "Chart Of Account", "Figure at the end of reporting year"];
  selectedFinYear:any;
  allFinYear:any;

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(
    private ledgerService: LedgerService,
    private chartOfAccountService: ChartOfAccountService,
    private spinner: NgxSpinnerService,
    private mainService: MainService
  ) { }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getBalanceSheet();
    await this.getAllFinYear();
    await this.getAllChartOfAccount();
    await this.setFinYear();
    await this.allBalanceSheet();
  }

  mainObj:any = {};


  async setFinYear() {

    if (this.selectedFinYear == undefined) {
      Swal.fire("warning", 'Please select any fin year...', "warning");
      this.mainObj['start_date'] = null;
      this.mainObj['end_date'] = null;
      return;
    }
    this.allFinYear.map((x:any) => {
      if (x['fin_year'] == this.selectedFinYear) {
        this.mainObj['start_date'] = x['start_date'];
        this.mainObj['end_date'] = x['end_date'];
      }
    })


  }
  // current_finYear;
  async getAllFinYear() {
    this.spinner.show();
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allFinYear = resp["data"];

      this.allFinYear.map((x:any) => {
        x['fin_year_desc'] = x['fin_year'] + "-" + (Number(x['fin_year']) + 1)
        if (x['status'] == "OPEN") {
          this.selectedFinYear = x['fin_year']
        }
      })

      console.log("Active Fin Year is : ", this.allFinYear);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while featching all field", "error");
    }
  }

  // obj0 = new Object();
  // obb1 = new Object();
  // obj = new Object();
  // objLib0 = new Object();
  // objLib = new Object();
  // obbLib1 = new Object();
  ass_op_bal = 0
  lib_op_bal = 0
  ass_cl_bal = 0;
  lib_cl_bal = 0;
  async getBalanceSheet() {
    this.spinner.show();
    await this.refresh();
    let objToSend = Object();
    objToSend["b_acct_id"] = this.b_acct_id;
    var resp = await this.ledgerService.getBalanceSheet(JSON.stringify(objToSend));
    if (resp["error"] == false) {
      this.allData = resp["data"];
      console.log(this.allData);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while getting all field");
    }
  }

  // lvl4_data = [];
  // lvl4_data_lib = [];
  allCoaObj:any = {};
  // flage_for_opening = false
  // bal = 0
  // async allBalanceData() {

  //   //Assets infromation is gainning
  //   let asset1 = this.allData.filter((x) => {
  //     return x["lvl3_value"] == "Assets" && x['fin_year'] == this.selectedFinYear;
  //   });

  //   await this.refresh();
  //   console.log("asset1", asset1);

  //   for (let i = 0; i < asset1.length; i++) {
  //     if (this.obj[asset1[i]["lvl4_code"]] == undefined) {
  //       this.obj[asset1[i]["lvl4_code"]] = {};
  //       this.obj0[asset1[i]["lvl4_code"]] = asset1[i]["txn_amt"];
  //     } else {
  //       this.obj0[asset1[i]["lvl4_code"]] = this.obj0[asset1[i]["lvl4_code"]] + asset1[i]["txn_amt"];
  //     }
  //   }

  //   for (let i = 0; i < asset1.length; i++) {
  //     if (asset1[i]["lvl5_code"] != null) {
  //       if (this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] == undefined) {
  //         this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] = asset1[i]["txn_amt"];
  //       } else {
  //         this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] = this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] + asset1[i]["txn_amt"];
  //       }
  //     }
  //   }
  //   //here add opening balance + closing balance
  //   //Assets opening balance
  //   let asset_data = this.allData.filter((x) => {
  //     return x["lvl3_value"] == "Assets" && x['fin_year'] <= this.selectedFinYear;
  //   });

  //   console.log('asset_data  : ', asset_data)

  //   for (let i = 0; i < asset_data.length; i++) {
  //     if (this.ass_op_bal[asset_data[i]["lvl4_code"]] == undefined) {
  //       this.ass_op_bal[asset_data[i]["lvl4_code"]] = {};
  //     }
  //     if (asset_data[i]["lvl5_code"] != null) {

  //       if (this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] == undefined) {
  //         if (asset_data[i]['fin_year'] == this.selectedFinYear) {
  //           this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] = 0;
  //         } else {
  //           this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] = asset_data[i]["txn_amt"];
  //         }
  //       }
  //       else {
  //         //if from date is grater than account start date then it add all amount above from date
  //         if (this.selectedFinYear > asset_data[i]["fin_year"]) {
  //           this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] += asset_data[i]["txn_amt"];
  //         }
  //       }
  //     }
  //   }
  //   console.log(" Asset Opening balance is :", this.ass_op_bal);

  //   //Converting the object into array of object

  //   this.lvl4_data = Object.keys(this.obj0);
  //   console.log(this.obj);
  //   let aa = Object.keys(this.obj);
  //   console.log(aa);
  //   this.obb1 = new Object();

  //   aa.map((x) => {
  //     this.obb1[x] = [];
  //     let tt = Object.keys(this.obj[x]);
  //     tt.map((y) => {
  //       let oo = new Object();
  //       oo["lvl5_cd"] = y;
  //       oo["lvl5_value"] = this.obj[x][y];
  //       this.obb1[x].push(oo);
  //     });
  //   });

  //   console.log("Assets level5 data", this.obb1);
  //   console.log("Assets level 4 data", this.lvl4_data);

  //   //This code is for liability
  //   let liability1 = this.allData.filter((x) => {
  //     return x["lvl3_value"] == "Liabilities & Equities" && x['fin_year'] == this.selectedFinYear;
  //   });

  //   for (let i = 0; i < liability1.length; i++) {
  //     if (this.objLib[liability1[i]["lvl4_code"]] == undefined) {
  //       this.objLib[liability1[i]["lvl4_code"]] = {};
  //       this.objLib0[liability1[i]["lvl4_code"]] = liability1[i]["txn_amt"];
  //     } else {
  //       this.objLib0[liability1[i]["lvl4_code"]] =
  //         this.objLib0[liability1[i]["lvl4_code"]] + liability1[i]["txn_amt"];
  //     }
  //   }
  //   for (let i = 0; i < liability1.length; i++) {
  //     if (liability1[i]["lvl5_code"] != null) {
  //       if (
  //         this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] ==
  //         undefined
  //       ) {
  //         this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] =
  //           liability1[i]["txn_amt"];
  //       } else {
  //         this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] =
  //           this.objLib[liability1[i]["lvl4_code"]][
  //           liability1[i]["lvl5_code"]
  //           ] + liability1[i]["txn_amt"];
  //       }
  //     }
  //   }
  //   console.log(this.objLib);
  //   this.lvl4_data_lib = Object.keys(this.objLib0);

  //   //Converting the object into array of object

  //   let bb = Object.keys(this.objLib);
  //   this.obbLib1 = new Object();

  //   bb.map((x) => {
  //     this.obbLib1[x] = [];
  //     let ss = Object.keys(this.objLib[x]);
  //     ss.map((y) => {
  //       let oo = new Object();
  //       oo["lvl5_cd"] = y;
  //       oo["lvl5_value"] = this.objLib[x][y];
  //       this.obbLib1[x].push(oo);
  //     });
  //   });

  //   console.log("Libility level 5  data is  ", this.obbLib1);
  //   console.log("Libility level 4  data is ", this.lvl4_data_lib);

  //   //Liability opening balance
  //   let liability = this.allData.filter((x) => {
  //     return x["lvl3_value"] == "Liabilities & Equities" && x['fin_year'] <= this.selectedFinYear;
  //   });

  //   for (let i = 0; i < liability.length; i++) {
  //     if (this.lib_op_bal[liability[i]["lvl4_code"]] == undefined) {
  //       this.lib_op_bal[liability[i]["lvl4_code"]] = {};
  //     }
  //     if (liability[i]["lvl5_code"] != null) {
  //       if (this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] == undefined) {
  //         if (liability[i]['fin_year'] == this.selectedFinYear) {
  //           this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] = 0;
  //         } else {
  //           this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] = liability[i]["txn_amt"];
  //         }
  //       }
  //     }
  //     //if from date is grater than account start date then it add all amount above from date
  //     if (this.selectedFinYear > liability[i]["fin_year"]) {
  //       this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] += liability[i]["txn_amt"];
  //     }
  //   }


  //   console.log(" Liability Opening balance is :", this.lib_op_bal);
  // }



  closing_lib = [];
  closing_asset = [];
  // total_sum:any = { 'lib_opening': 0, 'lib_closing': 0, 'ass_opening': 0, 'ass_closing': 0 };

  async allBalanceSheet() {
    this.spinner.show();


    // if (!this.selectedFinYear) {
    //   Swal.fire('Opps..', 'Please Select Financial Year', 'info');
    //   this.spinner.hide();
    //   return;
    // }
    await this.refresh();
    // this.total_sum = { 'lib_opening': 0, 'lib_closing': 0, 'ass_opening': 0, 'ass_closing': 0 };

    //opening for liability********************************************************************************
    // let obj = Object.assign({}, this.mainObj);
    // obj["b_acct_id"] = this.b_acct_id;
    // obj["chart_of_account"] = 'CH111';//Liabilities & Equities
    // obj["as_on_date"] = this.mainObj['start_date'];//Start Date
    // obj["lvl_number"] = 3;///Level Number
    // var resp = await this.ledgerService.getBalanceSheet(JSON.stringify(obj));
    // console.table(resp.data);

    // let liability = this.allData.filter((x) => {
    //   return x["lvl3_value"] == "Liabilities & Equities" && x['fin_year'] <= this.selectedFinYear;
    // });
    /*  let liability=resp.data

     for (let i = 0; i < liability.length; i++) {
       if (this.lib_op_bal[liability[i]["lvl4_code"]] == undefined) {
         this.lib_op_bal[liability[i]["lvl4_code"]] = {};
       }
       if (liability[i]["lvl5_code"] != null) {
         if (this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] == undefined) {
           if (liability[i]['fin_year'] == this.selectedFinYear) {
             this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] = 0;
           } else {
             this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] = liability[i]["txn_amt"];
           }
         }
       }
       //if from date is grater than account start date then it add all amount above from date
       if (this.selectedFinYear > liability[i]["fin_year"]) {
         this.lib_op_bal[liability[i]["lvl4_code"]][liability[i]["lvl5_code"]] += liability[i]["txn_amt"];
       }
     }


     console.log(" Liability Opening balance is :", this.lib_op_bal);
  */



    //***********************************Cloasing of liability****************************************************************************** */


    let obj1 = Object.assign({}, this.mainObj);
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["chart_of_account"] = 'CH111';//Liabilities & Equities
    // obj1["as_on_date"] = this.mainObj['end_date'];//End Date
    obj1["lvl_number"] = 3;//Level Number
    var resp1 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj1));
    console.table(resp1.data)
    this.closing_lib = resp1.data


    this.closing_lib.map(x => {
      // x['txn_amt'] = (-1) * x['txn_amt'];
      this.lib_op_bal += x['opening'];
      this.lib_cl_bal += x['txn_amt'];
    })



    console.log("Libility ", resp1.data)

    // let liability1 = this.allData.filter((x) => {
    //   return x["lvl3_value"] == "Liabilities & Equities" && x['fin_year'] == this.selectedFinYear;
    // });
    /*  let liability1=resp1.data

     for (let i = 0; i < liability1.length; i++) {
       if (this.objLib[liability1[i]["lvl4_code"]] == undefined) {
         this.objLib[liability1[i]["lvl4_code"]] = {};
         this.objLib0[liability1[i]["lvl4_code"]] = liability1[i]["txn_amt"];
       } else {
         this.objLib0[liability1[i]["lvl4_code"]] =
           this.objLib0[liability1[i]["lvl4_code"]] + liability1[i]["txn_amt"];
       }
     }
     for (let i = 0; i < liability1.length; i++) {
       if (liability1[i]["lvl5_code"] != null) {
         if (
           this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] ==
           undefined
         ) {
           this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] =
             liability1[i]["txn_amt"];
         } else {
           this.objLib[liability1[i]["lvl4_code"]][liability1[i]["lvl5_code"]] =
             this.objLib[liability1[i]["lvl4_code"]][
             liability1[i]["lvl5_code"]
             ] + liability1[i]["txn_amt"];
         }
       }
     }
     console.log(this.objLib);
     this.lvl4_data_lib = Object.keys(this.objLib0); */

    //Converting the object into array of object

    /*  let bb = Object.keys(this.objLib);
     this.obbLib1 = new Object();

     bb.map((x) => {
       this.obbLib1[x] = [];
       let ss = Object.keys(this.objLib[x]);
       ss.map((y) => {
         let oo = new Object();
         oo["lvl5_cd"] = y;
         oo["lvl5_value"] = this.objLib[x][y];
         this.obbLib1[x].push(oo);
       });
     });

     console.log("Libility level 5  data is  ", this.obbLib1);
     console.log("Libility level 4  data is ", this.lvl4_data_lib); */
    //********************************************cloing of assets********************************************************************* */


    let obj2 = Object.assign({}, this.mainObj);
    obj2["b_acct_id"] = this.b_acct_id;
    obj2["chart_of_account"] = 'CH112';//Assets
    // obj2["as_on_date"] = this.mainObj['end_date'];//End Date
    obj2["lvl_number"] = 3;//Level Number
    var resp2 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj2));
    // console.table(resp2.data)

    this.closing_asset = resp2.data

    this.closing_asset.map((x:any) => {
      x['txn_amt'] = (-1) * x['txn_amt']
      this.ass_op_bal += x['opening'];
      this.ass_cl_bal += (x['txn_amt']);

    })

    await this.getClosingStock()
    await this.getPL();
    this.spinner.hide();
    // let asset1 = this.allData.filter((x) => {
    //   return x["lvl3_value"] == "Assets" && x['fin_year'] == this.selectedFinYear;
    // });
    /*  let asset1 = resp2.data;

     console.log("asset1", asset1);

     for (let i = 0; i < asset1.length; i++) {
       if (this.obj[asset1[i]["lvl4_code"]] == undefined) {
         this.obj[asset1[i]["lvl4_code"]] = {};
         this.obj0[asset1[i]["lvl4_code"]] = asset1[i]["txn_amt"];
       } else {
         this.obj0[asset1[i]["lvl4_code"]] = this.obj0[asset1[i]["lvl4_code"]] + asset1[i]["txn_amt"];
       }
     }

     for (let i = 0; i < asset1.length; i++) {
       if (asset1[i]["lvl5_code"] != null) {
         if (this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] == undefined) {
           this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] = asset1[i]["txn_amt"];
         } else {
           this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] = this.obj[asset1[i]["lvl4_code"]][asset1[i]["lvl5_code"]] + asset1[i]["txn_amt"];
         }
       }
     } */
    //here add opening balance + closing balance


    //***********************************************opening for asset****************************************************************** */

    //Assets opening balance


    // let obj3 = Object.assign({}, this.mainObj);
    // obj3["b_acct_id"] = this.b_acct_id;
    // obj3["chart_of_account"] = 'CH112';//Assets
    // obj3["as_on_date"] = this.mainObj['start_date'];//End Date
    // obj3["lvl_number"] = 3;//Level Number
    // var resp3 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj3));
    // console.table(resp3.data)
    // let asset1 = this.allData.filter((x) => {
    //   return x["lvl3_value"] == "Assets" && x['fin_year'] == this.selectedFinYear;
    // });
    /* let asset_data = resp3.data;

    // let asset_data = this.allData.filter((x) => {
    //   return x["lvl3_value"] == "Assets" && x['fin_year'] <= this.selectedFinYear;
    // });

    console.log('asset_data  : ', asset_data)

    for (let i = 0; i < asset_data.length; i++) {
      if (this.ass_op_bal[asset_data[i]["lvl4_code"]] == undefined) {
        this.ass_op_bal[asset_data[i]["lvl4_code"]] = {};
      }
      if (asset_data[i]["lvl5_code"] != null) {

        if (this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] == undefined) {
          if (asset_data[i]['fin_year'] == this.selectedFinYear) {
            this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] = 0;
          } else {
            this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] = asset_data[i]["txn_amt"];
          }
        }
        else {
          //if from date is grater than account start date then it add all amount above from date
          if (this.selectedFinYear > asset_data[i]["fin_year"]) {
            this.ass_op_bal[asset_data[i]["lvl4_code"]][asset_data[i]["lvl5_code"]] += asset_data[i]["txn_amt"];
          }
        }
      }
    }
    console.log(" Asset Opening balance is :", this.ass_op_bal);

    //Converting the object into array of object

    this.lvl4_data = Object.keys(this.obj0);
    console.log(this.obj);
    let aa = Object.keys(this.obj);
    console.log(aa);
    this.obb1 = new Object();

    aa.map((x) => {
      this.obb1[x] = [];
      let tt = Object.keys(this.obj[x]);
      tt.map((y) => {
        let oo = new Object();
        oo["lvl5_cd"] = y;
        oo["lvl5_value"] = this.obj[x][y];
        this.obb1[x].push(oo);
      });
    });

    console.log("Assets level5 data", this.obb1);
    console.log("Assets level 4 data", this.lvl4_data); */

    console.log("Assets ", resp2.data)

  }

  async getAllChartOfAccount() {
    this.spinner.show();
    let obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.chartOfAccountService.getchartofaccount(obj);
    if (resp["error"] == false) {
      resp["data"].map((x:any) => {
        this.allCoaObj[x["leaf_code"]] = x["leaf_value"];
      });
      console.log("all coa", this.allCoaObj);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while featching all field", "error");
    }
  }

  async refresh() {
    // this.lvl4_data_lib = [];
    // this.objLib0 = [];
    // this.obbLib1 = [];
    this.lib_op_bal = 0;
    this.lib_cl_bal = 0;
    // this.obj0 = [];
    this.ass_op_bal = 0;
    this.ass_cl_bal = 0;
    // this.obb1 = [];
    // this.obj = [];
    // this.objLib = [];
    // this.total_sum = {}
  }

  closing_st :any= []
  dataObj:any = {}
  async getClosingStock() {
    this.mainObj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getClosingStock(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {
      this.closing_st = resp['data'];
      console.log(this.closing_st)
      // let curr_fin_data = new Object();

      this.dataObj = { 'curr_row_m': 0, 'curr_work_p': 0, 'curr_finished_st': 0, 'curr_total': 0 }
      // await this.getCogsEntry();

      this.closing_st.map((x :any)=> {
        if (this.selectedFinYear == x['fin_year']) {
          this.dataObj['curr_total'] += x['final_stock_amt'];
          if (this.mainObj['end_date'] == x['as_on_date'] && x['stock_cat'] == 'Raw Stock') {
            this.dataObj['curr_row_m'] = x['final_stock_amt']
          }
          if (this.mainObj['end_date'] == x['as_on_date'] && x['stock_cat'] == 'In Progress Stock') {
            this.dataObj['curr_work_p'] = x['final_stock_amt']
          }
          if (this.mainObj['end_date'] == x['as_on_date'] && x['stock_cat'] == 'Finished Stock') {
            this.dataObj['curr_finished_st'] = x['final_stock_amt']
          }
        }
      })
      console.log(this.dataObj);
    } else {
      Swal.fire('ERROR', 'Error while getting Closing Stock', 'error');
    }
  }
  expence = []
  exp_total = 0;
  exp_op_total = 0;
  income = []
  inc_op_total = 0;
  inc_total = 0;
  // first_line;
  ass_prop = 0;
  lib_prop = 0;



  async getPL() {
    this.spinner.show();
    // await this.refresh();

    //***********************************Cloasing of Expense****************************************************************************** */

    let obj1 = Object.assign({}, this.mainObj);
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["chart_of_account"] = 'CH121';// Expenditure A/C
    // obj1["as_on_date"] = this.mainObj['end_date'];//End Date
    obj1["lvl_number"] = 3;//Level Number
    var resp1 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj1));
    // console.table(resp1.data)
    this.expence = resp1.data
    this.exp_total = 0;
    this.exp_op_total = 0;
    this.expence.map((x:any) => {
      x['txn_amt'] = (-1) * x['txn_amt'];
      this.exp_total += x['txn_amt'];

    })
    //********************************************cloing of Income********************************************************************* */

    let obj2 = Object.assign({}, this.mainObj);
    obj2["b_acct_id"] = this.b_acct_id;
    obj2["chart_of_account"] = 'CH122';//Income
    obj2["lvl_number"] = 3;//Level Number
    var resp2 = await this.ledgerService.getBalanceSheet(JSON.stringify(obj2));
    this.income = resp2.data
    this.inc_op_total = 0;
    this.inc_total = 0;
    this.income.map(x => {
      this.inc_total += x['txn_amt'];
    })

    this.ass_prop = 0;
    this.lib_prop = 0;




    if (this.exp_total > this.inc_total) {
      this.ass_prop = (this.exp_total - this.inc_total);
    }
    if (this.exp_total < this.inc_total) {
      this.lib_prop = (this.inc_total - this.exp_total);
    }
    this.spinner.hide();
  }

  async excel() {
    let obj:any = new Object();
    obj['acc_name'] = this.mainService.accInfo["account_name"];
    obj['acc_sort_name'] = this.mainService.accInfo["account_short_name"];
    obj['year'] = this.selectedFinYear + '-' + (Number(this.selectedFinYear) + 1);
    obj['end_date'] = this.mainObj['end_date']
    obj['closing_lib'] = this.closing_lib;
    obj['exp_total'] = this.exp_total;
    obj['inc_total'] = this.inc_total;
    obj['lib_op_bal'] = this.lib_op_bal
    obj['lib_cl_bal'] = this.lib_cl_bal
    obj['lib_prop'] = this.lib_prop
    obj['curr_row_m'] = this.dataObj['curr_row_m']
    obj['curr_work_p'] = this.dataObj['curr_work_p']
    obj['curr_finished_st'] = this.dataObj['curr_finished_st']
    obj['closing_asset'] = this.closing_asset
    obj['ass_prop'] = this.ass_prop
    obj['ass_cl_bal'] = this.ass_cl_bal
    obj['ass_op_bal'] = this.ass_op_bal
    obj['curr_total'] = this.dataObj['curr_total']
    obj['b_acct_id'] = this.b_acct_id;

    let resp:any = await this.ledgerService.balancesheetExcel(obj);
    let blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Balance Sheet .xlsx');
  }

  print2() {
    this.spinner.show();

    var txt =
      this.mainService.accInfo["account_name"] +
      "(" +
      this.mainService.accInfo["account_short_name"] +
      ")" +
      "  BALANCE SHEET AS " +
      " AT " +
      this.selectedFinYear +
      " ";
    var dd :any= {
      pageSize: "A4",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [10, 60, 40, 10],
      content: [],
    };

    let tab = {
      fontSize: 10,
      table: {
        widths: ["*", "*"],
        body: [
          [
            [
              { text: "Liability ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: [90, 95, 90, 90],
                  body: [
                    [
                      {
                        text: "Figure at the end of the Privious year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: "Liability",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      // {
                      //   text: "Sch No.",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      // {
                      //   text: "",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      {
                        text: "Figure at the end of the Reporting year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],

            [
              { text: "Assets ", alignment: "center", fontSize: 20 },
              {
                table: {
                  widths: [90, 95, 90, 90],
                  body: [
                    [
                      {
                        text: "Figure at the end of the Privious year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      {
                        text: "Assets",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                      // {
                      //   text: "Sch No.",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      // {
                      //   text: "",
                      //   style: "tableHeader",
                      //   bold: true,
                      //   alignment: "center",
                      // },
                      {
                        text: "Figure at the end of the Reporting year",
                        style: "tableHeader",
                        bold: true,
                        alignment: "center",
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        ],
      },
    };
    dd.content.push(tab);


    // Liability Print
    for (let i = 0; i < this.closing_lib.length; i++) {

      let printLib = []

      let obj = {
        columns: [
          {
            width: '*',
            text: this.closing_lib[i]['opening']
          }
        ]
      }
      printLib.push(obj);

      if (this.closing_lib[i]['lvl5_code'] != null) {
        let obj2 = {
          columns: [
            {
              width: '*',
              text: this.closing_lib[i]['lvl4_value'],
              bold: true
            }
            ,
            {
              width: '*',
              text: "- " + this.closing_lib[i]['lvl5_value'],
            }
          ]
        }
        printLib.push(obj2);
      } else {
        let obj2 = {
          columns: [{
            width: '*',
            text: this.closing_lib[i]['lvl4_value'],
            bold: true
          }]
        }
        printLib.push(obj2);
      }


      //code show

      // if (this.closing_lib[i]['lvl5_code'] != null) {
      //   let obj3 = {
      //     columns: [
      //       {
      //         width: '*',
      //         text: this.closing_lib[i]['lvl4_code'],
      //         bold: true
      //       }
      //       ,
      //       {
      //         width: '*',
      //         text: "- " + this.closing_lib[i]['lvl5_code'],
      //       }
      //     ]
      //   }
      //   printLib.push(obj3);
      // } else {
      //   let obj3 = {
      //     columns: [{
      //       width: '*',
      //       text: this.closing_lib[i]['lvl4_code'],
      //       bold: true
      //     }]
      //   }
      //   printLib.push(obj3);
      // }
      let obj5 = {
        columns: [
          {
            width: '*',
            text: this.closing_lib[i]['txn_amt']
          }
        ]
      }
      printLib.push(obj5);
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);

    }
    let printLib = []
    if (this.exp_total < this.inc_total) {
      printLib.push({ text: "", alignment: "left", bold: true });
      printLib.push({ text: "To Excess of Income Over Expenditure", alignment: "center", bold: true });
      // printLib.push({ text: "", alignment: "center", bold: true });
      printLib.push({ text: (this.inc_total - this.exp_total), alignment: "left", bold: true });
      dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);
    }

    printLib = []
    printLib.push({ text: this.lib_op_bal, alignment: "left", bold: true });
    printLib.push({ text: "TOTAL", alignment: "center", bold: true, });
    // printLib.push({ text: "", alignment: "center", bold: true });
    printLib.push({ text: (this.lib_cl_bal + this.lib_prop), alignment: "left", bold: true });
    dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(printLib);


    //   tot_lib_cl += this.objLib0[this.lvl4_data_lib[i]];
    //   dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(
    //     printLib
    //   );

    //   for (let j = 0; j < this.obbLib1[this.lvl4_data_lib[i]].length; j++) {
    //     let printLib5 = [];
    //     printLib5.push({
    //       text: this.lib_op_bal[this.lvl4_data_lib[i]][
    //         this.obbLib1[this.lvl4_data_lib[i]][j]["lvl5_cd"]
    //       ],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.allCoaObj[
    //         this.obbLib1[this.lvl4_data_lib[i]][j]["lvl5_cd"]
    //       ],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.obbLib1[this.lvl4_data_lib[i]][j]["lvl5_cd"],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.obbLib1[this.lvl4_data_lib[i]][j]["lvl5_value"],
    //       alignment: "center",
    //     });
    //     printLib5.push({ text: " ", alignment: "center" });
    //     tot_lib_op +=
    //       this.lib_op_bal[this.lvl4_data_lib[i]][
    //       this.obbLib1[this.lvl4_data_lib[i]][j]["lvl5_cd"]
    //       ];
    //     dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push(
    //       printLib5
    //     );
    //   }
    // }
    // dd.content[dd.content.length - 1].table.body[0][0][1].table.body.push([
    //   { text: tot_lib_op },
    //   { text: " " },
    //   { text: " ", fontSize: 10 },
    //   { text: " " },
    //   { text: tot_lib_cl },
    // ]);




    // Assets Print
    let closingStock = []

    closingStock.push({ text: '' })
    let obj = {
      columns: [{
        width: '*',
        text: 'Closing Stock ',
        bold: true
      },
      {
        width: '*',
        text: '- Raw Stock '

      }
      ]
    }
    closingStock.push(obj);
    // closingStock.push({ text: '' })
    closingStock.push({ text: this.dataObj['curr_row_m'] })
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(closingStock);


    closingStock = []
    closingStock.push({ text: '' })
    let obj1 = {
      columns: [{
        width: '*',
        text: 'Closing Stock ',
        bold: true
      },
      {
        width: '*',
        text: '- In Progress Stock'

      }
      ]
    }
    closingStock.push(obj1)
    // closingStock.push({ text: '' })
    closingStock.push({ text: this.dataObj['curr_work_p'] })
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(closingStock);

    closingStock = []
    closingStock.push({ text: '' })
    let obj2 = {
      columns: [{
        width: '*',
        text: 'Closing Stock ',
        bold: true
      },
      {
        width: '*',
        text: ' - Finished Stock'

      }
      ]
    }
    closingStock.push(obj2)
    // closingStock.push({ text: '' })
    closingStock.push({ text: this.dataObj['curr_finished_st'] })
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(closingStock);



    for (let i = 0; i < this.closing_asset.length; i++) {


      let printAss = []

      let obj = {
        columns: [
          {
            width: '*',
            text: this.closing_asset[i]['opening']
          }
        ]
      }
      printAss.push(obj);

      if (this.closing_asset[i]['lvl5_code'] != null) {
        let obj2 = {
          columns: [
            {
              width: '*',
              text: this.closing_asset[i]['lvl4_value'],
              bold: true
            }
            ,
            {
              width: '*',
              text: "- " + this.closing_asset[i]['lvl5_value'],
            }
          ]
        }
        printAss.push(obj2);
      } else {
        let obj2 = {
          columns: [{
            width: '*',
            text: this.closing_asset[i]['lvl4_value'],
            bold: true
          }]
        }
        printAss.push(obj2);
      }


      //code show

      // if (this.closing_asset[i]['lvl5_code'] != null) {
      //   let obj3 = {
      //     columns: [
      //       {
      //         width: '*',
      //         text: this.closing_asset[i]['lvl4_code'],
      //         bold: true
      //       }
      //       ,
      //       {
      //         width: '*',
      //         text: "- " + this.closing_asset[i]['lvl5_code'],
      //       }
      //     ]
      //   }
      //   printAss.push(obj3);
      // } else {
      //   let obj3 = {
      //     columns: [{
      //       width: '*',
      //       text: this.closing_asset[i]['lvl4_code'],
      //       bold: true
      //     }]
      //   }
      //   printAss.push(obj3);
      // }

      let obj5 = {
        columns: [
          {
            width: '*',
            text: this.closing_asset[i]['txn_amt']
          }
        ]
      }
      printAss.push(obj5);
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);

    }
    let printAss = []
    if (this.exp_total > this.inc_total) {
      printAss.push({ text: "", alignment: "center", bold: true });
      printAss.push({ text: "To Excess of Income Over Expenditure", alignment: "center", bold: true });
      // printAss.push({ text: "", alignment: "center", bold: true });
      printAss.push({ text: (this.exp_total - this.inc_total), alignment: "left", bold: true });
      dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);
    }

    printAss = []
    printAss.push({ text: this.ass_op_bal, alignment: "left", bold: true });
    printAss.push({ text: "TOTAL", alignment: "center", bold: true, });
    // printAss.push({ text: "", alignment: "center", bold: true });
    printAss.push({ text: (this.ass_cl_bal + this.ass_prop + this.dataObj['curr_total']), alignment: "left", bold: true });
    dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(printAss);



    // for (let i = 0; i < this.lvl4_data.length; i++) {
    //   let printLib = [];
    //   printLib.push({ text: "", alignment: "center", bold: true });
    //   printLib.push({
    //     text: this.allCoaObj[this.lvl4_data[i]],
    //     alignment: "center",
    //     bold: true,
    //   });
    //   printLib.push({
    //     text: this.lvl4_data[i],
    //     alignment: "center",
    //     bold: true,
    //   });
    //   printLib.push({ text: "", alignment: "center", bold: true });
    //   printLib.push({
    //     text: this.obj0[this.lvl4_data[i]],
    //     alignment: "center",
    //     bold: true,
    //   });
    //   tot_ass_cl += this.obj0[this.lvl4_data[i]];
    //   dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(
    //     printLib
    //   );

    //   for (let j = 0; j < this.obb1[this.lvl4_data[i]].length; j++) {
    //     let printLib5 = [];
    //     printLib5.push({
    //       text: this.ass_op_bal[this.lvl4_data[i]][
    //         this.obb1[this.lvl4_data[i]][j]["lvl5_cd"]
    //       ],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.allCoaObj[this.obb1[this.lvl4_data[i]][j]["lvl5_cd"]],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.obb1[this.lvl4_data[i]][j]["lvl5_cd"],
    //       alignment: "center",
    //     });
    //     printLib5.push({
    //       text: this.obb1[this.lvl4_data[i]][j]["lvl5_value"],
    //       alignment: "center",
    //     });
    //     printLib5.push({ text: " ", alignment: "center" });
    //     tot_ass_op +=
    //       this.ass_op_bal[this.lvl4_data[i]][
    //       this.obb1[this.lvl4_data[i]][j]["lvl5_cd"]
    //       ];
    //     dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push(
    //       printLib5
    //     );
    //   }
    // }
    // dd.content[dd.content.length - 1].table.body[0][1][1].table.body.push([
    //   { text: tot_ass_op },
    //   { text: " " },
    //   { text: " ", fontSize: 10 },
    //   { text: " " },
    //   { text: tot_ass_cl },
    // ]);

    pdfMake.createPdf(dd).download("Balance report ");

    this.spinner.hide();

  }
}
