import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service'
declare var $: any
import { FilterService } from '../../../service/filter.service';

import { MainService } from '../../../service/main.service'
import { UserService } from '../../../service/user.service';
import { UndertakingService } from '../../../service/undertaking.service';
import { normalizeUnits } from 'moment';
@Component({
  selector: 'app-undertaking',
  templateUrl: './undertaking.component.html',
  styleUrls: ['./undertaking.component.css']
})
export class UndertakingComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = ['s_no', 'doc_type', 'fields', 'action'];
  datasource:any;

  l_under_flag=true;
  c_under_flag=false;
  u_under_flag=false;

  constructor(private undertakings: UndertakingService, private filterService: FilterService, private userService: UserService, public mainService: MainService, private referenceDataS: ReferenceDataService, private router: Router, private spinner: NgxSpinnerService) { }
  ebillUser:any;
  b_acct_id:any;
  allFields:any = [];
  Obj:any = {
    data: []
  };
  Doc_type = [{ doc_type: "EBILL" }/* , { doc_type: "EMB" } */];
  bill_amt = [{ key: "ebill_amt", value: "BILL AMOUNT" }];
  selectField:any;
  allCodeValue:any = [];
  selectedCodeValue:any = []
  codeValueObj:any = {};
  codeValueShowObj:any = {};
  dataSource:any;
  fileType = { data: [] }


  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getcurrentroleswithresources();
    await this.getAllUndertakings();
  }


  Change_doc() {
    if (this.Obj['doc_type'] == "EBILL") {
      this.Obj["fields"] = this.bill_amt[0]['key'];
    } else {
      this.Obj["fields"] = ''
    }

  }

  async gotolist(){
    this.l_under_flag=true;
    this.c_under_flag=false;
    this.u_under_flag=false;

    await this.getcurrentroleswithresources();
    await this.getAllUndertakings();

  }
  gotocrete(){
    this.refresh();
    this.l_under_flag=false;
    this.c_under_flag=true;
    this.u_under_flag=false;

  }


  validtion:any={}
  undertakingList:any = []
  async getAllUndertakings() {
    var obj:any=new Object();
    obj['b_acct_id']=this.b_acct_id;
    obj['module_cd']='EMB';

    var resp = await this.undertakings.getUndertakings(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      this.validtion={}
      this.undertakingList = resp['data'];
      for(let i=0;i<this.undertakingList.length;i++){
        this.validtion[this.undertakingList[i]['module_cd']+this.undertakingList[i]['doc_type']+this.undertakingList[i]['role_cd']]=1
      }
    }
    else {
      this.spinner.hide();

    }
    console.log(resp)

  }

  async submit() {
    let flag = true
    for (let i = 0; i < this.undertakingList.length; i++) {
      console.log(this.undertakingList[i]['doc_type']);

      if (this.undertakingList[i]['doc_type'] == this.Obj['doc_type'] && this.undertakingList[i]['role_cd'] == this.Obj['role_cd']) {
        flag = false
        break
      }
    }
    if(this.validtion['EMB'+this.Obj['doc_type']+this.Obj['role_cd']]==1){
      swal.fire("Warning...", "Record of this document with this role is already exist..!", "warning");

      return;
    }
    if (flag == true) {
      if (this.Obj['doc_type'] && this.Obj['role_cd']) {
        let obj:any = {}
        obj = this.Obj
        obj['b_acct_id'] = this.b_acct_id;
        obj['create_user_id'] = this.ebillUser.user_id;
        obj['module_cd'] = 'EMB';
        obj['data'] = JSON.stringify(this.data)
        console.log(obj)
        var resp = await this.undertakings.createUndertakings(obj);
        console.log(resp)
        if (resp['error'] == false) {
          this.spinner.hide();
          $('.nav-tabs a[href="#tab-1"]').tab('show')
          swal.fire("Success", "Saved Successfully", "success");
          await this.getAllUndertakings()
          await this.refresh()
         this.gotolist();
        } else {
          this.spinner.hide();
          swal.fire("Error", "Error while adding", "error");
        }
      } else {
        swal.fire("Error", "Please Select All Fields", "error");

      }

    } else {
      swal.fire("Warning...", "Record of this document with this role is already exist..!", "warning");
    }

  }
  async update() {
    if (this.updateObj['doc_type'] && this.updateObj['role_cd']) {
      let obj:any = {}
      obj = this.updateObj
      obj['b_acct_id'] = this.b_acct_id;
      obj['create_user_id'] = this.ebillUser.user_id;
      obj['module_cd'] = 'EMB';
      obj['data'] = JSON.stringify(this.data)
      console.log(obj, 'dsgjhgduyeguy');

      var resp = await this.undertakings.updateUndertakings(obj);
      console.log(resp)
      if (resp['error'] == false) {
        this.getAllUndertakings()
        await this.changeList()
        this.spinner.hide();
        $('.nav-tabs a[href="#tab-1"]').tab('show')
        swal.fire("Success", "Updated Successfully", "success");
       this.gotolist()

      } else {
        this.spinner.hide();
        swal.fire("Error", "Error while updating", "error");
      }
    } else {
      swal.fire("Error", "Please Select All Fields", "error");

    }

  }

  changeList() {
    let dummy = []
    this.datasource = null
    if (this.Obj['role_cd'] && this.Obj['doc_type']) {

      for (let i = 0; i < this.undertakingList.length; i++) {
        if (this.undertakingList[i]['fields'] == this.bill_amt[0]['key']) [
          this.undertakingList[i]['field_value'] = this.bill_amt[0]['value']
        ]
        if (this.undertakingList[i]['doc_type'] == this.Obj['doc_type'] && this.undertakingList[i]['role_cd'] == this.Obj['role_cd']) {
          console.log(this.undertakingList[i]);
          for (let j = 0; j < this.bill_amt.length; j++) {
            if (this.bill_amt[j]['key'] == this.undertakingList[i]['fields']) {
              this.undertakingList[i]['field_desc'] = this.bill_amt[j]['value']
            }
          }
          dummy.push(this.undertakingList[i])
          this.datasource = new MatTableDataSource(dummy);
          this.datasource.paginator = this.paginator;
          this.datasource.sort = this.sort;
        }
      }
      console.log(this.undertakingList);

    }

  }


  data:any = []
  addRow() {
    this.data.push({ desc: '' });
  }
  deleteRow(i:any) {
    this.data.splice(i, 1);
  }


  updateObj:any = {}
  updateData:any = []
  temp_selectedCodeValue:any = [];
  async open_update(element:any) {
    this.updateObj = element
    console.log(element)
    this.data = JSON.parse(element['data'])
    this.l_under_flag=false;
          this.c_under_flag=false;
          this.u_under_flag=true;
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.Obj['data'] = []
    this.updateObj = {}
    this.data = []
    this.datasource = null
    this.Obj['fields'] = null
    this.Obj['role_cd'] = null
    this.Obj['doc_type'] = null
  }
  allRoles:any;

  async getcurrentroleswithresources() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    console.log(obj)
    var resp = await this.mainService.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()

      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 0) {
          data.push(resp.data[i])
        }
      }
      console.log(data);
      this.allRoles = data;

    } else {
      this.spinner.hide()
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
