import { Component, OnInit, ViewChild } from '@angular/core';
 

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service'
declare var $: any
import swal from 'sweetalert2';
import { UserService } from '../../../service/user.service';
import { ExcelService } from '../../../service/file-export.service';
import { MainService } from '../../../service/main.service';
import { MainService as mainServiceMD } from '../../../../md/service/main.service';

@Component({
  selector: 'app-bud',
  templateUrl: './bud.component.html',
  styleUrls: ['./bud.component.css']
})
export class BudComponent implements OnInit {


  constructor(private mainServiceMD: mainServiceMD, private userService: UserService, private excl: ExcelService, private router: Router, private spinner: NgxSpinnerService, private referenceDataS: ReferenceDataService, private snackBar: MatSnackBar, public mainService: MainService) { }
  erpUser:any;
  b_acct_id:any;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = ['id', 'bud_cd', 'bud_desc', 'bud_amt', 'expense_amount', 'create_bud_amount', 'action'];
  datasource:any;
  bud:any = {}
  list_bud_flag=true;
  crete_bud_flag=false;
  update_bud_flag=false;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllBudget();
  }

  refresh() {
    this.bud = {}
  }

  data:any
  async getAllexportBudget(element:any) {
    this.spinner.show();
    console.log({ bud_cd: element.bud_cd, b_acct_id: this.b_acct_id })
    var resp = await this.referenceDataS.getlogOfbud(JSON.stringify({ bud_cd: element.bud_cd, b_acct_id: this.b_acct_id }));
    console.log(resp)
    if (resp['error'] == false) {

      this.data = resp.data
      await this.getUsersRoles()
      await this.export(element.bud_desc)
      this.spinner.hide()
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all budget list", 'Error', {
        duration: 5000
      });
    }
  }
  userRoles = [];
  role_name = []
  user:any = {}
  async getUsersRoles() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.mainServiceMD.getuserforrolemapping(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        dt[i]['desc'] = dt[i]['user_id'] + " - " + dt[i]['emp_name'];
        this.user[dt[i]['user_id']] = dt[i]['emp_name']
      }
      console.log(this.user)
      this.userRoles = dt
    } else {
      swal.fire("Oops", "Error while getting all users", "error");
    }
  }

  export(bud_desc:any) {
    var exp = []
    for (var i = 0; i < this.data.length; i++) {
      var obj:any = new Object();
      obj['SNO'] = i + 1;
      obj['Budget creation Date'] = this.data[i]['create_date'];
      obj['Created By'] = this.user[this.data[i]['create_user_id']];
      obj['Budget Description'] = bud_desc//this.data[i]['designation_code'];
      obj['Budget Amount'] = this.data[i]['bud_amt'];
      obj['Update Budget Amount'] = this.data[i]['create_bud_amt'];
      obj['Update Budget date'] = this.data[i]['update_timestamp'];
      obj['Budget Code'] = this.data[i]['bud_cd'];
      obj['Create Time'] = this.data[i]['create_time'];
      obj['Update Time'] = this.data[i]['time'];
      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'Budget_log')
  }
  available_amount = 0;
  async checkForBudget(bud_code:any, total_payable_amount:any, exp_amt:any) {
    var flag = false;
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bud_cd'] = bud_code;
    console.log(obj);


    var resp = await this.referenceDataS.getAllExpenseOnBud(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.available_amount = 0;
      if (resp['data'][0]['bill_expense_amount'] != null) {
        this.available_amount = total_payable_amount - exp_amt - resp['data'][0]['bill_expense_amount']
      } else {
        this.available_amount = total_payable_amount - exp_amt
      }
      console.log(this.available_amount);

      if (this.available_amount > 0) {
        flag = true;
      }

      console.log(total_payable_amount);

    } else {
      //this.toastr.errorToastr(resp['data'])
    }
    return flag;
  }
  async getAllBudget() {
    this.spinner.show()
    var resp = await this.referenceDataS.getBudget(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {

      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all budget list", 'Error', {
        duration: 5000
      });
    }
  }
  async create() {
    let validate = 0
    if (this.bud['bud_cd'] == null || this.bud['bud_desc'] == null ||
      this.bud['bud_amt'] == null || this.bud['expense_amount'] == null ||
      this.bud['bud_cd'] == undefined || this.bud['bud_desc'] == undefined ||
      this.bud['bud_amt'] == undefined || this.bud['expense_amount'] == undefined) {
      validate = 1
    }

    if (validate == 0) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.bud
      obj['b_acct_id'] = this.b_acct_id
      obj['create_user_id'] = this.erpUser.user_id
      var resp = await this.referenceDataS.createBudget(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllBudget()
        this.snackBar.open("Budget Added Successfully!", 'Success', {
          duration: 5000
        });
     this.gotolist()
  this.update_bud_flag=false
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Adding Budget", 'Error', {
          duration: 5000
        });
      }
    } else {
      swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }
    

  }

  async update() {
    let validate = 0
    if (this.bud['bud_cd'] == null || this.bud['bud_desc'] == null ||
      this.bud['bud_amt'] == null || this.bud['expense_amount'] == null ||
      this.bud['bud_cd'] == undefined || this.bud['bud_desc'] == undefined ||
      this.bud['bud_amt'] == undefined || this.bud['expense_amount'] == undefined) {
      validate = 1
    }
    if (!(await this.checkForBudget(this.bud['bud_cd'], this.bud['bud_amt'], this.bud['expense_amount']))) {
      swal.fire('Warning...', 'budget Amount is not Less Than Expence Amount! ', 'warning');
      return;
    }
    if (validate == 0) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.bud
      obj['b_acct_id'] = this.b_acct_id
      obj['update_user_id'] = this.erpUser.user_id
      var resp = await this.referenceDataS.updateBudget(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllBudget()
        this.snackBar.open("Budget Updated Successfully!", 'Success', {
          duration: 5000
        });
        this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Updating Budget", 'Error', {
          duration: 5000
        });
      }
    } else {
      swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }
        this.list_bud_flag=true;
    this.crete_bud_flag=false;
  this.update_bud_flag=false

  }
  expense_amount:any
  open_update(element:any) {
    this.list_bud_flag=false;
    this.crete_bud_flag=false;
  this.update_bud_flag=true

    this.bud = Object.assign({}, element);
    this.expense_amount = this.bud['expense_amount']
   // $('.nav-tabs a[href="#tab-2"]').tab('show')

  
  }

  gotocrete(){
    this.bud = {}
    this.list_bud_flag=false;
    this.crete_bud_flag=true;
  this.update_bud_flag=false

  }
  async gotolist(){
    this.list_bud_flag=true;
    this.crete_bud_flag=false;
  this.update_bud_flag=false
  await this.getAllBudget();

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  async  delete(element:any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }


  async finaldelete(e:any) {
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = e['id']
    var resp = await this.referenceDataS.deleteBudget(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllBudget();
      this.snackBar.open("Budget Deleted Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while deleting Budget", 'Error', {
        duration: 5000
      });
    }
  }

}
