import { Component, OnInit, ViewChild } from '@angular/core';
import { JoiningService } from '../../service/joining.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../../../authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatStepper } from '@angular/material/stepper';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { ExcelService } from '../../service/file-export.service';
import * as moment from 'moment';
import { AllEmpService } from '../../service/all-emp.service';
import { DataAccessService } from '../../service/data-access.service';
import { HrHierService } from '../../service/hr-hier.service';
import * as XLSX from "xlsx";
import { Console } from 'console';
import { EstablishService } from '../../service/establish.service';
import { SettingService } from '../../service/setting.service';
import { NgxSpinnerService } from "ngx-spinner"
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from '../../service/main.service';
declare var $: any;
@Component({
  selector: 'app-show-data',
  templateUrl: './show-data.component.html',
  styleUrls: ['./show-data.component.css']
})
export class ShowDataComponent implements OnInit {

  constructor(private estNewService: EstablishService, private dataAccessService: DataAccessService, private settingService: SettingService, private hrHierService: HrHierService, public mainService: MainService,private joiningservice: JoiningService, public auth: AuthenticationService, private MD_mainService: MD_mainService, private excl1: ExcelService, private allEmpService: AllEmpService, private snackBar: MatSnackBar,private spinner: NgxSpinnerService) { }
  auth1:any  = [{ code: "VDA", value: "28" }];
  allcomp :any = [{ code: "JOINING", value: "JOINING" }, { code: "ESTABLISHMENT", value: "ESTABLISHMENT" }];
  erpUser:any
  email:any
  // displayedColumns = ['emp_id', 'emp_name',  'action'];

  displayedColumns:any  = ['emp_id', 'emp_name', 'emp_dob', 'emp_email', 'emp_phone_no', 'action'];
  datasource:any ;
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  b_acct_id:any ;
  user_id:any ;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    console.log(this.erpUser)
    this.email = this.erpUser['contact_phone']
    console.log(this.email)
    this.user_id = this.erpUser.user_id;
    this.b_acct_id = this.erpUser.b_acct_id
    await this.login()
    await this.getAccountProjectHier()
    await this.getAllRuleInfo()
    await this.getDate()
    // await this.getEmpolyeeEffectiveArrangemt()
    this.b_acct_id = this.erpUser.b_acct_id;
    this.allcomp
    console.log(this.allcomp)
    console.log(this.objcomp['comp'])
    // await this.getAllAccountModule()
    // this.planing_arrproj=[{ hierarchy_type:1, is_leaf:1, level:1,existing_heirarchy_nodes:[{node_cd: 1, desc: 'P1 - HR-root - ROOT', is_leaf: 0}]},
    //                       {hierarchy_type:5871,is_leaf:1,level:2,existing_heirarchy_nodes:[{desc:"VDA - VDA_EMP - Cost Center", is_leaf:1,node_cd:5871}]}]
    // this.planing_arrproj=[{ hierarchy_type:1, is_leaf:1, level:1,existing_heirarchy_nodes:[{node_cd: 1, desc: 'P1 - HR-root - ROOT', is_leaf: 0}]}]

    console.log(this.planing_arrproj, " planning")
    await this.getEmpolyeeEffectiveArrangemt();
    await this.getAllRuleInfo();
    await this.getAllPayMatrix();
    await this.getAllHRAMatrix();
    await this.getAllGISMatrix();
    await this.getAllCCAMatrix();
    await this.getAllCityMatrix();

    await this.getAllPRBMatrix();
    await this.getAllWFMatrix();
  }
  join_flag: boolean = false
  establish_flag: boolean = false
  changecomp() {
    console.log(this.objcomp['comp'])
    if (this.objcomp['comp'] == 'JOINING') {
      this.join_flag = true
      this.establish_flag = false
    }
    else if (this.objcomp['comp'] == 'ESTABLISHMENT') {
      this.join_flag = false
      this.establish_flag = true
    }
  }

  DOWNLOADjoin() {
    var exp1 = []
    var obj :any  = new  Object();
    // obj['SNO'] = i + 1;
    obj['emp_id'] = '';
    obj['emp_name'] = '';
    // obj['emp_name'] = this.alldata[i]['emp_name']
    obj['emp_dob'] = '';
    obj['emp_email'] = '';
    obj['emp_phone_no'] = '';
    // obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
    obj['emp_father_name'] = '';
    obj['emp_permanent_addr_line1'] = ''
    obj['emp_permanent_addr_line2'] = ''
    obj['emp_permanent_addr_city'] = ''
    obj['emp_permanent_addr_dist'] = ''
    obj['emp_permanent_addr_state'] = ''
    obj['emp_permanent_addr_pin_code'] = ''
    obj['emp_local_addr_line1'] = ''
    obj['emp_local_addr_line2'] = ''
    obj['emp_local_addr_city'] = ''
    obj['emp_local_addr_dist'] = ''
    obj['emp_local_addr_state'] = ''
    obj['emp_local_addr_pin_code'] = ''
    obj['pin'] = ''
    obj['emp_pan_no'] = ''
    obj['emp_adhar_no'] = ''
    obj['emp_gst_no'] = ''
    obj['gis_no'] = ''
    obj['nps_no'] = ''
    obj['bank_code'] = ''
    obj['branch_code'] = ''
    obj['ifsc_code'] = ''
    obj['nps_no'] = ''
    obj['acct_no'] = ''
    obj['pf_acct_no'] = ''
    obj['pf_type'] = ''
    obj['pf_ifsc_code'] = ''
    obj['reservation_category_code'] = ''
    obj['marital_status'] = ''
    obj['emp_husband_name'] = ''
    obj['emp_religeon'] = ''
    obj['emp_nationality'] = ''
    obj['emp_mother_name'] = ''
    obj['emp_cat_code'] = ''
    obj['identification_mark'] = ''
    // obj['identification_mark']=this.alldata[i]['identification_mark']
    obj['valid_from'] = ''
    obj['valid_upto'] = ''
    obj['txn_start'] = ''
    obj['txn_end'] = ''
    obj['status'] = ''
    obj['user_id'] = ''
    obj['life_cycle_status'] = ''
    obj['lvl1_cd'] = ''
    obj['lvl1_value'] = ''
    obj['lvl1_node_type'] = ''
    obj['lvl1_user_cd'] = ''
    obj['lvl2_cd'] = ''
    obj['lvl2_value'] = ''
    obj['lvl2_node_type'] = ''
    obj['lvl2_user_cd'] = ''
    obj['hierarchy_type'] = ''
    // obj['emp_id'] = '1298';
    // obj['emp_name'] = 'swati';
    // // obj['emp_name'] = this.alldata[i]['emp_name']
    // obj['emp_dob'] = '01-01-2000';
    // obj['emp_email'] = 'sw@gmail.com';
    // obj['emp_phone_no'] = '6385741296';
    // // obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
    // obj['emp_father_name'] = 'abc';
    // obj['emp_permanent_addr_line1'] = 'lucknow'
    // obj['emp_permanent_addr_line2'] = 'lucknow'
    // obj['emp_permanent_addr_city'] = 'lucknow'
    // obj['emp_permanent_addr_dist'] = ''
    // obj['emp_permanent_addr_state'] = ''
    // obj['emp_permanent_addr_pin_code'] = ''
    // obj['emp_local_addr_line1'] = ''
    // obj['emp_local_addr_line2'] = ''
    // obj['emp_local_addr_city'] = ''
    // obj['emp_local_addr_dist'] = ''
    // obj['emp_local_addr_state'] = ''
    // obj['emp_local_addr_pin_code'] = ''
    // obj['pin'] = ''
    // obj['emp_pan_no'] = ''
    // obj['emp_adhar_no'] = ''
    // obj['emp_gst_no'] = ''
    // obj['gis_no'] = ''
    // obj['nps_no'] = ''
    // obj['bank_code'] = ''
    // obj['branch_code'] = ''
    // obj['ifsc_code'] = ''
    // obj['nps_no'] = ''
    // obj['acct_no'] = ''
    // obj['pf_acct_no'] = ''
    // obj['pf_type'] = ''
    // obj['pf_ifsc_code'] = ''
    // obj['reservation_category_code'] = ''
    // obj['marital_status'] = ''
    // obj['emp_husband_name'] = ''
    // obj['emp_religeon'] = ''
    // obj['emp_nationality'] = ''
    // obj['emp_mother_name'] = ''
    // obj['emp_cat_code'] = ''
    // obj['identification_mark'] = ''
    // // obj['identification_mark']=this.alldata[i]['identification_mark']
    // obj['valid_from'] = ''
    // obj['valid_upto'] = ''
    // obj['txn_start'] = ''
    // obj['txn_end'] = ''
    // obj['status'] = 'ACITVE'
    // obj['user_id'] = '02'
    // obj['life_cycle_status'] = 'ACITVE'
    // obj['lvl1_cd'] = 1
    // obj['lvl1_value'] = 'All Projects'
    // obj['lvl1_node_type'] = 'ROOT'
    // obj['lvl1_user_cd'] = 'P1'
    // obj['lvl2_cd'] = 66
    // obj['lvl2_value'] = 'Gomati Nagar'
    // obj['lvl2_node_type'] = 'CostCenter'
    // obj['lvl2_user_cd'] = 'VMS_GOMATI'
    // obj['hierarchy_type'] = 66
    exp1.push(obj);

    this.excl1.exportAsExcelFile(exp1, "download joining")


  }
  DOWNLOADest() {
    this.exp = []
    // for (var i = 0; i < this.allestdata.length; i++) {
    var obj :any  = new  Object();
    // obj['SNO'] = i + 1;
    obj['arr_id'] = ''
    obj['party_le_id'] = ''
    obj['cp_le_id'] = ''
    obj['emp_id'] = ''
    obj['src_cd'] = ''
    obj['arr_instr_cd'] = ''
    // obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
    obj['emp_name'] = ''
    obj['emp_dob']=''
    obj['employee_current_type_code'] = ''
    obj['establishment_type_code'] = ''
    obj['level_code'] = ''
    obj['basic_pay'] = ''
    obj['cadre_code'] = ''
    obj['class_code'] = ''
    obj['pay_commission_code'] = ''
    obj['grade_pay_code'] = ''
    obj['pay_scale_code'] = ''
    obj['emp_status_code'] = ''
    obj['retirement_age'] = ''
    obj['designation_code'] = ''
    obj['effective_timestamp'] = ''
    obj['order_id'] = ''
    obj['promotion_type_code'] = ''
    obj['inc_month'] = ''
    obj['joining_date'] = ''
    obj['joining_type_code'] = ''
    obj['joining_service_date'] = ''
    obj['retirement_date'] = ''
    obj['ordering_authority'] = ''
    obj['probation_end_dt'] = ''
    obj['probation_feedback'] = ''
    obj['notice_period'] = ''
    obj['designation_group_code'] = ''
    obj['uniform_ind'] = ''
    obj['conv_code'] = ''
    obj['conv_amt']=''
    obj['family_planing'] = ''

    obj['joining_time'] = ''
    obj['leave_time'] = ''
    obj['work_city'] = ' '
    obj['dep_work_city'] = ''
    obj['valid_from'] = ''
    obj['valid_upto'] = ''

    obj['txn_start'] = ''

    obj['txn_end'] = ''
    obj['status'] = ''
    // obj['user_id']=this.alldata[i]['create_user_id']
    obj['life_cycle_status'] = ''
    obj['user_id'] = ''
    obj['notice_period']=''


    this.exp.push(obj);
    // }
    this.excl1.exportAsExcelFile(this.exp, "download establishmentexcel format")
  }
  check:any
  setHierarchyNodeitem(item:any , i:any ) {
    console.log(item, i);
    this.check = item['hierarchy_type']
    console.log(this.check);
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]
    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
    }
    this.Obj['path_desc'] = dummy2.join(' --> ');

  }
  submit_act() {
    console.log(this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']);
    if (this.planing_arrproj.length < 2 || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null || this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type'] == null) {
      Swal.fire("Warning", "Please Select at least two level", 'warning')
    }
    else {

      $('#ACTHIER').modal('hide');
    }
    this.joiningObj['node_cd'] = this.planing_arrproj[this.planing_arrproj.length - 1]['hierarchy_type']
    console.log(this.joiningObj['node_cd'], " node code")
  }
  objauth:any  = {}
  alldata :any = []
  alldata1:any  = []
  async submit() {
    console.log(this.objauth["auth"])
    var obj :any = {}
    obj["b_acct_id"] = this.objauth["auth"]
    var resp = await this.joiningservice.getvdadata(obj)
    console.log(resp, "shweta data--")
    if (resp["error"] == false) {
      console.log(resp['data'])
      this.alldata = resp['data']

    }
    this.datasource = new MatTableDataSource(this.alldata)
    console.log(this.datasource, " datasource")
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
    await this.getAllAccountModule()
  }
  allestdata:any  = []
  allesterpdata :any = []
  async submitestablish() {
    var obj:any  = {}
    obj["b_acct_id"] = this.objauth["auth"]
    obj["emp_status_code"] = 'ACTIVE'
    var resp = await this.joiningservice.getvdaestablishdata(obj)
    console.log(resp)
    if (resp["error"] == false) {
      this.allestdata = resp["data"]
      console.log(this.allestdata, " this.allestdata")
      var obj1:any  = {}
      obj1["b_acct_id"] = this.b_acct_id
      var resp1 = await this.joiningservice.geterpstablishdata(obj1)
      console.log(resp1, "resp1 dataa---")
      if (resp1["error"] == false) {
        this.allesterpdata = resp1['data']
        console.log(this.allesterpdata, "this.allesterpdata dataa---")

        this.allestdata = this.allestdata.map((e:any , i:any ) => {
          console.log(e)
          let temp = this.allesterpdata.find((element:any ) => {
            if (element.emp_id == e.emp_id) {
              //  console.log(temp,"shweta temp ")

              e.arr_id = element.arr_id;
              e.party_le_id = element.party_le_id
              e.cp_le_id = element.cp_le_id
              e.src_cd = element.src_cd
              e.arr_instr_cd = element.arr_instr_cd
              e.valid_from = element.valid_from
              e.valid_upto = element.valid_upto
              e.txn_start = element.txn_start
              e.txn_end = element.txn_end
              e.user_id = element.user_id
            }
            // return e;
          })
          return e;
          // this.DistArr=this.DistArr.map({

          // })
        })

      }
      console.log(this.allestdata, "shweta ojha oppppp---")
    }
    console.log(this.allestdata, "shweta ojha oppppp")
  }
  exp:any  = []
  exclestablish() {
    // var exp = []
    this.exp = []
    for (var i = 0; i < this.allestdata.length; i++) {
      var obj :any  = new  Object();
      // obj['SNO'] = i + 1;
      obj['arr_id'] = this.allestdata[i]['arr_id'];
      obj['party_le_id'] = this.allestdata[i]['party_le_id'];
      obj['cp_le_id'] = this.allestdata[i]['cp_le_id']
      obj['emp_id'] = this.allestdata[i]['emp_id'];
      obj['src_cd'] = this.allestdata[i]['src_cd'];
      obj['arr_instr_cd'] = this.allestdata[i]['arr_instr_cd'];
      // obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
      obj['emp_name'] = this.allestdata[i]['emp_name'];
      obj['employee_current_type_code'] = this.allestdata[i]['employee_current_type_code']
      obj['establishment_type_code'] = this.allestdata[i]['establishment_type_code']
      obj['level_code'] = this.allestdata[i]['level_code']
      obj['basic_pay'] = this.allestdata[i]['basic_pay']
      obj['cadre_code'] = this.allestdata[i]['cadre_code']
      obj['class_code'] = this.allestdata[i]['class_code']
      obj['pay_commission_code'] = this.allestdata[i]['pay_commission_code']
      obj['grade_pay_code'] = this.allestdata[i]['grade_pay_code']
      obj['pay_scale_code'] = this.allestdata[i]['pay_scale_code']
      obj['emp_status_code'] = this.allestdata[i]['emp_status_code']
      obj['retirement_age'] = this.allestdata[i]['retirement_age']
      obj['designation_code'] = this.allestdata[i]['designation_code']
      obj['effective_timestamp'] = this.allestdata[i]['effective_timestamp']
      obj['order_id'] = this.allestdata[i]['order_id']
      obj['promotion_type_code'] = this.allestdata[i]['promotion_type_code']
      obj['inc_month'] = this.allestdata[i]['inc_month']
      obj['joining_date'] = this.allestdata[i]['joining_date']
      obj['joining_type_code'] = this.allestdata[i]['joining_type_code']
      obj['joining_service_date'] = this.allestdata[i]['joining_service_date']
      obj['retirement_date'] = this.allestdata[i]['retirement_date']
      obj['ordering_authority'] = this.allestdata[i]['ordering_authority']
      obj['probation_end_dt'] = this.allestdata[i]['probation_end_dt']
      obj['probation_feedback'] = this.allestdata[i]['probation_feedback'];
      obj['notice_period'] = this.allestdata[i]['notice_period']
      obj['designation_group_code'] = this.allestdata[i]['designation_group_code']
      obj['uniform_ind'] = this.allestdata[i]['uniform_ind']
      obj['conv_code'] = this.allestdata[i]['conv_code']
      obj['family_planing'] = this.allestdata[i]['family_planing']

      obj['joining_time'] = this.allestdata[i]['joining_time']
      obj['leave_time'] = this.allestdata[i]['leave_time']
      obj['work_city'] = ' '
      obj['dep_work_city'] = ''
      obj['valid_from'] = this.allestdata[i]['valid_from']
      obj['valid_upto'] = this.allestdata[i]['valid_upto']

      obj['txn_start'] = this.allestdata[i]['txn_start']

      obj['txn_end'] = this.allestdata[i]['txn_end']
      obj['status'] = ''
      // obj['user_id']=this.alldata[i]['create_user_id']
      obj['life_cycle_status'] = ''
      obj['user_id'] = this.allestdata[i]['user_id']
      this.exp.push(obj);
    }
    this.excl1.exportAsExcelFile(this.exp, "download establishmentexcel")
  }

  allProject:any  = [];
  planing_arrproj :any = []
  projecthier:any  = []
  projhierobj:any  = {}
  projhierobjtype:any  = {}
  projhierobjtypecd:any  = {}
  projhierobjisleaf :any = {}
  async getAccountProjectHier() {

    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpService.getAccountProjectHier(obj);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {

        var tt = []
        for (let k = 1; k < 8; k++) {
          if (resp.data[i]['lvl' + k + '_cd'] != null) {
            tt.push(resp.data[i]['lvl' + k + '_cd'])
          }
        }
        this.dataAccessService.data_assigment = this.dataAccessService.data_assigment.filter((x:any ) => x.user_id == this.user_id)
        var exit_flag = false;
        for (let j = 0; j < this.dataAccessService.data_assigment.length; j++) {
          if (tt.includes(parseInt(this.dataAccessService.data_assigment[j]['node_cd']))) {
            exit_flag = true;
          }
        }

        if (tt.length == 1) {
          exit_flag = true;
        }
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined) && exit_flag) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      this.allProject = resp.data;
      this.projecthier = resp['data']
      console.log(this.projecthier, "shweta projecthier")
      console.log(this.allProject, "shweta this.allProject")
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_user_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
        }
      }
      console.log(this.projhierobj, "shweta this.projhierobj")
      console.log(this.projhierobjtype, "shweta this.projhierobjtype")
      console.log(this.projhierobjtypecd, "shweta this.projhierobjtypecd")
      console.log(this.projhierobjisleaf, "shweta this.projhierobjisleaf")
    } else {
      console.log("Error in getting all project hier")
    }
  }

  emp_id :any = []
  emp_name :any = []
  emp_Arr_id:any  = []
  uploaded_file_data :any = []
  objcomp:any  = {}
  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = []
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data)
      this.alldata = data
      console.log(this.alldata)

      // this.setData(data);
      // Data will be logged in array format containing objects
    };
  }
  // uploaded_file_data = []
  // async setData(data) {
  //   this.uploaded_file_data = []
  //   let sum_lat = 0
  //   let sum_lng = 0
  //   //console.log(data)
  //   let dummy = []
  //   for (let i = 1; i < data.length; i++) {
  //     let obj = {}
  //     obj['loc_ln_seq_num'] = 1
  //     obj['loc_pt_seq_num'] = 1
  //     obj['loc_gis_x_long'] = data[i][3]
  //     obj['loc_gis_y_lat'] = data[i][4]
  //     let obj2 = {}
  //     obj2['loc_ln_seq_num'] = 1
  //     obj2['loc_pt_seq_num'] = 2
  //     obj2['loc_gis_x_long'] = data[i][6]
  //     obj2['loc_gis_y_lat'] = data[i][7]
  //     // console.log()
  //     // this.lat = obj2['loc_gis_y_long']
  //     // this.lng = obj2['loc_gis_x_lat']

  //     dummy.push(obj)
  //     dummy.push(obj2)
  //   }
  //   // console.log(sum_lat/data.length)
  //   // console.log(sum_lng/data.length)
  //   // this.lat = sum_lat/data.length
  //   // this.lng = sum_lng/data.length
  //   // this.lat = obj2['loc_gis_y_long']
  //   // this.lng = obj2['loc_gis_x_lat']
  //   this.uploaded_file_data = dummy

  //   //console.log(dummy, 'set data')
  // }




  refres() {
    this.objcomp={}
    this.establish_flag=false
    this.join_flag=false
  }
  async submitdata() {
    // this.datasource = new MatTableDataSource(this.alldata)
    // console.log(this.datasource,"shweta datasource")
    // this.datasource.paginator = this.paginator;
    // this.datasource.sort = this.sort;
    // await this.getAllAccountModule()
    $('#myModal').modal('show')

  }
  async submitalldata() {
    console.log(this.alldata, "shweta console.log(this.alldata1)")
    var obj :any  = new  Object()
    obj["b_acct_id"] = this.b_acct_id
    // console.log(this.alldata, "shweta alldata")
    for (let i = 0; i < this.alldata.length; i++) {
      this.alldata1[i] = this.alldata[i]
      this.alldata1[i]["emp_dob"] = moment(this.alldata[i]["emp_dob"]).format('YYYY-MM-DD')
    }
    console.log(this.alldata1, " all data")
    obj["alldata"] = this.alldata1
    obj["life_cycle_status"] = 'ACTIVE'
    var post_data = [];
    // for (let i = 0; i < this.projecthier.length; i++) {
    //   if (this.planing_arrproj[1]['hierarchy_type'] == this.projecthier[i]['leaf_cd']) {
    //     // temp_module = this.projecthier[i]['module_cd']
    //     post_data.push({ cost_center: this.projecthier[i]['leaf_user_cd'], module_cd: 'HR', is_primary: 1 })
    //   }
    // }
    // obj["posting_data"] =post_data
    // [{cost_center: 'CD-K-01', module_cd: 'EMB', is_primary: 1}]
    obj['role_data'] = ['HR_EMP'];
    console.log(obj, "insert all data ")

    var obj1:any  = {}
    var a :any = this.alldata1.length - 1
    for (let i = 0; i < this.alldata1.length; i++) {
      if (obj1[i] == undefined) {
        obj1[i] = {}
        obj1[i]["b_acct_id"] = this.b_acct_id
        obj1[i]["alldata"] = this.alldata1[i]
        obj1[i]["life_cycle_status"] = 'ACTIVE'
        for (let j = 0; j < this.projecthier.length; j++) {
          if (this.alldata1[i]['hierarchy_type'] == this.projecthier[j]['leaf_cd']) {
            // temp_module = this.projecthier[i]['module_cd']
            post_data.push({ cost_center: this.projecthier[j]['leaf_user_cd'], module_cd: 'HR', is_primary: 1 })
          }
        }
        obj1[i]["posting_data"] = post_data
        // obj1[i]["posting_data"] =[{cost_center: 'CD-K-01', module_cd: 'EMB', is_primary: 1}]
        obj1[i]['role_data'] = ['HR_EMP'];
        obj1[i]['node_cd'] = this.alldata1[i]['hierarchy_type']
        // obj1[i]['user_id'] = 'User@1'
        obj1[i]['user_id'] =this.erpUser.le_id

        console.log(obj1[i], "shweta---")
        var resp = await this.joiningservice.insertalldata(obj1[i])
        console.log(resp, "resp data---")
        //  $('#myModal').modal('show')
        if (resp["error"] == false) {
          // for(let i=0;i<resp['data']['emp_arr_id'].length;i++)
          // {
          //   this.emp_name[i]=this.alldata1[i]['emp_name']
          // }

          //   this.emp_name=['Rahul Pandey', 'Isha Duhan']
          //   this.emp_Arr_id=[5759, 5760]
          //   // await this.getAccountProjectHier()
          await this.addAccountHier(resp.data, this.alldata1[i]['emp_name'], this.alldata1[i])
          // await this.dataAccessService.getCurrentDataAccess(this.b_acct_id,this.erpUser['user_id'])
          this.spinner.show()
          if (i == a) {
            this.spinner.hide()
          }
        }
      }
    }


    // s var resp = await this.joiningservice.insertalldata(obj)
    //  console.log(resp,"resp data---")
    // //  $('#myModal').modal('show')
    //  if(resp["error"]==false){
    //     for(let i=0;i<resp['data']['emp_arr_id'].length;i++)
    //     {
    //       this.emp_name[i]=this.alldata1[i]['emp_name']
    //     }

    //   //   this.emp_name=['Rahul Pandey', 'Isha Duhan']
    //   //   this.emp_Arr_id=[5759, 5760]
    //   //   // await this.getAccountProjectHier()
    //   await this.addAccountHier(resp.data, this.emp_name)
    //   await this.dataAccessService.getCurrentDataAccess(this.b_acct_id,this.erpUser['user_id'])

    //  }//s
    //  this.emp_name=['Rahul Pandey', 'Isha Duhan']
    //     this.emp_Arr_id=[5759, 5760]
    //     // await this.getAccountProjectHier()
    //   await this.addAccountHier(this.emp_Arr_id, this.emp_name)
  }
  submitnodedata() {
    console.log(this.alempdata)
  }
  final_obj1 = []
  arr2 = [{}]
  arr = []
  // async addAccountHier(final_obj, emp_name) {

  //   console.log(final_obj)
  //   console.log(emp_name)
  //   let level = 0
  //   this.final_obj1=final_obj['emp_arr_id']
  //   //  this.final_obj1=final_obj
  //   let obb :any = new Object()
  //   // obb['b_acct_id'] = this.b_acct_id

  //   for(let j=0;j<this.final_obj1.length;j++){

  //     // obb['b_acct_id'] = this.b_acct_id
  //     if(obb[j]==undefined){
  //       obb[j]={}
  //       obb[j]['b_acct_id'] = this.b_acct_id
  //   for (let i = 0; i < this.planing_arrproj.length; i++) {
  //     console.log(this.planing_arrproj[i])
  //     //obb['lvl'+ this.planing_arrproj[i]['level']+'_user_cd'] = this.planing_arrproj[i]['level']
  //     if (this.planing_arrproj[i]['level'] == 1) obb[j]['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'

  //     obb[j]['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
  //     obb[j]['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
  //     obb[j]['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
  //     obb[j]['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
  //     console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
  //     level = this.planing_arrproj[i]['level']

  //   }

  //   obb[j]['b_acct_id'] = this.erpUser.b_acct_id;
  //   obb[j]['create_user_id'] = this.erpUser.user_id;

  //   obb[j]['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + this.final_obj1[j]
  //   obb[j]['lvl' + (level + 1) + '_node_type'] = 'HREMP' //obb['node_type']
  //   obb[j]['lvl' + (level + 1) + '_value'] = emp_name[j]
  //   obb[j]['lvl' + (level + 1) + '_type'] = 'text'
  //   obb[j]['leaf_user_cd'] = 'HRARR' + this.final_obj1[j]
  //   obb[j]['leaf_node_type'] = 'HREMP' //obb['node_type']
  //   obb[j]['leaf_value'] = emp_name[j]
  //   obb[j]['leaf_type'] = 'text'
  //   obb[j]['module_cd'] = 'HR'
  //   console.log(obb[j]);
  //   // for(let i=0;i<this.final_obj1.length;i++){
  //   //   obb['lvl' + (level + 1) + '_user_cd'].push('HRARR' + this.final_obj1[i])
  //   //   obb['leaf_user_cd'].push('HRARR' + this.final_obj1[i])
  //   //   obb['lvl' + (level + 1) + '_value'].push(emp_name[i])
  //   //   obb['leaf_value'].push(emp_name[i])
  //   // }
  //   let avi=this.final_obj1.map((x)=>
  //   {
  //     return 'HRARR' + x;
  //   })
  //   // obb['lvl' + (level + 1) + '_user_cd']=avi;
  //   // obb['leaf_user_cd']='HRARR' + this.final_obj1
  //   // obb['lvl' + (level + 1) + '_value']=emp_name
  //   // obb['leaf_value']=emp_name
  //   // this.arr2=obb
  //   this.arr.push(obb[j])
  // }
  // }
  //   console.log(this.arr,"shweta obb1")
  //   console.log(obb,"shweta obb")
  //   console.log(this.arr2,"shweta obb arr")
  //   var obb1={}
  //   // obb1["arr1"]=this.arr
  //   console.log(obb1,"shweta obb1")
  //   for(let i =0;i<this.arr.length;i++){
  //     if(obb1[i]==undefined)
  //     {obb1[i]={}

  //     obb1[i]["arr1"]=this.arr[i]
  //     console.log(obb1,"shweta obb1")
  //   var resp = await this.allEmpService.createAccountproject1(obb1[i]["arr1"]);
  //   console.log(resp,"Account Hier Project Added...")
  //     }

  //   if (resp['error'] == false) {
  //     console.log(resp.data);
  //     console.log('Account Hier Project Added...')
  //     await this.UpdateNodeCode(resp.data, final_obj)
  //   } else {
  //     console.log('Error in Account Hier Project Added...')
  //     Swal.fire("Sorry", resp.data, 'warning');

  //   }
  // }
  // }
  async addAccountHier(final_obj:any , emp_name:any , planing_arrproj1:any ) {

    console.log(planing_arrproj1)
    this.planing_arrproj.push(planing_arrproj1)
    console.log(final_obj)
    console.log(emp_name)
    let level = 0
    let obb :any = new Object()
    obb['b_acct_id'] = this.b_acct_id

    // for (let i = 0; i < this.planing_arrproj.length; i++) {
    //   console.log(this.planing_arrproj[i])
    //   //obb['lvl'+ this.planing_arrproj[i]['level']+'_user_cd'] = this.planing_arrproj[i]['level']
    //   if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'

    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
    //   console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
    //   level = this.planing_arrproj[i]['level']

    // }
    // for (let i = 0; i < this.planing_arrproj.length; i++) {
    //   console.log(this.planing_arrproj[i])
    //   //obb['lvl'+ this.planing_arrproj[i]['level']+'_user_cd'] = this.planing_arrproj[i]['level']
    //   if (this.planing_arrproj[i]['level'] == 1) obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = 'All Projects'

    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_cd'] = this.planing_arrproj[i]['hierarchy_type']
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_value'] = this.projhierobj[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_node_type'] = this.projhierobjtype[this.planing_arrproj[i]['hierarchy_type']]
    //   obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'] = this.projhierobjtypecd[this.planing_arrproj[i]['hierarchy_type']]
    //   console.log(this.planing_arrproj[i]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])
    //   level = this.planing_arrproj[i]['level']

    // }
    // if (this.planing_arrproj[0]['level'] == 1)
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb['lvl1' + '_value'] = 'All Projects'

      obb['lvl1' + '_cd'] = this.planing_arrproj[i]['lvl1_cd']
      obb['lvl1' + '_value'] = this.planing_arrproj[i]['lvl1_value']
      obb['lvl1' + '_node_type'] = this.planing_arrproj[i]['lvl1_node_type']
      obb['lvl1' + '_user_cd'] = this.planing_arrproj[i]['lvl1_user_cd']
      // if (this.planing_arrproj[0]['level'] == 1)
      obb['lvl2' + '_value'] = this.planing_arrproj[i]['lvl2_value']

      obb['lvl2' + '_cd'] = this.planing_arrproj[i]['lvl2_cd']

      obb['lvl2' + '_node_type'] = this.planing_arrproj[i]['lvl2_node_type']
      obb['lvl2' + '_user_cd'] = this.planing_arrproj[i]['lvl2_user_cd']
    }
    // console.log(this.planing_arrproj[1]['level'], obb['lvl' + this.planing_arrproj[i]['level'] + '_user_cd'])

    level = 2
    obb['b_acct_id'] = this.erpUser.b_acct_id;
    obb['create_user_id'] = this.erpUser.user_id;

    obb['lvl' + (level + 1) + '_user_cd'] = 'HRARR' + final_obj['emp_arr_id']
    obb['lvl' + (level + 1) + '_node_type'] = 'HREMP' //obb['node_type']
    obb['lvl' + (level + 1) + '_value'] = emp_name
    obb['lvl' + (level + 1) + '_type'] = 'text'
    obb['leaf_user_cd'] = 'HRARR' + final_obj['emp_arr_id']
    obb['leaf_node_type'] = 'HREMP' //obb['node_type']
    obb['leaf_value'] = emp_name
    obb['leaf_type'] = 'text'
    obb['module_cd'] = 'HR'
    console.log(obb);

    var resp = await this.allEmpService.createAccountproject1(obb);
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp.data);
      console.log('Account Hier Project Added...')
      await this.UpdateNodeCode(resp.data, final_obj)
    } else {
      console.log('Error in Account Hier Project Added...')
      Swal.fire("Sorry", resp.data, 'warning');

    }
  }
  async UpdateNodeCode(node_cd:any , final_obj:any ) {
    let proObj :any = {};
    proObj['b_acct_id'] = this.b_acct_id
    proObj['id'] = final_obj['emp_line_id'];
    proObj['node_cd'] = node_cd;
    console.log(proObj);
    var resp = await this.joiningservice.updateNodeCode1(proObj);
    console.log(resp, "shweta update node code ---")
    if (resp['error'] == false) {
      console.log('Node Code Updated....')
      await this.addDataAssnigment(node_cd, final_obj['le_id'])
    } else {
      console.log('Error in Node Code Updated....')
    }
  }
  async addDataAssnigment(node_cd:any , le_id:any ) {
    let proObj1 :any = {};
    proObj1['b_acct_id'] = this.b_acct_id
    proObj1['user_id'] = le_id;
    proObj1['node_cd'] = node_cd;
    proObj1['status'] = 'ACTIVE';
    proObj1['create_user_id'] = this.erpUser.user_id;

    console.log(proObj1);
    var resp = await this.hrHierService.insertHrdataAssign1(proObj1);
    console.log(resp, "datassignment")
    if (resp['error'] == false) {
      console.log("Data Assigment Done...")
    } else {
      console.log("Error in Data Assigment Done...")
    }
  }
  open_activitypopup() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1)
    }

    console.log("Open Pop Up")
    $('#ACTHIER').modal('show');
  }
  joiningObj :any = []

  down(item:any , k:any ) {
    this.joiningObj['module_cd'] = ['HR']
    console.log(k)
    console.log(item)
    console.log(this.planing_arrproj)
    console.log(this.joiningObj['module_cd'])
    let patharr = []
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      console.log(this.planing_arrproj[i])

      patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])


    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] })
    console.log(this.planing_arrproj, item)
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {

        if ((this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1) && this.projecthier[i]['leaf_node_type'] != 'HREMP') {

          if (k == 0) {
            arr = []
            for (let q = 0; q < this.joiningObj['module_cd'].length; q++) {
              if ((this.joiningObj['module_cd'][q] == this.projecthier[i]['module_cd']) && (this.projecthier[i]['lvl2_cd'] == this.projecthier[i]['leaf_cd'])) {
                console.log(this.projecthier[i])
                this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
                arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
              }
            }
          }
          else {
            this.planing_arrproj[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_user_cd'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_value'] + ' - ' + this.projecthier[i]['lvl' + (k + 2) + '_node_type'], is_leaf: this.projecthier[i]['is_leaf'] })
            arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])
          }
        }
      }
    }
  }

  remove(item:any , i:any ) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i])

        patharr.push(this.projhierobj[this.planing_arrproj[i]['hierarchy_type']])
      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  AllAccountData :any = [];
  assigned_accounts:any  = []
  alempdata :any = []
  open_modal( element:any ) {
    console.log(element)
    $('#myModal').modal('show');
    this.alempdata = element
    console.log(this.alempdata)
  }
  allModule = [];
  moduleObj = {}
  async getAllAccountModule() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.objauth["auth"]
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    console.log(resp, "module resp")
    if (resp['error'] == false) {
      this.allModule = resp.data;
      console.log(this.allModule, "shweta module")
      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
      console.log(this.moduleObj)
    } else {
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  async login() {

    // if (this.currentlyChecked == 0 || this.currentlyChecked == 1) {
    // var valid_mob = this.validatePhoneNumber(this.obj['email'])
    // var valid_email = this.validateEmail(this.obj['email']);

    // this.login_obj['login_type'] = 'PASSWORD';
    // this.login_obj['login_id'] = this.obj['email'];
    // this.login_obj['password'] = null;

    // if (valid_mob == true && this.currentlyChecked == 0) {
    var obj :any  = new  Object();
    obj['ident_to_verify'] = this.email;
    obj['ident_verify_method'] = 'P-KEY';
    obj['ident_verify_secret'] = 'User@1';
    // this.spinner.show();
    var resp = await this.auth.loginERP(obj);
    console.log(resp, "shweta")
    if (resp['error'] == false && resp['data'].length > 0) {
      this.AllAccountData = resp['data'];
      console.log(this.AllAccountData, "shweta account authority")
      // this.token = resp['token']['data'];

      for (let i = 0; i < this.AllAccountData.length; i++) {
        if (this.AllAccountData[i]['b_acct_id'] == 33) {
          this.AllAccountData[i]['b_acct_id'] = 28
        }
        this.AllAccountData[i]['acct_desc'] = this.AllAccountData[i]['b_acct_id'] + " - " + this.AllAccountData[i]['account_name']
        this.assigned_accounts.push(this.AllAccountData[i]['account_short_name'])
      }
      console.log(this.AllAccountData, "shweta account authority")

      //   if (resp['data'].length > 1) {
      //     await this.selectMultiAccount();
      //   } else {
      //     await this.selectOneAccount();
      //   }

      //   this.spinner.hide()
      // } else if (resp['data'].length == 0) {
      //   this.spinner.hide();
      //   this.login_obj['status'] = "FAIL";
      //   this.login_obj['user_id'] = null;
      //   this.login_obj['message'] = this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18'];

      //   await this.insertLoginInfo(this.login_obj);
      //   Swal.fire("Oops", this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18'], 'error');
      // } else {
      //   this.spinner.hide();
      //   this.login_obj['status'] = "FAIL";
      //   this.login_obj['user_id'] = null;
      //   this.login_obj['message'] = 'This Email Or Mobile Is Not Registered';

      //   await this.insertLoginInfo(this.login_obj);
      //   Swal.fire('Error...', 'This Email Or Mobile Is Not Registered', 'error')
      // }
      // }
      // else if (valid_email == true && this.currentlyChecked == 1) {
      //   this.spinner.show();
      //   var obj :any  = new  Object();
      //   obj['ident_to_verify'] = this.obj['email'];
      //   obj['ident_verify_method'] = 'E-KEY';
      //   obj['ident_verify_secret'] = this.obj['password'];

      //   var resp = await this.auth.loginERP(obj);
      //   if (resp['error'] == false && resp['data'].length > 0) {

      //     this.AllAccountData = resp['data'];
      //     for (let i = 0; i < this.AllAccountData.length; i++) {
      //       this.AllAccountData[i]['acct_desc'] = this.AllAccountData[i]['b_acct_id'] + " - " + this.AllAccountData[i]['account_name']
      //     }

      //     if (resp['data'].length > 1) {
      //       await this.selectMultiAccount();
      //     } else {
      //       await this.selectOneAccount();
      //     }

      //     this.spinner.hide();
      //   } else if (resp['data'].length == 0) {
      //     this.spinner.hide();
      //     this.login_obj['status'] = "FAIL";
      //     this.login_obj['user_id'] = null;
      //     this.login_obj['message'] = this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18']

      //     await this.insertLoginInfo(this.login_obj);
      //     Swal.fire("Oops", this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18'], 'error');
      //   } else {
      //     this.spinner.hide();
      //     this.login_obj['status'] = "FAIL";
      //     this.login_obj['user_id'] = null;
      //     this.login_obj['message'] = this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18']

      //     await this.insertLoginInfo(this.login_obj);
      //     Swal.fire('Error...', this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN18'], 'error')
      //   }
      // }
      // else {
      //   Swal.fire('Error...', this.auth.allLableShowObj[this.auth.language_cd + 'LOGIN20'], 'error')
      // }

      // }
      // else {
      //   Swal.fire('Warning', ' Please Select An Option For Login', 'warning')
      // }
    }
  }
  Obj :any = {}
  selectedModule(module_cd:any ) {
    console.log(module_cd)
    this.planing_arrproj = [];
    this.Obj = {}
  }
  excl() {
    var exp1 = []
    for (var i = 0; i < this.alldata.length; i++) {
      var obj :any = new Object();
      // obj['SNO'] = i + 1;
      obj['emp_id'] = this.alldata[i]['emp_id'];
      obj['emp_name'] = this.alldata[i]['emp_name'];
      // obj['emp_name'] = this.alldata[i]['emp_name']
      obj['emp_dob'] = this.alldata[i]['emp_dob'];
      obj['emp_email'] = this.alldata[i]['emp_email'];
      obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
      // obj['emp_phone_no'] = this.alldata[i]['emp_phone_no'];
      obj['emp_father_name'] = this.alldata[i]['emp_father_name'];
      obj['emp_permanent_addr_line1'] = this.alldata[i]['emp_permanent_addr_line1']
      obj['emp_permanent_addr_line2'] = this.alldata[i]['emp_permanent_addr_line2']
      obj['emp_permanent_addr_city'] = this.alldata[i]['emp_permanent_addr_city']
      obj['emp_permanent_addr_dist'] = this.alldata[i]['emp_permanent_addr_dist']
      obj['emp_permanent_addr_state'] = this.alldata[i]['emp_permanent_addr_state']
      obj['emp_permanent_addr_pin_code'] = this.alldata[i]['emp_permanent_addr_pin_code']
      obj['emp_local_addr_line1'] = this.alldata[i]['emp_local_addr_line1']
      obj['emp_local_addr_line2'] = this.alldata[i]['emp_local_addr_line2']
      obj['emp_local_addr_city'] = this.alldata[i]['emp_local_addr_city']
      obj['emp_local_addr_dist'] = this.alldata[i]['emp_local_addr_dist']
      obj['emp_local_addr_state'] = this.alldata[i]['emp_local_addr_state']
      obj['emp_local_addr_pin_code'] = this.alldata[i]['emp_local_addr_pin_code']
      obj['pin'] = this.alldata[i]['pin']
      obj['emp_pan_no'] = this.alldata[i]['emp_pan_no']
      obj['emp_adhar_no'] = this.alldata[i]['emp_adhar_no']
      obj['emp_gst_no'] = this.alldata[i]['emp_gst_no']
      obj['gis_no'] = this.alldata[i]['gis_no']
      obj['nps_no'] = this.alldata[i]['nps_no']
      obj['bank_code'] = this.alldata[i]['bank_code']
      obj['branch_code'] = this.alldata[i]['branch_code']
      obj['ifsc_code'] = this.alldata[i]['ifsc_code']
      obj['nps_no'] = this.alldata[i]['nps_no']
      obj['acct_no'] = this.alldata[i]['acct_no'];
      obj['pf_acct_no'] = this.alldata[i]['pf_acct_no']
      obj['pf_type'] = this.alldata[i]['pf_type']
      obj['pf_ifsc_code'] = this.alldata[i]['pf_ifsc_code']
      obj['reservation_category_code'] = this.alldata[i]['reservation_category_code']
      obj['marital_status'] = this.alldata[i]['marital_status']
      obj['emp_husband_name'] = this.alldata[i]['emp_husband_name']
      obj['emp_religeon'] = this.alldata[i]['emp_religeon']
      obj['emp_nationality'] = this.alldata[i]['emp_nationality']
      obj['emp_mother_name'] = this.alldata[i]['emp_mother_name']
      obj['emp_cat_code'] = this.alldata[i]['emp_cat_code']
      obj['identification_mark'] = this.alldata[i]['identification_mark']
      obj['identification_mark'] = this.alldata[i]['identification_mark']
      obj['valid_from'] = this.alldata[i]['create_timestamp']
      obj['valid_upto'] = this.alldata[i]['update_timestamp']
      obj['txn_start'] = ''
      obj['txn_end'] = ''
      obj['status'] = ''
      obj['user_id'] = this.alldata[i]['create_user_id']
      obj['life_cycle_status'] = ''
      obj['lvl1_cd'] = ''
      obj['lvl1_value'] = ''
      obj['lvl1_node_type'] = ''
      obj['lvl1_user_cd'] = ''
      obj['lvl2_cd'] = ''
      obj['lvl2_value'] = ''
      obj['lvl2_node_type'] = ''
      obj['lvl2_user_cd'] = ''

      // obj['node_cd']
      exp1.push(obj);
    }
    this.excl1.exportAsExcelFile(exp1, "download")
  }
  objectToSend :any = {}
  personalInfoObj :any = {};
  updateServiceObj:any  = {}
  estdata :any = []
  AllFixPay:any  = []
  async allDone1() {
    // $('#ACTHIER1').modal('show')
  }
  start_dt:any
  // setJoiningData() {

  //   var startdt;
  //   if (this.addEstObj['joining_time'] == 'MORNING') {
  //     startdt = this.addEstObj['joining_date'].split('T')[0];
  //   } else {

  //     startdt = this.addOneDay(this.addEstObj['joining_date'].split('T')[0]);
  //   }
  //   this.addEstObj['old_start_dt'] = startdt;
  //   this.addEstObj['start_dt'] = startdt;
  //   this.addEstObj['end_dt'] = this.end_dt

  // }
  addOneDay(dt:any ) {
    var tomorrow = new Date(dt);

    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];

  }
  end_dt = "2030-08-19"
  async allDone() {
    var startdt = [];
    // $('#myModal1').modal('show')
    console.log(this.alldata, "shweta establishment data")
    for (let i = 0; i < this.alldata.length; i++) {
      this.estdata[i] = this.alldata[i]
      this.estdata[i]['joining_date'] = moment(this.alldata[i]['joining_date']).format('YYYY-MM-DD')
      this.estdata[i]['joining_service_date'] = moment(this.alldata[i]['joining_service_date']).format('YYYY-MM-DD')
      this.estdata[i]['retirement_date'] = moment(this.alldata[i]['retirement_date']).format('YYYY-MM-DD')
      if (this.estdata[i]['joining_time'] == 'MORNING') {
        startdt[i] = this.estdata[i]['joining_date'].split('T')[0];
      } else {

        startdt[i] = this.addOneDay(this.estdata[i]['joining_date'].split('T')[0]);
      }
      this.estdata[i]['old_start_dt'] = startdt[i];
      this.estdata[i]['start_dt'] = startdt[i];
      this.estdata[i]['end_dt'] = this.end_dt
    }
    console.log(this.estdata, "shweta establishment data")
    // await this.setVariablePay()
    for (let i = 0; i < this.estdata.length; i++) {
      await this.applyRule(this.estdata[i], this.estdata[i]['emp_id'])
      var dt = this.addSixtyYears(this.estdata[i]['emp_dob'], this.estdata[i]['retirement_age']);
      this.estdata[i]['retirement_date'] = dt;
      if (this.objectToSend[i] == undefined) {

        this.objectToSend[i] = {};
        this.objectToSend[i]['b_acct_id'] = this.b_acct_id;
        this.estdata[i]['emp_status_code'] = 'ACTIVE';
        this.objectToSend[i]['establishment_info'] = this.estdata[i];
        this.objectToSend[i]['fixed_pay_amount'] = this.AllFixPay;

        this.start_dt = moment(this.alldata[i]['joining_date']).format('YYYY-MM-DD')
        //  this.objectToSend[i]['eff_from'] =this.start_dt.split('T')[0];

        this.objectToSend[i]['eff_from'] = this.estdata[i]['start_dt']
        //  this.objectToSend[i]['eff_from'] =this.estdata[i]['joining_service_date']

        //  moment(this.dateToday).format('YYYY-MM-DD')
        // '2023-02-14'
        console.log(this.objectToSend[i])
        //  this.estdata[i]['start_dt']
        var resp = await this.estNewService.addNewEstabishment1(this.objectToSend[i]);
        if (resp['error'] == false) {
          this.spinner.show();
          await this.addNewLeaveEstabishment(this.estdata[i])
          //  setTimeout(async () => {
          //   //  await this.getAllEmployees();
          //   //  await this.getAllArrangementOfParty();
          //    this.updateServiceObj = {};
          //   //  await this.open_list()
          //   //  Swal.fire("Success", "Employee Activated", 'success')
          //   this.spinner.show();
          //  }, 1000);

        } else {
          //  this.spinner.hide()
          Swal.fire("Error", "Error occurred", 'error')
        }

      }
    }
    console.log(resp, "shweta---")
    if (resp['error'] == false) {
      //  await this.addNewLeaveEstabishment()
      setTimeout(async () => {
        //  await this.getAllEmployees();
        //  await this.getAllArrangementOfParty();
        this.updateServiceObj = {};
        //  await this.open_list()
        Swal.fire("Success", "Employee Activated", 'success')
        this.spinner.hide();
      }, 1000);

    } else {
      //  this.spinner.hide()
      Swal.fire("Error", "Error occurred", 'error')
    }

  }
  async addNewLeaveEstabishment(addEstObj:any ) {
    var obj :any  = new  Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['leave_info'] = this.allLeaveRule;
    obj['emp_id'] = addEstObj['emp_id'];
    obj['create_user_id'] = this.erpUser.user_id;
    var resp = await this.estNewService.addNewleaveEstabishment(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      // this.snackBar.open("Add Leave Successfully", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide()
      // this.snackBar.open("Error while adding leave", 'Error', {
      //   duration: 5000
      // });
    }
  }
  allPayRule :any = []
  allPayNonSepRule:any  = []
  allRuleCurrentlyActive :any = []
  fixPay :any = {}
  VarPay :any = {}
  allSepPayRule :any = []

  async getAllRuleInfo() {
    var temp_allSepPayRule :any = [];
    this.allPayNonSepRule = [];
    this.allRuleCurrentlyActive = [];
    this.fixPay = {};
    this.VarPay = {};
    // this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = this.dateToday;
    obj['status'] = ['ACTIVE']
    var resp = await this.settingService.getAllSalaryCD(obj);
    console.log(resp)
    if (resp['error'] == false) {
      // this.spinner.hide()
      this.allPayRule = resp.data;
      for (let i = 0; i < this.allPayRule.length; i++) {
        if (this.allPayRule[i]['valid_from'] <= this.dateToday && this.dateToday < this.allPayRule[i]['valid_upto']) {
          this.allRuleCurrentlyActive.push(this.allPayRule[i])

          if (this.allPayRule[i]['is_spe_allowd'] == 1) {
            this.VarPay[this.allPayRule[i]['component_code']] = true
            temp_allSepPayRule.push(this.allPayRule[i])
          } else {
            this.fixPay[this.allPayRule[i]['component_code']] = true
            this.allPayNonSepRule.push(this.allPayRule[i])
          }
        }
      }
      this.allSepPayRule = temp_allSepPayRule
      console.log(this.allSepPayRule)
      console.log(this.allPayNonSepRule)
      console.log(this.fixPay)
      console.log(this.VarPay)
    } else {
      // this.spinner.hide()
      // this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  allLeaveRule = []
  async getAllLeaveRules() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getAllRules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allLeaveRule = resp.data;
    } else {

      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  objPayCompVar :any  = {};
  objPayCompDataVar:any  = {}
  async applyRuleOnSepComp() {
    this.objPayCompVar = {};
    this.objPayCompDataVar = {};

    for (let i = 0; i < this.allRuleCurrentlyActive.length; i++) {

      var amt :any = 0;
      var pay_code = this.allRuleCurrentlyActive[i].pay_code;
      if (this.allRuleCurrentlyActive[i].rate_type == 'CAL') {
        console.log(this.allRuleCurrentlyActive[i].function_name)
        amt = await this.allRuleCurrentlyActive[i].function_name();
        console.log(amt)
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-CAL', is_change: true };

      }
      else if (this.allRuleCurrentlyActive[i].rate_type == 'FIX') {
        amt = this.allRuleCurrentlyActive[i].amount;
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-FIX', is_change: false };

      } else if (this.allRuleCurrentlyActive[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allRuleCurrentlyActive[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (this.objPayCompVar[arr[j]] == undefined) {
            this.objPayCompVar[arr[j]] = 0;
          }
          amt += this.objPayCompVar[arr[j]];

        }
        amt = parseFloat((amt * this.allRuleCurrentlyActive[i].amount / 100).toFixed(2));
        this.objPayCompVar[this.allRuleCurrentlyActive[i].component_code] = amt;
        this.objPayCompDataVar[this.allRuleCurrentlyActive[i].component_code] = { amt: amt, pay_code: this.allRuleCurrentlyActive[i].pay_code, type: 'by_rule-PERCENTAGE', is_change: true };


      }
    }


    console.log(this.objPayCompVar)
    console.log(this.objPayCompDataVar)
  }
  objPayComp :any = {};
  objPayCompData:any  = {}
  latestLeaveArr:any  = []
  async applyRule(addEstObj:any , emp_id:any ) {
    var obj :any  = new  Object()
    obj['emp_id'] = emp_id
    obj['b_acct_id'] = this.b_acct_id;
    var resp1 = await this.estNewService.getNewEstabishment(JSON.stringify(obj));
    console.log(resp1)
    if (resp1.data.length == 0) {
      // if(resp1['data']==true){

      //this.PRINT_flag1=true
      // this.PRINT_flag2=false
      await this.getAllLeaveRules()

      //}
    }
    else {
      // this.PRINT_flag1=false
      // this.PRINT_flag2=true
      // await this.getAllLeaveRules()
      this.latestLeaveArr = resp1.data;

      // console.log( this.latestLeaveArr['leave_code'].sort)
    }
    this.objPayComp = {};
    this.objPayCompData = {};

    for (let i = 0; i < this.allPayNonSepRule.length; i++) {

      var amt = 0;
      var pay_code = this.allPayNonSepRule[i].pay_code;
      if (this.allPayNonSepRule[i].rate_type == 'CAL') {
        console.log(this.allPayNonSepRule[i].function_name)
        amt = await this.allPayNonSepRule[i].function_name(addEstObj);
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };

      }
      else if (this.allPayNonSepRule[i].rate_type == 'FIX') {
        amt = this.allPayNonSepRule[i].amount;
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };

      } else if (this.allPayNonSepRule[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allPayNonSepRule[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (this.objPayComp[arr[j]] == undefined) {
            this.objPayComp[arr[j]] = 0;
          }
          amt += this.objPayComp[arr[j]];

        }
        amt = parseFloat((amt * this.allPayNonSepRule[i].amount / 100).toFixed(2));
        this.objPayComp[this.allPayNonSepRule[i].component_code] = amt;
        this.objPayCompData[this.allPayNonSepRule[i].component_code] = { amt: amt, pay_code: this.allPayNonSepRule[i].pay_code, type: 'by_rule' };


      }
    }

    // shweta await this.applyRuleOnSepComp();
    this.AllFixPay = []
    // this.AllFixPay1 = []
    var comp = Object.keys(this.objPayComp);
    for (var i = 0; i < comp.length; i++) {
      var ob:any  = {};
      ob['b_acct_id'] = this.b_acct_id;
      ob['arr_id'] = addEstObj['arr_id'];
      //  ob['eff_from'] = moment(this.dateToday).format('YYYY-MM-DD')
      ob['eff_from'] = addEstObj['start_dt']

      // addEstObj['arr_id']
      // '2023-02-14' ;
      ob['effective_end_dt'] = "2038-01-01"
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_comp'] = comp[i];
      ob['rate'] = this.objPayCompData[comp[i]].amt;
      ob['pay_code'] = this.objPayCompData[comp[i]].pay_code;
      ob['curr_cd'] = 'INR';
      ob['rate_unit'] = 'MNTH';
      // if (comp[i] == 'CONV') {
      //   // ob['rate']=this.test['conv']
      //   ob['rate'] = addEstObj['conv']

      // }
      console.log(ob);
      // this.ob.push(this.test['conv'])
      if (this.prviousCompStatus[comp[i]] != undefined) {
        ob['status'] = this.prviousCompStatus[comp[i]]
      } else {
        ob['status'] = true;
      }

      if (ob['rate'] != 0) {
        this.AllFixPay.push(ob);
        // this.AllFixPay1.push(ob);
      }
    }

    this.AllFixPay = this.sortData(this.AllFixPay, 'pay_comp');
    console.log(this.AllFixPay)

  }

  ///************************get pay matrix *****************************8*/
  BasicPayObj :any = {};

  matrixObj:any  = {}
  grade_pay:any  = [];
  pay_scale :any = [];
  level :any = []
  async getAllPayMatrix() {
    //  this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getMatrix(JSON.stringify(obj));
    var grade_pay_obj:any  = {};
    var lvlArr :any = [];
    var gradePayArr :any = []
    var payScaleArr :any = []

    var pay_band_obj:any  = {};
    var lvl_obj:any  = {};
    if (resp['error'] == false) {
      //  this.spinner.hide()
      this.allMatrix = resp.data;
      for (var i = 0; i < this.allMatrix.length; i++) {
        if (grade_pay_obj[this.allMatrix[i].grade_pay_code] == undefined) {
          gradePayArr.push({ code: this.allMatrix[i].grade_pay_code, value: this.allMatrix[i].grade_pay_code });
          grade_pay_obj[this.allMatrix[i].grade_pay_code] = '1';
        }
        if (pay_band_obj[this.allMatrix[i].pay_band] == undefined) {
          payScaleArr.push({ code: this.allMatrix[i].pay_band, value: this.allMatrix[i].pay_band });
          pay_band_obj[this.allMatrix[i].pay_band] = '1';
        }
        if (lvl_obj[this.allMatrix[i].level_code] == undefined) {
          lvlArr.push({ code: this.allMatrix[i].level_code, value: this.allMatrix[i].level_code });
          lvl_obj[this.allMatrix[i].level_code] = '1';
        }

        if (this.matrixObj[this.allMatrix[i].grade_pay_code] == undefined) {
          this.matrixObj[this.allMatrix[i].grade_pay_code] = []
        }
        this.matrixObj[this.allMatrix[i].grade_pay_code].push({ code: this.allMatrix[i].index_num.toString(), value: this.allMatrix[i].basic_pay.toString() });
        // this.BasicPayObj[this.allMatrix[i]['pay_band'].toString() +"-"+ this.allMatrix[i]['grade_pay_code'].toString() +"-"+ this.allMatrix[i]['level_code'].toString() +"-"+ this.allMatrix[i]['index_num'].toString()] = this.allMatrix[i]['basic_pay']

      }
      this.level = lvlArr;
      this.grade_pay = gradePayArr;
      this.pay_scale = payScaleArr;
    } else {
      //  this.spinner.hide()
      //  this.snackBar.open(resp.data, 'Error', {
      //    duration: 5000
      //  });
    }
  }

  ///************************get HRA matrix *****************************8*/

  allHRAMatrix:any  = []
  async getAllHRAMatrix() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getHRAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allHRAMatrix = resp.data;
      console.log(this.allHRAMatrix)

    } else {

    }
  }

  ///************************get GIS matrix *****************************8*/

  allGISMatrix:any  = []
  async getAllGISMatrix() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estNewService.getGISMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allGISMatrix = resp.data;
      console.log(this.allGISMatrix)
    } else {

    }
  }

  ///************************get CCA matrix *****************************8*/

  allCCAMatrix :any = []
  async getAllCCAMatrix() {
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';

    var resp = await this.estNewService.getCCAMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allCCAMatrix = resp.data;
    } else {
    }
  }
  allCityMatrix = [];
  allCityMatrixObj = [];
  async getAllCityMatrix() {
    //  this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getCityMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allCityMatrix = resp.data;
      for (let i = 0; i < this.allCityMatrix.length; i++) {
        this.allCityMatrixObj[this.allCityMatrix[i]['city']] = this.allCityMatrix[i]['city_group']
      }

    } else {
      //  this.spinner.hide()
      //  this.snackBar.open("Error while getting  all City Matrix", 'Error', {
      //    duration: 5000
      //  });
    }

    console.log(this.allCityMatrixObj)
  }
  allPRBMatrix :any = []
  async getAllPRBMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getpbrMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allPRBMatrix = resp.data;

      this.spinner.hide();

    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }

  }
  allwfMatrix:any  = []
  async getAllWFMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getwfMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allwfMatrix = resp.data;

      this.spinner.hide();

    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }
  //***********************************pre define function****************************** */
  allMatrix :any = []
  async getbasic(addEstObj:any ) {
    console.log(addEstObj);

    var obj = Object.assign({}, addEstObj);
    var amount = 0;
    for (let i = 0; i < this.allMatrix.length; i++) {
      console.log(!obj['pay_scale_code'] , !this.allMatrix[i]['pay_band'] , i);
      console.log(!obj['grade_pay_code'] , !this.allMatrix[i]['grade_pay_code']);
      console.log(!obj['level_code'] , !this.allMatrix[i]['level_code']);
      console.log(!obj['basic_pay'] ,!this.allMatrix[i]['index_num']);

      if ((obj['pay_scale_code'].toString() == this.allMatrix[i]['pay_band'].toString()) && (obj['grade_pay_code'].toString() == this.allMatrix[i]['grade_pay_code'].toString())
        && (obj['level_code'].toString() == this.allMatrix[i]['level_code'].toString()) && (obj['basic_pay'].toString() == this.allMatrix[i]['index_num'].toString())) {
        amount = this.allMatrix[i]['basic_pay'];
      }
    }
    return amount;
  }

  async gethra(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allHRAMatrix.length; i++) {
      if ((this.allHRAMatrix[i].level_code.toString() == obj['level_code'].toString() && this.allHRAMatrix[i].city_group == this.allCityMatrixObj[obj['work_city']])) {
        amount = this.allHRAMatrix[i].amount
      }
    }
    return amount;
  }
  async getCCA(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    console.log(obj)

    var amount = 0;
    console.log(this.allCCAMatrix)
    for (let i = 0; i < this.allCCAMatrix.length; i++) {
      if ((this.allCCAMatrix[i].upper_limit >= obj['grade_pay_code'] && this.allCCAMatrix[i].lower_limit <= obj['grade_pay_code'] && this.allCCAMatrix[i].city_cd == obj['work_city'])) {
        amount = this.allCCAMatrix[i].amount
      }
    }
    return amount;
  }
  async getDepAllow(addEstObj :any ) {
    var amount = 0;
    var obj = Object.assign({}, addEstObj);

    console.log(obj)
    if (obj['joining_type_code'] == 'DEPUTATION') {
      var basic = await this.getbasic(addEstObj);

      if (obj['work_city'] == obj['dep_work_city']) {
        amount = basic * 5 / 100;
        if (amount > 1500) {
          amount = 1500;
        }
      } else {
        amount = basic * 10 / 100;
        if (amount > 3000) {
          amount = 3000;
        }
      }
    }

    console.log(amount)
    return amount;

  }
  getMed(addEstObj :any ) {
    var amount = 0;
    var obj = Object.assign({}, addEstObj);

    if (obj['joining_type_code'] != 'DEPUTATION') {
      amount = this.mainService.codeValueTechObj['HR0053'][0]['value']
      // 200;
    }
    return amount;

  }

  getGIS(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    var amount = 0;
    for (let i = 0; i < this.allGISMatrix.length; i++) {
      if ((this.allGISMatrix[i].upper_limit >= obj['grade_pay_code'] && this.allGISMatrix[i].lower_limit <= obj['grade_pay_code'])) {
        amount = this.allGISMatrix[i].amount
      }
    }
    return amount;
  }

  getConv(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    var amount = 0;
    amount=addEstObj['conv_amt']
    // if (obj['conv_code'] == 'CYCLE') {
    //   amount = 300
    // }
    // if (obj['conv_code'] == 'MOTORCYCLE') {
    //   amount = 700
    // }
    // if (obj['conv_code'] == 'CAR') {
    //   amount = 1000
    // }
    return amount;
  }

  getWA(addEstObj:any ) {
    var obj = Object.assign({}, addEstObj);
    var amount = 0;

    if (obj['class_code'] == 'IV' || (obj['designation_code']).toLowerCase() == ('DRIVER').toLowerCase()) {
      amount = this.mainService.codeValueTechObj['HR0054'][0]['value']
      // 100
    }

    return amount;
  }
  getHVDTAllow(addEstObj:any ) {
    var obj = Object.assign({}, addEstObj);
    var amount = 0;

    if ((obj['designation_code']).toLowerCase() == ('DRIVER').toLowerCase()) {
      amount = this.mainService.codeValueTechObj['HR0054'][0]['value']
    }

    return amount;
  }

  async getPr_Bhatta_Rule(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allPRBMatrix.length; i++) {
      if (this.allPRBMatrix[i].grade_pay_code == obj['grade_pay_code'].toString()) {
        amount = this.allPRBMatrix[i].pbr_pay
      }
    }
    return amount;
  }
  async getWF(addEstObj :any ) {
    var obj = Object.assign({}, addEstObj);
    console.log(obj)

    var amount = 0;
    for (let i = 0; i < this.allwfMatrix.length; i++) {
      if (this.allwfMatrix[i].class_code.toString() == obj['class_code']) {
        amount = this.allwfMatrix[i].wf_pay
      }
    }
    return amount;
  }

  //***********************************pre define function****************************** */

  //************************meta data******************************************** */





  // async allDone() {
  //   // await this.updateLeave()

  //   //  if (this.addEstObj['start_dt'] < this.addEstObj['joining_date']) {
  //   //    swal.fire("Warning", "Salary Start Date should not less then joining Date of Employee.....", 'warning')
  //   //    return;
  //   //  }
  //   //  if (this.addEstObj['joining_service_date'] > this.addEstObj['joining_date']) {
  //   //    swal.fire("Warning", "Joining Service Date should not greater than joining Date of Employee.....", 'warning')
  //   //    return;
  //   //  }
  //   //  this.spinner.show()
  //    await this.setVariablePay();
  //    var empInfo = new Object();
  //    empInfo['b_acct_id'] = this.b_acct_id;
  //    empInfo['arr_id'] = this.arr_id;
  //    var resp = await this.estNewService.getEmployeePersonalInfo(JSON.stringify(empInfo));
  //    if (resp['error'] == false) {
  //      this.personalInfoObj = resp.data[0];
  //    } else {
  //      Swal.fire('Error', 'Error while getting employee date of birth', 'error');
  //    }
  //    var dt = this.addSixtyYears(this.personalInfoObj['emp_dob'], this.addEstObj['retirement_age']);
  //    this.addEstObj['retirement_date'] = dt;

  //    this.objectToSend = {};
  //    this.objectToSend['b_acct_id'] = this.b_acct_id;
  //    this.addEstObj['emp_status_code'] = 'ACTIVE';
  //    this.objectToSend['establishment_info'] = this.addEstObj;
  //    this.objectToSend['fixed_pay_amount'] = this.AllFixPay;
  //    //  this.objectToSend['leave_info'] = this.Allleave;
  //    //   this.objectToSend['variable_pay'] = this.variablepayObj;
  //    //   this.objectToSend['promotion'] = this.promotionArr;

  //    this.objectToSend['eff_from'] = this.addEstObj['start_dt']

  //    console.log(this.objectToSend)
  //    var resp = await this.estNewService.addNewEstabishment(this.objectToSend);
  //    if (resp['error'] == false) {
  //      await this.addNewLeaveEstabishment()
  //      setTimeout(async () => {
  //       //  await this.getAllEmployees();
  //       //  await this.getAllArrangementOfParty();
  //        this.updateServiceObj = {};
  //       //  await this.open_list()
  //        Swal.fire("Success", "Employee Activated", 'success')
  //       //  this.spinner.hide();
  //      }, 1000);

  //    } else {
  //     //  this.spinner.hide()
  //      Swal.fire("Error", "Error occurred", 'error')
  //    }


  //  }
  addSixtyYears(dt:any , age:any ) {
    dt = new Date(dt);
    age = parseInt(age)
    var date = new Date(dt.setFullYear(dt.getFullYear() + age))
    var returmDate = ""
    var mnth;
    var day;
    if (date.getDate() == 1) {
      date = new Date(date.setDate(date.getDate() - 1))
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      returmDate = [date.getFullYear(), mnth, day].join("-");
    } else {
      mnth = ("0" + (date.getMonth() + 2)).slice(-2)
      date = new Date([date.getFullYear(), mnth, "01"].join("-"));
      date = new Date(date.setDate(date.getDate() - 1))
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      returmDate = [date.getFullYear(), mnth, day].join("-");
    }

    return returmDate;
  }
  async setVariablePay() {
    await this.getEmpolyeeEffectiveArrangemt()

    console.log(this.allVarComp)
    // for (let i = 0; i < this.allVarComp.length; i++) {
    //   if (this.fixPay[this.allVarComp[i]['pay_comp']] || this.VarPay[this.allVarComp[i]['pay_comp']]) {
    //     if (this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['is_change'] && this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['amt'] != this.allVarComp[i]['rate']) {
    //       let ob = {};
    //       ob['b_acct_id'] = this.b_acct_id;
    //       ob['arr_id'] = this.arr_id;
    //       ob['eff_from'] = this.addEstObj['start_dt'];
    //       ob['effective_end_dt'] = this.allVarComp[i]['valid_upto']
    //       ob['status'] = 'ACTIVE';
    //       ob['create_user_id'] = this.erpUser.user_id;
    //       ob['component_status_code'] = 'ACTIVE';
    //       ob['pay_comp'] = this.allVarComp[i]['pay_comp'];
    //       ob['rate'] = this.objPayCompDataVar[this.allVarComp[i]['pay_comp']]['amt'];
    //       ob['pay_code'] = this.allVarComp[i].pay_code;
    //       ob['curr_cd'] = 'INR';
    //       ob['rate_unit'] = 'MNTH';
    //       ob['status'] = true;
    //       console.log(ob)
    //       this.AllFixPay.push(ob);
    //     }
    //   }
    // }
  }
  allSalComp:any  = [];
  allVarComp :any = [];
  prevAllSalComp :any = [];
  prviousCompStatus :any = {}
  // fixPay=[]
  dateToday = "0000-00-00"
  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data;
      console.log(this.dateToday)
    } else {

    }
  }
  async getEmpolyeeEffectiveArrangemt() {
    // this.spinner.show()
    var obj :any  = new  Object()
    for (let i = 0; i < this.estdata.length; i++) {

      if (obj[i] == undefined) {
        obj[i] = {}
        obj[i]['status'] = false;
        obj[i]['data'] = [];


        // var obj :any  = new  Object();
        obj[i]['b_acct_id'] = this.b_acct_id;
        obj[i]['arr_id'] = this.estdata[i]['arr_id'];
        // }
        var resp = await this.estNewService.getCurrentEstablishementInfo(JSON.stringify(obj[i]));
        console.log(obj[i])
        console.log(resp['data'], "shweta---")
        if (resp['error'] == false) {
          if (resp.data.length > 0) {
            obj[i]['status'] = true;
            obj[i]['data'] = resp.data[0]
          }
          // this.spinner.hide()

        } else {
          // this.snackBar.open("Error while getting employee list", 'Error', {
          //   duration: 5000
          // });
          // this.spinner.hide()
        }

        var resp1 = await this.estNewService.getArrangmentSalComponent(JSON.stringify(obj[i]));
        console.log(resp1);
        this.allSalComp = [];
        this.allVarComp = [];
        this.prevAllSalComp = [];
        if (resp1['error'] == false) {
          this.prevAllSalComp = resp1.data;

          console.log(this.prevAllSalComp);
          for (let i = 0; i < this.prevAllSalComp.length; i++) {

            if ((new Date(this.prevAllSalComp[i]['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(this.prevAllSalComp[i]['valid_upto']))) {

              if (this.fixPay[this.prevAllSalComp[i]['pay_comp']]) {
                this.allSalComp.push(this.prevAllSalComp[i])
              } else {
                this.allVarComp.push(this.prevAllSalComp[i])
              }
            }
          }
          // this.allSalComp = await this.sortData(this.allSalComp, 'pay_comp')
          this.allVarComp = await this.sortData(this.allVarComp, 'pay_comp')

        } else {
          console.log("Error in sal getting...")
        }


        var resp2 = await this.estNewService.getFixPayStatus(JSON.stringify(obj[i]));
        console.log(resp2);
        if (resp2['error'] == false) {
          for (let i = 0; i < this.prevAllSalComp.length; i++) {
            this.prevAllSalComp[i]['status'] = true;
            for (let j = 0; j < resp2.data.length; j++) {
              if (this.prevAllSalComp[i]['pay_comp'] == resp2.data[j]['pay_comp_code'] && resp2.data[j]['status'] == 0) {
                this.prevAllSalComp[i]['status'] = false;
              }
            }
          }
          this.prviousCompStatus = {}
          for (let j = 0; j < resp2.data.length; j++) {
            this.prviousCompStatus[resp2.data[j]['pay_comp_code']] = true;
            if (resp2.data[j]['status'] == 0) {
              this.prviousCompStatus[resp2.data[j]['pay_comp_code']] = false;
            }
          }
        } else {
          console.log("Error in sal getting...")
        }


        console.log(this.prviousCompStatus)
        return obj[i]
      }
    }
  }

  sortData(arr:any , key:any ) {

    return arr.sort((a:any , b:any ) => (a[key] > b[key]) ? 1 : (b[key] > a[key]) ? -1 : 0)
  }
}
