<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Deviation -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION1']}}</div>
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->


                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="p_dev_flag" class="nav-link active " href="#tab-7-2" data-toggle="tab" (click)="propos()"><i
                                    class="fa fa-deviantart" aria-hidden="true"> </i> Proposed  Deviation
                            </a>
                        </li>
                        <li class="nav-item">
                            <a  class="nav-link " href="#tab-7-1" data-toggle="tab" (click)="aprroved()"><i
                                    class="fa fa-line-chart"></i> Approved Deviation</a>
                        </li>
                       
                        <li class="nav-item">
                            <a *ngIf="c_dev_flag" class="nav-link active" href="#tab-7-3" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> <!-- Deviate -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION5']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_dev_flag" class="nav-link active" href="#tab-7-4" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Update Deviation
                            </a>
                        </li>
                        

                        <td>

                        </td>


                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_dev_flag" >
                            <br>
                           
                           <!--  <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select Project :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div>

                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Work -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWList" bindLabel="work_des" bindValue="id" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}}" (change)="getTenderId()" [(ngModel)]="selectedWork" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6> <!-- Select Deviation Number -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION4']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="devidarr" bindLabel="dev_id" bindValue="dev_id" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION4']}}" [(ngModel)]="selecteddevid" (change)="changedevid()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter2($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                   <!--  <button class="btn btn-info" (click)="Appr()" *ngIf="apprflag == true">Add Approval
                                    </button>&nbsp; -->
                                   
                                    <button class="btn btn-success" (click)="print1()"><!-- Print -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION6']}}</button>

                                </div>
                            </div>
                            <br>
                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort #sortCol3="matSort">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> SNO<!-- {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION7']}} -->


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                           {{element['sno']}} 
                                            </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Item Desc -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{boqObj[element['sno']]['item_desc']}}
                                        </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="tender_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Tender ID


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tender_id }} </td>
                                    </ng-container> -->


                                    <ng-container matColumnDef="dev_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Deviation Date -->BOQ Qty
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{ element.dev_dt}}  -->
                                            <div *ngIf="boqObj[element['sno']]!= undefined && boqObj[element['sno']]!= null ">

                                                <div *ngIf="element.dev_type=='A'">
                                                    {{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']-element.dev_quantity}}

                                                </div>
                                                <div *ngIf="element.dev_type=='S'">
                                                    {{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']+element.dev_quantity}}

                                                </div>

                                            </div>
                                            

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dev_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Deviation Type -->
                                            Deviation Change
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.dev_quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="dev_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Deviation Quantity -->
                                            Final Qty


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']}} </td>
                                    </ng-container>


                                    <!-- <ng-container matColumnDef="is_comp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Is Completion
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.is_comp}} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="sortype">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Sor Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.sortype}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sordesc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Sor Description
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.sordesc}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-info" (click)="viewStatus(element)">View
                                                Status</button>&nbsp; -->
                                            <!-- <button class="btn btn-primary" (click)="open_update(element)">Update</button>&nbsp; -->
                                        </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="pp_dev_flag">
                            
                           <!--  <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select Project :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div>

                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Work -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWList" bindLabel="work_des" bindValue="id" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}}" (change)="getTenderIdtab2()" [(ngModel)]="selectedWork" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Deviation</button>
                                    &nbsp; &nbsp;
                                    <button class="btn btn-success" (click)="printtab2()"><!-- Print -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION6']}}</button>&nbsp;

                                    <button class="btn btn-success" (click)="Approved()">Approval </button>&nbsp;
                                    <button class="btn btn-primary" (click)="open_update()">Update </button>&nbsp;
                                   
                                </div>
                            </div>
                            <br>
                            <div class="example-container">

                                <table mat-table [dataSource]="datasource2" matSort #sortCol4="matSort">



                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- S.No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION16']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['sno']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Item Desc -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}


                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="max-width: 350px;">
                                            <div *ngIf="boqObj[element['sno']]!= undefined && boqObj[element['sno']]!= null ">{{boqObj[element['sno']]['item_desc']}}

                                            </div>
                                            <div  *ngIf="boqObj[element['sno']]== undefined && boqObj[element['sno']]== null ">
                                            {{ element.sordesc}}
                                            </div> </td>
                                    </ng-container>
                                    <ng-container matColumnDef="boq_qty">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Boq Qty

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <div *ngIf="boqObj[element['sno']]!= undefined && boqObj[element['sno']]!= null ">{{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']}}

                                            </div>
                                            <div  *ngIf="boqObj[element['sno']]== undefined && boqObj[element['sno']]== null ">
                                            {{ 0}}
                                            </div>
                                            </td>
                                    </ng-container>
                                    <ng-container matColumnDef="deviation_change">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Deviation Change

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.dev_quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Final_qty">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Final Qty

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="min-width: 100px;">
                                            <div *ngIf="boqObj[element['sno']]!= undefined && boqObj[element['sno']]!= null ">

                                                <div *ngIf="element.dev_type=='A'">
                                                    {{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']+element.dev_quantity}}

                                                </div>
                                                <div *ngIf="element.dev_type=='S'">
                                                    {{boqObj[element['sno']]['eff_quantity']+boqObj[element['sno']]['already_measured']-element.dev_quantity}}

                                                </div>

                                            </div>
                                            <div  *ngIf="boqObj[element['sno']]== undefined && boqObj[element['sno']]== null ">
                                            {{ element.dev_quantity}}
                                            </div> 
                                            <!-- <input type="number" class="form-control" [(ngModel)]="element['new_quantity']" (change)="changeNewQuantity(element['sno'])"> -->

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- <button class="btn btn-info" (click)="viewStatus(element)">View
                                                Status</button>&nbsp; -->
                                            <button class="btn btn-danger" (click)="delete(element)">Delete</button>&nbsp; 
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator3 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="c_dev_flag" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="propos()">Proposed  Deviation</button>
                                &nbsp; &nbsp;
                                <button class=" btn btn-outline-primary" (click)="aprroved()"> Approved Deviation</button>

                            </div>

                            
                            <br>
                           <!--  <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select Project :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div>

                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Work -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWList" bindLabel="work_des" bindValue="id" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}}" (change)="changeWorkInTab2()" [(ngModel)]="selectedWork" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button class="btn btn-success" (click)="deviate()"><!-- Save -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION13']}} </button>&nbsp;
                                    <button class="btn btn-info" (click)="addsor()"><!-- Add SOR Item -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION14']}} </button>&nbsp;
                                    <button class="btn btn-primary" (click)="addnonsor()"><!-- Add Non SOR Item -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION15']}}</button>
                                </div>
                            </div>
                            <br>
                            <div class="example-container">

                                <table mat-table [dataSource]="datasource1" matSort #sortCol2="matSort">



                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- S.No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION16']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['sno']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Item Desc -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="max-width: 350px;">
                                            {{ element.item_desc}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Work Order Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION17']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="curr_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Current Effective Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION18']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.curr_quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="new_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- New Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION19']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="min-width: 100px;">
                                            <!-- {{ element.new_quantity}}  -->
                                            <input type="number" class="form-control" [(ngModel)]="element['new_quantity']" (change)="changeNewQuantity(element['sno'])">

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="less">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Less -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION20']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.less}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="excess">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Excess -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION21']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.excess}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="remark">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Remark -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION22']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="min-width: 150px;">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="element['remark']" class="form-control"></textarea>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="openPopup(element)">Select</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="u_dev_flag" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="propos()">Proposed  Deviation</button>
                                &nbsp; &nbsp;
                                <button class=" btn btn-outline-primary" (click)="aprroved()"> Approved Deviation</button>

                            </div>
                            <br>

                            
                            <!-- <br> -->
                           <!--  <div class="row">

                                <div class="col-4 text-right">
                                    <h6> Select Project :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div>

                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Work -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWList" bindLabel="work_des" bindValue="id" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION3']}}" disabled [(ngModel)]="selectedWork" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right">
                                    <button class="btn btn-success" (click)="updatedeviate()"><!-- Save -->Update </button>&nbsp;
                                    <button class="btn btn-info" (click)="updatesortab4()"><!-- Add SOR Item --> Update SOR Item</button>&nbsp;
                                    <button class="btn btn-primary" (click)="addnonsoruppdate()"><!-- Add Non SOR Item -->Update Non SOR Item</button>
                                </div>
                            </div>
                            <br>
                            <div class="example-container">

                                <table mat-table [dataSource]="datasource3" matSort #sortCol5="matSort">



                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- S.No. -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION16']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['sno']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Item Desc -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="max-width: 350px;">
                                            {{ element.item_desc}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Work Order Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION17']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="curr_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Current Effective Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION18']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.curr_quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="new_quantity">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- New Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION19']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="min-width: 100px;">
                                            <!-- {{ element.new_quantity}}  -->
                                            <input type="number" class="form-control" [(ngModel)]="element['new_quantity']" (change)="changeNewQuantitytab4(element['sno'])">

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="less">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Less -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION20']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.less}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="excess">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Excess -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION21']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.excess}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="remark">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Remark -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION22']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" style="min-width: 150px;">
                                            <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="element['remark']" class="form-control"></textarea>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="openPopup(element)">Select</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator4 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>


                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="A">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-2">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['sno']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6><!-- Item Desc  -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}:</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['item_desc']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6><!-- Work Order Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION17']}} :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['quantity']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6><!-- Current Effective Quantity -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION18']}} :</h6>
                    </div>
                    <div class="col-10">
                        {{selectedElement['curr_quantity']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Enter New Quantity :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['new_quantity']" (change)="changeNewQuantity(selectedElement['sno'])">
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-2">
                        <h6>Enter Less Quantity :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['less']" disabled>
                        <!-- {{selectedElement['less']}} -->
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Enter Excess Quantity :</h6>
                    </div>
                    <div class="col-4">
                        <input type="number" class="form-control" [(ngModel)]="selectedElement['excess']" disabled>

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br>


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="deviate()"data-bs-dismiss="modal"><!-- SAVE -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION13']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="SOR">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">SOR</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-6">
                        {{last_sno+sorItemList.length}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}} : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <textarea placeholder="Enter Item Description " style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="sorObj['item_desc']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Unit : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="this.mainService.codeValueTechObj['EMB009']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Unit" [(ngModel)]="sorObj['unit']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Rate" class="form-control" [(ngModel)]="sorObj['rate']">
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Enter Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Quantity" class="form-control" [(ngModel)]="sorObj['quantity']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Paid Last Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Paid Last Quantity" class="form-control" [(ngModel)]="sorObj['already_measured']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Last Paid Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Last Paid Rate" class="form-control" [(ngModel)]="sorObj['part_rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Item Code :<span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Enter Item Code" class="form-control" [(ngModel)]="sorObj['item_cd']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Is Pre GST Deduction Applicable : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="preGST" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..."
                         [(ngModel)]="sorObj['is_pre_gst']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="mainService.codeValueTechObj['EMB008']" bindLabel="value" bindValue="code" 
                        [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" (change)="changeoverunder()" [selectableGroup]="true" 
                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under Percentage (%): <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" *ngIf="sorObj['over_under'] =='ATPR'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                        <input type="number" class="form-control" *ngIf="sorObj['over_under'] !='ATPR'" placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-6">
                        <textarea placeholder="Enter Remark" style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="sorObj['remark']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary" (click)="addSorItem()" >ADD</button>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <hr>
                <div style="overflow-x: scroll;">

                    <table style="width: 100%;" class="table  table-bordered">
                        <thead style="background-color: blue; color: white;">
                            <tr>
                                <th>S No.</th>
                                <th><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}</th>
                                <th>Unit</th>
                                <th>Enter Rate</th>
                                <th>Enter Quantity</th>
                                <th>Enter Paid Last Quantity </th>
                                <th>Enter Last Paid Rate </th>
                                <th>Enter Item Code : </th>
                                <th>Is Pre GST Deduction Applicable :</th>
                                <th>Over Under  </th>
                                <th>Over Under Percentage (%)</th>
                                <th>Remark</th>
                                <th><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of sorItemList;let i=index">
                                <td>{{i+last_sno}}</td>
                                <td> {{ob['item_desc']}}</td>
                                <td>{{ob['unit']}}</td>
                                <td> {{ob['rate']}}</td>
                                <td > {{ob['quantity']}}</td>
                                <td>{{ob['already_measured']}}</td>
                                <td>{{ob['part_rate']}}</td>
                                <td>{{ob['item_cd']}}</td>
                                <td>{{ob['is_pre_gst']}}</td>
                                <td>{{ob['over_under']}}</td>
                                <td>{{ob['over_under_per']}}</td>
                                <td>{{ob['remark']}}</td>
                                <td><button type="button" class="btn btn-danger" (click)="deleteSorItem(i)" >Delete</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="submitsor()"data-bs-dismiss="modal"><!-- SAVE -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION13']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>




<div class="modal" id="NON-SOR">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">NON-SOR</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-6">
                        {{last_sno+nonsorItemList.length}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}} : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <textarea style="width: 100%; height: 80px;" placeholder="Enter Item Description " id="re" name="re" [(ngModel)]="sorObj['item_desc']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6> Item Type  : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="nonsoritem" bindLabel="code" bindValue="code" 
                        [multiple]="false" placeholder="Select Item type" (change)="changeitem()" [(ngModel)]="sorObj['item_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Unit : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="this.mainService.codeValueTechObj['EMB009']" bindLabel="desc" bindValue="code" 
                        [multiple]="false" placeholder="Select Unit" [(ngModel)]="sorObj['unit']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Rate" [(ngModel)]="sorObj['rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Quantity"  [(ngModel)]="sorObj['quantity']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Paid Last Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Paid Last Quantity " [(ngModel)]="sorObj['already_measured']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Last Paid Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Last Paid Rate" [(ngModel)]="sorObj['part_rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Item Code :<span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="text" class="form-control" placeholder="Enter Item Code" [(ngModel)]="sorObj['item_cd']">
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Is Pre GST Deduction Applicable : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="preGST" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['is_pre_gst']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select *ngIf="this.sorObj['item_type'] == 'Local item'" disabled [items]="mainService.codeValueTechObj['EMB008']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                        <ng-select [items]="mainService.codeValueTechObj['EMB008']" *ngIf="this.sorObj['item_type'] == 'DSR Item'"  bindLabel="value" bindValue="code" (change)="changeoverunder()" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under Percentage (%): <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'Local item'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">
                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'DSR Item' && sorObj['over_under'] =='ATPR'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'DSR Item' && sorObj['over_under'] !='ATPR'" placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-6">
                        <textarea style="width: 100%; height: 80px;" placeholder="Enter Remark" id="re" name="re" [(ngModel)]="sorObj['remark']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary" (click)="addnonSorItem()" >ADD</button>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <hr>
                <div style="overflow-x: scroll;">

                    <table style="width: 100%;" class="table  table-bordered">
                        <thead style="background-color: blue; color: white;">
                            <tr>
                                <th>S No.</th>
                                <th><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}</th>
                                <th>Unit</th>
                                <th>Enter Rate</th>
                                <th>Enter Quantity</th>
                                <th>Enter Paid Last Quantity </th>
                                <th>Enter Last Paid Rate </th>
                                <th>Enter Item Code : </th>
                                <th>Is Pre GST Deduction Applicable :</th>
                                <th>Over Under  </th>
                                <th>Over Under Percentage (%)</th>
                                <th>Remark</th>
                                <th><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of nonsorItemList;let i=index">
                                <td>{{i+last_sno}}</td>
                                <td> {{ob['item_desc']}}</td>
                                <td>{{ob['unit']}}</td>
                                <td> {{ob['rate']}}</td>
                                <td > {{ob['quantity']}}</td>
                                <td>{{ob['already_measured']}}</td>
                                <td>{{ob['part_rate']}}</td>
                                <td>{{ob['item_cd']}}</td>
                                <td>{{ob['is_pre_gst']}}</td>
                                <td>{{ob['over_under']}}</td>
                                <td>{{ob['over_under_per']}}</td>
                                <td>{{ob['remark']}}</td>
                                <td><button type="button" class="btn btn-danger" (click)="deleteNonSorItem(i)" >Delete</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="submitnonsor()"data-bs-dismiss="modal"><!-- SAVE -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION13']}}</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="SORUPDATE">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">UPDATE SOR</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-6">
                        {{sorObj['sno']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}} : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <textarea placeholder="Enter Item Description " style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="sorObj['item_desc']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Unit : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="this.mainService.codeValueTechObj['EMB009']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Unit" [(ngModel)]="sorObj['unit']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Rate" class="form-control" [(ngModel)]="sorObj['rate']">
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Enter Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Quantity" class="form-control" [(ngModel)]="sorObj['quantity']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Paid Last Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Paid Last Quantity" class="form-control" [(ngModel)]="sorObj['already_measured']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Last Paid Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Last Paid Rate" class="form-control" [(ngModel)]="sorObj['part_rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Item Code :<span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="Enter Item Code" class="form-control" [(ngModel)]="sorObj['item_cd']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Is Pre GST Deduction Applicable : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="preGST" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..."
                         [(ngModel)]="sorObj['is_pre_gst']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="mainService.codeValueTechObj['EMB008']" bindLabel="value" bindValue="code" 
                        [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" (change)="changeoverunder()" [selectableGroup]="true" 
                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under Percentage (%): <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" *ngIf="sorObj['over_under'] =='ATPR'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                        <input type="number" class="form-control" *ngIf="sorObj['over_under'] !='ATPR'" placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-6">
                        <textarea placeholder="Enter Remark" style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="sorObj['remark']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary" (click)="updatetab4SorItem()" >Update</button>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <hr>
                <div style="overflow-x: scroll;">

                    <table style="width: 100%;" class="table  table-bordered">
                        <thead style="background-color: blue; color: white;">
                            <tr>
                                <th>S No.</th>
                                <th><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}</th>
                                <th>Unit</th>
                                <th>Enter Rate</th>
                                <th>Enter Quantity</th>
                                <th>Enter Paid Last Quantity </th>
                                <th>Enter Last Paid Rate </th>
                                <th>Enter Item Code : </th>
                                <th>Is Pre GST Deduction Applicable :</th>
                                <th>Over Under  </th>
                                <th>Over Under Percentage (%)</th>
                                <th>Remark</th>
                                <th><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of sorItemList;let i=index">
                                <td>{{ob['sno']}}</td>
                                <td> {{ob['item_desc']}}</td>
                                <td>{{ob['unit']}}</td>
                                <td> {{ob['rate']}}</td>
                                <td > {{ob['quantity']}}</td>
                                <td>{{ob['already_measured']}}</td>
                                <td>{{ob['part_rate']}}</td>
                                <td>{{ob['item_cd']}}</td>
                                <td>{{ob['is_pre_gst']}}</td>
                                <td>{{ob['over_under']}}</td>
                                <td>{{ob['over_under_per']}}</td>
                                <td>{{ob['remark']}}</td>
                                <td><button type="button" class="btn btn-danger" (click)="deleteSorItem(i)" >Delete</button>
                                    <button type="button" class="btn btn-primary" (click)="updatesoritem(ob)" >update</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
              
                </div>

            </div>
        </div>
    </div>
</div>




<div class="modal" id="NON-SORUPDATE">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">NON-SOR</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-6">
                        {{sorObj['sno']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}} : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <textarea style="width: 100%; height: 80px;" placeholder="Enter Item Description " id="re" name="re" [(ngModel)]="sorObj['item_desc']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6> Item Type  : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="nonsoritem" bindLabel="code" bindValue="code" 
                        [multiple]="false" placeholder="Select Item type" (change)="changeitem()" [(ngModel)]="sorObj['item_type']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Unit : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="this.mainService.codeValueTechObj['EMB009']" bindLabel="desc" bindValue="code" 
                        [multiple]="false" placeholder="Select Unit" [(ngModel)]="sorObj['unit']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Rate" [(ngModel)]="sorObj['rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Quantity"  [(ngModel)]="sorObj['quantity']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Paid Last Quantity : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Paid Last Quantity " [(ngModel)]="sorObj['already_measured']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Last Paid Rate : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" placeholder="Enter Last Paid Rate" [(ngModel)]="sorObj['part_rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Item Code :<span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="text" class="form-control" placeholder="Enter Item Code" [(ngModel)]="sorObj['item_cd']">
                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Is Pre GST Deduction Applicable : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="preGST" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['is_pre_gst']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under : <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <ng-select *ngIf="this.sorObj['item_type'] == 'Local item'" disabled [items]="mainService.codeValueTechObj['EMB008']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                        <ng-select [items]="mainService.codeValueTechObj['EMB008']" *ngIf="this.sorObj['item_type'] == 'DSR Item'"  bindLabel="value" bindValue="code" (change)="changeoverunder()" [multiple]="false" placeholder="Select..." [(ngModel)]="sorObj['over_under']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Over Under Percentage (%): <span style="color: red;">*</span></h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'Local item'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">
                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'DSR Item' && sorObj['over_under'] =='ATPR'" disabled placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                        <input type="number" class="form-control" *ngIf="this.sorObj['item_type'] == 'DSR Item' && sorObj['over_under'] !='ATPR'" placeholder="Enter Over Under Percentage (%)" [(ngModel)]="sorObj['over_under_per']">

                    </div>
                </div>
                <br>

                <div class="row">
                    <div class="col-4">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-6">
                        <textarea style="width: 100%; height: 80px;" placeholder="Enter Remark" id="re" name="re" [(ngModel)]="sorObj['remark']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-primary" (click)="updatetab4nonSorItem()" >Update</button>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <hr>
                <div style="overflow-x: scroll;">

                    <table style="width: 100%;" class="table  table-bordered">
                        <thead style="background-color: blue; color: white;">
                            <tr>
                                <th>S No.</th>
                                <th><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}}</th>
                                <th>Unit</th>
                                <th>Enter Rate</th>
                                <th>Enter Quantity</th>
                                <th>Enter Paid Last Quantity </th>
                                <th>Enter Last Paid Rate </th>
                                <th>Enter Item Code : </th>
                                <th>Is Pre GST Deduction Applicable :</th>
                                <th>Over Under  </th>
                                <th>Over Under Percentage (%)</th>
                                <th>Remark</th>
                                <th><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION12']}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ob of nonsorItemList;let i=index">
                                <td>{{ob['sno']}}</td>
                                <td> {{ob['item_desc']}}</td>2	0.00 - 1.50M	9807	125
                                <td>{{ob['unit']}}</td>
                                <td> {{ob['rate']}}</td>
                                <td > {{ob['quantity']}}</td>
                                <td>{{ob['already_measured']}}</td>
                                <td>{{ob['part_rate']}}</td>
                                <td>{{ob['item_cd']}}</td>
                                <td>{{ob['is_pre_gst']}}</td>
                                <td>{{ob['over_under']}}</td>
                                <td>{{ob['over_under_per']}}</td>
                                <td>{{ob['remark']}}</td>
                                <td><button type="button" class="btn btn-danger" (click)="deleteNonSorItem(i)" >Delete</button>
                                    <button type="button" class="btn btn-primary" (click)="updatenonsoritem(ob)" >Update</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                 
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="update_SOR">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Update Item</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">


                <div class="row">
                    <div class="col-4">
                        <h6>SNO. :</h6>
                    </div>
                    <div class="col-6">
                        {{updatesorObj['sno']}}
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6><!-- Item Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEVIATION8']}} :</h6>
                    </div>
                    <div class="col-6">
                        <textarea placeholder="Enter Item Description" style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="updatesorObj['item_desc']" class="form-control"></textarea>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Unit :</h6>
                    </div>
                    <div class="col-6">
                        <ng-select [items]="this.mainService.codeValueTechObj['EMB009']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Unit" [(ngModel)]="updatesorObj['unit']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="unit">
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Rate :</h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Rate" class="form-control" [(ngModel)]="updatesorObj['rate']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Quantity :</h6>
                    </div>
                    <div class="col-6">
                        <input type="number" placeholder="Enter Quantity" class="form-control" [(ngModel)]="updatesorObj['new_dev_quantity']">
                    </div>
                </div>
                <br>
                <!-- <div class="row">
                    <div class="col-4">
                        <h6>Enter Paid Last Quantity :</h6>
                    </div>
                    <div class="col-6">
                        <input type="number" class="form-control" [(ngModel)]="sorObj['already_measured']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4">
                        <h6>Enter Item Code :</h6>
                    </div>AllItem
                </div>
                <br> -->

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                    <button type="button" class="btn btn-success" (click)="updatesor()"data-bs-dismiss="modal">Update</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

           
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                             
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                                
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{ob.level_of_approval}} - {{ob.designation_cd}} - {{ob.status}}

                               
                            </div>
                        </div>
                    </a>


                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div> -->