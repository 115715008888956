import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../service/establishment.service';
import { MainService } from '../service/main.service';
import { JsonPipe } from '@angular/common';
declare var $: any
import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { ScriptLoaderService } from '../../_services/script-loader.service';
@Component({
  selector: 'app-hrms-notification',
  templateUrl: './hrms-notification.component.html',
  styleUrls: ['./hrms-notification.component.css']
})
export class HrmsNotificationComponent implements OnInit {

  constructor(public mainService: MainService, private sanitizer: DomSanitizer, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpServ: EstablishmentService) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: any = ['id', 'subject', 'description', 'action'];
  datasource: any;
  employeeObj: any = {};
  erpUser: any;
  b_acct_id: any;
  ERObj: any = {};
  selectEmpObj: any = {};
  completeERObj: any = {};
  allEmployees: any = [];
  employeeIdtoName: any = {};
  allArr: any = [];

  Obj: any = {};

  uploadObj: any = {}
  imageBlobUrl: any;
  imgURL: any
  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;

    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any ) => { file.withCredentials = false; };
    await this.getNotice();
  }

  // Avinash
  async aviMatch(t:any ) {

    let ext = t.split(".");

    for (let i = 0; i < ext.length; i++) {
      if (ext[i] == "exe" || ext[i] == "mp4" || ext[i] == "MKV" || ext[i] == "AVI" || ext[i] == "MOV" || ext[i] == 'apk') {
        return 0;
      }

    }
    return
  }
  async onFileUpload(event:any , files:any ) {
    this.selectedFile = <File>event.target.files[0];
    // console.log('thsi is the selected doc --',event,files)

    for (let i = 0; i < event.target.files.length; i++) {
      let text = event.target.files[i]["name"]
      console.log("this is doc name length--", text.length);
      let res = await this.aviMatch(text);

      if (res == 0) {
        $('#uploadDoc').val('');
        this.uploadDisabled = false;
        swal.fire('Info', `You Enter the .exe File / Video File, Remove This First..!`, 'info');
        return;
      }
    }

    if (files.length === 0) {
      this.uploadDisabled = false;
      return;
    }

    const mimeType = files[0].type;
    console.log(" this is the imnetype --", mimeType);
    // if (mimeType.match(/image\/*/) == null) {
    //   this.uploadDisabled=true;
    //   return;
    // }
    this.uploadDisabled = true;
    const reader = new FileReader();
    // this.uploadDisabled=false;
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      console.log("this is the onload event")
    };

  }

  uploadDisabled = false;
  async refraceCreate() {
    this.Obj['subject'] = '';
    this.Obj['description'] = '';
    this.uploadDisabled = false;
    $('#uploadDoc').val('');
  }

  async upload() {

    if (this.Obj['description'] == '' || this.Obj['description'] == null || this.Obj['description'] == undefined ||
      this.Obj['subject'] == '' || this.Obj['subject'] == null || this.Obj['subject'] == undefined) {
      swal.fire('Warning', '* Field Required !', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const obj:any  = new Object();
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['document_name'] = this.uploader.queue[0].some.name;
    obj['description'] = this.Obj['description'];
    obj['document_type_code'] = 'NOTICE';
    obj['subject'] = this.Obj['subject'];
    obj['create_user_id'] = this.erpUser.user_id;
    //Avinash ( not sure about employee id so by default sending create user id as emp id)
    obj['emp_id'] = this.erpUser.user_id;
    this.spinner.show()
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = this.httpUrl + '/hr/setting/notice/insertnotice' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      if (!response.error) {
        this.refraceCreate();
        this.getNotice();
        this.spinner.hide();
        // this.snackBar.open("File Uploaded Successfully", 'Success', {
        //   duration: 5000
        // });
        swal.fire("Success", "File Uploaded Successfully", "success")


      } else {
        this.spinner.hide()

        swal.fire('Error', `${JSON.parse(response.data)}`, 'error')
        // this.snackBar.open(JSON.parse(response.data), 'Error', {
        //   duration: 5000
        // });

      }
    };
  }


  async getNotice() {
    this.spinner.show()
    var resp = await this.allEmpServ.getAllNotice(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting notice list ', 'error')
      // this.snackBar.open("Error while getting notice list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  deleteConformation(element:any ) {
    swal.fire({
      title: 'Are You Sure?',
      // text:'Do You Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.value) {
        await this.delete(element)
      }
      else {
        // this.getEmpSuspensionReinstate();
        // this.reinstate();

      }
    })
  }
  async delete(element:any ) {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id;

    var resp = await this.allEmpServ.deleteNotice(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.getNotice();
      this.spinner.hide();
      swal.fire('Success', 'Notice Deleted Successfully ', 'success')
      // this.snackBar.open("Notice Deleted Successfully", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while deleting notice ', 'error')
      // this.snackBar.open("Error while deleting notice", 'Error', {
      //   duration: 5000
      // });
    }
  }

  open_update(element:any ) {
    this.Obj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }
  async update() {
    if (this.Obj['subject'] == '' || this.Obj['subject'] == null || this.Obj['subject'] == undefined ||
      this.Obj['description'] == '' || this.Obj['description'] == null || this.Obj['description'] == undefined) {
      swal.fire('Warning', '* Field Required', 'warning');
      return;

    }
    this.spinner.show()
    this.Obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.allEmpServ.updateNotice(this.Obj);
    if (resp['error'] == false) {
      this.getNotice();
      this.spinner.hide();
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      //  this.snackBar.open("Notice Update Successfully", 'Success', {
      //    duration: 5000
      //  });
      swal.fire('Success', "Notice Update Successfully", "success")
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while updating notice ', 'error')
      //  this.snackBar.open("Error while updating notice", 'Error', {
      //    duration: 5000
      //  });
    }
  }

  filename:any ;
  async view(element:any ) {
    var obj:any  = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = element.upload_id
    obj['filename'] = element.document_name
    this.filename = element.document_name
    this.spinner.show()
    const res = await this.allEmpServ.getUploadedFileData(obj);
    if (res) {

      var docname = element.document_name;
      var ext = docname.split('.');

      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      this.spinner.hide()

    }
  }

  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }





}
