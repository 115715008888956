<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!--Employee Education Info -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-bar-chart"></i>
                                <!-- Employee Education Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-settings"></i>
                                <!-- Add Employee Education Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info4']}}</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-announcement"></i>
                                <!-- Update Employee Education Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info3']}}</a>

                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Add Education Detail</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info5']}}   <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info12']}}" [(ngModel)]="selectEmpObj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info13']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="education_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Course Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0005'][element.education_name]}}


                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="education_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--  Course Type -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0006'][element.education_type_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pass_year">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Passing Year -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pass_year_code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">
                                                <!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info10']}}
                                            </button>
                                            <button class="btn btn-outline-danger" (click)="deleteEducation(element)">
                                                <!-- Delete -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info11']}}
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info5']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info12']}}" [(ngModel)]="selectEmpObj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Course Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info6']}} <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0005']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info14']}}" [(ngModel)]="eductionObj['education_name']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Course Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info7']}} <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0006']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info15']}}" [(ngModel)]="eductionObj['education_type_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Passing Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info8']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="month" [(ngModel)]="eductionObj['pass_year_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitEductionInfo()">
                                        <!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info16']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--   Course Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info6']}}<span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0005']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info12']}}" [(ngModel)]="eductionObj['education_name']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Course Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info7']}}<span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0006']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info12']}}" [(ngModel)]="eductionObj['education_type_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!--  Passing Year : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info8']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="month" [(ngModel)]="eductionObj['pass_year_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateEductionInfo()">
                                        <!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'Employee-Education_Info10']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>
    Loading....
</ngx-spinner>
