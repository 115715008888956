import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileUploader } from 'ng2-file-upload';
declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import { DocumentService } from '../../service/document.service';
import { MatStepper } from '@angular/material/stepper';
import { PropCostingService } from '../../service/prop-costing.service';
import { PropCommonSService } from '../../service/prop-common-s.service';

@Component({
  selector: 'app-prop-costing',
  templateUrl: './prop-costing.component.html',
  styleUrls: ['./prop-costing.component.css']
})
export class PropCostingComponent implements OnInit {


  datasource:any;
  isLinear:any = false;
  first:any = true;
  selectedFile: any = null;
  second :any= true;
  radio:any = false;
  propObj:any= {}
  subsidy_type = [{ id: "Amount" }, { id: "Percentage" }]
  cost_type = [{ id: "Basic" }, { id: "Total" }]
  displayedColumns = ['assigned_property_number', 'property_type', 'description', 'area', 'action'];
  displayedColumns_1 = ['id', 'code', 'name', 'no_of_prop', 'status', 'effective_date','action']
  GISArr:any = []
  third:any = true;
  fourth :any= true;
  fifth:any = true
  sixth :any= true
  work_id:any;
  BoqTable :any= [];
  total_sum:any = 0;
  imgURL:any;
  Obj :any= {}
  enableStep3: any;
  b_acct_id:any;
  ebillUser:any;
  planing_arr:any = [{}]
  acp_arr = [{}]
  public imagePath:any;
  httpUrl:any;
  radio2 = false;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  property_group_check_data:any
  allProp: any;
  constructor(private spinner: NgxSpinnerService, private commonS: PropCommonSService, private propHierS: PropHierarchyService, private costingService: PropCostingService, private docService: DocumentService, private sale_service: SaleService, public auth: AuthenticationService, public mainService: MainService, private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }
  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.Obj['total_area'] = 0
    this.Obj['unit'] = this.mainService.codeValueTechObj['PROP0021'][0]['code'];
    this.httpUrl = this.mainService.httpUrl
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getAllLevls()
  }
  async add_dropdown(data:any, index:any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.allHier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.allHier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.allHier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.allHier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.allHier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.allHier, this.Obj)
    }
  }
  remove_dropdown(data:any, index:any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
  }
  data:any = [{}]
  first_list :any= []
  async getAllHier() {
    this.first_list = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, 'list data')
      this.first_list = resp['data']
      for (let i = 0; i < this.first_list.length; i++) {
        if (this.first_list[i]['hier_type'] != "Planing") {
          this.first_list.splice(i, 1)
        }
      }
    } else {

    }
  }
  allHier:any = []
  async getAllLevls() {
    this.Obj['hier_type_id'] = this.first_list[0]['hier_type_id']
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    //console.log(obj, 'ddddddddddd')
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    //console.log(resp)
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      console.log(this.allHier, 'before')
      this.level1 = await this.commonS.refresh(this.allHier)
      console.log(this.allHier, this.level1, 'after')

    } else {
    }
  }
  level1:any = []
  level2:any = []
  level3:any = []
  level4:any = []
  level5:any = []
  level6:any = []
  level7:any = []
  list_flag:any = true
  create_flag = false
  update_flag = false
  open_create() {
    let ob:any = {}
    ob = Object.assign({}, this.Obj)
    this.Obj['hier_ident_code'] = ob['hier_ident_code']
    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
  async open_list() {
    this.Obj = {}
    this.data = []
    this.data = [{}]
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
    await this.getAllLevls()

  }
  propertyTypeData = []
  async fetchPropertyType() {
    this.propertyTypeData = []
    let obj:any = {}
    obj = Object.assign({}, this.Obj)
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    obj['prop_hier_id'] = hier_id
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    console.log(obj)
    let resp:any = await this.costingService.getPropertyType(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      if (resp['data'].length > 0) {
        this.propertyTypeData = resp['data']
      } else {
        Swal.fire('Information', 'No Record Found On This Node..! Please Select Till Leaf Level', 'info')
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Property Type', 'error')
    }
  }
  async fetchlist() {
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    this.spinner.show()
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['prop_hier_id'] = hier_id
    console.log(this.Obj)
    let resp :any= await this.costingService.getPropertyTypeCost(this.Obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.allProp = resp['data']
      this.spinner.hide()
      this.datasource = new MatTableDataSource(this.allProp);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  hier_node_obj:any = {}
  async getAllLevls2() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }
  async open_update(element:any) {
    this.Obj = Object.assign({}, element)
    await this.setPreDefinedHierrarchy(element)
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
  }
  leaf_level:any;
  async setPreDefinedHierrarchy(data:any) {
    this.data = []
    console.log(data)
    let ob = this.hier_node_obj[data['prop_hier_id']]
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }
        this.data.push(ob)
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }

  }
  async update() {
    this.spinner.show()
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    this.Obj['prop_hier_id'] = hier_id
    this.Obj['b_acct_id'] = this.b_acct_id
    console.log(this.Obj)
    let resp :any= await this.costingService.updatePropertyCost(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      let ob = {}
      ob = Object.assign({}, this.Obj)
      // this.Obj = {}
      this.list_flag = true
      this.create_flag = false
      this.update_flag = false
      this.data = []
      this.data = [{}]
      // this.Obj['hier_type_id'] = null
      // this.Obj['lvl1_cd'] = null
      Swal.fire('Success...', resp['data'], 'success')
      await this.fetchlist()
      this.Obj['property_type'] = null;
      this.Obj['pro_rate'] = null;
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }

  async delete(element:any) {
    let ob = Object.assign({}, this.Obj)

    this.Obj = Object.assign({}, element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete()
        this.Obj = Object.assign({}, ob)
      }
    })


  }
  async finaldelete() {
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    console.log(this.Obj)
    let resp :any= await this.costingService.inactivePropertyTypeCost(this.Obj);
    console.log(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.fetchlist()
      this.list_flag = true
      this.create_flag = false
      this.update_flag = false

      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }


  }


  // remove_dropdown(data, i) {

  //   if (this.planing_arr.length > 1) {
  //     this.planing_arr.pop()
  //   }
  //   let dummy = []
  //   for (let i = 0; i < this.planing_arr.length; i++) {
  //     dummy.push(this.planing_arr[i]['hierarchy_type'])
  //   }
  //   let dummy2 = []
  //   if (dummy.length > 0) {
  //     for (let i = 0; i < dummy.length; i++) {
  //       for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
  //         if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
  //           this.Obj['level'] = this.existing_heirarchy_nodes[j]['level']
  //           this.Obj['node_cd'] = this.existing_heirarchy_nodes[j]['node_cd']
  //           dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['path_desc'] = dummy2.join(' --> ')
  // }


  used_nodes = []
  selected_row = {}
  // add_dropdown(data, index) {

  //    let flag = true
  //   let obj:any = {}
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
  //       obj = Object.assign({}, this.existing_heirarchy_nodes[i])
  //     }
  //   }
  //   this.selected_row = Object.assign({}, obj)
  //   this.selected_row['index'] = index

  //   this.filter_data()
  // }

  async submit() {
    if (!this.Obj['pro_rate'] || !this.Obj['unit'] || !this.Obj['property_type']) {
      Swal.fire('Warning...', 'Please Select All * Fields', 'warning')
    } else {
      let n = 0
      for (let j = 1; j <= 8; j++) {
        if (this.Obj['lvl' + j + '_cd']) {
          n = j
        }
      }
      let hier_id = 0
      for (let i = 0; i < this.allHier.length; i++) {
        if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
          {
            console.log(this.allHier[i])
            hier_id = this.allHier[i]['prop_hier_id']
          }
        }
      }
      this.Obj['prop_hier_id'] = hier_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['user_id'] = this.ebillUser['user_id']
      console.log(this.Obj)
      this.spinner.show()
      let resp :any= await this.costingService.createPropertyCosting(this.Obj)
      console.log(resp)
      if (resp['error'] == false) {
        let ob = {}
        // ob = Object.assign({}, this.Obj)
        // this.Obj = {}
        // console.log(ob)
        // this.Obj['hier_ident_code'] = ob['hier_ident_code']

        // await this.fetchlist()
        this.spinner.hide()
        this.list_flag = false;
        this.create_flag = true;
        this.update_flag = false
        // empty afeter creating cast
        this.Obj['property_type'] = null;
        this.Obj['pro_rate'] = null;
        // this.data = []
        // this.data = [{}]
        // this.Obj['hier_type_id'] = null
        // this.Obj['lvl1_cd'] = null
        Swal.fire('Success...', resp['data'], 'success')
      } else {
        this.spinner.hide()
        Swal.fire('info', resp['data'], 'info')
      }
    }

  }
  locationInfoData = []
  used_node_arr = []

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
