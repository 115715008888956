<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Employee Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                               <!--  REPORT LIST -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="view_flag" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i> <!-- VIEW REPORT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" *ngIf="create_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i> <!-- CREATE REPORT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee4']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4"  *ngIf="update_flag" data-toggle="tab" (click)="refresh()"><i
                                     class="ti-settings"></i> <!-- UPDATE REPORT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee5']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active"  *ngIf="list_flag" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Open Create</button>
                                </div>
                            </div>
                             <br>

                            <!-- <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"  [(ngModel)]="filterValue" placeholder="Filter">
                            </mat-form-field> -->

                            <!-- <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field> -->
                            <mat-form-field>

                                <!-- <mat-label>Filter</mat-label> -->

                                <input matInput (keyup)="applyFilter($event)" placeholder="filter" #input>
                              </mat-form-field>


                            <div class=" example-container">
                                <!-- <table mat-table [dataSource]="datasource" matSort> -->
                                <table mat-table [dataSource]="datasource"  matSort  #sortCol0="matSort">

                                    <ng-container matColumnDef="report_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Report ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.report_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="report_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Report Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.report_name }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee8']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-info" (click)="submit_for_view(element)"><!-- View -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee9']}}</button>&nbsp;&nbsp;
                                            <button class="btn btn-outline-primary" (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee10']}}</button>&nbsp;&nbsp;
                                            <button class="btn btn-outline-danger" (click)="deleteData(element)"><!-- Delete -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee11']}}</button>&nbsp;&nbsp;
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator0 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="view_flag"  id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                             <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Select Node :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Employee Node</button>
                                </div>
                                </div>
                                <br>
                                <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Selected Node Path: <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>

                            <hr>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Cadre -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee12']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="CadreArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['cadre_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Class -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee13']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ClassArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['class_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="class_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee14']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="DesignationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['designation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="designation_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6><-- Select Section --{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee15']}}:</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="SectionArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['section_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="section_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br> -->


                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Education -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee16']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="EducationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['education_name']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="education_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Pay Commission -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee17']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="PayCommissionArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['pay_commission_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_commission_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- STATUS -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee18']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="Status" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['emp_status_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_status_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Project Fields -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee19']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="project_fields" bindLabel="field_business_name" bindValue="field_technical_name" [multiple]="true" placeholder="Select  Fields Name" [(ngModel)]="project" [closeOnSelect]="false" name="project_fields">

                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()"><!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee20']}}</button>
                                </div>
                            </div>
                            <hr>
                            <div id="p" [hidden]="true">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img [src]="mainService.accountImageUrl" class="img-circle" width="90px" />

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h4>{{mainService.accInfo['account_name']}} ({{mainService.accInfo['account_short_name']}})
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <h6>
                                            <!-- Employee Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee1']}}
                                        </h6>
                                    </div>
                                </div>
                                <table class="table" style="border: black;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th *ngFor="let col of displayedColumns1; let i= index">{{ObjFieldTechNameToBusinessName[col]}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ob of result">

                                            <td *ngFor="let col of displayedColumns1; let i= index">
                                                <h6>{{ob[col]}}</h6>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                                <hr>
                            </div>








                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-primary" (click)="printPdf()"><!-- Print -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee21']}}</button>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-primary" (click)="export()"><!-- EXPORT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee22']}}</button>
                                </div>
                            </div>

                            <br>

                            <div class=" example-container">
                                <!-- <table mat-table [dataSource]="datasource1" matSort #sortCol2="matSort"> -->

                                    <table mat-table [dataSource]="datasource1" matSort  #sortCol1="matSort">


                                    <ng-container matColumnDef="{{col}}" *ngFor="let col of displayedColumns1; let i= index">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{ObjFieldTechNameToBusinessName[col]}}
                                        </th>

                                        <div *ngIf="ObjFieldTechNameToBusinessName[col] != 'Employee ID'">
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element[col] }} </td>
                                        </div>
                                        <div *ngIf="ObjFieldTechNameToBusinessName[col] == 'Employee ID'">
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element[col] }} </td>
                                        </div>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" *ngIf="create_flag"  id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                             <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Select Node :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Employee Node</button>
                                </div>
                                </div>
                                <br>
                                <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Selected Node Path: <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>


                           <hr>
<br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Cadre -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee12']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="CadreArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['cadre_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Class -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee13']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ClassArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['class_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="class_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee14']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="DesignationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['designation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="designation_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6><-- Select Section --{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee15']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="SectionArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['section_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="section_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-left">
                                    <h6>
                                      Select Functional Area :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allModule"  (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="false" name="field_name">
                                    </ng-select>
                                </div>
                                <br>
                            </div> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Education -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee16']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="EducationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['education_name']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="education_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Pay Commission -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee17']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="PayCommissionArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['pay_commission_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_commission_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- STATUS -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee18']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="Status" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['emp_status_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_status_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Project Fields -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee19']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="project_fields" bindLabel="field_business_name" bindValue="field_technical_name" [multiple]="true" placeholder="Select  Fields Name" [(ngModel)]="project" [closeOnSelect]="false" name="project_fields">

                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" data-toggle="modal" (click)="submitCheck()" data-backdrop="false"><!-- Save -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee23']}}</button>&nbsp;
                                    <!-- <button class="btn btn-primary" data-toggle="modal" data-target="#save-report" (click)="submitCheck()" data-backdrop="false">Save{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee23']}}</button>&nbsp; -->
                                    <button class="btn btn-primary" (click)="submit_and_view()"><!-- View -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee9']}}</button>
                                </div>
                            </div>
                        </div>


                        <div class="tab-pane fade show active"  *ngIf="update_flag" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                             <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Select Node :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                        Employee Node</button>
                                </div>
                                </div>
                                <br>
                                <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Selected Node Path: <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>

                            <hr style="color: brown;">
                          <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Cadre -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee12']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="CadreArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['cadre_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cadre_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Class -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee13']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ClassArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['class_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="class_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee14']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="DesignationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['designation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="designation_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Education -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee16']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="EducationArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['education_name']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="education_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Pay Commission -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee17']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="PayCommissionArr" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['pay_commission_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="pay_commission_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- STATUS -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee18']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="Status" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="filter['emp_status_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="emp_status_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6><!-- Select Project Fields -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee19']}}:<span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="project_fields" bindLabel="field_business_name" bindValue="field_technical_name" [multiple]="true" placeholder="Select  Fields Name" [(ngModel)]="project" [closeOnSelect]="false" name="project_fields">

                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <!-- <button class="btn btn-primary" data-toggle="modal" data-target="#update-report" data-backdrop="false">Update{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee10']}}</button> -->
                                    <button class="btn btn-primary" data-toggle="modal" (click)="updateCheck()" data-backdrop="false"><!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee10']}}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="save-report">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title"><!-- Report Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee7']}} </h4>
            </div>


            <div class="modal-body">

                <div class="row">
                    <label class="col-lg-4 control-label" style="font-size: 15px ;"><b><!-- Report Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee7']}}:<span style="color: red;">*</span></b></label>
                    <div class="col-lg-8">
                        <input type="text" [(ngModel)]="report_name" class="form-control" />
                    </div>
                </div>

            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="save()"><!-- Save Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee24']}}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee25']}}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="update-report">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title"><!-- Report Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee7']}}</h4>
            </div>


            <div class="modal-body">

                <div class="row">
                    <label class="col-lg-4 control-label" style="font-size: 15px ;"><b><!-- Report Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee7']}}:<span style="color: red;">*</span></b></label>
                    <div class="col-lg-8">
                        <input type="text" [(ngModel)]="report_name" class="form-control" />
                    </div>
                </div>

            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="update()"><!-- Update Report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee5']}}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal"><!-- Close -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Employee25']}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary"  (click)="submit_act()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>


<ngx-spinner>Loading....</ngx-spinner>
