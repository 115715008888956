import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/tracking";
  }

  async createLocation(obj :any){
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/boundry_map/createZonelocation' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getLocationData(obj :any){
    const resp = await this.http.get(this.httpUrl + '/boundry_map/getZonedata' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
 async getcordinateLocationData(obj :any){
    const resp = await this.http.get(this.httpUrl + '/boundry_map/getallzonecordinate' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async deletezone(obj :any){
    const resp = await this.http.delete<any>(this.httpUrl + '/boundry_map/deletezone' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getcordinateLocationData_for(obj :any){
    const resp = await this.http.get(this.httpUrl + '/boundry_map/getallzonecordinate_for' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

}


