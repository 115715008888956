import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service';
import { MainService } from '../../service/main.service';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any
@Component({
  selector: 'app-termination',
  templateUrl: './termination.component.html',
  styleUrls: ['./termination.component.css']
})
export class TerminationComponent implements OnInit {


  constructor(public mainService: MainService, private sanitizer: DomSanitizer, private allEmpService: AllEmpService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private estabService: EstablishmentService) { }
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  activeEmpArr: any = []
  terminatedArr: any = []
  allEmployees: any = [];
  selectEmpObj: any = {};
  terminationObj: any = {};
  codeValueTechObj: any = {};
  allArr: any = []
  employeeObj: any = {};
  newallEmplyees: any = []
  toPay: any = []
  totalAmtToPay: any = 0
  lastPaidArr: any = []
  selectedFile: any;
  imagePath: any;
  currDate: any;
  allEnqArr: any = [];
  allChargesheetFileEnquiry: any = [];
  allChargesheetFileEnquiryObj: any = {};
  compArr: any = [];
  empObj: any = {};
  uploader: any;
  httpUrl: any;
  allTermination: any = [];
  allTerminationObj: any = {}
  viewObj: any = {};
  imgURL: any;
  filename: any;
  terminateFile: any = [];
  termArray: any = [];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  displayedColumns = ['term_id', 'emp_id', 'emp_name', 'chargesheet_date', 'enq_officer_name', 'order_id', 'termination_date', 'term_status', 'action'];
  datasource: any;

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;
    await this.getTerminateFile();
    await this.getTermination();
    await this.getAllEmployees();
    await this.getEnquiryForTermination();
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    this.currDate = new Date().toISOString().slice(0, 10);
    this.terminationObj['termination_date'] = this.currDate;
  }


  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees() {
    var arr: any = []

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];
    var resp = await this.estabService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {
      arr = resp.data;


      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
        this.allEmployees.push(obj)
      }
      this.activeEmpArr = []
      this.terminatedArr = []
      for (let i = 0; i < this.allEmployees.length; i++) {
        if (this.allEmployees[i].employee_current_type_code == "TERMINATED") {
          this.terminatedArr.push(this.allEmployees[i]);

        } else {
          if (this.allEmployees[i].emp_status_code == "ACTIVE") {
            this.activeEmpArr.push(this.allEmployees[i])

          }
        }
      }

      this.newallEmplyees = []
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.activeEmpArr[i]);
        obj['only_name'] = obj['emp_name'];
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }
    } else {
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  checkTrue:any = false;
  checkBox() {

    // console.log("thsi is the value--",val)
    //     if(val==true)
    //     {
    //        this.checkTrue=true;
    //     }
    //     else{
    // this.checkTrue==false;
    //     }
    let check:any  = Array.from(document.getElementsByClassName('check'))
    console.log("this si chexk--", check[0]['checked']);
    if (check[0]['checked'] == true) {
      console.log("this is checked ")
      this.checkTrue = true;
    }
    else {
      this.checkTrue = false;
    }

  }



  async getEnquiryForTermination() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.estabService.getEnquiryForTermination(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allEnqArr = [];
      this.allEnqArr = resp.data;
      this.allChargesheetFileEnquiry = [];
      this.spinner.hide();
      for (let i = 0; i < this.allEnqArr.length; i++) {
        if (this.allEnqArr[i]['chargesheet_status'] == 'ACTIVE' && this.allEnqArr[i]['en_status'] == 'IN PROCESS') {
          this.allChargesheetFileEnquiry.push(this.allEnqArr[i]);
        }
      }
      this.allChargesheetFileEnquiryObj = Object.assign({}, this.allChargesheetFileEnquiry);
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting Enquiry ', 'error')
      // this.snackBar.open("Error while getting Enquiry ", 'Error', {
      //   duration: 5000
      // });
    }
  }



  async getTermination() {
    var resp = await this.estabService.getTermination(this.b_acct_id);
    if (resp['error'] == false) {
      //this.spinner.hide()
      this.allTermination = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['term_status'] == 'INITIATED' || resp.data[i]['term_status'] == 'UNDER APPROVED') {
          this.termArray.push(resp.data[i]['enq_id'])
        }
      }
      for (let i = 0; i < this.allTermination.length; i++) {
        for (let j = 0; j < this.terminateFile.length; j++) {
          if (this.allTermination[i]['id'] == this.terminateFile[j]['termination_id']) {
            this.allTermination[i]['document_name'] = this.terminateFile[j]['document_name']
            this.allTermination[i]['document_type_code'] = this.terminateFile[j]['document_type_code']
            this.allTermination[i]['upload_id'] = this.terminateFile[j]['upload_id']
          }
        }
      }
      this.datasource = new MatTableDataSource(this.allTermination)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting employee all Termination list", 'Error', {
        duration: 5000
      });
    }
  }



  // async getAllEmployees() {
  //   var obj:any  = new  Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   obj['party_status_code'] = ["JOINING", "JOINED", "LEFT"];
  //   var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.allEmployees = resp.data;
  //     for (let i=0; i<this.allEmployees.length ;i++){
  //       this.employeeObj[this.allEmployees[i]['emp_id']]=this.allEmployees[i]['emp_name'];
  //     }
  //   } else {
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }

  async submitTermination() {

    if (this.checkTrue == false) {



      for (let i = 0; i < this.termArray.length; i++) {
        if (this.termArray[i] == this.terminationObj['enq_id']) {
          Swal.fire("Error", 'This Enquiry ID you have selected already in Initiated or under approved Plz Refresh Page and again select other enquiry ID', 'error');
          return
        }
      }
      if (this.terminationObj['enq_id'] == null || this.terminationObj['enq_id'] == undefined || this.terminationObj['enq_id'] == '') {
        Swal.fire("Error", 'Enquiry ID can not be null', 'error')
        return
      }
    }
    // if(this.terminationObj['order_id']==null || this.terminationObj['order_id']==undefined || this.terminationObj['order_id']==''){
    //   Swal.fire("Error",'Order Id can not be null','error')
    //     return
    // }
    if (this.terminationObj['emp_name'] == null || this.terminationObj['emp_name'] == undefined || this.terminationObj['emp_name'] == '') {
      Swal.fire("Error", 'Order Id can not be null', 'error')
      return
    }
    if (this.terminationObj['termination_date'] == null || this.terminationObj['termination_date'] == undefined || this.terminationObj['termination_date'] == '') {
      Swal.fire("Error", 'Termination Date can not be null', 'error')
      return
    }
    if (this.terminationObj['termination_note'] == null || this.terminationObj['termination_note'] == undefined || this.terminationObj['termination_note'] == '') {
      Swal.fire("Error", 'Termination Note can not be null', 'error')
      return
    }
    if (this.terminationObj['termination_order_by'] == null || this.terminationObj['termination_order_by'] == undefined || this.terminationObj['termination_order_by'] == '') {
      Swal.fire("Error", 'Termination Order By can not be null', 'error')
      return
    }


    this.terminationObj['b_acct_id'] = this.b_acct_id;
    this.terminationObj['create_user_id'] = this.erpUser.user_id;
    this.terminationObj['emp_status_code'] = "INACTIVE";
    this.terminationObj['employee_current_type_code'] = 'TERMINATED'

    this.terminationObj['term_status'] = 'INITIATED';
    this.terminationObj['data'] = []
    if (this.checkTrue == false) {
      this.terminationObj['chargesheet_date'] = this.allChargesheetFileEnquiryObj['chargesheet_dt'];
      this.terminationObj['enq_result'] = this.allChargesheetFileEnquiryObj['en_status'];
      this.terminationObj['designation'] = this.empObj['designation_code'];
      this.terminationObj['emp_id'] = this.empObj['emp_id'];
    }
    else {
      console.log("this is else of checksubmir true value")
    }

    // console.log("this is teh obj --", this.terminationObj,this.newallEmplyees);
    //await this.getBillDetailForTerminaton()  this will not open
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.value) {
        this.finalsubmitTermination()
      }
    })

  }

  async finalsubmitTermination() {

    this.spinner.show();
    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      this.terminationObj['document_name'] = this.uploader.queue[0].some.name;
      this.terminationObj['document_type_code'] = 'TERMINATION DOC';
      const params = JSON.stringify(this.terminationObj);
      this.uploader.queue[0].url = this.httpUrl + '/hr/establishment_info/termination/terminateEmployee' + params;
      this.uploader.queue[0].upload();

      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        if (!response.error) {
          this.uploader.queue = [];
          // await this.getEnquiry();
          this.spinner.hide();
          this.terminationObj = {};
          $('.nav-tabs a[href="#tab-1"]').tab('show');
          await this.getTermination();
          swal.fire('Success', 'Termination Added Successfully', 'success')
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Adding Termination  Of Employee', 'error');
        }
      };
    }
    var resp = await this.estabService.terminateEmployee(this.terminationObj);
    if (resp['error'] == false) {
      this.terminationObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      await this.getTermination();
      Swal.fire('Success', 'Termination  Added Successfully! ', 'success')
      // this.snackBar.open("Termination  Added Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Adding Termination  Of Employee ', 'error')
      // this.snackBar.open("Error while Adding Termination  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }

  getDetail() {
    console.log("this is get detail ");
    for (let i of this.newallEmplyees) {
      // console.log("this is get detail --",i);
      if (i['emp_id'] == this.terminationObj['emp_name']) {
        this.terminationObj['enq_id'] = 0
        this.terminationObj['chargesheet_date'] = "0000-00-00";
        this.terminationObj['enq_result'] = "No Enquiry"
        this.terminationObj['enq_officer_name'] = "No Enquiry"
        this.terminationObj['emp_name'] = i['only_name']
        this.terminationObj['emp_id'] = i['emp_id']
        this.terminationObj['designation'] = i['designation_code'];
      }
    }
  }



  async checkEnquiry() {
    for (let i = 0; i < this.termArray.length; i++) {
      if (this.termArray[i] == this.terminationObj['enq_id']) {
        Swal.fire("Error", 'Already Initiated or Under Approved', 'error');
        return
      }
      else {
        await this.changeEmp();
      }
    }
    await this.changeEmp();
  }
  async changeEmp() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allChargesheetFileEnquiry.length; i++) {
      if (this.allChargesheetFileEnquiry[i]['id'] == this.terminationObj['enq_id']) {
        this.allChargesheetFileEnquiryObj = Object.assign({}, this.allChargesheetFileEnquiry[i])
      }
    }
    obj['complaint_id'] = this.allChargesheetFileEnquiryObj['complaint_id'];
    var resp = await this.estabService.getComp(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.compArr = [];
      this.compArr = resp.data[0];
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        if (this.compArr['emp_id'] == this.activeEmpArr[i]['emp_id']) {
          this.empObj = Object.assign({}, this.activeEmpArr[i]);
          break;
        }
      }
      this.terminationObj['emp_name'] = this.empObj['emp_name'];
      this.terminationObj['enq_officer_name'] = this.allChargesheetFileEnquiryObj['en_officer_name'];
      this.terminationObj['chargesheet_date'] = this.allChargesheetFileEnquiryObj['chargesheet_dt'];
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting Enquiry  ', 'error')
      //  this.snackBar.open("Error while getting Enquiry ", 'Error', {
      //    duration: 5000
      //  });
    }
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  onFileUpload(event:any , files:any ) {
    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }

  approve(element :any) {
    swal.fire({
      title: 'Are you sure want to approve?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.value) {
        this.finalapprove(element);
      }
    })
  }

  async finalapprove(element :any) {
    var approveObj:any  = new Object();
    approveObj['b_acct_id'] = this.b_acct_id;
    approveObj['term_id'] = element.id;
    approveObj['term_status'] = 'UNDER APPROVED';
    approveObj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.estabService.approve(approveObj);
    if (resp['error'] == false) {
      await this.getTermination();
      this.spinner.hide();
      Swal.fire('Success', ' Approved Successfully!', 'success')
      // this.snackBar.open("Approved Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', ' Error while Approving  Of Employee', 'error')
      // this.snackBar.open("Error while Approving  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  terminate(element :any) {
    swal.fire({
      title: 'Are you sure want to FinalTerminate ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Submit it!'
    }).then((result) => {
      if (result.value) {
        this.finalterminate(element);
      }
    })
  }
  async finalterminate(element :any) {
    var termObj :any = new Object();
    for (let i = 0; i < this.allEmployees.length; i++) {
      if (element.emp_id == this.allEmployees[i]['emp_id']) {
        termObj['arr_id'] = this.allEmployees[i]['arr_id'];
        break;
      }
    }
    for (let j = 0; j < this.allEnqArr.length; j++) {
      if (element.enq_id == this.allEnqArr[j]['id']) {
        termObj['complaint_id'] = this.allEnqArr[j]['complaint_id'];
        // break;
      }
    }
    termObj['emp_id'] = element.emp_id;
    termObj['enq_id'] = element.enq_id;
    termObj['termination_date'] = element.termination_date;
    termObj['update_user_id'] = this.erpUser.user_id;
    termObj['term_order_id'] = element.term_order_id;
    termObj['b_acct_id'] = this.b_acct_id;
    termObj['term_id'] = element.id;
    termObj['term_status'] = 'TERMINATED';
    termObj['complaint_status'] = 'CLOSED';
    termObj['en_status'] = 'CLOSED';
    termObj['en_result'] = 'COMPLETED';
    termObj['emp_current_type_code'] = 'TERMINATED';
    termObj['emp_status_code'] = 'INACTIVE';
    termObj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.estabService.finalterminate(termObj);
    if (resp['error'] == false) {
      await this.getTermination();
      this.spinner.hide();
      Swal.fire('Success', 'Final Terminated Successfully!', 'success')
      // this.snackBar.open("Final Terminated Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Termination Of Employee', 'error')
      // this.snackBar.open("Error while Termination Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }



  view_status() {

  }

  view_details(element :any) {
    this.viewObj = element;
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }

  async getTerminateFile() {
    let resp1 = await this.estabService.getTerminateFile(this.b_acct_id);
    if (resp1['error'] == false) {
      this.spinner.hide()
      this.terminateFile = [];
      this.terminateFile = resp1['data'];
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting Terminate File ', 'error')
      // this.snackBar.open("Error while getting Terminate File", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async view_order() {
    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = this.viewObj['upload_id']
    obj['filename'] = this.viewObj['document_name']
    this.filename = this.viewObj['document_name']
    this.spinner.show()
    const res = await this.estabService.getUploadedFileData_T(obj);
    if (res) {

      var docname = this.viewObj['document_name'];
      var ext = docname.split('.');

      if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.spinner.hide()
    }
  }

  async getBillDetailForTerminaton() {
    this.totalAmtToPay = 0
    this.spinner.show()
    var obj: any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['emp_id'] = this.terminationObj['emp_id']
    var resp = await this.estabService.getBillDetailForTermination(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.lastPaidArr = resp.data



      var tempDate = new Date(resp.data[0]['fin_year'] + "-" + resp.data[0]['month'] + "-01")
      var startDate = this.add_months(tempDate, 1)
      var endDate = new Date(this.terminationObj['termination_date'])
      await this.generateYearMnths(startDate, endDate)

      if (this.totalAmtToPay == 0) {

      } else {
        var endDateFinYear
        if ((endDate.getMonth() + 1) < 4) {
          endDateFinYear = endDate.getFullYear() - 1
        } else {
          endDateFinYear = endDate.getFullYear()
        }
        var obb:any  = new Object;

        obb['pay_component_amt'] = this.totalAmtToPay.toFixed(2)
        obb['pay_component_code'] = 'TERMINATION ARREAR'
        obb['fin_year'] = endDateFinYear
        obb['month'] = endDate.getMonth()
        obb['pay_status_code'] = 'ACTIVE'
        obb['emp_id'] = this.terminationObj['emp_id']
        obb['pay_code'] = 'PAY'
        this.terminationObj['data'].push(obb)



      }
      this.spinner.hide();

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Getting Salary Details Of Employee', 'error')
      // this.snackBar.open("Error while Getting Salary Details Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }

  add_months(dt:any , n:any ) {

    return new Date(dt.setMonth(dt.getMonth() + n));
  }
  async generateYearMnths(startDate:any , endDate:any ) {

    var arr: any = []
    this.toPay = []
    while (startDate <= endDate) {
      var obj: any = new Object()
      if ((startDate.getMonth() + 1) < 4) {
        obj['year'] = startDate.getFullYear() - 1
        obj['month'] = startDate.getMonth() + 1
      } else {
        obj['year'] = startDate.getFullYear()
        obj['month'] = startDate.getMonth() + 1
      }
      arr.push(obj)
      startDate = new Date(this.add_months(startDate, 1))
    }
    var endDateFinYear
    if ((endDate.getMonth() + 1) < 4) {
      endDateFinYear = endDate.getFullYear() - 1
    } else {
      endDateFinYear = endDate.getFullYear()
    }


    for (let i = 0; i < arr.length; i++) {

      for (let j = 0; j < this.lastPaidArr.length; j++) {
        let obj = Object.assign({}, this.lastPaidArr[j])
        obj['fin_year'] = arr[i]['year']
        obj['month'] = arr[i]['month']


        var month = endDate.getMonth() + 1


        if ((arr[i]['year'] == endDateFinYear) && (obj['month'] == month)) {
          var days = Number(this.getDaysInMonth(month, endDateFinYear))
          if (endDate.getDate() == days) {
            obj['pay_component_amt'] = obj['pay_component_amt']
          } else {
            var amt = obj['pay_component_amt'] / 31
            obj['pay_component_amt'] = amt * endDate.getDate()
          }

          this.toPay.push(obj)
          this.totalAmtToPay = this.totalAmtToPay + obj['pay_component_amt']
        } else {
          this.totalAmtToPay = this.totalAmtToPay + obj['pay_component_amt']
          this.toPay.push(obj)
        }
      }
    }


  }
  getDaysInMonth(month:any , year:any ) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  };

  refresh() {
    this.terminationObj = {}
  }


}
