import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import * as XLSX from "xlsx";

//************************************************* */
import { VendorService } from "../../md/service/vendor.service";
import Swal from "sweetalert2";
import { ExcelService } from "../../emb/service/file-export.service";

@Component({
  selector: 'app-vendor-fee',
  templateUrl: './vendor-fee.component.html',
  styleUrls: ['./vendor-fee.component.css']
})
export class VendorFeeComponent implements OnInit {


  constructor(private excel: ExcelService, private vendorService: VendorService, private spinner: NgxSpinnerService) { }
  Obj:any = { purpose: 'Vender Registration Fee' };
  l_ved_flag = true;
  c_ved_flag = false;
  u_ved_flag = false;
  up_ved_flag = false;
  ebillUser:any;
  b_acct_id:any;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  datasource:any

  purpose = [{ code: "Vender Registration Fee", value: "Vender Registration Fee" }];

  displayedColumns = ["id", "purpose", "vender_type", 'amount', 'effective_date', "action"];

  vender_type = [
    { code: "A", value: "A" },
    { code: "B", value: "B" },
    { code: "C", value: "C" },
    { code: "D", value: "D" }]


  async ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.Obj['purpose'] = 'Vender Registration Fee';
    await this.getAllFee();
  }


  async open_update(element:any) {
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = true;
    this.spinner.hide();
  }

  async delete(element:any) {
    this.spinner.show()
    let obj = { id: element['id'], b_acct_id: this.b_acct_id };
    var resp = await this.vendorService.deleteFee(JSON.stringify(obj));
    if (resp["error"] == false) {
      await this.getAllFee();
      this.spinner.hide();
      Swal.fire('success', 'Deleted Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }

  async update() {
    this.spinner.show()

    this.Obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.vendorService.updateFee(this.Obj);
    if (resp["error"] == false) {
      await this.getAllFee();
      await this.gotolist();
      this.spinner.hide();
      Swal.fire('success', 'Updated Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }

  async submit() {
    this.spinner.show()
    let ob:any = new Object();
    ob['b_acct_id'] = this.b_acct_id;
    ob['create_user_id'] = this.ebillUser['user_id'];

    let rows:any = [];
    if (this.Obj['vender_type'] == 'ALL') {

      this.vender_type.map(x => {
        if (x['code'] != 'ALL') {
          let ob = Object.assign({}, this.Obj);
          ob['vender_type'] = x['code'];
          rows.push(ob)
        }
      })

      ob['rows'] = rows;

    } else {
      ob['rows'] = [this.Obj];

    }
    console.log(ob)
    var resp = await this.vendorService.addFee(ob);
    if (resp["error"] == false) {
      await this.getAllFee();
      await this.gotolist();
      this.spinner.hide();
      Swal.fire('success', 'Added Successfully...', 'success')
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }

  }


  changePurpose() {
    let data:any = [];
    this.allFee.map(x => {
      if (x['purpose'] == this.Obj['purpose']) {
        data.push(x)
      }
    })
    this.datasource = new MatTableDataSource(data);
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  allFee = [];
  async getAllFee() {
    this.spinner.show();
    var resp = await this.vendorService.getFee(JSON.stringify({ b_acct_id: this.b_acct_id }));
    console.log(resp);
    if (resp["error"] == false) {
      this.allFee = resp.data;
      if (this.allFee.length == 0) {

        let dt = [{ code: "ALL", value: "ALL" }];
        this.vender_type.map(x => {
          dt.push(x);
        });
        console.log(dt)

        this.vender_type = dt;

      }
      await this.changePurpose();
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('error', 'Error Occered...', 'error')
    }
  }


  gotolist() {
    this.Obj = { purpose: 'Vender Registration Fee' }
    this.l_ved_flag = true;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
    this.getAllFee();
  }

  gotocrete() {
    this.Obj = { purpose: 'Vender Registration Fee' }
    this.l_ved_flag = false;
    this.c_ved_flag = true;
    this.u_ved_flag = false;
  }
  gotoUpload() {
    this.Obj = { purpose: 'Vender Registration Fee' }
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
  }

  refresh() {

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
