import { HttpClient, HttpEventType } from '@angular/common/http';

import { Injectable } from '@angular/core';
import {MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/accounts/hierarchy";
  }
  async  getHierarchy(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getHierarchy' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }

  async  createHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/createHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async deleteHierarchy(obj:any){
    const resp = this.http.delete<any>(this.httpUrl + '/deleteHierarchy' + JSON.stringify(obj)).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  updateHierarchy(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl+'/updateHierarchy',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }




  ////////////******Project start */
  async  createProjectHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/createHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async  getProjectHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteProjectHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/deleteHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updateProjectHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/updateHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updateHierarchyModule(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/updateHierarchyModule' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }


  ////////////******Project end*********/


  ////////////******Activitystart**********/
  async  createActivityHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/activity/createHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }
  async  moveActivityHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/activity/moveHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async  getActivityHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/activity/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteActivityHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/activity/deleteHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updateActivityHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/activity/updateHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  ////////////******Activity end*********/



  ////////////******Product start*********/
  async  createProductHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/product/createHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }
  async  moveProductHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/product/moveHierarchy' ,obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }
  async  getProductHierarchy(obj:any){
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/product/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteProductHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/product/deleteHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updateProductHierarchy(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/product/updateHierarchy' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  ////////////******Product end*********/


async  createBudgetHierarchy(obj:any){
  const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/createHierarchy' ,obj).toPromise().then(res => {
    console.log(res)
    return res
  });
  return resp
}

async  getBudgetHierarchy(obj:any){
  const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/getHierarchy',obj).toPromise().then(res => {
    return res
  });
  return resp
}
async  getBudgetHierarchyWithAllocation(obj:any){
  const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/getHierarchyWithAllocation',obj).toPromise().then(res => {
    return res
  });
  return resp
}
async deleteBudgetHierarchy(obj:any){
  const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/budget/deleteHierarchy' ,obj).toPromise().then(res => {
    return res
  });
  return resp
}

async  moveBudgetHierarchy(obj:any){
  const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/moveHierarchy' ,obj).toPromise().then(res => {
    console.log(res)
    return res
  });
  return resp
}

async  updateBudgetHierarchy(obj:any){
  const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/budget/updateHierarchy' ,obj).toPromise().then(res => {
    return res
  });
  return resp
}

async updateBudgetAmount(obj:any){
  const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/budget/updateHierarchyAmount' ,obj).toPromise().then(res => {
    return res
  });
  return resp
}
}
