<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">WorkFlow</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_flow_flag" class="nav-link active" href="#tab-1" data-toggle="tab"
                                (click)="refresh()"><i class="ti-bar-chart"></i>
                                View ALL WorkFlow</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_flow_flag" class="nav-link" href="#tab-2" data-toggle="tab"><i
                                    class="fa fa-plus-circle"></i> Create
                                WorkFlow</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_flow_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                Update WorkFlow</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_flow_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()"> Create WorkFlow</button>
                            </div>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ i+1 }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="doc_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Document
                                            Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element['doc_type']}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="module_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Module

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element['module_cd']}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-info"
                                                (click)="viewRule(element)">View</button>&nbsp;&nbsp;
                                            <button class="btn btn-primary"
                                                (click)="open_update(element)">Update</button>&nbsp;&nbsp;
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="c_flow_flag">




                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">ALL WorkFlow</button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 text-left">
                                    <h6>Select Document Type : </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="doc_type" bindLabel="value" bindValue="value" [multiple]="false"
                                        placeholder="" [(ngModel)]="FlowObj['doc_type']" (change)="changedoctype()"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <mat-step [stepControl]="first" label="Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;" class="text-center">Level</div>
                                        <div style="width: 5%;" class="text-center">Branch</div>
                                        <div style="width: 10%;" class="text-center">Branch Logical Condition</div>
                                        <div style="width: 10%;" class="text-center">Module</div>
                                        <div style="width: 15%;" class="text-center">Role</div>
                                        <div style="width: 10%;" class="text-center">Role Function</div>
                                        <div style="width: 10%;" class="text-center">Success Action</div>
                                        <div style="width: 10%;" class="text-center">Condition Exist</div>
                                        <div style="width: 10%;" class="text-center">Vendor Approval Required </div>
                                        <div style="width: 10%;" class="text-center">All User Approval Required </div>
                                        <div style="width: 5%;" class="text-center">Action</div>
                                    </div>




                                    <!-- <div *ngFor="let lvl of moduledata; let i = index">

                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control" [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control" [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Condition" [(ngModel)]="ob['branch_logical_condition']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="allModuleForEMB" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="ob['module_role']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select awesome.appendTo="body" [items]="allRolesForEMB" bindLabel="role_name" bindValue="role_cd" [multiple]="true" placeholder="Select Role" [(ngModel)]="ob['role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select awesome.appendTo="body" [items]="roleFunction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Role Function" [(ngModel)]="ob['role_function']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select awesome.appendTo="body" [items]="successAction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Success Action" [(ngModel)]="ob['success_action']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['condition_exists']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info" (click)="condtion(i,j,0,'M')">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['all_user_approval_req']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 5%;">
                                                <button class="btn btn-danger" (click)="deleteRowFromMudule(i,j)">DELETE</button>
                                            </div>

                                        </div>


                                    </div>
                                    <br> -->


                                    <!------------------------------------------------ New code Start ---------------------------------- -->
                                    <br>
                                    <div *ngFor="let module of moduledata; let i = index">
                                        <div *ngFor="let ob of module['branch_data']; let j = index" class="row">

                                            <!-- <div *ngFor="let ob of lvl['lev']; let k = index" class="row"> -->

                                            <div style="width: 4%;">
                                                <input type="number" placeholder="Enter Level" class="form-control"
                                                    [(ngModel)]="module['level']" disabled>
                                            </div>

                                            <div style="width: 4%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control"
                                                    [(ngModel)]="ob['branch']" disabled>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value"
                                                    bindValue="value" [multiple]="false" placeholder="Select Condition"
                                                    [(ngModel)]="ob['branch_logical_condition']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="allModuleForEMB" bindLabel="module_name"
                                                    bindValue="module_cd" [multiple]="false" placeholder="Select Module"
                                                    [(ngModel)]="ob['module_role']"
                                                    (change)="changeModule(ob['module_role'])" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select awesome.appendTo="body" [items]="allRolesForEMB"
                                                    bindLabel="role_name" bindValue="role_cd" [multiple]="true"
                                                    placeholder="Select Role" [(ngModel)]="ob['role_cd']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Role" [class.ng-dropdown-panel]>
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select awesome.appendTo="body" [items]="roleFunction"
                                                    bindLabel="value" bindValue="value" [multiple]="false"
                                                    placeholder="Select Role Function" [(ngModel)]="ob['role_function']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select awesome.appendTo="body" [items]="successAction"
                                                    bindLabel="value" bindValue="value" [multiple]="false"
                                                    placeholder="Select Success Action"
                                                    [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['condition_exists']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>
                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                    (click)="condtion(i,j,0,'M')">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                   disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['all_user_approval_req']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                     disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 0%;" class="text-center">
                                                <button class="btn btn-info" *ngIf="ob['branch']==1"
                                                    (click)="addBranchForModule(i+1)"><i class="fa fa-plus"
                                                        aria-hidden="true"></i> Branch</button>
                                                <button class="btn btn-danger"
                                                    (click)="deleteRowFromMudule(i,j)">DELETE</button>
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                        <br>
                                        <br>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="second" label="Cross-Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addCrossModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i>
                                                Cross-Module</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 15%;">Success Action</div>
                                        <div style="width: 5%;">Condition Exist</div>
                                        <div style="width: 5%;">Vendor Approval Required </div>
                                        <div style="width: 5%;">All User Approval Required </div>
                                        <div style="width: 15%;">Action</div>
                                    </div>


                                    <div *ngFor="let module of crossmoduledata1; let i = index">
                                        <br>

                                        <div class="row" *ngIf="module['module_show']==true">
                                            <div class="col-6 text-right">
                                                <h4>Level :</h4>
                                            </div>
                                            <div class="col-6 text-left">
                                                {{module['module']}}
                                                <button class="btn btn-info" (click)="addLevel(module['module'])">
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Level</button>&nbsp;
                                                <button class="btn btn-danger" (click)="deleteLevel(i)">
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Delete </button>
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngFor="let lvl of module['level_data']; let j = index">

                                            <div *ngFor="let ob of lvl['branch_data']; let k = index" class="row">

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Level" class="form-control"
                                                        [(ngModel)]="ob['level']">
                                                </div>

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Branch" class="form-control"
                                                        [(ngModel)]="ob['branch']">
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="logicalCondition" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Condition"
                                                        [(ngModel)]="ob['branch_logical_condition']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="Condition">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="allModule" bindLabel="module_name"
                                                        bindValue="module_cd" [multiple]="false"
                                                        placeholder="Select Module" [(ngModel)]="ob['module_role']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="Module">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="allRoles" bindLabel="role_name"
                                                        bindValue="role_cd" [multiple]="true" placeholder="Select Role"
                                                        [(ngModel)]="ob['role_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="roleFunction" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Role Function"
                                                        [(ngModel)]="ob['role_function']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="successAction" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Success Action"
                                                        [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['condition_exists']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['sent_to_vendor']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['all_user_approval_req']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 15%;">
                                                    <button class="btn btn-info" *ngIf="ob['branch']==1"
                                                        (click)="addBranch(module['module'],lvl['level'])"><i
                                                            class="fa fa-plus" aria-hidden="true"></i> Branch</button>
                                                    <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                        (click)="condtion(i,j,k,'CM')">Condtion</button>
                                                    <button class="btn btn-danger"
                                                        (click)="deleteRowFromCrossModule(i,j,k)">DELETE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Approval">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForApprModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 10%;">Success Action</div>
                                        <div style="width: 10%;">Condition Exist</div>
                                        <div style="width: 10%;">Vendor Approval Required </div>
                                        <div style="width: 10%;">All User Approval Required </div>
                                        <div style="width: 5%;">Action </div>
                                    </div>


                                    <div *ngFor="let lvl of Apprmoduledata; let i = index">
                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control"
                                                    [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control"
                                                    [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value"
                                                    bindValue="value" [multiple]="false" placeholder="Select Condition"
                                                    [(ngModel)]="ob['branch_logical_condition']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="Module" bindLabel="module_name"
                                                    bindValue="module_cd" [multiple]="false" placeholder="Select Module"
                                                    [(ngModel)]="ob['module_role']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="roles" bindLabel="role_name" bindValue="role_cd"
                                                    [multiple]="true" placeholder="Select Role"
                                                    [(ngModel)]="ob['role_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="roleFunction" bindLabel="value" bindValue="value"
                                                    [multiple]="false" placeholder="Select Role Function"
                                                    [(ngModel)]="ob['role_function']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="successAction" bindLabel="value" bindValue="value"
                                                    [multiple]="false" placeholder="Select Success Action"
                                                    [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['condition_exists']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                disabled>

                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                    (click)="condtion(i,j,0,'A')">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>

                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['all_user_approval_req']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 5%;">
                                                <button class="btn btn-danger"
                                                    (click)="deleteRowFromApprMudule(i,j)">DELETE</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button class="btn btn-primary" (click)="submit()">CREATE</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="u_flow_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">ALL WorkFlow</button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 text-left">
                                    <h6>Document Type : </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="doc_type" disabled bindLabel="value" bindValue="value"
                                        [multiple]="false" placeholder="" [(ngModel)]="FlowObj['doc_type']"
                                        (change)="changedoctype()" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>
                            <br>
                            <!-- <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <mat-step [stepControl]="first" label="Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;" class="text-center">Level</div>
                                        <div style="width: 5%;" class="text-center">Branch</div>
                                        <div style="width: 10%;" class="text-center">Branch Logical Condition</div>
                                        <div style="width: 10%;" class="text-center">Module</div>
                                        <div style="width: 15%;" class="text-center">Role</div>
                                        <div style="width: 10%;" class="text-center">Role Function</div>
                                        <div style="width: 15%;" class="text-center">Success Action</div>
                                        <div style="width: 10%;" class="text-center">Condition Exist</div>
                                        <div style="width: 10%;" class="text-center">Vendor Approval Required </div>
                                        <div style="width: 10%;" class="text-center">All User Approval Required </div>
                                    </div>




                                    <div *ngFor="let lvl of moduledata; let i = index">

                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control" [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control" [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Condition" [(ngModel)]="ob['branch_logical_condition']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="allModuleForEMB" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="ob['module_role']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="allRolesForEMB" bindLabel="role_name" bindValue="role_cd" [multiple]="false" placeholder="Select Role" [(ngModel)]="ob['role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="roleFunction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Role Function" [(ngModel)]="ob['role_function']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="successAction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Success Action" [(ngModel)]="ob['success_action']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['condition_exists']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info" (click)="condtion(i,j)">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['all_user_approval_req']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                            </div>

                                        </div>


                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary" matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="second" label="Cross-Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addCrossModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i>
                                                Cross-Module</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 15%;">Success Action</div>
                                        <div style="width: 5%;">Condition Exist</div>
                                        <div style="width: 5%;">Vendor Approval Required </div>
                                        <div style="width: 5%;">All User Approval Required </div>
                                        <div style="width: 15%;">Action</div>
                                    </div>


                                    <div *ngFor="let module of crossmoduledata1; let i = index">
                                        <br>

                                        <div class="row" *ngIf="module['module_show']==true">
                                            <div class="col-6 text-right">
                                                <h4>Level :</h4>
                                            </div>
                                            <div class="col-6 text-left">
                                                {{module['module']}}
                                                <button class="btn btn-info" (click)="addLevel(module['module'])">
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngFor="let lvl of module['level_data']; let j = index">

                                            <div *ngFor="let ob of lvl['branch_data']; let k = index" class="row">

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Level" class="form-control" [(ngModel)]="ob['level']">
                                                </div>

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Branch" class="form-control" [(ngModel)]="ob['branch']">
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="logicalCondition" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Condition" [(ngModel)]="ob['branch_logical_condition']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Condition">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="allModule" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="ob['module_role']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Module">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="allRoles" bindLabel="role_name" bindValue="role_cd" [multiple]="false" placeholder="Select Role" [(ngModel)]="ob['role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="roleFunction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Role Function" [(ngModel)]="ob['role_function']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="successAction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Success Action" [(ngModel)]="ob['success_action']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin" [(ngModel)]="ob['condition_exists']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin" [(ngModel)]="ob['all_user_approval_req']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 15%;">
                                                    <button class="btn btn-info" *ngIf="ob['branch']==1" (click)="addBranch(module['module'],lvl['level'])"><i
                                                            class="fa fa-plus" aria-hidden="true"></i> Branch</button>
                                                    <button *ngIf="ob['condition_exists']" class="btn btn-info" (click)="condtion(i,j)">Condtion</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                            <button mat-button mat-raised-button class="btn btn-primary" matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Approval">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForApprModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 15%;">Success Action</div>
                                        <div style="width: 10%;">Condition Exist</div>
                                        <div style="width: 10%;">Vendor Approval Required </div>
                                        <div style="width: 10%;">All User Approval Required </div>
                                    </div>


                                    <div *ngFor="let lvl of Apprmoduledata; let i = index">
                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control" [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control" [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Condition" [(ngModel)]="ob['branch_logical_condition']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="Module" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="ob['module_role']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="roles" bindLabel="role_name" bindValue="role_cd" [multiple]="false" placeholder="Select Role" [(ngModel)]="ob['role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="roleFunction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Role Function" [(ngModel)]="ob['role_function']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="successAction" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select Success Action" [(ngModel)]="ob['success_action']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['condition_exists']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>

                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info" (click)="condtion(i,j)">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>

                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['all_user_approval_req']" [(indeterminate)]="indeterminate" [labelPosition]="labelPosition" disabled>
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger" matStepperPrevious>Back</button>
                                            <button class="btn btn-primary" (click)="Update()">Update</button>
                                        </div>
                                    </div>
                                    <br>
                                </mat-step>
                            </mat-horizontal-stepper> -->

                            <mat-horizontal-stepper [linear]="isLinear" #stepper>
                                <mat-step [stepControl]="first" label="Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;" class="text-center">Level</div>
                                        <div style="width: 5%;" class="text-center">Branch</div>
                                        <div style="width: 10%;" class="text-center">Branch Logical Condition</div>
                                        <div style="width: 10%;" class="text-center">Module</div>
                                        <div style="width: 15%;" class="text-center">Role</div>
                                        <div style="width: 10%;" class="text-center">Role Function</div>
                                        <div style="width: 10%;" class="text-center">Success Action</div>
                                        <div style="width: 10%;" class="text-center">Condition Exist</div>
                                        <div style="width: 10%;" class="text-center">Vendor Approval Required </div>
                                        <div style="width: 10%;" class="text-center">All User Approval Required </div>
                                        <div style="width: 5%;" class="text-center">Action</div>
                                    </div>




                                    <div *ngFor="let lvl of moduledata; let i = index">

                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control"
                                                    [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control"
                                                    [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value"
                                                    bindValue="value" [multiple]="false" placeholder="Select Condition"
                                                    [(ngModel)]="ob['branch_logical_condition']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="allModuleForEMB" bindLabel="module_name"
                                                    bindValue="module_cd" [multiple]="false" placeholder="Select Module"
                                                    [(ngModel)]="ob['module_role']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select awesome.appendTo="body" [items]="allRolesForEMB"
                                                    bindLabel="role_name" bindValue="role_cd" [multiple]="true"
                                                    placeholder="Select Role" [(ngModel)]="ob['role_cd']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select awesome.appendTo="body" [items]="roleFunction"
                                                    bindLabel="value" bindValue="value" [multiple]="false"
                                                    placeholder="Select Role Function" [(ngModel)]="ob['role_function']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="successAction" bindLabel="value" bindValue="value"
                                                    [multiple]="false" placeholder="Select Success Action"
                                                    [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['condition_exists']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>
                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                    (click)="condtion(i,j,0,'M')">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                   disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['all_user_approval_req']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 5%;">
                                                <button class="btn btn-danger"
                                                    (click)="deleteRowFromMudule(i,j)">DELETE</button>
                                            </div>

                                        </div>


                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="second" label="Cross-Module">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addCrossModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i>
                                                Cross-Module</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 15%;">Success Action</div>
                                        <div style="width: 5%;">Condition Exist</div>
                                        <div style="width: 5%;">Vendor Approval Required </div>
                                        <div style="width: 5%;">All User Approval Required </div>
                                        <div style="width: 15%;">Action</div>
                                    </div>


                                    <div *ngFor="let module of crossmoduledata1; let i = index">
                                        <br>

                                        <div class="row" *ngIf="module['module_show']==true">
                                            <div class="col-6 text-right">
                                                <h4>Level :</h4>
                                            </div>
                                            <div class="col-6 text-left">
                                                {{module['module']}}
                                                <button class="btn btn-info" (click)="addLevel(module['module'])">
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Level</button>&nbsp;
                                                <button class="btn btn-danger" (click)="deleteLevel(i)">
                                                    <i class="fa fa-plus" aria-hidden="true"></i> Delete </button>
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngFor="let lvl of module['level_data']; let j = index">

                                            <div *ngFor="let ob of lvl['branch_data']; let k = index" class="row">

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Level" class="form-control"
                                                        [(ngModel)]="ob['level']">
                                                </div>

                                                <div style="width: 5%;">
                                                    <input type="number" placeholder="Enter Branch" class="form-control"
                                                        [(ngModel)]="ob['branch']">
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="logicalCondition" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Condition"
                                                        [(ngModel)]="ob['branch_logical_condition']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="Condition">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="allModule" bindLabel="module_name"
                                                        bindValue="module_cd" [multiple]="false"
                                                        placeholder="Select Module" [(ngModel)]="ob['module_role']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                        [closeOnSelect]="true" name="Module">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="allRoles" bindLabel="role_name"
                                                        bindValue="role_cd" [multiple]="true" placeholder="Select Role"
                                                        [(ngModel)]="ob['role_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 10%;">
                                                    <ng-select [items]="roleFunction" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Role Function"
                                                        [(ngModel)]="ob['role_function']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 15%;">
                                                    <ng-select [items]="successAction" bindLabel="value"
                                                        bindValue="value" [multiple]="false"
                                                        placeholder="Select Success Action"
                                                        [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                        name="Role">
                                                    </ng-select>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['condition_exists']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['sent_to_vendor']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 5%;" class="text-center">
                                                    <mat-checkbox class="example-margin"
                                                        [(ngModel)]="ob['all_user_approval_req']"
                                                        [(indeterminate)]="indeterminate"
                                                        [labelPosition]="labelPosition" disabled>
                                                    </mat-checkbox>
                                                </div>
                                                <div style="width: 15%;">
                                                    <button class="btn btn-info" *ngIf="ob['branch']==1"
                                                        (click)="addBranch(module['module'],lvl['level'])"><i
                                                            class="fa fa-plus" aria-hidden="true"></i> Branch</button>
                                                    <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                        (click)="condtion(i,j,k,'CM')">Condtion</button>
                                                    <button class="btn btn-danger"
                                                        (click)="deleteRowFromCrossModule(i,j,k)">DELETE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button mat-button mat-raised-button class="btn btn-primary"
                                                matStepperNext>Next</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Approval">
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-info" (click)="addRowLevelForApprModule()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Level</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row"
                                        style="background-color: #1589FF;color:white;padding-top: 8px;padding-bottom: 8px;">
                                        <div style="width: 5%;">Level</div>
                                        <div style="width: 5%;">Branch</div>
                                        <div style="width: 10%;">Branch Logical Condition</div>
                                        <div style="width: 10%;">Module</div>
                                        <div style="width: 15%;">Role</div>
                                        <div style="width: 10%;">Role Function</div>
                                        <div style="width: 10%;">Success Action</div>
                                        <div style="width: 10%;">Condition Exist</div>
                                        <div style="width: 10%;">Vendor Approval Required </div>
                                        <div style="width: 10%;">All User Approval Required </div>
                                        <div style="width: 5%;">Action </div>
                                    </div>


                                    <div *ngFor="let lvl of Apprmoduledata; let i = index">
                                        <br>

                                        <div *ngFor="let ob of lvl['branch_data']; let j = index" class="row">

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Level" class="form-control"
                                                    [(ngModel)]="ob['level']">
                                            </div>

                                            <div style="width: 5%;">
                                                <input type="number" placeholder="Enter Branch" class="form-control"
                                                    [(ngModel)]="ob['branch']">
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="logicalCondition" bindLabel="value"
                                                    bindValue="value" [multiple]="false" placeholder="Select Condition"
                                                    [(ngModel)]="ob['branch_logical_condition']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="Condition">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="Module" bindLabel="module_name"
                                                    bindValue="module_cd" [multiple]="false" placeholder="Select Module"
                                                    [(ngModel)]="ob['module_role']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="Module">
                                                </ng-select>
                                            </div>
                                            <div style="width: 15%;">
                                                <ng-select [items]="roles" bindLabel="role_name" bindValue="role_cd"
                                                    [multiple]="true" placeholder="Select Role"
                                                    [(ngModel)]="ob['role_cd']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="roleFunction" bindLabel="value" bindValue="value"
                                                    [multiple]="false" placeholder="Select Role Function"
                                                    [(ngModel)]="ob['role_function']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;">
                                                <ng-select [items]="successAction" bindLabel="value" bindValue="value"
                                                    [multiple]="false" placeholder="Select Success Action"
                                                    [(ngModel)]="ob['success_action']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="Role">
                                                </ng-select>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['condition_exists']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>

                                                </mat-checkbox>
                                                <button *ngIf="ob['condition_exists']" class="btn btn-info"
                                                    (click)="condtion(i,j,0,'A')">Condtion</button>

                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin" [(ngModel)]="ob['sent_to_vendor']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>

                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 10%;" class="text-center">
                                                <mat-checkbox class="example-margin"
                                                    [(ngModel)]="ob['all_user_approval_req']"
                                                    [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                                    disabled>
                                                </mat-checkbox>
                                            </div>
                                            <div style="width: 5%;">
                                                <button class="btn btn-danger"
                                                    (click)="deleteRowFromApprMudule(i,j)">DELETE</button>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button class="btn btn-primary" (click)="Update()">Update</button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner type="ball-atom">Loading....</ngx-spinner>



</div>
<div class="modal" id="viewuser">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">This level can not be deleted ( This role can not be
                    changed ) because currently many documents are pending with users in this role at this level. In
                    order to delete this level, you will either need to reassign the document to a user at different
                    level or ask the user to approve or reject the document to send it to next level
                </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="card">
                    <div style="margin: 2%;">
                        <table class="table table-borederd" style="width: 80%; margin-left: 10%;">
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Document Id</th>
                                    <th>Document Description</th>
                                    <th>Pending with User</th>
                                    <th>Pending with Role</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of workflowdata,let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{item['doc_local_no']}}</td>
                                    <td>{{item['doc_desc']}}</td>
                                    <td>{{userObj[item['user_id']]}}</td>
                                    <td>{{item['role_cd']}}</td>
                                    <!-- <td>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']=='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length==1 && item['role_cd']!='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                        <button class="btn btn-danger"
                                            *ngIf="single_users_role.length!=1 && item['role_cd']!='EMB_EMP'"
                                            (click)="delete(item)">Delete</button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="condtion">
    <div class="modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create Condition</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <br>
                <div class="row">
                    <div class="col-12 text-right">
                        <button class="btn btn-info" (click)="addConditionRow()">ADD</button>
                    </div>
                </div>
                <br>
                <table style="width: 100%;">
                    <tr>
                        <th class="r">S No.</th>
                        <th class="r">Select Field</th>
                        <th class="r">Select operator</th>
                        <th class="r">Enter Value</th>
                        <th class="r">Action</th>
                    </tr>
                    <tr *ngFor="let ob of updateData; let i = index">
                        <td class="r">
                            <h6>{{i+1}}</h6>

                        </td>
                        <td class="r">
                            <ng-select [items]="conditionField[FlowObj['doc_type']]" bindLabel="value" bindValue="code"
                                [multiple]="false" placeholder="Select Field" [(ngModel)]="ob['selected_field']"
                                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                name="Field">
                            </ng-select>
                        </td>
                        <td class="r">
                            <ng-select [items]="oprater" bindLabel="value" bindValue="value" [multiple]="false"
                                placeholder="Select oprater" [(ngModel)]="ob['selected_oprater']"
                                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                name="Field">
                            </ng-select>
                        </td>
                        <td class="r">
                            <input type="number" placeholder="Enter value" class="form-control"
                                [(ngModel)]="ob['value']">
                        </td>
                        <td class="r">
                            <button class="btn btn-danger" (click)="deleteConditionRow(i)">DELETE</button>
                        </td>
                    </tr>

                </table>

                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="saveCondition()">Save</button>
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="workflow">
    <div class="modal-xl">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">WorkFlow</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">

                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>Branch</th>
                                <th>Branch Logical Condition</th>
                                <th>Module</th>
                                <th>Role</th>
                                <th>Role Function</th>
                                <th>Success Action</th>
                                <th>Condition Exist</th>
                                <th>Condition</th>
                                <th>Vendor Approval Required </th>
                                <th>All User Approval Required </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of viewData">
                                <td>{{item['level']}}</td>
                                <td>{{item['branch']}}</td>
                                <td>{{item['branch_logical_condition']}}</td>
                                <td>{{item['module_cd']}}</td>
                                <td>{{item['role_cd']}}</td>
                                <td>{{item['role_function']}}</td>
                                <td>{{item['success_action']}}</td>
                                <td>{{birnaryObj[item['condition_exists']]}}</td>
                                <td>{{item['data_desc']}}</td>
                                <td>{{birnaryObj[item['sent_to_vendor']]}}</td>
                                <td>{{birnaryObj[item['all_user_approval_req']]}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>
