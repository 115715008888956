import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class  DashboardService {
  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/tracking";
  }

  async getcount(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/dashboard/getcount/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }


  async getalluserdetail(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/dashboard/getuserofzone/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }


  async getactiveuserdetail(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/dashboard/getActiveuserofzone/' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }


}


