<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves1']}}    <!-- Manage Employee Leaves -->
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves2']}}   <!-- Employee All Leave List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves3']}}   <!--

                                 Employee leave detail     -->
                                </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves4']}} <!-- Update Employee Leave Detail -->
                                    </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-pencil-alt"></i>
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves5']}} <!-- Carry Forward Leave Info -->
                                    </a>
                        </li>
                       <!--  <li class="nav-item">
                            <a class="nav-link" href="#tab-5" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i>Adjust Leave</a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves6']}} <!-- Select Employee : -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" (change)="changeEmployee()" [multiple]="false" placeholder="" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves7']}}">
                            </mat-form-field>

                            <div class=" example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="leave_code">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves8']}}<!-- Leave Type -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{  mainService.codeValueShowObj['HR0026'][element.leave_code]}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="num_of_leaves">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves9']}} <!-- Number of Leaves -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.num_of_leaves }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="leave_rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves10']}} <!-- Leave Rate -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.leave_rate}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="is_leave_carry_fwd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves11']}} <!-- Is Leave Carry Forward -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0028'][element.is_leave_carry_fwd]}}

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="renew_ind_on_year_change">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves12']}} <!-- Renew Indicator On Year Change -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0029'][element.renew_ind_on_year_change]}}

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="year">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves13']}} <!-- Effective Year -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.year}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="month">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves14']}} <!-- Effective Month -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.month}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="leaves_remaining">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves15']}} <!-- Leaves Remaining -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.leaves_remaining}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="adjust_remaining_leaves">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves16']}} <!-- Adjusted Leaves -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.adjust_remaining_leaves}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves17']}}    <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves18']}}    <!-- Update -->
                                            </button>
                                            <button class="btn btn-outline-danger" (click)="deleteleave(element)">
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves19']}}    <!-- Delete -->
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves6']}} :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectEmpObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Leave Type : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves8']}}:<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allLeaveRuleInfo" bindLabel="leave_value" bindValue="leave_code"  (change)="changeLeaveType()" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['leave_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <br>
                            <div class="row" *ngIf ="print_flag ">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves20']}}   <!-- Leave Collapse on Year Change: -->
                                </div>
                                <div class="col-4">


                                    {{mainService.codeValueShowObj['HR0029'][leaveObj['renew_ind_on_year_change']]}}


                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">

                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves10']}}    <!-- Leave Rate: -->
                                </div>
                                <div class="col-4">

                                    {{this.mainService.codeValueShowObj['HR0027'][leaveObj['leave_rate']]}}

                                </div>

                            </div>
                            <br>
                            <div class="row" *ngIf ="print_flag ">
                                <div class="col-4 text-right">
                                    <!-- Leave Carry Forward : -->{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves21']}}
                                </div>
                                <div class="col-4">


                                    {{mainService.codeValueShowObj['HR0028'][leaveObj['is_leave_carry_fwd']]}}


                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves22']}} :<span style="color: red;">*</span>   <!-- Effective Date : -->
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="leaveObj['eff_dt']">

                                </div>

                            </div>
                            <br>
                            <!-- <div class="row">


                                <div class="col-4 text-right">
                                    Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="month">
                                    </ng-select>
                                </div>

                            </div>
                            <br> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves9']}} :<span style="color: red;">*</span>  <!-- Number of Leaves : -->
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['num_of_leaves']">

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitLeave()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves23']}} <!-- Submit -->

                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">


                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves9']}} : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['num_of_leaves']">

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves15']}} :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['leaves_remaining']">

                                </div>

                            </div>
                            <br>
                           <!--  <div class="row">
                                <div class="col-4 text-right">
                                    Carry Forward Leaves :
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['carry_forward_leaves']">

                                </div>

                            </div> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves24']}} :<span style="color: red;">*</span><!-- Month : -->
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['month']">

                                </div>

                            </div>
                            <br>
                           <!--  <div class="row">
                                <div class="col-4 text-right">
                                    Adjust Remaining Leaves :
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['adjust_remaining_leaves']">

                                </div>

                            </div> -->

                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves25']}}:<span style="color: red;">*</span> <!-- Year : -->
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['year']">

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateLeave()">{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves18']}}</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves25']}} : <span style="color: red;">*</span>  <!-- Year : -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="year">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">


                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves24']}} : <span style="color: red;">*</span> <!-- Month : -->
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="month">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">

                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="carrForward()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves26']}}    <!-- CARRY FORWARD -->
                                    </button>
                                </div>

                            </div>
                        </div>
                      <!--   <div class="tab-pane fade" id="tab-5">
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="year">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    Select Leave Type :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0026']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['leave_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="year">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    Actual Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['adjustment_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="year">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    Actual Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['adjustment_month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="month">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Number of Leaves :
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" [(ngModel)]="leaveObj['adjust_leaves']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Current Year :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0030']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['current_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="year">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Current Month :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="leaveObj['current_month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="month">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="adjustLeave()">Adjust Leave</button>
                                </div>

                            </div>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'hr_leaves27']}}</ngx-spinner>
