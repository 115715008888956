import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import swal from 'sweetalert2';
import { ExcelService } from '../../service/file-export.service';
import { EstablishmentService } from '../../service/establishment.service';
import { Router } from '@angular/router';
import { DeductionService } from '../../service/deduction.service';
import { MainService } from '../../service/main.service';
import { SettingService } from '../../service/setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { AllEmpService } from '../../service/all-emp.service';
import { PayrollService } from '../../service/payroll.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-pension-contribution',
  templateUrl: './pension-contribution.component.html',
  styleUrls: ['./pension-contribution.component.css']
})
export class PensionContributionComponent implements OnInit {

  monthObj: any = { '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
  monthEnd: any = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }

  constructor(private establishmentService: EstablishmentService, private settingService: SettingService, private payableService: PayrollService, private snackBar: MatSnackBar, private excl: ExcelService, private spinner: NgxSpinnerService, public mainService: MainService, private router: Router) { }
  erpUser: any;
  b_acct_id: any;
  licObj: any = {}

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  data: any = []
  joining_type_code: any = [{ 'value': 'NONCENTRALISED' }, { 'value': 'CENTRALISED' }, { 'value': 'DEPUTATION' }, { 'value': 'ALL' },]
  displayedColumns: any = ['s_no', 'emp_id', 'emp_name', 'designation', 'basic', 'da', 'daper', 'pen'];
  datasource: any;

  obj: any = {};
  total: any = 0.00;
  result: any = [];
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;



  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getcontribution()
    await this.getAllCurrentArrangements()
  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }

  allArr: any = []
  async getAllCurrentArrangements() {
    this.spinner.show();

    var transferdArr1 = []
    var arr = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ['ACTIVE']

    var resp = await this.establishmentService.getAllCurrentArrangements(obj);
    if (resp['error'] == false) {
      this.allArr = resp.data;
      for (var i = 0; i < this.allArr.length; i++) {
        this.salaryObj.employement_info[this.allArr[i].emp_id] = this.allArr[i];

      }

      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', "Error while getting All  Employee", 'error')
      // this.snackBar.open("Error while getting All  Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  getLeap(year: any) {
    //var year = this.attendanceObj['year'];
    var leap = false;
    if (year % 4 == 0) {
      if (year % 100 == 0) {
        // year is divisible by 400, hence the year is a leap year
        if (year % 400 == 0)
          leap = true;
        else
          leap = false;
      }
      else
        leap = true;
    }
    else
      leap = false;
    if (leap == true) {
      this.monthEnd[2] = 29;
    }


  }
  async getFixedPay() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['month'] = this.salaryObj.month;
    this.getLeap(year);

    var year;
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      year = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      year = parseInt(this.salaryObj.fin_year);
    }
    obj['year'] = year;
    obj['end_dt'] = '2090-10-10';

    this.salaryObj.fixed_pay_info = {};
    var resp = await this.payableService.getEffectiveFixedSalary(obj);
    if (resp['error'] == false) {
      this.allFixedPay = resp.data;
      for (var i = 0; i < this.allFixedPay.length; i++) {
        if (this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id] == undefined) {
          this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id] = [];
        }
        this.salaryObj.fixed_pay_info[this.allFixedPay[i].emp_id].push(this.allFixedPay[i]);



      }
    } else {
      swal.fire("Error", "Error while getting Fixed Pay", 'error');
    }
  }
  async getSalary() {
    //variable_pay
    this.spinner.show();
    await this.getFixedPay()
    // await this.getVariablePay();
    //await this.getAllAttandence();
    // await this.getSalaryStatus();

    await this.calculateSalary();
    this.spinner.hide();


  }

  salaryObj: any = { accrual_date: '', b_acct_id: '', fin_year: '', month: '', section_code: '', emp_cat_code: '', post_info: {}, emp_info: {}, employement_info: {}, bank_info: {}, att_info: {}, fixed_pay_info: {}, variable_pay_info: {}, total_pay: {} }
  allEmplyees: any = [];
  allAttendance: any = [];
  //billIdObj={}
  ind_emp_id: any = [];
  updateSalaryObj: any = {};
  allBankAccount: any = [];
  allCurrentArrangements: any = [];
  allFixedPay: any = [];
  allEmplyees_new: any = []
  allVariablePay: any = [];
  allPosting: any = []
  salaryArr: any = [];
  allBillData: any = [];
  allRule: any = {};
  //allBillId=[];
  actualSalaryArr: any = [];
  currentBillObj: any = { header: {}, allEmployees: [], data: {}, sums: {} };
  allApproval: any = [];
  stopObj: any = {};
  t = [{ code: 'ind', value: 'Individual' }, { code: 'sec', value: 'Category Wise' }]
  statusArr = [];
  levelOfApproval = {}
  async calculateSalary() {
    console.log(this.salaryObj)
    this.salaryArr = []
    var year;
    if (this.salaryObj.month == '1' || this.salaryObj.month == '2' || this.salaryObj.month == '3') {
      year = parseInt(this.salaryObj.fin_year) + 1;
    } else {
      year = parseInt(this.salaryObj.fin_year);
    }
    var flag = false;
    var errorMsg = "";
    var activeEmps = Object.keys(this.salaryObj.employement_info);
    if (this.salaryObj['type'] == 'ind') {
      activeEmps = this.ind_emp_id;
      // this.salaryObj.section_code = this.salaryObj.post_info[activeEmps[0]]
      // this.salaryObj.emp_cat_code = this.salaryObj.emp_info[activeEmps[0]]['emp_cat_code']

    }

    var payObj :any = {};
    var accrual_date:any  = this.salaryObj.accrual_date;
    //*********************Fix and Variable Pay Calculation */

    for (var i = 0; i < activeEmps.length; i++) {
      if (this.salaryObj.post_info[activeEmps[i]] == undefined) {
        this.salaryObj.post_info[activeEmps[i]] = "ESP";
      }
      if ((this.salaryObj['type'] == 'ind')) {//this.salaryObj.emp_info[activeEmps[i]]['emp_cat_code'] == this.salaryObj.emp_cat_code ||  //&& (this.stopObj[activeEmps[i]] == undefined || this.stopObj[activeEmps[i]] =='START')
        var salArr: any = this.salaryObj.fixed_pay_info[activeEmps[i]];
        if (salArr == undefined) {
          salArr = [];
        }
        var salObj: any = {}
        if (salArr != undefined) {
          for (var j = 0; j < salArr.length; j++) {

            var effectiveStartDate = salArr[j].effective_start_dt;
            var effectiveEndDate = salArr[j].effective_end_dt
            var x = effectiveStartDate.split('-');
            var y = effectiveEndDate.split('-');
            var effectiveStartYear = parseInt(x[0]);
            var effectiveStartMonth = parseInt(x[1]);
            var effectiveStartDay = parseInt(x[2]);
            var effectiveEndYear = parseInt(y[0]);
            var effectiveEndMonth = parseInt(y[1]);
            var effectiveEndDay = parseInt(y[2]);
            //    console.log(effectiveStartYear);
            //   console.log(effectiveStartMonth);
            //     console.log(effectiveStartDay);
            //   console.log(effectiveEndYear);
            //   console.log(effectiveEndMonth);
            //   console.log(effectiveEndDay);
            if (activeEmps[i] == '20') {
              // console.log(effectiveStartYear);
              // console.log(effectiveStartMonth);
              // console.log(effectiveStartDay);
              // console.log(effectiveEndYear);
              // console.log(effectiveEndMonth);
              // console.log(effectiveEndDay);
            }
            var currYear = parseInt(this.salaryObj.fin_year);
            var currMonth = parseInt(this.salaryObj.month);
            if (currMonth == 1 || currMonth == 2 || currMonth == 3) {
              currYear += 1;
            }

            if (currYear > effectiveStartYear || (currYear == effectiveStartYear && currMonth >= effectiveStartMonth)) {
              var start = 1;
              var end = this.monthEnd[this.salaryObj.month];
              if (currYear == effectiveStartYear && currMonth == effectiveStartMonth) {
                start = effectiveStartDay;
              }
              if (currYear == effectiveEndYear && currMonth == effectiveEndMonth) {
                end = effectiveEndDay;
                //end=end-1;
              }
              // console.log(salArr[j]["pay_component_code"]+"-"+String( end-start+1) )
              if (end - start + 1 >= 0) {
                if (salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)] == undefined) {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)] = { type: 'fix', pay_component_code: salArr[j].pay_component_code, emp_id: activeEmps[i], pay_code: salArr[j].pay_code, pay_component_amt: 0, num_of_days: end - start + 1 };
                }
                if (salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_code'] == 'PAY') {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] = Math.round(salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] + parseFloat((salArr[j].pay_component_amt * (end - start + 1) / this.monthEnd[this.salaryObj.month]).toFixed(2)));

                } else {
                  salObj[salArr[j]["pay_component_code"] + "-" + String(end - start + 1)]['pay_component_amt'] = salArr[j].pay_component_amt;

                }
                //  console.log(salObj[salArr[j]["pay_component_code"]+"-"+String( end-start+1)]['pay_component_amt'] )
                //  console.log(salObj)
              }


            }
          }
        }
        var keys = Object.keys(salObj);
        for (var j = 0; j < keys.length; j++) {
          this.salaryArr.push(salObj[keys[j]]);
        }
        //  console.log(this.salaryArr)

      }


    }
    //********************Attendance Calculation , Suspended Calculation*/
    var emps = []
    for (var i = 0; i < this.salaryArr.length; i++) {
      var present_days;
      var absent_days;
      var total_days;
      if (emps.indexOf(this.salaryArr[i].emp_id) < 0) {
        emps.push(this.salaryArr[i].emp_id);
      }

      if (this.salaryArr[i].type == 'fix' && this.salaryArr[i].pay_code == 'PAY') {

        if (payObj[this.salaryArr[i].emp_id] == undefined) {
          payObj[this.salaryArr[i].emp_id] = 0;
        }
        payObj[this.salaryArr[i].emp_id] += this.salaryArr[i].pay_component_amt;

      }
      this.salaryArr[i]['b_acct_id'] = this.b_acct_id;
      this.salaryArr[i]['emp_id'] = this.salaryArr[i].emp_id;
      this.salaryArr[i]['fin_year'] = this.salaryObj.fin_year;
      this.salaryArr[i]['month'] = this.salaryObj.month;
      if (this.salaryObj['type'] == 'ind') {// && this.flag_sal == true
        this.salaryArr[i]['bill_desc'] = "Pay Bill for " +/* this.mainService.accInfo['account_short_name']  */ +this.getNumberFormat(this.salaryArr[i].emp_id) + "-"////+this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name/* " hold employees"+ *///" for Month "+this.monthObj[this.salaryObj.month]+", "+year;

      } else if (this.salaryObj['type'] == 'ind') {
        this.salaryArr[i]['bill_desc'] = "Pay Bill for " +/* this.mainService.accInfo['account_short_name']  */ +this.getNumberFormat(this.salaryArr[i].emp_id) + "-" + this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name +/* " hold employees"+ */" for Month " + this.monthObj[this.salaryObj.month] + ", " + year;

      }
      else {
        this.salaryArr[i]['bill_desc'] = "Pay Bill for " + this.mainService.codeValueShowObj['HR0046'][this.salaryObj.emp_cat_code] + " for Month " + this.monthObj[this.salaryObj.month] + ", " + year;

      }
      //  console.log(this.monthEnd[this.salaryObj.month])
      this.salaryArr[i]['pay_component_code'] = this.salaryArr[i].pay_component_code;
      this.salaryArr[i]['pay_code'] = this.salaryArr[i].pay_code;
      this.salaryArr[i]['section_code'] = this.salaryObj.post_info[this.salaryArr[i].emp_id];
      this.salaryArr[i]['bill_status_code'] = "GENERATED";
      this.salaryArr[i]['create_user_id'] = this.erpUser.user_id;
      // this.salaryArr[i]['emp_name'] = this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_name;
      // this.salaryArr[i]['emp_phone_no'] = this.salaryObj.emp_info[this.salaryArr[i].emp_id].emp_phone_no;
      this.salaryArr[i]['accrual_date'] = accrual_date;
      this.salaryArr[i]['bill_date'] = accrual_date;
      //this.salaryArr[i]['num_of_days'] = this.monthEnd[this.salaryObj.month] - absent_days;
      if (this.salaryArr[i]['pay_component_code'] == 'NPS') {
        this.salaryArr[i]['pay_component_amt'] = Math.round(this.salaryArr[i]['pay_component_amt'])
      }




    }


    //***************************Deduction Normalisation */
    this.actualSalaryArr = [];
    var salObjNew :any = {}
    for (var i = 0; i < this.salaryArr.length; i++) {
      if (salObjNew[this.salaryArr[i].emp_id] == undefined) {
        salObjNew[this.salaryArr[i].emp_id] = {};
      }
      salObjNew[this.salaryArr[i].emp_id][this.salaryArr[i]["pay_component_code"] + "-" + i] = this.salaryArr[i];
    }
    var seq = ['BASIC', 'DA', 'HRA', 'CCA', 'MA', 'PB', 'CONV', 'PERSNLPAY', 'GIS'];
    for (var i = 0; i < emps.length; i++) {
      var obj = salObjNew[emps[i]];
      var keys = Object.keys(obj);

      for (var j = 0; j < seq.length; j++) {
        if (obj[seq[j]] != undefined) {
          if (obj[seq[j]].pay_code == 'PAY') {
            this.actualSalaryArr.push(obj[seq[j]]);

          } else {
            if (obj[seq[j]].pay_component_amt <= payObj[emps[i]]) {
              this.actualSalaryArr.push(obj[seq[j]]);
              payObj[emps[i]] -= obj[seq[j]].pay_component_amt;
            } else {
              obj[seq[j]].pay_component_amt = payObj[emps[i]]
              payObj[emps[i]] = 0;
              this.actualSalaryArr.push(obj[seq[j]]);

            }
          }
        }
      }
      for (var j = 0; j < keys.length; j++) {
        if (seq.indexOf(keys[j]) < 0) {
          if (obj[keys[j]].pay_code == 'PAY' || obj[keys[j]].pay_code == 'CONT') {
            this.actualSalaryArr.push(obj[keys[j]]);
          } else {
            if (obj[keys[j]].pay_component_amt <= payObj[emps[i]]) {
              this.actualSalaryArr.push(obj[keys[j]]);
              payObj[emps[i]] -= obj[keys[j]].pay_component_amt;
            } else {
              obj[keys[j]].pay_component_amt = payObj[emps[i]]
              payObj[emps[i]] = 0;
              this.actualSalaryArr.push(obj[keys[j]]);

            }
          }
        }
      }
    }

    console.log(this.actualSalaryArr)
    //if(flag == false && this.flag_sal == false){
    /*  this.datasource = new MatTableDataSource(this.actualSalaryArr);
     this.datasource.paginator = this.paginator;
     this.datasource.sort = this.sort; */

    /*    }else if(flag == false && this.flag_sal == true ){
        console.log(this.salaryArr)
        for (let i = 0; i < this.actualSalaryArr.length; i++) {
          this.hold_sal.push(this.actualSalaryArr[i])

        }
         this.flag_sal = false
       }
       else{
         this.salaryArr = [];
         this.actualSalaryArr = [];
         swal.fire("Error",errorMsg,'error');
         this.datasource = new MatTableDataSource(this.actualSalaryArr);
         this.datasource.paginator = this.paginator;
         this.datasource.sort = this.sort;
       } */

  }
  pencontribution = []
  async getcontribution() {
    this.spinner.show()
    var resp = await this.settingService.getcontribution(this.b_acct_id);
    if (resp['error'] == false) {
      this.pencontribution = resp.data;


      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire("Oops", "...Error while getting all values!", 'error');

    }
  }
  billIdObj:any  = {}
  allBillId:any  = []
  async getAllBillID() {

    if (this.licObj['joining_type_code'] == '' || this.licObj['joining_type_code'] == null || this.licObj['joining_type_code'] == undefined ||
      this.licObj['fin_year'] == '' || this.licObj['fin_year'] == null || this.licObj['fin_year'] == undefined ||
      this.licObj['month'] == '' || this.licObj['month'] == null || this.licObj['month'] == undefined) {
      swal.fire('Warning', '* Field Required', 'warning');
      return;
    }


    this.billIdObj['b_acct_id'] = this.b_acct_id;
    this.billIdObj['fin_year'] = this.licObj['fin_year'];
    this.billIdObj['month'] = this.licObj['month'];
    this.salaryObj['fin_year'] = this.licObj['fin_year']
    this.salaryObj['month'] = this.licObj['month']
    this.salaryObj['type'] = "ind"
    console.log(this.allArr)

    for (let i = 0; i < this.allArr.length; i++) {
      //  console.log(this.allArr[i])
      this.ind_emp_id.push(this.allArr[i]['emp_id'])
    }
    await this.getSalary();
    for (let i = 0; i < this.actualSalaryArr.length; i++) {
      var obj = Object()
      obj = Object.assign({}, this.actualSalaryArr[i])
      if (obj['pay_code'] == 'PAY' && (obj['pay_component_code'] == 'BASIC' || obj['pay_component_code'] == 'DA')) {
        this.allBillId.push(obj)
      }

    }
    await this.pensioncontribution();
    //this.spinner.show();
    /*  var resp = await this.payableService.getMonthlyBill(JSON.stringify(this.billIdObj));
     if (resp['error'] == false) {

       for (let i = 0; i < resp.data.length; i++) {
         var obj = Object()
         obj = resp.data[i]
         if (obj['pay_code'] == 'PAY' && (obj['pay_component_code'] == 'BASIC' || obj['pay_component_code'] == 'DA')) {
           this.allBillId.push(obj)
         }

       }
       await this.pensioncontribution();
       this.spinner.hide()
     } else {
       this.spinner.hide()
       this.snackBar.open("Error in getting All Bill", 'Error', {
         duration: 5000
       });
     } */
  }

  contribution:any  = []
  async pensioncontribution() {
    this.contribution = []

    for (let i = 0; i < this.allArr.length; i++) {
      var Obj = Object()
      Obj = this.allArr[i];
      if (this.licObj['joining_type_code'] == 'ALL' || Obj['joining_type_code'] == this.licObj['joining_type_code']) {
        this.contribution[Obj['emp_id']] = []
        for (let j = 0; j < this.allBillId.length; j++) {
          if (Obj['emp_id'] == this.allBillId[j]['emp_id']) {

            this.allBillId[j]['emp_name'] = Obj['emp_name']
            this.allBillId[j]['designation_code'] = Obj['designation_code']
            this.contribution[Obj['emp_id']].push(this.allBillId[j])
          }


        }
      }

    }
    var emparr = []
    for (let i = 0; i < this.allArr.length; i++) {
      if (this.licObj['joining_type_code'] == 'ALL' || this.allArr[i]['joining_type_code'] == this.licObj['joining_type_code']) {
        if (this.contribution[this.allArr[i]['emp_id']].length > 1) {
          var obj = Object()
          console.log(this.contribution[this.allArr[i]['emp_id']])
          obj['emp_id'] = this.contribution[this.allArr[i]['emp_id']][0]['emp_id']
          obj['emp_name'] = this.contribution[this.allArr[i]['emp_id']][0]['emp_name']
          obj['designation_code'] = this.contribution[this.allArr[i]['emp_id']][0]['designation_code']
          for (let j = 0; j < this.contribution[this.allArr[i]['emp_id']].length; j++) {
            if (this.contribution[this.allArr[i]['emp_id']][j]['pay_component_code'] == 'BASIC') {
              obj['basic'] = this.contribution[this.allArr[i]['emp_id']][j]['pay_component_amt']
            }
            else if (this.contribution[this.allArr[i]['emp_id']][j]['pay_component_code'] == 'DA') {
              obj['da'] = this.contribution[this.allArr[i]['emp_id']][j]['pay_component_amt']
            }
          }
          console.log(emparr)

          emparr.push(obj)
        }
      }


    }
    var Total = 0
    var arr = []
    for (let i = 0; i < emparr.length; i++) {
      for (let j = 0; j < this.pencontribution.length; j++) {
        if (this.pencontribution[j]['emp_id'] == emparr[i]['emp_id']) {
          var empobj = Object()
          empobj = emparr[i]
          if (empobj['da'] == undefined || empobj['da'] == null) {
            empobj['da'] = 0
          }
          var da = Number(((empobj['da'] * 100) / empobj['basic']).toFixed(2))
          var pen = Number((((empobj['da'] + empobj['basic']) * 12) / 100).toFixed(2))
          emparr[i]['daper'] = da
          emparr[i]['pen'] = pen
          Total = Total + pen
          console.log(emparr[i])
          arr.push(emparr[i])
        }
      }
    }
    this.total = Number(Total.toFixed(2));
    this.data = arr
    this.datasource = new MatTableDataSource(arr)
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort
  }
  export() {
    if (this.data.length == 0) {
      swal.fire("Info", 'No Data For Export', 'info');
      return;
    }
    var exp = []
    for (var i = 0; i < this.data.length; i++) {
      var obj: any = new Object();
      obj['SNO'] = i + 1;
      obj['EMPLOYEE ID'] = /* this.mainService.accInfo['account_short_name'] + "" + */ this.getNumberFormat(this.data[i]['emp_id']);
      obj['EMPLOYEE NAME'] = this.data[i]['emp_name'];
      obj['DESIGNATION'] = this.data[i]['designation_code'];
      obj['BASIC'] = this.data[i]['basic'];
      obj['DA'] = this.data[i]['da'];
      obj['DA%'] = this.data[i]['daper'];
      obj['PENSION CONTRIBUTION'] = this.data[i]['pen'];

      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'pension_contribution_report')
  }
  print() {
    if (this.data.length == 0) {
      swal.fire("Info", 'No Data For Print', 'info');
      return;
    }
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + " PENSION CONTRIBUTION LIST";
    var dd:any  = {
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    var tbl = {

      layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*'],

        body: [
          ['SNO', 'Employee ID', 'Employee Name', 'Designation', 'BASIC', 'DA', 'DA%', 'Pension Contribution']


        ]
      }
    };
    dd.content.push(tbl);
    for (var i = 0; i < this.data.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(/* this.mainService.accInfo['account_short_name'] + "" + */ this.getNumberFormat(this.data[i]['emp_id']));
      arr.push(this.data[i]['emp_name']);
      arr.push(this.data[i]['designation_code']);
      arr.push(this.data[i]['basic']);
      arr.push(this.data[i]['da']);
      arr.push(this.data[i]['daper'])
      arr.push(this.data[i]['pen'])




      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var tbl1 = [
      '', '', '', '', '', 'Total :', this.total.toFixed(2)
    ]
    var totalObjRow = {
      columns: [

        {
          width: '*',
          text: '',
        },
        {
          width: '*',
          text: '',
          bold: true

        },
        {
          width: '*',
          text: '',
        },
        {
          width: '*',
          text: '',
          bold: true

        },
        {
          width: '*',
          text: 'Total : Rs.',
          bold: true
        },
        {
          width: '*',
          text: this.total.toFixed(2)
        },


      ],

    }
    dd.content.push(totalObjRow);




    pdfMake.createPdf(dd).download("pension-list");
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
