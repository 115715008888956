<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Public Complaints</div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" *ngIf="complaint" data-toggle="tab"><i
                  class="fa fa-line-chart"></i>&nbsp;Complaints</a>
            </li>


          </ul>
          <div class="tab-content">
            <div *ngIf="complaint">

              <br>
              <div class="row">
                <div class="col-4">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>
                </div>

              </div>


              <div class="example-container">

                <table mat-table [dataSource]="datasource" matSort class="text-center">
                  <ng-container matColumnDef="s.no">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <h6>
                        <b>S.No</b>
                      </h6>
                    </th>
                    <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                  </ng-container>




                  <ng-container matColumnDef="Complaint_ID">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Complaint ID
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.complain_app_id}} </td>
                  </ng-container>

                  <ng-container matColumnDef="zone_cd">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Zone
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.zone_name}} </td>
                  </ng-container>


                  <ng-container matColumnDef="Complaint_type">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Complaint Type
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.complian_type}} </td>
                  </ng-container>



                  <ng-container matColumnDef="Complaint_Date">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Complaint
                      Date
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.complain_date}} </td>
                  </ng-container>


                  <ng-container matColumnDef="Complaint_Status">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Complaint Status
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{element.status}} </td>
                  </ng-container>




                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      <button (click)="viewDetails(element)" class="btn btn-outline-primary">View
                        Details</button>&nbsp;&nbsp;
                      <button (click)="forward(element)" class="btn btn-outline-success"
                        *ngIf="element['status']=='GENERATED'">Forward</button>&nbsp;&nbsp;


                      <!-- <button (click)="inspection(element)" class="btn btn-outline-danger">Inspection</button> -->
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
              </div>

            </div>




            <!-- <div class="tab-pane fade" id="tab-7-3">
                            <br>

                        </div> -->
          </div>
          <br>
        </div>
      </div>

    </div>
  </div>

</div>

<div class="modal fade" id="myModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #AED6F1;">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>DETAILS</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div style="border: 2px solid gray; border-radius:10px;">
          <div class="row justify-content-center mt-2">
            <h6>Complaint Details</h6>
          </div>
          <div class="row mt-3">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Complaint ID</h6>
            </div>:
            <div class="col-2">{{complaint_detail.complain_app_id}}</div>
            <div class="col-1"></div>
            <div class="col">
              <h6>Complaint Date</h6>
            </div>:
            <div class="col">{{complaint_detail.complain_date}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Zone</h6>
            </div>:
            <div class="col-2">{{complaint_detail.zone_name}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Complaint Type</h6>
            </div>:
            <div class="col-2">{{complaint_detail.complian_type}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Latitude</h6>
            </div>:
            <div class="col-2">{{complaint_detail.latitude}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Longitude</h6>
            </div>:
            <div class="col-2">{{complaint_detail.longitude}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Property Address</h6>
            </div>:
            <div class="col-2">{{complaint_detail.property_address}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Photo of Site</h6>
            </div>:

            <div class="col-2">
              <button (click)="view_photo()" class="btn btn-sm btn-outline-success">View</button>&nbsp;&nbsp;
            </div>
            <div class="col-1"></div>
          </div>

          <div class="row mt-2" *ngIf="complaint_detail['status']=='UNDER-INSPECTION'">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Assigned Officer</h6>
            </div>:
            <div class="col-2">{{complaint_detail.party_name}}</div>
            <div class="col-1"></div>
            <div class="col-2"></div>
            <div class="col-2"></div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2" *ngIf="complaint_detail['status']=='UNDER-INSPECTION'">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Officer's ssDesignation</h6>
            </div>:
            <div class="col">
              (<span *ngFor="let item of userdetail; let i =index">{{item.role_name}}
                <span *ngIf="item.role_name && (i!=userdetail.length-1) ">,</span></span>)

            </div>
            <!-- <div class="col-6"></div> -->
          </div>
          <div class="row mt-2" *ngIf="complaint_detail['other_type']">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Complaint type</h6>
            </div>:
            <div class="col-4">{{complaint_detail.other_type}}</div>


          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Complaint Description</h6>
            </div>:
            <div class="col-4">{{complaint_detail.detail}}</div>

            <br>
            <!--  <div class="row justify-content-center">

            <div class="col-4"></div> -->
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Complaint Status</h6>
            </div>:
            <div class="col-4">
              <h6>{{complaint_detail.status}}</h6>
            </div>
            <div class="col-4"></div>
          </div>

          <br>
        </div>

        <!-- inspection report after final inspection -->
        <div class="mt-2" *ngIf="complaint_detail['status']=='INSPECTION REPORT SUBMITED' || this.complaint_detail['status'] == 'CASE_FILED' || this.complaint_detail['status'] == 'CASE_CLOSED' "
          style="border: 2px solid gray; border-radius:10px;">
          <div class="row justify-content-center mt-1">
            <h6>Inspection Details</h6>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Inspection ID</h6>
            </div>:
            <div class="col-2">{{inspection_detail.inspection_id}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Inspection Date</h6>
            </div>:
            <div class="col-2">{{inspection_detail.inspection_date}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Owner Name</h6>
            </div>:
            <div class="col-2">{{inspection_detail.owner_name}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Police Station</h6>
            </div>:
            <div class="col-2">{{complaint_detail.police_station}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Inspection officer</h6>
            </div>:
            <div class="col-2">{{inspection_detail.inspection_officer}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Inspection Photo</h6>
            </div>:
            <div class="col-2">
              <button class="btn btn-sm btn-outline-success" (click)="view_inspection_photo()">View</button>&nbsp;&nbsp;
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Officer's Designation</h6>
            </div>:
            <div class="col-2">
              (<span *ngFor="let item of userdetail; let i =index">{{item.role_name}}
                <span *ngIf="item.role_name && i!=userdetail.length-1 ">,</span></span>)</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>View Memo</h6>
            </div>:
            <div class="col-2">
              <button class="btn btn-sm btn-outline-success" (click)="view_memo_img()">View</button>&nbsp;&nbsp;
            </div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Person on site</h6>
            </div>:
            <div class="col-2">{{inspection_detail.person_on_site}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Person 's mobile no </h6>
            </div>:
            <div class="col-2">{{inspection_detail.person_on_site_number}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Is__Notice_Required</h6>
            </div>:
            <div class="col-2">{{inspection_detail.is__notice_required}}</div>
            <div class="col-1"></div>
            <div class="col-2">
              <h6>Notice Under Act</h6>
            </div>:
            <div class="col-2">{{inspection_detail.notice_under_act}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2">
              <h6>Section</h6>
            </div>:
            <div class="col">
              <span *ngFor="let item of section_detail; let i =index">{{item.section_cd}}
                <span *ngIf="i!=section_detail.length-1 ">,</span></span>
            </div>

          </div>
          <!-- <div class="row mt-2">
            <div class="col-2"></div>
           <div class="col-3"><h6>Inspection Description notes</h6></div>:
           <div class="col-6">{{inspection_detail.insp_offi_note}}</div>
           <div class="col-1"></div>
           </div> -->
          <div class="row mt-2" *ngFor="let item of description ; let i = index">
            <div class="col-2"></div>
            <div class="col-3">
              <h6>Inspection Description{{i+1}}</h6>
            </div>:
            <div class="col-6">{{item['description']}}</div>
            <div class="col-1"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-3">
              <h6>Inspection status</h6>
            </div>:
            <div class="col-6"><b>{{inspection_detail.life_cycle_status}}</b></div>
            <div class="col-1"></div>
          </div>
        </div>
        <!-- end -->

        <!-- feedback detail -->
<ng-container *ngIf="feedbackdetail.length > 0" >
  <div class="mt-2"
    style="border: 2px solid gray; border-radius:10px;">
    <div class="row justify-content-center mt-1">
      <h6>Feedback Details</h6>

    </div>
    <div class="row" *ngFor="let item of feedbackdetail ; let i = index">
      <div class="col-2"></div>
      <div class="col-3">
        <h6>Feedback Description{{i+1}}&nbsp;&nbsp;&nbsp;{{item.feedback_date | date}}</h6>
      </div>:
      <div class="col-5">{{item.description}}</div>
      <div class="col-1">
        <button class="btn btn-sm btn-outline-success" (click)="view_feedback()">View images</button>
      </div>
      <div class="col-1"></div>
    </div>




  </div>
</ng-container>
        <!-- end -->







        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Photo of site</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


        <div class="row">
          <div class="col-12 text-center">
            <span *ngFor="let item of complaint_image ;let i =index">
              <button class="btn btn-outline-success" (click)="view(i)">view Image{{i+1}}</button>
            </span>
          </div>

        </div>
        <hr>


        <div class="row justify-content-center" *ngIf="imageflag">

          <img [src]="imgURL1" height="550px" object-fit="contain" />


        </div>





      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="inspection_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Inspection Photo</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center">
            <span *ngFor="let item of inspection_image ;let i =index">
              <button class="btn btn-outline-success" (click)="view_inspection(i)">view Image{{i+1}}</button>
            </span>
          </div>

        </div>
        <hr>



        <div class="row mt-2 justify-content-center" *ngIf="inspectionimageflag">

          <img [src]="imgURL2" height="550px" width="auto" object-fit="contain" />


        </div>





      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="memo_photo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Memo Photo</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center" *ngIf="memoimageflag">

          <img [src]="imgURL3" height="550px" width="auto" object-fit="contain" />


        </div>





      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="feedback_img" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Feedback Photo</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-12 text-center">
            <span *ngFor="let item of feedbackimg ;let i =index">
              <button class="btn btn-outline-success" (click)="view_feedback_photo(i)">view Image{{i+1}}</button>
            </span>
          </div>

        </div>
        <div class="row mt-2 justify-content-center" *ngIf="feedbackimage">

          <img [src]="imgURL4" height="550px" width="auto" object-fit="contain" />


        </div>



      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:80%; ">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #AED6F1;">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Forward Complaint</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <br>
        <div style="border: 2px solid gray; border-radius:10px;">
          <br>
          <br>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2"><b>Complaint ID:</b></div>
            <div class="col-1"></div>
            <div class="col-4">
              <input type="text" class="form-control" placeholder="{{complaint_detail.complain_app_id}}" disabled>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2"><b>Select User:</b></div>
            <div class="col-1"></div>
            <div class="col-4" *ngIf="Inzone">
              <ng-select [items]="userbyzone" [multiple]="false"
                placeholder="Select User of {{complaint_detail.zone_name}}" bindLabel="desc" bindValue="user_id"
                [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                [(ngModel)]="assign_Obj['user_id']">
              </ng-select>

            </div>
            <div class="col-4" *ngIf="OuterZone">
              <ng-select [items]="userbynotzone" [multiple]="false" placeholder="Select User from other zone"
                bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                [closeOnSelect]="true" name="hierarchy" [(ngModel)]="assign_Obj['user_id']">
              </ng-select>
            </div>
            <div class="col-3">
              <input class=" pt-1" type="checkbox" (click)='sameAddress()' maxlength="50" value=""> &nbsp;select user
              from other zone
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-2"></div>
            <div class="col-2"><b>Note:</b></div>
            <div class="col-1"></div>
            <div class="col-4">
              <textarea class="form-control" rows="4" cols="50" type="text" name="note" maxlength="150"
                [(ngModel)]="assign_Obj['note']" placeholder="note"></textarea>

              <div class="col-3"></div>
            </div>
          </div>
          <br>
          <br>
          <div class="row justify-content-center mt-2">
            <button class="btn btn-outline-danger" data-dismiss="modal">Close</button>&nbsp;&nbsp;


            <button class="btn btn-outline-success" (click)="forward2()">Forward</button>



          </div>
          <br>
          <br>
        </div>

      </div>
    </div>
  </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>
