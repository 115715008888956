import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emd-footer',
  templateUrl: './emd-footer.component.html',
  styleUrls: ['./emd-footer.component.css']
})
export class EmdFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
