import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emd-administration',
  templateUrl: './emd-administration.component.html',
  styleUrls: ['./emd-administration.component.css']
})
export class EmdAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
