import { Injectable } from '@angular/core';
import { MainService } from '../service/main.service';
import { BillService } from '../service/bill.service';
import { SettingService } from './setting.service';
import { MasterDataService } from "../../emb/service/master-data.service";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import Swal from 'sweetalert2';
declare let $: any
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PrintCbService {

  b_acct_id:any;
  httpUrl:any;
  objToSend:any = {}

  constructor(private http: HttpClient, private mainService: MainService, private billService: BillService,
    private masterDataService: MasterDataService, private settingService: SettingService, private spinner: NgxSpinnerService) {
    this.httpUrl = this.mainService.httpUrl
  }



  async printCB(element:any, erpUser:any) {
    console.log(element)
    this.objToSend['b_acct_id'] = erpUser.b_acct_id;
    this.objToSend['cb_id'] = element['bill_id'];
    this.objToSend['work_id'] = element['node_cd'];
    this.b_acct_id = erpUser.b_acct_id
    await this.getgst();
    await this.getDedType();
    await this.getDedData();
    await this.getrepresentative();
    await this.getparty();
    await this.getCbById();
    await this.partyFilter();
    await this.getworkInfo();
    await this.check();
    await this.print1();
  }

  hsnArr:any
  gstObj:any
  hsnObj:any
  rateObj:any
  work_order_id:any = []
  async getworkInfo() {
    this.spinner.show()
    var obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.billService.getworkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      console.log("All Work",resp['data'])
      resp['data'].map((x:any) => {
        if (x['work_id'] == this.allCbData[0]['work_id']) {
          this.work_order_id = x;
        }
      })
      console.log(" work order id : ", this.work_order_id)
      this.spinner.hide();
    } else {
      this.spinner.hide();

    }
  }
  async getgst() {
    var resp = await this.billService.getgst(this.b_acct_id);
    if (resp["error"] == false) {
      this.hsnArr = resp.data;
      this.gstObj = new Object();
      this.hsnObj = new Object();
      this.rateObj = new Object();
      for (let i = 0; i < this.hsnArr.length; i++) {
        this.gstObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i];
        this.hsnObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i]["hsn_desc"];
        this.rateObj[this.hsnArr[i]["hsn_code"]] = this.hsnArr[i]["igst"];
      }
    } else {
      Swal.fire("Error", "..Error while getting GST!", "error");
    }
  }
  dedTypeObj:any = {}

  async getDedType() {
    var dedArr:any = [];
    if (this.mainService.codeValueTechObj["ACC0057"] != undefined) {
      this.mainService.codeValueTechObj["ACC0057"].map((x:any) => {
        if (!(x["code"] == "CGST" || x["code"] == "SGST" || x["code"] == "IGST"))
          this.allUserDeduction.push(x);
      });
      dedArr = this.allUserDeduction;
    }
    this.dedTypeObj = new Object();
    for (let i = 0; i < dedArr.length; i++) {
      this.dedTypeObj[dedArr[i]["code"]] = dedArr[i]["value"];
    }
    console.log("dedTypeObj : ", this.dedTypeObj)
  }

  dedDataArr:any = {};
  dedtoActivity:any = {};
  dedtoBudeget:any = {};
  allUserDeduction:any= [];
  allDeduction :any= [];
  async getDedData() {
    console.log("In ded ", this.b_acct_id)
    var resp = await this.settingService.getDeductionMappingList(JSON.stringify(this.b_acct_id));
    if (resp["error"] == false) {
      this.allDeduction = resp.data;
      var dd = Object.keys(this.dedTypeObj);
      for (let i = 0; i < dd.length; i++) {
        if (!(dd[i] == "IGST" || dd[i] == "IGST" || dd[i] == "IGST"))
          this.dedDataArr[dd[i]] = [];
      }

      for (let j = 0; j < this.allDeduction.length; j++) {
        this.dedtoActivity[this.allDeduction[j]["ded_code"]] =
          this.allDeduction[j]["act_cd"];
        this.dedtoBudeget[this.allDeduction[j]["ded_code"]] =
          this.allDeduction[j]["bud_cd"];
        if (this.allDeduction[j]["ded_owner"] != "SYSTEM") {
          this.dedDataArr[this.allDeduction[j]["ded_code"]].push(
            this.allDeduction[j]
          );
        }
      }
      console.log("dedtoActivity : ", this.dedtoActivity);
      console.log('dedtoBudeget  : ', this.dedtoBudeget);
    } else {
      Swal.fire("Error", "...Error while getting list!", "error");
    }
  }
  reperesentative:any = {};
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    }
  }
  all_party :any= [];
  partyNameObj :any= {};
  obj :any= {}
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      this.partyNameObj = {};

      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["party_type"] == "ORG") {
          resp["data"][i]["party_leagal_name"] = resp["data"][i]["party_leagal_name"] + " ( " + this.reperesentative[resp["data"][i]["rep_le_id"]] + ")";
        }
        this.partyNameObj[resp["data"][i]["le_id"]] = resp["data"][i]["party_leagal_name"];
      }
      this.all_party = resp["data"];
      console.log(this.all_party)
    }
  }
  patryInfo:any;
  async partyFilter() {
    console.log("In Fileter ", this.allCbData)
    let obj:any = JSON.parse(this.allCbData[0].data)
    this.all_party.map((x:any) => {
      if (x['le_id'] == obj['party_le_id']) {
        this.patryInfo = x;
      }
    })
  }


  allCbData:any = [];
  async getCbById() {

    console.log("OBJ to send : ", this.objToSend)
    let bill_data;
    let resp = await this.billService.getGenCbById(JSON.stringify(this.objToSend));
    if (resp['error'] == false) {
      bill_data = resp['data'];
      this.allCbData = resp['data']
      console.log("Bill Data ", bill_data)
    }
    else {
      Swal.fire("error", "Error while getting CB information", 'error');
      return;
    }
    let ob = JSON.parse(this.allCbData[0].data);
    this.AllBillRow = ob["payable_rows"];
  }


  net_amount:any
  async check() {
    var total1:any = 0;
    var cgstAmount:any = 0;
    var sgstAmount :any= 0;
    var igstAmount:any = 0;
    var totalAmount:any = 0;
    this.net_amount = 0;
    let obj:any = new Object();
    let obj_ded_rule :any= new Object();

    for (let i = 0; i < this.AllBillRow.length; i++) {
      await this.changeGst(i);

      cgstAmount = cgstAmount + this.AllBillRow[i]["cgst"];
      sgstAmount = sgstAmount + this.AllBillRow[i]["sgst"];
      igstAmount = igstAmount + this.AllBillRow[i]["igst"];
      let tax_amount = this.AllBillRow[i]["cgst"] + this.AllBillRow[i]["sgst"] + this.AllBillRow[i]["igst"];

      this.AllBillRow[i]["tax_amount"] = parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["payable_amount"] = this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
      this.AllBillRow[i]["total_amount"] = this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
      let totalDed = 0;
      for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
        if (obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] == undefined) {
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = 0;
        }
        if (obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] == undefined) {
          obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] = "";
        }
        obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] = obj_ded_rule[this.AllBillRow[i]["ded"][j]["deduction_type"]] + "@" + this.AllBillRow[i]["ded"][j]["gov_rule"];

        if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
          totalDed = totalDed + (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100;
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] + (this.AllBillRow[i]["amount"] * this.AllBillRow[i]["ded"][j]["ded_amount"]) / 100;
        } else {
          totalDed = totalDed + this.AllBillRow[i]["ded"][j]["ded_amount"];
          obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] = obj[this.AllBillRow[i]["ded"][j]["deduction_type"]] + this.AllBillRow[i]["ded"][j]["ded_amount"];
        }
      }

      this.AllBillRow[i]["payable_amount"] = this.AllBillRow[i]["payable_amount"] - totalDed;
      this.AllBillRow[i]["deduction_amount"] = totalDed;

      this.net_amount = this.net_amount + this.AllBillRow[i]["payable_amount"];

      total1 = total1 + this.AllBillRow[i]["amount"] + this.AllBillRow[i]["tax_amount"];
      totalAmount = totalAmount + this.AllBillRow[i]["amount"];
    }
    this.total_amt = parseFloat(total1.toFixed(2));
    this.totalAmount = parseFloat(totalAmount.toFixed(2));
    this.cgstAmount = parseFloat(cgstAmount.toFixed(2));
    this.sgstAmount = parseFloat(sgstAmount.toFixed(2));
    this.igstAmount = parseFloat(igstAmount.toFixed(2));

    var keys = Object.keys(obj);
    this.total_ded_amount = 0;
    this.AllDedRow = [];
    for (let i = 0; i < keys.length; i++) {
      this.AllDedRow.push({
        deduction_type: keys[i],
        ded_amount: obj[keys[i]],
        gov_rule: obj_ded_rule[keys[i]],
      });
      this.total_ded_amount = this.total_ded_amount + obj[keys[i]];
    }
    return true;
  }
  total_amt:any = 0
  totalAmount:any = 0
  cgstAmount :any= 0
  igstAmount:any = 0
  total_ded_amount:any = 0
  sgstAmount:any = 0
  AllDedRow:any = []
  AllBillRow :any= []
  async changeGst(i:any) {
    this.AllBillRow[i]["cgst"] = 0;
    this.AllBillRow[i]["sgst"] = 0;
    this.AllBillRow[i]["igst"] = 0;
    if (this.AllBillRow[i]["gst_type"] == "cgst_sgst") {
      let amt = 0;
      amt = (this.AllBillRow[i]["amount"] * this.rateObj[this.AllBillRow[i]["hsn_code"]]) / 100;

      this.AllBillRow[i]["cgst"] = parseFloat((amt / 2).toFixed(2));
      this.AllBillRow[i]["sgst"] = parseFloat((amt / 2).toFixed(2));
    }

    if (this.AllBillRow[i]["gst_type"] == "igst") {
      let amt = 0;
      amt =
        (this.AllBillRow[i]["amount"] *
          this.rateObj[this.AllBillRow[i]["hsn_code"]]) /
        100;

      this.AllBillRow[i]["igst"] = parseFloat(amt.toFixed(2));
    }

    let tax_amount =
      this.AllBillRow[i]["cgst"] +
      this.AllBillRow[i]["sgst"] +
      this.AllBillRow[i]["igst"];

    this.AllBillRow[i]["tax_amount"] = parseFloat(tax_amount.toFixed(2));
    this.AllBillRow[i]["total_amount"] =
      this.AllBillRow[i]["amount"] + parseFloat(tax_amount.toFixed(2));
  }


  async print1() {
    let obj = JSON.parse(this.allCbData[0]['data']); //For getting work order decs
    let txt = "CB(Dep.CB )";
    let dd :any= {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        let arr = [];
        let obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        let obj1 = {
          text: "DEPARTMENT BILL",
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj1);

        return arr;
      },

      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };
    let header1 = {
      columns: [
        {
          width: "*",
          text: "Party Name :",
          bold: true,
        },
        {
          width: "*",
          text: this.patryInfo["party_leagal_name"],
        },
        {
          width: "*",
          text: "CB No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.allCbData[0]["cb_id"],
        },
      ],
    };
    let header2 = {
      columns: [
        {
          width: "*",
          text: "Work Order No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.work_order_id['work_order_no'],
        },
        {
          width: "*",
          text: "Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.allCbData[0]["cb_date"]),
        },
      ],
    };
    let header3 = {
      columns: [
        {
          width: "*",
          text: "Work/Service Name :",
          bold: true,
        },
        {
          width: "*",
          text: obj["work_desc"],
        },
        {
          width: "*",
          text: "Invoice No. :",
          bold: true,
        },
        {
          width: "*",
          text: this.allCbData[0]["bill_no"],
        },
      ],
    };
    let header4 = {
      columns: [
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "Invoice Date :",
          bold: true,
        },
        {
          width: "*",
          text: this.mainService.dateFormatChange(this.allCbData[0]["cb_date"]),
        },
      ],
    };
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });

    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push({ text: " " });
    dd.content.push(header4);
    dd.content.push({ text: " " });

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    let header5 = {
      columns: [
        {
          width: "*",
          text: "DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "HSN",
          bold: true,
        },
        {
          width: "*",
          text: "RATE",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "CGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "SGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "IGST",
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(header5);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    console.log("this.AllBillRow.length ", this.AllBillRow.length)
    dd.content.push({ text: " " });

    for (let i = 0; i < this.AllBillRow.length; i++) {
      console.log("In print method ", this.AllBillRow.length)
      let objRow = {
        columns: [
          {
            width: "*",
            text: this.AllBillRow[i]["event_desc"],
            bold: true,
          },
          {
            width: "*",
            text: this.hsnObj[this.AllBillRow[i]["hsn_code"]],
          },
          {
            width: "*",
            text: this.rateObj[this.AllBillRow[i]["hsn_code"]],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["amount"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["cgst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["sgst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["igst"],
            alignment: "right",
          },
          {
            width: "*",
            text: this.AllBillRow[i]["total_amount"],
            bold: true,
            alignment: "right",
          },
        ],
      };

      dd.content.push(objRow);
      dd.content.push({ text: " " });
      if (this.AllBillRow[i]["ded"].length > 0) {
        let objRow1 = {
          columns: [
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "Deductions",
              bold: true,
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
            {
              width: "*",
              text: "",
            },
          ],
        };
        dd.content.push(objRow1);
        dd.content.push({ text: " " });
      }

      for (let j = 0; j < this.AllBillRow[i]["ded"].length; j++) {
        if (this.AllBillRow[i]["ded"][j]["amt_type"] == "percentage") {
          let dedRowObj = {
            columns: [
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: j + 1 + ".",
              },
              {
                width: "*",
                text: this.dedTypeObj[
                  this.AllBillRow[i]["ded"][j]["deduction_type"]
                ],
              },

              {
                width: "*",
                text: (
                  (this.AllBillRow[i]["amount"] *
                    this.AllBillRow[i]["ded"][j]["ded_amount"]) /
                  100
                ).toFixed(2),
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
            ],
          };
          dd.content.push(dedRowObj);
        } else {
          let dedRowObj = {
            columns: [
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: j + 1 + ".",
              },
              {
                width: "*",
                text: this.dedTypeObj[
                  this.AllBillRow[i]["ded"][j]["deduction_type"]
                ],
              },

              {
                width: "*",
                text: this.AllBillRow[i]["ded"][j]["ded_amount"].toFixed(2),
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
              {
                width: "*",
                text: "",
              },
            ],
          };
          dd.content.push(dedRowObj);
        }

        dd.content.push({ text: " " });
      }
      let dedObj = {
        columns: [
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "DEDUCTION AMOUNT:",
            bold: true,
          },
          {
            width: "*",
            text: this.AllBillRow[i]["deduction_amount"].toFixed(2),
            bold: true,
            alignment: "right",
          },
        ],
      };
      let paybObj = {
        columns: [
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "TOTAL PAYABLE AMOUNT:",
            bold: true,
          },
          {
            width: "*",
            text: this.AllBillRow[i]["payable_amount"].toFixed(2),
            bold: true,
            alignment: "right",
          },
        ],
      };
      dd.content.push(dedObj);
      dd.content.push({ text: " " });
      dd.content.push(paybObj);
      dd.content.push({ text: " " });
      dd.content.push({
        canvas: [
          { type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 },
        ],
      });
      dd.content.push({ text: " " });
    }

    let totalObjRow = {
      columns: [
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "TOTAL",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: this.totalAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.cgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.sgstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.igstAmount.toFixed(2),
          bold: true,
          alignment: "right",
        },
        {
          width: "*",
          text: this.net_amount.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(totalObjRow);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    dd.content.push({ text: " " });

    let netPayRow = {
      columns: [
        {
          width: "*",
          text: "NET PAYABLE AMOUNT:   " + this.net_amount.toFixed(2),
          bold: true,
          fontSize: 20,
          alignment: "right",
        },
      ],
    };
    dd.content.push(netPayRow);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 2 }],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    let header7 = {
      columns: [
        {
          width: "*",
          text: "DEDUCTIONS",
          bold: true,
        },
      ],
    };
    dd.content.push(header7);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    let header6 = {
      columns: [
        {
          width: "*",
          text: "DESCRIPTION",
          bold: true,
        },
        {
          width: "*",
          text: "AMOUNT",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "",
        },
      ],
    };
    dd.content.push(header6);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });

    for (let i = 0; i < this.AllDedRow.length; i++) {
      let ded = {
        columns: [
          {
            width: "*",
            text: this.dedTypeObj[this.AllDedRow[i]["deduction_type"]],
          },
          {
            width: "*",
            text: this.AllDedRow[i]["ded_amount"],
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
          {
            width: "*",
            text: "",
          },
        ],
      };
      dd.content.push(ded);
      dd.content.push({ text: " " });
    }

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });
    let dedPayRow = {
      columns: [
        {
          width: "*",
          text: "TOTAL DEDUCTION AMOUNT:   " + this.total_ded_amount.toFixed(2),
          bold: true,
          alignment: "right",
        },
      ],
    };
    dd.content.push(dedPayRow);
    dd.content.push({ text: " " });

    dd.content.push({
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 1115, y2: 0, lineWidth: 0.5 }],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    dd.content.push({
      text: "Passed for payment of Rupees.........................................................................................................................(in word)",
    });

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    let sig = {
      columns: [
        {
          width: "*",
          text: "Accounts Clerk",
          bold: true,
        },
        {
          width: "*",
          text: "Accountant",
          bold: true,
        },
        {
          width: "*",
          text: "AAO/AO",
          bold: true,
        },
        {
          width: "*",
          text: "FC",
          bold: true,
        },
      ],
    };
    dd.content.push(sig);

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      text: "Above paypent of Rupees.........................................................................................................................(in word) sanctioned",
    });

    pdfMake.createPdf(dd).download("CB_Bill.pdf");
  }
}
