import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendorComponent } from '../vendor/vendor.component';
import { VmsDashboardComponent } from '../vms-dashboard/vms-dashboard.component';

import { DataAssignmentComponent } from '../vms-administration/data-assignment/data-assignment.component';
import { VmsRoleComponent } from '../vms-administration/vms-role/vms-role.component';
import { VmsUserComponent } from '../vms-administration/vms-user/vms-user.component';
import { VmsSettingComponent } from '../vms-setting/vms-setting.component';
import { CodeValueComponent } from '../vms-setting/code-value/code-value.component';
import { FieldComponent } from '../vms-setting/field/field.component';
import { HierarchyComponent } from '../vms-setting/hierarchy/hierarchy.component';
import { WorkflowComponent } from '../vms-setting/workflow/workflow.component';
import { VmsAdministrationComponent } from '../vms-administration/vms-administration.component';
import { WorkComponent } from '../vms-administration/work/work.component';
import { FAQComponent } from '../vms-administration/faq/faq.component';


const routes: Routes = [
  {
    path: "vms-index",
    component: VmsDashboardComponent, 
    //canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R1' }
  },
  {
    path: "vms-vendor",
    component: VendorComponent, 
    //canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R1' }
  },
 

  {
    "path": "administration",
    "component": VmsAdministrationComponent,
    "children": [
      {
        path: "work",
        component: WorkComponent, 
      //   canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R5' }
      },
      {
        path: "roles",
        component: VmsRoleComponent, 
      //   canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R5' }
      },
      {
        path: "faq",
        component: FAQComponent, 
      //   canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R5' }
      },
      {
        path: "users",
        component: VmsUserComponent, 
         //canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R6' }
      },

      {
        path: "data-assignment",
        component: DataAssignmentComponent, 
        // canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R7' }
      },

    ]
  },

  {

    "path": "vms-setting",
    "component": VmsSettingComponent,
    "children": [
      {
        path: "code-value",
        component: CodeValueComponent, 
         //canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R10' }
      },
      {
        path: "field",
        component: FieldComponent, 
        // canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R9' }
      },
    
      {
        path: "workflow",
        component: WorkflowComponent, 
      //  canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R8' }
      },
      {
        path: "hierarchy",
        component: HierarchyComponent, 
      //  canActivate: [RoleGuardService], data: { ComponentCode: 'EMD-R11' }

      }
      
    ]

  },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VMSLayoutRoutingModule { }
