<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Lease Mutation</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Mutation List </a>
                            <!-- list details -->
                        </li>

                        <li class="nav-item ">
                            <a class="nav-link" href="#tab-2" *ngIf="create_flag" data-toggle="tab"><i class="ti-settings"></i>
                               Create Mutation </a>
                            <!-- Applicant list -->
                        </li>
                    </ul>


                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1" *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">

                                </div>
                                <div class="col-4 text-right">
                                    <button (click)="create()" class="btn btn-outline-primary">Create</button>
                                </div>

                            </div>
                            <div class="example-container">

                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                    <ng-container matColumnDef="lease_type">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>Lease Type</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.lease_type}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="file_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>File Number </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.file_num}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="plot_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Plot/ Khasra No</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.assigned_property_number}} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="address_of_property">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Address of Property</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.address}}</td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="lease_details">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application10']}}</b> -->
                                            <b>Transfree Details</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.transfree_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="mutaion_plot_area">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Area of Mutation</b>

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.mutaion_plot_area}} </td>
                                    </ng-container>


                                    <!-- <ng-container matColumnDef="Sub_divison">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Sub Division Area</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.Sub-division}} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-success"
                                            (click)='view(element)'>View</button>
                                            <button class="btn btn-outline-success"
                                                (click)='view(element)'>Update</button>
                                            <!-- <button class="btn btn-outline-primary " (click)='approve(element)'
                                                *ngIf="element['arr_status_code']=='APPROVAL_PENDING'">Delete</button> -->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>

                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                            </div>
                        </div>

                        <div class="tab2-content" id="tab-2" *ngIf="create_flag">

                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-4 text-center"></div>
                                <div class="col-4 text-right">
                                    <button (click)="goback()" class="btn btn-outline-primary">Go to List</button>
                                </div>

                            </div>

                            <br>


                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">File Number :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="File Number" [selectableGroup]="true"
                                        [items]="allfile" bindLabel="file_num" bindValue="file_num"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['fileno']" (change)="getAllData()">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>

                            <div class="row mt-3" *ngFor="let item of data,let i=index">
                                <!-- <div class="col-3"></div> -->
                                <div class="col-4 text-right">
                                    <h6>Select Level {{i+1}} :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                                        (change)='setLeafNode()' [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                        [multiple]="false" (change)='setLeafNode()' placeholder="Select..."
                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>

                                </div>
                                <div class="col-3" *ngIf="i==data.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                        class="btn btn-outline-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Select Plot Number :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Total Area of Plot"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['plotno']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>


                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Total Area of Plot :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Total Area of Plot"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" [(ngModel)]="Obj['areaOfPlot']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>

                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Transferor :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Transferor"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" [(ngModel)]="Obj['transferor']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>


                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Transfree :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" placeholder="Transfree" [(ngModel)]="Obj['transfree']"/>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>



                            <!-- <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Select Lease Type :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Lease Type"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" [(ngModel)]="Obj['leaseType']">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br> -->

                            <!-- <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Leasee's Plot Area :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Plot Area"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" [(ngModel)]="Obj['plotArea']">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br> -->



                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Upload Document :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="file" class="form-control" />
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>

                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Mutation Date :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['date']"/>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)="createMutation()" class="btn btn-outline-primary">Submit</button>
                                </div>

                            </div>

                        </div>
                    </div>
                      <!-- View Details Modal -->
          <div class="modal" id="viewDetails">
            <div class="modal-dialog modal-lg" style="max-width: 90%">
              <div class="modal-content rounded-50">
                <!-- Modal Header -->
                <div class="modal-header bg-primary">
                  <h4 class="modal-title text-white" style="font: bold">
                    Mutation Details :
                  </h4>
                  <button type="button" class="close text-white" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="border-radius: 10px">
                  <div class="card" style="border: 2px solid rgb(24, 24, 97)">
                    <div style="margin: 3%">
                      <div class="content-container">

                        <div class="row mt-2 mb-2">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <h2> Lease Details </h2>
                          </div>
                          <div class="col-4"></div>
                        </div>
                        <hr>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>File Number :</b></h5>
                          </div>
                          <div class="col-3">

                            <h5 style="width: 100%">
                              {{ viewdata["file_num"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Assigned Property Number :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                             {{viewdata['assigned_property_number']}}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Alloted Property Id :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["alloted_property_id"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Total Area :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["area"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"> <b>Address :</b> </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["address"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease Type :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["leasee_type"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Applicant Name :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["leasee_name"] }}
                            </h5>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-3">
                            <h5 style="width: 100%"></h5>
                          </div>
                        </div>
                        <hr>
                        <br>

                        <div class="row mt-2 mb-2">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <h2> Mutation Details </h2>
                          </div>
                          <div class="col-4"></div>
                        </div>
                        <hr>
                        <br>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                                <b>Transferee Name :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["transfree_name"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Mutation ID :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["mutation_id"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                                <b>Muatation Plot Area :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ viewdata["mutaion_plot_area"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Mutation Date :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                                {{ viewdata["mutation_date"] | date}}
                            </h5>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                                             (click)="download(data)">Download</button> -->
                  <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
                </div>
            </div>
        </div>
    </div>
</div>
