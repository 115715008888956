<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Possession Rule</div>
          <!--Property-->
          <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
        </div>

        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab"><i class="fa
                       fa-clock-o"> &nbsp; </i>Possession Rule</a>
            </li>
          </ul>

          <div class="tab-content">
            <br>

            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3 text-right">
                <h6>Possession Code : <span style="color: red;"> * </span></h6>
              </div>
              <div class="col-3 text-left">
                <input type="text" class="form-control" placeholder="Enter Possession code"
                  [(ngModel)]="obj['possession_cd']">
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3 text-right">
                <h6>Select Property Category : <span style="color: red;"> * </span></h6>
              </div>
              <div class="col-3 text-left">
                <ng-select [items]="mainService.codeValueTechObj['PROP0018']" bindLabel="value" bindValue="code"
                  [multiple]="false" placeholder="Select..." [(ngModel)]="obj['property_category']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3 text-right">
                <h6>Select Property Type : <span style="color: red;"> * </span> </h6>
              </div>
              <div class="col-3 text-left">
                <ng-select [items]="mainService.codeValueTechObj['PROP0015']" bindLabel="value" bindValue="code"
                  [multiple]="false" placeholder="Select..." [(ngModel)]="obj['property_type']" [selectableGroup]="true"
                  [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                </ng-select>
              </div>
            </div>
            <!--  -->
            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-2 text-right">
                <h6>Paid % for Possession : <span style="color: red;"> * </span> </h6>
              </div>
              <div class="col-2 text-left">
                <input type="number" class="form-control" placeholder="Enter percentage" [(ngModel)]="obj['amount']">
              </div>
              <h6 class="pt-2">

                of
              </h6>
              <div class="col-2 text-left ">

                <ng-select [items]="amount" bindLabel="name" bindValue="id" [(ngModel)]="obj['type']">
                </ng-select>
              </div>
            </div>


            <!--  late fee =========================== -->

            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-2 text-right">
                <h6>Late / Maintenance Fee (Rs): <span style="color: red;"> * </span> </h6>
              </div>
              <div class="col-2 text-left">
                <input type="number" class="form-control" placeholder="Enter percentage"
                  [(ngModel)]="obj['possession_late_fee']">
              </div>
              <h6 class="pt-2">

                /
              </h6>
              <div class="col-2 text-left ">

                <ng-select [items]="cal_fee_type" bindLabel="name" bindValue="id" [(ngModel)]="obj['calcu_fee_type']">
                </ng-select>
              </div>
            </div>

            <!-- effective date -->


            <div class="row mt-2">
              <div class="col-2"></div>
              <div class="col-3 text-right">
                <h6>Effective Date : <span style="color: red;"> * </span></h6>
              </div>
              <div class="col-3 text-left">
                <input type="date" [(ngModel)]="obj['effective_date_from']" min="{{todayformatedDate()}}"
                  class="form-control" name="" id="">
              </div>
            </div>
           
            <!-- btn -->
            <br>
            <div class="row">
              <div class="col-5"></div>
              <div class="col-3 text-center">
                <button class="btn btn-outline-primary" (click)="submit()">SUBMIT</button>
              </div>
            </div>
            <br>
            <!-- table start -->
            <div class="example-container">
              <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
                <!-- id -->
                <ng-container matColumnDef="ID">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <h6>ID</h6>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                    {{i+1}}
                  </td>
                </ng-container>
                <!-- possession_cd -->
                <ng-container matColumnDef="Possession_cd">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>Possession Code</b>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.possession_cd}}
                  </td>
                </ng-container>
                <!-- party_id Column -->
                <ng-container matColumnDef="Property_Category">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>Property Category</b>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.property_category}}
                  </td>
                </ng-container>

                <!-- property_type Column -->
                <ng-container matColumnDef="Property_Type">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>Property Type</b>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.property_type}}
                  </td>
                </ng-container>
                <!--  -->
                <ng-container matColumnDef="Min_Amount">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>Min Amount %</b>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{element.amount}} % of {{element.type}}
                  </td>
                </ng-container>

                <!-- Weight Column -->


                <ng-container matColumnDef="Action">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    <b>Action</b>
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                    <button class="btn btn-outline-danger but-w mt-1" (click)="inactivePossessionRule(element)">
                      Delete</button>

                  </td>
                </ng-container>
                <!--  -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

              <!-- mat table  -->
            </div>



            <!-- table end -->



          </div>
        </div>





        <hr class="hrline">
        <br>
        <br>
      </div>
    </div>

    <br>
  </div>
</div>