<div class="page-content fade-in-up">
    <br>
    <div class="row">
        <button style="margin-left:0.5%;" class="btn btn-link" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i> Go Back</button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <br>
                <div class="ibox-head">
                    <div class="ibox-title">TASK MANAGEMENT</div>
                </div>
                <div class="ibox-body">
                    <!-- <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Employee Complaint Detail List</a>
                        </li> -->
                    <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Suspend After Complaint Forward</a>
                        </li> -->

                    <!-- </ul> -->
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="complain_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Complaint ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.complain_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="complian_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Complaint
                                            Type

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complian_type}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="complain_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Complaint
                                            From

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.complain_from}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="owner_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Owner name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.owner_name}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="property_address">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Property
                                            Address

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_address}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Status

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <h6 *ngIf="element.complaint_status == 'FORWARD'"  >Forward To {{element.forward_to}}</h6>
                                            <button class="btn btn-primary" *ngIf="element.complaint_status == 'FORWARD' && element.forward_status == '1'" (click)="open_forward(element)"> Forward</button>
                                            <button class="btn btn-success" *ngIf="element.complaint_status != 'APPROVED' && element.complaint_status != 'CLOSED' && element.complaint_status != 'SUSPENDED'" (click)="approve(element)"> Approve</button>
                                            <button class="btn btn-secondary" *ngIf="element.complaint_status != 'SUSPENDED' && element.complaint_status != 'APPROVED' && element.complaint_status != 'CLOSED'" (click)="open_suspend(element)">Suspend</button>
                                            <button class="btn btn-danger" *ngIf="element.complaint_status != 'CLOSED' && element.complaint_status != 'APPROVED' && element.complaint_status != 'SUSPENDED'" (click)="open_close(element)">Close</button>
                                        </td>
                                    </ng-container> -->



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
