import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// import * as CanvasJS from '../../../assets/js/scripts/canvasjs.min.js';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { DashboardService } from './../service/dashboard.service';
import { SaleService } from '../../property/service/sale.service';
import { from } from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import * as  pdfMake from "pdfmake/build/pdfmake";
import { MainService } from '../service/main.service';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApprovalUserService } from '../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../emb/service/main.service';
import { MainService as Portalmain } from '../../portal/service/main.service';

declare var $: any;
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexResponsive
} from "ng-apexcharts";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { map } from 'rxjs/operators';
import { formatNumber } from '@angular/common';
declare var $: any;
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  labels: any;
  responsive: ApexResponsive[];
  colors: any[];
};



@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]

})
export class NewDashboardComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer,private dashboardService: DashboardService,private mainService:MainService,private portalmain: Portalmain,public ApprovalUserService: ApprovalUserService, public mainserviceEmb: mainserviceEmb, public toastr: ToastrService, private snackBar: MatSnackBar, private router: Router, private spinner: NgxSpinnerService, private sale_service: SaleService) { }
  erpUser: any;
  b_acct_id: any;
  propObj: any = {}
  total_offered_property: any = 0;
  allOffer: any = [];
  total_offered: any = 0;
  total_sold_properties: any = 0;
  total_available_properties : any= 0;
  total_allotements: any = 0;
  total_boolet_purchase: any = 0;
  total_applicant: any = 0;
  ebillUser: any;

  allArrAllotment: any = [];
  allArrSubScheme: any = [];
  code_to_value: any={}
  quota_obj: any={}
  subQuota_obj: any={}
  occupation: any={}
  required_doc_arr: any=[]
  required_doc_obj: any={}
  showDetail = false;
  async ngOnInit() {
    // this.erpUser = JSON.parse(localStorage.getItem('erpUser'));
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    // this.ebillUser = JSON.parse(localStorage.getItem('erpUser'));
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    await this.getDashBoardCountInfo();
    await this.getAllOffer();
    await this.allDashboardCount()
    await this.getWorkflowlog();
    // await this.getAllPropertyOffer();

    this.getCodeValue()
    for (
      let i = 0;i < this.mainService.codeValueTechObj["PROP0015"].length;i++) {
      this.code_to_value[this.mainService.codeValueTechObj["PROP0015"][i]["code"]] = this.mainService.codeValueTechObj["PROP0015"][i]["value"];
    }
    for (let i = 0;i < this.mainService.codeValueTechObj["PROP0019"].length;i++) {
      this.quota_obj[this.mainService.codeValueTechObj["PROP0019"][i]["code"]] =
        this.mainService.codeValueTechObj["PROP0019"][i]["value"];
    }

   // console.log(this.quota_obj)
    for (
      let i = 0;
      i < this.mainService.codeValueTechObj["PROP0020"].length;
      i++
    ) {
      this.subQuota_obj[
        this.mainService.codeValueTechObj["PROP0020"][i]["code"]
      ] = this.mainService.codeValueTechObj["PROP0020"][i]["value"];
    }
    for (
      let i = 0;
      i < this.mainService.codeValueTechObj["Occupation"].length;
      i++
    ) {
      this.occupation[
        this.mainService.codeValueTechObj["Occupation"][i]["code"]
      ] = this.mainService.codeValueTechObj["Occupation"][i]["value"];
    }
    for (
      let i = 0;
      i < this.mainService.codeValueTechObj["PROP0022"].length;
      i++
    ) {
      this.required_doc_arr.push(
        this.mainService.codeValueTechObj["PROP0022"][i]
      );
    }
    for (
      let i = 0;
      i < this.mainService.codeValueTechObj["PROP0023"].length;
      i++
    ) {
      this.required_doc_arr.push(
        this.mainService.codeValueTechObj["PROP0023"][i]
      );
    }
    for (let i = 0; i < this.required_doc_arr.length; i++) {
      this.required_doc_obj[this.required_doc_arr[i]["code"]] =
        this.required_doc_arr[i]["value"];
    }
    await this.getAllotmentLetterData()
    // this.buildAllotmentChart();
    // this.buildSubSchemeChart()
    // this.paginator.pageIndex =0
  }

  async getCodeValue() {
    this.spinner.show()
    var resp: any  = await this.mainService.getCodeValue(this.b_acct_id);
    var codeValueTempObj: any = {}
    var codeValueShowTempObj: any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }

      this.mainService.codeValueTechObj = codeValueTempObj;
      this.mainService.codeValueShowObj = codeValueShowTempObj;

     // console.log(this.mainService.codeValueTechObj, this.mainService.codeValueShowObj, 'code_value')
    } else {
      this.spinner.hide()
    }
  }

  // new
  displayedColumns: any = []
  dataSource:any
  // displayedColumns1: string[] = ['id', 'offer_name', 'offer_code', 'no_of_property', 'offer_start_date', 'offer_end_date', 'status', 'Action'];
  // dataSource1: any;
   //  2 and 4 and 5
  displayedColumns2: string[] = ['sr', 'offer_cd', 'leaf_value', 'property_type', 'property_no','pro_status'];
  // for 3 applicant detail

  displayedColumns3: string[] = ['sr', 'offer_cd', 'leaf_value', 'party_name', 'party_phone_no', 'property_type', 'property_no', 'form_status','Action'];

  // for property cost detail
  displayedColumns6: string[] = ['sr', 'offer_cd', 'leaf_value', 'property_type','pro_rate','no_of_pro','total_property_cost'];


  displayedColumns7: string[] =  ['sr', 'offer_cd', 'leaf_value','party_name','party_phone_no', 'property_no', 'property_type','registration_amt'];
  // ,'party_phone_no','party_email'
  displayedColumns8: string[] =  ['sr', 'offer_cd', 'leaf_value', 'property_type','pro_rate','property_no','area'];



  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  async getDashBoardCountInfo() {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.getDashBoardCount(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
     // console.log(resp, 'this is called my side')
      this.total_sold_properties = resp.data[0][0]['total_sold_properties'];
      this.total_available_properties = resp.data[1][0]['total_available_properties'];
      this.total_allotements = resp.data[2][0]['total_allotements'];
      this.total_boolet_purchase = resp.data[3][0]['total_boolet_purchase'];
      this.total_applicant = resp.data[4][0]['total_applicant'];
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }
  cur_page = 0
  paginatorPageChange(){

    if(this.paginator.pageIndex>this.cur_page){
      this.cur_page++;
    }else{
      this.cur_page--;
    }
  }

  propertyOffer = []
  fcfsPropertyOffer = []
  lotteryPropertyOffer = []
  fcfsPropertyOfferReserved=[]
  fcfsPropertyOfferRemain=[]
  async getAllPropertyOffer() {
    const obj : any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.getAllProperty(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.propertyOffer = resp.data
      for (let mode of this.propertyOffer) {
        if (mode['mode_of_sale'] == 'FCFS') {
          this.fcfsPropertyOffer.push(mode)
        } else {
          this.lotteryPropertyOffer.push(mode)
        }
      }
      for(let mode of this.fcfsPropertyOffer){
        if(mode['life_cycle_status']=='RESERVED'){
          this.fcfsPropertyOfferReserved.push(mode)
        }else{
          this.fcfsPropertyOfferRemain.push(mode)
        }
      }
      this.dataSource = new MatTableDataSource(this.fcfsPropertyOffer)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()

      // this.dataSource5 = new MatTableDataSource(this.lotteryPropertyOffer)
      // this.dataSource5.sort = this.sort;
      // this.dataSource5.paginator = this.paginator;
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }

  totalApplicantData: any = []

  async totalProOffer(){   // 2
    this.cur_page = 0
    this.curOffer = undefined
    this.curProType = undefined
    this.showChooseProType= true
    this.onApplicant= true
    this.Status = 2 // reserve and vacant pro
    const obj : any = {}
    this.spinner.show()
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.allVacantANDResevedPro(obj)
    this.displayedColumns = this.displayedColumns2
    // console.log(this.displayedColumns)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.totalPropertyData = resp.data
      for (let index = 0; index < this.totalPropertyData.length; index++) {
        this.totalPropertyData[index]['sr'] = index+1;
      }
      this.forDataforPdf = this.totalPropertyData
      // console.log(this.forDataforPdf)
      this.dataSource = new MatTableDataSource(this.totalPropertyData)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()
      // $('#viewDetails3').modal('show')
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }

  totalPropertyData: any
  forDataforPdf: any // for 2 and  4 and  5
  async allVacantANDResevedPro(status: any){  // for 4 and 5
    this.curOffer = undefined
    this.cur_page = 0
    this.Status = 3 //reserve

    if(status=='ACTIVE'){
      this.Status = 4 // vacant pro
    }
    this.curProType = undefined
    this.showChooseProType = true
    this.onApplicant= true
    const obj : any = {}
    this.spinner.show()
    obj['b_acct_id'] = this.b_acct_id;
    obj['life_cycle_status'] = status;
    var resp = await this.dashboardService.allVacantANDResevedPro(obj)
    this.displayedColumns = this.displayedColumns2
   // console.log(this.displayedColumns)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.totalPropertyData = resp.data
      // console.log(this.totalPropertyData, 'all applicant getting here')
      for (let index = 0; index < this.totalPropertyData.length; index++) {
        this.totalPropertyData[index]['sr'] = index+1;
      }
      this.forDataforPdf = this.totalPropertyData
      this.dataSource = new MatTableDataSource(this.totalPropertyData)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()
      // $('#viewDetails3').modal('show')
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }
  // for to decide whether to show second filter propType or not
  showChooseProType=true

  Status = 1 //for property cost
  async propertyCostDetails() {     // for 6
    this.spinner.show();
    this.cur_page = 0
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    this.showChooseProType= false  // for
    // this.onApplicant= false
    this.Status = 7 //for property cost
    var resp = await this.dashboardService.propertyCostDetails(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
     // console.log(resp, 'this is called my side')
      this.totalPropertyData= resp.data;
      // console.log(this.totalPropertyData, 'this is called my side')
      this.displayedColumns = this.displayedColumns6
      // console.log(this.totalPropertyData, 'all applicant getting here')



      for (let index = 0; index < this.totalPropertyData.length; index++) {

        this.totalPropertyData[index]['sr'] = index+1;
      }

      this.forDataforPdf = this.totalPropertyData
      this.dataSource = new MatTableDataSource(this.totalPropertyData)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()
      // this.total_applicant = resp.data[4][0]['total_applicant'];
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }
  goBack(){
    this.showDetail = false
  }
  async totalBissinessDetails() {     // for 8
    this.spinner.show();
    this.cur_page = 0
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    this.showChooseProType= false  // for
    // this.onApplicant= false
    this.Status = 8 //for property cost
    var resp = await this.dashboardService.totalBissinessDetails(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
     // console.log(resp, 'this is called my side')
      this.totalPropertyData= resp.data;
      // console.log(this.totalPropertyData, 'this is called my side')
      this.displayedColumns = this.displayedColumns8
      // console.log(this.totalPropertyData, 'all applicant getting here')
      for (let index = 0; index < this.totalPropertyData.length; index++) {
        this.totalPropertyData[index]['sr'] = index+1;
      }
      this.forDataforPdf = this.totalPropertyData
      this.dataSource = new MatTableDataSource(this.totalPropertyData)
      this.dataSource.sort = this.sort;

      // $('#viewDetails3').modal('show')
      this.showDetail = true
      this.makePageNator()
      // this.total_applicant = resp.data[4][0]['total_applicant'];
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }

  onApplicant= true
  // onApplican
  async getAllApplicant() {  //4 // 2 3 4
    const obj : any = {}
    this.cur_page = 0
    obj['b_acct_id'] = this.b_acct_id;
    // this.displayedColumns = this.displayedColumns3
    // this.onApplicant= false
    this.showChooseProType= true
    this.spinner.show()
    var resp = await this.dashboardService.getAllApplicantData(obj)

    if (resp['error'] == false) {
      this.spinner.hide()
      this.onApplicant = false
      this.Status = 5 // reserve and vacant pro
      this.totalApplicantData = resp.data
      this.displayedColumns = this.displayedColumns3
      // console.log( this.totalApplicantData)

      for (let index = 0; index < this.totalApplicantData.length; index++) {
        this.totalApplicantData[index]['sr'] = index+1;
      }
      this.forDataforPdf = this.totalApplicantData
      // console.log(this.totalApplicantData, 'all applicant getting here')
      this.dataSource = new MatTableDataSource(this.totalApplicantData)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()
      $('#viewDetails3').modal('show')
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }

  }
  curOffer: any
  availbleProType: any=[]
  currProFilterByArc: any=[]
  currProFilterByArcAndProType: any=[]
  filtByOffer(){
   let temp = []
   this.curProType = undefined
   let tempProType: any={};
   let tempAllData: any
   if(this.onApplicant){
     tempAllData = this.totalPropertyData
   }else{
     tempAllData = this.totalApplicantData
   }
  //  console.log(this.curOffer);
   if(this.curOffer){
     for (let index = 0; index < tempAllData.length; index++) {
      // console.log(tempAllData[index].offer_cd)
      if(tempAllData[index].offer_cd==this.curOffer){
        tempProType[tempAllData[index].property_type]=1
        temp.push(tempAllData[index])
      }
     }
    //  console.log(this.curOffer,temp)
     this.availbleProType = Object.keys(tempProType).map((ele)=>{
      return {property_type:ele}
     })
     // console.log(this.availbleProType)
     for (let index = 0; index < temp.length; index++) {
       temp[index]['sr'] = index+1;
     }
      this.currProFilterByArc = temp
      this.dataSource = new MatTableDataSource(temp)
      this.forDataforPdf = this.currProFilterByArc
    }else{
      //console.log(tempAllData)
      this.forDataforPdf = tempAllData
      this.dataSource = new MatTableDataSource(tempAllData)
      for (let index = 0; index < tempAllData.length; index++) {
        tempAllData[index]['sr'] = index+1;
      }
      this.forDataforPdf = tempAllData

   }

   this.dataSource.sort = this.sort;

  }
  curProType: any
  filtByOfferAndType(){
   let temp = []
   if(this.curProType){
     for (let index = 0; index < this.currProFilterByArc.length; index++) {
      console.log(this.currProFilterByArc[index].offer_cd)
      if(this.currProFilterByArc[index].property_type==this.curProType){
        temp.push(this.currProFilterByArc[index])
      }
     }
     this.currProFilterByArcAndProType = temp
     this.forDataforPdf = temp
     this.dataSource = new MatTableDataSource(temp)
   }else{
    this.forDataforPdf = this.currProFilterByArc
    this.dataSource = new MatTableDataSource(this.currProFilterByArc)
   }
   this.dataSource.sort = this.sort;

  }

  async  getAllOffer(){

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['life_cycle_status'] = 'ACTIVE'
    var resp = await this.sale_service.getAllOffers(obj);
   // console.log(resp)
    if (resp['error'] == false) {
      this.allOffer = resp['data']
     // console.log(this.allOffer,'allOffer')
      for(var i=0;i<this.allOffer.length;i++){
       this.total_offered_property=this.total_offered_property+Number(this.allOffer[i]['sum(pro.no_of_pro)'])

    }
       // console.log(this.total_offered_property);
    }
    this.total_offered=this.allOffer.length;
  }

  async getAllotmentInfo() {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.getAllotmentInYears(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allArrAllotment = resp.data;
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }

  async getSubSchemeInfo() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.getSubSchemeInYears(obj);
    if (resp['error'] == false) {
      this.allArrSubScheme = resp.data;
    } else {
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }
  }
  // buildAllotmentChart() {
  //   var dp = [];
  //   var dpTempObj = {};
  //   for (var i = 0; i < this.allArrAllotment.length; i++) {
  //     if (dpTempObj[this.allArrAllotment[i].grade_pay_code] == undefined) {
  //       dpTempObj[this.allArrAllotment[i].grade_pay_code] = 1;
  //     } else {
  //       dpTempObj[this.allArrAllotment[i].grade_pay_code]++;
  //     }
  //   }
  //   var keys = Object.keys(dpTempObj)
  //   for (var i = 0; i < keys.length; i++) {
  //     dp.push({ y: dpTempObj[keys[i]], label: keys[i] })
  //   }
  //   let chart = new CanvasJS.Chart("chartContainer", {
  //     animationEnabled: true,
  //     exportEnabled: true,
  //     title: {
  //       text: "Allotment In Years"
  //     },
  //     data: [{
  //       type: "column",
  //       dataPoints: dp
  //     }]
  //   });

  //   chart.render();
  // }
  // buildSubSchemeChart() {
  //   var dp = [];
  //   var dpTempObj = {};
  //   for (var i = 0; i < this.allArrSubScheme.length; i++) {
  //     if (dpTempObj[this.allArrSubScheme[i].class_code] == undefined) {
  //       dpTempObj[this.allArrSubScheme[i].class_code] = 1;
  //     } else {
  //       dpTempObj[this.allArrSubScheme[i].class_code]++;
  //     }
  //   }
  //   var keys = Object.keys(dpTempObj)
  //   for (var i = 0; i < keys.length; i++) {
  //     dp.push({ y: dpTempObj[keys[i]], label: keys[i] })
  //   }
  //   let chart = new CanvasJS.Chart("chartContainer1", {
  //     animationEnabled: true,
  //     exportEnabled: true,
  //     title: {
  //       text: "Sub Scheme In Years"
  //     },
  //     data: [{
  //       type: "column",
  //       dataPoints: dp
  //     }]
  //   });

  //   chart.render();
  // }





  // for image
  fileURL: any
  fileURL2: any;
  list = [];
  async getAllotmentLetterData() {
    let dummy: any;
    let obj : any= {};
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.dashboardService.getAllAllotmentLetterData(JSON.stringify(obj));
    //console.log(resp)
    if (resp["error"] == false) {
      dummy = resp["data"];
      this.list = dummy;
      //console.log(this.list)
      if (this.list.length > 0) {
        this.fileURL = await this.view_doc(this.list[0]["img1_name"]);
        this.fileURL2 = await this.view_doc(this.list[0]["img2_name"]);
        // console.log(this.fileURL,'lllllllllllllllllllll')
      }
    } else {
      Swal.fire("Error", "Server side errror ", "error");
    }
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async view_doc(doc_name: any) {
    var obj: any = new Object();
    obj["doc_name"] = doc_name;
    obj["b_acct_id"] = this.b_acct_id;
    // console.log(obj, doc_name)
    const res = await this.dashboardService.getUploadedFileofparty(obj);
    // console.log(res)
    if (res) {
      return this.blobToBase64(res);
    } else {
    }

    return
  }

  async view_doc2(Obj: any) {
    console.log(Obj, 'Obj')
    if (Obj['doc_id']) {
      this.spinner.show();
      let obj: any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['doc_id'] = Obj['doc_id']
      obj['doc_name'] = Obj['doc_name']
      console.log(obj)
      var res = await this.sale_service.view_Doc(obj);
      //console.log(res)
      if (res) {
        var docname = Obj['doc_name']
        ////console.log(this.element, 'this.element')
        var ext = docname.split('.');
        let a = document.createElement('a'), file;
        if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg' || ext[1].toLowerCase() == '') {
          const unsafeImageUrl = window.URL.createObjectURL(res);
          this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
          console.log('first')
        } else {
          let file = new Blob([res], { type: 'application/pdf/image' });
          // var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          a.href = window.URL.createObjectURL(file);
          a.download = Obj['doc_name'];
          a.click();
        }
        this.spinner.hide();
      }
    } else {
      Swal.fire('Error...', 'Please Upload The File First', 'error')
    }

  }



  total_property_cost: any
  total_remaining_property: any
  total_reserved_property: any
  total_sold_amount: any
  total_bussiness: any

  onSoldPro = true
  async totalSoldProperties(){
    this.onApplicant= true
    this.showChooseProType = true
    this.Status = 6
    this.onSoldPro = true

    this.curOffer = undefined
    this.curProType = undefined
    this.cur_page = 0
    const obj : any = {}
    this.spinner.show()
    // obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.allSoldProp(this.b_acct_id)
    this.displayedColumns = this.displayedColumns7
    //console.log(this.displayedColumns)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.totalPropertyData = resp.data
      // console.log(this.totalPropertyData, 'all applicant getting here')
      for (let index = 0; index < this.totalPropertyData.length; index++) {
        this.totalPropertyData[index]['sr'] = index+1;
      }
      this.forDataforPdf = this.totalPropertyData
      this.dataSource = new MatTableDataSource(this.totalPropertyData)
      this.dataSource.sort = this.sort;

      this.showDetail = true
      this.makePageNator()
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }

  }
  makePageNator(){
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.paginator.pageIndex=0
    }, 100);
  }
  async allDashboardCount() {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.dashboardService.allDashboardCount(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
    //  console.log(resp, 'this is called my side')
      this.total_reserved_property = resp.data[0][0]['total_reserved_property'];
      this.total_remaining_property = resp.data[1][0]['total_remaining_property'];
      this.total_property_cost = resp.data[2][0]['total_property_cost'];
      this.total_sold_amount = resp.data[3][0]['total_sold_amount'];
      this.total_bussiness = resp.data[4][0]['total_bussiness'];

    // Formate digit in Us Formate

      this.total_reserved_property =  formatNumber(this.total_reserved_property, 'en-US', '1.0-0');
      this.total_remaining_property =  formatNumber(this.total_remaining_property, 'en-US', '1.0-0');
      this.total_property_cost =  formatNumber(this.total_property_cost, 'en-US', '1.0-0');
      this.total_sold_amount =  formatNumber(this.total_sold_amount, 'en-US', '1.0-0');
      this.total_bussiness =  formatNumber(this.total_bussiness, 'en-US', '1.0-0');

      // this.total_boolet_purchase = resp.data[3][0]['total_boolet_purchase'];
      // this.total_applicant = resp.data[4][0]['total_applicant'];
    } else {
      this.spinner.hide()
      this.snackBar.open(resp['data'], 'Error', {
        duration: 5000
      });
    }



  }

  async downloadStatusChange(element: any){
    var obj: any = new Object();
    this.spinner.show()
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element['id']
    console.log(obj, element)
    let resp = await this.dashboardService.downloadStatusChange(obj)
    console.log(resp, 'response')
    if (resp['error'] == false) {
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
    this.getAllApplicant()
  }

  allUploadedDoc = [];
  async viewDocuments(element: any) {
    // this.fileURL = ''
    // this.viewDocumentObj = element
    this.allUploadedDoc = []
    let obj : any= {}
    this.spinner.show()
    obj['b_acct_id'] = this.b_acct_id
    obj['party_id'] = element['party_id']
    console.log(obj, element)
    let resp = await this.sale_service.getAllPartyUploadedDocuments(JSON.stringify(obj))
    console.log(resp, 'response')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allUploadedDoc = resp['data']
      $('#myModal2').modal('show');
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }
  async viewDocumentsforPdf(element : any) {
    // this.fileURL = ''
    // this.viewDocumentObj = element
    this.allUploadedDoc = []
    let obj : any= {}
    this.spinner.show()
    obj['b_acct_id'] = this.b_acct_id
    obj['party_id'] = element['party_id']
    console.log(obj, element)
    let resp = await this.sale_service.getAllPartyUploadedDocuments(JSON.stringify(obj))
    console.log(resp, 'response')
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allUploadedDoc = resp['data']
      // $('#myModal2').modal('show');
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured', 'error')
    }
  }


 //  view party imaGE FOR PDF
imgURL2: any;
imgURL3: any
async viewApplicantFile_photo(element : any) {
  this.spinner.show()
 // console.log(element)
  var obj: any = new Object
  obj['filename'] = element['party_photo_file_name']
  obj['b_acct_id'] = this.b_acct_id
  obj['offer_cd'] = element['offer_cd']
  obj['party_id'] = element['party_id']
 // console.log(obj)
  const res = await this.dashboardService.partyImage(obj);

  if (res) {
    this.spinner.hide();
    return this.blobToBase64(res);
  } else {
  }
  return
}

async viewApplicantFile_signature(element : any) {
  this.spinner.show()
 // console.log(element)
  var obj: any = new Object
  obj['filename'] = element['party_signature_file_name']
  obj['b_acct_id'] = this.b_acct_id
  obj['offer_cd'] = element['offer_cd']
  obj['party_id'] = element['party_id']
 // console.log(obj)
  const res = await this.dashboardService.partyImage(obj);

  if (res) {
    this.spinner.hide();
    return this.blobToBase64(res);
  } else {
  }

  return
}


async PdfPrint(element : any) {
  let obj : any= {};
  obj["party_id"] = element.party_id;
  obj["b_acct_id"] = this.b_acct_id;
  var resp = await this.dashboardService.getApplicationInfo(JSON.stringify(obj));
  await this.viewDocumentsforPdf(element);
  this.imgURL2=await this.viewApplicantFile_photo(element);
  this.imgURL3=await this.viewApplicantFile_signature(element)
  await this.downloadStatusChange(element)
  //console.log(resp, 'this.allUploadedDoc')
  var txt: any = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
  var dd: any = {
    pageSize: "A4",
    header: {
      margin: 8,
      columns: [
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["30%", "40%", "30%"],
            body: [
              [
                {
                  image: this.fileURL,
                  width: 100,
                  height: 60,
                  alignment: "center",
                },
                {
                  text: this.list[0]["header_desc"],
                  alignment: "center",
                  bold: true,
                  margin: 6,
                  fontSize: 12,
                },
                {
                  image: this.fileURL2,
                  width: 100,
                  height: 60,
                  alignment: "center",
                },
              ],
            ],
          },
          layout: "noBorders",
          height: "210",
        },
      ],
    },
    pageOrientation: "portrait",
    pageMargins: [40, 80, 40, 60],
    content: [],
  };

  var headerImge = {
    widths: ["15%","15%","40%", "30%"],
    columns: [
      {

        text: "Registration Date",
        bold: true,
        fontSize: 10,
      },
      {

        text: ": " + this.mainService.changeDate(resp["txn_info"][0]["create_time"]),
        fontSize: 8,
      },
      {
        text: "",
        alignment: "center",
        bold: true,
        margin: 6,
        fontSize: 12,
      },
      {
        image: this.imgURL2,
        width: 100,
        height: 70,
        alignment: "center",
      },
    ],
  };
  var headerSign = {
    widths: ["30%", "40%", "30%"],
    columns: [
      {
        text: "",
        alignment: "center",
        bold: true,
      },
      {
        text: "",
        alignment: "center",
        bold: true,
        margin: 6,
        fontSize: 12,
      },
      {
        image: this.imgURL3,
        width: 100,
        height: 70,
        alignment: "center",
      },
    ],
  };
  var headerName = {

    columns: [
      {
        width: "20%",
        text: "",
        // bold: true,
        alignment: "center",
      },
      {
        width: "30%",
        text: " ",
      },
      {
        width: "30%",
        text: " ",
      },
      {
        width: "20%",
        text: " Applicant Pic",
        alignment: "center",
      },
    ],
  };
  var headerNameSign = {

    columns: [
      {
        width: "20%",
        text: "",
        // bold: true,
        alignment: "center",
      },
      {
        width: "30%",
        text: " ",
      },
      {
        width: "30%",
        text: " ",
      },
      {
        width: "20%",
        text: " Applicant Sign",
        alignment: "center",
        fontSize: 10,
      },
    ],
  };
  var header0 = {
    columns: [
      {
        width: "*",
        text: " Application Receipt ",
        bold: true,
        alignment: "center",
      },
    ],
  };
  var header1_1 = {
    columns: [
      {
        width: "25%",
        text: "Applicant Name",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_name"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Applicant Email",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ":" + resp["party_ifno"][0]["party_email"],
        fontSize: 8,
      },
    ],
  };
  var header1 = {
    columns: [
      {
        width: "25%",
        text: "Property Type",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["arr_info"][0]["property_type"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Property Number",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ":" + element.property_no,
        fontSize: 8,
      },
    ],
  };

  var scheem_arc = {
    columns: [
      {
        width: '25%',
        text: 'Offer Name',
        bold: true,
        fontSize: 10,
      },
      {
        width: '*',
        text: ": " + resp['txn_info'][0]['offer_name'],
        fontSize: 8,
      },


    ],
  }
  var header6 = {
    columns: [
      {
        width: '25%',
        text: 'Phone No',
        bold: true,
        fontSize: 10,
      },
      {
        width: '25%',
        text: ": " + resp['party_ifno'][0]['party_phone_no'],
        fontSize: 8,
      },

      {
        width: '25%',
        text: 'Architecture Plan',
        bold: true,
        fontSize: 10,
      },
      {
        width: '25%',
        text: ":" + resp['txn_info'][0]['architecture_plan'],
        fontSize: 8,
      }

    ],
  }
  var header_pin_parmanenet = {
    columns: [
      {
        width: '25%',
        text: 'Permanent Address',
        bold: true,
        fontSize: 10,
      },
      {
        width: '25%',
        text: ": " + resp['party_ifno'][0]['party_permanent_addr_line'],
        fontSize: 8,
      },
     {
        width: '25%',
       text: 'Permanent  PIN',
       bold: true,
       fontSize: 10,
     },
      {
        width: '25%',
        text: ": " + resp['party_ifno'][0]['party_permanent_addr_pin_code'],
        fontSize: 8,
      }

    ],
  }


  var header_pin = {
    columns: [
      {
        width: '25%',
        text: 'Current Address ',
        bold: true,
        fontSize: 10,
      },
      {
        width: '25%',
        text: ": " + resp['party_ifno'][0]['party_current_addr_line'],
        fontSize: 8,
      },
      {
        width: '25%',
        text: 'Current  PIN ',
        bold: true,
        fontSize: 10,
      },
      {
        width: '25%',
        text: ": " + resp['party_ifno'][0]['party_current_addr_pin_code'],
        fontSize: 8,
      },

    ],
  }
  var header2 = {
    columns: [
      {
        width: "25%",
        text: "Father/Husband Name ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_father_or_husband_name"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Gender ",
        bold: true,
        fontSize: 10,
      },

      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["gender"],
        fontSize: 8,
      },
    ],
  };
  var header3 = {
    columns: [
      {
        width: "25%",
        text: "Category ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + this.quota_obj[resp["party_ifno"][0]["party_quota"]],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Sub-Category ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text:
          ": " + this.subQuota_obj[resp["party_ifno"][0]["party_sub_quota"]],
          fontSize: 8,
      },
    ],
  };
  var header4 = {
    columns: [
      {
        width: "25%",
        text: "Pan Number ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_pan_no"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Adhaar Number ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_adhar_no"],
        fontSize: 8,
      },
    ],
  };
  var header5 = {
    columns: [
      {
        width: "25%",
        text: "Payment Option",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["arr_info"][0]["payment_option"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Annual Income ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_annual_income"],
        fontSize: 8,
      },
    ],
  };

  var header7 = {
    columns: [
      {
        width: "25%",
        text: "Bank Account Number ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_acct_no"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Bank Name",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_bank_name"],
        fontSize: 8,
      },
    ],
  };
  var header8 = {
    columns: [
      {
        width: "25%",
        text: "Bank Branch ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_branch_name"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Bank IFSC Code",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_ifsc_code"],
        fontSize: 8,
      },
    ],
  };
  var header9 = {
    columns: [
      {
        width: "25%",
        text: "Application ID ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + element.party_id,
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Transaction ID",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["txn_info"][0]["txn_id"],
        fontSize: 8,
      },
    ],
  };
  var header_occ = {
    columns: [
      {
        width: "25%",
        text: "Occupation",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["party_ifno"][0]["party_occupation"],
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Date Of Birth ",
        bold: true,
        fontSize: 10,
      },

      {
        width: "25%",
        text:
          ": " +
          this.mainService.changeDate(resp["party_ifno"][0]["party_dob"]),
          fontSize: 8,
      },
    ],
  };

  let status = "Failed";
  if (resp["txn_info"][0]["status"]) {
    status = resp["txn_info"][0]["status"];
  }

  var header10 = {
    columns: [
      {
        width: "25%",
        text: "Transaction Status ",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + status,
        fontSize: 8,
      },
      {
        width: "25%",
        text: "Paid Amount",
        bold: true,
        fontSize: 10,
      },
      {
        width: "25%",
        text: ": " + resp["txn_info"][0]["total_amt"],
        fontSize: 8,
      },
    ],
  };
  var header10_1 = {
    columns: [
      {
        width: "25%",
        text: "Co-Applicant/Nominee :",
        bold: true,
      },
    ],
  };
  var header10_3 = {
    columns: [
      {
        width: "25%",
        text: "Uploaded Documents :",
        bold: true,
      },
    ],
  };
  var header10_2 = {
    columns: [
      {
        width: "*",
        text: "Note : This is a computer generated document .",
        bold: true,
        fontSize: 10,
      },
    ],
  };

  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  dd.content.push(header0);
  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  // dd.content.push(header6);
  // dd.content.push({ text: " " });
  dd.content.push(headerImge);
  // dd.content.push(headerName);

  // dd.content.push({ text: " " });
  // dd.content.push({
  //   canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  // });

  dd.content.push({ text: " " });
  // dd.content.push({
  //   canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  // });
  // dd.content.push({ text: " " });
  dd.content.push(header1_1);
  dd.content.push({ text: " " });
  dd.content.push(header2);
  dd.content.push({ text: " " });
  dd.content.push(header3);
  dd.content.push({ text: " " });
  dd.content.push(header_occ);
  dd.content.push({ text: " " });
  dd.content.push(header4);
  dd.content.push({ text: " " });
  dd.content.push(header5);
  dd.content.push({ text: " " });
  dd.content.push(header_pin);
  dd.content.push({ text: " " });
  dd.content.push(header_pin_parmanenet);
  dd.content.push({ text: " " });
  dd.content.push(header6);
  dd.content.push({ text: " " });
  dd.content.push(header1);
  dd.content.push({ text: " " });
  dd.content.push(scheem_arc);
  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  dd.content.push(header10_1);
  dd.content.push({ text: " " });
  // dd.content.push({
  //   canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  // });
  // dd.content.push({ text: " " });
  var tbl = {
    // layout: 'lightHorizontalLines',
    fontSize: 10,
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*"],
      body: [
        [
          "Applicant Name",
          "Applicant Mobile",
          "Applicant Email",
          "Occupation",
        ],
      ],
    },
  };
  let data = [];
  data = resp["co_app_info"];
  // console.log(data, 'data')
  dd.content.push(tbl);
  for (var i = 0; i < data.length; i++) {
    var arr = [];
   // console.log(data[i])
    arr.push(data[i]["co_applicant_name"]);
    arr.push(data[i]["co_applicant_phone_no"]);
    arr.push(data[i]["co_applicant_email"]);
    arr.push(this.occupation[data[i]["co_applicant_occupation"]]);
    // arr.push('eee');
    dd.content[dd.content.length - 1].table.body.push(arr);
  }
  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  dd.content.push(header10_3);
  dd.content.push({ text: " " });
  // ================
  var tbl = {
    // layout: 'lightHorizontalLines',
    fontSize: 10,
    table: {
      headerRows: 1,
      widths: ["*", "*"],
      body: [["S No.", "Document Name"]],
    },
  };
  let data2 = [];
  data2 = this.allUploadedDoc;
  // console.log(data, 'data')
  // console.log(this.required_doc_obj, this.allUploadedDoc, 'required doc obj')
  dd.content.push(tbl);
  for (var i = 0; i < data2.length; i++) {
    var arr = [];
    arr.push(i + 1);
    if (this.required_doc_obj[data2[i]["doc_type"]] != undefined) {
      arr.push(this.required_doc_obj[data2[i]["doc_type"]]);
    } else {
      arr.push(data2[i]["doc_type"]);
    }
    dd.content[dd.content.length - 1].table.body.push(arr);
  }

  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  dd.content.push(header7);
  dd.content.push({ text: " " });
  dd.content.push(header8);
  dd.content.push({ text: " " });
  dd.content.push(header9);
  dd.content.push({ text: " " });
  dd.content.push(header10);
  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });
  dd.content.push({ text: " " });
  dd.content.push(headerSign);
  dd.content.push(headerNameSign);
  dd.content.push({ text: " " });
  dd.content.push({
    canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }],
  });

  dd.content.push({ text: " " });
  dd.content.push({ text: " " });
  dd.content.push({ text: " " });
  dd.content.push({ text: " " });
  dd.content.push({ text: " " });
  dd.content.push(header10_2);
  pdfMake.createPdf(dd).download("Application Reciept");
}

  async printProperty() {
    this.spinner.show()
    let data = []
    data = this.forDataforPdf
    console.log(data)
    var txt = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'All Properties Detail',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    let filName: any
    if(this.Status==1){
      filName ="Offer-Details"
      header0.columns[0].text="Offer Details"
    }else if(this.Status==2){
      filName ="Offered-Property-Details"
      header0.columns[0].text="Offered Property Details"
    }else if(this.Status==3){
      filName ="Reserved-Property-Details"
      header0.columns[0].text="Reserved Property Details"
    }
    else if(this.Status==4){
      filName = "Vacant-Property-Details"
      header0.columns[0].text="Vacant Property Details"
    }
    else if(this.Status==5){
      filName ="Applicant-Details"
      header0.columns[0].text="Applicant Details"
    }
    else if(this.Status==6){
      filName = "Registration-Amount-Details"
      header0.columns[0].text="Registration Amount Details"
    }
    else if(this.Status==7){
      filName = "Property-Cost-Details"
      header0.columns[0].text="Property Cost Details"
    }
    else if(this.Status==8){
      filName ="Sold-Property-Details"
      header0.columns[0].text="Sold Property Details"
    }


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    // dd.content.push(header1);
    // dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.05 }] });
    var tbl: any = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [],
        body: [
          []
        ],
      }
    };
    //console.log(data)
    let colHead: any;
    let col: any;

    if(this.Status==1){

    }else if(this.Status==2 || this.Status==3 || this.Status==4 ){ //2
      tbl.table.widths = ["5%","25%",'*', '*', '*', '12%']
      colHead= ['Sr No','offer Name', 'Architecture Plan', 'Property Type', 'Property No.','Property Status']
      col =  ['offer_name', 'leaf_value', 'property_type', 'property_no','pro_status']
    }
    else if( this.Status==5){ //3
      tbl.table.widths = ["5%","25%",'*', '*', '*','*','*', '12%']
      colHead= ['Sr No', 'Offer Name','Architecture Plan','Party Name','Phone Number','Property Type','Property no.','Status']
      col = ['offer_name', 'leaf_value', 'party_name', 'party_phone_no', 'property_type', 'property_no', 'form_status'];
    }
    else if( this.Status==6){ //3
      tbl.table.widths = ["5%","25%",'*', '*', '*','*','*', '12%']
      colHead= ['Sr No','offer Name', 'Architecture Plan','Party Name','Phone Number', 'Property no.', 'Property Type','Registration Amount(₹)']
      col =  ['offer_name', 'leaf_value','party_name','party_phone_no', 'property_no', 'property_type','registration_amt'];
    }
    else if( this.Status==7){ //3
      tbl.table.widths = ["5%","25%",'*','*', '*', '*', '12%']
      colHead= ['Sr No','offer Name', 'Architecture Plan', 'Property Type','Property Rate(₹)', 'Property No.','Total Property Cost(₹)']
      col =  ['offer_name','leaf_value','property_type','pro_rate','no_of_pro','total_property_cost'];
    }
    else if(this.Status==8){ //4
      tbl.table.widths = ["5%","25%",'*','*','*','*', '12%']
      colHead= ['Sr No','offer Name', 'Architecture Plan','Party Type','Property Rate(₹)', 'Property no.', 'Area(SQM)']
      col =  ['offer_name', 'leaf_value', 'property_type','pro_rate','property_no','area'];
      // 5 is not used for print
    }


    for (let index = 0; index < colHead.length; index++) {
      // giving the table column name
      tbl.table.body[0].push({text: colHead[index], bold:true})
    }
    //console.log(tbl, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {  //  ittrating the all the rows in data
      var arr = []  //creating the empty array
      arr.push(i+1);  // putting index into it
      for (let index = 0; index < col.length; index++) {  // getting all row key
        let value = col[index];    // getting values through key
        arr.push(data[i][value]);
      }
      dd.content[dd.content.length - 1].table.body.push(arr);
    }

    // for adding total

    let sum =0
    if(this.Status==6){
      for (let index = 0; index < data.length; index++) {
        sum += parseInt(data[index]['registration_amt']);
      }
      // console.log(arr)
      dd.content.push({ text: " " });
       dd.content.push({ text: `Total Amount: ${sum}₹`,bold: true,alignment: 'right'});
    }
    else if(this.Status==7){
      for (let index = 0; index < data.length; index++) {
        sum += parseInt(data[index]['total_property_cost']);
      }
      // console.log(arr)
      dd.content.push({ text: " " });
       dd.content.push({ text: `Total Propert Cost: ${sum}₹`,bold: true,alignment: 'right'});

      }
      else if(this.Status==8){
      for (let index = 0; index < data.length; index++) {
        sum += parseInt(data[index]['pro_rate']);
      }
      // console.log(arr)
      dd.content.push({ text: " " });
       dd.content.push({ text: `Total sold property amount: ${sum}₹`,bold: true,alignment: 'right'});
    }

    dd.content.push({ text: " " });
    dd.content.push({ text: " " });


    dd.content.push(header9);
    this.spinner.hide()

    pdfMake.createPdf(dd).download(filName);
  }


  //workflow*********************************************//
  apprflag = false;
  level_for_approval: any = []
  workflow_level1: any = {}
  async getWorkflowlog() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'PLANLAYOUT';
    this.apprflag=false;
    var resp = await this.portalmain.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      let currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
          this.apprflag = true
        }

      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }


  undertakings: any
  salBill_apprdata: any
  async Appr(element : any) {
    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['party_id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'APPLICATION'//this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['project_cd']
    this.salBill_apprdata['module_cd'] = 'PROPERTY';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'APPLICATION', 'PROPERTY');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#myModal3').modal('show');
  }
  message_flag = false
  async getle(user_id: any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'],resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile: any,user_name: any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'] , this.salBill_apprdata['doc_local_no'],user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }


  // async sendmessage(mobile) {
  //   console.log(mobile, 'Application  of' + ' Number ' + this.salBill_apprdata['party_id'])
  //   let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'] + ' Number ' + this.salBill_apprdata['party_id'])
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     Swal.fire("Error", resp['data'], 'error');
  //   }
  // }
  forwarded_user_id: any;
  appr_remark: any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();
      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'APPLICATION'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'UNDERAPPROVAL')
        await this.getAllApplicant()
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      // this.toastr.errorToastr("Please Select Any User..!")
      this.spinner.hide();
    }
  }

  async ChangestatusSalBill(data: any, status: any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp = await this.dashboardService.changeApplicationStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'APPLICATION'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.successToastr("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }





  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  statusArr: any = [];
  selectedEle: any;
  userRoles: any = []

  async viewStatus(element : any) {
    this.selectedEle = Object.assign({}, element);
    var obj: any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'APPLICATION';
    obj['module_cd'] = 'PROPERTY'
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'APPLICATION'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'PROPERTY';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr = resp.data
      console.log(this.statusArr);
      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  selectedTender = {}
  workObj = {}
}
