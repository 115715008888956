import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/offers";
  }

  async getGroupPlanLayoutProperty(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/getAllfreepropertyGroupData' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getrefunds(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getrefunds' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllOffers(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/offers/getofferData' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllAuctionOffers(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/offers/getAllAuctionOffers' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async auction_view_Doc(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/auction/viewDocument', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getAllPropOffers(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getAllOffProperty' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createOffer(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/offers/createoffer', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async creteAuction(obj:any){
    console.log(obj)
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/auction/createAuction', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateAuction(obj:any){
    console.log(obj)
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/auction/updateAuction', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createEvent(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/account/event/addevent', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createEventHierarchy(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/hierarchy/addModulesHierarchy', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async sendToAccount(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/eventgroup/createevent', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteOffer(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/offers/inactiveOffer', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async changeOfferStatus(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/offers/changeStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async changeAuctionStatus(obj:any){
    console.log("i am in ")
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/auction/changeStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllPartyUploadedDocuments(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getPartyUplodedData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async view_Doc(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/PropertyDoc/viewDocument', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getAllRequiredDocumentsForUpload(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getOfferDocData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllUsedAccount(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getGatewayOffer' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllPropertyByOffer(obj:any){

    const resp = await this.http.post<any>(this.main.httpUrl + '/property/offers/getofferPro' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllPropertyByAuction(obj:any){

    const resp = await this.http.post<any>(this.main.httpUrl + '/property/offers/getAuctionPro' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAuctionDetail(obj:any){

    const resp = await this.http.post<any>(this.main.httpUrl + '/property/auction/auctionDetail' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllSubsidyData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getofferSubsidy' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getCostingByOffer(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getCostingByOffer' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateOffer(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/property/offers/updateoffer', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllAuctionUser(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/interface/property/auction/getauctionPartyInfo' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async getAllPropertyOffers(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getAllofferProperty' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }
  // samarjeet
  async getAllPaymentOption(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getOfferPaymentData' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp

  }
  async getAllCastingData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getAllCostingData' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp

  }

}
