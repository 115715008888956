<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- EMB DATA ASSIGNMENT -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA1']}}
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" (click)="reset()" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>&nbsp;
                                <!-- LIST -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA2']}}
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" (click)="reset()" data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Hierarchy Node Assignment --> &nbsp;{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA3']}}
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-4" data-toggle="tab"><i class="ti-settings"></i> Update
                                User Role
                            </a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <!-- <div class="row pb-2" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-2" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA15']}}: <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-2" *ngIf="i!=0"></div>
                                <div class="col-1">
                                  <span *ngIf="item['level']">  Level - </span>{{item['level']}}
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="node_cd" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA15']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    
                                </div>
                               
                                
                                <div class="col-1" *ngIf="i==planing_arr.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-4" style="text-align: center;"><button class="btn btn-outline-success" (click)=find()>Search</button></div>
                                <div class="col-4"></div>
                            </div>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="example-container">
                                    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort>


                                        <ng-container matColumnDef="user_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                <b>
                                                    Employee Name
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.user_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_cd">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>
                                                Node Type
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element">
                                                {{element.node_cd}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Node Level
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.level}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Roles">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Role Name
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.roles}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_path">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Node Path
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.path}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_desc">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                <b>
                                                    <!-- Node Description -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA5']}}
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.node_desc}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    <!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA8']}}
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                <button class="btn btn-primary" *ngIf="element.status=='ACTIVE'" (click)="inactive(element)">
                                                    <!-- INACTIVE -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA9']}}
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>



                        <div class="tab-pane fade" id="tab-7-2">

                            <br>

                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">
                                    <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-6">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Node User -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA11']}} : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="users" [multiple]="false" bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA12']}}" name="user_id"
                                        [(ngModel)]="Obj['user_id']">
                                    </ng-select>
                                    <br>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">
                                        <!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-EMB42']}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-4">
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Role Code" class="form-control" [(ngModel)]="Obj['role_cd']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Name : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Name" class="form-control" [(ngModel)]="Obj['role_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['role_desc']" placeholder="Enter Role Description" class="form-control"></textarea>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Access : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Access " [items]="access" bindLabel="value" bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Access" [closeOnSelect]="false" name="hierarchy" [(ngModel)]="Obj['access']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4" *ngIf="flag == false">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4" *ngIf="flag == true">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">
    <!-- Loading.... -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA14']}}....
</ngx-spinner>

<!-- <div class="modal" id="createRole">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create Role</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Select
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Whats is Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="addNewRole()">Add</button>
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->


<ngx-spinner type="ball-atom">
    <p style="color: white">
        <!-- Loading... -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DATA14']}}....
    </p>
</ngx-spinner>

<!-- <div class="modal" id="updateRole">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Update Role</h4>
            </div>


            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Selected
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b> Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="updateRole()">Update</button>
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div> -->