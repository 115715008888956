<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="col-12 text-right">
                        <h6> {{current_user}}</h6>
                    </div>
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item {{act_list}}">
                            <a class="nav-link" *ngIf="list_head" (click)="list()" href="#tab-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                ITEM LIST
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link {{act_upload}}" *ngIf="upload_fg" (click)="uploadOrder()" href="#tab-2"
                                data-toggle="tab"><i class="fa fa-line-chart"></i>
                                Upload Order
                            </a>
                        </li> -->


                        <!-- Report Sub button  -->

                        <!-- <li class="nav-item active" *ngIf="order_exe">
                            <a class="nav-link " href="#tab-4" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i>
                                Order Execution
                            </a>
                        </li> -->

                        <!-- when click on recovery cal  -->
                        <!-- <li class="nav-item active" *ngIf="update">
                            <a class="nav-link " href="#tab-5" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i>
                                Update Order
                            </a>
                        </li> -->

                    </ul>

                    <div class="tab-content">
                        <div *ngIf="list_fg" class="active" id="tab-1">
                            <div class="tab-content">
                                <br>

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="col-4">
                                    </div>
                                </div>
                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource" matSort>


                                        <ng-container matColumnDef="Sr.No">
                                            <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                                            <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="case_id_f">
                                            <th mat-header-cell *matHeaderCellDef> Case ID</th>
                                            <td mat-cell *matCellDef="let element"> {{element.case_id_f}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="co_ord_id">
                                            <th mat-header-cell *matHeaderCellDef> Order ID </th>
                                            <td mat-cell *matCellDef="let element"> {{element.co_ord_id}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="co_ord_date">
                                            <th mat-header-cell *matHeaderCellDef> Order Date</th>
                                            <td mat-cell *matCellDef="let element"> {{element.co_ord_date}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="co_ord_type">
                                            <th mat-header-cell *matHeaderCellDef>Order Type</th>
                                            <td mat-cell *matCellDef="let element"> {{element.co_ord_type}} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="compliner_name">
                                            <th mat-header-cell *matHeaderCellDef> Opponent Name </th>
                                            <td mat-cell *matCellDef="let element"> {{element.owner_name}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="order_status">
                                            <th mat-header-cell *matHeaderCellDef> Order Status </th>
                                            <td mat-cell *matCellDef="let element"> {{element.order_status}} </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="compliner_name">
                                            <th mat-header-cell *matHeaderCellDef> Opponent Name </th>
                                            <td mat-cell *matCellDef="let element"> {{element.owner_name}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="compliner_phone_no">
                                            <th mat-header-cell *matHeaderCellDef>Opponent Mobile No. </th>
                                            <td mat-cell *matCellDef="let element"> {{element.compliner_phone_no}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="order_status">
                                            <th mat-header-cell *matHeaderCellDef>Order Status </th>
                                            <td mat-cell *matCellDef="let element"> {{element.order_status}} </td>
                                        </ng-container> -->

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef> Action </th>
                                            <td mat-cell *matCellDef="let element" style="width: 20%;">
                                                <button class="btn btn-outline-warning" (click)="viewDetails(element)">View
                                                    Details</button>
                                                <button type="button" class="btn btn-outline-success " (click)="downloadDocument(element)">Download Order
                                                    <!-- getAttachedDocumnet() -->
                                                </button>
                                                <button class="btn btn-outline-primary" *ngIf="element.order_status == 'UNDER_EXECUTION'" (click)="forwordFile(element)">Forword
                                                </button>


                                                <!-- <button class="btn btn-outline-success"
                                                    (click)="supportLetterPopUp()">Support Letters</button> -->
                                                <!-- <button class="btn btn-outline-danger"
                                                    (click)="orderExeFg(element)">Report
                                                    Sub</button> -->

                                                <!-- <button class="btn btn-outline-success" (click)="viewReport()">View  Report </button> -->


                                                <!-- <button class="btn btn-outline-info" *ngIf="element.order_status==='EXECUTION_COMPLETED' ||element.order_status==='SELF_DEMOLITION_DONE' ||element.order_status==='COMPOUNDING_DONE'  " (click)="Appr(element)">Add -->
                                                <button class="btn btn-outline-info" *ngIf="element.order_status==='DEMOLITION_DONE' || element.order_status==='SELF_DEMOLITION_VERIFIED' || element.order_status==='COMPOUNDING_DONE' || element.order_status==='SEALING_DONE' || element.order_status==='UNSEALING_DONE' " (click)="Appr(element)">Add
                                                    Approval</button>
                                                <button class="btn btn-outline-warning" type="button" style="margin-left: 2px; margin-top:2px;" *ngIf="element.order_status == 'UNDERAPPROVAL' || element.order_status=='APPROVED' || element.order_status=='REJECTED' || element.order_status=='REFUNDED'"
                                                    (click)="viewStatus(element)"> View Status </button>
                                                <!-- <button class="btn btn-outline-primary" (click)="downloadOrder()">Download
                                                    Order</button> -->


                                                <!--

                                                <button class="btn btn-outline-warning" (click)="recoveryOrder()">Recovery
                                                    Cal </button> -->

                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5,10, 15,20,50]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>


                            </div>

                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>

<!-- Order Details model  -->
<div class="modal fade" id="orderDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 80%;">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #AED6F1;">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <h6>ORDER DETAILS</h6>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div class="modal-body">
                <div style="border: 2px solid gray; border-radius:10px;padding: 2% 5%;">
                    <div class="row">
                        <div class="col-3">Order ID : </div>
                        <div class="col-3">
                            {{veiw_details['co_ord_id']}}

                        </div>
                        <div class="col-3">Order Date : </div>
                        <div class="col-3">
                            {{veiw_details['co_ord_date']}}
                        </div>

                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3">Order Type : </div>
                        <div class="col-3">
                            {{veiw_details['co_ord_type']}}

                        </div>
                        <!-- <div class="col-3">Type of Construction : </div>
                            <div class="col-3">
                                {{veiw_details['co_ord_type']}}
                            </div> -->

                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3">Order Description : </div>
                        <div class="col-9">{{veiw_details['co_summary']}}</div>
                    </div>
                    <br>

                    <div class="row">
                        <br>
                        <div class="col-5">
                            <hr class="hrline">
                        </div>
                        <div class="col-3 text-center">
                            <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                Compilation Time Limit
                            </h6>
                        </div>
                        <div class="col-4">
                            <hr class="hrline">
                        </div>
                        <br>
                        <br>
                        <div class="col-3">Date : </div>
                        <div class="col-3">
                            {{veiw_details['comp_time_limit']}}
                        </div>
                        <div class="col-3">Day : </div>
                        <div class="col-3">
                            {{veiw_details['comp_day_limit']}}
                        </div>
                    </div>
                    <br>

                    <div class="row">
                        <br>
                        <div class="col-5">
                            <hr class="hrline">
                        </div>
                        <div class="col-3 text-center">
                            <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                Address of Property
                            </h6>
                        </div>
                        <div class="col-4">
                            <hr class="hrline">
                        </div>
                        <br>
                        <br>
                        <div class="col-3">Property Address : </div>

                        <div class="col-9">
                            {{veiw_details['property_address']}}

                        </div>
                    </div>

                    <br>

                    <div class="row">
                        <div class="col-3">Zone :</div>
                        <div class="col-3">
                            {{veiw_details['leaf_value']}}
                        </div>
                        <div class="col-3">District : </div>
                        <div class="col-3">
                            {{veiw_details['dist']}}
                        </div>
                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3">State : </div>
                        <div class="col-3">
                            {{veiw_details['state']}}
                        </div>

                        <div class="col-3">Country : </div>
                        <div class="col-3">
                            {{veiw_details['country']}}
                        </div>
                    </div>
                    <br>

                    <div class="row">
                        <div class="col-3">Landmark : </div>
                        <div class="col-9">
                            {{veiw_details['landmark']}}
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <br>
                        <div class="col-5">
                            <hr class="hrline">
                        </div>
                        <div class="col-3 text-center">
                            <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                Opponent Party Details :
                            </h6>
                        </div>
                        <div class="col-4">
                            <hr class="hrline">
                        </div>
                        <br>
                        <br>

                        <div class="col-3">
                            Name :
                        </div>

                        <div class="col-3">
                            {{veiw_details['owner_name']}}
                        </div>
                        <div class="col-3">Phone No. : </div>
                        <div class="col-3">
                            {{veiw_details['owner_mobile']}}
                        </div>
                    </div>
                    <br>
                    <!-- <div class="row">
                            <div class="col-3">Phone/Mobile No. : </div>
                            <div class="col-3">
                                {{veiw_details['compliner_phone_no']}}
                            </div>
                             <div class="col-3">Email : </div>
                            <div class="col-3">
                                {{veiw_details['compliner_email']}}
                            </div>
                        </div>
                        <br> -->


                    <!-- <div class="row">
                            <div class="col-3">Address : </div>
                            <div class="col-9">
                                {{veiw_details['property_address']}}
                            </div>
                        </div> -->
                    <br>

                    <div class="row">
                        <div class="col-3">
                            <h6>Order Status : </h6>
                        </div>
                        <div class="col-3">
                            {{veiw_details['order_status']}}
                        </div>

                    </div>
                    <br>
                 <div *ngIf="veiw_details['order_status'] == 'EXECUTION_COMPLETED' ">
                        <br>
                        <div class="row">
                            <div class="col-5">
                                <hr class="hrline">
                            </div>
                            <div class="col-3 text-center">
                                <h6 id="h" style="color: brown;min-height: 1px;margin: 6px 1px;text-align: left;">
                                    EXECUTION DETAILS :
                                </h6>
                                <!-- {{execution_data|json}} -->
                            </div>
                            <div class="col-4">
                                <hr class="hrline">
                            </div>
                        </div>
                        <br>



                        <div class="row">
                            <div class="clo-3">
                                <h6>Before Execution : </h6>
                            </div>
                            <div class="col-9"></div>
                        </div>
                        <br>
                        <div class="row" *ngFor="let item of before_exe">
                            <div class="col-2">
                                Longitude :
                            </div>
                            <div class="col-2">
                                {{item['longitude']}}
                            </div>
                            <div class="col-2">
                                Latitude :
                            </div>
                            <div class="col-2">
                                {{item['latitude']}}
                            </div>
                            <div class="col-2">
                                <button class="btn btn-outline-success" (click)="downloadexecutionImage(item)">View
                                        Image</button>
                            </div>
                            <div class="col-3">
                                Description :
                            </div>
                            <div class="col-9">
                                {{item['description']}}
                            </div>
                            <br><br><br>
                        </div>


                        <br>
                        <div class="row">
                            <div class="clo-3">
                                <h6>After Execution : </h6>
                            </div>
                            <div class="col-9"></div>
                        </div>
                        <br>
                        <div class="row" *ngFor="let item of after_exe">
                            <div class="col-2">
                                Longitude:
                            </div>
                            <div class="col-2">
                                {{item['longitude']}}
                            </div>
                            <div class="col-2">
                                Latitude :
                            </div>
                            <div class="col-2">
                                {{item['latitude']}}
                            </div>
                            <div class="col-2">
                                <button class="btn btn-outline-success" (click)="downloadexecutionImage(item)">View
                                        Image</button>
                            </div>
                            <div class="col-3">
                                Description :
                            </div>
                            <div class="col-9">
                                {{item['description']}}
                            </div>
                            <br>
                            <br><br>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <div class="row m-auto">
                        <div class="col-12 text-center">

                            <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview model  -->
<div class="modal fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog exemodel-dialog modal-dialog-centered" role="document">
        <!-- style="max-width: 30%;" -->
        <div class="modal-content execontent">
            <div class="modal-header exeheader">
                <h6>Image Preview</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-icon">
                    <span aria-hidden="true" (click)="reOpenMOdel()">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div>
                    <img [src]="executionImage" class="exeImage" />

                    <!-- <div class="modal-footer">
                        <div class="row m-auto">
                            <div class="col-12 text-center">
                                <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

                            </div>
                        </div>

                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>



<!-- Forword Modal -->
<div class="modal fade" id="forword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:70%; ">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #AED6F1;">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <h6>FORWORD ASSIGNED ORDER</h6>
                </h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div style="border: 2px solid gray; border-radius:10px;">
                    <br>
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3"><b>Order ID : </b> </div>
                        <div class="col-3">
                            <input type="text" class="form-control" [(ngModel)]="forwardData['co_ord_id']" disabled>
                        </div>
                        <div class="col-3"></div>
                    </div>
                    <br>
                    <!-- <div class="row">
                  <div class="col-3"></div>
                  <div class="col-3"> Select User : <span class="text-danger">*</span> </div>
                  <div class="col-3">
                      <ng-select [items]="allSearchableEmp" bindLabel="desc" bindValue="user_id"
                          [multiple]="false" placeholder="Select User" [(ngModel)]="forwardData['user_id']"
                          [selectableGroup]="true" [selectableGroupAsModel]="true" name="chart_of_account0">res
                      </ng-select>


                  </div>
                  <div class="col-3"></div>
              </div> -->
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3"><b>Select User :</b></div>

                        <div class="col-3" *ngIf="Inzone">
                            <ng-select [items]="userbyzone" [multiple]="false" placeholder="Select User of {{forwardData.leaf_value}}" bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy" [(ngModel)]="forwardData['user_id']">
                            </ng-select>

                        </div>


                        <div class="col-3" *ngIf="OuterZone">
                            <ng-select [items]="userbynotzone" [multiple]="false" placeholder="Select User from other zone" bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy" [(ngModel)]="forwardData['user_id']">
                            </ng-select>
                        </div>
                        <div class="col-3">
                            <input class=" pt-1" type="checkbox" (click)='sameAddress()' maxlength="50" value=""> &nbsp;select user from other zone
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3"> <b> Note :</b> </div>
                        <div class="col-3"> <textarea class="form-control" placeholder="Write Your Note" [(ngModel)]="forwardData['desc']" id="floatingTextarea"></textarea></div>
                        <div class="col-3"></div>
                    </div>
                    <br>

                </div>
                <div class="modal-footer">
                    <div class="row m-auto">
                        <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button> &nbsp;&nbsp;
                        <button type="button" class="btn btn-outline-primary" (click)="submitForword()">Forword</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Preview model  -->
<div class="modal fade" id="preview" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 20%; max-width: 60%;">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #AED6F1;">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <h6 *ngIf="identity=='before'">Preview Photo Before Execution</h6>
                    <h6 *ngIf="identity=='after'">Preview Photo After Execution</h6>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-auto">
                <div style="border: 2px solid gray; border-radius:10px;padding: 2% 5%;display: contents;">

                    <div *ngIf="identity=='before'">
                        <img *ngFor='let url of imgURLBefore' [src]="url" width="300" style="    margin: 0px -44px 17px 58px;max-height: 312px;min-height: 312px;"> <br />
                    </div>
                    <div *ngIf="identity=='after'">
                        <img *ngFor='let url of imgURLAfter' [src]="url" width="300" style="    margin: 0px -44px 17px 58px; max-height: 312px;min-height: 312px;"> <br />
                    </div>

                    <div class="modal-footer">
                        <div class="row m-auto">
                            <div class="col-12 text-center">
                                <!-- <button type="button" class="btn btn-outline-success "
                   (click)="downloadReport(veiw_details)">Download</button>
               &nbsp; &nbsp; -->
                                <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- WORKFLOW MODEL -->
<div class="modal fade" id="workflowmodel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                {{ApprovalUserService.vendorflag}}
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr" [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark" placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">

                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>

                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- VIEW WORKFLOW STATUS -->

<div class="modal" id="workflowStatus">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    <!-- Status -->Approval
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr1;let i=index">
                        <!-- <h6>{{ob['status']}}</h6> -->
                        <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr1.length-1"></div>
                            </div>

                            <div class="content">
                                {{statusArr1.length-i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}} ({{ob.user_name}})

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">Close</span>
                    </button>


                </div>

            </div>
        </div>
    </div>
</div>


<!-- -------------------------------------------------------Unsued Code ------------------------------ -->

<!-- Shao Attached Document -->
<!-- <div class="modal fade" id="attachedDoc" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title m-auto" id="staticBackdropLabel">Documents</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">

                <div class="tab-content">
                    <br>

                    <div class="row">
                        <div class="col-4">
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-4">
                        </div>
                    </div>
                    <div class="example-container">
                        <table mat-table [dataSource]="attachedDocDatasource" matSort>


                            <ng-container matColumnDef="Sr.No">
                                <th mat-header-cell *matHeaderCellDef> Sr No. </th>
                                <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                            </ng-container>

                            <ng-container matColumnDef="doc_id">
                                <th mat-header-cell *matHeaderCellDef> Document ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.doc_id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="case_id">
                                <th mat-header-cell *matHeaderCellDef>Case ID</th>
                                <td mat-cell *matCellDef="let element"> {{element.case_id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="document_type_code">
                                <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                <td mat-cell *matCellDef="let element"> {{element.document_type_code}} </td>
                            </ng-container>





                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element" style="width: 20%;">
                                    <button class="btn btn-outline-warning" (click)="downloadDocument(element)">Download
                                    </button>

                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="attachedDocCoulmns"></tr>
                            <tr mat-row *matRowDef="let row; columns: attachedDocCoulmns;">
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5,8]" showFirstLastButtons>
                        </mat-paginator>
                    </div>


                </div>





            </div>
            <div class="modal-footer">
                <div class="row m-auto">
                    <button type="button" class="btn btn-outline-danger " data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
