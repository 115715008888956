<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Receipt And Payment Account</div>
                </div>
                <div class="ibox-body">

                    <div class="tab-pane fade show active" id="tab-1">
                        <br>
                        <form name="form" (ngSubmit)="f.form.valid && getReceiptPaymnet()" #f="ngForm">

                            <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                <div class="col-4 text-left">
                                    <h6>From Date : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">

                                    <input type="date" class="form-control" [(ngModel)]="mainObj['from_dt']"
                                        name="from_dt" #from_dt="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && from_dt.invalid }" required>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="from_dt.dirty && from_dt.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && from_dt.invalid" class="error-message">
                                        <div *ngIf="from_dt.errors?.['required']">Please Fill From Date </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->
                                </div>
                            </div><br>
                            <div class="row" style="margin-left: 2%;margin-right: 2%;">
                                <div class="col-4 text-left">
                                    <h6>To Date : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="mainObj['to_dt']" name="to_dt"
                                        #to_dt="ngModel" [ngClass]="{ 'is-invalid': f.submitted && to_dt.invalid }"
                                        required>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.border_green]="to_dt.dirty && to_dt.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && to_dt.invalid" class="error-message">
                                        <div *ngIf="to_dt.errors?.['required']">Please Fill To Date </div>
                                    </div>


                                      <!-- If from date is greater than to date  -->
                                      <div *ngIf="from_dt.value > to_dt.value" class="error-message">
                                        Please Select Correct Date
                                    </div>
                                    <!--------------------------------------------- validation End ------------------- -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <!-- <button class="btn btn-primary" (click)="getReceiptPaymnet()">Submit</button> -->
                                    <button class="btn btn-primary" type="submit">Submit</button>
                                </div>

                            </div>
                        </form>
                        <br>
                        <hr>

                        <div class="row">

                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print()">Print</button> &nbsp;&nbsp;

                                <button class="btn btn-success" (click)="export()">Export</button> &nbsp;&nbsp;
                            </div>


                        </div>

                        <br>
                        <div id="a">
                            <div class="row">
                                <div class="col-3">
                                    <h6>From :</h6>
                                </div>
                                <div class="col-3">
                                    {{mainObj['from_dt']}}
                                </div>
                                <div class="col-3">
                                    <h6>To Date : </h6>
                                </div>
                                <div class="col-3 text-right">
                                    {{mainObj['to_dt']}}
                                </div>
                            </div>
                            <br>




                            <table class="table table-bordered">
                                <thead>
                                    <tr class="text-center">
                                        <th>CR</th>
                                        <th>DR</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <td>
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Receipt </th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <!-- opening balance start -->
                                                <tr *ngIf="openingBank < 0 ">
                                                    <td colspan="2">
                                                        <h6> To Balance b/d (Opening Balance) </h6>
                                                    </td>

                                                </tr>
                                                <tr *ngIf="openingBank < 0 ">
                                                    <td>
                                                        <h6>Bank : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{openingBank}} </h6>
                                                    </td>
                                                </tr>

                                                <tr *ngIf="openingCash < 0 ">
                                                    <td colspan="2" *ngIf="openingBank > 0 ">
                                                        <h6> To Balance b/d (Opening
                                                            Balance) </h6>
                                                    </td>

                                                <tr *ngIf="openingCash < 0 ">
                                                    <td>
                                                        <h6>Cash : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{openingCash}} </h6>
                                                    </td>

                                                </tr>


                                                <!-- opening balance end  -->


                                                <!-- -------------------------------------Content Section start  ------------------------------------------->
                                                <tr *ngFor="let item of paymentRepDB">
                                                    <td>{{item['jrnl_desc']}}</td>
                                                    <td>{{item['txn_amt'] | number: '1.2-2' }}</td>
                                                </tr>
                                                <!-- --------------------------------------Content Section end  --------------------------------------------->



                                                <!-- closing balance start -->

                                                <tr *ngIf="closingBank >= 0 ">
                                                    <td colspan="2">
                                                        <h6>
                                                            To Balance c/d (Closing Balance)
                                                        </h6>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="closingBank >= 0 ">
                                                    <td>
                                                        <h6>Bank : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{closingBank}} </h6>
                                                    </td>

                                                </tr>

                                                <tr *ngIf="closingCash >= 0 ">
                                                    <td colspan="2" *ngIf="closingBank < 0">
                                                        To Balance c/d (Closing Balance)
                                                    </td>
                                                <tr *ngIf="closingCash >= 0 ">
                                                    <td>
                                                        <h6>Cash : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{closingCash}} </h6>
                                                    </td>
                                                </tr>
                                                <!-- closing balance end  -->
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>

                                        <!------------------------------------------------CR Side ------------------------------------------------------  -->

                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Payment </th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <!-- opening balance start -->
                                                <tr *ngIf="openingBank >= 0 ">
                                                    <td colspan="2">
                                                        <h6> To Balance b/d (Opening Balance) </h6>
                                                    </td>

                                                </tr>
                                                <tr *ngIf="openingBank >= 0 ">
                                                    <td>
                                                        <h6>Bank : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{openingBank}} </h6>
                                                    </td>
                                                </tr>

                                                <tr *ngIf="openingCash >= 0 ">
                                                    <td colspan="2" *ngIf="openingBank < 0">
                                                        <h6> To Balance b/d (Opening
                                                            Balance)</h6>
                                                    </td>

                                                <tr *ngIf="openingCash >= 0 ">
                                                    <td>
                                                        <h6>Cash : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{openingCash}} </h6>
                                                    </td>

                                                </tr>



                                                <!-- opening balance end  -->



                                                <tr *ngFor="let item of paymentRepCR">
                                                    <td>{{item['jrnl_desc']}}</td>
                                                    <td>{{item['txn_amt'] | number: '1.2-2' }}</td>
                                                </tr>



                                                <!-- closing balance start -->
                                                <tr *ngIf="closingBank < 0 ">
                                                    <td colspan="2">
                                                        <h6>
                                                            To Balance c/d (Closing Balance)
                                                        </h6>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="closingBank < 0 ">
                                                    <td>
                                                        <h6>Bank : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{closingBank}} </h6>
                                                    </td>

                                                </tr>

                                                <tr *ngIf="closingCash < 0 ">
                                                    <td colspan="2" *ngIf="closingBank > 0">
                                                        To Balance c/d (Closing Balance)
                                                    </td>
                                                <tr *ngIf="closingCash < 0 ">
                                                    <td>
                                                        <h6>Cash : </h6>
                                                    </td>
                                                    <td>
                                                        <h6>{{closingCash}} </h6>
                                                    </td>
                                                </tr>
                                                <!-- closing balance end  -->

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <!-- Total amount start  -->
                                <tr>
                                    <td>
                                        <table class="table table-bordered">
                                            <td>
                                                <h6>Total : </h6>
                                            </td>
                                            <td>
                                                <h6> {{tot_db}}</h6>
                                            </td>
                                        </table>

                                    </td>
                                    <td>
                                        <table class="table table-bordered">
                                            <td>
                                                <h6>Total : </h6>
                                            </td>
                                            <td>
                                                <h6> {{tot_cr}}</h6>
                                            </td>
                                        </table>

                                    </td>
                                </tr>
                                <!-- total amount end  -->



                            </table>






                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
