import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import { AuthenticationService } from '../../../authentication.service';
import { MatStepper } from '@angular/material/stepper';
import { LocationService } from '../../service/location.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PropCommonSService } from '../../service/prop-common-s.service';
declare var $: any;
@Component({
  selector: 'app-land-input',
  templateUrl: './land-input.component.html',
  styleUrls: ['./land-input.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class LandInputComponent implements OnInit {

  datasource: any;
  isLinear: any = false;
  first: any = true;
  second : any= true;
  radio: any = true;
  displayedColumns = ['group_id', 'hier_ident_code', 'target_name', 'life_cycle_status', 'total_area', 'unit', 'action'];
  GISArr: any = []
  third: any = true;
  fourth: any = true;
  work_id: any;
  BoqTable: any = [];
  total_sum: any = 0;
  Obj : any= {}
  docArr: any = [{}]
  enableStep3: any;
  b_acct_id: any;
  ebillUser: any;
  planing_arr : any= [{}]
  acp_arr: any = [{}]
  outer_acq_row : any= [{
    'level1': [],
    location: [{}],
    data: [{}]
  }]
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  constructor(private spinner: NgxSpinnerService, private propHierS: PropHierarchyService, private commonS: PropCommonSService, private locationS: LocationService, public auth: AuthenticationService, public mainService: MainService, private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }
  allProjects = []
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    // await this.getHierarchynodes()
    await this.getLocationList()
    // await this.getAllHeirarchy()
    await this.getAllLandInput()
    await this.getAllHier()
    await this.getAllLevls2()
    this.Obj = {}
    this.Obj['total_area'] = 0
    this.changeList();
    await this.getAllLevls2()

  }
  async getAllLevls2() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
    } else {

    }
    console.log(this.hier_node_obj)
  }
  differentHierNode : any= {}
  async getHierarchynodes() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    ////////console.log(obj)
    var resp = await this.hierarchyS.getHierarchynodes(JSON.stringify(obj));
    //////console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      let data = resp['data']
      for (let i = 0; i < data.length; i++) {
        if (this.differentHierNode[data[i]['hier_ident_code']] == null || this.differentHierNode[data[i]['hier_ident_code']] == undefined) {
          this.differentHierNode[data[i]['hier_ident_code']] = []
          this.differentHierNode[data[i]['hier_ident_code']].push(data[i])
        } else {
          this.differentHierNode[data[i]['hier_ident_code']].push(data[i])
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  locationToType: any = {}
  all_locations : any= []
  async getLocationList() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.locationS.getLocationData(JSON.stringify(obj));
    //////console.log(resp, 'list location')
    if (resp['error'] == false) {
      this.all_locations = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        this.locationToType[resp['data'][i]['loc_id']] = resp['data'][i]
      }
      //////console.log(this.locationToType)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  remove() {
    if (this.outer_acq_row.length > 1) {
      this.outer_acq_row.pop()
    }
    this.setRowData('data')
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  reset() {
    // this.stepper1.selectedIndex = 0
    // this.stepper2.selectedIndex = 0
    this.Obj = {}
    this.outer_acq_row = []
    this.planing_arr = []
    this.planing_arr = [{}]
    this.acp_arr = [{}]
    this.outer_acq_row = [{
      'level1': [],
      location: [{}]
      , data: [{}]
    }]
    this.done_Table = []
    //////console.log(this.planing_arr, this.outer_acq_row, this.Obj)
  }
  existing_heirarchy: any = []
  planaing_hierarchy: any = []
  acquisition_hierarchy: any = []
  async getAllHeirarchy() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.hierarchyS.getHierarchyData(JSON.stringify(obj));
    ////////console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy = resp['data']
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        this.existing_heirarchy[i]['desc'] = this.existing_heirarchy[i]['hier_ident_code'] + ' - ' + this.existing_heirarchy[i]['hier_buss_name']
      }
      let acq = []
      let planing = []
      for (let i = 0; i < this.existing_heirarchy.length; i++) {
        if (this.existing_heirarchy[i]['hier_type'] == "Acquisition") {
          acq.push(this.existing_heirarchy[i])
        }
        if (this.existing_heirarchy[i]['hier_type'] == "Planing") {
          planing.push(this.existing_heirarchy[i])
        }
      }
      this.planaing_hierarchy = planing
      this.acquisition_hierarchy = acq

      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  HierarchyWithLocationInfo = []
  async getHierarchyWithLocationInfo() {
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code_acq']
    var resp = await this.hierarchyS.getHierarchyWithLocationInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.HierarchyWithLocationInfo = resp['data']
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {
    this.getAllLevls();

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false

  }
  async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.Obj = {}
    this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
    await this.changeList()
  }
  list: any = []
  all_land_input: any = []
  async getAllLandInput() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    let dummy = []
    var resp = await this.landInput_S.getLandInputData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.all_land_input = resp['data']
      //////console.log(dummy, 'data')
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  changeList() {
    this.Obj['hier_type_id'] = this.location_hier[0]['hier_type_id']
    let dummy = []
    for (let i = 0; i < this.all_land_input.length; i++) {
      if (this.all_land_input[i]['hier_type_id'] == this.Obj['hier_type_id']) {
        let ob = this.hier_node_obj[this.all_land_input[i]['prop_hier_id']]
        //////console.log(ob, this.hier_node_obj)
        let dummy2 = []
        for (let j = 1; j <= 8; j++) {
          if (ob['lvl' + j + '_cd']) {
            dummy2.push(ob['lvl' + j + '_value'])
          }
        }
        this.all_land_input[i]['path_desc'] = dummy2.join(' --> ')
        this.all_land_input[i]['target_name'] = ob['leaf_value']
        this.all_land_input[i]['target_type'] = ob['leaf_node_type']
        dummy.push(this.all_land_input[i])
      }
    }
    this.datasource = new MatTableDataSource(dummy);
    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];

  }
  change_data(data: any, outer_index: any, k: any) {
    //////console.log(outer_index, k, this.outer_acq_row, this.hierarchyS)
    let dummy2 = []
    let dummy = []
    for (let i = 0; i < this.all_locations.length; i++) {
      if (data['loc_type_cd'] == this.all_locations[i]['loc_type_cd']) {
        dummy.push(this.all_locations[i])
      }
    }
    this.outer_acq_row[outer_index]['location'][k]['area'] = []
    this.outer_acq_row[outer_index]['location'][k]['area'] = dummy
    //////console.log(dummy2, dummy)
  }
  set_acq_row_data(data: any, i: any) {
    ////////console.log(data, i)
  }

  existing_heirarchy_nodes: any = []
  async getAllpropHierarchynodes() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code']
    obj['status'] = 'ACTIVE'
    ////////console.log(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    //////console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy: any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  setPath_desc() {
    //
    this.Obj['hier_type_id_acq'] = this.acq_hier[0]['hier_type_id']
    this.getAllLevels_acq();
    //
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          //////console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    let ob = this.hier_node_obj[hier_id]
    let dummy2 = []
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        dummy2.push(ob['lvl' + j + '_value'])
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  async submit() {
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          ////////// console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    this.Obj['prop_hier_id'] = hier_id
    let dummy = []
    if (!this.Obj['hier_type_id']) {
      Swal.fire('Error', 'Please Select Planing Hierarchy', 'error')
    } else if (this.data.length < 1) {
      Swal.fire('Error', 'Please Select planing Target', 'error')
    } else if (!this.Obj['hier_type_id_acq']) {
      Swal.fire('Error', 'Please Select Acquisition Hierarchy', 'error')
    } else {

      for (let i = 0; i < this.outer_acq_row.length; i++) {

        let data: any=[]
        console.log('called')
        data = Object.assign([],this.outer_acq_row[i]['location'])
        for (let j = 0; j < data.length; j++) {
          let obj: any = {}
          console.log(data[j])
          obj['loc_type_cd'] = data[j]['loc_type_cd']
          obj['hier_type_id'] = this.outer_acq_row[i]['hier_type_id_acq']
          obj['prop_hier_id'] = this.outer_acq_row[i]['prop_hier_id']
          obj['target_no'] = i
          obj['loc_id'] = data[j]['node_cd']
          dummy.push(obj)
        }
      }
      this.Obj['data'] = dummy
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['group_type'] = 'LANDINPUT'
      console.log(this.Obj, 'object', this.outer_acq_row, dummy)
      // this.spinner.show()
      var resp = await this.landInput_S.createLandInput(this.Obj)
      ////////console.log(resp, 'response')
      if (resp['error'] == false) {
        this.open_list()
        this.reset()
        this.spinner.hide()
        await this.getAllLandInput()
        Swal.fire('Success', 'Created Successfully', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Creating', 'error')
      }
    }

  }
  existing_heirarchy_nodes_acq: any = []
  async getAllpropHierarchynodes_for_acq() {
    // this.spinner.show()
    for (let i = 0; i < this.existing_heirarchy.length; i++) {
      if (this.Obj['hier_ident_code_acq'] == this.existing_heirarchy[i]['hier_ident_code']) {
        this.Obj['hier_buss_name'] = this.existing_heirarchy[i]['hier_buss_name']
        this.Obj['hier_type'] = this.existing_heirarchy[i]['hier_type']
        break;
      }
    }
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.Obj['hier_ident_code_acq']
    obj['status'] = 'ACTIVE'
    ////////console.log(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    //////console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes_acq = resp['data']
      let dummy : any= []
      for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
        if (1 == this.existing_heirarchy_nodes_acq[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes_acq[i])
        }
      }
      this.outer_acq_row[0]['dropDown'][0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
    await this.getHierarchyWithLocationInfo()
  }

  setDataForS1() {
    let data = []
    ////////console.log(this.planing_arr, ////////console.log(this.Obj))
    if (this.planing_arr.length > 0) {
      data = this.planing_arr[this.planing_arr.length - 1]['existing_heirarchy_nodes']
      ////////console.log(data)
      for (let i = 0; i < data.length; i++) {
        if (data[i]['node_cd'] == this.planing_arr[this.planing_arr.length - 1]['hierarchy_type']) {
          this.Obj['level'] = data[i]['level']
          this.Obj['node_cd'] = data[i]['node_cd']
          this.Obj['life_cycle_status'] = 'ACTIVE'
        }
      }
    }
    ////////console.log(this.Obj, 'obj')
  }
  setRowData(data: any) {
    //////console.log(data)
    let sum = 0
    for (let i = 0; i < this.outer_acq_row.length; i++) {
      let data = this.outer_acq_row[i]['location']
      for (let j = 0; j < data.length; j++) {
        for (let k = 0; k < data[j]['area'].length; k++) {
          if (data[j]['node_cd'] == data[j]['area'][k]['loc_id']) {
            sum = sum + Number(data[j]['area'][k]['loc_geo_calc_value'])
            if (data[j]['area'][k]['loc_geo_ent_value']) {
              data[j]['loc_geo_ent_value'] = data[j]['area'][k]['loc_geo_ent_value']
            } else {
              data[j]['loc_geo_ent_value'] = 'NA'
            }
            if (data[j]['area'][k]['location_use_type']) {
              data[j]['location_use_type'] = data[j]['area'][k]['location_use_type']
            } else {
              data[j]['location_use_type'] = 'Not Defined Yet'
            }
            data[j]['hier_ident_code'] = data[j]['area'][k]['hier_ident_code']
            if (data[j]['area'][k]['loc_geo_calc_value']) {
              data[j]['loc_geo_calc_value'] = data[j]['area'][k]['loc_geo_calc_value']
            } else {
              data[j]['loc_geo_calc_value'] = 'NA'
            }
          }
        }
      }
    }
    this.Obj['total_area'] = sum
  }
  done_Table: any = []
  setDone_data() {
    ////////console.log(this.outer_acq_row)
    let dummy: any = []
    let obj : any= {}
    for (let i = 0; i < this.outer_acq_row.length; i++) {
      let data = this.outer_acq_row[i]['location']
      for (let j = 0; j < data.length; j++) {
        obj = Object.assign({}, data[j])
        obj['target_no'] = i
        for (let k = 0; k < this.HierarchyWithLocationInfo.length; k++) {
          if (data[j]['loc_geo_calc_value'] == this.HierarchyWithLocationInfo[k]['loc_geo_calc_value']) {
            obj['location_use_type'] = this.HierarchyWithLocationInfo[k]['location_use_type']
            obj['loc_geo_ent_value'] = this.HierarchyWithLocationInfo[k]['loc_geo_ent_value']
            obj['loc_geo_ent_value'] = this.HierarchyWithLocationInfo[k]['loc_geo_ent_value']
            obj['loc_geo_ent_value'] = this.HierarchyWithLocationInfo[k]['loc_geo_ent_value']
          }
        }
        dummy.push(obj)
      }
    }
    ////////console.log(dummy, 'dummy')
    this.done_Table = dummy
  }

  add_data(i: any, k: any) {
    this.outer_acq_row[i]['location'].push({})
  }
  remove_data(data: any, i: any, k: any) {
    if (this.outer_acq_row[i]['location'].length > 1) {
      this.Obj['total_area'] = this.Obj['total_area'] - Number(data['loc_geo_calc_value'])
      this.outer_acq_row[i]['location'].pop()
      this.setRowData(data)

    }
  }
  used_nodes: any = []
  selected_row: any = {}
  // add_dropdown(data, index) {

  //   ////////console.log(data, index)
  //   let flag = true
  //   let obj :any= {}
  //   for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
  //     if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
  //       obj = Object.assign({}, this.existing_heirarchy_nodes[i])
  //     }
  //   }
  //   this.selected_row = Object.assign({}, obj)
  //   this.selected_row['index'] = index

  //   this.filter_data()
  // }
  filter_data() {
    let dummy: any = []
    let max_level: any = 0
    for (let i = 0; i < this.planaing_hierarchy.length; i++) {
      if (this.Obj['hier_ident_code'] == this.planaing_hierarchy[i]['hier_ident_code']) {
        max_level = this.planaing_hierarchy[i]['level_count']
        break;
      }
    }
    ////////console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr: any = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        ////////console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({})
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
    //////console.log(dummy, 'dummy', this.planing_arr)
  }
  // add_dropdown_acq(data, i, j) {

  //   ////////console.log(data, i, j)
  //   let flag = true
  //   let obj :any= {}
  //   for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
  //     if (data['hierarchy_type'] == this.existing_heirarchy_nodes_acq[i]['node_cd']) {
  //       obj = Object.assign({}, this.existing_heirarchy_nodes_acq[i])
  //     }
  //   }
  //   this.selected_row = Object.assign({}, obj)
  //   this.selected_row['outer_index'] = i
  //   this.selected_row['iner_index'] = j

  //   this.filter_data_acq()
  // }

  locationInfoData = []
  used_node_arr = []
  filterRow(data: any, outer_index: any, inner_index: any) {
    //////console.log(this.outer_acq_row, 'outer data')
  }
  remove_dropdown_acq(data: any, i: any, j: any) {
    this.outer_acq_row[i]['data'].splice(j, 1)
    let index = i
    this.leaf_obj[i] = this.leaf_obj[i] - 1
    let hier_id = 0
    for (let i = 0; i < this.allHier_acq.length; i++) {
      if (this.allHier_acq[i]['lvl' + this.leaf_obj[index] + '_cd'] == data['lvl' + this.leaf_obj[index] + '_cd'] && data['lvl' + this.leaf_obj[index] + '_cd'] == this.allHier_acq[i]['leaf_cd']) {
        {
          hier_id = this.allHier_acq[i]['prop_hier_id']
        }
      }
    }
    this.outer_acq_row[i]['prop_hier_id'] = hier_id
    this.outer_acq_row[i]['hier_type_id_acq'] = this.Obj['hier_type_id_acq']
    //////console.log(this.outer_acq_row, data, i, j, this.leaf_obj, data)
  }
  filter_data_acq() {
    let dummy: any = []
    let max_level: any = 0
    for (let i = 0; i < this.acquisition_hierarchy.length; i++) {
      if (this.Obj['hier_ident_code_acq'] == this.acquisition_hierarchy[i]['hier_ident_code']) {
        max_level = this.acquisition_hierarchy[i]['level_count']
        break;
      }
    }
    ////////console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes_acq.length; i++) {
        let node_arr: any = []
        node_arr = this.existing_heirarchy_nodes_acq[i]['path'].split('|')
        ////////console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes_acq[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row['node_cd'])
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes_acq[i]['level'] == j && this.existing_heirarchy_nodes_acq[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes_acq[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.outer_acq_row[this.selected_row['outer_index']]['dropDown'].push({
      existing_heirarchy_nodes: []
    })
    // this.outer_acq_row[this.selected_row['outer_index']]['dropDown'][this.selected_row['iner_index']+1]['existing_heirarchy_nodes'] = []
    this.outer_acq_row[this.selected_row['outer_index']]['dropDown'][this.selected_row['iner_index'] + 1]['existing_heirarchy_nodes'] = dummy
    ////////console.log(dummy, 'dummy', this.acp_arr)
  }



  setHierarchyNode(item: any, i: any) {
    let dummy = []
    ////////console.log(this.planing_arr, this.existing_heirarchy_nodes, item, i)
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }

  // remove_dropdown(data, i) {
  //   ////////console.log(data, i)
  //   if (this.planing_arr.length > 1) {
  //     this.planing_arr.pop()
  //   }
  //   let dummy = []
  //   for (let i = 0; i < this.planing_arr.length; i++) {
  //     dummy.push(this.planing_arr[i]['hierarchy_type'])
  //   }
  //   let dummy2 = []
  //   if (dummy.length > 0) {
  //     for (let i = 0; i < dummy.length; i++) {
  //       for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
  //         if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
  //           dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
  //         }
  //       }
  //     }
  //   }
  //   this.Obj['path_desc'] = dummy2.join(' --> ')
  // }
  async open_update(data: any) {
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    this.Obj = Object.assign({}, data)
    await this.getAllLevls()
    await this.setPreDefinedHierrarchy(this.Obj)

    ////console.log(this.Obj)
    await this.getlocationGroupTargetdata(data['group_id'])
    console.log(this.Obj)
  }
  async setPreDefinedHierrarchy(data: any) {
    this.data = []
    let ob: any = this.hier_node_obj[data['prop_hier_id']]
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }
        this.data.push(ob)
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  async getlocationGroupTargetdata(group_id: any) {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_id'] = group_id
    obj['status'] = 'ACTIVE'
    ////////console.log(obj)
    var resp = await this.landInput_S.getlocationGroupTargetdata(JSON.stringify(obj));
    ////console.log(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {
        this.Obj['hier_type_id_acq'] = resp['data'][0]['hier_type_id']
      }
      await this.getAllLevels_acq()
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['node_cd'] = resp['data'][i]['loc_type_cd']
        resp['data'][i]['loc_type_cd'] = resp['data'][i]['loc_type']
      }
      await this.set_data_for_update(resp['data'])
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async set_data_for_update(data: any) {
    let max_target_no = 0
    let final_data = []
    for (let i = 0; i < data.length; i++) {
      if (data[i]['target_no'] && data[i]['target_no'] > max_target_no) {
        max_target_no = data[i]['target_no']
      }
    }

    for (let i = 0; i <= max_target_no; i++) {
      let dummy: any = []
      let dummy2: any = []
      let obj: any = {}
      for (let j = 0; j < data.length; j++) {
        if (data[j]['target_no'] == i) {
          this.Obj['hier_type_id_acq'] = data[j]['hier_type_id']
          obj['hier_type_id'] = this.Obj['hier_type_id_acq']
          dummy.push(data[j])
        }
      }
      for (let k = 0; k < data.length; k++) {
        if (data[k]['target_no'] == i) {
          dummy2.push(data[k])
        }
      }
      obj['data'] = dummy
      for (let k = 0; k < dummy2.length; k++) {
        let arr = []
        for (let j = 0; j < this.all_locations.length; j++) {
          if (this.all_locations[j]['loc_type_cd'] == dummy2[k]['loc_type_cd']) {

            arr.push(this.all_locations[j])
          }
        }
        for (let j = 0; j < this.all_locations.length; j++) {
          if (this.all_locations[j]['loc_id'] == dummy2[k]['node_cd']) {
            if (this.all_locations[j]['loc_geo_calc_value']) {
              dummy2[k]['loc_geo_calc_value'] = this.all_locations[j]['loc_geo_calc_value']
            } else {
              dummy2[k]['loc_geo_calc_value'] = 'NA'
            }
            if (this.all_locations[j]['loc_geo_ent_value']) {
              dummy2[k]['loc_geo_ent_value'] = this.all_locations[j]['loc_geo_ent_value']
            } else {
              dummy2[k]['loc_geo_ent_value'] = 'NA'
            }
            if (this.all_locations[j]['location_use_type']) {
              dummy2[k]['location_use_type'] = this.all_locations[j]['location_use_type']
            } else {
              dummy2[k]['location_use_type'] = 'Not Defined Yet'
            }
            obj['prop_hier_id'] = dummy2[k]['prop_hier_id']
          }
        }
        dummy2[k]['area'] = []
        dummy2[k]['area'] = arr
      }
      obj['location'] = dummy2
      //console.log(obj)
      final_data.push(obj)
    }

    this.outer_acq_row = final_data
    for (let i = 0; i < this.outer_acq_row.length; i++) {
      this.outer_acq_row[i]['level1'] = this.level1_acq
      this.outer_acq_row[i]['data'] = []
      let ob = this.hier_node_obj[this.outer_acq_row[i]['prop_hier_id']]
      console.log(ob)
      await this.commonS.refresh(this.allHier_acq)
      for (let j = 1; j <= 8; j++) {
        if (ob['lvl' + j + '_cd']) {
          this.outer_acq_row[i]['lvl' + j + '_cd'] = ob['lvl' + j + '_cd']
          let index = j
          if (index == 1) {
            this.outer_acq_row[i]['level2'] = await this.commonS.onChangeLvl1(this.allHier_acq, ob)
          } else if (index == 2) {
            this.outer_acq_row[i]['level3'] = await this.commonS.onChangeLvl2(this.allHier_acq, ob)
          }
          else if (index == 3) {
            this.outer_acq_row[i]['level4'] = await this.commonS.onChangeLvl3(this.allHier_acq, ob)
          }
          else if (index == 4) {
            this.outer_acq_row[i]['level5'] = await this.commonS.onChangeLvl4(this.allHier_acq, ob)
          }
          else if (index == 5) {
            this.outer_acq_row[i]['level6'] = await this.commonS.onChangeLvl5(this.allHier_acq, ob)
          }
          else if (index == 6) {
            this.outer_acq_row[i]['level7'] = await this.commonS.onChangeLvl6(this.allHier_acq, ob)
          }
          this.outer_acq_row[i]['data'].push(ob)
        }
      }
    }
  }
  async update() {
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {

          hier_id = this.allHier[i]['prop_hier_id']
        }
      }
    }
    this.Obj['prop_hier_id'] = hier_id
    let dummy = []
    if (!this.Obj['hier_type_id']) {
      Swal.fire('Error', 'Please Select Planing Hierarchy', 'error')
    } else if (this.data.length < 1) {
      Swal.fire('Error', 'Please Select planing Target', 'error')
    } else if (!this.Obj['hier_type_id_acq']) {
      Swal.fire('Error', 'Please Select Acquisition Hierarchy', 'error')
    } else {

      for (let i = 0; i < this.outer_acq_row.length; i++) {

        let data: any=[]
        console.log('called')
        data = Object.assign([],this.outer_acq_row[i]['location'])
        for (let j = 0; j < data.length; j++) {
          let obj: any = {}
          console.log(data[j])

          obj['loc_type_cd'] = data[j]['loc_type_cd']
          obj['hier_type_id'] = this.outer_acq_row[i]['hier_type_id_acq']
          obj['prop_hier_id'] = this.outer_acq_row[i]['prop_hier_id']
          obj['target_no'] = i
          obj['loc_id'] = data[j]['node_cd']
          dummy.push(obj)
        }
      }

      this.Obj['data'] = dummy
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['group_type'] = 'LANDINPUT'
      ////console.log(this.Obj, 'object', this.outer_acq_row, dummy)
      this.spinner.show()
      var resp = await this.landInput_S.updateLandInput(this.Obj)
      ////////console.log(resp, 'response')
      if (resp['error'] == false) {
        this.open_list()
        this.reset()
        this.spinner.hide()
        await this.getAllLandInput()
        Swal.fire('Success', 'Updated Successfully', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Some Error Occured While Creating', 'error')
      }
    }

  }
  async delete(element: any) {
    //////console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element: any) {
    let obj: any = {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.landInput_S.deleteLandInput(obj)
    //////console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getAllLandInput()
      await this.changeList()
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Deleting', 'error')
    }
  }

  // ============= new code
  level2: any = []
  level3: any = []
  level4: any = []
  level5: any = []
  level6: any = []
  level7: any = []
  async add_dropdown(data: any, index: any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.allHier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.allHier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.allHier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.allHier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.allHier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.allHier, this.Obj)
    }
  }
  level2_acq : any= []
  level3_acq: any = []
  level4_acq: any = []
  level5_acq: any = []
  level6_acq: any = []
  level7_acq: any = []
  async add_dropdown_acq(data: any, outer_index: any, index: any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.outer_acq_row[outer_index]['data'].push({})
    }
    let ob = {}
    if (index == 0) {
      this.level2_acq = await this.commonS.onChangeLvl1(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level2'] = this.level2_acq
    } else if (index == 1) {
      this.level3_acq = await this.commonS.onChangeLvl2(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level3'] = this.level3_acq
    }
    else if (index == 2) {
      this.level4_acq = await this.commonS.onChangeLvl3(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level4'] = this.level4_acq
    }
    else if (index == 3) {
      this.level5_acq = await this.commonS.onChangeLvl4(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level5'] = this.level5_acq
    }
    else if (index == 4) {
      this.level6_acq = await this.commonS.onChangeLvl5(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level6'] = this.level6_acq
    }
    else if (index == 5) {
      this.level7_acq = await this.commonS.onChangeLvl6(this.allHier_acq, data)
      this.outer_acq_row[outer_index]['level7'] = this.level7_acq
    }
    //////console.log(data, outer_index, index, this.outer_acq_row)
  }
  leaf_level = 0
  remove_dropdown(data: any, index: any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  data: any = [{}]
  location_hier: any = []
  acq_hier: any = []
  async getAllHier() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    ////////console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      //////console.log(resp, 'list data')
      let data = resp['data']
      let dummy1 = []
      let dummy2 = []
      for (let i = 0; i < data.length; i++) {
        if (data[i]['hier_type'] == "Planing") {
          dummy1.push(data[i])
        }
        if (data[i]['hier_type'] == "Acquisition") {
          dummy2.push(data[i])
        }
      }
      this.location_hier = dummy1
      this.acq_hier = dummy2
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  level1: any = []
  allHier: any = []
  hier_node_obj: any = {}
  async getAllLevls() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allHier = resp.data;
      for (let i = 0; i < this.allHier.length; i++) {
        this.hier_node_obj[this.allHier[i]['prop_hier_id']] = this.allHier[i]
      }
      this.level1 = await this.commonS.refresh(this.allHier)
    } else {
    }
  }
  leaf_obj: any = {}
  setLeaf_node(ob: any, i: any, k: any) {
    let index: any = i
    this.leaf_obj[i] = k + 1
    let hier_id = 0
    for (let i = 0; i < this.allHier_acq.length; i++) {
      if (this.allHier_acq[i]['lvl' + this.leaf_obj[index] + '_cd'] == ob['lvl' + this.leaf_obj[index] + '_cd'] && ob['lvl' + this.leaf_obj[index] + '_cd'] == this.allHier_acq[i]['leaf_cd']) {
        {
          //////console.log(this.allHier_acq[i])
          hier_id = this.allHier_acq[i]['prop_hier_id']
        }
      }
    }
    this.outer_acq_row[i]['prop_hier_id'] = hier_id
    this.outer_acq_row[i]['hier_type_id_acq'] = this.Obj['hier_type_id_acq']
    //////console.log(this.outer_acq_row, ob, this.leaf_obj)
  }
  allHier_acq: any = []
  level1_acq: any = []
  async getAllLevels_acq() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id_acq']
    //////console.log(obj)
    this.Obj['hier_type_id_acq']=obj['hier_type_id']
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //////console.log(resp, ' data')
      this.allHier_acq = resp.data;
      this.level1_acq = await this.commonS.refresh(this.allHier_acq)
      this.outer_acq_row = [{
        'level1': this.level1_acq,
        location: [{}], data: [{}]
      }]
    } else {
    }
  }
  add(i: any) {
    let ob = {}
    this.outer_acq_row.push({
      'level1': this.level1_acq,
      location: [{
      }],
      data: [{}]
    })
    this.outer_acq_row[i + 1]['level1'] = this.level1_acq
    ////console.log(this.outer_acq_row, 'outer_acq_row')
  }
  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_obj[i] = i
      } else {
        break
      }
    }
  }
}
