
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { MainService } from '../../service/main.service';
import { ComplainServiceService } from '../../service/complain-service.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { ReportService } from '../../service/report.service'
import { ToastrService } from 'ngx-toastr';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { MainService as Portalmain } from '../../../portal/service/main.service';

import * as pdfFonts from "pdfmake/build/vfs_fonts";
import  * as pdfMake from "pdfmake/build/pdfmake";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-inspection-report',
  templateUrl: './inspection-report.component.html',
  styleUrls: ['./inspection-report.component.css']
})
export class InspectionReportComponent implements OnInit {
  datasource: any;
  displayedColumns = ['s.no', 'Complaint_ID', 'Complaint_type', 'Complaint_Date', 'Complaint_Assign_Date', 'Complaint_Status', 'inspection_status', 'action'];
  erpUser: any;
  b_acct_id: any;
  complaint_detail: any = {};
  complaint_image = [];
  imgURL1: any;
  assign_Obj:any = {}
  user_id: any
  formBuilder: any;
  ebillUser:any
  inspection_detail: any = {};
  description: any = {};
  inspection_image: any = [];
  section_detail: any;
  memo_image = [];
  memoimageflag = false;
  imgURL3: any;
  imgURL: any;
  feedbackimage: boolean = false;
  imgURL4: any;
  constructor(public ComplainService: ComplainServiceService, private sanitizer: DomSanitizer,
     private portalmain: Portalmain, public ApprovalUserService: ApprovalUserService,
     public mainserviceEmb: mainserviceEmb, public toastr: ToastrService,
     private spinner: NgxSpinnerService, private mainServiceMD: mainService_MD,
     private ReportService: ReportService) { }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    console.log(this.erpUser)
    this.getAllEmployees()
    this.getcomplaindata()
    await this.getWorkflowlog();
    // await this.getAllModules()
  }

  complaint = true
  complaint_inspection = false
  imageflag = false


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  allEmplyees_new = []
  allEmployees = []
  allSearchableEmp = []
  userObj:any = {}



  public_photo :any= {}

  async getphotodetail() {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Public"

    console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.complaint_image = resp['data']
      this.public_photo = Object.assign({}, this.complaint_image[0]);
    }
  }




  async viewDetails(element:any) {
    this.complaint_detail = element
    $('#myModal1').modal('show');
    this.getphotodetail();
    this.getuserinfo();
    this.inspection(element);
    this.getfeedbackdetails()
    if (this.complaint_detail['status'] == 'INSPECTION REPORT SUBMITED' || this.complaint_detail['status'] == 'CASE_FILED' || this.complaint_detail['status'] == 'CASE_CLOSED') {
      const obj:any = {}
      obj['b_acct_id'] = this.b_acct_id
      obj['complain_id'] = this.complaint_detail['complain_id']
      var resp:any = await this.ComplainService.getinspectionDescription(JSON.stringify(obj));
      console.log(resp);
      if (resp['error'] == true) {
        Swal.fire('error', "server side error", 'error');
        return
      } else {
        this.description = resp['data'];
        console.log(this.description);
      }
      return
    }
  }



  forward(element:any) {
    // this.complaint_detail = {};
    this.complaint_detail = element
    $('#staticBackdrop2').modal('show');



  }
  inspection_req = [
    { id: 1, name: 'yes' },
    { id: 2, name: 'no' },


  ]

  async forward2() {
    this.assign_Obj['b_acct_id'] = this.b_acct_id
    this.assign_Obj['life_cycle_status'] = "PENDIND"
    this.assign_Obj['complain_id'] = this.complaint_detail['complain_id']
    this.assign_Obj['status'] = "FORWARDED "
    console.log(this.assign_Obj);
    var resp:any = await this.ComplainService.insertassigncomplain(this.assign_Obj)
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      Swal.fire('success', 'compalint forward successfully', 'success');
      this.getcomplaindata();
      $('#staticBackdrop2').modal('hide');
    }
    this.assign_Obj = {};


  }


  async inspection(element:any) {

    this.complaint_detail = element
    this.getinspectionphotodetail(element)
    this.getmemophoto();
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
    console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      this.section_detail = resp['data'][1]
      this.inspection_detail = Object.assign({}, ...resp['data'][0]);
      console.log(this.complaint_detail);
    }

  }

  async getcomplaindata() {
    const obj :any= {}
    console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id'] = this.erpUser.user_id;
    var resp:any = await this.ReportService.allAssignComplain(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      // this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }

  }

  view_photo() {
    console.log(this.complaint_image.length)
    this.imageflag = false
    if (this.complaint_image.length > 0) {
      $('#staticBackdrop').modal('show');
      return
    }
    else {
      Swal.fire('info', "No image found", 'info');
    }





  }

  async inspection_view(i:any) {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.inspection_image[i]['doc_id']
    obj['file_name'] = this.inspection_image[i]['file_name']
    obj['complain_id'] = this.inspection_image[i]['complain_id']
    console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // console.log(this.imgURL1, 'imgURL1')

    }


  }





  async view(i:any) {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.complaint_image[i]['doc_id']
    obj['file_name'] = this.complaint_image[i]['file_name']
    obj['complain_id'] = this.complaint_image[i]['complain_id']
    console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL1 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      console.log(this.imgURL1, 'imgURL1')

    }


  }

  open_list() {
    this.complaint = true;
    this.complaint_inspection = false;
  }

  // cancel_insp(){
  //   this.complaint = true;
  //   this.complaint_inspection = false;
  // }

  close() {
    $('#myModal1').modal('hide');

  }

  async getAllEmployees() {
    console.log('all emp', this.b_acct_id)
    this.spinner.show()
    var arr = [];
    const obj:any = {};
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    console.log(obj)
    var resp:any = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    // console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

  // get user info
  userdetail:any = []
  async getuserinfo() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getuserinfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {

      this.userdetail = resp['data']
      this.complaint_detail['party_name'] = this.userdetail[0]['party_name']
      for(let i=0;i<this.userdetail.length;i++){
        if(this.userdetail[i]['role_cd'] == "ICMS_EMP" ){
         this.userdetail[i]['role_name'] = undefined

        }

   }
      // console.log(this.userdetail);
    }


  }


  passengerForm = [
    {
      name: ''

    }
  ];

  addForm(i:any) {
    console.log(i)
    this.passengerForm.push({
      name: ''

    });
  }
  FinalSubmit() {
    console.log(this.passengerForm)
  }
  /////////////////////////AP////////////inspection
  view_inspection_photo() {
    this.imageflag = false
    $('#inspection_photo').modal('show');

  }
  async getinspectionphotodetail(element:any) {
    this.complaint_detail = element

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Inspection";
    console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.inspection_image = resp['data']
      console.log(this.inspection_image)
    }
  }

  ///////////////////////////memo image
  async getmemophoto() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Memo";
    console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.memo_image = resp['data']
      console.log(this.memo_image)
    }
  }
  view_memo_img() {
    this.memoimageflag = false;
    $('#memo_photo').modal('show');
    this.view_memo();
  }

  async view_memo() {

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.memo_image[0]['doc_id']
    obj['file_name'] = this.memo_image[0]['file_name']
    obj['complain_id'] = this.memo_image[0]['complain_id']
    console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.memoimageflag = true;
      console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL3 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }


    }



  }



  //workflow*********************************************//
  apprflag = false;
  level_for_approval = []
  workflow_level1:any = {}
  async getWorkflowlog() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'INSPECTION REPORT';
    this.apprflag = false;
    var resp:any = await this.portalmain.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      let currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
          this.apprflag = true
        }

      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }


  undertakings:any
  salBill_apprdata:any
  async Appr(element:any) {
    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['complain_app_id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'INSPECTION REPORT'//this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['zone_cd']
    this.salBill_apprdata['module_cd'] = 'ICMS';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'INSPECTION REPORT', 'ICMS');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#workflow').modal('show');
  }
  message_flag = false
  async getle(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['doc_local_no'], user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }


  // async sendmessage(mobile) {
  //   console.log(mobile, 'Application  of' + ' Number ' + this.salBill_apprdata['party_id'])
  //   let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'] + ' Number ' + this.salBill_apprdata['party_id'])
  //   if (resp['error'] == false) {
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide();
  //     Swal.fire("Error", resp['data'], 'error');
  //   }
  // }
  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'INSPECTION REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['user_name'] = this.ebillUser.party_name;
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangeComplainStatus(this.salBill_apprdata['doc_local_no'], 'UNDERAPPROVAL')
        await this.getcomplaindata()
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }

  async ChangeComplainStatus(data:any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp:any = await this.ReportService.changeComplainStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'INSPECTION REPORT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      // obb['user_name'] = this.ebillUser.party_name;
      obb['user_name'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['emp_name']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp:any = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }





  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp:any = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  statusArr1:any = [];
  selectedEle:any;
  userRoles = []

  async viewStatus(element:any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'INSPECTION REPORT';
    obj['module_cd'] = 'ICMS'
    var resp:any = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'INSPECTION REPORT'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'ICMS';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr1 = resp.data
      console.log(this.statusArr1);
      $('#workflowStatus').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }
  selectedTender = {}
  workObj = {}

  async download() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    // obj['complain_id'] = this.complaint_detail['complain_id']
    obj['id'] = this.complaint_detail['id']
    obj['user_id'] = this.ebillUser.user_id;
    await this.ReportService.printInspectionRep(obj)
  }


  /*******************************************************unused code *************************************************** */
  // downloadReport() {

  //   let txt = ("INSPECTION REPORT");
  //   var dd = {
  //     pageSize: 'A4',
  //     header: function (currentPage, pageCount) {
  //       var arr = []
  //       var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
  //       arr.push(obj);
  //       return arr;
  //     },

  //     pageOrientation: 'portrait',
  //     pageMargins: [40, 60, 40, 60],
  //     content: [

  //     ]
  //   };

  //   var tbl = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*', '*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };

  //   var tbl1 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*', '*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };



  //   // dd.content.push({ text: "COMPLAINT DETAILS ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: "COMPLAINT DETAILS ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 10, 0, 10] });

  //   let arr = [];

  //   arr.push({ text: "Complaint ID : " });
  //   arr.push(this.complaint_detail['complain_app_id']);
  //   arr.push({ text: "Complaint Date : " });
  //   arr.push(this.complaint_detail['complain_date']);
  //   tbl.table.body.push(arr);


  //   arr = [];
  //   arr.push({ text: "Zone : " });
  //   arr.push(this.complaint_detail['zone_name']);
  //   arr.push({ text: "Complaint Type : " });
  //   arr.push(this.complaint_detail['complian_type']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Latitude : " });
  //   arr.push(this.complaint_detail['latitude']);
  //   arr.push({ text: "Longitude : " });
  //   arr.push(this.complaint_detail['longitude']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Property Address :" });
  //   arr.push(this.complaint_detail['property_address']);
  //   arr.push({ text: "Assigned Officer : " });
  //   arr.push(this.complaint_detail['party_name']);
  //   tbl.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Assigned Date : " });
  //   arr.push(this.complaint_detail['comp_assign_date']);
  //   arr.push({ text: "Officer's Designation : " });

  //   let deg = []
  //   for (let i = 0; i < this.userdetail.length; i++) {
  //     // deg += this.userdetail[i]['role_name']
  //     deg.push(this.userdetail[i]['role_name'])
  //   }
  //   let degig = deg.join(',');
  //   arr.push("(" + degig + ")");
  //   tbl.table.body.push(arr);




  //   arr = [];
  //   arr.push({ text: "ComplaintDescription : " });
  //   arr.push(this.complaint_detail['detail']);
  //   arr.push({ text: "Complaint Status : " });
  //   arr.push({ text: this.complaint_detail['status'], bold: true });
  //   tbl.table.body.push(arr);


  //   dd.content.push(tbl);
  //   // dd.content.push({ text: "INSPECTION DETAILS", fontSize: 15, bold: true, colSpan: 2, margin: [0, 5, 0, 5] });
  //   dd.content.push({ text: "INSPECTION DETAILS", fontSize: 15, bold: true, colSpan: 2, margin: [0, 10, 0, 10] });



  //   // inspection report after final inspection
  //   arr = [];
  //   arr.push({ text: "Inspection ID : " });
  //   arr.push(this.inspection_detail['inspection_id']);
  //   arr.push({ text: "Inspection Date : " });
  //   arr.push(this.inspection_detail['inspection_date']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Owner Name : " });
  //   arr.push(this.inspection_detail['owner_name']);
  //   arr.push({ text: "Police Station : " });
  //   arr.push(this.complaint_detail['police_station']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Inspection officer : " });
  //   arr.push(this.inspection_detail['inspection_officer']);
  //   arr.push({ text: "Officer's Designation : " });
  //   arr.push("(" + degig + ")");
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Is Notice_Required : " });
  //   arr.push(this.inspection_detail['is__notice_required']);
  //   arr.push({ text: "Notice Under Act : " });
  //   arr.push(this.inspection_detail['notice_under_act']);
  //   tbl1.table.body.push(arr);

  //   arr = [];
  //   arr.push({ text: "Section : " });
  //   let temp = []
  //   this.section_detail.map(x => {
  //     temp.push(x['section_cd']);
  //   })
  //   let section = temp.join(',')

  //   arr.push(section);
  //   arr.push('');
  //   arr.push('');
  //   tbl1.table.body.push(arr);


  //   arr = []
  //   arr.push({ text: "Inspection Description notes : " });
  //   arr.push(this.inspection_detail['insp_offi_note']);
  //   arr.push('')
  //   arr.push('')
  //   tbl1.table.body.push(arr);

  //   for (let i = 0; i < this.description.length; i++) {
  //     arr = []
  //     arr.push({ text: "Inspection Description : " + (i + 1) });
  //     arr.push(this.description[i]['description'])
  //     arr.push('')
  //     arr.push('')
  //     tbl1.table.body.push(arr);
  //   }

  //   arr = [];
  //   arr.push({ text: "Inspection status : " });
  //   arr.push({ text: this.inspection_detail['life_cycle_status'], bold: true });
  //   arr.push("");
  //   arr.push("");
  //   tbl1.table.body.push(arr);


  //   dd.content.push(tbl1)
  //   dd.content.push({ text: " ", fontSize: 15, bold: true, colSpan: 2, margin: [0, 10, 0, 10] });

  //   dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05, margin: [0, 10, 20, 10] }] });

  //   var tbl2 = {

  //     fontSize: 10,
  //     table: {
  //       headerRows: 0,
  //       widths: ['*', '*', '*', '*'],
  //       body: [
  //       ]
  //     },
  //     layout: 'noBorders'
  //   };
  //   dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05, margin: [0, 10, 10, 10] }] });

  //   const d = new Date();
  //   let today = d.toISOString();
  //   let bb = today.split('T')[0]

  //   arr = [];
  //   arr.push({ text: "This is system generated " });
  //   arr.push({ text: " " });
  //   arr.push("");
  //   arr.push(bb);
  //   tbl2.table.body.push(arr);

  //   dd.content.push(tbl2)
  //   pdfMake.createPdf(dd).download('Report.pdf');

  // }
// get feedback details
view_feedback(){
  $('#feedback_img').modal('show');

}
feedbackdetail : any = {}
async getfeedbackdetails(){

  console.log( this.complaint_detail)
  let obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['complaint_id'] = this.complaint_detail['complain_id']
  var resp:any = await this.ComplainService.getfeedbackdetails(JSON.stringify(obj));
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error');
    return
  } else {
    this.feedbackdetail = resp['data'];
    console.log(this.feedbackdetail);
  }
 this.getfeedbackdocument();

}
feedbackimg:any = [];
async getfeedbackdocument(){
console.log(   this.feedbackdetail)
  console.log( this.complaint_detail)
  let obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['complain_id'] = this.feedbackdetail[0]['feedback_id']
  var resp:any = await this.ComplainService.getfeedbackdocuments(JSON.stringify(obj));
  console.log(resp)
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error');
    return
  } else {
    this.feedbackimg = resp['data'];
    // console.log(this.description);
  }


}

async view_feedback_photo(i:any) {
  this.feedbackimage = false;

  const obj:any = {}
  obj['b_acct_id'] = this.b_acct_id
  obj['doc_id'] = this.feedbackimg[i]['doc_id']
  obj['file_name'] = this.feedbackimg[i]['file_name']

  console.log(obj)
  this.spinner.show();
  let resp: any = await this.ComplainService.feedback(obj);
  console.log(resp);
  if (resp) {
    this.feedbackimage = true
    console.log(resp, 'res')
    this.spinner.hide();
    this.imgURL4 = resp;
    var docname = obj['file_name'];
    var ext = docname.split('.');
    if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
      const unsafeImageUrl = window.URL.createObjectURL(resp);
      this.spinner.hide();
      this.imgURL4 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
    } else {
      const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
      this.imgURL4 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    }

  console.log(this.imgURL4)
  }



}

}

