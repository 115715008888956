import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalLayoutsRoutingModule } from './portal-layouts-routing.module';
import { PortalLayoutsComponent } from './portal-layouts.component';
import { PortalHeaderComponent } from './portal-header/portal-header.component';
import { PortalFooterComponent } from './portal-footer/portal-footer.component';
import { PortalSidebarComponent } from './portal-sidebar/portal-sidebar.component';
import { PortalDashComponent } from '../portal-dash/portal-dash.component';
import { LeaveComponent } from '../leave/leave.component';
import { PortalProductsComponent } from '../portal-products/portal-products.component';
import { TaskComponent } from '../task/task.component';
import { ComplaintWorkflowComponent } from '../complaint-workflow/complaint-workflow.component';
import { SalaryComponent } from '../salary/salary.component';
import { PortalUsersComponent } from '../portal-users/portal-users.component';
import { AccountProfileComponent } from '../account-profile/account-profile.component';
import { PortalUserProfileComponent } from '../portal-user-profile/portal-user-profile.component';
import { ApprovalComponent } from '../approval/approval.component';
// import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';



import { ToastrModule } from 'ngx-toastr';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
// import { NgApexchartsModule } from 'ng-apexcharts';
// import { ToastrModule } from 'ng6-toastr-notifications';



@NgModule({
  declarations: [
    PortalLayoutsComponent,
	  PortalHeaderComponent,
	  PortalFooterComponent,
	  PortalSidebarComponent,
    PortalDashComponent,
    LeaveComponent,
    PortalProductsComponent,
    TaskComponent,
    ComplaintWorkflowComponent,
    SalaryComponent,
    PortalUsersComponent,
    AccountProfileComponent,
    PortalUserProfileComponent,
    ApprovalComponent
  ],
  exports: [
    PortalHeaderComponent,
    PortalFooterComponent,
    PortalSidebarComponent,
],
  imports: [

    CommonModule,
    PortalLayoutsRoutingModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    // NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
  ]
})
export class PortalLayoutsModule { }
