<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Employee Nominee Info </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-bar-chart"></i>
                                Employee Nominee Detail</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-settings"></i> Add Employee
                                Nominee Detail</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab" (click)="chamgeTab()"><i
                                    class="ti-announcement"></i> Update
                                Employee Nominee Detail</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Add Nominee</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee : <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="nom_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Nominee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="nom_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Email

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Phone No

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_phone_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nominee_relation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Relation

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nominee_relation_code}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="nom_share">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Share

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_share}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_bank_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Nominee Bank

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_bank_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_branch_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Branch Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_branch_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_ifsc_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>IFSC Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_ifsc_code}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nom_bank_acct_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Account Number

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.nom_bank_acct_no}} </td>
                                    </ng-container>






                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">Update</button>
                                            <button class="btn btn-outline-danger" (click)="eduDelete(element)">Delete</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <!-- <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" disabled="false">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Name <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="nomineeObj['nom_name']">
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Email <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_email']"> -->


                                    <input type="email" class="form-control" #ngInput1 [(ngModel)]="nomineeObj['nom_email']"  (ngModelChange)="checkEmail(ngInput1.value)" >
                                    <ng-container *ngIf="spanAvi==false; else elseTemplate"  style="color: red">
                                        <span style="color: red"> <i>Enter Valid Email</i>  </span>
                                     </ng-container>
                                                <ng-template #elseTemplate  style="color: green">
                                                             <span style="color: green"> <i>Email Is Valid</i></span>
                                                 </ng-template>

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Phone No. <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="number" [(ngModel)]="nomineeObj['nom_phone_no']"> -->
                                    <input class="form-control" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  type="number"  maxlength="10" [(ngModel)]="nomineeObj['nom_phone_no']" >

                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Relation <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0004']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="nomineeObj['nominee_relation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee IFSC Code <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" #ngInput (ngModelChange)="checkIFSC(ngInput.value)" [(ngModel)]="nomineeObj['nom_ifsc_code']">

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Bank Name <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_bank_name']" disabled>

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Branch Name <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_branch_name']" disabled>

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Bank Account No <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="text" [(ngModel)]="nomineeObj['nom_bank_acct_no']"> -->
                                    <input class="form-control"  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"  type="number"  maxlength="14"  [(ngModel)]="nomineeObj['nom_bank_acct_no']" >

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Share <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="nomineeObj['nom_share']">

                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitNomineeInfo()">Submit</button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <!-- "selectEmpObj['le_id']" -->
                                    <!-- "selectEmpObj['emp_id']" -->
                                    <!-- <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                         -->
                                        <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"  disabled='true'>
                                        </ng-select>
                                        <!-- <input type="text" class="form-control" value={{selectedEmpName}}>  -->

                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Name  <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="nomineeObj['nom_name']">
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Email <span style="color:red">*:</span>
                                </div>

                                <div class="col-4">


                                    <input type="email" class="form-control" #ngInput1 [(ngModel)]="nomineeObj['nom_email']"  (ngModelChange)="checkEmail(ngInput1.value)" >
                                    <ng-container *ngIf="spanAvi==false; else elseTemplate"  style="color: red">
                                        <span style="color: red"> <i>Enter Valid Email</i>  </span>
                                     </ng-container>
                                                <ng-template #elseTemplate  style="color: green">
                                                             <span style="color: green"> <i>Email Is Valid</i></span>
                                                 </ng-template>

                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Phone No. <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="nomineeObj['nom_phone_no']">

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Relation <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0004']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="nomineeObj['nominee_relation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee IFSC Code <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_ifsc_code']"> -->
                                    <input class="form-control" type="email" #ngInput (ngModelChange)="checkIFSC(ngInput.value)" [(ngModel)]="nomineeObj['nom_ifsc_code']">
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Bank Name <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_bank_name']" disabled>

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Branch Name <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_branch_name']" disabled>

                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Bank Account No <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="email" [(ngModel)]="nomineeObj['nom_bank_acct_no']">

                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Nominee Share <span style="color:red">*:</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="nomineeObj['nom_share']">

                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateNomineeInfo()">Update</button>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
