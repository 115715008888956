<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Terms & Conditions</div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
              
                        
                <div style="margin: 1%;" *ngIf="!showList" >
                    <div class="card">
                        <div class="row" style="margin-top: 1%; margin-right: 1%;">
                            <div class="col-12 text-right">
                                <button (click)="open_list(true)" class="btn btn-outline-primary">Go To
                                    List</button>
                            </div>
                        </div>
                        <br>
                        <div class="row ">

                                <!-- (change)="resetModeData()"  -->
                        </div>
                        <div class="row mt-3">
                            <div class="col-2 my-3"></div>
                            <div class="col-3 my-3">
                                <h6>Purpose : </h6>
                            </div>
                            <div class="col-3 my-3 text-center">
                                <ng-select [items]="purposeList" bindLabel="value" bindValue="code"
                                    [multiple]="false" placeholder="Select..."
                                    [(ngModel)]="purpose" [selectableGroup]="true"
                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                    (change)="modeOfferAction()"
                                    name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-4 my-3"></div>

                            <div class="col-md-2"></div>
                            <div class="col-12 col-md-9">
                                <div class="row border border-secondary rounded p-3">
                                    <div class="col-12 "> <h6><input type="radio" id="inputMode" [checked]="inputMode" name="inputMode"   (click)="inputModeChange(true)" >Enter Terms & Conditions</h6></div>
                                        <div class="col-2"></div><div class="col-10"><h6>OR</h6></div>
                                    <div class="col-12 col-md-4">
                                        <h6><input type="radio" id="inputMode" [checked]="!inputMode" name="inputMode" (click)="inputModeChange(false)" >
                                                Upload Terms & Conditions File 
                                        </h6>
                                    </div>

                                    <div class="col-12 col-md-4">
                                        CSV
                                        (<button class="btn btn-link" (click)="export()">Download CSV
                                            Format</button>)
                                    </div>
                                    <!--CSV-->
                                </div>
                            </div>        
                        </div>
                        <hr>
                        <div class="row mt-3" *ngIf="this.inputMode" >
                            <div class="col-md-2"></div>
                            <div class="col-12 col-md-9">
                                <div class="row border border-secondary rounded p-3">
                                    <div class="col-12 col-md-3 "> <h6>Enter T&C Decription:</h6> </div>
                                    <div class="col-12 col-md-5 text-center"> <textarea name="" id="" cols="100" rows="5" [(ngModel)]="enterTandC"></textarea> </div>
                                    <div class="col-12 col-md-2 text-center"> <button class="btn btn-success" (click)="addTermCondition()" >Save</button> </div>
                                </div>
                            </div>        
                        </div>
                        <div class="row mt-3" *ngIf="!inputMode">
                            <div class="col-md-2"></div>
                            <div class="col-12 col-md-9">
                                <div class="row border border-secondary rounded p-3">
                                    <div class="col-12 col-md-3 ">Choose T&C Decription File:</div>
                                    <div class="col-12 col-md-5 text-center"> <input #file type="file" (change)="fileSelected(file)" [(ngModel)]="fileInput"> </div>
                                    <div class="col-12 col-md-2 text-center"> <button class="btn btn-success" (click)="addTermCondition()" >Save</button> </div>
                                </div>
                            </div>        
                        </div>
                        <hr>
                        <ng-container *ngIf="tAndcList.length">
                            <div class="row mt-5" >
                                <div class="col-md-2"></div>
                                <div class="col-12 col-md-8">
                                    <table class="table">
                                        <thead class="thead-light">
                                        <tr>
                                            <th style="text-align: center; vertical-align: middle;">Sr. No.</th>
                                            <th style="text-align: center; vertical-align: middle;">T&C Description</th>
                                            <th style="text-align: center; vertical-align: middle;">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let item of tAndcList;let i = index" >
                                            <th scope="row" style="text-align: center; vertical-align: middle;">{{i+1}}.</th>
                                            <td style="text-align: center; vertical-align: middle;">{{item}}</td>
                                            <td style="text-align: center; vertical-align: middle;"><button class="btn btn-danger" (click)="deleteTandC(i)" >delete</button></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="submit()" >Submit</button>
                                </div>
                            </div>
                        </ng-container>
                        <br>
                    </div>
                </div>

                <div style="margin: 1%;" *ngIf="showList">
                    <div class="card">
                        <br>
                        <div class="row">
                            <div class="col-12 text-right"> <button class="btn btn-rounded btn-info" (click)="open_list(false)" >Create</button> </div>
                            <div class="col-12 text-center b-2"><h5>Terms And Conditions</h5></div>
                            <div class="col-md-1"></div>    
                            <div class="col-12 col-md-10  ">
                                <table class="table table-bordered">
                                    <thead class="thead-light">
                                    <tr >
                                        <th style="text-align: center; vertical-align: middle;">Sr. No.</th>
                                        <th style="text-align: center; vertical-align: middle;">T&C Description</th>
                                        <th style="text-align: center; vertical-align: middle;">Actions</th>
                                    </tr >
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of tAndcListDB;let i = index" >
                                        <th scope="row" style="text-align: center; vertical-align: middle;">{{i+1}}.</th>
                                        <td style="text-align: center; vertical-align: middle;" >{{item.tc}}</td>
                                        <td style="text-align: center; vertical-align: middle;"><button class="btn btn-danger" (click)="deleteTandCDB(item)" >delete</button></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                   



                    </div>
                </div>       
                <br>
                        
                        
                
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Lodaing...</ngx-spinner>