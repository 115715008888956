<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">BANK REPORT</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Bank Report</a>
                        </li>




                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Create Relation </a>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" (click)="refresh()" data-toggle="tab"><i class="ti-settings"></i> Update
                                Relation
                            </a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>

                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-3 text-left">
                                        <h6> Bank Account :</h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="s2" bindLabel="desc" bindValue="relation" [multiple]="false"
                                            placeholder="Select chart of account" (change)="changeDetails()"
                                            [(ngModel)]="obj['bank_id']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="bank_id"
                                            #bank_id="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && bank_id.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->

                                        <!-- border color green -->
                                        <div [class.selection-box]="bank_id.dirty && bank_id.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && bank_id.invalid" class="error-message">
                                            <div *ngIf="bank_id.errors?.['required']">Please Select Bank Account</div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->


                                    </div>
                                    <div class="col-3 text-left">
                                        <h6> Select Financial Year :</h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- <ng-select [items]="fin_year" bindLabel="desc" bindValue="fin_year"
                                            [multiple]="false" placeholder="Select financial year"
                                            [(ngModel)]="obj['fin_year']" name="fin_year" [selectableGroup]="true"
                                            (change)="setdate()" [selectableGroupAsModel]="false"
                                            [closeOnSelect]="true">
                                        </ng-select> -->


                                        <ng-select [items]="fin_year" bindLabel="desc" bindValue="fin_year"
                                            [multiple]="false" placeholder="Select financial year"
                                            [(ngModel)]="obj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year2"
                                            (change)="setdate()" #fin_year2="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && fin_year2.invalid }" required>
                                        </ng-select>

                                        <!--------------------------------------------- validation Start ------------------- -->
                                        <!-- border color green -->
                                        <div [class.selection-box]="fin_year2.dirty && fin_year2.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && fin_year2.invalid" class="error-message">
                                            <div *ngIf="fin_year2.errors?.['required']">Please Select Financial Year </div>
                                        </div>

                                        <!--------------------------------------------- validation End ------------------- -->

                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-3 text-left">
                                        <h6>From Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" class="form-control" name="from_accounting_dt"
                                            [(ngModel)]="obj['from_accounting_dt']" (change)="setrange()"
                                            #from_accounting_dt="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && from_accounting_dt.invalid }"
                                            required>

                                        <!-- ----------------------------------validation start --------------- -->

                                        <!-- date validation  -->
                                        <div *ngIf="from_date" class="error-message">
                                            <span>Please Select Date In The Range Of Financial Year</span>
                                        </div>

                                        <!-- border color green -->
                                        <div
                                            [class.border_green]="from_accounting_dt.dirty && from_accounting_dt.valid">
                                        </div>

                                        <!-- Date Validation -->


                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && from_accounting_dt.invalid" class="error-message">
                                            <div *ngIf="from_accounting_dt.errors?.['required']">Please Select From Date
                                            </div>
                                        </div>

                                        <!-- ----------------------------------validation end --------------- -->
                                    </div>
                                    <div class="col-3 text-left">
                                        <h6>To Date :</h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="date" [(ngModel)]="obj['to_accounting_dt']" class="form-control"
                                            name="to_accounting_dt" #to_accounting_dt="ngModel" (change)="setrange()"
                                            [ngClass]="{ 'is-invalid': f.submitted && to_accounting_dt.invalid }"
                                            required>

                                        <!-- date validation  -->
                                        <div *ngIf="to_date" class="error-message">
                                            <span>Please Select Date In The Range Of Financial Year</span>
                                        </div>

                                        <!-- border color green -->
                                        <div [class.border_green]="to_accounting_dt.dirty && to_accounting_dt.valid">
                                        </div>

                                        <!--------------------------------- Checking For Date Range ------------------- -->
                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && to_accounting_dt.invalid" class="error-message">
                                            <div *ngIf="to_accounting_dt.errors?.['required']">Please Select To Date
                                            </div>
                                        </div>

                                        <!-- ----------------------------------validation end --------------- -->
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-3 text-left">
                                        <h6>Bank Name :</h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- {{ mainService.codeValueShowObj['ACC0006'][objData2['bank_code']]}} -->
                                        {{objData2['bank_code']}}
                                    </div>
                                    <div class="col-3 text-left ">
                                        <h6>Bank Branch :</h6>
                                    </div>
                                    <div class="col-3">
                                        <!-- {{ mainService.codeValueShowObj['ACC0007'][objData2['branch_code']]}} -->
                                        {{objData2['branch_code']}}
                                    </div>
                                </div>
                                <br>
                                <div class="row" style="margin-left: 2%;margin-right: 2;">
                                    <div class="col-3 text-left ">
                                        <h6>Account Number :</h6>
                                    </div>
                                    <div class="col-3">{{objData2['bank_acct_no']}}</div>
                                    <div class="col-3 text-left">
                                        <h6>Balancing Period :</h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="balance" bindLabel="id" bindValue="id" [multiple]="false"
                                            placeholder="Select balancing period" (change)="changeDetails()"
                                            [(ngModel)]="obj['balancing']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="balancing"
                                            #balancing="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && balancing.invalid }" required>
                                        </ng-select>


                                        <!-- border color green -->
                                        <div [class.selection-box]="balancing.dirty && balancing.valid">
                                        </div>
                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && balancing.invalid" class="error-message">
                                            <div *ngIf="balancing.errors?.['required']">Please Select Balancing Period
                                            </div>
                                        </div>



                                        <!-- ----------------------------------validation end --------------- -->




                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button type="submit" class="btn btn-primary">Submit</button>


                                    </div>
                                </div>
                                <br>
                                <hr>
                            </form>

                            <div class="text-right"> <button (click)="print()" class="btn btn-primary"
                                    style="margin-left: 5%;">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export()">Export</button> &nbsp;&nbsp;
                            </div>
                            <br>

                            <div class=" example-container">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <!-- <th>Account Code</th> -->
                                            <th>Particulars</th>
                                            <th>Voucher Type</th>
                                            <th>Voucher Number</th>
                                            <th style="text-align: right;">Debit(Dr)</th>
                                            <th style="text-align: right;">Credit(Cr)</th>
                                            <th style="text-align: right;">Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of final_res">

                                            <td *ngIf="item['acct_dt']">
                                                {{mainService.dateFormatChange(item['acct_dt'])}}</td>
                                            <td *ngIf="!item['acct_dt']"></td>
                                            <!-- <td>{{obj['c_acc']}}</td> -->
                                            <td>{{item['jrnl_desc']}}</td>
                                            <td>{{item['jrnl_type']}}</td>
                                            <td>{{item['jrnl_id']}}</td>
                                            <td style="text-align:right">{{item['db']}}</td>
                                            <td style="text-align:right">{{item['cr']}}</td>
                                            <td style="text-align: right;">{{item['bal']}} <span
                                                    *ngIf="item['bal'] >=0">({{item['ind']}})</span></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>

                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading....</ngx-spinner>
