<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Vendors
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_ved_flag" class="nav-link active" href="#tab-7-1" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-line-chart"></i>&nbsp;
                                All Vendor List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_ved_flag" class="nav-link" href="#tab-7-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                                Create Vendor
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_ved_flag" class="nav-link" href="#tab-7-3" (click)="refresh()"
                                data-toggle="tab"><i class="ti-settings"></i>
                                Update Vendor

                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="cc_ved_flag" class="nav-link" href="#tab-7-4" (click)="refresh();"
                                data-toggle="tab"><i class="ti-settings"></i> Create New Legal Entity
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_ved_flag">
                            <br>

                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()"> Create
                                    Vendor</button> &nbsp; &nbsp;
                                <button class=" btn btn-outline-primary" (click)="gotoccrete()"> Create New Legal Entity
                                </button>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="party_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor ID


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_leagal_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_leagal_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_gstin_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor GSTIN
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_gstin_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor Phone Number
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_phone_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor Email
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_email}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_pan_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor Pan No
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_pan_no}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="party_adhar">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Vendor Aadhar No
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_adhar}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="delete">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="representative(element)"
                                                *ngIf="element.party_type == 'ORG'">View Representative </button>

                                            <button class="btn btn-primary" (click)="open_update(element)">
                                                Update
                                            </button>
                                            <button class="btn btn-danger" (click)="delete(element)">
                                                Delete
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>


                        <div *ngIf="c_ved_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor List </button>
                            </div>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1 [selectedIndex]="stepperIndex">

                                <mat-step [stepControl]="first" label="Search Legal Entity">


                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>
                                                Select Legal Entity Type :
                                                <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="le_type" bindLabel="value" bindValue="code"
                                                (change)="changeparty_type()" [multiple]="false"
                                                placeholder="Party Type" [(ngModel)]="Obj['party_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;">
                                                Enter Mobile Number/Email :<span style="color: red;">*</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" Enter Mobile Number/Email"
                                                class="form-control" [(ngModel)]="Obj['credential']">
                                        </div>
                                        <div class="col-3"></div>


                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="search()" class="btn btn-primary">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>


                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button MatStepper matStepperNext class="btn btn-primary"
                                                *ngIf="next_button_1">
                                                Next
                                            </button>
                                        </div>
                                    </div>



                                </mat-step>


                                <mat-step [stepControl]="fourth" id="registerStep" label="Choose Representative"
                                    *ngIf="Obj['party_type'] == 'ORG'">
                                    <!-- Done -->

                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;">
                                                Enter Representative Mobile Number/Email : <span
                                                    style="color: red;">*</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" Enter Representative Mobile Number/Email"
                                                class="form-control" [(ngModel)]="Obj['credential2']">
                                        </div>
                                        <div class="col-3"></div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="searchtab2()" class="btn btn-primary">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="cpObj['party_email']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="cpObj['party_leagal_name']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="cpObj['party_phone_no']" maxlength="10" disabled>

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                (click)="gotolist()">Back</button>
                                            <button MatStepper matStepperNext class="btn btn-primary"
                                                *ngIf="next_button_2">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fifth" id="registerStep" label="Provide Vendor Details">

                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-3 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR PERSONAL DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                VENDOR ID :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_id']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                GSTIN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_gstin_no']" maxlength="15"
                                                oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                PAN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_pan_no']" maxlength="10"
                                               oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                AADHAR NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="" class="form-control"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                [(ngModel)]="Obj['party_adhar']" maxlength="12" minlength="12">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR BANK DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME OF BANK :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="16">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK BRANCH :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                IFSC :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" " class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']" maxlength="11"
                                                style="text-transform:uppercase">
                                        </div>
                                    </div>

                                    <br> -->

                                    <div class="row">

                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="18" minlength="4">
                                        </div>
                                        <div class="col-2">
                                            <h6 style="margin-left: 2%;">
                                                IFS Code :
                                            </h6>
                                        </div>
                                        <div class="col-2">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']"  oninput="this.value = this.value.toUpperCase()"> 
                                                <span *ngIf="flage == true" class="text-success">{{status}}</span>
                                                <span *ngIf="flage == false" class="text-danger">{{status}}</span>
                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-success" (click)="fetchBankDtl()">Verify</button>
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK NAME :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']" maxlength=" 16" disabled>
                                        </div>
                                        <div class="col-2">
                                            <h6 style="margin-left: 2%;">
                                                BRANCH NAME :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']" disabled>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3">
                                            <h6>BANK ADDRESS : </h6>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_address']" disabled>
                                        </div>
                                    </div>
                                    <br><br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button class="btn btn-primary" (click)="submitOn3()">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="sixth" id="registerStep" label="Done">
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-3 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR PERSONAL DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                VENDOR ID :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_id']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                GSTIN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_gstin_no']" maxlength="15" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                PAN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_pan_no']" maxlength="10" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                AADHAR NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_adhar']" maxlength="12" minlength="12" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR BANK DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME OF BANK :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="16"
                                                disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK BRANCH :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                IFSC :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" " class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']" maxlength="11" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="Obj['party_type'] == 'ORG'">
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    REPRESENTATIVE DETAILS </h6>
                                            </div>


                                            <div class="col-4">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    EMAIL:
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                                <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="cpObj['party_email']" disabled>
                                            </div>
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    NAME:
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="Obj['party_leagal_name']"
                                                    disabled>

                                                <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="cpObj['party_leagal_name']"
                                                    disabled>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    MOBILE NUMBER :
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="number" name=""
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                    [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                                <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                    [(ngModel)]="cpObj['party_phone_no']" maxlength="10" disabled>

                                            </div>

                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>


                                            <button class="btn btn-primary" (click)="submit()">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>

                            <br>

                        </div>
                        <div *ngIf="u_ved_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor List </button>
                            </div>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2 [selectedIndex]="stepperIndex">

                                <mat-step [stepControl]="first" label="Search Legal Entity">
                                    <!-- Select Work Line -->

                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>
                                                Select Legal Entity Type :
                                                <span style="color: red;"> *</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="le_type" bindLabel="value" bindValue="code"
                                                (change)="changeparty_type()" [multiple]="false"
                                                placeholder="Party Type" [(ngModel)]="Obj['party_type']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false" disabled
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <br>
                                    <hr>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-VENDORS33']}}"
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text"
                                                placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-VENDORS33']}}"
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button matStepperPrevious class="btn btn-danger">Back</button>
                                            <button MatStepper matStepperNext class="btn btn-primary">
                                                Next
                                            </button>

                                        </div>
                                    </div>



                                </mat-step>
                                <mat-step [stepControl]="fourth" id="registerStep" label="Choose Representative"
                                    *ngIf="Obj['party_type'] == 'ORG'">
                                    <!-- Done -->
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6 style="margin-left: 2%;">
                                                Enter Mobile Number/Email : <span style="color: red;">*</span>
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['credential2']">
                                        </div>
                                        <div class="col-3"></div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="searchtab2()" class="btn btn-primary">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="cpObj['party_email']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>

                                            <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                class="form-control" [(ngModel)]="cpObj['party_leagal_name']" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="cpObj['party_phone_no']" maxlength="10" disabled>

                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button MatStepper matStepperNext class="btn btn-primary">
                                                Next
                                            </button>

                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fifth" id="registerStep" label="Provide Vendor Details">
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-3 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR PERSONAL DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                VENDOR ID :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="}" class="form-control"
                                                [(ngModel)]="Obj['party_id']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder="}"
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                GSTIN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_gstin_no']" maxlength="15"  oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                PAN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_pan_no']" maxlength="10"  oninput="this.value = this.value.toUpperCase()">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                AADHAR NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_adhar']" maxlength="12" minlength="12">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR BANK DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME OF BANK :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="16">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK BRANCH :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']">
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                IFSC :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" " class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']" maxlength="11">
                                        </div>
                                    </div>
                                    <br> -->
                                  
                                    <div class="row">

                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="16">
                                        </div>
                                        <div class="col-2">
                                            <h6 style="margin-left: 2%;">
                                                IFS Code :
                                            </h6>
                                        </div>
                                        <div class="col-2">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']" oninput="this.value = this.value.toUpperCase()">
                                                <span *ngIf="flage == true" class="text-success">{{status}}</span>
                                                <span *ngIf="flage == false" class="text-danger">{{status}}</span>
                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-success" (click)="fetchBankDtl()">Verify</button>
                                        </div>
                                    </div>
                                    <br>


                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK NAME :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']" maxlength=" 16" disabled>
                                        </div>
                                        <div class="col-2">
                                            <h6 style="margin-left: 2%;">
                                                BRANCH NAME :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']" disabled>
                                        </div>

                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-3">
                                            <h6>BANK ADDRESS : </h6>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_address']" disabled>
                                        </div>
                                    </div>
                                    <br><br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>
                                            <button MatStepper matStepperNext class="btn btn-primary"(click)='capitalize()'>
                                                Next </button>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="sixth" id="registerStep" label="Done">
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-3 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR PERSONAL DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                VENDOR ID :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_id']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_leagal_name']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                GSTIN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_gstin_no']" maxlength="15" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                EMAIL:
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']" disabled>
                                            <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                class="form-control" [(ngModel)]="Obj['party_email']">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                PAN :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_pan_no']" maxlength="10" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                AADHAR NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_adhar']" maxlength="12" minlength="12" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                MOBILE NUMBER :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input *ngIf="!Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                            <input *ngIf="Obj['le_flag']" type="number" name=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['party_phone_no']" maxlength="10">
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                        <div class="col-2 text-center">
                                            <h6 id="h" style="color: brown; min-height: 20px;">
                                                VENDOR BANK DETAILS
                                            </h6>
                                        </div>
                                        <div class="col-5">
                                            <hr class="hrline">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                NAME OF BANK :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_name']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                ACCOUNT NO. :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder=""
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                class="form-control" [(ngModel)]="Obj['party_acct_no']" maxlength="16"
                                                disabled>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                BANK BRANCH :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder="" class="form-control"
                                                [(ngModel)]="Obj['bank_branch_name']" disabled>
                                        </div>
                                        <div class="col-3 ">
                                            <h6 style="margin-left: 2%;">
                                                IFS Code :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="text" placeholder=" " class="form-control"
                                                [(ngModel)]="Obj['ifsc_code']" maxlength="11" disabled>
                                        </div>
                                    </div>
                                    <br>
                                    <div *ngIf="Obj['party_type'] == 'ORG'">
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-3 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    REPRESENTATIVE DETAILS </h6>
                                            </div>


                                            <div class="col-4">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    EMAIL:
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="Obj['party_email']" disabled>

                                                <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="cpObj['party_email']" disabled>
                                            </div>
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    NAME:
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="Obj['party_leagal_name']"
                                                    disabled>

                                                <input *ngIf="!Obj['le_flag'] " type="text" placeholder=""
                                                    class="form-control" [(ngModel)]="cpObj['party_leagal_name']"
                                                    disabled>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    MOBILE NUMBER :
                                                </h6>
                                            </div>
                                            <div class="col-3">
                                                <input *ngIf="Obj['le_flag']" type="number" name=""
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                    [(ngModel)]="Obj['party_phone_no']" maxlength="10" disabled>
                                                <input *ngIf="!Obj['le_flag'] " type="number" name=""
                                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                    id="ENTER MOBILE NUMBER" placeholder="" class="form-control"
                                                    [(ngModel)]="cpObj['party_phone_no']" maxlength="10" disabled>

                                            </div>

                                        </div>
                                        <br>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-danger"
                                                matStepperPrevious>Back</button>

                                            <button class="btn btn-primary" (click)="update()">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>

                            <br>

                        </div>
                        <div *ngIf="cc_ved_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor List </button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        Party Type :
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="le_type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="Party Type" [(ngModel)]="Obj['party_type']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Party Name : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" placeholder="Enter Party Name" [(ngModel)]="Obj['party_name']"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Party Email : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="email" placeholder="Enter Party Email" pattern="[a-z]*"
                                        [(ngModel)]="Obj['contact_email']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Party Phone Number : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="text"
                                        onkeypress="if (isNaN( String.fromCharCode(event.keyCode) )) return false;"
                                        maxlength="10" placeholder="Enter Party Phone Number"
                                        [(ngModel)]="Obj['contact_phone']" class="form-control">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['party_type']=='IND'">
                            <div class="row" *ngIf="Obj['party_type']=='IND'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Party Nick Name : </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" required min="10" max="20" placeholder="Party Name"
                                        [(ngModel)]="Obj['nick_name']" class="form-control">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['party_type']=='IND'">
                            <div class="row" *ngIf="Obj['party_type']=='IND'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Date Of Birth : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" placeholder="Enter DOB" [(ngModel)]="Obj['party_dob']"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['party_type']=='IND'">
                            <div class="row" *ngIf="Obj['party_type']=='IND'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Enter Password : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="password" placeholder="Enter Password"
                                        [(ngModel)]="Obj['ident_verify_secret']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>

                            <br>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)="submitlegal()" class="btn btn-primary"> Submit</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Representative Info </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;">
                            EMAIL:</h6>
                    </div>
                    <div class="col-3">


                        <input type="text" placeholder="" class="form-control" [(ngModel)]="cpObj['party_email']"
                            disabled>
                    </div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;">
                            NAME:</h6>
                    </div>
                    <div class="col-3">


                        <input type="text" placeholder="" class="form-control" [(ngModel)]="cpObj['party_leagal_name']"
                            disabled>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;">
                            MOBILE NUMBER :</h6>
                    </div>
                    <div class="col-3">

                        <input type="number" name="mobile_name"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            id="ENTER MOBILE NUMBER" placeholder="ENTER MOBILE NUMBER" class="form-control"
                            [(ngModel)]="cpObj['party_phone_no']" maxlength="10" disabled>

                    </div>

                </div>
                <br>


                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>