<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Details</div>
                    <!--Geographic Location -->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" (click)="getallcomplain()" data-toggle="tab">
                                All Complaint Reports</a>
                            <!-- Geographic Location List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="complain_status()" data-toggle="tab">Complaint Status Reports

                            </a>
                            <!-- Create Geographic Location-->
                        </li>


                    </ul>
                    <br>
                    <div class="tab-content">
                        <div *ngIf="complaint">
                            <table cellspacing="0" border="0">
                                <colgroup width="64"></colgroup>
                                <colgroup span="2" width="78"></colgroup>
                                <colgroup width="86"></colgroup>
                                <tr>
                                    <td class="tbl1" colspan=4 height="24" valign=top><b>
                                            <font size=4>Zone Wise ICMS Status </font>
                                        </b></td>
                                </tr>
                                <tr>
                                    <td class="tbl1" rowspan=2 height="58" valign=top><b>
                                            <font color="#000000">Zone</font>
                                        </b></td>
                                    <td class="tbl1" colspan=2 valign=top><b>
                                            <font color="#000000">Number of Applications</font>
                                        </b></td>
                                    <td class="tbl1" rowspan=2 valign=top><b>
                                            <font color="#000000">Forwarded to Field Staff for Survey</font>
                                        </b></td>
                                </tr>
                                <tr>
                                    <td class="tbl1" valign=top><b>
                                            <font color="#000000">From Public</font>
                                        </b></td>
                                    <td class="tbl1" valign=top><b>
                                            <font color="#000000">Staff Survey</font>
                                        </b></td>
                                </tr>
                                <tr *ngFor="let item of allreports">
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.zone_name}}</font>
                                        </b></td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000">{{item.public_cmp_count}}</font>
                                    </td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000">{{item.emp_camp_count}}</font>
                                    </td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000">{{item.assigned_cmp_count}}</font>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">Total Count</font>
                                        </b></td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000"><b>{{public_count}}</b></font>
                                    </td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000"><b>{{employee_count}}</b></font>
                                    </td>
                                    <td class="tbl1" valign=center sdval="0" sdnum="1033;">
                                        <font color="#000000"><b>{{aasigned_count}}</b></font>
                                    </td>
                                </tr>






                            </table>
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="print()"> Download </button>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="complaint_status">
                            <table cellspacing="0" border="0">
                                <colgroup width="150"></colgroup>
                                <colgroup width="200"></colgroup>
                                <colgroup span="3" width="150"></colgroup>
                                <colgroup width="150"></colgroup>
                                <colgroup span="3" width="150"></colgroup>
                                <colgroup width="150"></colgroup>
                                <colgroup width="150"></colgroup>
                                <colgroup span="2" width="150"></colgroup>
                                <tr>
                                    <td class="tbl1" colspan=11 height="24" valign=top><b>
                                            <font size=4>Zone And Officials ICMS Status</font>
                                        </b></td>
                                </tr>
                                <tr>
                                    <td class="tbl1" rowspan=2 height="58" valign=middle style="width:250px;"><b>
                                            <font color="#000000">Zone</font>
                                        </b></td>
                                    <td class="tbl1" colspan=4 valign=middle><b>
                                            <font color="#000000">Vihit Pradhikari/ Zonal Officer </font>
                                        </b></td>
                                    <td class="tbl1" colspan=3 valign=middle><b>
                                            <font color="#000000">AE </font>
                                        </b></td>
                                    <td class="tbl1" colspan=3 valign=middle><b>
                                            <font color="#000000">JE </font>
                                        </b></td>
                                </tr>
                                <tr>
                                    <td class="tbl1"  valign=middle style="width:500px;"><b>
                                            <font color="#000000">User Name</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Total</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Pending</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">View</font>
                                        </b></td>
                                    <!-- <td class="tbl1"  valign=middle><b><font color="#000000">User Name</font></b></td> -->
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Total</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Pending</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">View</font>
                                        </b></td>
                                    <!-- <td class="tbl1"  valign=middle><b><font color="#000000">User Name</font></b></td> -->
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Total</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">Pending</font>
                                        </b></td>
                                    <td class="tbl1" valign=middle><b>
                                            <font color="#000000">View</font>
                                        </b></td>
                                </tr>
                                <tr *ngFor="let item of allreports; let i=index">
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.zone_name}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.ZO_NAME}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.total_complaint}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.ZO_PENDING_CMP}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center>
                                        <button class="btn btn-sm btn-outline-primary" (click)="ZO_view(i)">View</button>
                                    </td>
                                    <!-- <td class="tbl1" height="35" valign=center><b>
                                    <font color="#000000">{{item.AE_NAME}}</font>
                                </b></td> -->
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.AE_ASSI_CMP}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.AE_PENDING_CMP}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center>
                                        <button class="btn btn-sm btn-outline-primary" (click)="AE_view(i)">View</button>
                                    </td>
                                    <!-- <td class="tbl1" height="35" valign=center><b>
                                <font color="#000000">{{item.JE_NAME}}</font>
                            </b></td> -->
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.JE_ASSI_CMP}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                            <font color="#000000">{{item.PENDING_CMP}}</font>
                                        </b></td>
                                    <td class="tbl1" height="35" valign=center>
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="JE_view(i)">View</button>
                                    </td>



                                </tr>
                                 <tr>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">Total count</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000"></font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{ZO_TOTAL_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{ZO_PEND_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000"></font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{AE_ASSI_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{AE_PEND_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000"></font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{JE_ASSI_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000">{{JE_PEND_CMP}}</font>
                                    </b></td>
                                    <td class="tbl1" height="35" valign=center><b>
                                        <font color="#000000"></font>
                                    </b></td>
                                 </tr>



                            </table>
                            <div class="row mt-5">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="print2()"> Download </button>
                                </div>
                            </div>
                        </div>



                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>




<div class="modal fade" id="details" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #AED6F1;">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <h6>Complaint Details</h6>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <table cellspacing="1" border="1">

                <tr>
                    <td><b>S.No</b></td>
                    <td><b>Complaint ID</b></td>
                    <td><b>Officer's Name</b></td>
                    <td><b>Zone</b></td>
                    <td><b>Assigned Date</b></td>

                </tr>
                  <tr *ngFor="let item of alldetails ; let i = index">
                       <td>{{i+1}}</td>
                       <td>{{item.complain_app_id}}</td>
                       <td>{{item.emp_name}}</td>
                       <td>{{item.Zone_name}}</td>
                       <td >{{item.comp_assign_date | date}}</td>

                  </tr>



                </table>


            </div>




            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="details2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #AED6F1;">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <h6>Complaint Details</h6>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <table cellspacing="1" border="1">

                <tr>
                    <td><b>S.No</b></td>
                    <td><b>Complaint ID</b></td>
                    <td><b>Officer's Name</b></td>
                    <td><b>Zone</b></td>
                    <td><b>Complaint Date</b></td>

                    

                </tr>
                  <tr *ngFor="let item of alldetails ; let i = index">
                       <td>{{i+1}}</td>
                       <td>{{item.complain_app_id}}</td>
                       <td>{{item.emp_name}}</td>
                       <td>{{item.Zone_name}}</td>
                       <td>{{item.complain_date | date}}</td>
                     

                  </tr>



                </table>


            </div>




            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

            </div>

        </div>
    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>