import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prop-inventory',
  templateUrl: './prop-inventory.component.html',
  styleUrls: ['./prop-inventory.component.css']
})
export class PropInventoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
