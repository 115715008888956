import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MainService } from './main.service';
import { map } from 'rxjs/operators';



const detail = {
  property_no:'7A/B-17/G-02',
  allottee_name:'RAJESH KUMAR',
  father_name:'',
  address:'VILLAGE- KATKAKHANPUR POST-DWARIKAGANJ DISTICT- SULTANPUR U.P.,',
  letter_no:'01/08/2021',
  Registration_no:'',
  property_cost:350000.00,
  registration_amt:25000.00,
  amt_after_regis:325000.00,
  mode_of_pay:'Installment',
  no_of_intallment:60,
  date_frt_install:'01/04/2021',
  pre_instaal_amt:68322.00,
  pre_instaal_str_date:'01/02/2021',
  pre_instaal_end_date:'31/03/2021',
  amt_install_fixed:265000.00,
  installment_amt:5621.00,
  fre_installment:'MONTHLY',
  rate_intrest_install:'9.50%',
  toltal_instrest_charged:'11.50%',
  addi_intrest_cal:'NA',


}

@Injectable({
  providedIn: 'root'
})
export class PropertyLedgerService {

  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/propertyLedger";
  }
  // /getLedgerData
  async getLedgerData(obj:any){
    const resp = this.http.get<any>(this.httpUrl + "/getLedgerData" + obj).toPromise().then(res =>{
      return res
    });
    return resp;
  }
  // /getDATAAccordingLedger
  async getLedgerDataAccOffer(obj:any){
    const resp = this.http.get<any>(this.httpUrl + "/getDATAAccordingLedger" + obj).toPromise().then(res =>{
      return res
    });
    return resp;
  }


  // async getPropertyDetais(obj:any){

  //   //console.log(obj)
  //   const resp = this.http.get<any>(this.httpUrl +'/getAllVacantDetails' + obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp

  // }
  async getPaymentDetails(obj:any){

    // console.log(obj)
    const resp = this.http.get<any>(this.httpUrl +'/getPaymentData' +obj).toPromise().then(res => {
      return res
    });
    return resp

  }



  getFetchlist(): Observable<any>{
    // return this.http.get<any>('http://upavponline.in/GPMSPublish/api/gatepass/GetPropertyPaymentDetails');

    // return this.http.get<any>('https://jsonplaceholder.typicode.com/todos').pipe(map(this.mapFetchList));// without html change
    // return this.http.get<any>('https://jsonplaceholder.typicode.com/todos'); change html
     return new Observable((d) => d.next(detail));
  }
}
