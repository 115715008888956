import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class VmsDashboardService {

  httpUrl;

  constructor(private http: HttpClient,private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/vms/dashboard";
  }

  async getAllCount(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/getAllCount' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
