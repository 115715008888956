import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PayrollService {
  adjustLeave(leaveObj: {}) {
    throw new Error('Method not implemented.');
  }

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/hr";
  }
  async getbillbydate(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getbillbydate' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async sendToAccount(obj :any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/eventgroup/createevent', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
//salary hold service

async getItReport(obj :any) {
  const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/bill/getItReport' + obj).toPromise().then(res => {
    return res;
  });
  return resp;
}

async getEmpReport(obj :any) {
  console.log("server response");
  const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/bill/getEmpReport' + obj).toPromise().then(res => {
    return res;
  });
  return resp;

}

async getPersonalInfo(obj :any) {
  const resp = await this.http.get<any>(this.main.httpUrl+'/hr/emp_info/empInfo/getPersonalInfo' + JSON.stringify(obj)).toPromise().then(res => {
    return res;
  });
  return resp;
}

 async updatesalary(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/salaryhold/updatesalary',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }




  async getLeaveEncashment(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/leaveencash/getLeaveEncashment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertarrayarrear(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/arrear/insertarrayarrear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getarrear(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/arrear/getarrear' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertarrear(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/arrear/insertarrear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updatearrear(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/arrear/updatearrear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deletearrear(obj :any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/payroll_info/arrear/deletearrear' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getEL(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/leaveencash/getEL' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async createLeaveEncashment(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/leaveencash/createLeaveEncashment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateLeaveEncashment(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/leaveencash/updateLeaveEncashment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteLeaveEncashment(obj :any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/payroll_info/leaveencash/deleteLeaveEncashment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteloan(obj :any) {
    // console.log(" service called",this.httpUrl +'/payroll_info/loan/deleteloan',obj);
    const resp = await this.http.post<any>(this.httpUrl+'/payroll_info/loan/deleteloan',obj).toPromise().then(res => {
      // console.log("internal --",res)
      return res;
    });
    return resp;
  }
  //hr
  // async getEmployeeMasterData(obj :any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/emp_info/empInfo/getAllEmployee' + JSON.stringify(obj)).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  //-------------this service for all employee list in the select employee dropdown----------/
  async getEmployeeMasterData(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/emp_info/empInfo/getAllListOfEmployee' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
    //-------------this service for all Active  employee list Only  in the select employee dropdown----------/
  // sasync get1EmployeeMasterData(obj :any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/emp_info/empInfo/get1AllListOfEmployee' + JSON.stringify(obj)).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  //s }
  async get1EmployeeMasterData(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/emp_info/empInfo/get1AllListOfEmployee' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllAttendence(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/attendance/getAttendanceDetail' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getPresentDays(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/attendance/getPresentDays' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllPosting(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment_info/post/getCurrentlyAssignedPosts' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //fixed pay
  async getMaxYearAndMonth(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getMaxYearAndMonth' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getstopsalary(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/salaryhold/getstopsalary' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getCurrentArrangement(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getCurrentArrangementOfParty' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  // async getAllFixedPay(obj :any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getAllFixedPay' + JSON.stringify(obj)).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  async getAllFixedPay(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getAllFixedPay' ,obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getEffectiveFixedSalary(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getEffectiveFixedPay' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getNewEffectiveFixedPay(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getNewEffectiveFixedPay' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getTimeSheet(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getTimeSheet' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllEmpTimeSheet(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getAllEmpTimeSheet' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async insertAttendenceSheet(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/fixedPayAmount/insertAttendenceSheet' ,obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSalaryOfEmployees(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/salaryCal/getSalaryOfEmployees',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getMonthAndYearForBill(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/bill/getMonthAndYearForBill',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
/*---------------------start  by ts-----------------*/
  async getlvl2(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getlvl2'+obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  /*----------------------End by ts----------------------*/
  async getBillForArrear(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/bill/getBillForArrear',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAttendenceSheet(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/fixedPayAmount/getAttendenceSheet' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addFixedPayComponent(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/fixedPayAmount/addFixedPayComponent', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addFixedPay(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/fixedPayAmount/addFixedPay', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async addFixedPayonly(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/fixedPayAmount/addFixedPayonly', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFixedPay(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/fixedPayAmount/updateFixedPay', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFixedPayonly(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/fixedPayAmount/updateFixedPayonly', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  //variable pay
  async getAllVariablePay(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/variablePay/getVariablePay' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getEffectiveVariablePay(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/variablePay/getEffectiveVariablePay' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async addVariablePay(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/variablePay/addVariablePay', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //salary-bill


  async getSalaryComponents(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/salary/getSalaryComponents' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getRemainingSalary(obj :any) {

    const resp = await this.http.get<any>(this.httpUrl + '/remainingSalary/getRemainingsalary' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async getAllCurrentArrangements(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getAllCurrentEstablishementInfo' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Avinash Mishra
  async getAllTransferEmployee(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getAllTransferedInfo' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getArrayAllCurrentEstablishementInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/establishment/getArrayAllCurrentEstablishementInfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async salaryAccrual(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/salary/salaryAccrual', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createContingentBill(obj :any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/contingentBill/createContingentBill', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async sendToApproval(obj :any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/approval/addapprovalstatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSalaryDocumentStatus(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/approval/getdataofapprovalstatus' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createparty(obj :any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/party/createparty', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllBankAccounts(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/emp_info/bankAccount/getAllBankAccounts' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }




  //paid-salary
  async getAllPaidSalary(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/salary/getSalaryRecords' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async salaryPaid(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/salary/salaryPaid', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  //loan
  async getAllLoanInfo(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/loan/getAllLoans' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addLoan(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/loan/applyForLoan', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async changeStatusOfLoan(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/loan/changeStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async disburseLoan(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/loan/disburseLoan', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLoanInstallmentInfo(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/loan/getLoanInstallmentInfo' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //bill
  async generateSalaryBill(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/bill/addBill', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async generateArrear(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/bill/addArrear', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async changeStatusOfBill(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/bill/changeStatusOfBill', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteBill(obj :any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/payroll_info/bill/deleteBill' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllBillId(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getAllBillId' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllBill(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getAllBills' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllComponent(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getAllComponent' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getMonthlyBill(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getMonthlyBill' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // paySlip
  async getSalarySlip(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getPaySlip' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
//shweta  lpctransfer
  async getSalarylpcTrans(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getSalarylpcTrans' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async getSalaryamtlpcTrans(obj :any) {
  //   const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getSalaryamtlpcTrans' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  async getCompCode(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/bill/getCompCode' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta
  //other-payment
  async deletefixedpay(obj :any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/payroll_info/fixedPayAmount/deletefixedpay' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSystemDate() {
    const res = await this.http.get<any>(this.main.httpUrl + '/metadata/sysAttribute/getSystemDate').toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updateOtherPayment(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/otherPayments/updateOtherPayment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateStatusOfPayment(obj :any) {
    const resp = await this.http.put<any>(this.httpUrl + '/payroll_info/otherPayments/updateStatusOfPayment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async defineOtherPayments(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/payroll_info/otherPayments/defineOtherPayments', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getOtherPayments(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/payroll_info/otherPayments/getOtherPayments' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getComponentDefinition(obj :any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/hr/payroll_info/salaryComponents/getComponentDefinition' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }


}
