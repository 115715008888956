import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService } from '../../service/main.service';
import { SettingService } from '../../service/setting.service';
import Swal from 'sweetalert2';
//import { element } from 'protractor';

declare var $: any

@Component({
  selector: 'app-emp-promotion',
  templateUrl: './emp-promotion.component.html',
  styleUrls: ['./emp-promotion.component.css']
})


export class EmpPromotionComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(private settingService: SettingService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService) { }

  displayedColumns = ['emp_id', 'emp_name', 'promotion_type_code', 'order_id', 'grade_pay_code', 'basic_pay', 'promotion_interval', 'promotion_date', 'effective_dt'];
  displayedColumns1 = ['emp_id', 'emp_name', 'next_promotion', 'next_promotion_date', 'action'];



  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  intArr: any = [{ code: '10', value: 'FIRST' }, { code: '16', value: 'SECOND' }, { code: '26', value: 'THIRD' }]
  newallEmplyees: any
  datasource: any;
  datasource1: any;
  allMatrix: any = [];
  matrixObj: any = {};
  baseArr: any = []
  addEstObj: any = {};
  acpObj: any = {};
  erpUser: any;
  b_acct_id: any = 11;
  promObj: any = {};
  allEmployeesObj: any = {};
  selectEmpObj: any = {};
  allPromotion: any = [];
  codeValueTechObj: any = {};
  allEmplyees: any = [];
  allCurrentArrangements: any = [];
  expPromotion: any = [];
  empNameMap: any;
  empServiceDateMap: any;
  selectedEstablishment: any = {};

  gradePayArr: any = []
  payScaleArr: any = []
  levelArr: any = []
  basicPayArr: any = []
  htmlgradePayArr: any = []
  htmlpayScaleArr: any = []
  htmllevelArr: any = []
  htmlbasicPayArr: any = []
  htmlDesignationArr: any = [];
  scheduledPromotions: any = []
  allPay: any = [];
  allBasicPay: any = {};
  today: any = '';
  dateToday: any;
  addDCPEstObj: any = {};
  personalInfo: any = {};
  salArr: any = [];
  allSCD: any = [];
  activeEmpArr: any = [];
  salArr1: any = [];
  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getDate();
    await this.getAllActiveEmployees();
    await this.getPersonalInfo();
    await this.getAllSalaryComponentDefination();
    await this.getAllPromotions();
    await this.getAllMatrix();
    await this.getFixedPay();

    await this.buildExpectedPromotionArray();

  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  async getAllSalaryComponentDefination() {

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = '2090-10-10';
    obj['status'] = ['ACTIVE', 'INACTIVE'];
    var resp = await this.settingService.getAllSalaryCD(obj);
    if (resp['error'] == false) {
      this.allSCD = resp.data;

    } else {
      Swal.fire('Error', 'Error while getting  all salary component list', 'error')
      //    this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //    duration: 5000
      //  });
    }
  }
  async addACP() {
    if (this.acpObj['promotion_date'] == undefined || this.acpObj['promotion_date'] == null || this.acpObj['promotion_date'] == '' ||
      this.acpObj['promotion_interval'] == undefined || this.acpObj['promotion_interval'] == null || this.acpObj['promotion_interval'] == '' ||
      this.acpObj['emp_id'] == undefined || this.acpObj['emp_id'] == null || this.acpObj['emp_id'] == '') {
      Swal.fire('Warning', "* fields required !", 'warning')
      return;
    }
    this.acpObj['b_acct_id'] = this.b_acct_id;
    this.acpObj['promotion_type_code'] = 'ACP';
    this.acpObj['order_id'] = 1;
    this.acpObj['grade_pay_code'] = 0;
    this.acpObj['create_user_id'] = this.erpUser.user_id;
    this.acpObj['promotion_status'] = 'SCHEDULED';
    this.spinner.show()
    // console.log(this.acpObj,"fkkkkkkg")
    var resp = await this.establishmentService.addACP(this.acpObj);

    if (resp['error'] == false) {
      await this.getAllPromotions();
      await this.buildExpectedPromotionArray();

      this.spinner.hide();

      Swal.fire('Success', 'Successfully Added ACP', 'success');
      $('.nav-tabs a[href="#tab-2"]').tab('show');
      this.acpObj = {}
    } else {
      this.spinner.hide();
      Swal.fire('Error', resp.data, 'error');
      this.acpObj = {}
    }
  }
  // self added
  incyear() {
    this.acpObj['b_acct_id'] = this.b_acct_id;
    this.acpObj['promotion_type_code'] = 'ACP';
    this.acpObj['order_id'] = 1;
    this.acpObj['grade_pay_code'] = 0;
    this.acpObj['create_user_id'] = this.erpUser.user_id;
    this.acpObj['promotion_status'] = 'SCHEDULED';
    for (let i = 0; i < this.allCurrentArrangements.length; i++) {
      if ((this.acpObj['promotion_interval'] == '10') && (this.acpObj['emp_id'] == this.allCurrentArrangements[i]['emp_id'])) {
        this.acpObj['promotion_date'] = this.allCurrentArrangements[i]['joining_date']

        var date = new Date(this.acpObj['promotion_date'])
        // console.log(date)
        //
        date.setFullYear(date.getFullYear() + 10);

        //console.log(date)

        //
        this.acpObj['promotion_date'] = date.toISOString().split('T')[0]
        //console.log(this.acpObj['promotion_date'])


      }

      else if ((this.acpObj['promotion_interval'] == '16') && (this.acpObj['emp_id'] == this.allCurrentArrangements[i]['emp_id'])) {
        this.acpObj['promotion_date'] = this.allCurrentArrangements[i]['joining_date']

        var date = new Date(this.acpObj['promotion_date'])
        //console.log(date)
        //
        date.setFullYear(date.getFullYear() + 16);

        //console.log(date)

        //
        this.acpObj['promotion_date'] = date.toISOString().split('T')[0]
        // console.log(this.acpObj['promotion_date'])

      }
      else if ((this.acpObj['promotion_interval'] == '26') && (this.acpObj['emp_id'] == this.allCurrentArrangements[i]['emp_id'])) {
        this.acpObj['promotion_date'] = this.allCurrentArrangements[i]['joining_date']

        var date = new Date(this.acpObj['promotion_date'])
        //console.log(date)
        //
        date.setFullYear(date.getFullYear() + 26);

        // console.log(date)

        //
        this.acpObj['promotion_date'] = date.toISOString().split('T')[0]
        // console.log(this.acpObj['promotion_date'])

      }

    }
    // self added
  }

  async deleteMessage(element: any) {
    Swal.fire({
      title: 'Are you Sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      // cancelButtonText: 'No, Take it!'
    }).then(async (result) => {
      if (result.value) {
        await this.deleteACP1(element)
      }
    })

  }

  async deleteACP1(element: any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.deleteACP(element)
      }
    })
  }

  async deleteACP(element: any) {
    var obj: any = new Object();

    obj['b_acct_id'] = this.b_acct_id;
    obj['promotion_id'] = element['promotion_id'];

    this.spinner.show()
    var resp = await this.establishmentService.deleteACP(obj);

    if (resp['error'] == false) {
      await this.getAllPromotions();
      await this.buildExpectedPromotionArray();
      this.spinner.hide();
      Swal.fire('Success', 'Successfully Deleted ACP', 'success');

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error in Deleting ACP', 'error');
    }
  }
  async getPersonalInfo() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getAllPartyFields(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        this.personalInfo[dt[i].emp_id] = dt[i];
      }

    } else {
      Swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }


  }
  async getDate() {
    var resp = await this.establishmentService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data;
    } else {

    }
  }

  async addEmpDCPPromotion() {
    this.addDCPEstObj['b_acct_id'] = this.b_acct_id;
    this.addDCPEstObj['create_user_id'] = this.erpUser.user_id;
    this.addDCPEstObj['promotion_status'] = "PROMOTED";
    this.spinner.show();
    var resp = await this.establishmentService.addDCPPromtion(this.addDCPEstObj);
    if (resp['error'] == false) {
      await this.incReq();
      await this.getAllActiveEmployees();
      await this.getAllPromotions();
      await this.buildExpectedPromotionArray();
      this.addDCPEstObj = {};
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      this.spinner.hide();
      Swal.fire('Success', 'Successfully Added Employee Promotion', 'success');
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding DCP Promotion Info Of Employee', 'error');

    }
  }
  async calSalaryAfterAcp(establishment: any, promotionObj: any) {
    var obj: any = {};
    var obj1: any = {};
    for (var i = 0; i < this.allPay.length; i++) {
      if (this.allPay[i]['emp_id'] == promotionObj['emp_id']) {
        obj1[this.allPay[i]['pay_component_code']] = this.allPay[i]['pay_component_amt'];
      }
    }
    obj['BASIC'] = { amt: parseFloat(promotionObj['basic_pay']), pay_code: 'PAY' };
    obj1['BASIC'] = parseFloat(promotionObj['basic_pay']);
    if (establishment['joining_type_code'] == 'DEPUTATION') {
      var mn = 0;
      if (this.personalInfo[establishment['emp_id']]['emp_local_addr_dist'] == 'LKO') {
        mn = obj1['BASIC'] * 5 / 100;
        if (mn > 1500) {
          mn = 1500;
        }
      } else {
        mn = obj1['BASIC'] * 10 / 100;
        if (mn > 3000) {
          mn = 3000;
        }
      }


      obj['DEP'] = { amt: parseFloat(mn.toFixed(2)), pay_code: 'PAY' };
      obj1['DEP'] = parseFloat(mn.toFixed(2));

    }
    for (var i = 0; i < this.allSCD.length; i++) {


      var amt = 0;
      if (this.allSCD[i].rate_type == 'PERCENTAGE') {
        amt = 0;
        var arr = this.allSCD[i].dependent_component.split(',');
        for (var j = 0; j < arr.length; j++) {
          if (obj1[arr[j]] == undefined) {
            obj1[arr[j]] = 0;
          }
          amt += obj1[arr[j]];

        }
        amt = parseFloat((amt * this.allSCD[i].amount / 100).toFixed(2));
        if (obj1[this.allSCD[i].component_code] != undefined && obj1[this.allSCD[i].component_code] > amt) {
          amt = obj1[this.allSCD[i].component_code];
        }
        obj1[this.allSCD[i].component_code] = amt;
        obj[this.allSCD[i].component_code] = { amt: amt, pay_code: this.allSCD[i].pay_code };


      } else {
        if (this.allSCD[i].upper_limit >= promotionObj['grade_pay_code'] && this.allSCD[i].lower_limit <= promotionObj['grade_pay_code']) {
          obj[this.allSCD[i].component_code] = { amt: this.allSCD[i].amount, pay_code: this.allSCD[i].pay_code };
          obj1[this.allSCD[i].component_code] = this.allSCD[i].amount;;

        }
      }


    }
    var fixPay = [];

    var comp = Object.keys(obj);
    for (var i = 0; i < comp.length; i++) {
      var ob: any = {};
      ob['b_acct_id'] = this.b_acct_id;
      ob['emp_id'] = establishment['emp_id'];
      ob['arr_id'] = establishment['arr_id'];
      ob['effective_start_dt'] = promotionObj['promotion_date'];
      ob['effective_end_dt'] = "2090-10-10"
      ob['status'] = 'ACTIVE';
      ob['create_user_id'] = this.erpUser.user_id;
      ob['component_status_code'] = 'ACTIVE';
      ob['pay_component_code'] = comp[i];
      ob['curr_cd'] = 'INR';
      ob['rate_unit'] = 'MNTH'
      if (ob['pay_component_code'] == 'NPS') {
        ob['pay_component_amt'] = Math.round(obj[comp[i]].amt);

      } else {
        ob['pay_component_amt'] = obj[comp[i]].amt;

      }
      ob['pay_code'] = obj[comp[i]].pay_code;
      fixPay.push(ob);
    }
    this.salArr = fixPay;
    for (let i = 0; i < this.salArr.length; i++) {
      for (let j = 0; j < this.allPay.length; j++) {
        if (this.salArr[i]['arr_id'] == this.allPay[j]['arr_id'] && this.salArr[i]['pay_component_code'] == this.allPay[j]['pay_component_code']) {
          this.salArr[i]['pay_id'] = this.allPay[j]['pay_id'];
          break;
        }
        this.salArr[i]['pay_id'] = 'N'
      }

    }
    this.salArr1 = [...this.salArr];
  }

  async incReq() {
    var obj1: any = new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['fixed_pay_info'] = this.salArr1;
    // obj1['end_dt'] = '2090-10-10';
    obj1['end_dt'] = '2037-12-01';
    var resp = await this.settingService.addFixedPayPromotion(obj1);
    if (resp['error'] == false) {
      $('#myModal').modal('hide')
      $('#myModal1').modal('hide')


    } else {
      $('#myModal').modal('hide')
      $('#myModal1').modal('hide')
      this.spinner.hide();
      Swal.fire("Error", "Error occurred while increment", 'error')


    }
  }
  async getFixedPay() {

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = this.dateToday.split(' ')[0];
    var resp = await this.establishmentService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.allPay = resp.data;
      for (var i = 0; i < this.allPay.length; i++) {
        if (this.allPay[i].pay_component_code == 'BASIC') {
          this.allBasicPay[this.allPay[i].arr_id] = this.allPay[i].pay_component_amt;
        }
      }


    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
  }

  async buildExpectedPromotionArray() {
    var arr: any = []
    this.expPromotion = []
    for (let i = 0; i < this.scheduledPromotions.length; i++) {
      var obj: any = new Object;
      obj = this.scheduledPromotions[i]
      for (let j = 0; j < this.allCurrentArrangements.length; j++) {
        if (this.allCurrentArrangements[j]['emp_id'] == this.scheduledPromotions[i]['emp_id']) {
          obj['emp_name'] = this.allCurrentArrangements[j]['emp_name']
          obj['old_grade_pay_code'] = this.allCurrentArrangements[j]['grade_pay_code']
          obj['old_pay_scale_code'] = this.allCurrentArrangements[j]['pay_scale_code']
          obj['old_level_code'] = this.allCurrentArrangements[j]['level_code']
          //shweta ///
          obj['old_basic_pay'] = Number(this.allBasicPay[this.allCurrentArrangements[j]['arr_id']])
          //obj['old_basic_pay'] = this.allCurrentArrangements[j]['emp_id']
          obj['old_designation_code'] = this.allCurrentArrangements[j]['designation_code']

          this.expPromotion.push(obj)


        }
      }

    }
    arr = this.expPromotion
    arr = [...new Set(arr)]
    var expPromotion = []
    for (let i = 0; i < arr.length; i++) {
      var obj: any = new Object();
      obj = Object.assign({}, arr[i]);
      obj['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])
      expPromotion.push(obj)
    }
    for (let i = 0; i < expPromotion.length; i++) {
      expPromotion[i]['temppromotion_date'] = this.mainService.dateformatchange(expPromotion[i]['promotion_date'])

    }
    this.datasource1 = new MatTableDataSource(expPromotion)
    this.datasource1.paginator = this.paginator1;
    this.datasource1.sort = this.sortCol2
    console.log("this si the list oof ACP ", this.expPromotion);

  }

  async getAllActiveEmployees() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code'] = ['ACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    this.empServiceDateMap = new Map;
    this.empNameMap = new Map;
    if (resp['error'] == false) {
      this.allCurrentArrangements = resp['data'];
      this.allEmplyees = resp['data'];

      this.newallEmplyees = []
      this.activeEmpArr = [];
      for (let i = 0; i < this.allEmplyees.length; i++) {

        obj = Object.assign({}, this.allEmplyees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
        if (obj['emp_status_code'] == "ACTIVE") {
          this.activeEmpArr.push(obj);
        }
      }
      // this.activeEmpArr=[...new Set(this.activeEmpArr)]
      for (let i = 0; i < this.allCurrentArrangements.length; i++) {
        this.empNameMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['emp_name'])
        this.empServiceDateMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['joining_service_date']);
      }
    }
  }

  async changeDCPEmployee() {
    for (let j = 0; j < this.allCurrentArrangements.length; j++) {
      if (this.allCurrentArrangements[j]['emp_id'] == this.addDCPEstObj['emp_id']) {
        this.addDCPEstObj['emp_name'] = this.allCurrentArrangements[j]['emp_name']
        this.addDCPEstObj['old_grade_pay_code'] = this.allCurrentArrangements[j]['grade_pay_code']
        this.addDCPEstObj['old_pay_scale_code'] = this.allCurrentArrangements[j]['pay_scale_code']
        this.addDCPEstObj['old_level_code'] = this.allCurrentArrangements[j]['level_code']
        this.addDCPEstObj['old_basic_pay'] = this.allBasicPay[this.allCurrentArrangements[j]['arr_id']];
        this.addDCPEstObj['old_designation_code'] = this.allCurrentArrangements[j]['designation_code'];
        this.selectedEstablishment = this.allCurrentArrangements[j];
      }
    }
    this.gradePayArr = []
    this.payScaleArr = []
    this.levelArr = []
    this.basicPayArr = []
    this.htmlgradePayArr = []
    this.htmlpayScaleArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    this.htmlDesignationArr = [];


    for (let i = 0; i < this.allMatrix.length; i++) {

      if (!this.payScaleArr.includes(this.allMatrix[i]['pay_band'])) {
        this.payScaleArr.push(this.allMatrix[i]['pay_band'])
        let obj:any = new Object

        obj['value'] = this.allMatrix[i]['pay_band']
        this.htmlpayScaleArr.push(obj)
      }

    }


    await this.changePayScaleDCP()
    await this.changeGradePayDCP()
    await this.changeLevelDCP()

  }

  async changeGradePayDCP() {

    this.levelArr = []
    this.basicPayArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addDCPEstObj['grade_pay_code'] == this.allMatrix[i]['grade_pay_code']) {
        if (!this.levelArr.includes(this.allMatrix[i]['level_code'])) {
          this.levelArr.push(this.allMatrix[i]['level_code'])
          let obj :any= new Object
          obj['value'] = this.allMatrix[i]['level_code']
          this.htmllevelArr.push(obj)
        }
      }
    }
  }

  async changePayScaleDCP() {
    this.gradePayArr = []
    this.levelArr = []
    this.basicPayArr = []
    this.htmlgradePayArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addDCPEstObj['pay_scale_code'] == this.allMatrix[i]['pay_band']) {
        if (!this.gradePayArr.includes(this.allMatrix[i]['grade_pay_code'])) {
          this.gradePayArr.push(this.allMatrix[i]['grade_pay_code'])
          let obj :any= new Object
          obj['value'] = this.allMatrix[i]['grade_pay_code']
          this.htmlgradePayArr.push(obj)
        }
      }
    }
  }
  async changeLevelDCP() {
    this.basicPayArr = []
    this.htmlbasicPayArr = []

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addDCPEstObj['level_code'] == this.allMatrix[i]['level_code']) {
        if (!this.levelArr.includes(this.allMatrix[i]['basic_pay'])) {
          this.levelArr.push(this.allMatrix[i]['basic_pay'])
          let obj:any = new Object
          obj['value'] = this.allMatrix[i]['basic_pay']
          this.htmlbasicPayArr.push(obj)
        }
      }
    }
  }
  async getAllMatrix() {
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allMatrix = resp.data;
    } else {
      Swal.fire('Error', `${resp.data}`, 'error')
      // this.snackBar.open(resp.data, 'Error', {
      //   duration: 5000
      // });
    }
  }

  async promote(element: any) {
    this.gradePayArr = []
    this.payScaleArr = []
    this.levelArr = []
    this.basicPayArr = []
    this.htmlgradePayArr = []
    this.htmlpayScaleArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    this.htmlDesignationArr = [];
    this.addEstObj = Object.assign({}, element);
    for (var i = 0; i < this.allCurrentArrangements.length; i++) {
      if (this.allCurrentArrangements[i]['emp_id'] == element['emp_id']) {
        this.selectedEstablishment = this.allCurrentArrangements[i];
      }
    }
    for (let i = 0; i < this.allMatrix.length; i++) {

      if (!this.payScaleArr.includes(this.allMatrix[i]['pay_band'])) {
        this.payScaleArr.push(this.allMatrix[i]['pay_band'])
        let obj:any = new Object

        obj['value'] = this.allMatrix[i]['pay_band']
        this.htmlpayScaleArr.push(obj)
      }




      if (this.addEstObj['old_pay_scale_code'] == this.allMatrix[i]['pay_band']) {

        if (Number(this.addEstObj['old_grade_pay_code']) == this.allMatrix[i]['grade_pay_code']) {

          if (this.addEstObj['old_level_code'] == this.allMatrix[i]['level_code']) {
            if (this.addEstObj['old_basic_pay'] < this.allMatrix[i]['basic_pay']) {
              if (this.addEstObj['compare_basic_pay'] == undefined) {
                this.addEstObj['compare_basic_pay'] = this.allMatrix[i]['basic_pay']
              }
              else {
                if (this.addEstObj['compare_basic_pay'] > this.allMatrix[i]['basic_pay']) {
                  this.addEstObj['compare_basic_pay'] = this.allMatrix[i]['basic_pay']

                }
              }

            }
          }
        }
      }
    }
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addEstObj['compare_basic_pay'] < this.allMatrix[i]['basic_pay']) {
        if ((Number(this.addEstObj['old_level_code']) + 1) == this.allMatrix[i]['level_code']) {
          if (this.addEstObj['basic_pay'] == undefined) {
            this.addEstObj['basic_pay'] = this.allMatrix[i]['basic_pay']
            this.addEstObj['pay_scale_code'] = this.allMatrix[i]['pay_band']
            this.addEstObj['grade_pay_code'] = this.allMatrix[i]['grade_pay_code']

            this.addEstObj['level_code'] = this.allMatrix[i]['level_code']

          }
          else {
            if (this.addEstObj['basic_pay'] > this.allMatrix[i]['basic_pay']) {
              this.addEstObj['basic_pay'] = this.allMatrix[i]['basic_pay']
              this.addEstObj['pay_scale_code'] = this.allMatrix[i]['pay_band']
              this.addEstObj['grade_pay_code'] = this.allMatrix[i]['grade_pay_code']
              this.addEstObj['level_code'] = this.allMatrix[i]['level_code']

            }
          }
        }

      }
    }
    await this.changePayScale()
    await this.changeGradePay()
    await this.changeLevel()


    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }
  async reject(element: any) {
    var obj = Object.assign({}, element);
    obj['promotion_status'] = "REJECTED";
    obj['update_user_id'] = this.erpUser.user_id;
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.establishmentService.rejectPromotion(obj);
    if (resp['error'] == false) {
      await this.getAllPromotions();
      this.spinner.hide();
      Swal.fire('Success', 'Promotion Info Rejected', 'success')
      // this.snackBar.open("Promotion Info Rejected", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while rejecting Promotion Info Of Employee', 'error')
      // this.snackBar.open("Error while rejecting Promotion Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }



  async getAllPromotions() {

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.establishmentService.getPreviousPromotions(obj);
    if (resp['error'] == false) {
      this.allPromotion = []
      this.scheduledPromotions = []
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['promotion_status'] == 'PROMOTED') {
          var ob = Object.assign({}, resp.data[i]);
          ob['tempid'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(ob['emp_id'])

          this.allPromotion.push(ob);
        }

        if (resp.data[i]['promotion_status'] == 'SCHEDULED') {
          this.scheduledPromotions.push(resp.data[i])
        }
      }
      for (let i = 0; i < this.allPromotion.length; i++) {
        this.allPromotion[i]['temppromotion_effective_dt'] = this.mainService.dateformatchange(this.allPromotion[i]['promotion_effective_dt'])
        this.allPromotion[i]['temppromotion_date'] = this.mainService.dateformatchange(this.allPromotion[i]['promotion_date'])

      }
      this.datasource = new MatTableDataSource(this.allPromotion)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort
    } else {
      Swal.fire('Error', 'Error while getting employee list', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  askAcp() {
    if (
      this.addEstObj['order_id'] == undefined || this.addEstObj['order_id'] == null || this.addEstObj['order_id'] == '' ||
      this.addEstObj['basic_pay'] == undefined || this.addEstObj['basic_pay'] == null || this.addEstObj['basic_pay'] == '' ||
      this.addEstObj['level_code'] == undefined || this.addEstObj['level_code'] == null || this.addEstObj['level_code'] == '' ||
      this.addEstObj['grade_pay_code'] == undefined || this.addEstObj['grade_pay_code'] == null || this.addEstObj['grade_pay_code'] == '' ||
      this.addEstObj['pay_scale_code'] == undefined || this.addEstObj['pay_scale_code'] == null || this.addEstObj['pay_scale_code'] == ''
    ) {
      Swal.fire('Warning', '* fields required !', 'warning');
      return;
    }
    this.calSalaryAfterAcp(this.selectedEstablishment, this.addEstObj)
    $('#myModal').modal('show');
  }
  askDpc() {
    if (this.addDCPEstObj['effective_dt'] == undefined || this.addDCPEstObj['effective_dt'] == null || this.addDCPEstObj['effective_dt'] == '' ||
      this.addDCPEstObj['order_id'] == undefined || this.addDCPEstObj['order_id'] == null || this.addDCPEstObj['order_id'] == '' ||
      this.addDCPEstObj['designation_code'] == undefined || this.addDCPEstObj['designation_code'] == null || this.addDCPEstObj['designation_code'] == '' ||
      this.addDCPEstObj['basic_pay'] == undefined || this.addDCPEstObj['basic_pay'] == null || this.addDCPEstObj['basic_pay'] == '' ||
      this.addDCPEstObj['level_code'] == undefined || this.addDCPEstObj['level_code'] == null || this.addDCPEstObj['level_code'] == '' ||
      this.addDCPEstObj['grade_pay_code'] == undefined || this.addDCPEstObj['grade_pay_code'] == null || this.addDCPEstObj['grade_pay_code'] == '' ||
      this.addDCPEstObj['pay_scale_code'] == undefined || this.addDCPEstObj['pay_scale_code'] == null || this.addDCPEstObj['pay_scale_code'] == '' ||
      this.addDCPEstObj['emp_id'] == undefined || this.addDCPEstObj['emp_id'] == null || this.addDCPEstObj['emp_id'] == '') {
      Swal.fire("Warning", "* fields required", 'warning');
      return;
    }
    for (var i = 0; i < this.allCurrentArrangements.length; i++) {
      if (this.allCurrentArrangements[i]['emp_id'] == this.addDCPEstObj['emp_id']) {
        this.selectedEstablishment = this.allCurrentArrangements[i];
      }
    }
    this.addDCPEstObj['promotion_date'] = this.addDCPEstObj['effective_dt']
    this.calSalaryAfterAcp(this.selectedEstablishment, this.addDCPEstObj)
    $('#myModal1').modal('show');
  }
  deleteSalArr(i:any) {
    this.salArr.splice(i, 1);
  }
  async addEmpPromotion() {
    this.addEstObj['b_acct_id'] = this.b_acct_id;
    this.addEstObj['create_user_id'] = this.erpUser.user_id;
    this.addEstObj['promotion_status'] = "PROMOTED";
    this.spinner.show();
    var resp = await this.establishmentService.addPromtion(this.addEstObj);
    if (resp['error'] == false) {
      await this.incReq();
      await this.getAllActiveEmployees();
      await this.getAllPromotions();
      await this.buildExpectedPromotionArray();
      this.spinner.hide();
      this.addEstObj = {};
      $('#myModal').modal('hide')
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      Swal.fire('Success', 'Employee Promoted Successfully ', 'success')
      // this.snackBar.open("Employee Promoted Successfully", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Promotion Info Of Employee', 'error')
      // this.snackBar.open("Error while Adding Promotion Info Of Employee", 'Error', {
      //   duration: 5000
      // });
      $('#myModal').modal('hide')
    }

  }


  async changePayScale() {
    this.gradePayArr = []
    this.levelArr = []
    this.basicPayArr = []
    this.htmlgradePayArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addEstObj['pay_scale_code'] == this.allMatrix[i]['pay_band']) {
        if (!this.gradePayArr.includes(this.allMatrix[i]['grade_pay_code'])) {
          this.gradePayArr.push(this.allMatrix[i]['grade_pay_code'])
          let obj :any= new Object
          obj['value'] = this.allMatrix[i]['grade_pay_code']
          this.htmlgradePayArr.push(obj)
        }
      }
    }
  }

  async changeLevel() {
    this.basicPayArr = []
    this.htmlbasicPayArr = []

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addEstObj['level_code'] == this.allMatrix[i]['level_code']) {
        if (!this.levelArr.includes(this.allMatrix[i]['basic_pay'])) {
          this.levelArr.push(this.allMatrix[i]['basic_pay'])
          let obj :any= new Object
          obj['value'] = this.allMatrix[i]['basic_pay']
          this.htmlbasicPayArr.push(obj)
        }
      }
    }
  }


  async changeGradePay() {

    this.levelArr = []
    this.basicPayArr = []
    this.htmllevelArr = []
    this.htmlbasicPayArr = []
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (this.addEstObj['grade_pay_code'] == this.allMatrix[i]['grade_pay_code']) {
        if (!this.levelArr.includes(this.allMatrix[i]['level_code'])) {
          this.levelArr.push(this.allMatrix[i]['level_code'])
          let obj :any= new Object
          obj['value'] = this.allMatrix[i]['level_code']
          this.htmllevelArr.push(obj)
        }
      }
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }
  async refresh() {
    this.addEstObj = {}
  }
}
