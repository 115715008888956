<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Auction Bid Rule</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" click="getAllBidRule()" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                List Auction Bid Rule</a>
                        </li>
                        <li class="nav-item" >
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings mr-1"></i>
                                Create Auction Bid Rule</a>
                        </li>
                    </ul>

                    <div class="tab-content">


                        <div class="tab-pane fade show active" id="tab-1">
                            <div class="row">
                                <div class="col-4">
                                    <!-- <mat-form-field > -->
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="Filter..">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">

                                </div>
                                <!-- <div class="col-4 text-right">
                                    <button (click)="print1()" class="btn btn-outline-success">Download List</button>
                                </div> -->

                            </div>
                            <!-- <hr> -->
                                <div class="example-container">

                                    <table mat-table [dataSource]="dataSource" matSort >
                                        <!-- ['Sr','Start Amount','End Amount','Cofficient'] -->
                                        <ng-container matColumnDef="Sr">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Sr no.</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element;let i = index;" class="right">
                                                {{ i +1 }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Start Amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                Start Amount</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{element.start_amount }} </td>
                                        </ng-container>
                                         <ng-container matColumnDef="End Amount">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                End Amount</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{element['end_amount'] }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Cofficient">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                Co-efficient </b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{element['cofficient'] }}  </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b>
                                                Action </b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element;let i = index;" class="right">
                                                <button (click)="deleteAuctionBid(element)" class="btn btn-danger" >Delete</button>  </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>


                        <div class="tab-pane fade show " id="tab-2">
                            <div class="row mt-3">
                                <div class="col-3"></div> <div class="col-2 text-right"> <h6>From (Amount in Rupees) :</h6></div>
                                <div class="col-2"><input class="form-control" type="number" name="" id="" [(ngModel)]="ObjToInsert['start_amount']" ></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3"></div> <div class="col-2 text-right"> <h6>To (Amount in Rupees) :</h6></div>
                                <div class="col-2"><input class="form-control" type="number" name="" id="" [(ngModel)]="ObjToInsert['end_amount']"></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3"></div> <div class="col-2 text-right"><h6>Co-efficient :</h6></div>
                                <div class="col-2"><input class="form-control" type="number" name="" id="" [(ngModel)]="ObjToInsert['cofficient']" ></div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12 text-center">
                                    <Button class="btn btn-success " (click)="submit()" >Submit</Button>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
