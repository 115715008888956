import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }
  // async getAllPropHierarchy(obj:any) {
  //   console.log('called')
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getAllpropHierarchy' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async insertPropHierarchyNode(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/propHier/insertpropHierarchyNode' , obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async updatePropHierarchyNode(obj:any) {
  //   const resp = await this.http.put<any>(this.main.httpUrl + '/property/propHier/updatepropHierarchyNode' , obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getuserAssigneddata(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/icms/icmsdataAssign/getuserAssigneddata' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // *****************************************************************************************
  async getEmdAssigneddata2(obj:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/emd/data-assignment/getEmdAssigned' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertDataAssignment(obj:any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/emd/data-assignment/insertDataAssignment' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateDataAssignment(obj:any) {
    const resp = await this.http.put<any>(this.main.httpUrl + '/emd/data-assignment/updateDataAssign' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
