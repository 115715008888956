<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Fields Manage -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" href="#tab-1" data-toggle="tab"
                                (click)="refresh()"><i class="ti-bar-chart"></i>
                                <!-- All Fields List -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" href="#tab-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle"></i>
                                <!-- Create Field -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                <!-- Update Field -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields4']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-danger"
                                        style="border-radius: 12px;" (click)=open_create()>Create Field</button>
                                </div>
                            </div>

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="field_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Field ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.field_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="field_business_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Field Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_business_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="field_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Field Code -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_code }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="open_update(element)">
                                                <!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields9']}}</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 12px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Field Business Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields10']}}
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Field Code : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields11']}}
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Field Technical Name : -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields12']}}
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_technical_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">
                                        <!-- Submit -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields13']}}</button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 12px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Field Name: -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields14']}}
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">
                                        <!-- Update -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields15']}}</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>
    <!-- Loading.... -->{{mainService.allLableShowObjHR[mainService.language_cd+'Fields16']}}</ngx-spinner>
