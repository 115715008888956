import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class StoreKeeperService {
  httpUrl;
  constructor(private main: MainService, private http: HttpClient) {
    this.httpUrl = this.main.httpUrl + "/account/storekeeper";
  }


  async createStoreKeeper(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/createStoreKeeper', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async getStoreKeeper(obj:any){
  //   const resp = await this.http.get<any>(this.httpUrl + '/getStoreKeeper' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  // async deleteStoreKeeper(obj:any){
  //   const resp = await this.http.delete<any>(this.httpUrl + '/deleteStoreKeeper' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async updateStoreKeeper(obj:any){
  //   const resp = await this.http.put<any>(this.httpUrl + '/updateStoreKeeper', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }


  // async printStoreKeeper(obj:any){
  //   const resp = await this.http.post<any>(this.httpUrl + '/printStoreKeeper', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  //////////////////////////////////workflow data  ///////////////////////////////////////////////////////


  async getworkFlowData(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getworkFlowData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async changeStatus(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/changeStatus', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async fileApproved(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/fileApproved', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteItem(obj:any){
    const resp = await this.http.delete<any>(this.httpUrl + '/deleteItem' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getPandingTask(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getPendingTask' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async rejectTask(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/rejectTask' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async viewIteminfo(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/viewIteminfo' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
