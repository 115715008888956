<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        HR DATA ASSIGNMENT
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" (click)="reset()" *ngIf="list_flag" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>&nbsp;
                                LIST
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-2" (click)="reset()" *ngIf="create_flag" data-toggle="tab"><i class="ti-settings"></i>
                                Hierarchy Node Assignment &nbsp;
                            </a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Create Hierarchy Node Assignment</button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;"> Select Node<span style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']">Level</span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-4" style="text-align: center;"><button class="btn btn-outline-success" (click)=find()>Search</button></div>
                                <div class="col-4"></div>
                            </div>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="example-container">
                                    <table style="width: 100%" mat-table [dataSource]="dataSource" matSort>


                                        <ng-container matColumnDef="user_id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                <b>
                                                    Employee Name
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.user_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_cd">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header><b>
                                                Node Type
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element">
                                                {{element.node_cd}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Node Level
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.level}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Roles">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Role Name
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.roles}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_path">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Node Path
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.path}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="node_desc">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                <b>
                                                    Node Description
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                {{element.node_desc}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <b>
                                                    Action
                                                </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index;">
                                                <button class="btn btn-primary" *ngIf="element.status=='ACTIVE'" (click)="inactive(element)">
                                                    INACTIVE
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>



                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-7-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">Select Node <span style="color: red;"> *</span></h6>
                                    <!--  -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']">Level </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>

                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                                    </h6>
                                </div>
                                <div class="col-6">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">
                                        Node User : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="filterUser" [multiple]="false" bindLabel="desc" bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" placeholder="" name="user_id" [(ngModel)]="Obj['user_id']">
                                    </ng-select>
                                    <br>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>
    Loading....
</ngx-spinner>



<ngx-spinner type="ball-atom">
    <p style="color: white">
        Loading...
    </p>
</ngx-spinner>
