import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from "@angular/platform-browser";
import swal from "sweetalert2";
//oc
// import { SettingService } from "../../accounts/service/setting.service";
// private settingService: SettingService
//ec
// import { SettingService } from "../service/se";
import { MatStepper } from "@angular/material/stepper";
//oc
// import { ThrowStmt } from "@angular/compiler";
//ec
declare var $: any;

//************************************************* */
import { MainService as MD_mainService } from "../../md/service/main.service";
import { MasterDataService } from "../../emb/service/master-data.service";
//oc
// import { MainService as MainServiceAdmin } from "../../admin/service/main.service";
// public MainServiceAdmin: MainServiceAdmin,
//ec
import { MainService } from "../../emb/service/main.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-vendors",
  templateUrl: "./vendors.component.html",
  styleUrls: ["./vendors.component.css"],
})
export class VendorsComponent implements OnInit {
  isLinear = false;
  first:any = true;
  second:any = true;
  third:any = true;
  fourth:any = true;
  fifth:any = true;
  sixth:any = true;
  stepperIndex = 0;
  displayedColumns = [
    "party_id",
    "party_leagal_name",
    "party_gstin_no",
    "party_phone_no",
    "party_email",
    "party_pan_no",
    "party_adhar",
    "delete",
  ];
  email:any;
  password:any;
  confirm_pass:any;
  party = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  FirstName:any;
  LastName:any;
  User_Email:any;
  le_type = [
    { code: "IND", value: "Individual" },
    { code: "ORG", value: "Organization" },
  ];
  b_acct_id:any;
  ebillUser:any;

  l_ved_flag = true;
  c_ved_flag = false;
  u_ved_flag = false;
  cc_ved_flag = false;
  constructor(
    private MD_mainService: MD_mainService,

    private MasterDataService: MasterDataService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService ,
    private sanitizer: DomSanitizer,
    public mainService: MainService,

  ) { }
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild("stepper1", { static: false }) stepper1!: MatStepper;
  @ViewChild("stepper2", { static: false }) stepper2!: MatStepper;

  async ngOnInit() {
    this.Obj["le_flag"] = true;
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.getAllLegalEntity();
    this.getparty();
  }
  changeparty_type() {
    let party_type = this.Obj["party_type"];
    this.Obj = {};
    this.Obj["party_type"] = party_type;
  }
  AllLegalEntity = [];
  async getAllLegalEntity() {
    //oc
    // var resp = await this.MainServiceAdmin.getCurrentLegalEntity(
    //   this.b_acct_id
    // );
    //ec

    // rc
    var resp:any=[]
    //lc
    console.log(resp);
    if (resp["error"] == false) {
      this.AllLegalEntity = resp.data;
    } else {
    }
  }
  gotolist() {
    this.Obj = {};
    this.l_ved_flag = true;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
    this.cc_ved_flag = false;
    this.getAllLegalEntity();
    this.getparty();
  }

  gotocrete() {
    this.Obj = {};
    this.l_ved_flag = false;
    this.c_ved_flag = true;
    this.u_ved_flag = false;
    this.cc_ved_flag = false;
  }
  gotoccrete() {
    this.Obj = {};
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = false;
    this.cc_ved_flag = true;
  }
  cpObj:any = {};
  next_button_1 = false;
  async search() {
    this.Obj["le_flag"] = true;
    this.Obj["party_leagal_name"] = null;
    this.Obj["party_phone_no"] = null;
    this.Obj["party_email"] = null;
    console.log(this.Obj["credential"]);
    console.log(this.AllLegalEntity);
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    if (isNaN(this.Obj["credential"])) {
      obj["contact_email"] = this.Obj["credential"];
      this.Obj["contact_email"] = this.Obj["credential"];
    } else {
      obj["phone_no"] = this.Obj["credential"];
      this.Obj["phone_no"] = this.Obj["credential"];
    }
    this.spinner.show();
    var resp = await this.MD_mainService.getCurrentLegalEntity(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      if (resp["data"].length > 0) {
        console.log(resp);
        if (resp["data"][0]["party_type"] == this.Obj["party_type"]) {
          this.Obj["party_leagal_name"] = resp["data"][0]["party_name"];
          this.Obj["party_phone_no"] = resp["data"][0]["phone_no"];
          this.Obj["party_email"] = resp["data"][0]["contact_email"];
          this.Obj["le_flag"] = false;
          this.Obj["le_id"] = resp["data"][0]["le_id"];
          this.next_button_1 = true;
        }
      }
    }

    if (this.Obj["party_type"] == undefined || this.Obj["party_type"] == null) {
      this.next_button_1 = false;
      swal.fire("Error", "Please Select Party Type", "error");
    } else if (
      this.Obj["party_leagal_name"] == undefined ||
      this.Obj["party_leagal_name"] == null
    ) {
      this.next_button_1 = false;
      swal.fire(
        "Error",
        "The Legal Entity does not exist. You will need to first create a new legal entity with these details before you can setup the legal entity as Vendor.",
        "error"
      );
    }
  }
  next_button_2 = false;

  async searchtab2() {
    this.cpObj = new Object();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    if (isNaN(this.Obj["credential2"])) {
      obj["contact_email"] = this.Obj["credential2"];
      this.Obj["contact_email"] = this.Obj["credential2"];
    } else {
      obj["phone_no"] = this.Obj["credential2"];
      this.Obj["phone_no"] = this.Obj["credential2"];
    }
    this.spinner.show();
    var resp = await this.MD_mainService.getCurrentLegalEntity(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      if (resp["data"].length > 0) {
        console.log(resp);
        if (resp["data"][0]["party_type"] == "IND") {
          this.cpObj["party_type"] = "IND";
          this.cpObj["party_leagal_name"] = resp["data"][0]["party_name"];
          this.cpObj["party_phone_no"] = resp["data"][0]["phone_no"];
          this.cpObj["party_email"] = resp["data"][0]["contact_email"];
          this.cpObj["le_flag"] = false;
          this.cpObj["le_id"] = resp["data"][0]["le_id"];
          this.Obj["le_flag"] = false;
          this.next_button_2 = true;
        }
      }
    }

    if (
      this.cpObj["party_type"] == undefined ||
      this.cpObj["party_type"] == null
    ) {
      this.next_button_2 = false;
      swal.fire(
        "Error",
        "The Legal Entity does not exist. You will need to first create a new legal entity with these details before you can setup the legal entity as Vendor.",
        "error"
      );
    }
  }
  partyduplicatearr:any = [];
  async getparty() {
    this.partyduplicatearr = [];
    var resp = await this.MasterDataService.getAllparties(this.b_acct_id);

    if (resp["error"] == false) {
      this.party = resp.data;
      for (let i = 0; i < this.party.length; i++) {
        this.partyduplicatearr.push(this.party[i]["le_id"]);
        this.partyduplicatearr.push(this.party[i]["rep_le_id"]);
      }
      this.datasource = new MatTableDataSource(this.party);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }
  }

  async delete(element:any) {
    swal
      .fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      })
      .then((result) => {
        if (result.value) {
          this.finaldelete(element);
        }
      });
  }
  underAgeValidate(j:any, d:any) {
    var dob = d.split("-");
    var year = parseInt(dob[0]);
    var month = parseInt(dob[1]) - 1;
    var day = parseInt(dob[2]);
    var today = new Date(j);
    var age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() == month && today.getDate() < day)
    ) {
      age--;
    }
    return age;
  }
  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return false;
    } else {
      return true;
    }
  }
  async submitlegal() {
    if (this.Obj["party_dob"]) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      let today_date = mm + "-" + dd + "-" + yyyy;
      var jdt = today_date;
      var ddt = this.Obj["party_dob"];
      var yr:any = this.underAgeValidate(jdt, ddt);
    }
    if (this.Obj['contact_phone']) {
      if (this.Obj['contact_phone']) {
        if (this.Obj['contact_phone'].toString().length != 10) {
          Swal.fire("Info", 'Enter valid number', 'info')
          return
        }
      }
    }

    this.Obj["valid_upto"] = "2030-10-22";
    if (!this.Obj["party_type"]) {
      swal.fire("Error", "Please Select Legal Entity Type", "error");
    } else if (!this.Obj["contact_email"]) {
      swal.fire("Error", "Please Enter Email", "error");
    } else if (!this.validate(this.Obj["contact_email"])) {
      swal.fire("Error", "Please Enter A Valid Email", "error");
    } else if (!this.Obj["contact_phone"]) {
      swal.fire("Error", "Please Enter Mobile Number", "error");
    } else if (!this.Obj["party_name"]) {
      swal.fire("Error", "Please Enter Party Name", "error");
    } else if (!this.Obj["party_dob"] && this.Obj["party_type"] == "IND") {
      swal.fire("Error", "Please Enter Date Of Birth", "error");
    } else if (yr < 18 && this.Obj["party_type"] == "IND") {
      swal.fire("Error", "Your Age less than 18", "error");
    } else if (
      !this.Obj["ident_verify_secret"] &&
      this.Obj["party_type"] == "IND"
    ) {
      swal.fire("Error", "Please Enter Password", "error");
    } else {
      var obj = Object.assign({}, this.Obj);
      obj["login_user_id"] = obj["contact_phone"] + "-" + obj["contact_email"];
      obj["user_id"] = this.ebillUser["user_id"];

      console.log(obj);
      this.spinner.show();
      var resp = await this.mainService.createLegalEntity(obj);
      console.log(resp);
      if (resp["error"] == false) {
        await this.getAllLegalEntity();

        setTimeout(() => {
          this.spinner.hide();
          this.getAllLegalEntity;
          swal.fire("Success...", "Created Successfully", "success");
          this.Obj = {};
        }, 1000);
      } else {
        this.spinner.hide();
        if (resp["data"] == "Duplicate entry") {
          swal.fire(
            "Error...",
            "Either Mobile Or Email Is Already Exists.",
            "error"
          );
        } else {
          swal.fire("Error", "Error While Creating", "error");
        }
      }
    }
  }
  async finaldelete(element:any) {
    this.spinner.show();
    var obj = Object();
    obj["rep_le_id"] = element.rep_le_id;
    obj["le_id"] = element.le_id;
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.MasterDataService.deleteparty(obj);
    if (resp["error"] == false) {
      await this.getparty();

      this.spinner.hide();

       this.toastr.success("Party Deleted Successfully.", "Success!");

    } else {
      this.spinner.hide();

       this.toastr.error(resp["data"]);

    }
  }
  refresh() {
    this.stepper1.selectedIndex = 0;
    this.stepper2.selectedIndex = 0;
    this.Obj = { le_flag: true };
  }

  next_button_3 = false;

  status:any;
  flage:any;
  ifsc_cd:any;
  async fetchBankDtl() {
    if (!this.Obj['ifsc_code']){
      swal.fire("Info", "Enter IFSC Code", 'info');
      this.status = "Not Verified";
      this.flage = false;
      return;
    }

    if (this.Obj['ifsc_code'] == undefined) {
      this.spinner.hide();
      swal.fire("ERROR", "Please Varify IFS Code", "error");
      return;
    }
    this.Obj['ifsc_code'] = this.Obj['ifsc_code']

    this.ifsc_cd = this.Obj['ifsc_code']

    if (this.ifsc_cd != this.Obj['ifsc_code']) {
      this.spinner.hide();
      swal.fire("ERROR", "Please Enter IFS Code", "error");
      return;
    }
//oc
    // let resp = await this.settingService.getBankDtlByIfsc(JSON.stringify(this.Obj['ifsc_code']));
    //ec
    //rc
   let resp:any=[]
   //lc
    console.log(resp['data']);
    if (resp['error'] == false) {
      this.Obj['bank_branch_name'] = resp['data']['BRANCH'];
      this.Obj['bank_name'] = resp['data']['BANK'];
      this.Obj['bank_address'] = resp['data']['ADDRESS'];
      this.Obj['ifsc_code'] = resp['data']['IFSC'];
      this.status = "Verified ";
      this.flage = true;
    } else {
      this.Obj['bank_branch_name'] = null;
      this.Obj['bank_name'] = null;
      this.Obj['bank_address'] = null;
      this.Obj['ifsc_code'] = null;
      this.status = "Not Verified";
      this.flage = false;
    }

    if(resp['data'] == 'Not Found'){
      this.status = "Not Verified";
      this.flage = false;
    }
  }

  async submitOn3() {
    this.check();
    this.Obj["party_gstin_no"] = this.Obj["party_gstin_no"]
    this.Obj["party_pan_no"] = this.Obj["party_pan_no"]
    this.Obj["ifsc_code"] = this.Obj["ifsc_code"]

    if (this.ifsc_cd != this.Obj['ifsc_code'] || this.Obj['ifsc_code'] == undefined) {
      this.spinner.hide();
      swal.fire("ERROR", "Please Varify IFS Code", "error");
      return;
    }
    if(!this.Obj['party_gstin_no']){
      Swal.fire('Info', 'Enter GSTIN', 'info')
      return
    }
    console.log(this.Obj['party_pan_no'])
      if (!this.Obj['party_pan_no'].match(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
        console.log(this.Obj['party_pan_no'])
        Swal.fire('Info', 'Enter valid pan number', 'info')
        return
      }


    if (this.Obj['party_adhar']) {
      if (this.Obj['party_adhar']) {
        if (this.Obj['party_adhar'].toString().length != 12) {
          Swal.fire("Info", 'Enter 12 digit valid Adhar number', 'info')
          return
        }
      }
    }


    if (this.Obj['party_acct_no']) {
      if (this.Obj['party_acct_no']) {
        if (this.Obj['party_acct_no'].toString().length < 4 ) {
          Swal.fire("Info", 'Enter Account Number greater then 4 digit ', 'info')
          return
        }
      }
    }
    // console.log(this.Obj["party_gstin_no"]);
    // console.log(this.Obj["party_pan_no"]);

    // if (type == 'SUBMIT') {
    let gst_pan = this.Obj["party_gstin_no"].substring(2, 12);
    if (this.partyduplicatearr.includes(this.Obj["le_id"])) {
      swal.fire(
        "Error",
        "Duplicate Entry of " + this.Obj["party_leagal_name"],
        "error"
      );
      this.next_button_3 = false;
      return;
    } else if (this.partyduplicatearr.includes(this.cpObj["le_id"])) {
      swal.fire(
        "Error",
        "Duplicate Entry of " + this.cpObj["party_leagal_name"],
        "error"
      );
      this.next_button_3 = false;
      return;
    }
    // }
    else if (!(this.Obj["party_gstin_no"])) {
      swal.fire("Error", "GISTIN can not be blank...", "error");
      this.next_button_3 = false;
      return;
    }
    else if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(this.Obj["party_gstin_no"])) {
      swal.fire("Error", "Invalid GISTIN", "error");
      this.next_button_3 = false;
      return;
    }

    else if (gst_pan != (this.Obj["party_pan_no"])) {
      swal.fire("Error", "Invalid PAN", "error");
      this.next_button_3 = false;
      return;
    }
    else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.Obj["party_email"]
      )
    ) {
      swal.fire("Error", "Invalid Email", "error");
      this.next_button_3 = false;
      return;
    }
    else if (!(this.Obj["party_pan_no"])) {
      swal.fire("Error", "PAN can not be blank...", "error");
      this.next_button_3 = false;
      return;
    }
    else if (
      !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.Obj["party_pan_no"])
    ) {
      swal.fire("Error", "Invalid PAN", "error");
      this.next_button_3 = false;
      return;
    }
    // else if (!(this.Obj["party_adhar"])){
    //   swal.fire("Error", "Adhar can not be blank...", "error");
    //   this.next_button_3 = false;
    //   return;
    // }
    // else if (!/^\d{12}$/.test(this.Obj["party_adhar"])) {
    //   swal.fire("Error", "Invalid Adhar", "error");
    //   this.next_button_3 = false;
    //   return;
    // }
    else if (!/^\+?\d{10}$/.test(this.Obj["party_phone_no"])) {
      swal.fire("Error", "Invalid Mobile Number", "error");
      this.next_button_3 = false;
      return;
    }
    else if (!(this.Obj["party_acct_no"])) {
      swal.fire("Error", "Bank Account Number can not be blank...", "error");
      this.next_button_3 = false;
      return;
    }
    else if (this.Obj["party_acct_no"].toString().length > 18) {
      swal.fire("Error", "Invalid Account Number ", "error");
      this.next_button_3 = false;
      return;
    }
    else if (!(this.Obj["ifsc_code"])) {
      swal.fire("Error", "IFS Code can not be blank...", "error");
      this.next_button_3 = false;
      return;
    }
    else if (!/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/.test(this.Obj["ifsc_code"])) {
      swal.fire("Error", "Invalid IFS Code ", "error");
      this.next_button_3 = false;
      return;
    } else if (
      // this.Obj["party_adhar"] == undefined ||
      this.Obj["ifsc_code"] == undefined ||
      this.Obj["party_phone_no"] == undefined ||
      this.Obj["bank_branch_name"] == undefined ||
      this.Obj["party_email"] == undefined ||
      this.Obj["party_leagal_name"] == undefined ||
      this.Obj["party_pan_no"] == undefined ||
      this.Obj["party_id"] == undefined
    ) {

       this.toastr.error("Some Fields Are Incorrect");

      this.next_button_3 = false;
      return;
    } else {
      this.stepper1.next();
      this.stepper2.next();
      this.next_button_3 = true;
    }
  }
  gistn_flag :boolean  = false
  check() {
    // let flage: boolean = false;
    this.party.map(x => {
      if (x['party_gstin_no'] == this.Obj['party_gstin_no']) {
         this.gistn_flag = true;
        return;
      }
    })

    if(!this.Obj['party_gstin_no']){
      Swal.fire('Info', 'Please Enter GSTIN Number','info');
      return;
    }
    // if ( this.gistn_flag) {
    //   Swal.fire('ERROR', 'Duplicate GSTIN Number ', 'error');
    //   this.Obj['party_gstin_no'] = '';
    //    this.gistn_flag = true;
    //   return;
    // }
    if (this.Obj['party_acct_no'] == undefined) {
      Swal.fire('ERROR', 'Please Enter Account Number', 'error');
      this.Obj['party_acct_no'] = '';
      return;
    }
    if (this.Obj['ifsc_code'] == undefined) {
      Swal.fire('ERROR', 'Please verify IFSC', 'error');
      this.Obj['ifsc_code'] = '';
      return;
    }

    let gst_pan = this.Obj["party_gstin_no"].substring(2, 12);

    if (gst_pan != (this.Obj["party_pan_no"])) {
      swal.fire("Error", "Invalid PAN ", "error");
      return;
    }

  }
  async submit() {
    this.check();
    this.status = ''
    this.spinner.show();
    this.Obj["cp_obj"] = this.cpObj;
    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["user_id"] = this.ebillUser["user_id"];
    this.Obj["party_local_no"] = "1";
    this.Obj["party_type_cd"] = "VENDER";
    this.Obj["valid_upto"] = "2030-01-01";
    this.Obj["party_source_cd"] = "ENG";
    this.Obj["ident_verify_secret"] = "work4fun";
    console.log(this.Obj);
    var resp = await this.MasterDataService.addpartymd(this.Obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getparty();
      this.Obj = Object.assign({}, { le_flag: true });

       this.toastr.success("Party created successfully", "Success!");

      this.status = ' '
      this.getparty();
      this.refresh();
      this.gotolist();
    } else {
      this.spinner.hide();

       this.toastr.error(resp["data"]);

    }
  }
  async representative(element:any) {
    var resp = await this.MasterDataService.getrepresentative(
      JSON.stringify({
        b_acct_id: this.b_acct_id,
        rep_le_id: element.rep_le_id,
      })
    );
    if (resp["error"] == false) {
      this.cpObj = resp["data"][0];
      $("#myModal3").modal("show");
    } else {
      this.spinner.hide();

       this.toastr.error(resp["data"]);

    }
  }
  async open_update(element:any) {
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    this.l_ved_flag = false;
    this.c_ved_flag = false;
    this.u_ved_flag = true;
    this.cc_ved_flag = false;

    this.Obj["old_party_phone_no"] = this.Obj["party_phone_no"];
    //this.Obj['user'] = element.id;
    var resp = await this.MasterDataService.getrepresentative(
      JSON.stringify({
        b_acct_id: this.b_acct_id,
        rep_le_id: element.rep_le_id,
      })
    );
    console.log(resp)
    this.cpObj = resp["data"][0];
    if (this.cpObj["le_id"] != undefined)
      this.Obj["old_rep_le_id"] = this.cpObj["le_id"];
    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
    this.spinner.hide();
  }
  /*  gstinfunction () {
     var inputvalues =  this.Obj['party_gstin_no'];
     var gstinformat = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
     if (gstinformat.test(inputvalues)) {
         return false;
     } else {
         alert('Please Enter Valid GSTIN Number');
         return true;
     }
 } */
  Adhaarvalidation() {
    // var regexp=/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;

    var x = this.Obj["party_adhar"];
    if (this.Obj["party_adhar"] != undefined) {
      if (x.length == 12) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }

  capitalize() {
    this.Obj["party_gstin_no"] = this.Obj["party_gstin_no"].toUpperCase();
    this.Obj["party_pan_no"] = this.Obj["party_pan_no"].toUpperCase();
    this.Obj["ifsc_code"] = this.Obj["ifsc_code"].toUpperCase();
  }
  async update() {
    this.check();

    this.status = ''
    if (this.Obj["le_id"] == undefined && this.Obj["le_id"] == null) {
      swal.fire("Error", "Please Search Leagal Entity", "error");
      return;
    }
    if (this.cpObj["le_id"] == undefined && this.cpObj["le_id"] == null) {
      if (this.Obj["party_type"] == "ORG") {
        swal.fire("Error", "Choose Representative Leagal Entity", "error");
        return;
      }
    }
    this.Obj["cp_obj"] = this.cpObj;

    if (
      !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
        this.Obj["party_gstin_no"]
      )
    ) {
      swal.fire("Error", "Invalid GSTIN", "error");
      return;
    }
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.Obj["party_email"]
      )
    ) {
      swal.fire("Error", "Invalid Email", "error");
      return;
    }
    if (
      !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.Obj["party_pan_no"])
    ) {
      swal.fire("Error", "Invalid PAN", "error");
      return;
    }
    // if (!/^\d{12}$/.test(this.Obj["party_adhar"])) {
    //   swal.fire("Error", "Invalid Adhar", "error");
    //   return;
    // }

    if (!/^\+?\d{10}$/.test(this.Obj["party_phone_no"])) {
      swal.fire("Error", "Invalid Mobile Number", "error");
      return;
    }
    if (this.Obj["party_acct_no"].toString().length > 16) {
      swal.fire("Error", "Invalid Account Number", "error");
      return;
    }
    if (!/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/.test(this.Obj["ifsc_code"])) {
      swal.fire("Error", "Invalid IFSC", "error");
      return;
    }

    if (
      // this.Obj["party_adhar"] == undefined ||
      this.Obj["ifsc_code"] == undefined ||
      this.Obj["party_phone_no"] == undefined ||
      this.Obj["bank_branch_name"] == undefined ||
      this.Obj["party_email"] == undefined ||
      this.Obj["party_leagal_name"] == undefined ||
      this.Obj["party_pan_no"] == undefined ||
      this.Obj["party_id"] == undefined
    ) {

       this.toastr.error("Some Fields Are Incorrect");

      this.spinner.hide();
      return;
    }

    if (this.Obj['party_acct_no']) {
      if (this.Obj['party_acct_no']) {
        if (this.Obj['party_acct_no'].toString().length < 4 ) {
          Swal.fire("Info", 'Enter Account Number greater then 4 digit ', 'info')
          return
        }
      }
    }
    this.spinner.show();

    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["user_id"] = this.ebillUser["user_id"];
    console.log(this.Obj);
    var resp = await this.MasterDataService.updatepartymd(this.Obj);
    if (resp["error"] == false) {
      await this.getparty();
      this.Obj = Object.assign({}, { le_flag: true });
      this.spinner.hide();

       this.toastr.success("Party Updated successfully", "Success!");

      this.status = ''
      this.gotolist();
      this.refresh();
    } else {
      this.spinner.hide();

       this.toastr.error(resp["data"]);

    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
