import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HierarchyService } from './hierarchy.service';
import { LedgerService } from './ledger.service';
@Injectable({
  providedIn: 'root'
})
export class BudgetEffectService {

  constructor(private hierarchyService: HierarchyService, private ledgerService: LedgerService,private spinner:NgxSpinnerService) { }
  async getBudgetObj(eventdata:any, b_acct_id:any) {
    let bud_obj:any = new Object();

    await this.getActiveFinYear(b_acct_id);
    await this.getAllBudget(b_acct_id);
    console.log(this.allBudgetObj)

    for (let i = 0; i < eventdata.length; i++) {
      console.log(eventdata[i]['ev_ln_dist_bdgt_cd']);
      let bud:any = this.allBudgetObj[eventdata[i]['ev_ln_dist_bdgt_cd']];

      if(bud){
        for (let j:any = 1; j < 8; j++) {

        if (bud["lvl" + j + "_cd"] == null || bud["lvl" + j + "_cd"] == "" || bud["lvl" + j + "_cd"] == " ") {
          continue;
        }
        if (bud_obj[bud["lvl" + j + "_cd"]] == undefined) {
          bud_obj[bud["lvl" + j + "_cd"]] = 0
        }
        bud_obj[bud["lvl" + j + "_cd"]] = bud_obj[bud["lvl" + j + "_cd"]] + eventdata[i]['ev_ln_amt']

        console.log(37 , bud_obj)
      }
    }else{
      this.spinner.hide()
      Swal.fire('Error','Error While Getting Budget Info','error');
      return null;
    }
  }
    console.log(bud_obj)





    return bud_obj


  }
  allBudgetHier :any= [];
  allBudgetObj:any = {};


  fin_year:any
  async getActiveFinYear(b_acct_id:any) {
    var obj:any = new Object();
    obj['b_acct_id'] = b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp.data.length == 0) {
        // swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      // swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }
  async getAllBudget(b_acct_id:any) {
    var obj :any= new Object();
    obj["b_acct_id"] = b_acct_id;
    obj["table_name"] = "bud_hier";
    var resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      this.allBudgetHier = resp.data.filter((x:any) => { return x['fin_year'] == this.fin_year });

      for (let i = 0; i < this.allBudgetHier.length; i++) {
        this.allBudgetObj[this.allBudgetHier[i]['leaf_cd']] = this.allBudgetHier[i]
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allBudgetHier[i]["lvl" + j + "_cd"] != null &&
            this.allBudgetHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allBudgetHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allBudgetHier[i]["desc"] = str;
        // this.budgetObj[this.allBudgetHier[i]["leaf_cd"]] = str;
        // this.hierobj["budget"][this.allBudgetHier[i]["leaf_cd"]] =this.allBudgetHier[i];
      }
    } else {
    }
  }
}
