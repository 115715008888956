<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'auction_application1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click) = "tab1Click()" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'auction_application2']}}</a>
                            <!-- list details -->
                        </li>
                        <li class=" hideItm nav-item ">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'auction_application3']}}</a>
                            <!-- Applicant list -->
                        </li>
                        <!--    <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Create</a>
                        </li>-->

                    </ul>

                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1">
                            <br>

                            <!-- <div class="row">
                                <div class="col-3 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application4']}}</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="schemeArr" bindLabel="scheme_name" (change)='getAllSubschemes()' bindValue="scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'auction_application4']}}" [(ngModel)]="obj['scheme_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application5']}}</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="subschemeArr" bindLabel="sub_scheme_name" bindValue="sub_scheme_code" [multiple]="false" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'auction_application5']}}" [(ngModel)]="obj['sub_scheme_code']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        (change)='getAllPropertyInfos()' [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                            </div><br> -->
                            <div class="row">


                                <!-- <div class="col-3"></div>
                                <div class="col-3 text-right">
                                    <h6>Select Offer</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allOffer" bindLabel="offer_name" (change)='show()'
                                        bindValue="offer_cd" [multiple]="false" placeholder="Select Offer"
                                        [(ngModel)]="obj['offer_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div> -->

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Select Offer</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allOffer" bindLabel="desc" (change)='show()'
                                        bindValue="auction_cd" [multiple]="false" placeholder="Select Offer"
                                        [(ngModel)]="obj['offer_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_bank_name">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <!-- <div class="row">
                                <div class="col-12 text-center">
                                    <button
                                        class="btn btn-primary">{{auth.allLableShowObjProp[auth.language_cd+'auction_application6']}}</button>
                                </div>
                            </div> -->
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'auction_application7']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">

                                </div>
                                <div class="col-4 text-right">
                                    <button (click)="print1()" class="btn btn-outline-success">Download
                                        List</button>
                                </div>

                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                    <ng-container matColumnDef="app_id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application8']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="property_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Property type </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="property_number">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Property Number </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="purpose">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application9']}}</b> -->
                                            <b>Quota </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.quota }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="app_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application10']}}</b> -->
                                            <b>Applicant Name </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="app_mobile">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application11']}}</b>

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.mobile_no }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="app_user_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application12']}}</b> -->
                                            <b>Applicant Aadhar Number </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.aadhar }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nationality">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application13']}}</b> -->
                                            <b>State </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.state }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application14']}}</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-success"
                                                (click)='view(element)'>{{auth.allLableShowObjProp[auth.language_cd+'auction_application39']}}</button>
                                            <button class="btn btn-outline-primary " (click)='approve(element)'
                                                *ngIf="element['arr_status_code']=='APPROVAL_PENDING'">{{auth.allLableShowObjProp[auth.language_cd+'auction_application40']}}</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="card">
                                <div style="margin: 2%;">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h3>{{auth.allLableShowObjProp[auth.language_cd+'auction_application15']}}
                                            </h3>
                                        </div>
                                    </div>
                                    <hr><br>
                                    <div class="example-container">
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application16']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['id']}}</div>
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application17']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['name']}}</div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application18']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['mobile_no']}}</div>
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application19']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['email']}}</div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application20']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['user_id']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application21']}}
                                                    :
                                                </h6> -->
                                                <h6>Father's Name :</h6>
                                            </div>
                                            <div class="col-3">{{showData['father_name_or_rep_name']}}</div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Aadhaar Number :</h6>
                                            </div>
                                            <div class="col-3">{{showData['aadhar']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6>-->
                                                <h6>PAN Number :</h6>
                                            </div>
                                            <div class="col-3">{{showData['pan']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Address :</h6>
                                            </div>
                                            <div class="col-3">{{showData['address']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>District :</h6>
                                            </div>
                                            <div class="col-3">{{showData['district']}}</div>
                                        </div>
                                        <br>

                                        <!-- From here i add new Details -->

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>State :</h6>
                                            </div>
                                            <div class="col-3">{{showData['state']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>User Type :</h6>
                                            </div>
                                            <div class="col-3">{{showData['user_type']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Date of Birth :</h6>
                                            </div>
                                            <div class="col-3">{{showData['dob_or_inc_date']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Pin Code :</h6>
                                            </div>
                                            <div class="col-3">{{showData['pin_code']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>User ID :</h6>
                                            </div>
                                            <div class="col-3">{{showData['update_user_id']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Account Holder Name :</h6>
                                            </div>
                                            <div class="col-3">{{showData['account_holder_name']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Bank Name :</h6>
                                            </div>
                                            <div class="col-3">{{showData['bank_name']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Account Number :</h6>
                                            </div>
                                            <div class="col-3">{{showData['account_no']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>IFSC Code :</h6>
                                            </div>
                                            <div class="col-3">{{showData['ifsc']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Payment Options :</h6>
                                            </div>
                                            <div class="col-3">{{showData['payment_option']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>GSTIN :</h6>
                                            </div>
                                            <div class="col-3">{{showData['gstin']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Quota :</h6>
                                            </div>
                                            <div class="col-3">{{showData['quota']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Booklet Challan Number :</h6>
                                            </div>
                                            <div class="col-3">{{showData['booklet_challan_no']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Booklet Amount :</h6>
                                            </div>
                                            <div class="col-3">{{showData['booklet_amount']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Booklet Challan Status :</h6>
                                            </div>
                                            <div class="col-3">{{showData['booklet_purchase_challan_status']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Application Challan Number :</h6>
                                            </div>
                                            <div class="col-3">{{showData['application_challan_no']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application22']}}
                                                    :
                                                </h6> -->
                                                <h6>Application Amount :</h6>
                                            </div>
                                            <div class="col-3">{{showData['application_amount']}}</div>
                                            <div class="col-3 text-right">
                                                <!-- <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application23']}}
                                                    :
                                                </h6> -->
                                                <h6>Application Challan Status :</h6>
                                            </div>
                                            <div class="col-3">{{showData['application_challan_status']}}</div>
                                        </div>
                                        <br>




                                        <hr>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <h3>{{auth.allLableShowObjProp[auth.language_cd+'auction_application24']}}
                                                </h3>
                                            </div>
                                        </div>
                                        <hr>
                                        <br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>Offer Code :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['offer_cd']}}</div>
                                            <div class="col-3 text-right">
                                                <h6>Offer Name :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{offerObj[showData['offer_cd']]}}</div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>Property Type :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['property_type']}}</div>
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application28']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['alloted_property_id']}}</div>
                                        </div><br>
                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>Area :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['area']}}</div>
                                            <div class="col-3 text-right">
                                                <h6>Unit :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['unit']}}</div>
                                        </div><br>

                                        <div class="row">

                                            <div class="col-3 text-right">

                                                <h6>Applied Property :</h6>
                                            </div>
                                            <div class="col-3">{{showData['applied_property']}}</div>
                                            <div class="col-3 text-right">

                                                <h6>Alloted Property ID :</h6>
                                            </div>
                                            <div class="col-3">{{showData['alloted_property_id']}}</div>

                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">

                                                <h6>Property Category :</h6>
                                            </div>
                                            <div class="col-3">{{showData['property_category']}}</div>
                                            <div class="col-3 text-right">

                                                <h6>Residential/Commercial :</h6>
                                            </div>
                                            <div class="col-3">{{showData['residential_or_commercial']}}</div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-3 text-right">

                                                <h6>Subsidised / Non Subsidised :</h6>
                                            </div>
                                            <div class="col-3">{{showData['subsidised_or_non_subsidised']}}</div>
                                            <div class="col-3 text-right">

                                                <h6>Status :</h6>
                                            </div>
                                            <div class="col-3">{{showData['life_cycle_status']}}</div>
                                        </div>
                                        <br>


                                        <div class="row">
                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application37']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['reserve_price']}}</div>

                                            <div class="col-3 text-right">
                                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'auction_application36']}}
                                                    :
                                                </h6>
                                            </div>
                                            <div class="col-3">{{showData['reserve_price']}}</div>

                                        </div>
                                    </div>
                                    <br>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> {{auth.allLableShowObjProp[auth.language_cd+'auction_application38']}}</p>
</ngx-spinner>
