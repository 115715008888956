import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class JoiningService {
  httpUrl:any;

  employee_id:any;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }




  async insertEmployeeMasterData(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/establishment_info/joining/addJoining3',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateNodeCode(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/establishment_info/joining/updateNodeCode',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta
  async updateNodeCode1(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/establishment_info/joining/1updateNodeCode',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getMaxEmployee(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl+'/hr/establishment_info/joining/getMaxEmployeeid' +b_acct_id).toPromise().then(res => {

      return res;
    });
    return resp;

  }


  async  getEmployeeFunctionArea(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl+'/hr/establishment_info/joining/getEmployeeFunctionArea' +obj).toPromise().then(res => {

      return res;
    });
    return resp;
  }
  // async  getvdadata(obj :any) {
  //   const resp = await this.http.post<any>(this.httpUrl + '/hr/vda_data/vda/check',obj).toPromise().then(res => {

  //     return res;
  //   });
  //   return resp;
  // }

  async getvdadata(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/vda_data/vda/getvdadata',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //shweta
  async getvdaestablishdata(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/vda_data/vda/getvdaestablishdata',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
   //shweta
   async geterpstablishdata(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/establishment_info/joining/geterpstablishdata',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertalldata(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/establishment_info/joining/insertalldata',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
