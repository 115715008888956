import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class AllreportService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async getComplainData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/allreport/getallreport_complain/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

  async getpenalitydata(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/allreport/getallpenality/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

  async getallreoprts_details(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/allreport/getallreports_details/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

  async getAEreoprts_details(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/allreport/getAEreports_details/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

async getZOreoprts_details(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/allreport/getZOreports_details/' + obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return

  }

}
