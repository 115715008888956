import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AllEmpService } from '../../service/all-emp.service'
import swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

import { MainService } from '../../service/main.service';
import { DataAccessService } from '../../service/data-access.service';
import { __assign } from 'tslib';

declare var $: any
@Component({
  selector: 'app-bank-account-info',
  templateUrl: './bank-account-info.component.html',
  styleUrls: ['./bank-account-info.component.css']
})
export class BankAccountInfoComponent implements OnInit {

  constructor(private http:HttpClient,private dataAccessService: DataAccessService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService) { }
  errorMsg = ''

  b_acct_id:any;
  user_id:any;
  id_obj:any = {};
  datasource:any;
  bankaccObj :any= {};
  bankaccObj1:any = {};
  selectEmpObj :any= {};
  allEmployees:any = [];
  selectedbankObj:any = [];
  erpUser:any;
  codeValueTechObj :any= {};
  allEmplyees_new:any = [];
  emp_id :any= 0;
  displayedColumns :any= ['bank_code', 'branch_code', 'ifsc_code', 'acct_no', 'pf_acct_no', 'action'];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getAllEmployees();
    // for (let i = 0; i < this.allEmployees.length; i++) {
    //   if (this.allEmployees[i]['le_id'] == this.erpUser.user_id) {
    //     this.selectEmpObj['le_id'] = this.allEmployees[i]['emp_id']
    //   }
    // }

    // this.selectEmpObj['le_id'] = this.erpUser.user_id;
    await this.changeEmployee();

  }

  list_flag :any= true;
  create_flag :any= false;
  update_flag :any= false;
  open_list() {
    this.bankaccObj={};
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  async open_create() {

    if(this.selectEmpObj['le_id']==''|| this.selectEmpObj['le_id']==null|| this.selectEmpObj['le_id']==undefined || this.selectEmpObj['le_id']=='')
    {
      swal.fire('Warning','Please select at least one employee','warning');
      return;
    }
    console.log("thsi is selected emp obj",this.selectEmpObj)
    this.bankaccObj=Object.assign({},this.bankaccObj1)
    console.log("assign ", this.bankaccObj);
    let num=this.bankaccObj['acct_no'];
    if(num!=null || num!=undefined)
    {
    await this.checkAccount(num);
     }
     else{
      this.accNoLen=false;
      this.errorMsg='';
      this.acnolen='0';
     }
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
  }

  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      console.log("Responce data --",arr)
      // this.allEmployees = arr
       this.allEmployees=[];
      var assignedEmp= this.dataAccessService.assignedEmp;
      console.log("all employee--",assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj :any= new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id']);
        if(assignedEmp[arr[i]['arr_id']]!=undefined){
          this.allEmployees.push(obj)
        }

      }
      console.log("thsi si the all employy list ", this.allEmployees);
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }


  }
  async changeEmployee() {
    // this.le_id = this.selectEmpObj['le_id']
    console.log("thsi is change selection -",this.selectEmpObj['le_id'])
    console.log("thsi is change selection All emp- -",this.allEmployees);
    this.bankaccObj1 ={};
    for (var i = 0; i < this.allEmployees.length; i++) {
      if (this.allEmployees[i]['le_id'] == this.selectEmpObj['le_id']) {
        // this.bankaccObj1 =Object.assign({},this.allEmployees[i]);
        console.log(" this is hte adata of the all ", this.allEmployees[i]);
        // this.bankaccObj1 ={};
        if(this.allEmployees[i]['acct_no']==null)
        {
          swal.fire("Info", "Please add bank detail first",'info')
        }
        this.bankaccObj1 =this.allEmployees[i];
      }
    }
    console.log("changeEmployee--",this.bankaccObj1);
  }
  openUpdate() {
    this.errorMsg = ''
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  }

  /*******Check Bank IFSC and Bank Details */
  // ifc=[]
//   async checkIFSC(){
//      let bankIfsc = this.bankaccObj['ifsc_code'];
//      //let ifscObj = {};
//     // var ifscObj = await this.allEmpService.bankIfsc(bankIfsc);
//     // console.log(ifscObj);
//     this.http.get('https://ifsc.razorpay.com/'+bankIfsc).subscribe(data=>{
//     //console.log(data)
//     this.bankaccObj['bank_code'] = data['BANK'];
//     this.bankaccObj['branch_code'] = data['BRANCH'];

// })
//   }

// -------------------------------

ifc:any=[]
async checkIFSC(bankIfsc:any){
  // let bankIfsc = this.nomineeObj['nom_ifsc_code'];
  console.log("this is enter", bankIfsc);
  this.ifc=[];
let resp= await this.http.get('https://ifsc.razorpay.com/'+bankIfsc).subscribe((data:any)=>{

//  this.nomineeObj['nom_bank_name'] = data['BANK'];
//  this.nomineeObj['nom_branch_name'] = data['BRANCH'];
//  console.log("this si the length of hte key ",data);
if(Object.values(data).length==17)
{

  this.ifc.push({
    bank:data['BANK'],
    branck: data['BRANCH']
  })
  this.bankaccObj['bank_code'] = data['BANK'];
    this.bankaccObj['branch_code'] = data['BRANCH'];
}

})

console.log("this is hte resp 1 ", this.ifc)
console.log("this si the length of hte key12", this.ifc);
if(this.ifc.length==1)
{
  this.bankaccObj['bank_code'] = this.ifc[0]['bank'];
  this.bankaccObj['branch_code'] = this.ifc[0]['branch'];
}
else{
  this.bankaccObj['bank_code'] = "";
  this.bankaccObj['branch_code'] = "";
}
}

accNoLen=false;
acnolen='';
async checkAccount(acNo:any)
{

    console.log("fun call--",acNo)
    console.log("fun before assign ",this.bankaccObj['acct_no']);
    this.bankaccObj['acct_no']=acNo;
    console.log("after assign ",this.bankaccObj['acct_no']);
    if(acNo.length<8)
    {
         this.accNoLen=false;
    }
    else if(acNo.length>18)
    {
         this.accNoLen=false
    }
    else{
      this.accNoLen=true;
      this.acnolen=acNo.length;
    }
}


//-------------------------

  async addBankAcctInfo() {
    swal.fire({
      title:'Are you Sure?',
      text: 'Do You Want To Add',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Add it!'
    }).then((result) => {
      if (result.value) {
        this.finaladdBankAcctInfo()
      }
    })
  }

  async finaladdBankAcctInfo() {
    this.bankaccObj['b_acct_id'] = this.b_acct_id;
    this.bankaccObj['emp_id'] = this.selectEmpObj['emp_id'];
    this.bankaccObj['create_user_id'] = this.user_id;
    //var myDate = new Date();
    //this.bankaccObj['effective_dt']= myDate.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })

    this.spinner.show();
    var resp = await this.allEmpService.updatePersonalInfo(this.bankaccObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.changeEmployee();

      swal.fire("Success", "Bank Info Added", 'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some error occured!", 'error');

    }
  }

  async updateBankAcctInfo() {
    this.errorMsg = ''
    if (
      this.bankaccObj['bank_code'] == "" || this.bankaccObj['bank_code'] == undefined
      || this.bankaccObj['branch_code'] == "" || this.bankaccObj['branch_code'] == undefined
      || this.bankaccObj['ifsc_code'] == "" || this.bankaccObj['ifsc_code'] == undefined
      || this.bankaccObj['acct_no'] == "" || this.bankaccObj['acct_no'] == undefined
      // || this.bankaccObj['pf_acct_no'] == "" || this.bankaccObj['pf_acct_no'] == undefined ||
     || this.accNoLen==false
    ) {


swal.fire("Warning","* fields required !","warning");

      this.errorMsg = "* fields required !"


    }
    else {

      swal.fire({
        title:'Are you Sure?',
        // text: 'Do You Want To Update',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdateBankAcctInfo()
        }
      })


    }
  }
  async finalupdateBankAcctInfo() {
    // this.selectEmpObj['le_id']
    this.bankaccObj['b_acct_id'] = this.b_acct_id;
    this.bankaccObj['emp_id'] = this.selectEmpObj['emp_id'];
    this.bankaccObj['update_user_id'] = this.user_id;

    this.spinner.show();
    var resp = await this.allEmpService.updatePersonalInfo(this.bankaccObj);
    if (resp['error'] == false) {
      // setTimeout(async () => {
      //   this.spinner.hide();
      //   await this.getAllEmployees();

      // }, 1000);
  this.bankaccObj={};
  this.bankaccObj1 ={};
      this.spinner.hide();
      this.allEmployees=[];
      this.accNoLen=false;
     setTimeout(async() => {
       await  this.getAllEmployees();
       await   this.changeEmployee();
       swal.fire("Success", "Bank Info Updated", 'success');
       this. open_list();
       }, 1000)
       // await this.getAllEmployees();
       // await this.changeEmployee()
// let this1=this;
  //  function  doIt()
  //     {        swal.fire("Success", "Bank Info Updated", 'success');
  //     // this1. open_list();
  //     }

//   var promise1 = new Promise<void>(function(resolve, reject) {
//     setTimeout(function a(){  this1.getAllEmployees(); resolve();}, 3000);
// })

// var promise2 = new Promise<void>(function(resolve, reject) {
//     setTimeout(function b(){  this1.changeEmployee(); resolve();}, 3000);
// })

// var promise3 = new Promise<void>(function(resolve, reject) {
//     setTimeout(function c(){
//                                this1.open_list(); resolve();}, 3000);
// })

// Promise.all([promise1, promise2, promise3]).then(function() {
//   function d(){
//     swal.fire("Success", "Bank Info Updated", 'success');
//   }
//   d();
// });


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Faiz will check the error!", 'error');

    }
  }

  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  chamgeTab() {
    this.errorMsg = ''
  }

}
