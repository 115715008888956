<!-- The main cointaner -->
<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Lease Allotment</div>
        </div>

        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">

              <a class="nav-link active" *ngIf="list_flag"   data-toggle="tab">
                <i class="fa fa-line-chart"></i>Lease Allotment List</a>

            </li>
            <!-- Allotment List -->
            <li class="nav-item">
              <a class="nav-link" *ngIf="create_flag"   data-toggle="tab">
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Create
                Lease Allotment
              </a>
            </li>
            <!-- Create Allotment -->
          </ul>
          <div class="tab-content" *ngIf="list_flag">
            <div class="row">
              <div class="col-6">
                <mat-form-field style="margin-left: 2%">
                  <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{
                      auth.allLableShowObjProp[auth.language_cd + 'property5']
                    }}" />
                  <!--Filter-->
                </mat-form-field>
              </div>
              <div class="col-6 text-right">
                <button (click)="open_create()" class="btn btn-outline-primary">
                  Create
                </button>
              </div>
            </div>

            <div class="example-container">
              <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                    S.No.
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                    {{ element.sno }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="target_path">
                  <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                    Target Path
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                    {{ element.project_desc }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="sector">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Sector </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> </td>
                </ng-container> -->

                <ng-container matColumnDef="lease type">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    Lease Type
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.lease_type }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="file no">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    File Number
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.file_num }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="plot no">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    Plot/Khasra no
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.assigned_property_number }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Address Of Property </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.address}} </td>
                </ng-container> -->

                <!-- <ng-container matColumnDef="details">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Leasee Details </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.leasee_name}} </td>
                </ng-container> -->

                <ng-container matColumnDef="area">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    total Area
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.area }}
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="start date">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease Start Date </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.lease_start_date | date: 'dd-MM-yyyy' }} </td>
                </ng-container> -->

                <!-- <ng-container matColumnDef="end date">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Lease End Date </th>
                  <td mat-cell flex-stretch *matCellDef="let element" class="right">{{element.lease_end_date }} </td>
                </ng-container> -->

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                    Action
                  </th>
                  <td mat-cell flex-stretch *matCellDef="let element; let i = index" class="right">
                    <button class="btn btn-outline-success" (click)="viewDetails(element.sno)">
                      View
                    </button>

                    <button (click)="open_update(element.sno)"
                    class="btn btn-outline-success">
                    Update
                  </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
          <div class="tab-content" *ngIf="create_flag">
            <br />
            <div class="row">
              <div class="col-12 text-right">
                <button (click)="open_list()" class="btn btn-outline-primary">
                  Go To List
                </button>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>File Number : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="File Number" [(ngModel)]="Obj['file_number']" class="form-control"
                  (change)="checkFilenum()" />
              </div>
              <div class="col-3"></div>
            </div>
            <!-- <br> -->
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Select Location Hierarchy : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id" [multiple]="false"
                  placeholder="Select..." [(ngModel)]="Obj['hier_type_id']" [selectableGroup]="true"
                  (change)="getAllLevls()" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
              </div>
            </div>
            <div class="row mt-3" *ngFor="let item of data; let i = index">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Select Level {{ i + 1 }} :</h6>
              </div>
              <div class="col-3">
                <ng-select *ngIf="i == 0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd" [multiple]="false"
                  placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']" (change)="set_layout_data(item, i)"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd" [multiple]="false"
                  (change)="set_layout_data(item, i)" placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 2" [items]="level3" (change)="set_layout_data(item, i)" bindLabel="lvl3_value"
                  bindValue="lvl3_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 3" [items]="level4" (change)="set_layout_data(item, i)" bindLabel="lvl4_value"
                  bindValue="lvl4_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 4" [items]="level5" (change)="set_layout_data(item, i)" bindLabel="lvl5_value"
                  bindValue="lvl5_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 5" [items]="level6" (change)="set_layout_data(item, i)" bindLabel="lvl6_value"
                  bindValue="lvl6_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
                <ng-select *ngIf="i == 6" [items]="level7" (change)="set_layout_data(item, i)" bindLabel="lvl7_value"
                  bindValue="lvl7_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
              </div>
              <div class="col-3" *ngIf="i == data.length - 1">
                <button (click)="add_dropdown(item, i)" class="btn btn-outline-primary">
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                </button>
                &nbsp;&nbsp;
                <button (click)="remove_dropdown(item, i)" *ngIf="i != 0" class="btn btn-outline-danger">
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Select Lease Layout : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <ng-select [items]="plan_layout" bindLabel="desc" bindValue="property_lease_group_id"
                  (change)="getAllProType()" placeholder="Select Plan Layout" [(ngModel)]="propObj['id']"
                  [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                  name="chart_of_account0">
                </ng-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Lease Type : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <ng-select [(ngModel)]="propObj['property_type']" placeholder="Select Property Type"
                  (change)="show_proprties()">
                  <ng-option *ngFor="let item of allProTypeAvailable" [value]="item.property_type">
                    {{ item.property_type }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Plot/Khasra no. : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <ng-select [(ngModel)]="propObj['property_id']" placeholder="Select Plot/Khasra no.">
                  <ng-option *ngFor="let item of allProperties" [value]="item.property_id">
                    {{ item.property_no }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Applicant Name : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Applicant Name" [(ngModel)]="Obj['applicant_name']"
                  class="form-control" />
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Lease Start Date : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <input type="date" placeholder="Lease Start Date" [(ngModel)]="Obj['start_date']"
                  class="form-control" />
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Lease Duration(years) : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Lease Duration(years)" [(ngModel)]="Obj['duration']"
                  class="form-control" />
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Lease Renewal Period : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Lease Renewal Period" [(ngModel)]="Obj['renewal_period']"
                  class="form-control" />
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>Lease Rent :<span style="color: red"> *</span></h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Lease Rent" [(ngModel)]="Obj['rent']" class="form-control" />
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Lease Rent Duration : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <ng-select [items]="rent_duration" [(ngModel)]="Obj['rent_duration']" placeholder="Lease Rent Duration">
                </ng-select>
              </div>
              <div class="col-3"></div>
            </div>
            <div class="row mt-3 align-items-center">
              <div class="col-3"></div>
              <div class="col-3">
                <h6>
                  Lease Rent Increment (%) : <span style="color: red"> *</span>
                </h6>
              </div>
              <div class="col-3">
                <input type="text" placeholder="Lease Rent Increment" [(ngModel)]="Obj['rent_increment']"
                  class="form-control" />
              </div>
              <div class="col-1">
                <h6>After : <span style="color: red"> *</span></h6>
              </div>
              <div class="col-2">
                <input type="text" placeholder="After period" [(ngModel)]="Obj['rent_increment_period']"
                  class="form-control" />
              </div>
            </div>
            <div class="col-12 text-center mt-5">
              <button class="btn btn-outline-success" (click)="submit()">
                {{
                auth.allLableShowObjProp[auth.language_cd + "p_layout48"]
                }}</button>&nbsp;&nbsp;
              <!--SUBMIT-->
            </div>
          </div>

          <div class="modal" id="viewDetails">
            <div class="modal-dialog modal-lg" style="max-width: 80%">
              <div class="modal-content rounded-50">
                <!-- Modal Header -->
                <div class="modal-header bg-primary">
                  <h4 class="modal-title text-white" style="font: bold">
                    Allotment Details :
                  </h4>
                  <button type="button" class="close text-white" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="border-radius: 10px">
                  <div class="card" style="border: 2px solid rgb(24, 24, 97)">
                    <div style="margin: 3%">
                      <div class="content-container">
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>File Number :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["file_num"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Project Description :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["project_desc"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Alloted Property Id :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["alloted_property_id"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Plot Number :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["assigned_property_number"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Total Area :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["rent_increment_period"] }} Years
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Address :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["address"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease Type :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["lease_type"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Applicant Name :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["leasee_name"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Lease Start Date :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["lease_start_date"] | date}}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease Duration :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["lease_duration"] }}
                              {{ objDetails["lease_duration_type"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Lease Renewal Period :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["renewal_period"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease Rent :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["rent"] }}
                            </h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Lease Rent Duration :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["rent_duration"] }}
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              <b>Lease Rent Increment (%) :</b>
                            </h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["rent_increment"] }}
                            </h5>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>Lease End Date :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["lease_end_date"] }}
                            </h5>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-3"></div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-3">
                            <h5 style="width: 100%"><b>After Period :</b></h5>
                          </div>
                          <div class="col-3">
                            <h5 style="width: 100%">
                              {{ objDetails["rent_increment_period"] }} in years
                            </h5>
                          </div>
                          <div class="col-3"></div>
                          <div class="col-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                                 (click)="download(data)">Download</button> -->
                  <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
