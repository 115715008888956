<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Fields Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                All Fields List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"  data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Create Field</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i> Update
                                Field</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>
                            <div class="col-11 text-right">
                                <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                            </div>
                            <br>
                            <br>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="field_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Field ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.field_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="field_business_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Field Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_business_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="field_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Field Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_code }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="open_update(element)">Update</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="create_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Business Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="Enter Field Business Name " [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Code :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="Enter Field Code " [(ngModel)]="field['field_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Technical Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="Enter Field Technical Name " [(ngModel)]="field['field_technical_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="update_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Field Name:
                                </div>
                                <div class="col-4">
                                    <input type="text" placeholder="Enter Field Name " class="form-control" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
