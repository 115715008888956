<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title" style="text-align: center;">Allotment Letter Configuration</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> List</a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true"> </i>
                                Create </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="fa fa-plus-circle"
                                    aria-hidden="true"> </i>
                                Update </a>
                        </li>
                    </ul>
                    <br>
                    <div *ngIf="list_flag">
                        <br>
                        <mat-form-field style="margin-left: 2%">
                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Fliter">
                        </mat-form-field>
                        <br>
                        <div class="col-11 text-right">
                            <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                        </div>
                        <div class=" example-container">
                            <table style="padding-left: 70px;" mat-table [dataSource]="datasource" matSort>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                        <b>ID</b>
                                    </th>

                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                        {{ element.id }}</td>
                                </ng-container>

                                <ng-container matColumnDef="desc1">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        <b>Header Desc</b>

                                    </th>

                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.header_desc }} </td>
                                </ng-container>
                                <ng-container matColumnDef="desc2">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        <b>Footer Desc</b>

                                    </th>

                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                        {{ element.footer_desc }} </td>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                        <b>Action</b>
                                    </th>

                                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                        <button class="btn btn-outline-primary"
                                            (click)="open_update(element)">Update</button>

                                        &nbsp;&nbsp;
                                        <!-- <button class="btn btn-outline-danger" (click)="delete(element)">Delete</button> -->
                                        <button class="btn btn-outline-primary" (click)="view(element)">View Image
                                            1</button>
                                        <button class="btn btn-outline-success" (click)="view2(element)">View Image
                                            2</button>
                                    </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                        <br>
                        <div style="background:white;text-align: center; ">
                            <img [src]="fileURL" width="300px"
                                style="max-width: 75%;padding-left: 3px;padding-top: 2px" />
                        </div>
                    </div>
                    <div *ngIf="create_flag">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Select image 1<span style="color: red;"> *</span></b></div>
                            <div class="col-3">

                                <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload($event,file.files)">
                            </div>
                            <div class="col-3">
                                <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                    class="btn btn-outline-info" (click)="Upload('co_applicant_sig_file_name')"><i
                                        class="fa fa-upload" aria-hidden="true">&nbsp;</i>Upload</button>

                            </div>
                            <div class="col-3"></div>
                        </div><br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Select image 2 <span style="color: red;"> *</span></b></div>
                            <div class="col-3">

                                <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload2($event,file.files)">
                            </div>
                            <div class="col-3">
                                <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                    class="btn btn-outline-info" (click)="Upload2('co_applicant_sig_file_name')"><i
                                        class="fa fa-upload" aria-hidden="true">&nbsp;</i>Upload</button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Enter Header Information</b><span style="color: red;"> *</span></div>
                            <div class="col-8">
                                <input type="text" class="form-control" placeholder="Enter Heading..."
                                    [(ngModel)]="Obj['header_desc']">
                            </div>


                        </div>
                        <br>
                        <div class="row">

                            <div class="col-8"></div>
                            <div class="col-4">
                                <button (click)="add()" class="btn btn-outline-primary"><i class="fa fa-plus-circle"
                                        aria-hidden="true"></i></button>

                            </div>
                        </div>
                        <br>

                        <div class=" example-container">
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                <thead>
                                    <tr>
                                        <th>ID</th>

                                        <th>Description</th>

                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of table_data;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <input type="text" class="form-control" placeholder="Enter Heading..."
                                                [(ngModel)]="item['desc']">
                                        </td>
                                        <td>
                                            <button (click)="remove(item,i)"
                                                class="btn btn-outline-danger">Remove</button> &nbsp;

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <br>
                        <div class="row">
                            <!-- <div class="col-1"></div> -->
                            <div class="col-1"></div>
                            <div class="col-2"><b>Enter Footer Information</b><span style="color: red;"> *</span></div>
                            <div class="col-8" style="width:100%; height: 150px; padding-left:80px;">
                                <input type="text" class="form-control" placeholder="Enter Heading..."
                                    [(ngModel)]="Obj['footer_desc']">
                            </div>

                        </div>

                        <div class="row text-center">
                            <div class="col-12">
                                <button class="btn btn-outline-success" (click)="submit()">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="update_flag">
                        <div class="row">
                            <div class="col-12 text-right">
                                <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Select image 1<span style="color: red;"> *</span></b></div>
                            <div class="col-3">

                                <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload($event,file.files)">
                            </div>
                            <div class="col-3">
                                <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                    class="btn btn-outline-info" (click)="Upload('co_applicant_sig_file_name')"><i
                                        class="fa fa-upload" aria-hidden="true">&nbsp;</i>Upload</button>

                            </div>
                            <div class="col-3"></div>
                        </div><br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Select image 2 <span style="color: red;"> *</span></b></div>
                            <div class="col-3">

                                <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader"
                                    class="form-control" style="border-color: black"
                                    (change)="onFileUpload2($event,file.files)">
                            </div>
                            <div class="col-3">
                                <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                    class="btn btn-outline-info" (click)="Upload2('co_applicant_sig_file_name')"><i
                                        class="fa fa-upload" aria-hidden="true">&nbsp;</i>Upload</button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2"><b>Enter Header Information</b><span style="color: red;"> *</span></div>
                            <div class="col-8">
                                <input type="text" class="form-control" placeholder="Enter Heading..."
                                    [(ngModel)]="Obj['header_desc']">
                            </div>


                        </div>
                        <br>
                        <div class="row">

                            <div class="col-8"></div>
                            <div class="col-4">
                                <button (click)="add()" class="btn btn-outline-primary"><i class="fa fa-plus-circle"
                                        aria-hidden="true"></i></button>

                            </div>
                        </div>
                        <br>

                        <div class=" example-container">
                            <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                <thead>
                                    <tr>
                                        <th>ID</th>

                                        <th>Description</th>

                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of table_data;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>
                                            <input type="text" class="form-control" placeholder="Enter Heading..."
                                                [(ngModel)]="item['desc']">
                                        </td>
                                        <td>
                                            <button (click)="remove(item,i)"
                                                class="btn btn-outline-danger">Remove</button> &nbsp;

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <br>
                        <div class="row">
                            <!-- <div class="col-1"></div> -->
                            <div class="col-1"></div>
                            <div class="col-2"><b>Enter Footer Information</b><span style="color: red;"> *</span></div>
                            <div class="col-8" style="width:100%; height: 150px; padding-left:80px;">
                                <input type="text" class="form-control" placeholder="Enter Heading..."
                                    [(ngModel)]="Obj['footer_desc']">
                            </div>

                        </div>


                        <div class="row text-center">
                            <div class="col-12">
                                <button class="btn btn-outline-primary" (click)="update()">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
