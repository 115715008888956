<div class="page-content fade-in-up">
   <div class="row">
      <div class="col-12">
         <div class="ibox">
            <div class="ibox-head">
               <div class="ibox-title">Manage Defaulters</div>
               <!--Property-->
               <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
            </div>

            <div class="ibox-body" *ngIf="body1">
               <ul class="nav nav-tabs tabs-line">
                  <li class="nav-item">
                     <a class="nav-link active" data-toggle="tab"><i class="fa
                            fa-line-chart"></i> Defaults List</a>
                     <!--Property  ledger List-->
                  </li>
               </ul>
               <!-- {{propDetails_arr |json}} -->
               <div class="tab-content">
                  <!-- drop don code starts -->
                  <div class="row mt-5">
                     <div class="col-2"></div>
                     <div class="col-3 text-right">
                        <h6>Scheme / Offer :*</h6>
                     </div>
                     <div class="col-3 text-left">
                        <ng-select [items]="allOffer" bindLabel="offer_name" bindValue="offer_cd" [multiple]="false"
                           placeholder="Select Offer" [(ngModel)]="obj['offer_cd']" [selectableGroup]="true"
                           (click)="getpropertytype()" [selectableGroupAsModel]="false" [closeOnSelect]="true">
                        </ng-select>
                     </div>
                  </div>

                  <br />
<!--
                   <div class="row">
                      <div class="col-2"></div>
                      <div class="col-3 text-right">
                         <h6>Property Category :</h6>
                      </div>
                      <div class="col-3 text-left">
                        <ng-select [items]="" bindLabel="" bindValue="" [multiple]="false" placeholder="Select Offer"
                           [(ngModel)]="obj['']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                           [closeOnSelect]="true">
                        </ng-select>
                      </div>
                   </div> -->
                   <br />
                  <div class="row">
                     <div class="col-2"></div>
                     <div class="col-3 text-right">
                        <h6>Property Type :</h6>
                     </div>
                     <div class="col-3 text-left">
                        <ng-select [items]="pro_type" bindLabel="pro_type" bindValue="pro_type" [multiple]="false"
                           placeholder="Select property type" [(ngModel)]="obj['pro_type']" [selectableGroup]="true"
                           [selectableGroupAsModel]="false" [closeOnSelect]="true">
                        </ng-select>
                     </div>
                  </div>
                  <!--btun start-->
                  <br />
                  <div class="row">
                     <div class="col-5"></div>
                     <div class="col-3 text-center">
                        <button class="btn btn-outline-primary" (click)="getdefaulterdata()">
                           Search
                        </button>
                     </div>
                  </div>
                  <!--btun end-->
                  <br />
                  <!--btn-->
                  <div class="row">
                     <div class="col-4">
                        <mat-form-field style="margin-left: 2%">
                           <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="search">
                        </mat-form-field>
                     </div>
                     <div class=" col-8 text-right">
                        <!-- <button class="btn btn-outline-success ">Add approvel</button>
                        &nbsp;&nbsp; -->
                        <button class="btn btn-outline-success">Download</button>
                     </div>

                  </div>
                  <!--btn-->
                  <br />
                  <br />
                  <div class="example-container">

                     <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
                        <!-- selection -->
                        <ng-container matColumnDef="select">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <h6>select</h6>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" >
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                           </td>
                        </ng-container>

                        <!-- party_id Column -->
                        <ng-container matColumnDef="party_id">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b>Property Id</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.party_id }}
                           </td>
                        </ng-container>

                        <!-- property_type Column -->
                        <ng-container matColumnDef="property_type">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b>Property type</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.property_type }}
                           </td>
                        </ng-container>
                        <!--  -->
                        <ng-container matColumnDef="assigned_property_number">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b>Property No</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.assigned_property_number }}
                           </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="party_name">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b> Allottee Name</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.party_name }}
                           </td>
                        </ng-container>
                        <!--  -->
                        <ng-container matColumnDef="rem_count">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b> No of Notic</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              {{ element.rem_count }}
                           </td>
                        </ng-container>

                        <!-- Name Column -->
                        <!-- <ng-container matColumnDef="Notice_Date">
                       <th mat-header-cell mat-sort-header *matHeaderCellDef>
                       <h6 class="text-center" >Notic Date</h6>
                      </th>
                       <td mat-cell *matCellDef="let element">null</td>
                       </ng-container> -->

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="Status">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b>Status</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element" class="right">
                              Generated</td>
                        </ng-container>
                        <!--  -->
                        <ng-container matColumnDef="Action">
                           <th mat-header-cell mat-sort-header *matHeaderCellDef>
                              <b>Action</b>
                           </th>
                           <td mat-cell flex-stretch *matCellDef="let element; let i= index" class="right">
                              <button class="btn btn-outline-primary" (click)="flag1(i)">
                                 View Details</button><br>
                              <!-- <button class="btn btn-outline-success but-w  mt-1 " (click)="flag2(element)">Generate
                                 Notice</button><br>
                              <button class="btn btn-outline-info but-w  mt-1 " (click)="flag3(element)">
                                 Update Status</button><br>
                              <button class="btn btn-outline-danger but-w mb-3  mt-1" (click)="flag4(element)">
                                 Cancel Allotement</button> -->
                           </td>
                        </ng-container>
                        <!--  -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns "></tr>
                     </table>
                     <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

                     <!-- mat table  -->
                  </div>
               </div>
            </div>

            <!--flag 1 -->
            <div class="ibox-body" *ngIf="body2">
               <ul class="nav nav-tabs tabs-line d-flex justify-content-between">
                  <li class="nav-item">
                     <a class="nav-link active" data-toggle="tab"><i class="fa fa-line-chart"></i>Payment Default
                        Details</a>
                     <!--Property  ledger List-->
                  </li>

               </ul>

               <div class="tab-content">
                  <br>
                  <!-- start -->
                  <div class="col-12 text-right" *ngIf="body2">
                     <button class="btn btn-outline-primary" (click)="close()">
                        Go To List
                     </button>
                  </div>
                  <div class="row mt-5">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Allotment ID :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.allotment_id }}</h6>
                     </div>
                     <div class="col">
                        <h6>Scheme Description :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.offer_desc }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Allotte Name :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.party_name }}</h6>
                     </div>
                     <div class="col">
                        <h6>Allotment Date :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.allotment_date|date:'MMM d, y' }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Property ID :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.alloted_property_id }}</h6>
                     </div>
                     <div class="col">
                        <h6>Property Category :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.pro_type }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Payment Mode :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.payment_option }}</h6>
                     </div>
                     <div class="col">
                        <h6>Property Type :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.property_type }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Property Cost (Rs) :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.pro_rate }}</h6>
                     </div>
                     <div class="col">
                        <h6>Total No Of Instalment</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.no_of_payment }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Last Payment Date :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.challan_date|date:'MMM d, y'}}</h6>
                     </div>
                     <div class="col">
                        <h6>Last Paid Amount (Rs) :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.paid_amount }}</h6>
                     </div>
                  </div>


                  <!-- end  -->



                  <h5 class="mt-5">Missed Installment Details:</h5>


                  <table class="table table-bordered mt-5">
                     <thead>
                        <tr class="text-center">
                           <th scope="col">So No.</th>
                           <th scope="col">Instalment No.</th>
                           <th scope="col">Instalment Month</th>
                           <th scope="col">Instalment Amount Rs.</th>
                           <th scope="col">Aditional Intrest Rate</th>
                           <th scope="col">Balance Due</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>{{ flagdata.no_of_pay }}</td>
                           <td>{{ flagdata.challan_date | date:'MMM , y' }}</td>
                           <td>{{flagdata['emi_amount']}}</td>
                           <td>0.0</td>
                           <td>{{ flagdata.remaining_amount }}</td>
                        </tr>
                     </tbody>
                  </table>

                  <div class="row mt-3 justify-content-center">
                     <div class="col-3 text-center">
                        <button class="btn btn-outline-primary" (click)="close()">
                           Close
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <!-- flag -->
            <!--flag 2 start -->
            <div class="ibox-body" *ngIf="body3">
               <ul class="nav nav-tabs tabs-line d-flex justify-content-between">
                  <li class="nav-item">
                     <a class="nav-link active" data-toggle="tab"><i class="fa fa-line-chart"></i>Generate Default
                        Notice</a>
                     <!--Property  ledger List-->
                  </li>
                  <div class="text-center" *ngIf="body3">
                     <button class="btn btn-outline-primary" (click)="close()">
                        Go To List
                     </button>
                  </div>
               </ul>


               <div class="tab-content">
                  <div class="row mt-5">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Allotment ID :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.allotment_id }}</h6>
                     </div>
                     <div class="col">
                        <h6>Scheme Description :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.offer_desc }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Allotte Name :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.party_name }}</h6>
                     </div>
                     <div class="col">
                        <h6>Allotment Date :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.allotment_date|date:'MMM d, y' }}</h6>
                     </div>
                  </div>
                  <div class="row mt-3">
                     <div class="col-1"></div>
                     <div class="col">
                        <h6>Property ID :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.alloted_property_id }}</h6>
                     </div>
                     <div class="col">
                        <h6>Property Category :</h6>
                     </div>
                     <div class="col">
                        <h6>{{ flagdata.pro_type }}</h6>
                     </div>
                  </div>
                  <br />
                  <br />
                  <h5>Missed Installment Details:</h5>
                  <br />
                  <br />
                  <table class="table table-bordered mt-5">
                     <thead>
                        <tr class="text-center">
                           <th scope="col">So No.</th>
                           <th scope="col">Instalment No.</th>
                           <th scope="col">Instalment Month</th>
                           <th scope="col">Instalment Amount Rs.</th>
                           <th scope="col">Aditional Intrest Rate</th>
                           <th scope="col">Balance Due</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>

                           <td>1</td>
                           <td>{{ flagdata.no_of_pay }}</td>
                           <td>{{ flagdata.challan_date | date:'MMM , y' }}</td>
                           <td>{{flagdata['emi_amount']}}</td>
                           <td>0.0</td>
                           <td>{{ flagdata.remaining_amount }}</td>
                        </tr>
                     </tbody>
                  </table>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div class="row">
                     <div class="col-2">
                        <h6 class="font-19">Default Notice No.:*</h6>
                     </div>
                     <div class="col-md-3">
                        <h6>{{currentNoticeData['notice_no']}}</h6>
                     </div>
                     <div class="col-3">
                        <h6 class="font-19">Default Notice Periad:*</h6>
                     </div>
                     <div class="col-md-3">
                        <h6>{{this.currentNoticeData['period']}}</h6>
                     </div>

                  </div>
                  <div class="row mt-5">
                     <div class="col-2">
                        <h6 class="font-19">Notice Date:*</h6>
                     </div>
                     <div class="col-3">
                        <input type="date" class="form-control" [(ngModel)]="flagdata['date']">
                     </div>
                     <div class="col-3">
                        <h6 class="font-bold font-19 p-1">Singnatory Authority for Default Notice :*</h6>
                     </div>
                     <div class="col-3 text-left">
                        <ng-select [items]="allSearchableEmp" placeholder="Select User" bindLabel="desc"
                           bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                           [closeOnSelect]="true" name="hierarchy" [(ngModel)]="flagdata['selected_auth']"
                           [multiple]="true">
                        </ng-select>
                        <!-- <ng-select [items]="cars" bindLabel="name" bindValue="name" [(ngModel)]="flagdata['selected_auth']" [multiple]="true">
                       </ng-select> -->
                     </div>
                  </div>
                  <br />
                  <br />
                  <!--btn strat -->
                  <div class="row justify-content-center">
                     <div class="btndiv">
                        <button class="btn btn-outline-primary" (click)="close()">Close</button>
                        &nbsp;
                        <button class="btn btn-outline-primary" (click)="createGenerateNotice()">Generate
                           Notice</button>
                     </div>
                  </div>
                  <!--btn end -->
               </div>
            </div>
            <!--flag 2 end -->
            <!--flag 3 start -->
            <div class="ibox-body" *ngIf="body4">
               <ul class="nav nav-tabs tabs-line">
                  <li class="nav-item" style="display: flex">
                     <a class="nav-link active" data-toggle="tab"><i class="fa
                            fa-line-chart"></i>Update Status</a>
                     <a style="float: right">
                        <button class="btn btn-outline-primary" (click)="close()">
                           close
                        </button>
                     </a>
                     <!--Property  ledger List-->
                  </li>
               </ul>
               <div class="tab-content">
                  <div class="row">
                     <div class="col">
                        <div class="row text-left">
                           <h6>Allotment ID :</h6>
                        </div>
                        <div class="row text-left">
                           <h6>Allotte Name :</h6>
                        </div>
                        <div class="row text-left">
                           <h6>Property ID :</h6>
                        </div>
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>{{ flagdata.alloted_property_id }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.party_name }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.alloted_property_id }}</h6>
                        </div>
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>Scheme Description :</h6>
                        </div>
                        <div class="row text-left">
                           <h6>Allotment Date :</h6>
                        </div>
                        <div class="row text-left">
                           <h6>Property Category :</h6>
                        </div>
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>{{flagdata.offer_cd}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{flagdata.allotment_date}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{flagdata.pro_type</h6>
                        </div>
                     </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div class="row">
                     <div class="col-3"></div>
                     <div class="col-3 text-right">
                        <h6>Default Status :</h6>
                     </div>
                     <div class="col-3 text-left">
                        <ng-select [items]="cars" bindLabel="name" bindValue="id" [(ngModel)]="selectedCar">
                           placeholder = "Property Type"
                        </ng-select>
                     </div>
                  </div>
                  <br />
                  <br />
                  <!--btn strat -->
                  <div class="row align-items-center">
                     <div class="align-items-center">
                        <button class="btn btn-outline-primary">Close</button>
                        &nbsp;
                        <button class="btn btn-outline-primary">Generate Notice</button>
                     </div>
                  </div>
                  <!--btn end -->
               </div>
            </div>
            <!--flag three end -->
            <!--flag four starts-->
            <div class="ibox-body" *ngIf="body5">
               <ul class="nav nav-tabs tabs-line">
                  <li class="nav-item" style="display: flex">
                     <a class="nav-link active" data-toggle="tab"><i class="fa
                            fa-line-chart"></i>Property Allotment
                        Cancellation</a>
                     <a style="float: right">
                        <button class="btn btn-outline-primary" (click)="close()">
                           close
                        </button>
                     </a>
                     <!--Property  ledger List-->
                  </li>
               </ul>
               <div class="tab-content">
                  <div class="row">
                     <div class="col">
                        <div class="row text-left">
                           <h6>Allotment ID/No. :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Allottee Name :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Property ID :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Payment Mode :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Property Cost (RS) :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Total Number of Instalments :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Last Payment Date :</h6>
                        </div>
                        <br />
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>{{ flagdata.alloted_property_id }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.party_name }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.alloted_property_id }}</h6>
                        </div>
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>Scheme Description :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Allotment Date :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Property Category :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Property Type :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Total Paid Amount (RS) :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Remaining Amount (RS) :</h6>
                        </div>
                        <br />
                        <div class="row text-left">
                           <h6>Last Paid Amount (RS) :</h6>
                        </div>
                        <br />
                     </div>
                     <div class="col">
                        <div class="row text-left">
                           <h6>{{ flagdata.offer_cd }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.allotment_date }}</h6>
                        </div>
                        <div class="row text-left">
                           <h6>{{ flagdata.pro_type }}</h6>
                        </div>
                     </div>
                  </div>
                  <br />
                  <br />

                  <!-- table strat -->
                  <div class="row">
                     <h6>Missed Installment Details:</h6>
                  </div>
                  <br />
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">sr.no.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Additional Interest(Rs)</th>
                           <th scope="col">Balance Due</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                     </tbody>
                  </table>
                  <!-- table end -->
                  <br />
                  <!-- table 2 start-->
                  <div class="row">
                     <h6>Failed Installment Details:</h6>
                  </div>
                  <br />
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">sr.no.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Installment No.</th>
                           <th scope="col">Additional Interest(Rs)</th>
                           <th scope="col">Balance Due</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>2</td>
                           <td>Oct,2021</td>
                           <td>20,000.00</td>
                           <td>0.0</td>
                           <td>20,000.00</td>
                        </tr>
                     </tbody>
                  </table>
                  <!-- table 2 end  -->

                  <!-- table 3 start -->

                  <div class="row">
                     <h6>Default Notice Details:</h6>
                  </div>
                  <br />
                  <table class="table table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">Notice No.</th>
                           <th scope="col">Notice Later No.</th>
                           <th scope="col">Notice Date</th>
                           <th scope="col">Notice Period (Days)</th>
                           <th scope="col">Signatuary Authority</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>1</td>
                           <td>UPAV-2022/Jan-0012</td>
                           <td>01/01/2022</td>
                           <td>30</td>
                           <td>Dept. Incharge</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>UPAV-2022/Jan-0012</td>
                           <td>01/01/2022</td>
                           <td>30</td>
                           <td>Dept. Incharge</td>
                        </tr>
                        <tr>
                           <td>1</td>
                           <td>UPAV-2022/Jan-0012</td>
                           <td>01/01/2022</td>
                           <td>30</td>
                           <td>Dept. Incharge</td>
                        </tr>
                     </tbody>
                  </table>

                  <!-- table 3 start -->

                  <br />
                  <!--btn strat -->
                  <div class="row">
                     <div class="col text-right">
                        <button class="btn btn-outline-primary">Close</button>
                     </div>
                     <div class="col text-left">
                        <button class="btn btn-outline-primary">Generate Notice</button>
                     </div>
                  </div>
                  <!--btn end -->
               </div>
            </div>
            <!--flag forur ends -->
            <br />
            <br />
            <br />

            <!-- <hr> -->
            <hr class="hrline" />
            <br />
            <br />
         </div>
      </div>

      <br />
   </div>
</div>
