import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from '../service/main.service';
import { LitigationService } from '../service/litigation.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cause-list',
  templateUrl: './cause-list.component.html',
  styleUrls: ['./cause-list.component.css']
})
export class CauseListComponent implements OnInit {

  displayedColumns:any = ['s.no.','case_id','petitioner','defendent','defendent_counsel','hearing_time'];
  erpUser:any;
  causeListObj :any= {};
  courtArr:any = [];
  dataSource:any;
  causeListDate:any="";
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource){
      this.dataSource.sort = value;
    }
  }
  constructor(public mainservice:MainService,public liti:LitigationService) { }
  b_acct_id :any
  arr4:any=[]
  zoneCodeArr:any=[]
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    await this.getzone()
  }




  async submitCause()
   {
    this.causeListObj['b_acct_id']=this.erpUser.b_acct_id
    //this.causeListObj['zone_cd']=this.courtArr['lvl3_cd']

    var resp:any =await this.liti.getCauseList(this.causeListObj);
    // console.log(resp);
    var courtArr1 = [];
    if(resp["error"]==false)
    {
      if(resp["data1"].length==0)
      {
        Swal.fire('Info','No Case Available On This Date','warning');
        return;
      }
      this.causeListObj['date']=moment(this.causeListObj['date']).format("DD-MM-YYYY")
      // console.log("date is ", this.causeListObj["date"]);
      courtArr1 = resp['data1'];
      this.causeListDate = 'Date of Cause List'+" : " +this.causeListObj['date']
      // console.log(courtArr1)
      this.dataSource = new MatTableDataSource(courtArr1)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
   }
   async getzone(){
    let obj:any =new Object()
    obj['b_acct_id']=this.b_acct_id;
    obj['user_id']=this.erpUser.user_id
    //this.spinner.show()
    var resp1:any =await this.liti.getcausezonedata(obj)
    // console.log(resp1)
    let assignArr=[]
    if(resp1['error']==false){
     // this.spinner.hide()
    // resp1['data'].map(x=>{
    //   assignArr.push(x['node_cd'])
    //  })
     assignArr=resp1['data']
    //  console.log("this is the assign array data ",assignArr)
     for(let i =0;i<assignArr.length;i++)
     {
      this.zoneCodeArr.push(assignArr[i]['node_cd'])
     }
    //  console.log(this.zoneCodeArr)

     var obj1 :any=new Object()
     obj1["b_acct_id"]=this.b_acct_id
     obj1['zone_cd_arr']=this.zoneCodeArr
    //  console.log("this is the ",obj1)
    var resp:any = await this.liti.getcause(obj1)
    // console.log(resp)
    if(resp["error"]==false)
    {
      this.courtArr=resp['data']
      // console.log(this.courtArr)
      for(let i=0;i<this.courtArr.length;i++)
      {
        this.courtArr[i]['lvl3_cd']= this.courtArr[i]['lvl3_cd'].toString()
      }
      // console.log(this.courtArr)
    }

    }else{
     // this.spinner.hide()
    }



  }

}

