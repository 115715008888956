import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MainService as AdminMainService } from '../../../admin/service/main.service'
import Swal from 'sweetalert2';
import { PaymentService } from '../../service/payment.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})
export class PaymentGatewayComponent implements OnInit {
  ebillUser: any;
  b_acct_id: any;
  gatWay: any ;
  list_flag = true;
  create_flag = false;
  displayedColumns: string[] = ['s.no','Bank_name', 'branch_name', 'gateway_name', 'payment_purpose', 'action'];
  datasource: any;

  constructor(private adminMainService: AdminMainService, private paymnetgateway: PaymentService) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  ngOnInit() {

    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    // console.log(this.b_acct_id)

    this.getgatewaydetails();
  }

  open_create(){
    this.gatWayList = [];
    this.gateWayId = undefined;
    this.create_flag = true;
    this.list_flag = false;
    this.getGatwayList();
  }
  applyFilter(event:any){

  }
  open_list(){
    this.create_flag = false;
    this.list_flag = true;
  }
  gateWayId:any
  i:any
  async getAccountbyGatewayID(gatewayID:any, i:any) {
    // console.log(gatewayID);
    this.gateWayId = gatewayID
    this.getpaymentdetails();
  }
  admindata = []
  async getGatwayList() {
    var obj:any = new Object();
    obj['b_acct_id'] = 0
    var resp = await this.adminMainService.getPaymentGatwayData(JSON.stringify(obj)as any);
    // console.log(resp, 'gatway list')
    if (resp['error'] == false) {
      this.admindata = resp['data'];
      for (let i = 0; i < this.admindata.length; i++) {
        if (this.admindata[i]['life_cycle_status'] != 'ACTIVE') {
          this.admindata.splice(i, 1)
        }
      }
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }
  gatWayList = []
  async getpaymentdetails() {
    let obj:any = {}
    // console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id
    obj['gateway_id'] = this.gateWayId
    // console.log(obj)
    var resp :any= await this.paymnetgateway.getpaymentgatewayData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      this.gatWayList = resp['data']
      // console.log(this.gatWayList);
    }


  }


  async getgatewaydetails() {
    let obj:any = {}
    // console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id
    // console.log(obj)
    var resp:any = await this.paymnetgateway.getpaymentgatewayDetails(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      this.gatWay = resp['data']
      // console.log(this.gatWay);
      this.datasource =   new MatTableDataSource(this.gatWay)
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }


  }
  async submit(gateWayId:any) {
    // console.log(gateWayId)
    let Obj:any = {}
    for(let i = 0 ; i<this.gatWayList.length;i++){
      // console.log("i am in");
         if(this.gatWayList[i]['gateway_id'] == gateWayId ) {
          // console.log(this.gatWayList[i])
           Obj = this.gatWayList[i];
         }
    }
    // console.log(Obj)
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['gateway_id'] = Obj['gateway_id']
    obj['mid_id'] = Obj['mid_id']
    obj['payment_purpose'] = Obj['purpose']
    obj['life_cycle_status'] = Obj['life_cycle_status']
    obj['branch_name'] = Obj['branch_name']
    obj['gateway_name'] = Obj['gateway_name']
    obj['Bank_name'] =  Obj['bank_name']
    // console.log(obj);
  if(!( obj['b_acct_id'] && obj['gateway_id']  && obj['mid_id']  &&  obj['payment_purpose']  && obj['life_cycle_status']  &&  obj['branch_name']  &&  obj['gateway_name']  &&  obj['Bank_name'] )){
   Swal.fire('info','please select Gatway or data not found','info')
   return
  }
  else{
    var resp :any= await this.paymnetgateway.submitgatewaydetails(obj);
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      Swal.fire('success', 'Data inserted successfully','success');
      this.list_flag = true;
      this.create_flag = false;
      this.getgatewaydetails();
      return
    }
  }

  }
  async delete(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      // console.log(result, result.value)
      if (result.value) {

        this.finaldelete(element)

      }
    })
  }
  async finaldelete(element:any) {
    let obj:any = {}
    // console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id
    obj['mid_id'] = element.mid_id
    // console.log(obj)
    var resp :any= await this.paymnetgateway.deletegatewaydetails(obj);
    // console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      Swal.fire('success', 'Data deleted successfully','success');
      this.getgatewaydetails();
      return
    }


  }

}
