import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import { PropCostingService } from '../../service/prop-costing.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-property-feature',
  templateUrl: './property-feature.component.html',
  styleUrls: ['./property-feature.component.css']
})
export class PropertyFeatureComponent implements OnInit {
  planing_arr: any;
  datasource2: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  allCodeValue: any;
  selectedCodeValue: any;
  selectField: any;
  isLinear:any
  first:any
  second:any
  third:any
  erpUser: any;
  datasource:any;
  Obj :any= {}
  displayedColumns_1 = ['id', 'feature_code', 'feature_desc', 'feature_unit', 'action']
  displayedColumns_2 = ['id', 'feature_code', 'feature_desc', 'feature_unit', 'action']
  constructor(private settingService: SettingService, private spinner: NgxSpinnerService, private costingService: PropCostingService, public mainService: MainService) { }
  radio = true
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
  }
  feature_list :any= []
  async getFeatures() {
    if (this.Obj['feature_type']) {
      this.spinner.show()
      this.feature_list = []
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      console.log(this.Obj)
      var resp = await this.costingService.getFeatures(JSON.stringify(this.Obj));
      if (resp['error'] == false) {
        console.log(resp['data'], 'list')
        let data = resp['data']
        this.datasource = new MatTableDataSource(data);
        this.datasource.sort = this.sort.toArray()[0];
        this.datasource.paginator = this.paginator.toArray()[0];
        this.spinner.hide();
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Error While Getting Data', 'error')
      }
    } else {
      Swal.fire('Information...', 'Please Select Feature Type', 'info')
    }
  }
  select(data:any) {
    let ob = Object.assign({}, this.Obj)
    this.Obj=Object.assign({},data)
    this.Obj['feature_type']=ob['feature_type']
    this.stepper1.next()
  }
  async getFeaturesExceptSelectedGroup() {
    this.spinner.show()
    this.feature_list = []
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    console.log(this.Obj)
    var resp = await this.costingService.getFeaturesExceptGiven(JSON.stringify(this.Obj));
    if (resp['error'] == false) {
      console.log(resp['data'], 'list')
      let data = resp['data']
      this.datasource = new MatTableDataSource(data);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.feature_list = data
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {
    if (this.Obj['feature_type']) {
      this.list_flag = false
      this.create_flag = true
      this.update_flag = false
      this.datasource = new MatTableDataSource([]);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
    }
    else {
      Swal.fire("Oops", "First Select Feature Group* ", 'error');
    }

  }
  open_update(data:any) {
    this.Obj = Object.assign({}, data)
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true

  }
  open_list() {

    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    this.getFeatures()
  }
  b_acct_id(b_acct_id: any) {
    throw new Error('Method not implemented.');
  }
  async changeField() {
    this.selectedCodeValue = [];
    for (var i = 0; i < this.allCodeValue.length; i++) {
      if ('PROPFLD52' == this.allCodeValue[i].field_code) {
        this.selectedCodeValue.push(this.allCodeValue[i])
      }
    }
    console.log(this.selectedCodeValue);
  }
  refresh() {
    if (!this.Obj['feature_type'])
      Swal.fire("Oops", "First Select Feature Group* ", 'error');
  }
  async delete(element:any) {
    console.log(element)
    this.Obj = Object.assign({}, element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete()
      }
    })
  }
  async finaldelete() {
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    let resp = await this.costingService.deleteFeature(this.Obj);
    console.log(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getFeatures()
      this.list_flag = true
      this.create_flag = false
      this.update_flag = false
      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }


  }
  async submit() {
    if (!this.Obj['feature_type'] || !this.Obj['feature_code'] || !this.Obj['feature_desc'] || !this.Obj['feature_unit']) {
      Swal.fire('Inforation..', 'Please Select All * Fields', 'info')
    } else {
      console.log(this.Obj)
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      this.Obj['user_id'] = this.erpUser['user_id']
      console.log(this.Obj)
      this.spinner.show()
      let resp = await this.costingService.createFeature(this.Obj)
      if (resp['error'] == false) {
        this.spinner.hide()
        this.list_flag = true
        this.create_flag = false
        this.update_flag = false
        await this.getFeatures()
        Swal.fire('Success...', 'Submitted Successfully.', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', resp['data'], 'error')
      }
    }
  }
  async update() {
    if (!this.Obj['feature_type'] || !this.Obj['feature_code'] || !this.Obj['feature_desc'] || !this.Obj['feature_unit']) {
      Swal.fire('Inforation..', 'Please Select All * Fields', 'info')
    } else {
      console.log(this.Obj)
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'ACTIVE'
      this.Obj['user_id'] = this.erpUser['user_id']
      console.log(this.Obj)
      this.spinner.show()
      let resp = await this.costingService.updateFeature(this.Obj)
      if (resp['error'] == false) {
        this.spinner.hide()
        this.list_flag = true
        this.create_flag = false
        this.update_flag = false
        await this.getFeatures()
        Swal.fire('Success...', 'Updated Successfully.', 'success')
      } else {
        this.spinner.hide()
        Swal.fire('Error', resp['data'], 'error')
      }
    }

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
