<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">MANAGE Applicants</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" (click)="refressadd()" data-toggle="tab"><i class="ti-bar-chart"></i> All
                                Party List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refressadd()"><i class="ti-settings"></i> Create
                                Party</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refressadd()"><i class="ti-announcement"></i> Update
                                Party</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">





                            <div class="example-container">
                                <div class=" table-container ">
                                    <div class="row">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4 text-center">

                                        </div>

                                    </div>

                                    <div class="example-container">
                                        <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                                            <ng-container matColumnDef="party_id">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Party ID</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.party_id}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="party_name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Party Name</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.party_name}} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="party_father_name">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b> Father's Name</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.party_father_name }} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="party_phone_no">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> <b> Mobile No.</b> </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    {{ element.party_phone_no }} </td>
                                            </ng-container>


                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                                    <button class="btn btn-outline-primary" (click)="openUpdate(element,i)">Update</button>


                                                </td>

                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                            </tr>
                                        </table>
                                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <div class="row" style=" margin-top: 5px;">
                                <div class="col-lg-12 text-center">
                                    <h6><b>Party Detail</b></h6>
                                </div>
                            </div>
                            <br>



                            <div class="row">

                                <div class=" text-right" style="width:23%">
                                    <p> Party Name :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="Party Name" [(ngModel)]="obj['party_name']" placeholder="Party Name">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Father Name :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_father_name" [(ngModel)]="obj['party_father_name']" placeholder="Father's Name">
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Party Email :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_email" [(ngModel)]="obj['party_email']" placeholder="Party Email">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Mobile No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="number" name="party_phone_no" [(ngModel)]="obj['party_phone_no']" placeholder="Mobile Number">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Date of Birth :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="date" name="dob" [(ngModel)]="obj['party_dob']" placeholder="Date of Birth">
                                </div>

                                <div class=" text-right" style="width:18%">
                                    <p> Pan No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_pan_no" [(ngModel)]="obj['party_pan_no']" placeholder="Pan Number">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Aadhaar No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="number" name="party_adhar_no" [(ngModel)]="obj['party_aadhar_no']" placeholder="Aadhaar Number">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> GST No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_gst_no" [(ngModel)]="obj['party_gst_no']" placeholder="GST Number">
                                </div>
                            </div><br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Vertical Reservation :</p>

                                </div>

                                <div style="width:25%;margin-left: 2%;">
                                    <ng-select [items]="resv" bindLabel="reservation_category_name" bindValue="reservation_category_code"
                                    [multiple]="false" placeholder="Vertical Reservation" [(ngModel)]="obj['vertical_reservation']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="vertical_reservation">
                                    </ng-select>
                                    <!-- <select class="form-control" name="reservation_category_code" [(ngModel)]="obj['vertical_reservation']" placeholder="Horizontal">
                                        <option *ngFor="let resv of resv" ngValue="{{resv['reservation_category_code']}}">{{resv['reservation_category_name']}}

                                        </option>
                                    </select> -->

                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Horizontal Reservation :</p>

                                </div>

                                <div style="width:25%;margin-left: 2%;">
                                    <ng-select [items]="resh" bindLabel="reservation_category_name" bindValue="reservation_category_code"
                                    [multiple]="false" placeholder="Horizontal Reservation" [(ngModel)]="obj['horizontal_reservation']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="horizontal_reservation">
                                    </ng-select>
                                    <!-- <select class="form-control" name="reservation_category_code" [(ngModel)]="obj['horizontal_reservation']" placeholder="Vertical">
                                        <option *ngFor="let resh of resh" ngValue="{{resh['reservation_category_code']}}">{{resh['reservation_category_name']}}

                                        </option>
                                    </select> -->

                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col-6" style="border-right: 1px black;">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>Current Address </h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="text-right" style="width:15%;">
                                            <p> Address :</p>
                                        </div>
                                        <div style="width:80%;margin-left: 2%;">
                                            <textarea class="form-control" rows="4" cols="50" type="text" name="party_current_addr_line1" [(ngModel)]="obj['party_current_addr_line']" placeholder="Current Address"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="text-right" style="width:12%;">
                                            <p> City : </p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px; ">
                                            <input class="form-control" type="text" name="party_current_addr_city" [(ngModel)]="obj['party_current_addr_city']" placeholder="City">
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> State :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value"
                                            [multiple]="false" placeholder="State" [(ngModel)]="obj['party_current_addr_state']" [selectableGroup]="true"
                                             [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_current_addr_state">
                                            </ng-select>
                                            <!-- <input class="form-control" type="text" name="party_current_addr_state" [(ngModel)]="obj['party_current_addr_state']" placeholder="State"> -->
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> PIN :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <input class="form-control" type="text" name="party_current_addr_pin_code" [(ngModel)]="obj['party_current_addr_pin_code']" placeholder="PIN Code">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6" style="border-right: 1px ;">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>Permanent Address </h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="text-right" style="width:15%;">
                                            <p> Address :</p>
                                        </div>
                                        <div style="width:80%;margin-left: 2%;">
                                            <textarea class="form-control" rows="4" cols="50" type="text" name="party_permanent_addr_line" [(ngModel)]="obj['party_permanent_addr_line']" placeholder="Permanent Address"></textarea>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="text-right" style="width:12%;">
                                            <p> City : </p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px; ">
                                            <input class="form-control" type="text" name="party_permanent_addr_city" [(ngModel)]="obj['party_permanent_addr_city']" placeholder="City">
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> State :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value"
                                            [multiple]="false" placeholder="State" [(ngModel)]="obj['party_permanent_addr_state']" [selectableGroup]="true"
                                             [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_permanent_addr_state">
                                            </ng-select>
                                            <!-- <input class="form-control" type="text" name="party_permanent_addr_state" [(ngModel)]="obj['party_permanent_addr_state']" placeholder="State"> -->
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> PIN :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <input class="form-control" type="text" name="party_permanent_addr_pin_code" [(ngModel)]="obj['party_permanent_addr_pin_code']" placeholder="PIN Code">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="addNewRow()">Save</button>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <div class="row" style=" margin-top: 5px;">
                                <div class="col-lg-12 text-center">
                                    <h6><b>Party Detail</b></h6>
                                </div>
                            </div>
                            <br>

                            <!-- <div class="row">
                            </div> -->

                            <div class="row">

                                <div class=" text-right" style="width:23%">
                                    <p> Party Name :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="Party Name" [(ngModel)]="obj['party_name']" placeholder="Party Name">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Father Name :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_father_name" [(ngModel)]="obj['party_father_name']" placeholder="Father's Name">
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Party Email :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_email" [(ngModel)]="obj['party_email']" placeholder="Party Email">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Mobile No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="number" name="party_phone_no" [(ngModel)]="obj['party_phone_no']" placeholder="Mobile Number">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Date of Birth :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="date" name="dob" [(ngModel)]="obj['party_dob']" placeholder="Date of Birth">
                                </div>

                                <div class=" text-right" style="width:18%">
                                    <p> Pan No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_pan_no" [(ngModel)]="obj['party_pan_no']" placeholder="Pan Number">
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Aadhaar No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="number" name="party_adhar_no" [(ngModel)]="obj['party_aadhar_no']" placeholder="Aadhaar Number">
                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> GST No. :</p>

                                </div>
                                <div style="width:25%;margin-left: 2%;">
                                    <input class="form-control" type="text" name="party_gst_no" [(ngModel)]="obj['party_gst_no']" placeholder="GST Number">
                                </div>
                            </div><br>
                            <div class="row">
                                <div class=" text-right" style="width:23%">
                                    <p> Vertical Reservation :</p>

                                </div>

                                <div style="width:25%;margin-left: 2%;">
                                    <ng-select [items]="resv" bindLabel="reservation_category_name" bindValue="reservation_category_code"
                                    [multiple]="false" placeholder="Vertical Reservation" [(ngModel)]="obj['vertical_reservation']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="vertical_reservation">
                                    </ng-select>
                                    <!-- <select class="form-control" name="reservation_category_code" [(ngModel)]="obj['vertical_reservation']" placeholder="Horizontal">
                                        <option *ngFor="let resv of resv" ngValue="{{resv['reservation_category_code']}}">{{resv['reservation_category_name']}}

                                        </option>
                                    </select> -->

                                </div>
                                <div class=" text-right" style="width:18%">
                                    <p> Horizontal Reservation :</p>

                                </div>

                                <div style="width:25%;margin-left: 2%;">
                                    <ng-select [items]="resh" bindLabel="reservation_category_name" bindValue="reservation_category_code"
                                    [multiple]="false" placeholder="Horizontal Reservation" [(ngModel)]="obj['horizontal_reservation']" [selectableGroup]="true"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="horizontal_reservation">
                                    </ng-select>
                                    <!-- <select class="form-control" name="reservation_category_code" [(ngModel)]="obj['horizontal_reservation']" placeholder="Vertical">
                                        <option *ngFor="let resh of resh" ngValue="{{resh['reservation_category_code']}}">{{resh['reservation_category_name']}}

                                        </option>
                                    </select> -->

                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col-6" style="border-right: 1px black;">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>Current Address </h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="text-right" style="width:15%;">
                                            <p> Address :</p>
                                        </div>
                                        <div style="width:80%;margin-left: 2%;">
                                            <textarea class="form-control" rows="4" cols="50" type="text" name="party_current_addr_line" [(ngModel)]="obj['party_current_addr_line']" placeholder="Current Address"></textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="text-right" style="width:12%;">
                                            <p> City : </p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px; ">
                                            <input class="form-control" type="text" name="party_current_addr_city" [(ngModel)]="obj['party_current_addr_city']" placeholder="City">
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> State :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value"
                                            [multiple]="false" placeholder="State" [(ngModel)]="obj['party_current_addr_state']" [selectableGroup]="true"
                                             [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_current_addr_state">
                                            </ng-select>
                                            <!-- <input class="form-control" type="text" name="party_current_addr_state" [(ngModel)]="obj['party_current_addr_state']" placeholder="State"> -->
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> PIN :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <input class="form-control" type="text" name="party_permanent_addr_pin_code" [(ngModel)]="obj['party_current_addr_pin_code']" placeholder="PIN Code">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6" style="border-right: 1px ;">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>Permanent Address </h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="text-right" style="width:15%;">
                                            <p> Address :</p>
                                        </div>
                                        <div style="width:80%;margin-left: 2%;">
                                            <textarea class="form-control" rows="4" cols="50" type="text" name="party_permanent_addr_line" [(ngModel)]="obj['party_permanent_addr_line']" placeholder="Permanent Address"></textarea>
                                        </div>
                                    </div>

                                    <br>
                                    <div class="row">
                                        <div class="text-right" style="width:12%;">
                                            <p> City : </p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px; ">
                                            <input class="form-control" type="text" name="party_permanent_addr_city" [(ngModel)]="obj['party_permanent_addr_city']" placeholder="City">
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> State :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <ng-select [items]="mainService.codeValueTechObj['PROP0004']" bindLabel="value" bindValue="value"
                                            [multiple]="false" placeholder="State" [(ngModel)]="obj['party_permanent_addr_state']" [selectableGroup]="true"
                                             [selectableGroupAsModel]="false" [closeOnSelect]="true" name="party_permanent_addr_state">
                                            </ng-select>
                                            <!-- <input class="form-control" type="text" name="party_permanent_addr_state" [(ngModel)]="obj['party_permanent_addr_state']" placeholder="State"> -->
                                        </div>
                                        <div class="text-right" style="width:9%;">
                                            <p> PIN :</p>
                                        </div>
                                        <div class="text-right" style="width:22%;margin-left: 2px;">
                                            <input class="form-control" type="text" name="party_permanent_addr_pin_code" [(ngModel)]="obj['party_permanent_addr_pin_code']" placeholder="PIN Code">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">

                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
