<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri1']}}<!-- Pension Contribution Report --> </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri1']}}<!-- Pension Contribution Report -->
                            </a>
                        </li>



                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri2']}}<!-- Select Employee Type --> :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="joining_type_code" bindLabel="value" bindValue="value" [multiple]="false" placeholder="" [(ngModel)]="licObj['joining_type_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri3']}}<!-- Select Financial Year --> :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="licObj['fin_year']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri4']}}<!-- Select Month --> :<span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="licObj['month']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12  text-center">
                                    <button class="btn btn-primary" (click)="getAllBillID()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri5']}}<!-- SUBMIT -->
                                    </button> &nbsp;&nbsp;

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri6']}}<!-- Total Pension Contribution Amount --> :
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="total" disabled>

                                </div>
                                <div class="col-4 text-right">


                                </div>
                            </div>


                            <hr>

                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri7']}}"> <!-- Filter -->
                                    </mat-form-field>
                                </div>
                                <div class="col-12 text-right" style="margin-bottom: 8px;">
                                    <button class="btn btn-primary" (click)="print()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri8']}}<!-- Print -->
                                    </button> &nbsp;&nbsp;

                                    <button class="btn btn-primary" (click)="export()">
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri9']}}<!-- Export -->
                                    </button> &nbsp;&nbsp;
                                </div>


                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri10']}}<!-- S No -->.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{i+1}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri11']}}<!-- Employee ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{mainService.accInfo['account_short_name']}}{{getNumberFormat(element.emp_id)}}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri12']}}<!-- Employee Name -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri13']}}<!-- Designation -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="basic">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri14']}}<!-- BASIC -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.basic }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="da">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri15']}}<!-- DA -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.da }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="daper">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri15']}}<!-- DA -->%

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.daper }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pen">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri16']}}<!-- Pension Contribution -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pen }} </td>
                                    </ng-container>

                                    <!--  <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning" (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-PensionContri17']}}<!-- Loading -->....</ngx-spinner>
