import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';

@Injectable({
  providedIn: 'root'
})

export class EventGroupService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/accounts/eventgroup";
  }

  async geteventlist(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/geteventlist' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async geteventxref(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/geteventxref' + obj).toPromise().then(res => { // event_group_xref_line
      return res;
    });
    return resp;
  }

  async rejectevent(obj:any){
    const res = await this.http.post<any>(this.httpUrl + '/rejectEvent',obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async insertProcessedData(obj:any){

    const res = await this.http.post<any>(this.httpUrl + '/addProcessedData',obj).toPromise().then(res => {
      return res;
    });
    return res;
  }



}
