<div class="page-content fade-in-up">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-head">
            <div class="ibox-title">Request</div>
          </div>
          <div class="ibox-body">
            <ul class="nav nav-tabs tabs-line">
              <li class="nav-item">
                <a class="nav-link active"  data-toggle="tab"><i class="fa fa-bars" ></i>&nbsp;
                   Request</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" *ngIf="complaint_inspection" data-toggle="tab"><i class="fa fa-plus-circle"
                    aria-hidden="true"></i> Assign complaint inspection
                </a>
              </li> -->

            </ul>
            <div class="tab-content">
              <div >

                <br>
                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                    </mat-form-field>
                  </div>

                </div>


                <div class="example-container">

                  <table mat-table [dataSource]="datasource" matSort class="text-center">
                    <ng-container matColumnDef="s.no">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">
                        <h6>
                          <b>S.No</b>
                        </h6>
                      </th>
                      <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Request_Id">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Request Id
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.request_id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="case_ID">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Case Id
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.case_id}} </td>
                    </ng-container>



                    <ng-container matColumnDef="Request_Date">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Request Date Time
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.request_date}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Request_Purpose">
                      <th mat-header-cell *matHeaderCellDef class="text-center">Request Purpose

                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element">
                        {{element.request_purpose}}   </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="text-center">Status
                        </th>
                        <td mat-cell flex-stretch *matCellDef="let element">  {{element.life_cycle_status}}
                          </td>
                      </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      <button class="btn btn-outline-primary" (click)="viewdetails(element)">View
                        Details</button>&nbsp;&nbsp;
                        <button class="btn btn-outline-primary" (click)="viewdocument(element)">View
                          Document</button>&nbsp;&nbsp;
                        <button class="btn btn-outline-success" (click)="accept(element)"
                        *ngIf="element['life_cycle_status']=='GENERATED'"
                        >Accept</button>&nbsp;&nbsp;
                        <button class="btn btn-outline-danger"
                        *ngIf="element['life_cycle_status']=='GENERATED'"
                        (click)="reject(element)"
                        >Rejected</button>


                    </td>
                  </ng-container>







                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                    </tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                </div>

              </div>




            </div>
            <br>
          </div>
        </div>

      </div>
    </div>

  </div>


  <div class="modal fade" id="staticBackdrop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #AED6F1;">
        <h5 class="modal-title" id="exampleModalLongTitle">
          <h6>Request Detail</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Case ID</h6>
          </div>:
          <div class="col-2">{{requestdtls.case_id}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Request Purpose</h6>
          </div>:
          <div class="col">{{requestdtls.request_purpose}}</div>
          <div class="col-1"></div>
        </div>
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Status</h6>
          </div>:
          <div class="col-2">{{requestdtls.life_cycle_status}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Request Time</h6>
          </div>:
          <div class="col">{{requestdtls.request_date}}</div>
          <div class="col-1"></div>
        </div>

        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Defender Name</h6>
          </div>:
          <div class="col-2">{{requestdtls.name}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6>Defender's Mobile No.</h6>
          </div>:
          <div class="col">{{requestdtls.mob_no}}</div>
          <div class="col-1"></div>
        </div>
        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col-2">
            <h6>Description</h6>
          </div>:
          <div class="col-2">{{requestdtls.description}}</div>
          <div class="col-1"></div>
          <div class="col">
            <h6></h6>
          </div>
          <div class="col"></div>
          <div class="col-1"></div>
        </div>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>

      </div>
    </div>
  </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
