<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_work_flag" class="nav-link active" href="#tab-7-1" data-toggle="tab"><i class="fa fa-line-chart"
                                    aria-hidden="true"></i><!--  Work List -->
                                {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_work_flag" class="nav-link" href="#tab-7-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                <!-- Create Work -->  {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK3']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_work_flag" class="nav-link" href="#tab-7-3" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings" aria-hidden="true"> </i>
                                <!-- Update Work -->  {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK4']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="d_work_flag" class="nav-link" href="#tab-7-4" data-toggle="tab" (click)="updoc()"><i
                                    class="ti-settings" aria-hidden="true"> </i>
                                <!-- Documents -->  {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK5']}}
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_work_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()"> Create Work</button>
                            </div>
                            <div class="row">

                                <mat-form-field>
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>

                            </div>
                            <br>

                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.id}} </td>
                                    </ng-container>


                                    <!-- <ng-container matColumnDef="project_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Project
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['EMB001'][element.project_cd]}}
                                        </td>
                                    </ng-container> -->


                                    <ng-container matColumnDef="work_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Work Order No -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK7']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_no }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="work_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Work Order Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_name}} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="work_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Work Order Value -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.work_order_value}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="tender_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Budget Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.budget_name}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="tender_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Work Order Date -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tempwork_order_dt}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_ref_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Tender/Quotation Ref Number </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tender_ref_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_nic_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Tender/Quotation Nic ID </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tender_nic_id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="node_cd">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Node </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.node_cd}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" (click)="open_update(element)">
                                                <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK13']}}
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-primary" (click)="open_document(element)">
                                                <!-- Upload Document -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-danger" (click)="delete(element)">
                                                <!-- Delete -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK15']}}
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="c_work_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Work List</button>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex align-items-center justify-content-end text-right">
                                    <h6>
                                     Select Work/EMB Mode:
                                    </h6>
                                </div>
                                <div class="col-3 text-center">
                                    <h6 >

                                        <ng-select [items]="embMode" bindLabel="mode" bindValue="value"
                                        [multiple]="false" placeholder="" [(ngModel)]="Obj['emb_mode']"
                                        [selectableGroup]="true"  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="emb_mode">
                                    </ng-select>
                                    </h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Project-Budget Info -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK16']}}
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <!-- <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Select Project :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="AllProjectList" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select ........" [(ngModel)]="Obj['project_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div> -->
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK17']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" (click)="selectProjectPopUp()">Select Project</button>
                                </div>

                                <br>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK73']}} :

                                    </h6>
                                </div>
                                <div class="col-3 text-left">
                                    {{Obj['path_desc']}}
                                </div>


                            </div>


                            <br>
                            <!-- <div class="row" >

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK72']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB003']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK72']}}" [(ngModel)]="Obj['node_type']" [selectableGroup]="true"
                                        disabled [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br> -->
                            <div class="row">


                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Budget :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" (click)="selectbudgetbtn()">Select Budget</button>
                                </div>

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Budget Path:</h6>
                                </div>
                                <div class="col-3">
                                    {{budpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">


                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Product :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" (click)="selectproductbtn()">Select Product</button>
                                </div>

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Product Path:</h6>
                                </div>
                                <div class="col-3">
                                    {{prodpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Budget Head Code: -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK19']}} :
                                    </h6>
                                </div>
                                <div class="col-3">

                                    <input type="text" class="form-control" placeholder=" Budget Head Code" [(ngModel)]="Obj['budget_cd']" disabled>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Budget Amount : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK20']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder=" Budget Amount" [(ngModel)]="Obj['budget_value']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Work Details -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK21']}} :
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- WORK ORDER NUMBER : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK22']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Work Order Number" [(ngModel)]="Obj['work_order_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- NAME OF WORK : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK23']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['work_order_name']" placeholder="Enter Name Of Work" class="form-control"></textarea>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Work Cat: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK71']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB012']" bindLabel="desc" bindValue="value" [multiple]="false" placeholder="Select Work Category" [(ngModel)]="Obj['work_category']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="work_category">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Upload Work Order : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK24']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload($event,file.files)">
                                </div>
                                <!-- <div class="col-3">
                                    <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>Upload</button>
                                </div> -->

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Tender/ NIC Refernce Number: -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK25']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Tender/ NIC Refernce Number" [(ngModel)]="Obj['tender_ref_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Tender NIC ID: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK26']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Tender NIC ID" [(ngModel)]="Obj['tender_nic_id']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- TENDER QUOTATION SANCTIONED BY : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK27']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="userRoles" bindLabel="desc" bindValue="user_id" (change)="changesantion_user()" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['sanction_by']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_code">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Role :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="userrolearr" bindLabel="role_name" bindValue="role_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['sanction_by_role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Start Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK29']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['start_date']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- End Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK30']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['end_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!--  Work Order Value: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK31']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" placeholder="Enter Work Order Value" [(ngModel)]="Obj['work_order_value']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Work Order Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK11']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['work_order_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK28']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" placeholder="dd/mm/yyyy" [(ngModel)]="Obj['sanction_date']">
                                </div>
                                <div class="col-3 text-left"  >
                                    <h6 style="margin-left: 2%;">
                                        GST TYPE :
                                    </h6>
                                </div>
                                <div class="col-3">
                                <div class="form-check form-check-inline" style="margin-right: 24px;">
                                    <input class="form-check-input" type="radio"  [(ngModel)]="Obj['gst_type1']" name="gst_type" id="inlineRadio1" value="EXCLUDING" style="height: 20px;width: 20px;">
                                    <label class="form-check-label" for="inlineRadio1">Excluding</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio"  [(ngModel)]="Obj['gst_type1']" name="gst_type" id="inlineRadio2" value="INCLUSIVE" style="height: 20px;width: 20px;">
                                    <label class="form-check-label" for="inlineRadio2">Inclusive</label>
                                  </div>
                                  </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Vendor Details -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK33']}} :
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Vender : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK34']}} :
                                    </h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="rep_le_id" [multiple]="false" placeholder="Select Vender" [(ngModel)]="Obj['party_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" (click)="setPartyDetails()"
                                        name="project_cd">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- EMAIL ID : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK35']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_email']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- MOBILE NO. : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK36']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_phone_no']">


                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- GSTIN : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK38']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_gstin_no']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Number : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK39']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" [(ngModel)]="Obj['agr_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK40']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['agr_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Upload Agreement : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK41']}} :
                                    </h6>

                                </div>
                                <div class="col-3">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader_arg" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload_arg($event,file.files)">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Amount : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK42']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" [(ngModel)]="Obj['agr_amt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">
                                        <!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK43']}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="u_work_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Work List</button>
                            </div>
                            <div class="row">
                                <div class="col-6 d-flex align-items-center justify-content-end text-right">
                                    <h6>
                                     Select Work/EMB Mode:
                                    </h6>
                                </div>
                                <div class="col-3 text-center">
                                    <h6 >
                                        <ng-select [items]="embMode" bindLabel="mode" bindValue="value"
                                        [multiple]="false" placeholder="" [(ngModel)]="Obj['emb_mode']"
                                        [selectableGroup]="true"  [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="emb_mode" disabled>
                                    </ng-select>
                                    </h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Project-Budget Info -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK16']}}
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <!-- <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Select Project :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="AllProjectList" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select ........" [(ngModel)]="Obj['project_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="project_cd">
                                    </ng-select>
                                </div> -->
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK17']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-primary" disabled (click)="selectProjectPopUp()">Selected Project</button>
                                </div>

                                <br>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Node : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK73']}} :

                                    </h6>
                                </div>
                                <div class="col-3 text-left">
                                    {{Obj['path_desc']}}
                                </div>


                            </div>


                            <br>
                            <!-- <div class="row">

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK72']}} :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB003']" bindLabel="value" bindValue="value" [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK72']}}" [(ngModel)]="Obj['node_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0" disabled>
                                    </ng-select>

                                </div>
                                <div class="col-3 text-left">

                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br> -->

                            <div class="row">


                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Budget :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button *ngIf="!chekforbud" class="btn btn-primary" (click)="selectbudgetbtnupdate()">Select Budget</button>
                                    <button *ngIf="chekforbud" class="btn btn-primary" (click)="selectbudgetbtn()">Select Budget1</button>

                                </div>

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Budget Path:</h6>
                                </div>
                                <div class="col-3">
                                    {{budpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">


                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Product :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <button *ngIf="!chekforprod" class="btn btn-primary" (click)="selectproductbtnupdate()">Select Product</button>
                                    <button *ngIf="chekforprod" class="btn btn-primary" (click)="selectproductbtn()">Select Product1</button>
                                </div>

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">Product Path:</h6>
                                </div>
                                <div class="col-3">
                                    {{prodpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Budget Head Code: -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK19']}} :
                                    </h6>
                                </div>
                                <div class="col-3">

                                    <input type="text" class="form-control" placeholder=" Budget Head Code" [(ngModel)]="Obj['budget_cd']" disabled>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Budget Amount : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK20']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder=" Budget Amount" [(ngModel)]="Obj['budget_value']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Work Details -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK21']}} :
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- WORK ORDER NUMBER : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK22']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Work Order Number" [(ngModel)]="Obj['work_order_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- NAME OF WORK : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK23']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['work_order_name']" placeholder="Enter Name Of Work" class="form-control"></textarea>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Work Cat: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK71']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['EMB012']" bindLabel="desc" bindValue="value" [multiple]="false" placeholder="Select Work Category" [(ngModel)]="Obj['work_category']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="work_category">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Upload Work Order : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK24']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload($event,file.files)">
                                </div>
                                <!-- <div class="col-3">
                                    <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>Upload</button>
                                </div> -->

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Tender/ NIC Refernce Number: -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK25']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Tender/ NIC Refernce Number" [(ngModel)]="Obj['tender_ref_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Tender NIC ID: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK26']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" placeholder="Enter Tender NIC ID" [(ngModel)]="Obj['tender_nic_id']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- TENDER QUOTATION SANCTIONED BY : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK27']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="userRoles" bindLabel="desc" bindValue="user_id" (change)="changesantion_user()" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['sanction_by']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_code">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        Select Role :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="userrolearr" bindLabel="role_name" bindValue="role_cd" [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['sanction_by_role_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_code">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Start Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK29']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['start_date']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- End Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK30']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['end_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!--  Work Order Value: -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK31']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" placeholder="Enter Work Order Value" [(ngModel)]="Obj['work_order_value']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Work Order Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK11']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['work_order_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK28']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" placeholder="dd/mm/yyyy" [(ngModel)]="Obj['sanction_date']">
                                </div>
                                <div class="col-3 text-left"  >
                                    <h6 style="margin-left: 2%;">
                                        GST TYPE :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <div class="form-check form-check-inline" style="margin-right: 24px;">
                                        <input class="form-check-input" type="radio"  [(ngModel)]="Obj['gst_type1']" name="gst_type1" id="inlineRadio1" value="EXCLUDING" style="height: 20px;width: 20px;">
                                        <label class="form-check-label" for="inlineRadio1">Excluding</label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio"  [(ngModel)]="Obj['gst_type1']" name="gst_type1" id="inlineRadio2" value="INCLUSIVE" style="height: 20px;width: 20px;">
                                        <label class="form-check-label" for="inlineRadio2">Inclusive</label>
                                      </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                                <div class="col-2 text-center">
                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                        <!-- Vendor Details -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK33']}} :
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <hr class="hrline">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Select Vender : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK34']}} :
                                    </h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="all_party" bindLabel="party_leagal_name" bindValue="rep_le_id" [multiple]="false" placeholder="Select Vender" [(ngModel)]="Obj['party_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" (click)="setPartyDetails()"
                                        name="project_cd">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- EMAIL ID : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK35']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_email']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- MOBILE NO. : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK36']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_phone_no']">


                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- GSTIN : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK38']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" disabled [(ngModel)]="Obj['party_gstin_no']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Number : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK39']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" [(ngModel)]="Obj['agr_no']">
                                </div>
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Date : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK40']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="Obj['agr_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Upload Agreement : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK41']}} :
                                    </h6>

                                </div>
                                <div class="col-3">
                                    <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader_arg" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload_arg($event,file.files)">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-left">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Amount : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK42']}} :
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" [(ngModel)]="Obj['agr_amt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateprojhier()">
                                        <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK13']}}
                                    </button>
                                </div>
                            </div>



                        </div>
                        <div *ngIf="d_work_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Work List</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Work Order Document : -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK45']}}
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-success" (click)="view(uploadDoctypeObj['WORKORDER'])" style="margin-right: 10px;" *ngIf="uploadDoctypeObj['WORKORDER']!=null">
                                        <!-- VIEW -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK46']}}
                                    </button>
                                    <button class="btn btn-primary" (click)="openUpload('WORKORDER')" *ngIf="uploadDoctypeObj['WORKORDER']==null"><i class="fa fa-upload"></i>
                                        <!-- Upload Document -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}</button>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary" (click)="openupdateUpload('WORKORDER')" *ngIf="uploadDoctypeObj['WORKORDER']!=null"><i class="fa fa-upload"></i>
                                        <!-- Upload
                                        Document -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Agreement Document : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK47']}}
                                    </h6>
                                </div>
                                <div class="col-3 text-right">
                                    <button class="btn btn-success" (click)="view(uploadDoctypeObj['AGREEMENT'])" style="margin-right: 10px;" *ngIf="uploadDoctypeObj['AGREEMENT']!=null">
                                        <!-- VIEW -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK46']}}
                                    </button>
                                    <button class="btn btn-primary" (click)="openUpload('AGREEMENT')" *ngIf="uploadDoctypeObj['AGREEMENT']==null"><i class="fa fa-upload"></i>
                                        <!-- Upload
                                        Document -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}
                                    </button>
                                </div>
                                <div class="col-2">
                                    <button class="btn btn-primary" (click)="openupdateUpload('AGREEMENT')" *ngIf="uploadDoctypeObj['AGREEMENT']!=null"><i class="fa fa-upload"></i>
                                        <!-- Update
                                        Document -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}
                                    </button>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">About This</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>
                    Tender is the final bid selected after evaluation on UP Government eprocurement site @ etender.up.nic.in. After the evaluation is completed and a bid is finalized then the details of the selected bid are available for download from this site in two documents
                    - (a) BOQ in xls format and (b) Tender documents as pdf. Both of these must be downloaded and uploaded below to create the tender details.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="select">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Upload Document : -->{{mainService.allLableShowObj[mainService.language_cd+'EMBWORK14']}}
                </h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">

                    <div class="col-4 text-right">
                        Upload File:
                    </div>
                    <div class="col-4 text-center">
                        <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload($event,file.files)">
                    </div>

                </div>
                <br>
                <!-- <div class="row">

                    <div class="col-4 text-right">
                        Remark :
                    </div>
                    <div class="col-4 text-center">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="doc_remark" placeholder="enter work order name" class="form-control"></textarea>

                    </div>

                </div>
                <br> -->
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='upload()'><i class="fa fa-upload" aria-hidden="true">&nbsp;</i>Upload</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="select1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Update Upload Document :</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">

                    <div class="col-4 text-right">
                        Upload File:
                    </div>
                    <div class="col-4 text-center">
                        <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader" class="form-control" style="border-color: black" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="onFileUpload($event,file.files)">
                    </div>

                </div>
                <br>
                <!-- <div class="row">

                    <div class="col-4 text-right">
                        Remark :
                    </div>
                    <div class="col-4 text-center">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="doc_remark" placeholder="enter work order name" class="form-control"></textarea>

                    </div>

                </div>
                <br> -->
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-info" (click)='updateupload()'><i class="fa fa-upload"
                                aria-hidden="true">&nbsp;</i>Upload</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!--  -------------------------------------------------   CODE OF ACCOUNT INTEGRATION------------------------------------------                 -->

<div class="modal" id="select33">
    <!-- for crete modal for budget -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Budget Herirarchy1 :</h4>
                <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div>
                    <div>


                        <div class="row" *ngFor="let item of planing_arr,let i =index">
                            <div class="col-1"></div>
                            <div class="col-3" *ngIf="i==0">
                                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                <!-- Select Node : -->
                            </div>
                            <div class="col-3" *ngIf="i!=0"></div>
                            <div class="col-2">
                                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                <!-- Level -  -->
                            </div>
                            <div class="col-3">
                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                    (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                </ng-select>
                                <!-- Select  Node -->
                            </div>
                            <div class="col-3" *ngIf="i==planing_arr.length-1">
                                <button (click)="add_dropdown(item,i)" class="btn btn-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                <button (click)="remove_dropdown(item,i)" class="btn btn-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2 ">
                                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                    <!-- Current Node Path : -->
                                </h6>
                            </div>
                            <div class="col-9">
                                {{budpath}}
                            </div>
                        </div>
                        <br>



                        <br>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="submitbud()"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                                <!-- Submit -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="select44">
    <!-- for crete modal for prod -->
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Product Herirarchy :</h4>
                <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div>
                    <div>


                        <div class="row" *ngFor="let item of planing_arrprod,let i =index">
                            <div class="col-1"></div>
                            <div class="col-3" *ngIf="i==0">
                                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                <!-- Select Node : -->
                            </div>
                            <div class="col-3" *ngIf="i!=0"></div>
                            <div class="col-2">
                                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                <!-- Level -  -->
                            </div>
                            <div class="col-3">
                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                    (change)="setprodHierarchyNode(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                </ng-select>
                                <!-- Select  Node -->
                            </div>
                            <div class="col-3" *ngIf="i==planing_arrprod.length-1">
                                <button (click)="add_dropdown_prod(item,i)" class="btn btn-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                <button (click)="remove_dropdown_prod(item,i)" class="btn btn-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2 ">
                                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                    <!-- Current Node Path : -->
                                </h6>
                            </div>
                            <div class="col-9">
                                {{prodpath}}
                            </div>
                        </div>
                        <br>
                        <hr>


                        <br>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="submitprod()"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                                <!-- Submit -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="select3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Budget Herirarchy :</h4>
                <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div>
                    <div>


                        <div class="row" *ngFor="let item of planing_arr1,let i =index">
                            <div class="col-1"></div>
                            <div class="col-3" *ngIf="i==0">
                                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                <!-- Select Node : -->
                            </div>
                            <div class="col-3" *ngIf="i!=0"></div>
                            <div class="col-2">
                                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                <!-- Level -  -->
                            </div>
                            <div class="col-3">
                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                    (change)="setbudHierarchyNodeforupdate(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                </ng-select>
                                <!-- Select  Node -->
                            </div>
                            <div class="col-3" *ngIf="i==planing_arr1.length-1">
                                <button (click)="add_dropdownforupdate(item,i)" class="btn btn-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                <button (click)="remove_dropdownupdate(item,i)" class="btn btn-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2 ">
                                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                    <!-- Current Node Path : -->
                                </h6>
                            </div>
                            <div class="col-9">
                                {{budpath}}
                            </div>
                        </div>
                        <br>



                        <br>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="submitbud()"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                                <!-- Submit -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="select4">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Product Herirarchy :</h4>
                <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div>
                    <div>


                        <div class="row" *ngFor="let item of planing_arrprod,let i =index">
                            <div class="col-1"></div>
                            <div class="col-3" *ngIf="i==0">
                                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                <!-- Select Node : -->
                            </div>
                            <div class="col-3" *ngIf="i!=0"></div>
                            <div class="col-2">
                                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                <!-- Level -  -->
                            </div>
                            <div class="col-3">
                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                    (change)="setprodHierarchyNodeforupdate(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                </ng-select>
                                <!-- Select  Node -->
                            </div>
                            <div class="col-3" *ngIf="i==planing_arrprod.length-1">
                                <button (click)="add_dropdown_prod(item,i)" class="btn btn-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                <button (click)="remove_dropdown_prod(item,i)" class="btn btn-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-1"></div>
                            <div class="col-2 ">
                                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                    <!-- Current Node Path : -->
                                </h6>
                            </div>
                            <div class="col-9">
                                {{prodpath}}
                            </div>
                        </div>
                        <br>
                        <hr>


                        <br>
                        <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="submitprod()"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy26']}}</button>
                                <!-- Submit -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!--  END OF CODE OF ACCOUNT INTEGRATION-->
<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                        <!-- Select Node : -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                        <!-- Level -  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                            (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true" [closeOnSelect]="true">
                        </ng-select>
                        <!-- Select  Node -->
                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i
                                class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_act()">Submit</button>
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading..</ngx-spinner>