
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class VmsHierarchyService {

  httpUrl:any;


  constructor(private http: HttpClient, private main: MainService) {
    // this.httpUrl = this.main.httpUrl + "/property/dashboard";
  }
  async  getBudgetHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/budget/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  getproductHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/product/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  getActivityHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/activity/getHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  projectupdateHierarchy(obj:any) {
    const resp = this.http.put<any>(this.main.httpUrl + '/accounts/hierarchies/project/updateHierarchy',obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getDashBoardCount(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/getAllCount' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getprojectHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/getHierarchy', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getprojectcreateHierarchy(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/createHierarchy', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getprojectcreateHierarchyMultiple(obj:any) {
    const resp = this.http.post<any>(this.main.httpUrl + '/accounts/hierarchies/project/addMultipleHierarchy', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async getCost(obj:any) {
    const resp = await this.http.post<any>(this.main.httpUrl + '/accounts/costCenter/getCostCenter', obj).toPromise().then(res => {
      return res
    });
    console.log(resp)
    return resp
  }
  // async view_Doc(obj:any) {
  //   const resp = await this.http.post(this.main.httpUrl + '/property/location/getlocationImage', obj, { responseType: 'blob' }).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async createHierarchy(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/propHier/insertpropHierarchyNode', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async insertSyncWithAccountHierToProp(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/propHier/insertHierarchySyncWithProjectHier', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getHierarchyData(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getAllpropHierarchies' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getHierarchyWithLocationInfo(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getHierarchyWithLocationInfo' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getAllpropHierarchynodes(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getAllpropHierarchynodes' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async nextIdToInsert(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getNextAutoIncrementIdForHierarchy' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getHierarchynodes(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getpropHierarchynodes' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async deleteHierarchyNode(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/deletepropHierarchyNode', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async updateHierarchy(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/updatepropHierarchyNode', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }

  // async deleteHierarchy(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/deletepropHierarchy', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async deleteHierarchy(obj:any) {
  //   const resp = await this.http.delete<any>(this.main.httpUrl + '/property/propHier/updatepropHierarchyNode' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  //////////////////////////standered Hierarchy API
  // async getAllRegularHierarchies(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/RegularlHier/getAllRegularHierarchies' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  // async getRegularHierarchyNodes(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/RegularlHier/getRegularHierarchyNodes' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async insertRegularHierarchyNode(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/RegularlHier/insertRegularHierarchyNode', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async deleteRegularHierarchyNode(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/RegularlHier/deleteRegularHierarchyNode', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async updatepropHierarchyNode(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/RegularlHier/updatepropHierarchyNode', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async deleteRegularHierarchy(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/RegularlHier/deleteRegularHierarchy', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }

  // // letest changes
  // async createLocationHierarchy(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/propHier/addLocationHier', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getLocationHierarchy(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getAllLocationHier' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async update_Location_hier(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/updateLocationHeir', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }

  // async createLocationHierarchyNodes(obj:any) {
  //   const resp = await this.http.post<any>(this.main.httpUrl + '/property/propHier/createHierarchy', obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  // async getAllHierNodes(obj:any) {
  //   const resp = await this.http.get<any>(this.main.httpUrl + '/property/propHier/getHierarchy' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }


  // async update_Location_hier_nodes(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/updateHierarchy', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async deleteLocationHierarchyNodes(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/deleteHierarchy', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
  // async deleteLocationHierarchy(obj:any) {
  //   const resp = this.http.put<any>(this.main.httpUrl + '/property/propHier/deletepropHierarchy', obj).toPromise().then(res => {
  //     return res
  //   });
  //   return resp
  // }
}
