<!-- BEGIN PAGE LOADER  -->
<div class="preloader-backdrop">
    <div class="page-preloader">Loading</div>
</div>
<!-- END PAGE LOADER  -->

<!-- BEGIN PAGE  -->
<router-outlet></router-outlet>
<!-- END PAGE  -->

<!-- BEGIN THEME CONFIG PANEL-->
<!-- END THEME CONFIG PANEL-->

<!-- SCROLL TOP -->
<div class="to-top"><i class="fa fa-angle-double-up"></i></div>
<!-- END SCROLL TOP -->