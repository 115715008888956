import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../service/main.service';
import { AuthenticationService } from '../../../authentication.service';
import { ProfileService } from '../../service/profile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ComplaintWorkflowService } from '../../service/complaint-workflow.service';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: '[app-portal-header]',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.css']
})
export class PortalHeaderComponent implements AfterViewInit {

  imgURL:any;
  erpUser:any;
  userInfo:any = { first_name: 'UNKNOWN', designation: 'Accountant' }
  constructor(public auth: AuthenticationService, private complaintworkflowService: ComplaintWorkflowService, private router: Router, private profileService: ProfileService, private sanitizer: DomSanitizer, public mainService: MainService) { }
  hidden = true;
  accInfo = {}
  b_acct_id:any;
  totalForward:any;
  action = [
    { id: 1, name: 'Profile', route: "profile" },
    { id: 2, name: 'Account', route: "acc" },
    { id: 3, name: 'Logout', route: "login" },

  ];
  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;
  selectedAction: any;
  placeholderText: any;
  isDropdownOpen :any = false;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var dd = [];
    dd = this.erpUser.role_cd
    if (dd.includes("AA")) {
      this.hidden = false;
    } else {
      this.hidden = true
    }

    await this.mainService.getUserImage(this.erpUser['user_id']);
    await this.mainService.getAccImage(this.erpUser['b_acct_id']);
    await this.mainService.getAccountInfo(this.erpUser['b_acct_id']);
    await this.changeLanguage();
    await this.getCount();
    await this.mainService.getMessageCount(this.erpUser['b_acct_id'], this.erpUser['user_id'])
    this.placeholderText = this.erpUser['party_name']
  }

  onSelectionChange2(){
    const selectedVal = this.selectedAction.route
    this.router.navigate([selectedVal]);
  }

  toggleSelect(){
    if (this.isDropdownOpen) {
      this.ngSelectElement.close();
    } else {
      this.ngSelectElement.open();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  async getCount(){
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['user_id_to'] = this.erpUser.user_id;
    var resp = await this.complaintworkflowService.getCount(JSON.stringify(obj));
    if (resp['error'] == false) {

     this.totalForward = resp.data[0]['counter'];

    } else {

    }
  }
changeLanguage() {
    console.log("laungage Change");
    this.mainService.language_cd = this.auth.language_cd
    this.mainService.allLanguage = this.auth.allLanguage

  }

  home() {
    this.router.navigate(['/index'])
  }
  ngAfterViewInit() {
  }



























  // async getUserImage() {
  //   const res = await this.profileService.getImage(this.user_id);
  //   console.log(res)

  //   if (res) {
  //     const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
  //     this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  //     this.mainService.profileImageUrl = this.imgURL;
  //   }
  // }


  // async getUserInfo(){
  //   var resp= await this.profileService.getUserProfileInfo(this.user_id);
  //   console.log(resp);
  //   if(resp['error']==false){
  //     this.userInfo=resp.data[0];
  //     var erp=this.erpUser;
  //     erp['User_name']=this.userInfo['first_name']+" "+this.userInfo['last_name'];
  //     localStorage.setItem('erpUser',btoa(JSON.stringify(erp)));

  //     // console.log(this.userInfo)
  //   }else{

  //   }
  // }

  // async getAccImage() {
  //   const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
  //   if (res) {
  //     const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
  //     this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  //     this.mainService.accountImageUrl = this.imgURL;

  //   }
  // }
  // async getAccInfo() {
  //   var resp = await this.profileService.getAccProfileInfo(this.erpUser.b_acct_id);
  //   console.log(resp);
  //   if (resp['error'] == false) {
  //     this.accInfo = resp.data[0];
  //     this.mainService.accInfo = this.accInfo;
  //   } else {

  //   }

  // }

}


