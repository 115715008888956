import { Component, OnInit } from "@angular/core";
import { PropertyLedgerService } from "../../service/property-ledger.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { MainService } from "./../../service/main.service";
import { MainService as AdminMainService } from "../../../admin/service/main.service";
import { MainService as mainserviceEmb } from "../../../emb/service/main.service";
import { AuthenticationService } from "../../../authentication.service";
import { MatTableDataSource } from "@angular/material/table";
import { SaleService } from "../../service/sale.service";
import { MainService as portal_mainService } from '../../../portal/service/main.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: "app-general-ledger",
  templateUrl: "./general-ledger.component.html",
  styleUrls: ["./general-ledger.component.css"],
})
export class GeneralLedgerComponent implements OnInit {
  details: any;
  details1: any;
  propDetails: any;
  properyList : any= [];
  Obj: any = {};
  propDetails_arr: any;
  ebillUser: any;
  b_acct_id: any;
  objForLed: any = {};

  zero = new Array(19);
  erpUser: any;
  // properyList= [{property_id:1,property_value:210}, {property_id:2,property_value:'pro1'}, {property_id:23,property_value:220}]

  constructor(
    private propertyLedgerService: PropertyLedgerService,
    public auth: AuthenticationService,
    public mainService: MainService,
    private adminMainService: AdminMainService,
    public mainserviceEmb: mainserviceEmb,
    private spinner: NgxSpinnerService,
    private sale_service: SaleService,
    public portal_main: portal_mainService
  ) { }

  async ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // await this.allPropertyDetails();
    // this.Obj["transId"] = this.propDetails_arr[0].transId;

    await this.getAllOffers();
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);

  }

  // select offer
  offer_code_to_value: any = {}
  allOffer: any = []
  async getAllOffers() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'

    console.log(obj)
    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy = []
      for (let i = 0; i < this.allOffer.length; i++) {
        this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
        this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
        this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
        this.allOffer[i]['desc'] = this.allOffer[i]['offer_cd'] + ' - ' + this.allOffer[i]['offer_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }



  reportData: any;
  displayedColumns: string[] = [
    "alloted_property_id",
    //"assigned_property_number",
    "offer_cd",
    "property_type",
    "assigned_property_number",
    "party_phone_no",
    "party_name",
    "party_father_or_husband_name",
    'action'
  ];
  dataSource: any;
  installmentRecord: any;
  paidInstallmentRecord: any;

  async getLedgerbyProOrAllot() {
    this.objForLed["b_acct_id"] = this.ebillUser.b_acct_id;
    console.log(!this.field1, !this.field2, !this.field3)

    console.log(this.objForLed);
    this.spinner.show()
    let res;
    if (!this.field1 || !this.field2) {
      res = await this.propertyLedgerService.getLedgerData(
        JSON.stringify(this.objForLed)
      );
    } else if (!this.field3) {
      this.objForLed[this.option_code] = this.optionvalue
      console.log(this.objForLed)
      res = await this.propertyLedgerService.getLedgerDataAccOffer(
        JSON.stringify(this.objForLed)
      )
    }
    console.log(res);
    if (!res["error"]) {
      this.spinner.hide()
      this.reportData = res["data"][0];
      this.installmentRecord = res['data'][1].length > 0 ? res['data'][1] : [];
      this.paidInstallmentRecord = res['data'][2].length > 0 ? res['data'][2] : [];

      if (this.reportData.length == 0) {
        Swal.fire("Data not found", "Invalid Entry", "warning")
      }
      this.dataSource = new MatTableDataSource(this.reportData)
    } else {
      this.spinner.hide()
      Swal.fire('Server Error', "Something is wrong", 'error')
    }

  }
  shwodata = false

  userDetailRecord: any;
  async show(index: any) {
    this.userDetailRecord = this.reportData[index]  // be carefull this may create problem
    console.log(this.userDetailRecord)
    this.dueAndEndDate = []   //1
    this.installmentAmount = []  //3
    this.amountDue = []  //4 5 6
    this.paymentDate = [] //7
    this.amountPaid = []  // 8  9  10 11 12
    this.balanceDue = []           //13  14  15  16
    this.advancePaid = []  //17
    this.balanceValueNotDue = []  // 18  19
    this.numberOfLedgerRow;
    this.intallmentNo = []
    //-----------start for financial year wise closing
    this.year_wise_dueAndEndDate = []   //1
    this.year_wise_installmentAmount = []  //3
    this.year_wise_amountDue = []  //4 5 6
    // year_wise_paymentDate:any[] =[] //7  no need of this line
    this.year_wise_amountPaid = []  // 8  9  10 11 12
    this.year_wise_balanceDue = []           //13  14  15  16
    this.year_wise_advancePaid = []  //17
    this.year_wise_balanceValueNotDue = []  // 18  19
    //-----------end for financial year wise closeing
    // ----------start comulative year
    this.comulative_year_dueAndEndDate = []   //1
    this.comulative_year_installmentAmount = []  //3
    this.comulative_year_amountDue = []  //4 5 6
    // comulative_year_paymentDate:any[] =[] //7  no need of this line
    this.comulative_year_amountPaid = []  // 8  9  10 11 12
    this.comulative_year_balanceDue = []           //13  14  15  16
    this.comulative_year_advancePaid = []  //17
    this.comulative_year_balanceValueNotDue = []  // 18  19
    // ----------end comulative year

    this.displayListIndex = []

    this.createLedgerForInstallment()
    this.shwodata = true;
    // alert(element)
  }
  async show1(index: any) {
    this.userDetailRecord = this.reportData[index]  // be carefull this may create problem
    console.log(this.userDetailRecord)
    this.dueAndEndDate = []   //1
    this.installmentAmount = []  //3
    this.amountDue = []  //4 5 6
    this.paymentDate = [] //7
    this.amountPaid = []  // 8  9  10 11 12
    this.balanceDue = []           //13  14  15  16
    this.advancePaid = []  //17
    this.balanceValueNotDue = []  // 18  19
    this.numberOfLedgerRow;
    this.intallmentNo = []
    //-----------start for financial year wise closing
    this.year_wise_dueAndEndDate = []   //1
    this.year_wise_installmentAmount = []  //3
    this.year_wise_amountDue = []  //4 5 6
    // year_wise_paymentDate:any[] =[] //7  no need of this line
    this.year_wise_amountPaid = []  // 8  9  10 11 12
    this.year_wise_balanceDue = []           //13  14  15  16
    this.year_wise_advancePaid = []  //17
    this.year_wise_balanceValueNotDue = []  // 18  19
    //-----------end for financial year wise closeing
    // ----------start comulative year
    this.comulative_year_dueAndEndDate = []   //1
    this.comulative_year_installmentAmount = []  //3
    this.comulative_year_amountDue = []  //4 5 6
    // comulative_year_paymentDate:any[] =[] //7  no need of this line
    this.comulative_year_amountPaid = []  // 8  9  10 11 12
    this.comulative_year_balanceDue = []           //13  14  15  16
    this.comulative_year_advancePaid = []  //17
    this.comulative_year_balanceValueNotDue = []  // 18  19
    // ----------end comulative year

    this.displayListIndex = []

    this.createLedgerForInstallment()
    // alert(element)
  }


  allProperty() {
    //  console.log('working', this.Obj);
    this.propertyLedgerService.getFetchlist().subscribe((d) => {
      this.propDetails = d["Vacant Property"];
      // console.log(this.propDetails)
    });
  }

  // async allPropertyDetails() {
  //   var obj = {};
  //   obj["b_acct_id"] = this.b_acct_id;
  //   this.propDetails_arr = [];
  //   var resp = await this.propertyLedgerService.getPropertyDetais(
  //     JSON.stringify(obj)
  //   );
  //   this.propDetails_arr = resp["data"];
  // }

  async getPaymentDetails() {
    this.spinner.show();
    let obj: any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["transId"] = this.Obj["transId"];

    var resp = await this.propertyLedgerService.getPaymentDetails(
      JSON.stringify(obj)
    );

    if (resp["error"] == false) {
      this.details1 = resp["data"];
      console.log(this.details1, "details");
      this.details = this.details1[0];
    }
    this.spinner.hide();
  }

  getBase64FromUrl = async (url: any) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  goToList() {
    this.shwodata = false
    this.dueAndEndDate = []   //1
    this.installmentAmount = []  //3
    this.amountDue = []  //4 5 6
    this.paymentDate = [] //7
    this.amountPaid = []  // 8  9  10 11 12
    this.balanceDue = []           //13  14  15  16
    this.advancePaid = []  //17
    this.balanceValueNotDue = []  // 18  19
    this.numberOfLedgerRow = undefined;
    this.intallmentNo = []
    //-----------start for financial year wise closing
    this.year_wise_dueAndEndDate = []   //1
    this.year_wise_installmentAmount = []  //3
    this.year_wise_amountDue = []  //4 5 6
    // year_wise_paymentDate:any[] =[] //7  no need of this line
    this.year_wise_amountPaid = []  // 8  9  10 11 12
    this.year_wise_balanceDue = []           //13  14  15  16
    this.year_wise_advancePaid = []  //17
    this.year_wise_balanceValueNotDue = []  // 18  19
    //-----------end for financial year wise closeing
    // ----------start comulative year
    this.comulative_year_dueAndEndDate = []   //1
    this.comulative_year_installmentAmount = []  //3
    this.comulative_year_amountDue = []  //4 5 6
    // comulative_year_paymentDate:any[] =[] //7  no need of this line
    this.comulative_year_amountPaid = []  // 8  9  10 11 12
    this.comulative_year_balanceDue = []           //13  14  15  16
    this.comulative_year_advancePaid = []  //17
    this.comulative_year_balanceValueNotDue = []  // 18  19
    this.pri_num_pay = -1;
  }
  //  field related work
  field1 = false
  field2 = false
  field3 = false
  field3_relatedField_1 = false
  field3_relatedField_2 = false

  diableOtherField(con: any) {
    if (con == 1) {
      this.field2 = true
      this.field3 = true
    }
    if (con == 2) {
      this.field1 = true
      this.field3 = true
    }
    if (con == 3) {
      this.field1 = true
      this.field2 = true
      this.field3_relatedField_1 = true
    }
  }
  reset() {
    this.field1 = false
    this.field2 = false
    this.field3 = false
    this.field3_relatedField_1 = false
    this.field3_relatedField_2 = false
    this.objForLed['allotment_no'] = ""
    this.objForLed['alloted_property_id'] = ""
    this.objForLed['offer_cd'] = undefined
    this.objForLed['option'] = undefined
    this.optionvalue = undefined
    this.reportData = undefined
  }
  options = [
    { 'option': 'Mobile Number', code: "party_phone_no" },
    { 'option': 'Property Number', code: "assigned_property_number" },
    { 'option': 'Applicant Name', code: "party_name" },
  ]
  optionvalue: any
  option_name: any = ""
  option_code: any
  setOptionValue(item: any) {
    this.option_name = item.option
    this.option_code = item.code
  }
  showOptionEntry() {
    this.field3_relatedField_2 = true
  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  //  field related work

  // all ledger related work is here
  showLedger = false
  dueAndEndDate: { start: Date, end: Date }[] = []   //1
  installmentAmount: any = []  //3
  amountDue: { principal: number, intrest: number, additionalInterest: number }[] = []  //4 5 6
  paymentDate: any[][] = [] //7
  amountPaid: { installment: number[], tax: any, principal: number, intrest: number, additionalInterest: number }[] = []  // 8  9  10 11 12
  balanceDue: { principal: number, interest: number, total: number, additionalInterest: number }[] = []           //13  14  15  16
  advancePaid: any = []  //17
  balanceValueNotDue: { principal: number, interest: number }[] = []  // 18  19
  numberOfLedgerRow: any;
  intallmentNo: any = []
  //-----------start for financial year wise closing
  year_wise_dueAndEndDate: { start: Date, end: Date }[] = []   //1
  year_wise_installmentAmount: any = []  //3
  year_wise_amountDue: { principal: number, intrest: number, additionalInterest: number }[] = []  //4 5 6
  // year_wise_paymentDate:any[] =[] //7  no need of this line
  year_wise_amountPaid: { installment: number, tax: any, principal: number, intrest: number, additionalInterest: number }[] = []  // 8  9  10 11 12
  year_wise_balanceDue: { principal: number, interest: number, total: number, additionalInterest: number }[] = []           //13  14  15  16
  year_wise_advancePaid: any = []  //17
  year_wise_balanceValueNotDue: { principal: number, interest: number }[] = []  // 18  19
  //-----------end for financial year wise closeing
  // ----------start comulative year
  comulative_year_dueAndEndDate: { start: Date, end: Date }[] = []   //1
  comulative_year_installmentAmount : any= []  //3
  comulative_year_amountDue: { principal: number, intrest: number, additionalInterest: number }[] = []  //4 5 6
  // comulative_year_paymentDate:any[] =[] //7  no need of this line
  comulative_year_amountPaid: { installment: number, tax: any, principal: number, intrest: number, additionalInterest: number }[] = []  // 8  9  10 11 12
  comulative_year_balanceDue: { principal: number, interest: number, total: number, additionalInterest: number }[] = []           //13  14  15  16
  comulative_year_advancePaid: any = []  //17
  comulative_year_balanceValueNotDue: { principal: number, interest: number }[] = []  // 18  19
  // ----------end comulative year

  displayListIndex: any[] = []

  pri_num_pay = -1;

  sum_of_15_16: any;
  sum_of_15_16_18: any;
  createLedgerForInstallment() {
    //
    let cur_fin_year: any;
    let fin_end_date: any;

    this.showLedger = true
    console.log(this.installmentRecord)
    console.log(this.paidInstallmentRecord)
    let amount: any;
    let start_date;
    let end_date;
    this.numberOfLedgerRow = this.paidInstallmentRecord.length
    // for financial date
    let fin_year_index = 0
    this.year_wise_installmentAmount.push(0) //3
    this.year_wise_amountDue.push({ principal: 0, intrest: 0, additionalInterest: 0 }) //4 5 6
    this.year_wise_amountPaid.push({ installment: 0, tax: 0, principal: 0, intrest: 0, additionalInterest: 0 }) // 8 9 10 11  12
    this.year_wise_balanceDue.push({ principal: 0, interest: 0, total: 0, additionalInterest: 0 })  // 13 14 15 16
    this.year_wise_advancePaid.push(0)  // 17
    this.year_wise_balanceValueNotDue.push({ principal: 0, interest: 0 })
    let challan_date;
    let index = 0
    // privious balance due
    let pri_bal_due_i = 0
    let pri_bal_due_p = 0

    let am_paid_p = 0;
    let am_paid_i = 0;
    let am_paid_a_i = 0;

    let bal_due_i = 0
    let bal_due_p = 0;
    let bal_due_a_i = 0;
    let advance_amount = 0;
    let additional_intrest
    this.paidInstallmentRecord.filter((ele: any) => ele.party_id == this.userDetailRecord.party_id).forEach((element: any) => {
      //emi loop start from here
      if (element.no_of_pay == 0) {   // no of pay Ager 0 hoga to lumsum ka challan h
        // to find challan is for first or second lumsum using date
        //  console.log(this.toCheckWhichLumsum(element.challan_date),element.challan_date)
        if (this.toCheckWhichLumsum(element.challan_date) == 1) {     //  1 hoga ager to fisrst lumsum ki baat ho rahi h
          start_date = new Date(this.userDetailRecord.first_lumsum_start_date)
          end_date = new Date(this.userDetailRecord.first_lumsum_end_date)
          amount = this.userDetailRecord.first_lumsum
        }
        else {
          start_date = new Date(this.userDetailRecord.second_lumsum_start_date)
          end_date = new Date(this.userDetailRecord.second_lumsum_end_date)
          amount = this.userDetailRecord.second_lumsum
        }
        console.log(amount, this.userDetailRecord.first_lumsum)
        this.dueAndEndDate.push({ start: this.userDetailRecord.first_lumsum_start_date, end: this.userDetailRecord.first_lumsum_end_date }) //column 1
        this.intallmentNo.push(element.no_of_pay)// column 2
        this.installmentAmount.push(amount)  // 3
        this.amountDue.push({ principal: amount, intrest: 0, additionalInterest: 0 })  //4  5  6
        // creating chalallan date
        challan_date = new Date(element.challan_date)
        this.paymentDate.push([challan_date]) //7
        // further amount it will pay may be partial challan means not full
        this.amountPaid.push({ installment: [amount], tax: null, intrest: 0, principal: amount, additionalInterest: 0 }) // 8   9  10  11 12
        let balanceDue = 0
        this.balanceDue.push({ principal: balanceDue, interest: 0, total: 0, additionalInterest: 0 })  //13 14  15 16
        this.advancePaid.push(0)  //17
        this.balanceValueNotDue.push({ principal: this.userDetailRecord.principal_amount, interest: this.userDetailRecord.interest_amt_not_yet_due })// 18

      }
      else {
        // samarjeet 21 march
        let lum_amount = 0
        /**
         * this code is for when first or second lumsum date is overlap with EMI date
         */

        if (this.toCheckWhichLumsum(element.challan_date) == 1) {     //  1 hoga ager to fisrst lumsum ki baat ho rahi h
          lum_amount = this.userDetailRecord.first_lumsum
        }
        else if (this.toCheckWhichLumsum(element.challan_date) == 2) {
          lum_amount = this.userDetailRecord.second_lumsum
        }
        /***
         * installmentRecord gives arrays of object of installment information when to pay emi
         */
        let emi_data = this.installmentRecord.filter((ele : any)=> ele.no_of_pay == element.no_of_pay)[0]
        console.log(emi_data)
        this.dueAndEndDate.push({ start: emi_data.emi_start_date, end: emi_data.emi_end_date }) //column 1
        let payment;
        if (this.pri_num_pay != element.no_of_pay) { // this is saying payment is paid in the new month not in same month same time
          this.intallmentNo.push(element.no_of_pay)// column  2
          this.installmentAmount.push(emi_data.emi_amount + lum_amount)  // 3
          this.amountDue.push({
            principal: emi_data.cur_principal_amt + lum_amount,
            intrest: emi_data.cur_interest_amt,
            additionalInterest: element.additional_inst_amt
          })  //4  5  6
          this.paymentDate.push([])
          //  for year wise
        }
        payment = element.paid_amount
        // creating chalallan date
        challan_date = new Date(element.challan_date)
        this.paymentDate[this.paymentDate.length - 1].push(challan_date) //7
        // further amount it will pay may be partial challan means not full
        // if(element.additional_inst_amt>element.dues_amount){
        // }
        // payment made by user

        additional_intrest = (element.no_of_pay != this.pri_num_pay) ? element.additional_inst_amt : bal_due_a_i
        /**
         * |^^^^^^^^^
         * |additional instrest
         */
        am_paid_a_i = 0;

        bal_due_a_i = 0;  //     +_a_i stands for additional amount

        console.log(payment)
        if (payment <= (additional_intrest)) {  // privious miss-emis additional intrest
          // when payment is done less then equal to addtionl interest
          am_paid_a_i = payment; //because payment is less then additional amount so whole payment is spend to pay additional interest
          am_paid_i = 0;         // whole payment is done to give additional amount //no amount will be paid to interest
          am_paid_p = 0;         // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^//no amount will be paid to principal
          console.log(pri_bal_due_p, pri_bal_due_i)
          bal_due_p = (element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_principal_amt + pri_bal_due_p + lum_amount) : pri_bal_due_p
          /***        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^    ^^^^^^^^^^
           *          ^true for new payment false for same || total balance princepal due of p                   ||only pri bal due principal
           *  */
          bal_due_i = element.no_of_pay != this.pri_num_pay ? emi_data.cur_interest_amt + pri_bal_due_i : pri_bal_due_i
          bal_due_a_i = additional_intrest - payment
        } else {
          // when payment is more then addtional amount
          am_paid_a_i = additional_intrest; // then wole addtional amount will be paid
          bal_due_a_i = 0                   // then there will be no balance due
          // working right
          let am_after_a_i = payment - additional_intrest; // remaining, amount after addtional intrest
          let intr_am_to_pay = ((element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_interest_amt + pri_bal_due_i) : pri_bal_due_i)
          if (am_after_a_i <= (intr_am_to_pay)) {//  time to pay intrest amount
            // payment after additional int, remain less then equal to intrest amount
            am_paid_i = am_after_a_i;   // then whole amount will go to am_paid_i
            am_paid_p = 0;              // there will be no balance
            bal_due_p = (element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_principal_amt + pri_bal_due_p + lum_amount) : pri_bal_due_p    //whole principal will be due
            bal_due_i = ((element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_interest_amt + pri_bal_due_i) : (pri_bal_due_i)) - am_after_a_i // if some intrest is remain then it will go to bal_due_i
          } else {
            // payment after additional int, remain more then intrest amount
            am_paid_i = (element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_interest_amt + pri_bal_due_i) : pri_bal_due_i;   //whole intrest amount will be paid
            bal_due_i = 0                            //
            // now addtional interest and interst are done
            let am_after_int = am_after_a_i - ((element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_interest_amt + pri_bal_due_i) : pri_bal_due_i);  //amount after addtional amount and intrest
            let bal_pri_to_pay = ((element.no_of_pay != this.pri_num_pay) ? (emi_data.cur_principal_amt + pri_bal_due_p + lum_amount) : pri_bal_due_p)
            if (am_after_int <= bal_pri_to_pay) {
              am_paid_p = am_after_int;
              bal_due_p = bal_pri_to_pay - am_after_int
              advance_amount = 0
            } else {
              am_paid_p = bal_pri_to_pay;
              let am_after_principal = am_after_int - am_paid_p
              bal_due_p = 0
              // think is it fine to find round value or not
              advance_amount = am_after_principal;
            }
          }
        }

        console.log(am_paid_a_i)
        if (element.no_of_pay != this.pri_num_pay) {
          //new amount paid will be created when row will be added when on new no of pay
          this.amountPaid.push({ installment: [element.paid_amount], tax: null, intrest: am_paid_i, principal: am_paid_p, additionalInterest: am_paid_a_i }) // 8   9  10  11 12

          this.balanceDue.push({ principal: bal_due_p, interest: bal_due_i, total: bal_due_i + bal_due_p, additionalInterest: bal_due_a_i })  //13 14  15 16
          // let ad = ((advance_amount-lum_amount)>0)?(advance_amount-lum_amount):0
          this.advancePaid.push(advance_amount)  //17 samarjeet

          this.balanceValueNotDue.push({
            principal: element.principal_amt_not_yet_due,
            interest: element.interest_amt_not_yet_due
          })// 18 //19


        } else {
          // same no of pay amount will be added to privious
          this.amountPaid[this.amountPaid.length - 1].additionalInterest += am_paid_a_i
          this.amountPaid[this.amountPaid.length - 1]['installment'].push(element.paid_amount)//9
          this.amountPaid[this.amountPaid.length - 1].intrest += am_paid_i                     //11
          this.amountPaid[this.amountPaid.length - 1].principal += am_paid_p//12
          console.log(this.balanceDue[this.balanceDue.length - 1])
          console.log(bal_due_i)
          console.log(bal_due_p)
          console.log(bal_due_a_i)
          this.balanceDue[this.balanceDue.length - 1].principal -= am_paid_p //13
          this.balanceDue[this.balanceDue.length - 1].interest -= am_paid_i  //14
          this.balanceDue[this.balanceDue.length - 1].total -= (am_paid_i + am_paid_p) //15
          this.balanceDue[this.balanceDue.length - 1].additionalInterest -= am_paid_a_i //16
        }
      }
      this.sum_of_15_16 = this.balanceDue[this.balanceDue.length - 1].total + this.balanceDue[this.balanceDue.length - 1].additionalInterest;
      this.sum_of_15_16_18 = this.sum_of_15_16 + this.balanceValueNotDue[this.balanceValueNotDue.length - 1].principal;

      if (element.no_of_pay != this.pri_num_pay) { //when not a same no of pay
        // this field will add only when new month come
        this.year_wise_installmentAmount[fin_year_index] += this.installmentAmount[this.installmentAmount.length - 1]   // year installment will take amout
        //
        this.year_wise_amountDue[fin_year_index].principal += this.amountDue[this.amountDue.length - 1].principal
        this.year_wise_amountDue[fin_year_index].intrest += this.amountDue[this.amountDue.length - 1].intrest
        this.year_wise_amountDue[fin_year_index].additionalInterest += this.amountDue[this.amountDue.length - 1].additionalInterest
      }
      //
      if (element.no_of_pay != this.pri_num_pay) {
        this.displayListIndex.push({ list: index, fin_list: undefined, cumulative: undefined })
        index += 1
      }

      if (!cur_fin_year) {
        // only will run first time because first time it is null
        // challan_date =
        cur_fin_year = challan_date.getFullYear()  // after first time it will hold value which is nothing but current year
      }

      let cur_year_31_march = new Date(cur_fin_year, 3, 0)  // taking current 31 march of the year
      if (challan_date < cur_year_31_march) {  // if challan is before the 31 march of the year
        fin_end_date = cur_year_31_march;  // fin end date will that 31 march
      } else {
        // else it will be next year 31 march
        fin_end_date = new Date(cur_fin_year + 1, 3, 0)
      }
      console.log(cur_fin_year, challan_date)
      if (challan_date > fin_end_date) {
        console.log(challan_date, fin_end_date)
        //this will be created only if new challan date come after 31 march year = current_year or (next_year?)
        this.year_wise_installmentAmount.push(0) //3
        this.year_wise_amountDue.push({ principal: 0, intrest: 0, additionalInterest: 0 }) //4 5 6
        this.year_wise_amountPaid.push({ installment: 0, tax: 0, principal: 0, intrest: 0, additionalInterest: 0 }) // 8 9 10 11  12
        this.year_wise_balanceDue.push({ principal: 0, interest: 0, total: 0, additionalInterest: 0 })  // 13 14 15 16
        this.year_wise_advancePaid.push(0)  // 17
        this.year_wise_balanceValueNotDue.push({ principal: 0, interest: 0 })

        this.displayListIndex.push({ list: undefined, fin_list: fin_year_index })
        this.displayListIndex.push({ list: undefined, fin_list: undefined, cumulative: fin_year_index })
        ++fin_year_index
        ++cur_fin_year
      }
      //
      this.year_wise_amountPaid[fin_year_index].installment += element.paid_amount   // all amount will come come to amount paid
      this.year_wise_amountPaid[fin_year_index].principal += this.amountPaid[this.amountPaid.length - 1].principal  // all amount will come come to amount paid
      this.year_wise_amountPaid[fin_year_index].intrest += this.amountPaid[this.amountPaid.length - 1].intrest   // all amount will come come to amount paid
      this.year_wise_amountPaid[fin_year_index].additionalInterest += this.amountPaid[this.amountPaid.length - 1].additionalInterest
      console.log(this.year_wise_amountPaid[fin_year_index].additionalInterest)
      //
      this.year_wise_balanceDue[fin_year_index].principal += this.balanceDue[this.balanceDue.length - 1].principal
      this.year_wise_balanceDue[fin_year_index].interest += this.balanceDue[this.balanceDue.length - 1].interest
      this.year_wise_balanceDue[fin_year_index].total += this.balanceDue[this.balanceDue.length - 1].total
      this.year_wise_balanceDue[fin_year_index].additionalInterest += this.balanceDue[this.balanceDue.length - 1].additionalInterest
      //
      this.year_wise_advancePaid[fin_year_index] = this.advancePaid[this.advancePaid.length - 1]
      //
      this.year_wise_balanceValueNotDue[fin_year_index].principal = this.balanceValueNotDue[this.balanceValueNotDue.length - 1].principal
      this.year_wise_balanceValueNotDue[fin_year_index].interest = this.balanceValueNotDue[this.balanceValueNotDue.length - 1].interest
      //  if(element.no_of_pay!=this.pri_num_pay){
      //    this.displayListIndex.push({list:index,fin_list:undefined,cumulative:undefined})
      //    index+=1
      //  }
      pri_bal_due_i = bal_due_i
      pri_bal_due_p = bal_due_p
      // emi loop end here
      this.pri_num_pay = element.no_of_pay   // pri_num_pay is record of last num of pay
    }
    )
    for (let i = 0; i <= fin_year_index; i++) {
      console.log(this.year_wise_amountDue[i], i)
    }
    for (let i = 0; i <= fin_year_index; i++) {
      this.comulative_year_installmentAmount.push(0) //3
      this.comulative_year_amountDue.push({ principal: 0, intrest: 0, additionalInterest: 0 }) //4 5 6
      this.comulative_year_amountPaid.push({ installment: 0, tax: 0, principal: 0, intrest: 0, additionalInterest: 0 }) // 8 9 10 11  12
      this.comulative_year_balanceDue.push({ principal: 0, interest: 0, total: 0, additionalInterest: 0 })  // 13 14 15 16
      this.comulative_year_advancePaid.push(0)  // 17
      this.comulative_year_balanceValueNotDue.push({ principal: 0, interest: 0 })
      for (let j = 0; j <= i; j++) {
        this.comulative_year_installmentAmount[i] += this.year_wise_installmentAmount[j]
        //
        this.comulative_year_amountDue[i].principal += this.year_wise_amountDue[j].principal
        this.comulative_year_amountDue[i].intrest += this.year_wise_amountDue[j].intrest
        this.comulative_year_amountDue[i].additionalInterest += this.year_wise_amountDue[j].additionalInterest
        //
        this.comulative_year_amountPaid[i].installment += this.year_wise_amountPaid[j].installment
        this.comulative_year_amountPaid[i].principal += this.year_wise_amountPaid[j].principal
        this.comulative_year_amountPaid[i].intrest += this.year_wise_amountPaid[j].intrest
        this.comulative_year_amountPaid[i].additionalInterest += this.year_wise_amountPaid[j].additionalInterest
        //
        this.comulative_year_balanceDue[i].principal += this.year_wise_balanceDue[j].principal
        this.comulative_year_balanceDue[i].interest += this.year_wise_balanceDue[j].interest
        this.comulative_year_balanceDue[i].total += this.year_wise_balanceDue[j].total
        this.comulative_year_balanceDue[i].additionalInterest = this.year_wise_balanceDue[j].additionalInterest
        //
        this.comulative_year_advancePaid[i] = this.year_wise_advancePaid[j]
        //
        this.comulative_year_balanceValueNotDue[i].principal = this.year_wise_balanceValueNotDue[j].principal
        this.comulative_year_balanceValueNotDue[i].interest = this.year_wise_balanceValueNotDue[j].interest
      }
    }
    // this.printlist()
  }
  toCheckWhichLumsum(challan_date: any) {
    let challan_d = new Date(challan_date.substring(0, 10))
    if (this.userDetailRecord.first_lumsum) {
      let f_s = new Date(this.userDetailRecord.first_lumsum_start_date)
      let f_e = new Date(this.userDetailRecord.first_lumsum_end_date)
      if (f_s <= challan_d && f_e >= challan_d) {
        return 1
      }
    }
    else if (this.userDetailRecord.second_lumsum) {
      let s_s = new Date(this.userDetailRecord.second_lumsum_start_date)
      let s_e = new Date(this.userDetailRecord.second_lumsum_end_date)
      if (s_s <= challan_d && s_e >= challan_d) {
        return 2
      }
    }
    return 0
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  async printlist(element: any) {

    this.details1 = element;
    // this.propertyLedgerService.getFetchlist().subscribe(m => {
    console.log(this.details1, "uttam");
    // this.details1 = m;
    // })
    // var txt = this.accountObj['account_name'] + '( ' + this.accountObj['account_short_name'] + ' )'
    var text = "UP Housing and Development Council";
    var dd: any = {
      pageSize: "A3",
      header: {
        margin: 8,
        columns: [
          {
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many columns should be treated as headers
              headercolumns: 1,
              widths: ["30%", "40%", "30%"],

              body: [
                [
                  {
                    image: await this.getBase64FromUrl("../../assets/avas.png"),
                    width: 100,
                    height: 60,
                    alignment: "center",
                  },

                  {

                    text: this.portal_main.accInfo['account_name'],
                    // '104 Mahatma Gandhi Road, Lucknow-226001(UP)' ,
                    // 'Property & Estate Management System',
                    alignment: "center",
                    bold: true,
                    margin: 6,
                    fontSize: 20,
                  },
                  // {
                  //   text: '104 Mahatma Gandhi Road, Lucknow-226001(UP)',

                  //   alignment: 'center',
                  //   bold: true,
                  //   margin: 6,
                  //   fontSize: 18
                  // },
                  // {
                  //   text: 'Property & Estate Management System',

                  //   alignment: 'center',
                  //   bold: true,
                  //   margin: 6,
                  //   fontSize: 10
                  // },
                  {
                    image: await this.getBase64FromUrl(
                      "../../assets/avas2.png"
                    ),
                    width: 100,
                    height: 60,
                    alignment: "center",
                  },
                ],
              ],
            },
            layout: "noBorders",
            height: "210",
          },
        ],
      },
      pageOrientation: "landscape",
      pageMargins: [40, 80, 40, 60],
      content: [],
    };
    var header0 = {
      columns: [
        {
          width: "*",
          text: "General Property Ledger -Lucknow: PMAY SECTOR 7A (  PMAY SECTOR 7A-PMAY SECTOR 7A  ) ",
          bold: true,
          alignment: "left",
        },
      ],
    };

    var header1 = {
      columns: [
        {
          width: "*",
          text: "1. Property No.",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["assigned_property_number"],
          //bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: "8. Name of Allottee",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["party_name"],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "15. Father / Husband Name",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["party_father_or_husband_name"],
          // bold: true,
          fontSize: 10,
        },
      ],
    };
    var header2 = {
      columns: [
        {
          width: "*",
          text: "2. Full Address",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["party_permanent_addr_line"],
          //bold: true,
          fontSize: 8,
        },
        {
          width: "*",
          text: "9. Allotment Letter No./Date",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.details1["allotment_date"],
          // text: ":  " + this.details1["allotmentDate"].split('T')[0],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "16. Registration No./Form No.",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.details1["registration_no"],
          // bold: true,
          fontSize: 10,
        },
      ],
    };
    var header3 = {
      columns: [
        {
          width: "*",
          text: "3. Property Cost (Rs)",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["pro_rate"],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "10. Registration Amount (Rs)",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["application_amount"],
          //bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "17. Amount After Registration (Rs)",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["principal_amount"],
          // bold: true,
          fontSize: 10,
        },
      ],
    };
    var header4 = {
      columns: [
        {
          width: "*",
          text: "4. Mode of Payment",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["payment_option"],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "11. Total No. of Installments",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.details1["no_of_payment"],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "18. Date of FirstInstallment",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.details1["first_lumsum_start_date"],
          // text: ":  " + this.details1["firstInstallmentDate"].split('T')[0],
          // bold: true,
          fontSize: 10,
        },
      ],
    };
    var header5 = {
      columns: [
        {
          width: "*",
          text: "5. Pre Installment Amount(Rs)",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["application_amount"],
          //bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "12. Pre Installment Start Date",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.userDetailRecord["first_lumsum_start_date"],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "19. Pre Installment End Date",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.userDetailRecord["first_lumsum_end_date"],
          //bold: true,
          fontSize: 10,
        },
      ],
    };
    var amt: any
    if (this.userDetailRecord['first_lumsum'] && !this.userDetailRecord['second_lumsum']) {
      amt = this.userDetailRecord["pro_rate"] - this.userDetailRecord["application_amount"] - this.userDetailRecord["first_lumsum"]
    }
    if (this.userDetailRecord['second_lumsum'] && !this.userDetailRecord['first_lumsum']) {
      amt = this.userDetailRecord["pro_rate"] - this.userDetailRecord["application_amount"] - this.userDetailRecord["second"]
    }
    if (!this.userDetailRecord['first_lumsum'] && !this.userDetailRecord['second_lumsum']) {
      amt = this.userDetailRecord["pro_rate"] - this.userDetailRecord["application_amount"]
    }
    if (this.userDetailRecord['first_lumsum'] && this.userDetailRecord['second_lumsum']) {
      amt = this.userDetailRecord["pro_rate"] - this.userDetailRecord["application_amount"] - this.userDetailRecord["first_lumsum"] - this.userDetailRecord['second_lumsum']
    }

    var header6 = {
      columns: [
        {
          width: "*",
          text: "6. Amount on which Installment have been fixed(Rs) ",
          bold: true,
          fontSize: 10,
        },



        {
          width: "*",
          text: ":  " + amt,
          fontSize: 10,
        },
        {
          width: "*",
          text: "13. Installment Amount (Rs)",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.userDetailRecord["first_lumsum"],
          fontSize: 10,
        },
        {
          width: "*",
          text: "20. Frequency of Installment",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.details1["frequency_of_installment"],
          fontSize: 10,
        },
      ],
    };

    var header7 = {
      columns: [
        {
          width: "*",
          text: "7. Rate of Interest included in Installment",
          bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: ":  " + this.details1["interest_rate"] + " %",
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "14. Total Interest to becharged (in case of Default)",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  " + this.userDetailRecord['panel_interest'],
          // bold: true,
          fontSize: 10,
        },
        {
          width: "*",
          text: "21. Additional Interest Calculated From Date",
          bold: true,
          fontSize: 10,
        },

        {
          width: "*",
          text: ":  NA",
          // bold: true,
          fontSize: 10,
        },
      ],
    };

    var header8 = {
      columns: [
        {
          width: "*",
          text: " Bank Employee Signature",
          bold: true,
        },
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          text: "Date:",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1060, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1060, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    // dd.content.push(header6);
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push({ text: " " });
    dd.content.push(header4);
    dd.content.push({ text: " " });
    dd.content.push(header5);
    dd.content.push({ text: " " });
    dd.content.push(header6);
    dd.content.push({ text: " " });
    dd.content.push(header7);
    dd.content.push({ text: " " });
    // dd.content.push(header8);
    // dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1060, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });

    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {
        alignment: "center",
        headerRows: 4,
        // widths: [30, 250, 60, 60, 100, 40, 50, 50, 50, 70, 65, 60, 50, 50],
        widths: [
          60, 50, 50, 50, 50, 50, 60, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
          50, 50,
        ],
        body: [
          [
            {
              text: "Due & End Date",
              rowSpan: 3,
              alignment: "center",
              bold: true,
            },
            {
              text: "Installment No. ",
              rowSpan: 3,
              alignment: "center",
              bold: true,
            },
            {
              text: "Installment Amt.",
              rowSpan: 3,
              alignment: "center",
              bold: true,
            },
            { text: "Amount Due", bold: true, colSpan: 3, alignment: "center" },
            {},
            {},
            {
              text: "Amount Paid",
              colSpan: 6,
              alignment: "center",
              bold: true
            },
            {},
            {},
            {},
            {},
            {},
            {
              text: "Balance Due",
              colSpan: 4,
              alignment: "center",
              bold: true
            },
            {},
            {},
            {},
            {
              text: "Advance Paid",
              rowSpan: 3,
              alignment: "center",
              bold: true,
            },
            {
              text: "Balance Value Not Due",
              colSpan: 2,
              alignment: "center",
              bold: true
            },
            {},
          ],
          [
            { text: "", alignment: "left", bold: true },
            { text: "", alignment: "right", bold: true },
            { text: "", alignment: "right", bold: true },

            { text: "Principal", rowSpan: 2, bold: true },
            { text: "Interest", rowSpan: 2, bold: true },
            { text: "Additional Interest", rowSpan: 2, bold: true },
            // amount paid
            { text: "Date", rowSpan: 2, alignment: "center", bold: true },
            { text: "Total", colSpan: 2, alignment: "center", bold: true },
            //pri
            { text: "", rowSpan: 2, alignment: "center", bold: true },

            { text: "Principal", rowSpan: 2, alignment: "center", bold: true },
            { text: "Interest", rowSpan: 2, alignment: "center", bold: true },
            {
              text: "Additional Interest",
              rowSpan: 2,
              alignment: "center",
              bold: true,
            },
            { text: "Principal", rowSpan: 2, alignment: "center", bold: true },
            { text: "Interest", alignment: "center", rowSpan: 2, bold: true },
            { text: "Total", rowSpan: 2, alignment: "center", bold: true },


            {
              text: "Additional Interest",
              rowSpan: 2,
              alignment: "center",
              bold: true,
            },

            { text: "", alignment: "center", bold: true },
            { text: "Principal", rowSpan: 2, alignment: "center", bold: true },
            { text: "Interest", rowSpan: 2, alignment: "center", bold: true },
          ],
          [
            { text: "", alignment: "left", bold: true },
            { text: "", alignment: "right", bold: true },
            { text: "", alignment: "right", bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
            { text: "", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "Installment", alignment: "center", bold: true },
            { text: "Income Tax/ 26QB", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
            { text: "", alignment: "center", bold: true },
          ],
          [
            { text: "1", alignment: "center", bold: true },
            { text: "2", alignment: "center", bold: true },
            { text: "3", alignment: "center", bold: true },
            { text: "4", alignment: "center", bold: true },
            { text: "5", alignment: "center", bold: true },
            { text: "6", alignment: "center", bold: true },
            { text: "7", alignment: "center", bold: true },
            { text: "8", alignment: "center", bold: true },
            { text: "9", alignment: "center", bold: true },
            { text: "10", alignment: "center", bold: true },
            { text: "11", alignment: "center", bold: true },
            { text: "12", alignment: "center", bold: true },
            { text: "13", alignment: "center", bold: true },
            { text: "14", alignment: "center", bold: true },
            { text: "15", alignment: "center", bold: true },
            { text: "16", alignment: "center", bold: true },
            { text: "17", alignment: "center", bold: true },
            { text: "18", alignment: "center", bold: true },
            { text: "19", alignment: "center", bold: true },
          ],
        ],
      },
    };
    dd.content.push(tbl);
    // let data = []
    // let itt=0;

    // for (let i = 0; i < this.displayListIndex.length; i++) {

    //   // let obj = this.displayListIndex[i]

    //   // if (this.displayListIndex[i]['party_id']) {
    //   //   obj['party_id'] = this.displayListIndex[i]['party_id']
    //   // } else {
    //   //   obj['party_id'] = ''
    //   // }

    //   itt = itt+1;
    // }
    //  console.log(itt)

    for (var i = 0; i < this.displayListIndex.length; i++) {
      var arr = []

      if (this.displayListIndex[i]['list'] != undefined) {
        let start: any = new Date(this.dueAndEndDate[this.displayListIndex[i].list].start)
        start = String(start)
        start = start.split(" ").splice(1, 3).join(" ")
        let end: any = new Date(this.dueAndEndDate[this.displayListIndex[i].list].end)
        end = String(end)
        end = end.split(" ").splice(1, 3).join(" ")

        arr.push(start + "\n" + end)
        arr.push(this.intallmentNo[this.displayListIndex[i].list])
        arr.push(this.installmentAmount[this.displayListIndex[i].list])
        arr.push(this.amountDue[this.displayListIndex[i].list].principal)
        arr.push(this.amountDue[this.displayListIndex[i].list].intrest)

        arr.push((this.amountDue[this.displayListIndex[i].list].additionalInterest).toFixed(2))

        let str: string = " "
        for (let x = 0; x < this.paymentDate[this.displayListIndex[i].list].length; x++) {
          let s: any = this.paymentDate[this.displayListIndex[i].list][x]
          s = String(s)
          s = s.split(" ").splice(1, 3).join(" ")
          str = str + " " + s
        }
        arr.push(str)
        let str1: string = " "
        for (let x = 0; x < this.amountPaid[this.displayListIndex[i].list].installment.length; x++) {
          let s: any = this.amountPaid[this.displayListIndex[i].list]['installment'][x]
          s = s.toFixed(2)
          s = String(s)
          str1 = str1 + " " + s
        }


        arr.push(str1)
        arr.push(" ")
        arr.push((this.amountPaid[this.displayListIndex[i].list].principal).toFixed(2))
        arr.push(this.amountPaid[this.displayListIndex[i].list].intrest)
        arr.push(this.amountPaid[this.displayListIndex[i].list].additionalInterest)
        arr.push((this.balanceDue[this.displayListIndex[i].list].principal).toFixed(2))
        arr.push(this.balanceDue[this.displayListIndex[i].list].interest)
        arr.push((this.balanceDue[this.displayListIndex[i].list].total).toFixed(2))
        arr.push(this.balanceDue[this.displayListIndex[i].list].additionalInterest)
        arr.push(this.advancePaid[this.displayListIndex[i].list])
        arr.push(this.balanceValueNotDue[this.displayListIndex[i].list].principal)
        arr.push(this.balanceValueNotDue[this.displayListIndex[i].list].interest)
      } else if (this.displayListIndex[i]['fin_list'] != undefined) {
        // arr.push("Financial year wise closing");
        arr.push({ text: "Financial year wise closing", bold: true, colSpan: 2 });


        arr.push(" ")
        arr.push(this.year_wise_installmentAmount[this.displayListIndex[i].fin_list]);
        arr.push(this.year_wise_amountDue[this.displayListIndex[i].fin_list].principal);
        arr.push(this.year_wise_amountDue[this.displayListIndex[i].fin_list].intrest);
        arr.push((this.year_wise_amountDue[this.displayListIndex[i].fin_list].additionalInterest).toFixed(2));
        arr.push(" ");
        arr.push(this.year_wise_amountPaid[this.displayListIndex[i].fin_list].installment);
        arr.push(" ");
        arr.push(this.year_wise_amountPaid[this.displayListIndex[i].fin_list].principal);
        arr.push(this.year_wise_amountPaid[this.displayListIndex[i].fin_list].intrest);
        arr.push(this.year_wise_amountPaid[this.displayListIndex[i].fin_list].additionalInterest);
        arr.push(this.year_wise_balanceDue[this.displayListIndex[i].fin_list].principal);
        arr.push(this.year_wise_balanceDue[this.displayListIndex[i].fin_list].interest);
        arr.push(this.year_wise_balanceDue[this.displayListIndex[i].fin_list].total);
        arr.push(this.year_wise_balanceDue[this.displayListIndex[i].fin_list].additionalInterest);
        arr.push(this.year_wise_advancePaid[this.displayListIndex[i].fin_list]);
        arr.push(this.year_wise_balanceValueNotDue[this.displayListIndex[i].fin_list].principal);
        arr.push(this.year_wise_balanceValueNotDue[this.displayListIndex[i].fin_list].interest);


      } else if (this.displayListIndex[i]['cumulative'] != undefined) {
        arr.push({ text: "Cumulative Financial year wise closing ", bold: true, colSpan: 2 });


        arr.push(" ")
        arr.push(this.comulative_year_installmentAmount[this.displayListIndex[i].cumulative]);
        arr.push(this.comulative_year_amountDue[this.displayListIndex[i].cumulative].principal);
        arr.push(this.comulative_year_amountDue[this.displayListIndex[i].cumulative].intrest);
        arr.push((this.comulative_year_amountDue[this.displayListIndex[i].cumulative].additionalInterest).toFixed(2));
        arr.push("");
        arr.push(this.comulative_year_amountPaid[this.displayListIndex[i].cumulative].installment);
        arr.push("");
        arr.push(this.comulative_year_amountPaid[this.displayListIndex[i].cumulative].principal);
        arr.push(this.comulative_year_amountPaid[this.displayListIndex[i].cumulative].intrest);
        arr.push(this.comulative_year_amountPaid[this.displayListIndex[i].cumulative].additionalInterest);
        arr.push(this.comulative_year_balanceDue[this.displayListIndex[i].cumulative].principal);
        arr.push(this.comulative_year_balanceDue[this.displayListIndex[i].cumulative].interest);
        arr.push(this.comulative_year_balanceDue[this.displayListIndex[i].cumulative].total);
        arr.push(this.comulative_year_balanceDue[this.displayListIndex[i].cumulative].additionalInterest);
        arr.push(this.comulative_year_advancePaid[this.displayListIndex[i].cumulative]);
        arr.push(this.comulative_year_balanceValueNotDue[this.displayListIndex[i].cumulative].principal);
        arr.push(this.comulative_year_balanceValueNotDue[this.displayListIndex[i].cumulative].interest);


      }

      dd.content[dd.content.length - 1].table.body.push(arr);
    }



    var footer = {
      columns: [
        {
          width: "*",
          text: "Note:-",
          bold: true,
        },
      ],
    };
    var footer1 = {
      columns: [
        {
          width: "*",
          text:
            "*" +
            " Current defaulted amount Rs. " + this.sum_of_15_16.toFixed(2) + " (Sum of Column No. 15 & 16) ",
          // bold: true
          fontSize: 10,
        },
      ],
    };
    var footer2 = {
      columns: [
        {
          width: "*",
          text:
            "*" +
            " Final dues without rebate/discount Rs.  " + this.sum_of_15_16_18 + "    (Sum of Column No. 15, 16 & 18) .",
          // bold: true
          fontSize: 10,
        },
      ],
    };
    var footer3 = {
      columns: [
        {
          width: "*",
          text:
            "*" +
            " Final dues after rebate/discount Rs. " + this.balanceValueNotDue[this.balanceValueNotDue.length - 1].principal + " , ( 2% rebate/discount allowed for Rs. 4645.39 on Column No. 18) . ",
          // bold: true
          fontSize: 10,
        },
      ],
    };
    var footer4 = {
      columns: [
        {
          width: "*",
          text:
            "*" +
            " This ledger statement is only for the display purpose and information available in UPHDB will be treated as final. ",
          // bold: true
          fontSize: 10,
        },
      ],
    };
    var footer5 = {
      columns: [
        {
          width: "*",
          text:
            "*" +
            " In case of any discrepancy please contact concerning Estate Management Office(EMO). ",
          // bold: true
          fontSize: 10,
        },
      ],
    };

    dd.content.push(footer);
    dd.content.push({ text: " " });
    dd.content.push(footer1);
    dd.content.push({ text: " " });
    dd.content.push(footer2);
    dd.content.push({ text: " " });
    dd.content.push(footer3);
    dd.content.push({ text: " " });
    dd.content.push(footer4);
    dd.content.push({ text: " " });
    dd.content.push(footer5);
    dd.content.push({ text: " " });

    pdfMake.createPdf(dd).download("Ledger Report Download");
  }
}
