<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Offline Booklet </div>
                 </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="create_flag"  data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                Upload Booklet File</a> 
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="create_flag">
                         
                            <br>

                            <div class="card">
                                <div style="margin: 2%;">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Offer :<span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allOffer" bindLabel="offer_name"
                                                 bindValue="offer_cd" [multiple]="false"
                                                placeholder="Select.."
                                                [(ngModel)]="Obj['offer_cd']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="party_bank_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Gateway :<span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="admindata" bindLabel="gateway_name"
                                                (change)='changeAccount()' bindValue="gateway_id" [multiple]="false"
                                                placeholder="Select..."
                                                [(ngModel)]="Obj['gateway_id']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="party_bank_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Account : <span style="color: red;"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="filtered_gatWayList" bindLabel="desc"
                                                bindValue="account_no" [multiple]="false"
                                                placeholder="Select..."
                                                [(ngModel)]="Obj['account_no']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="party_bank_name">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Upload File
                                                <span style="color: red;"> *</span>
                                            </h6>
                                          
                                        </div>

                                        <div class="col-3">
                                            <input #file type="file" name="pimage" ng2FileSelect [uploader]="uploader2"
                                                class="form-control" style="border-color: black"
                                                (change)="onFileUpload2($event,file.files)"
                                                (change)="onFileChange($event)">
                                        </div>

                                        <div class="col-3">
                                            <h6>
                                                (<button class="btn btn-link" (click)="export()">Download CSV
                                                    Format</button>)

                                            </h6>
                                        </div>
                                        <div class="col-3">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="submit()" class="btn btn-outline-primary">Submit</button>
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'p_layout83']}}</ngx-spinner>
 