import { Component, OnInit,
  QueryList,
  ViewChild,
  ViewChildren, } from '@angular/core';

import { PropertyLedgerService } from "../../service/property-ledger.service";
import { DefaulterService } from "../../service/defaulter.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-defaulter-method',
  templateUrl: './defaulter-method.component.html',
  styleUrls: ['./defaulter-method.component.css']
})
export class DefaulterMethodComponent implements OnInit {


    // @ViewChild(MatSort, {static: true}) sort!: MatSort;
  // @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  //
  displayedColumns: string[] = [
    "defualt_method_id",
    "no_of_failed_inst_default",
    "no_of_notice_before_cancellation",
    "seize_period",
    "action",
  ];

  obj:any  = {};
  ebillUser:any ;
  b_acct_id:any ;
  inputarray:any ;
  nobj:any  = {};
  inputobj :any = [];
  flag: any = true;
  dataSource: any;

  constructor( private spinner: NgxSpinnerService,public propertyLedgerService: PropertyLedgerService,public DefaulterService: DefaulterService)
     { }

  ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.showdata();
    this.dataSource.sort = this.sort.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[0];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // applyFilter(filterValue: string) {

  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  testinput() {
    this.inputarray = new Array();
    for (let i = 0; i < this.obj["no_of_notice_before_cancellation"]; i++) {
      this.inputarray[i] = i;
    }
    console.log(this.inputarray);
  }
  reportDat:any ;
  async postdata() {



    var obj:any  = {};
    obj["b_acct_id"] = this.b_acct_id;
   obj["no_of_failed_inst_default"] = this.obj["no_of_failed_inst_default"];
  //  obj["no_of_failed_inst_default"] = 1 ;
    obj["no_of_notice_before_cancellation"] =
      this.obj["no_of_notice_before_cancellation"];
    obj["seize_period"] = this.obj["seize_period"];
    obj["life_cycle_status"] = "ACTIVE";
    obj["user_id"] = 1;
    obj['key'] =   obj['no_of_failed_inst_default'] +''+  obj['no_of_notice_before_cancellation'] +''+ obj['seize_period']


    //inserting data element

    for (let i = 0; i < this.obj["no_of_notice_before_cancellation"]; i++) {
      this.inputobj.push({
        no_of_notice: i + 1,
        notice_period: this.nobj[i],
      });
    }
    console.log(this.inputobj);
    obj["data"] = this.inputobj;
    console.log(obj);
    this.spinner.show()
    var resp :any = await this.DefaulterService.createDefaulter(obj);
    if(resp['error'] == false){
      this.spinner.hide()
      this.reset()
       this.showdata()
      Swal.fire('Success', 'Created Successfully', 'success')
      //this.alertWithSuccess()
      //console.log(resp);
      this.inputobj=[]
      this.nobj=[]

    }else {
      this.spinner.hide()
      Swal.fire('Error', 'Rule may already exists', 'error')
    }
  }

  async showdata() {
    let getobj:any  = {};
    getobj["b_acct_id"] = this.b_acct_id;
    console.log("get api obj", getobj);
    var res:any  = await this.DefaulterService.getDefaulter(getobj);
      this.reportDat = res["data"];
      console.log("test2", res["data"]);
    this.dataSource = new MatTableDataSource(this.reportDat);
    this.dataSource.sort = this.sort.toArray()[0];
    this.dataSource.paginator = this.paginator.toArray()[0];
  }
  //

  show() {
    this.flag = !this.flag;
  }
  // async delete(element:any) {
  //   console.log(element);
  //   var obj = {};
  //   obj["b_acct_id"] = this.b_acct_id;
  //   obj["defualt_method_id"] = element.defualt_method_id;

  //   console.log(obj);
  //   await this.DefaulterService.deleteDefaulter(obj);
  //   this.alertafterremove()
  //   this.showdata()
  // }
   reset(){
    this.obj["no_of_failed_inst_default"] = null;
    this.obj["no_of_notice_before_cancellation"]= null;
    this.obj["seize_period"]= null;
    this.inputarray =[]
  }


  async delete(element:any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(data:any ) {
     let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["defualt_method_id"] = data.defualt_method_id;
    console.log(obj);
    this.spinner.show()
    var resp = await this.DefaulterService.deleteDefaulter(obj);
    // console.log(resp, 'list')
    if (resp['error'] == false) {
      await this.showdata()
      this.spinner.hide();
      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

}
