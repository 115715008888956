<div class="page-content fade-in-up">
    <br>
    <div class="row">
        <button style="margin-left:0.5%;" class="btn btn-link" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i> Go Back</button>
    </div>
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT1']}}
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i
                                    class="ti-settings"></i>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i
                                    class="ti-announcement"></i>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT4']}}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img [src]="imgURL" class="img-circle" width="153px" />

                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT5']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <h6>{{mainService.accInfo['b_acct_id']}}</h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT6']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <h6>{{mainService.accInfo['account_name']}}
                                        ({{mainService.accInfo['account_short_name']}})
                                    </h6>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT7']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <h6>{{mainService.accInfo['address']}}</h6>
                                </div>
                            </div>





                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT8']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['account_short_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT6']}} :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="updateObj['account_name']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT7']}}:</h6>
                                </div>
                                <div class="col-4">
                                    <textarea id="addr" name="addr" class="form-control"
                                        [(ngModel)]="updateObj['address']"></textarea>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="updateAccInfo()">{{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT9']}}</button>
                                </div>

                            </div>




                        </div>

                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-12  text-center">
                                    <img [src]="imgURL" class="img-circle" width="150" height="150">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6" style="margin-left: 20%">
                                    <input #file type="file" accept=".png, .jpg, .jpeg" name="pimage" ng2FileSelect
                                        [uploader]="uploader" class="form-control" style="border-color: black"
                                        (change)="onFileUpload($event,file.files)">
                                </div>
                                <div class="col-3">
                                    <button type="button" [disabled]="!uploader.getNotUploadedItems().length"
                                        class="btn btn-info" (click)='Upload()'><i class="fa fa-upload"
                                            aria-hidden="true">&nbsp;</i>
                                        {{mainService.allLableShowObj[mainService.language_cd+'PORTALACCOUNT10']}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <style type="text/css">
        .profile-social a {
            font-size: 16px;
            margin: 0 10px;
            color: #999;
        }

        .profile-social a:hover {
            color: #485b6f;
        }

        .profile-stat-count {
            font-size: 22px
        }
    </style>


    <div class="row">
        <div class="col-12 text-center">
            <!-- <div class="font-13">build=erpbuild</div> -->
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">Loading...</ngx-spinner>