import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ToastrModule } from 'ngx-toastr';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { ToastrModule } from 'ng6-toastr-notifications';

import { SharedRoutingModule } from './shared-routing.module';
// import { WorkflowDefinitionComponent } from '../md/workflow-definition/workflow-definition.component';
import { WorkflowDefinitionComponent } from '../md/workflow-definition/workflow-definition.component';
//oc
import { VendorsComponent } from '../md/vendors/vendors.component';
//ec


@NgModule({
  declarations: [
    //oc
    WorkflowDefinitionComponent,
    // VendorsComponent
    //ec
  ],
  exports:[
    //oc
    WorkflowDefinitionComponent,
    // VendorsComponent
    //ec
  ],

  imports: [
    CommonModule,
    SharedRoutingModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
  ]
})
export class SharedModule { }
