<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Calendar</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">



                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="create_flag" data-toggle="tab"><i
                                    class="fa fa-calendar"></i>
                                Holiday Calendar</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-2" *ngIf="list_flag" data-toggle="tab"><i
                                    class="fa fa-calendar"></i>
                                Holiday List</a>
                        </li>

                    </ul>
                    <div class="tab-content" *ngIf="create_flag">
                        <div class="tab-pane fade show active" id="tab-1">
                            <!-- </div> -->
                            <br>

                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row mt-2" *ngFor="let item of selectedDate ; let i =index">
                                <div class="col-4 text-right">
                                    <h6>Select Date {{i+1}} :</h6>
                                </div>
                                <div class="col-4">
                                    <div>
                                        <input type='date' class="form-control" [(ngModel)]="item.date">

                                    </div>
                                </div>

                                <div class="col-4">
                                    <button class="btn btn-outline-success" (click)="addForm()"><i class="fa fa-plus"
                                            style="color: green;"></i></button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button *ngIf="i!=0" class="btn btn-outline-danger" (click)="remove(i)"><i
                                            class="fa fa-trash-o" style="color:red"></i> </button>
                                </div>

                            </div>
                            <div class="row mt-2">
                                <div class="col-4 text-right">
                                    <h6>Holiday Purpose :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" placeholder="Holiday Purpose"
                                        [(ngModel)]="obj['Purpose']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-success" (click)="submit()">Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-content" *ngIf="list_flag">
                        <div class="tab-pane fade show active" id="tab-2">
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)">
                                    </mat-form-field>
                                </div>
                                <div class="col-7 text-right">

                                    <button class="btn btn-outline-primary" (click)="create_holiday()">Create
                                        Holiday</button>
                                    <br>
                                </div>
                                <div class="col-1 text-right">


                                    <button class="btn btn-outline-success" (click)="print()">Print</button>
                                    <br>

                                </div>


                            </div>




                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%"
                                    class="text-center">

                                    <ng-container matColumnDef="s_no">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef>
                                            <b>S.No</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element let i=index ">
                                            {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="date">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef>
                                            <b>Date</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.day}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="month">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef> <b>
                                                Month</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.month}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="year">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef> <b>
                                                Year</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{element.year}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Purpose">

                                        <th class="text-center" mat-header-cell *matHeaderCellDef>
                                            <b>Purpose</b>
                                        </th>
                                        <td class="text-center" mat-cell flex-stretch *matCellDef="let element">
                                            {{element.holiday_purpose}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="Action">
                                        <th class="text-center" mat-header-cell *matHeaderCellDef>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="text-center">

                                            <button class="btn btn-outline-primary"
                                                (click)="update(element)">Update</button>&nbsp;
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">Delete</button>

                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="update" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:60%;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title justify-content-center" id="exampleModalLongTitle">
                    <h5>Update</h5>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">


                <div class="row mt-2" *ngFor="let item of selectedDate ; let i =index">
                    <div class="col-4 text-right">
                        <h6>Select Date {{i+1}} :</h6>
                    </div>
                    <div class="col-4">
                        <div>
                            <input type='date' class="form-control" [(ngModel)]="item.date">
                        </div>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-4 text-right">
                        <h6>Holiday Purpose :</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" placeholder="Holiday Purpose"
                            [(ngModel)]="obj['Purpose']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-outline-success" (click)="update_day()">Update</button>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal"
                    (click)="refresh()">Close</button>

            </div>
        </div>
    </div>
</div>




<ngx-spinner>
    <p style="color: white"> Loading...</p>
</ngx-spinner>
