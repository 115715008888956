<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">App Attendance Report </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Attendance report</a>
                        </li>

                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-bar-chart"></i>
                                View Images</a>
                        </li> -->


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees" bindLabel="emp_desc" bindValue="le_id"
                                        [multiple]="false" placeholder="Select Employee" [(ngModel)]="attObj['le_id']"
                                        (change)="change()" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Date :
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="attObj['date']">

                                </div>

                            </div>

                            <br>
                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-primary" (click)="getAttendanceReport()">SUBMIT</button>
                                &nbsp;&nbsp;
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="print()">Download
                                        PDF</button>&nbsp;&nbsp;
                                    <!-- <button class="btn btn-info" (click)="print1()">Download PDF1</button>&nbsp;&nbsp; -->

                                    <!-- <button class="btn btn-outline-success" (click)="export()">Download Excel</button> -->

                                </div>
                            </div>
                            <br>





                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource" matSort>
                                    <ng-container matColumnDef="serial_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Serial No.

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            <!-- {{i+1}} -->
                                            {{element.new_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Employee ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="zone">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Zone

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.zone1 }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Emp Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pi_time">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In
                                            Time

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.create_timestamp | date: 'h:mm:ss a' }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pi_latitude">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In
                                            Latitude

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pi_latitude }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pi_longitude">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch In
                                            Longitude

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pi_longitude }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="po_time">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out
                                            Time

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.update_timestamp | date: 'h:mm:ss a' }} </td>

                                    </ng-container>
                                    <ng-container matColumnDef="po_latitude">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out
                                            Latitude

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.po_latitude }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="po_longitude">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Punch Out
                                            Longitude

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.po_longitude }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Attendance
                                            Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.date1 }} </td>
                                    </ng-container>



                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10,20,50,100]"
                                    showFirstLastButtons></mat-paginator>
                            </div>


                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>

<!-- <div class="modal fade" id="pi_img" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
            <h5 class="text-center">{{Emp_Name}}</h5>
          <h6>Punch In Image</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        
        <div class="row mt-2 justify-content-center" >
         <img [src]="imgURLPI" height="550px" width="auto" object-fit="contain" />
        </div>



      </div>

    </div>
  </div>
</div> -->

<!-- <div class="modal fade" id="po_img" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
            <h5 class="text-center">{{Emp_Name}}</h5>
          <h6>Punch Out Image</h6>
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        
        <div class="row mt-2 justify-content-center" >
         <img [src]="imgURLPO" height="550px" width="auto" object-fit="contain" />
        </div>



      </div>

    </div>
  </div>
</div> -->
<ngx-spinner>Loading....</ngx-spinner>