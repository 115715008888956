<div id="sidebar-collapse" class="sidebar">

    <div class="admin-block d-flex" style="align-items: center;">
      <div>
        <img src="./../../../assets/img/dash/emb.png" class="img-circle" width="45px" />
      </div>
      <div class="admin-info">
        <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['EMB']}}</div>
      </div>
    </div>
    <ul class="side-menu metismenu">
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" (click)='refreshAssignedData()' routerLink="/emb/emb-index"><i
            class="sidebar-item-icon fa fa-th-large"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR1']}}</span><!-- //Dashboard -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['BOQ-R2']" (click)='refreshAssignedData()'
          routerLink="/emb/create-tender"><i class="sidebar-item-icon fa  fa-gavel"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR2']}}</span><!-- //BOQ -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['EMBREPORT-R20']" (click)='refreshAssignedData()'
          routerLink="/emb/reports"><i class="sidebar-item-icon fa fa-flag-checkered"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR27']}}</span><!-- //BOQ -->
        </a>
      </li>
      <li>
        <!-- reports -->
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['EMB-R3']" (click)='refreshAssignedData()'
          routerLink="/emb/create-emb"><i class="sidebar-item-icon fa fa-bar-chart"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR3']}}</span><!-- //EMB -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['EBILL-R4']" (click)='refreshAssignedData()'
          routerLink="/emb/ebill-create"><i class="sidebar-item-icon fa  fa-money"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR4']}}</span><!-- //E-BILL -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" (click)='refreshAssignedData()' routerLink="/emb/cb"><i
            class="sidebar-item-icon fa fa-object-ungroup"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR5']}}</span><!-- CB -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['DEVIATION-R5']" (click)='refreshAssignedData()'
          routerLink="/emb/deviation"><i class="sidebar-item-icon  fa fa-wrench"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR6']}}</span><!-- Deviation -->
        </a>
      </li>
      <li>
        <a class = "text-decoration-none" routerLinkActive="active" [hidden]="sideBarFlag['Completion-R6']" (click)='refreshAssignedData()'
          routerLink="/emb/completion"><i class="sidebar-item-icon  fa fa-check"></i>
          <span
            class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR7']}}</span><!-- Completion -->
        </a>
      </li>
      <!-- <li>
              <a class = "text-decoration-none" routerLinkActive="active" routerLink="/emb/department"><i class="sidebar-item-icon  fa fa-info"></i>
                  <span class="nav-label">Department</span>
              </a>
          </li> -->
      <li id="accordion">
        <ul>
          <li routerLinkActive="active">
            <a class = "text-decoration-none"  data-bs-toggle="collapse" href="#collapse1" aria-expanded="false"
              aria-controls="collapse1"
              [hidden]="sideBarFlag['undertaking'] && sideBarFlag['DEFAPPR-R21'] && sideBarFlag['Work-R11'] && sideBarFlag['Budget-R19'] && sideBarFlag['Geometry-R13'] && sideBarFlag['TERM-R7'] && sideBarFlag['CODE-VALUE-R8'] && sideBarFlag['Vendors-R9'] && sideBarFlag['Deduction-R12'] && sideBarFlag['Authorization-R16'] && sideBarFlag['EMBHIER-R22'] && sideBarFlag['Users-R14'] && sideBarFlag['Roles-R15']">
              <i class="sidebar-item-icon fa fa-database"></i>
              <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR8']}}</span>
              <i class="fa fa-angle-left arrow"></i>
            </a>
            <!-- Administration -->
            <ul class="nav-2-level collapse activeCol" id="collapse1" data-bs-parent="#accordion" routerLinkActive="in">
              <div id="child1">
                <li>

                  <a class = "text-decoration-none" data-bs-toggle="collapse" href="#collapse11" aria-expanded="false" aria-controls="collapse11"
                    [hidden]="sideBarFlag['undertaking'] && sideBarFlag['DEFAPPR-R21'] && sideBarFlag['Work-R11'] && sideBarFlag['Budget-R19'] && sideBarFlag['Geometry-R13'] && sideBarFlag['TERM-R7'] && sideBarFlag['CODE-VALUE-R8'] && sideBarFlag['Vendors-R9'] && sideBarFlag['Deduction-R12']">
                    <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR9']}}</span><i
                      class="fa fa-angle-left arrow"></i></a>
                  <!-- Master Data -->
                  <ul class="nav-3-level collapse" id="collapse11" data-bs-parent="#child1">
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/fields" [hidden]="sideBarFlag['TERM-R7']"
                        (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR10']}}</a>
                      <!-- Term -->
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/code-value" [hidden]="sideBarFlag['CODE-VALUE-R8']"
                        (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR11']}}</a>
                      <!-- Code Value -->
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/party" [hidden]="sideBarFlag['Vendors-R9']"
                        (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR12']}}</a>
                      <!-- Vendors/Contractors -->
                    </li>
                                                                                                  <!-- <li>
                                                                                    <a class = "text-decoration-none" routerLink="/emb/administration/project" routerLinkActive="active">Project
                                                                                        </a>
                                                                                </li> -->

                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/deduction" [hidden]="sideBarFlag['Deduction-R12']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR13']}} <!-- Deduction -->
                      </a>
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/geometry" [hidden]="sideBarFlag['Geometry-R13']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR14']}}<!-- Geometry --></a>
                    </li>
                    <!--  <li>
                                                                <a class = "text-decoration-none" routerLink="/emb/administration/bud" (click)='refreshAssignedData()' routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR15']}}</a>
                                                            </li> -->
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/work" [hidden]="sideBarFlag['Work-R11']"
                        (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR16']}}<!-- Work -->
                      </a>
                    </li>
                    <li>

                      <a class = "text-decoration-none" routerLink="/emb/administration/def-Appr" (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB_Workflow1']}}</a>

                    </li>
                    <li>

                      <a class = "text-decoration-none" routerLink="/emb/administration/role-print" [hidden]="sideBarFlag['DEFAPPR-R21']"
                        (click)='refreshAssignedData()' routerLinkActive="active">role-print</a>

                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/undertacking" [hidden]="sideBarFlag['undertaking']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'undertaking1']}}
                      </a>
                    </li>

                  </ul>
                </li>
                <li>
                  <a class = "text-decoration-none" href="#collapse12" data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapse12"
                    [hidden]="sideBarFlag['Authorization-R16'] && sideBarFlag['EMBHIER-R22'] && sideBarFlag['Users-R14'] && sideBarFlag['Roles-R15']">
                    <span
                      class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR30']}}<!-- User --></span><i
                      class="fa fa-angle-left arrow"></i></a>
                  <ul class="nav-3-level collapse" data-bs-parent="#child1" id="collapse12">
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/create-role" [hidden]="sideBarFlag['Users-R14']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR19']}}<!--  Role -->
                      </a>
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/users" [hidden]="sideBarFlag['Roles-R15']"
                        (click)='refreshAssignedData()'
                        routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR18']}}<!-- Users --></a>
                    </li>
                    <!-- <li>
                                                                <a class = "text-decoration-none" routerLink="/emb/administration/user-mgmt/zone-head" routerLinkActive="active">
                                                                    Zone
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class = "text-decoration-none" routerLink="/emb/administration/user-mgmt/project-head" routerLinkActive="active">
                                                                    Project
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class = "text-decoration-none" routerLink="/emb/administration/user-mgmt/work-head" routerLinkActive="active">
                                                                    Work
                                                                </a>
                                                            </li> -->
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/emb-hier" [hidden]="sideBarFlag['EMBHIER-R22']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR20']}} <!-- Hierarchy -->
                      </a>
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/administration/data-assignment" [hidden]="sideBarFlag['Authorization-R16']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR21']}} <!--  Data Assignment -->
                      </a>
                    </li>



                  </ul>

                </li>
              </div>
            </ul>
          </li>


          <li routerLinkActive="active"  >
            <a class = "text-decoration-none"  href="#collapse2" data-bs-toggle="collapse" aria-expanded="false"
              aria-controls="collapse2" [hidden]="sideBarFlag['Inception-R20'] || sideBarFlag['Estimate-R21']"><i
                class="sidebar-item-icon fa fa-database"></i>
              <span class="nav-label">
                Engineering Estimation
                <!-- {{mainService.allLableShowObj[mainService.language_cd+'eng-estimation1']}} -->
              </span><i
                class="fa fa-angle-left arrow"></i></a>

            <ul class="nav-2-level collapse activeCol" id="collapse2" data-bs-parent="#accordion" routerLinkActive="in">
              <div id="child2">

                <li>
                  <a class = "text-decoration-none" href="#collapse2-1" data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapse2-1">
                    <!-- Estimation -->
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'estimation1']}} -->
                    <span class="nav-label">Estimation
                    </span><i
                      class="fa fa-angle-left arrow"></i></a>
                  <ul class="nav-3-level collapse" id="collapse2-1" data-bs-parent="#child2">
                    <li>
                      <a class = "text-decoration-none" routerLink='/emb/depricated/inceptioin' [hidden]="sideBarFlag['Inception-R20']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        <!-- Inception -->
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'inception1']}} -->Inception
                      </a>
                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/depricated/Estimate" [hidden]="sideBarFlag['Estimate-R21']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        Estimate
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'estimate1']}} -->
                      </a>
                    </li>




                  </ul>

                </li>
                <!-- List Item -->
                <li>
                  <a class = "text-decoration-none" href="#collapse2-2" data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapse2-2"
                    [hidden]="sideBarFlag['sor-R24'] || sideBarFlag['dsr-R25'] || sideBarFlag['dsr-R25'] ">
                    <!-- {{mainService.allLableShowObj[mainService.language_cd+'list_item1']}} -->List Item
                     <i
                      class="fa fa-angle-left arrow"></i></a>

                  <ul class="nav-3-level collapse" id="collapse2-2" data-bs-parent="#child2">
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/depricated/sor" [hidden]="sideBarFlag['sor-R24']" (click)='refreshAssignedData()'
                        routerLinkActive="active">
                        SOR
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'sor1']}} -->
                      </a>

                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/depricated/dsr" [hidden]="sideBarFlag['dsr-R25']" (click)='refreshAssignedData()'
                        routerLinkActive="active">
                        DSR
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'dsr1']}} -->
                      </a>

                    </li>
                    <li>
                      <a class = "text-decoration-none" routerLink="/emb/depricated/morth" [hidden]="sideBarFlag['morth-R26']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        MORTH
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'morth1']}} -->
                      </a>

                    </li>








                  </ul>
                </li>

                <li>
                  <!-- EStimation  Administration-->
                  <a class = "text-decoration-none" href="#collapse2-3" data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapse2-3"
                    [hidden]="sideBarFlag['Estimation-Master-data-R22']">
                    <span
                      class="nav-label">
                      <!-- {{mainService.allLableShowObj[mainService.language_cd+'eng_admistration1']}} -->
                      EStimation  Administration
                    </span><i
                      class="fa fa-angle-left arrow"></i></a>
                  <ul class="nav-3-level collapse" id="collapse2-3" data-bs-parent="#child2">
                    <!-- Estimation  Master Data-->
                    <li>
                      <a class = "text-decoration-none" routerLink='/emb/depricated/dep_master_data' [hidden]="sideBarFlag['Estimation-Master-data-R22']"
                        (click)='refreshAssignedData()' routerLinkActive="active">
                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'eng_master_data1']}} -->
                        Estimation  Master Data
                      </a>
                    </li>
                  </ul>

                </li>
              </div>
            </ul>
          </li>


          <li routerLinkActive="active">
            <a class = "text-decoration-none"  href="#collapse3" data-bs-toggle="collapse" aria-expanded="false"
              aria-controls="collapse3"><i class="sidebar-item-icon fa fa-question-circle"></i>
              <span
                class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR22']}}<!-- Help --></span><i
                class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse activeCol" id="collapse3" data-bs-parent="#accordion" routerLinkActive="in">
              <li>
                <a class = "text-decoration-none" routerLink="/emb/help/faq"
                  routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR23']}}<!-- FAQ --></a>
              </li>
              <li>
                <a class = "text-decoration-none" routerLink="/emb/help/doc"
                  routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR24']}}<!-- User Manual --></a>
              </li>
              <li>
                <a class = "text-decoration-none" routerLink="/emb/help/videos"
                  routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR25']}}<!-- Tutorial Videos --></a>
              </li>
              <li>
                <a class = "text-decoration-none" routerLink="/emb/help/online-support"
                  routerLinkActive="active">{{mainService.allLableShowObj[mainService.language_cd+'EMB-SIDEBAR26']}}<!-- Online Support --></a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!-- <li>
              <a class = "text-decoration-none" routerLinkActive="active"  routerLink="/emb/eng-master-data"><i class="sidebar-item-icon  fa fa-check"></i>
                  <span class="nav-label">Master Data</span>
              </a>
          </li>
          <li>
              <a class = "text-decoration-none" routerLinkActive="active"  routerLink="/emb/eng-sor-items"><i class="sidebar-item-icon  fa fa-check"></i>
                  <span class="nav-label">SOR Items</span>
              </a>
          </li>
          <li>
              <a class = "text-decoration-none" routerLinkActive="active"  routerLink="/emb/dsr"><i class="sidebar-item-icon  fa fa-check"></i>
                  <span class="nav-label">DSR</span>
              </a>
          </li>
          <li>
              <a class = "text-decoration-none" routerLinkActive="active"  routerLink="/emb/morth"><i class="sidebar-item-icon  fa fa-check"></i>
                  <span class="nav-label">MORTH</span>
              </a>
          </li>
          <li>
              <a class = "text-decoration-none" routerLinkActive="active"  routerLink="/emb/estimate"><i class="sidebar-item-icon  fa-check"></i>
                  <span class="nav-label">Estimate</span>
              </a>
          </li> -->
    </ul>
  </div>
