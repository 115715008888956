<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo1']}}
                        <!-- Employee Dependent Info -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo2']}}
                                <!-- Employee Dependent Detail --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo3']}}
                                <!-- Add Employee Dependent Detail --></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-announcement"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo4']}}
                                <!-- Update Employee Dependent Detail --></a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_create()>Add Dependent Detail</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}
                                    <!-- Select Employee -->: <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}" [(ngModel)]="selectEmpObj['le_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>

                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo6']}}">
                                <!-- Filter -->
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="dependent_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo7']}}
                                            <!-- Dependent Name -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.dependent_name }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="relation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo8']}}
                                            <!-- Relation -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0004'][element.relation_code]}}
                                        </td>

                                    </ng-container>





                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo9']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo10']}}
                                                <!-- Update --></button>
                                            <button class="btn btn-outline-danger" (click)="deleteDependent(element)">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo11']}}
                                                <!-- Delete --></button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}
                                    <!-- Select Employee -->:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                        <!-- Select Employee -->
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo7']}}
                                    <!-- Dependent Name --><span style="color: red;">*</span> :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" onkeydown="return /[A-Za-z ]/i.test(event.key)" [(ngModel)]="dependentObj['dependent_name']" >
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo8']}}
                                    <!-- Relation --><span style="color: red;">*</span> :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0004']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="dependentObj['relation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>



                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitDependentInfo()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo12']}}
                                        <!-- Submit --></button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}
                                    <!-- Select Employee -->:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="le_id" [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo5']}}" [(ngModel)]="selectEmpObj['le_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" disabled="true" > </ng-select>
                                    </div>
                                    <!-- <input class="form-control" type="text" [(ngModel)]="selectEmpObj['emp_name']" disabled> -->



                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo7']}}
                                    <!-- Dependent Name --><span style="color: red;">*</span> :
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="text"  [(ngModel)]="dependentObj['dependent_name']"   onkeydown="return /[A-Za-z ]/i.test(event.key)">
                                </div>



                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo8']}}
                                    <!-- Relation --><span style="color: red;">*</span> :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0004']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="dependentObj['relation_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>



                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateDependentInfo()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo10']}}
                                        <!-- Update --></button>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-DependentInfo13']}}
    <!-- Loading -->....</ngx-spinner>
