import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../md/service/main.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit {

  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='VMS'
  }

}
