import { Component, OnInit } from '@angular/core';
// import { EChartsOption } from 'echarts';
import Swal from 'sweetalert2';
import { DashboardService } from '../service/dashboard.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dash-charts',
  templateUrl: './dash-charts.component.html',
  styleUrls: ['./dash-charts.component.css']
})
export class DashChartsComponent implements OnInit {

  constructor(private dashboardService: DashboardService,private datePipe: DatePipe) { }
  readonly Theme = Theme;
  erpUser: any
  b_acct_id: any
  ebillUser: any

// oc
  chartOption: any
  chartOption2!: any;
  // ac
  // chartOption : any ;
  // chartOption2 : any ;

  dataForChart: any=[];
  async ngOnInit()  {
    console.log(Theme)
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // this.erpUser = JSON.parse(localStorage.getItem('erpUser'));
    this.b_acct_id = this.erpUser.b_acct_id;
    // this.ebillUser = JSON.parse(localStorage.getItem('erpUser'));
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
   await this.getChartData()
   this.setDataforChart1()
   this.setDataforChart2()

    console.log(this.data)

  }
// create_time: "2022-08-29T06:52:54.000Z"
// total_amt: 192700
// txn_id: 100008205
// [[Prototype]]: Object
  data:any=[]
  async getChartData(){  // for 1 and 2
    const obj: any = {}
    obj['limit']=20
    obj['b_acct_id'] = this.b_acct_id
    console.log("kuch hua")
    const resp = await this.dashboardService.getChartProRegData(JSON.stringify(obj))
    console.log(resp)
    if(!resp['error']){
      this.dataForChart = resp['data']
      console.log(this.data)
    }else{
      Swal.fire("Error",'Server Side Error','error')
    }
  }

  setDataforChart1(){
    let temp: any ={}
    for (let i = 0; i < this.dataForChart.length; i++) {
        let date = new Date(this.dataForChart[i].create_time)
        console.log(date,this.dataForChart[i].total_amt)
       if(temp[date.toLocaleDateString()]){
           temp[date.toLocaleDateString()]=temp[date.toLocaleDateString()]+this.dataForChart[i].total_amt
        }else{
            temp[date.toLocaleDateString()]=this.dataForChart[i].total_amt
        }
        console.log(temp)
      }
      // ----------
      let datlist = Object.keys(temp)
      let startDate = new Date(datlist[0])
      // let
      for(let i=0;i<datlist.length;i++){
        if(!temp[startDate.toLocaleDateString()]){
          this.data.push({date:startDate.toLocaleDateString(),amount:0})
        }else{
          this.data.push({date:startDate.toLocaleDateString(),amount:temp[datlist[i]]})
        }
        startDate.setDate(startDate.getDate()+1)
        console.log(startDate.toLocaleDateString())
      }
      this.chartOption = {
        tooltip:{
          show:true
        },
        background:'transparent',
        xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  // data: this.data.map(ele=> ele.date),
                  data: this.data.map((ele:any)=> this.datePipe.transform(ele.date,'d MMM, y')),
                  name:"Date"
                },
        yAxis: {
          type: 'value',
          name:'Registration Amount'
        },
        series: [
          {
            data: this.data.map((ele:any)=>ele.amount),
            type: 'line',
            // areaStyle: {}
          },
        ],
      };

  }
  setDataforChart2(){
    let temp: any ={}
    let data: any = []
    console.log(this.dataForChart)
    for (let i = 0; i < this.dataForChart.length; i++) {
      console.log(this.dataForChart[i])
      let date = new Date(this.dataForChart[i].create_time)
       if(temp[date.toLocaleDateString()]){
           temp[date.toLocaleDateString()]+=1
          }else{
            temp[date.toLocaleDateString()]=1
       }
      }
      console.log(JSON.stringify(temp))

      // ----------
      let datlist = Object.keys(temp)
      let startDate = new Date(datlist[0])
      // let
      for(let i=0;i<datlist.length;i++){

        if(!temp[startDate.toLocaleDateString()]){
          data.push({date:startDate.toLocaleDateString(),amount:0})
        }else{
          data.push({date:startDate.toLocaleDateString(),amount: temp[datlist[i]]})
        }
        startDate.setDate(startDate.getDate()+1)
        console.log(startDate.toLocaleDateString())
      }
      this.chartOption2 = {
        tooltip:{
          show:true
        },
        background:'transparent',
        xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  // data: this.data.map(ele=> ele.date),
                  data: data.map((ele: any)=> this.datePipe.transform(ele.date,'d MMM, y')),
                  name:'Date'
                },
        yAxis: {
          type: 'value',
          name:'No Of Count'
        },
        series: [
          {
            data: data.map((ele: any)=>ele.amount),
            type: 'line',
            // areaStyle: {}
          },
        ],
      };
      console.log(data)

  }

}


export const Theme = {
  "color": [
      "#2ec7c9",
      "#b6a2de",
      "#5ab1ef",
      "#ffb980",
      "#d87a80",
      "#8d98b3",
      "#e5cf0d",
      "#97b552",
      "#95706d",
      "#dc69aa",
      "#07a2a4",
      "#9a7fd1",
      "#588dd5",
      "#f5994e",
      "#c05050",
      "#59678c",
      "#c9ab00",
      "#7eb00a",
      "#6f5553",
      "#c14089"
  ],
  "backgroundColor": "rgba(0,0,0,0)",
  "textStyle": {},
  "title": {
      "textStyle": {
          "color": "#008acd"
      },
      "subtextStyle": {
          "color": "#aaaaaa"
      }
  },
  "line": {
      "itemStyle": {
          "borderWidth": 1
      },
      "lineStyle": {
          "width": 2
      },
      "symbolSize": 3,
      "symbol": "emptyCircle",
      "smooth": true
  },
  "radar": {
      "itemStyle": {
          "borderWidth": 1
      },
      "lineStyle": {
          "width": 2
      },
      "symbolSize": 3,
      "symbol": "emptyCircle",
      "smooth": true
  },
  "bar": {
      "itemStyle": {
          "barBorderWidth": 0,
          "barBorderColor": "#ccc"
      }
  },
  "pie": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "scatter": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "boxplot": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "parallel": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "sankey": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "funnel": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "gauge": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      }
  },
  "candlestick": {
      "itemStyle": {
          "color": "#d87a80",
          "color0": "#2ec7c9",
          "borderColor": "#d87a80",
          "borderColor0": "#2ec7c9",
          "borderWidth": 1
      }
  },
  "graph": {
      "itemStyle": {
          "borderWidth": 0,
          "borderColor": "#ccc"
      },
      "lineStyle": {
          "width": 1,
          "color": "#aaaaaa"
      },
      "symbolSize": 3,
      "symbol": "emptyCircle",
      "smooth": true,
      "color": [
          "#2ec7c9",
          "#b6a2de",
          "#5ab1ef",
          "#ffb980",
          "#d87a80",
          "#8d98b3",
          "#e5cf0d",
          "#97b552",
          "#95706d",
          "#dc69aa",
          "#07a2a4",
          "#9a7fd1",
          "#588dd5",
          "#f5994e",
          "#c05050",
          "#59678c",
          "#c9ab00",
          "#7eb00a",
          "#6f5553",
          "#c14089"
      ],
      "label": {
          "color": "#eeeeee"
      }
  },
  "map": {
      "itemStyle": {
          "areaColor": "#dddddd",
          "borderColor": "#eeeeee",
          "borderWidth": 0.5
      },
      "label": {
          "color": "#d87a80"
      },
      "emphasis": {
          "itemStyle": {
              "areaColor": "rgba(254,153,78,1)",
              "borderColor": "#444",
              "borderWidth": 1
          },
          "label": {
              "color": "rgb(100,0,0)"
          }
      }
  },
  "geo": {
      "itemStyle": {
          "areaColor": "#dddddd",
          "borderColor": "#eeeeee",
          "borderWidth": 0.5
      },
      "label": {
          "color": "#d87a80"
      },
      "emphasis": {
          "itemStyle": {
              "areaColor": "rgba(254,153,78,1)",
              "borderColor": "#444",
              "borderWidth": 1
          },
          "label": {
              "color": "rgb(100,0,0)"
          }
      }
  },
  "categoryAxis": {
      "axisLine": {
          "show": true,
          "lineStyle": {
              "color": "#008acd"
          }
      },
      "axisTick": {
          "show": true,
          "lineStyle": {
              "color": "#333"
          }
      },
      "axisLabel": {
          "show": true,
          "color": "#333"
      },
      "splitLine": {
          "show": false,
          "lineStyle": {
              "color": [
                  "#eee"
              ]
          }
      },
      "splitArea": {
          "show": false,
          "areaStyle": {
              "color": [
                  "rgba(250,250,250,0.3)",
                  "rgba(200,200,200,0.3)"
              ]
          }
      }
  },
  "valueAxis": {
      "axisLine": {
          "show": true,
          "lineStyle": {
              "color": "#008acd"
          }
      },
      "axisTick": {
          "show": true,
          "lineStyle": {
              "color": "#333"
          }
      },
      "axisLabel": {
          "show": true,
          "color": "#333"
      },
      "splitLine": {
          "show": true,
          "lineStyle": {
              "color": [
                  "#eee"
              ]
          }
      },
      "splitArea": {
          "show": true,
          "areaStyle": {
              "color": [
                  "rgba(250,250,250,0.3)",
                  "rgba(200,200,200,0.3)"
              ]
          }
      }
  },
  "logAxis": {
      "axisLine": {
          "show": true,
          "lineStyle": {
              "color": "#008acd"
          }
      },
      "axisTick": {
          "show": true,
          "lineStyle": {
              "color": "#333"
          }
      },
      "axisLabel": {
          "show": true,
          "color": "#333"
      },
      "splitLine": {
          "show": true,
          "lineStyle": {
              "color": [
                  "#eee"
              ]
          }
      },
      "splitArea": {
          "show": true,
          "areaStyle": {
              "color": [
                  "rgba(250,250,250,0.3)",
                  "rgba(200,200,200,0.3)"
              ]
          }
      }
  },
  "timeAxis": {
      "axisLine": {
          "show": true,
          "lineStyle": {
              "color": "#008acd"
          }
      },
      "axisTick": {
          "show": true,
          "lineStyle": {
              "color": "#333"
          }
      },
      "axisLabel": {
          "show": true,
          "color": "#333"
      },
      "splitLine": {
          "show": true,
          "lineStyle": {
              "color": [
                  "#eee"
              ]
          }
      },
      "splitArea": {
          "show": false,
          "areaStyle": {
              "color": [
                  "rgba(250,250,250,0.3)",
                  "rgba(200,200,200,0.3)"
              ]
          }
      }
  },
  "toolbox": {
      "iconStyle": {
          "borderColor": "#2ec7c9"
      },
      "emphasis": {
          "iconStyle": {
              "borderColor": "#18a4a6"
          }
      }
  },
  "legend": {
      "textStyle": {
          "color": "#333333"
      }
  },
  "tooltip": {
      "axisPointer": {
          "lineStyle": {
              "color": "#008acd",
              "width": "1"
          },
          "crossStyle": {
              "color": "#008acd",
              "width": "1"
          }
      }
  },
  "timeline": {
      "lineStyle": {
          "color": "#008acd",
          "width": 1
      },
      "itemStyle": {
          "color": "#008acd",
          "borderWidth": 1
      },
      "controlStyle": {
          "color": "#008acd",
          "borderColor": "#008acd",
          "borderWidth": 0.5
      },
      "checkpointStyle": {
          "color": "#2ec7c9",
          "borderColor": "#2ec7c9"
      },
      "label": {
          "color": "#008acd"
      },
      "emphasis": {
          "itemStyle": {
              "color": "#a9334c"
          },
          "controlStyle": {
              "color": "#008acd",
              "borderColor": "#008acd",
              "borderWidth": 0.5
          },
          "label": {
              "color": "#008acd"
          }
      }
  },
  "visualMap": {
      "color": [
          "#5ab1ef",
          "#e0ffff"
      ]
  },
  "dataZoom": {
      "backgroundColor": "rgba(47,69,84,0)",
      "dataBackgroundColor": "#efefff",
      "fillerColor": "rgba(182,162,222,0.2)",
      "handleColor": "#008acd",
      "handleSize": "100%",
      "textStyle": {
          "color": "#333333"
      }
  },
  "markPoint": {
      "label": {
          "color": "#eeeeee"
      },
      "emphasis": {
          "label": {
              "color": "#eeeeee"
          }
      }
  }
}
