<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'C_Value1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'C_Value2']}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'C_Value3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'C_Value4']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>

                            <ng-select [items]="allFields" bindLabel="field_business_name" (change)="changeField()"
                                bindValue="field_code" [multiple]="false"
                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value5']}}"
                                [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name">
                            </ng-select>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                        <!-- Filter  -->
                                    </mat-form-field>
                                </div>
                                <div class="col-4">

                                </div>
                                <div class="col-4 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>

                            </div>


                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'C_Value7']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'C_Value8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'C_Value9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.value }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'C_Value10']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'C_Value11']}}</button>
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'C_Value12']}}</button>

                                            <!-- <button class="btn btn-outline-danger" (click)="delete(element)">Delete</button> -->

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="create_flag">

                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <br>
                            <ng-select [items]="allFields" bindLabel="field_business_name" bindValue="field_code"
                                [multiple]="false"
                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value5']}}"
                                [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name">
                            </ng-select>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{auth.allLableShowObjProp[auth.language_cd+'C_Value13']}} :
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value13']}}"
                                        [(ngModel)]="obj['code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{auth.allLableShowObjProp[auth.language_cd+'C_Value14']}} :
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value14']}}"
                                        [(ngModel)]="obj['value']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="save()">{{auth.allLableShowObjProp[auth.language_cd+'C_Value15']}}</button>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="update_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <br>
                            <ng-select [items]="allFields" bindLabel="field_business_name" bindValue="field_code"
                                [multiple]="false"
                                placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value5']}}"
                                [(ngModel)]="selectField" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name">
                            </ng-select>

                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{auth.allLableShowObjProp[auth.language_cd+'C_Value13']}} :
                                </div>
                                <div class="col-4">

                                    <input type="text" class="form-control"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value13']}}"
                                        [(ngModel)]="obj['code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    {{auth.allLableShowObjProp[auth.language_cd+'C_Value14']}} :
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'C_Value14']}}"
                                        [(ngModel)]="obj['value']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary"
                                        (click)="update()">{{auth.allLableShowObjProp[auth.language_cd+'C_Value16']}}</button>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
