import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import swal from 'sweetalert2';

declare var $: any


@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent implements OnInit {

  constructor(public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  allBankAccounts :any= [];
  bankObj :any= {};
  message_creat:any;
  message_update:any;
  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  displayedColumns = ['id', 'bank_acct_no', 'bank_code', 'ifsc_code', 'branch_code', 'bank_email', 'bank_phone_no', 'bank_acct_desc', 'action'];
  datasource:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getBankAccount();
  }



  list_sc = 'active'
  update_sc = 'deactive'
  create_sc = 'deactive'

  create_flag: boolean = false;
  list_flag: boolean = true;
  update_flag: boolean = false;

  list() {
    this.list_sc = 'active'
    this.create_sc = 'deactive'
    this.update_sc = 'deactive'

    this.create_flag = false;
    this.list_flag = true;
    this.update_flag = false;
  }

  create() {
    this.update_sc = 'deactive'
    this.list_sc = 'deactive'
    this.create_sc = 'active'

    this.create_flag = true;
    this.list_flag = false;
    this.update_flag = false;
    this.bankObj = new Object();
  }

  // opUpdate(){
  //   this.update_sc = 'deactive'
  //   this.list_sc = 'active'
  //   this.create_sc = 'deactive'
  //   this.create_flag = false;
  //   this.list_flag = true;
  //   this.update_flag= true;
  // }

  open_update(element:any) {
    this.update_sc = 'active'
    this.list_sc = 'deactive'
    this.create_sc = 'deactive'

    this.create_flag = false;
    this.list_flag = false;
    this.update_flag = true;
    this.ifsc_cd = '';
    this.bankObj = Object.assign({}, element);
    // $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.bankObj = {};
  }

  async getBankAccount() {
    var resp = await this.settingService.getBankAccounts(this.b_acct_id);
    if (resp['error'] == false) {
      this.allBankAccounts = resp.data;
      this.datasource = new MatTableDataSource(this.allBankAccounts)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all fields list!", 'error');
    }
  }
  // status: boolean;
  ifs_flag:any;
  ifs_mes:any = ''
  ifsc_cd:any
  async fetchBankDtl() {
    this.ifsc_cd = this.bankObj['ifsc_code'];
    let regx = "^[A-Z]{4}0[A-Z0-9]{6}$"

    if (!this.ifsc_cd.match(regx)) {
      this.ifs_flag = false;
      this.ifs_mes = "Please Enter Correct IFS Code."
      return;
    }

    let resp = await this.settingService.getBankDtlByIfsc(JSON.stringify(this.ifsc_cd));
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.bankObj['branch_code'] = resp['data']['BRANCH'];
      this.bankObj['bank_code'] = resp['data']['BANK'];
      this.bankObj['bank_address'] = resp['data']['ADDRESS'];
      this.bankObj['ifsc_code'] = resp['data']['IFSC'];
      // this.status = true;
      this.ifs_mes = "Varified"
      this.ifs_flag = true;
    } else {
      this.bankObj['bank_code'] = null;
      this.bankObj['branch_code'] = null;
      this.bankObj['bank_address'] = null;
      this.bankObj['ifsc_code'] = null;
      // this.status = false;
      this.ifs_flag = false;
      this.ifs_mes = "Please Enter Correct IFS Code."

    }
  }
  valid_con_acc: boolean = false;
  // type = "submit"
  dup_acc_no: boolean = false
  async save() {


    // if (this.ifsc_cd != this.bankObj['ifsc_code'] || this.bankObj['ifsc_code'] == null) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Please Varify IFS Code", "warning");
    //   return;
    // } else if (this.bankObj['bank_acct_no'] != this.bankObj['c_bank_acct_no'] ||
    //   this.bankObj['bank_acct_no'] == undefined || this.bankObj['c_bank_acct_no'] == undefined
    // ) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Account Number Not Match", "warning");
    //   return;
    // } else if (this.bankObj['bank_acct_desc'] == undefined || this.bankObj['bank_phone_no'] == undefined || this.bankObj['bank_email'] == undefined) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Please Fill All Details", "warning");
    //   return;
    // }
    //checking for same account number within same bank or with same IFSC
    this.allBankAccounts.map((x:any) => {
      if (x['ifsc_code'] == this.bankObj['ifsc_code'] && x['bank_acct_no'] == this.bankObj['bank_acct_no']) {
        this.dup_acc_no = true;
        this.spinner.hide();
        return;
      }
    })
    if (this.dup_acc_no) {
      this.spinner.hide();
      return;
    }
    this.bankObj['create_user_id'] = this.erpUser.user_id;
    this.bankObj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.createBankAccount(this.bankObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getBankAccount();
      document.getElementById("clean")!.click();
      swal.fire("Success", "...Bank Account Added Successfully!!", 'success');
      this.refresh();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while Adding Bank Account!", 'error');
    }
    this.dup_acc_no = false;
  }


  async update() {

    this.spinner.show();
    // if (this.bankObj['bank_acct_no'] != this.bankObj['c_bank_acct_no']) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Account Number Not Match ", "warning");
    //   return;
    // } else if (this.ifsc_cd != this.bankObj['ifsc_code'] || this.bankObj['ifsc_code'] == null) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Please Varify IFS Code", "warning");
    //   return;
    // } else if (this.bankObj['bank_acct_no'] != this.bankObj['c_bank_acct_no'] ||
    //   this.bankObj['bank_acct_no'] == undefined || this.bankObj['c_bank_acct_no'] == undefined
    // ) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Account Number Not Match", "warning");
    //   return;
    // } else if (this.bankObj['bank_acct_desc'].length < 3 || this.bankObj['bank_phone_no'] < 10 || this.bankObj['bank_email'] < 8) {
    //   this.spinner.hide();
    //   swal.fire("Warning", "Please Fill All Details", "warning");
    //   return;
    // }
    this.allBankAccounts.map((x:any) => {
      if (x['ifsc_code'] == this.bankObj['ifsc_code'] && x['bank_acct_no'] == this.bankObj['bank_acct_no']) {
        this.dup_acc_no = true;
        this.spinner.hide();
        return;
      }
    })
    this.bankObj['update_user_id'] = this.erpUser.user_id;
    this.bankObj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateBankAccount(this.bankObj);
    if (resp['error'] == false) {
      this.spinner.hide();
      document.getElementById("update_clean")!.click();
      await this.getBankAccount();
      swal.fire("Success", "Bank Account Update Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while updating Bank Account!", 'error');
    }

    // this.status
    this.bankObj = {}
    $('.nav-tabs a[href="#tab-1"]').tab('show')
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
