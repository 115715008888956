import { UserService } from "../service/user.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatStepper } from "@angular/material/stepper";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";
import { MainService } from "../service/main.service";
import { MasterDataService } from "../service/master-data.service";
import { TenderService } from "../service/tender.service";
import { ApproveService } from "../service/approve.service";
import * as XLSX from "xlsx";
import { ExcelService } from "../service/file-export.service";
import swal from "sweetalert2";
import { FilterService } from "../service/filter.service";
import { CommonDataService } from "../common _services/common-data.service";
import { EmbHierService } from "../service/emb-hier.service";
import { EmbService } from "../service/emb.service";
import Swal from "sweetalert2";
import { EbillService } from "../service/ebill.service";
declare var $: any;
@Component({
  selector: "app-tender-create",
  templateUrl: "./tender-create.component.html",
  styleUrls: ["./tender-create.component.css"],
})
export class TenderCreateComponent implements OnInit {

  displayedColumns: string[] = [
    "id",
    "party_id",
    "party_leagal_name",
    "work_order_no",
    "work_order_name",
    "tender_nic_id",
    "tender_ref_no",
    "over_under",
    "over_under_rate",
    "amount",
    "status",
    "action",
  ];
  displayedColumns2 = [
    "s_no",
    "line_desc",
    "item_code_or_make",
    "quantity",
    "unit",
    "rate",
    "lime_amt_without_tax",
    "line_amt_in_word",
    "already_measured",
    "part_rate",
  ];
  displayedColumns3 = [
    "s_no",
    "Description",
   "Individual",
   "Cumulative",
   "Paid_last"
  ];
  c_tender_epc_flag = false;;
  l_tender_flag = true;
  c_tender_flag = false;
  uu_tender_flag = false;
  ur_tender_flag = false;
  pay_sch_flag=false;
  ul_tender_flag = false;
  u_paid_last_payment=false
  datasource:any;
  datasource2:any;
  datasource3:any;
  isLinear = false;
  first:any = true;
  second:any = true;
  third:any = true;
  fourth:any = true;
  work_id:any;
  BoqTable = [];
  total_sum = 0;
  obj:any = { already_emb: '0' };
  enableStep3:any;
  b_acct_id:any;
  ebillUser:any;
  displayedColumns5: string[] = [
    "sn",
    "line_desc",
    "rate",
    "new_meas",
    "part_rate",
    "action",
  ];

  dropDown = [
    { id: "Only Ebill", value: "only_emb" },
    { id: "Emb with Ebill", value: "emb_with_ebill" },
  ];
  TableFieldscsv:any = {
    1: "s_no",
    3: "line_group_desc",
    2: "line_desc",
    90: "item_code_or_make",
    4: "quantity",
    5: "unit",
    6: "rate",
    60: "curr",
    53: "lime_amt_without_tax",
    55: "line_amt_in_word",
  };
  TableFieldscsvobj = {
    s_no: 0,
    line_group_desc: "",
    line_desc: "",
    item_code_or_make: "",
    quantity: "0",
    unit: "NO",
    rate: "0",
    curr: 0,
    lime_amt_without_tax: 0,
    line_amt_in_word: "INR ZERO",
  };
  TableHeader = [
    "S.N.",
    "Line Group Description",
    "Line Description",
    "Item Code/make",
    "Quantity",
    "Unit",
    "Rate",
    "Curr",
    "Line Amt without taxes",
    "Line Amount in Words",
  ];
  TableFields = [
    "s_no",
    "line_group_desc",
    "line_desc",
    "item_code_or_make",
    "quantity",
    "unit",
    "rate",
    "curr",
    "lime_amt_without_tax",
    "line_amt_in_word",
  ];
  table_data:any = [];
  constructor(
    private ebillService: EbillService,
    public filterService: FilterService,
    private embHierService: EmbHierService,
    private excel: ExcelService,
    private commonDataService: CommonDataService,
    private approveService: ApproveService,
    private tenderService: TenderService,
    private masterDataService: MasterDataService,
    private userService: UserService,
    private embService: EmbService,
    private spinner: NgxSpinnerService,
    public toastr: ToastrService,
    public mainService: MainService
  ) { }


  @ViewChild("stepper", { static: false }) stepper!: MatStepper;
  @ViewChild("stepper", { static: false }) stepper1!: MatStepper;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild("paginator3", { static: false }) paginator3!: MatPaginator;
  @ViewChild("sort3", { static: false }) sort3!: MatSort;
  allProjects:any = [];
  interval:any;
  refresh_flag:any;
  async ngOnInit() {
    this.refresh_flag = true;
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.allProject = this.mainService.codeValueTechObj["EMB001"];
    console.log(this.mainService.codeValueTechObj);
    console.log(this.mainService.codeValueShowObj);
    this.commonDataService.getAssignedWorkInfo(
      this.b_acct_id,
      this.ebillUser["user_id"]
    );
    this.getAllWorkInfo();
    this.getAllHeadRecord();
    this.commonDataService.getAssignedWorkInfo(
      this.b_acct_id,
      this.ebillUser["user_id"]
    );
    await this.getAllHeadRecord();
    await this.getAllWorkInfo();
    await this.getAllparty();
    await this.getAllApprovalHier();
    await this.getAllGeomatery();
    await this.getAllHeirNodeList();
    await this.reset();
  }


  datasource5:any;
  update_tender_id = 0;
  async getData() {
    var reqObj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.update_tender_id,
    };
    var data = [];
    var resp = await this.tenderService.getItem(JSON.stringify(reqObj));
    if (resp["error"] == false) {
      data = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        resp["data"][i]["old_already_measured"] =
          resp["data"][i]["already_measured"];
        resp["data"][i]["new_already_measured"] =
          resp["data"][i]["already_measured"];
      }
      console.log(resp.data);

      this.spinner.hide();
      this.datasource5 = new MatTableDataSource(resp["data"]);
      this.datasource5.sort = this.sort;
      this.datasource5.paginator = this.paginator;
    } else {
      this.spinner.hide();
      swal.fire("Oops", "Error while getting tender items", "error");
    }
  }
  async open_updateAllreadyMeasured(element:any) {
    this.spinner.show();
    this.update_tender_id = element.tender_id;
    await this.getData();
    this.l_tender_flag = false;
    this.c_tender_flag = false;
    this.uu_tender_flag = false;
    this.ur_tender_flag = false;
    this.ul_tender_flag = true;
    $('.nav-tabs a[href="#tab-7-5"]').tab("show");
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  async updateAllreadyMeasured(element:any) {
    this.spinner.show();
    element["b_acct_id"] = this.b_acct_id;
    var resp = await this.tenderService.updateAlreadyMeasured(element);
    if (resp["error"] == false) {
      await this.getData();
      swal.fire("Success...", "Updated Successfully", "success");
      this.gotolist();
      this.spinner.hide();
    } else {
      await this.getData();
      this.spinner.hide();
      swal.fire("Error", "Error While Getting All Head Data", "error");
    }
  }
  assignedWorks:any = {};
  async getAllHeadRecord() {
    var work_ids:any = [];
    work_ids = Object.values(this.commonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }

  }
  selected_proj:any;
  allProject:any = [];
  changeUnit:any = [];
  updateUnitSwal(ob:any, j:any) {
    swal
      .fire({
        title: "Are you sure? Updating this will replace in all items",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Update it!",
      })
      .then((result) => {
        if (result.value) {
          this.updateUnit(ob, j);
        }
      });
  }
  async gotolist() {
    this.datasource = [];
    this.work_id = null;
    if(this.work_id && (!this.BOQInfo["tender_id"])){
      this.work_id = null;
    }
    this.l_tender_flag = true;
    this.c_tender_flag = false;
    this.uu_tender_flag = false;
    this.ur_tender_flag = false;
    this.ul_tender_flag = false;
    this.c_tender_epc_flag = false;
    this.u_paid_last_payment=false;
  }
  gotocrete() {
    this.reset();
    this.l_tender_flag = false;
    this.c_tender_flag = true;
    this.uu_tender_flag = false;
    this.ur_tender_flag = false;
    this.ul_tender_flag = false;
    this.c_tender_epc_flag = false;
    this.u_paid_last_payment=false;
  }

gotoCreateEpcBOQ() {
  this.reset();
  this.l_tender_flag = false;
  this.c_tender_flag = false;
  this.c_tender_epc_flag = true;
  this.uu_tender_flag = false;
  this.ur_tender_flag = false;
  this.ul_tender_flag = false;
  this.u_paid_last_payment=false;
}
  async updateUnit(ob:any, j:any) {
    ob["b_acct_id"] = this.b_acct_id;
    var resp = await this.tenderService.updateUnit(ob);
    if (resp["error"] == false) {
      ob["unit"] = ob["new_unit"];
      this.changeWorkInTab3();
      swal.fire("Success", "Unit Updated", "success");
      this.gotolist();
    } else {
      swal.fire("Oops", "Error while updating Unit", "error");
    }
  }
  async changeWorkInTab3() {
    this.changeUnit = [];
    var ob:any = {};
    ob["b_acct_id"] = this.b_acct_id;
    ob["work_id"] = this.work_id;
    var tenderObj:any = {};
    var resp = await this.tenderService.getBoq(JSON.stringify(ob));
    if (resp["error"] == false) {
      var data = resp.data;
      if (data.length > 0) {
        tenderObj = data[0];
      }

      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
      return;
    }
    var reqObj = {
      b_acct_id: this.b_acct_id,
      tender_id: tenderObj["tender_id"],
    };
    this.spinner.show();
    var resp = await this.tenderService.getItem(JSON.stringify(reqObj));
    if (resp["error"] == false) {
      data = resp["data"];
      for (var i = 0; i < data.length; i++) {
        var flg = 0;
        for (var j = 0; j < this.allGeo.length; j++) {
          if (data[i]["unit"] == this.allGeo[j]["unit"]) {
            flg = 1;
          }
        }
        if (flg == 0) {
          this.changeUnit.push(data[i]);
        }
      }
      this.spinner.hide();
    } else {
      swal.fire("Oops", "Error while getting tender items", "error");
      this.spinner.hide();
    }
  }
  allGeo = [];
  async getAllGeomatery() {
    this.spinner.show();
    var resp = await this.tenderService.getGeometry(
      JSON.stringify(this.b_acct_id)
    );
    if (resp["error"] == false) {
      this.allGeo = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }
  async getAllProjectInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.masterDataService.getProjectInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.allProject = resp["data"];
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }
  workarrchange:any = [];
  changeproject() {
    var arr = [];
    for (let i = 0; i < this.workArr.length; i++) {
      arr.push(this.workArr[i]);
    }
    this.workarrchange = arr;
  }
  workarrchangecr:any = [];
  changeprojectcreate() {
    var arr = [];
    for (let i = 0; i < this.workArr.length; i++) {
      arr.push(this.workArr[i]);
    }
    this.workarrchangecr = arr;
  }
  async check() {
    swal.fire(
      "Error",
      "Your Amount Is Not Matched..! Please Check Entered Amount",
      "error"
    );
  }
  d = [];

  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }
  applyFilter3(filterValue: string) {
    this.datasource3.filter = filterValue.trim().toLowerCase();
  }
  applyFilter5(filterValue: string) {
    this.datasource5.filter = filterValue.trim().toLowerCase();
  }

  approvalHierForTENDER:any = [];
  async getAllApprovalHier() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.approveService.getApprovalHier(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.approvalHierForTENDER = [];
      var temp = resp.data;

      for (let i = 0; i < temp.length; i++) {
        if ("TENDER" == temp[i]["doc_type"]) {
          this.approvalHierForTENDER.push(temp[i]);
        }
      }
    } else {
      this.spinner.hide();
    }
  }
  existing_heirarchy_nodes = [];
  async getAllHeirNodeList() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    var resp = await this.embHierService.getAllEmbHierarchy(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      this.existing_heirarchy_nodes = resp["data"];
    } else {
      this.spinner.hide();
      swal.fire("Error...", "Error While EMB HIER", "error");
    }
  }
  async changeWork() {

    if (this.obj["work_id"] != undefined) {
      for (let i = 0; i < this.workArr.length; i++) {
        if (this.workArr[i]["id"] == this.obj["work_id"]) {
          this.obj["project_cd"] = this.workArr[i]["project_cd"];
          this.obj["budget_name"] = this.workArr[i]["budget_name"];
          this.obj["work_order_name"] = this.workArr[i]["work_order_name"];
          this.obj["work_desc"] = this.workArr[i]["desc"];
          this.obj["tender_nic_id"] = this.workArr[i]["tender_nic_id"];
          this.obj["tender_ref_no"] = this.workArr[i]["tender_ref_no"];
          this.obj["party_id"] = this.workArr[i]["party_id"];
          this.obj["node_cd"] = this.workArr[i]["node_cd"];
          this.obj['emb_mode'] = this.workArr[i]['emb_mode'];
        }
      }
      this.obj['discount']=0;
      this.obj['labour_cess'] = 0;
      let planing_path = "";
      //set works`s path description
      // for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      //   if (
      //     this.obj["node_cd"] == this.existing_heirarchy_nodes[i]["node_cd"]
      //   ) {
      //     planing_path = this.existing_heirarchy_nodes[i]["path"];
      //   }
      // }
      // let dum = [];
      // dum = planing_path.split("|");
      // let dummy2 = [];
      // if (dum.length > 0) {
      //   for (let i = 0; i < dum.length; i++) {
      //     for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
      //       if (dum[i] == this.existing_heirarchy_nodes[j]["node_cd"]) {
      //         dummy2.push(this.existing_heirarchy_nodes[j]["node_desc"]);
      //       }
      //     }
      //   }
      // }

      // this.obj["path_desc"] = dummy2.join(" --> ");
      this.obj["path_desc"] = this.obj["node_cd"]
      console.log(this.obj, "path desc");
      let number = this.obj['work_id']
      let string = number.toString().padStart(4, '0');

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      this.obj['already_emb'] = string + '/' + mm + '-' + yyyy;

      await this.changeParty();
    } else {
      this.obj["project_cd"] = null;
      this.obj["budget_name"] = null;
      this.obj["work_order_name"] = null;
      this.obj["work_desc"] = null;
      this.obj["tender_nic_id"] = null;
      this.obj["tender_ref_no"] = null;
      this.obj["path_desc"] = null;
    }
  }
  defected_items:any = [];
  setData(data:any) {
    console.log(data);
    this.table_data = [];
    this.defected_items = [];
    var k = 0;
    var tablenumberdata = [];
    var keyfirst = Object.keys(data[0])[0];
    for (let i = 0; i < data.length - 3; i++) {
      if (data[i][keyfirst] == "1") {
        var keys:any = Object.keys(data[i]);
        for (let j = 0; j < keys.length; j++) {
          tablenumberdata.push(data[i][keys[j]]);
        }
        k = i + 1;
        break;
      }
    }
    console.log(keys);
    for (let i = k; i < data.length - 3; i++) {
      var obj11:any = new Object();
      for (let j = 0; j < keys.length; j++) {
        if (this.TableFieldscsv[j + 1] != null && j != 2) {
          var sl_no = data[i][keys[0]].toString().split(".");
          if (data[i][keys[52]] != null) {
            if (j == 1 && sl_no.length != 2) {
              obj11[this.TableFieldscsv[j + 2]] = data[i][keys[j]];
            } else if (j == 1 && sl_no.length == 2) {
              obj11[this.TableFieldscsv[j + 2]] = desc;
            }

            obj11[this.TableFieldscsv[j + 1]] = data[i][keys[j]];
          } else {
            var desc = data[i][keys[1]];

            obj11 = Object.assign({}, this.TableFieldscsvobj);
            obj11["line_desc"] = data[i][keys[1]];
          }
        }
      }
      if (Object.keys(obj11).length != 0) {
        obj11[this.TableFieldscsv[60]] = "INR";
        obj11["already_measured"] = 0;
        this.table_data.push(obj11);
      }
    }
    let s_no = [];
    console.log(this.table_data);
    this.total_sum = 0;
    for (let k = 0; k < this.table_data.length; k++) {
      this.table_data[k]["s_no"] = k + 1;
      this.table_data[k]["quantity"] = this.mainService.trunc(
        this.table_data[k]["quantity"]
      );
      this.table_data[k]["eff_quantity"] = this.mainService.trunc(
        this.table_data[k]["eff_quantity"]
      );
      this.table_data[k]["already_measured"] = this.mainService.trunc(
        this.table_data[k]["already_measured"]
      );
      this.table_data[k]["lime_amt_without_tax"] = Number(
        this.table_data[k]["lime_amt_without_tax"].toFixed(2)
      );
      this.total_sum =
        this.table_data[k]["lime_amt_without_tax"] + this.total_sum;
      let cmp: any =
        this.table_data[k]["quantity"] * this.table_data[k]["rate"];
      cmp = cmp.toFixed(2);
      if (cmp != this.table_data[k]["lime_amt_without_tax"]) {
        this.defected_items.push(this.table_data[k]);
        s_no.push(this.table_data[k]["s_no"]);
      }
    }

    for (let k = 0; k < this.table_data.length; k++) {
      this.table_data[k]["part_rate"] = this.table_data[k]["rate"];
      this.table_data[k]["is_extra_item"] = 0;
    }
    let msg =
      "There are Total " +
      this.defected_items.length +
      " Items (Who`s S No. Is '" +
      s_no.join() +
      "')" +
      "  Who`s Rate * Quantity Is Not Eqaul To Total Amount  In The Uploaded BOQ";
    if (s_no.length > 0) {
      Swal.fire("Warning...", msg, "warning");
    }
    console.log(this.table_data, "table_data");
    this.total_sum = Number(this.total_sum.toFixed(2));
    this.datasource2 = new MatTableDataSource(this.table_data);
    this.datasource2.sort = this.sort;
    this.datasource2.paginator = this.paginator;
    console.log(this.defected_items, "defected_items");
  }
  payschudeinfo:any={};
  BOQInfo:any = {};
  async getAllBOQ() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["work_id"] = this.work_id;
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      var data = resp.data;
      console.log(data);
      if(data.length==0){
        this.pay_sch_flag=false;
      }else{
        if(resp.data[0]["emb_mode"]=="EPC"){
          this.pay_sch_flag=true;
        }else{
        this.pay_sch_flag=false;}
      this.BoqTable = resp["data"];
      this.work_id=resp.data[0]['work_id'];
      this.payschudeinfo=resp.data[0];
      if (resp.data.length == 0) {
        swal.fire("warning", 'BOQ Not Uploaded...', 'warning');
        this.spinner.hide();
        this.BOQInfo={};
        return;
      }
      this.BOQInfo = resp.data[0];
      this.datasource = new MatTableDataSource(this.BoqTable);
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;

    }
    this.spinner.hide();
} else {
  this.spinner.hide();
  this.toastr.error(resp["data"]);
}
}

async changeWorkOnList() {
  this.BOQInfo=[];
  var obj:any = new Object();
  obj["b_acct_id"] = this.b_acct_id;
  obj["work_id"] = this.work_id;
  this.spinner.show();
  var resp = await this.tenderService.getBoq(JSON.stringify(obj));
  if (resp["error"] == false) {
    var data = resp.data;
    this.spinner.hide();
  if(data.length==0){
    this.work_id={};
    this.pay_sch_flag=false;
    Swal.fire("Oppps!!!", "BOQ is not upload", 'error');
    return;
  }else{
    if(resp.data[0]["emb_mode"]=="EPC"){
      this.pay_sch_flag=true;
    }else{
    this.pay_sch_flag=false;}
    console.log(data[0]['work_id']);
    this.work_id=data[0]['work_id'];
    this.BoqTable = resp["data"];
    this.BOQInfo=resp.data[0];
    this.payschudeinfo=resp.data[0];
    this.datasource = new MatTableDataSource(this.BoqTable);
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }
  } else {
    this.spinner.hide();
    this.toastr.error(resp["data"]);
  }
}


  workArr:any = [];
  async getAllWorkInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    console.log(resp, "workarr");
    if (resp["error"] == false) {
      var dt = [];
      var temp = resp["data"];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]["id"]] != undefined) {
          dt.push(temp[i]);
        }
      }
      this.workArr = dt;
      for (let i = 0; i < this.workArr.length; i++) {
        this.workArr[i]["desc"] =
          this.workArr[i]["work_order_no"] +
          " - " +
          this.workArr[i]["work_order_name"];
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  reperesentative:any = {};
  async getrepresentative() {
    var resp = await this.masterDataService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    }
  }
  partyArr:any = [];
  partyObj:any = {};
  async getAllparty() {
    await this.getrepresentative();
    var resp = await this.masterDataService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      this.partyArr = resp.data;

      for (let i = 0; i < this.partyArr.length; i++) {
        this.partyArr[i]["desc"] =
          this.partyArr[i]["rep_le_id"] +
          " - " +
          this.partyArr[i]["party_leagal_name"] +
          " ( " +
          this.reperesentative[this.partyArr[i]["rep_le_id"]] +
          " )";
        this.partyObj[this.partyArr[i]["rep_le_id"]] = this.partyArr[i]["desc"];
      }
    } else {
      this.toastr.error(resp["data"]);
    }
  }

  async delete(element:any) {
    swal
      .fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      })
      .then((result) => {
        if (result.value) {
          this.getAllEmbList(element);
        }
      });
  }

  embdata:any = [];
  embdeleteflag:any;
  selectedTender = {};
  async getAllEmbList(data:any) {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, tender_id: data["tender_id"] };
    console.log(obj, "tender obj");
    var resp = await this.embService.getAllEmbList(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      var respebill = await this.ebillService.getBill(JSON.stringify(obj));
      let count = 0;
      for (let i = 0; i < resp["data"].length; i++) {
        console.log(resp["data"][i]);
        if (resp["data"][i]["status"] == "APPROVED") count++;
        console.log(count);
      }
      let ebillcount = 0;
      for (let i = 0; i < respebill.data.length; i++) {
        if (
          respebill["data"][i]["status"] == "APPROVED" ||
          respebill["data"][i]["status"] == "UNDERAPPROVAL" ||
          respebill["data"][i]["status"] == "REJECTED"
        )
          ebillcount++;
      }
      if (ebillcount > 0) {
        Swal.fire(
          "Warning...",
          "You Can`t Delete This Boq,This Boq Contains Total  " +
          ebillcount +
          " APPROVED/UNDERAPPROVAL EBILL",
          "warning"
        );
      } else if (count > 0) {
        swal
          .fire({
            title:
              "This Boq Contains Total  " +
              count +
              " Approved EMB,Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!",
          })
          .then((result) => {
            if (result.value) {
              this.finaldelete(data);
            }
          });
        Swal.fire(
          "Warning...",
          "This Boq Contains Total  " + count + " Approved EMB",
          "warning"
        );
      } else {
        this.finaldelete(data);
      }
    } else {
      this.spinner.hide();
      swal.fire("Oops", "Error while getting tender information", "error");
    }
  }
  async finaldelete(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["tender_id"] = element["tender_id"];
    this.spinner.show();
    var resp = await this.tenderService.deleteBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      // await this.getAllBOQ();
      this.BOQInfo=[];
      this.work_id=null;
      this.spinner.hide();
      this.toastr.success(resp["data"]);
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }

  changeParty() {
    if (this.obj["party_id"] != undefined) {
      for (let i = 0; i < this.partyArr.length; i++) {
        if (this.partyArr[i]["rep_le_id"] == this.obj["party_id"]) {
          this.obj["party_gstin_no"] = this.partyArr[i]["party_gstin_no"];
          this.obj["party_phone_no"] = this.partyArr[i]["party_phone_no"];
          this.obj["party_email"] = this.partyArr[i]["party_email"];
          this.obj["party_leagal_name"] = this.partyArr[i]["party_leagal_name"];
        }
      }
    } else {
      this.obj["party_gstin_no"] = null;
      this.obj["party_phone_no"] = null;
      this.obj["party_email"] = null;
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }



  async onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data);
      this.setData(data);
      // Data will be logged in array format containing objects
    };
  }


  async onFileChange1(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.setData1(data);
      // Data will be logged in array format containing objects
    };
  }

  async onFileChange2(event: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.setPaymentSchedule(data);
      // Data will be logged in array format containing objects
    };




  }


  setData1(data:any) {
    console.log(data);
    this.total_sum = 0;
    this.table_data = [];

    for (var i = 1; i < data.length; i++) {
      console.log(data[i]);
      var obj:any = new Object();
      obj["s_no"] = i + 1;
      obj["line_group_desc"] = data[i]["2"];
      obj["line_desc"] = data[i]["3"];
      obj["item_code_or_make"] = data[i]["4"];
      if (isNaN(data[i]["5"])) obj["quantity"] = 0;
      else if (data[i]["6"] != undefined) obj["quantity"] = data[i]["5"];
      else obj["quantity"] = 0;
      if (data[i]["6"] != undefined) obj["unit"] = data[i]["6"];
      else obj["unit"] = "NA";
      if (isNaN(data[i]["7"])) obj["rate"] = 0;
      else if (data[i]["7"] != undefined) obj["rate"] = data[i]["7"];
      else obj["rate"] = 0;
      obj["curr"] = data[i]["8"];
      if (data[i]["9"] != undefined) obj["lime_amt_without_tax"] = data[i]["9"];
      else obj["lime_amt_without_tax"] = 0;
      obj["line_amt_in_word"] = data[i]["10"];
      obj["already_measured"] = 0;
      this.table_data.push(obj);
      if (data[i]["9"] != undefined)
        this.total_sum += obj["lime_amt_without_tax"];
    }

    for (let k = 0; k < this.table_data.length; k++) {
      this.table_data[k]["part_rate"] = this.table_data[k]["rate"];
      this.table_data[k]["is_extra_item"] = 0;
    }

    console.log(this.total_sum);
    this.total_sum = Number(this.total_sum.toFixed(2));
    this.datasource2 = new MatTableDataSource(this.table_data);
    this.datasource2.sort = this.sort;
    this.datasource2.paginator = this.paginator;
  }
  allPaymentSed:any = [];
  setPaymentSchedule(data:any) {
    this.allPaymentSed = [];
    let total_of_last_paid = 0;
    let total_sum = 0;
    for (let i = 1; i < data.length; i++) {
      if (isNaN(data[i]['3']) || isNaN(data[i]['4'])) {
        Swal.fire("error", "% values are not in number format...", 'error')
        return;
      }
      total_sum += data[i]['3'];
      total_of_last_paid += data[i]['4'];
    }


    if (total_of_last_paid < 0 || total_of_last_paid > 100 || total_sum != 100) {
      Swal.fire("error", "% values are not in proper way...", 'error')
      return;
    }

    total_sum = 0;
    for (var i = 1; i < data.length; i++) {
      total_sum += data[i]['3']
      var obj:any = new Object();
      obj['sno'] = i;
      obj['payment_line_desc'] = data[i]['2']
      obj['payment_line_per'] = data[i]['3']
      obj['cum_per'] = total_sum;
      obj['payment_last_paid_per'] = data[i]['4']
      this.allPaymentSed.push(obj);
    }
    this.datasource3 = new MatTableDataSource(this.allPaymentSed);
    this.datasource3.sort3 = this.sort;
    this.datasource3.paginator = this.paginator;
  }
  updateobj:any = {};
  open_update_unit(element:any) {
    this.changeWorkInTab3();
    this.l_tender_flag = false;
    this.c_tender_flag = false;
    this.uu_tender_flag = true;
    this.ur_tender_flag = false;
    this.ul_tender_flag = false;

    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
  }
  open_update_rate(element:any) {
    this.updateobj["tender_id"] = element["tender_id"];
    this.updateobj["over_under"] = element["over_under"];
    this.updateobj["over_under_rate"] = element["over_under_per"];
    this.updateobj["discount"] = element["discount"];
    this.updateobj["already_emb"] = element["already_emb"];
    this.l_tender_flag = false;
    this.c_tender_flag = false;
    this.uu_tender_flag = false;
    this.ur_tender_flag = true;
    this.ul_tender_flag = false;
    $('.nav-tabs a[href="#tab-7-4"]').tab("show");
  }
  checkPartRate(element:any, i:any) {

  }
  async UpdateRate() {
    this.spinner.show();
    this.updateobj["b_acct_id"] = this.b_acct_id;
    var resp = await this.tenderService.updaterate(this.updateobj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.getAllBOQ();
      this.toastr.success("BOQ Rate Update Successfully!!");
      this.gotolist();
      this.gotolist();
      $('.nav-tabs a[href="#tab-7-1"]').tab("show");
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }

  export() {
    let ExpData = [
      {
        one: "S No",
        two: "Line Group Description  (Optional)",
        three: "Line Description ",
        four: "item Code/ Make (Optional)",
        five: "Quantity",
        six: "Unit ",
        seven: "Rate ",
        eight: "Currency (Optional)",
        nine: "Line Item  Amount Without Taxes",
        ten: "Line Item Amount In Words (Optional)",
      },
    ];
    var exp = [];
    for (var i = 0; i < ExpData.length; i++) {
      var obj:any = new Object();
      obj["1"] = ExpData[i]["one"];
      obj["2"] = ExpData[i]["two"];
      obj["3"] = ExpData[i]["three"];
      obj["4"] = ExpData[i]["four"];
      obj["5"] = ExpData[i]["five"];
      obj["6"] = ExpData[i]["six"];
      obj["7"] = ExpData[i]["seven"];
      obj["8"] = ExpData[i]["eight"];
      obj["9"] = ExpData[i]["nine"];
      obj["10"] = ExpData[i]["ten"];
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, "boqFormat");
  }
  export1() {
    let ExpData = [{
      'one': 'S No',
      'two': ' Description',
      'three': 'Individual',
      'four': 'Paid Last %age',
    },
    ]
    var exp = []
    for (var i = 0; i < ExpData.length; i++) {
      var obj:any = new Object();
      obj['1'] = ExpData[i]['one']
      obj['2'] = ExpData[i]['two']
      obj['3'] = ExpData[i]['three']
      obj['4'] = ExpData[i]['four']
      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, 'payment_schedule')
  }
  async getAllApprovalStatus() {
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["status"] = ["UNDERAPPROVAL"];
    obj["designation_cd"] = this.ebillUser.role_cd;
    var resp = await this.approveService.getApprovalStatus(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.mainService.taskcount = resp.data.length;
    } else {
    }
  }
  filtered_work_arr:any = [];
  filtered_work_arr_for_epc:any = []
  async reset() {
    this.datasource2 = new MatTableDataSource([]);
    this.datasource3 = new MatTableDataSource([]);
    this.filtered_work_arr = [];
    this.obj = { already_emb: '0' };
    this.table_data = [];
    this.changeproject();
    console.log(this.workArr);
    let used_work = [];
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.tenderService.getAllTender(JSON.stringify(obj));
    console.log(resp, "data");
    for (let i = 0; i < resp["data"].length; i++) {
      used_work.push(resp["data"][i]["work_id"]);
    }
    let dummy = [];
    let EPC_dummy = []
    let GEN_dummy = []
    for (let i = 0; i < this.workArr.length; i++) {
      if (!used_work.includes(this.workArr[i]["id"])) {
        dummy.push(this.workArr[i]);
        if (this.workArr[i]['emb_mode'] == 'GEN') {
          GEN_dummy.push(this.workArr[i]);
        } else {
          EPC_dummy.push(this.workArr[i]);
        }
      }
    }
    this.filtered_work_arr = GEN_dummy
    this.filtered_work_arr_for_epc = EPC_dummy
    console.log(used_work, this.filtered_work_arr, "data to be shown");
  }

  async insertForApproval(doc_local_no:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser["b_acct_id"];
    obj["data"] = this.getApprovalData(doc_local_no);
    var resp = await this.approveService.insertForApproval(obj);
    if (resp["error"] == false) {
    } else {
      this.toastr.error(resp["data"]);
    }
  }

  getApprovalData(doc_local_no:any) {
    var arr = [];
    for (let i = 0; i < this.approvalHierForTENDER.length; i++) {
      var obj:any = new Object();
      obj["level_of_approval"] =
        this.approvalHierForTENDER[i]["level_of_approval"];
      obj["order_of_approval"] =
        this.approvalHierForTENDER[i]["order_of_approval"];
      obj["doc_type"] = "TENDER";
      obj["doc_desc"] = "TENDER";
      if (this.approvalHierForTENDER[i]["order_of_approval"] == "F") {
        obj["status"] = "UNDERAPPROVAL";
      } else {
        obj["status"] = "PENDING";
      }
      obj["doc_local_no"] = doc_local_no;
      obj["create_user_id"] = this.ebillUser.user_id;
      arr.push(obj);
    }

    return arr;
  }

  statusArr:any = [];
  async viewStatus(element:any) {
    var obj:any = new Object();
    obj["doc_local_no"] = element.tender_id;
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["doc_type"] = "TENDER";
    var resp = await this.approveService.getApprbydoclocalno(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      await this.getUsersRoles();
      for (let i = 0; i < resp.data.length; i++) {
        for (let j = 0; j < this.userRoles.length; j++) {
          if (resp.data[i]["user_id"] == this.userRoles[j]["user_id"]) {
            resp.data[i]["name"] = this.userRoles[j]["name"];
            resp.data[i]["role_cd"] = this.userRoles[j]["role_cd"];
          }
        }
      }
      this.statusArr = resp.data;
      $("#myModal1").modal("show");
    } else {
      this.toastr.error(resp["data"]);
    }
  }

  changeoverunder() {
    if (this.obj["over_under"] == "ATPR") {
      this.obj["over_under_rate"] = 0;
    }
  }
  changeoverunderupdate() {
    if (this.updateobj["over_under"] == "ATPR") {
      this.updateobj["over_under_rate"] = 0;
    }
  }

  async submit() {
    this.work_id = this.obj["work_id"];
    await this.getAllBOQ();

    if (this.BoqTable.length > 1) {
      swal.fire("Sorry", "You can not upload more than one boq", "error");
      return;
    }
    this.obj["emb_type"] = "emb_with_ebill";
    this.obj["b_acct_id"] = this.b_acct_id;
    this.obj["create_user_id"] = this.ebillUser.user_id;
    for (var i = 0; i < this.table_data.length; i++) {
      this.table_data[i]["eff_quantity"] =
        this.table_data[i]["quantity"] - this.table_data[i]["already_measured"];
      this.table_data[i]["eff_quantity"] = Number(
        this.table_data[i]["eff_quantity"].toFixed(2)
      );
    }
    this.obj["data"] = this.table_data;
    if (this.obj['emb_mode'] == 'EPC') {
      if (this.allPaymentSed.length != 0) {
        this.obj['allPaymentSed'] = this.allPaymentSed;
        let file_eff_rate=0;
        this.table_data.map((x:any)=>{
          file_eff_rate=file_eff_rate+(x['quantity']*x['rate']);
        })
        this.obj['file_eff_rate'] = file_eff_rate;
      }else{

        this.spinner.hide();
        Swal.fire("error",'Not Fount Any Payment Schedule......','error')
        return;
      }
    }else{
      this.obj['allPaymentSed'] =[];
    }
    this.obj["status"] = "APPROVED";
    if (this.obj["labour_cess_flag"]) this.obj["labour_cess_flag"] = 1;
    else this.obj["labour_cess_flag"] = 0;
    if (this.defected_items.length < 1) {
      var resp = await this.tenderService.createBoq(this.obj);
      if (resp["error"] == false) {
        await this.getAllBOQ();
        this.filtered_work_arr = [];
        this.spinner.hide();
        this.toastr.success("BOQ Added Successfully!!");
        this.gotolist();
        $('.nav-tabs a[href="#tab-7-1"]').tab("show");
      } else {
        this.spinner.hide();
        this.toastr.error(resp["data"]);
      }
    } else {
      this.spinner.hide();
      let s_no = [];
      for (let i = 0; i < this.defected_items.length; i++) {
        s_no.push(this.defected_items[i]["s_no"]);
      }
      let msg =
        "There are Total " +
        this.defected_items.length +
        " Items (Who`s S No. Is '" +
        s_no.join() +
        "')" +
        "  Who`s Rate * Quantity Is Not Eqaul To Total Amount In The Uploaded BOQ";
      if (s_no.length > 0) {
        Swal.fire("Error...", msg, "error");
      }
    }
  }


  async print(element:any) {
    this.spinner.show();
    let work;
    let project;
    console.log(element, this.work_id);
    for (let i = 0; i < this.workArr.length; i++) {
      if (this.work_id == this.workArr[i]["id"]) {
        work =
          this.workArr[i]["work_order_no"] +
          " - " +
          this.workArr[i]["work_order_name"];
      }
    }

    var reqObj = { b_acct_id: this.b_acct_id, tender_id: element.tender_id };
    var data = [];
    var resp = await this.tenderService.getItem(JSON.stringify(reqObj));
    if (resp["error"] == false) {
      data = resp["data"];
    } else {
      swal.fire("Oops", "Error while getting tender items", "error");
    }
    let sum = 0;
    for (let k = 0; k < data.length; k++) {
      sum = data[k]["line_amt"] + sum;
    }
    var txt = "TENDER -";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    var header1 = {
      columns: [
        {
          width: "*",
          text: "Work Name :",
          bold: true,
        },

        {
          width: "*",
          text: work,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    var header2 = {
      columns: [
        {
          width: "*",
          text: "Total Boq Amount without tax:",
          bold: true,
        },

        {
          width: "*",
          text: sum.toFixed(2),
        },
        {
          width: "*",
          text: "",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    var tbl = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [30, 205, 205, 60, 60, 45, 45, 45, 80, 120, 80, 45],
        body: [
          [
            "S NO.",
            "Line Description",
            { text: "item Code/Make", alignment: "left" },
            { text: "Quantity", alignment: "left" },
            { text: "Unit", alignment: "left" },
            "Rate",
            { text: "Currency", alignment: "left" },
            { text: "Line Item Without Taxes", alignment: "left" },
            { text: "Line Item Amount In Words", alignment: "left" },
            { text: "Paid Last Quantity", alignment: "left" },
            { text: "Last Paid Rate", alignment: "left" },
          ],
        ],
      },
    };
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = [];
      arr.push(data[i]["sno"]);

      arr.push(data[i]["item_desc"]);
      arr.push({ text: data[i]["item_cd"], alignment: "right" });
      arr.push({ text: data[i]["quantity"], alignment: "right" });
      arr.push({ text: data[i]["unit"], alignment: "left" });
      arr.push({ text: data[i]["rate"], alignment: "right" });
      arr.push({ text: data[i]["curr"], alignment: "left" });
      arr.push({ text: data[i]["line_amt"], alignment: "right" });
      arr.push({ text: data[i]["line_amt_words"], alignment: "left" });
      arr.push({ text: data[i]["already_measured"], alignment: "right" });
      arr.push({ text: data[i]["part_rate"], alignment: "right" });
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    this.spinner.hide();
    pdfMake.createPdf(dd).download("tender");
  }

  tender_apprdata:any = {};
  async Appr(element:any) {
    this.tender_apprdata = Object.assign({}, element);
    await this.getUsersRoles();
    $("#myModal2").modal("show");
  }
  async saveappr() {
    var obb:any = new Object();
    obb["user_id"] = this.forwarded_user_id;
    obb["remark"] = this.appr_remark;
    obb["b_acct_id"] = this.b_acct_id;
    obb["forwarded_by"] = this.ebillUser.user_id;
    obb["status"] = "PENDING";
    obb["doc_type"] = "TENDER";
    obb["doc_local_no"] = this.tender_apprdata["tender_id"];
    obb["doc_desc"] = "TENDER";
    var resp = await this.approveService.insertForAppr(obb);
    if (resp["error"] == false) {
      await this.update(this.tender_apprdata["tender_id"]);
      await this.getAllApprovalStatus();
      await this.getAllBOQ();
      this.spinner.hide();
      this.toastr.success("Approval Added Successfully!!");
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  async update(id:any) {
    var obb:any = new Object();
    obb["b_acct_id"] = this.ebillUser.b_acct_id;
    obb["tender_id"] = id;
    obb["update_user_id"] = this.ebillUser.user_id;
    obb["status"] = "UNDERAPPROVAL";
    var resp = await this.tenderService.changestatus(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
  }
  appr_remark:any;
  forwarded_user_id:any;
  userRoles = [];
  role_name = [];
  async getUsersRoles() {
    var resp = await this.userService.getUsersRoles(this.b_acct_id);
    var obj = {};

    if (resp["error"] == false) {
      var dt = resp.data;
      for (var i = 0; i < dt.length; i++) {
        dt[i]["desc"] =
          dt[i]["user_id"] + " - " + dt[i]["name"] + " - " + dt[i]["role_cd"];
      }
      this.userRoles = dt;
    } else {
      swal.fire("Oops", "Error while getting all users", "error");
    }
  }
  async printPaymentSchedule(payschudeinfo:any) {
    this.spinner.show();
    let work;
    for (let i = 0; i < this.workArr.length; i++) {
      if (this.work_id == this.workArr[i]["id"]) {
        work =
          this.workArr[i]["work_order_no"] +
          " - " +
          this.workArr[i]["work_order_name"];
      }
    }
    let reqObj = { b_acct_id: this.b_acct_id, tender_id:payschudeinfo["tender_id"] };
    var data = [];
    var resp = await this.tenderService.getPaySchedule(JSON.stringify(reqObj));
    if (resp["error"] == false) {
      this.spinner.hide();
      data = resp["data"];
      if(data.length==0){
      swal.fire("Oops", "There no payment schedule", "error");
      }
      console.log(data)
    } else {
      swal.fire("Oops", "Error while getting payment schedule information", "error");
    }
    let Total_Boq_Amount_without_tax=data[0]["file_eff_rate"];
    let sum = 0;
    for (let k = 0; k < data.length; k++) {
      sum = data[k]["line_amt"] + sum;
    }
    var txt = "Payment Schedule";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    var header1 = {
      columns: [
        {
          width: "*",
          text:"Work Name :",
          bold:true,
        },

        {
          width: "*",
          text: work,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    var header2 = {
      columns: [
        {
          width: "*",
          text: "Total Boq Amount without tax:",
          bold: true,
        },

        {
          width: "*",
          text:Total_Boq_Amount_without_tax,
        },
        {
          width: "*",
          text: "",
          bold: true,
        },

        {
          width: "*",
          text: "",
        },
      ],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    var tbl = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: [30, 285, 250,250,250],
        body: [
          [
            {text:"S NO.",bold:true},
            { text: "Description", alignment: "center" ,bold:true},
            { text: "Individual %", alignment: "center" ,bold:true},
            { text: "Cumulative %", alignment: "center", bold:true},
            { text: "Paid Last % age", alignment: "center" ,bold:true},
          ],
        ],
      },
    };
    dd.content.push(tbl);
    let last_cum=0;
    for (var i = 0; i < data.length; i++) {
      var arr = [];
      arr.push(data[i]["sno"]);

      arr.push({text:data[i]["payment_line_desc"],alignment: "left" });
      arr.push({ text: data[i]["payment_line_per"], alignment: "center" });
      arr.push({ text: last_cum+data[i]["payment_line_per"], alignment: "center" });
      arr.push({ text: data[i]["payment_last_paid_per"], alignment: "center" });
      last_cum=last_cum+data[i]["payment_line_per"];
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    this.spinner.hide();
    pdfMake.createPdf(dd).download("PaymentSChedule");
  }


  lastPayment:any = {};
  async open_updateAllreadyPayment(tender_id:any) {
    // this.spinner.show();
    this.l_tender_flag = false;
    this.c_tender_flag = false;
    this.uu_tender_flag = false;
    this.ur_tender_flag = false;
    this.ul_tender_flag = false;
    this.c_tender_epc_flag = false;
    this.u_paid_last_payment=false;
    this.u_paid_last_payment=true;
    this.update_tender_id = tender_id;
    await this.getDeductions(this.b_acct_id);
    await this.getLastPaymentData(tender_id);


  }
  lastDedArr:any = [];
  async getLastPaymentData(tender_id:any) {
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['tender_id'] = tender_id;
    var resp = await this.ebillService.getLastPaymentAndDeduction(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.lastPayment = resp['data_payment'][0];
      this.lastDedArr = resp['data_ded'];
    } else {
      Swal.fire("Error", "Error while getting getLastPaymentAndDeduction", "error");
    }
  }
  addDeduction() {
    this.lastDedArr.push({ deduction_id: 0, amt: 0 })
  }
  deleteDeduction(i:any) {
    this.lastDedArr.splice(i, 1)
  }
  allDed:any = []
  allDedObj:any = {}
  async getDeductions(b_acct_id:any) {
    var resp = await this.ebillService.getded(b_acct_id);
    if (resp['error'] == false) {
      this.allDed = resp['data'];
      this.allDed.map((x:any) => {
        this.allDedObj[x['deduction_id']] = x
      })
      console.log(this.allDedObj)
    } else {
      Swal.fire("Error", "Error while getting deductions", "error");
    }
  }

  async submitPaymentAndDeduction() {
    this.spinner.show();
    let obj:any = Object.assign({}, this.lastPayment);
    obj['b_acct_id'] = this.b_acct_id;
    obj['ded_data'] = [];
    obj['create_user_id'] = this.ebillUser.user_id;

    this.lastDedArr.map((x:any) => {
      x['rate'] = this.allDedObj[x['deduction_id']]['ded_amt']
      x['apply_on'] = this.allDedObj[x['deduction_id']]['apply_on']
      x['type'] = this.allDedObj[x['deduction_id']]['ded_type']
      x['tender_id'] = obj['tender_id']
      x['create_user_id'] = this.ebillUser.user_id;
      obj['ded_data'].push(x)
    })
    var resp = await this.ebillService.updateLastPaymentAndDeduction(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      Swal.fire("success", "Update Successfully", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting deductions", "error");
    }

  }


}
