<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Property Transfer Managment</div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <!-- <div class="ibox-head"> -->
                <!-- <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'Refund1']}}</div> -->
                <!-- </div> -->
                <div class="ibox-body">

                    <mat-tab-group>
                        <mat-tab label="Appliaction" *ngIf="flag1">
                            <!-- page2 -->
                            <br>
                            <div class="global">
                                <!-- global div start -->
                                <div class="page2">
                                    <!-- {{allOffer|json}} -->
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>Scheme / Offer :*</h6>
                                        </div>
                                        <div class="col-4 text-left">
                                            <ng-select [items]="allOffer" bindLabel="offer_name" bindValue="offer_cd"
                                                [(ngModel)]="Obj['offer_cd']"
                                                placeholder=" Select Scheme / Offer  ">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br><br>
                                    <button class="btn1" mat-raised-button
                                        style="width: 110px;margin-left: 42%; color:green;" (click)="search()">SEARCH</button><br><br>
                                       <br>
                                       <div class="row">
                                        <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                    placeholder="search">
                                            </mat-form-field>
                                        </div>

                                        <!-- <div class="col-4">

                                        </div>
                                        <div class="col-4 text-right">
                                            <button (click)="print1()" class="btn btn-outline-success">Download List</button>
                                        </div> -->


                                    </div>
                                    <!-- tabel -->
                                    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
                                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->


                                        <ng-container matColumnDef="Allotmentid">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Allotment ID </b></th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.allotment_id}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="AllotteeName">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b> Allottee Name </b></th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.party_name}}</td>
                                        </ng-container>


                                        <ng-container matColumnDef="PropertyType">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b> Property Type </b></th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.property_type}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="Property_number">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b> Property Number</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right"> *null </td>
                                        </ng-container>

                                        <ng-container matColumnDef="AppliedDate">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b> Applied Date </b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element"  class="right"> {{element.arr_effective_date | date}}</td>
                                        </ng-container>
                                        <ng-container matColumnDef="Applicaent_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b> Applicant Name</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element"  class="right"> *null</td>
                                        </ng-container>
                                        <ng-container matColumnDef="reason_transfer">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b>Reason </b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right"> *null</td>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><b> Status </b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right"> {{element.arr_type_code}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="Action" >
                                            <th mat-header-cell *matHeaderCellDef class="action" mat-sort-header> <b>Action</b> </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <div class="row mt-2"><button mat-raised-button class="btn btn-sm "
                                                        style="width: 130px; max-width: auto; color:green; background-color:rgb(245, 245, 245);">Accept</button></div>
                                                <div class="row my-1"><button mat-raised-button class="btn btn-sm "
                                                        style="width: 130px; color:red; background-color:rgb(245, 245, 245);">Reject</button></div>
                                                <div class="row my-1"><button mat-raised-button class="btn btn-sm "
                                                        style="width: 140px; color:blue; background-color:rgb(245, 245, 245);;" (click)="viewDetail(element)">View Details</button></div>
                                                <div class="row "><button mat-raised-button class="btn btn-sm "
                                                        style="width: 130px; color:blue; background-color:rgb(245, 245, 245);">View Docs</button></div>
                                                <div class="row my-1"><button mat-raised-button class="btn btn-sm "
                                                        style="width: 130px; color:blue; background-color:rgb(245, 245, 245);"
                                                        (click)="Transfer(element)">Transfer</button></div>
                                            </td>
                                        </ng-container>
                                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>



                                    <!-- mat -->
                                </div>


                                <!-- global div end tg below -->
                            </div>
                        </mat-tab>
                        <mat-tab label="Application Details" *ngIf="flag2">
                            <div class="page7 mt-3">
                                <div class="container page7_1 ">
                                    <div class="row tabel1 mt-3">
                                        <h6>Property Allotment Details:</h6>
                                    </div>
                                    <div class="container">
                                        <div class="row mt-3">
                                            <div class="col text-left">Allotment ID</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.allotment_id}}</div>
                                            <div class="col text-left">Property ID</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.alloted_property_id}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Allottee Name</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_name}}</div>
                                            <div class="col text-left">Property Type</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.property_type}}</div>
                                        </div>
                                         <div class="row mt-3">
                                            <div class="col text-left">Allotment Date</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.arr_effective_date | date}}</div>
                                            <div class="col text-left">Property No.</div>
                                            <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container page7_12 mt-3">

                                    <div class="row tabel1 mt-3">
                                        <h6>Mutation Applicant Details:</h6>
                                    </div>

                                    <div class="container">
                                        <div class="row mt-3">
                                            <div class="col text-left">Reason for Transfer</div>
                                            <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                            <div class="col text-left">Relation (if):</div>
                                            <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Applicant Name</div>
                                            <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                            <div class="col text-left">Father's Name</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_father_or_husband_name}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Gender</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.gender}}</div>
                                            <div class="col text-left">DOB</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_dob | date}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Mobile Number</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_phone_no}}</div>
                                            <div class="col text-left">Email ID</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_email}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Religion</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_religion}}</div>
                                            <div class="col text-left">Qouta</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_quota}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Permanent Address </div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_permanent_addr_line}}
                                            </div>
                                            <div class="col text-left">Sub-Qouta</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_sub_quota}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Aadhar Number </div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_adhar_no}}</div>
                                            <div class="col text-left">PAN Number</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_pan_no}}</div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col text-left">Occupation </div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_occupation}}</div>
                                            <div class="col text-left">Annual Income (Rs)</div>
                                            <div class="col text-left">:&nbsp;&nbsp;{{detail_data.party_annual_income}}</div>
                                        </div>
                                    </div>
                                </div>
                                <button mat-raised-button class="mt-3"
                                    style="width:110px;  margin-left: 45%; color:red; background-color:rgb(254, 255, 255)"
                                    (click)="close()">CLOSE</button>
                            </div>
                        </mat-tab>
                        <mat-tab label="Property Transfer" *ngIf="flag3">
                            <!-- page 8 start -->
                            <br>
                            <div class=" container page8_12">
                                <br>
                                <div class="row tabel1">
                                    <h6>Scheme : &nbsp; UP Awas Vikas Yojna 2022</h6>
                                </div>
                                <div class="tabel">
                                    <div class="row mt-3" >
                                        <div class="col text-left">Allotment ID</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.allotment_id}}</div>
                                        <div class="col text-left">Property ID</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.alloted_property_id}}</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Allottee Name</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.party_name}}</div>
                                        <div class="col text-left">Property Type</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.property_type}}</div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col text-left">Allotment Date</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.arr_effective_date | date }}</div>
                                        <div class="col text-left">Property No</div>
                                        <div class="col text-left">:&nbsp;&nbsp;p1/2254</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Payment Mode</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.payment_option}}</div>
                                        <div class="col text-left">Property Cost (Rs)
                                        </div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.pro_rate}}</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Total Paid Amount (Rs</div>
                                        <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                        <div class="col text-left">Balance Amount (Rs)
                                        </div>
                                        <div class="col text-left">:&nbsp;&nbsp;*null</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Permanent Address </div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.party_permanent_addr_line}}</div>
                                        <div class="col text-left">Sub-Qouta</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.party_sub_quota}}</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Rate of Interest (%) </div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.interest_rate}}</div>
                                        <div class="col text-left">Penal Interest (%)</div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.panel_interest}}</div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-left">Frequency of Installments </div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.frequency_of_installment}}</div>
                                        <div class="col text-left">Total No of Installments </div>
                                        <div class="col text-left">:&nbsp;&nbsp;{{transfer_data.no_of_payment}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="container page8_13 mt-3">
                                <div class="page8">
                                    <div class="row mt-3">
                                        <div class="col-2 text-left">Applicant Name</div>
                                        <div class="col-2 text-left">: New Applicant</div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-2 text-left">Transfer Date:*</div>
                                        <div class="col-2 text-left">
                                            <input type="text" placeholder=" dd/mm/yyyy " class="form-control"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-6 text-left">Is Mutation done with Previous Payment option?*
                                        </div>
                                        <div class="col text-left">
                                            <ng-select [items]="select_Mutation" bindLabel="name" bindValue="id"
                                                [(ngModel)]="selectedMutation" placeholder=" NO  "
                                                (click)="dropdown( selectedMutation )">
                                            </ng-select>
                                        </div>
                                        <div class="col">

                                        </div>
                                        <div class="col">

                                        </div>
                                    </div>
                                </div>
                                <div class="page8_2" *ngIf="flag4">
                                    <div class="row mt-3">
                                        <div class="col text-right">Total Property Cost :</div>
                                        <div class="col text-left"><input type="text" placeholder=" 1,02,221 "
                                                class="form-control" aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default" disabled></div>
                                        <div class="col text-left">New Principal Amount :</div>
                                        <div class="col text-left"><input type="text" placeholder=" 1,02,221 "
                                                class="form-control" aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default" disabled></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-right">No of Installments :*</div>
                                        <div class="col text-left"><input type="text"
                                                placeholder=" New No. of Installments " class="form-control"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default" ></div>
                                        <div class="col text-left">Frequency of Installments:</div>
                                        <div class="col text-left"><input type="text"
                                                placeholder=" Monthly / Quarterly " class="form-control"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default" ></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col text-right">New Rate of Interest (%):*</div>
                                        <div class="col text-left"><input type="text"
                                                placeholder=" * Rate of Interest in %  " class="form-control"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default" ></div>
                                        <div class="col text-left">New Penal Interest (%):*</div>
                                        <div class="col text-left"><input type="text"
                                                placeholder=" Penal Interest in % " class="form-control"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn mt-3">
                                <button mat-raised-button style="width: 110px;   color: red; background-color:rgb(254, 255, 255)"
                                    (click)="cancel()">CANCEL</button>&nbsp;&nbsp;
                                <button mat-raised-button style="width: 110px;   color:blue;  background-color:rgb(254, 255, 255)"
                                    (click)="update()">UPDATE</button>
                            </div>



                        </mat-tab>
                    </mat-tab-group>


                </div>
            </div>
        </div>
    </div>
</div>
