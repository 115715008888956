import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../authentication.service';


@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }
  profileImageUrl:any;
  codeValueTechObj = {};
  accountImageUrl:any;
  accInfo = {}
  codeValueShowObj = {};
  httpUrl = this.auth.httpUrl;


  dateFormatChange(date:any) {
    var datear1 = date.split('T')[0]
    var datearr = datear1.split("-")
    return datearr[2] + '/' + datearr[1] + '/' + datearr[0]
  }

  async getPaymentGatwayData(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/paymentGateway/getPaymentGatwayData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAccountDetails(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getaccountmodule' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createProduct(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/admin/products/createProduct', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getProductList(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/admin/products/getProductList' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async deleteProduct(obj:any) {
    const res = await this.http.delete<any>(this.httpUrl + '/admin/products/deleteProduct' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getProductResource(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/admin/products/getProductResource' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async updateProduct(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/admin/products/updateProduct', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async changeProduct(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/admin/products/changeProduct', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getFields(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/fields/getfields' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getCodeValue(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/metadata/codeValue/getCodeValues' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentLegalEntity(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getcurrentlegalentity' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getResource(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/resource/getcurrentresource' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getresourcebyrolecd(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/resource/getresourcebyrolecd' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getModules(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/resource/getmodules' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async createresource(obj:any) {
    const resp = await this.http.post<any>(this.httpUrl + '/admin/resource/createresource', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateresource(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/admin/resource/updateresource', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteresource(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/admin/resource/deleteresource', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAdminCodeValue(b_acct_id:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/codevalue/getCodeValue' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async createLegalEntity(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/legalentity/createindentity', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async createAccount(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/signup/signup', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async createRole(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/admin/role/createrole', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async updateRole(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/admin/role/updateRole', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async inactiverole(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/admin/role/inactiverole', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async createUser(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/admin/user/createuser', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getCurrentrole(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/role/getcurrentroles' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getEmployee(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/user/getallemployee' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getEmpIdUserId(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/user/getEmpIdUserId' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async inactiveuser(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/admin/user/inactiveuser', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCurrentUser(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl + '/admin/user/getcurrentuser' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAllCurrentAccoutns() {
    const resp = await this.http.get<any>(this.httpUrl + '/systemdata/legalentity/getcurrentAccounts').toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async addUser(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/admin/user/adduser', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async UpdateUserInfo(obj:any) {
    const resp = await this.http.put<any>(this.httpUrl + '/md/user/UpdateuserProfileDetails', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  // new code
  async getAllLanguages(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getAllLanguages' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAllComponent(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getAllComponent' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async createLanguage(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/languages/createLanguage', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async addComponent(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/languages/addComponent', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getcomponentLabelvalueswithLanguage(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/systemdata/languages/getcomponentLabelvalueswithLanguage' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async insertcomponentLabelvaluewithLanguage(obj:any) {
    const res = await this.http.post<any>(this.httpUrl + '/systemdata/languages/insertcomponentLabelvaluewithLanguage1', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


  async updatecomponentLabelvaluewithLanguage(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/systemdata/languages/updatecomponentLabelvaluewithLanguage', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async updateaccountinfo(obj:any) {
    const res = await this.http.put<any>(this.httpUrl + '/systemdata/legalentity/updateaccountinfo', obj).toPromise().then(res => {
      return res;
    });
    return res;
  }


 //*******************emb REPORT*********************************** */

 async getEMBAccountReport() {
  const resp = await this.http.get<any>(this.httpUrl + '/admin/embReport/getEMBAccountReport').toPromise().then(res => {
    return res;
  })

  return resp;

}

async getEMBDaysReportForAnyAccount(b_acct_id:any) {
  const resp = await this.http.get<any>(this.httpUrl + '/admin/embReport/getEMBDaysReportForAnyAccount' + b_acct_id,).toPromise().then(res => {
    return res;
  });
  return resp;
}

async getEMBNthDaysReportForAnyAccount(obj:any) {
  const resp = await this.http.post<any>(this.httpUrl + '/admin/embReport/getEMBNthDaysReportForAnyAccount' , obj ).toPromise().then(res => {
    return res;
  });
  return resp;
}

}


