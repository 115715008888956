import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../md/service/main.service';

@Component({
  selector: 'app-icms-workflow',
  templateUrl: './icms-workflow.component.html',
  styleUrls: ['./icms-workflow.component.css']
})
export class IcmsWorkflowComponent implements OnInit {

  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='ICMS'
  }

}


