

import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmdSettingService {

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/hr/setting";
  }
  //fields
  async getFields(obj :any ){

    const res = await this.http.get<any>(this.main.httpUrl + '/metadata/fields/getfields' + obj).toPromise().then(res => {
      return res;

    });
    return res;

  }
  async createFields(obj :any ){
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/fields/createField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFields(obj :any ){
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/fields/updateField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //codeValue
  async getCodeValue(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getCodeValues' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getFieldCodeValues(obj :any ){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getFieldCodeValues' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertCodeValue(obj :any ){
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/codeValue/createCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateCodeValues(obj :any ){
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/codeValue/updateCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteCodeValue(obj :any ){
    const resp = await this.http.delete<any>(this.main.httpUrl + '/metadata/codeValue/deleteCodeValue' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // *******************************************section map service***************//

  async sectionMap(obj :any ){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/inspection/sectionMap', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSectionMap(obj :any ){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/inspection/getSectionMap' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteSectionMap(obj :any ){
    const resp = await this.http.put<any>(this.main.httpUrl + '/icms/inspection/deleteSectionMap', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  /***************************************************Recovery Calculation****************************************** */

  async getOrderDataOnCaseID(obj :any ){
    let resp = await this.http.post<any>(this.main.httpUrl + '/icms/court-order/getOrderDataOnCaseID', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async insertRecoveryCalculationData(obj :any ){
    let resp = await this.http.post<any>(this.main.httpUrl + '/icms/court-order/insertRecoveryData', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async getDocumentDetails(obj :any ){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getDocumentDetails' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  /***************************************************************Support Department Screen start****************************/

  async createDepartment(obj :any ){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/support-department/createDepartment', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getDepartmentData(obj :any ){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/support-department/getDepartment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async delete(obj :any ){
    const resp = await this.http.delete<any>(this.main.httpUrl + '/icms/support-department/deleteDepartment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateDepartment(obj :any ){
    const resp = await this.http.put<any>(this.main.httpUrl + '/icms/support-department/updateDepartment', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }



}
