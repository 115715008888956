import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { PayrollService } from '../../service/payroll.service';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
import { EstablishService } from '../../service/establish.service';
import { DataAccessService } from '../../service/data-access.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { EstablishmentService } from '../../service/establishment.service';
declare var $: any;
@Component({
  selector: 'app-fixed-pay',
  templateUrl: './fixed-pay.component.html',
  styleUrls: ['./fixed-pay.component.css']
})
export class FixedPayComponent implements OnInit {


  constructor(private dataAccessService: DataAccessService, private settingService: SettingService, public mainService: MainService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private payableService: PayrollService, private estNewService: EstablishService,
    private establishmentService: EstablishmentService) { }
  erpUser: any;
  b_acct_id: any;
  arr_id: any;
  fixedpay: any = []
  allEmplyees: any = [];
  selected: any
  selectedendate: any = 'ALL PERIOD'
  status: any = [{ value: 'Current' }, { value: 'History' }]
  enddate: any = [{ value: 'CUSTOM' }, { value: 'ALL PERIOD' }]
  selectEmpObj: any = {};
  fixedpayObj: any = {};
  codeValueTechObj: any = {};
  allEmplyees_new: any = [];
  dateToday: any = "0000-00-00";
  systemDate: any
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns = ['pay_comp', 'rate', 'pay_code', 'valid_from', 'valid_upto', 'action'];
  datasource: any;


  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.payableService.getSystemDate();
    this.systemDate = resp.data;
    await this.getDate();

    await this.getAllRuleInfo();
    await this.getAllEmployees();
    await this.getAllActiveEmployees()

  }

  // active employee
  // Avinash Active employee
  activeEmpArr: any = [];
  empNameMap: any;
  empServiceDateMap: any;
  newallEmplyees: any
  allCurrentArrangements: any = [];

  async getAllActiveEmployees() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code'] = ['ACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    this.empServiceDateMap = new Map;
    this.empNameMap = new Map;
    if (resp['error'] == false) {
      this.allCurrentArrangements = resp['data'];
      this.allEmplyees = resp['data'];

      this.newallEmplyees = []
      this.activeEmpArr = [];
      for (let i = 0; i < this.allEmplyees.length; i++) {
        var obj :any= new Object();
        obj = Object.assign({}, this.allEmplyees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
        if (obj['emp_status_code'] == "ACTIVE") {
          this.activeEmpArr.push(obj);
        }
      }
      // this.activeEmpArr=[...new Set(this.activeEmpArr)]
      for (let i = 0; i < this.allCurrentArrangements.length; i++) {
        this.empNameMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['emp_name'])
        this.empServiceDateMap.set(this.allCurrentArrangements[i]['emp_id'], this.allCurrentArrangements[i]['joining_service_date']);
      }
    }
  }



  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {

    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    if (this.arr_id == null || this.arr_id == undefined || this.arr_id == '') {
      Swal.fire("Warning", 'Please Select At Least One Employee', 'warning');
      return;
    }
    this.amount = 0;
    this.sep_component_code = '';
    this.fixedpayObj['effective_start_dt'] = '';
    this.fixedpayObj['effective_end_dt'] = ''
    this.selectedendate = 'ALL PERIOD'
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }

  allPayRule :any= [];
  allSepPayRule :any= [];
  allPayNonSepRule :any= [];
  nonSepRuleObj :any= {};

  async getAllRuleInfo() {
    var temp_allSepPayRule :any= [];
    this.allPayNonSepRule = [];
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = ['ACTIVE']
    obj['effective_dt'] = this.dateToday;
    //
    var resp = await this.settingService.getAllSalaryCD(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allPayRule = resp.data;
      for (let i = 0; i < this.allPayRule.length; i++) {
        if (this.allPayRule[i]['valid_from'] <= this.dateToday && this.dateToday < this.allPayRule[i]['valid_upto']) {
          if (this.allPayRule[i]['is_spe_allowd'] == 1) {
            temp_allSepPayRule.push(this.allPayRule[i])
            this.nonSepRuleObj[this.allPayRule[i]['component_code']] = true
          } else {
            this.allPayNonSepRule.push(this.allPayRule[i])
            this.nonSepRuleObj[this.allPayRule[i]['component_code']] = false
          }
        }
      }
      this.allSepPayRule = temp_allSepPayRule
      console.log(this.allSepPayRule)
      console.log(this.allPayNonSepRule)
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  all salary component list ', 'error')
      // this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getDate() {
    var resp = await this.estNewService.getDate();
    if (resp['error'] == false) {
      this.dateToday = resp.data;
      console.log(this.dateToday)
    } else {

    }
  }
  objPayComp :any= {}
  async changeEmployeeOnAddVarPay() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;

    var resp1 = await this.estNewService.getArrangmentAllSalComponent(JSON.stringify(obj));
    console.log(resp1)
    if (resp1['error'] == false) {
      this.objPayComp = {};
      var temp_prevAllSalComp = resp1.data;
      for (let i = 0; i < temp_prevAllSalComp.length; i++) {
        if ((new Date(temp_prevAllSalComp[i]['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(temp_prevAllSalComp[i]['valid_upto']))) {
          this.objPayComp[temp_prevAllSalComp[i]['pay_comp']] = temp_prevAllSalComp[i]['rate']
        }
      }
    }
  }


  amount_disable :any= true;
  SepobjPayCompData :any= {};
  sep_component_code:any;
  async changeSepComp() {
    this.amount_disable = true;
    this.SepobjPayCompData = {};
    for (let i = 0; i < this.allSepPayRule.length; i++) {
      console.log(this.allSepPayRule[i]['component_code'] == this.sep_component_code)

      if (this.allSepPayRule[i]['component_code'] == this.sep_component_code) {
        console.log(this.sep_component_code)
        var amt:any = 0;
        var pay_code = this.allSepPayRule[i].pay_code;
        // var effective_dt = eff_dt;
        if (this.allSepPayRule[i].rate_type == 'CAL') {
          console.log(this.allSepPayRule[i].function_name)
          amt = await this.allSepPayRule[i].function_name;
          console.log(amt)
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        }
        else if (this.allSepPayRule[i].rate_type == 'FIX' && this.allSepPayRule[i].fix_is_var == 1) {
          amt = 0;
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };
          this.amount_disable = false;

        }
        else if (this.allSepPayRule[i].rate_type == 'FIX' && this.allSepPayRule[i].fix_is_var != 1) {
          amt = this.allSepPayRule[i].amount;
          // this.objPayComp[this.allSepPayRule[i].component_code] = amt;
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        } else if (this.allSepPayRule[i].rate_type == 'PERCENTAGE') {
          amt = 0;
          var arr = this.allSepPayRule[i].dependent_component.split(',');
          console.log(arr)
          for (var j = 0; j < arr.length; j++) {
            console.log(this.objPayComp[arr[j]])
            if (this.objPayComp[arr[j]] == undefined) {
              this.objPayComp[arr[j]] = 0;
            }
            amt += this.objPayComp[arr[j]];
            console.log(amt)
          }
          amt = parseFloat((amt * this.allSepPayRule[i].amount / 100).toFixed(2));
          this.SepobjPayCompData[this.allSepPayRule[i].component_code] = { amt: amt, pay_code: this.allSepPayRule[i].pay_code, type: 'fix' };

        }
      }
    }

    var comp = Object.keys(this.SepobjPayCompData);
    this.amount = this.SepobjPayCompData[comp[0]]['amt']
  }
  amount = 0
  async saveSepComp() {
    if (this.sep_component_code == null || this.sep_component_code == undefined || this.sep_component_code == "" ||
      this.fixedpayObj['effective_start_dt'] == null || this.fixedpayObj['effective_start_dt'] == undefined || this.fixedpayObj['effective_start_dt'] == ""
    ) {
      Swal.fire("Warning", "* Field  required", 'warning');
      return;
    }
    else if (this.selectedendate == 'CUSTOM') {
      if (this.fixedpayObj['effective_end_dt'] == null || this.fixedpayObj['effective_end_dt'] == undefined || this.fixedpayObj['effective_end_dt'] == '') {
        Swal.fire("Warning", "* Field  required", 'warning');
        return;
      }
      else {

        this.saveAnyWay();
      }
    }
    else if (this.selectedendate == null) {
      Swal.fire("Warning", "* Field  required", 'warning');
      return;
    }
    else {
      this.saveAnyWay();
    }
    // else if(this.fixedpayObj['effective_start_dt']==null ||this.fixedpayObj['effective_start_dt']==undefined ||this.fixedpayObj['effective_start_dt']=="" ||
    // this.fixedpayObj['effective_end_dt']==null || this.fixedpayObj['effective_end_dt']==undefined || this.fixedpayObj['effective_end_dt']==''   )
    // {
    //   Swal.fire({
    //     title: 'If you not enter the start date and end date then it will automatically start from today ',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, Do it!'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.saveAnyWay()
    //     }
    //   })
    // }

  }
  async saveAnyWay() {
    console.log(this.SepobjPayCompData);

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;
    obj['rate'] = this.amount;
    var comp = Object.keys(this.SepobjPayCompData);
    obj['pay_code'] = this.SepobjPayCompData[comp[0]]['pay_code']
    obj['pay_comp'] = comp[0]
    obj['eff_from'] = this.fixedpayObj['effective_start_dt']
    obj['rate_unit'] = "MNTH";
    obj['curr_cd'] = "INR";
    if (this.selectedendate == 'CUSTOM') {
      obj['effective_end_dt'] = this.fixedpayObj['effective_end_dt'] + 'T23:59:59.000000';
    }
    else {
      obj['effective_end_dt'] = '2037-12-01'
    }


    console.log(obj);
    var final_obj:any = new Object()
    final_obj['b_acct_id'] = this.b_acct_id;
    final_obj['fix_pay'] = obj;

    console.log("Save button obj ", final_obj)
    var resp = await this.estNewService.addVariablePayComp(final_obj);
    console.log(resp)
    if (resp['error'] == false) {
      if (resp['data'] != 'Inserted') {
        Swal.fire("Attenction !", `Your Start-Date must be after ${moment(resp['data'][0]['min_date']).format('DD-MM-YYYY')} and End-Date before ${moment(resp['data'][0]['max_date']).format('DD-MM-YYYY')}, If you want to change then you have to go in update section ! `, 'warning');
        return;
      }
      else {
        await this.changeEmployeeStatus();
        Swal.fire('Success', 'Variable Pay Added Successfully! ', 'success')
        // this.snackBar.open("Variable Pay Added Successfully!", 'Success', {
        //   duration: 5000
        // });
        await this.open_list();
        // await this.changeEmployeeStatus();

      }

    }

  }


  // ----

  his = false
  async changeEmployee() {

    if (this.arr_id == null || this.arr_id == undefined) {
      Swal.fire('Mandatory!', "Select At Least One Employee", 'warning');
      this.datasource = new MatTableDataSource()
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      return;
    }
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;
    this.selected = 'Current';
    await this.changeEmployeeOnAddVarPay();

    var resp1 = await this.estNewService.getArrangmentAllSalComponent(JSON.stringify(obj));
    console.log(resp1)
    if (resp1['error'] == false) {
      this.prevAllSalComp = resp1.data;
      this.allSalComp = []
      console.log(resp1)
      console.log(this.prevAllSalComp);
      // for (let i = 0; i < this.prevAllSalComp.length; i++) {
      //   if ((new Date(this.prevAllSalComp[i]['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(this.prevAllSalComp[i]['valid_upto']))) {
      //    this.his=true;
      //     this.allSalComp.push(this.prevAllSalComp[i])
      //   }
      // }
      let allEmployFirest = this.prevAllSalComp.map((x:any) => {
        if ((new Date(x['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(x['valid_upto'])) && x['status'] == 1) {
          x['his'] = true;
          x['valid_upto'] = 'Till Now'
          this.allSalComp.push(x);
        }
        return x
      })
      console.log("map --", allEmployFirest);
      console.log("push --", this.allSalComp);
      this.datasource = new MatTableDataSource(this.allSalComp)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }
  }
  prevAllSalComp:any = []
  allSalComp :any= []

  async changeEmployeeStatus() {
    this.his = false;
    let history = false;
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;

    var resp1 = await this.estNewService.getArrangmentAllSalComponent(JSON.stringify(obj));
    console.log(resp1)
    if (resp1['error'] == false) {

      console.log("this is selected --", this.selected);
      if (this.selected == 'Current') {
        var arr:any = []
        this.prevAllSalComp = [];
        this.prevAllSalComp = resp1.data;

        console.log(new Date(this.dateToday))
        this.dateToday = this.dateToday.split(" ")[0];
        console.log(this.dateToday)
        // for (let i = 0; i < this.prevAllSalComp.length; i++) {
        let ddd = this.prevAllSalComp.map((x:any) => {
          console.log(new Date(x['valid_from']).getTime())
          console.log(new Date(this.dateToday).getTime())
          if ((new Date(x['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(x['valid_upto']) && new Date(x['valid_upto']) <= new Date('2037-12-01')) && x['status'] == 1) {
            this.his = true;
            x['his'] = this.his;
            x['valid_upto'] = 'Till Now'
            arr.push(x)
          }
          // else{
          //   this.his=false;
          //   x['his']=this.his;

          // }

          return x;
        })
        // }
        // console.log("this is he current map arr--",ddd);
        // console.log("this is he current arr--",arr);
        this.datasource = new MatTableDataSource(arr)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;

      }
      else if (this.selected == 'History') {
        var arr1:any = [];
        this.prevAllSalComp = [];
        this.his = false;
        this.prevAllSalComp = resp1.data;
        console.log("this is the history--", this.prevAllSalComp);
        for (let i = 0; i < this.prevAllSalComp.length; i++) {
          if (this.prevAllSalComp[i]['valid_upto'] != '2037-12-01') {
            this.objPayComp[this.prevAllSalComp[i]['pay_comp']] = this.prevAllSalComp[i]['rate']
          }
        }
        // for (let i = 0; i < this.fixedpay.length; i++) {
        //   this.fixedpay[i]['tempeffective_start_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_start_dt'])
        //   this.fixedpay[i]['tempeffective_end_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_end_dt'])

        let dataavi = this.prevAllSalComp.map((x:any) => {
          if ((new Date(x['valid_from']) <= new Date(this.dateToday)) && (new Date(this.dateToday) <= new Date(x['valid_upto'])) && x['valid_upto'] <= '2037-12-01') {
            if (x['status'] == 0) {
              x['valid_upto'] = 'Close'
            }
            else {
              x['valid_upto'] = 'Till Now'
            }
            this.his = true
            x['his'] = this.his;
            arr1.push(x)
          }
          else {
            this.his = false;
            x['valid_upto'] = 'Close'
            x['his'] = this.his;
            arr1.push(x)
          }
          return x
        })
        console.log("this is map ", dataavi);
        console.log("this is map arr1--", arr1);
        // }
        this.datasource = new MatTableDataSource(this.prevAllSalComp)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }
    } else {

    }

  }



















  ////***********************************************old code*************************************************88



  async DependentCompFunction(data_obj:any) {
    //****************All Component********************* */
    var return_arr:any = []
    var allComp:any = this.mainService.codeValueTechObj['HR0021']
    var ComponentObj:any = {};

    for (let i = 0; i < allComp.length; i++) {
      ComponentObj[allComp[i]['code']] = [];
    }
    var GRADEPAY_Rule = [];


    //*****************All Dependent Component******************** */
    var allSCD = []
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['effective_dt'] = '2090-10-10';
    obj['status'] = ['ACTIVE', 'INACTIVE'];
    var resp = await this.settingService.getAllSalaryCD(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      allSCD = resp.data;
      for (let i = 0; i < allSCD.length; i++) {
        if (allSCD[i]['dependent_component'] != 'GRADEPAY') {
          ComponentObj[allSCD[i]['dependent_component']].push(allSCD[i])
        } else {
          GRADEPAY_Rule.push(allSCD[i])
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while getting  all salary component list', 'error')
      // this.snackBar.open("Error while getting  all salary component list", 'Error', {
      //   duration: 5000
      // });
    }
    console.log(ComponentObj);
    console.log(data_obj)
    console.log(data_obj['pay_component_code'])
    var rules = ComponentObj[data_obj['pay_component_code']];
    console.log(rules)

    for (let i = 0; i < rules.length; i++) {
      var obj1:any = new Object();
      obj1['pay_component_code'] = rules[i]['component_code'];
      obj1['effective_start_dt'] = data_obj['effective_start_dt'];
      obj1['effective_end_dt'] = data_obj['effective_end_dt'];
      obj1['b_acct_id'] = data_obj['b_acct_id'];
      obj1['emp_id'] = data_obj['emp_id'];
      obj1['pay_code'] = rules[i]['pay_code']
      obj1['create_user_id'] = this.erpUser.user_id;

      if (rules[i]['rate_type'] == "PERCENTAGE") {
        obj1['pay_component_amt'] = (data_obj['pay_component_amt'] * rules[i]['amount']) / 100;
      } else {
        obj1['pay_component_amt'] = rules[i]['amount'];
      }


      return_arr.push(obj1)
    }

    //******************* All Grade Pay Matrix****************** */
    var allMatrix = []
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      allMatrix = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  all matrix list', 'error')
      // this.snackBar.open("Error while getting  all matrix list", 'Error', {
      //   duration: 5000
      // });
    }
    //******************* Get Grade Pay on the BASIC PAY****************** */

    var greade_pay;
    if (data_obj['pay_component_code'] == 'BASIC') {

      for (let i = 0; i < allMatrix.length; i++) {
        if (allMatrix[i]['basic_pay'] <= data_obj['pay_component_amt']) {
          greade_pay = allMatrix[i]['grade_pay_code']
        }
      }

      for (let i = 0; i < GRADEPAY_Rule.length; i++) {
        if (GRADEPAY_Rule[i]['lower_limit'] <= greade_pay && greade_pay <= GRADEPAY_Rule[i]['upper_limit']) {
          var obj1 :any= new Object();
          obj1['pay_component_code'] = GRADEPAY_Rule[i]['component_code'];
          obj1['effective_start_dt'] = data_obj['effective_start_dt'];
          obj1['effective_end_dt'] = data_obj['effective_end_dt'];
          obj1['b_acct_id'] = data_obj['b_acct_id'];
          obj1['emp_id'] = data_obj['emp_id'];
          obj1['pay_code'] = GRADEPAY_Rule[i]['pay_code'];
          obj1['create_user_id'] = this.erpUser.user_id;

          if (GRADEPAY_Rule[i]['rate_type'] == "PERCENTAGE") {
            obj1['pay_component_amt'] = (data_obj['pay_component_amt'] * GRADEPAY_Rule[i]['amount']) / 100;
          } else {
            obj1['pay_component_amt'] = GRADEPAY_Rule[i]['amount'];
          }

          return_arr.push(obj1);
        }
      }
    }

    return_arr.push(data_obj);
    console.log(return_arr);
    return return_arr;

  }
  Arr = [];

  async submitFixedPay() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    obj['pay_component_amt'] = this.fixedpayObj['pay_component_amt']
    obj['pay_code'] = this.fixedpayObj['pay_code']
    obj['pay_component_code'] = this.fixedpayObj['pay_component_code']
    obj['effective_start_dt'] = this.fixedpayObj['effective_start_dt']
    obj['create_user_id'] = this.erpUser.user_id;
    if (this.selectedendate == 'CUSTOM') {
      obj['effective_end_dt'] = this.fixedpayObj['effective_end_dt']
    }
    else {
      obj['effective_end_dt'] = '2090-10-10'
    }
    console.log(obj)
    this.Arr = await this.DependentCompFunction(obj)
    console.log(this.Arr)
    $('#myModal').modal('show');
  }
  deleteArr(i:any) {
    this.Arr.splice(i, 1);
  }

  async addEmpFixPay() {
    var obj1 :any= new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['fixed_pay_info'] = this.Arr;
    obj1['end_dt'] = '2090-10-10';
    console.log(obj1)
    var resp = await this.settingService.addFixedPay(obj1);
    if (resp['error'] == false) {
      $('#myModal').modal('hide');
      await this.changeEmployeeempid();
      Swal.fire('Success', ' Fixed Pay Added Successfully!', 'success')
      // this.snackBar.open("Fixed Pay Added Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      $('#myModal').modal('hide')
      this.spinner.hide();
      Swal.fire('Error', 'Error while Adding Fixed Pay  Of Employee ', 'error')
      // this.snackBar.open("Error while Adding Fixed Pay  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }

  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  empObj:any = {};
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;


    //=----------------------- for getting all the employ in the select employee ----------------

    var resp = await this.payableService.getEmployeeMasterData(obj);

    // for getting the active employee only
    console.log(obj, "shweta")
    // var resp = await this.payableService.get1EmployeeMasterData(obj);

    //  var resp = await this.payableService.getEmployeeMasterData(obj);

    // for getting the active employee only
    // var resp = await this.payableService.get1EmployeeMasterData(obj);
    //console.log("this is active employee--",resp)

    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      for (let i = 0; i < arr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = obj['emp_id']
        this.allEmplyees.push(obj)
      }
      this.allEmplyees_new = [];
      for (let i = 0; i < resp.data.length; i++) {
        var obj: any = new Object();
        obj = Object.assign(resp.data[i]);
        obj['emp_name'] = obj['emp_id'] + "-" + obj['emp_name']
        this.empObj[obj['emp_id']] = obj['emp_name'];

        this.allEmplyees_new.push(obj)
      }
      this.allEmplyees_new = await this.dataAccessService.filterEmployeeData(this.allEmplyees_new)

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async changeEmployeeempid() {

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    this.selected = 'Current';
    var resp = await this.payableService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.fixedpay = resp.data

      var arr = []
      for (let i = 0; i < this.fixedpay.length; i++) {
        this.fixedpay[i]['tempeffective_start_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_start_dt'])
        this.fixedpay[i]['tempeffective_end_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_end_dt'])

        var obj: any = new Object();
        obj = Object.assign(this.fixedpay[i]);

        if (obj['effective_end_dt'] >= this.systemDate) {
          arr.push(obj)
        }

      }
      this.datasource = new MatTableDataSource(arr)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;


    } else {


      this.spinner.hide();
      Swal.fire('Error', 'Error while getting employee all Fixed Pay list ', 'error')
      // this.snackBar.open("Error while getting employee all Fixed Pay list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async changeEmployee1() {

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    var resp = await this.payableService.getAllFixedPay(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.fixedpay = resp.data
      if (this.selected == 'Current') {
        var arr = []
        for (let i = 0; i < this.fixedpay.length; i++) {
          this.fixedpay[i]['tempeffective_start_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_start_dt'])
          this.fixedpay[i]['tempeffective_end_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_end_dt'])

          var obj: any = new Object();
          obj = Object.assign(this.fixedpay[i]);
          if (obj['effective_end_dt'] >= this.systemDate) {
            arr.push(obj)
          }

        }
        this.datasource = new MatTableDataSource(arr)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;

      }
      else if (this.selected == 'History') {
        for (let i = 0; i < this.fixedpay.length; i++) {
          this.fixedpay[i]['tempeffective_start_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_start_dt'])
          this.fixedpay[i]['tempeffective_end_dt'] = this.mainService.dateformatchange(this.fixedpay[i]['effective_end_dt'])


        }
        this.datasource = new MatTableDataSource(this.fixedpay)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      }
    } else {


      this.spinner.hide();
      Swal.fire('Error', 'Error while getting employee all Fixed Pay list ', 'error')
      // this.snackBar.open("Error while getting employee all Fixed Pay list", 'Error', {
      //   duration: 5000
      // });
    }
  }


  async deleteVariablepay(element: any) {

    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element['id'];
    var resp = await this.estNewService.deleteVariablePayComp(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();

      await this.changeEmployeeStatus()
      Swal.fire('Success', 'Deleted Successfully ', 'success')
      // this.snackBar.open("Deleted Successfully", 'Success', {
      //   duration: 5000
      // });


    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while getting employee all Fixed Pay list ', 'error')
      // this.snackBar.open("Error while getting employee all Fixed Pay list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async updateFixPay1() {

    var obj = Object.assign({}, this.fixedpayObj);
    obj['update_user_id'] = this.erpUser.user_id;
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.selectEmpObj['arr_id'];
    obj['rate'] = this.amount;
    obj['pay_comp'] = this.sep_component_code
    if (this.selectedendate == 'CUSTOM') {
      obj['valid_upto'] = this.fixedpayObj['valid_upto'];
    }
    else {
      obj['valid_upto'] = '2037-12-01'
    }
    if (obj['valid_upto'] > this.end_date) {
      console.log("welcome")
      // var obj :any= new Object();
      // obj['b_acct_id'] = this.b_acct_id;
      //  obj['arr_id'] = this.arr_id;
      //  obj['rate'] =this.amount;
      var comp = Object.keys(this.SepobjPayCompData);
      obj['pay_code'] = this.SepobjPayCompData[comp[0]]['pay_code']
      //  obj['pay_comp'] = comp[0]
      obj['eff_from'] = this.fixedpayObj['valid_from']
      obj['rate_unit'] = "MNTH";
      obj['curr_cd'] = "INR";
      if (this.selectedendate == 'CUSTOM') {
        obj['effective_end_dt'] = this.fixedpayObj['valid_upto'];
      }
      else {
        obj['effective_end_dt'] = '2037-12-01'
      }


      console.log(obj);
      var final_obj :any= new Object()
      // final_obj['b_acct_id'] = this.b_acct_id;
      final_obj['fix_pay'] = obj;

      console.log(final_obj)
      var resp = await this.estNewService.addVariablePayComp1(final_obj);
      console.log(resp)
      if (resp['error'] == false) {
        await this.changeEmployeeStatus();
        Swal.fire('Success', 'Variable Pay Added Successfully!', 'success')
        // this.snackBar.open("Variable Pay Added Successfully!", 'Success', {
        //   duration: 5000
        // });
        await this.open_list();
        // await this.changeEmployeeStatus();

      }





    }


    //obj['rat1']=0;
    //obj['end_date']='2037-12-01'
    console.log(obj);


    //  this.spinner.show();
    var resp = await this.estNewService.updateVariablePayComp(obj);
    console.log(resp)
    if (resp['error'] == false) {
      //  await this.changeEmployeeempid()
      this.fixedpayObj = {}


      await this.changeEmployeeStatus()

      this.spinner.hide();
      Swal.fire('Success', 'Updated Fixed Pay  Of Employee ', 'success')
      // this.snackBar.open("Updated Fixed Pay  Of Employee", 'Success', {
      //   duration: 5000
      // });
      await this.open_list()
      // await this.changeEmployeeStatus()

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while Updating Fixed Pay  Of Employee ', 'error')
      // this.snackBar.open("Error while Updating Fixed Pay  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }

  }

  async updateFixPay() {
    //     if(this.fixedpayObj['valid_from']==null || this.fixedpayObj['valid_from']==undefined || this.fixedpayObj['valid_from']=='')
    //     {
    // Swal.fire("Warning","* field require",'warning')
    // return;
    //     }
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['arr_id'] = this.arr_id;
    obj['rate'] = this.amount;
    obj['pay_id'] = this.pay_id
    var comp = Object.keys(this.SepobjPayCompData);
    obj['pay_code'] = this.SepobjPayCompData[comp[0]]['pay_code']
    obj['pay_comp'] = comp[0]
    obj['valid_from'] = this.fixedpayObj['valid_from']
    obj['rate_unit'] = "MNTH";
    obj['curr_cd'] = "INR";
    if (this.selectedendate == 'CUSTOM') {
      obj['valid_upto'] = this.fixedpayObj['valid_upto'] + 'T23:59:59.000000';
    }
    else {
      obj['valid_upto'] = '2037-12-01'
    }


    console.log(obj);
    var final_obj:any = new Object()
    final_obj['b_acct_id'] = this.b_acct_id;
    final_obj['fix_pay'] = obj;

    console.log(final_obj)
    var resp = await this.estNewService.addVariablePayComp1(final_obj);
    console.log(resp)
    if (resp['error'] == false) {
      await this.changeEmployeeStatus();
      Swal.fire('Success', 'Variable Pay Added Successfully! ', 'success')
      // this.snackBar.open("Variable Pay Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      await this.open_list();
      // await this.changeEmployeeStatus();

    }
  }


  openUpdate1(element: any) {
    console.log(element);


    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;

    $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.fixedpayObj = Object.assign({}, element);
    if (this.fixedpayObj['effective_end_dt'] == '2037-12-01') {
      this.selectedendate = 'ALL PERIOD';
    } else {
      this.selectedendate = 'CUSTOM';
    }

  }
  end_date:any
  pay_id:any
  async openUpdate(element: any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.fixedpayObj = Object.assign({}, element);
    console.log(element)
    this.pay_id = element.pay_id

    if (this.fixedpayObj['valid_upto'] == '2037-12-01') {
      this.selectedendate = 'ALL PERIOD';
    } else {
      this.selectedendate = 'CUSTOM';
    }
    this.selectEmpObj['arr_id'] = this.arr_id
    await this.changeEmployeeOnAddVarPay()
    //  this.amount=this.fixedpayObj['rate'];
    this.sep_component_code = this.fixedpayObj['pay_comp']
    await this.changeSepComp();


    console.log(this.fixedpayObj)




  }



  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }


}
