import { Component } from "@angular/core";
import { MainService } from "../../service/main.service";
import { ProfileService } from "../../../portal/service/profile.service";
import { AuthenticationService } from "../../../authentication.service";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonDataService } from "../../common _services/common-data.service";

@Component({
  selector: "[app-emb-sidebar]",
  templateUrl: "./emb-sidebar.component.html",
  styleUrls: ["./emb-sidebar.component.css"],
})
export class EmbSidebarComponent {
  
  sideBarFlag:any={'BOQ-R2':true,'EMBREPORT-R20':true,'EMB-R3':true,'EBILL-R4':true,'DEVIATION-R5':true,'Completion-R6':true,
  'TERM-R7':true,'CODE-VALUE-R8':true,'Vendors-R9':true,'Deduction-R12':true,'Geometry-R13':true,'Budget-R19':true,'Work-R11':true,
  'DEFAPPR-R21':true,'Users-R14':true,'undertaking':true,'Roles-R15':true,'EMBHIER-R22':true,'Authorization-R16':true
  ,'sor-R24':true,"morth-R26":true,"dsr-R25":true}
  
  user_id:any;
  imgURL:any;
  erpUser:any;
  accInfo:any;
  userInfo = { emp_name: "Ram Prasad", designation: "Accountant" };
  constructor(
    public auth: AuthenticationService,
    private CommonDataService: CommonDataService,
    private router: Router,
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
    public mainService: MainService
  ) {
    this.erpUser =   JSON.parse(atob(localStorage.getItem('erpUser')as any));
      ///****************************************** */
      this.sideBarFlag['BOQ-R2']=!this.erpUser['assigned_component'].includes('BOQ-R2')
      this.sideBarFlag['EMBREPORT-R20']=!this.erpUser['assigned_component'].includes('EMBREPORT-R20')
      this.sideBarFlag['EMB-R3']=!this.erpUser['assigned_component'].includes('EMB-R3')
      this.sideBarFlag['EBILL-R4']=!this.erpUser['assigned_component'].includes('EBILL-R4')
      this.sideBarFlag['DEVIATION-R5']=!this.erpUser['assigned_component'].includes('DEVIATION-R5')
      this.sideBarFlag['Completion-R6']=!this.erpUser['assigned_component'].includes('Completion-R6')
      this.sideBarFlag['TERM-R7']=!this.erpUser['assigned_component'].includes('TERM-R7')
      this.sideBarFlag['CODE-VALUE-R8']=!this.erpUser['assigned_component'].includes('CODE-VALUE-R8')
      this.sideBarFlag['Vendors-R9']=!this.erpUser['assigned_component'].includes('Vendors-R9')
      this.sideBarFlag['Deduction-R12']=!this.erpUser['assigned_component'].includes('Deduction-R12')
      this.sideBarFlag['Geometry-R13']=!this.erpUser['assigned_component'].includes('Geometry-R13')
      this.sideBarFlag['Budget-R19']=!this.erpUser['assigned_component'].includes('Budget-R19')
      this.sideBarFlag['Work-R11']=!this.erpUser['assigned_component'].includes('Work-R11')
      this.sideBarFlag['DEFAPPR-R21']=!(this.erpUser['assigned_component'].includes('DEFAPPR-R21') && this.erpUser['role_cd'].includes('AA') ) //workflow
      this.sideBarFlag['Users-R14']=!(this.erpUser['assigned_component'].includes('Users-R14') && this.erpUser['role_cd'].includes('AA') ) //Create New-Role/Update-Role 
      this.sideBarFlag['undertaking']=!(this.erpUser['role_cd'].includes('AA') ) //Udertaking 
      this.sideBarFlag['Roles-R15']=!this.erpUser['assigned_component'].includes('Roles-R15')
      this.sideBarFlag['EMBHIER-R22']=!this.erpUser['assigned_component'].includes('EMBHIER-R22')
      this.sideBarFlag['Authorization-R16']=!this.erpUser['assigned_component'].includes('Authorization-R16')
      this.sideBarFlag['sor-R24']=!this.erpUser['assigned_component'].includes('sor-R24')
      this.sideBarFlag['morth-R26']=!this.erpUser['assigned_component'].includes('morth-R26')
      this.sideBarFlag['dsr-R25']=!this.erpUser['assigned_component'].includes('dsr-R25')
      this.sideBarFlag['Estimate-R21']=!this.erpUser['assigned_component'].includes('Estimate-R21')
      this.sideBarFlag['Inception-R20']=!this.erpUser['assigned_component'].includes('Inception-R20')
      this.sideBarFlag['Inception-R20']=!this.erpUser['assigned_component'].includes('Inception-R20')
      this.sideBarFlag['Estimation-Master-data-R22']=!this.erpUser['assigned_component'].includes('Estimation-Master-data-R22')
  }
  hidden = true;
  b_acct_id:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser["b_acct_id"];
    console.log(this.erpUser);
    this.user_id = this.erpUser.user_id;
    if (this.erpUser.is_admin == 0) {
      this.hidden = true;
    } else {
      this.hidden = false;
    }
    this.imgURL = "./assets/img/admin-avatar.png";
    await this.getAccImage();
  }
  home() {
    this.router.navigate(["/index"]);
  }
  async refreshAssignedData() {
    await this.CommonDataService.getWorkOrderTOWorkIdInfo(this.b_acct_id);
    await this.CommonDataService.getAssignedWorkInfo(
      this.b_acct_id,
      this.erpUser["user_id"]
    );
    await this.CommonDataService.getAllRolesWIthResource(this.b_acct_id);
  }

  async getAccImage() {
    const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.accountImageUrl = this.imgURL;
      
    }
  }
  
}
