<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">PAY COMPONENT</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                All Pay Component List</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort style="width: 100%;">
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Value

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.value }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Status

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.status }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="activity_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Activity Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.activity_code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button type="button" class="btn btn-success" data-toggle="modal" (click)="open_hier(element)">SET</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>


<div class="modal" id="myModal4">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">Activity Hierarchy
                </h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!--Modal Body-->
            <div class="modal-body">
                <div class="row">

                    <div class="col-3 text-center">

                    </div>
                    <div class="col-3 text-center" style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b> Code </b></h6>

                    </div>
                    <div class="col-3 text-center" style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                        <h6><b>Value </b></h6>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6>Level 1</h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl1_type']!='text'">
                        <input type="text" class="form-control" [(ngModel)]="obj['lvl1_cd']" disabled>

                    </div>
                    <div class="col-3" *ngIf="obj['lvl1_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl1_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl1_type']!='text'">
                        <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd" (change)="onChangeLvl1()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl1_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl1_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl1_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl1_value']">

                    </div>

                </div>
                <br>

                <div class="row">
                    <div class="col-3 text-right">
                        <h6> Level 2</h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl2_type']!='text'">
                        <input type="text" class="form-control" [(ngModel)]="obj['lvl2_cd']" disabled>

                    </div>
                    <div class="col-3" *ngIf="obj['lvl2_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl2_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl2_type']!='text'">
                        <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd" (change)="onChangeLvl2()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl2_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl2_value">
                        </ng-select>
                    </div>

                    <div class="col-3" *ngIf="obj['lvl2_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl2_value']">

                    </div>
                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6>Level 3 </h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl3_type']!='text'">

                        <input type="text" class="form-control" [(ngModel)]="obj['lvl3_cd']" disabled>

                    </div>
                    <div class="col-3" *ngIf="obj['lvl3_type']=='text'">

                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl3_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl3_type']!='text'">
                        <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd" (change)="onChangeLvl3()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl3_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl3_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl3_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl3_value']">

                    </div>
                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6>Level 4</h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl4_type']!='text'">
                        <input type="text" class="form-control" [(ngModel)]="obj['lvl4_cd']" disabled>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl4_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl4_cd']">
                    </div>

                    <div class="col-3" *ngIf="obj['lvl4_type']!='text'">
                        <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd" (change)="onChangeLvl4()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl4_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl4_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl4_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl4_value']">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3 text-right">
                        <h6> Level 5 </h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl5_type']!='text'">

                        <input type="text" class="form-control" [(ngModel)]="obj['lvl5_cd']" disabled>

                    </div>
                    <div class="col-3" *ngIf="obj['lvl5_type']=='text'">

                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl5_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl5_type']!='text'">

                        <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd" (change)="onChangeLvl5()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl5_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl5_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl5_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl5_value']">

                    </div>
                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6>Level 6 </h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl6_type']!='text'">

                        <input type="text" class="form-control" [(ngModel)]="obj['lvl6_cd']" disabled>

                    </div>

                    <div class="col-3" *ngIf="obj['lvl6_type']=='text'">

                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl6_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl6_type']!='text'">

                        <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd" (change)="onChangeLvl6()" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl6_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl6_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl6_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl6_value']">

                    </div>
                </div>
                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Level 7</h6>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl7_type']!='text'">

                        <input type="text" class="form-control" [(ngModel)]="obj['lvl7_cd']" disabled>

                    </div>
                    <div class="col-3" *ngIf="obj['lvl7_type']=='text'">

                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl7_cd']">

                    </div>
                    <div class="col-3" *ngIf="obj['lvl7_type']!='text'">

                        <ng-select [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false" placeholder="" [(ngModel)]="obj['lvl7_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" (change)="onChangeLvl7()" name="lvl7_value">
                        </ng-select>
                    </div>
                    <div class="col-3" *ngIf="obj['lvl7_type']=='text'">
                        <input type="text" class="form-control" (change)="makingLeafValues()" [(ngModel)]="obj['lvl7_value']">
                    </div>
                </div>

                <br>

                <div class="row">

                    <div class="col-3 text-right">
                        <h6> Leaf :</h6>
                    </div>
                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="obj['leaf_cd']">
                    </div>

                    <div class="col-3">
                        <input type="text" class="form-control" [(ngModel)]="obj['leaf_value']">
                    </div>
                </div>
                <br>


                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" (click)="submitHier()">Submit</button>

                    </div>
                </div>
                <br>
            </div>
            <!--Modal Footer-->
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
