import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class PropCostingService {


  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }
  async  getPropertyType(obj:any){
    const resp = await this.http.post(this.httpUrl+'/property/propertyTypeInfo/getAllpropertyGroupData' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async  getFeatureType(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/propertyTypeInfo/getForFeatureData' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getPropertyTypeCost(obj:any){
    const resp = await this.http.post(this.httpUrl+'/property/propertyTypeInfo/getPropertyTypeCost' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createPropertyCosting(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/propertyTypeInfo/createPropertyTypeCost', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async createFeatureCosting(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/propertyTypeInfo/createFetureTypeCost', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async createLocation(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/location/createlocation', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getLocationData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/location/getlocationdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getAllGisLocation(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/location/getlocationCoordinates' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getLocationDoc(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/location/getlocationdoc' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateLocation(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/location/updatelocation' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async updatePropertyCost(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/propertyTypeInfo/updatePropertyCost' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async deleteLocation(obj:any){
    const resp = await this.http.delete<any>(this.main.httpUrl + '/property/location/deletelocation' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async inactivePropertyTypeCost(obj:any){
  //   const resp = await this.http.delete<any>(this.main.httpUrl + '/property/propertyTypeInfo/inactivePropertyTypeCost' + obj).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }

  async inactivePropertyTypeCost(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/propertyTypeInfo/inactivePropertyTypeCost' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async createPaymentOption(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/paymentOption/createPaymentOption', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async createPaymentOptionAuction(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/property/paymentOption/createPaymentOptionAuction', obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  getPaymentOption(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/paymentOption/getPaymentOption/' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getPaymentOptionaAuction(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/paymentOption/getPaymentOptionAuction/' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getPaymentOptionList(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/paymentOption/getforapaymentData' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getPaymentOptionAuctionList(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/paymentOption/getforapaymentAuctionData' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updatePaymentOption(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/paymentOption/updatePaymentOption' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async updatePaymentOptionAuction(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/paymentOption/updatePaymentOptionAuction' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async inactivePaymentOption(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/paymentOption/inactivePaymentOption' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async inactivePaymentOptionAuction(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/paymentOption/inactivePaymentOptionAuction' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  // feature

  async createFeature(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/feature/createFeature', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async  getFeatures(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/feature/getFeature' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateFeature(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/feature/updateFeature' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async deleteFeature(obj:any){
    const resp = this.http.put<any>(this.main.httpUrl + '/property/feature/inactiveFeature' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  async  getFeaturesExceptGiven(obj:any){
    const resp = await this.http.get<any>(this.httpUrl+'/property/feature/allfeatureExceptGivenType' +obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
