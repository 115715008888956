import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { SettingService } from '../../../service/setting.service'
import { MainService } from '../../../service/main.service';
import { LedgerService } from '../../../service/ledger.service'
import Swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ExcelService } from '../../../service/file-export.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-tds',
  templateUrl: './tds.component.html',
  styleUrls: ['./tds.component.css']
})
export class TdsComponent implements OnInit {

  erpUser:any;
  b_acct_id:any;
  allParty:any = []
  obj :any= {}
  constructor(private ChartOfAccountService: ChartOfAccountService, public mainService: MainService, private excl: ExcelService, private ledgerService: LedgerService, private spinner: NgxSpinnerService, private settingService: SettingService) { }
  acount_name = ''
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.acount_name = this.mainService.accInfo['account_name']
    await this.getrepresentative();
    await this.getparty();
  }

  reperesentative:any = {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }


  all_party:any = [];
  partyNameObj:any = {};
  partyPANObj:any = {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      this.partyNameObj = {};
      this.partyPANObj = {};
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.partyNameObj[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
        this.partyPANObj[resp['data'][i]['le_id']] = resp['data'][i]['party_pan_no']
      }
      this.all_party = [{ le_id: null, party_leagal_name: 'All' }];
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
      this.obj['party_type']
    }
  }


  // async check_validation(){
  //   if(!this.obj['from_dt']){
  //     Swal.fire("ERROR","Please Select From Date",'error');
  //     this.spinner.hide();
  //     return;
  //   }
  //   if(!this.obj['to_dt']){
  //     Swal.fire("ERROR","Please Select To Date",'error');
  //     this.spinner.hide();
  //     return;
  //   }
  // }
  reportdata:any = []
  // tdsPercent=0;
  async getreportoutput() {
    if(this.obj['from_dt'] > this.obj['to_dt']){
      return;
    }
    // await this.check_validation();
    this.obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getTDSDeduction(JSON.stringify(this.obj))
    if (resp['error'] == false) {
      var arr = resp['data']
      console.log("Raw data", arr)
      this.reportdata = [];
      arr.map((x:any) => {
        let gst_line:any = new Object();
        gst_line['party_name'] = x['cp_nm']
        gst_line['pan'] = this.partyPANObj[x['cp_num_in_src']]
        gst_line['amount'] = x['ev_grp_amt']
        gst_line['deduction_amount'] = x['ev_ln_amt']
        gst_line['ded_code'] = x['ev_ln_id']
        gst_line['ded_date'] = x['update_timestamp'].split("T")[0]
        gst_line['it_act'] = x['ev_ln_cmnt'];
        gst_line['rate'] =((Number(x['ev_ln_amt'])/Number(x['ev_grp_amt']))*100).toFixed(2)
        this.reportdata.push(gst_line)
      })
    }
    console.log("Repo data : ", this.reportdata)
  }

  export() {
    var exp = []
    for (var i = 0; i < this.reportdata.length; i++) {
      var obj:any = new Object();
      obj['SNO'] = i + 1;
      if (this.reportdata[i]['cb_date']) {
        obj['Date of Deduction'] = this.mainService.dateFormatChange(this.reportdata[i]['cb_date'])

      } else {
        obj['Date of Deduction'] = this.reportdata[i]['cb_date']

      }
      obj['Party Name'] = this.reportdata[i]['party_name']

      obj['Section of IT Act'] = this.reportdata[i]['it_act']
      // obj['Rate of TDS'] = this.reportdata[i]['rate']
      obj['PAN of Party'] = this.reportdata[i]['pan']
      obj['Gross Amount (Event Value)'] = this.reportdata[i]['amount']
      obj['TDS amount'] = this.reportdata[i]['deduction_amount']

      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'Tds_report')
  }
  print() {

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + " TDS REPORT";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var header2 =
      [
        {

          text: 'From Date :',
          bold: true,
          colSpan: 2, alignment: 'center'

        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['from_dt']), colSpan: 1, alignment: 'center'
        },
        {

          text: 'To Date :',
          bold: true, colSpan: 2, alignment: 'center'
        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['to_dt']), colSpan: 2, alignment: 'center'
        }, {}
      ]
    var tbl = {

      fontSize: 10,
      table: {

        headerRows: 2,
        widths: ['*', '*', '*', '*', '*', '*', '*'],

        body: [header2,
          ['SNO', 'Date of Deduction', 'Party Name',
            { text: 'Section of IT Act', alignment: 'center' },
            // { text: 'Rate of TDS', alignment: 'center' },
            { text: 'PAN of Party', alignment: 'center' },
            { text: 'Gross Amount (Event Value)', alignment: 'center' },
            { text: 'TDS amount', alignment: 'center' }]


        ]
      }
    };
    dd.content.push(tbl);
    var deduction_amount = 0

    var amount = 0
    for (var i = 0; i < this.reportdata.length; i++) {
      var arr = []
      arr.push(i + 1);
      if (this.reportdata[i]['ded_date']) {
        arr.push(this.mainService.dateFormatChange(this.reportdata[i]['ded_date']));
      } else {
        arr.push(this.reportdata[i]['ded_date'])
      }
      arr.push(this.reportdata[i]['party_name']);
      arr.push({ text: this.reportdata[i]['it_act'], alignment: 'right' });
      // arr.push({ text: Number(this.reportdata[i]['rate']).toFixed(2), alignment: 'right' });
      arr.push({ text: this.reportdata[i]['pan'], alignment: 'right' })
      arr.push({ text: this.reportdata[i]['amount'].toFixed(2), alignment: 'right' })
      arr.push({ text: this.reportdata[i]['deduction_amount'].toFixed(2), alignment: 'right' })

      amount = amount + this.reportdata[i]['amount']

      deduction_amount = deduction_amount + this.reportdata[i]['deduction_amount']


      dd.content[dd.content.length - 1].table.body.push(arr);

    }

    var totalObjRow =
      ['', '', '', '', 'Total : Rs.', amount.toFixed(2), deduction_amount.toFixed(2)]


    dd.content[dd.content.length - 1].table.body.push(totalObjRow);




    pdfMake.createPdf(dd).download("TDS-GST-Report");
  }
}
