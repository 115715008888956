import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-emb-footer]',
  templateUrl: './emb-footer.component.html',
  styleUrls: ['./emb-footer.component.css']
})
export class EmbFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
