<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage Suspension -->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- All Employee Suspension List -->
                                {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code2']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                                 <!-- Employee Suspension  -->
                                 {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code3']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                 <!-- Update Suspension -->
                                 {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code4']}}
                                </a>

                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="ti-settings"></i>
                                  Restructure
                                 <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code4']}} -->
                                </a>

                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">




                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code5']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            Employee ID
                                           <!--  {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code6']}}-->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ empIdToName[element.emp_id] }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Employee Name -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code6']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ empIdToName[element.emp_name] }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Order ID -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="suspension_start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Suspension Start Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.suspension_start_dt)}} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="suspension_end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> -->
                                            <!-- Suspension End Date -->
                                            <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.suspension_end_dt)}} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="charge_sheet_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> -->
                                            <!-- Charge Sheet Date -->
                                            <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.dateformatchange(element.charge_sheet_dt)}} </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="en_officer_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> -->
                                            <!-- Enquiry Officer Name -->
                                            <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.en_officer_name}} </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-info" (click)="openDetails(element)">
                                                View Details
                                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code14']}} -->
                                            </button>
                                            <button class="btn btn-outline-primary" (click)="openUpdate(element)">
                                                <!-- Update -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code13']}}
                                            </button>
                                            <!-- <button class="btn btn-outline-info" (click)="withdraw(element)"> -->
                                                <!-- Suspension Withdraw -->
                                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code14']}}
                                            </button> -->
                                            <button class="btn btn-outline-success" (click)="openReinstate(element)">
                                                Reinstate
                                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code14']}} -->
                                            </button>
                                            <button class="btn btn-outline-danger" (click)="terminate(element)">
                                                 Terminate
                                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code14']}} -->
                                            </button>


                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code15']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" (change)="getFixedPayDetails()" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="suspensionObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>

                            <hr>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code16']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionObj['order_id']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Start Date : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code17']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionObj['suspension_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Note : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code31']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code32']}}" type="text" class="form-control" [(ngModel)]="suspensionObj['suspension_note']"></textarea>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Suspension End Date : -->
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code18']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionObj['suspension_end_dt']">
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Enquiry Officer Name :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code19']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="suspensionObj['en_officer_name']">
                                </div>
                            </div>
                            <br> -->

                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Charge Sheet Date : -->
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code10']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionObj['charge_sheet_dt']">
                                </div>
                            </div>
                            <br> -->

                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Fraction Percentage :{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code20']}} -->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionObj['fraction_per']">
                                </div>
                            </div> -->
                            <br>
                               <div class="row">
                                <h6 class="text-center"><!--Payment Fractions-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code27']}}</h6>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">

                                     <table>
                                         <tr>
                                         <th><!--Pay-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code28']}}</th>
                                         <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}</th>
                                         </tr>
                                         <tr *ngFor="let pay of payArr;let i=index">
                                            <td>{{pay.pay_component_code}}</td>
                                            <td><input class="form-control" type="number" [(ngModel)]="payObj[i]"></td>
                                         </tr>
                                     </table>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                     <table>
                                     <tr>
                                    <th><!--Deduction-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code30']}}</th>
                                    <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}</th>
                                     </tr>
                                     <tr *ngFor="let ded of dedArr;let j=index">
                                        <td>{{ded.pay_component_code}}</td>
                                        <td><input class="form-control" type="number" [(ngModel)]="dedObj[j]" ></td>
                                     </tr>
                                    </table>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitSuspension()">
                                        <!-- Submit -->     {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code21']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code15']}}
                                </div>
                                <div class="col-4">

                                    <input class="form-control" type="text" [(ngModel)]="suspensionUpdtObj['emp_name']" disabled>
                                </div>
                            </div>
                            <br>

                            <hr>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Order ID : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code16']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionUpdtObj['order_id']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Start Date : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code17']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionUpdtObj['suspension_start_dt']" disabled>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Suspension Note : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code31']}}
                                </div>
                                <div class="col-4">

                                    <textarea placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code32']}}" class="form-control" type="text" [(ngModel)]="suspensionUpdtObj['suspension_note']" disabled></textarea>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                     <!-- Updation Remark :  -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code33']}} <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <textarea  placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code33']}} " class="form-control" type="text" [(ngModel)]="suspensionUpdtObj['updation_remark']"></textarea>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                   <!-- Old Fraction Percentage :     {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code22']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionObj['old_fraction_per']" disabled>
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Enquiry Officer Name :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code19']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="text" [(ngModel)]="suspensionObj['en_officer_name']">
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Suspension End Date :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code18']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionObj['suspension_end_dt']">
                                </div>
                            </div>
                            <br> -->


                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Charge Sheet Date :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code10']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionObj['charge_sheet_dt']">
                                </div>
                            </div>
                            <br> -->

                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                   <!-- New Fraction Percentage :
                                   {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code23']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="suspensionObj['fraction_per']">
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Do You Want To Generate Arrears ? :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code24']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <ng-select [items]="arrearArray" bindLabel="value" bindValue="value" [multiple]="false" placeholder="" (change)="check()" [(ngModel)]="suspensionObj['arrear']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br> -->
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Arrear Amount :
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code25']}}-->
                                <!-- </div>
                                <div class="col-4">
                                    <input class="form-control" type="number" [(ngModel)]="amountObj['pay_component_amt']">

                                </div>
                            </div>
                            <br> -->
                            <br>
                               <div class="row">
                                <h6 class="text-center"><!--Payment Fractions-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code27']}}</h6>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">

                                     <table>
                                         <tr>
                                         <th><!--Pay-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code28']}}</th>
                                         <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}</th>
                                         </tr>
                                         <tr *ngFor="let pay of payUpdtArr;let i=index">
                                            <td>{{pay.pay_component_code}}</td>
                                            <td><input class="form-control"  type="number" [(ngModel)]="payUpdtObj[i]"></td>
                                         </tr>
                                     </table>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                     <table>
                                     <tr>
                                    <th><!--Deduction-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code30']}}</th>
                                    <th><!--Fraction(%)-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code29']}}</th>
                                     </tr>
                                     <tr *ngFor="let ded of dedUpdtArr;let j=index">
                                        <td>{{ded.pay_component_code}}</td>
                                        <td><input class="form-control"  maxLength="2" type="number" [(ngModel)]="dedUpdtObj[j]" ></td>
                                     </tr>
                                    </table>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                            </div>
                             <div class="row">
                                <div class="col-12 text-center">
                                 <!--   <button class="btn btn-info" (click)="check()"> -->
                                        <!-- Check
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code26']}}-->
                                    <!-- </button> -->

                                    <button class="btn btn-primary" (click)="updateSuspension()">
                                        <!-- Update -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code13']}}
                                    </button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code15']}}
                                </div>
                                <div class="col-4">

                                    <input class="form-control" type="text" [(ngModel)]="suspensionRestObj['emp_name']" disabled>
                                </div>
                            </div>
                            <br>

                            <hr>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                     <!-- Restructure Start Date :  -->
                                     {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code34']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="suspensionRestObj['restructure_start_dt']">
                                </div>
                            </div>
                            <br>

                               <div class="row">
                                <h6 class="text-center"><!--Payment Fractions-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code27']}}</h6>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">

                                     <table>
                                         <tr>
                                         <th><!--Pay-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code28']}}</th>
                                          <th><!--Before Suspension Pay Component Amt-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code35']}}</th>
                                         </tr>
                                         <tr *ngFor="let pay of payRestArr;let i=index">
                                            <td>{{pay.pay_component_code}}</td>
                                            <td><input class="form-control"  type="number" [(ngModel)]="payRestObj[i]"></td>
                                         </tr>
                                     </table>
                                 </div>
                                 <div class="col-lg-4 col-md-4 col-sm-12">
                                     <table>
                                     <tr>
                                    <th><!--Deduction-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code30']}}</th>
                                    <th><!--Before Suspension Pay Component Amt-->{{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code35']}}</th>
                                     </tr>
                                     <tr *ngFor="let ded of dedRestArr;let j=index">
                                        <td>{{ded.pay_component_code}}</td>
                                        <td><input class="form-control" type="number" [(ngModel)]="dedRestObj[j]" ></td>
                                     </tr>
                                    </table>
                                 </div>
                                 <div class="col-lg-2 col-md-2 col-sm-12"></div>
                            </div>
                             <div class="row">
                                <div class="col-12 text-center">
                                   <button class="btn btn-primary" (click)="restructure()">
                                        <!-- Restructure -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'Suspension_code36']}}
                                    </button>

                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

   </div>
   </div>
<ngx-spinner>Loading....</ngx-spinner>
