import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class MkAttendanceService {
  httpUrl:any;

  employee_id:any;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl;
  }
  async gethierid(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/payroll_info/erp_app/gethierid',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async MarkAttemdanceIn(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/payroll_info/erp_app/WebMarkAttemdanceIn',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async MarkAttemdanceOut(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/payroll_info/erp_app/WebMarkAttemdanceOut',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpunchdata(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl + '/hr/payroll_info/erp_app/getpunchdata',obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async checkcords(obj :any) {
    const resp = await this.http.get<any>(this.httpUrl + '/hr/payroll_info/erp_app/CheckLocationCord' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

}
