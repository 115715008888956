<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Matrix Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" href="#tab-1" data-toggle="tab"
                                (click)="refresh()"><i class="ti-bar-chart"></i>
                                All Matrix List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="create_flag" href="#tab-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle"></i> Create Matrix</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="update_flag" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i> Update
                                Matrix</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 20px;" (click)=open_create()>Create Matrix</button>
                                </div>
                            </div>

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.pbr_matrix_id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="eff_date">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Effective
                                            Date

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.eff_date }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="grade_pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Grade Pay

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.grade_pay_code }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="pbr_pay">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Prb Pay

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pbr_pay }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">DELETE</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 20px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>

                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Pay Commission * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0016']"
                                    (change)="changepayband(pbrMatrix['pay_commission_code'])"
                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                    [(ngModel)]="pbrMatrix['pay_commission']"
                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" name="field_name">
                                </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Pay Band * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="pay_scale"
                                    (change)="changeGradePay1(pbrMatrix['pay_scale_code'])"
                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                    [(ngModel)]="pbrMatrix['pay_scale_code']" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name" >
                                </ng-select>
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allgradepay" bindLabel="grade_pay_code"
                                        bindValue="grade_pay_code" [multiple]="false" placeholder=""
                                        [(ngModel)]="pbrMatrix['grade_pay_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>



                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter PRB Amount * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="pbrMatrix['pbr_pay']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Effective Date * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="pbrMatrix['eff_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" *ngIf="update_flag" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        style="border-radius: 20px;" (click)=open_list()>Back to List</button>
                                </div>
                            </div>


                            <br>
                            <div class="row" *ngIf="errorMsg!=''" style="color: red;">
                                <div class="col-12 text-center">
                                    {{errorMsg}}
                                </div>
                            </div>
                            <br>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Grade Pay * :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allgradepay" bindLabel="grade_pay_code"
                                        bindValue="grade_pay_code" [multiple]="false" placeholder=""
                                        [(ngModel)]="pbrMatrix['grade_pay_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"
                                        disabled>
                                    </ng-select>
                                </div>
                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Enter Prb Pay * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="pbrMatrix['pbr_pay']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Effective Date * :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="date" [(ngModel)]="pbrMatrix['eff_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
