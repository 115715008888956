import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class LeaseService {

  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/lease-inventory";
  }

  async createpropertyLease(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/createpropertyLease', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getpropertygroup(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getpropertygroup' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updatepropertyGroup(obj:any){
    const resp = this.http.put<any>(this.httpUrl + '/updatepropertyGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  async getpropertyGroupdata(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getallpropertyGroupdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async inactivePropertyGroup(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/inactivePropertyGroup', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  // **********************************************lease-property*******************************************************************
  async getpropertiesData(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getpropertiesData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getpropertiesWithFeature(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getpropertiesWithFeature' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getFeatures(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getFeature' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateproperties(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/updateproperties', obj).toPromise().then(res => {
      return res
    });
    return resp
  }

  //**************************************************** Lease Allotment *****************************************************************************************
  async createLeaseAllotment(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleaseallotment', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async checkFilenum(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/checkfilenum' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLeaseAllotment(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleaseallotment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // *********************************************** Lease Sub-Division *******************************************************************************
  async getallfileNum(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getallfilenum' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLeaseType(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleasetype' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createSubDivision(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createsubdivision' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSubDivision(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getsubdivision' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSubDivisionSingleData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl+'/property/lease/getMoreSubDTLS' + obj).toPromise().then(res=>{
      return res;
    });
    return resp;
  }

  // *************************************************************** Lease Muation *******************************************************************************


  async getMutationFileNum(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getmutationfilenum' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getLeaseGroupdata(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getallleaseGroupdata' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createMutation(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleasemutation' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLeasemutation(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleasemutation' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getMutationDetails(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/getmutationdetails' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // ************************************************************************ Lease Renewal *******************************************************************************

  async createRenewal(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleaserenewal' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getRenewalData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleaserenewal' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // ************************************************************************ Lease Litigation *******************************************************************************
  async createLitigation(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleaselitigation' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getLitigationData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleaselitigation' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // ************************************************************************ Lease Inspection *******************************************************************************
  async createInspection(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleaseinspection' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getInspectionData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleaseinspection' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // ************************************************************************ Lease Freehold *******************************************************************************
  async createFreehold(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/property/lease/createleasefreehold' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getFreeholdData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/lease/getleasefreehold' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
