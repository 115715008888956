import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Accounts-administration',
  templateUrl: './Accounts-administration.component.html',
  styleUrls: ['./Accounts-administration.component.css']
})
export class AccountsAdministrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
