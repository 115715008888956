import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService as md_mainService } from "../../../../md/service/main.service";
import { MainService } from "../../../service/main.service";
import { MainService as mainServiceMd } from "../../../../md/service/main.service";
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-create-role",
  templateUrl: "./create-role.component.html",
  styleUrls: ["./create-role.component.css"],
})
export class CreateRoleComponent implements OnInit {
  list_flage = true;
  create_flage = false;
  update_flage = false;
  checkBoxValue: any = false;
  displayedColumns = [
    "role_cd",
    "role_name",
    "role_desc",
    "role_type",
    "res",
    "action",
  ];
  type_data = [];
  party = [];
  datasource:any;
  user_id:any;
  index:any;
  Obj:any = {};
  b_acct_id = 0;
  erpUser:any;
  le_type = [];
  allCodeValue = [];

  constructor(
    private mainServiceMd: mainServiceMd,
    private spinner: NgxSpinnerService,
    public mainservice: MainService,
    public toastr: ToastrService,
    private mainService: md_mainService
  ) {}
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  data = [
    {
      id: 1,
      acct_short_name: "AC",
      acct_full_name: "Account",
      name: "suraj kumar",
      email: "suraj@infiniseasoft.com",
      phone_no: "9984904063",
    },
  ];
  async ngOnInit() {
    this.erpUser =JSON.parse(atob(localStorage.getItem("erpUser") as any))
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getCurrentResource();
    await this.getcurrentroleswithresources();
    await this.viewRule();
  }
  checkCheckBoxvalue() {
    let dummy:any = [];
    if (this.checkBoxValue == false) {
      for (let i = 0; i < this.resource.length; i++) {
        dummy.push(this.resource[i]["resource_cd"]);
      }
      this.Obj["res"] = dummy;
    } else {
      this.Obj["res"] = null;
    }
    console.log(this.checkBoxValue, this.Obj);
  }
  workflowroleobj:any = [];
  async viewRule() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["module_cd"] = "EMB";
    var resp = await this.mainServiceMd.getWorkflows(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      console.log(resp.data);
      for (let i = 0; i < resp.data.length; i++) {
        this.workflowroleobj[resp.data[i]["role_cd"]] = 1;
      }
    } else {
      Swal.fire("Error", resp.data, "error");
    }
  }
  async delete(element:any) {
    console.log(element);
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }

  async finaldelete(element:any) {
    let obj:any = {};
    obj = Object.assign({}, element);
    obj["b_acct_id"] = this.b_acct_id;
    if (this.workflowroleobj[element["role_cd"]] == 1) {
      Swal.fire(
        "Warning",
        "This Role is in Workflow Hence Connot be deleted",
        "warning"
      );
      return;
    }
    this.spinner.show();
    var resp = await this.mainService.deleteRole(obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getcurrentroleswithresources();
      Swal.fire("Success", resp["data"], "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }
  async getcurrentroleswithresources() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    console.log(obj);
    var resp = await this.mainService.getcurrentroleswithresources(
      JSON.stringify(obj)
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();

      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        data.push(resp.data[i]);
      }
      this.datasource = new MatTableDataSource(data);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting  roles", "error");
    }
  }
  async getAllModules() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    var resp = await this.mainService.getMdModules(JSON.stringify(obj));
    console.log(resp, "modules");
    if (resp["error"] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", "..Error while getting modules", "error");
    }
  }
  resource:any = [];
  async getCurrentResource() {
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.mainService.getMdResource(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      console.log(resp);
      let dummy = [];
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["module_cd"] == "EMB") {
          resp["data"][i]["desc"] =
            resp["data"][i]["module_cd"] +
            " - " +
            resp["data"][i]["resource_desc"];
          dummy.push(resp["data"][i]);
        }
      }
      this.resource = dummy;
    } else {
      Swal.fire("Error", "..Error while getting  resource", "error");
    }
  }

  viewObj = {};
  viewDetails(element:any) {
    this.viewObj = Object.assign({}, element);
    console.log(element);
    $("#B").modal("show");
  }

  changeparty() {
    for (let i = 0; i < this.party.length; i++) {
      if (this.party[i]["party_id"] == this.Obj["party_id"]) {
        this.Obj["party_leagal_name"] = this.party[i]["party_leagal_name"];
      }
    }
  }

  async refresh() {
    this.Obj = {};
  }

  back() {
    $('.nav-tabs a[href="#tab-7-1"]').tab("show");
    this.Obj = {};
  }

  async open_update(element:any) {
    this.list_flage = false;
    this.create_flage = false;
    this.update_flage = true;
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    this.Obj["old_role_cd"] = element["role_cd"];
    if (element["resource_cd"]) {
      this.Obj["res"] = element["resource_cd"].split(",");
    }
    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
    this.spinner.hide();
  }

  createrole() {
    this.refresh;
    this.list_flage = false;
    this.create_flage = true;
    this.update_flage = false;
  }
  async gotolist() {
    this.list_flage = true;
    this.create_flage = false;
    this.update_flage = false;
    await this.getCurrentResource();
    await this.getcurrentroleswithresources();
    await this.viewRule();
  }

  async submit() {
    if (!this.Obj["role_cd"]) {
      Swal.fire("Error", "Please Enter Role Code", "error");
    } else if (!this.Obj["role_name"]) {
      Swal.fire("Error", "Please Enter Role Description", "error");
    } else if (!this.Obj["role_desc"]) {
      Swal.fire("Error", "Please Enter Role Description", "error");
    } else if (!this.Obj["res"]) {
      Swal.fire("Error", "Please Select Resource", "error");
    } else {
      let dummy = [];
      for (let i = 0; i < this.resource.length; i++) {
        for (let j = 0; j < this.Obj["res"].length; j++) {
          if (this.Obj["res"][j] == this.resource[i]["resource_cd"]) {
            dummy.push(this.resource[i]);
          }
        }
      }
      this.Obj["b_acct_id"] = this.b_acct_id;
      this.Obj["life_cycle_status"] = "GENERATED";
      this.Obj["user_id"] = this.erpUser.le_id;
      this.Obj["role_type"] = "Module Role";
      this.Obj["data"] = dummy;
      this.Obj["module_cd"] = "EMB";
      console.log(this.Obj);
      this.spinner.show();
      var resp = await this.mainService.createMdRole(this.Obj);
      console.log(resp);
      if (resp["error"] == false) {
        setTimeout(() => {
          this.spinner.hide();
          Swal.fire("Success...", "Created Successfully", "success");
          this.getcurrentroleswithresources();
        }, 500);
        this.gotolist();
      } else {
        this.spinner.hide();
        Swal.fire("Error", resp["data"], "error");
      }
    }
  }

  async update() {
    if (!this.Obj["role_cd"]) {
      Swal.fire("Error", "Please Enter Role Code", "error");
    } else if (!this.Obj["role_name"]) {
      Swal.fire("Error", "Please Enter Role Description", "error");
    } else if (!this.Obj["role_desc"]) {
      Swal.fire("Error", "Please Enter Role Description", "error");
    } else if (!this.Obj["res"]) {
      Swal.fire("Error", "Please Select Resource", "error");
    } else {
      let dummy = [];
      for (let i = 0; i < this.resource.length; i++) {
        for (let j = 0; j < this.Obj["res"].length; j++) {
          if (this.Obj["res"][j] == this.resource[i]["resource_cd"]) {
            dummy.push(this.resource[i]);
          }
        }
      }
      this.Obj["b_acct_id"] = this.b_acct_id;
      this.Obj["life_cycle_status"] = "GENERATED";
      this.Obj["user_id"] = this.erpUser.le_id;
      this.Obj["role_type"] = "Module Role";
      this.Obj["data"] = dummy;
      this.Obj["module_cd"] = "EMB";
      console.log(this.Obj);
      this.spinner.show();
      var resp = await this.mainService.updateRole(this.Obj);
      console.log(resp);
      if (resp["error"] == false) {
        setTimeout(() => {
          this.spinner.hide();
          Swal.fire("Success...", "Update Successfully", "success");
          this.getcurrentroleswithresources();
        }, 500);
        this.gotolist();
      } else {
        this.spinner.hide();
        Swal.fire("Error", "Error While Creating", "error");
      }
    }
  }

  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return false;
    } else {
      return true;
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
