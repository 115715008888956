import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../service/main.service';
import { SettingService } from '../service/setting.service';
import swal from 'sweetalert2';
import { BpService } from '../service/bp.service'
import * as pdfMake from "pdfmake/build/pdfmake";
import  * as pdfFonts from "pdfmake/build/vfs_fonts";
// import * as pdfFonts from '../../../assets/font/vfs_fonts'



(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


// oc

// pdfMake.fonts = {
//   Hindi: {
//     normal: './Roboto-Hindi.ttf',
//     bold: './advice.component.ts',
//     italics: './Roboto-Hindi.ttf',
//     bolditalics: './Roboto-Hindi.ttf'
//   },

//   // download default Roboto font from cdnjs.com
//   Roboto: {
//     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
//   }
// }


// ec
declare var $: any;

import { BillService } from '../service/bill.service';
declare var $: any
import { ChartAcctMapingServiceService } from '../service/chart-acct-maping-service.service';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.css']
})
export class AdviceComponent implements OnInit {


  constructor(private billService: BillService, private chartAccMapingS: ChartAcctMapingServiceService, private settingService: SettingService, public mainService: MainService, private BPS: BpService, private spinner: NgxSpinnerService) {

  }
  erpUser:any;
  b_acct_id:any;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  list:any  = ['id', 'amount', 'date', 'remark', 'status', 'action'];
  datasource:any ;
  systemDate:any ;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp = await this.billService.getSystemDate();
    this.systemDate = resp.data;

    await this.getrepresentative();
    await this.getparty();

    await this.getChartOfAccountMappingList();
    await this.getBankAccount();
    await this.getList();
    await this.getAdviceList();
  }
  //******************************Party Info*********************************************** */
  reperesentative:any = {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }
  all_party:any = [];
  partyNameObj :any= {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      this.partyNameObj = {};

      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.partyNameObj[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
        this.partyIdToDetail[resp['data'][i]['le_id']] = resp['data'][i]

      }
      this.all_party = resp['data'];
    }
  }


  bankDtlObj :any= {};
  allBankAccounts:any = [];
  async getBankAccount() {
    var resp = await this.settingService.getBankAccounts(this.b_acct_id);
    if (resp['error'] == false) {
      this.allBankAccounts = resp.data;
      for (let i = 0; i < this.allBankAccounts.length; i++) {
        this.bankDtlObj[this.allBankAccounts[i]['id']] = this.allBankAccounts[i]
      }
    } else {
      swal.fire("Error", "...Error while getting  all Bank list!", 'error');
    }
  }

  allParty:any = [];
  partyIdToName:any = {};
  partyIdToDetail:any = {};
  // async getAllParties() {
  //   var obj:any = new Object();
  //   obj['b_acct_id'] = this.b_acct_id;
  //   var resp = await this.settingService.getPartyInfoNew(this.b_acct_id);
  //   if (resp['error'] == false) {

  //     this.allParty = resp.data;
  //     this.partyIdToName = {};
  //     this.partyIdToIFSC = {};
  //     for (let i = 0; i < this.allParty.length; i++) {
  //       this.partyIdToName[this.allParty[i]['party_id']] = this.allParty[i]['party_name']
  //       this.partyIdToIFSC[this.allParty[i]['party_id']] = this.allParty[i]['ifsc_code']
  //     }

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     swal.fire("Error", "...Error while getting  all party list!", 'error');

  //   }
  // }
  bp_data :any= [];
  async getList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.BPS.getList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.bp_data = resp['data'];
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all party list!", 'error');
    }
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async getAdviceList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.BPS.getAdvice(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      var table_data = []
      for (let i = 0; i < resp['data'].length; i++) {
        var obj1 = Object.assign({}, resp['data'][i])
        obj1['date'] = obj1['create_timestamp'].split("T")[0]
        table_data.push(obj1)
      }
      this.datasource = new MatTableDataSource(table_data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  data!", 'error');

    }
  }



  ChartOfAccountToAccountObj:any = {};
  async getChartOfAccountMappingList() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.chartAccMapingS.getRelationList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.ChartOfAccountToAccountObj = {};
      for (let i = 0; i < resp['data'].length; i++) {
        this.ChartOfAccountToAccountObj[resp['data'][i]['chart_of_account']] = resp['data'][i]['relation']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  list!", 'error');
    }
  }


  async delete(element:any) {
    this.spinner.show()
    let obj:any = {}
    obj['id'] = element['id'];
    obj['bpid'] = element['bpid'];
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.BPS.deleteAdvice(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      swal.fire('Deleted Successfully', '', 'success')
      await this.getAdviceList();
      await this.getList();
    }
    else {
      swal.fire("Error", "...Some Error Exist..!", 'error');
      this.spinner.hide()
    }

  }
  printAdvice(element:any) {
    var arr = [];
    var bank_acct_no = ''
    let bankobj = {}
    var bpid = element['bpid'].split(",");
    for (let i = 0; i < bpid.length; i++) {
      for (let j = 0; j < this.bp_data.length; j++) {
        if (this.bp_data[j]['id'] == bpid[i]) {
          arr.push(this.bp_data[j])
          var tt = JSON.parse(this.bp_data[j]['data']);
          bankobj = Object.assign({}, tt)
          bank_acct_no = tt['relation']
        }
      }
    }
    var bank_name = this.bankDtlObj[bank_acct_no]['bank_code']
    var branch_name = this.bankDtlObj[bank_acct_no]['branch_code']
    this.print11(arr, bank_acct_no, bank_name, branch_name);
  }



  print11(data:any, org_bank_acct_no:any, org_bank_name:any, org_branch_name:any) {
    console.log(data,  org_bank_acct_no, org_bank_name, org_branch_name)
    var txt = "Bank Payment REPORT (Advice)";
    var dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Payment Bank Detail : '+org_bank_acct_no+ ":" +org_bank_name + ":" + org_branch_name,
          bold: false
        }
      ],
    }





    var header7 = {
      columns: [
        {
          width: '70%',
          text: "",
          bold: false

        },
        {
          width: '10%',
          text: "Date : ",
          bold: false,
          alignment: 'right'

        },
        {
          width: '20%',
          text: this.mainService.dateFormatChange(this.systemDate),
          bold: false,
          alignment: 'left'

        },
      ],
    }



    var total_amount = 0
    for (var i = 0; i < data.length; i++) {
      total_amount = total_amount + data[i]['bp_amount']
    }
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header7);
    dd.content.push({ text: " " });

    var tbl = {

      fontSize: 10,
      table: {

        headerRows: 2,
        widths: ['*', '*', '*', '*', '*', '*'],

        body: [
          ['SNO', 'Name', 'Bank Account NO',
            { text: 'Bank Name', alignment: 'center' },
            { text: 'IFSC Code', alignment: 'center' },
            { text: 'Amount', alignment: 'center' }
          ]
        ]
      }
    };

    dd.content.push(tbl);

    var amount = 0
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(i + 1);
      arr.push(this.partyNameObj[data[i].party_id]);
      arr.push({ text: this.partyIdToDetail[data[i].party_id]['party_acct_no'], alignment: 'center' });
      arr.push({ text:this.partyIdToDetail[data[i].party_id]['bank_name'], alignment: 'center' });
      arr.push({ text: this.partyIdToDetail[data[i].party_id]['ifsc_code'], alignment: 'center' });
      arr.push({ text: data[i]['bp_amount'].toFixed(2), alignment: 'right' })
      amount = amount + data[i]['bp_amount']

      dd.content[dd.content.length - 1].table.body.push(arr);

    }

    var totalObjRow =
      [{ text: '', alignment: 'right' }, { text: '', alignment: 'right' }, { text: '', alignment: 'right' },
       { text: '', alignment: 'right' }, 'Total : Rs.',  { text:amount.toFixed(2) , alignment: 'right' }]


    dd.content[dd.content.length - 1].table.body.push(totalObjRow);


    // oc
    // pdfMake.createPdf(dd, null, pdfMake.fonts, pdfMake.vfs).download("advice");
    //  ec
  }
}
