import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileUploader } from 'ng2-file-upload';
declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import { DocumentService } from '../../service/document.service';
import { MatStepper } from '@angular/material/stepper';
import { PropCostingService } from '../../service/prop-costing.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
import { GatewayMidConfigService } from '../../../accounts/service/gateway-mid-config.service';
import { MainService as AdminMainService } from '../../../admin/service/main.service'
import { PropertySettingService } from '../../service/property-setting.service';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import * as  pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MainService as Portalmain } from '../../../portal/service/main.service';

@Component({
  selector: 'app-fcfs',
  templateUrl: './fcfs.component.html',
  styleUrls: ['./fcfs.component.css']
})
export class FcfsComponent implements OnInit {

  datasource: any;
  isLinear: any = false;
  first: any = true;
  selectedFile: any = null;
  second: any = true;
  radioBooklet: any = false;
  propObj: any = {}
  subsidy_type: any = [{ id: "Amount" }, { id: "Percentage" }]
  cost_type: any = [{ id: "Basic" }, { id: "Total" }]
  displayedColumns = ['assigned_property_number', 'property_type', 'description', 'area', 'action'];
  displayedColumns_1 = ['id', 'code', 'name', 'no_of_prop', 'start_dt', 'end_dt', 'status', 'action']
  GISArr: any = []
  costing_method: any = [{ id: 'Fixed' }, { id: 'Variable %' }, { id: 'Variable Rate' }]
  third: any = true;
  modeOfPayment: any = [{ id: "Installment" }, { id: "Cash Down" }]
  fourth: any = true;
  fifth: any = true
  sixth: any = true
  yesNo_arr: any = [{ id: 'YES' }, { id: 'NO' }]
  work_id: any;
  BoqTable: any = [];
  total_sum: any = 0;
  imgURL: any;
  Obj: any = {}
  paymentOptionObj: any = {}
  docArr: any = [{}]
  enableStep3: any;
  b_acct_id: any;
  ebillUser: any;
  planing_arr: any = [{}]
  acp_arr: any = [{}]
  public imagePath: any;

  httpUrl: any;
  radioSub: any = false;
  uploader: any;
  required_doc_obj: any = {}
  optional_doc_obj: any = {}
  is_gst: any = [{ id: 'YES' }, { id: 'No' }]
  required_doc_arr: any = []
  location_type_obj: any = {}
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('stepper1', { static: false }) stepper1!: MatStepper;
  @ViewChild('stepper2', { static: false }) stepper2!: MatStepper;
  property_group_check_data: any
  gatwayData: any = []
  constructor(private portalmain: Portalmain, public ApprovalUserService: ApprovalUserService, public mainserviceEmb: mainserviceEmb, private spinner: NgxSpinnerService, private settingService: PropertySettingService, private adminMainService: AdminMainService, private GatewayMidConfig: GatewayMidConfigService, private commonS: PropCommonSService, private propHierS: PropHierarchyService, private costingService: PropCostingService, private docService: DocumentService, private sale_service: SaleService, public auth: AuthenticationService, public mainService: MainService, private landInput_S: LandInputService, private hierarchyS: PropHierarchyService, public toastr: ToastrService, private sanitizer: DomSanitizer) { }
  allProjects: any = []
  all_premium_feature: any = []
  all_add_feature: any = []
  quota_obj: any = {}
  subQuota_obj: any = {}
  purpose_arr: any = [
    { code: "Booklet" },
    { code: "Application" },
    { code: "Property_Payment" }
  ]

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.Obj['total_area'] = 0
    this.httpUrl = this.mainService.httpUrl
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };
    // await this.getpropertygroup()
    await this.getAllOffers()
    await this.getFeatures()
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getactivity()
    await this.getbudhier()
    await this.getprodhier()
    await this.getAllplanLayoutForOffer()
    this.applicationEndDate = this.currentDate()
    for (let i = 0; i < this.mainService.codeValueTechObj['PROP0022'].length; i++) {
      this.required_doc_obj[this.mainService.codeValueTechObj['PROP0022'][i]['code']] = this.mainService.codeValueTechObj['PROP0022'][i]['value']
      this.required_doc_arr.push(this.mainService.codeValueTechObj['PROP0022'][i])
    }
    for (let i = 0; i < this.mainService.codeValueTechObj['PROP0023'].length; i++) {
      this.optional_doc_obj[this.mainService.codeValueTechObj['PROP0023'][i]['code']] = this.mainService.codeValueTechObj['PROP0023'][i]['value']
      this.required_doc_arr.push(this.mainService.codeValueTechObj['PROP0023'][i])
    }

    await this.getGatwayList()
    await this.getConfigureData()
    await this.getproject()
    for (let i = 0; i < this.mainService.codeValueTechObj['PROP0019'].length; i++) {
      this.quota_obj[this.mainService.codeValueTechObj['PROP0019'][i]['code']] = this.mainService.codeValueTechObj['PROP0019'][i]['value']
    }
    for (let i = 0; i < this.mainService.codeValueTechObj['PROP0020'].length; i++) {
      this.subQuota_obj[this.mainService.codeValueTechObj['PROP0020'][i]['code']] = this.mainService.codeValueTechObj['PROP0020'][i]['value']
    }

    for (let i = 0; i < this.mainService.codeValueTechObj['PROPFLD5'].length; i++) {
      this.location_type_obj[this.mainService.codeValueTechObj['PROPFLD5'][i]['code']] = this.mainService.codeValueTechObj['PROPFLD5'][i]['value']
    }

    //get work flow offer code....
    await this.getWorkflowlog();

  }


  projecthier: any = []
  projhierobj: any = {}
  projhierobjtype: any = {}
  projhierobjtypecd: any = {}
  projectLeafCode: any = {}
  project_hier: any = []
  projectKeyValue: any = {}
  level11: any = []
  objToSubmit: any = {}
  async getproject() {
    let obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    let resp = await this.hierarchyS.getprojectHierarchy(obj)
    console.log(resp)
    for (let i = 0; i < resp.data.length; i++) {
      this.projectKeyValue[resp['data'][i]['leaf_cd']] = resp['data'][i]
      this.projectLeafCode[resp['data'][i]['leaf_user_cd']] = resp['data'][i]
      if (resp.data[i]['lvl1_value'] == "All Projects") {
        resp.data[i]['lvl1_value'] = 'PROPERTY-root'
      }
    }
    this.project_hier = resp['data']
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    console.log(this.project_hier, this.level1, 'klklklklkllllllllllllllllklklklk')
  }
  budhier = []
  async getbudhier() {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'bud_hier';
    this.spinner.show();
    var resp = await this.hierarchyS.getBudgetHierarchy(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      let data = resp['data']
      this.budhier = []
      this.budhier = data
      console.log(data, 'budget hier')
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }
  prodhier = []
  async getprodhier() {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'prod_hier';
    this.spinner.show();
    var resp = await this.hierarchyS.getproductHierarchy(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      let data = resp['data']
      this.prodhier = []
      this.prodhier = data
      console.log(data, 'product hier')
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }
  project_obj: any = {}
  selectProject() {
    this.project_obj = Object.assign({}, {})
    this.project_arr = []
    this.project_arr = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.project_hier.length; i++) {
      if (!arr.includes(this.project_hier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.project_hier[i]['lvl1_cd'], lvl1_value: this.project_hier[i]['lvl1_value'], lvl1_node_type: this.project_hier[i]['lvl1_node_type'] })
        arr.push(this.project_hier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER').modal('show');
  }
  selectProduct() {
    this.prod_obj = Object.assign({}, {})
    this.prod_arr = []
    this.prod_arr = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.prodhier.length; i++) {
      if (!arr.includes(this.prodhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.prodhier[i]['lvl1_cd'], lvl1_value: this.prodhier[i]['lvl1_value'], lvl1_node_type: this.prodhier[i]['lvl1_node_type'] })
        arr.push(this.prodhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_prod').modal('show');
  }
  prod_obj_booklet: any = {}
  selectProduct_booklet() {
    this.prod_obj_booklet = Object.assign({}, {})
    this.prod_arr_booklet = []
    this.prod_arr_booklet = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.prodhier.length; i++) {
      if (!arr.includes(this.prodhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.prodhier[i]['lvl1_cd'], lvl1_value: this.prodhier[i]['lvl1_value'], lvl1_node_type: this.prodhier[i]['lvl1_node_type'] })
        arr.push(this.prodhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_booklet_prod').modal('show');
  }
  selectActivity_booklet() {
    this.act_obj_booklet = Object.assign({}, {})
    this.act_arr_booklet = []
    this.act_arr_booklet = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (!arr.includes(this.activity_arr[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.activity_arr[i]['lvl1_cd'], lvl1_value: this.activity_arr[i]['lvl1_value'], lvl1_node_type: this.activity_arr[i]['lvl1_node_type'] })
        arr.push(this.activity_arr[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_act_booklet').modal('show');
  }
  act_obj_booklet_gst: any = {}
  act_arr_booklet_gst: any = [{}]
  selectActivity_booklet_gst() {
    this.act_obj_booklet_gst = Object.assign({}, {})
    this.act_arr_booklet_gst = []
    this.act_arr_booklet_gst = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (!arr.includes(this.activity_arr[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.activity_arr[i]['lvl1_cd'], lvl1_value: this.activity_arr[i]['lvl1_value'], lvl1_node_type: this.activity_arr[i]['lvl1_node_type'] })
        arr.push(this.activity_arr[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_act_booklet_gst').modal('show');
  }
  selectBudget_booklet() {
    this.bud_obj_booklet = Object.assign({}, {})
    this.bud_arr_booklet = []
    this.bud_arr_booklet = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (!arr.includes(this.budhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.budhier[i]['lvl1_cd'], lvl1_value: this.budhier[i]['lvl1_value'], lvl1_node_type: this.budhier[i]['lvl1_node_type'] })
        arr.push(this.budhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_booklet_bud').modal('show');
  }
  bud_obj_booklet_gst: any = {}
  bud_arr_booklet_gst: any = []
  selectBudget_booklet_gst() {
    this.bud_obj_booklet_gst = Object.assign({}, {})
    this.bud_arr_booklet_gst = []
    this.bud_arr_booklet_gst = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (!arr.includes(this.budhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.budhier[i]['lvl1_cd'], lvl1_value: this.budhier[i]['lvl1_value'], lvl1_node_type: this.budhier[i]['lvl1_node_type'] })
        arr.push(this.budhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_booklet_bud_gst').modal('show');
  }
  act_obj: any = {}
  selectActivity() {
    this.act_obj = Object.assign({}, {})
    this.bud_arr = []
    this.bud_arr = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (!arr.includes(this.activity_arr[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.activity_arr[i]['lvl1_cd'], lvl1_value: this.activity_arr[i]['lvl1_value'], lvl1_node_type: this.activity_arr[i]['lvl1_node_type'] })
        arr.push(this.activity_arr[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_act').modal('show');
  }
  bud_obj: any = {}
  selectBudget() {
    this.bud_obj = Object.assign({}, {})
    this.bud_arr = []
    this.bud_arr = [{}]
    let arr: any = []
    let duumy: any = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (!arr.includes(this.budhier[i]['lvl1_cd'])) {
        duumy.push({ lvl1_cd: this.budhier[i]['lvl1_cd'], lvl1_value: this.budhier[i]['lvl1_value'], lvl1_node_type: this.budhier[i]['lvl1_node_type'] })
        arr.push(this.budhier[i]['lvl1_cd'])
      }
    }
    this.level11 = duumy
    $('#ACTHIER_bud').modal('show');
  }
  async set_Path_desc(index: any) {
    console.log(this.project_hier, index, this.project_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.project_hier, this.project_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.project_hier, this.project_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.project_hier, this.project_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.project_hier, this.project_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.project_hier, this.project_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.project_hier, this.project_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.project_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['lvl' + i + '_user_cd'] = this.project_obj['lvl' + i + '_user_cd']
        this.project_obj['lvl' + i + '_cd'] = this.project_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.project_obj['lvl' + i + '_cd']
        dummy.push(this.project_obj['lvl' + i + '_value'])
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.project_obj, this.level11, this.level2)
  }
  async set_Path_desc_act(index: any) {
    console.log(this.activity_arr, index, this.act_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.activity_arr, this.act_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.activity_arr, this.act_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.activity_arr, this.act_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.activity_arr, this.act_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.activity_arr, this.act_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.activity_arr, this.act_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.act_obj['lvl' + i + '_cd']) {
        this.act_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.act_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['lvl' + i + '_user_cd'] = this.act_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.act_obj['lvl' + i + '_cd']
        dummy.push(this.act_obj['lvl' + i + '_value'])
      }
    }
    this.act_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.act_obj, this.level11, this.level2)
  }
  async set_Path_desc_act_booklet(index: any) {
    console.log(this.activity_arr, index, this.act_obj_booklet)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.activity_arr, this.act_obj_booklet)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.activity_arr, this.act_obj_booklet)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.activity_arr, this.act_obj_booklet)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.activity_arr, this.act_obj_booklet)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.activity_arr, this.act_obj_booklet)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.activity_arr, this.act_obj_booklet)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.act_obj_booklet['lvl' + i + '_cd']) {
        this.act_obj_booklet['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.act_obj_booklet['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        this.act_obj_booklet['node_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        this.act_obj_booklet['lvl' + i + '_user_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        dummy.push(this.act_obj_booklet['lvl' + i + '_value'])
      }
    }
    this.act_obj_booklet['project_path_desc'] = dummy.join('-->')
    console.log(this.act_obj_booklet, this.level11, this.level2)
  }
  async set_Path_desc_act_booklet_gst(index: any) {
    console.log(this.activity_arr, index, this.act_obj_booklet_gst)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.activity_arr, this.act_obj_booklet_gst)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.activity_arr, this.act_obj_booklet_gst)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.activity_arr, this.act_obj_booklet_gst)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.activity_arr, this.act_obj_booklet_gst)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.activity_arr, this.act_obj_booklet_gst)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.activity_arr, this.act_obj_booklet_gst)
    }
    let dummy = []
    for (let i = 1; i <= 7; i++) {
      if (this.act_obj_booklet_gst['lvl' + i + '_cd']) {
        this.act_obj_booklet_gst['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.act_obj_booklet_gst['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        this.act_obj_booklet_gst['node_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        this.act_obj_booklet_gst['lvl' + i + '_user_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        dummy.push(this.act_obj_booklet_gst['lvl' + i + '_value'])
      }
    }
    this.act_obj_booklet_gst['project_path_desc'] = dummy.join('-->')
    console.log(this.act_obj_booklet_gst, this.level11, this.level2)
  }
  act_obj_booklet: any = {}
  async set_Path_desc_bud(index: any) {
    console.log(this.budhier, index, this.bud_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.budhier, this.bud_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.budhier, this.bud_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.budhier, this.bud_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.budhier, this.bud_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.budhier, this.bud_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.budhier, this.bud_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.bud_obj['lvl' + i + '_cd']) {
        this.bud_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.bud_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['lvl' + i + '_user_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.bud_obj['lvl' + i + '_cd']
        dummy.push(this.bud_obj['lvl' + i + '_value'])
      }
    }
    this.bud_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.bud_obj, this.level11, this.level2)
  }
  async set_Path_desc_bud_booklet(index: any) {
    console.log(this.budhier, index, this.bud_obj_booklet)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.budhier, this.bud_obj_booklet)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.budhier, this.bud_obj_booklet)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.budhier, this.bud_obj_booklet)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.budhier, this.bud_obj_booklet)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.budhier, this.bud_obj_booklet)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.budhier, this.bud_obj_booklet)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.bud_obj_booklet['lvl' + i + '_cd']) {
        this.bud_obj_booklet['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.bud_obj_booklet['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        this.bud_obj_booklet['node_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        this.bud_obj_booklet['lvl' + i + '_user_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        dummy.push(this.bud_obj_booklet['lvl' + i + '_value'])
      }
    }
    this.bud_obj_booklet['project_path_desc'] = dummy.join('-->')
    console.log(this.bud_obj_booklet, this.level11, this.level2)
  }
  async set_Path_desc_bud_booklet_gst(index: any) {
    console.log(this.budhier, index, this.bud_obj_booklet_gst)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.budhier, this.bud_obj_booklet_gst)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.budhier, this.bud_obj_booklet_gst)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.budhier, this.bud_obj_booklet_gst)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.budhier, this.bud_obj_booklet_gst)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.budhier, this.bud_obj_booklet_gst)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.budhier, this.bud_obj_booklet_gst)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.bud_obj_booklet_gst['lvl' + i + '_cd']) {
        this.bud_obj_booklet_gst['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.bud_obj_booklet_gst['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        this.bud_obj_booklet_gst['node_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        this.bud_obj_booklet_gst['lvl' + i + '_user_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        dummy.push(this.bud_obj_booklet_gst['lvl' + i + '_value'])
      }
    }
    this.bud_obj_booklet_gst['project_path_desc'] = dummy.join('-->')
    console.log(this.bud_obj_booklet_gst, this.level11, this.level2)
  }
  bud_obj_booklet: any = {}
  prod_obj: any = {}
  async set_Path_desc_prod(index: any) {
    console.log(this.prodhier, index, this.prod_obj)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.prodhier, this.prod_obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.prodhier, this.prod_obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.prodhier, this.prod_obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.prodhier, this.prod_obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.prodhier, this.prod_obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.prodhier, this.prod_obj)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.prod_obj['lvl' + i + '_cd']) {
        this.prod_obj['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.prod_obj['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['lvl' + i + '_user_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.prod_obj['lvl' + i + '_cd']
        dummy.push(this.prod_obj['lvl' + i + '_value'])
      }
    }
    this.prod_obj['project_path_desc'] = dummy.join('-->')
    console.log(this.prod_obj, this.level11, this.level2)
  }
  async set_Path_desc_prod_booklet(index: any) {
    console.log(this.prodhier, index, this.prod_obj_booklet)
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.prodhier, this.prod_obj_booklet)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.prodhier, this.prod_obj_booklet)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.prodhier, this.prod_obj_booklet)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.prodhier, this.prod_obj_booklet)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.prodhier, this.prod_obj_booklet)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.prodhier, this.prod_obj_booklet)
    }
    let dummy = []


    for (let i = 1; i <= 7; i++) {
      if (this.prod_obj_booklet['lvl' + i + '_cd']) {
        this.prod_obj_booklet['lvl1_node_type'] = this.level11[0]['lvl1_node_type']
        // this.prod_obj_booklet['lvl1_user_cd'] = this.level11[0]['lvl1_user_cd']
        this.Obj['project_node_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        this.prod_obj_booklet['node_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        this.prod_obj_booklet['lvl' + i + '_user_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        this.objToSubmit['project_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        dummy.push(this.prod_obj_booklet['lvl' + i + '_value'])
      }
    }
    this.prod_obj_booklet['project_path_desc'] = dummy.join('-->')
    console.log(this.prod_obj_booklet, this.level11, this.level2)
  }
  project_arr: any = []
  async add_dropdown2(data: any, index: any) {
    if (index > 4) {
      Swal.fire('Information...', 'You Can Select Till Level 4 In Project Hierarchy', 'info')
      return
    }
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.project_arr.push({})
    }
  }
  async add_dropdown2_act(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.act_arr.push({})
    }
  }
  async add_dropdown2_act_booklet(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.act_arr_booklet.push({})
    }
  }
  async add_dropdown2_act_booklet_gst(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.act_arr_booklet_gst.push({})
    }
  }
  async add_dropdown2_bud(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.bud_arr.push({})
    }
  }
  async add_dropdown2_bud_booklet(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.bud_arr_booklet.push({})
    }
  }
  async add_dropdown2_bud_booklet_gst(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.bud_arr_booklet_gst.push({})
    }
  }
  async add_dropdown2_prod(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.prod_arr.push({})
    }
  }
  async add_dropdown2_prod_booklet(data: any, index: any) {

    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.prod_arr_booklet.push({})
    }
  }
  submit_prj_hier() {
    $('#ACTHIER').modal('hide');
    this.project_arr = []
    console.log(this.project_obj)
  }
  submit_prj_hier_act() {

    $('#ACTHIER_act').modal('hide');
    this.act_arr = []
    console.log(this.project_obj)
  }
  act_arr_booklet = [{}]
  submit_prj_hier_act_booklet() {

    $('#ACTHIER_act_booklet').modal('hide');
    this.act_arr_booklet = []
    console.log(this.project_obj)
  }
  submit_prj_hier_act_booklet_gst() {

    $('#ACTHIER_act_booklet_gst').modal('hide');
    this.act_arr_booklet_gst = []
    console.log(this.project_obj)
  }
  submit_prj_hier_bud() {
    $('#ACTHIER_bud').modal('hide');
    this.bud_arr = []
    console.log(this.project_obj)
  }
  bud_arr_booklet = [{}]
  submit_prj_hier_bud_booklet() {
    $('#ACTHIER_booklet_bud').modal('hide');
    this.bud_arr_booklet = []
    console.log(this.project_obj)
  }
  submit_prj_hier_bud_booklet_gst() {
    $('#ACTHIER_booklet_bud_gst').modal('hide');
    this.bud_arr_booklet_gst = []
    console.log(this.project_obj)
  }
  prod_arr = [{}]
  submit_prj_hier_prod() {
    $('#ACTHIER_prod').modal('hide');
    this.prod_arr = []
    console.log(this.project_obj)
  }
  prod_arr_booklet = [{}]
  submit_prj_hier_prod_booklet() {
    $('#ACTHIER_booklet_prod').modal('hide');
    this.prod_arr_booklet = []
    console.log(this.project_obj)
  }
  admindata = []
  async getGatwayList() {
    var obj: any = new Object();
    obj['b_acct_id'] = 0
    var resp = await this.adminMainService.getPaymentGatwayData(JSON.stringify(obj));
    console.log(resp, 'gatway list')
    if (resp['error'] == false) {
      this.admindata = resp['data'];
      for (let i = 0; i < this.admindata.length; i++) {
        if (this.admindata[i]['life_cycle_status'] != 'ACTIVE') {
          this.admindata.splice(i, 1)
        }
      }
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }
  activity_arr = []
  async getactivity() {
    let obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    obj['table_name'] = 'activity_hier';
    let resp = await this.hierarchyS.getActivityHierarchy(obj)
    if (resp['error'] == false) {
      // this.allcostcenters = []
      console.log(resp['data'])
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'PROPERTY-root'

        }
      }
      this.activity_arr = resp['data']
      console.log(resp['data'], 'activity Hier')
      //   this.projecthier = resp['data']
      //    for (let i = 0; i < resp['data'].length; i++) {
      //      for (let j = 1; j < 8; j++) {
      //      this.projhierobj[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_value']
      //      this.projhierobjtype[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_node_type']
      //      this.projhierobjtypecd[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['lvl'+(j)+'_user_cd']
      //      this.projhierobjisleaf[resp['data'][i]['lvl'+(j)+'_cd']] = resp['data'][i]['is_leaf']

      //      }
      //    /*  if(resp['data'][i]['cc_func']=="PROPERTY")
      //     {
      //       let obb = Object.assign({},resp['data'][i])
      //       obb['node_cd'] = obb['cc_code']
      //       obb['desc'] = obb['cc_code'] +'-'+obb['cc_name']
      //       obb['node_desc'] = obb['desc']
      //       this.allcostcenters.push(obb)

      //     } */

      //   }
      //  // this.allcostcenters = [...resp['data']]
      //   //console.log(this.allcostcenters)
      //   // this.planing_arr[1] = {existing_heirarchy_nodes:[...this.allcostcenters]}
      //   console.log(this.planing_arrproj);



    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }

  uniqueData = []
  paymentOptionList = []

  async paymentOptionData() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['mode_of_payment'] = this.Obj['payment_option']
    console.log(obj)
    var resp = await this.costingService.getPaymentOptionList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.paymentOptionList = resp['data']
      console.log(this.paymentOptionList)
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
    this.resetPaymentOption()
  }
  async resetPaymentOption() {
    this.paymentOptionObj['option_list'] = []

  }
  payment_option_data: any = []
  paymentDiscriptionList: any = []

  async getAllPaymentOption() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    var res = await this.sale_service.getAllPaymentOption(JSON.stringify(obj));
    // console.log(res)
    this.paymentDiscriptionList = res['data']
    this.spinner.hide()
  }
  remove_paymentDiscription_item(item: any, index: any) {
    this.paymentDiscriptionList.splice(index, 1)
    // paymentOptionList
  }
  checkInPaymentDescriptionList(item: any) {
    // console.log(item)
    let flag = 0;
    if (this.paymentDiscriptionList.length == 0) {
      var obj: any = new Object();
      obj["description"] = item.mode_of_payment + "-" + item.pro_type_or_sale_value + item.interest_rate + "(%)";
      obj["payment_option_id"] = item.pay_option_id;
      obj["mode_of_payment"] = item.mode_of_payment;
      this.paymentDiscriptionList.push(obj)
    }
    else {
      this.paymentDiscriptionList.forEach((ele: any) => {
        if (ele.payment_option_id == item.pay_option_id) {
          flag = 1

        }
      })
      if (flag == 1) {
        Swal.fire('Warning...', 'Already selected', 'warning')
      }
      else {
        var obj: any = new Object();
        obj["description"] = item.mode_of_payment + "-" + item.pro_type_or_sale_value + item.interest_rate + "(%)";
        obj["payment_option_id"] = item.pay_option_id;
        obj["mode_of_payment"] = item.mode_of_payment;
        this.paymentDiscriptionList.push(obj)
      }

    }
    console.log(this.paymentDiscriptionList)
  }


  gatWayList: any = []
  async getConfigureData() {

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    // console.log(this.Obj)
    var resp = await this.GatewayMidConfig.getGatewayConfigureData(JSON.stringify(obj));

    if (resp['error'] == false) {
      this.gatWayList = resp['data']
      console.log(resp)
    }
    else {
      Swal.fire("Error", "server side Error", 'error');
    }
  }
  addrow() {
    this.gatwayData.push({})
  }
  removerow(index: any) {
    this.gatwayData.splice(index, 1)
  }
  setAccountData(item: any, index: any) {
    let dummy: any = []
    for (let i = 0; i < this.gatWayList.length; i++) {
      if (item['gateway_id'] == this.gatWayList[i]['gateway_id']) {
        dummy.push(this.gatWayList[i])
      }
    }
    this.gatwayData[index]['arr'] = dummy
    this.gatwayData[index]['mid_id'] = null
  }
  async checkDuplicate(item: any, index: any) {
    // for (let i = 0; i < this.gatwayData.length; i++) {
    //   if (this.gatWayList[i]['gateway_id'] == item['gateway_id'] && this.gatWayList[i]['mid_id'] == item['mid_id'] && index != i) {
    //     this.gatwayData[index]['gateway_id'] = null
    //     Swal.fire('Information...', 'This Account Is Already Selected', 'info')
    //     this.gatwayData.splice(index, 1)
    //   }
    // }
  }

  feature_list: any = []
  feature_obj: any = {}
  async getFeatures() {
    this.spinner.show()
    this.feature_list = []
    this.Obj['b_acct_id'] = this.b_acct_id
    this.Obj['life_cycle_status'] = 'ACTIVE'
    console.log(this.Obj)
    var resp = await this.costingService.getFeatures(JSON.stringify(this.Obj));
    if (resp['error'] == false) {
      console.log(resp['data'], 'feature list')
      this.feature_list = resp['data']
      for (let i = 0; i < this.feature_list.length; i++) {
        this.feature_obj[this.feature_list[i]['feature_code']] = this.feature_list[i]
        if (this.feature_list[i]['feature_type'] == 'Property') {
          this.all_premium_feature.push(this.feature_list[i])
        }
        if (this.feature_list[i]['feature_type'] != 'Property') {
          this.all_add_feature.push(this.feature_list[i])
        }
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }

  }
  setFilteredData(item: any, type: any, index: any) {
    let dup: any = []
    let selected_feature: any = Object.assign({}, this.feature_obj[item['feature_type']])
    let data: any = []
    let above_arr: any = []
    if (type == 'premium') {
      for (let i = 0; i < this.premium_costing_arr.length; i++) {
        above_arr.push(this.premium_costing_arr[i]['feature_type'])
      }
      for (let i = 0; i < this.feature_list.length; i++) {
        console.log(above_arr, selected_feature, this.feature_list[i])
        if (above_arr.includes(this.feature_list[i]['feature_code']) && this.feature_list[i]['feature_code'] != selected_feature['feature_code']) {
          dup.push(this.feature_list[i]['feature_code'].toLowerCase())
          data.push(this.feature_list[i])
        }
      }
    }
    if (type != 'premium') {
      for (let i = 0; i < this.add_costing_arr.length; i++) {
        above_arr.push(this.add_costing_arr[i]['feature_type'])
      }
      for (let i = 0; i < this.feature_list.length; i++) {
        if (above_arr.includes(this.feature_list[i]['feature_code']) && this.feature_list[i]['feature_code'] != selected_feature['feature_code']) {
          dup.push(this.feature_list[i]['feature_code'].toLowerCase())
          data.push(this.feature_list[i])
        }
      }
    }

    console.log(this.feature_obj[item['feature_type']])
    console.log(data)

    let basic = 'Basic'
    let ab = basic.toLowerCase()
    if (!dup.includes(ab)) {
      let obj: any = {}
      obj['feature_desc'] = 'Basic Cost'
      obj['feature_code'] = 'BASIC'
      data.push(obj)
    }
    if (type == 'premium') {
      this.premium_costing_arr[index]['arr'] = []
      this.premium_costing_arr[index]['arr'] = data
    }
    if (type != 'premium') {
      this.add_costing_arr[index]['arr'] = []
      this.add_costing_arr[index]['arr'] = data
    }
  }
  list_flag = true
  create_flag = false
  update_flag = false
  open_create() {

    this.list_flag = false
    this.create_flag = true
    this.update_flag = false
  }
  add_costing_arr: any = []
  costing_arr: any = []
  set_costingData() {
    this.costing_arr = []
    let data = []
    let obj: any = {}
    for (let i = 0; i < this.premium_costing_arr.length; i++) {
      obj = Object.assign({}, this.premium_costing_arr[i])
      obj['costing_type'] = 'PERMIUM'
      data.push(obj)
    }
    for (let i = 0; i < this.add_costing_arr.length; i++) {
      obj = Object.assign({}, this.add_costing_arr[i])
      obj['costing_type'] = 'ADDITIONAL'
      data.push(obj)
    }
    this.costing_arr = data
    console.log(data, 'costing')
  }
  async open_list() {
    this.list_flag = true
    this.create_flag = false
    this.update_flag = false
    // await this.getpropertygroup()
    await this.getAllplanLayoutForOffer()
    await this.getAllOffers()
  }
  async delete(element: any) {
    //console(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  setAllDesc(element: any) {
    let duumy: any = []
    // ======= project desc
    let proj_obj = this.projectKeyValue[element['project_cd']]
    this.project_obj = proj_obj
    console.log(proj_obj, 'project bj')
    for (let i = 1; i <= 7; i++) {
      if (proj_obj['lvl' + i + "_cd"]) {
        duumy.push(proj_obj['lvl' + i + "_value"])
        this.Obj['project_node_cd'] = proj_obj['lvl' + i + "_cd"]
        this.project_obj['project_node_cd'] = proj_obj['lvl' + i + "_cd"]
        this.project_obj['node_cd'] = proj_obj['lvl' + i + "_cd"]
      }
    }
    this.project_obj['project_path_desc'] = duumy.join(' --> ')
    // ======= Budget desc
    duumy = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (this.budhier[i]['leaf_cd'] == element['booklet_budget_cd']) {
        let bud_obj = this.budhier[i]
        this.bud_obj_booklet = this.budhier[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.bud_obj_booklet['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.bud_obj_booklet['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.bud_obj_booklet['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    // ===========
    duumy = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (this.budhier[i]['leaf_cd'] == element['booklet_gst_budget_node_cd']) {
        let bud_obj = this.budhier[i]
        this.bud_obj_booklet_gst = this.budhier[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.bud_obj_booklet_gst['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.bud_obj_booklet_gst['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.bud_obj_booklet_gst['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    duumy = []
    for (let i = 0; i < this.budhier.length; i++) {
      if (this.budhier[i]['leaf_cd'] == element['budget_cd']) {
        let bud_obj = this.budhier[i]
        this.bud_obj = this.budhier[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.bud_obj['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.bud_obj['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.bud_obj['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    duumy = []
    for (let i = 0; i < this.prodhier.length; i++) {
      if (this.prodhier[i]['leaf_cd'] == element['product_cd']) {
        let bud_obj = this.prodhier[i]
        this.prod_obj = this.prodhier[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.prod_obj['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.prod_obj['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.prod_obj['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    duumy = []
    for (let i = 0; i < this.prodhier.length; i++) {
      if (this.prodhier[i]['leaf_cd'] == element['booklet_product_cd']) {
        let bud_obj = this.prodhier[i]
        this.prod_obj_booklet = this.prodhier[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.prod_obj_booklet['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.prod_obj_booklet['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.prod_obj_booklet['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    duumy = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (this.activity_arr[i]['leaf_cd'] == element['booklet_activity_cd']) {
        let bud_obj = this.activity_arr[i]
        this.act_obj_booklet = this.activity_arr[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.act_obj_booklet['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.act_obj_booklet['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.act_obj_booklet['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    // =====================
    duumy = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (this.activity_arr[i]['leaf_cd'] == element['booklet_gst_activity_node_cd']) {
        let bud_obj = this.activity_arr[i]
        this.act_obj_booklet_gst = this.activity_arr[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.act_obj_booklet_gst['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.act_obj_booklet_gst['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.act_obj_booklet_gst['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    duumy = []
    for (let i = 0; i < this.activity_arr.length; i++) {
      if (this.activity_arr[i]['leaf_cd'] == element['activity_cd']) {
        let bud_obj = this.activity_arr[i]
        this.act_obj = this.activity_arr[i]
        for (let i = 1; i <= 7; i++) {
          if (bud_obj['lvl' + i + "_cd"]) {
            duumy.push(bud_obj['lvl' + i + "_value"])
            this.act_obj['project_node_cd'] = bud_obj['lvl' + i + "_cd"]
            this.act_obj['node_cd'] = bud_obj['lvl' + i + "_cd"]
          }
        }
        this.act_obj['project_path_desc'] = duumy.join(' --> ')
        break
      }
    }
    console.log(this.act_obj, this.act_obj_booklet, this.bud_obj, this.bud_obj_booklet, this.prod_obj, this.prod_obj_booklet, this.project_obj)
  }
  async open_update(element: any) {
    await this.setAllDesc(element)
    this.list_flag = false
    this.create_flag = false
    this.update_flag = true
    this.premium_costing_arr = []
    this.property_group_data = []
    this.add_costing_arr = []
    console.log(element)
    this.Obj = Object.assign({}, element)
    if (this.Obj['lottery_date'] != null) {
      this.Obj['lottery_date'] = this.Obj['lottery_date'].split('T')[0]
    }
    if (this.Obj['allotment_date'] != null) {
      this.Obj['allotment_date'] = this.Obj['allotment_date'].split('T')[0]
    }
    if (this.Obj['is_booklet_required'] == 1) {
      this.radioBooklet = true
      this.Obj['booklet_price'] = element['booklet_price']
      if (this.Obj['booklet_start_dt'] != null) {
        this.Obj['booklet_start_date'] = this.Obj['booklet_start_dt'].split('T')[0]
      }
      if (this.Obj['booklet_end_dt'] != null) {
        this.Obj['booklet_end_date'] = this.Obj['booklet_end_dt'].split('T')[0]
      }
      this.Obj['booklet_price'] = element['booklet_price']
      this.Obj['booklet_price'] = element['booklet_price']
    }
    this.Obj['application_start_date'] = element['application_start_dt']
    this.Obj['application_end_date'] = element['application_end_dt']
    this.Obj['pdf_doc_id'] = element['booklet_doc_id']
    this.Obj['pdf_file_name'] = element['booklet_doc_name']
    await this.getAllDocumentForUpload()
    await this.getAllPropertyOffer()
    await this.getAllSubsidyData()
    await this.getCostingByOffer()
    await this.getAllUsedAccount()
    await this.getAllLevls()
    await this.paymentOptionData()
    await this.getAllPaymentOption()
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');

  }
  // property_arr
  async getAllDocumentForUpload() {
    this.requied_doc = []
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    //console(obj)
    var resp = await this.sale_service.getAllRequiredDocumentsForUpload(JSON.stringify(obj));
    //console(resp)
    if (resp['error'] == false) {
      this.requied_doc = resp['data']
      for (let i = 0; i < this.requied_doc.length; i++) {
        for (let j = 0; j < this.required_doc_arr.length; j++) {
          if (this.requied_doc[i]['doc_type'] == this.required_doc_arr[j]['code']) {
            this.requied_doc[i]['desc'] = this.required_doc_arr[j]['value']
          }
        }
      }
      this.spinner.hide()
      //console(resp['data'], 'documents fetched data', this.required_doc_arr, this.required_doc_obj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getAllUsedAccount() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    //console(obj)
    var resp = await this.sale_service.getAllUsedAccount(JSON.stringify(obj));
    //console(resp)
    if (resp['error'] == false) {
      this.gatwayData = resp['data']
      for (let i = 0; i < this.gatwayData.length; i++) {
        let arr: any = []
        for (let j = 0; j < this.gatWayList.length; j++) {
          if (this.gatwayData[i]['gateway_id'] == this.gatWayList[j]['gateway_id']) {
            arr.push(this.gatWayList[j])
          }
        }
        this.gatwayData[i]['arr'] = arr
      }
      console.log(this.gatwayData, 'gatwayData')
      this.spinner.hide()
      //console(resp['data'], 'documents fetched data', this.required_doc_arr, this.required_doc_obj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  type = [{ id: 'Amount' }, { id: 'Percentage' }]
  async getAllSubsidyData() {
    this.subs_arr = []
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    //console(obj)
    var resp = await this.sale_service.getAllSubsidyData(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.subs_arr = resp['data']
      for (let i = 0; i < this.subs_arr.length; i++) {
        this.subs_arr[i]['subs_quantity'] = this.subs_arr[i]['subs_quantity']
        this.subs_arr[i]['cost_type'] = this.subs_arr[i]['subs_applied_on']
        this.subs_arr[i]['sub_quota'] = this.subs_arr[i]['sub_quota_cd']
        this.subs_arr[i]['quota'] = this.subs_arr[i]['quota_cd']
      }
      if (this.subs_arr.length > 0) {
        this.radioSub = true
      }
      this.spinner.hide()
      console.log(resp['data'], 'documents fetched data', this.subs_arr)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getCostingByOffer() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    //console(obj)
    var resp = await this.sale_service.getCostingByOffer(JSON.stringify(obj));
    console.log(resp, 'costing arr')
    if (resp['error'] == false) {
      let data = resp['data']
      let obj: any = {}
      obj['feature_desc'] = 'Basic Cost'
      obj['feature_code'] = 'BASIC'
      for (let i = 0; i < data.length; i++) {
        let selected_feature = Object.assign({}, this.feature_obj[data[i]['feature_code']])
        if (data[i]['costing_type'] == 'PERMIUM') {
          let ar = []
          for (let j = 0; j < this.feature_list.length; j++) {
            if (selected_feature['feature_unit'] == this.feature_list[j]['feature_unit']) {
              ar.push(this.feature_list[j])
            }
          }
          ar.push(obj)
          data[i]['arr'] = ar
          console.log(selected_feature, data[i], this.feature_list, this.feature_obj)
          this.premium_costing_arr.push(data[i])
        } else {
          let ar = []
          for (let j = 0; j < this.feature_list.length; j++) {
            if (selected_feature['feature_unit'] == this.feature_list[j]['feature_unit']) {
              ar.push(this.feature_list[j])
            }
          }
          ar.push(obj)
          data[i]['arr'] = ar
          this.add_costing_arr.push(data[i])
          selected_feature = Object.assign({}, {})
        }
      }

      this.spinner.hide()
      console.log(resp['data'], 'documents fetched data', this.subs_arr)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getAllPropertyOffer() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['offer_cd'] = this.Obj['offer_cd']
    // console.log(obj)

    var resp = await this.sale_service.getAllPropertyByOffer(obj);
    console.log(resp, 'all property')
    if (resp['error'] == false) {
      let dummy1: any = []
      let dummy: any = []
      let ob: any = {}
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['property_type'] = resp['data'][i]['pro_type']

        if (!dummy1.includes(resp['data'][i]['pro_type'])) {
          dummy.push(resp['data'][i])
          dummy1.push(resp['data'][i]['pro_type'])
          ob[resp['data'][i]['pro_type']] = resp['data'][i]
        }
      }
      for (let i = 0; i < dummy.length; i++) {
        //console(ob[dummy[i]['pro_type']], ob, 'wwwwwwwwwww')
        let obj: any = {}
        obj = Object.assign({}, dummy[i])
        obj['property_type'] = dummy[i]['pro_type']
        obj['property_arr'] = []
        obj['property_group_id'] = dummy[i]['property_group_id']
        obj['additional_cost'] = ob[dummy[i]['pro_type']]['additional_cost']
        obj['premium_cost'] = ob[dummy[i]['pro_type']]['premium_cost']
        obj['basic_cost'] = ob[dummy[i]['pro_type']]['basic_cost']
        obj['pro_type'] = obj['property_type']
        for (let j = 0; j < resp['data'].length; j++) {
          if (dummy[i]['pro_type'] == resp['data'][j]['pro_type']) {
            resp['data'][j]['id'] = resp['data'][j]['property_id']
            let sum = 0
            sum = ob[dummy[i]['pro_type']]['basic_cost'] + ob[dummy[i]['pro_type']]['additional_cost'] + ob[dummy[i]['pro_type']]['premium_cost']
            obj['property_cost'] = sum
            obj['property_no'] = resp['data'][j]['assigned_property_number']
            obj['property_arr'].push(resp['data'][j])

          }
        }
        let ob2 = this.hier_node_obj[dummy[i]['prop_hier_id']]
        console.log(ob2, this.hier_node_obj)
        let dummy2 = []
        for (let j = 1; j <= 8; j++) {
          if (ob2['lvl' + j + '_cd']) {
            dummy2.push(ob2['lvl' + j + '_value'])
          }
        }
        console.log(ob2)
        obj['from_inventory'] = dummy2.join(' --> ')
        obj['prop_hier_id'] = ob2['prop_hier_id']
        obj['hier_type_id'] = ob2['hier_type_id']
        obj['no_of_pro'] = obj['property_arr'].length
        obj['feature_id_arr'] = dummy[i]['feature_id']
        this.property_group_data.push(obj)
        this.property_group_check_data = this.property_group_data[0]['property_arr']
      }
      console.log(this.property_group_data, dummy1, dummy, ob, 'basic_cost')
      this.spinner.hide()
      console.log(resp['data'], 'documents fetched data', this.required_doc_obj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  viewdata(item: any, index: any) {
    console.log(item)
    if (item['property_arr']['pro_type'] == this.property_group_data[index]['property_arr']['pro_type']) {
      this.property_group_check_data = this.property_group_data[index]['property_arr']
    }
    for (let i = 0; i < this.property_group_check_data.length; i++) {
      this.property_group_check_data[i]['property_no'] = this.property_group_check_data[i]['assigned_property_number']
    }
    // for(let i=0;i<this.)

    //console(this.property_group_check_data)
  }


  async finaldelete(element: any) {
    let obj: any = {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.sale_service.deleteOffer(obj)
    //console(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getAllOffers()
      Swal.fire('Success', 'Deleted Successfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Deleting', 'error')
    }
  }
  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'PROPERTY'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }
  statusArr: any = [];
  selectedEle: any;
  userRoles: any = []

  async viewStatus(element: any) {
    this.selectedEle = Object.assign({}, element);
    var obj: any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'OFFER';
    obj['module_cd'] = 'PROPERTY'
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'OFFER'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'PROPERTY';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr = resp.data
      console.log(this.statusArr);
      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }


  // offerDetails={};
  // async viewDetails(element){
  //   this.offerDetails =element
  //   console.log(this.offerDetails,'offer data')
  dispBook = false
  offerDetails: any = {};
  offercost: any = [];
  documents: any = []
  async viewDetails(element: any) {
    this.offerDetails = element
    console.log(this.offerDetails, 'offer data')
    if (this.offerDetails['is_booklet_required'] == 1) {
      this.dispBook = true
    }
    const obj: any = {}
    obj['offer_cd'] = this.offerDetails['offer_cd']
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.sale_service.getAllCastingData(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      this.offercost = resp.data;
      console.log(this.offercost)
    }
    else {
      console.log("server side erroe")
    }
    var resp = await this.sale_service.getAllRequiredDocumentsForUpload(JSON.stringify(obj));
    console.log(resp, "this")
    this.documents = resp.data
    console.log(this.documents)
    $('#viewDetails').modal('show');
  }

  selectedTender = {}
  workObj: any = {}

  allOffer: any = []
  async getAllOffers() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['mode'] = 'FCFS'
    //console(obj)
    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp, 'offer')
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy: any = []
      for (let i = 0; i < this.allOffer.length; i++) {
        if (this.allOffer[i]['mode_of_sale'] == "FCFS") {
          this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
          this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
          dummy.push(this.allOffer[i])
        }
      }
      this.datasource = new MatTableDataSource(dummy);
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  validatePdfSize(input: any) {
    const fileSize = input.target.files[0].size / 1024; // in MiB
    let numb: any = fileSize.toFixed(2);
    if (numb > 3072) {
      Swal.fire('Information', 'to big, maximum is 3072 KB(3MB) . You file size is: ' + numb + ' KB', 'info')
      return false
    } else {
      return true
    }
  }


  async onFileUpload(event: any, files: any) {

    let resp = await this.validatePdfSize(event)
    if (!resp) {
      return
    }

    this.selectedFile = <File>event.target.files[0];

    if (files.length === 0) {
      return;
    }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    ////console(this.selectedFile)
  }
  async upload() {
    // let index = i
    if (this.selectedFile == null) {
      Swal.fire('Error', 'Please Select File First', 'error')
      return true
    }

    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    let obj: any = new Object();
    // obj = Object.assign({}, data)
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_name'] = this.uploader.queue[0].some.name;
    obj['doc_formate'] = 'pdf'
    obj['doc_type'] = 'Offer'
    obj['user_id'] = this.ebillUser.user_id
    obj['doc_local_no'] = 1
    this.Obj['pdf_file_name'] = obj['doc_name']
    this.spinner.show()
    //console(obj)
    const params = JSON.stringify(obj);
    this.uploader.queue[0].url = this.httpUrl + '/property/PropertyDoc/uploadDoc' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response);
      //console(resp)
      if (resp.error == false) {
        //console(resp, 'resp after success')
        this.spinner.hide();
        this.Obj['pdf_doc_id'] = resp['data']
        //console(this.Obj)
        this.uploader.queue = []
        Swal.fire('Success', 'File Uploaded Successfully!!', 'success');
      } else {
        this.spinner.hide()
        Swal.fire('Error', resp.data);
      }
    }
    return
  }
  fileURL: any;
  async view() {
    //console(this.Obj)
    this.spinner.show();
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.Obj['pdf_doc_id']
    obj['doc_name'] = this.Obj['pdf_file_name']
    //console(obj)
    var res = await this.docService.view_Doc(obj);
    //console(res)
    if (res) {
      var docname = this.Obj['pdf_file_name']
      var ext = docname.split('.');
      if (ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf/image' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.spinner.hide();
    }
  }
  show_booklet() {
    //console(this.radio)
    if (this.radioBooklet) {

      this.applicationEndDate = this.Obj['booklet_start_date']
    }
    else {
      this.applicationEndDate = this.currentDate()
    }
  }
  currentDate() {
    let n = new Date();
    let y: any = n.getFullYear();
    let m: any = n.getMonth() + 1;
    let d: any = n.getDate();
    if (m < 10) {
      m = "0" + m
    }
    if (d < 10) {
      d = "0" + d
    }
    return y + "-" + m + "-" + d;
  }
  propertyHierNodes = {}
  existing_heirarchy_nodes = []
  async getAllpropHierarchynodes() {
    // this.spinner.show()
    this.existing_heirarchy_nodes = []
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['hier_ident_code'] = this.propObj['hier_ident_code']
    obj['status'] = 'ACTIVE'
    ////console(obj)
    var resp = await this.hierarchyS.getAllpropHierarchynodes(JSON.stringify(obj));
    //console(resp, 'list all heirarchy')
    if (resp['error'] == false) {
      this.existing_heirarchy_nodes = resp['data']
      let dummy: any = []
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        this.propertyHierNodes[this.existing_heirarchy_nodes[i]['leaf_cd']] = this.existing_heirarchy_nodes[i]
        if (1 == this.existing_heirarchy_nodes[i]['level']) {
          dummy.push(this.existing_heirarchy_nodes[i])
        }
      }
      this.planing_arr[0]['existing_heirarchy_nodes'] = dummy
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }


  plan_layout = []
  selected_hier_id = 0
  set_layout_data(item: any, i: any) {
    this.plan_layout = []
    let n = 0
    for (let j = 1; j <= 8; j++) {
      if (this.Obj['lvl' + j + '_cd']) {
        n = j
      }
    }
    let hier_id = 0
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['lvl' + n + '_cd'] == this.Obj['lvl' + n + '_cd'] && this.Obj['lvl' + n + '_cd'] == this.allHier[i]['leaf_cd']) {
        {
          console.log(this.allHier[i])
          hier_id = this.allHier[i]['prop_hier_id']
          this.selected_hier_id = hier_id
        }
      }
    }
    let dummy: any = []
    for (let i = 0; i < this.allPlan_layout.length; i++) {
      if (this.allPlan_layout[i]['hier_type_id'] == this.Obj['hier_type_id'] && this.allPlan_layout[i]['prop_hier_id'] >= hier_id) {
        dummy.push(this.allPlan_layout[i])
        console.log(hier_id, this.allPlan_layout[i]['prop_hier_id'], this.allPlan_layout[i]['hier_type_id'])
      }
    }
    this.plan_layout = dummy
    console.log(this.plan_layout, 'plan_layout')
  }
  async add_dropdown(data: any, index: any) {
    if (index > 5) {
      Swal.fire('Information...', 'You Can Select Till Level 7 Only', 'info')
    } else {
      this.data.push({})
    }
    if (index == 0) {
      this.level2 = await this.commonS.onChangeLvl1(this.allHier, this.Obj)
    } else if (index == 1) {
      this.level3 = await this.commonS.onChangeLvl2(this.allHier, this.Obj)
    }
    else if (index == 2) {
      this.level4 = await this.commonS.onChangeLvl3(this.allHier, this.Obj)
    }
    else if (index == 3) {
      this.level5 = await this.commonS.onChangeLvl4(this.allHier, this.Obj)
    }
    else if (index == 4) {
      this.level6 = await this.commonS.onChangeLvl5(this.allHier, this.Obj)
    }
    else if (index == 5) {
      this.level7 = await this.commonS.onChangeLvl6(this.allHier, this.Obj)
    }
  }

  level1: any = []
  level2: any = []
  level3: any = []
  level4: any = []
  level5: any = []
  level6: any = []
  level7: any = []
  leaf_level: any = 0
  remove_dropdown(data: any, index: any) {
    if (this.data.length > 1) {
      this.data.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.Obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        this.Obj['node_cd'] = this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.Obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')

  }
  remove_dropdown2(data: any, index: any) {
    if (this.project_arr.length > 1) {
      this.project_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.project_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.project_obj['lvl' + i + '_cd']) {
        this.project_obj['leaf_cd'] = this.project_obj['lvl' + i + '_cd']
        this.project_obj['node_cd'] = this.project_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.project_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.project_obj['project_path_desc'] = dummy.join('-->')

  }
  act_arr = [{}]
  remove_dropdown2_act(data: any, index: any) {
    if (this.act_arr.length > 1) {
      this.act_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.act_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.act_obj['lvl' + i + '_cd']) {
        this.act_obj['leaf_cd'] = this.act_obj['lvl' + i + '_cd']
        this.act_obj['node_cd'] = this.act_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.act_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.act_obj['project_path_desc'] = dummy.join('-->')
  }
  remove_dropdown2_act_booklet(data: any, index: any) {
    if (this.act_arr_booklet.length > 1) {
      this.act_arr_booklet.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.act_obj_booklet['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.act_obj_booklet['lvl' + i + '_cd']) {
        this.act_obj_booklet['leaf_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        this.act_obj_booklet['node_cd'] = this.act_obj_booklet['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.act_obj_booklet['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.act_obj_booklet['project_path_desc'] = dummy.join('-->')

  }
  remove_dropdown2_act_booklet_gst(data: any, index: any) {
    if (this.act_arr_booklet_gst.length > 1) {
      this.act_arr_booklet_gst.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.act_obj_booklet_gst['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.act_obj_booklet_gst['lvl' + i + '_cd']) {
        this.act_obj_booklet_gst['leaf_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        this.act_obj_booklet_gst['node_cd'] = this.act_obj_booklet_gst['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.act_obj_booklet_gst['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.act_obj_booklet_gst['project_path_desc'] = dummy.join('-->')

  }
  bud_arr = [{}]
  remove_dropdown2_bud(data: any, index: any) {
    if (this.bud_arr.length > 1) {
      this.bud_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.bud_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.bud_obj['lvl' + i + '_cd']) {
        this.bud_obj['leaf_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.bud_obj['node_cd'] = this.bud_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.bud_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.bud_obj['project_path_desc'] = dummy.join('-->')

  }
  remove_dropdown2_bud_booklet(data: any, index: any) {
    if (this.bud_arr_booklet.length > 1) {
      this.bud_arr_booklet.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.bud_obj_booklet['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.bud_obj_booklet['lvl' + i + '_cd']) {
        this.bud_obj_booklet['leaf_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        this.bud_obj_booklet['node_cd'] = this.bud_obj_booklet['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.bud_obj_booklet['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.bud_obj_booklet['project_path_desc'] = dummy.join('-->')

  }
  remove_dropdown2_bud_booklet_gst(data: any, index: any) {
    if (this.bud_arr_booklet_gst.length > 1) {
      this.bud_arr_booklet_gst.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.bud_obj_booklet_gst['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.bud_obj_booklet_gst['lvl' + i + '_cd']) {
        this.bud_obj_booklet_gst['leaf_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        this.bud_obj_booklet_gst['node_cd'] = this.bud_obj_booklet_gst['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.bud_obj_booklet_gst['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.bud_obj_booklet_gst['project_path_desc'] = dummy.join('-->')

  }
  remove_dropdown2_prod(data: any, index: any) {
    if (this.prod_arr.length > 1) {
      this.prod_arr.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.prod_obj['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.prod_obj['lvl' + i + '_cd']) {
        this.prod_obj['leaf_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.prod_obj['node_cd'] = this.prod_obj['lvl' + i + '_cd']
        this.leaf_level = i
        dummy.push(this.prod_obj['lvl' + i + '_value'])
      } else {
        break
      }
    }
    this.prod_obj['project_path_desc'] = dummy.join('-->')
  }
  remove_dropdown2_prod_booklet(data: any, index: any) {
    if (this.prod_arr_booklet.length > 1) {
      this.prod_arr_booklet.splice(index, 1)
    }
    for (let i = index + 1; i <= 8; i++) {
      delete this.prod_obj_booklet['lvl' + i + '_cd'];
    }
    let dummy = []
    for (let i = 1; i <= 8; i++) {
      if (this.prod_obj_booklet['lvl' + i + '_cd']) {
        this.prod_obj_booklet['leaf_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        this.prod_obj_booklet['node_cd'] = this.prod_obj_booklet['lvl' + i + '_cd']
        dummy.push(this.prod_obj_booklet['lvl' + i + '_value'])
        this.leaf_level = i
      } else {
        break
      }
    }
    this.prod_obj_booklet['project_path_desc'] = dummy.join('-->')
  }
  data = [{}]
  first_list = []

  async getAllHier() {
    this.first_list = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    //console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, 'list data')
      this.first_list = resp['data']
      for (let i = 0; i < this.first_list.length; i++) {
        if (this.first_list[i]['hier_type'] != "Planing") {
          this.first_list.splice(i, 1)
        }
      }
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  allHier: any = []
  hier_node_obj: any = {}
  async getAllLevls() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    console.log(obj)
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)
    } else {
    }
  }
  async getAllLevls2() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }
  allPlan_layout = []
  async getAllplanLayoutForOffer() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "PLANLAYOUT"
    var resp = await this.landInput_S.getAllplanLayoutForOffer(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Plan Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']
        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Propetry ' + ' - ' + resp['data'][i]['count']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  allSelected = false;
  selectAll() {
    for (let i = 0; i < this.allProperties.length; i++) {
      if (!this.allSelected) {
        this.allProperties[i].is_checked = true
      } else {
        this.allProperties[i].is_checked = false
      }

    }
    this.allSelected = !this.allSelected;
    console.log(this.allProperties)
    this.datasource2.data = this.allProperties;
  }
  allProperties: any;
  datasource2: any;
  async show_proprties() {
    //console(this.propObj)
    if (this.propObj['property_type']) {
      this.propObj['b_acct_id'] = this.b_acct_id
      this.propObj['property_group'] = this.propObj['id']
      let resp = await this.sale_service.getGroupPlanLayoutProperty(this.propObj)
      console.log(resp)
      if (resp['error'] == false) {
        let dummy = []
        for (let i = 0; i < resp['data'].length; i++) {
          resp['data'][i]['is_checked'] = false
          resp['data'][i]['property_no'] = resp['data'][i]['assigned_property_number']
          if (this.propObj['property_type'] == resp['data'][i]['property_type']) {

            dummy.push(resp['data'][i])
          }
        }

        console.log(dummy)
        this.allProperties = dummy;

        this.datasource2 = new MatTableDataSource(dummy);
        this.datasource2.sort = this.sort.toArray()[0];
        this.datasource2.paginator = this.paginator.toArray()[0];
      } else {
        Swal.fire('Error', 'Error While Getting Properties', 'error')
      }
    } else {
      Swal.fire('Error...', 'Please Select All Fields', 'error')
    }
  }

  reset_property() {

    this.planing_arr = []
    this.planing_arr = [{}]
    this.datasource2 = new MatTableDataSource([]);
    this.datasource2.sort = this.sort.toArray()[0];
    this.datasource2.paginator = this.paginator.toArray()[0];
  }
  property_group_data: any = []
  property_to_saved: any = []
  async save_property() {
    //console(this.propObj)
    let flag = true
    let obj: any = {}
    for (let i = 0; i < this.property_group_data.length; i++) {
      if (this.property_group_data[i]['property_type'] == this.propObj['property_type']) {
        obj = this.property_group_data[i]
        flag = false
      }
      if (flag == false) {
        Swal.fire('Warning...', 'There Are Some Duplicate Property In The Same Property Type,System Automatically Removed Them', 'warning')
        let all_property = []
        all_property = obj['property_arr']
        let all_used_property = []
        let resp = await this.sale_service.getAllPropertyOffers(JSON.stringify(this.propObj))
        console.log(resp.data);
        let otherOfferProperty = []
        for (let i = 0; i < resp['data'].length; i++) {
          otherOfferProperty.push(resp['data'][i]['property_id'])
        }
        for (let i = 0; i < all_property.length; i++) {
          all_used_property.push(all_property[i]['id'])
        }
        for (let i = 0; i < this.datasource2.filteredData.length; i++) {
          if (!all_used_property.includes(this.datasource2.filteredData[i]['id'])) {
            {
              if (!otherOfferProperty.includes(this.datasource2.filteredData[i]['id']))
                all_property.push(this.datasource2.filteredData[i])
            }
          }
        }
        for (let i = 0; i < this.property_group_data.length; i++) {
          if (this.property_group_data[i]['property_type'] == this.propObj['property_type']) {
            this.property_group_data[i]['property_arr'] = all_property
            this.property_group_data[i]['no_of_pro'] = all_property.length
            this.property_group_data[i]['property_cost'] = this.propObj['total_cost']
          }
        }
        this.propObj = Object.assign({}, {})
        this.data = []
        this.data = [{}]
        this.Obj['hier_type_id'] = null
        this.Obj['lvl1_cd'] = null
        this.datasource2 = new MatTableDataSource([]);
        this.datasource2.sort = this.sort.toArray()[0];
        this.datasource2.paginator = this.paginator.toArray()[0];
      }
    }
    if (flag == true) {

      let dummy = []
      for (let i = 0; i < this.planing_arr.length; i++) {
        dummy.push(this.planing_arr[i]['hierarchy_type'])
      }
      let ob = this.hier_node_obj[this.selected_hier_id]
      console.log(ob, this.hier_node_obj)
      let dummy2 = []
      for (let j = 1; j <= 8; j++) {
        if (ob['lvl' + j + '_cd']) {
          dummy2.push(ob['lvl' + j + '_value'])
        }
      }
      let selectedRow = []
      for (let i = 0; i < this.datasource2.filteredData.length; i++) {
        if (this.datasource2.filteredData[i]['is_checked'] == true) {
          selectedRow.push(this.datasource2.filteredData[i])
        }
      }
      let g = true;
      let resp = await this.sale_service.getAllPropertyOffers(JSON.stringify(this.propObj))
      console.log(resp.data);
      console.log(selectedRow)
      for (let i = 0; i < selectedRow.length; i++) {
        for (let j = 0; j < resp.data.length; j++) {
          if (selectedRow[i]['id'] == resp.data[j]['property_id']) {
            Swal.fire('Error...', 'The Property number ' + resp.data[j]['assigned_property_number'] + ' Is Already Exists In ' + resp.data[j]['offer_cd'] + ' offer ', 'error')
            g = false;
            break;
          }
        }
        if (!g) {
          break
        }
      }
      if (g) {
        this.Obj['path_desc'] = dummy2.join(' --> ')
        let obj: any = {}
        obj = Object.assign({}, this.propObj)
        obj['pro_type'] = this.propObj['property_type']
        obj['no_of_pro'] = selectedRow.length
        obj['property_cost'] = this.propObj['total_cost']
        obj['from_inventory'] = this.Obj['path_desc']
        obj['prop_hier_id'] = this.selected_hier_id
        obj['life_cycle_status'] = 'ACTIVE'
        obj['hier_type_id'] = this.Obj['hier_type_id']
        if (selectedRow.length > 1) {
          obj['property_arr'] = selectedRow
        } else {
          obj['property_arr'] = selectedRow
        }
        obj['feature_id_arr'] = JSON.stringify(this.propObj['feature_id_arr'])
        this.property_group_data.push(obj)
        this.propObj = Object.assign({}, {})
        this.data = []
        this.data = [{}]
        this.Obj['hier_type_id'] = null
        this.Obj['lvl1_cd'] = null
        this.datasource2 = new MatTableDataSource([]);
        this.datasource2.sort = this.sort.toArray()[0];
        this.datasource2.paginator = this.paginator.toArray()[0];
      }

    }
    console.log(this.property_group_data)
  }
  sum_amt() {
    let sum = 0
    if (this.propObj['basic_cost'] && this.propObj['additional_cost'] && this.propObj['premium_cost']) {
      sum = Number(this.propObj['basic_cost']) + Number(this.propObj['additional_cost']) + Number(this.propObj['premium_cost'])
    }
    this.propObj['total_cost'] = sum
  }
  remove(item: any, index: any) {
    for (let i = 0; i < this.property_to_saved.length; i++) {
      if (item['property_type'] == this.property_to_saved[i]['property_type']) {
        this.property_to_saved.splice(i, 1)
      }
    }
    this.property_group_data.splice(index, 1)
  }
  save_At_step2() {
    //console(this.property_to_saved, this.property_group_data, this.Obj)
  }

  requied_doc: any = []
  add_document(is_required: any) {
    let obj: any = {}
    if (this.Obj['required_doc']) {
      let dummy = []
      for (let i = 0; i < this.requied_doc.length; i++) {
        dummy.push(this.requied_doc[i]['doc_type'])
      }
      if (!dummy.includes(this.Obj['required_doc'])) {
        obj['is_req'] = is_required
        obj['desc'] = this.required_doc_obj[this.Obj['required_doc']]
        obj['doc_type'] = this.Obj['required_doc']
        this.requied_doc.push(obj)
      } else {
        Swal.fire('Warning...', 'Duplicate Entry', 'warning')
      }
    } else {
      Swal.fire('Error', 'Please Select Dcoument type', 'error')
    }
  }
  remove_doc(item: any, index: any) {
    this.requied_doc.splice(index, 1)
  }
  subsObj: any = {}
  add_document2(is_required: any) {
    let obj: any = {}
    if (this.Obj['optional_doc']) {
      let dummy = []
      for (let i = 0; i < this.requied_doc.length; i++) {
        dummy.push(this.requied_doc[i]['doc_type'])
      }
      if (!dummy.includes(this.Obj['optional_doc'])) {
        obj['is_req'] = is_required
        obj['desc'] = this.optional_doc_obj[this.Obj['optional_doc']]

        obj['doc_type'] = this.Obj['optional_doc']
        this.requied_doc.push(obj)
      } else {
        Swal.fire('Warning...', 'Duplicate Entry', 'warning')
      }
    } else {
      Swal.fire('Error', 'Please Select Dcoument type', 'error')
    }
  }
  reset() {
    this.stepper1.selectedIndex = 0
    this.stepper2.selectedIndex = 0
    this.Obj = {}
    this.property_group_data = []
    this.docArr = []
    this.subs_arr = []
    this.requied_doc = []
  }
  subs_arr: any = []
  save_subsidy() {
    console.log(this.subsObj, 'subsobj')
    if (this.subsObj['quota'] && this.subsObj['sub_quota'] && this.subsObj['subs_type'] && this.subsObj['cost_type']) {
      this.subsObj['quota_cd'] = this.subsObj['quota']
      this.subsObj['sub_quota_cd'] = this.subsObj['sub_quota']
      this.subsObj['quota_cd'] = this.subsObj['quota']
      this.subsObj['subs_applied_on'] = this.subsObj['cost_type']
      if (this.subsObj['subs_quantity']) {
        this.subsObj['subs_quantity'] = this.subsObj['subs_quantity']
      } else {
        this.subsObj['subs_quantity'] = 0
      }
      this.subs_arr.push(this.subsObj)
      this.subsObj = {}
    } else {
      Swal.fire('Info...', 'Please Enter All Fields', 'info')
    }
  }
  remove_subs(item: any, index: any) {
    this.subs_arr.splice(index, 1)
  }

  async setObjectForProjectHierarchy() {
    let obj: any = {}
    obj = Object.assign({}, this.project_obj)
    let num = 0
    for (let i = 1; i <= 8; i++) {
      if (obj['lvl' + i + "_cd"]) {
        num = i
      }
    }
    let new_obj2: any = {}
    for (let i = 0; i < this.project_hier.length; i++) {
      console.log(this.project_hier[i]['leaf_cd'], obj['node_cd'])
      if (this.project_hier[i]['leaf_cd'] == obj['node_cd']) {
        new_obj2 = Object.assign({}, this.project_hier[i])
      }
    }
    console.log(new_obj2, this.projectKeyValue)
    for (let m = 0; m <= 7; m++) {
      if (new_obj2['lvl' + m + '_cd'] && m != 1) {
        obj['lvl' + m + '_user_cd'] = new_obj2['lvl' + m + '_user_cd']
      }
    }
    num = num + 1
    obj['lvl' + num + "_cd"] = null
    obj['lvl' + num + "_user_cd"] = this.Obj['offer_cd']
    obj['lvl' + num + "_value"] = this.Obj['offer_desc']
    obj['lvl' + num + "_type"] = 'text'
    obj['lvl' + num + "_node_type"] = 'offer'
    obj['module_cd'] = 'PROPERTY'
    obj["leaf_user_cd"] = this.Obj['offer_cd']
    obj["leaf_cd"] = null
    obj["leaf_value"] = this.Obj['offer_desc']
    obj["leaf_type"] = 'text'
    obj['lvl1_user_cd'] = new_obj2['lvl1_user_cd']
    obj["leaf_node_type"] = 'offer'
    obj["b_acct_id"] = this.b_acct_id
    console.log(obj, this.project_obj, new_obj2, this.project_hier)
    var resp = await this.hierarchyS.getprojectcreateHierarchy(obj);
    console.log(resp, 'respppppppppppppp')
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      console.log('created Successfully in accounts')
      // Swal.fire("Success...", "Successfuly Created in account", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Create A New Node..', 'error');
    }
  }

  codeValueObj: any = {}
  codeValueShowObj: any = {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj: any = {}
    var codeValueShowTempObj: any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }
  code_value_arr: any = []
  async insert_code_value() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['code_value_arr'] = this.code_value_arr

    console.log(obj)
    var resp = await this.landInput_S.createCodeValueForPlanLayout(obj)
    console.log(resp)
    if (resp['error'] == false) {
      console.log()
      this.spinner.hide()
      await this.getCodeValue_obj()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Some Error Occured While Updating', 'error')
    }
  }
  async submit() {
    if (!this.location_type_obj['offer']) {
      let obj: any = {}
      obj['term'] = 'PROPFLD5'
      obj['code'] = 'offer'
      obj['value'] = 'Offer'
      this.code_value_arr.push(obj)
    }
    let obj: any = {}
    obj = Object.assign({}, this.Obj)
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.ebillUser.user_id
    obj['prop_type_arr'] = this.property_group_data
    obj['doc_arr'] = this.requied_doc
    obj['subs_arr'] = this.subs_arr
    obj['mode_of_sale'] = 'FCFS'
    obj['project_node_cd'] = this.project_obj['node_cd']
    obj['activity_node_cd'] = this.act_obj['node_cd']
    obj['budget_node_cd'] = this.bud_obj['node_cd']
    obj['product_node_cd'] = this.prod_obj['node_cd']
    obj['booklet_product_node_cd'] = this.prod_obj_booklet['node_cd']
    obj['booklet_budget_node_cd'] = this.bud_obj_booklet['node_cd']
    obj['booklet_activity_node_cd'] = this.act_obj_booklet['node_cd']
    obj['booklet_gst_budget_node_cd'] = this.bud_obj_booklet_gst['node_cd']
    obj['booklet_gst_activity_node_cd'] = this.act_obj_booklet_gst['node_cd']
    if (!obj['project_node_cd']) {
      Swal.fire('Info..', 'Please Select Project', 'info')
      return
    }
    if (!obj['activity_node_cd']) {
      Swal.fire('Info..', 'Please Select Activity', 'info')
      return
    }
    // ==
    if (this.radioBooklet && !obj['booklet_gst_budget_node_cd']) {
      Swal.fire('Info..', 'Please Select Booklet GST Budget', 'info')
      return
    }
    if (this.radioBooklet && !obj['booklet_gst_activity_node_cd']) {
      Swal.fire('Info..', 'Please Select Booklet GST Activity', 'info')
      return
    }
    // ==

    if (!this.project_obj['lvl2_cd']) {
      Swal.fire('Info..', 'Please Select Project At Level 2', 'info')
      return
    }
    if (!obj['budget_node_cd']) {
      Swal.fire('Info..', 'Please Select Budget', 'info')
      return
    }
    if (!obj['product_node_cd']) {
      Swal.fire('Info..', 'Please Select  Product', 'info')
      return
    }
    if (this.radioBooklet && !obj['booklet_activity_node_cd']) {
      Swal.fire('Info..', 'Please Select Booklet Activity', 'info')
      return
    }
    if (this.radioBooklet && !obj['booklet_budget_node_cd']) {
      Swal.fire('Info..', 'Please Select Booklet Budget', 'info')
      return
    }
    if (this.radioBooklet && !obj['booklet_product_node_cd']) {
      Swal.fire('Info..', 'Please Select Booklet Product', 'info')
      return
    }
    console.log(obj)
    if (this.radioBooklet) {
      obj['is_booklet_required'] = 1
    } else {
      obj['is_booklet_required'] = 0
    }
    obj['booklet_start_dt'] = this.Obj['booklet_start_date']
    obj['booklet_end_dt'] = this.Obj['booklet_end_date']
    obj['booklet_doc_id'] = this.Obj['pdf_doc_id']
    obj['booklet_doc_name'] = this.Obj['pdf_file_name']
    obj['application_start_dt'] = this.Obj['application_start_date']
    obj['application_end_dt'] = this.Obj['application_end_date']
    obj['life_cycle_status'] = 'ACTIVE'
    obj['costing_arr'] = this.costing_arr
    obj['payment_option_data'] = this.paymentDiscriptionList
    let flag = true
    for (let i = 0; i < this.allOffer.length; i++) {
      if (this.allOffer[i]['offer_cd'] == this.Obj['offer_cd']) {
        flag = false
        break
      }
    }
    if (flag == true) {
      if (this.property_group_data.length < 1) {
        Swal.fire('Error...', 'You Have To Add Atleast One Property In This Offer', 'error')
      } else if (this.requied_doc.length < 1) {
        Swal.fire('Error...', 'You Have To Add Atleast One Document In This Offer', 'error')
      }
      else if (this.gatwayData.length < 1) {
        Swal.fire('Error...', 'You Have To Add Atleast One Payment Gatway', 'error')
      }
      // else
      //  if (this.subs_arr.length < 1) {
      //   Swal.fire('Error...', 'You Have To Add Atleast One Subsidy In This Offer', 'error')
      // }
      else if (this.costing_arr.length < 1) {
        Swal.fire('Error...', 'You Need To Add Atleast One Costing', 'error')
      }
      else {
        if (!this.Obj['offer_cd'] || !this.Obj['offer_name'] || !this.Obj['offer_desc'] || !this.Obj['application_start_date'] || !this.Obj['application_end_date']) {
          Swal.fire('Error', 'Please Enter all * Fields', 'error')
        } else {
          this.spinner.show()
          obj['data'] = this.gatwayData
          console.log(obj)
          let resp = await this.sale_service.createOffer(obj)
          if (resp['error'] == false) {
            if (this.code_value_arr.length > 0) {
              await this.insert_code_value()
            }
            await this.setObjectForProjectHierarchy()
            await this.setObjectForPropertyHierarchy()
            this.open_list()
            this.Obj = {}
            this.property_group_data = []
            this.requied_doc = []
            this.subs_arr = []
            this.premium_costing_arr = []
            this.add_costing_arr = []
            this.spinner.hide()
            await this.getAllOffers()
            Swal.fire('Success', 'Created Successfully', 'success')
            $('.nav-tabs a[href="#tab-7-1"]').tab('show');
          } else {
            this.spinner.hide()
            Swal.fire('Error', resp['data'], 'error')
          }
        }
      }
    } else {
      Swal.fire('Error', 'This Offer Code Is Already Exists', 'error')
    }
    //console(obj)
  }

  allAccountList: any = []
  gateWayId: any
  i:any
  async getAccountbyGatewayID(gatewayID: any, i: any) {
    this.gateWayId = gatewayID
  }
  clickingcheckbox(i: any, id: any) {
    this.gatWayList[i].selected = !this.gatWayList[i].selected
    if (this.gatWayList[i]['selected']) {
      console.log(this.gatWayList[i])
      this.gatwayData.push(this.gatWayList[i])
    } else {
      for (let index = 0; index < this.gatwayData.length; index++) {
        const element: any = this.gatwayData[index];
        if (element.id == id) {
          this.gatwayData.splice(index, 1)
        }
      }
      // this.gatWayList
    }
    console.log(this.gatwayData)
  }
  appEndDate: any;
  define_end_date_of_app_end() {
    if (this.Obj['booklet_end_date']) {
      this.appEndDate = this.Obj['application_start_date']
      return
    }

    let a = new Date(this.Obj['booklet_end_date'])
    let b = new Date(this.Obj['application_start_date'])
    if (a > b)
      this.appEndDate = this.Obj['booklet_end_date']
    else
      this.appEndDate = this.Obj['application_start_date']

  }

  applicationEndDate: any






  async setObjectForProjectHierarchy_update() {
    let obj: any = {}
    obj = Object.assign({}, this.project_obj)
    let obj2 = {}
    let num = 0
    for (let i = 1; i <= 8; i++) {
      if (obj['lvl' + i + "_cd"]) {
        obj2 = Object.assign({}, this.projectKeyValue[obj['lvl' + i + "_cd"]])
        num = i
      }
    }
    let new_obj2: any = {}
    let id = 0
    for (let i = 0; i < this.project_hier.length; i++) {
      console.log(this.project_hier[i]['leaf_cd'], obj['node_cd'])
      if (this.project_hier[i]['leaf_cd'] == obj['node_cd']) {
        new_obj2 = Object.assign({}, this.project_hier[i])
      }
    }
    console.log(this.projectLeafCode[this.Obj['offer_cd']]['id'], this.projectLeafCode[this.Obj['offer_cd']], this.projectLeafCode, this.Obj['offer_cd'])

    id = this.projectLeafCode[this.Obj['offer_cd']]['id']
    console.log(new_obj2, this.projectKeyValue, this.projectLeafCode)
    for (let m = 0; m <= 7; m++) {
      if (new_obj2['lvl' + m + '_cd'] && m != 1) {
        obj['lvl' + m + '_user_cd'] = new_obj2['lvl' + m + '_user_cd']
      }
    }
    num = num + 1
    obj['id'] = id
    obj['lvl' + num + "_cd"] = this.projectLeafCode[this.Obj['offer_cd']]['leaf_cd']
    obj['lvl' + num + "_user_cd"] = this.Obj['offer_cd']
    obj['lvl' + num + "_value"] = this.Obj['offer_desc']
    obj['lvl' + num + "_type"] = 'text'
    obj['lvl' + num + "_node_type"] = 'offer'
    obj['module_cd'] = 'PROPERTY'
    obj["leaf_user_cd"] = this.Obj['offer_cd']
    obj["leaf_cd"] = this.projectLeafCode[this.Obj['offer_cd']]['leaf_cd']
    obj['lvl1_user_cd'] = new_obj2['lvl1_user_cd']
    obj["leaf_value"] = this.Obj['offer_desc']
    obj["leaf_type"] = 'text'
    obj["leaf_node_type"] = 'offer'
    obj["b_acct_id"] = this.b_acct_id
    console.log(obj, this.project_obj, 'updated obj')
    var resp = await this.hierarchyS.projectupdateHierarchy(obj);
    console.log(resp, 'respppppppppppppp')
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getproject();
      console.log('created Successfully in accounts')
      // Swal.fire("Success...", "Successfuly Created in account", 'success');
    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Create A New Node..', 'error');
    }
  }
  async setObjectForPropertyHierarchy_update() {
    let obj: any = {}
    obj = Object.assign({}, this.project_obj)
    let num = 0
    for (let i = 1; i <= 8; i++) {
      if (obj['lvl' + i + "_cd"]) {
        obj['lvl' + i + "_cd"] = obj['lvl' + i + "_user_cd"]
        num = i
      }
    }
    let new_obj2: any = {}
    for (let i = 0; i < this.project_hier.length; i++) {
      console.log(this.project_hier[i]['leaf_cd'], obj['node_cd'])
      if (this.project_hier[i]['leaf_cd'] == obj['node_cd']) {
        new_obj2 = Object.assign({}, this.project_hier[i])
      }
    }
    console.log(new_obj2, this.projectKeyValue)
    for (let m = 0; m <= 7; m++) {
      if (new_obj2['lvl' + m + '_cd'] && m != 1) {
        obj['lvl' + m + '_user_cd'] = new_obj2['lvl' + m + '_user_cd']
        obj['lvl' + m + '_cd'] = new_obj2['lvl' + m + '_user_cd']
      }
    }
    num = num + 1
    obj['lvl' + num + "_user_cd"] = this.Obj['offer_cd']
    obj['lvl' + num + "_cd"] = obj['lvl' + num + "_user_cd"]
    obj['lvl' + num + "_value"] = this.Obj['offer_desc']
    obj['lvl' + num + "_type"] = 'text'
    obj['lvl' + num + "_node_type"] = 'offer'
    obj['module_cd'] = 'PROPERTY'
    obj["leaf_cd"] = this.Obj['offer_cd']
    obj["leaf_value"] = this.Obj['offer_desc']
    obj['lvl1_cd'] = new_obj2['lvl1_user_cd']
    obj["leaf_type"] = 'text'
    obj["leaf_node_type"] = 'offer'
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['b_acct_id'] = this.b_acct_id;
    obj['hier_type_id'] = 1
    for (let i = 0; i < this.allHier.length; i++) {
      if (this.allHier[i]['leaf_cd'] == obj['leaf_cd']) {
        obj['id'] = this.allHier[i]['id']
        break
      }
    }
    let obj2: any = {}
    obj2['loc_type_cd'] = 'offer'
    obj2['loc_id_std'] = this.ebillUser['account_short_name']
    obj2['loc_id_str'] = obj['leaf_cd']
    obj2['life_cycle_status'] = 'ACTIVE'
    obj2['loc_short_nm'] = obj['leaf_cd'] + ' - ' + obj['leaf_value']
    let dummy = []
    dummy.push(obj2)
    for (let m = 0; m <= 7; m++) {
      if (new_obj2['lvl' + m + '_cd'] && m != 1) {
        obj['lvl' + m + '_cd'] = new_obj2['lvl' + m + '_user_cd']
        obj['lvl' + m + '_value'] = new_obj2['lvl' + m + '_value']
        obj['lvl1_cd'] = new_obj2['lvl1_cd']
      }
    }
    obj['lvl2_cd'] = new_obj2['lvl2_cd']
    obj['lvl1_cd'] = new_obj2['lvl1_cd']
    obj['old_data'] = dummy
    obj['data'] = dummy
    console.log(obj)
    var resp = await this.hierarchyS.update_Location_hier_nodes(obj);
    console.log(resp, 'Property Hierarchy Inserted')

  }
  async setObjectForPropertyHierarchy() {
    let obj: any = {}
    obj = Object.assign({}, this.project_obj)
    let num = 0
    for (let i = 1; i <= 8; i++) {
      if (obj['lvl' + i + "_cd"]) {
        obj['lvl' + i + "_cd"] = obj['lvl' + i + "_user_cd"]
        num = i
      }
    }
    let new_obj2: any = {}
    for (let i = 0; i < this.project_hier.length; i++) {
      if (this.project_hier[i]['leaf_cd'] == obj['node_cd']) {
        new_obj2 = this.project_hier[i]
      }
    }
    num = num + 1
    obj['lvl' + num + "_user_cd"] = this.Obj['offer_cd']
    obj['lvl' + num + "_cd"] = obj['lvl' + num + "_user_cd"]
    obj['lvl' + num + "_value"] = this.Obj['offer_desc']
    obj['lvl' + num + "_type"] = 'text'
    obj['lvl' + num + "_node_type"] = 'offer'
    obj['module_cd'] = 'PROPERTY'
    obj["leaf_cd"] = this.Obj['offer_cd']
    obj["leaf_value"] = this.Obj['offer_desc']
    obj["leaf_type"] = 'text'
    obj["leaf_node_type"] = 'offer'
    obj['create_user_id'] = this.ebillUser.user_id;
    obj['b_acct_id'] = this.b_acct_id;
    obj['hier_type_id'] = 1
    let obj2: any = {}
    obj2['loc_type_cd'] = 'offer'
    obj2['loc_id_std'] = this.ebillUser['account_short_name']
    obj2['loc_id_str'] = obj['leaf_cd']
    obj['lvl1_cd'] = new_obj2['lvl1_user_cd']
    obj2['life_cycle_status'] = 'ACTIVE'
    obj2['loc_short_nm'] = obj['leaf_cd'] + ' - ' + obj['leaf_value']
    for (let m = 0; m <= 7; m++) {
      if (new_obj2['lvl' + m + '_cd'] && m != 1) {
        obj['lvl' + m + '_cd'] = new_obj2['lvl' + m + '_user_cd']
        obj['lvl' + m + '_value'] = new_obj2['lvl' + m + '_value']
        obj['lvl1_cd'] = new_obj2['lvl1_cd']
      }
    }
    obj['lvl2_cd'] = new_obj2['lvl2_cd']
    obj['lvl1_cd'] = new_obj2['lvl1_cd']
    let dummy = []
    dummy.push(obj2)
    obj['data'] = dummy
    console.log(obj, this.project_obj)
    var resp = await this.hierarchyS.createLocationHierarchyNodes(obj);
    console.log(resp, 'Property Hierarchy Inserted')

  }
  async update() {
    console.log(this.bud_obj_booklet_gst);
    console.log(this.bud_obj_booklet)
    console.log(this.act_obj_booklet)
    console.log(this.act_obj_booklet_gst)
    let obj: any = {}
    obj = Object.assign({}, this.Obj)
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.ebillUser.user_id
    obj['prop_type_arr'] = this.property_group_data
    obj['doc_arr'] = this.requied_doc
    obj['subs_arr'] = this.subs_arr
    obj['mode_of_sale'] = 'FCFS'
    obj['payment_option_data'] = this.paymentDiscriptionList
    if (this.radioBooklet) {
      obj['is_booklet_required'] = 1
    } else {
      obj['is_booklet_required'] = 0
    }
    obj['booklet_start_dt'] = this.Obj['booklet_start_date']
    obj['booklet_end_dt'] = this.Obj['booklet_end_date']
    obj['booklet_doc_id'] = this.Obj['pdf_doc_id']
    obj['booklet_doc_name'] = this.Obj['pdf_file_name']
    obj['application_start_dt'] = this.Obj['application_start_date']
    obj['application_end_dt'] = this.Obj['application_end_date']
    obj['life_cycle_status'] = 'ACTIVE'
    obj['costing_arr'] = this.costing_arr
    obj['project_node_cd'] = this.project_obj['node_cd']
    obj['activity_node_cd'] = this.act_obj['node_cd']
    obj['budget_node_cd'] = this.bud_obj['node_cd']
    obj['product_node_cd'] = this.prod_obj['node_cd']
    obj['booklet_product_node_cd'] = this.prod_obj_booklet['node_cd']
    obj['booklet_budget_node_cd'] = this.bud_obj_booklet['node_cd']
    obj['booklet_activity_node_cd'] = this.act_obj_booklet['node_cd']
    obj['booklet_gst_budget_node_cd'] = this.bud_obj_booklet_gst['node_cd']
    obj['booklet_gst_activity_node_cd'] = this.act_obj_booklet_gst['node_cd']

    if (!obj['project_node_cd']) {
      Swal.fire('Info..', 'Please Select Project', 'info')
      return
    }
    if (!this.project_obj['lvl2_cd']) {
      Swal.fire('Info..', 'Please Select Project At Level 2', 'info')
      return
    }





    console.log(obj['booklet_gst_budget_node_cd'])
    // if (!obj['booklet_gst_budget_node_cd']) {
    //   Swal.fire('Info..', 'Please Select Booklet GST Budget', 'info')
    //   return
    // }
    // if (!obj['booklet_gst_activity_node_cd']) {
    //   Swal.fire('Info..', 'Please Select Booklet GST Activity', 'info')
    //   return
    // }
    if (!obj['activity_node_cd']) {
      Swal.fire('Info..', 'Please Select Activity', 'info')
      return
    }
    if (!obj['budget_node_cd']) {
      Swal.fire('Info..', 'Please Select Budget', 'info')
      return
    }
    if (!obj['product_node_cd']) {
      Swal.fire('Info..', 'Please Select  Product', 'info')
      return
    }
    // if (!obj['booklet_activity_node_cd']) {
    //   Swal.fire('Info..', 'Please Select Booklet Activity', 'info')
    //   return
    // }
    // if (!obj['booklet_budget_node_cd']) {
    //   Swal.fire('Info..', 'Please Select Booklet Budget', 'info')
    //   return
    // }
    // if (!obj['booklet_product_node_cd']) {
    //   Swal.fire('Info..', 'Please Select Booklet Product', 'info')
    //   return
    // }
    let flag = true
    for (let i = 0; i < this.allOffer.length; i++) {
      if (this.allOffer[i]['offer_cd'] == this.Obj['offer_cd']) {
        flag = false
        break
      }
    }

    if (this.property_group_data.length < 1) {
      Swal.fire('Error...', 'You Have To Add Atleast One Property In This Offer', 'error')
    } else if (this.requied_doc.length < 1) {
      //console(this.Obj, this.subs_arr, this.property_group_data, this.requied_doc)
      Swal.fire('Error...', 'You Have To Add Atleast One Document In This Offer', 'error')
    }
    else if (this.gatwayData.length < 1) {
      //console(this.Obj, this.subs_arr, this.property_group_data, this.requied_doc)
      Swal.fire('Error...', 'You Have To Add Atleast One Payment Gatway', 'error')
    }
    else if (this.radioSub && this.subs_arr.length < 1) {
      //console(this.Obj, this.subs_arr, this.property_group_data, this.requied_doc)
      Swal.fire('Error...', 'You Have To Add Atleast One Subsidy In This Offer', 'error')
    }
    else if (this.costing_arr.length < 1) {
      Swal.fire('Error...', 'You Need To Add Atleast One Costing', 'error')
    }
    else {
      if (!this.Obj['offer_cd'] || !this.Obj['offer_name'] || !this.Obj['offer_desc'] || !this.Obj['application_start_date'] || !this.Obj['application_end_date']) {
        Swal.fire('Error', 'Please Enter all * Fields', 'error')
      } else {
        // this.spinner.show()
        obj['data'] = this.gatwayData
        console.log(obj)
        let resp = await this.sale_service.updateOffer(obj)
        if (resp['error'] == false) {
          // await this.createEvent()
          await this.setObjectForProjectHierarchy_update()
          await this.setObjectForPropertyHierarchy_update()
          this.Obj = {}
          this.property_group_data = []
          this.requied_doc = []
          this.subs_arr = []
          this.spinner.hide()
          this.open_list()
          await this.getAllOffers()
          Swal.fire('Success', 'Updated Successfully', 'success')
          $('.nav-tabs a[href="#tab-7-1"]').tab('show');
        } else {
          this.spinner.hide()
          Swal.fire('Error', 'Some Error Occured While Updating', 'error')
        }
      }
    }

    //console(obj)
  }
  premium_costing_arr: any = []
  addPremiunCosting() {
    this.premium_costing_arr.push({ is_checked: false })
  }
  addAdditionalCosting() {
    this.add_costing_arr.push({ is_checked: false })
  }
  removeAdditionalCosting(index: any, data: any) {
    this.add_costing_arr.splice(index, 1)
  }
  removePremiunCosting(index: any, data: any) {
    this.premium_costing_arr.splice(index, 1)
  }
  async createEvent() {
    let obj: any = {}
    obj['event_source'] = 'PROPERTY'
    obj['event_code'] = "PROPERTY" + this.Obj['offer_cd']
    obj['event_desc'] = this.Obj['offer_name']
    obj['event_record_code'] = 'R101'
    obj['create_user_id'] = this.ebillUser.user_id
    obj['bud_cd'] = 'B11105'
    obj['proj_cd'] = this.Obj['offer_cd']
    obj['prod_cd'] = 'PD130100'
    obj['act_cd'] = 'AC110000'
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.sale_service.createEvent(obj)
    //console(resp)
    if (resp['error'] == false) {
      await this.createEventHierarchy()
      //console('Event Created')
    } else {
      Swal.fire('Error', 'Error While Creating', 'error')
    }
  }
  async createEventHierarchy() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['lvl1_cd'] = 'PROPERTY'
    obj['lvl1_value'] = 'PROPERTY DEPARTMENT ROOT'
    obj['lvl2_cd'] = this.Obj['offer_cd']
    obj['lvl2_value'] = this.Obj['offer_name']
    obj['lvl3_cd'] = ''
    obj['lvl3_value'] = ''
    obj['lvl4_cd'] = ''
    obj['lvl4_value'] = ''
    obj['lvl5_cd'] = ''
    obj['lvl5_value'] = ''
    obj['lvl6_cd'] = ''
    obj['lvl6_value'] = ''
    obj['lvl7_cd'] = ''
    obj['lvl7_value'] = ''
    obj['leaf_cd'] = this.Obj['offer_cd']
    obj['module_cd'] = 'PROPERTY'
    obj['leaf_value'] = this.Obj['offer_name']
    obj['create_user_id'] = this.ebillUser.user_id
    obj['table_name'] = 'proj_hier'
    let resp = await this.sale_service.createEventHierarchy(obj)
    //console(resp)
    if (resp['error'] == false) {
      //console('hierarchy Created')
    } else {
      Swal.fire('Erorr', 'Error While Creating', 'error')
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  ///************************************work flow ececution code************************************** */

  //workflow*********************************************//
  apprflag = false;
  level_for_approval = []
  workflow_level1: any = {}
  async getWorkflowlog() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'OFFER';
    this.apprflag = false;
    var resp = await this.portalmain.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      let currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
          this.apprflag = true
        }
      }
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }


  undertakings: any
  salBill_apprdata: any
  async Appr(element: any) {
    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['offer_name']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'OFFER'//this.salBill_apprdata['id']
    this.salBill_apprdata['node_cd'] = element['project_cd']
    this.salBill_apprdata['module_cd'] = 'PROPERTY';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'OFFER', 'PROPERTY');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#myModal3').modal('show');
  }
  message_flag = false
  async getle(user_id: any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name']
      )
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }
  async sendmessage(mobile: any, user_name: any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['doc_local_no'], user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.successToastr("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }
  // async sendmessage(mobile) {
  //   console.log(mobile, 'offer of' + ' Number ' + this.salBill_apprdata['doc_local_no'])
  //   let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'] + ' Number ' + this.salBill_apprdata['doc_local_no'])
  //   if (resp['error'] == false) {

  //     this.spinner.hide();

  //   } else {
  //     this.spinner.hide();
  //     Swal.fire("Error", resp['data'], 'error');
  //   }
  // }

  forwarded_user_id: any;
  appr_remark: any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();



      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'OFFER'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'UNDERAPPROVAL')
        await this.getAllOffers()
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async ChangestatusSalBill(data: any, status: any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp = await this.sale_service.changeOfferStatus(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
      // let data = resp['data']
      // this.budhier = []
      // this.budhier = data
      // console.log(data, 'budget hier')
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp['data']);
    }
  }


  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb: any = new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'OFFER'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'PROPERTY'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.successToastr("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
}
