<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Deduction -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_ded_flag" class="nav-link active" href="#tab-1" data-toggle="tab"
                                (click)="refresh()"><i class="ti-bar-chart"></i>
                                <!-- Deduction List -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION2']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_ded_flag" class="nav-link" href="#tab-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle"></i>
                                <!-- Create Deduction -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="u_ded_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                <!-- Update
                                Deduction -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION4']}}
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_ded_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Deduction
                                </button>
                            </div>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="deduction_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            <!-- Deduction ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.deduction_id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_owner">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header> Deduction
                                            Owner

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.ded_owner }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="apply_on">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Deduction
                                            Apply ON

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.ded_apply_on }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Deduction Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <div *ngIf="element.ded_owner=='USER'">
                                                {{ element.ded_bus_name }}
                                            </div>
                                            <div *ngIf="element.ded_owner=='SYSTEM'">
                                                {{ to_show_System_ded[element.ded_bus_name] }}
                                            </div>
                                        </td>

                                    </ng-container>

                                    <ng-container matColumnDef="ded_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Deduction Type -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.ded_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="ded_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Deduction Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.ded_amt }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" (click)="open_update(element)">
                                                <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION12']}}
                                            </button>
                                            <button class="btn btn-danger" (click)="delete(element)">
                                                <!-- Delete -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION13']}}
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="c_ded_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Deduction List </button>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> Deduction OWNER : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="ded_owner" bindLabel="value" bindValue="value"
                                        [multiple]="false" (change)="change_owner()"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_owner']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input type="text" class="form-control" [(ngModel)]="deduction['ded_owner']"
                                    placeholder="USER" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row" *ngIf="deduction['ded_owner'] == 'USER'">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> Deduction Name
                                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION5']}} -->:
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="deduction['ded_bus_name']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION19']}}">
                                </div>
                            </div>
                            <div class="row" *ngIf="deduction['ded_owner'] == 'SYSTEM'">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Deduction Name
                                        <!-- Deduction ID -->
                                        <!-- {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION6']}} -->:
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="system_deduction" bindLabel="value" bindValue="code"
                                        [multiple]="false" (change)="change_dedname()"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION19']}}"
                                        [(ngModel)]="deduction['ded_bus_name']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Budget : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <button class=" btn btn-outline-primary" (click)="selectbudgetbtn()">Budget</button>
                                    <br>
                                    {{budpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Activity : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class=" btn btn-outline-primary" (click)="selectactbtn()">Activity</button>
                                    <br>
                                    {{actpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction Category -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION14']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="dedduction_dropdown" bindLabel="ded_value"
                                        bindValue="ded_category" [multiple]="false" *ngIf="!flag"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="dedduction_dropdown" bindLabel="ded_value"
                                        bindValue="ded_category" [multiple]="false" *ngIf="flag" disabled
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction Apply On -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION15']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="applyon" bindLabel="value" bindValue="code" [multiple]="false"
                                        *ngIf="!flag"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION21']}}"
                                        [(ngModel)]="deduction['apply_on']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="applyon" bindLabel="value" bindValue="code" [multiple]="false"
                                        *ngIf="flag" disabled
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION21']}}"
                                        [(ngModel)]="deduction['apply_on']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> Deduction Category : <span
                                            style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <ng-select [items]="ded_cat" bindLabel="value" bindValue="value" [multiple]="false"
                                        *ngIf="!flag" placeholder="Deduction Category"
                                        [(ngModel)]="deduction['ded_cat']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="ded_cat" bindLabel="value" bindValue="value" [multiple]="false"
                                        *ngIf="flag" disabled placeholder="Deduction Category"
                                        [(ngModel)]="deduction['ded_cat']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                    <input type="text"  placeholder="{{DeductionCategory}}" class="form-control"  [(ngModel)]="deduction['ded_cat']"disabled >
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 *ngIf="deduction['ded_type'] !='PERCENTAGE'" style="margin-left: 2%;">
                                        <!-- Deduction Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION8']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                    <h6 *ngIf="deduction['ded_type']== 'PERCENTAGE'" style="margin-left: 2%;">
                                        <!-- Deduction Amount In % -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION16']}}:
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input *ngIf="!flag" class="form-control" type="number"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['ded_amt']">
                                    <input class="form-control" type="number"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['ded_amt']" *ngIf="flag" disabled>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6> Enter Event Code: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input *ngIf="!flag" class="form-control" type="number" placeholder="Enter Event Code"
                                        [(ngModel)]="deduction['event_cd']"> -->
                                    <input class="form-control" type="text" placeholder="Enter Event Code"
                                        [(ngModel)]="deduction['event_cd']">
                                    <!-- *ngIf="flag" disabled> -->


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="create()">
                                        <!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION17']}}
                                    </button>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="u_ded_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Deduction List </button>
                            </div>


                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> Deduction OWNER : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ded_owner" bindLabel="value" bindValue="value"
                                        [multiple]="false" (change)="change_owner()"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_owner']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row" *ngIf="deduction['ded_owner'] == 'USER'">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction ID -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION5']}}: <span
                                            style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="deduction['ded_bus_name']"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION19']}}">
                                </div>
                            </div>
                            <div class="row" *ngIf="deduction['ded_owner'] == 'SYSTEM'">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction ID -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION6']}}: <span
                                            style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="system_deductiontocheck" bindLabel="value" bindValue="code"
                                        [multiple]="false" (change)="change_dedname()"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION19']}}"
                                        [(ngModel)]="deduction['ded_bus_name']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Budget : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <button class=" btn btn-outline-primary"
                                        (click)="selectbudgetbtnupdate()">Budget</button>
                                    <br>
                                    {{budpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">Select Activity : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <button class=" btn btn-outline-primary"
                                        (click)="selectactbtnupdate()">Activity</button>
                                    <br>
                                    {{actpath}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction Category -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION14']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="dedduction_dropdown" bindLabel="ded_value"
                                        bindValue="ded_category" [multiple]="false" *ngIf="!flag"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="dedduction_dropdown" bindLabel="ded_value"
                                        bindValue="ded_category" [multiple]="false" *ngIf="flag" disabled
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION20']}}"
                                        [(ngModel)]="deduction['ded_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Deduction Apply On -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION15']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="applyon" bindLabel="value" bindValue="code" [multiple]="false"
                                        *ngIf="!flag"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION21']}}"
                                        [(ngModel)]="deduction['apply_on']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="applyon" bindLabel="value" bindValue="code" [multiple]="false"
                                        *ngIf="flag" disabled
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION21']}}"
                                        [(ngModel)]="deduction['apply_on']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;"> Deduction Category : <span
                                            style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ded_cat" bindLabel="value" bindValue="value" [multiple]="false"
                                        *ngIf="!flag" placeholder="Deduction Category"
                                        [(ngModel)]="deduction['ded_cat']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                    <ng-select [items]="ded_cat" bindLabel="value" bindValue="value" [multiple]="false"
                                        *ngIf="flag" disabled placeholder="Deduction Category"
                                        [(ngModel)]="deduction['ded_cat']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <h6 *ngIf="deduction['ded_type'] !='PERCENTAGE'" style="margin-left: 2%;">
                                        <!-- Deduction Amount -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION8']}}
                                        : <span style="color: red;">*</span>
                                    </h6>
                                    <h6 *ngIf="deduction['ded_type']== 'PERCENTAGE'" style="margin-left: 2%;">
                                        <!-- Deduction Amount In % -->
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION16']}}:
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input *ngIf="!flag" class="form-control" type="number"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['ded_amt']">
                                    <input class="form-control" type="number"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['ded_amt']" *ngIf="flag" disabled>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4">
                                    <h6 style="margin-left: 2%;">
                                        <!-- Enter Event Code --> Enter Event Code : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input *ngIf="!flag" class="form-control" type="text"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['event_cd']">
                                    <input class="form-control" type="text"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION22']}}"
                                        [(ngModel)]="deduction['event_cd']" *ngIf="flag" disabled>
                                </div>
                            </div>
                            <br>
                            <br>
                            <!-- <div class="row"> -->
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" (click)="update()">
                                            <!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-DEDUCTION12']}}
                                        </button>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>



    <div class="modal" id="select3">
        <!-- creat modal of budget-->
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Budget Herirarchy :</h4>
                    <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div>
                        <div>


                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-1"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span
                                            style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-2">
                                    <span
                                        *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="code" [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Node -->
                                </div>
                                <div class="col-3" *ngIf="i==planing_arr.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2 ">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span
                                            style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-9">
                                    {{budpath}}
                                </div>
                            </div>
                            <br>



                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitbud()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy26']}}</button>
                                    <!-- Submit -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="select4">
        <!-- creat modal of Activity -->
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Activity Herirarchy :</h4>
                    <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div>
                        <div>


                            <div class="row" *ngFor="let item of planing_arr1,let i =index">
                                <div class="col-1"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span
                                            style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-2">
                                    <span
                                        *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="code" [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setactHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-3" *ngIf="i==planing_arr1.length-1">
                                    <button (click)="add_dropdown_act(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown_act(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2 ">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span
                                            style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-9">
                                    {{actpath}}
                                </div>
                            </div>
                            <br>
                            <hr>


                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitact()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy26']}}</button>
                                    <!-- Submit -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal" id="select33">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Budget Herirarchy :</h4>
                    <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div>
                        <div>


                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-1"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span
                                            style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-2">
                                    <span
                                        *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="code" [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setbudHierarchyNodeforupdate(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-3" *ngIf="i==planing_arr.length-1">
                                    <button (click)="add_dropdownforupdate(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdownupdate(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2 ">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span
                                            style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-9">
                                    {{budpath}}
                                </div>
                            </div>
                            <br>



                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitbudupdate()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy26']}}</button>
                                    <!-- Submit -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="select44">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Activity Herirarchy :</h4>
                    <!--<button type="button" class="close"data-bs-dismiss="modal">&times;</button>-->
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div>
                        <div>


                            <div class="row" *ngFor="let item of planing_arr1,let i =index">
                                <div class="col-1"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span
                                            style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-2">
                                    <span
                                        *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy17']}} </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="code" [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setactHierarchyNodeforupdate(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-3" *ngIf="i==planing_arr1.length-1">
                                    <button (click)="add_dropdownforupdate_act(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdownupdate_act(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-1"></div>
                                <div class="col-2 ">
                                    <h6 style="margin-left: 2%;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span
                                            style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-9">
                                    {{actpath}}
                                </div>
                            </div>
                            <br>
                            <hr>


                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitact()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                        Hierarchy26']}}</button>
                                    <!-- Submit -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner type="ball-atom">Loading....</ngx-spinner>