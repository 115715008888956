import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { MainService } from '../../service/main.service';

import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { ComplainServiceService } from '../service/complain-service.service';
import { DashboardServiceService } from '../service/dashboard-service.service';
import { Router } from '@angular/router'

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as pdfMake from "pdfmake/build/pdfmake";

import * as XLSX from 'xlsx';
import swal from 'sweetalert2';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { FileUploader } from 'ng2-file-upload';
import { LocationService } from '../service/location.service';
import { ZoneService } from '../service/zone.service';
import { MainService } from '../service/main.service';
import { DataControlService } from '../service/data-control.service'
// import { element } from 'protractor';
import { DatePipe } from '@angular/common';


declare var $: any;

declare const google: any;
@Component({
  selector: 'app-icms-dashborad',
  templateUrl: './icms-dashborad.component.html',
  styleUrls: ['./icms-dashborad.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class IcmsDashboradComponent implements OnInit {
  erpUser: any;
  b_acct_id: any;
  complaint_number: any = {};
  total_inprogress:any
  total_completed:any
  total_assign:any
  showTable:any = false;
  dashboard:any = true;
  complaint:any = false;
  header:any = false;
  Obj:any = {}
  user_id_d :any= {}
  Attendnce : boolean = false;
  location_area:any;
  displayedColumns:any = []
  displayedColumns1 = ['id', 'location_type', 'zone_name', 'zone_cd_value', 'action'];
  displayedColumns2 = ['id', 'emp_name', 'emp_phone_no', 'zone_name', 'action'];
  displayedColumns3 = ['id', 'complain_app_id', 'complain_date', 'complain_from', 'complian_type', 'zone_name', 'property_address', 'status','assign_user'];
  displayedColumns4 = ['id', 'complain_app_id', 'complain_date', 'complain_from', 'complian_type', 'zone_name', 'emp_name', 'property_address', 'status'];
  displayedColumns5 = ['id', 'complain_app_id', 'complain_date', 'complain_from', 'complian_type', 'zone_name', 'emp_name', 'property_address', 'status'];
  displayedColumns6 = ['id', 'case_id', 'notice_id_f', 'section', 'zone_name', 'status'];
  displayedColumns7 = ['id', 'case_id', 'complain_app_id', 'property_address', 'zone_name', 'status'];
  displayedColumns8 = ['id', 'case_id', 'complain_app_id', 'case_status', 'zone_name', 'status'];
  displayedColumns9 = ['id', 'case_id_f', 'co_ord_id', 'co_ord_date', 'order_status', 'owner_name', 'owner_mobile'];
  displayedColumns10 = ['id', 'case_id_f', 'co_ord_id', 'co_ord_date', 'order_status', 'owner_name', 'owner_mobile'];
  displayedColumns11 = ['id', 'case_id_f', 'co_ord_id', 'co_ord_date', 'order_status', 'owner_name', 'owner_mobile'];
  displayedColumns12 = ['id', 'location', 'zone_name', 'zone_cd_value', 'action'];
  displayedColumns13 = ['id','emp_name','user_id','attendance_date', 'attendance_time','life_cycle_status'];
  life_cycle_status:any
  datasource: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  zoom: any;
  lat: number = 26.850000;
  lng: number = 80.949997;
  mapType = 'satellite';
  myDate = new Date();
  paths: any = []
  paths2: any = []

  loaction_type = [
    { id: 1, name: 'ZONE' },

  ]
  user_id: any = {};
  end_num: any;
  total_case: any;
  total_notice: any;
  total_case_in_progress: any;
  demolition: any;
  compunding: any;
  self_demolition: any;
  accessed_zone = []
  accessed_complain = []
  // attendence: any;
  complaint_list_flag :any ;
  pdf:boolean = false;
  constructor(public ComplainService: DashboardServiceService,
    private spinner: NgxSpinnerService, private locationS: LocationService,
    public mainService: MainService, public zone: ZoneService,
    public toastr: ToastrService, private sanitizer: DomSanitizer,
    private dataControlService: DataControlService,
    private datePipe: DatePipe
  ) { }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    let accessedData = await this.dataControlService.getmappedcomplain({ user_id: this.user_id, b_acct_id: this.b_acct_id });
    this.accessed_zone = accessedData.zone_array;
    this.accessed_complain = accessedData.compain_array;
    console.log(accessedData)
    await this.getAllDashCount();

    this.paths = []
    this.paths2 = []
  }

  async getAllDashCount() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone;
    obj['complain_id'] = this.accessed_complain;
    obj['attendance_date'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    console.log(obj)
    var resp:any = await this.ComplainService.getAllDashCount(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.complaint_number = resp['data'][0][0]['count_ids']
      this.total_inprogress = resp['data'][1][0]['total_inprogress']
      this.total_completed = resp['data'][2][0]['total_completed']
      this.total_assign = resp['data'][3][0]['total_assign']
      this.total_case = resp['data'][4][0]['case_id']
      this.total_notice = resp['data'][5][0]['case_id'];
      this.total_case_in_progress = resp['data'][6][0]['case_id'];
      this.demolition = resp['data'][7][0]['co_ord_id']
      this.compunding = resp['data'][8][0]['co_ord_id']
      this.self_demolition = resp['data'][9][0]['co_ord_id']
      // this.attendence = resp['data'][10][0]['present_user']
      // console.log(this.attendence,this.demolition, this.compunding, this.self_demolition)
    }


  }
  onPolyClick($event: any) {
    //console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  dashboard1() {
    this.showTable = false
    this.dashboard = true;
    this.complaint = false;
    this.pdf = false

  }
  /////////////////////////////////map/////////////////////////////////
  view_zone() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true
    this.pdf = false
    // this.getLocationList();
    this.getallzonedetailList();

  }
  async getLocationList() {

    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = true;
    this.pdf = false
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id
    obj['zone_cd'] = this.accessed_zone;
    // console.log(obj)
    var resp :any= await this.ComplainService.getcomplaint(JSON.stringify(obj));
    // console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns1
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async getallzonedetailList() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = true;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone;
    // console.log(obj)
    var resp:any = await this.ComplainService.getallcomplaintdetails(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.pdf = true
      this.displayedColumns = this.displayedColumns3
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
       console.log(this.datasource)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }


  // markers = [
  //   {

  //     lat: 26.8467,
  //     lng: 80.9462,
  //     label: "1",
  //     draggable: true
  //   },

  // ]
  async getAllCasesDtls() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone
    // console.log(obj)
    this.spinner.show();
    var resp:any = await this.ComplainService.getAllCasesDtls(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns7
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async CaseInProgress() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone;
    // console.log(obj)
    this.spinner.show();
    var resp:any = await this.ComplainService.CaseInProgress(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns8
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async getDemolitionorder() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
obj['zone_cd'] = this.accessed_zone
    // console.log(obj)
    var resp:any = await this.ComplainService.Demolitionorders(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns9
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getCompundingorder() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
obj['zone_cd'] = this.accessed_zone
    // console.log(obj)
    var resp:any = await this.ComplainService.Compunding(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns10
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getSelfDemolitionorder() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
obj['zone_cd'] = this.accessed_zone
    // console.log(obj)
    var resp:any = await this.ComplainService.SelfDemolition(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns11
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getnoticesent() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone;
    // console.log(obj)
    var resp:any = await this.ComplainService.getnoticedetails(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns6
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getassignedList() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone
    // console.log(obj)
    var resp:any = await this.ComplainService.getallassignedcomplaintdetails(JSON.stringify(obj));
    // console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns4
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  async getcomplaintcompletedList() {
    this.showTable = true
    this.dashboard = false;
    this.complaint = true;
    this.header = false;
    this.pdf = false

    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.accessed_zone;
    // console.log(obj)
    var resp:any = await this.ComplainService.getcompletedcomplaint(JSON.stringify(obj));
    // console.log(resp, 'list')
    if (resp['error'] == false) {
      // console.log(resp['data'])
      this.displayedColumns = this.displayedColumns5
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
current_date =  this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
  // async user_attendence(){

  //   this.showTable = true
  //   this.dashboard = false;
  //   this.complaint = true;
  //   this.header = false;
  //   this.Attendnce = true;
  //   this.pdf = false

  //   let obj = {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['attendance_date'] = this.current_date
  //   this.spinner.show();
  //   var resp = await this.ComplainService.getuserattdenece(JSON.stringify(obj));
  //   // console.log(resp, 'list')
  //   if (resp['error'] == false) {
  //     // console.log(resp['data'])
  //     this.displayedColumns = this.displayedColumns13
  //     if(resp['data'].length <= 0 ){
  //       swal.fire('info','No record Found','info');
  //     }
  //     this.datasource = new MatTableDataSource(resp['data'])
  //     this.datasource.paginator = this.paginator.toArray()[0];
  //     this.datasource.sort = this.sort.toArray()[0];
  //     this.spinner.hide();
  //   } else {
  //     this.spinner.hide()
  //     Swal.fire('Error', 'Error While Getting Data', 'error')
  //   }

  // }
  search_datewiese(){
    console.log(this.current_date)
    // this.user_attendence();
  }

// map tracking

async view_on_map(element:any) {
  let dummy = []
  let dummy2 = []
  this.paths = null;
  this.zoom = null;
  this.markers = [];
  // console.log(this.zoom)
  this.spinner.show()
  // console.log(element['zone_cd']);
  const obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['zone_id'] = element['zone_id']
  // console.log(obj);
  var resp:any = await this.zone.getcordinateLocationData(JSON.stringify(obj));
  // console.log(resp)
  if (resp['error'] == true) {
    swal.fire('error', 'server side error', 'error');
    return
  }
  else {
    dummy = resp['data']
    // console.log(dummy)
  }
  for (let i = 0; i < dummy.length; i++) {
    let obj2:any = {}

    obj2['lat'] = dummy[i]['loc_gis_x_long']
    obj2['lng'] = dummy[i]['loc_gis_y_lat']

    this.lat = obj2['lat']
    this.lng = obj2['lng']
    dummy2.push(obj2)
  }

  this.paths = dummy2
  console.log(this.paths, this.lat, this.lng, "0000")
  this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
  console.log(this.Obj['loc_geo_calc_value'])
  this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
  this.getzonecomplaint(element);
  this.spinner.hide();
  this.zoom = 10
  console.log(this.zoom)

  $('#myModal2').modal('show');





}

markers: {
  lat: String,
  lng: String,
  label: String,
  Complaint: string,
  Address: string,
  compliantype: string,
}[] = []
complaint_length = {}
async getzonecomplaint(element:any) {
  let dummy:any = []
  const obj :any= {}
  obj['b_acct_id'] = this.b_acct_id;
  obj['zone_cd'] = element['zone_cd'];
  console.log(obj);
  var resp:any = await this.ComplainService.getzonecomplaint(JSON.stringify(obj));
  console.log(resp);
  if (resp['error'] == true) {
    swal.fire('error', 'server side error', 'error');
    return
  } else {
    dummy = resp['data'];
    for (let i = 0; i < dummy.length; i++) {


      // obj2['lat'] = dummy[i]['latitude']
      // obj2['lng'] = dummy[i]['longitude']
      // // obj2['label'] = dummy[i+1]

      // // this.lat = obj2['lat']
      // // this.lng = obj2['lng']

      this.markers.push(
        {
          lat: dummy[i]['latitude'],
          lng: dummy[i]['longitude'],
          label: `${i + 1}`,
          Complaint: dummy[i]['complain_app_id'],
          Address: dummy[i]['property_address'],
          compliantype: dummy[i]['complian_type']
        })
    }
    console.log(dummy);
    this.complaint_length = dummy.length
    if (this.complaint_length == 0) {
      this.markers = [];
    }
  }

}



// /////////////////////////////map end ///////////////////////////

/////////user///////////////////////
async getuser() {
  this.complaint = true;
  this.header = false;
  this.showTable = true
  this.dashboard = false;
  this.pdf = false

  console.log("ia mm in user")
  let obj:any = {}
  obj['b_acct_id'] = this.b_acct_id

  console.log(obj)
  var resp:any = await this.ComplainService.getuser(JSON.stringify(obj));
  console.log(resp, 'list')
  if (resp['error'] == false) {
    // this.userlist = resp['data']
    this.displayedColumns = this.displayedColumns2
    this.datasource = new MatTableDataSource(resp['data'])
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];

  } else {

    Swal.fire('Error', 'Error While Getting Data', 'error')
  }
}
// ///user track///
async track_on_map(element:any) {
  this.spinner.show();
  this.trackedusername = element.emp_name
  // this.getusercordinates()
  console.log("i a m herere in zone")
  let dummy :any = []
  let dummy2 :any= []
  this.paths = null;
  this.zoom = null
  const obj:any = {}
  obj['b_acct_id'] = this.b_acct_id
  this.Obj['user_id'] = element.user_id
  obj['zone_id'] = element['zone_id']
  console.log(obj);
  var resp:any = await this.zone.getcordinateLocationData(JSON.stringify(obj));
  console.log(resp)
  if (resp['error'] == true) {
    swal.fire('error', 'server side error', 'error');
    return
  }
  else {
    dummy = resp['data']
    console.log(dummy)
  }
  for (let i = 0; i < dummy.length; i++) {
    let obj2:any = {}

    obj2['lat'] = dummy[i]['loc_gis_x_long']
    obj2['lng'] = dummy[i]['loc_gis_y_lat']

    this.lat = obj2['lat']
    this.lng = obj2['lng']
    dummy2.push(obj2)
  }
  this.spinner.hide();
  this.paths = dummy2
  console.log(this.paths)
  this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
  console.log(this.Obj['loc_geo_calc_value'])
  this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
  this.zoom = 10
  $('#myModal3').modal('show');
}

usercordinates: any = []
dummy2:any = []

// start= []
currenttime: any;
currentdate: any;
start: {
  lat: String,
  lng: String,
  label: String,
  location: String,
  currenttime: String;
  currentdate: String;
}[] = []

end: {
  lat: String,
  lng: String,
  label: String,
  location: String,
  currenttime: String;
  currentdate: String;

}[] = []
trackedusername: any = {}
async getusercordinates() {
  console.log(this.trackedusername)
  this.dummy2 = [];
  this.start = [];
  this.end = [];
  let dummy = []
  let obj:any = {}
  obj['b_acct_id'] = this.b_acct_id
  obj['user_id'] = this.Obj['user_id']
  obj['date'] = this.Obj['date']
  console.log(obj)
  this.spinner.show();
  var resp:any = await this.ComplainService.getusercordinates(JSON.stringify(obj));
  console.log(resp, 'co')
  if (resp['error'] == false) {
    // this.usercordinates = resp['data']
    console.log(this.usercordinates);

    dummy = resp['data']
    console.log(dummy)




  } else {

    Swal.fire('Error', 'Error While Getting Data', 'error')
  }
  this.dummy2 = [...dummy]
  // for (let i = 0; i < dummy.length; i++) {
  //   let obj2 = {}

  //   obj2['lat'] = dummy[i]['latitude']
  //   obj2['lng'] = dummy[i]['longitude']

  //   this.lat = obj2['lat']
  //   this.lng = obj2['lng']
  //   this.dummy2.push(obj2)

  // }
  // this.end_num = this.dummy2.length-1
  console.log(this.dummy2);
  // this.start.push(this.dummy2[0])

  // samar start
  for (let index = 0; index < this.dummy2.length; index++) {
    const element = this.dummy2[index]
    let start = element[0]
    let end = element[element.length - 1]

    this.start.push(
      {
        lat: start['latitude'],
        lng: start['longitude'],
        label: `SP`,
        location: `start point`,
        currentdate: obj['date'],
        currenttime: start['currenttime'],
      })

    this.end.push(
      {
        lat: end['latitude'],
        lng: end['longitude'],
        label: `EP`,
        location: `end point`,
        currentdate: obj['date'],
        currenttime: end['currenttime'],
      })

  }

  this.start[0] =
  {
    lat: this.dummy2[0][0]['latitude'],
    lng: this.dummy2[0][0]['longitude'],
    label: `Attending`,
    location: `Intial point`,
    currentdate: obj['date'],
    currenttime: this.dummy2[0][0]['currenttime'],
  }

  // samar end
  let lastIndex = this.dummy2.length - 1
  let lastIndexOfLast = this.dummy2[lastIndex]
  console.log(lastIndex, lastIndexOfLast, this.dummy2[lastIndex], this.dummy2[lastIndex][this.dummy2[lastIndex].length - 1])
  this.end[0] = {
    lat: this.dummy2[lastIndex][this.dummy2[lastIndex].length - 1]['latitude'],
    lng: this.dummy2[lastIndex][this.dummy2[lastIndex].length - 1]['longitude'],
    label: `Final End`,
    location: `end point`,
    currentdate: obj['date'],
    currenttime: this.dummy2[lastIndex][this.dummy2[lastIndex].length - 1]['currenttime'],
  }





  this.end.push(this.dummy2[this.dummy2.length - 1])


  console.log(this.start)
  console.log(this.end)

  this.spinner.hide();
  //  this.paths2 = this.dummy2
  //  console.log(this.paths2)
  //  this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths2)
  //  console.log(this.Obj['loc_geo_calc_value'])
  //  this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
  //  this.zoom = 10





}


print() {
  this.spinner.show();
  let account_name = this.erpUser['account_name'];
  let account_short_name = this.erpUser['account_short_name'];
  let txt = account_name + "(" + account_short_name + ")";
  var dd:any = {
    pageSize: 'A4',
    header: function (currentPage:any, pageCount:any) {
      var obj = { text: txt , alignment: 'center', margin: [15,15,15,15]};
      return obj;
    },


    pageOrientation: 'landscape',

    pageMargins: [40, 40, 40, 40],
    content: [

    ]
  };

  dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 775, y2: 0, lineWidth: 0.2 }] }); //x2:760
  dd.content.push({ text: " " });
  dd.content.push({ text: " " });

let tbl2:any = {
    fontSize: 10,
    table: {
      widths: [30,50,60,50,80,60,115,60,90,90],
      body: [
        [{ colSpan: 10,  fontSize:12, text: 'Zone Wise ICMS Complaint List', alignment: 'center', bold: true }, {}, {}, {},{},{},{},{},{},{}],
        [{ text: 'S.No', bold: true, alignment: 'center' }, { text: 'Complaint ID', alignment: 'center', bold: true },
         {text: 'Complaint Date', alignment: 'center', bold: true},{text: 'Complaint Form', alignment: 'center', bold: true },
         {text: 'Complaint Type', alignment: 'center', bold: true },{text: 'Zone Name', alignment: 'center', bold: true },
         {text: 'Property Address', alignment: 'center', bold: true },{text: 'Status', alignment: 'center', bold: true },
         {text: 'Officer Name', alignment: 'center', bold: true },{text: 'Officer Mobile No.', alignment: 'center', bold: true },],
      ],
    }
  };



  let arrp = []
  arrp = this.datasource.filteredData;
  arrp.map((x:any,i:any) => {
    let arr = []
    arr.push({ text: i+1, bold: true, alignment: 'center' })
    arr.push({ text: x['complain_id'], bold: true, alignment: 'center' })
    arr.push({ text: x['complain_date'].split('T')[0], alignment: 'center' })
    arr.push({ text: x['complain_from'], alignment: 'center' })
    arr.push({ text: x['complian_type'], alignment: 'center' })
    arr.push({ text: x['zone_name'], alignment: 'center' })
    arr.push({ text: x['property_address'], alignment: 'center' })
    arr.push({ text: x['status'], alignment: 'center' })
    arr.push({ text: x['assign_user'] ? x['assign_user']:"No Value", alignment: 'center' })
    arr.push({ text: x['emp_phone_no'] ? x['emp_phone_no']:"No Value" , alignment: 'center' })

    tbl2.table.body.push(arr);
  })
  dd.content.push(tbl2);
  this.spinner.hide();
  pdfMake.createPdf(dd).download('Complaint List' + '.pdf');

}


}
