import { Component, AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '../../../portal/service/profile.service';
import { MainService as portal_main } from '../../../portal/service/main.service';
import { MainService } from '../../service/main.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication.service';

@Component({
  selector: '[app-md-header]',
  templateUrl: './md-header.component.html',
  styleUrls: ['./md-header.component.css']
})
export class MdHeaderComponent implements AfterViewInit {

  constructor(private router: Router, public auth: AuthenticationService, public portal_main: portal_main, public mainService: MainService, private profileService: ProfileService, private sanitizer: DomSanitizer) { }
                         
  erpUser:any = {}
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
  }


  home() {
    this.router.navigate(['/index'])
  }



  ngAfterViewInit()  {
  }

}