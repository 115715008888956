<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Payment Option</div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i> Payment Option List</a>
                            <!--Property List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Payment Option </a>
                            <!--Create Property-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Update Property Costing </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <div class="row ">
                                <div class="col-2"></div>
                                    <div class="col-3">
                                        <h6>Mode of Offer : </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="OfferModeList" bindLabel="value" bindValue="code"
                                            [multiple]="false" placeholder="Select..."
                                            [(ngModel)]="OfferMode" [selectableGroup]="true"
                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                            (change)="modeOfferAction()"
                                            name="chart_of_account0">
                                        </ng-select>
                                    </div>
                                    <!-- (change)="resetModeData()"  -->
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="getPaymentOption()">Fetch</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-left">
                                    <mat-form-field style="margin-left: 2%" *ngIf="displayedColumns_1">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property5']}}">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>
                            </div>
                            <br>
                            <div class=" example-container" *ngIf="displayedColumns_1">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="sno">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            ID
                                        </th>
                                        <!--Property Number-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.sno }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="mode_of_payment">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Property type-->
                                            Mode Of Payment
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.mode_of_payment }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="interest_depend_on">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Property type-->
                                            Interest Dependecy
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <span *ngIf="displayedColumns_1.includes('range')" >
                                                Sale Value
                                            </span>
                                            <span *ngIf="!displayedColumns_1.includes('range')" >
                                                {{ element.interest_depend_on }}
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pro_type_or_sale_value" *ngIf="displayedColumns_1.includes('pro_type_or_sale_value')">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Property type / Sale Value
                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['pro_type_or_sale_value']}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="range" *ngIf="displayedColumns_1.includes('range')">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Sale Value Range
                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['range_start_from']}}-{{element['range_end_at']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="frequency_of_installment">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Frequency Of Installment

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <span *ngIf="element['mode_of_payment']=='Installment'" >
                                                {{element['frequency_of_installment']}}
                                            </span>
                                            <span *ngIf="element['mode_of_payment']!='Installment'">
                                                ---
                                            </span>
                                        </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="max_no_installment">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Max Number Of Installment

                                        </th>

                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.max_no_installment }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="interest_rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Interest Rate (%)
                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <span *ngIf="element['mode_of_payment']!='Installment'" >
                                             ---
                                            </span>
                                            <span *ngIf="element['mode_of_payment']=='Installment'" >
                                                {{ element.interest_rate }}
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="effective_date_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Effective Date from

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right" >
                                            <span *ngIf="element['effective_date_from']">
                                                {{mainService.dateFormatChange(element.effective_date_from)}}
                                            </span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'property11']}}</button>
                                            <!--Update-->
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'property12']}}</button>
                                            <!--Delete-->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns_1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns_1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div *ngIf="create_flag">
                            <div style="margin: 1%;">
                                <div class="card">
                                    <div class="row" style="margin-top: 1%; margin-right: 1%;">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Go To
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row ">
                                        <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Mode of Offer : </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="OfferModeList" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="OfferMode" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    (change)="modeOfferAction()"
                                                    name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <!-- (change)="resetModeData()"  -->
                                    </div>
                                    <hr>
                                    <ng-container *ngIf="OfferMode" >
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Mode Of Payment : <span style="color:red"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id"
                                                [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="Obj['mode_of_payment']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                (change)="resetModeData()" name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Property Category : <span style="color:red"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="propertyCategoryArr" bindLabel="id" bindValue="id"
                                                [multiple]="false" placeholder="Select Property Category"
                                                [(ngModel)]="Obj['property_category']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                 name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>




                                    <ng-container *ngIf="Obj['interest_depend_on']=='Property Type'">
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Property Type : <span style="color:red"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['pro_type_or_sale_vaue']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                    </ng-container>
                                    <ng-container *ngIf="OfferMode==1 && Obj['interest_depend_on']=='Sale Value'" >
                                        <div >
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div class="col-3">
                                                    <h6>Sale Value: <span style="color:red"> *</span></h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['Sale Value']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['pro_type_or_sale_vaue']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                        </div>
                                        <br>
                                    </ng-container>

                                    <div *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <ng-container *ngIf="OfferMode==1" >
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div class="col-3">
                                                    <h6>Range :<span style="color:red"> *</span> </h6>
                                                </div>
                                                <div class="col-2">
                                                    <input type="number" placeholder="Range start from"
                                                        [(ngModel)]="Obj['range_start_from']" class="form-control" name=""
                                                        id="">
                                                </div>
                                                <div class="col-1 text-center ">to</div>
                                                <div class="col-2">
                                                    <input type="number" placeholder="Range end at"
                                                        [(ngModel)]="Obj['range_end_at']" class="form-control" name=""
                                                        id="">
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Number Of Installments :<span style="color:red"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" placeholder="Number Of Installments"
                                                    [(ngModel)]="Obj['no_of_payment']" class="form-control" name=""
                                                    id="">

                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Frequency Of Installment : <span style="color:red"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD50']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..."
                                                    [(ngModel)]="Obj['frequency_of_installment']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>

                                        <br>
                                    </div>
                                    <div *ngIf="Obj['mode_of_payment']=='Cash Down'">
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Payment Within : <span style="color:red"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="withinlist1"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['first_pay_day']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" >

                                                    <ng-template ng-option-tmp let-item="item" >
                                                        <div (click)=" firstWithin(item)">
                                                            {{item.value}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <div class=" row  ">
                                            <div class="col-2"></div>
                                            <div class="col rounded border border-dark">

                                                <div class="row mt-4">
                                                    <div class="col-3">
                                                        <h6>Discount Method : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="paymentMethodArr" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select..."
                                                            [(ngModel)]="Obj['valueType']" [selectableGroup]="true"
                                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                            (change)="changeDiscountPlaceHolder()"
                                                            name="chart_of_account0">
                                                        </ng-select>
                                                    </div>

                                                    <div class="col-2" >
                                                        <h6>Discount(as {{discountValueType}}) : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <input type="number" placeholder="Enter discount as {{discountValueType}}"
                                                            [(ngModel)]="Obj['discount']" class="form-control" name="" id="">
                                                    </div>

                                                </div>

                                                <br>
                                                <div class="row mb-4">
                                                    <div class="col-3">
                                                        <h6>Avail Discount Within : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-3">
                                                        <ng-select [items]="withinlist2"
                                                            bindLabel="value" bindValue="code" [multiple]="false"
                                                            placeholder="Select..." [(ngModel)]="Obj['discount_days']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1"></div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row mt-3" *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Interest Rate : <span style="color:red" > *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="Interest Rate"
                                                [(ngModel)]="Obj['interest_rate']" class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Penal Interest(in a case of Late):<span style="color:red" > *</span> </h6>
                                            <!-- <h6>Penal Interest(in a case ) : <span style="color:red" *ngIf="Obj['mode_of_payment']=='Installment'"> *</span> </h6> -->
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="Penal Interest"
                                                [(ngModel)]="Obj['panel_interest']" class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row" *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Effective Date : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" [(ngModel)]="Obj['effective_date_from']" min="{{todayformatedDate()}}"
                                                class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>No of failed installments to default : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="No of Defaulter"
                                                [(ngModel)]="Obj['no_of_defaulter']" class="form-control" name="" id="" value="3">
                                        </div>
                                        <div class="col-3"></div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Defaulter Method : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="defaulterlist"
                                            bindLabel="name"
                                            bindValue="defualt_method_id"
                                                [multiple]="false" placeholder="fail../notice../seize..."
                                                 [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                [(ngModel)]="Obj['no_of_defaulter']" >
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="submit()" class="btn btn-outline-primary">Submit</button>
                                        </div>
                                    </div>
                                    <br>
                                </ng-container>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div *ngIf="update_flag">
                            <br>
                            <div style="margin: 2%;">
                                <div class="card">
                                    <div class="row" style="margin-top: 1%; margin-right: 1%;">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Go To
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row ">
                                        <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Mode of Offer : </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="OfferModeList" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder="Select..."
                                                    [(ngModel)]="OfferMode" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                    (change)="modeOfferAction()"
                                                    name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                    </div>
                                    <hr>
                                    <ng-container *ngIf="OfferMode" >
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Mode Of Payment : <span style="color:red"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            {{Obj['mode_of_payment']}}
                                            <!-- <ng-select [items]="modeOfPayment" bindLabel="id" bindValue="id"
                                                [multiple]="false" placeholder="Select..."
                                                [(ngModel)]="Obj['mode_of_payment']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                (change)="resetModeData()" name="chart_of_account0">
                                            </ng-select> -->
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Property Category : <span style="color:red"> *</span></h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="propertyCategoryArr" bindLabel="id" bindValue="id"
                                                [multiple]="false" placeholder="Select Property Category"
                                                [(ngModel)]="Obj['property_category']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                 name="chart_of_account0">
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <ng-container *ngIf="OfferMode==1" >
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Range :<span style="color:red"> *</span> </h6>
                                            </div>
                                            <div class="col-2">
                                                <input type="number" placeholder="Range start from"
                                                    [(ngModel)]="Obj['range_start_from']" class="form-control" name=""
                                                    id="">
                                            </div>
                                            <div class="col-1 text-center ">to</div>
                                            <div class="col-2">
                                                <input type="number" placeholder="Range end at"
                                                    [(ngModel)]="Obj['range_end_at']" class="form-control" name=""
                                                    id="">
                                            </div>
                                        </div>
                                        <br>
                                    </ng-container>

                                    <ng-container *ngIf="Obj['interest_depend_on']=='Property Type'">
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Property Type : <span style="color:red"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROP0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['pro_type_or_sale_vaue']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                    </ng-container>
                                    <ng-container *ngIf="OfferMode==1 && Obj['interest_depend_on']=='Sale Value'" >
                                        <div >
                                            <div class="row">
                                                <div class="col-2"></div>
                                                <div class="col-3">
                                                    <h6>Sale Value: <span style="color:red"> *</span></h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['Sale Value']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['pro_type_or_sale_vaue']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3"></div>
                                            </div>
                                        </div>
                                        <br>
                                    </ng-container>

                                    <div *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <br>
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Number Of Installments :<span style="color:red"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <input type="number" placeholder="Number Of Installments"
                                                    [(ngModel)]="Obj['no_of_payment']" class="form-control" name=""
                                                    id="">

                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Frequency Of Installment : <span style="color:red"> *</span></h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD50']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..."
                                                    [(ngModel)]="Obj['frequency_of_installment']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                </ng-select>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>

                                        <br>
                                    </div>
                                    <div *ngIf="Obj['mode_of_payment']=='Cash Down'">
                                        <div class="row">
                                            <div class="col-2"></div>
                                            <div class="col-3">
                                                <h6>Payment Within : <span style="color:red"> *</span> </h6>
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="withinlist1"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="Select..." [(ngModel)]="Obj['first_pay_day']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0" >

                                                    <ng-template ng-option-tmp let-item="item" >
                                                        <div (click)=" firstWithin(item)">
                                                            {{item.value}}
                                                        </div>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <div class=" row  ">
                                            <div class="col-2"></div>
                                            <div class="col rounded border border-dark">

                                                <div class="row mt-4">
                                                    <div class="col-3">
                                                        <h6>Discount Method : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-2">
                                                        <ng-select [items]="paymentMethodArr" bindLabel="id" bindValue="id"
                                                        [multiple]="false" placeholder="Select..."
                                                            [(ngModel)]="Obj['valueType']" [selectableGroup]="true"
                                                            [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                            (change)="changeDiscountPlaceHolder()"
                                                            name="chart_of_account0">
                                                        </ng-select>
                                                    </div>

                                                    <div class="col-2" >
                                                        <h6>Discount(as {{discountValueType}}) : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <input type="number" placeholder="Enter discount as {{discountValueType}}"
                                                            [(ngModel)]="Obj['discount']" class="form-control" name="" id="">
                                                    </div>

                                                </div>

                                                <br>
                                                <div class="row mb-4">
                                                    <div class="col-3">
                                                        <h6>Avail Discount Within : <span style="color:red"> *</span> </h6>
                                                    </div>
                                                    <div class="col-3">
                                                        <ng-select [items]="withinlist2"
                                                            bindLabel="value" bindValue="code" [multiple]="false"
                                                            placeholder="Select..." [(ngModel)]="Obj['discount_days']"
                                                            [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                            [closeOnSelect]="true" name="chart_of_account0">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-1"></div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row mt-3" *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Interest Rate : <span style="color:red" > *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="Interest Rate"
                                                [(ngModel)]="Obj['interest_rate']" class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Penal Interest(in a case of Late):<span style="color:red" > *</span> </h6>
                                            <!-- <h6>Penal Interest(in a case ) : <span style="color:red" *ngIf="Obj['mode_of_payment']=='Installment'"> *</span> </h6> -->
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="Penal Interest"
                                                [(ngModel)]="Obj['panel_interest']" class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row" *ngIf="Obj['mode_of_payment']=='Installment'">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Effective Date : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="date" [(ngModel)]="Obj['effective_date_from']" min="{{todayformatedDate()}}"
                                                class="form-control" name="" id="">
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>No of failed installments to default : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <input type="number" placeholder="No of Defaulter"
                                                [(ngModel)]="Obj['no_of_defaulter']" class="form-control" name="" id="" value="3">
                                        </div>
                                        <div class="col-3"></div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-2"></div>
                                        <div class="col-3">
                                            <h6>Defaulter Method : <span style="color:red"> *</span> </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="defaulterlist"
                                            bindLabel="name"
                                            bindValue="defualt_method_id"
                                                [multiple]="false" placeholder="fail../notice../seize..."
                                                 [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                [(ngModel)]="Obj['no_of_defaulter']" >
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button (click)="update()" class="btn btn-outline-primary">Update</button>
                                        </div>
                                    </div>
                                    <br>
                                </ng-container>
                                </div>

                            </div>
                            <br>
                        </div>


                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>Lodaing...</ngx-spinner>
