import { NgxSpinnerService } from "ngx-spinner";
import { MasterDataService } from "../service/master-data.service";
import { TenderService } from "../service/tender.service";
import {
  Component,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
  Query,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatStepper } from "@angular/material/stepper";
import { MatPaginator } from "@angular/material/paginator";
import { ToastrService } from 'ngx-toastr';
import { MatSort } from "@angular/material/sort";
import { ReferenceDataService } from "../service/reference-data.service";
import { EmbService } from "../service/emb.service";
import { DeviationService } from "../service/deviation.service";
import { MainService } from "../service/main.service";
import { ApproveService } from "../service/approve.service";
import { EbillService } from "../service/ebill.service";
import { FilterService } from "../service/filter.service";
import { CommonDataService } from "../common _services/common-data.service";
import Swal from "sweetalert2";

declare var $: any;
@Component({
  selector: "app-completion",
  templateUrl: "./completion.component.html",
  styleUrls: ["./completion.component.css"],
})
export class CompletionComponent implements OnInit {
  displayedColumns2: string[] = [
    "id",
    "sno",
    "tender_id",
    "dev_dt",
    "dev_type",
    "dev_quantity",
  ];

  displayedColumns: string[] = [
    "sn",
    "line_desc",
    "unit",
    "work_order_q",
    "rate",
    "excess_quantity",
    "dev_type",
    "saving_quantity",
  ];
  compute: any = [
    { id: "ACTUAL", value: "ACTUAL" },
    { id: "AVERAGE", value: "AVERAGE" },
  ];
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild("stepper", { static: false }) stepper!: MatStepper;
  @ViewChild("stepper", { static: false }) stepper1!: MatStepper;

  datasource2:any;
  datasource:any;
  allWorkList:any = [];
  stepperIndex:any = 0;
  stepperIndex1:any = 0;
  Obj:any = {};
  ebillUser:any;
  b_acct_id:any;
  allWork:any = [];
  len: boolean = false;
  width: boolean = false;
  height: boolean = false;
  isLinear = false;
  first = true;
  second = true;
  sordata = [];
  nonsordata = [];
  compobj:any = { A: "EXCESS", S: "SAVING" };
  changesaving(ele:any){

  }
  changeexcess(element:any){

  }
  constructor(
    private CommonDataService: CommonDataService,
    public filterService: FilterService,
    private embService: EmbService,
    private ebillService: EbillService,
    private DeviationService: DeviationService,
    private spinner: NgxSpinnerService,
    public mainService: MainService,
    private referenceDataS: ReferenceDataService,
    private approveService: ApproveService,
    private toastr: ToastrService,
    private MSS: MasterDataService,
    private masterDataService: MasterDataService,
    private tenderService: TenderService
  ) { }
  create_flag: boolean = false;
  update_flag: boolean = false;
  list_flag: boolean = true;
  createtab() {
    this.create_flag = true;
    this.list_flag = false;
    this.update_flag = false;
  }

  listbtab() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }

  async ngOnInit() {
    this.ebillUser =  JSON.parse(atob(localStorage.getItem("erpUser") as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getAssignedWork();
    await this.getAllWorkInfo();
  }

  refresh() {
    this.Obj = {};
    this.tabledata = [];
    this.viewdata = [];
    this.sordata = [];
    this.nonsordata = [];
    this.datasource = new MatTableDataSource(this.tabledata);
    this.datasource2 = new MatTableDataSource(this.tabledata);
    this.stepper.selectedIndex = 0;
    this.stepper1.selectedIndex = 0;
  }

  selectedTender:any;
  selectedProject:any;
  selectedWork:any;
  async getTenderId() {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork };
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp["error"] == false) {
      if (resp.data.length != 0) {
        this.selectedTender = resp["data"][resp["data"].length - 1];
        await this.getBoqItems();
        await this.getAllEmbList();
      }
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error");
    }
  }
  embdata = [];
  dev_id = 0;
  async getAllEmbList() {
    var obj = new Object();
    obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    this.spinner.show();

    var resp = await this.DeviationService.getdev(JSON.stringify(obj));
    console.log(resp);

    if (resp["error"] == false) {
      console.log(resp.data);
      var comp = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["is_comp"] == "Y") {
          comp.push(resp.data[i]);
        }
        if (
          resp["data"][i]["dev_id"] >= this.dev_id &&
          resp["data"][i]["dev_id"] != null
        ) {
          this.dev_id = resp["data"][i]["dev_id"];
        }
      }
      this.datasource2 = new MatTableDataSource(comp);
      this.datasource2.sort = this.sort.toArray()[0];
      this.datasource2.paginator = this.paginator.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  assignedWorks:any = {};
  async getAssignedWork() {
    this.spinner.show();
    var work_ids:any = []
    work_ids = Object.values(this.CommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }
    this.spinner.hide();
  }

  async getAllWorkInfo() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.MSS.getWorkInfo(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      var dt = [];
      var temp = resp['data'];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]['id']] != undefined) {
          dt.push(temp[i])
        }
      }
      this.allWork = dt;
      for (let i = 0; i < this.allWork.length; i++) {
        this.allWork[i]['desc'] = this.allWork[i]['work_order_no'] + "-" + this.allWork[i]['work_order_name']
        this.workObj[this.allWork[i]['work_order_no']] = this.allWork[i]['work_order_name']
      }
      this.spinner.hide();


    } else {
      this.spinner.hide();
    }
  }

  workObj:any = {};
  async changeworklist() {
    this.spinner.show();
    var obj:any = new Object();
    obj["work_order_id"] = this.Obj["work_order_id"];
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.DeviationService.getdeviation(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp);
      var deviation = resp.data;
      var dev = [];
      for (let i = 0; i < deviation.length; i++) {
        if (deviation[i]["dev_date"] != null) {
          var date = deviation[i]["dev_date"].split("T");
          let d3 = this.mainService.dateformatchange(date[0]);

          deviation[i]["dev_date"] = d3;
        }
        if (deviation[i]["status"] == 1) {
          dev.push(deviation[i]);
        }
      }

      this.datasource2 = new MatTableDataSource(dev);
      this.datasource2.sort = this.sort.toArray()[0];
      this.datasource2.paginator = this.paginator.toArray()[0];
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }

  async changeworkcomp() {
    await this.getTenderId();
    console.log(this.selectedTender);
    if (this.selectedTender == undefined) {
      Swal.fire("Oops", "BOQ Not Uploaded...!");
      return;
    }
    await this.getBoqItems();
    await this.getEmbTotal();
  }

  boqItems:any = [];
  async getBoqItems() {
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.boqItems = resp["data"];
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqobj[this.boqItems[i]["sno"]] = this.boqItems[i];
      }
    } else {
      Swal.fire("Oops", "Error while getting Boq Items");
    }
  }
  boqobj:any = {};
  async getEmbTotal() {
    var obj = {
      b_acct_id: this.b_acct_id,
      tender_id: this.selectedTender["tender_id"],
    };
    var resp = await this.embService.getLastEMB(JSON.stringify(obj));
    if (resp["error"] == false) {
      var dt = resp["data"];
      for (var i = 0; i < this.boqItems.length; i++) {
        this.boqItems[i]["part_rate"] = this.boqItems[i]["rate"];
        this.boqItems[i]["remaining_quantity"] =
          this.boqItems[i]["eff_quantity"];
        this.boqItems[i]["already_bill_quantity"] = 0;
        for (var j = 0; j < dt.length; j++) {
          if (this.boqItems[i]["sno"] == dt[j]["sno"]) {
            this.boqItems[i]["remaining_quantity"] -= dt[j]["q"];
            this.boqItems[i]["already_bill_quantity"] += dt[j]["q"];
          }
        }
      }
      console.log(this.boqItems);
      for (var i = 0; i < this.boqItems.length; i++) {
        if (this.boqItems[i]["remaining_quantity"] < 0) {
          this.boqItems[i]["dev_type"] = "EXCESS";
          this.boqItems[i]["remaining_quantity"] =
            -1 * this.boqItems[i]["remaining_quantity"];
        } else {
          this.boqItems[i]["dev_type"] = "SAVING";
        }
      }
      this.datasource = new MatTableDataSource(this.boqItems);
      this.datasource.sort = this.sort.toArray()[1];
      this.datasource.paginator = this.paginator.toArray()[1];
    } else {
      Swal.fire("Oops", "Error while getting previous emb", "error");
    }
  }
  tabledata:any = [];
  async changework() {
    var id = this.Obj["work_order_id"];
    for (let i = 0; i < this.allWork.length; i++) {
      if (this.Obj["work_order_id"] == this.allWork[i]["id"]) {
        this.Obj["work_order_name"] = this.allWork[i]["work_order_name"];
      }
    }

    this.Obj["project_desc"] = "";
    this.Obj["zone"] = "";

    this.Obj["dev_desc"] = "Completion " + " - " + this.Obj["work_order_name"];
    var obj1 = Object();
    obj1["b_acct_id"] = this.b_acct_id;
    obj1["work_order_id"] = this.Obj["work_order_id"];
    var lastdev = await this.DeviationService.getLastdeviation(
      JSON.stringify(obj1)
    );
    console.log(lastdev);
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    var boq:any = {};
    var obj2:any = new Object();
    obj2["b_acct_id"] = this.ebillUser.b_acct_id;
    obj2["work_order_id"] = this.Obj["work_order_id"];
    console.log(obj2);
    var emb = await this.referenceDataS.getLastEMB(JSON.stringify(obj2));
    console.log(emb);
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    console.log(resp);
    this.Obj["boq_no"] = resp["data"][0]["id"];
    for (let i = 0; i < resp["data"].length; i++) {
      if (this.Obj["work_order_id"] == resp["data"][i]["work_order_id"]) {
        boq = Object.assign({}, resp["data"][i]);
      }
    }
    let previousBills = [];
    previousBills = await this.getWorkOrderBill(this.Obj["work_order_id"]);
    if (lastdev["data"].length == 0) {
      this.Obj["dev_no"] = 1;
      if (boq["emb_type"] == "emb_with_ebill") {
        var tabledata = JSON.parse(emb["data"][0]["lines"]);
        for (let i = 0; i < tabledata.length; i++) {
          this.Obj["amount"] =
            this.Obj["amount"] + tabledata[i]["lime_amt_without_tax"];
          tabledata[i]["already_m_q"] =
            tabledata[i]["already_m_q"] + tabledata[i]["ebill_quantity"];
          tabledata[i]["remaining_q"] =
            tabledata[i]["remaining_q"] - tabledata[i]["ebill_quantity"];
          tabledata[i]["completion_quantity"] =
            tabledata[i]["already_m_q"] - tabledata[i]["remaining_q"];
        }
      } else {
        if (previousBills.length > 0) {
          console.log(previousBills[0]);
          var tabledata = JSON.parse(previousBills[0]["data"]);
          for (let i = 0; i < tabledata.length; i++) {
            this.Obj["amount"] =
              this.Obj["amount"] + tabledata[i]["lime_amt_without_tax"];
            tabledata[i]["already_m_q"] =
              tabledata[i]["already_bill_quantity"] +
              tabledata[i]["ebill_quantity"];
            tabledata[i]["remaining_q"] =
              tabledata[i]["remaning_quantity"] -
              tabledata[i]["ebill_quantity"];
            tabledata[i]["completion_quantity"] =
              tabledata[i]["already_bill_quantity"] -
              tabledata[i]["remaning_quantity"];
          }
        }
      }
      var tabledata1 = JSON.parse(boq["data"]);
      console.log(tabledata1);
      console.log(this.Obj);
      this.Obj["amount"] = 0;

      this.tabledata = tabledata;
      this.datasource = new MatTableDataSource(tabledata);
      this.datasource.sort = this.sort.toArray()[1];
      this.datasource.paginator = this.paginator.toArray()[1];
    } else {
      if (boq["emb_type"] == "emb_with_ebill") {
        this.tabledata = JSON.parse(emb["data"][0]["lines"]);
      } else {
        this.tabledata = JSON.parse(previousBills[0]["data"]);
        for (let i = 0; i < this.tabledata.length; i++) {
          this.tabledata[i]["remaining_q"] =
            this.tabledata[i]["remaning_quantity"];
          this.tabledata[i]["already_m_q"] =
            this.tabledata[i]["already_bill_quantity"];
        }
      }
      console.log(lastdev);
      var deviation = JSON.parse(lastdev["data"][0]["data"]);
      console.log(deviation);
      var flagarrdev1:any = [];
      for (let i = 0; i < this.tabledata.length; i++) {
        if (!flagarrdev1.includes(this.tabledata[i]["s_no"])) {
          for (let j = 0; j < deviation.length; j++) {
            if (this.tabledata[i]["s_no"] == deviation[j]["s_no"]) {
              flagarrdev1.push(this.tabledata[i]["s_no"]);
              this.tabledata[i]["quantity"] =
                Number(deviation[j]["quantity"]) -
                Number(deviation[j]["saving_quantity"]) +
                Number(deviation[j]["excess_quantity"]);
              this.tabledata[i]["remaning_quantity"] =
                Number(this.tabledata[i]["remaning_quantity"]) -
                Number(deviation[j]["saving_quantity"]) +
                Number(deviation[j]["excess_quantity"]);
            }
          }
        }
      }
      for (let i = 0; i < deviation.length; i++) {
        if (!flagarrdev1.includes(deviation[i]["s_no"])) {
          console.log(lastdev[i]);
          var obj1 = Object.assign({}, deviation[i]);
          obj1["already_bill_quantity"] = 0;
          obj1["already_bill_amount"] = 0;
          obj1["remaning_quantity"] = Number(obj1["quantity"]);
          obj1["ebill_quantity"] = 0;
          obj1["dtlLines"] = [];
          obj1["part_rate"] = obj1["rate"];
          obj1["lime_amt_without_tax"] = Number(obj1["lime_amt_without_tax"]);
          obj1["ebill_amount"] = 0;
          this.tabledata.push(obj1);
          flagarrdev1.push(deviation[i]["s_no"]);
        }
      }
      this.Obj["dev_no"] = Number(lastdev["data"][0]["dev_no"]) + 1;
      this.Obj["last_dev_amount"] = lastdev["data"][0]["dev_amount"];
      this.Obj["amount"] = 0;
      var flagarrdev12:any = [];
      var index = -1;
      for (let i = 0; i < this.tabledata.length; i++) {
        if (!flagarrdev12.includes(this.tabledata[i]["s_no"])) {
          flagarrdev12.push(this.tabledata[i]["s_no"]);
          this.Obj["amount"] =
            this.Obj["amount"] + this.tabledata[i]["lime_amt_without_tax"];
          this.tabledata[i]["already_m_q"] =
            this.tabledata[i]["already_m_q"] +
            this.tabledata[i]["ebill_quantity"];
          this.tabledata[i]["remaining_q"] =
            this.tabledata[i]["remaining_q"] -
            this.tabledata[i]["ebill_quantity"];
          this.tabledata[i]["completion_quantity"] =
            this.tabledata[i]["already_m_q"] - this.tabledata[i]["remaining_q"];
          index = i;
        } else {
          console.log(this.tabledata[index]);
          console.log(this.tabledata[i]);
          this.tabledata[index]["already_m_q"] =
            this.tabledata[index]["already_m_q"] +
            this.tabledata[i]["ebill_quantity"];
          this.tabledata[index]["remaining_q"] =
            this.tabledata[index]["remaining_q"] -
            this.tabledata[i]["ebill_quantity"];
          this.Obj["amount"] =
            this.Obj["amount"] + this.tabledata[i]["lime_amt_without_tax"];
          this.tabledata[index]["completion_quantity"] =
            this.tabledata[index]["already_m_q"] -
            this.tabledata[index]["remaining_q"];
          this.tabledata.splice(i, 1);
        }
      }
      this.datasource = new MatTableDataSource(this.tabledata);
      this.datasource.sort = this.sort.toArray()[1];
      this.datasource.paginator = this.paginator.toArray()[1];
    }
  }
  viewdata:any = [];
  nextClick() {
    if (this.Obj["dev_no"] <= 1) {
      this.Obj["amount"] = 0;
    }
    this.Obj["dev_amount"] = 0;
    this.viewdata = [];
    console.log(this.tabledata);
    for (let i = 0; i < this.tabledata.length; i++) {
      if (this.tabledata[i]["remaining_q"] > 0) {
        this.tabledata[i]["saving_quantity"] = this.tabledata[i]["remaining_q"];
        this.tabledata[i]["excess_quantity"] = 0;
        this.tabledata[i]["dev_amount"] =
          (this.tabledata[i]["quantity"] -
            this.tabledata[i]["saving_quantity"]) *
          this.tabledata[i]["rate"];
      } else if (this.tabledata[i]["remaining_q"] < 0) {
        this.tabledata[i]["excess_quantity"] =
          this.tabledata[i]["remaining_q"] * -1;
        this.tabledata[i]["saving_quantity"] = 0;
        this.tabledata[i]["dev_amount"] =
          (this.tabledata[i]["quantity"] +
            this.tabledata[i]["excess_quantity"]) *
          this.tabledata[i]["rate"];
      }

      if (
        this.tabledata[i]["saving_quantity"] > 0 ||
        this.tabledata[i]["excess_quantity"] > 0
      ) {
        this.viewdata.push(this.tabledata[i]);
      }
      this.Obj["dev_amount"] = (
        Number(this.Obj["dev_amount"]) + Number(this.tabledata[i]["dev_amount"])
      ).toFixed(2);
    }
  }
  async getWorkOrderBill(workOrderNo:any) {
    this.spinner.show();
    let arr = [];
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["work_order_id"] = workOrderNo;

    var resp = await this.ebillService.getBillsOfWork(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      arr = resp.data;

      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp["data"]);
    }
    return arr;
  }
  async submit() {
    var data = this.tabledata;
    this.Obj["status"] = 1;
    console.log(data);
    this.Obj["tender_id"] = this.selectedTender["tender_id"];
    this.Obj["data"] = [];
    console.log(this.boqItems);
    for (let i = 0; i < this.boqItems.length; i++) {
      var obj2:any = new Object();

      if (this.boqItems[i]["dev_type"] == "SAVING") {
        obj2["dev_quantity"] = this.boqItems[i]["remaining_quantity"];
        obj2["is_comp"] = "Y";
        obj2["dev_type"] = "S";
        obj2["sno"] = this.boqItems[i]["sno"];
        obj2["tender_id"] = this.selectedTender["tender_id"];
        obj2["dev_dt"] = this.Obj["dev_dt"];
        obj2["dev_id"] = this.dev_id + 1;
        console.log(obj2);
        this.Obj["data"].push(obj2);
      } else if (this.boqItems[i]["dev_type"] == "EXCESS") {
        obj2["dev_quantity"] = Number(this.boqItems[i]["remaining_quantity"]);
        obj2["is_comp"] = "Y";
        obj2["dev_type"] = "A";
        obj2["dev_id"] = this.dev_id + 1;
        obj2["sno"] = this.boqItems[i]["sno"];
        obj2["tender_id"] = this.selectedTender["tender_id"];
        obj2["dev_dt"] = this.Obj["dev_dt"];
        this.Obj["data"].push(obj2);
      }
    }
    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["create_user_id"] = this.ebillUser["user_id"];
    console.log(this.Obj), console.log(data);
    var resp = await this.DeviationService.completion(this.Obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.changeworklist();
      $('.nav-tabs a[href="#tab-7-1"]').tab("show");
      Swal.fire("Success", "Completion Created Successfully ", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }

  statusArr:any = [];
  async viewStatus(element:any) {
    console.log(element);
    var obj:any = new Object();
    obj["doc_local_no"] = element.dev_id;
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["doc_type"] = "DEVIATION";
    var resp = await this.approveService.getStatus(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.statusArr = resp.data;
      $("#myModal1").modal("show");
    } else {
      Swal.fire("Error", "" + resp["data"], "error");
    }
  }
  approvalHierForEBILL:any = [];
  async getAllApprovalHier() {
    this.spinner.show();
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    var resp = await this.approveService.getApprovalHier(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.approvalHierForEBILL = [];
      var temp = resp.data;

      for (let i = 0; i < temp.length; i++) {
        if ("EBILL" == temp[i]["doc_type"]) {
          this.approvalHierForEBILL.push(temp[i]);
        }
      }
    } else {
      this.spinner.hide();
    }
  }

  getApprovalData(doc_local_no:any) {
    var arr = [];
    for (let i = 0; i < this.approvalHierForEBILL.length; i++) {
      var obj:any = new Object();
      obj["level_of_approval"] =
        this.approvalHierForEBILL[i]["level_of_approval"];
      obj["order_of_approval"] =
        this.approvalHierForEBILL[i]["order_of_approval"];
      obj["doc_type"] = "DEVIATION";
      obj["doc_desc"] = "DEVIATION";
      if (this.approvalHierForEBILL[i]["order_of_approval"] == "F") {
        obj["status"] = "UNDERAPPROVAL";
      } else {
        obj["status"] = "PENDING";
      }
      obj["doc_local_no"] = doc_local_no;
      obj["create_user_id"] = this.ebillUser.user_id;
      arr.push(obj);
    }
    return arr;
  }

  async insertForApproval(doc_local_no:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.ebillUser["b_acct_id"];
    obj["data"] = this.getApprovalData(doc_local_no);
    var resp = await this.approveService.insertForApproval(obj);
    if (resp["error"] == false) {
    } else {
      Swal.fire("Error..", "" + resp["data"], "error");
    }
  }
  async getAllApprovalStatus() {
    let obj:any = {};
    obj["b_acct_id"] = this.ebillUser.b_acct_id;
    obj["status"] = ["UNDERAPPROVAL"];
    obj["designation_cd"] = this.ebillUser.role_cd;
    var resp = await this.approveService.getApprovalStatus(JSON.stringify(obj));
    console.log(resp["data"]);
    if (resp["error"] == false) {
      this.mainService.taskcount = resp.data.length;
    } else {
    }
  }
  async open_update(element:any) {
    console.log(element);
    this.Obj = Object.assign({}, element);
    if (this.Obj["dev_date"] != null) {
      var date = this.Obj["dev_date"].split("T");
      this.Obj["dev_date"] = date[0];
    }
    var data = JSON.parse(element["data"]);
    for (let i = 0; i < data.length; i++) {
      this.tabledata.push(data[i]);
    }
    this.Obj["zone"] = "";
    this.Obj["project_desc"] = "";

    this.datasource = new MatTableDataSource(this.tabledata);
    this.datasource.sort = this.sort.toArray()[2];
    this.datasource.paginator = this.paginator.toArray()[2];
    $('.nav-tabs a[href="#tab-7-3"]').tab("show");
  }
  async update() {
    this.spinner.show();
    this.nextClick();

    var data = this.tabledata;

    console.log(data);
    this.Obj["b_acct_id"] = this.b_acct_id;
    this.Obj["data"] = JSON.stringify(data);
    this.Obj["update_user_id"] = this.ebillUser["user_id"];
    console.log(this.Obj);
    var resp = await this.DeviationService.updatedeviation(this.Obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.changeworklist();
      Swal.fire("Success", "Completion Updated Successfully ", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }

  async delete(element:any) {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element);
      }
    });
  }

  async finaldelete(element:any) {
    var obj:any = new Object();
    obj["id"] = element["id"];
    obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.DeviationService.deletedeviation(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.changeworklist();
      Swal.fire("Success", "Completion Deleted Successfully ", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Some Error Occured", "error");
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }
}
