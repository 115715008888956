<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Fields Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                All Fields List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i>Create Field</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>Update
                                Field</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="field_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Field ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.field_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="field_business_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Field Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_business_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="field_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Field Code

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_code }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">Update</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <br>


                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Business Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Code :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field Technical Name :</h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="field['field_technical_name']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>Field DataType Name :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allDataTyeps" bindLabel="bus_datatype_name"
                                         bindValue="datatype_code" [multiple]="false"
                                        placeholder="Select Field DataType" [(ngModel)]="field['datatype_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="tab-3">

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Field Name:
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
