import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../../service/main.service';
// import {  mainServiceMd } from "../../../md/service/main.service";
import { ExcelService } from '../../../service/file-export.service';
// import { NewEngService } from '../../../service/new-eng.service';
import {MasterDataService as DepMasterDataComponent} from '../../service/master-data.service'
import {SorItemsService} from '../../service/sor-items.service'
// import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from "xlsx";
import Swal from 'sweetalert2';
import { group } from 'console';
declare var $: any

@Component({
  selector: 'app-sor',
  templateUrl: './sor.component.html',
  styleUrls: ['./sor.component.css']
})
export class SorComponent implements OnInit {
  // columnObj={};
  // columnObjkey={}
  constructor(public mainService: MainService, private excel: ExcelService, private depMasterDataComponent: DepMasterDataComponent, private spinner: NgxSpinnerService,private sorItemsService:SorItemsService) {
    // this.columnObj={category:'Category',sed_item_no:'Schedule Item Number',code_no:'Code No.',ref_to_dar:'Ref. to DAR',group:'Group',sub_group:'Sub Group',pwd_sch:'PWD SCH',item_desc:'Item Description',item_qty:'Item Quantity ',qty_unit:'Quantity Unit',costing_rate:'Costing Rate',costing_rate_unit:'Costing Rate Unit',distance:'Distance',distance_unit:'Distance Unit',laber_rate:'Labour Rate'}
    // this.columnObjkey={'Category':'category','Schedule Item Number':'sed_item_no','Code No.':'code_no','Ref. to DAR':"ref_to_dar",'Group':'group','Sub Group':'sub_group','PWD SCH':'pwd_sch','Item Description':'item_desc','Item Quantity':'item_qty','Quantity Unit':'qty_unit','Costing Rate':'costing_rate','Costing Rate Unit':'costing_rate_unit','Distance':'distance','Distance Unit':'distance_unit','Labour Rate':'laber_rate'}
  }
  b_acct_id:any;
  ebillUser:any;
  chap_lists:any;
  dataObj:any = new Object();
  screen:any;

  async ngOnInit() {
    this.screen = this.sorItemsService.screen;
    this.dataObj['item_type'] = this.sorItemsService.screen;
    let obj:any = {}
    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.ebillUser["b_acct_id"];
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    var resp = await this.depMasterDataComponent.getallChapterField(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      resp.data.map((x:any) => {
        x['field_data'] = JSON.parse(x['field_data'])
      })
      resp.data.map((x:any) => {
        x['chap_desc'] = x['chapter_no'] + ' - ' + x['chapter_desc'];
      })
      this.chap_lists = resp.data;
      console.log(this.chap_lists)
    } else {
      this.spinner.hide();
    }

  }
  ngOnDestroy() {
    // this.sorItemsService.costing_rate_std = " ";
    // this.sorItemsService.screen =  " ";
    // this.screen =
  }

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;


  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sort1', { static: false }) sort1!: MatSort;


  displayedColumns = ["id", "cate", "si_no", "sch_or_dar", "group", "sub_group", "item_desc", "qty_unit", "item_qty", "caste_rate_unit", "cast_rate", "eff_date", "action"];
  datasource:any;
  datasource1:any;

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }




  field_id:any;
  excelflag:any = false;
  fieldsObj:any = {}
  async fields() {
    let maxId: any = 0

    this.spinner.show()
    this.dataObj['b_acct_id'] = this.b_acct_id;
    this.dataObj['item_type'] = this.depMasterDataComponent.screen;

    var resp = await this.sorItemsService.getMaxID(JSON.stringify(this.dataObj));
    if (resp['error'] == false) {
      maxId = resp.data.max_id
    } else {
      this.spinner.hide()
    }
    this.dataObj['id'] = maxId + 1;
    this.spinner.hide()


    this.chap_lists.map((x:any) => {
      if (x['id'] == this.dataObj['chapter_desc']) {
        this.fields_list = Object.keys(x['field_data'])
        this.fieldsObj = x['field_data']
        this.excelflag = true;
      }
    })

    console.log(this.fieldsObj)
    console.log(this.fields_list)
  }


  upload_flag = false;
  update_flag = false;
  u_flag = false;
  c_flag = false;
  l_flag = true;
  list_fg = 'active'
  update_fg = 'deactive'
  create_fg = 'deactive'
  upload_fg = 'deactive'
  list() {
    this.datasource1 = []
    this.dataObj = {}
    this.datasource = new MatTableDataSource([])
    console.log("list")
    this.upload_flag = false
    this.update_flag = false
    this.c_flag = false
    this.l_flag = true
    this.u_flag = false
    this.excel1 = false
    this.list_fg = 'active'
    this.update_fg = 'deactive'
    this.create_fg = 'deactive'
    this.upload_fg = 'deactive'
    this.fetch_fg = false
  }


  createitem() {
    this.datasource1 = []
    this.dataObj = {}
    this.data = {};
    this.fieldsObj = {}
    console.log("createitem")
    this.l_flag = false;
    this.excelflag = false
    this.upload_flag = false
    this.update_flag = false
    this.excel1 = false
    this.u_flag = false
    this.c_flag = true
    this.list_fg = 'deactive'
    this.update_fg = 'deactive'
    this.create_fg = 'active'
    this.upload_fg = 'deactive'
    this.fetch_fg = false
    this.dataObj['costing_rate_std'] = this.sorItemsService.costing_rate_std;
  }

  uploaditems() {
    this.datasource1 = []
    this.dataObj = {}
    console.log("uploaditems")
    this.l_flag = false;
    this.upload_flag = true
    this.excelflag = false
    this.u_flag = false
    this.update_flag = false
    this.excel1 = false
    this.c_flag = false
    this.list_fg = 'deactive'
    this.update_fg = 'deactive'
    this.create_fg = 'deactive'
    this.upload_fg = 'active'
    this.fetch_fg = false
    this.dataObj['costing_rate_std'] = this.sorItemsService.costing_rate_std;
  }

  uploaditem() {
    console.log("uploaditem")
    this.datasource1 = []
    this.l_flag = false;
    this.excel1 = false
    this.upload_flag = false
    this.update_flag = true
    this.c_flag = false
    this.excelflag = false
    // this.u_flag = false
    this.list_fg = 'deactive'
    this.update_fg = 'active'
    this.create_fg = 'deactive'
    this.upload_fg = 'deactive'
    this.fetch_fg = false
  }
  feild_id:any;
  chap_desc:any
  openupdate(element:any) {
    this.datasource1 = []
    console.log(this.chap_lists)
    this.feild_id = element.id
    this.dataObj = element;
    // chap_desc = element.chapter_desc
    this.dataObj['id'] = element.chapter_desc
    this.fieldsObj = element
    this.data = element;
    console.log(this.dataObj)
    this.l_flag = false;
    this.upload_flag = false
    this.update_flag = true
    this.excel1 = false
    this.u_flag = true;
    this.c_flag = false
    this.excelflag = false
    this.list_fg = 'deactive'
    this.update_fg = 'active'
    this.create_fg = 'deactive'
    this.upload_fg = 'deactive'


    this.chap_lists.map((x:any) => {
      if (x['id'] == element.chapter_desc) {
        this.chap_desc = x['chap_desc']
      }
    })


  }
  gotoList() {
    this.upload_flag = false
    this.update_flag = false
    this.c_flag = false
    this.excelflag = false
    this.l_flag = true
    this.list_fg = 'active'
    this.update_fg = 'deactive'
    this.create_fg = 'deactive'
    this.upload_fg = 'deactive'
    this.datasource = []
    this.dataObj = {}
  }
  fetch_fg = false;
  all_itemList:any ;
  async fetch() {
    this.spinner.show()
    this.dataObj['b_acct_id'] = this.b_acct_id;
    this.dataObj['item_type'] = this.depMasterDataComponent.screen;
    console.log(this.dataObj)
    console.log(this.depMasterDataComponent.screen);
    var resp = await this.sorItemsService.fetch(JSON.stringify(this.dataObj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.all_itemList = resp.data;
      console.log(resp.data)
      if (resp.data.length != 0) {
        // this.displayedColumns = []
        // let obj = resp['data'][0]
        // for (var key in obj) {
        // if (obj[key] != null && obj[key] != "" && obj[key] != undefined)
        // this.displayedColumns.push(key)
        // }
        this.fetch_fg = true;
        // this.displayedColumns.push('Action');
        this.datasource = new MatTableDataSource(resp.data)
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      } else {
        Swal.fire("Oops!!",this.screen + ' item are not created given year of '+ this.screen+' and  Region and Chapter / Sub-Head', "warning");
        this.dataObj = {}
        this.fetch_fg = false;
        // this.displayedColumns = [];
        this.datasource = new MatTableDataSource([]);
      }
    } else {
      this.spinner.hide()

    }
  }


  fields_list:any = []

  export() {
    console.log(this.fields_list)
    this.spinner.show();
    var exp = [];
    var obj:any = new Object();

    for (var j = 0; j < this.fields_list.length; j++) {
      obj[this.fields_list[j]] = '';
    }

    exp.push(obj);
    this.excel.exportAsExcelFile(exp,( this.sorItemsService.screen+" item file"));
    this.spinner.hide()
  }
  excel1:any = false;
  data_excel:any = [];
  async onFileChange(event: any) {
    this.spinner.show()
    this.data_excel = []
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { raw: false }); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data);
      this.data_excel = data
      this.datasource1 = new MatTableDataSource(this.data_excel);
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sort1;

      // Data will be logged in array format containing objects
    };
    this.spinner.hide()
    this.excel1 = true
  }

  async upload() {
    this.spinner.show()
    this.dataObj['b_acct_id'] = this.b_acct_id;
    this.dataObj['data'] = this.data_excel
    this.dataObj['item_type'] = (this.sorItemsService.screen).toUpperCase()
    console.log(this.dataObj)
    var resp = await this.sorItemsService.createsoritem(this.dataObj);
    if (resp['error'] == false) {
      console.log(resp)
      this.spinner.hide()
      Swal.fire("UPLOAD", 'Uploaded Successfully', 'success');
      this.data_excel = []
      this.gotoList();
      this.fetch_fg = false;
    } else {
      this.spinner.hide()

      Swal.fire("Errro", 'Error While Uploading', 'error');

    }
  }


  data:any = {};
  async submit() {
    this.spinner.show();
    let ob = []
    ob.push(this.data)
    this.dataObj['data'] = ob
    this.dataObj['b_acct_id'] = this.b_acct_id
    this.dataObj['item_type'] = (this.sorItemsService.screen).toUpperCase()
    console.log(this.dataObj)
    var resp = await this.sorItemsService.createsoritem(this.dataObj);
    if (resp['error'] == false) {
      console.log(resp)
      this.spinner.hide()
      Swal.fire("SUCCESS", "Submitted Successfully", 'success');
      this.dataObj = {}
      this.data = ''
      $('.nav-tabs a[href="#tab-7-1"]').tab('show')
      this.gotoList();
      this.fetch_fg = false;
    } else {
      this.spinner.hide()
      Swal.fire("ERROR", "Error While Submitting ", 'error');

    }

  }

  async update() {
    this.spinner.show()
    let ob = []
    ob.push(this.data)
    // this.dataObj['data'] = ob;
    this.dataObj['b_acct_id'] = this.b_acct_id
    this.dataObj['id'] = this.feild_id
    this.dataObj['item_type'] = this.depMasterDataComponent.screen;
    console.log(this.dataObj)
    var resp = await this.sorItemsService.updatesoritem(this.dataObj);
    if (resp['error'] == false) {

      this.spinner.hide()
      console.log(resp)
      Swal.fire("UPDATE", "Updated Successfully", 'success');
      this.data = ''
      this.field_id = ''
      $('.nav-tabs a[href="#tab-7-1"]').tab('show')
      this.gotoList();
      this.fetch_fg = false;
    } else {
      this.spinner.hide()

      Swal.fire("ERROR", "Error While Updating", 'error');
    }
  }

}
