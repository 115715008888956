<div class="page-content fade-in-up">
<div class="row">

    <div class="col-lg-12 col-md-12">

        <div class="ibox">
            <br>
            <div class="ibox-head">
                <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement1']}}<!-- Annual Salary Statement --> </div>
            </div>

            <div class="ibox-body">
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement2']}}<!-- Select Employee --> :<span style="color: red;">*</span>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="allEmplyees_new" bindLabel="tempemp_name" bindValue="emp_id"
                            [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Report2']}}" [(ngModel)]="selectObj['emp_id']"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" (change)="changeName()"
                            [closeOnSelect]="true" name="field_name"> <!-- Select Employee -->
                        </ng-select>

                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement3']}}<!-- Name Of Employee --> :
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" disabled [(ngModel)]="selectObj['emp_name']" name="" id="">

                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement4']}}<!-- Select Year --> : <span style="color: red;">*</span>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="mainService.codeValueTechObj['HR0023']" bindLabel="value" bindValue="code"
                            [multiple]="false" placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Report4']}}" [(ngModel)]="selectObj['fin_year']"
                            [selectableGroup]="true" (change)="reset_flag()" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="field_name"> <!-- Select Year -->
                        </ng-select>
                    </div>

                </div>

                <br>
                <div class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-success" (click)="itreport()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement5']}}<!-- Submit --></button>
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="this.selectObj['print_flag']">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" (click)="print1()">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement6']}}<!-- Download ITR --></button>
                    </div>
                </div>
              
                <br>
            </div>
        </div>
    </div>
</div>
</div>
<ngx-spinner>{{mainService.allLableShowObjHR[mainService.language_cd+'HR-AnnualSalaryStatement7']}}<!-- Loading -->...</ngx-spinner>