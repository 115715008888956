import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service';
//oc
import { SettingService } from '../../../../property/service/setting.service';
// private SettingService:SettingService
//ec
declare var $: any
import { FilterService } from '../../../service/filter.service';

import { MainService } from '../../../service/main.service'
@Component({
  selector: 'app-code-value',
  templateUrl: './code-value.component.html',
  styleUrls: ['./code-value.component.css']
})
export class CodeValueComponent implements OnInit {
  constructor(private SettingService :SettingService , private filterService: FilterService,public mainService: MainService, private referenceDataS: ReferenceDataService, private router: Router, private spinner: NgxSpinnerService) { }

  l_code_flag=true;
  c_code_flag=false;
  u_code_flag=false;
  ebillUser:any;
  b_acct_id:any;
  allFields = [];
  obj:any = {};
  selectField:any;
  allCodeValue:any = [];
  selectedCodeValue:any = []
  codeValueObj:any = {};
  codeValueShowObj:any = {};
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  displayedColumns = ['id', 'code', 'value', 'action'];
  datasource:any;
  async ngOnInit() {


    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getAllFields();
    await this.getCodeValue();

  }
  async reset_CodeValue() {
    this.spinner.show();
    //oc
     var resp= await this.SettingService.getCodeValue(this.b_acct_id);
    //ec
    //rc
    var resp:any=[]
    //lc
    console.log(resp)
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        resp['data'][i]['desc'] = resp['data'][i]['code'] + '-' + resp['data'][i]['value']
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }

  }
  async getCodeValue() {
    this.spinner.show()
    let obj:any  = {}
    obj['b_acct_id'] = this.b_acct_id
    //oc
    var resp:any= await this.SettingService.getCodeValue(this.b_acct_id);
    //ec
//rc

//lc
    if (resp['error'] == false) {
      this.allCodeValue = resp.data;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire("Oops", "...Error while getting all values!");
    }
  }

  temp_selectedCodeValue:any=[];
  open_update(element:any) {
    this.l_code_flag=false;
    this.c_code_flag=false;
    this.u_code_flag=true;
    this.temp_selectedCodeValue=[];
    for (var i = 0; i < this.selectedCodeValue.length; i++) {
      if (element['code'] != this.selectedCodeValue[i]['code']) {
        this.temp_selectedCodeValue.push(this.selectedCodeValue[i]);
      }
    }
    this.obj = Object.assign({}, element);
    this.selectField = element.field_code;
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.obj = {};
  }


  async changeField() {
    this.selectedCodeValue = [];
    for (var i = 0; i < this.allCodeValue.length; i++) {
      if (this.selectField == this.allCodeValue[i].field_code) {
        this.selectedCodeValue.push(this.allCodeValue[i]);
      }
    }
    console.log(this.selectField);
    console.log(this.selectedCodeValue);



    console.log(this.selectedCodeValue);
    this.datasource = new MatTableDataSource(this.selectedCodeValue)

    this.datasource.sort = this.sort.toArray()[0];
    this.datasource.paginator = this.paginator.toArray()[0];
  }

  async getAllFields() {
    this.spinner.show()
    var obj:any = new Object();
    obj['domain_code'] = 'EMB';
    obj['b_acct_id'] = this.b_acct_id;
    //oc
    var resp = await this.SettingService.getFields(JSON.stringify(obj));
    //ec
    //rc
    // var resp:any=[]
    //lc
    if (resp['error'] == false) {
      this.allFields = resp.data;

    } else {
      this.spinner.hide()
      swal.fire("Oops", "...Error while getting all fields!");

    }
  }

  async save() {
    let validate = 1
    this.obj['create_user_id'] = this.ebillUser.user_id
    this.obj['field_code'] = this.selectField;
    this.obj['b_acct_id'] = this.b_acct_id;
    if (this.selectField ==undefined || this.obj['code']==undefined || this.obj['value'] == undefined) {
      validate = 0
    }
    for(var i=0;i<this.selectedCodeValue.length;i++){
      if(this.selectedCodeValue[i]['code'] == this.obj['code']){
        validate =0;
        break;
      }
      if(this.selectedCodeValue[i]['value'] == this.obj['value']){
        validate =0;
        break;
      }
    }

    if (validate == 1) {
      this.spinner.show();
      //oc
      var resp:any = await this.SettingService.insertCodeValue(this.obj);
      //ec
      //rc
      // let resp:any=[]
      //lc
      if (resp['error'] == false) {
        await this.getCodeValue();
        await this.changeField()
        await this.getCodeValueForService();
        await this.reset_CodeValue()
        this.spinner.hide();
        swal.fire("Successfully Created", "...code value", 'success');

     this.gotolist();

        this.datasource.sort = this.sort.toArray()[0];
        this.datasource.paginator = this.paginator.toArray()[0];

      } else {
        this.spinner.hide();
        swal.fire("Oops", "...Error while adding code value!");

      }
    } else {
      swal.fire('Warning...', 'Either the code-value already exists or you have left it blank', 'warning')

    }

  }
  async gotolist(){
    this.l_code_flag=true;
    this.c_code_flag=false;
    this.u_code_flag=false;
    await this.getCodeValue();
        await this.changeField()
        await this.getCodeValueForService();
        await this.reset_CodeValue()

  }
  async getCodeValueForService() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    //oc
    var resp = await this.SettingService.getCodeValue(JSON.stringify(obj));
    //ec
    //re
    var resp:any=[]
    //lc
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;

      if(this.ebillUser['zone_cd']!=null){
        this.codeValueObj['EMB003']=[{code:this.ebillUser['zone_cd'],value:this.codeValueShowObj['EMB003'][this.ebillUser['zone_cd']]}]
      }

      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
      await this.filterService.FilterDataFunctionForCodeValue();


    } else {

    }

  }
  async update() {
    console.log(this.temp_selectedCodeValue)
    var validate = 1;
    if (this.selectField ==undefined || this.obj['code']==undefined || this.obj['value'] == undefined) {
      validate = 0
    }
    var arr=[];

    for(var i=0;i<this.temp_selectedCodeValue.length;i++){
      if(this.temp_selectedCodeValue[i]['code'] == this.obj['code']){
        validate =0;
        break;
      }
      arr.push(this.temp_selectedCodeValue[i])
      if(this.temp_selectedCodeValue[i]['value'] == this.obj['value']){
        validate =0;
        break;
      }
    }



    console.log(validate)
    this.obj['update_user_id'] = this.ebillUser.user_id;
    this.obj['field_code'] = this.selectField;
    this.obj['b_acct_id'] = this.b_acct_id;
    if (validate == 1) {
      this.spinner.show();
      //oc
      // var resp = await this.SettingService.updateCodeValues(this.obj);
      //ec
      //re
      let resp:any=[]
      //lc
      if (resp['error'] == false) {
        await this.getCodeValue();
        await this.changeField();
        this.spinner.hide();
        await this.getCodeValueForService();
        await this.reset_CodeValue()
        swal.fire("Successfully Updated", "", 'success');
       this.gotolist()



      } else {
        this.spinner.hide();
        swal.fire("Oops", "...Error while updating code value!", 'error');

      }
    } else {
      swal.fire('Warning...', 'Either the code-value already exists or you have left it blank', 'warning')
    }

  }
  gotocrete()
  {
    this.refresh();
    this.l_code_flag=false;
        this.c_code_flag=true;
        this.u_code_flag=false;
        this.datasource = new MatTableDataSource(this.selectedCodeValue)
        this.datasource.sort = this.sort.toArray()[0];
        this.datasource.paginator = this.paginator.toArray()[0];

  }

  async deleteSwal(element:any){
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.delete(element)
      }
    })
  }
  async delete(element:any) {
    this.spinner.show();
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id;
    //oc
    var resp:any = await this.SettingService.deleteCodeValue(JSON.stringify(obj));
    //ec
    //rc

    //lc

    if (resp['error'] == false) {
      await this.getCodeValue();
      await this.changeField()
      await this.getCodeValueForService();
      await this.reset_CodeValue()
      this.spinner.hide();
      swal.fire("Successfully Deleted", " ", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Oops", "...code value not deleted!", 'error');
    }
  }


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

}
