<div id="sidebar-collapse" class="sidebar">

    <div class="admin-block d-flex" style="align-items: center;">
        <div>
            <img src="./../../../assets/img/dash/vms.png" class="img-circle" width="45px" />
        </div>
        <div class="admin-info">
            <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['VMS']}}</div>
        </div>
    </div>
    <ul class="side-menu metismenu">
        <li>
            <a class = "text-decoration-none"  routerLinkActive="active" (click)="refreshCol()" routerLink="/vms/vms-index"><i class="sidebar-item-icon fa fa-th-large"></i>
                <span class="nav-label"> Dashboard </span>
            </a>
        </li>
        <li>
            <a class = "text-decoration-none"  routerLinkActive="active" (click)="refreshCol()" routerLink="/vms/vms-vendor"><i class="sidebar-item-icon fa fa-user"
                aria-hidden="true"></i>
                <span class="nav-label"> Vendor </span>
            </a>
        </li>

        <!-- <li [hidden]="this.sidebar['tender_management']">
            <a class = "text-decoration-none"  routerLinkActive="active" routerLink="/emd/tender"><i class="sidebar-item-icon fa fa-file-text"
                    aria-hidden="true"></i>
                <span class="nav-label"> Tender </span>
            </a>
        </li>

        <li [hidden]="this.sidebar['vendor_management']">
            <a class = "text-decoration-none"  routerLinkActive="active" routerLink="/emd/vendor"><i class="sidebar-item-icon fa fa-user"
                    aria-hidden="true"></i>
                <span class="nav-label"> Vendor </span>
            </a>
        </li>

        <li [hidden]="this.sidebar['payment_management']">
            <a class = "text-decoration-none"  routerLinkActive="active" routerLink="/emd/payment"><i class="sidebar-item-icon fa fa-money"
                    aria-hidden="true"></i>
                <span class="nav-label"> Payment </span>
            </a>
        </li>

        <li [hidden]="this.sidebar['assign_tender']">
            <a class = "text-decoration-none"  routerLinkActive="active" routerLink="/emd/assign-tender"><i class="sidebar-item-icon fa fa-handshake-o"
                    aria-hidden="true"></i>
                <span class="nav-label"> Assign Tender </span>
            </a>
        </li>

        <li>
            <a class = "text-decoration-none"  [hidden]="this.sidebar['emd_refund']" routerLinkActive="active" routerLink="/emd/emd-refund"><i
                    class="sidebar-item-icon fa fa-exchange" aria-hidden="true"></i>
                <span class="nav-label"> EMD Refund </span>
            </a>
        </li> -->

        <!-- <li routerLinkActive="active">
            <a class = "text-decoration-none"  href="javascript:;"><i class="sidebar-item-icon fa fa-archive"></i>
                <span class="nav-label">Tracking</span><i class="fa fa-angle-left arrow"></i></a>

            <ul class="nav-2-level collapse" routerLinkActive="in">

                <li>
                    <a class = "text-decoration-none"  routerLink="/emp-tracking" routerLinkActive="active">Emp Tracking</a>
                </li>

            </ul>
        </li> -->



        <!-- ////////////////////////////////////////////////////////////////////////////////////////////// -->

         <li routerLinkActive="active">
            <a class = "text-decoration-none"  href="#AdminCol" data-bs-toggle="collapse" aria-expanded="false"
            aria-controls="AdminCol"><i
                    class="sidebar-item-icon fa fa-database"></i>
                <span class="nav-label">Administration</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" id="AdminCol" routerLinkActive="in">
              <div id="adminChild">
                <li>
                    <a class = "text-decoration-none" href="#md" data-bs-toggle="collapse" aria-expanded="false"
                    aria-controls="md">
                        <span class="nav-label">Master Data</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse" id="md"  data-bs-parent="#adminChild">

                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/vms-setting/code-value" routerLinkActive="active">Code Value</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/vms-setting/field" routerLinkActive="active">Field</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/administration/work" routerLinkActive="active">Work</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/administration/faq" routerLinkActive="active">FAQ's</a>
                        </li>


                        <li >
                            <a class = "text-decoration-none"  routerLink="/vms/vms-setting/hierarchy" routerLinkActive="active">
                                Hierachy
                            </a>
                        </li>

                    </ul>
                </li>
                <li>
                    <a class = "text-decoration-none"   href="#sc" data-bs-toggle="collapse" aria-expanded="false"
                    aria-controls="sc">
                        <span class="nav-label">Security</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse" id="sc"  data-bs-parent="#adminChild">
                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/administration/roles" routerLinkActive="active">
                                Role
                            </a>
                        </li>
                        <li>
                            <a class = "text-decoration-none"  routerLink="/vms/administration/users" routerLinkActive="active">User</a>
                        </li>
                        <li >
                            <a class = "text-decoration-none"  routerLink="/vms/administration/data-assignment" routerLinkActive="active">
                                Data Assignment
                            </a>
                        </li>
                        <li >
                            <a class = "text-decoration-none"  routerLink="/vms/vms-setting/workflow" routerLinkActive="active">
                                Workflow
                            </a>
                        </li>
                    </ul>
                </li>
              </div>
            </ul>
        </li>
        <!-- <li routerLinkActive="active">
            <a class = "text-decoration-none"  href="javascript:;"><i class="sidebar-item-icon fa fa-question-circle"></i>
                <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">

            </ul>
        </li> -->
    </ul>
</div>
