<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <!-- <div class="ibox-title">{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Deduction1']}} -->
                        <!-- Deduction Reports --> 
                    <!-- </div> -->
                    <div class="ibox-title"> Salary Rule  </div>

                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Deduction2']}} -->
                                <!-- PF Deduction Report -->
                                Salary Calculation Rule List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refrace()" ><i class="ti-bar-chart"></i> 
                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Deduction3']}} -->
                                <!-- TDS Deduction Report -->
                                Create Month's Day
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"  id="ex1-tab-2" ><i class="ti-bar-chart"></i>
                                <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Deduction4']}} -->
                                <!-- Allowances/Deductions Report -->
                                Update Month's Day
                            </a>
                        </li>

                    </ul>
                    <!-- show list -->
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1" >
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right"> -->
                                    <!-- Employement Type : -->
                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'
                                    Establishments_code30']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                        bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="addEstObj['establishment_type_code']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name" (change)="changeemployement()">
                                    </ng-select>
                                </div>
                            </div> -->
                            <br>
<!-- m_avinash -->
       
<div class="row">
    <div class="col-5 text-right">
        <h6 style="margin-right: 10%;">
            Select Node : <span style="color:red">*</span>
        </h6>
    </div>
    <div class="col-4 text-left margin-left 10%">
        <button class="btn btn-primary" (click)="open_activitypopup()">Select
            Employee Node</button>
    </div>
 

    </div>
    <br>
    <div class="row">
    <div class="col-5 text-right">
        <h6 style="margin-right: 10%;">
            Selected Node Path:<span style="color: red;">*</span>
        </h6>
    </div>
    <div class="col-4 text-left margin-left: 10%">
       <input class="form-control" [(ngModel)]="Obj['path_desc']" disabled>
       <!-- {{Obj['path_desc']}} -->
    </div>
</div>
<br>
<div class="row">
    <div class="col-12 text-center">
        <button class="btn btn-primary" (click)="check_rule()" >
            <!-- check Rule -->
            Check
        </button>
    </div> 
</div>
<br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="open_create()" >
                                        <!-- SUBMIT -->
                                        Update
                                    </button>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-3 r1 text-center">
                                    <h6>MONTH</h6>
                                </div>
                                <div class="col-3 r1 text-center">
                                    <h6>NO OF DAYS(GENERAL)</h6>
                                </div>
                                <div class="col-3 r1 text-right">
                                    <h6>NO OF DAYS(LEAP YEAR)</h6>
                                </div>
                            
                            </div>
                            <div class="row" *ngFor="let ob of  alldata; let i = index">
                               
                                <div class="col-3 r1 text-center">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['month']" disabled>
                                </div>
                                <div class="col-3 r1 text-center">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['no_of_days_gen']" disabled>
                                </div>
                                <div class="col-4 r1 text-center" *ngIf="alldata[i]['month'] =='February' ">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['no_of_days_leap']" disabled>
                                </div>
                                
                            </div>

                    </div>
                    <!-- CREATE  -->
                    <div class="tab-pane fade" id="tab-2">
                        <br>
                        <!-- <div class="row">
                        <div class="col-4 text-right"> -->
                            <!-- Employement Type : -->
                            <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'
                            Establishments_code30']}}
                        </div>
                        <div class="col-4">
                            <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                [(ngModel)]="addEstObj['establishment_type_code']"
                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name" aria-disabled="false" (change)="changetype()">
                            </ng-select>
                        </div>
                    </div> -->

                    <br>
               <!-- m_avinash        -->
                    <div class="row">
                        <div class="col-5 text-right">
                            <h6 style="margin-right: 10%;">
                                Select Node : <span style="color:red">*</span>
                            </h6>
                        </div>
                        <div class="col-4 text-left margin-left 10%">
                            <button class="btn btn-primary" (click)="open_activitypopup()">Select
                                Employee Node</button>
                        </div>
                     

                        </div>
                        <br>
                        <div class="row">
                        <div class="col-5 text-right">
                            <h6 style="margin-right: 10%;">
                                Selected Node Path:<span style="color: red;">*</span>
                            </h6>
                        </div>
                        <div class="col-4 text-left margin-left: 10%">
                           <input class="form-control" [(ngModel)]="Obj['path_desc']" disabled>
                           <!-- {{Obj['path_desc']}} -->
                        </div>
                    </div>
<br>
                            <br>
                            <div class="row">
                                <div class="col-4 r1 text-center">
                                    <h6>MONTH</h6>
                                </div>
                                <div class="col-4 r1 text-center">
                                    <h6>NO OF DAYS(GENERAL)</h6>
                                </div>
                                <div class="col-3 r1 text-right">
                                    <h6>NO OF DAYS(LEAP YEAR)</h6>
                                </div>
                            
                            </div>
                            <div class="row" *ngFor="let ob of ALLmonth; let i = index">
                                <!-- <div class="col-3 r1 text-center">
                                    {{i+1}}
                                </div> -->
        
                                <div class="col-4 r1 text-center">
                                    <input class="form-control text-center" [(ngModel)]=" ALLmonth[i]['value']" disabled>
                            
                                </div>
                                <div class="col-4 r1 text-center">
                                    <input class="form-control text-center" size="4" [(ngModel)]="ALLdays[i]">
                                </div>
                                <div class="col-4 r1 text-center" *ngIf="ALLmonth[i]['value'] =='February' ">
                                    <!-- <div>
                                       {{ ob.allleap_days}}
                                    </div> -->
                                     <input class="form-control text-center" size="4" [(ngModel)]=" leap['leap_date']"> 

                                    <!-- <input class="form-control text-center" size="4" [(ngModel)]=" this.ALLleap[i]">  -->
                            
                                </div>
                                
                            </div>
                            
                             <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()" >SUBMIT</button>
                                    <!-- SUBMIT -->
                                </div>
                            </div>


                    </div>
                      <!-- update  -->
                    <div class="tab-pane fade" id="tab-3" >
                        <br>
                        <!-- <div class="row">
                        <div class="col-4 text-right"> -->
                            <!-- Employement Type : -->
                            <!-- {{mainService.allLableShowObjHR[mainService.language_cd+'
                            Establishments_code30']}}
                        </div>
                        <div class="col-4">
                            <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                [(ngModel)]="addEstObj['establishment_type_code']"
                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                [closeOnSelect]="true" name="field_name" disabled>
                            </ng-select>
                        </div>
                    </div> -->

                    <br>
                            <br>
                            <div  *ngIf="display">
                            <div class="row">
                                <div class="col-3 r1 text-center">
                                    <h6>MONTH</h6>
                                </div>
                                <div class="col-3 r1 text-center">
                                    <h6>NO OF DAYS(GENERAL)</h6>
                                </div>
                                <div class="col-3 r1 text-right">
                                    <h6>NO OF DAYS(LEAP YEAR)</h6>
                                </div>
                            
                            </div>
                            <div *ngIf="display" >
                            <div class="row" *ngFor="let ob of  alldata; let i = index"  >
                               
                                <div class="col-3 r1 text-center">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['month']" disabled>
                                </div>
                                <div class="col-3 r1 text-center">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['no_of_days_gen']" >
                                </div>
                                
                                <div class="col-4 r1 text-center" *ngIf="alldata[i]['month'] =='February' ">
                                    <input class="form-control text-center" [(ngModel)]=" alldata[i]['no_of_days_leap']" >
                                </div>
                                
                            </div>
          <br>
        </div>
          <br>
          <div class="row">
            <div class="col-12 text-center">
          <button class="btn btn-primary " (click)="allupdate()" >
            <!-- update -->
            Update
        </button>
        </div>
        </div>
    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
</div>

<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false"
                         placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                          (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submitProjectHierNode()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>
