<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Product </div>
                </div>

                <div class="ibox-body">

                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Product List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="create_flag" data-toggle="tab">
                                <div *ngIf="update_flag==true"><i class="ti-settings"></i> Create
                                    Product</div>
                                <div *ngIf="update_flag==false"><i class="ti-settings"></i> Update Product</div>
                            </a>
                        </li>

                    </ul>

                    <div class="tab-content">




                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right" style="text-align: right;">
                                    <button style="margin-right: 5px" class="btn btn-primary" href="#tab-2"
                                        data-toggle="tab" (click)="createNew()" *ngIf="update_flag==true">Create
                                        New</button>
                                    <button style="margin-right: 5px" class="btn btn-success" href="#tab-2"
                                        data-toggle="tab" (click)="createNew()"
                                        *ngIf="update_flag==false">Update</button>
                                </div>
                            </div>
                            <br>
                            <br>
                            <div *ngFor="let x of allProduct">
                                <div class="row">
                                    <div class="col-6 text-center">
                                        <h6>Product Code:</h6>
                                    </div>


                                    <div class="col-6">{{x.product_cd}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-6 text-center">
                                        <h6> Product Desc:</h6>
                                    </div>
                                    <div class="col-6">{{x.product_desc}}</div>
                                </div>

                                <br>
                            </div>

                            <br>
                        </div>


                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="listfunc()">Back to list</button>

                                </div>
                                <div class="col-3 text-center">


                                </div>
                                <div class="col-3 text-center"
                                    style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                    <h6><b> Code </b></h6>
                                    <!-- Code -->

                                </div>
                                <div class="col-3 text-center"
                                    style="background-color: #217dbb;color:white;padding-top: 8px;padding-bottom: 8px;">
                                    <h6><b>Value </b></h6>
                                    <!--  -->

                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>Level 1</h6>
                                </div>
                                <!-- Level 1: -->
                                <div class="col-3" *ngIf="obj['lvl1_type']!='text'">
                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl1_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl1_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl1_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl1_type']!='text'">
                                    <ng-select [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                        (change)="onChangeLvl1()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl1_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl1_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl1_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl1_value']">

                                </div>
                                <div id="text" class="col-3" (click)="addNew('1')"
                                    *ngIf="obj['lvl1_type']!=text  && level1.length==0"
                                    style="color: rgb(37, 130, 236);">
                                    <h6><b><u>Add New
                                            </u></b></h6>
                                </div>
                                <!--   -->
                                <div id="text" class="col-3" *ngIf="obj['lvl1_type']==text && level1.length==0"
                                    (click)="addNew('1')" style="color: rgb(37, 130, 236);">
                                    <h6><b><u>add new
                                            </u></b></h6>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Level 2</h6>
                                </div>
                                <!--  : -->
                                <div class="col-3" *ngIf="obj['lvl2_type']!='text'">
                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl2_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl2_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl2_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl2_type']!='text'">
                                    <ng-select [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                        (change)="onChangeLvl2()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl2_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl2_value">
                                    </ng-select>
                                </div>

                                <div class="col-3" *ngIf="obj['lvl2_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl2_value']">

                                </div>

                                <!-- Add New -->

                                <!-- Select  -->
                            </div>
                            <br>

                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6>Level 3 </h6>
                                </div>
                                <!-- Level 3 : -->
                                <div class="col-3" *ngIf="obj['lvl3_type']!='text'">

                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl3_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl3_type']=='text'">

                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl3_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl3_type']!='text'">
                                    <ng-select [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                        (change)="onChangeLvl3()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl3_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl3_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl3_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl3_value']">

                                </div>

                                <!-- Add New   -->

                                <!-- Select -->
                            </div>
                            <br>

                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6>Level 4</h6>
                                </div>
                                <!--  :  -->
                                <div class="col-3" *ngIf="obj['lvl4_type']!='text'">

                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl4_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl4_type']=='text'">

                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl4_cd']">

                                </div>

                                <div class="col-3" *ngIf="obj['lvl4_type']!='text'">
                                    <ng-select [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                        (change)="onChangeLvl4()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl4_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl4_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl4_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl4_value']">

                                </div>

                                <!-- -->

                                <!--  -->
                            </div>
                            <br>

                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6> Level 5 </h6>
                                </div>
                                <!--  -->
                                <div class="col-3" *ngIf="obj['lvl5_type']!='text'">

                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl5_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl5_type']=='text'">

                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl5_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl5_type']!='text'">

                                    <ng-select [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                        (change)="onChangeLvl5()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl5_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl5_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl5_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl5_value']">

                                </div>


                                <!--  -->
                            </div>
                            <br>

                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6>Level 6 </h6>
                                </div>
                                <!--  : -->
                                <div class="col-3" *ngIf="obj['lvl6_type']!='text'">

                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl6_cd']" disabled>

                                </div>

                                <div class="col-3" *ngIf="obj['lvl6_type']=='text'">

                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl6_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl6_type']!='text'">

                                    <ng-select [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                        (change)="onChangeLvl6()" [multiple]="false" placeholder=""
                                        [(ngModel)]="obj['lvl6_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="lvl6_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl6_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl6_value']">

                                </div>
                            </div>
                            <br>

                            <div class="row">

                                <div class="col-3 text-right">
                                    <h6> Level 7</h6>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl7_type']!='text'">

                                    <input type="text" class="form-control" [(ngModel)]="obj['lvl7_cd']" disabled>

                                </div>
                                <div class="col-3" *ngIf="obj['lvl7_type']=='text'">

                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl7_cd']">

                                </div>
                                <div class="col-3" *ngIf="obj['lvl7_type']!='text'">

                                    <ng-select [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['lvl7_cd']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        (change)="onChangeLvl7()" name="lvl7_value">
                                    </ng-select>
                                </div>
                                <div class="col-3" *ngIf="obj['lvl7_type']=='text'">
                                    <input type="text" class="form-control" (change)="makingLeafValues()"
                                        [(ngModel)]="obj['lvl7_value']">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Leaf :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" [(ngModel)]="obj['leaf_cd']">
                                </div>
                                <div class="col-3">
                                    <input type="text" class="form-control" [(ngModel)]="obj['leaf_value']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()" *ngIf="update_flag==true">
                                        Submit </button>
                                    <button class="btn btn-success" (click)="save()" *ngIf="update_flag==false">
                                        Update </button>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<ngx-spinner>Loading....</ngx-spinner>