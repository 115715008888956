import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainService } from '../../service/main.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { AllEmpService } from '../../service/all-emp.service';
import { SettingService } from '../../service/setting.service';
import Swal from 'sweetalert2';
declare var $: any

@Component({
  selector: 'app-leaves',
  templateUrl: './leaves.component.html',
  styleUrls: ['./leaves.component.css']
})
export class LeavesComponent implements OnInit {

  constructor(public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService, private payableService: EstablishmentService) { }

  erpUser: any;
  b_acct_id: any;
  allEmployees: any = [];
  selectEmpObj: any = {};
  leaveObj: any = { is_leave_carry_fwd: '', renew_ind_on_year_change: '', leave_rate: '' };
  llObj: any = {};
  codeValueTechObj: any = {};
  leave_status: any = 0;
  create_user_id: any;
  update_user_id: any;
  newallEmplyees: any = []
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns: any = ['leave_code', 'num_of_leaves', 'year', 'month', 'leaves_remaining', 'action'];
  datasource: any;
  allLeaveRuleInfo: any = [];
  allLeaveInfo: any = [];
  activeEmpArr: any = [];
  allEmp: any = [];
  employeeObj: any = {};
  remLeaveInfo: any = [];
  print_flag: boolean = true
  print_flag_1: boolean = true
  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllLeaveRuleDetails();
    await this.getAllEmployees();
    await this.getAllLeaveInfo();
  }
  async getAllLeaveInfo() {
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.payableService.getLeaveInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allLeaveInfo = resp.data;
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee all leave info list', 'error')
      // this.snackBar.open("Error while getting employee all leave info list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async getAllLeaveRuleDetails() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.getAllRules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allLeaveRuleInfo = resp.data;
      for (let i = 0; i < this.allLeaveRuleInfo.length; i++) {
        this.allLeaveRuleInfo[i]['leave_value'] = this.mainService.codeValueShowObj['HR0026'][this.allLeaveRuleInfo[i]['leave_code']];
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  all Leave Rule list ', 'error')
      // this.snackBar.open("Error while getting  all Leave Rule list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  leaveType: any;
  allLeaveInfoEmp: any = [];
  async getAllLeaveInfoEmp() {
    for (let i = 0; i < this.allLeaveInfo.length; i++) {
      if (this.allLeaveInfo[i]['emp_id'] == this.selectEmpObj['emp_id']) {
        this.allLeaveInfoEmp.push(this.allLeaveInfo[i])
      }
    }
  }
  async changeLeaveType() {
    await this.getAllLeaveInfoEmp();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    var resp = await this.payableService.getRemainingLeave(JSON.stringify(obj));
    this.remLeaveInfo = [];
    if (resp['error'] == false) {
      this.remLeaveInfo = resp.data;

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting  remaining leave list ', 'error')
      // this.snackBar.open("Error while getting  remaining leave list", 'Error', {
      //   duration: 5000
      // });
    }
    console.log(this.leaveObj)
    if (this.allLeaveInfoEmp.length == 0) {
      for (let i = 0; i < this.allLeaveRuleInfo.length; i++) {
        if (this.allLeaveRuleInfo[i]['leave_code'] == this.leaveObj['leave_code']) {
          this.leaveType = this.allLeaveRuleInfo[i];
          this.leaveObj['is_leave_carry_fwd'] = this.allLeaveRuleInfo[i]['is_leave_carry_fwd'];
          this.leaveObj['renew_ind_on_year_change'] = this.allLeaveRuleInfo[i]['renew_ind_on_year_change'];
          this.leaveObj['leave_rate'] = this.allLeaveRuleInfo[i]['leave_rate'];
          this.leaveObj['num_of_leaves'] = this.allLeaveRuleInfo[i]['num_of_leaves'];
        }
      }
    }
    else {
      if (this.remLeaveInfo.length != 0) {
        for (let i = 0; i < this.remLeaveInfo.length; i++) {
          if (this.remLeaveInfo[i]['leave_code'] == this.leaveObj['leave_code']) {
            this.leaveType = this.allLeaveRuleInfo[i];
            this.leaveObj['is_leave_carry_fwd'] = this.remLeaveInfo[i]['is_leave_carry_fwd'];
            this.leaveObj['renew_ind_on_year_change'] = this.remLeaveInfo[i]['renew_ind_on_year_change'];
            this.leaveObj['leave_rate'] = this.remLeaveInfo[i]['leave_rate'];
            this.leaveObj['num_of_leaves'] = this.remLeaveInfo[i]['leaves_remaining'];
            break;
          }
          else {
            for (let i = 0; i < this.allLeaveRuleInfo.length; i++) {
              if (this.allLeaveRuleInfo[i]['leave_code'] == this.leaveObj['leave_code']) {
                this.leaveType = this.allLeaveRuleInfo[i];
                this.leaveObj['is_leave_carry_fwd'] = this.allLeaveRuleInfo[i]['is_leave_carry_fwd'];
                this.leaveObj['renew_ind_on_year_change'] = this.allLeaveRuleInfo[i]['renew_ind_on_year_change'];
                this.leaveObj['leave_rate'] = this.allLeaveRuleInfo[i]['leave_rate'];
                this.leaveObj['num_of_leaves'] = this.allLeaveRuleInfo[i]['num_of_leaves'];
              }
            }
          }
        }
      }
      else {
        for (let i = 0; i < this.allLeaveRuleInfo.length; i++) {
          if (this.allLeaveRuleInfo[i]['leave_code'] == this.leaveObj['leave_code']) {
            this.leaveType = this.allLeaveRuleInfo[i];
            this.leaveObj['is_leave_carry_fwd'] = this.allLeaveRuleInfo[i]['is_leave_carry_fwd'];
            this.leaveObj['renew_ind_on_year_change'] = this.allLeaveRuleInfo[i]['renew_ind_on_year_change'];
            this.leaveObj['leave_rate'] = this.allLeaveRuleInfo[i]['leave_rate'];
            this.leaveObj['num_of_leaves'] = this.allLeaveRuleInfo[i]['num_of_leaves'];

          }
        }
      }
    }
    //shweta code start
    if (this.leaveObj['leave_rate'] == "LIFETIME") {
      this.print_flag = false
      this.print_flag_1 = false
      console.log(this.print_flag)
      console.log(this.print_flag_1)
    }
    else if (this.leaveObj['leave_rate'] == "YEARLY") {
      this.print_flag_1 = true
      this.print_flag = false
      //this.print_flag=this.flag_yl
    }
    else {
      this.print_flag = true
      this.print_flag_1 = true
    }

    //shweta code end


  }

  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees() {
    // var obj:any  = new Object();
    // obj['b_acct_id'] = this.b_acct_id;
    // var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    // if (resp['error'] == false) {
    //   this.allEmployees = resp.data;

    //   this.newallEmplyees = []
    //   for (let i = 0; i < this.allEmployees.length; i++) {
    //     var obj:any  = new Object();
    //     obj = Object.assign({}, this.allEmployees[i]);
    //     obj['emp_name'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
    //     this.newallEmplyees.push(obj)
    //   }
    // } else {
    //   this.snackBar.open("Error while getting employee list", 'Error', {
    //     duration: 5000
    //   });
    // }
    this.spinner.show()
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];
    var resp = await this.payableService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allEmployees = resp.data;
      this.allEmp = resp.data;
      this.activeEmpArr = []

      for (let i = 0; i < this.allEmployees.length; i++) {
        this.employeeObj[this.allEmployees[i]['emp_id']] = this.allEmployees[i]['emp_name'];
        if (this.allEmployees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmployees[i])
        }
      }
      this.newallEmplyees = []
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.activeEmpArr[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
      }

    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }


  async changeEmployee() {
    this.spinner.show();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id'];
    var resp = await this.payableService.getLeaveInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee all leave info list ', 'error')
      // this.snackBar.open("Error while getting employee all leave info list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async submitLeave() {

    if (
      this.leaveObj['num_of_leaves'] == null || this.leaveObj['num_of_leaves'] == undefined || this.leaveObj['num_of_leaves'] == '' ||
      this.leaveObj['eff_dt'] == null || this.leaveObj['eff_dt'] == undefined || this.leaveObj['eff_dt'] == '' ||
      this.leaveObj['leave_code'] == null || this.leaveObj['leave_code'] == undefined || this.leaveObj['leave_code'] == '' ||
      this.selectEmpObj['emp_id'] == null || this.selectEmpObj['emp_id'] == undefined || this.selectEmpObj['emp_id'] == '') {
      Swal.fire("Warning", " * Field Required", 'warning')
      return;
    }


    if (this.leaveObj['num_of_leaves'] == '0') {
      Swal.fire("Error", 'Your Number of Leaves is remaining 0 ', 'error');
      return
    }
    else {
      var obj: any = new Object();
      obj['b_acct_id'] = this.b_acct_id;

      this.leaveObj['b_acct_id'] = this.b_acct_id;
      this.leaveObj['emp_id'] = this.selectEmpObj['emp_id'];
      this.leaveObj['create_user_id'] = this.erpUser.user_id;
      this.leaveObj['leaves_remaining'] = this.leaveObj['num_of_leaves'];
      this.leaveObj['carry_forward_leaves'] = 0;
      this.leaveObj['adjust_remaining_leaves'] = 0;
      var sp = this.leaveObj['eff_dt'].split("-");
      this.leaveObj['year'] = sp[0];
      this.leaveObj['month'] = parseInt(sp[1])
      obj['leave_data'] = [this.leaveObj];

      this.spinner.show();
      var resp = await this.payableService.addLeaveInfo(obj);
      if (resp['error'] == false) {
        await this.changeEmployee();
        await this.getAllLeaveInfo();
        this.spinner.hide();
        // this.snackBar.open("Employee Leave Added Successfully!", 'Success', {
        //   duration: 5000
        // });
        Swal.fire("Success", "Employee Leaves Added Successfully", 'success');
      } else {
        this.spinner.hide();
        Swal.fire('Error', 'Error while Adding Leave Of Employee', 'error')
        // this.snackBar.open("Error while Adding Leave Of Employee", 'Error', {
        //   duration: 5000
        // });
      }
    }
  }

  openUpdate(element:any) {
    this.leaveObj = Object.assign({}, element);

    $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.leaveObj['year'] = this.leaveObj['year'].toString();
    this.leaveObj['is_leave_carry_fwd'] = this.leaveObj['is_leave_carry_fwd'].toString();
  }
  refresh() {
    this.leaveObj = { is_leave_carry_fwd: '', renew_ind_on_year_change: '', leave_rate: '' };

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async updateLeave() {
    if (this.leaveObj['year'] == null || this.leaveObj['year'] == undefined || this.leaveObj['year'] == '' ||
      this.leaveObj['month'] == null || this.leaveObj['month'] == undefined || this.leaveObj['month'] == '' ||
      this.leaveObj['leaves_remaining'] == null || this.leaveObj['leaves_remaining'] == undefined || this.leaveObj['leaves_remaining'] == '' ||
      this.leaveObj['num_of_leaves'] == null || this.leaveObj['num_of_leaves'] == undefined || this.leaveObj['num_of_leaves'] == '') {
      Swal.fire("Warning", "* Field Required", "warning");
      return;
    }
    this.leaveObj['b_acct_id'] = this.b_acct_id;
    this.leaveObj['emp_id'] = this.selectEmpObj['emp_id'];
    this.leaveObj['update_user_id'] = this.erpUser.user_id;
    this.spinner.show();
    var resp = await this.payableService.updateLeaveInfo(this.leaveObj);
    if (resp['error'] == false) {
      await this.changeEmployee();
      this.spinner.hide();
      Swal.fire('Error', ' Employee Leave Update Successfully!', 'error')
      // this.snackBar.open("Employee Leave Update Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error while updating Leave Info Of Employee ', 'error')
      // this.snackBar.open("Error while updating Leave Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }

  async carrForward() {
    if (this.leaveObj['month'] == null || this.leaveObj['month'] == undefined || this.leaveObj['month'] == '' ||
      this.leaveObj['year'] == null || this.leaveObj['year'] == undefined || this.leaveObj['year'] == ''
    ) {


      Swal.fire("Warning", "* Field Required", "warning");
      return;
    }


    this.leaveObj['create_user_id'] = this.b_acct_id;
    this.leaveObj['b_acct_id'] = this.b_acct_id;
    //  let check=await  this.payableService.checkLeaveInfo(this.leaveObj);
    //  console.log(" this is the check value ", check);
    //  if(check['data'].length>0)
    //  {
    //   Swal.fire("Warning", "Data Already Exist", 'warning');
    //   return;

    //  }


    var obj:any  = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['leave_data'] = this.createLeave(parseInt(this.leaveObj['year']), parseInt(this.leaveObj['month']), this.allLeaveInfo, this.allLeaveRuleInfo);


    this.spinner.show();
    var resp = await this.payableService.addLeaveInfo(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      // if(resp['data']=="No_Prevoius_Data")
      // {
      //   Swal.fire("Info", "You Can Not Carry Forword Leaves Previous Month From Start Month of Leaves", "info");
      //   return;
      // }
      this.getAllLeaveInfo();
      Swal.fire('Success', "Carry Forward Operation Successfully", 'success');

      // this.snackBar.open("Carry Forward Operation Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Operation Failed', 'error')
      // this.snackBar.open("Operation Failed", 'Error', {
      //   duration: 5000
      // });
    }
  }

  // delete conformation
  async deleteleave(element:any) {
    Swal.fire({
      title: 'Are you Sure?',
      // text: 'Do You Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.value) {
        await this.deleteLeave(element)
      }
      else {
        // this.getEmpSuspensionReinstate();
        // this.reinstate();

      }
    })
  }




  async deleteLeave(element:any) {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id
    this.spinner.show();
    var resp = await this.payableService.deleteLeaveInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.changeEmployee();
      this.spinner.hide();
      // this.snackBar.open("Employee Leave Delete Successfully!", 'Success', {
      //   duration: 5000
      // });
      Swal.fire("Success", "Employee Leave Delete Successfully!", 'success');
    } else {
      this.spinner.hide();
      Swal.fire('Error', ' Error while deleteing Leave Info Of Employee', 'error')
      // this.snackBar.open("Error while deleteing Leave Info Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async adjustLeave() {
    this.leaveObj['b_acct_id'] = this.b_acct_id;
    this.leaveObj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.payableService.adjustLeave(this.leaveObj);
    if (resp['error'] == false) {
      Swal.fire('Success', 'Adjust Leave Operation Successfully! ', 'success')
      // this.snackBar.open("Adjust Leave Operation Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Operation Failed ', 'error')
      // this.snackBar.open("Operation Failed", 'Error', {
      //   duration: 5000
      // });
    }
  }






  /***************************************Kunal Code********************************************** */
  createLeave(year:any, month:any, allLeaveInfo:any, allLeaveRule:any) {
    var returnArr:any = []
    let carryYear:any = 0
    let carryMonth :any= 0

    //if let year end is 12th month its working on calendar year

    if (month == 12) {
      carryMonth = 1
      carryYear = year + 1
    } else {
      carryMonth = month + 1
      carryYear = year
    }

    //LeaveInfo Filter start//

    var temp = [];
    for (let i = 0; i < allLeaveInfo.length; i++) {
      if (allLeaveInfo[i]['month'] == month && allLeaveInfo[i]['year'] == year) {
        temp.push(allLeaveInfo[i]);
      }
    }

    allLeaveInfo = temp;
    //LeaveInfo Filter end //
    var ruleObj :any= new Object;
    for (let i = 0; i < allLeaveRule.length; i++) {
      ruleObj[allLeaveRule[i]['leave_code']] = Object.assign({}, allLeaveRule[i])
    }

    for (let j = 0; j < allLeaveInfo.length; j++) {
      let obj:any = new Object
      obj['month'] = carryMonth
      obj['year'] = carryYear;
      obj['create_user_id'] = this.erpUser.user_id;
      obj['emp_id'] = allLeaveInfo[j]['emp_id']
      obj['leave_code'] = allLeaveInfo[j]['leave_code']
      let leaveRuleObj = Object.assign({}, ruleObj[allLeaveInfo[j]['leave_code']])

      ////************************************************************LIFETIME*********************************** */
      if (leaveRuleObj['leave_rate'] == 'LIFETIME') {
        obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
        obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
        returnArr.push(obj)
      }
      ////************************************************************MONTHLY*********************************** */

      if (leaveRuleObj['leave_rate'] == 'MONTHLY') {
        if (month < 12) {
          if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            returnArr.push(obj)
          } else {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
            returnArr.push(obj)

          }

        }
        if (month == 12) {
          if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            returnArr.push(obj)
          } else {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
            returnArr.push(obj)

          }
        }

      }

      ////************************************************************QUATERLY*********************************** */

      if (leaveRuleObj['leave_rate'] == 'QUATERLY') {
        let quaterArr = [3, 6, 9, 12]
        if (quaterArr.includes(month)) {
          if (month < 12) {
            if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              returnArr.push(obj)
            } else {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
              returnArr.push(obj)

            }

          }
          if (month == 12) {
            if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              returnArr.push(obj)
            } else {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
              returnArr.push(obj)

            }
          }
        }
        else {
          obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
          obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
          returnArr.push(obj)
        }


      }


      ////************************************************************HALFYEARLY*********************************** */

      if (leaveRuleObj['leave_rate'] == 'HALFYEARLY') {
        let halfYearlyArr = [6, 12]
        if (halfYearlyArr.includes(month)) {
          if (month < 12) {
            if (leaveRuleObj['is_leave_carry_fwd'] == 1) {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              returnArr.push(obj)
            } else {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
              returnArr.push(obj)

            }

          }
          if (month == 12) {
            if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
              returnArr.push(obj)
            } else {
              obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
              obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
              returnArr.push(obj)

            }
          }
        }
        else {
          obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
          obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
          returnArr.push(obj)
        }


      }




      ////************************************************************YEARLY*********************************** */

      if (leaveRuleObj['leave_rate'] == 'YEARLY') {


        if (month == 12) {
          if (leaveRuleObj['is_leave_carry_fwd'] == 1 && leaveRuleObj['renew_ind_on_year_change'] == 0) {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves'] + allLeaveInfo[j]['leaves_remaining']
            returnArr.push(obj)
          } else {
            obj['num_of_leaves'] = leaveRuleObj['num_of_leaves']
            obj['leaves_remaining'] = leaveRuleObj['num_of_leaves']
            returnArr.push(obj)

          }
        }

        else {
          obj['num_of_leaves'] = allLeaveInfo[j]['num_of_leaves']
          obj['leaves_remaining'] = allLeaveInfo[j]['leaves_remaining']
          returnArr.push(obj)
        }


      }
    }
    return returnArr;
  }






}
