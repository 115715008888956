// import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
//  import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service'
import {MainService} from '../../../service/main.service';
declare var $: any
import swal from 'sweetalert2';
import { EmbHierService } from '../../../service/emb-hier.service';
import { EbillService } from '../../../service/ebill.service';
@Component({
  selector: 'app-deduction',
  templateUrl: './deduction.component.html',
  styleUrls: ['./deduction.component.css']
})
export class DeductionComponent implements OnInit {

  constructor(private embHierService:EmbHierService,private router: Router, private spinner: NgxSpinnerService, private referenceDataS: ReferenceDataService, private snackBar: MatSnackBar,public mainService:MainService , private ebillService:EbillService) { }
  DeductionCategory="DEDUCT";
  erpUser:any;
  b_acct_id:any;
  l_ded_flag=true;
  c_ded_flag=false;
  u_ded_flag=false;
  dedduction_dropdown = [{ ded_category: 'FIXED', ded_value: 'FIXED' }, { ded_category: 'PERCENTAGE', ded_value: 'PERCENTAGE' }]

  //dedduction_dropdown = [{ ded_category: 'AMOUNT FIXED', ded_value: 'AMOUNT FIXED' }, { ded_category: 'AMOUNT VARIABLE', ded_value: 'AMOUNT VARIABLE' }, { ded_category: 'PERCENTAGE VARIABLE', ded_value: 'PERCENTAGE VARIABLE' }, { ded_category: 'PERCENTAGE FIXED', ded_value: 'PERCENTAGE FIXED' }]
  applyon = [{ code: 'L', value: 'Line' }, { code: 'B', value: 'Bill Amount' }, { code: 'CGST', value: 'CGST Amount' }, { code: 'SGST', value: 'SGST Amount' }]
  applyonobj:any = { 'L': 'Line' ,'B':'Bill Amount' , 'CGST': 'CGST Amount', 'SGST': 'SGST Amount' }
  allFields:any = [];
  allDataTyeps = [];
  field = {};
  ded_owner = [{value:'USER'},{value:'SYSTEM'}]
  ded_cat = [{value:'DEDUCT'}] //{value:'PAY'},:any
  system_deductiontocheck:any = [{value:'With Held Temporary',code:'temp_withheld'},{value:'With Held Permanent',code:'per_withheld'},{value:'Release Previous Temporary Withheld',code:'rel_temp_withheld'},{value:'Add Previous With Held',code:'add_with_held'},{value:'Add Security',code:'add_security'},{value:' WITH HELD AMOUNT',code:'withheldamount'},{value:'CGST',code:'cgst'},{value:'SGST',code:'sgst'},{value:'IGST',code:'igst'},{value:'Add Labour Cess',code:'labuor_cess'},{value:'Deduct Labour Cess',code:'labuor_cess_deduct'}]

  system_deduction = [{value:'With Held Temporary',code:'temp_withheld'},{value:'With Held Permanent',code:'per_withheld'},{value:'Release Previous Temporary Withheld',code:'rel_temp_withheld'},{value:'Add Previous With Held',code:'add_with_held'},{value:'Add Security',code:'add_security'},{value:' WITH HELD AMOUNT',code:'withheldamount'},{value:'CGST',code:'cgst'},{value:'SGST',code:'sgst'},{value:'IGST',code:'igst'},{value:'Add Labour Cess',code:'labuor_cess'},{value:'Deduct Labour Cess',code:'labuor_cess_deduct'}]
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = [ 'deduction_id', 'ded_owner','apply_on','ded_name', 'ded_type', 'ded_amt', 'action'];
  datasource:any;
  deduction:any = {}

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFields();
    this.deduction['ded_owner'] = 'USER'
    await this.getacthier()
    await this.getbudhier();
    // await this.getEvent_code();
  }
/*--------------Get Event Code---------*/
// event_Code;
//   async getEvent_code(){
//   var resp = await this.ebillService.getded(this.b_acct_id);
//   if(resp['error']==false){
//     this.event_Code=resp['data']
//     console.log(resp['data'])
//   }
//   else{
//     console.log("error")
//   }
// }
  ///////////////////////Activity HIer
  planing_arr:any = []
  planing_arr1:any=[]
  selected_row:any = {}
  prodpath:any = ''
  remove_dropdown_prod(data:any, i:any) {
    //console.log(data, i)
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
    }
    let dummy:any = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hier_desc'])
    }
    
    this.prodpath = dummy.join(' --> ')
  }
 
  async submitact() {
 
    if (this.planing_arr1[this.planing_arr1.length - 1]['is_leaf']==0) {
      swal.fire('Error', 'You Have Not Selected Node At The Last Level', 'error')
     
    } else {
      $('#select44').modal('hide'); 
      $('#select4').modal('hide'); 
    }
    
  }

  selectactbtn(){
   
if(this.planing_arr1.length == 0){
   // this.planing_arr=[]
    this.planing_arr1=[]
  this.actpath=""
  this.budget_flag=true
 
  let b_cd=""
  let desc=""
 
  let existing_heirarchy_nodes=[]
  let flag=0;
  for(let i=0;i<this.act_hier.length;i++)
  {
    flag=0;
    desc=this.act_hier[i]['lvl1_cd']+"-"+this.act_hier[i]['lvl1_value']
    b_cd=this.act_hier[i]['lvl1_cd']
    let obj={'code':b_cd,'desc':desc};
  
    for(let j=0;j<existing_heirarchy_nodes.length;j++)
    {
      if(existing_heirarchy_nodes[j]['code']==obj['code'])
      {
        flag++;
      }
    }
    if(flag==0)
    {
      existing_heirarchy_nodes.push(obj)
    }
  }
  
  this.planing_arr1=[{'level':1,'is_leaf':0,  'existing_heirarchy_nodes':existing_heirarchy_nodes}]
  console.log(this.planing_arr1);
}
  
  if(this.flag_openpopup) {
    $('#select4').modal('show'); 
    
  }  
  this.flag_openpopup =true
  
  }

  budpath:any
  budget_flag:any
selectbudgetbtn(){
  if(this.planing_arr.length == 0){
  this.planing_arr=[]
  //this.planing_arr1=[]
  this.budpath=""
  this.budget_flag=true
  
  let b_cd=""
  let desc=""
  
  let existing_heirarchy_nodes=[]
  let flag=0;
  for(let i=0;i<this.budhier.length;i++)
  {
    flag=0;
    desc=this.budhier[i]['lvl1_cd']+"-"+this.budhier[i]['lvl1_value']
    b_cd=this.budhier[i]['lvl1_cd']
    let obj={'code':b_cd,'desc':desc,'is_leaf':this.budhier[i]['is_leaf']};
  
    for(let j=0;j<existing_heirarchy_nodes.length;j++)
    {
      if(existing_heirarchy_nodes[j]['code']==obj['code'])
      {
        flag++;
      }
    }
    if(flag==0)
    {
      existing_heirarchy_nodes.push(obj)
    }
  }
  
  this.planing_arr=[{'level':1,'hier_desc':desc,   'existing_heirarchy_nodes':existing_heirarchy_nodes}]
  console.log(this.planing_arr);
}
if(this.flag_openpopup) {
  $('#select3').modal('show');
  
}  
this.flag_openpopup =true

}

async selectbudgetbtnupdate(){
  this.planing_arr1=[]
  this.path_arr=[]
  this.path_arr1=[]
  this.planing_arr=[]


  this.budpath=""
  this.budget_flag=true
  await this.getbudgetforupdate();
  $('#select33').modal('show'); 

}
async selectactbtnupdate(){

  this.prodpath=""
  this.planing_arr1=[]
  this.path_arr=[]
  this.path_arr1=[]
  this.planing_arr=[]
  this.actpath=""

 
   console.log(this.act_hier);
   await this.getactforupdate();
   $('#select44').modal('show'); 

}
path_arr:any=[]
act_level:any;
async getactforupdate(){

  this.planing_arr1=[]
      let flag=false;
      let lv:any=[];
      let exist:any={};
      let desc=""
      let is_leaf=0
      let lvel=0
      let desired_hier:any={};
        this.existing_act=await [...this.act_hier];
        console.log(this.existing_act)
        for(let i=0;i<this.existing_act.length;i++) 
        {
            
              if(this.existing_act[i]['leaf_cd']==this.act_cd  )
              {
                for (let j = 1; j < 8; j++) {
                 if(this.existing_act[i]['lvl'+j+'_cd'] == this.act_cd){
                  lvel=j;
                 }
                  
                }
               
                desired_hier=this.existing_act[i];
              break;
              }
            
        }
  
        console.log(lvel,desired_hier);
        
        
    this.act_level=lvel 
    for(let j=1;j<=lvel;j++)
    {
      let l=j;
      lv=[];
      for(let i=0;i<this.existing_act.length;i++)
      {
        flag=false
        if(this.existing_act[i]['lvl'+l+'_cd']!=null && this.existing_act[i]['leaf_cd']==this.existing_act[i]['lvl'+l+'_cd'])
           {
              desc=  this.existing_act[i]['lvl'+l+'_cd']+"-"+this.existing_act[i]['lvl'+l+'_value']
             
              is_leaf=this.existing_act[i]['is_leaf']
              exist={'code':this.existing_act[i]['lvl'+l+'_cd'],'desc':desc,'leaf_cd':this.existing_act[i]['leaf_cd'],'is_leaf':is_leaf}
              console.log(exist)
              for(let j=0;j<lv.length;j++)
                {
                    if(exist['code']==lv[j]['code'])
                        flag=true;
                }
                
                
                if(!flag)
                lv.push(exist)
    
         
           }
        }
    let x={level: l,'hier_desc':desc, existing_heirarchy_nodes: lv, hierarchy_type: desired_hier['lvl'+l+'_cd'],is_leaf:desired_hier['is_leaf']}
   
      this.planing_arr1.push(x)
    }
  
  
    
    console.log(desired_hier);
   this.path_arr1=[] 
  for(let i=0;i<7;i++)
  {
    let x=i+1
    if(desired_hier['lvl'+x+'_cd']!=null)
     this.path_arr1[i]=desired_hier['lvl'+x+'_cd']+"-"+desired_hier['lvl'+x+'_value'];
  }
  
  this.actpath=this.path_arr1.join(' --> ')
    console.log(this.path_arr1);
    
    if(this.planing_arr.length == 0){
      this.flag_openpopup = false
      this.selectactbtn()
    }
      
  }
  existing_bud:any;
  bud_level:any;
  bud_cd:any;
  path_arr1:any=[];
  async getbudgetforupdate(){
  
    console.log(this.budhier);
    console.log(this.bud_cd);
    
    this.planing_arr=[]
        let flag=false;
        let lv:any=[];
        let exist:any={};
        let desc=""
        let is_leaf=0
        let lvel=0
        let desired_hier:any={};
          this.existing_bud=await [...this.budhier];
          for(let i=0;i<this.existing_bud.length;i++)
          {
              
                if(this.existing_bud[i]['leaf_cd']==this.bud_cd  )
                {
                  for(let j=1;j<8;j++)
              {
                if(this.existing_bud[i]['lvl'+j+'_cd']!=null)
                  lvel=j;
              }
                  desired_hier=this.existing_bud[i];
                break;
                
              }
          }
    
          
          
          
      this.bud_level=lvel 
      console.log(this.bud_level);
      
      for(let j=1;j<=lvel;j++)
      {
        let l=j;
        lv=[];
        for(let i=0;i<this.existing_bud.length;i++)
        {
          flag=false
          if(this.existing_bud[i]['lvl'+l+'_cd']!=null && this.existing_bud[i]['lvl'+l+'_cd'] == this.existing_bud[i]['leaf_cd'])
             {
               console.log(this.existing_bud[i])
                desc=  this.existing_bud[i]['lvl'+l+'_cd']+"-"+this.existing_bud[i]['lvl'+l+'_value']
               
                is_leaf=this.existing_bud[i]['is_leaf']
                
                exist={'code':this.existing_bud[i]['lvl'+l+'_cd'],'desc':desc,'is_leaf':is_leaf,'leaf_cd':this.existing_bud[i]['leaf_cd'],'remaining_amount':this.existing_bud[i]['remaining_amount']}
                for(let j=0;j<lv.length;j++)
                  {
                      if(exist['code']==lv[j]['code'])
                          flag=true;
                  }
                  
                  
                  if(!flag)
                  lv.push(exist)
      
           
             }
          }
          let x
          if(l == lvel){
             x={level: l,'hier_desc':desc, existing_heirarchy_nodes: lv, hierarchy_type: desired_hier['lvl'+l+'_cd'],is_leaf:desired_hier['is_leaf']}

          }else{
             x={level: l,'hier_desc':desc, existing_heirarchy_nodes: lv, hierarchy_type: desired_hier['lvl'+l+'_cd'],is_leaf:0}

          }
              this.planing_arr.push(x)
      }
    
    
      
      console.log(desired_hier,is_leaf);
     this.path_arr=[] 
    for(let i=0;i<7;i++)
    {
      let x=i+1
      if(desired_hier['lvl'+x+'_cd']!=null)
       this.path_arr[i]=desired_hier['lvl'+x+'_cd']+"-"+desired_hier['lvl'+x+'_value'];
    }
    console.log(this.planing_arr)
    this.budpath=this.path_arr.join(' --> ')
      console.log(this.path_arr);
      
    if(this.planing_arr.length == 0){
      this.flag_openpopup = false
      this.selectbudgetbtn()
    } 
        
    }
flag_openpopup = true
is_leaf:any
bud_head_cd:any
bud_amt:any
bud_name:any
setHierarchyNode(item:any, i:any) {
  this.is_leaf=0
  
  for(let i=0;i<item['existing_heirarchy_nodes'].length;i++)
  {
    console.log(item['existing_heirarchy_nodes'][i]['code'],item['hierarchy_type']);
    console.log(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type']);
    
    
      if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'])
      {
         this.bud_head_cd=item['existing_heirarchy_nodes'][i]['code']
         this.bud_amt=item['existing_heirarchy_nodes'][i]['remaining_amount']
         this.is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf'];
         this.bud_name=item['existing_heirarchy_nodes'][i]['desc'];
         
      }
  }
  console.log(this.bud_head_cd,this.bud_amt,this.is_leaf,this.bud_name);
  
  console.log(item);
  
  let flag = false
  let dummy = []
 
  console.log(this.planing_arr,item['level']);
  this.path_arr[item['level']-1]=this.bud_name
 
  this.budpath= this.path_arr.join(' --> ')
  
  
}
act_cd:any
act_is_leaf:any
setactHierarchyNode(item:any,i:any){

  console.log(item);
  
  let flag = false
  let dummy = []
  console.log(this.planing_arr1);
  let act_des=""
  for(let i=0;i<item['existing_heirarchy_nodes'].length;i++)
  {
    console.log(item['existing_heirarchy_nodes'][i]['code'],item['hierarchy_type']);
    console.log(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type']);
    if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'])
      {
         this.act_cd=item['existing_heirarchy_nodes'][i]['code']
          this.act_is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf'];
          act_des=item['existing_heirarchy_nodes'][i]['desc']
         
      }
  }
  
  this.path_arr1[item['level']-1]=act_des
 
  this.actpath= this.path_arr1.join(' --> ')

}


setactHierarchyNodeforupdate(item:any,m:any){
  this.act_is_leaf=0
  console.log(item);
  let make_desc;
  for(let i=0;i<item['existing_heirarchy_nodes'].length;i++)
  {

    console.log(item['existing_heirarchy_nodes'][i]['code'],item['hierarchy_type']);
    console.log(item['existing_heirarchy_nodes'][i]['leaf_cd']==item['hierarchy_type']);
    
    
      if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'])
      {
        this.planing_arr1[m]['is_leaf'] = item['existing_heirarchy_nodes'][i]['is_leaf']
         this.act_is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf']
         this.act_cd=item['existing_heirarchy_nodes'][i]['code']
         make_desc=item['existing_heirarchy_nodes'][i]['desc']
      }
      
      if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'] &&item['level']==this.act_level )
      {
        this.planing_arr1[m]['is_leaf'] = item['existing_heirarchy_nodes'][i]['is_leaf']
        console.log(this.planing_arr1[m])
         this.act_is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf']
         this.act_cd=item['existing_heirarchy_nodes'][i]['code']
         make_desc=item['existing_heirarchy_nodes'][i]['desc']
      }
  }
  console.log(this.act_level,item['level']);
  
  if(item['level']<this.act_level)
{
  for(let j=item['level'];j<this.act_level;j++)
  {
   
    this.planing_arr1.pop();
    this.path_arr1.pop()
  }

   this.act_level=item['level']
}
 


 this.path_arr1[item['level']-1]=make_desc
 
  this.actpath= this.path_arr1.join(' --> ')

}

setbudHierarchyNodeforupdate(item:any,m:any){
  this.act_is_leaf=0
  console.log(item);

  for(let i=0;i<item['existing_heirarchy_nodes'].length;i++)
  {
      if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'])
      {this.planing_arr[m]['is_leaf'] = item['existing_heirarchy_nodes'][i]['is_leaf']
          this.is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf'];
          this.bud_name=item['existing_heirarchy_nodes'][i]['desc'];
        }
     
      
      if(item['existing_heirarchy_nodes'][i]['code']==item['hierarchy_type'] && item['existing_heirarchy_nodes'][i]['is_leaf']==1)
      {this.planing_arr[m]['is_leaf'] = item['existing_heirarchy_nodes'][i]['is_leaf']
         this.bud_head_cd=item['existing_heirarchy_nodes'][i]['code']
         this.bud_amt=item['existing_heirarchy_nodes'][i]['remaining_amount']
         this.is_leaf=item['existing_heirarchy_nodes'][i]['is_leaf'];
         this.bud_name=item['existing_heirarchy_nodes'][i]['desc'];
        }

  }
  console.log(item['level'],this.bud_level);
  console.log(this.planing_arr);
  
if(item['level']<this.bud_level)
{
  for(let j=item['level'];j<this.bud_level;j++)
  {
   
    this.planing_arr.pop();
    this.path_arr.pop()
  }

   this.bud_level=item['level']
}
  
  

 this.path_arr[item['level']-1]=this.bud_name
this.budpath= this.path_arr.join(' --> ')

}

add_dropdownforupdate(data:any, index:any) {
  //console.log(data, index)
  let flag = true
  let obj1 = {}
  if(this.planing_arr.length >= 8){
    return
    swal.fire('Warning..', '', 'warning')
  }
  if(this.planing_arr1.length >= 8){
    return
    swal.fire('Warning..', '', 'warning')
  }
  for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
    if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
      obj1 = Object.assign({}, this.existing_heirarchy_nodes[i])
    }
  }
  this.selected_row = Object.assign({}, obj1)
  this.selected_row['index'] = index
  this.bud_level++;
  this.filter_data()
}
remove_dropdownupdate(data:any, i:any) {
  //console.log(data, i)
  if (this.planing_arr.length > 1) {
    this.planing_arr.pop()
  }

  let arr=this.planing_arr[this.planing_arr.length-1]['existing_heirarchy_nodes']
  for(let i=0;i<arr.length;i++)
  {
    if(arr[i]['code']==this.planing_arr[this.planing_arr.length-1]['hierarchy_type'])
    this.is_leaf=arr[i]['is_leaf']
       
  }
  this.bud_level--;
  console.log(this.act_is_leaf);
  this.path_arr.pop();
  this.budpath= this.path_arr.join(' --> ')
 
}

add_dropdownforupdate_act(data:any, index:any) {
  //console.log(data, index)
  let flag = true
  let obj1 = {}
  if(this.planing_arr.length >= 8){
    return
    swal.fire('Warning..', '', 'warning')
  }
  if(this.planing_arr1.length >= 8){
    return
    swal.fire('Warning..', '', 'warning')
  }
  for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
    if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
      obj1 = Object.assign({}, this.existing_heirarchy_nodes[i])
    }
  }
  this.selected_row = Object.assign({}, obj1)
  this.selected_row['index'] = index

  this.filter_data_act()
}
remove_dropdownupdate_act(data:any, i:any) {
  //console.log(data, i)
  if (this.planing_arr1.length > 1) {
    this.planing_arr1.pop()
  }

  let arr=this.planing_arr1[this.planing_arr1.length-1]['existing_heirarchy_nodes']
  for(let i=0;i<arr.length;i++)
  {
    if(arr[i]['code']==this.planing_arr1[this.planing_arr1.length-1]['hierarchy_type'])
    this.is_leaf=arr[i]['is_leaf']
       
  }
  console.log(this.act_is_leaf);
  this.path_arr1.pop();
  this.actpath= this.path_arr1.join(' --> ')
}
existing:any
add_dropdown_act(data:any, index:any) {
  //console.log(data, index)
  let flag = true
  let obj1 = {}
  if(this.planing_arr1.length >= 7){
    return
    swal.fire('Warning..', '', 'warning')
  }
  for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
    if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
      obj1 = Object.assign({}, this.existing_heirarchy_nodes[i])
    }
  }
  this.selected_row = Object.assign({}, obj1)
  this.selected_row['index'] = index

  this.filter_data_act()
}
actpath:any;
remove_dropdown_act(data:any, i:any) {
  //console.log(data, i)
  if (this.planing_arr1.length > 1) {
    this.planing_arr1.pop()
  }
if(this.planing_arr1.length < this.path_arr1.length) this.path_arr1.pop();
 
  this.actpath= this.path_arr1.join(' --> ')
}

add_dropdown(data:any, index:any) {
  //console.log(data, index)
  let flag = true
  let obj1 = {}
  if(this.planing_arr.length >= 7){
    return
    swal.fire('Warning..', '', 'warning')
  }
  for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
    if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
      obj1 = Object.assign({}, this.existing_heirarchy_nodes[i])
    }
  }
  this.selected_row = Object.assign({}, obj1)
  this.selected_row['index'] = index

  this.filter_data()
}
remove_dropdown(data:any, i:any) {
  //console.log(data, i)
  if (this.planing_arr.length > 1) {
    this.planing_arr.pop()
  }

  if(this.planing_arr.length < this.path_arr.length) this.path_arr.pop();
  
  this.budpath= this.path_arr.join(' --> ')
}
async filter_data() {
  let flag=false
  let lv:any=[];
  let exist:any={};
  let desc=""
  let is_leaf=0
    this.existing=await [...this.budhier];

 
  let l=this.selected_row['index']+2
 
  for(let i=0;i<this.existing.length;i++)
  {
        
     if(this.existing[i]['lvl'+l+'_cd']!=null && this.planing_arr[this.selected_row['index']]['hierarchy_type'] == this.existing[i]['lvl'+(l-1)+'_cd'])
       {
         flag=false
          desc=  this.existing[i]['lvl'+l+'_cd']+"-"+this.existing[i]['lvl'+l+'_value']
          is_leaf=this.existing[i]['is_leaf']
          
          exist={'code':this.existing[i]['lvl'+l+'_cd'],'desc':desc,'is_leaf':is_leaf,'leaf_cd':this.existing[i]['leaf_cd'],'remaining_amount':this.existing[i]['remaining_amount']}
          for(let j=0;j<lv.length;j++)
          {
              if(exist['code']==lv[j]['code'])
                  flag=true;
          }
         
          
          if(!flag)
          lv.push(exist)
     
       }
    }
let x={level: l,'hier_desc':desc,existing_heirarchy_nodes: lv, hierarchy_type: "l"}
  this.planing_arr.push(x)
  

  
}

async submitbud() {
  if (this.is_leaf==0) {
    swal.fire('Error', 'You Have Not Selected Node At The Last Level', 'error')
   
  } else {
   
  this.bud_cd=this.bud_head_cd;
    console.log(this.bud_head_cd,this.bud_amt);
    
    
  $('#select3').modal('hide'); 

  }
}
async submitbudupdate() {
  console.log(this.planing_arr[this.planing_arr.length-1])
  if (this.planing_arr[this.planing_arr.length-1]['is_leaf']==0) {
    swal.fire('Error', 'You Have Not Selected Node At The Last Level', 'error')
   
  } else {
   
  this.bud_cd=this.bud_head_cd;
    console.log(this.bud_head_cd,this.bud_amt);
    
    
  $('#select33').modal('hide'); 

  }
}


  existing_heirarchy_nodes = []

  existing_act=[]
async filter_data_act() {
  let flag=false;
  let lv=[];
  let exist:any={};
  let desc=""
  let is_leaf=0
    this.existing_act=await [...this.act_hier];
 

  let l=this.selected_row['index']+2

  for(let i=0;i<this.existing_act.length;i++)
  {
    flag=false
    if(this.existing_act[i]['lvl'+l+'_cd']!=null && this.planing_arr1[this.selected_row['index']]['hierarchy_type'] == this.existing_act[i]['lvl'+(l-1)+'_cd'])
       {
          desc=  this.existing_act[i]['lvl'+l+'_cd']+"-"+this.existing_act[i]['lvl'+l+'_value']
          
          is_leaf=this.existing_act[i]['is_leaf']
          exist={'code':this.existing_act[i]['lvl'+l+'_cd'],'desc':desc,'is_leaf':is_leaf,'leaf_cd':this.existing_act[i]['leaf_cd']}
          
          for(let j=0;j<lv.length;j++)
            {
                if(exist['code']==lv[j]['code'])
                    flag=true;
            }
            
            
            if(!flag)
            lv.push(exist)

     
       }
    }
let x={level: l, 'is_leaf':is_leaf, existing_heirarchy_nodes: lv, hierarchy_type: "l"}
  this.planing_arr1.push(x)

  
}
  act_hier = []
  async getacthier(){
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
      obj['table_name']='activity_hier';
      this.spinner.show();
      var resp = await this.embHierService.getactHierarchy(obj)
      if (resp['error'] == false) {
        this.spinner.hide();
        let data = resp['data']
        this.act_hier = []
        this.act_hier = data
        console.log(data)
      }
    else {
      this.spinner.hide();
     // this.toastr.errorToastr(resp['data']);
    }
  }

  budhier = []
  async getbudhier(){
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
      obj['table_name']='bud_hier';
      this.spinner.show();
      var resp = await this.embHierService.getBudgetHierarchy(obj)
      if (resp['error'] == false) {
        this.spinner.hide();
        let data = resp['data']
        this.budhier = []
        this.budhier = data
        console.log(data)
      }
    else {
      this.spinner.hide();
     
    }
 
  }
  refresh() {
    this.deduction = {}
    this.planing_arr=[]
    this.planing_arr1=[]
    this.path_arr=[]
    this.path_arr1=[]
    this.budpath=""
    this.actpath=""
    this.bud_head_cd=""
    this.act_cd=""

    this.prodpath="";
  }
  flag = false
  change_owner(){
    this.flag = false
    if(this.deduction['ded_owner'] == 'SYSTEM') this.flag = true
    
  }
change_dedname(){
  this.flag = true
  if(this.deduction['ded_bus_name'] == 'sgst' || this.deduction['ded_bus_name'] == 'cgst' || this.deduction['ded_bus_name'] == 'igst'){
    this.deduction['ded_type'] = 'PERCENTAGE VARIABLE'
    this.deduction['apply_on'] = 'B'
    this.deduction['ded_cat'] = 'PAY'
    this.deduction['ded_amt'] = 0
  }else if(this.deduction['ded_bus_name'] == 'rel_temp_withheld' || this.deduction['ded_bus_name'] == 'add_with_held' || this.deduction['ded_bus_name'] == 'add_security'){
    this.deduction['ded_type'] = 'AMOUNT VARIABLE'
    this.deduction['apply_on'] = 'B'
    this.deduction['ded_cat'] = 'PAY'
    this.deduction['ded_amt'] = 0
  }else{
    this.deduction['ded_type'] = 'AMOUNT VARIABLE'
    this.deduction['apply_on'] = 'B'
    this.deduction['ded_cat'] = 'DEDUCT'
    this.deduction['ded_amt'] = 0
  }
}
to_show_System_ded:any={};
  async getAllFields() {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id']=this.b_acct_id
    var resp = await this.referenceDataS.getmasterdeduction(JSON.stringify(obj));
    console.log(resp);
    
    
    if (resp['error'] == false) {
      this.allFields = resp.data;
   
     
      let ded = []
      ded = [...this.system_deductiontocheck]
      for (let i = 0; i < this.system_deductiontocheck.length; i++) {
        let index:any = this.allFields.map(function(e:any){return e.ded_bus_name}).indexOf(this.system_deductiontocheck[i]['code'])
        
       if(index != -1) ded.splice(ded.map(function(e){return e.code}).indexOf(this.system_deductiontocheck[i]['code']),1)

       
        
      }
      let obj:any={}
      let obj2={}
     for(let i=0;i<this.system_deductiontocheck.length;i++)
      {
          obj[this.system_deductiontocheck[i]['code']]=this.system_deductiontocheck[i]['value']
        
        
      }
      this.to_show_System_ded=obj;
      this.system_deduction = [...ded]
      
      this.datasource = new MatTableDataSource(this.allFields)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }
  async gotolist(){
    this.l_ded_flag=true;
    this.c_ded_flag=false;
    this.u_ded_flag=false;
    await this.getAllFields();
    this.planing_arr=[]
    this.planing_arr1=[]
    this.path_arr=[]
    this.path_arr1=[]
    this.budpath=""
    this.prodpath="";

  }
  gotocrete(){
    this.refresh();

    this.l_ded_flag=false;
    this.c_ded_flag=true;
    this.u_ded_flag=false;

  }
  async create() {
    let validate = 0
    console.log(this.deduction);
    console.log(this.deduction['ded_bus_name'],this.deduction['ded_owner'] , this.deduction['apply_on'] , this.deduction['ded_type'] , this.deduction['ded_amt'],this.deduction['event_cd']);
    
    if (this.deduction['ded_bus_name']&&this.deduction['ded_owner'] && this.deduction['apply_on'] && this.deduction['ded_type']  &&  this.act_cd && this.deduction['event_cd']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.deduction
      obj['b_acct_id'] = this.b_acct_id
      obj['last_act_by_user'] = this.erpUser.user_id
      obj['budget_cd']=this.bud_head_cd
      obj['act_cd']=this.act_cd
      obj['ded_apply_on']=this.deduction['apply_on']
      var resp = await this.referenceDataS.createmasterDeduction(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.createevent(resp['data'])
        await this.getAllFields()
        this.snackBar.open("Deduction Added Successfully!", 'Success', {
          duration: 5000
        });
       this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Adding Deduction", 'Error', {
          duration: 5000
        });
      }
    } else {
      swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }

  }
async createevent(id:any){
  let desc = this.deduction['ded_name']+" on "+ this.applyonobj[this.deduction['apply_on']]
  let Obj:any = new Object()
  Obj['b_acct_id'] = this.b_acct_id
  Obj['event_source'] = 'EMB'
  Obj['event_code'] = 'EMBDED'+id
  Obj['module_cd'] = 'EMB'
  Obj['event_desc'] = desc
  Obj['event_record_code'] = 'R101'
  Obj['create_user_id'] = this.erpUser['user_id']
  console.log(Obj)
  var resp = await this.referenceDataS.addevent(Obj)
  if (resp['error'] == false) {
    this.spinner.hide();
    console.log(resp)
    await this.updatededeventcode(id)
  } else {
    this.spinner.hide();
    this.snackBar.open("Error while Adding Event", 'Error', {
      duration: 5000
    });
  }
}
async updatededeventcode(id:any){
  let Obj:any = new Object()
  Obj['b_acct_id'] = this.b_acct_id
  Obj['id'] = id
  Obj['event_code'] = 'EMBDED'+id
  Obj['update_user_id'] = this.erpUser['user_id']
  var resp = await this.referenceDataS.updatededeventcode(Obj)
  if (resp['error'] == false) {
    this.spinner.hide();
  } else {
    this.spinner.hide();
    this.snackBar.open("Error while Adding Event", 'Error', {
      duration: 5000
    });
  }
}
  async update() {
    let validate = 0
    console.log(this.deduction['ded_bus_name'],this.deduction['ded_owner'] , this.deduction['apply_on'] , this.deduction['ded_type'] , this.bud_head_cd,this.act_cd, this.deduction['event_cd']);
    if (this.deduction['ded_bus_name']&&this.deduction['ded_owner'] && this.deduction['apply_on'] && this.deduction['ded_type']  &&  this.act_cd&&this.deduction['event_cd']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.deduction
      obj['act_cd'] = this.act_cd
      obj['b_acct_id'] = this.b_acct_id
      obj['last_act_by_user'] = this.erpUser.user_id
      obj['budget_cd']=this.bud_head_cd
      obj['ded_apply_on']=this.deduction['apply_on']
      obj['ded_id']=this.deduction['deduction_id']
      obj['event_cd']=this.deduction['event_cd']
      console.log(obj);
      
      var resp = await this.referenceDataS.updatemasterded(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllFields()
        this.snackBar.open("Deduction Updated Successfully!", 'Success', {
          duration: 5000
        });
        this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Updating Deduction", 'Error', {
          duration: 5000
        });
      }
    } else {
      swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }

  }
  open_update(element:any) {
    this.l_ded_flag=false;
    this.c_ded_flag=false;
    this.u_ded_flag=true;
    console.log(element);
    this.bud_cd=element['budget_cd']
    this.bud_head_cd=element['budget_cd']
    this.act_cd=element['act_cd']
    this.deduction['ded_id']=element['deduction_id']
    this.actpath = ''
    this.budpath = ''
    element['apply_on']=element['ded_apply_on']
    this.deduction = Object.assign({}, element);
    if(this.deduction['ded_owner'] == 'SYSTEM'){
      this.flag = true
    }
    $('.nav-tabs a[href="#tab-3"]').tab('show')
    this.getbudgetforupdate()
    this.getactforupdate()
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async  delete(element:any) {
    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }

  async finaldelete(e:any) {
    
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['ded_id'] = e['deduction_id']
    var resp = await this.referenceDataS.deletemasterded(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllFields();
      this.snackBar.open("Field Deleted Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while deleting Field", 'Error', {
        duration: 5000
      });
    }
  }
}

