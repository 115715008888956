import { Component, OnInit, ViewChild } from '@angular/core';
import { EstablishmentService } from '../../service/establishment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { AppAttendService } from '../../service/app-attend.service';
import { DataSource } from '@angular/cdk/collections';
import * as moment from "moment";
import { DomSanitizer } from '@angular/platform-browser';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any
import { DatePipe } from '@angular/common'
import { MainService } from '../../service/main.service';
import { ExcelService } from '../../service/file-export.service';
@Component({
  selector: 'app-atten-report',
  templateUrl: './atten-report.component.html',
  styleUrls: ['./atten-report.component.css']
})
export class AttenReportComponent implements OnInit {

  constructor(private excl: ExcelService, private establishmentService: EstablishmentService, public mainService: MainService, private spinner: NgxSpinnerService, private attendance: AppAttendService, private sanitizer: DomSanitizer, public datepipe: DatePipe) { }
  attObj :any = {}
  b_acct_id:any
  erpUser:any
  empObj:any  = {}
  allEmplyees :any = []
  currentDate:any
  currentDate1:any
  currentDate2:any
  displayedColumns = ['serial_no', 'zone', 'emp_id', 'emp_name', 'pi_time', 'pi_latitude', 'pi_longitude', 'po_time', 'po_latitude', 'po_longitude', 'date'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    await this.getAllEmployees();
    this.currentDate = new Date();

    this.currentDate1 = this.currentDate.toLocaleDateString('en-GB').split('/').join('-');;
    // console.log('Current Date:', this.currentDate1);
    this.currentDate2 = moment(this.currentDate1, 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.attObj['date'] = this.currentDate2
    console.log(this.erpUser, "shweta app this.erpUserthis.erpUser")
    // console.log(this.attendance.httpUrl,"shweta app")
  }
  getNumberFormat(num:any ) {
    return num.toString().padStart(3, "0")
  }
  empArr = []
  async getAllEmployees() {

    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    // obj['emp_status_code'] = ["ACTIVE", "INACTIVE"];

    // obj['emp_status_code'] = ["ACTIVE"];
    obj['emp_status_code'] = ["ACTIVE", "JOINING"];
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    if (resp['error'] == false) {

      var dt1 = resp.data;
      this.empArr = resp.data;
      var dt = []
      dt.push({ arr_id: "-1", le_id: "-1", emp_name: "All Employee", emp_desc: "All Employee" })

      for (var i = 0; i < dt1.length; i++) {
        this.empObj[dt1[i]['emp_id']] = dt1[i];
        dt[i + 1] = dt1[i];
        this.empObj
        dt[i + 1]['emp_desc'] = /* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(dt1[i]['emp_id']) + " - " + dt1[i]['emp_name'];
      }
      // console.log(  resp.data)
      this.allEmplyees = dt;

      console.log(this.allEmplyees)

    } else {
      this.spinner.hide()
      swal.fire("Error", "Error while getting All employee Info", "error");
    }
  }
  change() {

  }
  le_id :any = []
  dataSource:any
  async getAttendanceReport() {
    this.le_id = []
    // console.log(this.empArr)
    // console.log(this.attObj)
    if (this.attObj['le_id'] == '-1') {
      for (let i = 0; i < this.empArr.length; i++) {
        this.le_id.push(this.empArr[i]['le_id'])
      }
    } else {
      this.le_id.push(this.attObj['le_id'])
    }
    // console.log(this.le_id)
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['le_id'] = this.le_id;
    obj['date'] = this.attObj['date'];
    var resp = await this.attendance.getattendancereport(obj)
    // console.log(resp)
    if (resp["error"] == false) {

      let j = 1
      for (let i = 0; i < resp.data.length; i++) {
        resp.data[i]['new_index'] = j
        j = j + 1
      }
      this.excelArr = resp.data
      this.dataSource = new MatTableDataSource(resp.data)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
    }
  }
  excelArr :any = [];
  getBase64ImageFromURL(url:any ) {
    console.log(url)
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx :any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  async print() {
    if (this.excelArr.length == 0) {
      swal.fire('Info', "Firstly Submit The Data Then Download PDF", 'info')
      return
    }
    let img = {
      image: await this.getBase64ImageFromURL('../../../../assets/img/pcs_logo.jpg'),
      fit: [55, 55],
      alignment: 'center'
    };

    // var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "\n\n Attendance Report  (" + this.attObj['date'] +")" ;
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")"
    // + "\n\n Attendance Report  (" + this.attObj['date'] +")" ;
    let parts = this.attObj['date'].split("-");
    let formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    var attendanceReport = "( Attendance Report - " + formattedDate  + ")"
    let dd :any = {
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        let arr = []

      },
      pageOrientation: 'landscape',
      pageMargins: [40, 10, 10, 10],
      content: [],

    };
    let c = await this.getBase64ImageFromURL('../../../../assets/img/pcs_logo.jpg')
    var header0 = {
      widths: ["30%", "60%"],
      columns: [
        {
          image: c,
          width: 100,
          height: 70,
          bold: true,
          alignment: "center",
        },
        {
          text: txt,
          alignment: "center",
          bold: true,
          fontSize: 18,
          margin: [20, 20, 20, 10]
        },


      ],
    };
    var header02 = {
      // widths: ["70%", "30%"],
      columns: [
        {


          text: '',
          bold: true
        },



        {
          width: '*',
          text: attendanceReport,
          bold: true,

          alignment: 'right',

          fontSize: 12
        },
        {


          text: '',
          bold: true
        },





      ],
    }
    // var header02 = {
    //   columns: [

    //     {
    //       width: '*',
    //       text: attendanceReport,
    // bold: true,
    // alignment: 'center',
    // fontSize: 12,
    // margin: [0, 20, 0, 0]}
    //   ]
    // };
    var currentDate = new Date();
    var day = currentDate.getDate().toString().padStart(2, '0');
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var year = currentDate.getFullYear().toString();
    var txtdate = day + '-' + month + '-' + year;

    var header01 = {
      columns: [
        {

          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: ''
        },
        {
          width: '*',
          text: '  Print Date : ',
          bold: true,
          alignment: "right",
        },


        {
          width: '*',
          text: txtdate,

          alignment: "left",
        },


      ],
    }
    dd.content.push(header01)
    dd.content.push(header0);
    dd.content.push(header02);
    dd.content.push({ text: " " });

    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2:  770, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    let tbl = {
      layout: {

        hLineWidth: function (i:any , node:any ) {
          return (i === 0 || i === node.table.body.length) ? 2 : 1;
        },
        vLineWidth: function (i:any ) {
          return (i === 0) ? 2 : 1;
        },
        hLineColor: function (i:any ) {
          return (i === 0 || i === 1) ? 'black' : 'gray';
        },
        vLineColor: function (i:any ) {
          return (i === 0) ? 'black' : 'gray';
        },
        paddingLeft: function (i:any ) { return 5; },
        paddingRight: function (i:any ) { return 5; },
        paddingTop: function (i:any ) { return 2; },
        paddingBottom: function (i:any ) { return 2; }

      },
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'SI.NO', bold: true },

            { text: 'Employee ID', bold: true },
            { text: 'Employee Name', bold: true },
            { text: 'Punch In Time', bold: true },
            { text: 'Punch In Latitude', bold: true },
            { text: 'Punch In Longitude', bold: true },
            { text: 'Punch Out Time', bold: true },
            { text: 'Punch Out Latitude', bold: true },
            { text: 'Punch Out Longitude', bold: true },
            // { text: 'Attendance Date', bold: true }
          ]
        ]
      }
    };


    for (let i = 0; i < this.excelArr.length; i++) {
      var arr = [];
      arr.push(i + 1);

      arr.push(this.excelArr[i]['emp_id']);
      arr.push(this.excelArr[i]['emp_name']);
      arr.push(this.datepipe.transform(this.excelArr[i]['create_timestamp'], 'h:mm:ss a'));
      arr.push(this.excelArr[i]['pi_latitude']);
      arr.push(this.excelArr[i]['pi_longitude']);
      arr.push(this.datepipe.transform(this.excelArr[i]['update_timestamp'], 'h:mm:ss a'));
      arr.push(this.excelArr[i]['po_latitude']);
      arr.push(this.excelArr[i]['po_longitude']);
      // arr.push(this.datepipe.transform(this.excelArr[i]['date'], 'dd/MM/yyyy'));

      tbl.table.body.push(arr);
    }

    dd.content.push(tbl);

    // dd.content.push(header01)
    // var columnWidths = [6, 9, 12, 15, 12, 12, 12, 12, 12, 12, 12];
    // tbl.table.widths = columnWidths.map(width => width.toString() + '%');
    pdfMake.createPdf(dd).download("Attendance PDF" + "-" + this.attObj['date']);
  }

  async export() {
    if (this.excelArr.length == 0) {
      swal.fire('Info', "Firstly Submit The Data Then Download The Excel", 'info')
      return
    }
    var exp = []
    for (var i = 0; i < this.excelArr.length; i++) {
      var obj :any  = new  Object();
      obj['SI.NO'] = i + 1;
      obj['ZONE'] = this.excelArr[i]['zone_cd']
      obj['EMPLOYEE ID'] = this.excelArr[i]['emp_id'];
      obj['EMPLOYEE NAME'] = this.excelArr[i]['emp_name'];

      obj['PUNCH_IN_TIME'] = this.datepipe.transform(this.excelArr[i]['create_timestamp'], 'hh:mm:ss');
      obj['PUNCH_IN_LATTITUDE'] = this.excelArr[i]['pi_latitude'];
      obj['PUNCH_IN_LONGITUDE'] = this.excelArr[i]['pi_longitude'];
      obj['PUNCH_OUT_TIME'] = this.datepipe.transform(this.excelArr[i]['update_timestamp'], 'hh:mm:ss');
      obj['PUNCH_OUT_LATTITUDE'] = this.excelArr[i]['po_latitude'];
      obj['PUNCH_OUT_LONGITUDE'] = this.excelArr[i]['po_longitude'];

      obj['DATE'] = this.datepipe.transform(this.excelArr[i]['date'], 'dd/MM/yyyy');
      // obj['REMARKS'] = "";


      exp.push(obj);
    }

    this.excl.exportAsExcelFile(exp, 'Attendance Excel' + "-" + this.attObj['date'])
  }



  async print1() {
    if (this.excelArr.length == 0) {
      swal.fire('Info', "Firstly Submit The Data Then Download PDF", 'info');
      return;
    }
    let img = {
      image: await this.getBase64ImageFromURL('../../../../assets/img/pcs_logo.jpg'),
      fit: [55, 55],
      alignment: 'center'
    };

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")";
    var attendanceReport = "( Attendance Report " + this.attObj['date'] +")";
    let dd:any  = {
      pageSize: 'A4',
      header: function (currentPage:any , pageCount:any ) {
        let arr = [];
      },
      pageOrientation: 'portrait',
      pageMargins: [10, 10, 10, 10],
      content: [],
    };

    let c = await this.getBase64ImageFromURL('../../../../assets/img/pcs_logo.jpg');
    var header0 = {
      widths: ["30%", "60%"],
      columns: [
        {
          image: c,
          width: 100,
          height: 70,
          bold: true,
          alignment: "center",
        },
        {
          text: txt,
          alignment: "center",
          bold: true,
          fontSize: 18,
          margin: [20, 20, 20, 10]
        },
      ],
    };
    var header02 = {
      columns: [
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '  Print Date : ',
          bold: true,
          alignment: "right",
        },
        {
          width: '*',
          text: '',
        },
      ],
    };
    var currentDate = new Date();
    var day = currentDate.getDate().toString().padStart(2, '0');
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var year = currentDate.getFullYear().toString();
    var txtdate = day + '-' + month + '-' + year;

    var header01 = {
      columns: [
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true,
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: '',
          bold: true
        },
        {
          width: '*',
          text: 'Print Date : ',
          bold: true,
          alignment: "right",
        },
        {
          width: '*',
          text: txtdate,
          alignment: "left",
        },
      ],
    };

    dd.content.push(header01);
    dd.content.push(header0);
    // dd.content.push(header02);

    dd.content.push({ text: "" });dd.content.push({ text: "" });
    dd.content.push({ text: "" });
    dd.content.push({ text: "" });
    dd.content.push({ text: "" });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 570, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: "" });
    dd.content.push({ text: "" });

    var attendanceHeader = {
      text: attendanceReport,
      bold: true,
      alignment: 'center',
      fontSize: 12,
      margin: [0, 10, 0, 0]
    };
    dd.content.push(attendanceHeader);
    dd.content.push({ text: "" });
    dd.content.push({ text: "" });
    let tbl = {
      layout: {
        hLineWidth: function (i:any , node:any ) {
          return (i === 0 || i === node.table.body.length) ? 2 : 1;
        },
        vLineWidth: function (i:any ) {
          return (i === 0) ? 2 : 1;
        },
        hLineColor: function (i:any ) {
          return (i === 0 || i === 1) ? 'black' : 'gray';
        },
        vLineColor: function (i:any ) {
          return (i === 0) ? 'black' : 'gray';
        },
        paddingLeft: function (i:any ) { return 5; },
        paddingRight: function (i:any ) { return 5; },
        paddingTop: function (i:any ) { return 2; },
        paddingBottom: function (i:any ) { return 2; }
      },
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'SI.NO', bold: true },
            { text: 'Employee ID', bold: true },
            { text: 'Employee Name', bold: true },
            { text: 'Punch In Time', bold: true },
            { text: 'Punch In Latitude', bold: true },
            { text: 'Punch In Longitude', bold: true },
            { text: 'Punch Out Time', bold: true },
            { text: 'Punch Out Latitude', bold: true },
            { text: 'Punch Out Longitude', bold: true },
            // { text: 'Attendance Date', bold: true }
          ]
        ]
      }
    };

    for (let i = 0; i < this.excelArr.length; i++) {
      var arr = [];
      arr.push(i + 1);
      arr.push(this.excelArr[i]['emp_id']);
      arr.push(this.excelArr[i]['emp_name']);
      arr.push(this.datepipe.transform(this.excelArr[i]['create_timestamp'], 'h:mm:ss a'));
      arr.push(this.excelArr[i]['pi_latitude']);
      arr.push(this.excelArr[i]['pi_longitude']);
      arr.push(this.datepipe.transform(this.excelArr[i]['update_timestamp'], 'h:mm:ss a'));
      arr.push(this.excelArr[i]['po_latitude']);
      arr.push(this.excelArr[i]['po_longitude']);
      // arr.push(this.datepipe.transform(this.excelArr[i]['date'], 'dd/MM/yyyy'));

      tbl.table.body.push(arr);
    }

    dd.content.push(tbl);

    pdfMake.createPdf(dd).download("Attendance PDF" + "-" + this.attObj['date']);
  }

}
