import { Component, OnInit, ViewChild } from '@angular/core';
import { TaBillService } from '../../service/ta-bill.service';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-ta-bill',
  templateUrl: './ta-bill.component.html',
  styleUrls: ['./ta-bill.component.css']
})
export class TaBillComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, private tabillservice: TaBillService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any
  b_acct_id:any
  displayedColumns:any = ['s_no', 'le_id', 'emp_name','trip_name', 'applied_le_id', 'rembus_amt', 'applied_date', 'remark', 'status', 'action'];
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  user_id:any
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.gettabilldata()
    this.user_id = this.erpUser.user_id
  }
  datasource:any

  async gettabilldata() {
    let obj :any= {}
    obj["b_acct_id"] = this.b_acct_id
    let resp = await this.tabillservice.gettabilldata(JSON.stringify(obj))
    console.log(resp);
    if (resp["error"] == false) {
      // console.log(resp.data)
      this.spinner.hide();
      let j = 1
      for (let i = 0; i < resp.data.length; i++) {
        resp.data[i]['new_index'] = j
        j = j + 1
      }
      // console.log(resp.data)
      this.datasource = new MatTableDataSource(resp.data)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;

    }
    else {
      Swal.fire('Error', "Some Error Ocuured At server Side", 'error')
    }

  }

  aaproveObj :any= {}
  async approve(element :any) {

    this.aaproveObj = Object.assign({}, element)

    Swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approved it!'
    }).then((result) => {
      if (result.value) {
        this.finalapprove()
      }
    })



  }
  async finalapprove() {

    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["emp_expense_id"] = this.aaproveObj['emp_expense_id']
    obj["life_cycle_status"] = 'APPROVED'
    obj["user_id"] = this.user_id
    // console.log(obj)
    var resp = await this.tabillservice.approvetabillstatus(obj)
    if (resp["error"] == false) {
      Swal.fire('Success', "Successfully Approved It", 'success')
      await this.gettabilldata()

    }
    else {
      Swal.fire('Error', "Some Error Occurred At Server Side", 'error')
    }

  }
  async cancel(element :any) {

    this.aaproveObj = Object.assign({}, element)

    Swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Reject',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Reject it!'
    }).then((result) => {
      if (result.value) {
        this.finalcancel()
      }
    })



  }
  async finalcancel() {

    var obj :any= new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["emp_expense_id"] = this.aaproveObj['emp_expense_id']
    obj["life_cycle_status"] = 'REJECTED'
    obj["user_id"] = this.user_id
    var resp = await this.tabillservice.approvetabillstatus(obj)
    if (resp["error"] == false) {
      Swal.fire('Success', "Successfully Rejected", 'success')
      await this.gettabilldata()

    }
    else {
      Swal.fire('Error', "Some Error Occurred At Server Side", 'error')
    }
  }

  ///////////////////////////memo image
  //  async getmemophoto() {
  //   const obj = {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['complain_id'] = this.complaint_detail['complain_id']
  //   obj['doc_type'] = "Memo";
  //   console.log(obj)
  //   var resp = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
  //   console.log(resp)
  //   if (resp['error'] == true) {
  //     Swal.fire('error', "server side error", 'error')
  //     return
  //   } else {
  //     this.memo_image = resp['data']
  //     console.log(this.memo_image)
  //   }
  // }
  photoObj = {}

  filename:any;
  imgURL:any;
  async view(element :any) {
    console.log(element)
    var obj :any= new Object();

    obj["b_acct_id"] = this.b_acct_id;

    obj["doc_id"] = element.id;

    obj["filename"] = element.doc_name;

    this.filename = element.doc_name;

    const res = await this.tabillservice.getUploadedtabbillData(obj);
    // console.log(res)
    if (res) {
      var docname = element.doc_name;
      var ext = docname.split(".");
      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf" });

        var fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    }
  }

}
