import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from '../../../service/main.service';
import { MorthService } from '../../service/morth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
// import { ExcelService } from "../service/file-export.service";
import { ExcelService } from "../../../service/file-export.service";
import * as XLSX from "xlsx";



@Component({
  selector: 'app-morth',
  templateUrl: './morth.component.html',
  styleUrls: ['./morth.component.css']
})
export class MorthComponent implements OnInit {
  /*--------------- Old Code S------------------------*/
  // constructor(private sorItemsService: SorItemsService) {
  //   this.sorItemsService.costing_rate_std = "MORTH"
  //   this.sorItemsService.screen = "Morth"
  // }

  // ngOnInit() {

  // }
  // ngOnDestroy() {
  //   this.sorItemsService.costing_rate_std = " ";
  //   this.sorItemsService.screen =  " ";
  //   // this.screen =
  // }
  /*----------------Old Code E------------------------*/
  constructor(public mainService: MainService, private morthService: MorthService, private spinner: NgxSpinnerService, private excel: ExcelService,) {

  }
  // displayedColumns = ["sno", "ref_to_morth", "level_cd", "item_desc", "unit", "quantity" , "action"];
  displayedColumns = ["sno", "ref_to_morth", "level_cd", "item_desc", "action"];
  datasource: any
  obj: any = {}
  obj1: any = {}
  _level_arr: any = []
  _erp_user: any;
  m_item_list: any = true;
  map_m_item: any = false;
  create_m_item: any = false
  create_m_excel_item: any = false;
  dataObj: any
  chap_lists: any
  region: any
  allMorthData: any
  selectedRegion: any
  finYear: any
  isDisable: boolean = false
  list_flag: any = 'active'
  update_m_flag: any = "deactive"
  create_m_flag: any = "deactive"
  upload_m_flag: any = "deactive"
  map_m_flag: any = "deactive"
  create_m_excel_flag: any = "deactive"
  update_flag = false;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  createByExcl: any = false
  async ngOnInit() {
    this._erp_user = JSON.parse(atob(localStorage.getItem('erpUser') as any));

    // this.createByExcl = this._erp_user.role_cd.includes('AA') ? true :false
    // console.log('erp User ==> ' , this._erp_user.role_cd.includes('AA') , this.createByExcl)
    this.obj['costing_rate_std'] = 'MORTH'
    this.no = this.no + 1;
    this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': '', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': 1 })
    await this.getAllRegions();
    await this.getAllMorth()

  }
  finYearCh(fin_Year: any) {
    this.finYear = fin_Year
  }
  chap_desc: any = {}
  chapter_obj: any
  async chapterCh() {
    if (!this.chapter_obj) {
      this.datasource = new MatTableDataSource([]);
      this.datasource.paginator = this.paginator;
    }
    await this.tableData(this.chapter_obj.ch_name, this.chapter_obj.ch_no)
  }

  bind_val: any
  async regaionCh(selected_Region: any) {
    console.log('selected Region', selected_Region);
    this.selectedRegion = selected_Region
    let obj: any = {}
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    obj['fin_year'] = this.finYear;
    obj['region'] = this.selectedRegion
    let resp: any = await this.morthService.getChapter(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      resp.data.map((x: any) => {
        x['chap_desc'] = x['chap_no'] + ' - ' + x['chap_name'];
        x['bind_val'] = {
          ch_name: x['chap_name'],
          ch_no: x['chap_no']
        }
      })
      this.chap_lists = resp.data
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }

  }



  tableObjjj: any
  async tableData(ch_name: any, ch_no: any) {
    let table_obj: any = []

    for (let i = 0; i < this.allMorthData.length; i++) {
      if (this.allMorthData[i].chap_no == ch_no && this.allMorthData[i].chap_name == ch_name) {
        if (this.allMorthData[i].sno != null && this.allMorthData[i].ref_to_morth != null && this.allMorthData[i].level_cd == null) {
          table_obj.push(this.allMorthData[i]);

        }
      }
    }
    this.tableObjjj = table_obj
    this.datasource = new MatTableDataSource(table_obj);
    this.datasource.paginator = this.paginator;

  }
  morth_id: any
  table_data: any = []
  async handleMouseOver(s_no: any) {
    this.table_data = []
    console.log('hovereed on' + s_no);
    for (let i = 0; i < this.allMorthData.length; i++) {
      if (this.allMorthData[i].sno == s_no) {
        this.morth_id = this.allMorthData[i].morth_id
      }
    }
    console.log('loop closed', this.morth_id);
    for (let i = 0; i < this.allMorthData.length; i++) {
      if (this.allMorthData[i].morth_id == this.morth_id) {
        this.table_data.push(this.allMorthData[i])
      }
    }


    console.log('final data based on morth id', this.table_data)
  }








  async getAllMorth() {
    let obj: any = {}
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    let resp: any = await this.morthService.getAllMorth(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log('all morth ---->', resp.data);
      this.allMorthData = resp.data
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }

  }

  async getAllRegions() {
    let obj: any = {}
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    let resp: any = await this.morthService.getAllRegions(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log('regionn ---->', resp.data);
      this.region = resp.data
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }

  }



  no: any = 0
  async level(level_no: any, i: any) {
    console.log('ddddddd' , this.obj)
    await this.getSubAnalysis(this.obj)
    if (this.insertSubAnalysisEnabled) {
      if (level_no == 1) {
        this.no = this.no + 1;
        this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': 'Sub Analysis', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': level_no })
      }
    } else {
      if (level_no == 1) {
        this.no = this.no + 1;
        this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': '', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': level_no })
      }
    }
    if (level_no == 2) {
      // this.no=t his.no+1;
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': '', 'ref_to_morth': '', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': level_no })
    } else if (level_no == 3) {
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': null, 'item_desc': null, 'unit': '', 'tak_op': '', 'item_unit': null, 'quantity': null, 'tbl_level_cd': level_no });
    } else if (level_no == 4) {
      `  `
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': null, 'item_desc': null, 'unit': null, 'tak_op': null, 'item_unit': '', 'quantity': '', 'tbl_level_cd': level_no });
    } else if (level_no == 5) {
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': '', 'tbl_level_cd': level_no, check: 'FW' });
    } else if (level_no == 6) {
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': '', 'item_desc': 'Overhead charges', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': '', 'tbl_level_cd': level_no });
    } else if (level_no == 7) {
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': '', 'item_desc': `Contractor's profit`, 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': '', 'tbl_level_cd': level_no });
    } else if (level_no == 8) {
      this._level_arr.splice(i + 1, 0, { 'no': null, 'sno': null, 'ref_to_morth': null, 'level_cd': '', 'item_desc': `Royality`, 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': '', 'tbl_level_cd': level_no });
    }


  }
  async submit() {
    let obj: any = {}

    obj['b_acct_id'] = this._erp_user.b_acct_id;
    obj['cost_rate_std'] = this.obj['costing_rate_std'];
    obj['fin_year'] = this.obj['fin_year'];
    obj['chap_no'] = this.obj['chapter_no'];
    obj['chap_name'] = this.obj['chapter_name'];
    obj['est_type'] = this.obj['est_type'];
    obj['user_id'] = this._erp_user.le_id
    obj['life_cycle_status'] = 'ACTIVE'
    obj['region'] = this.selectedRegion
    this._level_arr.map((x: any) => {
      for (let key in x) {
        if (x[key] == '') {
          x[key] = null
        }
      }
    })


    const findYear = this.allMorthData.filter((x: any) => x.fin_year == obj['fin_year']);
    const findRegain = this.allMorthData.filter((x: any) => x.region == obj['region']);
    const findChapNum = this.allMorthData.filter((x: any) => x.chap_no == obj['chap_no'])

    if (findYear.length != 0 && findRegain.length != 0 && findChapNum.length != 0) {
      console.log('fin Year || regiion || chapNO mathched', findYear, findChapNum, findRegain)
      let findSno: any
      let findRef: any
      this._level_arr.map((x: any) => {
        findSno = this.allMorthData.filter((y: any) => {
          if (Number(y.sno) == Number(x.sno) && (x.sno != null || x.sno != undefined || x.sno != '')) {
            return y
          }
        })
        findRef = this.allMorthData.filter((y: any) => {
          if (Number(y.ref_to_morth) == Number(x.ref_to_morth) && (x.ref_to_morth != null || x.ref_to_morth != undefined || x.ref_to_morth)) {
            return y
          }
        })
      })

      if (findSno.length != 0 && findRef.length != 0) {
        Swal.fire('Warning', 'Morth with same "SNo" and "Ref. to Morth Specification" already exist in the this Region and Financial Year', 'warning')
        return
      }
    }

    obj['data'] = this._level_arr;
    console.log('submit', obj);
    // return
    this.spinner.show();
    let resp = await this.morthService.createMorthItems(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      this.morthList()
      Swal.fire('Sucess', 'Created sucessfully', 'success')
    } else {
      this.spinner.hide()
      this.createMorth()
      this._level_arr = []

      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }

  }

  delete(i: any) {
    let no = 1
    this._level_arr.splice(i, 1)
    this._level_arr.map((x: any, i: any) => {
      if (x['no'] != null) {
        x['no'] = no
        no = no + 1
      }
    })
    this.no = no - 1
  }

  skip(data: any, i: any, key: any) {
    if (data == null) {
      this._level_arr[i][key] = ''
    }
  }
  createMorth() {
    this.create_m_item = true
    this.m_item_list = false
    this.update_flag = false
    this.create_m_excel_item = false

    this.list_flag = "deactive"
    this.update_m_flag = "deactive"
    this.create_m_flag = "active"
    this.create_m_excel_flag = "deactive"
    this.upload_m_flag = "deactive"
    this.map_m_flag = "deactive"

    this._level_arr = []
    this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': '', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': 1 })

    this.obj['chapter_name'] = null
    this.isDisable = false
    this.obj['fin_year'] = null
    this.obj['chap_no'] = null
    this.obj['costing_rate_std'] = 'MORTH'

  }

  mapMorth() {
    // this.map_m_item = true;
    // this.create_m_item = true
    // this.m_item_list = false
    this.create_m_excel_flag = "deactive"
    this.create_m_excel_item = false

  }

  async morthList() {
    // this.map_m_item = false;
    this.create_m_item = false
    this.m_item_list = true
    this.create_m_excel_flag = "deactive"
    this.list_flag = "active"
    this.update_m_flag = "deactive"
    this.create_m_flag = "deactive"
    this.upload_m_flag = "deactive"
    this.map_m_flag = "deactive"
    this.create_m_excel_item = false

    this._level_arr = []
    this.obj = {}
    this.finYear = null
    this.selectedRegion = null
    this.chapter_obj = null

    await this.getAllRegions();
    await this.getAllMorth()
  }

  async deleteMorth(element: any) {
    let obj: any = {}
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    obj['morth_id'] = element.morth_id
    let respp: any = await this.morthService.deleteMorthItems(obj)
    if (respp['error'] == false) {
      this.spinner.hide()
      this.chapter_obj = null
      this.chapterCh()
      // this.datasource = new MatTableDataSource([]);
      // this.datasource.paginator = this.paginator;
      Swal.fire('Sucess', 'Deleted sucessfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }
  }

  alldata = []
  async open_update(element: any) {
    console.table([element.sno, element.ref_to_morth]);

    let dataWrtM_id = []
    let m_id: any
    // to get all data respective of morth id  :start
    console.log('all data', this.allMorthData);
    for (let i = 0; i < this.allMorthData.length; i++) {
      if (this.allMorthData[i].sno == element.sno && this.allMorthData[i].ref_to_morth == element.ref_to_morth) {
        m_id = this.allMorthData[i].morth_id
      }
    }
    console.log(' morth_id', m_id);

    //  console.log('loop closed' , m_id );
    for (let i = 0; i < this.allMorthData.length; i++) {
      if (this.allMorthData[i].morth_id == m_id) {
        dataWrtM_id.push(this.allMorthData[i])
      }
    }


    console.log(' based on morth id', dataWrtM_id)

    // to get all morth id respective of morth id  :end



    this.update_flag = true
    this.create_m_item = true
    this.m_item_list = false;
    this.list_flag = 'deactive'
    this.update_m_flag = "active"
    this.create_m_flag = "deactive"
    this.upload_m_flag = "deactive"
    this.map_m_flag = "deactive"

    this.obj['chapter_name'] = element.chap_name
    this.isDisable = true
    this.obj['fin_year'] = element.fin_year
    this.obj['chap_no'] = element.chap_no


    // this._level_arr = dataWrtM_id

    // for(let j = 0; j< dataWrtM_id.length ; j++){
    //   for (let i = 0; i < dataWrtM_id.length; i++) {
    //     if( j == i){
    //       this._level_arr[i] = dataWrtM_id[j]
    //     }

    //   }
    // }


  }

  async update() {

  }
  async createMorthByExcel() {
    this.update_flag = false

    this.list_flag = "deactive"
    this.update_m_flag = "deactive"
    this.upload_m_flag = "deactive"
    this.create_m_flag = "deactive"
    this.map_m_flag = "deactive"
    this.create_m_excel_flag = "active"

    this.create_m_item = false
    this.m_item_list = false
    this.map_m_item = false
    this.create_m_excel_item = true
    //+++++++++++++++++++++++




  }


  exportExcelFormat() {
    let ExpData = [
      {
        // one: "No",
        one: "S No",
        two: "Ref. to Morth&H specifi.",
        three: "Code",
        four: "Item Description",
        five: "Unit",
        six: "Quantity",
        seven: "Overhead charges(Quantity)",
        eight: "Contractor's profit(Quantity)",
        nine: "Taking Output"


      },
    ];
    var exp = [];
    for (var i = 0; i < ExpData.length; i++) {
      var obj: any = new Object();
      //++


      // obj["1"] = ExpData[i]["one"];
      // obj["2"] = ExpData[i]["two"];
      // obj["3"] = ExpData[i]["three"];
      // obj["4"] = ExpData[i]["four"];
      // obj["5"] = ExpData[i]["five"];
      // obj["6"] = ExpData[i]["six"];
      // obj["7"] = ExpData[i]["seven"];
      // obj["8"] = ExpData[i]["eight"];
      // obj["9"] = ExpData[i]["nine"];
      // obj["10"] = ExpData[i]["ten"];
      obj["1"] = ExpData[i]["one"];
      obj["2"] = ExpData[i]["two"];
      obj["3"] = ExpData[i]["three"];
      obj["4"] = ExpData[i]["four"];
      obj["5"] = ExpData[i]["five"];
      obj["6"] = ExpData[i]["six"];
      obj["7"] = ExpData[i]["seven"];
      obj["8"] = ExpData[i]["eight"];
      obj["9"] = ExpData[i]["nine"];

      exp.push(obj);
    }
    this.excel.exportAsExcelFile(exp, "morthExcelFormat");
  }


  onFileChange1(event: any) {
    this.wrongExcel = false
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    console.log('target file', target.files);

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {

      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });


      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      const data = XLSX.utils.sheet_to_json(ws);

      this.setMorthData(data);

    };
  }

  dataByExcel: any = [];
  wrongExcel: any = false
  setMorthData(data: any) {
    console.log("excelllll data", data);
    this.dataByExcel = [];
    for (let i = 1; i < data.length; i++) {
      let obj: any = new Object();

      // obj["no"] = data[i][1] == undefined ? null : data[i][1];
      obj["sno"] = data[i]["1"] == undefined ? null : data[i]["1"];
      obj["ref_to_morth"] = data[i]["2"] == undefined ? null : data[i]["2"];
      obj["level_cd"] = data[i]["3"] == undefined ? null : data[i]["3"];
      obj["item_desc"] = data[i]["4"] == undefined ? null : data[i]["4"];
      obj["unit"] = data[i]["5"] == undefined ? null : data[i]["5"];
      obj["quantity"] = data[i]["6"] == undefined ? null : data[i]["6"];
      obj["tak_op"] = data[i]["9"] == undefined ? null : data[i]["9"];

      if (data[i][9]) {
        obj["unit"] = data[i]["5"]
      } else {
        obj["unit"] = null;
        obj["item_unit"] = data[i]["5"]
      }

      if (data[i][7]) {
        if (obj["item_desc"]) {
          console.log('there should be no desc with overhead charges');
          Swal.fire('Warning', 'overhead charges should be in The row with no desc, unit and quantity , Only Level code Is allowed with overhead charges', 'warning')
          this.wrongExcel = true
          obj['quantity'] = data[i]["7"]
        } else {
          obj["item_desc"] = 'Overhead charges'
          obj['quantity'] = data[i]["7"]
        }
      }
      if (data[i][2]) {
        if (!data[i][1]) {
          Swal.fire('Warning', 'Please write Sno With ref to morth ', 'warning')

        }
      }


      if (data[i][8]) {
        if (obj["item_desc"]) {
          console.log('there should be no desc with Contractors profit');
          Swal.fire('Warning', 'Contractors profit should be in The row with no desc, unit and quantity, Only Level code Is allowed withContractors profit ', 'warning')
          this.wrongExcel = true
        } else {
          obj["item_desc"] = 'Contractors profit'
          obj['quantity'] = data[i]["8"]
        }
      }
      if (data[i][1] && data[i][2]) {
        if (data[i][6]) {
          Swal.fire('Warning', 'there should be no Quantitiy with Sno & Ref to Morth', 'warning')
          this.wrongExcel = true
        }

        // if (data[i][7]) {
        //   Swal.fire('Warning', 'there should be no Overhead Charges with Sno & Ref to Morth', 'warning')
        //   this.wrongExcel = true
        // }
        // if (data[i][8]) {
        //   Swal.fire('Warning', 'there should be no contrators profit with Sno & Ref to Morth', 'warning')
        //   this.wrongExcel = true
        // }
      }














      // + validation for two entries with same
      for (let j = 0; j < this.dataByExcel.length; j++) {
        if (this.dataByExcel[j].sno != null && this.dataByExcel[j].sno != undefined) {
          if (this.dataByExcel[j].sno == data[i]["1"]) {
            // console.log('s no matched' , data[i]["1"])
            if (this.dataByExcel[j].ref_to_morth != null && this.dataByExcel[j].ref_to_morth == data[i]["2"]) {
              // console.log('both mathced', this.dataByExcel[j].ref_to_morth);
              Swal.fire('warning', 'Can not have two entries with same S.No and Ref. to Morth&H specifi.', 'warning')
              this.wrongExcel = true
            }
          }
        }
      }
      //+
      this.dataByExcel.push(obj);
      // console.log('lrngth' , this.dataByExcel.length ,this.dataByExcel);
    }
    console.log("excel data final ", this.dataByExcel);



    this.showExcelData = this.dataByExcel
  }
  showExcelData: any = []
  async submitByExcel() {
    let obj: any = {}
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    obj['cost_rate_std'] = this.obj['costing_rate_std'];
    obj['fin_year'] = this.obj['fin_year'];
    obj['region'] = this.obj['region']
    obj['chap_no'] = this.obj['chapter_no'];
    obj['chap_name'] = this.obj['chapter_name'];
    obj['user_id'] = this._erp_user.le_id
    obj['life_cycle_status'] = 'ACTIVE'

    obj['data'] = this.dataByExcel;
    console.log(' obj ==> ', obj)


    if (!obj['chap_name']) {
      Swal.fire('warning', 'Please Enter Chapter Name', 'warning')
      return
    }
    if (!obj['fin_year']) {
      Swal.fire('warning', 'Please Enter Year of Costing Rate Standard', 'warning')
      return
    }
    if (!obj['region']) {
      Swal.fire('warning', 'Please Enter Region', 'warning')
      return
    }
    if (!obj['chap_no']) {
      Swal.fire('warning', 'Please Enter Chapter Number', 'warning')
      return
    }

    if (this.wrongExcel == true) {
      Swal.fire('warning', 'Some Erros in Excel Entries', 'warning')
      return
    }

    this.spinner.show();
    let resp = await this.morthService.createMorthItems(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      Swal.fire('Sucess', 'Created sucessfully', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }

  }





  // Ref to Estimate
  type_of_Estimate: any = [
    {
      code: 'Culvert with Sub Structure of Brick Masonary (Rular Bridge)',
      value: 'Rular Bridge-->CULVERT1'
    },
    {
      code: 'Culvert with Sub Structure of RCC (Rular Bridge)',
      value: 'Rular Bridge-->CULVERT2'
    },
    {
      code: 'Box Culvert (Rular Bridge)',
      value: 'Rular Bridge-->Box Culvert'
    },
    {
      code: 'Well Foundation (Rular Bridge)',
      value: 'Rular Bridge-->Well Foundation'
    },
    {
      code: 'MDR (Urban Bridge)',
      value: 'Urban Bridge-->MDR'
    },
    {
      code: 'DDR (Urban Bridge)',
      value: 'Urban Bridge-->DDR'
    },
    {
      code: 'State Highway (Urban Bridge)',
      value: 'Urban Bridge-->State Highway'
    },
    {
      code: 'Well Foundation (Urban Bridge)',
      value: 'Urban Bridge-->Well Foundation'
    },

    // Road
    {
      code: 'Approach Road (New Technique)',
      value: 'Approach Road-->New Tech'
    },
    {
      code: 'Approach Road (Old Technique)',
      value: 'Approach Road-->Old Tech'
    },

    {
      code: 'Additional Approach Road (New Technique)',
      value: 'Add Approach Road-->New Tech'
    },
    {
      code: 'Additional Approach Road (Old Technique)',
      value: 'Add Approach Road-->Old Tech'
    },
  ]


  insertSubAnalysisEnabled: any;
  // this.
  isBlue: boolean = false;
  isBlack: boolean = true;

  async onToggleChange(event: any) {
    // this.isBlue = !this.isBlack;
    console.log('Toggle state:', event.checked, this._level_arr, this.insertSubAnalysisEnabled);

    if (event.checked) {
      this._level_arr = []
      this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': 'Sub Analysis', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': 1 })
      this.isBlue = true
      this.isBlack = false

      console.log('obj=>', this.obj)

      if (!this.obj['chapter_name'] || this.obj['chapter_name'] == null ||
        !this.obj['chapter_no'] || this.obj['chapter_no'] == null ||
        !this.obj['costing_rate_std'] || this.obj['costing_rate_std'] == null ||
        !this.obj['est_type'] || this.obj['est_type'] == null ||
        !this.selectedRegion || this.selectedRegion == null
      ) {
        Swal.fire('Warning', `Please Insert All Feilds`, 'warning')
      }


    } else {
      this.isBlue = false
      this.isBlack = true
      this._level_arr = []
      this._level_arr.push({ 'no': this.no, 'sno': '', 'ref_to_morth': '', 'level_cd': '', 'item_desc': '', 'unit': null, 'tak_op': null, 'item_unit': null, 'quantity': null, 'tbl_level_cd': 1 })
    }
  }
  sNoForRef:any
  async getSubAnalysis(obj: any) {
    obj['b_acct_id'] = this._erp_user.b_acct_id;
    obj['region'] = this.selectedRegion
    console.log('objecttt ', obj);

    let resp: any = await this.morthService.getSubAnalysis(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      let allSno = !resp.data[0].sNo ? undefined: resp.data[0].sNo.split(',');
      console.log('all sun analysis no  ---->', allSno , typeof( allSno ));
      this.sNoForRef = allSno
    } else {
      this.spinner.hide()
      console.log('server side error on getting sub analysis items');

      // Swal.fire('Error', 'server side error await a moment then try again if problem is exit connect with support', 'error')
    }


  }

}
