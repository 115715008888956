<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Vendor T&C
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="l_ved_flag" class="nav-link active" href="#tab-7-1" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-line-chart"></i>&nbsp;
                                Vendor T&C List
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="c_ved_flag" class="nav-link" href="#tab-7-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                                Create Vendor T&C
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="u_ved_flag" class="nav-link" href="#tab-7-3" (click)="refresh()"
                                data-toggle="tab"><i class="ti-settings"></i>
                                Update Vendor T&C
                            </a>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="up_ved_flag" class="nav-link" href="#tab-7-4" (click)="refresh()"
                                data-toggle="tab"><i class="ti-settings"></i>
                                Upload Vendor T&C
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="l_ved_flag">
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6>
                                        Select Purpose :
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="purpose" bindLabel="value" bindValue="code"
                                        (change)="changePurpose()" [multiple]="false" placeholder="Party Type"
                                        [(ngModel)]="Obj['purpose']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-6">
                                    <button class=" btn btn-outline-primary" (click)="gotocrete()">Create Vendor
                                        T&C</button> &nbsp;
                                    <button class=" btn btn-outline-primary" (click)="gotoUpload()">Upload Vendor
                                        T&C</button>
                                </div>

                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>

                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="purpose">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Purpose
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.purpose}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="tc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            T&C
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.tc}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" (click)="open_update(element)">
                                                Update
                                            </button>&nbsp;
                                            <button class="btn btn-danger" (click)="delete(element)">
                                                Delete
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>


                        <div *ngIf="c_ved_flag">
                            <br>
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor T&C List </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        Select Purpose :
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="purpose" bindLabel="value" bindValue="code"
                                        (change)="changePurpose()" [multiple]="false" placeholder="Party Type"
                                        [(ngModel)]="Obj['purpose']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"
                                        disabled>
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Enter T&C Decription:</div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px" id="re" name="re"
                                        [(ngModel)]="Obj['tc']" class="form-control"></textarea>
                                </div>
                                <div class="col-3"></div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class=" btn btn-outline-primary" (click)="submit()">Submit</button>
                                </div>
                            </div>
                            <br>
                        </div>
                        <div *ngIf="u_ved_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor T&C List </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        Select Purpose :
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="purpose" bindLabel="value" bindValue="code"
                                        (change)="changePurpose()" [multiple]="false" placeholder="Party Type"
                                        [(ngModel)]="Obj['purpose']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name"
                                        disabled>
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">Enter T&C Decription:</div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px" id="re" name="re"
                                        [(ngModel)]="Obj['tc']" class="form-control"></textarea>
                                </div>
                                <div class="col-3"></div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class=" btn btn-outline-primary" (click)="update()">Upadte</button>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="up_ved_flag">

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Vendor T&C List </button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>
                                        Select Purpose :
                                        <span style="color: red;"> *</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="purpose" bindLabel="value" bindValue="code"
                                        (change)="changePurpose()" [multiple]="false" placeholder="Party Type"
                                        [(ngModel)]="Obj['purpose']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <h6> Download format :</h6>
                                    <button class="btn btn-link" (click)="export()"> Download</button>
                                </div>
                                <div class="col-3">
                                    <input type="file" style="display: inline-block;" (change)="onFileChange1($event)"
                                        placeholder="Upload file " accept=".xlsx"> <!-- Upload file -->
                                </div>
                            </div>
                            <br>
                            <hr>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            S No.
                                        </th>
                                        <th scope="col">
                                            T&C Description
                                        </th>
                                        <th scope="col">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ob of table_data;let j=index">
                                        <td>{{ob['sno']}}</td>
                                        <td>{{ob['tc']}}</td>
                                        <td>
                                            <button class="btn btn-danger"
                                                (click)="deleteItem(ob,j)">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class=" btn btn-outline-primary" (click)="submitUpload()">Submit</button>
                                </div>
                            </div>
                            <br>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>