<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        Manage Variable Pay

                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab"><i class="ti-bar-chart"></i>
                              Employee All Pay Component List

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2" *ngIf="create_flag" data-toggle="tab"><i class="ti-settings"></i>
                               Add Employee Variable Pay Detail

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-3" *ngIf="update_flag" data-toggle="tab"><i class="ti-settings"></i>
                                Update Employee Variable Pay Detail
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-primary" (click)=open_create()>Add Variable Pay</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code5']}}  <span style="color: red;">*</span>
                                </div>
                                <div class="col-4">
                                    <!-- this is for all emp  -->
                                    <!-- <ng-select [items]="allEmplyees_new" bindLabel="emp_name" (change)="changeEmployee()" bindValue="arr_id" [multiple]="false" placeholder="Select Employee" [(ngModel)]="arr_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->

                                    <!-- this is for only active employee  -->
                                    <ng-select [items]="activeEmpArr" bindLabel="emp_name" bindValue="arr_id" (change)="changeEmployee()" [multiple]="false" placeholder="Select Employee" [(ngModel)]="arr_id" [selectableGroup]="true"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="field_name">
                                </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Status : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code6']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="status" bindLabel="value" (change)="changeEmployeeStatus()" bindValue="value" [multiple]="false" placeholder="  Select Status" [(ngModel)]="selected" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <hr>

                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code7']}} ">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="pay_comp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Component Code -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{ mainService.codeValueShowObj['HR0021'][element.pay_component_code] }} -->
                                            {{ element.pay_comp }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="rate">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Amount -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.rate }} </td>

                                    </ng-container>
                                    <ng-container matColumnDef="pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Component Type -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="valid_from">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Start Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_from}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="valid_upto">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- End Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code12']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_upto}}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Action -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code13']}} </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                            <button class="btn btn-outline-primary" *ngIf="nonSepRuleObj[element.pay_comp] && element.his==true" (click)="openUpdate(element)">
                                                <!-- Update -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code14']}} </button>
                                             <!-- <button class="btn btn-danger" *ngIf="nonSepRuleObj[element.pay_comp]" (click)="deleteVariablepay(element)">
                                                <-- Delete --
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code15']}} </button>   -->


                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code5']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" (change)="changeEmployeeOnAddVarPay()" bindLabel="emp_name" bindValue="arr_id" [multiple]="false" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code5']}} " [(ngModel)]="arr_id" [selectableGroup]="true"
                                        disabled [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>



                            </div>
                            <br>
                            <hr>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Special Component<span style="color: red;">*</span> :
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="allSepPayRule" bindLabel="component_code" (change)="changeSepComp()" bindValue="component_code" [multiple]="false" placeholder="" [(ngModel)]="sep_component_code" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="component_code">
                                    </ng-select>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Amount:
                                </div>
                                <div class="col-4">
                                    <input class="form-control" *ngIf="amount_disable" disabled type="number" [(ngModel)]="amount">
                                    <input class="form-control" *ngIf="!amount_disable" type="number" [(ngModel)]="amount">
                                </div>
                            </div>
                            <br>

                            <!--  <div class="row">
                                <div class="col-4 text-right">
                                    Select Component:
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0021']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="fixedpayObj['pay_component_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>


                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Type:
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0022']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="fixedpayObj['pay_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Enter Amount :
                                </div>
                                <div class="col-4">

                                    <input type="number" class="form-control" [(ngModel)]="fixedpayObj['pay_component_amt']">



                                </div>

                            </div>
                            <br>-->
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Start Date <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="fixedpayObj['effective_start_dt']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select End Period (If any)  <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="enddate" bindLabel="value" bindValue="value" [multiple]="false" placeholder="Select End Date" [(ngModel)]="selectedendate" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>
                            <br>
                            <div class="row" *ngIf="selectedendate=='CUSTOM'">
                                <div class="col-4 text-right">
                                    Enter End Date <span style="color: red;">*</span>:
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="fixedpayObj['effective_end_dt']">
                                </div>

                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-info" (click)="saveSepComp()">
                                                    SUBMIT
                                                </button>
                                </div>


                            </div>



                            <!-- <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submitFixedPay()">
                                        Submit

                                </div>
                            </div>  -->
                        </div>
                        <div class="tab-pane fade show active" *ngIf="update_flag"  id="tab-3">

                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary" (click)=open_list()>Back to List</button>
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code24']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new"  bindLabel="emp_name"  bindValue="arr_id" [multiple]="false" placeholder=" {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code5']}}" [(ngModel)]="selectEmpObj['arr_id']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" disabled>
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <hr>

                             <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Component: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code25']}}
                                </div>


                            <div class="col-4">

                                <ng-select [items]="allSepPayRule" bindLabel="component_code" (change)="changeSepComp()" bindValue="component_code" [multiple]="false" placeholder="" [(ngModel)]="sep_component_code" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="component_code" disabled>
                                </ng-select>


                            </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-4 text-right">
                                    <-- Type: --
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code26']}}
                                </div>
                                <div class="col-4">
                                    <p *ngIf="fixedpayObj['pay_code']== 'PAY'">
                                        PAYABLE
                                    </p>
                                    <p *ngIf="fixedpayObj['pay_code'] == 'DED'">
                                        DEDUCTION
                                    </p>
                                    <-- <ng-select [items]="mainService.codeValueTechObj['HR0022']" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="fixedpayObj['pay_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select> --
                                </div>
                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enter Amount : -->
                                  Current Amount
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control"   [(ngModel)]="this.fixedpayObj['rate']" disabled>



                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Enter Amount : -->
                                   Proposed Amount
                                </div>
                                <div class="col-4">
                                    <input type="number" class="form-control" *ngIf="amount_disable"  [(ngModel)]="amount" disabled>


                                    <input type="number" class="form-control" *ngIf="!amount_disable" [(ngModel)]="amount">
                                </div>
                            </div>


                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Start Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code20']}} <span style="color:red">*</span>:
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="fixedpayObj['valid_from']">
                                </div>
                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select End Period (If any) : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code21']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="enddate" bindLabel="value" bindValue="value" [multiple]="false" placeholder="  {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code28']}} " [(ngModel)]="selectedendate" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>


                            <br *ngIf="selectedendate=='CUSTOM'">
                            <div class="row" *ngIf="selectedendate=='CUSTOM'">
                                <div class="col-4 text-right">
                                    <!-- Select End Date: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code28']}}
                                </div>
                                <div class="col-4">
                                    <input type="date" class="form-control" [(ngModel)]="fixedpayObj['valid_upto']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="updateFixPay()">
                                        <!-- Submit -->
                                      Update </button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>

<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Are You Sure to change this...? -->
                    {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code29']}} </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <h6>
                            <!-- Following Component are being effected after this .. -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code30']}} </h6>
                    </div>
                </div>

                <table style="width: 100%;">
                    <tr style="width: 100%;background-color: blue;color: white;">
                        <th>
                            <!-- Component Code -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code31']}} </th>
                        <th>
                            <!-- Amount -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code32']}}
                        </th>

                        <th>
                            <!-- Type -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code26']}} </th>
                        <th>
                            <!-- Effective Start Date -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code33']}} </th>
                        <th>
                            <!-- Effective End Date -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code34']}} </th>
                        <th>
                            <!-- Action -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code13']}} </th>
                    </tr>
                    <tr></tr>
                    <tr></tr>
                    <tr *ngFor="let ob of Arr;let i=index">
                        <td> {{ob['pay_component_code']}}</td>
                        <td> <input type="number" class="form-control" [ngModel]="ob['pay_component_amt']"></td>
                        <td> {{ob['pay_code']}}</td>
                        <td> {{ob['effective_start_dt']}}</td>
                        <td *ngIf="ob['effective_end_dt']=='2090-10-10'">
                            <!-- ALL PERIOD -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code35']}}
                        </td>
                        <td *ngIf="ob['effective_end_dt']!='2090-10-10'"> {{ob['effective_end_dt']}}</td>
                        <td> <button class="btn btn-danger" (click)="deleteArr(i)">
                            <!-- Delete -->
                            {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code15']}}</button></td>
                    </tr>

                </table>



                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" (click)="addEmpFixPay()">
                        <!-- Submit -->
                        {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code23']}} </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                        {{mainService.allLableShowObjHR[mainService.language_cd+'Fixed_code36']}}
                        <!-- Close -->
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
