// import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource } from '@angular/material/table';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
// import { HrHierService } from '../../service/hr-hier.service';
// import { MainService as mainServiceMD } from '../../../md/service/main.service';
// import { NgxSpinnerService } from "ngx-spinner";
// import { MainService } from '../../service/main.service';
// import { ThrowStmt } from '@angular/compiler';
// declare var $: any;
// import Swal from 'sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { EmbHierService } from '../../../service/emb-hier.service';
import { HrHierService } from '../../service/hr-hier.service';
import { MainService as mainServiceMD } from '../../../md/service/main.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from '../../service/main.service';
import { DataAccessService } from '../../service/data-access.service';
// import { ThrowStmt } from '@angular/compiler';
declare var $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-hr-data-assignment',
  templateUrl: './hr-data-assignment.component.html',
  styleUrls: ['./hr-data-assignment.component.css']
})
export class HrDataAssignmentComponent implements OnInit {



  erpUser: any;
  b_acct_id: any;
  Obj: any = {}
  ActiveNode: any = [];



  allRoles: any = [];
  allComp: any;
  selectedComp: any = [];
  dataSource: any;
  role_name: any;
  role_id: any;



  displayedColumns: string[] = ['user_id', 'node_cd', 'status', 'Roles', 'node_path', 'node_desc', 'action'];
  constructor(private dataAccessService: DataAccessService, private hrHierService: HrHierService, private spinner: NgxSpinnerService, private mainServiceMD: mainServiceMD, public mainService: MainService) { }
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllRoles();
    await this.getAllCurrentUserList();
    await this.getAccountUserInfo()
    await this.getcostcenters();
    await this.getAllHRdataAssigned();
    this.Obj['status'] = 'ACTIVE'
    await this.filter_list()
    await this.getactivity()
    this.reset()
  }

  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.Obj = {};
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }

  open_activitypopup() {
    if (this.planing_arr.length == 0) {
      this.down({}, -1)
    }
    $('#ACTHIER').modal('show');
  }


  setHierarchyNodeitem(item:any , i:any ) {
    item['is_leaf'] = this.projhierobjisleaf[item['hierarchy_type']]
    let flag = false
    let dummy2 = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])
    }
    this.Obj['node_cd'] = this.planing_arr[this.planing_arr.length - 1]['hierarchy_type']
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }



  projecthier:any  = []
  projhierobj :any = {}
  projhierobjtype :any = {}
  projhierobjtypecd :any = {}
  projhierobjisleaf:any  = {}
  projhierobjlevel :any = {}
  projhierobjpath :any = {}


  async getactivity() {
    let obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.hrHierService.getprojectHierarchy(obj)
    if (resp['error'] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['lvl1_value'] == "All Projects") {
          resp.data[i]['lvl1_value'] = 'HR-root'
        }
      }
      var data = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (((resp.data[i]['lvl1_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['lvl2_cd'] == resp.data[i]['leaf_cd']) || (resp.data[i]['module_cd'] == "HR"))
          && (resp.data[i]['module_cd'] != null || resp.data[i]['module_cd'] != undefined)) {
          data.push(resp.data[i])
        }
      }

      console.log(data)

      resp['data'] = data;
      console.log(resp['data'])
      this.projecthier = resp['data']
      for (let i = 0; i < resp['data'].length; i++) {
        let path = []
        for (let j = 1; j < 8; j++) {

          path.push(resp['data'][i]['lvl' + (j) + '_value'])
          this.projhierobj[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_value']
          this.projhierobjtype[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_node_type']
          this.projhierobjtypecd[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['lvl' + (j) + '_cd']
          this.projhierobjisleaf[resp['data'][i]['lvl' + (j) + '_cd']] = resp['data'][i]['is_leaf']
          this.projhierobjlevel[resp['data'][i]['lvl' + (j) + '_cd']] = j
          this.projhierobjpath[resp['data'][i]['lvl' + (j) + '_cd']] = path.join("-->")

        }
      }
      console.log(this.planing_arr);



    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  submit_act() {
    if (this.planing_arr[this.planing_arr.length - 1]['is_leaf'] == 0) {
      Swal.fire('Error', 'You Have Not Selected Leaf At The Last Level', 'error')

    } else {
      $('#ACTHIER').modal('hide');
    }
  }
  down(item:any , k:any ) {
    console.log(this.planing_arr)
    let patharr = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      console.log(this.planing_arr[i])

      patharr.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])


    }
    this.Obj['path_desc'] = patharr.join("-->")
    this.planing_arr.push({ level: k + 2, existing_heirarchy_nodes: [] })
    let arr:any  = []
    for (let i = 0; i < this.projecthier.length; i++) {
      if (!arr.includes(this.projecthier[i]['lvl' + (k + 2) + '_cd']) && this.projecthier[i]['lvl' + (k + 2) + '_cd'] != null) {
        if (this.projecthier[i]['lvl' + (k + 1) + '_cd'] == item['hierarchy_type'] || k == -1) {
          this.planing_arr[k + 1]['existing_heirarchy_nodes'].push({ node_cd: this.projecthier[i]['lvl' + (k + 2) + '_cd'], desc: this.projecthier[i]['lvl' + (k + 2) + '_value'], is_leaf: this.projecthier[i]['is_leaf'] })
          arr.push(this.projecthier[i]['lvl' + (k + 2) + '_cd'])

        }

      }
    }
  }
  remove(item:any , i:any ) {
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
      let patharr = []
      for (let i = 0; i < this.planing_arr.length; i++) {
        console.log(this.planing_arr[i])

        patharr.push(this.projhierobj[this.planing_arr[i]['hierarchy_type']])


      }
      this.Obj['path_desc'] = patharr.join("-->")
    }

  }
  // ***********************************Account Integration******************************************************************
  allcostcenters :any = []
  async getcostcenters() {
    let obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.hrHierService.getCost(obj)
    if (resp['error'] == false) {
      this.allcostcenters = []
      console.log(resp['data'])
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['cc_func'] == "EMB") {
          let obb = Object.assign({}, resp['data'][i])
          obb['node_cd'] = obb['cc_code']
          obb['desc'] = obb['cc_code'] + '-' + obb['cc_name']
          obb['node_desc'] = obb['desc']
          this.allcostcenters.push(obb)

        }

      }
      console.log(this.allcostcenters)
      console.log(this.planing_arr);



    } else {
      this.spinner.hide()
      Swal.fire('Error...', 'Error While Getting Cost Center', 'error')
    }
  }
  // ***********************************Account Integration******************************************************************
  assigned_data:any  = []
  async getAllHRdataAssigned() {
    var  obj :any  =new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.hrHierService.getAllHrdataAssigned(JSON.stringify(obj));
    console.log(resp, 'all data')
    this.assigned_data = resp['data']
    if (resp['error'] == false) {
    }
  }
  allembdata:any  = [];
  nodecdtopath:any  = {};
  nodecdtoleve:any  = {}
  nodecdtodesc:any  = {}

  async filter_list() {
    let dummy:any  = []
    for (let i = 0; i < this.assigned_data.length; i++) {
      if (this.Obj['status'] == this.assigned_data[i]['status']) {
        this.assigned_data[i]['path'] = this.projhierobjpath[this.assigned_data[i]['node_cd']]
        this.assigned_data[i]['level'] = this.projhierobjlevel[this.assigned_data[i]['node_cd']]
        this.assigned_data[i]['node_desc'] = this.projhierobj[this.assigned_data[i]['node_cd']]
        dummy.push(this.assigned_data[i])
      }
    }
    for (let i = 0; i < dummy.length; i++) {
      for (let j = 0; j < this.users.length; j++) {
        if (dummy[i]['user_id'] == this.users[j]['user_id']) {
          dummy[i]['user_name'] = this.users[j]['emp_name']
          let id = dummy[i]['user_id'];
          if (this.role_herarichy[id] != undefined)
            dummy[i]['roles'] = this.role_herarichy[id]['role_name']

        }
      }
    }

    console.log(dummy);

    this.allembdata = dummy
    this.dataSource = new MatTableDataSource()
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  access :any = []

  userObj :any = {};

  users:any  = []
  filterUser:any  = [];
  async getAccountUserInfo() {
    var  obj :any  =new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.mainServiceMD.getuserforrolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.users = resp.data;
      console.log(this.users);
      for (let i = 0; i < this.users.length; i++) {
        this.userObj[this.users[i]['user_id']] = this.users[i]['emp_name']
        this.users[i]['desc'] = this.users[i]['user_id'] + " - " + this.users[i]['emp_name'] + " - " + this.users[i]['emp_phone_no']
      }
    }

    this.filterUser = await this.dataAccessService.filterEmployeeDataByUserId(this.users)
    console.log(this.userObj, 'userObj')
  }
  status = [{ id: 'ACTIVE' }, { id: 'INACTIVE' }]
  lastselct:any  = [];
  setHierarchyNode(item:any , i:any ) {
    console.log(this.planing_arr)
    let flag = false
    let dummy = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2 = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            flag = true
            item['level'] = this.existing_heirarchy_nodes[j]['level']
            this.Obj['node_cd'] = this.existing_heirarchy_nodes[j]['node_cd']
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    if (flag == false) {
      this.Obj['node_cd'] = null
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
    this.lastselct = dummy
    console.log(this.lastselct);

  }
  used_nodes:any  = []
  selected_row:any  = {}
  add_dropdown(data:any , index:any ) {

    //console.log(data, index)
    let flag = true
    let obj = {}
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      if (data['hierarchy_type'] == this.existing_heirarchy_nodes[i]['node_cd']) {
        obj = Object.assign({}, this.existing_heirarchy_nodes[i])
      }
    }
    this.selected_row = Object.assign({}, obj)
    this.selected_row['index'] = index

    this.filter_data()
  }
  filter_data() {
    console.log(this.planing_arr);

    let dummy = []
    let max_level = 0
    for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
      // if (this.Obj['hier_ident_code'] == this.planaing_hierarchy[i]['hier_ident_code']) {
      //   max_level = this.planaing_hierarchy[i]['level_count']
      //   break;
      // }
      if (this.existing_heirarchy_nodes[i]['level'] >= max_level) {
        max_level = this.existing_heirarchy_nodes[i]['level']
      }

    }

    for (let i = 0; i < this.allcostcenters.length; i++) {
      if (!this.existing_heirarchy_nodes.includes(this.allcostcenters[i]))
        this.existing_heirarchy_nodes.push(this.allcostcenters[i])
    }
    console.log(max_level, this.selected_row, this.existing_heirarchy_nodes)
    for (let j = 1; j <= max_level; j++) {
      for (let i = 0; i < this.existing_heirarchy_nodes.length; i++) {
        let node_arr = []
        node_arr = this.existing_heirarchy_nodes[i]['path'].split('|')
        // console.log(node_arr, 'noida arr', this.existing_heirarchy_nodes[i], node_arr.includes(this.selected_row['node_cd']), this.selected_row)
        if (node_arr.includes(this.selected_row['node_cd'])) {
          if (this.existing_heirarchy_nodes[i]['level'] == j && this.existing_heirarchy_nodes[i]['level'] > this.selected_row['level']) {
            dummy.push(this.existing_heirarchy_nodes[i])
          }
        }
      }
      if (dummy.length > 0) {
        break;
      }
    }
    this.planing_arr.push({})
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = []
    this.planing_arr[this.selected_row['index'] + 1]['existing_heirarchy_nodes'] = dummy
    console.log(dummy, 'dummy', this.planing_arr)
  }
  allHRRole:any  = []
  roles:any  = []
  roleObj:any  = {}
  async getAllRoles() {
    var  obj :any  =new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'HR';
    var resp = await this.mainServiceMD.getCurrentMdRole(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.roles = []
      this.allHRRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['is_system_role'] == 0) {
          this.roles.push(resp.data[i])
        }
        this.roleObj[resp.data[i]['role_cd']] = resp.data[i]['role_name'];
      }

    }
  }
  allUsers :any = []
  role_herarichy :any = {}
  async getAllCurrentUserList() {
    var  obj :any  =new  Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'HR';
    var resp = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.allUsers = resp['data']
      var emp_id :any = new Object();
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.allHRRole.length; j++) {
          if (this.allHRRole[j]['role_cd'] == resp['data'][i]['role_cd']) {
            if (emp_id[resp['data'][i]['user_id']] == undefined) {
              resp['data'][i]['role_name'] = this.allHRRole[j]['role_name']
              emp_id[resp['data'][i]['user_id']] = resp['data'][i]
            } else {
              emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.allHRRole[j]['role_name']
            }
          }
        }
      }

      console.log(emp_id)
      this.role_herarichy = emp_id;


    }
  }
  serchfilter = ""
  find() {

    let l = this.planing_arr.length

    console.log(this.allembdata);
    let newdata = []
    let len = this.allembdata.length

    for (let x = 0; x < len; x++) {

      for (let j = 0; j < this.planing_arr.length; j++) {
        if (this.allembdata[x]['node_cd'] == this.planing_arr[j]['hierarchy_type']) {
          newdata.push(this.allembdata[x]);

        }

      }



    }
    console.log(newdata);

    this.dataSource = new MatTableDataSource(newdata)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;






  }
  remove_dropdown(data:any , i:any ) {
    //console.log(data, i)
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop()
    }
    let dummy = []
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy.push(this.planing_arr[i]['hierarchy_type'])
    }
    let dummy2:any  = []
    if (dummy.length > 0) {
      for (let i = 0; i < dummy.length; i++) {
        for (let j = 0; j < this.existing_heirarchy_nodes.length; j++) {
          if (dummy[i] == this.existing_heirarchy_nodes[j]['node_cd']) {
            this.Obj['node_cd'] = this.existing_heirarchy_nodes[j]['node_cd']
            dummy2.push(this.existing_heirarchy_nodes[j]['node_desc'])
          }
        }
      }
    }
    this.Obj['path_desc'] = dummy2.join(' --> ')
  }
  planing_arr :any = [{}]
  NodeCodeToNodeDesc :any = {};
  existing_heirarchy_nodes:any  = []





  async inactive(element:any ) {

    this.spinner.show();
    var obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    obj['update_user_id'] = this.erpUser.user_id;
    obj['status'] = 'INACTIVE';
    console.log(obj)
    var resp = await this.hrHierService.updateHrdataAssign(obj);
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllHRdataAssigned();
      this.filter_list()
      Swal.fire("Success...", "Status Successfully Changed To Inactive", 'success');

    }
    else {
      this.spinner.hide();
      Swal.fire('Error...', 'Error While Changing Status...', 'error');
    }

  }
  async reset() {
    this.Obj = {}
    this.planing_arr = []
    this.down({}, -1)
    this.getactivity()
    this.Obj['status'] = 'ACTIVE'
    await this.filter_list()
  }
  async submit() {
    this.Obj['node_cd'] = this.planing_arr[this.planing_arr.length - 1]['hierarchy_type']
    if (this.Obj['node_cd'] && this.Obj['user_id']) {
      // this.spinner.show()
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['create_user_id'] = this.erpUser.user_id;
      this.Obj['status'] = 'ACTIVE';
      console.log(this.Obj)
      var resp = await this.hrHierService.insertHrdataAssign(this.Obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllHRdataAssigned();
        Swal.fire("Success..", "Successfully Created", 'success');
      }
      else {
        this.spinner.hide();
        Swal.fire('Error...', 'Error While Assign Node..', 'error');
      }
    } else {
      Swal.fire('Warning...', 'Please Enter All Fields Marked As *', 'warning')
    }
  }

  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
