<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'property1']}} </div>
                    <!--Property-->
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'property2']}}</a>
                            <!--Property List-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                {{auth.allLableShowObjProp[auth.language_cd+'property3']}} </a>
                            <!--Create Property-->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>
                                {{auth.allLableShowObjProp[auth.language_cd+'property4']}}</a><!-- Update Property -->
                        </li>
                    </ul>

                    <br>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property14']}}<span
                                            style="color: red;"> *</span></h6>
                                </div>
                                <!--Select Plan Layout :-->
                                <div class="col-3">
                                    <ng-select [items]="allPlan_layout" bindLabel="desc"
                                        bindValue="property_group_id" [multiple]="false"
                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property14']}}"
                                        (change)="getAllProperty()"
                                        [(ngModel)]="Obj['property_group_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <!--Select Location Type-->
                                </div>
                                <div class="col-3">

                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property5']}}">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div> -->
                            </div>
                            <br>

                           <div class="row">
                            <div class="col-12 text-right" *ngIf="allProp.length>0">
                                 <button class="btn btn-outline-success" (click)="print1()" >Download List</button> </div>
                           </div>
                           <br>
                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="assigned_property_number">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property6']}}

                                        </th>
                                        <!--Property Number-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.assigned_property_number }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="property_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!--Property type-->
                                            {{auth.allLableShowObjProp[auth.language_cd+'property7']}}


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.property_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property8']}}

                                        </th>
                                        <!--Description-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.description }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="area">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property9']}}

                                        </th>
                                        <!--Total
                                            Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.area }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'property10']}}</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                            *ngIf="element.life_cycle_status=='ACTIVE' || element.life_cycle_status=='APPROVED' "
                                                (click)="open_update(element)">{{auth.allLableShowObjProp[auth.language_cd+'property11']}}</button>
                                            <!--Update-->
                                            <button class="btn btn-outline-danger"
                                            *ngIf="element.life_cycle_status=='ACTIVE'"
                                                (click)="delete(element)">{{auth.allLableShowObjProp[auth.language_cd+'property12']}}</button>
                                            <!--Delete-->
<!--
                                            <i *ngIf="element.life_cycle_status!=='ACTIVE'">
                                                {{element.life_cycle_status}}</i> -->
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>



                        </div>
                        <div *ngIf="create_flag">

                          <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first"
                                 label="{{auth.allLableShowObjProp[auth.language_cd+'property13']}}">
                                    <br>
                                    <div class="card">
                                        <!--Select Plan-->
                                        <div style="margin: 2%;">
                                            <br>

                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data_list,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()' [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()' placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()' bindLabel="lvl3_value"
                                                        bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()' bindLabel="lvl4_value"
                                                        bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()' bindLabel="lvl5_value"
                                                        bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()' bindLabel="lvl6_value"
                                                        bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()' bindLabel="lvl7_value"
                                                        bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data_list.length-1">
                                                    <button (click)="aleratMsg()"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="aleratMsg()"   *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property18']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property19']}}">
                                    <!--Property Details-->
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property20']}}</h6>
                                                    <!--Selected Plan Layout Hierarchy :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property21']}}
                                                        <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <!--Property Number :-->
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property21']}}"
                                                        [(ngModel)]="Obj['assigned_property_number']"
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property23']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property24']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">{{Obj['unit']}}</div>
                                            </div>
                                            <br>

                                           <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property25']}}</h6>
                                                </div>
                                                <!--Property type :-->
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property26']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Enter Description :-->
                                                </div>
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property26']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                            <!-- <div class="row">
                                                 <div class="col-3">
                                                    <h6>
                                                        Select Feature Group : <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                        bindLabel="code" bindValue="value" [multiple]="false"
                                                        placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        (change)="getFeatures()"
                                                        [closeOnSelect]="true" name="chart_of_account0"  >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                 <div class="col-3">
                                                    <h6>
                                                        Select Feature : <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="feature_list"
                                                        bindLabel="feature_desc" bindValue="feature_id" [multiple]="true"
                                                        placeholder="Select Feature " [(ngModel)]="Obj['feature_id_arr']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0"  >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br> -->
                                        <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-2">
                                                    <h6>  {{auth.allLableShowObjProp[auth.language_cd+'property28']}}</h6>
                                                 </div>
                                                <div class="col-2">
                                                    <ng-select [items]="features" bindLabel="feature_ident"
                                                        bindValue="feature_ident" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property28']}}"
                                                        (change)="setRowValues(item,i)" [(ngModel)]="item['feature']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                 </div>
                                                <div class="col-2">
                                                    <span *ngIf="item['type']=='3'">
                                                        <h6>{{auth.allLableShowObjProp[auth.language_cd+'property30']}}
                                                        </h6>
                                                     </span>
                                                    <span *ngIf="item['type']=='4'">
                                                        <button class="btn btn-outline-danger"
                                                            (click)="open_modal(item,i)">{{auth.allLableShowObjProp[auth.language_cd+'property31']}}</button>
                                                     </span>
                                                </div>
                                                <div class="col-2">
                                                     <input *ngIf="item['type']=='3'" type="number"
                                                        [(ngModel)]="item['feature_value']" class="form-control" name=""
                                                        id="">
                                                    <span *ngIf="item['type']=='4'"> {{item['feature_value']}}</span>
                                                    <span *ngIf="item['type']=='2'"> {{item['feature_value']}}</span>
                                                    <span *ngIf="item['type']=='1'"> {{item['feature_value']}}</span>
                                                </div>
                                                <div class="col-1">
                                                    <span
                                                        *ngIf="item['type']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property32']}}</span>
                                                 </div>
                                                <div class="col-1">
                                                    <span
                                                        *ngIf="item['type']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property33']}}</span>
                                                 </div>
                                                <div class="col-2">

                                                    <button *ngIf="i==data.length-1" (click)="addRow(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-plus-circle"
                                                            aria-hidden="true"></i></button>
                                                    <button (click)="removeRow(item,i)" class="btn btn-outline-danger">
                                                        <i class="fa fa-trash-o" aria-hidden="true"></i></button>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property34']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property35']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property36']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property37']}}</h6>
                                                    <!--Select Plan Layout :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property38']}}</h6>
                                                    <!--Property Number :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property39']}}"
                                                        disabled [(ngModel)]="Obj['assigned_property_number']"
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property40']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property41']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">{{Obj['unit']}}</div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property42']}}</h6>
                                                    <!--Property type :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property43']}}</h6>
                                                    <!--Enter Description :-->
                                                </div>
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']" disabled
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property44']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                             <div class="row">
                                                <div class="col-12">
                                                    <table class="table table-bordered"
                                                        style="width: 80%; margin-left: 10%;">
                                                        <thead>
                                                            <tr>
                                                                <th>{{auth.allLableShowObjProp[auth.language_cd+'property45']}}
                                                                </th>
                                                                 <th>{{auth.allLableShowObjProp[auth.language_cd+'property46']}}
                                                                </th>
                                                             </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of data">
                                                                <td>{{item['feature']}}</td>
                                                                <td>{{item['feature_value']}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property47']}}</button>
                                        &nbsp;&nbsp;

                                        <button class="btn btn-outline-success"
                                            (click)="submit()">{{auth.allLableShowObjProp[auth.language_cd+'property48']}}</button>&nbsp;&nbsp;
                                     </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property13']}}">
                                    <!--Select Plan-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data_list,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()' [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()' placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()' bindLabel="lvl3_value"
                                                        bindValue="lvl3_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()' bindLabel="lvl4_value"
                                                        bindValue="lvl4_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()' bindLabel="lvl5_value"
                                                        bindValue="lvl5_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()' bindLabel="lvl6_value"
                                                        bindValue="lvl6_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()' bindLabel="lvl7_value"
                                                        bindValue="lvl7_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" disabled>
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data_list.length-1">
                                                    <button (click)="aleratMsg()"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="aleratMsg()"   *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property53']}}</button>
                                         </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property19']}}">
                                    <!--Property Details-->
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property54']}}</h6>
                                                    <!--Selected Plan Layout Hierarchy:-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property55']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Property Number :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property55']}}"
                                                        [(ngModel)]="Obj['assigned_property_number']"
                                                         disabled
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property57']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property58']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">{{Obj['unit']}}</div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property59']}}</h6>
                                                    <!--Property type :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6> {{auth.allLableShowObjProp[auth.language_cd+'property60']}}<span
                                                            style="color: red;"> *</span></h6>
                                                </div><!-- Enter Description :-->
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property60']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                              <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property62']}}</h6>
                                                 </div>
                                                <div class="col-2">
                                                    <ng-select [items]="features" bindLabel="feature_ident"
                                                        bindValue="feature_ident" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property62']}}"
                                                        (change)="setRowValues(item,i)" [(ngModel)]="item['feature']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                 </div>
                                                <div class="col-2">
                                                    <span *ngIf="item['type']=='3'">
                                                        <h6>{{auth.allLableShowObjProp[auth.language_cd+'property64']}}
                                                        </h6>
                                                     </span>
                                                    <span *ngIf="item['type']=='4'">
                                                        <button class="btn btn-outline-danger"
                                                            (click)="open_modal(item,i)">{{auth.allLableShowObjProp[auth.language_cd+'property65']}}</button>
                                                     </span>
                                                </div>
                                                <div class="col-2">
                                                     <input *ngIf="item['type']=='3'" type="number"
                                                        [(ngModel)]="item['feature_value']" class="form-control" name=""
                                                        id="">
                                                    <span *ngIf="item['type']=='4'"> {{item['feature_value']}}</span>
                                                    <span *ngIf="item['type']=='2'"> {{item['feature_value']}}</span>
                                                    <span *ngIf="item['type']=='1'"> {{item['feature_value']}}</span>
                                                </div>
                                                <div class="col-1">
                                                    <span
                                                        *ngIf="item['type']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property66']}}</span>
                                                 </div>
                                                <div class="col-1">
                                                    <span
                                                        *ngIf="item['type']=='3'">{{auth.allLableShowObjProp[auth.language_cd+'property67']}}</span>
                                                 </div>
                                                <div class="col-2">
                                                    <button *ngIf="i==data.length-1" (click)="addRow(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-plus-circle"
                                                            aria-hidden="true"></i></button>

                                                    <button (click)="removeRow(item,i)" class="btn btn-outline-danger">
                                                        <i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>

                                            <!-- show images end -->

                                            <!-- <div class="row">
                                                 <div class="col-3">
                                                    <h6>
                                                        Select Feature Group : <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD52']"
                                                        bindLabel="code" bindValue="value" [multiple]="false"
                                                        placeholder="Select Feature Group" [(ngModel)]="Obj['feature_type']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        (change)="getFeatures()"
                                                        [closeOnSelect]="true" name="chart_of_account0"  >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                 <div class="col-3">
                                                    <h6>
                                                        Select Feature : <span style="color: red;"> *</span>
                                                    </h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="feature_list"
                                                        bindLabel="feature_desc" bindValue="feature_id" [multiple]="true"
                                                        placeholder="Select Feature " [(ngModel)]="Obj['feature_id_arr']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0"  >
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br> -->
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'property68']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'property69']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'property36']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property70']}}</h6>
                                                    <!-- Select Plan Layout :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['path_desc']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property71']}}</h6>
                                                    <!--Property Number :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="old_table_data"
                                                        bindLabel="assigned_property_number"
                                                        bindValue="assigned_property_number" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property72']}}"
                                                        disabled [(ngModel)]="Obj['assigned_property_number']"
                                                        [selectableGroup]="true" (change)="setSelectPropertyDetails()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                    </ng-select>
                                                    <!--Select Hierarchy Node-->
                                                </div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property73']}}</h6>
                                                    <!--Area :-->
                                                </div>
                                                <div class="col-2">{{Obj['area']}}</div>
                                                <div class="col-1">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property74']}}</h6>
                                                    <!--Unit :-->
                                                </div>
                                                <div class="col-1">
                                                    {{Obj['unit']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property75']}}</h6>
                                                    <!--Property type :-->
                                                </div>
                                                <div class="col-9">
                                                    {{Obj['property_type']}}
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'property76']}}</h6>
                                                    <!-- Enter Description :-->
                                                </div>
                                                <div class="col-3">
                                                    <textarea style="width: 100%; height: 80px;" id="re" name="re"
                                                        [(ngModel)]="Obj['description']" disabled
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property77']}}"
                                                        class="form-control"></textarea>
                                                </div>
                                                <!--Enter Node Code-->
                                            </div>
                                            <br>
                                             <div class="row">
                                                <div class="col-12">
                                                    <table class="table table-bordered"
                                                        style="width: 80%; margin-left: 10%;">
                                                        <thead>
                                                            <tr>
                                                                <th>{{auth.allLableShowObjProp[auth.language_cd+'property78']}}
                                                                </th>
                                                                 <th>{{auth.allLableShowObjProp[auth.language_cd+'property79']}}
                                                                </th>
                                                             </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of data">
                                                                <td>{{item['feature']}}</td>
                                                                <td>{{item['feature_value']}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>Back</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="update()">{{auth.allLableShowObjProp[auth.language_cd+'property81']}}</button>&nbsp;&nbsp;
                                        <!--Update-->
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner>{{auth.allLableShowObjProp[auth.language_cd+'property82']}}</ngx-spinner>
<!--Loading....-->
<!--Loading....-->
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg" style="max-width: 80%;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title" style="font: bold; ">
                    {{auth.allLableShowObjProp[auth.language_cd+'property83']}}
                </h4>
                <!--Select Location By Hierarchy-->
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <div class="card text-center">
                    <div style="margin: 2%;">
                        <br>
                        <div class="row" *ngIf="selected_feature=='Acquisition Location'">
                            <div class="col-3"></div>
                            <div class="col-3">
                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'property84']}}</h6>
                                <!--Select Planning Hierarchy :-->
                            </div>
                            <div class="col-3">
                                <ng-select [items]="acquisition_hierarchy" bindLabel="desc" bindValue="hier_ident_code"
                                    [multiple]="false"
                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property85']}}"
                                    (change)="getAllpropHierarchynodes2()" [(ngModel)]="Obj['hier_ident_code2']"
                                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                    name="chart_of_account0">
                                    <!--Select Location Type-->
                                </ng-select>
                            </div>
                            <div class="col-3"></div>

                        </div>
                        <div class="row" *ngIf="selected_feature!='Acquisition Location'">
                            <div class="col-3"></div>
                            <div class="col-3">
                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'property86']}}</h6>
                            </div>
                            <!--Select  Hierarchy :-->
                            <div class="col-3">
                                <ng-select [items]="planaing_hierarchy" bindLabel="desc" bindValue="hier_ident_code"
                                    [multiple]="false"
                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property85']}}"
                                    (change)="getAllpropHierarchynodes2()" [(ngModel)]="Obj['hier_ident_code2']"
                                    [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                    name="chart_of_account0">
                                    <!--Select Location Type-->
                                </ng-select>
                            </div>
                            <div class="col-3"></div>

                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">

                            </div>
                        </div>


                        <div class="row" *ngFor="let item of planing_arr2,let i =index">
                            <div class="col-3"></div>
                            <div class="col-3" *ngIf="i==0">
                                <h6>{{auth.allLableShowObjProp[auth.language_cd+'property87']}}</h6>
                                <!--Select Planning Target :-->
                            </div>
                            <div class="col-3" *ngIf="i!=0"></div>
                            <div class="col-3">
                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="node_desc"
                                    bindValue="node_cd" [multiple]="false"
                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property88']}}"
                                    [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                    (change)="setLocationData(item,i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true">
                                    <!--Select Hierarchy Node-->
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==planing_arr2.length-1">
                                <button (click)="add_dropdown2(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2(item,i)" class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-3"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                                    <thead>
                                        <tr>
                                            <th>{{auth.allLableShowObjProp[auth.language_cd+'property85']}}</th>
                                            <!--Select Location type-->
                                            <th>{{auth.allLableShowObjProp[auth.language_cd+'property89']}}</th>
                                            <!--Select Location-->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <ng-select [items]="mainService.codeValueTechObj['PROPFLD5']"
                                                    bindLabel="value" bindValue="code" [multiple]="false"
                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property85']}}"
                                                    (change)="change_data()" [(ngModel)]="Obj['loc_type']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </td>
                                            <td>
                                                <ng-select [items]="filtered_location_data" bindLabel="desc"
                                                    bindValue="node_cd" [multiple]="false"
                                                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'property85']}}"
                                                    [(ngModel)]="Obj['loc_type_cd']" (change)="setFeature()"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true" name="chart_of_account0">
                                                    <!--Select Location Type-->
                                                </ng-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <br>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="setFeature()"
                        data-dismiss="modal">{{auth.allLableShowObjProp[auth.language_cd+'property91']}}</button>
                </div>
                <!--Save And Close-->

            </div>
        </div>
    </div>
</div>

<ngx-spinner>Loading...</ngx-spinner>
