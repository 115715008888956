<div class="page-content fade-in-up">
  <div class="row">

    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">{{auth.allLableShowObjProp[auth.language_cd+'Allotment1']}}</div>
        </div>
        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                {{auth.allLableShowObjProp[auth.language_cd+'Allotment2']}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>
                {{auth.allLableShowObjProp[auth.language_cd+'Allotment3']}}</a>
            </li>
            <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                                {{auth.allLableShowObjProp[auth.language_cd+'Allotment4']}}</a>
                        </li> -->
            <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-4" data-toggle="tab"><i class="fa fa-plus-circle"></i>Allotment Status</a>
                        </li> -->
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab-1">

              <br>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <h6>Select Offer : </h6>
                </div>
                <div class="col-4">
                  <ng-select [items]="allOffer" bindLabel="desc" bindValue="offer_cd" [multiple]="false"
                    (change)="getAllPropOffers()" placeholder=" Select Offer" [(ngModel)]="obj1['offer_cd']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <h6>Select Property Type : </h6>
                </div>
                <div class="col-4">
                  <ng-select [items]="allProOffer" bindLabel="pro_type" bindValue="pro_type" [multiple]="false"
                    (change)="getAllAllotment()" placeholder=" Select Property Type" [(ngModel)]="obj1['property_type']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter($any($event.target).value)"
                      placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Allotment9']}}">
                  </mat-form-field>
                </div>
                <div class="col-8 text-right">
                  <button class="btn btn-outline-success"
                    (click)="export1()">{{auth.allLableShowObjProp[auth.language_cd+'Allotment10']}} Excel</button>
                  &nbsp;&nbsp;
                  <button class="btn btn-outline-primary"
                    (click)="print1()">{{auth.allLableShowObjProp[auth.language_cd+'Allotment11']}}</button>
                </div>
              </div>

              <div class="example-container">
                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">

                  <ng-container matColumnDef="party_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment12']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.party_id}} </td>
                  </ng-container>



                  <ng-container matColumnDef="arr_effective_date">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment13']}}</b>
                    </th>

                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{mainService.dateFormatChange(element.arr_effective_date)}}
                    </td>
                  </ng-container>



                  <ng-container matColumnDef="property_code">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment15']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.property_type }}
                    </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="length">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment16']}}</b> </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.length }} </td>
                                    </ng-container> -->
                  <ng-container matColumnDef="measurement_unit">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment17']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.unit }} </td>
                  </ng-container>
                  <ng-container matColumnDef="property_no">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment18']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.assigned_property_number }} </td>
                  </ng-container>
                  <ng-container matColumnDef="quota_code">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment19']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.quota_cd }} </td>
                  </ng-container>

                  <ng-container matColumnDef="scheme_code">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>Offer code</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.offer_cd }} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      <b>{{auth.allLableShowObjProp[auth.language_cd+'Allotment25']}}</b>
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.arr_status_code }} </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      {{auth.allLableShowObjProp[auth.language_cd+'Allotment26']}}</th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <button class="btn btn-outline-primary" *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                        (click)="approve(element,i)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment27']}}</button>
                      <button class="btn btn-outline-info" *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                        (click)="openUpdate(element)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment28']}}</button>
                      <button class="btn btn-outline-danger" *ngIf="element['arr_status_code']=='ALLOTMENT_PENDING'"
                        (click)="cancel(element, i)">{{auth.allLableShowObjProp[auth.language_cd+'Allotment29']}}</button>
                      <button class="btn btn-outline-success" *ngIf="element['arr_status_code']=='ALLOTED'"
                        (click)="view_info(element,i)">View Details</button>
                      <button class="btn btn-outline-primary" *ngIf="element['arr_status_code']=='ALLOTED'"
                        (click)="allotmentPrint(element)">Download</button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
              </div>
            </div>
            <div class="tab-pane fade" id="tab-2">

              <br>
              <div class="row">
                <div class="col-4 text-right">
                  <h6>Select Offer : </h6>
                </div>

                <div class="col-4">
                  <ng-select [items]="allOffer" bindLabel="desc" bindValue="offer_cd" [multiple]="false"
                    placeholder=" Select Offer" [(ngModel)]="allotmentObj['offer_cd']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn btn-primary" (click)="fetchDteaildFprAllotment()">
                    Fetch
                    <!-- {{auth.allLableShowObjProp[auth.language_cd+'Allotment33']}} -->
                  </button>
                </div>

              </div>
              <!-- samarjeet -->
              <div class="container-fluid mt-5 p-5 border border-5 border-info rounded">
                <div class="row">


                  <div class="col-md-6" *ngIf="mode_of_sale=='LOTTERY'">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Lottery Date : </h6>
                      </div>
                      <div class="col-6">
                        <input type="date" value="{{resObj['lottery_date']}}" class="form-control" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Allotment Date : </h6>
                      </div>
                      <div class="col-6">
                        <input type="date" [max]="current_date" class="form-control"
                          [(ngModel)]="resObj['allotment_date']">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Select Property Type : </h6>
                      </div>
                      <div class="col-6">
                        <ng-select [items]="unique_proType" bindLabel="pro_type" bindValue="pro_type" [multiple]="false"
                          placeholder=" Select property Type" [(ngModel)]="resObj['property_type']"
                          [selectableGroup]="true" (change)="filterApplicantUsinProType()"
                          [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">

                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Select Applicant : </h6>
                      </div>

                      <div class="col-6">
                        <ng-select [items]="selectedApplicant" bindLabel="party_name" bindValue="id" [multiple]="false"
                          placeholder=" Select Applicant Name" (change)="setProperty()" [(ngModel)]="resObj['id']"
                          [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                          name="field_name">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <!-- applicant -->
                  <div class="col-md-6">
                    <!-- property number -->
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Select Property Number : </h6>
                      </div>

                      <div class="col-6">
                        <!-- selected from propertyArr2 in setProperty function -->
                        <ng-select [items]="selectedPropertyNumber" bindLabel="desc" bindValue="property_id"
                          [multiple]="false" placeholder="Select Property Number"
                          [(ngModel)]="resObj['alloted_property_id']" [selectableGroup]="true"
                          [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Payment mode : </h6>
                      </div>

                      <div class="col-6">
                        <ng-select [readonly]=true [items]="seletedPaymentMethod" bindLabel="payment_option"
                          bindValue="payment_option" [multiple]="false" placeholder=" Select Payment"
                          (change)="allPaymetOption()" [(ngModel)]="resObj['mode_of_payment']" [selectableGroup]="true"
                          [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid mt-5 p-5 border border-2 border-info rounded">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Select Payment Option : </h6>
                      </div>
                      <div class="col-6">
                        <ng-select [items]="paymentOptionArr" bindLabel="description" bindValue="payment_option_id"
                          [multiple]="false" placeholder=" Select payment option" (change)="paymentOptionDetail()"
                          [(ngModel)]="resObj['payment_option_id']" [selectableGroup]="true"
                          [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-4 text-right">
                        <h6>Period Starts From : </h6>
                      </div>
                      <div class="col-6">
                        <input [(ngModel)]="resObj['period_start_from']" name="" class="form-control" id="" disabled
                          placeholder="Allotment">
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="resObj['mode_of_payment']=='Installment'">
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-4 text-right">
                          <h6>First Lumpsum Amount in ₹: </h6>
                        </div>

                        <div class="col-6">
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>First Lumpsum Amount in ₹</mat-label>
                            <input matInput placeholder="Enter lumpsum amount in ₹" type="number"
                              [(ngModel)]="resObj['first_lumsum']">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-4 text-right">
                          <h6>Within : </h6>
                        </div>

                        <div class="col-6">
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Enter number of days</mat-label>
                            <input matInput placeholder="example: 30 days" type="number"
                              [(ngModel)]="resObj['first_lumsum_day']">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-4 text-right">
                          <h6>Second Lumpsum Amount in ₹: </h6>
                        </div>
                        <div class="col-6">
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Second Lumpsum Amount in ₹</mat-label>
                            <input matInput placeholder="Enter lumpsum amount in ₹" type="number"
                              [(ngModel)]="resObj['second_lumsum']">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-4 text-right">
                          <h6>Within : </h6>
                        </div>

                        <div class="col-6">
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Enter number of days</mat-label>
                            <input matInput placeholder="example: 30 days" type="number"
                              [(ngModel)]="resObj['second_lumsum_day']">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container mt-4">
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" (click)="displayTable()">
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div class="container-fluid mt-5 p-5 border border-2 border-info rounded">
                                <table mat-table [dataSource]="resObjList" class="mat-elevation-z8">


                                    <ng-container matColumnDef="lottery_date">
                                      <th mat-header-cell *matHeaderCellDef> Lottery Date </th>
                                      <td mat-cell *matCellDef="let element"> {{element.lottery_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="allotment_date">
                                      <th mat-header-cell *matHeaderCellDef> Allotment Date </th>
                                      <td mat-cell *matCellDef="let element"> {{element.allotment_date}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="property_type">
                                      <th mat-header-cell *matHeaderCellDef>Property Type</th>
                                      <td mat-cell *matCellDef="let element"> {{element.property_type}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="party_name">
                                      <th mat-header-cell *matHeaderCellDef>Applicant</th>
                                      <td mat-cell *matCellDef="let element"> {{element.party_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Property Number</th>
                                      <td mat-cell *matCellDef="let element"> {{element.property_number}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Payment method</th>
                                      <td mat-cell *matCellDef="let element"> {{element.payment_method}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Payment Option</th>
                                      <td mat-cell *matCellDef="let element"> {{element.payment_option}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>First Lumpsum Amount</th>
                                      <td mat-cell *matCellDef="let element"> {{element.first_lumsum}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>First Lumpsum pay days</th>
                                      <td mat-cell *matCellDef="let element"> {{element.first_lumsum_within}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Second Lumpsum Amount</th>
                                      <td mat-cell *matCellDef="let element"> {{element.second_lumsum}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef>Second Lumpsum pay days</th>
                                      <td mat-cell *matCellDef="let element"> {{element.second_lumsum_within}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                  </table>
                            </div> -->


              <div class="container-fluid mt-5" *ngIf="displayTableFlag">
                <div class="row">


                  <table class="table-responsive table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Lottery Date</th>
                        <th scope="col">Allotment Date</th>
                        <th scope="col">Property Type</th>
                        <th scope="col">Applicant</th>
                        <th scope="col">Property Number</th>
                        <th scope="col">Payment method </th>
                        <th scope="col">Payment Option</th>
                        <th scope="col" *ngIf="resObj['mode_of_payment']=='Installment'">First Lumpsum Amount</th>
                        <th scope="col" *ngIf="resObj['mode_of_payment']=='Installment'">first Lumpsum Amount Within day
                        </th>
                        <th scope="col" *ngIf="resObj['mode_of_payment']=='Installment'">Second Lumpsum Amount</th>
                        <th scope="col" *ngIf="resObj['mode_of_payment']=='Installment'">second Lumpsum Amount Within
                          day</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h6>
                            {{displayTableData['lottery_date']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{displayTableData['allotment_date']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{displayTableData['property_type']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{resObj['id']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{displayTableData['alloted_property_id']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{displayTableData['mode_of_payment']}}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {{displayTableData['description']}}
                          </h6>
                        </td>
                        <td *ngIf="resObj['mode_of_payment']=='Installment'">
                          <h6>
                            {{displayTableData['first_lumsum']}}
                          </h6>
                        </td>
                        <td *ngIf="resObj['mode_of_payment']=='Installment'">
                          <h6>
                            {{displayTableData['first_lumsum_day']}}
                          </h6>
                        </td>
                        <td *ngIf="resObj['mode_of_payment']=='Installment'">
                          <h6>
                            {{displayTableData['second_lumsum']}}
                          </h6>
                        </td>
                        <td *ngIf="resObj['mode_of_payment']=='Installment'">
                          <h6>
                            {{displayTableData['second_lumsum_day']}}
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" (click)="addAllotment()">
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <!-- samarjeet -->

              <br>

            </div>


            <div class="tab-pane fade" id="tab-3">
              <br>

              <div class="row">
                <div class="col-4 text-right">
                  {{auth.allLableShowObjProp[auth.language_cd+'Allotment37']}} :
                </div>
                <div class="col-4">
                  <input class="form-control" type="text" [(ngModel)]="allotmentObj['id']"
                    placeholder=" {{auth.allLableShowObjProp[auth.language_cd+'Allotment37']}}" disabled>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-4 text-right">
                  {{auth.allLableShowObjProp[auth.language_cd+'Allotment38']}} :
                </div>
                <div class="col-4">
                  <!-- <input class="form-control" type="text" [(ngModel)]="allotmentObj['property_code']"> -->
                  <ng-select [items]="propertyArr2" bindLabel="desc" bindValue="property_id" [multiple]="false"
                    placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Allotment35']}}"
                    [(ngModel)]="allotmentObj['alloted_property_id']" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
              </div>
              <br>

              <div class="row">
                <div class="col-12 text-center">

                  <button class="btn btn-primary" (click)="updateAllotment()">
                    {{auth.allLableShowObjProp[auth.language_cd+'Allotment39']}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<ngx-spinner>
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<div class="modal" id="myModal1">
  <div class="modal-dialog modal-lg" style="max-width: 90%;">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" style="font: bold; ">Reason Of Action</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div style="overflow-x: scroll;">
          <h4 class="modal-title" style="font: bold; ">Please Give The Reason</h4>
          <br>
          <input type="text" placeholder="Enter Reason" [(ngModel)]="Obj['reason']" class="form-control">
          <br>
          <!-- <div class="btn btn-outline-primary" data-dismiss="modal" style="float: right;"> Submit Reason</div> -->
          <button type="button" class="btn btn-primary" (click)='cancel(globleElement["element"],globleElement["i"])'
            data-dismiss="modal" style="float: right;"> Submit Reason</button>

        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal" id="myModal10">
  <div class="modal-dialog modal-lg" style="max-width: 90%;">
    <div class="modal-content rounded-50">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" style="font: bold; ">Allotment Details :</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <!-- Modal body -->
      <div class="modal-body" style="border-radius: 10px;">
        <div class="card" style="background-color:rgb(255, 255, 255);  border: 2px solid  rgb(24, 24, 97);">
          <div style="margin: 3%;">
            <!-- <div class="row">
                            <div class="col-2"></div>
                            <div class="col-2">
                                <h5>Offer Code </h5>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-6">
                                : &nbsp; {{view_infoObj['offer_cd']}}
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-2">
                                <h5>Offer Name </h5>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-6">
                                : &nbsp; {{view_infoObj['offer_name']}}
                            </div>
                            <div class="col-2"></div>
                        </div> -->
            <div class="row">
              <!-- <div class="col-1"></div> -->
              <div class="col-2"></div>
              <div class="col-3 text-right">
                <h5><b>Offer Description :</b></h5>
              </div>

              <div class="col-3 text-left">
                <h5> <b> {{view_infoObj['offer_desc']}}</b></h5>
              </div>
              <div class="col-3"></div>
            </div>
            <br>

            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "><b>Offer Code :</b></div>
              <div class="col text-left "> {{view_infoObj['offer_cd']}}</div>
              <div class="col"><b>Offer Name :</b> </div>
              <div class="col text-left ">{{view_infoObj['offer_name']}}</div>
              <div class="col-2"></div>
            </div>

            <br>


            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "><b>Applicant Name :</b></div>
              <div class="col text-left "> {{view_infoObj['party_name']}}</div>
              <div class="col"><b>Mobile No. :</b></div>
              <div class="col text-left ">{{view_infoObj['party_phone_no']}}</div>
              <div class="col-2"></div>
            </div>

            <br>


            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "> <b>Email :</b></div>
              <div class="col text-left "> {{view_infoObj['party_email']}}</div>
              <div class="col"><b>Category :</b></div>
              <div class="col text-left ">{{view_infoObj['quota_cd']}}</div>
              <div class="col-2"></div>
            </div>

            <br>


            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "><b>Property Type :</b></div>
              <div class="col text-left "> {{view_infoObj['property_type']}}</div>
              <div class="col"><b>Alloted Property No :</b></div>
              <div class="col text-left ">{{view_infoObj['assigned_property_number']}}</div>
              <div class="col-2"></div>
            </div>

            <br>

            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "><b>Registration Amount (₹) :</b></div>
              <div class="col text-left "> {{view_infoObj['application_amount']}}</div>
              <div class="col"><b>Property Id :</b></div>
              <div class="col text-left ">{{view_infoObj['alloted_property_id']}}</div>
              <div class="col-2"></div>
            </div>

            <br>


            <div class="row">
              <div class="col-2"></div>
              <div class="col"><b>Total No of Payment :</b></div>
              <div class="col text-left ">{{view_infoObj['no_of_payment']}}</div>

              <div class="col offset-2 " *ngIf="view_infoObj['mode_of_payment']=='Installment'"><b>Payment Mode :</b>
              </div>
              <div class="col text-left " *ngIf="view_infoObj['mode_of_payment']=='Installment'">
                {{view_infoObj['mode_of_payment']}}</div>


              <div class="col-2"></div>
            </div>

            <br>


            <div class="row">
              <div class="col-2"></div>
              <div class="col offset-2 "><b>Interest Rate :</b></div>
              <div class="col text-left "> {{view_infoObj['interest_rate']}}</div>
              <div class="col"><b>Property Cost (₹) :</b></div>
              <div class="col text-left ">{{view_infoObj['pro_rate']}}</div>
              <div class="col-2"></div>
            </div>




            <br>


            <!-- samar comment -->

            <div class="row" *ngIf="view_infoObj['mode_of_payment']=='Installment'">
              <table class="table table-bordered" style="width: 80%; margin-left: 10%;">
                <thead style="color:white; background-color: rgb(118, 118, 118);">
                  <tr style="text-align: center;">
                    <th>S NO.</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Installment Amout</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="view_infoObj['first_lumsum']">
                    <td>{{0}}</td>
                    <td>{{view_infoObj['first_lumsum_start_date']}}</td>
                    <td>{{view_infoObj['first_lumsum_end_date']}}</td>
                    <td>{{view_infoObj['first_lumsum'] | number:'1.2-2' }}</td>
                  </tr>
                  <tr *ngIf="view_infoObj['second_lumsum']">
                    <td>{{0}}</td>
                    <td>{{view_infoObj['second_lumsum_start_date']}}</td>
                    <td>{{view_infoObj['second_lumsum_end_date']}}</td>
                    <td>{{view_infoObj['second_lumsum'] | number:'1.2-2'}}</td>
                  </tr>
                  <tr *ngFor="let item of  all_installment;let i =index">
                    <td>{{i+1}}</td>
                    <td>{{item['emi_start_date']}}</td>
                    <td>{{item['emi_end_date']}}</td>
                    <td>{{item['emi_amount'] | number:'1.2-2'}}</td>
                  </tr>

                </tbody>
              </table>

            </div>

            <!-- samar comment// -->


          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer">
          <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                        (click)="download(data)">Download</button> -->
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>
