import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LitigationService } from "../service/litigation.service";
import Swal from "sweetalert2";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from "@angular/platform-browser";
import * as XLSX from "xlsx";
import { MainService as Main } from "../../emb/service/main.service";
import { DatePipe, JsonPipe } from "@angular/common";
import { threadId } from "worker_threads";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { NgZone } from "@angular/core";
import { take } from "rxjs/operators";
import * as moment from "moment";
import {  Console } from "console";
import { FileUploader } from "ng2-file-upload";
import { MainService } from "../service/main.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ComplainServiceService } from "../service/complain-service.service";
import { ApprovalUserService } from "../../portal/service/approval-user.service";
// import { CKEditorModule } from 'ckeditor4-angular'
import { isConstructSignatureDeclaration } from "typescript";
import { LIVE_ANNOUNCER_ELEMENT_TOKEN_FACTORY } from "@angular/cdk/a11y";
import { MainService as mainService_MD } from "../../md/service/main.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Mainservice2Service } from "../service/mainservice2.service";
import { Router } from "@angular/router";
import {  ConsoleService } from "@ng-select/ng-select/lib/console.service";
import { ReportService } from "../service/report.service";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
// import { objectify } from "tslint/lib/utils";
import { async } from "@angular/core/testing";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
// import { // consoleReporter } from "jasmine";
// qr code
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcodez';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgForm } from "@angular/forms";



(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
@Component({
  selector: "app-litigation",
  templateUrl: "./litigation.component.html",
  styleUrls: ["./litigation.component.css"],
})
export class LitigationComponent implements OnInit {
  renderText = true;
  originalSize = false;
  fitToPage = false;
  showAll = true;
  autoresize = false;
  showBorders = true;
  renderTextModes = [0, 1, 2];
  renderTextMode = 1;
  rotation = 0;
  zoom = 1;
  zoomScale = "page-width";
  zoomScales = ["page-width", "page-fit", "page-height"];
  pdfQuery = "";
  totalPages: any;
  displayedColumns: string[] = [
    "s.no",
    "comp_id",
    "case_id",
    "filling_date",
    "auth_court",
    "next_hearing_date",
    "case_status",
    "action",
  ];
  dataSource:any;
  displayedColumns1: string[] = ["s.no", "date", "time", "desc", "attachment"];
  dataSource1:any;
  displayedColumns2: string[] = [
    "s.no",
    "document_type",
    "document_format",
    "verify_status",
    "description",
    "action",
  ];
  displayedColumns3: string[] = [
    "case_id",
    "document_name",
    "document_type_code",
    "action",
  ];
  dataSource2:any;
  dataSource3:any;
  dataSource10:any;
  displayedColumns4: string[] = [
    "s.no",
    "not_id",
    "no_un_act",
    "sec",
    "status",
    "action",
  ];
  dataSource4:any;
  displayedColumns9: string[] = [
    "s.no",
    "not_date",
    "no_un_act",
    "sec",
    "status",
    "action",
  ];
  dataSource9:any;
  Obj:any = {
    dataArr: [],
    contractor_prof: 0,
    overhead: 0,
    output_capacity_quantity: 1,
  };
  seevakaltobj:any = {};
  which_id:any;
  which_id_type:any;
  empty1:any;
  erpUser:any;
  b_acct_id:any;
  showvakaltArr :any= [];
  arr4 :any= [];
  caselist :any= {};
  caselist1 :any= {};
  arr :any= [];
  totalArr:any = [];

  f :any= false;
  noticeObj:any = {};

  owner_name: any;
  notice_under_act: any;
  sections: any;

  case_idArr:any;
  case_status:any
  case_f_date:any;
  arr1:any = [];
  noticeArr :any= [];
  noticeArr1 :any= [];
  showNotice :any= [];
  totalnot :any= [];
  totalnoticeArr :any= [];
  objhearing :any= {};
  latest_date:any;
  uploader:any;
  defobj:any = {};
  orderupload:any = {};
  h_f :any= [];
  order_idArr:any = [];
  defElement:any = [];

  orderuploadArr :any= [];

  defUpdate: boolean = false;
  avitotal:any = [];

  first_h_flag: boolean = false;
  last_h_flag: boolean = false;
  next_h_d_flag: boolean = false;
  next_h_t_flag: boolean = false;
  showdocument: boolean = false;

  notice_before: boolean = false;
  court_order_id: boolean = false;
  newcourt_order_id: boolean = true;
  upload_data: boolean = true;
  message_flag:any;
  public imagePath:any;
  penaltyObj:any = {};
  httpUrl:any;
  Username:any;

  orderuploadObj:any = {};
  notesheetObj:any = {};
  shownotesheetobj:any = [];
  showdocobj :any= [];
  alldoc:any = [];
  alldocarr:any = [];
  documentname:any;
  codeValueTechObj:any = {};
  htmlContent :any= "";
  role :any= [];

  ts = [
    { code: "Adharcard", value: "Adharcard" },
    { code: "PAN", value: "PAN" },
    { code: "Voter ID", value: "Voter ID" },
    { code: "OTHER", value: "OTHER" },
  ];
  ts1 = [
    { code: "Sealing", value: "Sealing" },
    { code: "Demolition", value: "Demolition" },
    { code: "Compunding", value: "Compunding" },
  ];

  allcasedetailObj:any = {};

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter3(filterValue: string) {
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }
  applyFilter4(filterValue: string) {
    this.dataSource4.filter = filterValue.trim().toLowerCase();
  }
  applyFilter10(filterValue: string) {
    this.dataSource10.filter = filterValue.trim().toLowerCase();
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  constructor(
    public ReportService: ReportService,
    public main2: Mainservice2Service,
    public ComplainService: ComplainServiceService,
    public liti: LitigationService,
    private sanitizer: DomSanitizer,
    public datepipe: DatePipe,
    private _ngZone: NgZone,
    public mainservice: MainService,
    private spinner: NgxSpinnerService,
    public ApprovalUserService: ApprovalUserService,
    public main: Main,
    private mainServiceMD: mainService_MD,
    private router: Router
  ) {}

  // constructor(public main2:Mainservice2Service,   public ComplainService: ComplainServiceService,public liti:LitigationService,private sanitizer: DomSanitizer,public datepipe: DatePipe,private _ngZone: NgZone,public mainservice:MainService,  private spinner: NgxSpinnerService,public ApprovalUserService: ApprovalUserService, public main: Main,) { }

  @ViewChild("autosize", { static: true }) autosize!: CdkTextareaAutosize;

  triggerResize() {
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

vihitpa: boolean = false;
  peshkar: boolean = false;
  aviInternal :any= {};

  async ngOnInit() {
    this.codeValueTechObj = this.main2.codeValueTechObj;
    // // // console.log(this.codeValueTechObj);
    this.aviInternal = {};
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    // // // console.log(this.erpUser);
    this.aviInternal = this.erpUser;
    this.Username = this.erpUser.party_name;
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainservice.httpUrl;
    this.role = this.erpUser.role_cd;
   console.log("This is role-----------------", this.role);
    for (let i = 0; i < this.role.length; i++) {
      if (this.role[i] == "PESH_RL") {
        this.peshkar = true;
      }
     if(this.role[i] == "VP")
      {
   this.vihitpa=true;
      }
    }

    await this.getNotification();
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: "file" });
    this.uploader.onAfterAddingFile = (file:any) => {
      file.withCredentials = false;
    };
    // await this.myFunction()
    await this.getWorkflowlog();
    // // // console.log("this is where i attached the log");
    await this.avinashFlow();
  }

  // this is for log_flow-----
  aviValue:any = {};
  async avinashFlow() {
    this.aviValue["user_id"] == this.aviInternal["user_id"];
    this.aviValue["party_name"] = this.aviInternal["party_name"];
    this.aviValue["contact_phone"] = this.aviInternal["contact_phone"];
    this.aviValue["contact_email"] = this.aviInternal["contact_email"];
    this.aviValue["b_acct_id"] = this.aviInternal["b_acct_id"];
    this.aviValue["arr_id"] = this.aviInternal["arr_id"];
    this.aviValue["acct_desc"] = this.aviInternal["acct_desc"];
    this.aviValue["account_short_name"] =
      this.aviInternal["account_short_name"];
    this.aviValue["account_name"] = this.aviInternal["account_name"];

    // // // console.log("this is call back function", this.aviValue);
  }

  // ------------------

  imgURL4:any;
  async view_doc(element:any) {
    // // // console.log(element);
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = element.doc_id;
    obj["filename"] = element.file_name;

    // // // console.log(obj);
    var resp:any = await this.liti.getalldocument(obj);
    // // // console.log("this is all document ", resp);
    if (resp) {
      var docname = obj["filename"];
      this.documentname = docname;
      // // // console.log(this.documentname);

      var ext = docname.split(".");
      // // // console.log("this is the document of docs", ext);
      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL4 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      // console.log(this.imgURL4);
          $("#myModal12").modal("show");
      } else {
        let file1 = new Blob([resp], { type: "application/pdf" });
        // // // console.log(file1);
        var fileURL = URL.createObjectURL(file1);
        // // // console.log(fileURL);
        window.open(fileURL);
      }
    }

  }
  @ViewChild("file", { static: true }) myInputVariable!: ElementRef;
  //@ViewChild('file')
  // myInputVariable:ElementRef
  reset() {
    this.myInputVariable.nativeElement.value = "";
  }

  htmlToPlaintext(text:any) {
    return text ? String(text).replace(/<\/?&[^>]+>/gi, "") : "";
  }

  vpname = "";
  zone_name = "";
  async getNotification() {
    var obj1:any = new Object();

    obj1["b_acct_id"] = this.erpUser.b_acct_id;

    var resp:any = await this.liti.getlist(JSON.stringify(obj1));
    // // // console.log(resp);

    if (resp["error"] == false) {
      this.showvakaltArr = resp["data1"];

      // chdecking the user assign zone
      let obj:any = new Object();
      obj["b_acct_id"] = this.b_acct_id;
      obj["user_id"] = this.erpUser.user_id;
      this.spinner.show();
      var resp1:any = await this.liti.getassignzone(obj);
      let assignArr:any = [];
      if (resp1["error"] == false) {
        // // // console.log("this is the responce1---",resp1);
        this.spinner.show();
        resp1["data"].map(async(x:any) => {
          // zone code for assign
          assignArr.push(x["node_cd"]);
        });
        // // // console.log("This is the t b -- assignArr--[node_code]--",assignArr);
      } else {
        this.spinner.hide();
      }


      // -- end of checking the user assign zone

      // this is for the zone vp name----------l;
           // zone name & vp name

          //  assignArr.map(async (item)=>{
                  // vpZonename.push(item);
            // // // console.log("this is the function call ");

          //  var obj2:any = new Object();
          //  obj2["b_acct_id"] = this.erpUser.b_acct_id;
          //  obj2["zone"] = assignArr[0];
          //  //------ zone name
          //  obj2["zone_cd"] =assignArr[0];
          //  // obj2["case_id"] = this.caseId;

          //  var resp2 = await this.liti.getVpname(obj2);
          //  // // console.log("thsu us the vpname resp2 ---------", resp2);
          //  if (resp2["error"] == false) {

          //     if(resp2['data'].length==0){
          //     //   vpZonename.push({
          //     //     zone_vp: 'VP Name Not Found',
          //     //  zone_nm: 'Zone Name Not Found',
          //     // })
          //        this.vpname='VP Name Not Found';
          //        this.zone_name='Zone Name Not Found';
          //     }
          //    else
          //    {
          //     // vpZonename.push({

          //     //     zone_vp:   resp2["data"][0]["vp_name"],
          //     //  zone_nm: resp2["data1"][0]["zone_name"],
          //     // })
          //     // // console.log("this is the vp name ",resp2["data"][0]["vp_name"] );
          //     this.vpname= resp2["data"][0]["vp_name"];
          //     this.zone_name= resp2["data1"][0]["zone_name"];

          //     }

          //  }
        // });
        // // // console.log("this is the zone and vp of %%%%%%%%%%%5",this.vpname,this.zone_name);
      // ---------end of the asign code

      // var obj2:any = new Object();
      // obj2["b_acct_id"] = this.erpUser.b_acct_id;
      // obj2["zone"] = resp["data1"][0]["zone_cd"];
      // //------ zone name
      // obj2["zone_cd"] = this.showvakaltArr[0]["zone_cd"];
      // obj2["case_id"] = this.caseId;

      // var resp2 = await this.liti.getVpname(obj2);
      // // // console.log("thsu us the vpname resp2 ---------", resp2);
      // if (resp2["error"] == false) {

      //    if(resp2['data'].length==0){
      //     this.vpname = 'Leng-0';
      //     this.zone_name = 'No Zone';
      //    }
      //   else
      //   {  this.vpname = resp2["data"][0]["vp_name"];
      //      this.zone_name = resp2["data1"][0]["zone_name"];
      //    }
      // }
      // else {
      //   this.vpname = "Not Found";
      //   this.zone_name = "Not Found";
      // }

      //---------------------------------

      // // // console.log("this is the show vakaltArr",this.showvakaltArr);
      for (let i = 0; i < this.showvakaltArr.length; i++) {
          // get zone name and vp name

          var obj2:any = new Object();
          obj2["b_acct_id"] = this.erpUser.b_acct_id;
          obj2["zone"] = this.showvakaltArr[i]["zone_cd"];
          //------ zone name
          obj2["zone_cd"] =this.showvakaltArr[i]["zone_cd"];
          // obj2["case_id"] = this.caseId;

          var resp2:any = await this.liti.getVpname(obj2);
          // // // console.log("thsu us the vpname resp2 ---------", resp2);
          if (resp2["error"] == false) {

             if(resp2['data'].length==0){

                this.vpname='VP Name Not Found';
                this.zone_name='Zone Name Not Found';
             }
            else
            {

            //  // // console.log("this is the vp name ",resp2["data"][0]["vp_name"] );
             this.vpname= resp2["data"][0]["vp_name"];
             this.zone_name= resp2["data1"][0]["zone_name"];

             }

          }




          // end zone and vp name
        this.totalArr.push({
          comp_id: this.showvakaltArr[i]["comp_id"],
          case_id: this.showvakaltArr[i]["case_id"],
          zone_cd: this.showvakaltArr[i]["zone_cd"],
          zone_vp: this.vpname,
          zone_nm: this.zone_name,
          f_date: this.showvakaltArr[i]["filing_date"],
          h_date: this.showvakaltArr[i]["hearing_date"],
          c_status: this.showvakaltArr[i]["case_status"],
        });
      }

      // // // console.log(this.totalArr);
      this.arr4 = [];

      for (let i = 0; i < this.totalArr.length; i++) {
        this.arr4.push(this.totalArr[i]);
      }
      // // // console.log("this is the list data", this.arr4);
      // let obj:any = new Object();
      // obj["b_acct_id"] = this.b_acct_id;
      // obj["user_id"] = this.erpUser.user_id;
      // this.spinner.show();
      // var resp1:any = await this.liti.getassignzone(obj);
      // let assignArr = [];
      // if (resp1["error"] == false) {
      //   // // console.log("this is the responce1---",resp1);
      //   this.spinner.hide();
      //   resp1["data"].map((x) => {
      //     assignArr.push(x["node_cd"]);
      //   });
      //   // // console.log("This is the t b -- assignArr--[node_code]--",assignArr);
      // } else {
      //   this.spinner.hide();
      // }
      // // // console.log( this.arr4)
      let assinData :any= [];
      this.arr4.map((x:any) => {
        if (assignArr.includes(x["zone_cd"])) {
          assinData.push(x);
        }
      });
      this.spinner.hide();
      // // // console.log("this is the assignData push -----[Zone-Code]------",assinData)
      this.dataSource = new MatTableDataSource(assinData);
      // // // console.log(this.dataSource);

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  rout() {
    //this.router.navigate([{ outlets: { modal: 'icms/cause-list' }}])
    this.router.navigate(["icms/cause-list"]);
    $("#myModal4").modal("hide");
  }

  digitalsignbtn: boolean = false;
  noticeFlag :any= "F";
  noticeFlag1:any = "F";
  noticeFlag2:any;
  arr11:any = [];
  notArr :any= [];
  notice_forward_zone:any = "";
  element:any;
  objcase:any={}
  async gen_notice(element:any, modelstatus:any) {
    this.spinner.show();
    this.element = element;

    this.getNoticeWorkflow(element.case_id);
    this.totalnot = [];
     this.objcase = new Object();
    this.objcase = element;
    this.notice_forward_zone = this.objcase["zone_cd"];
    // // // console.log(this.objcase);
    // // // console.log("thsi is the notice forward obj", this.notice_forward_zone);
    this.case_idArr = this.objcase["case_id"];
    this.case_status = this.objcase["c_status"];
    // // // console.log(this.case_idArr, this.case_status);

    // // // console.log("Zone Name", element.zone_nm);
    var obj:any = new Object();
    obj["case_id"] = this.case_idArr;
    obj["b_acct_id"] = this.b_acct_id;
    var resp1:any = await this.liti.getData(obj);
    // === for the notice update
   // this.spinner.show();
    var resp2 = await this.liti.getNotice(obj);
 console.log("thie is the responce for thge forward button ", resp2);
    if (resp2["error"] == false) {

      this.showNotice = resp2["data"];

      for (let i = 0; i < this.showNotice.length; i++) {
        if (
          this.notArr.length == 0 ||
          this.notArr.length < this.showNotice.length
        ) {
          // if(this.notArr.length == 0 )
          this.showNotice[i]["forward_to"] = "NA";
          // this.showNotice[i]['sent_role']='NA'
        } else {
          this.showNotice[i]["forward_to"] = this.notArr[i]["forward_to"];
          // this.showNotice[i]['sent_role']=this.notArr[i]['sent_role']
        }

        for (let j = 0; j < this.role.length; j++) {
          if (this.role[j] == "VP") {
            this.digitalsignbtn = true;
          }
          if (this.showNotice[i]["flag"] == 1) {
            if (
              this.role[j] == this.staticRole[0] &&
              this.showNotice[i]["flag"] == 1
            ) {
              this.noticeFlag1 = "T";
            }
          } else {
            var arr11 = [];
            for (let k = 0; k < this.notArr.length; k++) {
              if (
                (this.role[j] == this.staticRole[1] ||
                  this.role[j] == this.staticRole[0]) &&
                this.erpUser.user_id == this.notArr[k]["forward_to"] &&
                this.showNotice[i]["flag"] == 0
              ) {
                this.noticeFlag2 = "UApr";
                //       break;
              }
            }
          }
        }
        if (
          (this.showNotice[i]["forward_to"] == "NA" &&
          this.showNotice[i]["flag"] == 1 ) &&
          this.showNotice[i]["created_user_id"] == this.erpUser.user_id
        )
        {
          this.totalnot.push({
            not_id: this.showNotice[i]["notice_id_f"],
            not_act: this.showNotice[i]["notice_act"],
            not_date: this.showNotice[i]["notice_date"],
            not_sec: this.showNotice[i]["section"],
            not_status: this.showNotice[i]["status"],
            not_btn: this.digitalsignbtn,
            noticeFlag1: this.noticeFlag1,
            noticeFlag2: this.noticeFlag2,
            forward_to: this.showNotice[i]["forward_to"],
            //sent_role:this.showNotice[i]['sent_role'],
            user_id: this.erpUser.user_id,
          });
        }
        else if(this.showNotice[i]["forw_from"] == this.erpUser.user_id ||this.showNotice[i]["update_user_id"] == this.erpUser.user_id  || this.showNotice[i]["forw_from"] == null)  {
          if (
        (this.showNotice[i]["update_user_id"] == this.erpUser.user_id || this.showNotice[i]["created_user_id"] == this.erpUser.user_id )&&
          this.showNotice[i]["flag"] == 0
        )
         {
          this.totalnot.push({
            not_id: this.showNotice[i]["notice_id_f"],
            not_act: this.showNotice[i]["notice_act"],
            not_date: this.showNotice[i]["notice_date"],
            not_sec: this.showNotice[i]["section"],
            not_status: this.showNotice[i]["status"],
            not_btn: this.digitalsignbtn,
            noticeFlag1: this.noticeFlag1,
            noticeFlag2: this.noticeFlag2,
            forward_to: this.showNotice[i]["forward_to"],
            //sent_role:this.showNotice[i]['sent_role'],
            user_id: this.erpUser.user_id,
          });
        }
      }
      }

     // // console.log("this si the datasource of the ======", this.totalnot);

      this.dataSource4 = new MatTableDataSource(this.totalnot);

      this.dataSource4.sort = this.sort;

      if (resp1["error"] == false) {
        this.noticeArr = resp1["data"];
       // // console.log("This is the notice Arr--",this.noticeArr);
        //----------  notice under act code value------------------
        obj["notice_code"] = this.noticeArr[0]["notice_under_act"];
        var resp3 :any= await this.liti.getActName(obj);
        if (resp3["error"] == false) {
          this.noticeObj["notice_under_act"] = resp3["data"][0]["value"];
        } else {
          this.noticeObj["notice_under_act"] = "Code Value Not Define";
        }

        //--------------------------------
        this.noticeArr1 = resp1["data1"];
        // // // console.log("this is the sectino array", this.noticeArr1);
  this.spinner.hide();
        this.noticeArr.push(this.objcase);
        this.noticeArr.push(element.zone_nm);

         // filing date --
         this.case_f_date=this.noticeArr[1]["f_date"];

      //  console.log(this.noticeArr);
        //  this.noticeObj['notice_under_act']=this.noticeArr[0]['notice_under_act']
        this.noticeObj["owner_name"] = this.noticeArr[0]["owner_name"];
        // owner no
        this.noticeObj["mobile_no"] = this.noticeArr[0]["owner_mobile"];
        // // // console.log("second time check ", element.zone_nm);
        this.noticeObj["zone_name"] = element.zone_nm;
        // // // console.log("second time check ", this.noticeObj);
        // // // console.log("this is the notice object of the emp", this.noticeObj);
        // // console.log("this is hte date check ===", this.noticeArr);
        if (this.noticeArr[1]["f_date"] == "NA") {

          this.noticeObj['f_date']='';
          this.noticeObj['time']='';
          this.notice_before = true;
        } else {
          this.notice_before = false;
        }

        if (modelstatus != "ts") {
          $("#myModal9").modal("show");
           this.spinner.hide();
        }
      }
      if (modelstatus != "ts") {
        $("#myModal9").modal("show");
        this.spinner.hide();
      }
    }
    this.spinner.hide();
  }

  async view_digital_sign(element:any) {
    this.imgURL="";
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = this.case_idArr;
    obj["digital"] = "Digital_Signature";
    // // // console.log(element);
    obj["notice_id"] = element.not_id;
    var resp:any = await this.liti.getUploadedFiles(obj);
    // // // console.log(resp);
    var arr = [];
    if (resp["error"] == false) {
      arr = resp.data;
      this.dataSource10 = new MatTableDataSource(arr);
      // // // console.log(arr);

      this.dataSource10.sort = this.sort;
    } else {
    }

    // $('.nav-tabs a[href="#tab-2"]').tab('show');
    // await this.getphotodetail(4);
    // // // console.log(this.complaint_image.length)
    // //this.imageflag = false
    // if (this.complaint_image.length > 0) {
    //   $('#staticBackdrop1').modal('show');
    //   return
    // }
    // else {
    //   Swal.fire('info', "No image found", 'info');
    // }

    // $('.nav-tabs a[href="#tab-2"]').tab('show');

    $("#staticBackdrop1").modal("show");
  }

  aviTotal2:any = [];
  async generate_date(noticeArr:any, noticeObj:any) {
    $("#avinashSection").val("");
    this.aviTotal2 = [];
    noticeObj["section_cd"] = "";
    this.avitotal = [];
    //  // // console.log("this is thew notice Arr",noticeArr);
    //  // // console.log("this is thew notice object",noticeObj);
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = noticeArr[1]["case_id"];
    let not_sec:any = [];
    let notice_sec:any = this.noticeArr1;

    let resp:any = await this.liti.aviSection(obj);

    if (resp["error"] == false) {
      // // // console.log("length", resp["data"].length - 1);
      if (resp["data"].length - 1 == -1) {
        notice_sec.map((x:any) => {
          if (!not_sec.includes(x["section_cd"])) {
            this.noticeArr1.push(x);
          }
        });
      } else {
        for (let i = 0; i <= resp["data"].length - 1; i++) {
          let x;
          x = resp["data"][i]["not_sec"];
          this.avitotal.push(x);
          // not_sec.push({
          //      sw: resp['data'][i]['not_sec'] })

          //  this.avitotal.push({
          //   not_sec: resp['data'][i]['not_sec'],
          //                    } );
          //  avitotal['not_sec']=resp['data'][i]['not_sec'];
        }

        // let not_sec=[]
        // this.totalnot
        // this.avitotal.map(x=>{
        //   not_sec.push(x['not_sec'])
        // }
        //   )

        // let notice_sec=this.noticeArr1

        this.noticeArr1 = [];
        notice_sec.map((x:any) => {
          if (!this.avitotal.includes(x["section_cd"])) {
            this.noticeArr1.push(x);
          }
        });
      }
    }
    // // // console.log("this is the notice Array 1----", this.noticeArr1);
    const unique = [...new Set(this.noticeArr1.map((item:any) => item.section_cd))];

    if (unique.length == 0) {
      Swal.fire("Info", "All Notice Created For This Case..!", "warning");
      return;
    } else {
      // // // console.log("this is the unique---", unique);
      // this.noticeArr1=unique
      this.aviTotal2 = unique;
      // // // console.log("this is the unique2---", this.aviTotal2);
      $("#myModal2").modal("show");
    }
  }
  uploader3:any = [];
  addline() {
    this.uploader3.push({ Attachment: "" });
  }
  uploader4:any = [];
  addline1() {
    this.uploader4.push({ UploadFile1: "" });
  }
  uploader5 :any= [];
  addline2() {
    this.uploader5.push({ Attachment: "" });
  }
  imgURL3:any;
  async view_document1(shownotesheetobj:any, i:any) {
    this.showdocument = true;
    // // // console.log(shownotesheetobj, i);
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = shownotesheetobj[i]["case_id"];
    obj["doc_id"] = shownotesheetobj[i]["doc_id"];
    obj["filename"] = shownotesheetobj[i]["document_type_code"];

    // // // console.log(obj);
    // // // console.log(obj);
    var resp:any = await this.liti.getview_document(obj);
    // // // console.log(resp);
    if (resp) {
      var docname = obj["filename"];
      this.documentname = docname;
      // // // console.log(this.documentname);

      var ext = docname.split(".");

      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL3 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        //  console.log(this.imgURL3);
      } else {
        let file = new Blob([resp], { type: "application/pdf" });

        var fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    }
  }
  caselist3 :any= {};
  caseId:any;

  // availability(){
  //   this.router.navigate(['../cause-list'])
  // }
  s : any;
  async note_sheet(element:any, s:any) {
    this.selectedFile = [];
    this.imgURL3=""
    this.doc_name = [];
    $("#avinotesheet").val("");
    this.selectedFile = [];
    this.doc_name = [];

    this.caselist['text_notesheet']=""
    this.caselist = element;
    this.caselist3 = element;
    this.caseId = element.case_id;
    // // // console.log(this.caselist);
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = this.caseId;
    // // // console.log(obj);
    var resp:any = await this.liti.getnote_sheet(obj);
    // // console.log("this is the case notesheet data", resp);
    if (resp["error"] == false) {
      this.shownotesheetobj = resp["data"];

      // // console.log(
      //   "this is the case notesheet data in object",
      //   this.shownotesheetobj
      // );

      for (let i = 0; i < this.shownotesheetobj.length; i++) {
        this.shownotesheetobj[i]["time"] = moment(
          this.shownotesheetobj[i]["create_timestamp"]
        ).format("YYYY-MM-DD hh:mm:ss A ");
      }
    }

    if (s != "ts") {
      $("#myModal1").modal("show");
    }
    // $('#myModal1').modal('show');

    // this.shownotesheetobj['time']=moment(this.shownotesheetobj[0]['create_timestamp']).format("YYYY-MM-DD hh:mm:ss A ")
    // // console.log(moment(this.shownotesheetobj[0]['create_timestamp']).utc().format('DD/MM/YYYY'))
    // // console.log(moment(this.shownotesheetobj[0]['create_timestamp']).format("YYYY-MM-DD hh:mm:ss A "))
    // // console.log(this.shownotesheetobj['time'])
    //await this.submit_notesheet(this.caselist,this.Username)
  }

  sign:any = [];
  async digitalSignal(element:any) {
    $("#aviDigitalSig").val("");
    this.selectedFile = [];
    this.doc_name = [];
    var obj :any = new Object();
    this.caselist1 = {};
    // // console.log("this is the element", element);
    obj["case_id"] = this.case_idArr;
    obj["b_acct_id"] = this.b_acct_id;
    obj["notice_id"] = element.not_id;
    obj["rol_nm"] = this.Username;
    // // console.log("this i hte  obj ", obj);
    this.sign["data"] = obj;
    // // console.log("this siht the sign arrayv ", this.sign);
    $("#myModal9").modal("hide");
    $("#myModalup_sig").modal("show");
  }
  // await this.submit_notesheet(this.caselist)

  async upload_sig() {
    // // console.log("this si hte", this.sign["data"]);

    $("#myModalup_sig").modal("show");
  }
  async submit_sig(caselist1:any) {
    // // console.log("thi s is hte submit sign ", caselist1);
    var obj :any = new Object();
    // // console.log();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = this.sign["data"]["case_id"];
    obj["role_nm"] = this.sign["data"]["rol_nm"];
    obj["notice_id"] = this.sign["data"]["notice_id"];
    obj["text_notesheet"] = caselist1["text_notesheet"];
    obj["notice_id"] = this.sign["data"]["notice_id"];
    // // console.log("this is the notesheet data", obj);

    // // console.log("Form Data for case notesheet: ", obj);
    this.formData = new FormData();

    // // console.log("Sel : ", this.selectedFile);
    for (let i = 0; i < this.selectedFile.length; i++) {
      this.formData.append("image", this.selectedFile[i]);
    }
    // // console.log("this is the form data", this.formData);
    // // console.log(this.uploader);
    obj["doc_name"] = this.doc_name;
    obj["doc_local_name"] = "Digital_Signature";
    obj["relation"] = "VP";
    obj["create_user_id"] = this.erpUser.user_id;
    obj["case_stat"]=this.case_status
    this.formData.append("data", JSON.stringify(obj));
    let resp:any = await this.liti.insertDigital(this.formData);
    // // console.log("thsi si th eresp", resp);
    if (resp["error"] == false) {
      if(this.case_status=="GENERATED")
      {
        this.totalArr = [];
        this.arr4 = [];
        await this.getNotification();
      }
      Swal.fire("Success", `Upload Digital Signature Successfully `, "success");
      this.doc_name = [];
      this.selectedFile = [];
      this.formData = new FormData();
      this.caselist1 = {};
    } else {
      Swal.fire(
        "Error",
        "Error Occurred While Upload Digital Signature ",
        "error"
      );
    }
  }

  async view_note() {
    $("#myModal11").modal("show");
  }

  async submit_notice(noticeArr:any, noticeObj:any) {
    // console.log("submit button ");
  this.spinner.show();
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["owner_name"] = noticeObj["owner_name"];
    obj["own_mono"] = this.noticeObj["mobile_no"];
    obj["notice_under_act"] = noticeObj["notice_under_act"];
    obj["created_user_id"] = this.erpUser.user_id;
    obj["sections"] = noticeObj["section_cd"];
    obj["case_id"] = noticeArr[1]["case_id"];

    obj["zone_cd"] = noticeArr[1]["zone_cd"];
    obj["text"] = this.htmlContent;


    if (this.noticeArr[1]["f_date"] == "NA") {
      this.noticeArr[1]["f_date"] = new Date();
     // console.log(this.noticeArr[1]["f_date"]);
      obj["date"] = this.noticeArr[1]["f_date"].toISOString();
      // // console.log(obj["date"]);
      obj["avi_date"] = moment(obj["date"]).format("DD-MM-YYYY");
      obj["date"]= moment(obj["date"]).format("YYYY-MM-DD");
      obj["avi_first_h_date"] = this.comfortDateAvi(noticeObj["date"]);
      obj["first_h_date"]=noticeObj["date"]
      obj["h_time"] = noticeObj["time"];
      // // console.log("this is the insertTotalnotice1", obj);
      var resp:any = await this.liti.insertTotalnotice1(obj);
      // // console.log("this is the responce of aviuat==", resp);
      if (resp["error"] == false) {
        this.totalArr = [];
        this.arr4 = [];

        var resp2 = await this.liti.getNotice(obj); //  for modal9
        // // console.log("thie is the responce", resp2);
        if (resp2["error"] == false) {
          // // console.log("thie is the responce",resp2)
          this.showNotice = [];
          this.totalnot = [];

  //------------------- start ---------------------
          // this.showNotice = resp2["data"];

      /*    // for (let i = 0; i < this.showNotice.length; i++) {
          //   for (let j = 0; j < this.role.length; j++) {
          //     if (this.role[j] == "VP") {
          //       this.digitalsignbtn = true;
          //     }
          //   }
          //   this.totalnot.push({
          //     not_id: this.showNotice[i]["notice_id_f"],
          //     not_act: this.showNotice[i]["notice_act"],
          //     not_date: this.showNotice[i]["notice_date"],
          //     not_sec: this.showNotice[i]["section"],
          //     not_status: this.showNotice[i]["status"],
          //     not_btn: this.digitalsignbtn,
          //   });
          // }

          // // // console.log("this si the datasource of the ======", this.totalnot);

          // this.dataSource4 = new MatTableDataSource(this.totalnot);
          // //this.dataSource.paginator = this.paginator;
          // this.dataSource4.sort = this.sort;
   */
          this.showNotice = resp2["data"];

          for (let i = 0; i < this.showNotice.length; i++) {
            if (
              this.notArr.length == 0 ||
              this.notArr.length < this.showNotice.length
            ) {
              // if(this.notArr.length == 0 )
              this.showNotice[i]["forward_to"] = "NA";
              // this.showNotice[i]['sent_role']='NA'
            } else {
              this.showNotice[i]["forward_to"] = this.notArr[i]["forward_to"];
              // this.showNotice[i]['sent_role']=this.notArr[i]['sent_role']
            }

            for (let j = 0; j < this.role.length; j++) {
              if (this.role[j] == "VP") {
                this.digitalsignbtn = true;
              }
              if (this.showNotice[i]["flag"] == 1) {
                if (
                  this.role[j] == this.staticRole[0] &&
                  this.showNotice[i]["flag"] == 1
                ) {
                  this.noticeFlag1 = "T";
                }
              } else {
                var arr11 = [];
                for (let k = 0; k < this.notArr.length; k++) {
                  if (
                    (this.role[j] == this.staticRole[1] ||
                      this.role[j] == this.staticRole[0]) &&
                    this.erpUser.user_id == this.notArr[k]["forward_to"] &&
                    this.showNotice[i]["flag"] == 0
                  ) {
                    this.noticeFlag2 = "UApr";
                    //       break;
                  }
                }
              }
            }
            if (
              (this.showNotice[i]["forward_to"] == "NA" &&
              this.showNotice[i]["flag"] == 1 ) &&
              this.showNotice[i]["created_user_id"] == this.erpUser.user_id
            )
            {
              this.totalnot.push({
                not_id: this.showNotice[i]["notice_id_f"],
                not_act: this.showNotice[i]["notice_act"],
                not_date: this.showNotice[i]["notice_date"],
                not_sec: this.showNotice[i]["section"],
                not_status: this.showNotice[i]["status"],
                not_btn: this.digitalsignbtn,
                noticeFlag1: this.noticeFlag1,
                noticeFlag2: this.noticeFlag2,
                forward_to: this.showNotice[i]["forward_to"],
                //sent_role:this.showNotice[i]['sent_role'],
                user_id: this.erpUser.user_id,
              });
            }
            else if(this.showNotice[i]["forw_from"] == this.erpUser.user_id ||this.showNotice[i]["update_user_id"] == this.erpUser.user_id  || this.showNotice[i]["forw_from"] == null)  {
              if (
            (this.showNotice[i]["update_user_id"] == this.erpUser.user_id || this.showNotice[i]["created_user_id"] == this.erpUser.user_id )&&
              this.showNotice[i]["flag"] == 0
            )
             {
              this.totalnot.push({
                not_id: this.showNotice[i]["notice_id_f"],
                not_act: this.showNotice[i]["notice_act"],
                not_date: this.showNotice[i]["notice_date"],
                not_sec: this.showNotice[i]["section"],
                not_status: this.showNotice[i]["status"],
                not_btn: this.digitalsignbtn,
                noticeFlag1: this.noticeFlag1,
                noticeFlag2: this.noticeFlag2,
                forward_to: this.showNotice[i]["forward_to"],
                //sent_role:this.showNotice[i]['sent_role'],
                user_id: this.erpUser.user_id,
              });
            }
          }
          }

          // // console.log("this si the datasource of the ======", this.totalnot);

          this.dataSource4 = new MatTableDataSource(this.totalnot);

          this.dataSource4.sort = this.sort;
          // ----------------------------end -------------------
          this.case_f_date= obj["avi_date"];

        await this.getNotification();
        this.notice_before=false;
        this.spinner.hide();
        Swal.fire("Success", "Successfully Notice Generated", "success");
      }

    }
    }
     else if (this.noticeArr[1]["f_date"] != "NA") {
      this.noticeArr[1]["f_date"] = new Date();
      // // console.log(this.noticeArr[1]["date"]);
      obj["date"] = this.noticeArr[1]["f_date"].toISOString();
      // // console.log(obj["date"]);
      obj["date"] = moment(obj["date"]).format("DD-MM-YYYY");
      // // console.log(obj["date"]);

      // // console.log("this is the data find obj", obj);
      var resp:any = await this.liti.inserttotalnotice(obj);
      // // console.log("htis is the responce of the avi", resp);
      if (resp["error"] == false) {
        this.totalArr = [];
        this.arr4 = [];
        //  this is for without cloasing the refrace



        var resp2 = await this.liti.getNotice(obj); //  for modal9
        // // console.log("thie is the responce", resp2);
        if (resp2["error"] == false) {
          // // console.log("thie is the responce",resp2)
          this.showNotice = [];
          this.totalnot = [];

        this.showNotice = resp2["data"];

          for (let i = 0; i < this.showNotice.length; i++) {
            if (
              this.notArr.length == 0 ||
              this.notArr.length < this.showNotice.length
            ) {
              // if(this.notArr.length == 0 )
              this.showNotice[i]["forward_to"] = "NA";
              // this.showNotice[i]['sent_role']='NA'
            } else {
              this.showNotice[i]["forward_to"] = this.notArr[i]["forward_to"];
              // this.showNotice[i]['sent_role']=this.notArr[i]['sent_role']
            }

            for (let j = 0; j < this.role.length; j++) {
              if (this.role[j] == "VP") {
                this.digitalsignbtn = true;
              }
              if (this.showNotice[i]["flag"] == 1) {
                if (
                  this.role[j] == this.staticRole[0] &&
                  this.showNotice[i]["flag"] == 1
                ) {
                  this.noticeFlag1 = "T";
                }
              } else {
                var arr11 = [];
                for (let k = 0; k < this.notArr.length; k++) {
                  if (
                    (this.role[j] == this.staticRole[1] ||
                      this.role[j] == this.staticRole[0]) &&
                    this.erpUser.user_id == this.notArr[k]["forward_to"] &&
                    this.showNotice[i]["flag"] == 0
                  ) {
                    this.noticeFlag2 = "UApr";
                    //       break;
                  }
                }
              }
            }
            if (
              (this.showNotice[i]["forward_to"] == "NA" &&
              this.showNotice[i]["flag"] == 1 ) &&
              this.showNotice[i]["created_user_id"] == this.erpUser.user_id
            )
            {
              this.totalnot.push({
                not_id: this.showNotice[i]["notice_id_f"],
                not_act: this.showNotice[i]["notice_act"],
                not_date: this.showNotice[i]["notice_date"],
                not_sec: this.showNotice[i]["section"],
                not_status: this.showNotice[i]["status"],
                not_btn: this.digitalsignbtn,
                noticeFlag1: this.noticeFlag1,
                noticeFlag2: this.noticeFlag2,
                forward_to: this.showNotice[i]["forward_to"],
                //sent_role:this.showNotice[i]['sent_role'],
                user_id: this.erpUser.user_id,
              });
            }
            else if(this.showNotice[i]["forw_from"] == this.erpUser.user_id ||this.showNotice[i]["update_user_id"] == this.erpUser.user_id  || this.showNotice[i]["forw_from"] == null)  {
              if (
            (this.showNotice[i]["update_user_id"] == this.erpUser.user_id || this.showNotice[i]["created_user_id"] == this.erpUser.user_id )&&
              this.showNotice[i]["flag"] == 0
            )
             {
              this.totalnot.push({
                not_id: this.showNotice[i]["notice_id_f"],
                not_act: this.showNotice[i]["notice_act"],
                not_date: this.showNotice[i]["notice_date"],
                not_sec: this.showNotice[i]["section"],
                not_status: this.showNotice[i]["status"],
                not_btn: this.digitalsignbtn,
                noticeFlag1: this.noticeFlag1,
                noticeFlag2: this.noticeFlag2,
                forward_to: this.showNotice[i]["forward_to"],
                //sent_role:this.showNotice[i]['sent_role'],
                user_id: this.erpUser.user_id,
              });
            }
          }
          }

          // // console.log("this si the datasource of the ======", this.totalnot);

          this.dataSource4 = new MatTableDataSource(this.totalnot);

          this.dataSource4.sort = this.sort;
         await this.getNotification();
         this.spinner.hide();
          Swal.fire("Success", "Successfully  Notice Generated", "success");
        }

        // end
      } else {
        this.spinner.hide();
        Swal.fire("Alert", "Some Technical Issue In Generating Notice",'error');
      }
    }
  }
  penalty(element:any) {
    this.selectedFile = [];
    this.doc_name = [];
    $("#avipenality").val("");
    this.penaltyObj = element;
    this.penaltyObj['pen_impos_date']='';
    this.penaltyObj['pen_amut']='';
    this.penaltyObj['last_date']='';
    this.penaltyObj['reason_for_pen']=''
    // // console.log(this.penaltyObj);
    $("#myModal7").modal("show");
  }
  async submit_pen(penaltyObj:any) {
    if (
      !(
        penaltyObj["last_date"] &&
        penaltyObj["pen_amut"] &&
        penaltyObj["pen_impos_date"]
      )
    ) {
      Swal.fire(
        "Info",
        "Firstly Fill Penality last date, Penality Amount, Penality Impostion Date",
        "info"
      );
      return;
    }
    else if( penaltyObj['last_date']  <= penaltyObj['pen_impos_date'])
    {
      Swal.fire(
        "Info",
        "Last Date for Payment Be Latest From Penality Impostion Date",
        "info"
      );
 return;
    }
    this.spinner.show();
    // // console.log("this is the submit button main value", penaltyObj);
    var obj :any = new Object();
    var obj1:any = new Object();
    obj["case_id"] = penaltyObj["case_id"];
    obj["b_acct_id"] = this.b_acct_id;
    obj["pen_impos_date"] = penaltyObj["pen_impos_date"];
    obj["pen_amut"] = penaltyObj["pen_amut"];
    obj["last_date"] = penaltyObj["last_date"];
    obj["reason_for_pen"] = penaltyObj["reason_for_pen"];
    obj["imposer_name"] = this.Username;
    // // console.log(obj);
  obj1["case_id"]= penaltyObj["case_id"];
  obj1["b_acct_id"]=this.b_acct_id;
  obj1["amount"]= penaltyObj["pen_amut"];
  obj1["comp_id"]= penaltyObj["comp_id"];

    // // console.log("Form Data : ", obj);
    // // console.log("object 2  : ", obj1);
    this.formData = new FormData();

    // // console.log("Sel : ", this.selectedFile);
    for (let i = 0; i < this.selectedFile.length; i++) {
      this.formData.append("image", this.selectedFile[i]);
    }
    // // console.log("this is the form data", this.formData);
    // // console.log(this.uploader);
    obj["doc_name"] = this.doc_name;
    obj["doc_local_name"] = "Penality";
    obj["relation"] = "Penality";
    obj["create_user_id"] = this.erpUser.user_id;
    this.formData.append("data", JSON.stringify(obj));
    let resp:any = await this.liti.submitReportSub1(this.formData);
    if (resp["error"] == false) {
   let assignmentInsert :any= await this.liti.insertAssi(obj1);
  //  // console.log("this si hte resp og the arrangement", assignmentInsert);
     if(assignmentInsert['error']==false){
  this.spinner.hide();
       Swal.fire(
         "Success",
         `Added Penality and Uploaded Document Successfully `,
         "success"
         );
        }
      this.doc_name = [];
      this.selectedFile = [];
      this.formData = new FormData();
    } else {
      this.spinner.hide();
      Swal.fire(
        "Error",
        "Error Occurred While Adding Penality and Uploaded Document",
        "error"
      );
    }
  }
  async case_details(element:any) {
   // console.log(element);

   if(element.f_date=="NA" || element.h_date=="NA")
   {
    Swal.fire('Alert','Firstly Generate The Notice','warning');
    return;
   }
  //  else{
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = element.case_id;
    // // console.log(obj);
    var resp:any = await this.liti.getallcasedetails(obj);
  // // console.log(resp);
    if (resp["error"] == false) {
      this.allcasedetailObj = resp["data"];
      this.allcasedetailObj["case_id"] = element.case_id;
      this.allcasedetailObj["cs_zone_vp"] = element.zone_vp;
      this.allcasedetailObj["zone"] = element.zone_nm;

      //this.allcasedetailObj["next_h_date"]=this.allcasedetailObj[0]["max(h.next_h_date)"]
      // this.allcasedetailObj["last_h_date"]=this.allcasedetailObj[0]["max(h.old_h_date)"]
      // this.allcasedetailObj["filling_date"]=moment(this.allcasedetailObj["filling_date"]).format('DD-MM-YYYY')
      // this.allcasedetailObj[0]["complain_date"]=moment(this.allcasedetailObj[0]["complain_date"]).format('DD-MM-YYYY')
      // this.allcasedetailObj[0].complain_date=moment(this.allcasedetailObj[0].complain_date).format('DD-MM-YYYY')

      this.allcasedetailObj["next_h_date"] =
        this.allcasedetailObj[0]["next_h_date"];
      this.allcasedetailObj["filling_date"] =this.allcasedetailObj[0]["filling_date"]

     // // console.log("filing date--", this.allcasedetailObj["filling_date"],this.allcasedetailObj[0]["filling_date"])
      this.allcasedetailObj["complain_date"] =
        this.allcasedetailObj[0]["complain_date"];

      this.allcasedetailObj["old_h_date_new"] =
        this.allcasedetailObj[0]["old_h_date_new"];

      // // console.log(this.allcasedetailObj);
    }
    $("#myModal6").modal("show");
  // }
  }
  async updatehearing(element:any) {
    this.objhearing = element;
    if (this.objhearing["f_date"] == "NA") {
      Swal.fire("Alert", "Firstly Generate the Notice", "warning");
    } else {
      // document.getElementById("avinash").nodeValue="";
      $("#avinash").val("");
      this.selectedFile = [];
      this.doc_name = [];
      //  this.reset();
      this.objhearing["next_h_date"] = "";
      this.objhearing["next_h_t"] = "";
      this.objhearing["note_about"] = "";
      //  this.formData = new FormData();
      this.uphb=false;

      $("#myModal4").modal("show");
      // // console.log(this.objhearing);
      this.case_idArr = this.objhearing["case_id"];
      // // console.log(this.case_idArr);
      var obj :any = new Object();
      obj["case_id"] = this.case_idArr;
      obj["b_acct_id"] = this.b_acct_id;
      var resp1:any = await this.liti.getHearing(obj);
      // // console.log("this is the hearing button api resp==", resp1);
      if (resp1["error"] == false) {
        if (resp1["data"][0]["next_h_date"] != "0000-00-00") {
          this.objhearing["last_h_date"] = resp1["data"][0]["next_h_date"];

          this.last_h_flag = true;
          this.next_h_d_flag = true;
          this.next_h_t_flag = true;
        } else {
          Swal.fire(
            "Alert",
            "You have not entered the Reply Date while Creating the Notice..!",
            "error"
          );
        }
      }
    }
  }

  // update defendent

  async updatedefendent(element:any) {
    this.defElement = element;
    $("#avinashDef").val("");
    this.selectedFile = [];
    this.doc_name = [];

    // // console.log("thgis si the element", this.defElement);
    var obj1:any = new Object();
    obj1["case_id"] = this.defElement["case_id"];
    obj1["b_acct_id"] = this.b_acct_id;
    var resp:any = await this.liti.checkDefDetail(obj1);
    // // console.log("this is hte check defdetail =======", resp);
    if (resp["error"] == false && resp["data"].length > 0) {
      this.defUpdate = true;
      this.defobj["case_id"] = this.defElement["case_id"];
      this.defobj["def_name"] = resp["data"][0]["name"];
      this.defobj["father_name"] = resp["data"][0]["f_name"];
      this.defobj["dob"] = resp["data"][0]["dob"];
      this.defobj["def_mob"] = resp["data"][0]["mob_no"];
      this.defobj["def_email"] = resp["data"][0]["email"];
      this.defobj["id_proof"] = resp["data"][0]["proof_id"];
      // // console.log("this is resp when 1--", resp);
      $("#myModal5").modal("show");
      // Swal.fire('info','done','info');
    } else {
      this.defUpdate = false;
      this.defobj["case_id"] = this.defElement["case_id"];
      this.defobj["b_acct_id"] = this.b_acct_id;
      var resp1:any = await this.liti.getDefDetail(this.defobj);
      // // console.log("this si the resp", resp1);
      if (resp1["error"] == false) {
        this.defElement = [];
        this.defElement = resp1["data"];
        this.defobj["def_name"] = this.defElement[0]["owner_name"];
        this.defobj["def_mob"] = this.defElement[0]["owner_number"];
        // // console.log("this si th resp innner", this.defElement);

        $("#myModal5").modal("show");
      } else {
        Swal.fire("Info", "   Server Error", "info");
      }
    }
  }
  //  defendent submit
  //  defendent submit
  //---------------------
  getAge(obj:any)
  {
      var today = new Date();
      var birthDate = new Date(obj);

      var age = today.getFullYear() - birthDate.getFullYear();

      var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--;

      }

      return age;
  }




  //--------------------------
  async UPDATE_D(defobj:any) {
    var obj :any = new Object();
    var obj2:any = new Object();
    obj = defobj;
    if (
     ( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.defobj["def_email"]
      )||(defobj['id_proof']==null || defobj['id_proof']==undefined ))
    ) {
      Swal.fire("Info", "Enter Valid Email And Select At Least One Proof ID", "info");
      return;
    } else {
      obj["b_acct_id"] = this.b_acct_id;
      if (this.defUpdate == false) {
        obj2 = this.defElement[0];
        obj["def_landmark"] = obj2["landmark"];
        obj["def_state"] = obj2["state"];
        obj["def_prop_add"] = obj2["property_address"];
        obj["def_pin_code"] = obj2["pin_code"];
        obj["def_long"] = obj2["longitude"];
        obj["def_lat"] = obj2["latitude"];
        obj["def_dist"] = obj2["dist"];

        // // console.log(obj2)
        // // console.log(obj);

        // // console.log("Form Data : ", obj);
        this.formData = new FormData();

        // // console.log("Sel : ", this.selectedFile);
        for (let i = 0; i < this.selectedFile.length; i++) {
          this.formData.append("image", this.selectedFile[i]);
        }

        // // console.log(this.uploader);
        obj["doc_name"] = this.doc_name;
        obj["doc_local_name"] = "UPDATE DEFENDANT";
        obj["relation"] = "UPDATE DEFENDANT";
        obj["create_user_id"] = this.erpUser.user_id;
        this.formData.append("data", JSON.stringify(obj));
        // // console.log("this si the form data ", this.formData);
        var resp:any = await this.liti.insertDefendant(this.formData);
        if (resp["error"] == false) {
          Swal.fire(
            "Success",
            `Added Penality and Uploaded Document Successfully `,
            "success"
          );
          this.doc_name = [];
          this.selectedFile = [];
          obj = {};
          this.formData = new FormData();
          //this.list();
        } else {
          Swal.fire(
            "Error",
            "Error Occurred While Updating and Upload Document",
            "error"
          );
        }
      } else {
        // // console.log("ths-----------------------------------", defobj);

        this.formData = new FormData();

      let aviAge=  this.getAge(defobj['dob']);
      if(aviAge < 18){
  Swal.fire("Error",'Age Is Under 18..!','error');
  return ;
      }
    // else{
    //   Swal.fire('Info','Success You Got It','info');
    // }
        // // console.log("Sel : ", this.selectedFile);
        for (let i = 0; i < this.selectedFile.length; i++) {
          this.formData.append("image", this.selectedFile[i]);
        }

        // // console.log(this.uploader);
        obj["doc_name"] = this.doc_name;
        obj["doc_local_name"] = "UPDATE DEFENDANT";
        obj["relation"] = "UPDATE DEFENDANT";
        obj["create_user_id"] = this.erpUser.user_id;
        this.formData.append("data", JSON.stringify(obj));
        // // console.log("this si the form data ", this.formData);
        var resp:any = await this.liti.UpdateDefData(this.formData);
        if (resp["error"] == false) {
          Swal.fire("Success", "Successfully Updated ...!", "success");
          this.doc_name = [];
          this.selectedFile = [];
          obj = {};
          this.formData = new FormData();
          //this.list();
        } else {
          Swal.fire(
            "Error",
            "Error Occurred While Updating and Upload Document",
            "error"
          );
        }
      }
    }
  }

  async docs(element:any, idt:any) {
    // // console.log(element);
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = element.case_id;
    // // console.log(obj);
    var resp:any = await this.liti.getalldoc(obj);
    // // console.log("this is all document ", resp);

    this.alldocarr = [];

    if (resp["error"] == false) {
      //   this.spinner.show();
      //   let resp1 = await this.liti.getZone(obj)
      //     if(resp1['error']==false){
      //       let cd=[]
      //       resp1['data'].map(x=>{
      //         cd.push(x['zone'])
      //       })
      //       obj['code_cd']=cd
      //       // console.log(obj['code_cd'])
      //       this.spinner.hide()
      //     }else{
      //       this.spinner.hide()
      //     }

      // this.defendant_apprdata['module_cd']='ICMS'
      // this.defendant_apprdata['doc_type']='DEFENDANT DOCS'
      // this.defendant_apprdata['node_cd']= obj['code_cd']
      // await this.ApprovalUserService.getWorkflowloguser(this.erpUser,  this.defendant_apprdata)
      this.alldoc = resp["data"];
      // // console.log(this.alldoc);
      this.alldocarr = [];
      for (let i = 0; i < this.alldoc.length; i++) {
        this.alldocarr.push(this.alldoc[i]);
      }


      this.dataSource2 = new MatTableDataSource(this.alldocarr);
      // // console.log("docs/evidence", this.dataSource2);

      this.dataSource2.sort = this.sort;
    }
    await this.getWorkflowlogforappr();
    if (idt != "ts") {
      $("#myModal10").modal("show");
    }
  }
  aviDate: boolean=false;
  aviCheck:boolean=true;

aviUploadOrder()
{
  this.orderupload['comp_time_date']='';
  this.orderupload['no_of_days']='';
  if(this.aviCheck== true)
  {
    this.orderupload['no_of_days']='Null';
  //  // console.log("under the check box if part",this.orderupload['no_of_days'])
    this.aviDate=true;
  }
  else
  {
    this.orderupload['comp_time_date']='Null';
    this.aviDate=false;
  }

}

  async order_upload(element:any) {
    this.court_order_id=false;
    this.selectedFile = [];
    this.doc_name = [];
    //--reset data
    this.orderupload['comp_time_date']=''
    this.orderupload['no_of_days']=''
    this.orderupload['court_order_type']=''
    this.orderupload['court_order_date']=''
    this.orderupload['court_or_sumry']=''
    // this.upload_data = true;

    //--
    $("#aviuploadorder").val("");
    $("#myModal8").modal("show");
    // // console.log(element);
    this.orderupload = element;
    // this.aviUploadOrder();
    this.orderupload["b_acct_id"] = this.b_acct_id;
    this.orderupload["case_id"] = element.case_id;
    // // console.log(this.orderupload);
    var resp:any = await this.liti.getorderuploaddata(this.orderupload);
    // // console.log(resp);
    if (resp["error"] == false) {
      this.orderuploadObj = resp["data1"];
      let obj2:any ={}
      obj2['zone_cd']=this.orderuploadObj[0]['zone_cd']
      obj2['b_acct_id']=this.b_acct_id
      var resp3 :any= await this.liti.getVpname(obj2);
      // // console.log(resp3)
      if(resp3['error']==false || resp3["data1"].length >0 ){
      let zoneNameArr=resp3["data1"][0]["zone_name"].split("_")
      this.orderuploadObj['zone_name']=zoneNameArr[0]+" "+zoneNameArr[1];
      }else{
        this.orderuploadObj['zone_name'] =''
      }

      // // console.log("this is the ssection responce of the property---------",resp);
      this.order_idArr = resp["data2"];
      // // console.log("this is the section data aviansh --",this.order_idArr);

      // this.order_idArr=[]
      // if(resp["data2"].length==0)
      // {
      //     this.newcourt_order_id=true
      //     this.court_order_id=false
      // }
      // else
      // {
      //   this.newcourt_order_id=false
      //   this.court_order_id=true
      // }
    }
  }

  select() {
    // // console.log("shweta");
    // // console.log(this.order_idArr.length);
    if (this.order_idArr.length==0) {
      this.newcourt_order_id = true;
      this.court_order_id = false;
      this.upload_data = true;
      Swal.fire("Info", "Firstly Create Court Order ID", "info");
    } else {
      this.newcourt_order_id = false;
      this.court_order_id = true;
      this.upload_data = false;
    }
  }

  async order_id_data(orderupload:any) {
    // // console.log(orderupload);
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = orderupload["case_id"];

    obj["court_order_id"] = orderupload["court_order_id"];
    if (orderupload["court_order_id"] != null) {
      var resp:any = await this.liti.getorderiddata(obj);
      // // console.log(resp);
      if (resp["error"] == false) {
        this.orderuploadArr = resp["data1"];
        // // console.log(this.orderuploadArr);

        // orderupload=this.orderuploadArr
        this.orderupload["court_order_type"] =
          this.orderuploadArr[0]["co_ord_type"];
        this.orderupload["court_order_date"] =
          this.orderuploadArr[0]["co_ord_date"];
        this.orderupload["comp_time_date"] =
          this.orderuploadArr[0]["comp_time_limit"];
        this.orderupload["no_of_days"] =
          this.orderuploadArr[0]["comp_day_limit"];
        this.orderupload["court_or_sumry"] =
          this.orderuploadArr[0]["co_summary"];
        this.orderupload["add1"] = this.orderuploadArr[0]["add1"];
        this.orderupload["add2"] = this.orderuploadArr[0]["add2"];
        // // console.log(this.orderupload);
        orderupload = Object.assign({}, this.orderuploadArr);
      }
    }
  }
  /****view Photo of generate notice*/
  imgURL2:any;
  async view_photo(i:any) {
    await this.getphotodetail(i);
    // // console.log(this.complaint_image.length);
    this.f=false;
    //this.imageflag = false
    if (this.complaint_image.length > 0) {
      $("#staticBackdrop").modal("show");
      return;
    } else {
      Swal.fire("Info", "No Image Found", "info");
    }
  }

  async view_digitalSign(i:any) {
    await this.getphotodetail(i);
    // // console.log(this.complaint_image.length);
    //this.imageflag = false
    if (this.complaint_image.length > 0) {
      $("#staticBackdrop1").modal("show");
      return;
    } else {
      Swal.fire("Info", "No Image Found", "info");
    }
  }
  upload_signed(noticeArr:any, noticeObj:any) {}
  complaint_image:any;
  async getphotodetail(i:any) {
    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    if (i == 1) {
      obj["doc_type"] = "Public";
      this.f = true;
    } else if (i == 2) {
      obj["doc_type"] = "Inspection";
      this.f = true;
    } else if (i == 3) {
      obj["doc_type"] = "Inspectionreport";
    } else if (i == 4) {
      obj["document_name"] = "Digital_Signature";
      obj["case_id"] = this.case_idArr;
    }
    obj["complain_id"] = this.noticeArr[0].complain_id;

    // // console.log(obj);
    var resp:any = await this.liti.getUploadDocument(obj);
    // // console.log(resp);
    if (resp["error"] == true) {
      Swal.fire("Error", "Server Side Error", "error");
      return;
    } else {
      this.complaint_image = resp["data"];
      // // console.log(this.complaint_image);
    }
  }
  filename:any;
  imgURL:any;

  reportArr:any = [];
  showreportArr:any = [];
  complaint_detail: any = {};
  inspection_detail: any = {};
  section_detail: any;

  async view_photo1(noticeArr:any){
    // // console.log("shweta",noticeArr)
    // $('#myModalreport').modal('show');

    //this.getinspectionphotodetail()
    // // console.log("shweta cases",this.objcase)
  let insCaseId=this.objcase['case_id']
    let insCompId=this.objcase['comp_id']

    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["user_id"] = this.erpUser.user_id;
    var resp:any = await this.ReportService.allAssignComplain(JSON.stringify(obj));

    // // console.log(resp)
    if(resp["error"]==false)
    {
      this.reportArr=resp['data']

      for(let i =0;i<this.reportArr.length;i++)
      {
         //if(this.reportArr[i]['inspection_status']=="INSPECTION REPORT SUBMITED")
        if((this.reportArr[i]['inspection_status']=="APPROVED")&&(this.reportArr[i]['complain_app_id']==this.objcase['comp_id']))
        {
          $('#myModalreport').modal('show');
         this.complaint_detail=this.reportArr[i]

        const obj :any= {}
        obj['b_acct_id'] = this.b_acct_id
        obj['complain_id'] = this.complaint_detail['complain_id']
        // // console.log("shweta complain_id",obj)
        var resp1:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))

        // // console.log(resp1);
        if (resp1["error"] == true) {
          Swal.fire("Error", "Server Side Error", "error");
          return;
        } else {
          await this.getdescription();
          this.section_detail = resp1["data"][1];
          // // console.log(this.section_detail);
          this.inspection_detail = Object.assign({}, ...resp1["data"][0]);
          // // console.log(this.complaint_detail);
          // // console.log(this.inspection_detail);
          await this.getuserinfo();
          await this.getphotodetail1();
        }
        }

        else if((this.reportArr[i]['inspection_status']!="APPROVED")&&(this.reportArr[i]['complain_app_id']==this.objcase['comp_id']) )
        {
          Swal.fire('Info',"Inspection Report Not Approved",'info')
        }
      }
      // // console.log("this is the show report data",this.complaint_detail )
      // const obj :any= {}
      // obj['b_acct_id'] = this.b_acct_id
      // obj['complain_id'] = this.complaint_detail['complain_id']
      // var resp1:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
      // // console.log(resp1);
      // if (resp1['error'] == true) {
      //   Swal.fire('error', 'server side error', 'error');
      //   return
      // }
      // else {
      //   await this. getdescription()
      //   this.section_detail = resp1['data'][1]
      //   // console.log(this.section_detail)
      //   this.inspection_detail = Object.assign({}, ...resp1['data'][0]);
      //   // console.log(this.complaint_detail);
      //   // console.log(this.inspection_detail)
      // }
    }
 // shweta  await this.getuserinfo();
  // shweta  await this.getphotodetail1();
    // await this.getNotification()
    // const obj :any= {}
    // obj['b_acct_id'] = this.b_acct_id
    // obj['complain_id'] = this.noticeArr[0]['report_c_id']
    // // console.log(obj)
    // var resp:any = await this.ComplainService.getinspectionDescription(JSON.stringify(obj));
    // // console.log(resp);
  }
  description: any = {};
  async getdescription() {
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["complain_id"] = this.complaint_detail["complain_id"];
    // // console.log(obj);
    var resp:any = await this.ComplainService.getinspectionDescription(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.description = resp["data"];
      // // console.log(this.description);
    }
  }
  inspection_image: any = [];
  async getinspectionphotodetail() {
    //  this.complaint_detail = element

    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["complain_id"] = this.complaint_detail["complain_id"];
    obj["doc_type"] = "Inspection";
    // // console.log(obj);
    var resp:any = await this.ComplainService.getUploaddocumentrData(
      JSON.stringify(obj)
    );
    // // console.log(resp);
    if (resp["error"] == true) {
      Swal.fire("Error", "Server Side Error", "error");
      return;
    } else {
      this.inspection_image = resp["data"];
      // // console.log(this.inspection_image);
    }
  }
  userdetail = [];
  async getuserinfo() {
    const obj :any= {};
    // // console.log(this.complaint_detail);
    obj["b_acct_id"] = this.b_acct_id;
    obj["complain_id"] = this.complaint_detail["complain_id"];
    // // console.log("this is the userinfo", obj);
    var resp:any = await this.ComplainService.getuserinfo(JSON.stringify(obj));
    // // console.log(resp);
    if (resp["error"] == true) {
      Swal.fire("Error", "Server Side Error", "error");
      return;
    } else {
      this.userdetail = resp["data"];
      this.complaint_detail["party_name"] = this.userdetail[0]["party_name"];

      // // console.log(this.userdetail);
    }
  }
  async download(){
    let obj:any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["id"] = this.complaint_detail["id"];
    obj["user_id"] = this.erpUser.user_id;
    // // console.log("this is the download button obj-------", obj);
    await this.ReportService.printInspectionRep(obj);
  }

  imageflag1: any = false;
  memoimageflag: any = false;
  view_inspection_photo() {
    this.imageflag1 = false;
    $("#inspection_photo").modal("show");
    this.getinspectionphotodetail();
  }
  // inspection_image: any = [];
  // async getinspectionphotodetail(element) {
  //   this.complaint_detail = element

  //   const obj :any= {}
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['complain_id'] = this.complaint_detail['complain_id']
  //   obj['doc_type'] = "Inspection";
    // // console.log(obj)
  //   var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
  //   // console.log(resp)
  //   if (resp['error'] == true) {
  //     Swal.fire('error', "server side error", 'error')
  //     return
  //   } else {
  //     this.inspection_image = resp['data']
  //     // console.log(this.inspection_image)
  //   }
  // }
  async inspection_view(i:any) {
    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = this.inspection_image[i]["doc_id"];
    obj["file_name"] = this.inspection_image[i]["file_name"];
    obj["complain_id"] = this.inspection_image[i]["complain_id"];
    // // console.log(obj);
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag1 = true;
      // // console.log(resp, "res");
      // this.spinner.hide();
      this.imgURL = resp;
      var docname = obj["file_name"];
      var ext = docname.split(".");
      if (
        ext[1] == "png" ||
        ext[1] == "jpeg" ||
        ext[1] == "jpg" ||
        ext[1] == "PNG" ||
        ext[1] == "JPEG" ||
        ext[1] == "JPG"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // // console.log(this.imgURL1, 'imgURL1')
    }
  }
  memo_image = [];
  ///////////////////////////memo image
  async getmemophoto() {
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["complain_id"] = this.complaint_detail["complain_id"];
    obj["doc_type"] = "Memo";
    // // console.log(obj);
    var resp:any = await this.ComplainService.getUploaddocumentrData(
      JSON.stringify(obj)
    );
    // // console.log(resp);
    if (resp["error"] == true) {
      Swal.fire("Error", "Server Side Error", "error");
      return;
    } else {
      this.memo_image = resp["data"];
      // // console.log(this.memo_image);
    }
  }

  view_memo_img() {
    this.memoimageflag = false;
    $("#memo_photo").modal("show");
    this.view_memo();
  }
  async view_memo() {
    await this.getmemophoto();

    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = this.memo_image[0]["doc_id"];
    obj["file_name"] = this.memo_image[0]["file_name"];
    obj["complain_id"] = this.memo_image[0]["complain_id"];
    // // console.log(obj);
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.memoimageflag = true;
      // // console.log(resp, "res");
      // this.spinner.hide();
      this.imgURL3 = resp;
      var docname = obj["file_name"];
      var ext = docname.split(".");
      if (
        ext[1] == "png" ||
        ext[1] == "jpeg" ||
        ext[1] == "jpg" ||
        ext[1] == "PNG" ||
        ext[1] == "JPEG" ||
        ext[1] == "JPG"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL3 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
    }
  }
  public_photo:any = {};

  async getphotodetail1() {
    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["complain_id"] = this.complaint_detail["complain_id"];
    obj["doc_type"] = "Public";

    // // console.log(obj);
    var resp:any = await this.ComplainService.getUploaddocumentrData(
      JSON.stringify(obj)
    );
    // // console.log(resp);
    if (resp["error"] == true) {
      Swal.fire("Error", "Server Side Error", "error");
      return;
    } else {
      this.complaint_image = resp["data"];
      this.public_photo = Object.assign({}, this.complaint_image[0]);
      // // console.log(this.public_photo);
    }
  }
  view_photosite() {
    // // console.log(this.complaint_image.length);
    this.imageflag = false;
    if (this.complaint_image.length > 0) {
      $("#staticBackdropsite").modal("show");
      return;
    } else {
      Swal.fire("Info", "No Image found", "info");
    }
  }
  imgURL1:any;
  async  view1(i:any) {
    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = this.complaint_image[i]["doc_id"];
    obj["file_name"] = this.complaint_image[i]["file_name"];
    obj["complain_id"] = this.complaint_image[i]["complain_id"];
    // // console.log(obj);
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true;
      // // console.log(resp, "res");
      // this.spinner.hide();
      this.imgURL1 = resp;
      var docname = obj["file_name"];
      var ext = docname.split(".");

      if (
        ext[1] == "png" ||
        ext[1] == "jpeg" ||
        ext[1] == "jpg" ||
        ext[1] == "PNG" ||
        ext[1] == "JPEG" ||
        ext[1] == "JPG"
      ) {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL1 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // // console.log(this.imgURL1, "imgURL1");
    }
  }

  async view(element:any) {
    this.imgURL2 = {};
    this.f=true;
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_id"] = element.doc_id;
    obj["filename"] = element.file_name;
    this.filename = element.file_name;

    const res = await this.liti.getUploadedDocumentData(obj);
    if (res) {
      var docname = element.file_name;
      var ext = docname.split(".");

      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL2 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    }
  }
  async view_d1(element:any) {
    var obj :any = new Object();
    this.imgURL="";
    obj["b_acct_id"] = this.b_acct_id;

    obj["doc_id"] = element.doc_id;

    obj["filename"] = element.document_type_code;

    this.filename = element.document_type_code;

    const res = await this.liti.getUPLOADEDSIGNATUREDATA(obj);

    if (res) {
      var docname = element.document_type_code;
      var ext = docname.split(".");
      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf" });

        var fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    }
  }

  async view_d(element:any) {
    var obj :any = new Object();

    obj["b_acct_id"] = this.b_acct_id;

    obj["doc_id"] = element.doc_id;

    obj["filename"] = element.document_type_code;

    this.filename = element.document_type_code;

    const res = await this.liti.getUploadedFileData(obj);

    if (res) {
      var docname = element.document_type_code;
      var ext = docname.split(".");
      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: "application/pdf" });

        var fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    }
  }
  change() {
    this.imgURL2 = {};
  }
  change1() {
    this.selectedFile = [];

    this.doc_name = [];
  }
  async view_document(element:any) {
    var obj :any = new Object();
    this.imgURL=""
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = element.case_id;
    var resp:any = await this.liti.getUploadedFiles(obj);
    var arr = [];
    if (resp["error"] == false) {
      arr = resp.data;
      this.dataSource3 = new MatTableDataSource(arr);
      // // console.log(arr);

      this.dataSource3.sort = this.sort;
    } else {
    }

    $('.nav-tabs a[href="#tab-2"]').tab("show");
  }

  speed:any = {};
  async update(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["notice_id"] = element.not_id;
    obj["section"] = element.not_sec;
    // // console.log("this si the update button ", obj);
    var resp:any = await this.liti.upNotice(obj);
    // // console.log("this is the responce of the ", resp);

    this.speed["notice_id"] = element.not_id;
    this.speed["section"] = element.not_sec;

    if (resp["error"] == false) {
      this.htmlContent = resp["data"][0]["notice_body"];
      // // console.log("hiit", this.htmlContent);
      $("#myModalUpNotice").modal("show");
      // let elem: HTMLElement = document.getElementById("cke_2_contents");
      // elem.style.height = "410px";
    } else {
      Swal.fire("Info", "Some Error", "info");
    }
  }
 noticeSection:any;
  // for print notice
    // qrcode ---
 value:any;
 correctionLevel:any;
 elementType:any;

  async print(element:any) {
    this.noticeSection='';
    var obj :any= new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["notice_id"] = element.not_id;
    obj["section"] = element.not_sec;
    this.noticeSection=obj["section"];


    // // console.log("this si the update button ", obj);
    var resp:any = await this.liti.upNotice(obj);
    // // console.log("this is the responce of the ", resp);

    this.speed["notice_id"] = element.not_id;
    this.speed["section"] = element.not_sec;

    //  // console.log("thsi is hte qr data of the scan file ---case id=",this.noticeArr[1]['case_id'],"section==",element.not_sec,"bacct_id=",this.b_acct_id, "")
 let wsa="https://property.upda.co.in/#/prop/sale?acct=14"
//  +`${this.noticeArr[1]['case_id']}`+"&sectionid="+`${element.not_sec}`+"&baat="+`${this.b_acct_id}`+"&noticeid="+`${element.not_id}`;
//  // console.log("this iss the link of hte wsaaa----", wsa);
 this.not_sec=`${element.not_sec}`;
 this.baat=`${this.b_acct_id}`;
 this.noticeid=`${element.not_id}`;
   // // qr code   http://localhost:4200/#/prop/qrcode?id=123&caseid=1254674
   this.elementType = NgxQrcodeElementTypes.URL;
   this.correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
 //  this.value = 'https://www.techiediaries.com/';

 // this.value="http://localhost:4400/#/prop/qrcode?caseid="+`${this.noticeArr[1]['case_id']}`+"&sectionid="+`${element.not_sec}`+"&baat="+`${this.b_acct_id}`+"&noticeid="+`${element.not_id}`;
  // this.value="http://192.168.29.9:4400/#/qrcode?acct=14&caseid="+`${this.noticeArr[1]['case_id']}`+"&sectionid="+`${element.not_sec}`+"&noticeid="+`${element.not_id}`;
  // this.value="https://dev.erp2.upda.co.in/public/#/prop/qrcode?acct=14&caseid="+`${this.noticeArr[1]['case_id']}`+"&sectionid="+`${element.not_sec}`+"&noticeid="+`${element.not_id}`;
  this.value="https://sealing.upda.co.in/public/#/prop/qrcode?acct=14&caseid="+`${this.noticeArr[1]['case_id']}`+"&sectionid="+`${element.not_sec}`+"&noticeid="+`${element.not_id}`;

  // qr end
// // console.log("this is the qr-code value --",this.value);
    if (resp["error"] == false) {
      this.htmlContent = resp["data"][0]["notice_body"];
     // // console.log("hiit", this.htmlContent);
      $("#myModalUpNoticeP").modal("show");
      let elem: any = document.getElementById("cke_3_contents");
      elem.style.height = "402px";
    } else {
      Swal.fire("Info", "Some Error", "info");
    }
  }
  not_sec:any;
  baat:any;
  noticeid:any;
  href:any

    downloadQr()
    {
  var qrCodeBaseUri = 'http://192.168.29.9:4500/#/prop/qrcode?',
      params = {
        caseid: `${this.noticeArr[1]['case_id']}`,
        sectionid: this.not_sec,
        baat:this.baat,
        noticeid: this.noticeid,
        size: '150x150',
        margin: 0,
        download: 1
      };
  window.location.href = qrCodeBaseUri + $.param(params);


    }

  //   downloadQRCode() {
  //     const fileNameToDownload = 'image_qrcode';
  //     const base64Img = document.getElementsByClassName('coolQRCode')[0].children[0]['src'];
  //     fetch(base64Img)
  //        .then(res => res.blob())
  //        .then((blob) => {
  //           // IE
  //           if (window.navigator && window.navigator.msSaveOrOpenBlob){
  //              window.navigator.msSaveOrOpenBlob(blob,fileNameToDownload);
  //           } else { // Chrome
  //              const url = window.URL.createObjectURL(blob);
  //              const link = document.createElement('a');
  //              link.href = url;
  //              link.download = fileNameToDownload;
  //              link.click();
  //           }
  //        })
  //  }
  async uoDateNotice() {
    // // console.log("html contenet", this.htmlContent);
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["notice_id"] = this.speed["notice_id"];
    obj["section"] = this.speed["section"];
    obj["text"] = this.htmlContent;
    var resp:any = await this.liti.UpdateNotice(obj);
    if (resp["error"] == false) {
      Swal.fire("Success", "Updated Successfully", "success");
    }
  }
  async forward(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["notice_id"] = element.not_id;

    // // console.log("this si the forward button ", obj);
    var resp:any = await this.liti.forWord(obj);
    // // console.log("this is the resp", resp);
    if (resp["error"] == false) {
      obj["case_id"] = this.case_idArr;
      obj["b_acct_id"] = this.b_acct_id;

      var resp2 = await this.liti.getNotice(obj); //  for modal9
      // // console.log("thie is the responce", resp2);
      if (resp2["error"] == false) {
        // // console.log("thie is the responce",resp2)
        this.showNotice = [];
        this.totalnot = [];

        this.showNotice = resp2["data"];

        for (let i = 0; i < this.showNotice.length; i++) {
          for (let j = 0; j < this.role.length; j++) {
            if (this.role[j] == "VP") {
              this.digitalsignbtn = true;
            }
          }
          this.totalnot.push({
            not_id: this.showNotice[i]["notice_id_f"],
            not_act: this.showNotice[i]["notice_act"],
            not_date: this.showNotice[i]["notice_date"],
            not_sec: this.showNotice[i]["section"],
            not_status: this.showNotice[i]["status"],
            not_btn: this.digitalsignbtn,
          });
        }

        // // console.log("this si the datasource of the ======", this.totalnot);

        this.dataSource4 = new MatTableDataSource(this.totalnot);
        //this.dataSource.paginator = this.paginator;
        this.dataSource4.sort = this.sort;
      }

      Swal.fire("Info", " Notice Forwarded..!", "info");
      this.totalnot = [];
    } else {
      Swal.fire("Info", "Some Error", "info");
    }
  }

  // code for change the date
 comfortDateAvi(obj:any) {
    // // console.log("this is the ojb of the change date", obj);
    let text = obj;
    if(text== undefined || text.length==0)
    {
    let noDate="NO"
    return noDate;
    }
    const myArray = text.split("-");
    const myArray1 = myArray.reverse();
    const mrArray3 = myArray1.join("-");
    // // console.log("this is the daaate function ", mrArray3);
    return mrArray3;
  }

  async generate_notice(noticeArr:any, noticeObj:any) {
    var obj :any = new Object();

    obj["b_acct_id"] = this.b_acct_id;
    obj["owner_name"] = noticeObj["owner_name"];

    obj["notice_under_act"] = noticeObj["notice_under_act"];
    obj["sections"] = noticeObj["section_cd"];
    obj["case_id"] = noticeArr[1]["case_id"];
    obj["date"] = noticeArr[1]["f_date"];
    obj["zone_cd"] = noticeArr[1]["zone_cd"];
    obj["created_user_id"] = this.erpUser.user_id;

    obj["first_h_date"] = "NA";
    obj["h_time"] = "NA";

    if (this.notice_before == true) {
      // obj["first_h_date"]=noticeObj['date'];
      obj["first_h_date"] = this.comfortDateAvi(noticeObj["date"]);
      // // console.log(
      //   "this is the change date format din the aviuat atbel ",
      //   obj["first_h_date"]
      // );
      let date1= new Date();
      let date2 =new Date(noticeObj["date"]);
      if(date2 < date1)
      {
         Swal.fire('Info',' Reply To This Notice Before Date Must Be Latest From Today  ','info');
return ;
      }
      obj["h_time"] = noticeObj["time"];
    }


    // // console.log("tlhis si the obj", obj);
 //validation
    if (
      obj["sections"] == undefined || obj["sections"] == "" ||
      obj["first_h_date"] == undefined ||   obj["first_h_date"] =="NO"  ||
      obj["h_time"] == undefined
    )
    {
      Swal.fire("Alert", "Please Fill * Detail Carefully..!", "error");
      return;
    }
    // // console.log("tlhis is the section cd ----------",obj);
    // // console.log("tlhis is the section main ----------",noticeObj);
      var resp:any = await this.liti.selNot_h_f(obj);

      if (resp["error"] == false) {
        this.h_f = resp["data"];

        this.htmlContent =resp["data"][0]["mas_format"];

        $("#myModal3").modal("show");
        let elem: any = document.getElementById("cke_1_contents");
        elem.style.height = "440px";
      } else {
        Swal.fire("Alert", "Section Mismatch", "error");
      }

  }

  // update last hearing date
  uphb: boolean = false;
  hearing_id: any;
  async UPH(objhearing:any) {
    var obj :any = new Object();
    obj["case_id"] = objhearing["case_id"];
    obj["l_date"] = objhearing["h_date"];
    obj["b_acct_id"] = this.b_acct_id;
    // var resp= await this.liti.uph(obj)
    var resp:any = await this.liti.getHearing(obj);
    // // console.log("this is the hearing resp==", resp);
    if (resp["error"] == false) {
      // // console.log("this si the resp ", resp);

      if (resp["data"][0]["next_h_date"] != "0000-00-00") {
        this.objhearing["last_h_date"] = resp["data"][0]["old_h_date"];
        // // console.log("this is the main resp", resp["data"]);
        this.objhearing["note_about"] = resp["data"][0]["description"];
        this.objhearing["next_h_t"] = resp["data"][0]["time"];
        this.objhearing["next_h_date"] = resp["data"][0]["next_h_date"];
        //  this.objhearing['']=resp['data'][0]['next_h_date']
        this.last_h_flag = true;
        this.next_h_d_flag = true;
        this.next_h_t_flag = true;
        this.uphb = true;
        this.hearing_id = resp["data"][0]["hearing_id"];
      } else {
        Swal.fire(
          "Alert",
          " Some Error In Hearing Update",
          "error"
        );
      }
    }
  }

  formData:any
  doc_name:any = [];
  async UPDATE_H(objhearing:any) {
    var obj :any = new Object();
    obj = objhearing;

    obj["b_acct_id"] = this.b_acct_id;
    obj["last_h_date"] = objhearing["last_h_date"];
    obj["next_h_date"] = objhearing["next_h_date"];
    obj["avi_next_h_date"] = this.comfortDateAvi(objhearing["next_h_date"]);

    obj["next_h_t"] = objhearing["next_h_t"];

 let date1= new Date(objhearing["last_h_date"]);
 let date2= new Date(objhearing["next_h_date"]);

    if(obj["avi_next_h_date"]=="NO")
    {
   Swal.fire('Error',"Please Fill The Next Hearing Date",'error');
   return;
    }
    if(date1 >= date2)
    {
      Swal.fire('Error','Next Hearing Date Must be Greater than Last Hearing Date ..!','error');
      return;
    }
    if (this.uphb == true) {
      obj["hearing_id"] = this.hearing_id;
      obj["update"] = "yes";
    }
    // // console.log("this si hte obj update heatring submit", obj);

    this.formData = new FormData();

    for (let i = 0; i < this.selectedFile.length; i++) {
      this.formData.append("image", this.selectedFile[i]);
    }
    // // console.log(this.uploader);
    obj["doc_name"] = this.doc_name;
    obj["doc_local_name"] = "CASE HEARING DOC";
    obj["create_user_id"] = this.erpUser.user_id;
    this.formData.append("data", JSON.stringify(obj));
    let resp:any = await this.liti.submitReportSub(this.formData);
    // // console.log("this si the responce", resp);
    if (resp["error"] == false) {
      this.uphb = false;
      this.totalArr = [];
      this.arr4 = [];
      await this.getNotification();
      this.doc_name = [];
      this.selectedFile = [];
      this.objhearing = {};
      this.formData = new FormData();
      this.hearing_id = "";
      Swal.fire(
        "Success",
        `Hearing Update and Uploaded Document successfully `,
        "success"
      );

    } else {
      Swal.fire(
        "Error",
        "Error Occurred While Update Hearing and Upload Document",
        "error"
      );
    }
  }

  async onFileChange(event: any) {
    /* wire up file reader */
    this.uploaded_file_data = [];
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });
      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      // // console.log(data);
      this.setData(data);
      // Data will be logged in viewarray format containing objects
    };
  }
  uploaded_file_data = [];
  async setData(data:any) {}

  close_modal() {
    //   $(document).ready(function(){
    //     // Open modal on page load
    $("#myModal1").modal("hide");

    //     // Close modal on button click
    // $(".button").click(function(){
    //     $("#myModal1").modal('hide');
    // });

    //   $(function () {
    //     $('#myModal1').modal('close');
    //  });
    //   this.router.navigate(['/icms/litigation']);
    //   $("#myModal1").click(function () {
    //     $("#tab-2").fadeOut(500);

    // });
    $("#closemodal").click(function () {
      $("#myModal1").modal("hide");
    });
  }

  async aviMatch(t: any) {

    let ext = t.split(".");

    for (let i = 0; i < ext.length; i++) {
      if (ext[i] == "exe" || ext[i] == "mp4" || ext[i] == "MKV" || ext[i] == "AVI" || ext[i] == "MOV") {
        return 0;
      }
      return

    }
    return
  }


  selectedFile :any= [];
  async onFileUpload(event: any, files:any) {
    this.selectedFile = [];
    this.doc_name = [];
    if (event.target.files.length <= 5) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          let text=event.target.files[i]["name"]
          let res= await this.aviMatch(text);

          if(res==0)
          {
             Swal.fire('Info', `You Enter the .exe File / Video File, Remove This First..!`,'info');
             return;
          }
          this.selectedFile.push(event.target.files[i]);
          this.doc_name.push(event.target.files[i]["name"]);

          let reader = new FileReader();
          reader.onload = (event: any) => {
            // // console.log("result--",event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      Swal.fire("Error", "You Can Upload Only Five or Less Documents", "error");
    }

    // // console.log(this.doc_name);
    // // console.log("selectedFile : ", this.selectedFile);
  }

  async onFileUpload1(event: any, files :any) {
    this.selectedFile = [];
    this.doc_name = [];
    if (event.target.files.length <= 5) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          let text=event.target.files[i]["name"]
          let res= await this.aviMatch(text);

          if(res==0)
          {
             Swal.fire('Info', `You Enter the .exe File / Video File, Remove This First..!`,'info');
             return;
          }
          this.selectedFile.push(event.target.files[i]);
          this.doc_name.push(event.target.files[i]["name"]);

          let reader = new FileReader();
          reader.onload = (event: any) => {
            // // console.log(event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      Swal.fire("Error", "You Can Upload Only Five or Less Documents", "error");
    }

    // // console.log(this.doc_name);
    // // console.log("selectedFile : ", this.selectedFile);
  }
  selectedFileorder = [];
  async onFileUploadorder(event: any, files:any) {
    this.selectedFile = [];
    this.doc_name = [];
    if (event.target.files.length <= 5) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          let text=event.target.files[i]["name"]
          let res= await this.aviMatch(text);

          if(res==0)
          {
             Swal.fire('Info', `You Enter the .exe File / Video File, Remove This First..!`,'info');
             return;
          }
          this.selectedFile.push(event.target.files[i]);
          this.doc_name.push(event.target.files[i]["name"]);

          let reader = new FileReader();
          reader.onload = (event: any) => {
            // // console.log(event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      Swal.fire("Error", "You Can Upload Only Five or Less Documents", "error");
    }

    // // console.log(this.doc_name);
    // // console.log("selectedFile : ", this.selectedFile);
  }



// shweta code start upload court order
  async  upload_court_order(orderuploadObj:any,orderupload:any){
    this.spinner.show();
    if(!(orderupload['court_order_date']&&orderupload['court_order_type']))
   {
    this.spinner.hide();
    Swal.fire('Info',"Firstly Fill the Court Order Date and Court Order ID",'info')
    return;
   }
  var obj1:any = new Object()
  obj1["b_acct_id"]=this.b_acct_id
  obj1["case_id"]=orderupload["case_id"]
  var resp=await this.liti.getid(obj1)
  // // console.log(resp)
  let i=1
 let upload_id= resp['data'][0]['upload_id']
   var obj1 :any=new Object()
   if(this.aviCheck== true){

   this.orderupload['no_of_days']='Null';
   }
    obj1["b_acct_id"]=this.b_acct_id
    obj1["case_id"]=orderupload["case_id"]
    obj1["co_ord_id"]='NA'
    // obj1["co_ord_id"]=orderupload['court_order_id']
    obj1["co_ord_type"]=orderupload['court_order_type']
    obj1["co_ord_date"]=orderupload['court_order_date']
    obj1["comp_time_limit"]=orderupload['comp_time_date']
    obj1["comp_day_limit"]=orderupload['no_of_days']
    obj1["co_summary"]=orderupload['court_or_sumry']
    obj1["add1"]=orderuploadObj[0]['property_address']
    obj1["add2"]=orderupload["add2"]
    obj1["owner_name"]=orderuploadObj[0]["owner_name"]
    obj1["owner_mobile"]=orderuploadObj[0]["owner_mobile"]
    obj1["country"]=orderuploadObj[0]["country"]
    obj1["dist"]=orderuploadObj[0]["dist"]
    obj1["landmark"]=orderuploadObj[0]["landmark"]
    obj1["pin_code"]=orderuploadObj[0]["pin_code"]
    obj1["police_station"]=orderuploadObj[0]["police_station"]
    obj1["state"]=orderuploadObj[0]["state"]
    obj1["zone_cd"]=orderuploadObj[0]["zone_cd"]
    // obj1["order_status"]="order uploaded"
    obj1["order_status"]="INITIATED"
    // // console.log(obj1)


    // var obj =new Object()
    // // console.log(orderuploadObj);
    // // console.log(orderupload);


    // // console.log("this is the data", this.orderuploadArr);
    // // console.log("Form Data : ", obj1);
    this.formData = new FormData();

    // // console.log("SelectedFileorder : ", this.selectedFile);
    for (let i = 0; i < this.selectedFile.length; i++) {
      this.formData.append("image", this.selectedFile[i]);
    }
    // // console.log("this is the form data", this.formData);
    // // console.log(this.uploader);
    //let j=upload_id+1
    // const str1=upload_id
    // const str2='1'

    obj1["doc_name"] = this.doc_name;
    obj1["doc_local_name"] = "Uploadcourtorder";
    // obj["relation"]="Penality"
    obj1["create_user_id"] = this.erpUser.user_id;
    this.formData.append("data", JSON.stringify(obj1));
    // // console.log(obj["co_ord_id"])
    // // console.log("this is the seocnd order_id", obj1);
    // // console.log(this.order_idArr.length);
    // // console.log("this is the upload court order id", obj1["co_ord_id"]);
    // for (let i = 0; i <= this.order_idArr.length; i++) {
      if (  this.court_order_id ==true
        // ||
        // this.order_idArr.length != 0 &&
        // orderupload["court_order_id"] == this.order_idArr[i]["co_ord_id"]
      ) {
        var obj2:any = new Object();
        // // console.log("thgis is hte court order id boolean : true", this.court_order_id);
        obj2["b_acct_id"] = this.b_acct_id;
        obj2["case_id"] = orderupload["case_id"];
        obj2["court_order_type"] = orderupload["court_order_type"];
        obj2["court_order_date"] = orderupload["court_order_date"];
        obj2["comp_time_date'"] = orderupload["comp_time_date"];
        obj2["comp_day_limit"] = orderupload["no_of_days"];
        obj2["co_summary"] = orderupload["court_or_sumry"];
        obj2["add1"] = orderupload["add1"];
        obj2["add2"] = orderupload["add2"];
        obj2["comp_time_limit"] = orderupload["comp_time_date"];
        obj2["co_ord_id"] = orderupload["court_order_id"];
        // // console.log(obj2);
        var resp1:any = await this.liti.updateorderid(obj2);
        // // console.log(resp1);
        if (resp1["error"] == false) {
          this.spinner.hide();
          Swal.fire("Success", "Successfully Updated", "success");
          this.orderupload = {};
        } else {
          this.spinner.hide();
          Swal.fire("Error", "Some Error on Updation", "error");
        }
        //else{

        // }

        // else {
        //   Swal.fire('ERROR', 'Error Occurred While upload court order and Upload Document', 'error');
        // }
      } else {

        // // console.log("thgis is hte court order id boolean : false", this.court_order_id);
        let resp:any = await this.liti.submitcourtorder(this.formData);
        // // console.log(resp);
        if (resp["error"] == false) {
          // Swal.fire(
          //   "Success",
          //   `Upload Court Order and Uploaded Document Successfully `,
          //   "success"
          // );
          this.totalArr = [];
          this.arr4 = [];
          await this.getNotification();
          var obj1:any = new Object();
          obj1["b_acct_id"] = this.b_acct_id;
          obj1["case_id"] = orderupload["case_id"];
          if (orderupload["c_status"] == "IN PROGRESS") {
            obj1["case_status"] = "INITIATED";
            obj1["filing_date"] = orderupload["date"];
            var resp1:any = await this.liti.updatecasestatus(obj1);
            // // console.log(resp1);
          }
          this.doc_name = [];
          this.selectedFile = [];
          this.formData = new FormData();
          Swal.fire(
            "Success",
            `Upload Court Order and Uploaded Document Successfully `,
            "success"
          );
        }
        else{
          this.spinner.hide();
          Swal.fire("Error",'Order is not Submitted Sucessfully...!','error');
        }
      }
   // }
    // }
  }
  // shweta code end

  //   var obj =new Object()
    // // console.log(orderuploadObj)
  //   // console.log(orderupload)
  //   obj["b_acct_id"]=this.b_acct_id
  //   obj["case_id"]=orderupload["case_id"]
  //  // obj["co_ord_id"]=orderupload['court_order_id']
  //   obj["co_ord_type"]=orderupload['court_order_type']
  //   obj["co_ord_date"]=orderupload['court_order_date']
  //   obj["comp_time_limit"]=orderupload['comp_time_date']
  //   obj["comp_day_limit"]=orderupload['no_of_days']
  //   obj["co_summary"]=orderupload['court_or_sumry']
  //   obj["add1"]=orderuploadObj[0]['property_address']
  //   obj["add2"]=orderupload["add2"]
  //   obj["owner_name"]=orderuploadObj[0]["owner_name"]
  //   obj["owner_mobile"]=orderuploadObj[0]["owner_mobile"]
  //   obj["country"]=orderuploadObj[0]["country"]
  //   obj["dist"]=orderuploadObj[0]["dist"]
  //   obj["landmark"]=orderuploadObj[0]["landmark"]
  //   obj["pin_code"]=orderuploadObj[0]["pin_code"]
  //   obj["police_station"]=orderuploadObj[0]["police_station"]
  //   obj["state"]=orderuploadObj[0]["state"]
  //   obj["zone_cd"]=orderuploadObj[0]["zone_cd"]
  //   obj["order_status"]="order uploaded"
  //   // console.log(obj)
  //   // console.log("this is the data",this.orderuploadArr)
  //   // console.log("Form Data : ", obj)
  //   this.formData = new FormData();

  //   // console.log("SelectedFileorder : ",this.selectedFile);
  //   for (let i = 0; i < this.selectedFile.length; i++) {
  //     this.formData.append("image", this.selectedFile[i]);
  //   }
  //   // console.log("this is the form data",this.formData)
  //   // console.log(this.uploader)
  //   obj["b_acct_id"]=this.b_acct_id
  //   obj["doc_name"]=this.doc_name;
  //   obj["doc_local_name"]="Uploadcourtorder"
  //   // obj["relation"]="Penality"
  //   obj["create_user_id"]=this.erpUser.user_id;
  //   this.formData.append("data", JSON.stringify(obj))
  //   // console.log(obj["co_ord_id"])
  //   //// console.log(this.orderuploadArr[0]['co_ord_id'])

  // // console.log(this.order_idArr.length)

  // for(let i=0;i<=this.order_idArr.length;i++)
  // {
  //    if((this.order_idArr.length==0)||(obj["co_ord_id"]!=this.order_idArr[i]['co_ord_id']))
  //  {

  //   let resp:any = await this.liti.submitcourtorder(this.formData);
  //   // console.log("this is the order inserted ",resp)
  //   if (resp['error'] == false) {

  //   var obj1=new Object()
  //   obj1["b_acct_id"]=this.b_acct_id
  //   obj1["case_id"]=orderupload["case_id"]
  //    if(orderupload["c_status"]=="In Progress")
  //    {
  //     obj1["case_status"] ="ORDER ISSUED"
  //     obj1["filing_date"]=orderupload["date"]
  //     var resp2 = await this.liti.updatecasestatus(obj1)
  //     // console.log(resp2)
  //     if(resp2["error"]==false)
  //     {
  //       this.totalArr=[];
  //       this.arr4=[];
  //       this.showvakaltArr=[]
  //       this.orderupload={}
  //       Swal.fire("Success", `upload court order and Upload Document successfully `, 'success');
  //       await this.getNotification()
  //     }
  //    }

  //   this.doc_name = []
  //   this.selectedFile= []
  //   this.formData = new FormData();

  //  }
  // }
  //  var obj1 = new Object()
  //  obj1["b_acct_id"]=this.b_acct_id
  //  obj1["case_id"]=orderupload["case_id"]
  //  var resp=await this.liti.getid(obj1)
  //  // console.log(resp)
  //  let i=1
  //  if(resp['data'][0]['upload_id']==null)
  //  {
  //    var obj3=new Object()
  //   //  obj3['co_ord_id']='ord-${i}'
  //   //  // console.log(obj3)
  //    obj3['co_ord_id']="ord -"+i
  //    obj3["b_acct_id"]=this.b_acct_id
  //    obj3["case_id"]=orderupload["case_id"]
  //    // console.log("this is the seocnd order_id",obj3)
  //    let resp:any = await this.liti.submitcourtorder(this.formData);
  //    // console.log(resp)
  //  }
  //  else(resp['data'][0]['upload_id']!=null)
  //  {
  //   obj3['co_ord_id']="ord -"+i+1
  //    obj3["b_acct_id"]=this.b_acct_id
  //    obj3["case_id"]=orderupload["case_id"]
  //    // console.log("this is the seocnd order_id",obj3)
  //    let resp:any = await this.liti.submitcourtorder(this.formData);
  //    // console.log(resp)
  //  }

  // if(obj1["co_ord_id"]==this.order_idArr[i]['co_ord_id'])
  //  {
  //     var obj2=new Object()
  //     obj2["b_acct_id"]=this.b_acct_id
  //     obj2["case_id"]=orderupload["case_id"]
  //     obj2["court_order_type"]=orderupload['court_order_type']
  //     obj2["court_order_date"]=orderupload['court_order_date']
  //     obj2["comp_time_date'"] =orderupload['comp_time_date']
  //     obj2["comp_day_limit"]=orderupload['no_of_days']
  //     obj2["co_summary"]=orderupload['court_or_sumry']
  //     obj2["add1"]=orderupload['add1']
  //     obj2["add2"]=orderupload["add2"]
  //     obj2["comp_time_limit"]=orderupload['comp_time_date']
  //     obj2["court_order_id"]=orderupload['court_order_id']
  //     // console.log(obj2)
  //     var resp1=await this.liti.updateorderid(obj2)
  //     // console.log(resp1)
  // if(resp1["error"]==false)
  // {

  //   Swal.fire('success',"successfully updated",'success')
  //   this.orderupload={}
  // }
  // else{
  //   Swal.fire('error',"some error on updation",'error')
  // }
  //else{

  // }

  // else {
  //   Swal.fire('ERROR', 'Error Occurred While upload court order and Upload Document', 'error');
  // }
  // }

  //   else
  //   {
  //     let resp:any = await this.liti.submitcourtorder(this.formData);
  //     if (resp['error'] == false) {
  //       Swal.fire("Success", `upload court order and Upload Document successfully `, 'success');
  //         var obj1=new Object()
  //         obj1["b_acct_id"]=this.b_acct_id
  //         obj1["case_id"]=orderupload["case_id"]
  //          if(orderupload["c_status"]=="IN PROGRESS")
  //          {
  //           obj1["case_status"] ="ORDER ISSUED"
  //           obj1["filing_date"]=orderupload["date"]
  //           var resp1:any = await this.liti.updatecasestatus(obj1)
  //           // console.log(resp1)
  //          }
  //         this.doc_name = []
  //         this.selectedFile= []
  //         this.formData = new FormData();

  //   }
  // }
  // }

  async onFileUploadnotesheet(event: any, files:any) {
    this.doc_name = [];
    this.selectedFile = [];
    if (event.target.files.length <= 5) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          let text=event.target.files[i]["name"]
          let res= await this.aviMatch(text);

          if(res==0)
          {
             Swal.fire('Info', `You Enter the .exe File / Video File, Remove This First..!`,'info');
             return;
          }


          this.selectedFile.push(event.target.files[i]);
          this.doc_name.push(event.target.files[i]["name"]);
          let reader = new FileReader();
          reader.onload = (event: any) => {
            // // console.log(event.target.result);
          };
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else {
      Swal.fire("Error", "You Can Upload Only Five or Less Documents", "error");
    }

    // // console.log(this.doc_name);
    // // console.log("selectedFile for new note: ", this.selectedFile);
  }
  async submit_notesheet(caselist:any) {

    var obj :any = new Object();
    // // console.log(caselist);
    var obj1 :any= new Object();
    obj1 = caselist;
    // // console.log(obj1);
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = this.caseId;
    obj["text_notesheet"] = obj1["text_notesheet"];
    obj["assign_user"] = this.Username;
    // // console.log("this is the notesheet data", obj);

    // // console.log("Form Data for case notesheet: ", obj);
    this.formData = new FormData();

    // // console.log("Sel : ", this.selectedFile);
    for (let i = 0; i < this.selectedFile.length; i++) {
      this.formData.append("image", this.selectedFile[i]);
    }
    this.selectedFile = [];
    // // console.log("this is the form data", this.formData);
    // // console.log(this.uploader);
    obj["doc_name"] = this.doc_name;
    obj["doc_local_name"] = "Case Notesheet";
    obj["relation"] = "Case Notesheet";
    obj["create_user_id"] = this.erpUser.user_id;
    this.formData.append("data", JSON.stringify(obj));
    this.spinner.show();
    let resp:any = await this.liti.insertcasenotesheet(this.formData);
    // // console.log(resp);
    if (resp["error"] == false) {
      this.selectedFile = [];
      this.doc_name = [];
      await this.note_sheet(this.caselist3, "ts");
       this.spinner.hide();
      Swal.fire(
        "Success",
        `Added Case Notesheet and  Document Uploaded Successfully `,
        "success"
      );
      this.doc_name = [];
      this.selectedFile = [];
      this.formData = new FormData();
      this.caselist = {};
      this.reset();
    } else {
      Swal.fire(
        "Error",
        "Error Occurred While Adding Case Notesheet and Uploading Document",
        "error"
      );
    }
  }

  defendant_apprdata:any = {};
  async verify(element:any) {
    // // console.log(element);
    this.defendant_apprdata = Object.assign({}, element);
    this.defendant_apprdata["level"] = 1;
    this.defendant_apprdata["branch"] = 1;
    this.defendant_apprdata["doc_local_no"] = element["id"];
    this.defendant_apprdata["doc_type"] = "DEFENDANT DOCS";
    this.defendant_apprdata["doc_desc"] = element["description"];

    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;

    obj["case_id"] = element["case_id"];
    obj["code_cd"] = [];
    this.spinner.show();
    let resp:any = await this.liti.getZone(obj);
    if (resp["error"] == false) {
      let cd :any= [];
      resp["data"].map((x:any) => {
        cd.push(x["zone"]);
      });
      obj["code_cd"].push(cd[cd.length - 1]);
      this.defendant_apprdata["node_cd"] = cd;
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    this.spinner.show();
    this.defendant_apprdata["module_cd"] = "ICMS";
    await this.ApprovalUserService.getWorkflowlog(
      this.erpUser,
      "DEFENDANT DOCS",
      "ICMS"
    );
    await this.ApprovalUserService.getMostLatestWorkflowlog(
      this.erpUser,
      this.defendant_apprdata
    );
    await this.ApprovalUserService.getWorkflowloguser(
      this.erpUser,
      this.defendant_apprdata
    );
    this.defendant_apprdata["node_cd"] = obj["code_cd"];
    this.spinner.hide();
    $("#myModal15").modal("show");
  }
  forwarded_user_id:any;
  appr_remark:any;
  undertakings:any = [];
  level1:any = {};
  async Approvedapproval() {
    this.spinner.show();
    if (
      this.forwarded_user_id != null ||
      this.ApprovalUserService.vendorflag == true
    ) {
      var obb:any = new Object();

      obb["user_id"] = this.erpUser.user_id;

      obb["user_name"] = this.erpUser.party_name;
      obb["b_acct_id"] = this.erpUser.b_acct_id;
      obb["forwarded_by"] = this.erpUser.user_id;
      obb["forward_msg"] = this.appr_remark;
      obb["remark"] = "";
      obb["status"] = "APPROVED";
      obb["doc_type"] = "DEFENDANT DOCS";
      obb["doc_local_no"] = this.defendant_apprdata["id"];
      obb["doc_desc"] = this.defendant_apprdata["doc_desc"];
      obb["role_cd"] = this.level1["role_cd"];
      obb["module_cd"] = "ICMS";
      obb["node_cd"] = this.defendant_apprdata["node_cd"];
      obb["level"] = this.level1["level"];
      obb["undertaking"] = JSON.stringify(this.undertakings);
      obb["branch"] = this.level1["branch"];
      obb["field_value"] = this.defendant_apprdata["bill_amt"];
      // // console.log(obb);

      var resp:any = await this.main.insertTask(obb);
      if (resp["error"] == false) {
        await this.pendingapproval();
        await this.updatestatus(this.defendant_apprdata["id"], "IN PROGRESS");
        this.spinner.hide();

        Swal.fire("Success", "Approval Forwarded Successfully!!", "success");
      } else {
        this.spinner.hide();
        Swal.fire("Error", resp["data"], "error");
      }
    } else {
      Swal.fire("Error", "Please Select Any User..!", "error");

      this.spinner.hide();
    }
  }

  async pendingapproval() {
    this.spinner.show();
    if (
      this.forwarded_user_id != null ||
      this.ApprovalUserService.vendorflag == true
    ) {
      var obb:any = new Object();

      obb["user_id"] =
        this.ApprovalUserService.rolebyid[this.forwarded_user_id]["user_id"];
      for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
        if (this.ApprovalUserService.workuser[i]["user_id"] == obb["user_id"]) {
          let h = this.ApprovalUserService.workuser[i]["desc"].split("-");
          obb["user_name"] = h[1];
        }
      }
      // // console.log(this.forwarded_user_id);
      obb["b_acct_id"] = this.erpUser.b_acct_id;
      obb["forwarded_by"] = this.erpUser.user_id;
      obb["forward_msg"] = this.appr_remark;
      obb["remark"] = "";
      obb["status"] = "PENDING";
      obb["doc_type"] = "DEFENDANT DOCS";
      obb["doc_local_no"] = this.defendant_apprdata["doc_local_no"];

      obb["doc_desc"] = this.defendant_apprdata["doc_desc"];
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb["module_cd"] = "ICMS";

      obb["node_cd"] = this.defendant_apprdata["node_cd"];
      if (this.ApprovalUserService.vendorflag == true) {
        obb["level"] = this.defendant_apprdata["level"];
        obb["branch"] = this.defendant_apprdata["branch"];
      } else {
        obb["role_cd"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["role_cd"];
        obb["level"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["level"];
        obb["branch"] =
          this.ApprovalUserService.rolebyid[this.forwarded_user_id]["branch"];
      }

      // // console.log(obb);
      var resp:any = await this.main.insertTask(obb);
      if (resp["error"] == false) {
        this.spinner.hide();
      } else {
        this.spinner.hide();
        Swal.fire("Success", resp["data"], "success");
      }
    } else {
      Swal.fire("Error", "Please Select Any User..!", "error");

      this.spinner.hide();
    }
  }

  async updatestatus(id:any, status:any) {
    // // console.log(status);
    var obb :any= new Object();
    obb["b_acct_id"] = this.erpUser.b_acct_id;
    obb["id"] = id;
    obb["update_user_id"] = this.erpUser.user_id;
    obb["status"] = status;
    // // console.log(obb);
    this.spinner.show();
    let resp:any = await this.liti.updateevidancesList(obb);

    if (resp["error"] == false) {
      await this.docs(this.defendant_apprdata, "ts");

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  async approvedocment() {
    await this.lastapprandrejectedappr("VERIFIED");
  }

  async viewDoc(obj:any) {
    // // console.log(obj);
  }

  rejectdata:any;
  async reject(obj:any) {
    this.rejectdata = obj;
    $("#myModal14").modal("show");
  }
  async rejectedapproval() {
    await this.lastapprandrejectedappr("REJECTED");
  }
  async lastapprandrejectedappr(status:any) {
    if (status == "REJECTED") {
      this.defendant_apprdata = Object.assign({}, this.rejectdata);
    }
    // // console.log(this.rejectdata);

    var obb:any = new Object();

    obb["user_id"] = this.erpUser.user_id;

    obb["user_name"] = this.erpUser.party_name;
    obb["b_acct_id"] = this.erpUser.b_acct_id;
    obb["forwarded_by"] = this.erpUser.user_id;
    obb["forward_msg"] = this.appr_remark;
    obb["remark"] = "";
    obb["status"] = status;
    obb["doc_type"] = "DEFENDANT DOCS";
    obb["doc_local_no"] = this.defendant_apprdata["id"];
    obb["doc_desc"] = this.defendant_apprdata["desc"];
    obb["role_cd"] = this.level1["role_cd"];
    obb["module_cd"] = "ICMS";
    obb["node_cd"] = this.defendant_apprdata["node_cd"];
    obb["level"] = this.level1["level"];
    obb["undertaking"] = JSON.stringify(this.undertakings);
    obb["branch"] = this.level1["branch"];

    // // console.log(obb);

    var resp:any = await this.main.insertTask(obb);
    if (resp["error"] == false) {
      await this.updatestatus(this.defendant_apprdata["id"], status);
      if (status == "REJECTED") {
        Swal.fire("Success", "Rejected Successfully!!", "success");
      }
      if (status == "VERIFIED") {
        Swal.fire("Success", "Verified Successfully!!", "success");
      }
    } else {
      Swal.fire("Error", resp["data"], "error");
    }
  }

  allApproval = [];
  allDocType = [];

  workuser = [];
  vendorflag = false;

  zoneusers = [];
  rolebyid = {};
  level_for_approval = [];
  workflow_level1 :any= {};
  apprflag = false;
  async getWorkflowlog() {
    this.spinner.show();
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["doc_type"] = "DEFENDANT DOCS";
    var resp:any = await this.main.getWorkflows(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      if (resp["data"].length != 0) {
        this.workflow_level1 = resp["data"][0];
        // // console.log(this.workflow_level1);
        this.level_for_approval = resp["data"];
        var currentuser_role = this.erpUser.role_cd;
        for (let i = 0; i < currentuser_role.length; i++) {
          if (this.workflow_level1["role_cd"] == currentuser_role[i]) {
            this.apprflag = true;
          }
        }
        this.level1 = this.workflow_level1;
      }
    } else {
      this.spinner.hide();
      // // console.log(resp["data"]);
    }
  }
  async getWorkflowlogforappr() {
    this.spinner.show();
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["status"] = "ACTIVE";
    obj["doc_type"] = "DEFENDANT DOCS";
    var resp:any = await this.main.getWorkflows(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      if (resp["data"].length != 0) {
        this.workflow_level1 = resp["data"][0];
        this.level_for_approval = resp["data"];
        var currentuser_role = this.erpUser.role_cd;
        for (let i = 0; i < currentuser_role.length; i++) {
          if (this.workflow_level1["role_cd"] == currentuser_role[i]) {
            this.apprflag = true;
          }
        }
        this.level1 = this.workflow_level1;
      }
    } else {
      this.spinner.hide();
      // // console.log(resp["data"]);
    }
  }

  statusArr :any= [];
  selectedEle:any;
  async viewStatus(element:any) {
    this.spinner.show();
    this.selectedEle = element;
    var obj :any = new Object();
    obj["doc_local_no"] = element.id;
    obj["b_acct_id"] = this.erpUser.b_acct_id;
    obj["doc_type"] = "DEFENDANT DOCS";
    obj["module_cd"] = "ICMS";
    var resp:any = await this.main.getWorkflowlog(JSON.stringify(obj));
    if (resp["error"] == false) {
      await this.getWorkflowloguser(element);
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["user_id"] == -1) {
          resp.data[i]["role_cd"] = "Contractor";
          resp.data[i]["user_id"] = resp["data"][i]["vendor_id"];
          resp.data[i]["is_vendor"] = true;
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (
            resp.data[i]["user_id"] == this.workflowuser[j]["user_id"] &&
            resp.data[i]["role_cd"] == this.workflowuser[j]["role_cd"]
          ) {
            resp.data[i]["name"] = this.workflowuser[j]["emp_name"];
            // // console.log(this.workflowuser[j]);
            resp.data[i]["role_cd"] = this.workflowuser[j]["role_cd"];
          }
        }
        if (resp.data[i]["name"] == undefined) {
          resp.data[i]["name"] = "";
        }
      }
      this.statusArr = resp.data;
      this.spinner.hide();
      $("#myModal13").modal("show");
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }
  workflowuser = [];
  rolecdtolevelobj = {};
  async getWorkflowloguser(element:any) {
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "ICMS";
    obj["doc_type"] = "DEFENDANT DOCS";
    obj["status"] = "ACTIVE";
    let obj1 :any= new Object();
    obj1["case_id"] = element["case_id"];
    obj1["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    let resp1 = await this.liti.getZone(obj1);
    if (resp1["error"] == false) {
      let cd:any = [];
      resp1["data"].map((x:any) => {
        cd.push(x["zone"]);
      });
      obj["code_cd"] = cd;
      // // console.log(obj["code_cd"]);
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
    obj["node_cd"] = obj["code_cd"];
    // // console.log(obj);
    var resp:any = await this.main.getUsersForModuleWorkflow(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      // // console.log(resp["data"]);
      this.workflowuser = resp["data"];
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp["data"], "error");
    }
  }

  print_notice(): void {
    // // console.log("thgsi is the function calll");
    var plain_text = this.htmlToPlaintext(this.htmlContent);

    let printContents:any, popupWin:any;

    printContents = plain_text;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>DOWNLOAD CASE-ID-${this.case_idArr}  NOTICE-SECTION- ${this.noticeSection} </title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }

  all_zone_vp: boolean = false;
  async checkBox() {
    if (this.all_zone_vp == true) {
      this.all_zone_vp = false;
      this.getAllEmployees();
    } else {
      this.all_zone_vp = true;
      this.getAllEmployees();
    }
  }



/**Deepankar Workflow in Notice */
notice_detail:any;
assign_Obj :any= {};
allSearchableEmp = [];
userObj:any = {};
staticRole = ['PESH_RL','VP'];
forwardNoticeObj:any = {};
role_cd_from:any;
role_cd_to:any;

async getAllEmployees() {
 // // console.log('all emp', this.b_acct_id)
  this.spinner.show()
  var arr = [];
  const obj :any= {};
  obj['b_acct_id'] = this.b_acct_id
  //obj['module_cd'] = 'ICMS'
  if(this.all_zone_vp){
    obj['all_zone_vp']=this.all_zone_vp;
  }
  else{
    obj['all_zone_vp']=this.all_zone_vp;
    obj['zone_cd']= this.notice_forward_zone
  }


  for(let i=0;i<this.role.length;i++)
  {
    if(this.role[i] == this.staticRole[0]){
      obj['role_name'] = this.staticRole[1];
      this.role_cd_from = this.role[i];
      // // console.log("this is the first",this.role_cd_from)
      break;
    }
    else if(this.role[i] == this.staticRole[1])
    {
      obj['role_name'] = this.staticRole[0];
      this.role_cd_from = this.role[i];
      // // console.log("this is the second ",this.role_cd_from)
      break;
    }
  }
  //obj['role_name'] = 'VP';
  // // console.log("this is the  sending object to the server form the angular ------------",obj)
  var resp:any = await this.mainServiceMD.getUserRoleWise(JSON.stringify(obj));
  // // console.log(resp);
  if (resp['error'] == false) {
    this.spinner.hide()
    arr = resp.data;
    //// console.log(resp, 'users')
    for (let i = 0; i < arr.length; i++) {
      this.userObj[arr[i]['user_id']] = arr[i]
      arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no'] + ", " + arr[i]['zone_name']
      this.role_cd_to = arr[i]['role_cd'];
    }
    this.allSearchableEmp = arr;


  } else {
    this.spinner.hide()
  }
  this.spinner.hide()
  // // console.log(this.allEmployees, this.allEmplyees_new, 'employees')
}

assign_Obj1:any ={}
async forwardN(element:any) {
  this.assign_Obj = element;
  await this.getAllEmployees();
  this.notice_detail = element;
  $('#staticBackdropForNotice').modal('show');
}

async forwardNotice() {
  if(this.assign_Obj['not_id']=="" || this.assign_Obj['not_id']==undefined || this.assign_Obj['not_id']==null){
    Swal.fire("Error",'Notice ID can not be Null','error')
        return
  }
  if(this.assign_Obj1['user_id']=="" || this.assign_Obj1['user_id']==undefined || this.assign_Obj1['user_id']==null){
    Swal.fire("Error",'User ID  can not be Null','error')
        return
  }
  this.forwardNoticeObj['b_acct_id'] = this.b_acct_id
  this.forwardNoticeObj['create_user_id'] = this.erpUser.user_id;
  this.forwardNoticeObj['forward_from'] = this.erpUser.user_id;
  this.forwardNoticeObj['forward_to'] = this.assign_Obj1['user_id'];
  this.forwardNoticeObj['role_cd_from'] = this.role_cd_from
  this.forwardNoticeObj['role_cd_to'] =  this.role_cd_to
  this.forwardNoticeObj['sent_to'] = this.forwardNoticeObj['role_cd_from']+"-"+this.forwardNoticeObj['role_cd_to'];
  this.forwardNoticeObj['not_id'] = this.assign_Obj['not_id']
  this.forwardNoticeObj['note'] = this.assign_Obj['note']
  this.forwardNoticeObj['status'] = "UNDER-APPROVAL";
  this.forwardNoticeObj['flag'] = "0";
  this.forwardNoticeObj['life_cycle_status'] = "NA";
 // // console.log(this.assign_Obj);
  var resp:any = await this.liti.forwardInsNotice(this.forwardNoticeObj)
  //// console.log(resp)
  if (resp['error'] == true) {
    Swal.fire('Error', 'Server Side Error', 'error');
    return
  } else {
   //  after forward datasource4 will update update
let obj:any = new Object();
   obj["case_id"] = this.case_idArr;
   obj["b_acct_id"] = this.b_acct_id;

   var resp2 = await this.liti.getNotice(obj); //  for modal9
  //  // console.log("thie is the responce", resp2);
   if (resp2["error"] == false) {
     // // console.log("thie is the responce",resp2)
     this.showNotice = [];
     this.totalnot = [];

     this.showNotice = resp2["data"];

     for (let i = 0; i < this.showNotice.length; i++) {
       for (let j = 0; j < this.role.length; j++) {
         if (this.role[j] == "VP") {
           this.digitalsignbtn = true;
         }
       }
       this.totalnot.push({
         not_id: this.showNotice[i]["notice_id_f"],
         not_act: this.showNotice[i]["notice_act"],
         not_date: this.showNotice[i]["notice_date"],
         not_sec: this.showNotice[i]["section"],
         not_status: this.showNotice[i]["status"],
         not_btn: this.digitalsignbtn,
       });
     }

    //  // console.log("this si the datasource of the ======", this.totalnot);

     this.dataSource4 = new MatTableDataSource(this.totalnot);
     //this.dataSource.paginator = this.paginator;
     this.dataSource4.sort = this.sort;

     await this.gen_notice(this.element,'ts');
//
    Swal.fire('Success', 'Notice Forward Successfully', 'success');
    //this.getcomplaindata();

    $('#staticBackdropForNotice').modal('hide');
  //  $('#myModal9').modal('hide');
  }
  this.assign_Obj = {};
  this.assign_Obj1 = {};
  // swal await this.gen_notice(this.element,'ts');
  // // console.log("thsiu is the element of the forward",this.element);
  $('#myModal21').modal('hide');

  }
}
  imgURL5:any;
  imageflag:any = false;
  async viewNoticeDelivereddocs(element:any) {
    // // console.log(element);
    var obj :any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["element"] = element;
    // // console.log(obj);
    this.spinner.show();
    let resp:any = await this.liti.getNoticeDeliveredDocs(obj);
    this.spinner.hide();
    // // console.log("this is all document ", resp);
    if (resp) {
      var ext = element["document_type_code"].split(".");
      // // console.log(ext[1]);
      if (ext[1] == "png" || ext[1] == "jpeg" || ext[1] == "jpg") {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        // // this.imgURL5 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        this.imgURL5 =
          this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        this.imageflag = true;
        // // console.log(this.imgURL5);
      } else {
        let file = new Blob([resp], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    }
  }

  viewndreportObj :any= {};
  async viewndreport(element:any) {
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["not_id"] = element.not_id;
    // // console.log("called");
    this.spinner.show();
    let resp:any = await this.liti.getndreport(obj);
    // // console.log(resp);
    if (resp["error"] == false) {
      let obj2:any = {};
      obj2["b_acct_id"] = this.b_acct_id;
      obj2["zone_cd"] = resp["data"][0]["zone"];
      var resp2:any = await this.liti.getVpname(obj2);
      this.spinner.hide();
      // // console.log(resp);
      if (resp["data"].length > 0) {
        this.viewndreportObj = resp["data"][0];
        obj["user_id"] = this.viewndreportObj["user_id"];
        let resp1:any = await this.liti.getempinfo(obj);
        // // console.log(resp1);
        let str:any = "";
        let arr :any = [];
        resp1["data"].map((x:any) => {
          arr.push(x["role_name"]);
        });
        str = arr.join(" , ");
        this.viewndreportObj["party_name"] = resp1["data"][0]["party_name"];
        this.viewndreportObj["role_name"] = str;
        // // console.log(this.viewndreportObj);
        // // console.log("this is all document ", resp);
        if (resp2["error"] == false) {
          this.viewndreportObj["zone_name"] = resp2["data1"][0]["zone_name"];
        } else {
          Swal.fire("Error", "Unable to get Zone Name", "error");
        }
        $("#myModal21").modal("show");
      } else {
        this.spinner.hide();
        Swal.fire("Error", "Report are not availble", "error");
        return;
      }
    } else {
      Swal.fire("Error", "Server side error", "error");
      return;
    }
  }

  async viewndreportdocs(not_id:any) {
    let obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["not_id"] = not_id;
    this.spinner.show();
    let resp1:any = await this.liti.getNoticeDeliveredDocstype(obj);
    if (resp1["error"] == false) {
      this.spinner.hide();
      // // console.log(resp1["data"]);
      if (resp1["data"] != undefined) {
        this.viewndreportObj["docs"] = resp1["data"];
        this.imageflag = false;
        $("#notice-deliverd_images").modal("show");
      } else {
        Swal.fire("Warning", "There are no Dacument Availble ", "warning");
        return;
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Server side error", "error");
      return;
    }
  }

  downloadReport() {
    this.spinner.show();
    let txt = "NOTICE DELIVERED REPORT";
    var dd :any= {
      pageSize: "A4",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        return arr;
      },

      pageOrientation: "portrait",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    var tbl:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: ["*", "*", "*", "*"],
        body: [],
      },
      layout: "noBorders",
    };

    var tbl1:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: ["*", "*", "*", "*"],
        body: [],
      },
      layout: "noBorders",
    };

    dd.content.push({
      text: "Case Details ",
      fontSize: 15,
      bold: true,
      colSpan: 2,
      margin: [0, 10, 0, 10],
    });

    let arr = [];

    arr.push({ text: "Complaint ID : " });
    arr.push(this.viewndreportObj["complain_app_id"]);
    arr.push({ text: "Complaint Date : " });
    arr.push(this.viewndreportObj["complain_date"]);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Case ID  : " });
    arr.push(this.viewndreportObj["case_id"]);
    arr.push({ text: "Case Filling Date : " });
    arr.push(this.viewndreportObj["filing_date"]);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Notice ID  : " });
    arr.push(this.viewndreportObj["notice_id_f"]);
    arr.push({ text: "Notice Date : " });
    arr.push(this.viewndreportObj["notice_date"]);
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Zone  :" });
    arr.push(this.viewndreportObj["zone_name"]);
    arr.push({ text: " " });
    arr.push({ text: " " });
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Property Address : " });
    arr.push({ colSpan: 3, text: this.viewndreportObj["property_address"] });
    arr.push({ text: "" });
    arr.push({ text: "" });
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Notice Act : " });
    arr.push({ colSpan: 3, text: this.viewndreportObj["notice_act"] });
    arr.push({ text: "" });
    arr.push({ text: "" });
    tbl.table.body.push(arr);

    arr = [];
    arr.push({ text: "Notice Under Section : " });
    arr.push(this.viewndreportObj["section"]);
    arr.push({ text: " " });
    arr.push({ text: " " });
    tbl.table.body.push(arr);
    dd.content.push(tbl);

    dd.content.push({
      text: "Notice Delivery Details",
      fontSize: 15,
      bold: true,
      colSpan: 2,
      margin: [0, 10, 0, 10],
    });

    arr = [];
    arr.push({ text: "Notice Delivery Date  : " });
    arr.push(this.viewndreportObj["notice_deliv_date"]);
    arr.push({ text: "Notice Action : " });
    arr.push(this.viewndreportObj["notice_delivery_mode"]);
    tbl1.table.body.push(arr);

    arr = [];
    arr.push({ text: "Officer Name : " });
    arr.push({ text: this.viewndreportObj["party_name"] });
    arr.push({ text: "Officer Designation : " });
    arr.push({ text: this.viewndreportObj["role_name"] });
    tbl1.table.body.push(arr);

    arr = [];
    arr.push({ text: "Latitude : " });
    arr.push(this.viewndreportObj["lat"]);
    arr.push({ text: "Longitude : " });
    arr.push(this.viewndreportObj["long"]);
    tbl1.table.body.push(arr);

    arr = [];
    arr.push({ text: "Officer Note : " });
    arr.push(this.viewndreportObj["officer_note"]);
    arr.push({ text: " " });
    arr.push({ text: " " });
    tbl1.table.body.push(arr);

    dd.content.push(tbl1);

    dd.content.push({
      text: " ",
      fontSize: 15,
      bold: true,
      colSpan: 2,
      margin: [0, 10, 0, 10],
    });

    dd.content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 515,
          y2: 0,
          lineWidth: 0.05,
          margin: [0, 10, 20, 10],
        },
      ],
    });

    var tbl2:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: ["*", "*", "*", "*"],
        body: [],
      },
      layout: "noBorders",
    };
    dd.content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 515,
          y2: 0,
          lineWidth: 0.05,
          margin: [0, 10, 10, 10],
        },
      ],
    });

    const d = new Date();
    let today = d.toISOString();
    let bb = today.split("T")[0];

    arr = [];
    arr.push({ text: "This is system generated " });
    arr.push({ text: " " });
    arr.push("");
    arr.push(bb);
    tbl2.table.body.push(arr);

    dd.content.push(tbl2);

    pdfMake.createPdf(dd).download("Report.pdf");
    this.spinner.hide();
  }

  async getNoticeWorkflow(nId: any) {
    this.spinner.show();
    const obj :any= {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["case_id"] = nId;
    var resp:any = await this.liti.getNoticeWorkflow(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.notArr = resp.data;
    } else {
      this.spinner.hide();
    }
  }
  /**Deepankar Work End */

  /*-----------------CREATE CASE CODE START----------------------------------*/
  obj:any = {}
  zone_list = []
  section = []
  @ViewChild('resetButton', { static: true }) resetButton!: ElementRef<HTMLElement>;
  async caseMenu() {
    let el: HTMLElement = this.resetButton.nativeElement;
    el.click();
    this.obj["b_acct_id"] = this.b_acct_id;
    let resp:any = await this.liti.getzonecd(JSON.stringify(this.obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.zone_list = resp['data']
      console.log(this.zone_list, "this.zone_list")
    } else {
      this.spinner.hide();
    }

    let resp1 = await this.liti.getsection(JSON.stringify(this.obj));
    if (resp1["error"] == false) {
      this.spinner.hide();
      this.section = resp1['data']
      console.log(this.section)
      console.log(this.zone_list, "this.zone_list")
    } else {
      this.spinner.hide();
    }


  }


  async createCase(casefrom: NgForm) {
    if (casefrom.invalid) {
      return;
    }
    this.obj["b_acct_id"] = this.b_acct_id;
    this.obj["is__notice_required"] = 'YES';
    this.obj["notice_under_act"] = 'UP UPDA1973';
    this.obj["status"] = 'INSPECTION REPORT SUBMITED';
    // obj["case_id"] = nId;
    var resp:any = await this.liti.createCase(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      Swal.fire({
        text: "Case is created Successfully",
        icon: 'success',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Create, Other case!',
        cancelButtonText: `Case list`,
      }).then(async(result) => {
        if (result.value) {
          let el: HTMLElement = this.resetButton.nativeElement;
          el.click();
        } else {
          await this.getNotification()
          $('.nav-tabs a[href="#tab-1"]').tab('show');
        }
      })

    } else {
      this.spinner.hide();
      Swal.fire('error', 'server side error', 'error');
    }
  }

  same = false;
  condtion = {}
  sameAddress() {
    if (!this.same) {
      this.Inzone = false;
      this.OuterZone = true;
      this.same = true;
      this.condtion = "NOT_BY_ZONE"

      this.getuserinfobyzone();
    } else {
      this.Inzone = true;
      this.OuterZone = false;
      this.same = false;
      this.condtion = "BY_ZONE"

      this.getuserinfobyzone();

    }

  }
  Inzone = true;
  OuterZone = false;
  user_id: any;
  userbyzone = []
  userbynotzone = []
  async getuserinfobyzone() {
    let arr = []
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['condition'] = this.condtion;
    obj['node_cd'] = this.obj['zone_cd']
    var resp:any = await this.ComplainService.getuserinfoByzone(JSON.stringify(obj));
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      arr = resp['data'];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]['user_id'] == this.user_id) {
          arr.splice(i, 1);
        }
      }
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no'] + " - " + arr[i]['zone_name']
      }
      if (this.condtion == "BY_ZONE") {
        this.userbyzone = arr;
        return
      } else {
        this.userbyzone = arr;
      }





    }


  }

  /*-----------------CREATE CASE CODE END ----------------------------------*/

}
