<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Lease Litigation</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>
                                Litigation List </a>
                            <!-- list details -->
                        </li>

                        <li class="nav-item ">
                            <a class="nav-link" href="#tab-2" *ngIf="create_flag" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                Create Litigation </a>
                            <!-- Applicant list -->
                        </li>
                    </ul>


                    <div class="tab-content">

                        <div class="tab-pane fade show active" id="tab-1" *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-center">

                                </div>
                                <div class="col-4 text-right">
                                    <button (click)="create()" class="btn btn-outline-primary">Create</button>
                                </div>

                            </div>
                            <div class="example-container">

                                <table mat-table [dataSource]="dataSource" matSort style="width: 100%">


                                    <ng-container matColumnDef="file_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>File Number </b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.file_num}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="lease_details">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- <b>{{auth.allLableShowObjProp[auth.language_cd+'auction_application10']}}</b> -->
                                            <b>Leasee Name</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.leasee_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="litigation_plot_area">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Area of Litigation</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.area}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Litigation Status</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.litigation_status}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <b>Action</b>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-success"
                                                (click)='view(element)'>View</button>
                                            <button class="btn btn-outline-success"
                                                (click)='update(element)'>Update</button>
                                            <button class="btn btn-outline-primary " (click)='approve(element)'
                                                *ngIf="element['arr_status_code']=='APPROVAL_PENDING'">Delete</button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>

                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                            </div>
                        </div>

                        <div class="tab2-content" id="tab-2" *ngIf="create_flag">

                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-4 text-center"></div>
                                <div class="col-4 text-right">
                                    <button (click)="goback()" class="btn btn-outline-primary">Go to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">File Number :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="File Number" [selectableGroup]="true"
                                        [items]="allfile" bindLabel="file_num" bindValue="file_num"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['fileno']" (change)="getAllData()">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <div class="row mt-3" *ngFor="let item of data,let i=index">
                                <!-- <div class="col-3"></div> -->
                                <div class="col-4 text-right">
                                    <h6>Select Level {{i+1}} :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['lvl1_cd']"
                                        (change)='setLeafNode()' [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                        [multiple]="false" (change)='setLeafNode()' placeholder="Select..."
                                        [(ngModel)]="Obj['lvl2_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>
                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0"
                                        disabled>
                                    </ng-select>

                                </div>
                                <div class="col-3" *ngIf="i==data.length-1">
                                    <button (click)="add_dropdown(item,i)" class="btn btn-outline-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    &nbsp;&nbsp;
                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                        class="btn btn-outline-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Select Plot Number :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Total Area of Plot"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['plotno']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br>
                            <!-- <div class="row">

                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Total Area of Plot :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Total Area of Plot"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['areaOfPlot']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>

                            </div>
                            <br> -->
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Leasee Name :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Leasee Name" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['leasee_name']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Lease Type :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Lease Type" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['lease_type']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Leasee's Plot Area :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Leasee's Plot Area"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['areaOfPlot']" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Litigation Status :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Litigation Status" [items]="status"
                                        bindLabel="status_type" bindValue="status_type" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['status']">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Reason For Litigation :</h6>
                                </div>
                                <div class="col-4">
                                    <textarea class="form-control" [(ngModel)]="Obj['reason']"></textarea>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Court Name :</h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Court Name" [selectableGroup]="true"
                                        [items]="court" bindLabel="Court_name" bindValue="Court_name"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        [(ngModel)]="Obj['courtName']">
                                    </ng-select>
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Case Number :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" placeholder="Case Number" [(ngModel)]="Obj['case_num']"
                                        class="form-control">
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Any Order :</h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" placeholder="Fill If Any Order" [(ngModel)]="Obj['order_dtls']"
                                        class="form-control">
                                </div>
                                <div class="col-4"><input type="file" class="form-control" /></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6 class="mt-2">Next Date :<span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-4">

                                    <input type="date" class="form-control" [(ngModel)]="Obj['next_date']" />
                                </div>
                                <div class="col-4"></div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="createLitigation()">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="viewDetails">
                    <div class="modal-dialog modal-lg" style="max-width: 80%">
                        <div class="modal-content rounded-50">
                            <!-- Modal Header -->
                            <div class="modal-header bg-primary">
                                <h4 class="modal-title text-white" style="font: bold">
                                    Litigation Details :
                                </h4>
                                <button type="button" class="close text-white" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <!-- Modal body -->
                            <div class="modal-body" style="border-radius: 10px">
                                <div class="card" style="border: 2px solid rgb(24, 24, 97)">
                                    <div style="margin: 3%">
                                        <div class="content-container">
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>File Number :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["file_num"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        <b>Alloted Property ID :</b>
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["alloted_property_id"] }}
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        <b>Assigned Property Number :</b>
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["assigned_property_number"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Address :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["address"] }}
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        <b>Leasee Name :</b>
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["leasee_name"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Total Area :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["area"] }}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Lease Type :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["lease_type"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Litigation Status :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["litigation_status"] }}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Litigation ID :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["litigation_id"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Case Number :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["case_number"] }}
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Court Name :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["court_name"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Next Date :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["next_date"] | date}}
                                                    </h5>
                                                </div>
                                            </div>

                                            <div class="row mt-2">
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Order Details :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["order_dtls"] }}
                                                    </h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%"><b>Reason :</b></h5>
                                                </div>
                                                <div class="col-3">
                                                    <h5 style="width: 100%">
                                                        {{ litigation["reason"] }}
                                                    </h5>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal footer -->
                            <div class="modal-footer">
                                <!-- <b>Download Allotment </b><button class="btn btn-outline-primary"
                                         (click)="download(data)">Download</button> -->
                                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
