import { Component, OnInit } from '@angular/core';
import { LedgerService } from '../../../service/ledger.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cogs-report',
  templateUrl: './cogs-report.component.html',
  styleUrls: ['./cogs-report.component.css']
})
export class CogsReportComponent implements OnInit {

  constructor(private ledgerService: LedgerService) { }
  erpUser:any;
  b_acct_id:any;
  closing_totl:number = 0;
  async ngOnInit() {
    this.erpUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFinYear();
    await this.getClosingStock();
  }
  mainObj :any= { 'st_date': '', 'en_date': '' }
  sel_fin_yr:any
  all_fin_yr:any

  async getAllFinYear() {
    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.all_fin_yr = resp['data'];

      this.all_fin_yr.map((x:any) => {
        x['fin_ye_desc'] = x['fin_year'] + "-" + (Number(x['fin_year']) + 1);
        if (x['status'] == 'OPEN') {
          this.sel_fin_yr = x['fin_year'];
          this.mainObj['st_date'] = x['start_date']
          this.mainObj['en_date'] = x['end_date']
        }
      })

    }
  }

  async setDate() {

    this.all_fin_yr.map((x:any) => {
      if (this.sel_fin_yr == x['fin_year']) {
        this.mainObj['st_date'] = x['start_date']
        this.mainObj['en_date'] = x['end_date']
      }
    })
  }
  closing_st:any
  closing_sum = 0
  dataObj :any= new Object();

  async getClosingStock() {
    this.mainObj['b_acct_id'] = this.b_acct_id;
    let resp = await this.ledgerService.getClosingStock(JSON.stringify(this.mainObj));
    if (resp['error'] == false) {
      this.closing_st = resp['data'];
      console.log(this.closing_st)
      // let curr_fin_data = new Object();
      let next_fin = (Number(this.sel_fin_yr) + 1)
      let pre_fin = (Number(this.sel_fin_yr) - 1)
      this.dataObj = { 'curr_row_m': 0, 'curr_work_p': 0, 'curr_finished_st': 0, 'curr_total': 0, 'pre_row_m': 0, 'pre_work_p': 0, 'pre_finished_st': 0, 'next_row_m': 0, 'next_work_p': 0, 'next_finished_st': 0, 'next_total': 0 }
      await this.getCogsEntry();

      this.closing_st.map((x:any) => {
        if (this.sel_fin_yr == x['fin_year']) {
          this.dataObj['curr_total'] += x['final_stock_amt'];
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Raw Stock') {
            this.dataObj['curr_row_m'] = x['final_stock_amt']
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'In Progress Stock') {
            this.dataObj['curr_work_p'] = x['final_stock_amt']
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Finished Stock') {
            this.dataObj['curr_finished_st'] = x['final_stock_amt']
          }
        }

        // next Fin year
        if (next_fin == x['fin_year']) {
          this.dataObj['next_total'] += x['final_stock_amt'];

          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Raw Stock') {
            this.dataObj['next_row_m'] = x['final_stock_amt'];
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'In Progress Stock') {
            this.dataObj['next_work_p'] = x['final_stock_amt'];
            this.closing_sum += x['final_stock_amt'];
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Finished Stock') {
            this.dataObj['next_finished_st'] = x['final_stock_amt']
          }
        }
        //pre finyear
        if (pre_fin == x['fin_year']) {
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Raw Stock') {
            this.dataObj['pre_row_m'] = x['final_stock_amt']
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'In Progress Stock') {
            this.dataObj['pre_work_p'] = x['final_stock_amt']
          }
          if (this.mainObj['en_date'] == x['as_on_date'] && x['stock_cat'] == 'Finished Stock') {
            this.dataObj['pre_finished_st'] = x['final_stock_amt']
          }
        }
      })
    } else {
      Swal.fire('ERROR', 'Error while getting Closing Stock', 'error');
    }

    this.closing_totl = this.dataObj['next_total'] + (this.dataObj['curr_total'] - this.dataObj['next_total']);
    console.log("Closing total ", this.closing_totl)
  }

  cogs_entry = []
  cogsObj = {}
  async getCogsEntry() {
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['start_date'] = this.mainObj['st_date'];
    obj['end_date'] = this.mainObj['en_date'];
    let resp = await this.ledgerService.getCogsEntry(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.cogs_entry = resp['data'];
      console.log(this.cogs_entry);
      this.cogs_entry.map(x => {
        this.dataObj['curr_total'] += x['txn_amt'];

      })
    } else {
      Swal.fire('ERROR', 'Error While Getting COGS entry', 'error');
    }

  }

}
