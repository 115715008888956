<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox"> 
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Salary Component report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary1']}} </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- Payroll report -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary2']}} </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary3']}}:<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees" bindLabel="emp_desc" bindValue="arr_id" [multiple]="false" placeholder="" [(ngModel)]="reqObj['arr_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>

                            </div>

                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Salary Component -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary4']}} :<span style="color:red">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="all_component" bindLabel="value" bindValue="value"  [multiple]="false" placeholder="" [(ngModel)]="reqObj['pay_component_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>

                            </div>

                            <br>
                            <div class="row text-center">
                                <div class="col-5">

                                </div>
                                <button class="btn btn-primary" (click)="getFixPay()"><!-- SUBMIT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary5']}}</button> &nbsp;&nbsp;
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-info" (click)="print()"><!-- Download PDF -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary6']}}</button>
                                    <button class="btn btn-info" (click)="export()"><!-- Download Excel -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary7']}}</button>

                                </div>
                            </div>
                            <br>





                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="serial_no">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Serial No. -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary8']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            <!-- {{i+1}} -->
                                            {{element.new_index}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary9']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_id_desc }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Emp Name -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary10']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Designation -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary11']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation_code }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="pay_component_amt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Amount -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary12']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_component_amt }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="start_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Start Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary13']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.effective_start_dt }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="end_dt">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- End Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Salary14']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.effective_end_dt }} </td>
                                    </ng-container>

                                    <!--  <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-warning" (click)="openUpdate(element)">UPDATE</button>
                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>