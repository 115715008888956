import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeaseService } from '../../service/lease.service';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import { PropCommonSService } from '../../service/prop-common-s.service';
import { MainService } from '../../service/main.service';
import { SettingService } from '../../service/setting.service';
import { DatePipe } from '@angular/common';
declare var $: any;




@Component({
  selector: 'app-lease-sub-division',
  templateUrl: './lease-sub-division.component.html',
  styleUrls: ['./lease-sub-division.component.css']
})
export class LeaseSubDivisionComponent implements OnInit {
  ebillUser: any
  b_acct_id: any
  first_list: any = []
  level1: any = []
  level2: any = []
  level3: any = []
  level4: any = []
  level5: any = []
  level6: any = []
  level7: any = []
  leaf_level: any = 0
  data: any = [{}]
  propObj: any = {}
  uploader:any
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  constructor(private spinner: NgxSpinnerService,
    private leaseservice: LeaseService,
    private propHierS: PropHierarchyService,
    private commonS: PropCommonSService,
    public mainService: MainService,
    private settingService: SettingService,
    public datepipe: DatePipe) { }

  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    await this.getSubDivision()
    await this.getallfileNum()
    await this.getAllHier()
    await this.getAllLevls2()
    await this.getAllLevls()
    await this.getLeaseType()
  }

  dataSource: any;
  displayedColumns: string[] = ['lease_type', 'file_no', 'plot_no', 'lease_details', 'Sub_divison', 'action'];


  subDivisonData: any = [{ newLeaseName: '', newLeaseType: null, newplotno: '', newLeasePlotArea: 0, remaining_plot_area: null, newleaseedoc: '', subdivisiondate: '' }]
  addmore() {
    this.subDivisonData.push({ newLeaseName: '', newLeaseType: null, newplotno: '', newLeasePlotArea: 0, remaining_plot_area: null, newleaseedoc: '', subdivisiondate: '' })
  }

  removeLease(index: any) {
    this.subDivisonData.splice(index, 1)
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  AllData = {}
  // flag for hidding tab content
  list_flag = true
  create_flag = false
  reset() {

  }
  // Create Button code
  create() {
    this.list_flag = false
    this.create_flag = true
  }
  // Go to Button code
  goback() {
    this.list_flag = true
    this.create_flag = false
  }
  async checkFilenum(i: any) {
    this.spinner.show();
    let obj: any = {};
    obj["b_acct_id"] = this.b_acct_id;
    obj["file_number"] = this.subDivisonData[i]["newfileno"];
    console.log(obj)
    var resp = await this.leaseservice.checkFilenum(obj);
    if (resp["error"] == false) {
      console.log(resp, " data");
      if (resp.data.length > 0) {
        Swal.fire("Info", "File number Already Exist", "info");
        this.Obj["file_number"] = null;
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Something Went Wrong!", "error");
    }
  }
  // Dropdown code for file number
  allfile = []
  async getallfileNum() {
    this.spinner.show()
    let obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    // obj['arr_status_code'] = 'ALLOTED'
    var resp = await this.leaseservice.getMutationFileNum(JSON.stringify(obj))
    if (resp.error == false) {
      this.allfile = resp.data
      console.log(this.allfile)
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }


  }
  Obj: any = {}
  async getAllHier() {
    this.first_list = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['buss_ent_type'] = 'Location'
    //console.log(obj)
    var resp = await this.propHierS.getLocationHierarchy(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.first_list = resp['data'].filter((x: any) => { return x['hier_type'] == "Planing" });
      for (let i = 0 + 1; i <= 8; i++) {
        delete this.Obj['lvl' + i + '_cd'];
      }
      this.data = []
      this.data = [{}]
    } else {

    }
  }
  allHier = []
  hier_node_obj: any = {}
  async getAllLevls() {
    var obj: any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    obj['hier_type_id'] = this.Obj['hier_type_id']
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp, ' data')
      this.allHier = resp.data;
      this.level1 = await this.commonS.refresh(this.allHier)
    } else {
    }

    this.Obj['hier_type_id'] = this.first_list[0]['hier_type_id']
  }
  async getAllLevls2() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE';
    var resp = await this.propHierS.getAllHierNodes(JSON.stringify(obj));
    if (resp['error'] == false) {
      //console.log(resp, ' data')
      let data = resp.data;
      for (let i = 0; i < data.length; i++) {
        this.hier_node_obj[data[i]['prop_hier_id']] = data[i]
      }
      console.log(this.hier_node_obj, 'hier_node_obj')

    } else {
    }
  }

  setLeafNode() {
    for (let i = 1; i <= 8; i++) {
      if (this.Obj['lvl' + i + '_cd']) {
        this.Obj['leaf_cd'] = this.Obj['lvl' + i + '_cd']
        // this.objToSubmit['project_cd']=this.Obj['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
  }
  allPlan_layout = []
  async getAllplanLayoutForOffer(data: any) {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['group_type'] = "LEASELAYOUT"
    var resp = await this.leaseservice.getpropertygroup(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allPlan_layout = resp['data']
      console.log(this.allPlan_layout)
      for (let i = 0; i < resp['data'].length; i++) {
        resp['data'][i]['desc'] = 'Lease Layout ' + resp['data'][i]['leaf_value'] + ' Having Total Property - ' + resp['data'][i]['count']
        if (data['prop_hier_id'] == resp['data'][i]['prop_hier_id']) {
          this.Obj['property_lease_group_id'] = this.allPlan_layout[i]['property_lease_group_id']
        }
        // resp['data'][i]['desc'] = 'Plan Layout Number ' + resp['data'][i]['property_group_id'] + ' Having Propetry ' + ' - ' + resp['data'][i]['count']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data6', 'error')
    }
  }
  // allProTypeAvailable = []
  // async getAllProType() {
  //   let obj: any = {}
  //   if (!this.propObj['id']) {
  //     return
  //   }
  //   this.allProTypeAvailable = []
  //   let protype = [] // just for making allProtypeAvailable Unique obj
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['property_lease_group_id'] = this.propObj['id']
  //   let resp = await this.leaseservice.getpropertyGroupdata(JSON.stringify(obj)) // getting all free property
  //   console.log(resp)
  //   if (resp['error'] == false) {
  //     this.allFetchedProToShow = resp['data']
  //     console.log(resp['data'])
  //     for (let index = 0; index < resp['data'].length; index++) {
  //       const element = resp['data'][index];
  //       // console.log(!this.allProTypeAvailable.includes(element.property_type))
  //       if (!protype.includes(element.property_type)) {
  //         this.allProTypeAvailable.push({ property_type: element.property_type })
  //         protype.push(element.property_type)  //used as helper of making allprotype unique
  //       }
  //     }
  //     console.log(this.allProTypeAvailable)
  //   } else {
  //     Swal.fire('Error', 'Error While Getting Properties', 'error')
  //   }
  // }
  allFetchedProToShow: any
  async setPreDefinedHierrarchy(data: any) {
    this.data = []
    let ob = this.hier_node_obj[data['prop_hier_id']]
    await this.commonS.refresh(this.allHier)
    for (let j = 1; j <= 8; j++) {
      if (ob['lvl' + j + '_cd']) {
        let index = j
        if (index == 1) {
          this.level2 = await this.commonS.onChangeLvl1(this.allHier, ob)
        } else if (index == 2) {
          this.level3 = await this.commonS.onChangeLvl2(this.allHier, ob)
        }
        else if (index == 3) {
          this.level4 = await this.commonS.onChangeLvl3(this.allHier, ob)
        }
        else if (index == 4) {
          this.level5 = await this.commonS.onChangeLvl4(this.allHier, ob)
        }
        else if (index == 5) {
          this.level6 = await this.commonS.onChangeLvl5(this.allHier, ob)
        }
        else if (index == 6) {
          this.level7 = await this.commonS.onChangeLvl6(this.allHier, ob)
        }
        this.data.push(ob)
      }
    }
    for (let i = 1; i <= 8; i++) {
      if (ob['lvl' + i + '_cd']) {
        this.Obj['lvl' + i + '_cd'] = ob['lvl' + i + '_cd']
        this.leaf_level = i
      } else {
        break
      }
    }
    console.log(ob, this.first_list, this.allHier)
  }

  codeValueObj = {}
  codeValueShowObj = {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj: any = {}
    var codeValueShowTempObj: any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }

  async getAllData() {
    let data: any = {}
    this.allfile.map(x => {
      if (this.Obj['fileno'] == x['file_num']) {
        data['prop_hier_id'] = x['prop_hier_id']
        data['alloted_property_id'] = x['alloted_property_id']
        this.Obj['prop_hier_id'] = x['prop_hier_id']
        this.Obj['lease_duration_type'] = x['lease_duration_type']
        this.Obj['lease_duration'] = x['lease_duration']
        this.Obj['lease_start_date'] = this.datepipe.transform(x['lease_start_date'], 'yyyy-MM-dd hh:mm:ss');
        this.Obj['alloted_property_id'] = x['alloted_property_id']
        this.Obj['arr_id'] = x['arr_id']
      }
    })
    console.log(this.data)
    await this.getAllplanLayoutForOffer(data)
    await this.setPreDefinedHierrarchy(data)
    data['property_lease_group_id'] = this.Obj['property_lease_group_id']
    // data['prop_hier_id'] = this.Obj['prop_hier_id']
    // data['alloted_property_id'] = this.Obj['alloted_property_id']
    await this.getpropertyGroupdata(data)
  }

  async getpropertyGroupdata(object: any) {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['property_lease_group_id'] = object['property_lease_group_id']
    obj['status'] = 'ACTIVE'
    var resp = await this.leaseservice.getpropertyGroupdata(JSON.stringify(obj));
    if (resp['error'] == false) {
      let data = resp['data']
      for (let i = 0; i < data.length; i++) {
        if (object['alloted_property_id'] == data[i]['property_id']) {
          this.Obj['lease_type'] = data[i]['property_type']
          this.Obj['plotno'] = data[i]['assigned_property_number']
          this.Obj['address'] = data[i]['address']
          this.Obj['areaOfPlot'] = data[i]['area']
        }
      }
      console.log(this.Obj)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  lease_type = []
  async getLeaseType() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.leaseservice.getLeaseType(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.lease_type = resp.data
      console.log(this.lease_type)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  // new_total = 0
  getRemaining(i: any) {
    let tot = 0;
    this.subDivisonData.map((x: any) => {
      tot += x['newLeasePlotArea']

    })

    console.log(tot);

    // this.new_total += this.subDivisonData[i]['newLeasePlotArea']
    this.subDivisonData[i]['remaining_plot_area'] = this.Obj['areaOfPlot'] - tot

    if (tot >= this.Obj['areaOfPlot']) {
      Swal.fire('Info', `Not Allowed to do Sub-Division greater than ${this.Obj['areaOfPlot']}`, 'info')
      this.subDivisonData[i]['newLeasePlotArea'] = null
      this.subDivisonData[i]['remaining_plot_area'] = null
      return
    }

  }



  sub_division_data = []
  async getSubDivision() {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['arr_status_code'] = 'SUB_DIVISION'
    var resp = await this.leaseservice.getSubDivision(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.sub_division_data = resp.data
      console.log(this.sub_division_data)
      this.dataSource = new MatTableDataSource(this.sub_division_data);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }

    for (let i = 0; i < this.dataSource.data.length; i++) {
      this.dataSource.data[i].sno = i;
    }
  }

  // View Button Code
  viewdata: any = []
  subDivison_data: any = []
  remaningplotarea: any;
  async view(element: any) {
    $("#viewDetails").modal("show");
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['arr_status_code'] = 'ALLOTED'
    obj['arr_id'] = element.arr_id
    var resp = await this.leaseservice.getSubDivisionSingleData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.viewdata = resp.data[0]
      console.log(this.viewdata, "<-----------Api data");
      this.subDivison_data = this.sub_division_data[element.sno]
      this.remaningplotarea = this.viewdata['area'] - this.subDivison_data['area']
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Something Went Wrong!', 'error')

    }
  }


  // Submit Button code
  async createSubDivision() {
    if (!this.Obj["fileno"] ||
      !this.Obj["plotno"] ||
      !this.Obj["areaOfPlot"]) {
      Swal.fire("Inforamtion", "Please Select All * Fields", "info");
    } else {
      this.Obj['data'] = this.subDivisonData
      this.Obj['life_cycle_status'] = 'ACTIVE'
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['user_id'] = this.ebillUser.user_id
      this.Obj['arr_type_code'] = 'APPLIED'
      this.Obj['arr_status_code'] = 'SUB_DIVISION'
      this.Obj['unit'] = 'SQF'

      this.AllData = this.Obj

      let dt = new Date()
      let MM: string | number = dt.getMonth() + 1
      let dd: string | number = dt.getDate()
      let yyyy: string | number = dt.getFullYear()

      if (dd < 10) {
        dd = "0" + dd
      }
      if (MM < 10) {
        MM = "0" + MM
      }
      let currentDate = yyyy + "-" + MM + "-" + dd
      this.Obj['arr_effective_date'] = currentDate
      console.log(currentDate)
      console.log(this.Obj)
      var resp = await this.leaseservice.createSubDivision(this.Obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        console.log(resp)
        Swal.fire('Success', 'Successfully Submitted', 'success')
        this.goback()
        this.getSubDivision()
        this.Obj = {}
        this.subDivisonData = [{ newLeaseName: '', newLeaseType: null, newplotno: '', newLeasePlotArea: 0, remaining_plot_area: null, newleaseedoc: '', subdivisiondate: '' }]
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Error While Getting Data', 'error')
      }

    }


  }

  add_dropdown(item:any,i:any){

  }
  remove_dropdown(item:any,i:any){

  }
  onFileChange($event:any){

  }

}
