<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Cost Center
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list_flag==true">
                            <a class="nav-link active"><i class="ti-bar-chart"></i>
                                List</a>
                        </li>

                        <li class="nav-item" *ngIf="update_flag==true">
                            <a class="nav-link" (click)="refresh()">
                                <i class="ti-settings"></i>
                                Update
                            </a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1" *ngIf="list_flag==true">
                            <br>



                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field>
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">

                                </div>
                                <div class="col-4 text-right">

                                </div>
                                <div class="col-4"></div>

                            </div>
                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort #sort="matSort">

                                    <ng-container matColumnDef="cc_code">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Code
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.cc_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cc_name">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{element.cc_name}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cc_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Email

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.cc_email}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cc_mob_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Phone


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.cc_mob_no}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cc_add">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Address


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.cc_add}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="manager_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Manager


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.manager_id}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="org_hier_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Org
                                            Hierarchy


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.org_hier_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cc_func">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Module


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.cc_func}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-info"
                                                (click)="view(element)">View</button>&nbsp;

                                            <button class="btn btn-outline-primary" (click)="module(element)"
                                                *ngIf="element['cc_func']==null">Assign
                                                Module</button>&nbsp;


                                            <button class="btn btn-outline-success" href="#tab-3" data-toggle="tab"
                                                (click)="update(element)">Update</button>&nbsp;
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                        <br>

                        <div class="tab-pane fade" *ngIf="update_flag==true" id="tab-3">
                            <div class="col-12 text-right">

                                <button class="btn btn-primary" (click)="listfunc()">Back to list</button>

                            </div>

                            <br>

                            <form name="form" (ngSubmit)="f.form.valid && updateCost()" #f="ngForm">

                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Enter Cost Center Code* </h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="obj['cc_code']"
                                            name="cc_code" #cc_code="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && cc_code.invalid }" disabled
                                            required>

                                        <!-- validation start  -->

                                        <div [class.border_green]="cc_code.dirty && cc_code.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && cc_code.invalid" class="error-message">
                                            <div *ngIf="cc_code.errors?.['required']">Please Enter Cost Center Code
                                            </div>
                                        </div>
                                        <!-- validation end  -->


                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Enter Cost Center Name* </h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="obj['cc_name']"
                                            name="cc_name" #cc_name="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && cc_name.invalid }" disabled
                                            required>

                                        <!-- validation start  -->

                                        <!-- <div [class.border_green]="cc_name.dirty && cc_name.valid">
                                        </div> -->

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && cc_name.invalid" class="error-message">
                                            <div *ngIf="cc_name.errors?.['required']">Please Enter Cost Center Name
                                            </div>
                                        </div>
                                        <!-- validation end  -->

                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Enter Cost Center Email</h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="email" class="form-control" [(ngModel)]="obj['cc_email']"
                                            name="cc_email"  placeholder="Enter Cost Center Email"
                                            >


                                        <!-- validation start  -->

                                        <!-- <div [class.border_green]="cc_email.dirty && cc_email.valid">
                                        </div> -->

                                        <!-- error handling  -->
                                        <!-- <div *ngIf="f.submitted && cc_email.invalid" class="error-message">
                                            <div *ngIf="cc_email.errors?.['required']">Please Enter Cost Center Email
                                            </div>
                                        </div> -->
                                        <!-- validation end  -->
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Enter Cost Center Phone</h6>
                                    </div>
                                    <div class="col-4">
                                        <!-- <input type="text" class="form-control" [(ngModel)]="obj['cc_mob_no']" pattern="[0-9]+"> -->
                                        <input type="number" class="form-control" [(ngModel)]="obj['cc_mob_no']"
                                            maxlength="10" name="cc_mob_no" placeholder="Enter Cost Center Phone"
                                            />

                                        <!-- validation start  -->

                                        <!-- <div [class.border_red]="cc_mob_no.dirty && cc_mob_no.valid">
                                        </div> -->

                                        <!-- error handling  -->
                                        <!-- <div *ngIf="f.submitted && cc_mob_no.invalid" class="error-message">
                                            <div *ngIf="cc_mob_no.errors?.['required']">Please Enter Cost Center Phone
                                            </div>
                                        </div> -->
                                        <!-- validation end  -->


                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Enter Cost Center Address </h6>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="obj['cc_add']"
                                            name="cc_add"  placeholder="Enter Cost Center Address"
                                           >



                                        <!-- validation start  -->

                                        <div>
                                        </div>

                                        <!-- error handling  -->
                                        <!-- <div *ngIf="f.submitted && cc_add.invalid" class="error-message">
                                            <div *ngIf="cc_add.errors?.['required']">Please Enter Cost Center Address
                                            </div>
                                        </div> -->
                                        <!-- validation end  -->








                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Select Manager</h6>
                                    </div>
                                    <div class="col-4">

                                        <ng-select [items]="allEmp" bindLabel="desc" bindValue="le_id"
                                            [multiple]="false" placeholder="Manager" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [(ngModel)]="obj['manager_id']"
                                            [closeOnSelect]="true" name="manager_id" #manager_id="ngModel" placholder="Select"
                                            [ngClass]="{ 'is-invalid': f.submitted && manager_id.invalid }" required>
                                        </ng-select>


                                        <!-- validation start  -->

                                        <div [class.selection-box]="manager_id.dirty && manager_id.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && manager_id.invalid" class="error-message">
                                            <div *ngIf="manager_id.errors?.['required']">Please Select Manager
                                            </div>
                                        </div>
                                        <!-- validation end  -->


                                    </div>

                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Select Cost Center Function</h6>
                                    </div>
                                    <div class="col-4">
                                        <ng-select [items]="moduleArr" bindLabel="module_cd" bindValue="module_cd"
                                            [multiple]="false" placeholder="Cost center function"
                                            [selectableGroup]="true" [(ngModel)]="obj['cc_func']"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="cc_func"
                                            #cc_func="ngModel"
                                            [ngClass]="{ 'is-invalid': f.submitted && cc_func.invalid }" disabled>
                                        </ng-select>


                                        <!-- validation start  -->

                                        <div [class.selection-box]="cc_func.dirty && cc_func.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && cc_func.invalid" class="error-message">
                                            <div *ngIf="cc_func.errors?.['required']">Please Select Cost Center Function
                                            </div>
                                        </div>
                                        <!-- validation end  -->

                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-center">

                                        <h6>Cost Center Hierarchy *</h6>
                                    </div>
                                    <div class="col-1">


                                    </div>
                                    <div class="col-7">

                                        {{orgHierObject[obj['org_hier_leaf_code']]}}
                                    </div>

                                </div>
                                <br>
                                <div class="col-12 text-center">
                                    <!-- <button type="button" class="btn btn-outline-primary"
                                        (click)="updateCost()">Update</button> -->
                                    <button type="button" class="btn btn-outline-primary"
                                       type="submit">Update</button>
                                </div>
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="modal" id="myModal4">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Update Cost Center Function</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Enter Cost Center Code* </h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="obj['cc_code']" disabled>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Enter Cost Center Name* </h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="obj['cc_name']" disabled>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">
                        <h6>Select Cost Center Function*</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="moduleArr" bindLabel="module_cd" bindValue="module_cd" [multiple]="false"
                            placeholder="Cost center function" [selectableGroup]="true" [(ngModel)]="obj['cc_func']"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="status">
                        </ng-select>
                    </div>

                </div>
                <br>
                <br>
                <div class="col-12 text-center">
                    <button type="button" class="btn btn-outline-primary" (click)="updateCostCenterModule()"
                        data-dismiss="modal">Update</button>

                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal5">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Cost Center Info</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Code:</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['cc_code']" disabled>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Name</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['cc_name']" disabled>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">
                        <h6>Cost Center Function:</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="moduleArr" bindLabel="module_cd" bindValue="module_cd" [multiple]="false"
                            disabled placeholder="Cost center function" [selectableGroup]="true"
                            [(ngModel)]="viewCostCenter['cc_func']" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="status">
                        </ng-select>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Email</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['cc_email']" disabled>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Address</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['cc_add']" disabled>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Mobile Number:</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['cc_mob_no']" disabled>
                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-center">

                        <h6>Cost Center Manager:</h6>
                    </div>
                    <div class="col-4">
                        <input type="text" class="form-control" [(ngModel)]="viewCostCenter['manager_id']" disabled>
                    </div>

                </div>
                <br>

            </div>
        </div>
    </div>
</div>
