import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class AppAttendService {

  httpUrl:any;

  employee_id:any;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl+"/hr";
    // console.log(this.httpUrl,"shweta app")
  }
  async getattendancereport(obj :any) {
    const resp = await this.http.post<any>(this.httpUrl+ '/payroll_info/erp_app/getattendreport' ,obj).toPromise().then(res =>{
      return res;
    })
    return resp;
  }
}
