import { Component, OnInit, ViewChildren,QueryList } from '@angular/core';
import Swal from 'sweetalert2';
import { AuctionBidService } from '../service/auction-bid.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-auction-bid-rule',
  templateUrl: './auction-bid-rule.component.html',
  styleUrls: ['./auction-bid-rule.component.css']
})
export class AuctionBidRuleComponent implements OnInit {

  constructor(private spinner : NgxSpinnerService,private aucBidSer:AuctionBidService) { }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();


  erpUser:any
  b_acct_id:any
  user_id:any
  Obj:any
 async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.getAllBidRule()
  }

  ObjToInsert:any = {}
  dataSource:any;
  displayedColumns = ['Sr','Start Amount','End Amount','Cofficient','Action']

  async submit(){
     this.spinner.show()
     console.log("hello")
     let resp = await this.aucBidSer.insertAuctionBid({user_id:this.user_id,b_acct_id:this.b_acct_id,rules:[this.ObjToInsert]})
     if(resp['error']){
      this.spinner.hide()
      Swal.fire('Error','Server Error','error')
    }else{
       this.spinner.hide()
       this.ObjToInsert = {}
      Swal.fire('Success','Submitted Succesfully','success')
      await this.getAllBidRule()
     }

  }
  listOfBidRule = []
  async getAllBidRule(){
    this.spinner.show()
    let resp = await this.aucBidSer.getAuctionBidRule({b_acct_id:this.b_acct_id})
    if(resp['error']){
      this.spinner.hide()
      Swal.fire('Error','Server Error','error')
    }else{
      this.listOfBidRule = resp.data
      // console.log(resp.data)
      this.dataSource = new MatTableDataSource(this.listOfBidRule );
        this.dataSource.sort = this.sort.toArray()[0];
        this.dataSource.paginator = this.paginator.toArray()[0];
      this.spinner.hide()
      // Swal.fire('Success','Submitted Succesfully','success')
    }
  }

  async deleteAuctionBid(element:any) {
    console.log(element)
    this.Obj = Object.assign({}, element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete()
      }
    })
  }
  async finaldelete() {
    this.Obj['b_acct_id'] = this.b_acct_id;
    console.log(this.Obj);
    this.spinner.show()
    let resp = await this.aucBidSer.DelAuctionBidRule(JSON.stringify(this.Obj));
    console.log(this.Obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getAllBidRule()
      Swal.fire('Success...', 'Deleted Successfully.', 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }


  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
