<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- Manage Establishments -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                        Establishments_code1']}}
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" *ngIf="list_flag" (click)="refres()"
                                data-toggle="tab"><i class="ti-bar-chart"></i>
                                <!-- Establishments Detail -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                Establishments_code2']}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-2" *ngIf="create_flag" (click)="refres()"
                                data-toggle="tab"><i class="ti-settings"></i>
                                <!-- Add/Update Establishment -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                Establishments_code57']}}
                            </a>
                        </li>
                        <!--  <li class="nav-item">
                            <a class="nav-link" href="#tab-3" (click)="refres()" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                Update Service Info

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-4" (click)="refres()" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                Update Organisation Info

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-5" (click)="refres()" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                Update Establishments Info

                            </a>
                        </li>-->


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-1">
                            <br>

                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        (click)=open_create()>Add/Update Establishment</button>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Establishments_code5']}}

                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" (change)="changeEmployee()" bindLabel="emp_name"
                                        bindValue="arr_id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code5']}}"
                                        [(ngModel)]="arr_id" [selectableGroup]="false" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <!--  <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getAllArrangementOfParty()">
                                        EMPLOYMENT HISTORY
                                    </button>
                                </div>
                            </div> -->
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code55']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee ID -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code7']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['emp_id']}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Employee Name -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code8']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element['emp_name']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="establishment_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Service Type -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code9']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0015'][element.establishment_type_code]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation_group_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Designation Group -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code10']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0041'][element.designation_group_code]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Designation -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code11']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{ mainService.codeValueShowObj['HR0011'][element.designation_code]}} -->
                                            {{ element.designation_code}}

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cadre_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Cadre -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code12']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0013'][element.cadre_code]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="class_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Class -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code13']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0014'][element.class_code]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_commission_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Pay Commission -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code14']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0016'][element.pay_commission_code]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="grade_pay_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Grade Pay -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code15']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.grade_pay_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="pay_scale_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Pay Band -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code16']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.pay_scale_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="level_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Level Code -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code17']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{ mainService.codeValueShowObj['HR0020'][element.level_code]}} -->
                                            {{element.level_code}}

                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basic_pay">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Basic Pay
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.basic_pay}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="retirement_age">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Retirement Age -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code18']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0012'][element.retirement_age]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_status_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Status -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code19']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_status_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="order_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Order No -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code20']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.order_id}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="inc_month">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Month -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code21']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ mainService.codeValueShowObj['HR0024'][element.inc_month]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="joining_type_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Joining Type -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code22']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.joining_type_code}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="joining_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Joining Date -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code23']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.tempjoining_date}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="notice_period">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <!-- Notice Period -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code24']}}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.notice_period}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="effective_timestamp">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Effective Timestamp
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.valid_from}}
                                        </td>
                                    </ng-container>







                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div class="tab-pane fade show active" *ngIf="create_flag" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-11 text-right">
                                    <button mat-button mat-raised-button class="btn btn-outline-primary"
                                        (click)=open_list()>Back to List</button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                    Establishments_code25']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" disabled bindLabel="emp_name"
                                        (change)="changeEmployee()" bindValue="arr_id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code25']}}"
                                        [(ngModel)]="arr_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="false" name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <!-- <div *ngIf="addEstObj['emp_status_code'] == 'JOINING' || addEstObj['emp_status_code'] == undefined"> -->
                            <div>
                                <br>
                                <br>
                                <mat-horizontal-stepper [linear]="isLinear" #stepper1>

                                    <mat-step [stepControl]="first" *ngIf="joined" label="Joining  Detail"
                                        completed="false">

                                        <br>
                                        <br>

                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Joining Detail:
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Type  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining14']}}<span
                                                    style="color: red;">*</span> :
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0007']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['joining_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining of Service Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining15']}}
                                                <span style="color: red;">*</span> :
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="addEstObj['joining_service_date']">
                                            </div>


                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining16']}}
                                                <span style="color: red;">*</span> :
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="addEstObj['joining_date']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Time -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining18']}}<span
                                                    style="color: red;">*</span> :
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="ts" bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['joining_time']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Order ID  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining17']}}<span
                                                    style="color: red;">*</span> :
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" type="number"
                                                    [(ngModel)]="addEstObj['order_id']">
                                            </div>
                                        </div>


                                        <br>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">


                                                <!--Back-->
                                                <button mat-button mat-raised-button class="btn btn-outline-success"
                                                    (click)="setJoiningData()" >Next</button>
                                                    <!-- (click)="setJoiningData()" matStepperNext>Next</button> -->
                                                <!--Next-->


                                            </div>
                                        </div>
                                    </mat-step>

    <!-- EstablishMent Detail  -->

                                    <mat-step [stepControl]="second" label="Establishment Details" completed="false" >

                                        <br>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Establishment Detail:
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Class: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code13']}} : <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0014']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['class_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Cadre : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code12']}} : <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0013']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['cadre_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Designation: -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code11']}} :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0011']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['designation_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Designation Group : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code10']}}: <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0041']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['designation_group_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>

                                            </div>

                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Retirement Age : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code18']}} :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0012']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['retirement_age']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>


                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Increment Month : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code34']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0024']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['inc_month']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Promotion Interval : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code35']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0034']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['promotion_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Notice Period in Days : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code37']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">

                                                <input class="form-control" type="number" name="field_name" [(ngModel)]="addEstObj['notice_period']">
                                                <!-- <input class="form-control" type="number" name="field_name" [(ngModel)]="test['notice_period']"> -->

                                              <!--  <input class="form-control" type="number" name="field_name" [(ngModel)]="addEstObj['notice_period']"> -->

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Employement Type : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code30']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['establishment_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Pay Commission : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code14']}} :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0016']"
                                                    (change)="changepayband(addEstObj['pay_commission_code'])"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['pay_commission_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code16']}} :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="pay_scale1"
                                                    (change)="changeGradePay1(addEstObj['pay_scale_code'])"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['pay_scale_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Grade Pay: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code15']}} :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="grade_pay1"
                                                    (change)="changelevel(addEstObj['grade_pay_code'])"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['grade_pay_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code31']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="level1"
                                                    (change)="changeGradePay(addEstObj['level_code'])" bindLabel="value"
                                                    bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['level_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code32']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <!-- <h6>{{addEstObj['basic_pay']}}</h6> -->
                                                <ng-select [items]="baseArr1" bindLabel="basic_pay"
                                                    bindValue="index_num" [multiple]="false" placeholder="basicpay"
                                                    [(ngModel)]="addEstObj['basic_pay']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Conveyance Type : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code39']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0043']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['conv_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" (change)="convamt()">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <!-- shweta -->
                                        <div class="row">
                                            <div class="col-4 text-right" for="text">
                                                <!-- Convenience Amount : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code56']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">

                                                <!-- <input class="form-control" type="text" name="field_name" [(ngModel)]="test['conv']"> -->
                                                <input class="form-control" [(ngModel)]="addEstObj['conv_amt']">
                                                <!-- <ng-select
                                                    [multiple]="false" placeholder=""[(ngModel)]="addEstObj['conv_amt']"
                                                     [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" >
                                                </ng-select> -->
                                            </div>
                                        </div>
                                        <br>

<!-- shweta -->
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Uniform Given : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code40']}} <span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0042']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['uniform_ind']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                Work City :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0010']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['work_city']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="this.addEstObj['joining_type_code']=='DEPUTATION'">
                                            <div class="col-4 text-right">
                                                DEPUTATION City  :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0010']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['dep_work_city']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <h6>
                                                    <!-- Select the Date of Salary Start: -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code42']}} <span style="color: red;">*</span>
                                                </h6>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" *ngIf="joined" disabled type="date"
                                                    [(ngModel)]="addEstObj['start_dt']">
                                                <input class="form-control" *ngIf="!joined" type="date"
                                                    [(ngModel)]="addEstObj['start_dt']">
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">

                                                <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    matStepperPrevious>Back</button> &nbsp;&nbsp;
                                                <!--Back-->
                                                <!--Back-->
                                                <button mat-button mat-raised-button class="btn btn-outline-success"
                                                    (click)="applyRule()" >Next</button>
                                                <!--Next-->


                                            </div>
                                        </div>
                                    </mat-step>

                                    <mat-step [stepControl]="third" label="Pay Rule Component" completed="false">

                                        <br>

                                        <br>
                                        <div class="row" *ngIf="joined==false">
                                            <br>
                                            <!-- <div class="col-12"> -->
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Currently Active Pay Component :
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <!-- </div> -->
                                            <br>
                                            <div class="col-12 text-center">
                                                <div class="row">
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- S.No -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code43']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            <!-- Salary Component -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code44']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- Amount -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code45']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            Rate
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            Currency
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- Type -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code46']}}
                                                        </h6>
                                                    </div>


                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            Effective Start Date
                                                        </h6>
                                                    </div>
                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            Status
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="row" *ngFor="let ob of allSalComp; let i = index">

                                                    <div class="col-1 r1 text-center">
                                                        {{i+1}}
                                                    </div>
                                                    <div class="col-2 r1 text-center">

                                                        {{ob.pay_comp}}
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        {{ob.rate}}
                                                    </div>

                                                    <div class="col-1 r1 text-center">
                                                        Monthly
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        INR
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        {{ob.pay_code}}
                                                    </div>

                                                    <div class="col-2 r1 text-center">
                                                        {{ob.valid_from}}
                                                    </div>
                                                    <!-- <div class="col-2 r1 text-center">
                                                        {{ob.valid_upto}}
                                                    </div> -->
                                                    <div class="col-2 r1 text-center">

                                                        {{state[ob.status]}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                        <hr>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    New Proposed Pay Component 1:
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- S.No -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code43']}}
                                                </h6>
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    <!-- Salary Component -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code44']}}
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- Amount -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code45']}}
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    Rate
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    Currency
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- Type -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code46']}}
                                                </h6>
                                            </div>


                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    Effective Start Date
                                                </h6>
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    Status
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="row" *ngFor="let ob of AllFixPay; let i = index">

                                            <div class="col-1 r1 text-center">
                                                {{i+1}}
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                {{ob.pay_comp}}
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                {{ob.rate}}
                                            </div>

                                            <div class="col-1 r1 text-center">
                                                Monthly
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                INR
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                {{ob.pay_code}}
                                            </div>
                                            <!-- <div class="col-2 r1 text-center">
                                                        {{addEstObj.start_dt'}}
                                                    </div> -->
                                            <div class="col-2 r1 text-center">
                                                {{addEstObj['start_dt']}}
                                                <!--   <input class="form-control" type="date" [(ngModel)]="addEstObj['start_dt']"> -->
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                <input class="form-control" type="checkbox" [(ngModel)]="ob['status']">
                                            </div>
                                            <!-- <div class="col-2 r1 text-center">
                                                        <button class="btn btn-danger" (click)="deleteFixPay(i)">
                                                            Delete
                                                        </button>
                                                    </div> -->
                                        </div>
                                        <br>
 <div *ngIf="PRINT_flag2">
    <div class="row">
    <div class="col-12 text-center">
        <h6 id="h" style="color: brown; min-height: 20px;">
            Currently Active Leave of Employee
        </h6>
    </div>
</div>
 <div class="row">
    <div class="col-3 r1 text-center">
        <h6>S.No</h6>
    </div>
    <div class="col-3 r1 text-center">
        <h6>Leave Type</h6>
    </div>
    <div class="col-3 r1 text-right">
        <h6>Number of Leaves</h6>
    </div>


    <!-- <div class="col-3 r1 text-center">
        <h6>ADD</h6>
    </div> -->
</div>
<br>
 <div class="row" *ngFor="let ob of latestLeaveArr; let i = index">
    <div class="col-3 r1 text-center">
        {{i+1}}
    </div>
    <div class="col-3 r1 text-center">
          {{ob.leave_code}}
    </div>
    <div class="col-3 r1 text-right">
        <input class="form-control" [(ngModel)]="latestLeaveArr[i]['num_of_leaves']" disabled>

    </div>
    <!-- <div class="col-3 r1 text-center">
        <button class="btn btn-danger" (click)="delete(ob)">Delete</button>
    </div> -->


</div>

<br>
<br>
</div>
<div *ngIf="PRINT_flag2">
 <div class="row">
    <div class="col-12 text-center">
        <h6 id="h" style="color: brown; min-height: 20px;">
            New Proposed Leaves of the Employee
        </h6>
    </div>
</div>

<br>




<br>
<div class="row">
    <div class="col-3 r1 text-center">
        <h6>S.No</h6>
    </div>
    <div class="col-3 r1 text-center">
        <h6>Leave Type</h6>
    </div>
    <div class="col-3 r1 text-right">
        <h6>Number of Leaves</h6>
    </div>


    <div class="col-3 r1 text-center">
        <h6>Delete</h6>
    </div>
</div>
<br>
</div>

<div *ngIf="PRINT_flag1">
    <div class="row">
        <div class="col-12 text-center">
            <h6 id="h" style="color: brown; min-height: 20px;">
                New Proposed Leaves of the Employee
            </h6>
        </div>
    </div>

    <br>




    <br>
    <div class="row">
        <div class="col-3 r1 text-center">
            <h6>S.No</h6>
        </div>
        <div class="col-3 r1 text-center">
            <h6>Leave Type</h6>
        </div>
        <div class="col-3 r1 text-right">
            <h6>Number of Leaves</h6>
        </div>


        <div class="col-3 r1 text-center">
            <h6>Delete</h6>
        </div>
    </div>
    <br>

    <div class="row" *ngFor="let ob of allLeaveRule; let i = index">
        <div class="col-3 r1 text-center">
            {{i+1}}
        </div>
        <div class="col-3 r1 text-center">
              {{ob.leave_code}}
        </div>
        <div class="col-3 r1 text-right">
            <input class="form-control" [(ngModel)]="allLeaveRule[i]['num_of_leaves']">

        </div>
        <div class="col-3 r1 text-center">
            <button class="btn btn-danger" (click)="deleteLeave(ele,i)">Delete</button>
        </div>
    </div>
    </div>
 <div *ngIf="PRINT_flag2">
<div class="row" *ngFor="let ob of allLeaveRule; let i = index">
    <div class="col-3 r1 text-center">
        {{i+1}}
    </div>
    <div class="col-3 r1 text-center">
        {{ob.leave_code}}
    </div>
    <div class="col-3 r1 text-right">
        <input class="form-control" [(ngModel)]="allLeaveRule[i]['num_of_leaves']">

    </div>

    <!-- <div class="col-3 r1 text-center">
        <button class="btn btn-danger" (click)="delete(ob)">Delete</button>
    </div> -->
    <div class="col-3 r1 text-center">
        <button class="btn btn-danger" (click)="deleteLeave(ele,i)">Delete</button>
    </div>
</div>
</div>
<br>


                                        <div class="row">
                                            <div class="col-12 text-center">

                                                <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    matStepperPrevious>Back</button> &nbsp;&nbsp;
                                                <!--Back-->
                                                <button mat-button mat-raised-button class="btn btn-outline-success"
                                                  (click)="nextStep2()"  >Next</button>
                                                <!--Next-->

                                            </div>
                                        </div>



                                    </mat-step>

                                    <mat-step [stepControl]="fourth" label="Done" completed="false">

                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Joining Detail :
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Type  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining14']}}  :<span
                                                    style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0007']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['joining_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining of Service Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining15']}}
                                                 :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" disabled type="date"
                                                    [(ngModel)]="addEstObj['joining_service_date']">
                                            </div>


                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining16']}}
                                                 :<span style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" disabled type="date"
                                                    [(ngModel)]="addEstObj['joining_date']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Time -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining18']}} :<span
                                                    style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="ts" disabled bindLabel="value" bindValue="code"
                                                    [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['joining_time']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Joining Order ID  -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-Joining17']}} : <span
                                                    style="color: red;">*</span>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" disabled type="number"
                                                    [(ngModel)]="addEstObj['order_id']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Establishment Detail :
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Class: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code13']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0014']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['class_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Cadre : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code12']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0013']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['cadre_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>

                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Designation: -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code11']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0011']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['designation_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Designation Group : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code10']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0041']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['designation_group_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Retirement Age : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code18']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0012']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['retirement_age']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Increment Month : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code34']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0024']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['inc_month']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">

                                            <div class="col-4 text-right">
                                                <!-- Promotion Interval : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code35']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0034']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['promotion_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Notice Period in Days : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code37']}}
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" disabled type="number"
                                                    [(ngModel)]="addEstObj['notice_period']">
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">

                                            <div class="col-4 text-right">
                                                <!-- Employement Type : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code30']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['establishment_type_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name" disabled>
                                                </ng-select>


                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">

                                            <div class="col-4 text-right">
                                                <!-- Pay Commission : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code14']}}
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0016']"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['pay_commission_code']"
                                                    [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                    [closeOnSelect]="true" name="field_name" disabled>
                                                </ng-select>


                                            </div>
                                        </div>
                                        <br>



                                        <div class="row">

                                            <div class="col-4 text-right">
                                                <!-- Pay Band: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code16']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="pay_scale"
                                                    (change)="changeGradePay1(addEstObj['pay_scale_code'])"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['pay_scale_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" disabled>
                                                </ng-select>


                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Grade Pay: -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code15']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="grade_pay1"
                                                    (change)="changelevel(addEstObj['grade_pay_code'])"
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['grade_pay_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" disabled>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Level : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code31']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="level1"
                                                    (change)="changeGradePay(addEstObj['level_code'])" bindLabel="value"
                                                    bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['level_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" disabled>
                                                </ng-select>


                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Basic Pay : -->{{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code32']}}
                                            </div>
                                            <div class="col-4">
                                                <!-- <h6>{{addEstObj['basic_pay']}}</h6> -->
                                                <ng-select [items]="baseArr1" bindLabel="basic_pay"
                                                    bindValue="index_num" [multiple]="false" placeholder="basicpay"
                                                    [(ngModel)]="addEstObj['basic_pay']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name" disabled>
                                                </ng-select>


                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Conveyance Type : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code39']}}
                                            </div>
                                            <div class="col-4">


                                                <ng-select [items]="mainService.codeValueTechObj['HR0043']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['conv_code']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name"(change)="convamt()">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <br>
<!-- shweta -->
                                        <div class="row">
                                            <div class="col-4 text-right" for="text">
                                                <!-- Convenience Amount : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code56']}}
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" type="text"name="feild_name" [(ngModel)]="addEstObj['conv_amt']">

                                                <!-- <input class="form-control" type="text"name="feild_name" [(ngModel)]="test['conv']"> -->
                                                <!--  <ng-select
                                                 placeholder=""
                                                     [(ngModel)]="addEstObj['conv_amt']"

                                                    name="field_name" >
                                                </ng-select>  -->
                                            </div>
                                        </div>
                                        <br>
<!-- shweta -->
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                <!-- Uniform Given : -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code40']}}
                                            </div>
                                            <div class="col-4">

                                                <ng-select [items]="mainService.codeValueTechObj['HR0042']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['uniform_ind']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>



                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-4 text-right">
                                                Work City:
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0010']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['work_city']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="false"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row" *ngIf="this.addEstObj['joining_type_code']=='DEPUTATION'">
                                            <div class="col-4 text-right">
                                                DEPUTATION City :
                                            </div>
                                            <div class="col-4">
                                                <ng-select [items]="mainService.codeValueTechObj['HR0010']" disabled
                                                    bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                    [(ngModel)]="addEstObj['dep_work_city']" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="field_name">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <br>

                                        <br>
                                        <div class="row" *ngIf="joined==false">

                                            <!-- <div class="row"> -->
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Currently Active Pay Component :
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <!-- </div> -->

                                            <div class="col-12 text-center">
                                                <div class="row">
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- S.No -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code43']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            <!-- Salary Component -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code44']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- Amount -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code45']}}
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            Rate
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            Currency
                                                        </h6>
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        <h6>
                                                            <!-- Type -->
                                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                            Establishments_code46']}}
                                                        </h6>
                                                    </div>


                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            Effective Start Date
                                                        </h6>
                                                    </div>
                                                    <div class="col-2 r1 text-center">
                                                        <h6>
                                                            Status
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="row" *ngFor="let ob of allSalComp; let i = index">

                                                    <div class="col-1 r1 text-center">
                                                        {{i+1}}
                                                    </div>
                                                    <div class="col-2 r1 text-center">

                                                        {{ob.pay_comp}}
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        {{ob.rate}}
                                                    </div>

                                                    <div class="col-1 r1 text-center">
                                                        Monthly
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        INR
                                                    </div>
                                                    <div class="col-1 r1 text-center">
                                                        {{ob.pay_code}}
                                                    </div>

                                                    <div class="col-2 r1 text-center">
                                                        {{ob.valid_from}}
                                                    </div>
                                                    <div class="col-2 r1 text-center">
                                                        {{state[ob.status]}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                        <hr>
                                        <br>
                                        <div class="row">
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                            <div class="col-2 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    New Proposed Pay Component :
                                                </h6>
                                            </div>
                                            <div class="col-5">
                                                <hr class="hrline">
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- S.No -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code43']}}
                                                </h6>
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    <!-- Salary Component -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code44']}}
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- Amount -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code45']}}
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    Rate
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    Currency
                                                </h6>
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                <h6>
                                                    <!-- Type -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code46']}}
                                                </h6>
                                            </div>


                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    Effective Start Date
                                                </h6>
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                <h6>
                                                    Status
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="row" *ngFor="let ob of AllFixPay; let i = index">

                                            <div class="col-1 r1 text-center">
                                                {{i+1}}
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                {{ob.pay_comp}}
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                {{ob.rate}}
                                            </div>

                                            <div class="col-1 r1 text-center">
                                                Monthly
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                INR
                                            </div>
                                            <div class="col-1 r1 text-center">
                                                {{ob.pay_code}}
                                            </div>

                                            <div class="col-2 r1 text-center">
                                                {{addEstObj['start_dt']}}
                                            </div>
                                            <div class="col-2 r1 text-center">
                                                {{state[ob.status]}}
                                            </div>

                                        </div>
                                        <br>
                                        <!--  -->

                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <h6 *ngIf="!joined">
                                                    You are update Employee Arrangement to your organisation. Hope that
                                                    you have checked all the information. Once you will click SUBMIT,
                                                    your employee will be able to draw salary with current detail and
                                                    will be able to do lot of things.
                                                    <!-- {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code52']}} -->
                                                </h6>
                                                <h6 *ngIf="joined">
                                                    You are adding a new Employee to your organisation. Hope that you
                                                    have checked all the information. Once you will click SUBMIT, your
                                                    employee will be able to draw salary and will be able to do lot of
                                                    things.
                                                    <!--  {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code52']}} -->
                                                </h6>
                                            </div>
                                        </div>
                                        <div *ngIf="PRINT_flag2">
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    Currently Active Leave of Employee
                                                </h6>
                                            </div>
                                        </div>
                                         <div class="row">
                                            <div class="col-3 r1 text-center">
                                                <h6>S.No</h6>
                                            </div>
                                            <div class="col-3 r1 text-center">
                                                <h6>Leave Type</h6>
                                            </div>
                                            <div class="col-3 r1 text-right">
                                                <h6>Number of Leaves</h6>
                                            </div>


                                            <!-- <div class="col-3 r1 text-center">
                                                <h6>ADD</h6>
                                            </div> -->
                                        </div>
                                        <br>
                                         <div class="row" *ngFor="let ob of latestLeaveArr; let i = index">
                                            <div class="col-3 r1 text-center">
                                                {{i+1}}
                                            </div>
                                            <div class="col-3 r1 text-center">
                                                  {{ob.leave_code}}
                                            </div>
                                            <div class="col-3 r1 text-right">
                                                <input class="form-control" [(ngModel)]="latestLeaveArr[i]['num_of_leaves']" disabled>

                                            </div>
                                            <!-- <div class="col-3 r1 text-center">
                                                <button class="btn btn-danger" (click)="delete(ob)">Delete</button>
                                            </div> -->


                                        </div>
                                    </div>
                                        <br>
                                        <br>
                                        <div *ngIf="PRINT_flag2">
                                         <div class="row">
                                            <div class="col-12 text-center">
                                                <h6 id="h" style="color: brown; min-height: 20px;">
                                                    New Proposed Leaves of the Employee
                                                </h6>
                                            </div>
                                        </div>

                                        <br>




                                        <br>
                                        <div class="row">
                                            <div class="col-3 r1 text-center">
                                                <h6>S.No</h6>
                                            </div>
                                            <div class="col-3 r1 text-center">
                                                <h6>Leave Type</h6>
                                            </div>
                                            <div class="col-3 r1 text-right">
                                                <h6>Number of Leaves</h6>
                                            </div>


                                            <div class="col-3 r1 text-center">
                                                <h6>Delete</h6>
                                            </div>
                                        </div>
                                        <br>
                                        </div>

                                        <div *ngIf="PRINT_flag1">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <h6 id="h" style="color: brown; min-height: 20px;">
                                                        New Proposed Leaves of the Employee
                                                    </h6>
                                                </div>
                                            </div>

                                            <br>




                                            <br>
                                            <div class="row">
                                                <div class="col-3 r1 text-center">
                                                    <h6>S.No</h6>
                                                </div>
                                                <div class="col-3 r1 text-center">
                                                    <h6>Leave Type</h6>
                                                </div>
                                                <div class="col-3 r1 text-right">
                                                    <h6>Number of Leaves</h6>
                                                </div>


                                                <div class="col-3 r1 text-center">
                                                    <h6>Delete</h6>
                                                </div>
                                            </div>
                                            <br>

                                            <div class="row" *ngFor="let ob of allLeaveRule; let i = index">
                                                <div class="col-3 r1 text-center">
                                                    {{i+1}}
                                                </div>
                                                <div class="col-3 r1 text-center">
                                                      {{ob.leave_code}}
                                                </div>
                                                <div class="col-3 r1 text-right">
                                                    <input class="form-control" [(ngModel)]="allLeaveRule[i]['num_of_leaves']">

                                                </div>
                                                <div class="col-3 r1 text-center">
                                                    <button class="btn btn-danger" (click)="deleteLeave(ele,i)">Delete</button>
                                                </div>
                                            </div>
                                            </div>

                                         <div *ngIf="PRINT_flag2">
                                        <div class="row" *ngFor="let ob of allLeaveRule; let i = index">
                                            <div class="col-3 r1 text-center">
                                                {{i+1}}
                                            </div>
                                            <div class="col-3 r1 text-center">
                                                {{ob.leave_code}}
                                            </div>
                                            <div class="col-3 r1 text-right">
                                                <input class="form-control" [(ngModel)]="allLeaveRule[i]['num_of_leaves']">

                                            </div>

                                            <!-- <div class="col-3 r1 text-center">
                                                <button class="btn btn-danger" (click)="delete(ob)">Delete</button>
                                            </div> -->
                                            <div class="col-3 r1 text-center">
                                                <button class="btn btn-danger" (click)="deleteLeave(ele,i)">Delete</button>
                                            </div>
                                        </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button class="btn btn-success" (click)="allDone()" >
                                                    <!-- SUBMIT -->
                                                    {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                    Establishments_code41']}}
                                                </button>
                                            </div>
                                        </div>
                                        <br>
                                        <br>
                                        <br>
                                        <div class="row">
                                            <div class="col-12 text-center">
                                                <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                    matStepperPrevious>Back</button> &nbsp;&nbsp;
                                                <!--Back-->

                                            </div>
                                        </div>

                                    </mat-step>


                                </mat-horizontal-stepper>

                            </div>
                        </div>




                        <div class="tab-pane fade" id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code26']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name"
                                        (change)="changeEmployeeOnService()" bindValue="emp_id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code26']}}"
                                        [(ngModel)]="emp_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>



                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h6>
                                        <!-- BASIC DETAIL -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Establishments_code29']}}
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <!-- Order ID-->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code54']}}:
                                    {{updateServiceObj['order_id']}}
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <!-- Class: -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code13']}}
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0014']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateServiceObj['class_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                                <div class="col-3 text-right">
                                    <!-- Cadre : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code12']}}
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0013']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateServiceObj['cadre_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>

                            <br>


                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-info" (click)="updateServiceInfo()">
                                        <!-- UPDATE EMPLOYMENT -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Establishments_code53']}}
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-4">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code26']}}
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="newallEmplyees" bindLabel="emp_name"
                                        (change)="changeEmployeeOnOrg()" bindValue="emp_id" [multiple]="false"
                                        placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code26']}}"
                                        [(ngModel)]="emp_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>



                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <h6>
                                        <!-- BASIC DETAIL -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Establishments_code29']}}
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <!-- Order ID-->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code54']}}:
                                    {{updateOrgObj['order_id']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">

                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code11']}}
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0011']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateOrgObj['designation_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Designation Group : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code10']}}
                                </div>
                                <div class="col-4">


                                    <ng-select [items]="mainService.codeValueTechObj['HR0041']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateOrgObj['designation_group_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>

                            </div>
                            <br>




                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Increment Month : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code34']}}
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0024']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateOrgObj['inc_month']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Promotion Interval : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code35']}}
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0034']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateOrgObj['promotion_type_code']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>


                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-4 text-right">
                                    <!-- Notice Period in Days : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code37']}}
                                </div>
                                <div class="col-4">
                                    <input class="form-control" type="number"
                                        [(ngModel)]="updateOrgObj['notice_period']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Retirement Age : -->
                                    {{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code18']}}
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="mainService.codeValueTechObj['HR0012']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder=""
                                        [(ngModel)]="updateOrgObj['retirement_age']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>




                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-info" (click)="updateOrgInfo()">
                                        <!-- UPDATE EMPLOYMENT -->
                                        {{mainService.allLableShowObjHR[mainService.language_cd+'
                                        Establishments_code53']}}
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div class="tab-pane fade" id="tab-5">
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first" label="update" completed="false">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Select Employee : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code26']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="newallEmplyees" bindLabel="emp_name"
                                                (change)="changeEmployeeOnEstUpdate()" bindValue="emp_id"
                                                [multiple]="false"
                                                placeholder="{{mainService.allLableShowObjHR[mainService.language_cd+' Establishments_code26']}}"
                                                [(ngModel)]="emp_id" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>

                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h6>
                                                <!-- BASIC DETAIL -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code29']}}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <!-- Order ID-->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code54']}}: {{updateObj['order_id']}}
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employement Type : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code30']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['establishment_type_code']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Pay Commission : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code14']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0016']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['pay_commission_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Pay Band: -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code16']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="pay_scale"
                                                (change)="changeGradePay1(updateObj['pay_scale_code'])"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['pay_scale_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Grade Pay: -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code15']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="grade_pay1"
                                                (change)="changelevel(updateObj['grade_pay_code'])" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['grade_pay_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Level : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code31']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="level1"
                                                (change)="changeGradePay(updateObj['level_code'])" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['level_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Basic Pay : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code32']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="baseArr1" bindLabel="value" bindValue="code"
                                                [multiple]="false" placeholder="basicpay"
                                                [(ngModel)]="updateObj['basic_pay']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Conveyance Type : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code39']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0043']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['conv_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Uniform Given : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code40']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0042']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['uniform_ind']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>
                                                <!-- Select the Date of Salary Start: -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code42']}}
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="date" [(ngModel)]="updateObj['start_dt']">
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-info" (click)="saveArangmentOnUpdate()">
                                                <!-- SUBMIT -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code41']}}
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-2 r1 text-center">
                                            <h6>
                                                <!-- S.No -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code43']}}
                                            </h6>
                                        </div>
                                        <div class="col-3 r1 text-center">
                                            <h6>
                                                <!-- Salary Component -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code44']}}
                                            </h6>
                                        </div>
                                        <div class="col-3 r1 text-right">
                                            <h6>
                                                <!-- Amount -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code45']}}
                                            </h6>
                                        </div>
                                        <div class="col-2 r1 text-right">
                                            <h6>
                                                <!-- Type -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code46']}}
                                            </h6>
                                        </div>


                                        <div class="col-2 r1 text-center">
                                            <h6>
                                                <!-- Delete -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code47']}}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let ob of AllFixPayOnUpdate; let i = index">
                                        <div class="col-2 r1 text-center">
                                            {{i+1}}
                                        </div>
                                        <div class="col-3 r1 text-center">
                                            {{ob.pay_component_code}}
                                        </div>
                                        <div class="col-3 r1 text-right">
                                            {{ob.pay_component_amt}}
                                        </div>
                                        <div class="col-2 r1 text-right">
                                            {{ob.pay_code}}
                                        </div>



                                        <div class="col-2 r1 text-center">
                                            <button class="btn btn-danger" (click)="deleteFixPayOnUpdate(i)">
                                                <!-- Delete -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code47']}}
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">


                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-success"
                                                matStepperNext>Next</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="second" label="done" completed="false">
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Employement Type : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code30']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0015']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['establishment_type_code']"
                                                [selectableGroup]="true" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Pay Commission : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code14']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0016']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['pay_commission_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Pay Band: -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code16']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="pay_scale"
                                                (change)="changeGradePay1(updateObj['pay_scale_code'])"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['pay_scale_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Grade Pay: -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code15']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="grade_pay1"
                                                (change)="changelevel(updateObj['grade_pay_code'])" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['grade_pay_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Level : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code31']}}
                                        </div>
                                        <div class="col-4">
                                             <ng-select [items]="grade_pay1"
                                                (change)="changelevel(updateObj['grade_pay_code'])" bindLabel="value"
                                                bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['grade_pay_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Basic Pay : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code32']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="baseArr1" bindLabel="value" bindValue="code"
                                                [multiple]="false" placeholder="basicpay"
                                                [(ngModel)]="updateObj['basic_pay']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Conveyance Type : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code39']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0043']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['conv_code']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <!-- Uniform Given : -->
                                            {{mainService.allLableShowObjHR[mainService.language_cd+'
                                            Establishments_code40']}}
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="mainService.codeValueTechObj['HR0042']"
                                                bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                                                [(ngModel)]="updateObj['uniform_ind']" [selectableGroup]="true"
                                                [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                name="field_name" disabled>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <br>
                                    <hr>
                                    <br>
                                    <div class="row">
                                        <div class="col-4 text-right">
                                            <h6>
                                                <!-- Select the Date of Salary Start: -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code42']}}
                                            </h6>
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" type="date" [(ngModel)]="updateObj['start_dt']"
                                                disabled>
                                        </div>
                                    </div>
                                    <br>

                                    <br>
                                    <div class="row">
                                        <div class="col-2 r1 text-center">
                                            <h6>
                                                <!-- S.No -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code43']}}
                                            </h6>
                                        </div>
                                        <div class="col-3 r1 text-center">
                                            <h6>
                                                <!-- Salary Component -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code44']}}
                                            </h6>
                                        </div>
                                        <div class="col-3 r1 text-right">
                                            <h6>
                                                <!-- Amount -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code45']}}
                                            </h6>
                                        </div>
                                        <div class="col-2 r1 text-right">
                                            <h6>
                                                <!-- Type -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code46']}}
                                            </h6>
                                        </div>


                                        <div class="col-2 r1 text-center">
                                            <h6>
                                                <!-- Delete -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code47']}}
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let ob of AllFixPayOnUpdate; let i = index">
                                        <div class="col-2 r1 text-center">
                                            {{i+1}}
                                        </div>
                                        <div class="col-3 r1 text-center">
                                            {{ob.pay_component_code}}
                                        </div>
                                        <div class="col-3 r1 text-right">
                                            {{ob.pay_component_amt}}
                                        </div>
                                        <div class="col-2 r1 text-right">
                                            {{ob.pay_code}}
                                        </div>



                                        <div class="col-2 r1 text-center">
                                            <button class="btn btn-danger" (click)="deleteFixPayOnUpdate(i)">
                                                <!-- Delete -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code47']}}
                                            </button>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-info" (click)="updateArangment()">
                                                <!-- UPDATE EMPLOYMENT -->
                                                {{mainService.allLableShowObjHR[mainService.language_cd+'
                                                Establishments_code53']}}
                                            </button>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">

                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperPrevious>Back</button> &nbsp;&nbsp;
                                            <!--Back-->
                                            <!--Back-->



                                        </div>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
