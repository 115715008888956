<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Legal Entity</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-7-1" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-line-chart"></i>&nbsp;Legal Entity List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-7-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i> Create
                                Legal Entity
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"></i> Update
                                Account
                            </a>
                        </li> -->
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-7-1">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Legal Entity Type : </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="le_type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="Select Type..." [(ngModel)]="Obj['list_party_type']"
                                        [selectableGroup]="true" (change)="changeList()"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                            </div>



                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>



                                    <ng-container matColumnDef="le_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Legal
                                            Entity Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.le_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="login_user_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            <span *ngIf="Obj['list_party_type']=='IND'">Login User Id</span>
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <span *ngIf="Obj['list_party_type']=='IND'">{{element.login_user_id}}</span>

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nick_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            <span *ngIf="Obj['list_party_type']=='IND'">Nick Name</span>

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <span *ngIf="Obj['list_party_type']=='IND'">{{element.nick_name}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="legal_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Legal Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.party_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="contact_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Email
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.contact_email}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Phone Number
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.phone_no}} </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button (click)="viewDetails(element)"
                                                class="btn btn-primary">View</button>&nbsp;&nbsp;
                                            <button (click)="open_update(element)"
                                                class="btn btn-success">Update</button>&nbsp;&nbsp;
                                            <button (click)="delete()" class="btn btn-danger">Delete</button>
                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>


                        <div class="tab-pane fade" id="tab-7-2">
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Legal Entity Type : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="le_type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="Select Type..." [(ngModel)]="Obj['party_type']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Email : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="email" placeholder="Enter Email" [(ngModel)]="Obj['contact_email']"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Mobile Number : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="text"
                                        onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;"
                                        maxlength="10" placeholder="Enter Mobile Number"
                                        [(ngModel)]="Obj['contact_phone']" class="form-control">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['party_type']=='IND'">
                            <div class="row" *ngIf="Obj['party_type']=='IND'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Nick Name : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" required min="10" max="20" placeholder="Enter Nick Name"
                                        [(ngModel)]="Obj['nick_name']" class="form-control">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Password : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="password" placeholder="Enter Password"
                                        [(ngModel)]="Obj['ident_verify_secret']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Legal Entity Name :</h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" placeholder="Legal Entity Name" [(ngModel)]="Obj['party_name']"
                                        class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br *ngIf="Obj['party_type']=='IND'">

                            <div class="row" *ngIf="Obj['party_type']=='IND'">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Date Of Birth : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['dob']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <!-- <div class="row" >
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span></h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br> -->
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button (click)="submit()" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-3">
                            <br>

                        </div> -->
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>