import { Component, OnInit, ViewChild, QueryList, ViewChildren, Query } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { ZoneService } from '../service/zone.service';
import { MatTableDataSource } from '@angular/material/table';
import swal from 'sweetalert2';
import { AuthenticationService } from '../../authentication.service';
import { DashboardService } from '../service/dashboard.service';
import { DatePipe } from '@angular/common';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { FileExportService } from '../service/file-export.service';
import { EmpTrackingService } from '../service/emp-tracking.service';
import Swal from 'sweetalert2';

declare var $: any;
declare const google: any;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-tracking-dashboard',
  templateUrl: './tracking-dashboard.component.html',
  styleUrls: ['./tracking-dashboard.component.css']
})
export class TrackingDashboardComponent implements OnInit {
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  datasource: any;
  center!: google.maps.LatLngLiteral ;
  location_area:any
  Obj_test:any = {}
  Obj :any= {}
  paths: any = []
  zoom: any;
  lat: any = 26.850000;
  lng: any = 80.949997;
  zone_name: any = {}
  zone_count: any = {}
  emp_count: any = {}
  list_flag: any = false;
  dashboard: any = true;
  displayedColumns:any = []
  displayedColumns1:any = ['id', 'location', 'zone_name', 'zone_cd', 'action'];
  displayedColumns2:any = ['id', 'emp_name', 'emp_phone_no', 'user_id', 'zone_name', 'action'];
  displayedColumns3:any = ['id', 'emp_name', 'emp_phone_no', 'user_id', 'zone_name', 'currentdate'];

  myDate = new Date();
  active_emp_count = {};
  download_list: boolean = false
  markerPositions: google.maps.LatLngLiteral[] = [];
  vertices !:  google.maps.LatLngLiteral[]
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  constructor(private spinner: NgxSpinnerService, public zone: ZoneService, public auth: AuthenticationService,
    public dashboardservice: DashboardService, private datePipe: DatePipe, private employeeservice: EmpTrackingService,
    public excel: FileExportService) { }

  ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    this.getcount();
  }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  dashboard1() {
    this.list_flag = false;
    this.dashboard = true
  }
  // //////get all count detail for dashboard/////////////
  async getcount() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['currentdate'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    // this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    this.spinner.show()
    var resp :any= await this.dashboardservice.getcount(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      this.zone_count = resp['data'][0][0]['zone_count']
      this.emp_count = resp['data'][1][0]['employee_count']
      if (resp['data'][2].length <= 0) {
        this.active_emp_count = 0
      } else {
        this.active_emp_count = resp['data'][2].length

      }

      console.log(this.zone_count)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  // //////end count///////////////


  ///////get user detail///////
  all_user = []
  async userdetail() {
    this.dashboard = false;
    this.list_flag = true
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp :any= await this.dashboardservice.getalluserdetail(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      this.displayedColumns = this.displayedColumns2
      this.datasource = new MatTableDataSource(resp['data'])
      this.all_user = resp['data']
      this.download_list = true
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  ///////end user detail
  // //Active user //

  async activeuser() {
    this.dashboard = false;
    this.list_flag = true;
    this.download_list = false

    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['currentdate'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    // this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    this.spinner.show()
    var resp :any= await this.dashboardservice.getactiveuserdetail(JSON.stringify(obj))
    // console.log(resp) ;
    if (resp['error'] == false) {
      this.displayedColumns = this.displayedColumns3
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }


  // ///inactive user


  // /////////zone  start///////////////////////
  onPolyClick($event: any) {
    // console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
  }
  viewZone() {
    this.dashboard = false;
    this.list_flag = true
    this.getLocationList()
  }
  async getLocationList() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    // console.log(obj)
    this.download_list = false

    var resp:any = await this.zone.getLocationData(JSON.stringify(obj));
    console.log(resp, 'list')
    if (resp['error'] == false) {
      this.displayedColumns = this.displayedColumns1
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }

  async view_on_map(element:any) {
    let dummy :any= []
    let dummy2:any = []
    this.paths = null;
    this.zoom = null
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_id'] = element['zone_id']
    this.zone_name = element.zone_name
    // console.log(obj);
    var resp:any = await this.zone.getcordinateLocationData(JSON.stringify(obj));
    // console.log(resp)
    if (resp['error'] == true) {
      swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      dummy = resp['data']
      // console.log(dummy)
    }
    for (let i = 0; i < dummy.length; i++) {
      let obj2:any = {}

      obj2['lat'] = dummy[i]['loc_gis_x_long']
      obj2['lng'] = dummy[i]['loc_gis_y_lat']

      this.lat = obj2['lat']
      this.lng = obj2['lng']
      dummy2.push(obj2)
    }

    this.center =   {
      lat: 26.851991 , lng: 80.954479
    };
    // this.paths  = [
    //   {lat: 26.851991, lng: 80.954479},
    //   {lat: 26.852013, lng: 80.954675},
    //   {lat: 26.851907, lng: 80.954534},
    // ];


    this.paths = dummy2
    console.log(this.paths , "pathssss")
    this.Obj['loc_geo_calc_value'] = google.maps.geometry.spherical.computeArea(this.paths)
    // console.log(this.Obj['loc_geo_calc_value'])
     // this.location_area = parseFloat(this.Obj['loc_geo_calc_value']).toFixed(2);
    this.zoom = 10
    $('#myModal2').modal('show');
  }

  addMarker(event: google.maps.MapMouseEvent) {
    console.log("added marker");

    this.markerPositions.push(event.latLng!.toJSON());

  }

  ///////////zone end/////////////////////////////
  // ///////////////print userlist //////////////


  print() {
    this.spinner.show();
    let account_name = this.erpUser['account_name'];
    let account_short_name = this.erpUser['account_short_name'];
    let txt = account_name + "(" + account_short_name + ")";

    var dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 500, y2: 0, lineWidth: 0.2 }] }); //x2:760
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });



    let tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [
          [{ colSpan: 4, text: 'Zone Wise User List', alignment: 'center', bold: true }, {}, {}, {}],
          [{ text: 'User Name', bold: true, alignment: 'center' }, { text: 'Phone.No', alignment: 'center', bold: true },
          { text: 'user_id', alignment: 'center', bold: true }, { text: 'Zone Name', alignment: 'center', bold: true }]
        ],
      }
    };

    dd.content.push(tbl1);
    let tbl2:any = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [],
      }
    };
    this.all_user.map(x => {
      let arr = []
      arr.push({ text: x['emp_name'], bold: true, alignment: 'center' })
      arr.push({ text: x['emp_phone_no'], alignment: 'center' })
      arr.push({ text: x['user_id'], alignment: 'center' })
      arr.push({ text: x['zone_name'], alignment: 'center' })
      tbl2.table.body.push(arr);
    })


    dd.content.push(tbl2);
    this.spinner.hide();
    pdfMake.createPdf(dd).download('Employee List' + '.pdf');

  }
  // ////////////////exce
  export() {
    var exp = []
    this.spinner.show();
    for (let i = 0; i < this.all_user.length; i++) {
      var obj:any = new Object();
      obj['S.NO'] = i + 1;
      obj['Employee Name'] = this.all_user[i]['emp_name']
      obj['Phone No'] = this.all_user[i]['emp_phone_no']
      obj['User Id'] = this.all_user[i]['user_id']
      obj['Zone Name'] = this.all_user[i]['zone_name']
      exp.push(obj);
    }
    this.spinner.hide();
    swal.fire('Suceess', 'Execl Exported Successfully', 'success')
    this.excel.exportAsExcelFile(exp, 'Employee List')
  }

  // ///////////////////get start and end point of user///////
  user:any = {}
  async user_cordinates(element:any) {
    $('#myModal3').modal('show');
    this.employee_data = []
    this.Obj_date = {}
    this.user['user_id'] = element.user_id
    this.user['user_name'] = element.emp_name
  }
  Obj_date:any = {}
  employee_data:any= []
  sum :any
  async submit_date() {
    this.spinner.show();
    this.employee_data = []
    const obj:any = {}
    let user_obj:any = {}
    this.sum = null;
    user_obj[0] = this.user['user_id']
    let date :any= {}
    let ab : any ;
    if (!(this.Obj_date['start_date'] && this.Obj_date['end_date'])) {
      Swal.fire('info', "Please select both start and end date", 'info')
      this.spinner.hide();
      return
    }
    if (this.Obj_date['start_date'] > ab) {
      Swal.fire('info', 'Please Select Start date less then or equal to current date', 'info');
      this.spinner.hide();
      return
    }
    if (this.Obj_date['end_date'] > ab) {
      Swal.fire('info', 'Please Select End date less then or equal to current date', 'info');
      this.spinner.hide();
      return
    }
    if(this.Obj_date['start_date'] > this.Obj_date['end_date']){
      Swal.fire('info', 'Please Select End date greater then or equal to current date', 'info');
      this.spinner.hide();
      return
    }
    date['From_date'] = this.Obj_date['start_date']
    date['To_date'] = this.Obj_date['end_date']
    obj['b_acct_id'] = this.b_acct_id
    obj['date'] = date
    obj['user_id'] = user_obj
    obj['time'] = {}
    // console.log(obj)
    let resp:any = await this.employeeservice.getuser_coordinates(JSON.stringify(obj));
    if (resp['data'].length <= 0) {
      swal.fire('Info', 'No data found on selected data', 'info');
      this.spinner.hide();
      return
    }
    // console.log(resp)
    if (resp['error'] == false) {
      let all_data = resp['data']

      all_data.map((x:any) => {
        this.employee_data.push({
          currentdate: x[0].currentdate,
          currenttime: x[0].currenttime,
          End_date: x[x.length - 1].currentdate,
          End_time: x[x.length - 1].currenttime,
          start_status: x[0].user_status,
          end_status: x[x.length - 1].user_status,
          start_lat_long: '(' + x[0].latitude + ' ,  ' + x[0].longitude + ')',
          End_lat_long: '(' + x[x.length - 1].latitude + ' , ' + x[x.length - 1].longitude + ')'
        });
      })
      // console.log(this.employee_data)
      let resp2 :any= await this.employeeservice.getdistance(all_data);
      this.employee_data.map((x:any, i:any) => {
        resp2['data'].map((y:any, k:any) => {
          if (i == k) {
            this.employee_data[i]['distance'] = Number(y.toFixed(2))
          }
        })
      })
      let arr:any = []
       this.employee_data.map((x:any) =>{
        arr.push(x.distance)
       })
       var a = arr
       this.sum = a.reduce(function (a:any, b:any) { return a + b; }, 0);
      this.sum = this.sum.toFixed(2)
      this.spinner.hide();
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error While Getting Data', 'error')
    }

  }
}
