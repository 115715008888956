import { Component, OnInit } from '@angular/core';
// import { NewEngService } from '../../../service/new-eng.service';
import { SorItemsService} from '../../service/sor-items.service'
@Component({
  selector: 'app-dsr',
  templateUrl: './dsr.component.html',
  styleUrls: ['./dsr.component.css']
})
export class DsrComponent implements OnInit {

  constructor(private sorItemsService: SorItemsService) {
    this.sorItemsService.costing_rate_std = "DSR";
    this.sorItemsService.screen = "DSR";
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sorItemsService.costing_rate_std =  "UPPWD-SOR ";
    this.sorItemsService.screen =  "SOR";
  }
}
