<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Property Lease</div>
                    </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>Property Lease List
                                </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag" (click)="reset()" data-toggle="tab">
                                <i class="fa fa-plus-circle" aria-hidden="true"> </i>Create Property Lease
                                </a>

                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="update_flag" (click)="reset()" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Update Property Lease
                                 </a>
                        </li>



                    </ul>
                    <div class="tab-content">

                        <div *ngIf="list_flag">

                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Select Location Hierarchy :</h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="first_list" bindLabel="hier_buss_name" bindValue="hier_type_id"
                                        [multiple]="false" placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                        [selectableGroup]="true" (change)="changeList()" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true" name="chart_of_account0" [disabled]=true>
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{auth.allLableShowObjProp[auth.language_cd+'p_layout5']}}">
                                        <!--Filter-->
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create</button>
                                </div>
                            </div>


                            <div class="example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="group_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            ID

                                        </th>
                                        <!--Group ID-->
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.property_lease_group_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="hier_ident_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Target Type


                                        </th>
                                        <!--Hierarchy
                                            Identification Code-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.target_type }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="project">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Targeted Project Path

                                        </th>
                                        <!--Number of Property-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.project_desc }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="target_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Target Name

                                        </th>
                                        <!--Number of Property-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.target_name }} </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="hierarchy">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Hierarchy

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.path_desc }} </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="count">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Total Planned Property

                                        </th>
                                        <!--Total Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.count }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="total_area">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Total Area

                                        </th>
                                        <!--Total Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.total_area.toFixed(2) }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="unit">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Unit

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            SQF </td>
                                    </ng-container>

                                    <!-- status -->
                                    <ng-container matColumnDef="life_cycle_status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status

                                        </th>
                                        <!--Total Area-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.life_cycle_status}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{auth.allLableShowObjProp[auth.language_cd+'p_layout10']}}</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary" (click)="open_update(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE'"> Update
                                                <!-- {{auth.allLableShowObjProp[auth.language_cd+'LandInput34']}} -->
                                            </button>&nbsp;

                                            <!--Update-->
                                            <button class="btn btn-outline-danger" (click)="delete(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE'">{{auth.allLableShowObjProp[auth.language_cd+'p_layout11']}}</button>&nbsp;
                                            <!--Delete-->

                                            <button class="btn btn-outline-info" (click)="Appr(element)"
                                                *ngIf="element['life_cycle_status'] == 'ACTIVE' && apprflag == true">Add
                                                Approval
                                            </button>&nbsp;
                                            <!-- appoval button -->
                                            <button class="btn btn-outline-info"
                                                *ngIf="element.life_cycle_status=='UNDERAPPROVAL' || element.life_cycle_status=='APPROVED' || element.life_cycle_status=='REJECTED'"
                                                (click)="viewStatus(element)"> View Status </button>&nbsp;
                                            <!-- view stataus -->
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>
                        <div *ngIf="create_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper1>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'p_layout12']}}">
                                    <!--Target-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Ownership Type</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="ownership_list" bindLabel="type"
                                                        bindValue="type" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['ownership_type']"
                                                        [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" >


                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0" [disabled]=true>


                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()'
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()'
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                                <div class="col-3" *ngIf="i==data.length-1">
                                                    <button (click)="add_dropdown(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Project :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <button (click)="selectProject()"
                                                        class="btn btn-outline-primary">Select</button>
                                                </div>
                                                <div class="col-3">
                                                    <!-- {{project_obj['project_path_desc']}} -->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Selected Project Path:</h6>
                                                </div>
                                                <div class="col-3">
                                                    {{project_obj['project_path_desc']}}
                                                </div>
                                                <div class="col-3">
                                                    <!-- {{project_obj['project_path_desc']}} -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                (click)="setPath_desc()"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'p_layout17']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="Lease Details">
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Upload Plan</h6>
                                                </div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout20']}}
                                                        <span style="color: red;"> *</span>
                                                    </h6>
                                                    <!--Document Type : -->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'p_layout20']}}"
                                                        [(ngModel)]="Obj['pdf_doc_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout22']}}</h6>
                                                    <!--Document Format :-->
                                                </div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout23']}}</div>
                                            </div>
                                            <!--Pdf-->
                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>  Select Lease :<span style="color: red;"> *</span></h6>
                                                </div>
                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader" class="form-control"
                                                        style="border-color: black" placeholder="uploaded"
                                                        (change)="onFileUpload($event,file.files)">
                                                </div>

                                                <div class="col-3" *ngIf="pdf_fg == false">
                                                    <button type="button" class="btn btn-outline-info"
                                                        (click)='upload()'><i class="fa fa-upload"
                                                            aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout25']}}</button>
                                                    <!--Upload-->
                                                </div>
                                                <div class="col-3" *ngIf="pdf_fg == true">
                                                    <button class="btn btn-outline-info"
                                                        (click)="view()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout26']}}</button>
                                                    <!--View Plan-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Upload Lease Scheme</h6>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout28']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Document Type :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'p_layout28']}}"
                                                        [(ngModel)]="Obj['csv_doc_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout30']}}</h6>
                                                    <!--Document Format :-->
                                                </div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout31']}}
                                                    (<button class="btn btn-link" (click)="export()">Download CSV
                                                        Format</button>)
                                                </div>
                                                <!--CSV-->


                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>  Select Lease :<span style="color: red;"> *</span></h6>
                                                </div>

                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader2" class="form-control"
                                                        style="border-color: black"
                                                        (change)="onFileUpload2($event,file.files)"
                                                        (change)="onFileChange($event)"
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                                </div>

                                                <div class="col-3" *ngIf="xl_fg == false">
                                                    <h6>

                                                        <button type="button" *ngIf="!defeted_flag"
                                                            class="btn btn-outline-info" ng2FileSelect
                                                            (click)='upload2()'><i class="fa fa-upload"
                                                                aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout33']}}</button>
                                                        <!--Upload-->
                                                        <button *ngIf="defeted_flag" disabled type="button"
                                                            class="btn btn-outline-info" ng2FileSelect><i
                                                                class="fa fa-upload"
                                                                aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout33']}}</button>
                                                        <!--Upload-->

                                                    </h6>
                                                </div>
                                                <div class="col-3" *ngIf="xl_fg == true">
                                                    <button class="btn btn-outline-info" *ngIf="Obj['csv_doc_id']"
                                                        (click)="view2()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout35']}}</button>
                                                    <!--View Scheme-->
                                                </div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'p_layout36']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'p_layout37']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step [stepControl]="third"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'p_layout38']}}">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <h4>{{auth.allLableShowObjProp[auth.language_cd+'p_layout39']}}</h4>
                                                    <!--Confirm Details-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>Lease Target</h6>
                                                </div>
                                                <div class="col-7">
                                                    {{Obj['path_desc']}}
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>Selected Project Path :</h6>
                                                </div>
                                                <div class="col-7">
                                                    {{project_obj['project_path_desc']}}
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>Property Lease</h6>
                                                </div>
                                                <div class="col-3">
                                                </div>
                                                <div class="col-3">
                                                    <button class="btn btn-outline-info" *ngIf="Obj['pdf_doc_id']"
                                                        (click)="view()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout42']}}</button>
                                                    <!--View
                                                        Plan-->

                                                </div>


                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>Lease Details</h6>
                                                </div>
                                                <!-- <div class="col-7">
                                                {{Obj['pdf_file_name']}}
                                            </div>
                                            <div class="col-1">
                                                <button class="btn btn-info" *ngIf="Obj['pdf_doc_id']" (click)="view()">View
                                                    Plan</button>

                                            </div> -->


                                            </div>
                                            <br>
                                            <div class="row">
                                                <table class="table table-bordered"
                                                    style="width: 80%; margin-left: 10%;">
                                                    <thead>
                                                        <tr>
                                                            <th>Lease Type </th>
                                                            <th>Plot/Khasra no</th>
                                                            <th>Address Of Property</th>
                                                            <th>Bigha</th>
                                                            <th>Biswa</th>
                                                            <th>Biswansi</th>
                                                            <th>Kachwansi</th>
                                                            <th>Sqf </th>
                                                            <th>Total Area in Sqf</th>
                                                            <th>Count</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of last_table">
                                                            <td>{{item['lease_type']}}</td>
                                                            <td>{{item['plot_no']}}</td>
                                                            <td>{{item['address']}}</td>
                                                            <td>{{item['bigha']}}</td>
                                                            <td>{{item['biswa']}}</td>
                                                            <td>{{item['biswansi']}}</td>
                                                            <td>{{item['kachwansi']}}</td>
                                                            <td>{{item['sqf']}}</td>
                                                            <td>{{item['total_area_sqf']}}</td>
                                                            <td>{{item['count']}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'p_layout47']}}</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="submit()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout48']}}</button>&nbsp;&nbsp;
                                        <!--SUBMIT-->
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button (click)="open_list()" class="btn btn-outline-primary">Go To List</button>
                                </div>
                            </div>
                            <br>
                            <mat-horizontal-stepper [linear]="isLinear" #stepper2>
                                <mat-step [stepControl]="first"
                                    label="{{auth.allLableShowObjProp[auth.language_cd+'p_layout12']}}">
                                    <!--Target-->
                                    <br>
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Ownership Type</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="ownership_list" bindLabel="type"
                                                        bindValue="type" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['ownership_type']"
                                                        [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true" >


                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6> Select Location Hierarchy :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="first_list" bindLabel="hier_buss_name"
                                                        bindValue="hier_type_id" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['hier_type_id']"
                                                        [selectableGroup]="true" (change)="getAllLevls()"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row" *ngFor="let item of data,let i=index">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Select Level {{i+1}} :</h6>
                                                </div>
                                                <div class="col-3">
                                                    <ng-select *ngIf="i==0" [items]="level1" bindLabel="lvl1_value"
                                                        bindValue="lvl1_cd" [multiple]="false" placeholder="Select..."
                                                        [(ngModel)]="Obj['lvl1_cd']" (change)='setLeafNode()'
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value"
                                                        bindValue="lvl2_cd" [multiple]="false" (change)='setLeafNode()'
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl2_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==2" [items]="level3" (change)='setLeafNode()'
                                                        bindLabel="lvl3_value" bindValue="lvl3_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl3_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==3" [items]="level4" (change)='setLeafNode()'
                                                        bindLabel="lvl4_value" bindValue="lvl4_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl4_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==4" [items]="level5" (change)='setLeafNode()'
                                                        bindLabel="lvl5_value" bindValue="lvl5_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl5_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==5" [items]="level6" (change)='setLeafNode()'
                                                        bindLabel="lvl6_value" bindValue="lvl6_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl6_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>
                                                    <ng-select *ngIf="i==6" [items]="level7" (change)='setLeafNode()'
                                                        bindLabel="lvl7_value" bindValue="lvl7_cd" [multiple]="false"
                                                        placeholder="Select..." [(ngModel)]="Obj['lvl7_cd']"
                                                        [selectableGroup]="true" [selectableGroupAsModel]="true"
                                                        [closeOnSelect]="true" name="chart_of_account0">
                                                    </ng-select>

                                                </div>
                                                <div class="col-3" *ngIf="i==data.length-1">
                                                    <button (click)="add_dropdown(item,i)"
                                                        class="btn btn-outline-primary"><i class="fa fa-arrow-down"
                                                            aria-hidden="true"></i></button>
                                                    &nbsp;&nbsp;
                                                    <button (click)="remove_dropdown(item,i)" *ngIf="i!=0"
                                                        class="btn btn-outline-danger">
                                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <br>
                                            <br>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-3">
                                                    <h6>Selected Project Path:</h6>
                                                </div>
                                                <div class="col-3">
                                                    {{project_obj['project_path_desc']}}
                                                </div>
                                                <div class="col-3">
                                                    <!-- {{project_obj['project_path_desc']}} -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'p_layout53']}}</button>
                                            <!--Next-->
                                        </div>
                                    </div>

                                </mat-step>
                                <mat-step [stepControl]="second"
                                    label="Lease Details">
                                    <div class="card">
                                        <div style="margin: 2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout54']}}</h6>
                                                    <!--Upload Plan :-->
                                                </div>
                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout55']}}<span
                                                            style="color: red;"> *</span></h6>
                                                </div>
                                                <!--Document Type :-->
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'p_layout55']}}"
                                                        [(ngModel)]="Obj['pdf_doc_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout57']}}</h6>
                                                    <!--Document Format :-->
                                                </div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout58']}}</div>
                                            </div>
                                            <!--Pdf-->

                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout59']}}<span
                                                            style="color: red;"> *</span></h6>
                                                </div>
                                                <!--Select Plan :-->
                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader" class="form-control"
                                                        style="border-color: black"
                                                        (change)="onFileUpload($event,file.files)">
                                                </div>
                                                <div class="col-3" *ngIf="pdf_fg == false">
                                                    <button type="button" class="btn btn-outline-info"
                                                        (click)='upload()'><i class="fa fa-upload"
                                                            aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout60']}}</button>
                                                </div>
                                                <!--Upload-->
                                                <div class="col-3" *ngIf="pdf_fg == true">
                                                    <!-- <div class="col-3" *ngIf="Obj['pdf_doc_id']"> -->
                                                    <button class="btn btn-outline-info"
                                                        (click)="view()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout61']}}</button>
                                                    <!--View Plan-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Upload Lease Scheme</h6>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout63']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Document Type :-->
                                                </div>
                                                <div class="col-3">
                                                    <ng-select [items]="mainService.codeValueTechObj['PROPFLD3']"
                                                        bindLabel="value" bindValue="code" [multiple]="false"
                                                        placeholder="{{auth.allLableShowObjProp[auth.language_cd+'p_layout63']}}"
                                                        [(ngModel)]="Obj['csv_doc_type']" [selectableGroup]="true"
                                                        [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                        name="chart_of_account0">
                                                        <!--Select Location Type-->
                                                    </ng-select>
                                                </div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout65']}}</h6>
                                                    <!--Document Format :-->
                                                </div>
                                                <div class="col-3">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout66']}}</div>
                                                <!--CSV-->
                                            </div>

                                            <br>
                                            <div class="row">
                                                <div class="col-2" style="margin-left:2%">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout67']}}<span
                                                            style="color: red;"> *</span></h6>
                                                    <!--Select Plan :-->
                                                </div>

                                                <div class="col-3">
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader2" class="form-control"
                                                        style="border-color: black"
                                                        (change)="onFileUpload2($event,file.files)"
                                                        (change)="onFileChange($event)"
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                                </div>

                                                <div class="col-3" *ngIf="xl_fg == false">
                                                    <h6>
                                                        <button type="button" *ngIf="!defeted_flag"
                                                            class="btn btn-outline-info" ng2FileSelect
                                                            (click)='upload2()'><i class="fa fa-upload"
                                                                aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout33']}}</button>
                                                        <!--Upload--> <button *ngIf="defeted_flag" disabled
                                                            type="button" class="btn btn-outline-info" ng2FileSelect><i
                                                                class="fa fa-upload"
                                                                aria-hidden="true">&nbsp;</i>{{auth.allLableShowObjProp[auth.language_cd+'p_layout33']}}</button>
                                                        <!--Upload-->
                                                    </h6>
                                                </div>
                                                <div class="col-3" *ngIf="xl_fg == true">
                                                    <button class="btn btn-outline-info" *ngIf="Obj['csv_doc_id']"
                                                        (click)="view2()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout69']}}</button>
                                                    <!--View Scheme-->
                                                </div>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button mat-button mat-raised-button class="btn btn-outline-danger"
                                                matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'p_layout70']}}</button>
                                            &nbsp;&nbsp;
                                            <!--Back-->
                                            <button mat-button mat-raised-button class="btn btn-outline-primary"
                                                matStepperNext>{{auth.allLableShowObjProp[auth.language_cd+'p_layout71']}}</button>
                                            <!--Next-->


                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="third" label="Done">
                                    <!--Done-->
                                    <!-- <ng-template matStepLabel></ng-template> -->
                                    <br>
                                    <div class="card">
                                        <div style="margin:2%;">
                                            <br>
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <h4>{{auth.allLableShowObjProp[auth.language_cd+'p_layout72']}}</h4>
                                                    <!--Confirm Details-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout73']}}</h6>
                                                    <!--Plan Layout Target :-->
                                                </div>
                                                <div class="col-7">
                                                    {{Obj['path_desc']}}
                                                </div>
                                                <div class="col-1"></div>

                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>Selected Project Path :</h6>
                                                    <!--Plan Layout Target :-->
                                                </div>
                                                <div class="col-7">
                                                    {{project_obj['project_path_desc']}}
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-2">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout74']}}</h6>
                                                    <!--Plan Layout :-->
                                                </div>
                                                <div class="col-2">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout75']}}
                                                    <!-- {{Obj['pdf_file_name']}} -->
                                                </div>
                                                <!--Current :-->
                                                <div class="col-2">
                                                    <button class="btn btn-outline-info" *ngIf="Obj['old_pdf_doc_id']"
                                                        (click)="view3()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout76']}}</button>
                                                    <!--View
                                                            Plan-->

                                                </div>
                                                <div class="col-2">
                                                    {{auth.allLableShowObjProp[auth.language_cd+'p_layout77']}}
                                                </div>
                                                <!--New/Modified :-->
                                                <div class="col-2">
                                                    <button class="btn btn-outline-info" *ngIf="Obj['pdf_doc_id2']"
                                                        (click)="view()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout76']}}</button>
                                                    <!--View
                                                            Plan-->
                                                </div>

                                            </div>
                                            <br>

                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout78']}}</h6>
                                                    <!--Plan Details :-->
                                                </div>
                                                <!-- <div class="col-7">
                                            {{Obj['pdf_file_name']}}
                                        </div>
                                        <div class="col-1">
                                            <button class="btn btn-info" *ngIf="Obj['pdf_doc_id']" (click)="view()">View
                                                Plan</button>

                                        </div> -->


                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout79']}}</h6>
                                                    <!--Current Plan :-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <table class="table table-bordered"
                                                    style="width: 80%; margin-left: 10%;">
                                                    <thead>
                                                        <tr>
                                                            <th>Lease Type </th>
                                                            <th>Plot/Khasra no</th>
                                                            <th>Address Of Property</th>
                                                            <th>Bigha</th>
                                                            <th>Biswa</th>
                                                            <th>Biswansi</th>
                                                            <th>Kachwansi</th>
                                                            <th>Sqf </th>
                                                            <th>Total Area in Sqf</th>
                                                            <th>Count</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of old_table_data">
                                                            <td>{{item['lease_type']}}</td>
                                                            <td>{{item['plot_no']}}</td>
                                                            <td>{{item['address']}}</td>
                                                            <td>{{item['bigha']}}</td>
                                                            <td>{{item['biswa']}}</td>
                                                            <td>{{item['biswansi']}}</td>
                                                            <td>{{item['kachwansi']}}</td>
                                                            <td>{{item['sqf']}}</td>
                                                            <td>{{item['total_area_sqf']}}</td>
                                                            <td>{{item['count']}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1"></div>
                                                <div class="col-3">
                                                    <h6>{{auth.allLableShowObjProp[auth.language_cd+'p_layout80']}}</h6>
                                                    <!--New Plan :-->
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <table class="table table-bordered"
                                                    style="width: 80%; margin-left: 10%;">
                                                    <thead>
                                                        <tr>
                                                            <th>Lease Type </th>
                                                            <th>Plot/Khasra no</th>
                                                            <th>Address Of Property</th>
                                                            <th>Bigha</th>
                                                            <th>Biswa</th>
                                                            <th>Biswansi</th>
                                                            <th>Kachwansi</th>
                                                            <th>Sqf </th>
                                                            <th>Total Area in Sqf</th>
                                                            <th>Count</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of last_table">
                                                            <td>{{item['lease_type']}}</td>
                                                            <td>{{item['plot_no']}}</td>
                                                            <td>{{item['address']}}</td>
                                                            <td>{{item['bigha']}}</td>
                                                            <td>{{item['biswa']}}</td>
                                                            <td>{{item['biswansi']}}</td>
                                                            <td>{{item['kachwansi']}}</td>
                                                            <td>{{item['sqf']}}</td>
                                                            <td>{{item['total_area_sqf']}}</td>
                                                            <td>{{item['count']}}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                    <br>
                                    <br>
                                    <div class="col-12 text-center">
                                        <button mat-button mat-raised-button class="btn btn-outline-danger"
                                            matStepperPrevious>{{auth.allLableShowObjProp[auth.language_cd+'p_layout81']}}</button>
                                        &nbsp;&nbsp;
                                        <!--Back-->
                                        <button class="btn btn-outline-success"
                                            (click)="update()">{{auth.allLableShowObjProp[auth.language_cd+'p_layout82']}}</button>&nbsp;&nbsp;
                                        <!--Update-->
                                        <!-- <button class="btn btn-warning" >RESET</button>&nbsp;&nbsp; -->
                                    </div>

                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>



                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>

<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Project</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row">


                    <div class="col-12">
                        <div class="row" *ngFor="let item of project_arr,let i=index">
                            <div class="col-3">
                                <!-- <h6>Select Node :</h6> -->
                            </div>
                            <div class="col-3">
                                <h6>Select Level {{i+1}} Node:</h6>
                            </div>
                            <div class="col-3">
                                <ng-select *ngIf="i==0" [items]="level11" bindLabel="lvl1_value" bindValue="lvl1_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl1_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==1" [items]="level2" bindLabel="lvl2_value" bindValue="lvl2_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl2_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==2" [items]="level3" bindLabel="lvl3_value" bindValue="lvl3_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl3_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==3" [items]="level4" bindLabel="lvl4_value" bindValue="lvl4_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl4_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==4" [items]="level5" bindLabel="lvl5_value" bindValue="lvl5_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl5_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==5" [items]="level6" bindLabel="lvl6_value" bindValue="lvl6_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl6_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                                <ng-select *ngIf="i==6" [items]="level7" bindLabel="lvl7_value" bindValue="lvl7_cd"
                                    [multiple]="false" placeholder="Select..." [(ngModel)]="project_obj['lvl7_cd']"
                                    [selectableGroup]="true" (change)="set_Path_desc(i)" [selectableGroupAsModel]="true"
                                    [closeOnSelect]="true" name="chart_of_account0">
                                </ng-select>
                            </div>
                            <div class="col-3" *ngIf="i==project_arr.length-1">
                                <button (click)="add_dropdown2(item,i)" class="btn btn-outline-primary"><i
                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                &nbsp;&nbsp;
                                <button (click)="remove_dropdown2(item,i)" *ngIf="i!=0" class="btn btn-outline-danger">
                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> Selected Path Description : <span style="color: red;">*</span>
                            <!-- Current Node Path : -->
                        </h6>
                    </div>
                    <div class="col-6">
                        {{project_obj['project_path_desc']}}
                    </div>

                </div>
                <br>


                <!-- <div class="row">
                    <div class="col-2">
                        <h6>Enter date :</h6>
                    </div>
                    <div class="col-4">
                        <input type="date" class="form-control" [(ngModel)]="selectedElement['dev_dt']">

                    </div>
                </div>
                <br> -->


                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_prj_hier()">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="submit_prj_hier()"
                        data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- WORKFLOW MODEL -->
<div class="modal" id="myModal3">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i>

                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- VIEW WORKFLOW STATUS -->

<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Status -->Status
                </h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <!-- <h6>{{ob['status']}}</h6> -->
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step"
                            *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">
                        <!-- Close -->Close
                    </button>
                    <!-- <button type="button" class="btn btn-success" data-dismiss="modal" (click)="certificate()">Download
                        Certificate </button> -->

                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner>Loading..</ngx-spinner>
<!--Loading....-->
