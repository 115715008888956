import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
declare var $: any
import swal from 'sweetalert2';
import { EstablishService } from '../../service/establish.service';
import { MainService } from '../../service/main.service';
import * as moment from "moment";
@Component({
  selector: 'app-pbr-matrix',
  templateUrl: './pbr-matrix.component.html',
  styleUrls: ['./pbr-matrix.component.css']
})
export class PbrMatrixComponent implements OnInit {

  constructor(public mainService: MainService, private estNewService: EstablishService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  errorMsg :any= ''

  allMatrix :any= [];
  pbrMatrix :any= {};
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  displayedColumns :any= ['id', 'eff_date', 'grade_pay_code', 'pbr_pay', 'action'];
  datasource:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;

    await this.getPaymatrixCode()
    await this.getAllPayMatrix()
    console.log(this.allMatrix, "this.allMatrixthis.allMatrixthis.allMatrix")
    await this.getAllpbrMatrix()
    await this.getgradepaycode()
  }
  allgradepay :any= []
  async getgradepaycode() {
    var obj :any = {}
    obj["b_acct_id"] = this.b_acct_id
    var resp = await this.settingService.getallgradepaycode(JSON.stringify(obj));
    if (resp["error"] == false) {
      console.log(resp.data)
      this.allgradepay = resp.data
      console.log(this.allgradepay)

    }
  }

  async open_update(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.pbrMatrix = Object.assign({}, element);
    // this.pbrMatrix=moment(this.pbrMatrix['eff_date']).format('yyyy-mm-dd')
    console.log(this.pbrMatrix)
    $('.nav-tabs a[href="#tab-3"]').tab('show')
    console.log(this.pbrMatrix['grade_pay_code'])
    // console.log(this.pbrMatrix['pay_band'])
    // await this.changeGradePay1(this.pbrMatrix['pay_band'])
    // await this.changeGradePay1(this.pbrMatrix['grade_pay_code'])
  }
  pay_scale1 :any= []
  changepayband(pay_com:any) {
    this.pbrMatrix['pay_scale_code'] = null
    this.pbrMatrix['grade_pay_code'] = null;
    this.pbrMatrix['level_code'] = null;
    this.pbrMatrix['basic_pay'] = null;
    this.pay_scale1 = []
    this.grade_pay1 = [];
    this.grade_pay2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    this.level1 = [];
    this.level2 = [];
    for (let i = 0; i < this.allMatrix.length; i++) {
      if (pay_com == this.allMatrix[i]['pay_commission']) {
        if (!this.pay_scale1.includes(this.allMatrix[i]['pay_band']))
          this.pay_scale1.push(this.allMatrix[i]['pay_band']);
      }
    }
  }

  allPaymatrixCode:any = []
  async getPaymatrixCode() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['field_code'] = 'HR0016';
    let resp = await this.settingService.getPaymatrixcode(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'].length == 0) {
        swal.fire('Info', "No Data Found", 'info');
        return;
      }
      this.allPaymatrixCode = resp.data;

    }
    console.log('this is all pay matrix ', this.allPaymatrixCode);
  }

  level :any= []
  grade_pay :any= [];
  pay_scale :any= [];
  matrixObj:any = {};
  async getAllPayMatrix() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp:any = await this.estNewService.getMatrix(JSON.stringify(obj));
    var grade_pay_obj :any= {};
    var lvlArr:any = [];
    var gradePayArr:any = []
    var payScaleArr:any = []

    var pay_band_obj:any = {};
    var lvl_obj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allMatrix = resp.data;
      for (var i = 0; i < this.allMatrix.length; i++) {
        if (grade_pay_obj[this.allMatrix[i].grade_pay_code] == undefined) {
          gradePayArr.push({ code: this.allMatrix[i].grade_pay_code, value: this.allMatrix[i].grade_pay_code });
          grade_pay_obj[this.allMatrix[i].grade_pay_code] = '1';
        }
        if (pay_band_obj[this.allMatrix[i].pay_band] == undefined) {
          payScaleArr.push({ code: this.allMatrix[i].pay_band, value: this.allMatrix[i].pay_band });
          pay_band_obj[this.allMatrix[i].pay_band] = '1';
        }
        if (lvl_obj[this.allMatrix[i].level_code] == undefined) {
          lvlArr.push({ code: this.allMatrix[i].level_code, value: this.allMatrix[i].level_code });
          lvl_obj[this.allMatrix[i].level_code] = '1';
        }

        if (this.matrixObj[this.allMatrix[i].grade_pay_code] == undefined) {
          this.matrixObj[this.allMatrix[i].grade_pay_code] = []
        }
        this.matrixObj[this.allMatrix[i].grade_pay_code].push({ code: this.allMatrix[i].index_num.toString(), value: this.allMatrix[i].basic_pay.toString() });
        // this.BasicPayObj[this.allMatrix[i]['pay_band'].toString() +"-"+ this.allMatrix[i]['grade_pay_code'].toString() +"-"+ this.allMatrix[i]['level_code'].toString() +"-"+ this.allMatrix[i]['index_num'].toString()] = this.allMatrix[i]['basic_pay']

      }
      this.level = lvlArr;
      this.grade_pay = gradePayArr;
      this.pay_scale = payScaleArr;
    } else {
      this.spinner.hide()
      swal.fire('Error', `${resp.data}`, 'error')
      // this.snackBar.open(resp.data, 'Error', {
      //   duration: 5000
      // });
    }
  }
  unique(value:any, index:any, self:any) {
    return self.indexOf(value) === index
  }

  grade_pay1 :any= []
  grade_pay2:any = []
  baseArr1:any = []
  baseArr2 :any= []
  level1:any = []
  level2:any = []

  changeGradePay1(pay_scale:any) {
    // this.pbrMatrix['grade_pay_code'] = null;
    this.pbrMatrix['level_code'] = null;
    this.pbrMatrix['basic_pay'] = null;

    this.grade_pay1 = [];
    this.grade_pay2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    this.level1 = [];
    this.level2 = [];

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (pay_scale == this.allMatrix[i]['pay_band']) {
        this.grade_pay2.push(this.allMatrix[i]['grade_pay_code']);
      }
    }

    this.grade_pay1 = this.grade_pay2.filter(this.unique)
    console.log(this.grade_pay1)

  }
  changeGradePay(level:any) {

    this.pbrMatrix['basic_pay'] = null;


    this.baseArr1 = [];
    this.baseArr2 = [];

    for (let i = 0; i < this.allMatrix.length; i++) {
      if (level == this.allMatrix[i]['level_code']) {
        this.baseArr2.push(this.allMatrix[i]);
      }


    }
    console.log(this.allMatrix)
    console.log(this.baseArr2)
    this.baseArr1 = this.baseArr2
    console.log(this.baseArr1, "JJJ")
  }

  changelevel(grade_pay:any) {
    this.pbrMatrix['level_code'] = null;
    this.pbrMatrix['basic_pay'] = null;

    this.level1 = [];
    this.level2 = [];
    this.baseArr1 = [];
    this.baseArr2 = [];
    var pay_scale = this.pbrMatrix['pay_scale_code']



    for (let i = 0; i < this.allMatrix.length; i++) {

      if ((grade_pay == this.allMatrix[i]['grade_pay_code']) && (pay_scale == this.allMatrix[i]['pay_band'])) {
        this.level2.push(this.allMatrix[i]['level_code']);
      }
    }

    this.level1 = this.level2.filter(this.unique)


  }

  async save() {
    this.errorMsg = ''
    // console.log(' this is the selected obj --',this.pbrMatrix['pay_commission'])
    if (

      this.pbrMatrix['grade_pay_code'] == "" || this.pbrMatrix['grade_pay_code'] == undefined

      || this.pbrMatrix['pbr_pay'] == "" || this.pbrMatrix['pbr_pay'] == undefined
      || this.pbrMatrix['eff_date'] == "" || this.pbrMatrix['eff_date'] == undefined
    ) {

      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you Sure?',
        text: 'Do You Want To Add',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  refresh() {

  }
  allpbrMatrix = []
  async finalsave() {
    this.spinner.show();
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;

    this.pbrMatrix['user_id'] = this.erpUser.user_id;
    this.pbrMatrix['b_acct_id'] = this.b_acct_id;
    this.pbrMatrix['life_cycle_status'] = 'ACTIVE'
    // obj['matrix_data'] = this.pbrMatrix;
    console.log(obj, "kk")
    var resp = await this.settingService.addpbrMatrix(this.pbrMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      // await this.getAllMatrix();
      // this.snackBar.open("Matrix Added Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Added Successfully!", 'success');
      this.pbrMatrix = {}
      await this.getAllpbrMatrix()
      this.spinner.hide();
      this.open_list()

    } else {
      if (resp['data'] = "Dublicate Matrix values") {
        swal.fire("Sorry", "Dublicate  Matrix Value Added", 'error');
        this.spinner.hide();
        this.pbrMatrix = {}
      }
      else {
        this.spinner.hide();

        swal.fire("Sorry", "..Error while Adding Matrix!", 'error');

      }

    }
  }

  async getAllpbrMatrix() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getpbrMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp.data)
      this.allpbrMatrix = resp.data;
      console.log(this.allpbrMatrix, "kk")
      this.datasource = new MatTableDataSource(this.allpbrMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all fields list ', 'error')
      // this.snackBar.open("Error while getting  all fields list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.pbrMatrix = {}
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }


  async delete(element:any) {
    var obj = Object.assign({}, element)
    swal.fire({
      title: 'Are you Sure?',
      // text: 'Do You Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(obj)
      }
    })
  }
  async finaldelete(obj1:any) {
    var obj :any = {}
    obj["b_acct_id"] = this.b_acct_id
    obj["pbr_matrix_id"] = obj1['pbr_matrix_id']
    console.log(obj)
    var resp = await this.settingService.deletepbrMatrix(obj);
    if (resp['error'] == false) {


      this.spinner.hide();
      swal.fire('Success', 'Successfully Deleted', 'success')
      await this.getAllpbrMatrix()
    } else {
      this.spinner.hide()
      swal.fire('Error', ' Error while getting  all fields list', 'error')
      // this.snackBar.open("Error while getting  all fields list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async update() {
    this.errorMsg = ''
    // if (
    //   this.payMatrix['pay_commission'] == "" || this.payMatrix['pay_commission'] == undefined ||
    //   this.payMatrix['pay_band'] == "" || this.payMatrix['pay_band'] == undefined
    //   || this.payMatrix['grade_pay_code'] == "" || this.payMatrix['grade_pay_code'] == undefined
    //   || this.payMatrix['level_code'] == "" || this.payMatrix['level_code'] == undefined
    //   || this.payMatrix['index_num'] == "" || this.payMatrix['index_num'] == undefined
    //   || this.payMatrix['basic_pay'] == "" || this.payMatrix['basic_pay'] == undefined

    // ) {

    //   this.errorMsg = "* fields required."
    // }

    // else {

    swal.fire({
      title: 'Are you Sure?',
      text: 'Do You Want To Update',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.value) {
        this.finalupdate()
      }
    })


    // }
  }
  async finalupdate() {
    this.spinner.show();
    this.pbrMatrix['update_user_id'] = this.erpUser.user_id;
    this.pbrMatrix['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updatepbrMatrix(this.pbrMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllpbrMatrix();
      // this.snackBar.open("Matrix Update Successfully!", 'Success', {
      //   duration: 5000
      // });
      swal.fire("Success", "Matrix Update Successfully!", 'success');
      this.open_list()

    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating Matrix!", 'error');

    }
  }



}
