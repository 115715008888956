import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../../md/service/main.service';
@Component({
  selector: 'app-def-approval',
  templateUrl: './def-approval.component.html',
  styleUrls: ['./def-approval.component.css']
})
export class DefApprovalComponent implements OnInit {
  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='EMB'
  }
}
