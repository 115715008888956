import { MainService } from '../service/main.service'
import { PropertyInfoService } from '../service/property-info.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AllotmentService } from '../service/allotment.service';
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchemeService } from '../service/scheme.service';
import { AuctionService } from '../service/auction.service';

import { SubSchemeService } from '../service/sub-scheme.service';
import { RegistryService } from '../service/registry.service'
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { PropertyDefinitionService } from '../service/property-definition.service';
import { AuthenticationService } from '../../authentication.service';

import { ExcelService } from '../service/file-export.service';
import Swal from 'sweetalert2';
import { ApplicationService } from '../service/application.service';
import { SaleService } from '../service/sale.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;

@Component({
  selector: 'app-auction-application',
  templateUrl: './auction-application.component.html',
  styleUrls: ['./auction-application.component.css']
})
export class AuctionApplicationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  dataSource: any;
  displayedColumns = ['app_id','property_type','property_number', 'purpose', 'app_name', 'app_mobile', 'app_user_name', 'nationality', 'action'];

  constructor(public auth: AuthenticationService, private sale_service: SaleService, public mainService: MainService, private applicationService: ApplicationService
    , private auctionService: AuctionService, private PropertyDefinitionService: PropertyDefinitionService, private propInfoService: PropertyInfoService, private spinner: NgxSpinnerService, private SchemeService: SchemeService, private SubSchemeService: SubSchemeService) { }
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  async ngOnInit() {
    // $(".hideItm").css("display","none");
    this.showData = false

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    // await this.getAllSchemes();
    await this.getAllOffers()
  }

  dataToPrint = []
  async print1() {
    this.spinner.show()
    let data: any = []
    data =this.dataToPrint
    var txt: any = this.erpUser['account_name'] + '(' + this.erpUser['account_short_name'] + ')'
    var dd: any = {
      pageSize: 'A3',
      header: function (currentPage: any, pageCount: any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'landscape',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'All Applications Of Auction',
          bold: true,
          alignment: 'center'
        }

      ],
    }
    var header9 = {
      columns: [
        {
          width: '*',
          text: '* Note : This is a computer generated document.',
          bold: true,
          alignment: 'left'
        }

      ],
    }
    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Offer Name :',
          bold: true
        },

        {
          width: '*',
          text: this.offerObj[this.obj['offer_cd']]
        },
        {
          width: '*',
          text: ' ',
          bold: true
        },

        {
          width: '*',
          text: ''
        }

      ],
    }


    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    var tbl = {
      // layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          ['Applicant  ID','Property Type','Property Number', 'Purpose Of Appliction', 'Applicant Name', 'Applicant Mobile Number', 'Applicant User Name', 'Nationality']
        ],
      }
    };
    console.log(data, 'data')
    dd.content.push(tbl);
    for (var i = 0; i < data.length; i++) {
      var arr = []
      arr.push(data[i]['id']);
      arr.push(data[i]['property_type']);
      arr.push(data[i]['property_no']);
      arr.push(data[i]['quota']);
      arr.push(data[i]['name']);
      arr.push(data[i]['mobile_no']);
      arr.push(data[i]['aadhar']);
      arr.push(data[i]['state']);
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push(header9);
    this.spinner.hide()
    pdfMake.createPdf(dd).download("allAuctionApplictaion");
  }
  offerObj: any = {}
  allOffer: any = []
  async getAllOffers() {
    this.spinner.show()
    let obj : any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['life_cycle_status'] = 'ACTIVE'

    console.log(obj)
    var resp = await this.sale_service.getAllAuctionOffers(obj);
    console.log(resp,"this is what i want")
    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy = []
      for (let i = 0; i < this.allOffer.length; i++) {
        this.allOffer[i]['application_start_dt'] = this.allOffer[i]['application_start_dt'].split('T')[0]
        this.allOffer[i]['application_end_dt'] = this.allOffer[i]['application_end_dt'].split('T')[0]
        this.allOffer[i]['desc'] = this.allOffer[i]['auction_cd'] + '-' + this.allOffer[i]['auction_name']
        this.offerObj[this.allOffer[i]['auction_cd']] = this.allOffer[i]['auction_name']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
  }
  obj: any = {}
  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  showData: any = {}
  async view(element: any) {
    this.showData = {}
    $(".hideItm").css("display","block");
    console.log(this.data);
    console.log(element);
    this.showData = Object.assign({}, element)
    for (let i = 0; i < this.data.length; i++) {
      if (element['sub_scheme_code'] == this.data[i]['sub_scheme_code']) {
        this.showData['subsidised_or_non_subsidised'] = this.data[i]['subsidised_or_non_subsidised']

        this.showData['residential_or_commercial'] = this.data[i]['residential_or_commercial']

        this.showData['no_of_property'] = this.data[i]['no_of_property']

        this.showData['property_type_code'] = this.data[i]['property_type_code']

        this.showData['length'] = this.data[i]['length']

        this.showData['width'] = this.data[i]['width']

      }
    }
    console.log(this.subschemeArr);
    console.log(this.schemeArr);

    for (let i = 0; i < this.subschemeArr.length; i++) {
      if (element['sub_scheme_code'] == this.subschemeArr[i]['sub_scheme_code']) {
        this.showData['sub_scheme_name'] = this.subschemeArr[i]['sub_scheme_name']
        this.showData['locality'] = this.subschemeArr[i]['locality']
        this.showData['state'] = this.subschemeArr[i]['state']

      }
    }
    for (let i = 0; i < this.schemeArr.length; i++) {
      if (element['scheme_code'] == this.schemeArr[i]['scheme_code']) {
        this.showData['scheme_name'] = this.schemeArr[i]['scheme_name']
      }
    }
    $('.nav-tabs a[href="#tab-2"]').tab('show')

  }
  async approve(element: any) {
    var obj: any = new Object;
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = element.id
    obj['arr_status_code'] = 'APPLICATION_APPROVED'
    this.spinner.show()
    var resp = await this.applicationService.changeApplicationStatus(obj);
    if (resp['error'] == false) {
      await this.show()
      Swal.fire('Success', 'Successfully Approved', 'success')
      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Some Error Occured', 'error')

    }
  }

  tab1Click(){
    $(".hideItm").css("display","none");
  }


  async show() {
    this.spinner.show();
    console.log(this.data);
    this.obj['b_acct_id'] = this.b_acct_id;
    console.log(this.obj)
    var resp = await this.auctionService.getAllAuctionApplication(JSON.stringify(this.obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.dataToPrint=resp['data']
      console.log(resp['data']);
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('Error', 'Error While getting list', 'error')
    }
  }
  schemeArr = []
  async getAllSchemes() {
    this.spinner.show();

    var resp = await this.SchemeService.getScheme(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {


      this.schemeArr = resp.data;
      /* for (let i = 0; i < this.schemeArr.length; i++) {
        this.schemeObject[this.schemeArr[i]['scheme_code']] = this.schemeArr[i]['scheme_name']
      } */
      this.spinner.hide();
    } else {
      this.spinner.hide();
      /*  this.snackBar.open("Error occured while getting Schemes", 'Error', {
         duration: 5000,
       }); */
    }
  }
  data = []
  async getAllPropertyInfos() {
    this.data = []
    this.obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    var resp = await this.propInfoService.getAllPropertyType(this.obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.data = resp.data;
      console.log(this.data);
      /*  this.obj['property_type_id']=[]
       for(let i=0;i<this.data.length;i++){
         this.obj['property_type_id'].push(this.data[i]['property_type_id'])
       } */

      /*     this.dataSource = new MatTableDataSource(this.data);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator; */
    } else {
      this.spinner.hide();
      /* this.snackBar.open("Error occured while getting Property Informations", 'Error', {
        duration: 5000,
      }); */
    }
  }
  subschemeArr = []
  async getAllSubschemes() {
    this.spinner.show();

    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['scheme_code'] = this.obj['scheme_code'];
    var resp = await this.SubSchemeService.getsubScheme(obj);
    console.log(resp);

    if (resp['error'] == false) {
      this.subschemeArr = resp.data;


      this.spinner.hide();

    } else {

      this.spinner.hide();

    }
  }
}
