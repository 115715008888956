import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType,HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../authentication.service';
import { TokenService } from '../../token.service';


@Injectable({
  providedIn: 'root'
})

export class Mainservice2Service  {

  // httpUrl = 'http://preprod.upda.co.in:3010';
  // httpUrl = 'https://erp.upda.co.in:3001';
  httpUrl = this.auth.httpUrl+"/emb";
  httpUrl2 =  this.auth.httpUrl;
  version = "(UAT)31-03-2021";
  zone:any;
  constructor(private token:TokenService,private http: HttpClient, public auth: AuthenticationService) { }
  profileImageUrl:any;
  accountImageUrl:any;
  userprofileImageUrl:any;
  codeValueShowObj:any = {};
  codeValueTechObj:any = {};
  accountInfo:any = {};
  userInfo:any = {};
  taskcount:any = 0;
  allAssignedComponent:any;
  componentCode:any;
  eBillUser:any = { role_cd: '' };
  roleCodeToRoleDescription:any = {};
  ObjRoleCodeTOAccess :any= {};
count :any= 0
  trunc(number:any) {
    let amt=Number((number * 1000).toFixed(4))
    return (Math.trunc((amt))) / 1000
  }

  toFixed(number:any) {
    return Number(number.toFixed(2))
  }

  dateformatchange(date:any) {
    if (date == undefined || date == null) {
      return "";
    }
    var datear1 = date.split('T')[0]
    var datearr = datear1.split("-");
    return datearr[2] + '/' + datearr[1] + '/' + datearr[0]
  }

  allLanguage:any = []
  language_cd:any
  async getAllLanguage() {
    var obj = new Object();
    var resp = await this.getAllLanguages(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allLanguage = resp.data;
      this.language_cd = this.auth.language_cd
    } else {

    }
  }
  allLableShowObj :any= {}
  allLabel :any= []
  async getAllLabel() {
    var obj:any = new Object();
    //obj['module_cd'] = 'MD'
    var resp = await this.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allLabel = resp.data;

      var obj:any = new Object();
      for (let i = 0; i < resp.data.length; i++) {
        obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
      }

      //console.log(obj)

      this.allLableShowObj = obj;

    } else {

    }
  }
  async getcomponentLabelvalueswithLanguage(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/systemdata/languages/getcomponentLabelvalueswithLanguage' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getAllLanguages(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/systemdata/languages/getAllLanguages' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getWorkflows(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/workflow/getWorkflows' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getEmbLastlog(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/workflowlog/getEmbLastLog' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getUsersForModuleWorkflow(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/workflow/getUsersForModuleWorkflow' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async insertTask(obj:any) {
    const res = await this.http.post<any>(this.httpUrl2 + '/metadata/workflowlog/insertTask', obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  // async getVenderName(obj:any) {
  //   const res = await this.http.get<any>(this.httpUrl2 + '/metadata/party/getUserName'+ obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
  //     return res;
  //   });
  //   return res;
  // }

  async getWorkflowlog(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/workflowlog/getWorkflowlog' + obj).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getVenderName(obj:any) {
    const res = await this.http.get<any>(this.httpUrl + '/metadata/party/getUserName'+ obj).toPromise().then(res => {
      return res;
    });
    return res;
  }

  async getAllTaskOfUser(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/workflowlog/getAllTaskOfUser' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getundertakingsWithWorkflow(obj:any) {
    const res = await this.http.get<any>(this.httpUrl2 + '/metadata/undertakings/getundertakingWithWorkflow' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    })
    return res;
  }
  async getcurrentroleswithresources(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl2 + '/md/role/getroleswithresources' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createLegalEntity(obj:any) {
    const res = await this.http.post<any>(this.httpUrl2 + '/systemdata/legalentity/createindentity', obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return res;
  }
  async getroleprint(obj:any) {

  }
  async getcurrentlegalentity(obj:any) {
    const resp = await this.http.get<any>(this.httpUrl2 + '/systemdata/legalentity/getcurrentlegalentity' + obj,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // async sendMsg(mobile, message) {
  //   var str = "https://2factor.in/API/R1/?module=TRANS_SMS&apikey=4f581079-6160-11ec-b710-0200cd936042&to="+ mobile +"&from=EMBINF&templatename=EMB+Approval&var1="+ message
  //   const resp = this.http.get<any>(str,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
  //     return res;
  //   });
  //   return resp;
  // }
  async sendMsg(mobile:any, doc_type:any,doc_number:any,user_name:any) {
    let msg=`Dear Sir/Ma'am, `+user_name+`, You have `+doc_type+` Number `+doc_number+` for approval. Dial 1800 120 3452, 10AM-5PM for further assistance.-PCSMCPL.`
    var str = "https://2factor.in/API/R1/?module=TRANS_SMS&apikey=4f581079-6160-11ec-b710-0200cd936042&to="+mobile+"&from=PCSMCP&msg="+ msg
    const resp = this.http.get<any>(str,{headers: new HttpHeaders().append('token', this.token.token).append('session', this.token.session)}).toPromise().then(res => {
      return res;
    });
    return resp;
  }


}
