<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        <!-- E - Measurement Book -->{{ mainService.allLableShowObj[mainService.language_cd +
                        "EMB-EMB1"] }}
                    </div>
                    <!-- <button class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">About</button> -->
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item"> <a *ngIf="list_flag" class="nav-link" [class.active]="mylist"
                                href="#tab-7-1" data-toggle="tab" (click)="listbtab()"><i class="fa fa-line-chart"></i>
                                <!-- EMB List -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB2" ]
                                }}</a> </li>
                        <li class="nav-item"> <a class="nav-link" [class.active]="myapprov" href="#tab-7-4"
                                data-toggle="tab" (click)="apptab()"><i class="fa fa-circle" aria-hidden="true"></i>
                                Purposed EMBs </a> </li>
                        <li class="nav-item"> <a *ngIf="create_flag" class="nav-link" [class.active]="create_flag"
                                href="#tab-7-2" data-toggle="tab" (click)="refresh(stepper1)"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i> <!-- Create EMB -->{{
                                mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB3" ] }} </a> </li>
                        <li class="nav-item"> <a *ngIf="update_flag" class="nav-link" [class.active]="update_flag"
                                href="#tab-7-3" data-toggle="tab" (click)="reset(stepper1)"><i class="ti-settings"> </i>
                                <!-- Update EMB --> {{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB4"
                                ] }} </a> </li>
                        <td></td>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag && flag1">
                            <br>
                            <div style="text-align: right"> <button mat-button mat-raised-button class="btn btn-success"
                                    (click)="createtab()"> Create </button> </div> <br />
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Select Work -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB5" ] }} :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWork" bindLabel="work_des" bindValue="id"
                                        placeholder="Select Work" (change)="getTenderId()" [(ngModel)]="selectedWork"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div> <br />
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%"> <input matInput
                                            (keyup)="applyFilter4($any($event.target).value)"
                                            placeholder="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB56' ] }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right"> 
                                    <button class="btn btn-info" (click)="downloadabstractofcost()"> Abstract of Cost</button>&nbsp; 
                                        <button  class="btn btn-warning" (click)="Appr()" *ngIf="apprflag == true">
                                        <!-- Add Approval -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB55" ] }}
                                    </button>&nbsp; <button class="btn btn-success" (click)="print1()">
                                        <!-- Print -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB6" ] }}
                                    </button> </div>
                            </div> <br />
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="datasource5" matSort >
                                    <ng-container matColumnDef="check">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB ID -->Check EMB For Approval
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > <input
                                                type="checkbox" id="vehicle1" (click)="checkclick(element)"
                                                [(ngModel)]="element['check']" name="vehicle1" value="Bike" /> </td>
                                    </ng-container>
                                    <ng-container matColumnDef="line_id">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB ID -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB7" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{ element.id
                                            }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="line_desc">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB Description -->{{ mainService.allLableShowObj[
                                            mainService.language_cd + "EMB-EMB8" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{
                                            element["emb_desc"] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB Number -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB10" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{
                                            element.emb_no }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- Date of Measurement -->{{ mainService.allLableShowObj[
                                            mainService.language_cd + "EMB-EMB12" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  >
                                            <!-- {{ element.emb_dt}} --> <span *ngIf="element['emb_dt']">{{
                                                mainService.dateformatchange(element["emb_dt"]) }}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef   mat-sort-header>
                                            <!-- Status -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB13" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{
                                            emb_status[element.emb_no] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- Action -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB14" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > <button
                                                class="btn btn-info me-2" *ngIf="element.status != 'GENERATED'"
                                                (click)="viewStatus(element)">
                                                <!-- View Status -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB15" ] }}
                                            </button>&nbsp; <button class="btn btn-primary m-2"
                                                *ngIf=" ((element.status == 'GENERATED' || element.status == 'GENRATED') && element.id > embdeleteflag) || element.status == 'REJECTED' "
                                                (click)="open_update(element)">
                                                <!-- Update -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB16" ] }}
                                            </button> <button class="btn btn-danger  m-2"
                                                *ngIf=" element.id > embdeleteflag || element.status == 'REJECTED' "
                                                (click)="deleteemb(element)"><i class="fa fa-trash-o">
                                                <!-- Delete --></i>
                                            </button>
                                            <!-- <button class="btn btn-success" (click)="print(element)">Print</button> -->
                                            <button class="btn btn-success" (click)="seeworkflow(element)">
                                                <!-- See Workflow Details -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB18" ] }}
                                            </button> <button class="btn btn-success" (click)="reassign(element)"
                                                *ngIf=" element['status'] != 'GENERATED' && element['status'] != 'APPROVED' && role_cd == 'ZA' ">
                                                <!-- Re-Assign -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB19" ] }}
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="app_flag"> <br />
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        <!-- Select Work -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB5" ] }} :
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allWork" bindLabel="work_des" bindValue="id"
                                        placeholder="Select Work" (change)="getTenderIdtab4()"
                                        [(ngModel)]="selectedWork" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="work_order_id"> </ng-select>
                                </div>
                            </div> <br />
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%"> <input matInput
                                            (keyup)="applyFilter2($any($event.target).value)"
                                            placeholder="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB56' ] }}" />
                                    </mat-form-field>
                                </div>
                                <div class="col-8 text-right"></div>
                            </div> <br />
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="datasource2" matSort >
                                    <ng-container matColumnDef="line_id">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB ID -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB7" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{ element.id
                                            }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="line_desc">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB Description -->Approval Status
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{
                                            element["status"] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB Number -->Status Timestamp
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > <span
                                                *ngIf="element['update_timestamp']">{{
                                                mainService.dateformatchange(element.update_timestamp) }}</span> </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emb">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- EMB Description -->Measurement Number/s
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > {{
                                            element["appr_emb"] }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef    mat-sort-header>
                                            <!-- Action -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                            "EMB-EMB14" ] }}
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element"  > <button
                                                class="btn btn-primary " *ngIf="element.status != 'GENERATED'"
                                                (click)="viewStatus(element)">
                                                <!-- View Status -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB15" ] }}
                                            </button>&nbsp; <button class="btn btn-primary"
                                                *ngIf=" ((element.status == 'GENERATED' || element.status == 'GENRATED') && element.id > embdeleteflag) || element.status == 'REJECTED' "
                                                (click)="open_updatemodal(element)">
                                                <!-- Update -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB16" ] }}
                                            </button>
                                            <!-- <button class="btn btn-success" (click)="print(element)">Print</button> -->
                                            <button class="btn btn-success m-2" (click)="seeworkflow(element)">
                                                <!-- See Workflow Details -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB18" ] }}
                                            </button> <button class="btn btn-success" (click)="reassign(element)"
                                                *ngIf=" element['status'] != 'GENERATED' && element['status'] != 'APPROVED' && role_cd == 'ZA' ">
                                                <!-- Re-Assign -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB19" ] }}
                                            </button> <button class="btn btn-warning" (click)="print1()">
                                                <!-- Print -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB6" ] }}
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns4"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        <div *ngIf="create_flag">
                            <div style="text-align: right"> <button mat-button mat-raised-button class="btn btn-primary"
                                    (click)="listbtab()"> Go-Back </button> </div> <br />
                            <mat-horizontal-stepper [linear]="isLinear" #stepper [selectedIndex]="stepperIndex">
                                <mat-step [stepControl]="first"
                                    label="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB57' ] }}">
                                    <!-- Select Work Line --> <br />
                                    <div class="row">
                                        <!-- <div class="col-3 text-right"> <h6> Select Project :</h6> </div> <div class="col-3"> <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="project_cd"> </ng-select> </div> -->
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Select Work -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB5" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allWork" bindLabel="work_des" bindValue="id"
                                                placeholder="Select Work" (change)="changeWork()"
                                                [(ngModel)]="selectedWork" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="work_order_id"> </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6> {{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB61" ]
                                                }} : </h6>
                                        </div>
                                        <div class="col-3"> {{ obj["path_desc"] }} </div>
                                    </div> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Description -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB9" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <textarea style="width: 100%; height: 80px" id="re"
                                                name="re" [(ngModel)]="embData['emb_desc']"
                                                class="form-control"></textarea> </div>
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Date Of Measurement -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB12" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <input type="date" [(ngModel)]="embData['emb_dt']"
                                                class="form-control" /> </div>
                                    </div> <br />
                                    <div class="row" *ngIf="e_no == 1">
                                        <!-- <div class="col-3 text-right"> <h6> Emb Description :</h6> </div> <div class="col-3"> <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="embData['emb_desc']" class="form-control"></textarea> </div> -->
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Number -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB11" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <input type="number" [(ngModel)]="emb_no"
                                                class="form-control" (change)="changeembnumber()" disabled /> </div>
                                    </div> <br />
                                    <div *ngIf="calculate_flage">
                                        <div class="row">
                                            <div class="col-4">
                                                <mat-form-field style="margin-left: 2%"> <input matInput
                                                        (keyup)="applyFilter($any($event.target).value)"
                                                        placeholder="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB56' ] }}" />
                                                </mat-form-field>
                                            </div>
                                        </div> <br />
                                        <div class="" style="overflow-x: scroll">
                                            <table mat-table [dataSource]="datasource" matSort
                                                class="mat-elevation-z8">
                                                <ng-container matColumnDef="sn">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- S No -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB20" ] }}.
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > {{ element["sno"] }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="line_desc">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Line Description -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB21" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         >
                                                        <div class="showonhover"> <input type="text"
                                                                class="form-control" [(ngModel)]="element['item_desc']"
                                                                disabled name="" id="" /> <span class="hovertext">
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>Line Description :</h6> </div> <div class="col-9 text-left"> <p>{{element['item_desc']}}</p> </div> </div> -->
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Work Order Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB22" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["quantity"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Effective Work Order Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB23" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p> {{ element["eff_quantity"] +
                                                                            element["already_measured"] }} </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Work Order Rate -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB24" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["rate"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>BOQ Amount :</h6> </div> <div class="col-9 text-left"> <p>{{element['line_amt']}}</p> </div> </div> -->
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>Aleady Measured Quantity :</h6> </div> <div class="col-9 text-left"> <p>{{element['eff_quantity'] - element['remaining_quantity']}}</p> </div> </div> -->
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Remaining Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB25" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["remaining_quantity"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Unit -->{{ mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB26" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["unit"] }}</p>
                                                                    </div>
                                                                </div>
                                                            </span> </div>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="rate">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Unit -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB26" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > {{ element["unit"] }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="work_order_rate">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Remaining Quantity -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB25" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         >
                                                        <!-- <input type="number" class="form-control" (change)="calculatepartrate(element)" [(ngModel)]="element['part_rate']"> -->{{
                                                        element["remaining_quantity"] }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="cal_bill_q">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Calculated -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB27" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > <input type="text" class="form-control"
                                                            [(ngModel)]="element['ebill_quantity']" disabled /> <a
                                                            *ngIf=" element['ebill_quantity'] > element['remaining_quantity'] "
                                                            style="color: red">
                                                            <!-- Excess Quantity -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB28" ] }}
                                                        </a> <button class="btn btn-link"
                                                            (click)="openCalculatePopup(element, i)">
                                                            <!-- Calculate -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB29" ] }}
                                                        </button>
                                                        <!-- <button (click)="addWorkLine(element,i)" class="btn btn-primary" *ngIf="element['ebill_quantity'] >0 && element['flag'] != false "> <i class="fa fa-plus" style="font-size: 20px;" aria-hidden="true"></i></button> -->
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                        </mat-paginator> <br />
                                        <div class="row">
                                            <div class="col-12 text-center"> <button MatStepper matStepperNext
                                                    (click)="nextClick()" class="btn btn-primary">
                                                    <!-- Next -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB30" ] }}
                                                </button> </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!calculate_flage">
                                        <div class="card" tabindex="-1" role="dialog"
                                            aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
                                            <div role="document">
                                                <div>
                                                    <div>
                                                        <h5> Calculation for {{ selectedItem["item_desc"] }} </h5>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h6>
                                                                    <!-- Total Measured -->{{
                                                                    mainService.allLableShowObj[ mainService.language_cd
                                                                    + "EMB-EMB46" ] }} : {{ lineTotal }}
                                                                </h6>
                                                            </div>
                                                            <div class="col-2"> <input type="number"
                                                                    class="form-control" placeholder="enter Line"
                                                                    [(ngModel)]="no_of_lines" /> </div>
                                                            <div class="col-6 text-left"> <button
                                                                    class="btn btn-primary" (click)="addLine()">
                                                                    <!-- ADD -->{{ mainService.allLableShowObj[
                                                                    mainService.language_cd + "EMB-EMB47" ] }}
                                                                </button> </div>
                                                        </div> <br />
                                                        <table class="mat-elevation-z8">
                                                            <thead>
                                                                <tr style=" background-color: #c4e1ff; color: white; ">
                                                                    <th style="width: 5%">
                                                                        <!-- S No -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB20" ] }}..
                                                                    </th>
                                                                    <th style="width: 8%">
                                                                        <!-- Remark -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB41" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Measurement -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB49" ] }}
                                                                    </th>
                                                                    <th style="width: 36%">
                                                                        <!-- Calculate -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB59" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Calculated Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB52" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Action -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB14" ] }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let ln of lines; let i = index">
                                                                    <td class="remove-padding">{{ i + 1 }}</td>
                                                                    <td style="width: 20%" class="remove-padding">
                                                                        <textarea style="width: 100%; font-size: 10px"
                                                                            placeholder="Enter Remark"
                                                                            [(ngModel)]="lines[i]['measure_desc']"></textarea>
                                                                        <br />
                                                                        <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 90%; margin-left: 11px; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; border: none; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- operator -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB48" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr
                                                                                    style=" margin-left: 10px; border: none; ">
                                                                                    <td style="width: 10%; border: none"
                                                                                        class="remove-padding">
                                                                                        <ng-select [items]="operator"
                                                                                            bindLabel="value"
                                                                                            bindValue="code"
                                                                                            placeholder="Select Operator"
                                                                                            [(ngModel)]="lines[i]['operator']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id"
                                                                                            (change)="calculatelinetotal()">
                                                                                        </ng-select> <br />
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                    </td>
                                                                    <td class="remove-padding" style="border: none">
                                                                        <ng-select [items]="record_measurment"
                                                                            bindLabel="value" bindValue="code"
                                                                            placeholder="Select Measurement"
                                                                            [(ngModel)]="lines[i]['rec_measurment']"
                                                                            style="width: 100%; font-size: 10px"
                                                                            [selectableGroupAsModel]="false"
                                                                            [closeOnSelect]="true" name="work_order_id">
                                                                        </ng-select> <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 88%; margin-left: 10px; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- Geometry -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB50" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr style="margin-left: 10px">
                                                                                    <td class="remove-padding"
                                                                                        style="border: none">
                                                                                        <ng-select [items]="avlShap"
                                                                                            bindLabel="geometry"
                                                                                            bindValue="id"
                                                                                            placeholder="Select geometry"
                                                                                            [(ngModel)]="lines[i]['shape']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id"
                                                                                            (change)="changeShape(lines[i],i)">
                                                                                        </ng-select>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 88%; margin-left: 10px; overflow-x: scroll; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- Compute -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB51" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr style="margin-left: 10px">
                                                                                    <td class="remove-padding"
                                                                                        style="border: none">
                                                                                        <ng-select [items]="compute"
                                                                                            bindLabel="value"
                                                                                            bindValue="id"
                                                                                            placeholder="Select compute"
                                                                                            [(ngModel)]="lines[i]['compute']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            (change)=" popupmeasure1(lines[i], i) "
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id">
                                                                                        </ng-select>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                    </td>
                                                                    <td class="remove-padding">
                                                                        <div *ngIf="inner_table[i] == 'true'">
                                                                            <div class="row">
                                                                                <table class="mat-elevation-z8"
                                                                                    style=" width: 90%; margin-left: 5%; overflow-x: auto; ">
                                                                                    <thead>
                                                                                        <tr
                                                                                            style=" background-color: #c4e1ff; color: white; ">
                                                                                            <th style="text-align: center"
                                                                                                class="remove-padding">
                                                                                                <!-- Measurement -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB49" ] }}
                                                                                            </th>
                                                                                            <th style="text-align: center"
                                                                                                class="remove-padding">
                                                                                                <!-- Quantity -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB33" ] }}
                                                                                            </th>
                                                                                            <th class="remove-padding">
                                                                                                <!-- Remark -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB41" ] }}
                                                                                            </th>
                                                                                            <th style="text-align: center"
                                                                                                *ngIf=" embData['compute'] != 'ACTUAL' "
                                                                                                class="remove-padding">
                                                                                                <!-- Action -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB14" ] }}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr
                                                                                            *ngFor=" let k of measurearr[i]; let j = index ">
                                                                                            <td class="remove-padding">
                                                                                                <h6 class="Measurement">
                                                                                                    {{ k }} </h6>
                                                                                            </td>
                                                                                            <td class="remove-padding">
                                                                                                <input
                                                                                                    *ngIf="k == 'number'"
                                                                                                    type="text"
                                                                                                    style="font-size: 10px"
                                                                                                    class="form-control"
                                                                                                    placeholder="enter {{ k }}"
                                                                                                    [(ngModel)]=" measureobj[i][j]['mes'] "
                                                                                                    (change)="calculate1(i)" />
                                                                                                <input
                                                                                                    *ngIf="k != 'number'"
                                                                                                    type="number"
                                                                                                    style="font-size: 10px"
                                                                                                    class="form-control"
                                                                                                    placeholder="enter {{ k }}"
                                                                                                    [(ngModel)]=" measureobj[i][j]['mes'] "
                                                                                                    (change)="calculate1(i)" />
                                                                                            </td>
                                                                                            <td class="remove-padding">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    style="font-size: 10px"
                                                                                                    placeholder="enter remark"
                                                                                                    [(ngModel)]=" measureobj[i][j]['desc'] " />
                                                                                            </td>
                                                                                            <td *ngIf=" lines[i]['compute'] != 'ACTUAL' "
                                                                                                class="remove-padding">
                                                                                                <button
                                                                                                    (click)="add_n(k, j, i)"
                                                                                                    class="btn btn-primary"
                                                                                                    *ngIf="k != 'number'">
                                                                                                    <i class="fa fa-plus"
                                                                                                        aria-hidden="true"></i>
                                                                                                </button> <button
                                                                                                    (click)="delete(k, j, i)"
                                                                                                    class="btn btn-danger"
                                                                                                    *ngIf=" computeq != 'ACTUAL' && !measureobj[i][j]['flag'] && k != 'number' ">
                                                                                                    <i class="fa fa-trash-o"
                                                                                                        aria-hidden="true"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div> <br />
                                                                        </div>
                                                                    </td>
                                                                    <td style="width: 10%" class="remove-padding">
                                                                        <input type="text"
                                                                            [(ngModel)]="lines[i]['total']" disabled
                                                                        
                                                                            class="form-control" />
                                                                    </td>
                                                                    <td class="remove-padding"> <button
                                                                            class="btn btn-danger"
                                                                            (click)="deleteLine(i)"> <i
                                                                                class="fa fa-trash-o"
                                                                                aria-hidden="true"></i> </button>
                                                                        <button class="btn btn-success"
                                                                            (click)="copy(lines[i])"> C </button>
                                                                        <button class="btn btn-warning"
                                                                            (click)="paste(i)"> P </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div style="text-align: center"> <button type="button"
                                                            class="btn btn-secondary" (click)="refreshSavePopup()">
                                                            <!-- Close -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB45" ] }}
                                                        </button>&nbsp; &nbsp; <button type="button"
                                                            class="btn btn-primary" (click)="savepopup()">
                                                            <!-- Save changes -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB53" ] }}
                                                        </button> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth" id="registerStep"
                                    label="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB58' ] }}">
                                    <!-- Done --> <br />
                                    <hr /> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Work -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB31" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> {{ embData["work_order"] }} </div>
                                    </div> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Description -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB9" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <textarea style="width: 100%; height: 80px" id="re"
                                                name="re" [(ngModel)]="embData['emb_desc']"
                                                class="form-control"></textarea> </div>
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Date of Measurement -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB12" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3" *ngIf="embData['emb_dt']"> {{
                                            mainService.dateformatchange(embData["emb_dt"]) }} </div>
                                    </div> <br /> <br />
                                    <table class="mat-elevation-z8">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB20" ] }}..
                                                </th>
                                                <th scope="col">
                                                    <!-- Line Description -->{{ mainService.allLableShowObj[
                                                    mainService.language_cd + "EMB-EMB21" ] }}
                                                </th>
                                                <th scope="col">
                                                    <!-- Type -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB32" ] }}
                                                </th>
                                                <th scope="col">
                                                    <!-- Quantity -->{{ mainService.allLableShowObj[
                                                    mainService.language_cd + "EMB-EMB33" ] }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let i of result; let j = index">
                                                <th>{{ i["sno"] }}</th>
                                                <th>
                                                    <div class="showonhover"> <input type="text" class="form-control"
                                                            [(ngModel)]="i['line_desc']" disabled name="" id="" /> <span
                                                            class="hovertext">
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Line Description -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB21" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["item_desc"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Unit -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB26" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["unit"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Work Order Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB22" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["quantity"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Effective Work Order Amount -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB34" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ boqobj[i["sno"]]["eff_quantity"] +
                                                                        boqobj[i["sno"]]["already_measured"] }} </p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Work Order Rate -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB24" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["rate"] }}</p>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="row"> <div class="col-3 text-left"> <h6>BOQ Amount :</h6> </div> <div class="col-9 text-left"> <p>{{boqobj[i['sno']]['line_amt']}}</p> </div> </div> -->
                                                            <!-- <div class="row"> <div class="col-3 text-left"> <h6>Aleady Measured Quantity :</h6> </div> <div class="col-9 text-left"> <p>{{boqobj[i['sno']]['eff_quantity'] - boqobj[i['sno']]['remaining_quantity']}}</p> </div> </div> -->
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Remaining Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB25" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ boqobj[i["sno"]]["remaining_quantity"] }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span> </div>
                                                </th>
                                                <th>{{ i["operator"] }}</th>
                                                <th>{{ i["total"] }}</th>
                                            </tr>
                                        </tbody>
                                    </table> <br />
                                    <div class="row">
                                        <div class="col-12 text-center"> <button mat-button mat-raised-button
                                                matStepperPrevious class="btn btn-danger" (click)="firstStep()">
                                                <!-- Back -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB35" ] }}
                                            </button> <button class="btn btn-primary" (click)="update_new(true,'finalSubmit')">
                                                <!-- Submit -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB42" ] }}
                                            </button> </div>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                        <div *ngIf="update_flag">
                            <div style="text-align: right"> <button mat-button mat-raised-button class="btn btn-primary"
                                    (click)="listbtab()"> Go-Back </button> </div> <br />
                            <mat-horizontal-stepper [linear]="isLinear" #stepper [selectedIndex]="stepperIndex">
                                <mat-step [stepControl]="first"
                                    label="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB57' ] }}">
                                    <!-- Select Work Line --> <br />
                                    <div class="row">
                                        <!-- <div class="col-3 text-right"> <h6> Select Project :</h6> </div> <div class="col-3"> <ng-select [items]="filterService.filterObj['project']" bindLabel="desc" bindValue="code" [multiple]="false" placeholder="Select Project" [(ngModel)]="selectedProject" (change)="changeProject()" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="project_cd"> </ng-select> </div> -->
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Select Work in update-->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB5" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="allWork" bindLabel="work_des" bindValue="id"
                                                placeholder="Select Work" (change)="changeWork()"
                                                [(ngModel)]="selectedWork" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="work_order_id" disabled> </ng-select>
                                        </div>
                                        <div class="col-3 text-right">
                                            <h6> {{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB61" ]
                                                }} : </h6>
                                        </div>
                                        <div class="col-3"> {{ obj["path_desc"] }} </div>
                                    </div> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Description -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB9" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <textarea style="width: 100%; height: 80px" id="re"
                                                name="re" [(ngModel)]="embData['emb_desc']"
                                                class="form-control"></textarea> </div>
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Date Of Measurement -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB12" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <input type="date" [(ngModel)]="embData['emb_dt']"
                                                class="form-control" /> </div>
                                    </div> <br />
                                    <div class="row" *ngIf="e_no == 1">
                                        <!-- <div class="col-3 text-right"> <h6> Emb Description :</h6> </div> <div class="col-3"> <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="embData['emb_desc']" class="form-control"></textarea> </div> -->
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Number -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB11" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <input type="number" [(ngModel)]="emb_no"
                                                class="form-control" (change)="changeembnumber()" disabled /> </div>
                                    </div> <br />
                                    <div *ngIf="calculate_flage">
                                        <div class="row">
                                            <div class="col-4">
                                                <mat-form-field style="margin-left: 2%"> <input matInput
                                                        (keyup)="applyFilter($any($event.target).value)"
                                                        placeholder="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB56' ] }}" />
                                                </mat-form-field>
                                            </div>
                                        </div> <br />
                                        <div class="" style="overflow-x: scroll">
                                            <table mat-table [dataSource]="datasource" matSort
                                                class="mat-elevation-z8">
                                                <ng-container matColumnDef="sn">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- S No -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB20" ] }}.
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > {{ element["sno"] }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="line_desc">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Line Description -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB21" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         >
                                                        <div class="showonhover"> <input type="text"
                                                                class="form-control" [(ngModel)]="element['item_desc']"
                                                                disabled name="" id="" /> <span class="hovertext">
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>Line Description :</h6> </div> <div class="col-9 text-left"> <p>{{element['item_desc']}}</p> </div> </div> -->
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Work Order Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB22" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["quantity"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Effective Work Order Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB23" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p> {{ element["eff_quantity"] +
                                                                            element["already_measured"] }} </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Work Order Rate -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB24" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["rate"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>BOQ Amount :</h6> </div> <div class="col-9 text-left"> <p>{{element['line_amt']}}</p> </div> </div> -->
                                                                <!-- <div class="row"> <div class="col-3 text-left"> <h6>Aleady Measured Quantity :</h6> </div> <div class="col-9 text-left"> <p>{{element['eff_quantity'] - element['remaining_quantity']}}</p> </div> </div> -->
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Remaining Quantity -->{{
                                                                            mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB25" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["remaining_quantity"] }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-3 text-left">
                                                                        <h6>
                                                                            <!-- Unit -->{{ mainService.allLableShowObj[
                                                                            mainService.language_cd + "EMB-EMB26" ] }} :
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-9 text-left">
                                                                        <p>{{ element["unit"] }}</p>
                                                                    </div>
                                                                </div>
                                                            </span> </div>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="rate">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Unit -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB26" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > {{ element["unit"] }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="work_order_rate">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Remaining Quantity -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB25" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         >
                                                        <!-- <input type="number" class="form-control" (change)="calculatepartrate(element)" [(ngModel)]="element['part_rate']"> -->{{
                                                        element["remaining_quantity"] }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="cal_bill_q">
                                                    <th mat-header-cell *matHeaderCellDef    
                                                        mat-sort-header>
                                                        <!-- Calculated -->{{ mainService.allLableShowObj[
                                                        mainService.language_cd + "EMB-EMB27" ] }}
                                                    </th>
                                                    <td mat-cell flex-stretch *matCellDef="let element; let i = index"
                                                         > <input type="text" class="form-control"
                                                            [(ngModel)]="element['ebill_quantity']" disabled /> <a
                                                            *ngIf=" element['ebill_quantity'] > element['remaining_quantity'] "
                                                            style="color: red">
                                                            <!-- Excess Quantity -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB28" ] }}
                                                        </a> <button class="btn btn-link"
                                                            (click)="openCalculatePopup(element, i)">
                                                            <!-- Calculate -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB29" ] }}
                                                        </button>
                                                        <!-- <button (click)="addWorkLine(element,i)" class="btn btn-primary" *ngIf="element['ebill_quantity'] >0 && element['flag'] != false "> <i class="fa fa-plus" style="font-size: 20px;" aria-hidden="true"></i></button> -->
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                                        </mat-paginator> <br />
                                        <div class="row">
                                            <div class="col-12 text-center"> <button MatStepper matStepperNext
                                                    (click)="nextClick()" class="btn btn-primary">
                                                    <!-- Next -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB30" ] }}
                                                </button> </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!calculate_flage">
                                        <div class="card" tabindex="-1" role="dialog"
                                            aria-labelledby="exampleModalPreviewLabel" aria-hidden="true">
                                            <div role="document">
                                                <div>
                                                    <div>
                                                        <h5> Calculation for {{ selectedItem["item_desc"] }} </h5>
                                                    </div>
                                                    <div>
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h6>
                                                                    <!-- Total Measured -->{{
                                                                    mainService.allLableShowObj[ mainService.language_cd
                                                                    + "EMB-EMB46" ] }} : {{ lineTotal }}
                                                                </h6>
                                                            </div>
                                                            <div class="col-2"> <input type="number"
                                                                    class="form-control" placeholder="enter Line"
                                                                    [(ngModel)]="no_of_lines" /> </div>
                                                            <div class="col-6 text-left"> <button
                                                                    class="btn btn-primary" (click)="addLine()">
                                                                    <!-- ADD -->{{ mainService.allLableShowObj[
                                                                    mainService.language_cd + "EMB-EMB47" ] }}
                                                                </button> </div>
                                                        </div> <br />
                                                        <table class="mat-elevation-z8"
                                                            style="width: 100%; overflow-x: scroll">
                                                            <thead>
                                                                <tr style=" background-color: #c4e1ff; color: white; ">
                                                                    <th style="width: 5%">
                                                                        <!-- S No -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB20" ] }}..
                                                                    </th>
                                                                    <th style="width: 8%">
                                                                        <!-- Remark -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB41" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Measurement -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB49" ] }}
                                                                    </th>
                                                                    <th style="width: 36%">
                                                                        <!-- Calculate -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB59" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Calculated Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB52" ] }}
                                                                    </th>
                                                                    <th style="width: 10%">
                                                                        <!-- Action -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB14" ] }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let ln of lines; let i = index">
                                                                    <td class="remove-padding">{{ i + 1 }}</td>
                                                                    <td style="width: 20%" class="remove-padding">
                                                                        <textarea style="width: 100%; font-size: 10px"
                                                                            placeholder="Enter Remark"
                                                                            [(ngModel)]="lines[i]['measure_desc']"></textarea>
                                                                        <br />
                                                                        <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 90%; margin-left: 11px; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; border: none; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- operator -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB48" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr
                                                                                    style=" margin-left: 10px; border: none; ">
                                                                                    <td style="width: 10%; border: none"
                                                                                        class="remove-padding">
                                                                                        <ng-select [items]="operator"
                                                                                            bindLabel="value"
                                                                                            bindValue="code"
                                                                                            placeholder="Select Operator"
                                                                                            [(ngModel)]="lines[i]['operator']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id"
                                                                                            (change)="calculatelinetotal()">
                                                                                        </ng-select> <br />
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                    </td>
                                                                    <td class="remove-padding" style="border: none">
                                                                        <ng-select [items]="record_measurment"
                                                                            bindLabel="value" bindValue="code"
                                                                            placeholder="Select Measurement"
                                                                            [(ngModel)]="lines[i]['rec_measurment']"
                                                                            style="width: 100%; font-size: 10px"
                                                                            [selectableGroupAsModel]="false"
                                                                            [closeOnSelect]="true" name="work_order_id">
                                                                        </ng-select> <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 88%; margin-left: 10px; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- Geometry -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB50" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr style="margin-left: 10px">
                                                                                    <td class="remove-padding"
                                                                                        style="border: none">
                                                                                        <ng-select [items]="avlShap"
                                                                                            bindLabel="geometry"
                                                                                            bindValue="id"
                                                                                            placeholder="Select geometry"
                                                                                            [(ngModel)]="lines[i]['shape']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id"
                                                                                            (change)="changeShape(lines[i],i)">
                                                                                        </ng-select>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                        <div class="row">
                                                                            <table class="mat-elevation-z8"
                                                                                style=" width: 88%; margin-left: 10px; overflow-x: scroll; border: none; ">
                                                                                <tr style=" background-color: #c4e1ff; color: white; margin-left: 10px; "
                                                                                    *ngIf="i == 0">
                                                                                    <th style="width: 10%">
                                                                                        <!-- Compute -->{{
                                                                                        mainService.allLableShowObj[
                                                                                        mainService.language_cd +
                                                                                        "EMB-EMB51" ] }}
                                                                                    </th>
                                                                                </tr>
                                                                                <tr style="margin-left: 10px">
                                                                                    <td class="remove-padding"
                                                                                        style="border: none">
                                                                                        <ng-select [items]="compute"
                                                                                            bindLabel="value"
                                                                                            bindValue="id"
                                                                                            placeholder="Select compute"
                                                                                            [(ngModel)]="lines[i]['compute']"
                                                                                            style=" width: 100%; font-size: 10px; "
                                                                                            [selectableGroupAsModel]="false"
                                                                                            (change)=" popupmeasure1(lines[i], i) "
                                                                                            [closeOnSelect]="true"
                                                                                            name="work_order_id">
                                                                                        </ng-select>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </div> <br />
                                                                    </td>
                                                                    <td class="remove-padding">
                                                                        <div *ngIf="inner_table[i] == 'true'">
                                                                            <div class="row">
                                                                                <table class="mat-elevation-z8"
                                                                                    style=" width: 90%; margin-left: 5%; overflow-x: auto; ">
                                                                                    <thead>
                                                                                        <tr
                                                                                            style=" background-color: #c4e1ff; color: white; ">
                                                                                            <th style="text-align: center"
                                                                                                class="remove-padding">
                                                                                                <!-- Measurement -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB49" ] }}
                                                                                            </th>
                                                                                            <th style="text-align: center"
                                                                                                class="remove-padding">
                                                                                                <!-- Quantity -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB33" ] }}
                                                                                            </th>
                                                                                            <th class="remove-padding">
                                                                                                <!-- Remark -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB41" ] }}
                                                                                            </th>
                                                                                            <th style="text-align: center"
                                                                                                *ngIf=" embData['compute'] != 'ACTUAL' "
                                                                                                class="remove-padding">
                                                                                                <!-- Action -->{{
                                                                                                mainService.allLableShowObj[
                                                                                                mainService.language_cd
                                                                                                + "EMB-EMB14" ] }}
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr
                                                                                            *ngFor=" let k of measurearr[i]; let j = index ">
                                                                                            <td class="remove-padding">
                                                                                                <h6 class="Measurement">
                                                                                                    {{ k }} </h6>
                                                                                            </td>
                                                                                            <td class="remove-padding">
                                                                                                <input
                                                                                                    *ngIf="k == 'number'"
                                                                                                    type="text"
                                                                                                    style="font-size: 10px"
                                                                                                    class="form-control"
                                                                                                    placeholder="enter {{ k }}"
                                                                                                    [(ngModel)]=" measureobj[i][j]['mes'] "
                                                                                                    (change)="calculate1(i)" />
                                                                                                <input
                                                                                                    *ngIf="k != 'number'"
                                                                                                    type="number"
                                                                                                    style="font-size: 10px"
                                                                                                    class="form-control"
                                                                                                    placeholder="enter {{ k }}"
                                                                                                    [(ngModel)]=" measureobj[i][j]['mes'] "
                                                                                                    (change)="calculate1(i)" />
                                                                                            </td>
                                                                                            <td class="remove-padding">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    style="font-size: 10px"
                                                                                                    placeholder="enter remark"
                                                                                                    [(ngModel)]=" measureobj[i][j]['desc'] " />
                                                                                            </td>
                                                                                            <td *ngIf=" lines[i]['compute'] != 'ACTUAL' "
                                                                                                class="remove-padding">
                                                                                                <button
                                                                                                    (click)="add_n(k, j, i)"
                                                                                                    class="btn btn-primary"
                                                                                                    *ngIf="k != 'number'">
                                                                                                    <i class="fa fa-plus"
                                                                                                        aria-hidden="true"></i>
                                                                                                </button> <button
                                                                                                    (click)="delete(k, j, i)"
                                                                                                    class="btn btn-danger"
                                                                                                    *ngIf=" computeq != 'ACTUAL' && !measureobj[i][j]['flag'] && k != 'number' ">
                                                                                                    <i class="fa fa-trash-o"
                                                                                                        aria-hidden="true"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div> <br />
                                                                        </div>
                                                                    </td>
                                                                    <td style="width: 10%" class="remove-padding">
                                                                        <input type="text"
                                                                            [(ngModel)]="lines[i]['total']" disabled
                                                                           
                                                                            class="form-control" />
                                                                    </td>
                                                                    <td class="remove-padding"> <button
                                                                            class="btn btn-danger"
                                                                            (click)="deleteLine(i)"> <i
                                                                                class="fa fa-trash-o"
                                                                                aria-hidden="true"></i> </button>
                                                                        <button class="btn btn-success"
                                                                            (click)="copy(lines[i])"> C </button>
                                                                        <button class="btn btn-warning"
                                                                            (click)="paste(i)"> P </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div style="text-align: center"> <button type="button"
                                                            class="btn btn-secondary" (click)="refreshSavePopup()">
                                                            <!-- Close -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB45" ] }}
                                                        </button>&nbsp; &nbsp; <button type="button"
                                                            class="btn btn-primary" (click)="savepopup()">
                                                            <!-- Save changes -->{{ mainService.allLableShowObj[
                                                            mainService.language_cd + "EMB-EMB53" ] }}
                                                        </button> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>
                                <mat-step [stepControl]="fourth" id="registerStep"
                                    label="{{ mainService.allLableShowObj[ mainService.language_cd + 'EMB-EMB58' ] }}">
                                    <!-- Done --> <br />
                                    <hr /> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Work -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB31" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> {{ embData["work_order"] }} </div>
                                    </div> <br />
                                    <div class="row">
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Emb Description -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB9" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3"> <textarea style="width: 100%; height: 80px" id="re"
                                                name="re" [(ngModel)]="embData['emb_desc']"
                                                class="form-control"></textarea> </div>
                                        <div class="col-3 text-right">
                                            <h6>
                                                <!-- Date of Measurement -->{{ mainService.allLableShowObj[
                                                mainService.language_cd + "EMB-EMB12" ] }} :
                                            </h6>
                                        </div>
                                        <div class="col-3" *ngIf="embData['emb_dt']"> {{
                                            mainService.dateformatchange(embData["emb_dt"]) }} </div>
                                    </div> <br /> <br />
                                    <table class="mat-elevation-z8">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB20" ] }}..
                                                </th>
                                                <th scope="col">
                                                    <!-- Line Description -->{{ mainService.allLableShowObj[
                                                    mainService.language_cd + "EMB-EMB21" ] }}
                                                </th>
                                                <th scope="col">
                                                    <!-- Type -->{{ mainService.allLableShowObj[ mainService.language_cd
                                                    + "EMB-EMB32" ] }}
                                                </th>
                                                <th scope="col">
                                                    <!-- Quantity -->{{ mainService.allLableShowObj[
                                                    mainService.language_cd + "EMB-EMB33" ] }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let i of result; let j = index">
                                                <th>{{ i["sno"] }}</th>
                                                <th>
                                                    <div class="showonhover"> <input type="text" class="form-control"
                                                            [(ngModel)]="boqobj[i['sno']]['item_desc']" disabled name=""
                                                            id="" /> <span class="hovertext">
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Line Description -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB21" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["item_desc"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Unit -->{{ mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB26" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["unit"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Work Order Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB22" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["quantity"] }}</p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Effective Work Order Amount -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB34" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ boqobj[i["sno"]]["eff_quantity"] +
                                                                        boqobj[i["sno"]]["already_measured"] }} </p>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Work Order Rate -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB24" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p>{{ boqobj[i["sno"]]["rate"] }}</p>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="row"> <div class="col-3 text-left"> <h6>BOQ Amount :</h6> </div> <div class="col-9 text-left"> <p>{{boqobj[i['sno']]['line_amt']}}</p> </div> </div> -->
                                                            <!-- <div class="row"> <div class="col-3 text-left"> <h6>Aleady Measured Quantity :</h6> </div> <div class="col-9 text-left"> <p>{{boqobj[i['sno']]['eff_quantity'] - boqobj[i['sno']]['remaining_quantity']}}</p> </div> </div> -->
                                                            <div class="row">
                                                                <div class="col-3 text-left">
                                                                    <h6>
                                                                        <!-- Remaining Quantity -->{{
                                                                        mainService.allLableShowObj[
                                                                        mainService.language_cd + "EMB-EMB25" ] }} :
                                                                    </h6>
                                                                </div>
                                                                <div class="col-9 text-left">
                                                                    <p> {{ boqobj[i["sno"]]["remaining_quantity"] }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </span> </div>
                                                </th>
                                                <th>{{ i["operator"] }}</th>
                                                <th>{{ i["total"] }}</th>
                                            </tr>
                                        </tbody>
                                    </table> <br />
                                    <div class="row">
                                        <div class="col-12 text-center"> <button mat-button mat-raised-button
                                                matStepperPrevious class="btn btn-danger" (click)="firstStep()">
                                                <!-- Back -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                                "EMB-EMB35" ] }}
                                            </button> <button class="btn btn-primary" (click)="update()"> Update
                                                <!-- {{mainService.allLableShowObj[mainService.language_cd+'EMB-EMB42']}} -->
                                            </button> </div>
                                    </div>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </div> <br />
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner type="ball-atom">
    <!-- Loading -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB36"] }}....
</ngx-spinner>
<div class="modal" id="x">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Approval -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB37"] }}
                </h4> <button type="button" class="close"data-bs-dismiss="modal"> &times; </button>
            </div>
            <div class="modal-body">
                <table class="mat-elevation-z8"
                    style=" width: 90%; margin-left: 5%; overflow-x: scroll; scroll-margin-block: 1; ">
                    <thead>
                        <tr style="background-color: #c4e1ff; color: white">
                            <th style="width: 10%">
                                <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB20" ]
                                }}..
                            </th> <!-- <th style="width: 20%;">Level</th> -->
                            <th style="width: 10%">
                                <!-- Role -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB38" ] }}
                            </th>
                            <th style="width: 15%">
                                <!-- User -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB39" ] }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of pending_apprarr; let i = index">
                            <td>{{ i + 1 }}</td>
                            <!-- <td style="width: 20%;"> Level-{{ln['level_of_approval']}} </td> -->
                            <td style="width: 10%"> {{ mainService.roleCodeToRoleDescription[ln["role_cd"]] }} </td>
                            <td style="width: 10%" *ngIf="ln['user_id'] != -1"> {{ useridtoname[ln["user_id"]] }} </td>
                            <td style="width: 10%" *ngIf="ln['user_id'] == -1"> {{ this.partyObj[ln["vendor_id"]] }} -
                                VENDOR </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row" *ngIf="flag == false && this.approveflag == false">
                    <div class="col-4 text-right" *ngIf="this.approveflag == false">
                        <h6>
                            <!-- Select Forwarded To -->{{ mainService.allLableShowObj[ mainService.language_cd +
                            "EMB-EMB40" ] }} :
                        </h6>
                    </div>
                    <div class="col-4" *ngIf="flag == false && this.approveflag == false">
                        <ng-select [items]="workuserre" bindLabel="desc" bindValue="user_id" [multiple]="false"
                            placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"> </ng-select>
                    </div>
                </div> <br />
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>
                            <!-- Remark -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB41" ] }} :
                        </h6>
                    </div>
                    <div class="col-4"> <textarea style="width: 100%; height: 80px" id="re" name="re"
                            [(ngModel)]="appr_remark" placeholder="enter work order name"
                            class="form-control"></textarea> </div>
                </div> <br />
                <div class="row" *ngIf="flag == false && this.approveflag == false">
                    <div class="col-12 text-center"> <button class="btn btn-primary"data-bs-dismiss="modal"
                            (click)="saveapprpending()">
                            <!-- Submit -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB42" ] }}
                        </button> </div>
                </div> <br />
                <div *ngIf="flag == true && this.approveflag == false" class="row">
                    <div class="col-12 text-center">
                        <!-- <h6>OR</h6> -->
                    </div>
                </div>
                <div *ngIf="flag == true && this.approveflag == false" class="row">
                    <div class="col-12 text-center"> <button class="btn btn-primary"data-bs-dismiss="modal"
                            (click)="saveapprpending()">
                            <!-- Forward To Vendor -->{{ mainService.allLableShowObj[ mainService.language_cd +
                            "EMB-EMB43" ] }}
                        </button> </div>
                </div>
                <div class="row" *ngIf="this.approveflag == true">
                    <p style="color: red; margin-left: 2%; margin-right: 3%"> Note -: This is the last user with the
                        last role of the workflow for this document. If you want to revoke this user so the document
                        will be approved automatically. Please make sure what you are doing is correct </p>
                    <div class="col-12 text-center"> <button class="btn btn-primary"data-bs-dismiss="modal"
                            (click)="Approveswal()">
                            <!-- Approve -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB44" ] }}
                        </button> </div>
                </div> <!-- Modal footer -->
                <div class="modal-footer"> <button type="button" class="btn btn-danger"data-bs-dismiss="modal">
                        <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                    </button> </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="A" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Calculation for {{ selectedItem["item_desc"] }}
                </h5> <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-3">
                        <h6>
                            <!-- Total Measured -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB46"
                            ] }} : {{ lineTotal }}
                        </h6>
                    </div>
                    <div class="col-2"> <input type="number" class="form-control" placeholder="enter Line"
                            [(ngModel)]="no_of_lines" /> </div>
                    <div class="col-6 text-left"> <button class="btn btn-primary" (click)="addLine()">
                            <!-- ADD -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB47" ] }}
                        </button> </div>
                </div> <br />
                <!-- <div *ngFor="let ln of lines; let i = index" class="row"> <div class="col-4"> SNO. {{i+1}} </div> <div class="col-6"> <textarea style="width: 100%;" placeholder="Enter Remark" [(ngModel)]="ln['measure_desc']"></textarea> </div> <div class="col-2"> <button class="btn btn-danger" (click)="deleteLine(i)">Delete</button> </div> <div class="col-3"> <ng-select [items]="avlShap" bindLabel="geometry" bindValue="id" placeholder="Select geometry" [(ngModel)]="ln['shape']" style="width: 100%;" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id" (change)="changeShape(i)"> </ng-select> </div> <div class="col-3"> <ng-select [items]="compute" bindLabel="value" bindValue="id" placeholder="Select compute" [(ngModel)]="ln['compute']" style="width: 100%;" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"> </ng-select> </div> <div class="col-3"> <input class="form-control" placeholder="Enter No" [(ngModel)]="ln['no']"> </div> <div class="col-3"> Total : {{ln['total']}} </div> <br> -->
                <!-- <div *ngFor="let sd of sides; let j = index"> <div class="col-6 text-right"> <input class="form-control" [(ngModel)]="lines[i]['dtlLines'][sd][lines[i]['dtlLines'][sd].length-1]" placeholder="{{sd}}"> </div> <div class="col-6 text-right"> <input class="form-control" [(ngModel)]="lines[i]['dtlLines'][sd][lines[i]['dtlLines'][sd].length-1]" placeholder="{{sd}}"> </div> -->
                <!-- <div *ngIf="ln['compute'] == 'AVERAGE'" class="col-3"> <button class="btn btn-link" (click)="addDtlLine(i,sd)">Add</button> </div> -->
                <!-- <div *ngFor="let avsd of lines[i]['dtlLines'][sd]; let k = index"> <div *ngIf="k!=0" class="col-3 text-right"> <h6>{{sd}}</h6> </div> <div *ngIf="k!=0" class="col-6 text-right"> <input class="form-control" [(ngModel)]="lines[i]['dtlLines'][sd][k]" placeholder="{{sd}}"> </div> </div> -->
                <!-- </div> -->
                <!-- <div class="col-12"> <hr> </div> </div> -->
                <table class="mat-elevation-z8"
                    style=" width: 90%; margin-left: 5%; overflow-x: scroll; scroll-margin-block: 1; ">
                    <thead>
                        <tr style="background-color: #c4e1ff; color: white">
                            <th style="width: 10%">
                                <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB20" ]
                                }}..
                            </th>
                            <th style="width: 20%">
                                <!-- Remark -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB41" ]
                                }}
                            </th>
                            <th style="width: 10%">
                                <!-- operator -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB48" ]
                                }}
                            </th>
                            <th style="width: 15%">
                                <!-- Measurement -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                "EMB-EMB49" ] }}
                            </th>
                            <th style="width: 15%">
                                <!-- Geometry -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB50" ]
                                }}
                            </th>
                            <th style="width: 15%">
                                <!-- Compute -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB51" ]
                                }}
                            </th>
                            <th style="width: 15%">
                                <!-- Calculate -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB59"
                                ] }}
                            </th>
                            <th style="width: 15%">
                                <!-- Calculated Quantity -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                "EMB-EMB52" ] }}
                            </th>
                            <th style="width: 20%">
                                <!-- Action -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB14" ]
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of lines; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td style="width: 20%"> <textarea style="width: 100%" placeholder="Enter Remark"
                                    [(ngModel)]="ln['measure_desc']"></textarea> </td>
                            <td style="width: 10%">
                                <ng-select [items]="operator" bindLabel="value" bindValue="code"
                                    placeholder="Select Operator" [(ngModel)]="ln['operator']" style="width: 100%"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                    (change)="calculatelinetotal()"> </ng-select>
                            </td>
                            <td>
                                <ng-select [items]="record_measurment" bindLabel="value" bindValue="code"
                                    placeholder="Select Measurement" [(ngModel)]="ln['rec_measurment']"
                                    style="width: 100%" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                    name="work_order_id"> </ng-select>
                            </td>
                            <td>
                                <ng-select [items]="avlShap" bindLabel="geometry" bindValue="id"
                                    placeholder="Select geometry" [(ngModel)]="ln['shape']" style="width: 100%"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                    (change)="changeShape(ln,i)"> </ng-select>
                            </td>
                            <td>
                                <ng-select [items]="compute" bindLabel="value" bindValue="id"
                                    placeholder="Select compute" [(ngModel)]="ln['compute']" style="width: 100%"
                                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                </ng-select>
                            </td>
                            <td> <button (click)="popupmeasure(ln, i)" class="btn btn-primary" style="margin-left: 10%">
                                    <!-- Measurement -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                    "EMB-EMB49" ] }}
                                </button>
                                <!-- <button class="btn btn-primary" *ngIf="!ln['shape'] || !ln['compute']" (click)="c2()">Measurement</button> -->
                            </td>
                            <td style="width: 10%"> <input type="text" [(ngModel)]="ln['total']" disabled
                                  class="form-control" /> </td>
                            <td style="width: 20%"> <button class="btn btn-danger" (click)="deleteLine(i)"> <i
                                        class="fa fa-trash-o" style="font-size: 20px; margin-left: 10%"
                                        aria-hidden="true"></i> </button> <button class="btn btn-success"
                                    (click)="copy(ln)">
                                    <!-- <i class="fas fa-copy" style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->
                                    C
                                </button> <button class="btn btn-warning" (click)="paste(i)">
                                    <!-- <i class="fas fa-paste" style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->
                                    P
                                </button> </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer"> <button type="button" class="btn btn-secondary"data-bs-dismiss="modal"
                    (click)="savepopup()">
                    <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                </button> <button type="button" class="btn btn-primary"data-bs-dismiss="modal" (click)="savepopup()">
                    <!-- Save changes -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB53"] }}
                </button> </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Z" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <!-- See Workflow Details -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB18"]
                    }}
                </h5> <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body">
                <table class="table table-bordered"
                    style=" width: 90%; margin-left: 5%; overflow-x: scroll; scroll-margin-block: 1; ">
                    <thead>
                        <tr style="background-color: #c4e1ff; color: white">
                            <th style="width: 10%">
                                <!-- S No -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB20" ]
                                }}..
                            </th>
                            <th style="width: 20%">Levels</th>
                            <th style="width: 10%">Branch</th>
                            <th style="width: 20%">Branch Logical Condition</th>
                            <th style="width: 10%">
                                <!-- Role -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB38" ] }}
                            </th>
                            <th style="width: 15%">
                                <!-- User -->{{ mainService.allLableShowObj[ mainService.language_cd + "EMB-EMB39" ] }}
                            </th>
                            <th>Role Function</th>
                            <th>Vendor Approval Required</th>
                            <th>Condition</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of dtlworkflow; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td style="width: 20%">Level-{{ ln["level"] }}</td>
                            <td style="width: 10%">Branch-{{ ln["branch"] }}</td>
                            <td style="width: 20%"> {{ ln["branch_logical_condition"] }} </td>
                            <td style="width: 10%"> {{ln["role_cd"]}} </td>
                            <td style="width: 10%"> {{ ln["users"] }} </td>
                            <td style="width: 10%"> {{ ln["role_function"] }} </td>
                            <td style="width: 10%"> {{ idtovalue[ln["sent_to_vendor"]] }} </td>
                            <td style="width: 10%"> {{ ln["data_desc"] }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer"> <button type="button" class="btn btn-secondary"data-bs-dismiss="modal">
                    <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                </button> </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="B" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width2 modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content-full-width modal-content">
            <div class="modal-header-full-width modal-header text-center">
                <h5 class="modal-title w-100" id="exampleModalPreviewLabel"> Page Calculate </h5> <button type="button"
                    class="close"data-bs-dismiss="modal" aria-label="Close"> <span style="font-size: 1.3em"
                        aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body">
                <div class="col-12 text-center" style="margin-top: 2%">
                    <div>
                        <table class="mat-elevation-z8">
                            <thead>
                                <tr style="background-color: #c4e1ff; ">
                                    <th style="text-align: center">
                                        <!-- Measurement -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB49" ] }}
                                    </th>
                                    <th style="text-align: center">
                                        <!-- Quantity -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB33" ] }}
                                    </th>
                                    <th>
                                        <!-- Remark -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB41" ] }}
                                    </th>
                                    <th style="text-align: center" *ngIf="embData['compute'] != 'ACTUAL'">
                                        <!-- Action -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                        "EMB-EMB14" ] }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of measurearr; let j = index">
                                    <td>
                                        <h6>{{ i }}</h6>
                                    </td>
                                    <td> <input *ngIf="i == 'number'" type="text" class="form-control"
                                            placeholder="enter {{ i }}" [(ngModel)]="measureobj[j]['mes']"
                                            (change)="calculate()" />
                                            <input *ngIf="i != 'number'" type="number"
                                            class="form-control" placeholder="enter {{ i }}"
                                            [(ngModel)]="measureobj[j]['mes']" (change)="calculate()" />
                                     </td>
                                    <td> <input type="text" class="form-control" placeholder="enter remark"
                                            [(ngModel)]="measureobj[j]['desc']" /> 
                                    </td>
                                    <td *ngIf="computeq != 'ACTUAL'">
                                         <button (click)="add_n('',i, j)" class="btn btn-primary" *ngIf="i != 'number'"> 
                                            <i class="fa fa-plus" style="font-size: 20px" aria-hidden="true"></i> 
                                        </button>
                                        <button (click)="delete('',i, j)" style="font-size: 20px; margin-left: 5px" class="btn btn-danger"
                                            *ngIf=" computeq != 'ACTUAL' && !measureobj[j]['flag'] && i != 'number' ">
                                            <i class="fa fa-trash-o" aria-hidden="true"></i> </button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <br />
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-3 text-right">
                            <h6>
                                <!-- Calculated Quantity -->{{ mainService.allLableShowObj[ mainService.language_cd +
                                "EMB-EMB52" ] }} :
                            </h6>
                        </div>
                        <div class="col-3">
                            <!-- {{cal_q}} --> <input type="text" name="" [(ngModel)]="totaline" disabled
                                class="form-control" id="" />
                        </div>
                    </div> <br />
                </div> <br /> <!-- ---table -->
            </div>
            <div class="modal-footer-full-width modal-footer"> <button type="button"
                    class="btn btn-danger btn-md btn-rounded"data-bs-dismiss="modal" (click)="savemeasure()">
                    <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                </button> <button type="button" class="btn btn-primary btn-md btn-rounded"data-bs-dismiss="modal"
                    (click)="savemeasure()">
                    <!-- Save changes -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB53"] }}
                </button> </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">
                    <!-- Status -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB13"] }}
                </h4> <button type="button" class="close"data-bs-dismiss="modal"> &times; </button>
            </div>
            <div class="modal-body">
                <div class="container"> <a *ngFor="let ob of statusArr; let i = index">
                        <!-- <h6>{{ob['status']}}</h6> -->
                        <div class="step completed" *ngIf="ob.status == 'APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: rgb(196, 221, 253); margin-left: 40%"
                                    *ngIf="i < statusArr.length - 1"></div>
                            </div>
                            <div class="content"> {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.user_name }} - {{ ob.status }}
                            </div>
                        </div>
                        <div class="step"
                            *ngIf=" ob.status == 'REJECTED' || ob.status == 'REVOKED' || ob.status == 'SYSTEM REJECTED' ">
                            <div class="v-stepper">
                                <div class="circles"></div>
                            </div>
                            <div class="content"> {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.user_name }} - {{ ob.status }}
                            </div>
                        </div> <!-- active -->
                        <div class="step" *ngIf="ob.status == 'UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black; margin-left: 40%"
                                    *ngIf="i < statusArr.length - 1"></div>
                            </div>
                            <div class="content"> {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.user_name }} - {{ ob.status }}
                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} - {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status == 'PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black; margin-left: 40%"
                                    *ngIf="i < statusArr.length - 1"></div>
                            </div>
                            <div class="content"> {{ i + 1 }} - {{ ob.role_cd }} - {{ ob.user_name }} - {{ ob.status }}
                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} - {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a> </div> <!-- Modal footer -->
                <div class="modal-footer"> <button type="button" class="btn btn-danger"data-bs-dismiss="modal">
                        <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                    </button> <button type="button" class="btn btn-success"data-bs-dismiss="modal"
                        (click)="certificate()">
                        <!-- Download Certificate -->{{ mainService.allLableShowObj[mainService.language_cd +
                        "EMB-EMB54"] }}
                    </button> </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade right" id="Zreject" tabindex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"
    aria-hidden="true">
    <div class="modal-dialog-full-width modal-dialog momodel modal-fluid" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    <!-- See Workflow Details -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB18"]
                    }}
                </h5> <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close"> <span
                        aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body">
                <table class="mat-elevation-z8">
                    <thead>
                        <tr style="background-color: #c4e1ff; color: white">
                            <th style="width: 10%">
                                <!-- S No -->EMB ID
                            </th>
                            <th style="width: 20%">EMB Description</th>
                            <th style="width: 10%">EMB Number</th>
                            <th style="width: 20%">Date Of Measurement</th>
                            <th style="width: 10%">
                                <!-- Role -->Status
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ln of updateemblist; let i = index">
                            <td>{{ ln["id"] }}</td>
                            <td style="width: 20%"> {{ ln["emb_desc"] }} </td>
                            <td style="width: 10%"> {{ ln["emb_no"] }} </td>
                            <td style="width: 20%"> {{ ln["emb_dt"] }} </td>
                            <td style="width: 10%"> {{ [ln["status"]] }} </td>
                            <td style="width: 10%"> <button class="btn btn-primary"data-bs-dismiss="modal"
                                    (click)="open_update(ln)"> Update </button> </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer"> <button type="button" class="btn btn-secondary"data-bs-dismiss="modal">
                    <!-- Close -->{{ mainService.allLableShowObj[mainService.language_cd + "EMB-EMB45"] }}
                </button> </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4> <button type="button" class="close"data-bs-dismiss="modal"> &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="ApprovalUserService.vendorflag == false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id"> </ng-select>
                    </div>
                </div> <br />
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4"> <textarea style="width: 100%; height: 80px" id="re" name="re"
                            [(ngModel)]="appr_remark" placeholder="Enter Your Remark" class="form-control"></textarea>
                    </div>
                </div> <br />
                <div class="mat-elevation-z8">
                    <table >
                        <thead>
                            <tr>
                                <th style="width: 20%">S No.</th>
                                <th style="width: 20%">Decription</th>
                                <th style="width: 60%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td>{{ ln["desc"] }}</td>
                                <td style="width: 60%"> <input type="checkbox" [(ngModel)]="ln['check']"
                                        style="color: green" />
                                    <!-- <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check" style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button> <button class="btn btn-danger" (click)="copy(ln)"> <i class="fa fa-times" style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste" style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->
                                    <!-- </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> <br />
                <div class="row" style="border: black">
                    <div class="col-4 text-right"> <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1"
                            name="vehicle1" value="Bike" /> </div>
                    <div class="col-4">Notify Them</div>
                </div> <br />
                <div class="row" *ngIf="ApprovalUserService.vendorflag == false">
                    <div class="col-12 text-center"> <button class="btn btn-primary"data-bs-dismiss="modal"
                            (click)="Approvedapproval()"> Submit </button> </div>
                </div> <br />
                <!-- <div *ngIf="vendorflag==true" class="row"> <div class="col-12 text-center"> <h6>OR</h6> </div> </div> -->
                <div *ngIf="ApprovalUserService.vendorflag == true" class="row">
                    <div class="col-12 text-center"> <button class="btn btn-primary"data-bs-dismiss="modal"
                            (click)="Approvedapproval()"> Forward To Vendor </button> </div>
                </div> <!-- Modal footer -->
                <div class="modal-footer"> <button type="button" class="btn btn-danger"data-bs-dismiss="modal"> Close
                    </button> </div>
            </div>
        </div>
    </div>
</div>