<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Payment Gateway</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" *ngIf="list_flag"  data-toggle="tab"><i
                                    class="fa fa-credit-card"></i>&nbsp;Payment Gateway List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="create_flag"  data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i> Create Payment Gateway
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-7-3" data-toggle="tab"><i class="ti-settings"
                                    aria-hidden="true"> </i> Update User
                            </a>
                        </li> -->

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                                <div class="col-7 text-right">
                                    <button (click)="open_create()" class="btn btn-outline-primary">Create Payment Gateway </button>
                                </div>


                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort>

                                    <ng-container matColumnDef="s.no">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                          <h6>
                                            <b>S.No</b>
                                          </h6>
                                        </th>
                                        <td mat-cell *matCellDef="let element ; let i = index "> {{i+1}} </td>
                                      </ng-container>

                                    <ng-container matColumnDef="Bank_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Bank name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.Bank_name }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="branch_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Branch Name


                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.branch_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="gateway_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Gateway Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.gateway_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="payment_purpose">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Purpose
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.payment_purpose}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button
                                                class="btn btn-outline-danger" (click)=" delete(element)">Delete</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>

                        <div *ngIf="create_flag">
                            <div class="card">
                                <div style="margin:2%;">
                                    <br>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button (click)="open_list()" class="btn btn-outline-primary">Open
                                                List</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" >
                                        <div class="col-3"></div>
                                        <div class="col-3">
                                            <h6>Select Gatway :</h6>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="admindata" bindLabel="gateway_name"
                                                bindValue="gateway_id" [multiple]="false"
                                                placeholder="Select..."
                                                 [selectableGroup]="true"
                                                [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                                name="chart_of_account0">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div (click)="getAccountbyGatewayID(item.gateway_id,i)">
                                                        <!-- remove here -->
                                                        {{item.gateway_name}}
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                                    <div (click)="getAccountbyGatewayID(item.gateway_id,i)">
                                                        {{item.gateway_name}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div class="col-3"></div>
                                    </div>
                                    <br><br>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <button class="btn btn-outline-primary" (click)="submit(gateWayId)">Submit</button>
                                        </div>
                                    </div>
                                    <br><br>
                                    <div class="row" *ngIf="gateWayId" >
                                        <!-- {{gateWayId}} -->

                                        <!-- {{gatWayList[i] | json }} -->
                                        <div class="col-12 ml-3" >
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="row">S.No</th>
                                                    <th scope="col">Purpose</th>
                                                    <th scope="col">Account Number</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr  *ngFor="let item of gatWayList;let i=index " >
                                                    <ng-container   >
                                                        <th scope="row" class="text-left" >{{i+1}}</th>
                                                        <td  class="text-left">{{item['purpose']}}</td>
                                                        <td  class="text-left">{{item['account_no']}}</td>
                                                    </ng-container>
                                                  </tr>
                                                </tbody>
                                              </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
