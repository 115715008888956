import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class MutationService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/property/mutation";
  }

  //
  async createMutationRule(obj: any) {
    console.log("mutation obj pass in service ")
    const resp = await this.http.post(this.httpUrl + '/createMutationRule', obj).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    } else {
      return
    }
  }

  //

  //
  async getMutationRule(obj: any) {
    const resp = this.http.get(this.httpUrl + '/getMutationRule' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    } else {
      return
    }
  }

  async inactiveMutationRule(obj:any){
    const resp =   this.http.put<any>(this.httpUrl + '/inactiveMutationRule' ,obj).toPromise().then(res => {
      return res
    });
    return resp
  }


}
// }
