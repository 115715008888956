import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { MatStepperModule } from '@angular/material/stepper';
// import { ChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { AccountsLayoutsRoutingModule } from './accounts-layouts-routing.module';
import { AccFaqComponent } from "../account-help/acc-faq/acc-faq.component";
import { AccOnlineSupportComponent } from "../account-help/acc-online-support/acc-online-support.component";
import { AccTutorialComponent } from "../account-help/acc-tutorial/acc-tutorial.component";
import { AccUserManualComponent } from "../account-help/acc-user-manual/acc-user-manual.component";
import { AccountHelpComponent } from "../account-help/account-help.component";
import { AccountDefApprovalComponent } from "../accounts-administration/account-def-approval/account-def-approval.component";
import { AccountsAdministrationComponent } from "../accounts-administration/Accounts-administration.component";
import { AccountsDataAssignmentComponent } from "../accounts-administration/Accounts-data-assignment/Accounts-data-assignment.component";
import { AccountsDataAuthComponent } from "../accounts-administration/Accounts-data-auth/Accounts-data-auth.component";
import { AccountsHierarchyComponent } from "../accounts-administration/Accounts-hierarchy/Accounts-hierarchy.component";
import { AccountsRolesComponent } from "../accounts-administration/Accounts-roles/Accounts-roles.component";
import { AccountsUsersComponent } from "../accounts-administration/Accounts-users/Accounts-users.component";
import { AccountsDashComponent } from "../accounts-dash/accounts-dash.component";
import { AdviceComponent } from "../advice/advice.component";
import { BillComponent } from "../bill/bill.component";
import { BpComponent } from "../bp/bp.component";
import { ProductComponent } from "../budget/product.component";
import { ChallanComponent } from "../challan/challan.component";
import { ClosingStockComponent } from "../closing-stock/closing-stock.component";
import { CostCenterComponent } from "../cost-center/cost-center.component";
import { DemandComponent } from "../demand/demand.component";
import { DepartmentBillComponent } from "../department-bill/department-bill.component";
import { DepartmentChallansComponent } from "../department-challans/department-challans.component";
import { EventGroupComponent } from "../event-group/event-group.component";
import { AccJournalComponent } from "../ledger/acc-journal/acc-journal.component";
import { AccRuleComponent } from "../ledger/acc-rule/acc-rule.component";
import { ActivityHierComponent } from "../ledger/activity-hier/activity-hier.component";
import { BudHierComponent } from "../ledger/bud-hier/bud-hier.component";
import { CharOfAccountComponent } from "../ledger/char-of-account/char-of-account.component";
import { EventsComponent } from "../ledger/events/events.component";
import { JvComponent } from "../ledger/jv/jv.component";
import { LedgerComponent } from "../ledger/ledger.component";
import { OrgHierComponent } from "../ledger/org-hier/org-hier.component";
import { ProdHierComponent } from "../ledger/prod-hier/prod-hier.component";
import { ProjHierComponent } from "../ledger/proj-hier/proj-hier.component";
import { AdhocReportComponent } from "../ledger/report/adhoc-report/adhoc-report.component";
import { AllDedReportComponent } from "../ledger/report/all-ded-report/all-ded-report.component";
import { ArrListingComponent } from "../ledger/report/arr-listing/arr-listing.component";
import { BalanceSheetReportComponent } from "../ledger/report/balance-sheet-report/balance-sheet-report.component";
import { BankReportComponent } from "../ledger/report/bank-report/bank-report.component";
import { CogsReportComponent } from "../ledger/report/cogs-report/cogs-report.component";
import { FixAssestRegComponent } from "../ledger/report/fix-assest-reg/fix-assest-reg.component";
import { FixedAssetsMappingComponent } from "../ledger/report/fixed-assets-mapping/fixed-assets-mapping.component";
import { GstReportComponent } from "../ledger/report/gst-report/gst-report.component";
import { IncomeExpenseReportComponent } from "../ledger/report/income-expense-report/income-expense-report.component";
import { JrnlListingComponent } from "../ledger/report/jrnl-listing/jrnl-listing.component";
import { LedgerReportComponent } from "../ledger/report/ledger-report/ledger-report.component";
import { PartyReportComponent } from "../ledger/report/party-report/party-report.component";
import { ReportComponent } from "../ledger/report/report.component";
import { TdsGstReportComponent } from "../ledger/report/tds-gst-report/tds-gst-report.component";
import { TdsComponent } from "../ledger/report/tds/tds.component";
import { TrialBalanceComponent } from "../ledger/report/trial-balance/trial-balance.component";
import { AccCodeValueComponent } from "../setting/acc-code-value/acc-code-value.component";
import { AccFieldsComponent } from "../setting/acc-fields/acc-fields.component";
import { AccGstComponent } from "../setting/acc-gst/acc-gst.component";
import { AccSalComponent } from "../setting/acc-sal/acc-sal.component";
import { AccountInfoComponent } from "../setting/account-info/account-info.component";
import { ChartOfAccMappingComponent } from "../setting/chart-of-acc-mapping/chart-of-acc-mapping.component";
import { DeductiionMappingComponent } from "../setting/deductiion-mapping/deductiion-mapping.component";
import { EventLayoutsComponent } from "../setting/event-layouts/event-layouts.component";
import { FinYearComponent } from "../setting/fin-year/fin-year.component";
import { IpComponent } from "../setting/ip/ip.component";
import { JournalComponent } from "../setting/journal/journal.component";
import { AccountsPartyComponent } from "../setting/party/party.component";
import { ProjectBankAccComponent } from "../setting/project-bank-acc/project-bank-acc.component";
import { SalComponent } from "../setting/sal/sal.component";
import { AccountsSettingComponent } from "../setting/setting.component";
import { WorkComponent } from "../setting/work/work.component";
import { BankAccountComponent } from "../tresuary/bank-account/bank-account.component";
import { ContraComponent } from "../tresuary/contra/contra.component";
import { GatewayMIDConfigurationComponent } from "../tresuary/gateway-mid-configuration/gateway-mid-configuration.component";
import { TresuaryComponent } from "../tresuary/tresuary.component";
import { SavedReportComponent } from '../ledger/report/saved-report/saved-report.component';
import { CreateRoleComponent as AdminCreateRoleComponent } from '../../admin/administration/create-role/create-role.component';
import { JournalReportComponent } from '../ledger/report/journal-report/journal-report.component';
import { AccountsLayoutsComponent } from './accounts-layouts.component';
import { AccountsFooterComponent } from './accounts-footer/accounts-footer.component';
import { AccountsHeaderComponent } from './accounts-header/accounts-header.component';
import { AccountsSidebarComponent } from './accounts-sidebar/accounts-sidebar.component';
import { VendorsComponent } from '../../md/vendors/vendors.component';
import { SharedModule } from '../../shared/shared.module';
import { ReceiptsPaymentComponent } from '../ledger/report/receipts-payment/receipts-payment.component';
// import { WorkflowDefinitionComponent } from '../../md/workflow-definition/workflow-definition.component';
import { StoreKeeperRegisterComponent } from '../ledger/report/store-keeper-register/store-keeper-register.component';
@NgModule({
  declarations: [
    AccountsLayoutsComponent,
    AccountsHeaderComponent,
    AccountsFooterComponent,
    AccountsSidebarComponent,
    AllDedReportComponent,
    BankReportComponent,
    ProjectBankAccComponent,
    JvComponent,
    ArrListingComponent,
    JrnlListingComponent,
    EventsComponent,
    SalComponent,
    AccGstComponent,
    AccSalComponent,
    IpComponent,
    TdsComponent,
    JournalComponent,
    EventLayoutsComponent,
    TrialBalanceComponent,
    FinYearComponent,
    AccountInfoComponent,
    AccountInfoComponent,
    LedgerComponent,
    AccRuleComponent,
    ReportComponent,
    SavedReportComponent,
    AdhocReportComponent,
    CharOfAccountComponent,
    AccountsDashComponent,
    AdviceComponent,
    BillComponent,
    BankAccountComponent,
    ProductComponent,
    AccFieldsComponent,
    AccCodeValueComponent,
    WorkComponent,
    AccJournalComponent,
    AccountsPartyComponent,
    AccountsSettingComponent,
    DepartmentBillComponent,
    DepartmentChallansComponent,
    CostCenterComponent,
    AdminCreateRoleComponent,
    AccountDefApprovalComponent,
    EventGroupComponent,
    TdsGstReportComponent,
    DeductiionMappingComponent,
    LedgerReportComponent,
    ChartOfAccMappingComponent,
    GstReportComponent,
    GatewayMIDConfigurationComponent,
    BpComponent,
    ChallanComponent,
    PartyReportComponent,
    JournalReportComponent,
    ProdHierComponent,
    ProjHierComponent,
    BudHierComponent,
    ActivityHierComponent,
    TresuaryComponent,
    ContraComponent,
    DemandComponent,
    AccountHelpComponent,
    AccFaqComponent,
    AccUserManualComponent,
    AccTutorialComponent,
    AccOnlineSupportComponent,
    AccountsDataAuthComponent,
    BalanceSheetReportComponent,
    OrgHierComponent,
    IncomeExpenseReportComponent,
    ClosingStockComponent,
    FixedAssetsMappingComponent,
    CogsReportComponent,
    FixAssestRegComponent,
    AccountsAdministrationComponent,
    AccountsRolesComponent,
    AccountsUsersComponent,
    AccountsHierarchyComponent,
    AccountsDataAssignmentComponent,
     ReceiptsPaymentComponent,
     StoreKeeperRegisterComponent,
     VendorsComponent
  ],
  exports: [
    AccountsLayoutsComponent,
    AccountsHeaderComponent,
    AccountsFooterComponent,
    AccountsSidebarComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    AccountsLayoutsRoutingModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    NgSelectModule,
    NgChartsModule,
    // ChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    // NgtreegridModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
  ]
})
export class AccountsLayoutsModule { }
