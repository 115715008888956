import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AllreportService } from '../../service/allreport.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
// import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;
@Component({
  selector: 'app-allreports',
  templateUrl: './allreports.component.html',
  styleUrls: ['./allreports.component.css']
})
export class AllreportsComponent implements OnInit {
  ebillUser: any;
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  complaint = true;
  complaint_status = true;

  constructor(public allreport: AllreportService, private spinner: NgxSpinnerService,) { }

  ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    this.getallcomplain();
  }

  allreports: any = []
  public_count: any = []
  employee_count: any = []
  aasigned_count: any = []
  total_count_print: any = []
  ZO_TOTAL_CMP : any = []
  ZO_PEND_CMP : any = []
  AE_PEND_CMP : any = []
  AE_ASSI_CMP : any = []
  JE_PEND_CMP : any = []
  JE_ASSI_CMP : any = []
  async getallcomplain() {
    this.complaint = true;
    this.complaint_status = false;
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    let resp :any= await this.allreport.getComplainData(JSON.stringify(obj))


    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.allreports = resp['data']

    }

    for (let i = 0; i < this.allreports.length; i++) {
      this.public_count.push(this.allreports[i]['public_cmp_count'])
      this.employee_count.push(this.allreports[i]['emp_camp_count'])
      this.aasigned_count.push(this.allreports[i]['assigned_cmp_count'])
      this.ZO_TOTAL_CMP.push(this.allreports[i]['total_complaint'])
      this.ZO_PEND_CMP.push(this.allreports[i]['ZO_PENDING_CMP'])
      this.AE_ASSI_CMP.push(this.allreports[i]['AE_ASSI_CMP'])
      this.AE_PEND_CMP.push(this.allreports[i]['AE_PENDING_CMP'])
      this.JE_ASSI_CMP.push(this.allreports[i]['JE_ASSI_CMP'])
      this.JE_PEND_CMP.push(this.allreports[i]['PENDING_CMP'])
    }
    var a = this.public_count
    this.public_count = a.reduce(function (a:any, b:any) { return a + b; }, 0);
    var b = this.employee_count
    this.employee_count = b.reduce(function (a:any, b:any) { return a + b; }, 0);
    var c = this.aasigned_count
    this.aasigned_count = c.reduce(function (a:any, b:any) { return a + b; }, 0);
    var d = this.ZO_TOTAL_CMP
    this.ZO_TOTAL_CMP = d.reduce(function (a:any, b:any) { return a + b; }, 0);
    var e = this.ZO_PEND_CMP
    this.ZO_PEND_CMP = e.reduce(function (a:any, b:any) { return a + b; }, 0);
    var f = this.AE_ASSI_CMP
    this.AE_ASSI_CMP= f.reduce(function (a:any, b:any) { return a + b; }, 0);
    var e = this.AE_PEND_CMP
    this.AE_PEND_CMP =  e.reduce(function (a:any, b:any) { return a + b; }, 0);
    var g = this.JE_ASSI_CMP
    this.JE_ASSI_CMP = g.reduce(function (a:any, b:any) { return a + b; }, 0);
    var h = this.JE_PEND_CMP
    this.JE_PEND_CMP = h.reduce(function (a:any, b:any) { return a + b; }, 0);
    this.total_count_print.push(
      { 'total': 'Total count', 'public': this.public_count, 'employee': this.employee_count, 'assigned': this.aasigned_count ,
       'zo_total':this.ZO_TOTAL_CMP ,'zo_PEND':this.ZO_PEND_CMP , 'ae_assi':this.AE_ASSI_CMP,'ae_pend':this.AE_PEND_CMP,'je_ass':this.JE_ASSI_CMP,'je_pen':this.JE_PEND_CMP,'emp_n':''}
    )

console.log(this.total_count_print);


  }

  complain_status() {
    this.complaint_status = true;
    this.complaint = false
  }


  // /////view
  alldetails:any = []
  async JE_view(i:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.allreports[i].zone_cd
    this.spinner.show();
    let resp:any = await this.allreport.getallreoprts_details(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.alldetails = resp['data']
      $('#details').modal('show');
    }


  }


  async AE_view(i:any) {
    this.alldetails = [];
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.allreports[i].zone_cd
    this.spinner.show();
    let resp:any = await this.allreport.getAEreoprts_details(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.alldetails = resp['data']
      $('#details').modal('show');
    }


  }


  async ZO_view(i:any) {
    this.alldetails = [];
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['zone_cd'] = this.allreports[i].zone_cd
    this.spinner.show();
    let resp:any = await this.allreport.getZOreoprts_details(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.alldetails = resp['data']
      $('#details2').modal('show');
    }


  }
  // ///


















  print() {

    let account_name = this.erpUser['account_name'];
    let account_short_name = this.erpUser['account_short_name'];
    let txt = account_name + "(" + account_short_name + ") \n";
    let dd :any= {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        let arr = []
        let obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'potrait',
      pageMargins: [40, 60, 40, 60],
      content: []
    };
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.2 }] }); //x2:760
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    let tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ['*', '*', '*', '*'],
        body: [
          [{ colSpan: 4, text: 'Zone Wise ICMS Status', alignment: 'center', bold: true }, {}, {}, {}],
          [{ rowSpan: 2, text: 'Zone', bold: true, alignment: 'center' }, { colSpan: 2, text: 'Number of Applications', alignment: 'center', bold: true }, {}, { rowSpan: 2, text: 'Forwarded to Field Staff for Survey ', alignment: 'center', bold: true }],
          [{}, { text: 'From Public', alignment: 'center', bold: true }, { text: 'Staff Survey', alignment: 'center', bold: true }, {}],
        ],
      }
    };

    dd.content.push(tbl1);
    let tbl2 :any= {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ['*', '*', '*', '*'],
        body: [],
      }
    };

    this.allreports.map((x:any) => {
      let arr = []
      arr.push({ text: x['zone_name'], bold: true, alignment: 'center' })
      arr.push({ text: x['public_cmp_count'], alignment: 'center' })
      arr.push({ text: x['emp_camp_count'], alignment: 'center' })
      arr.push({ text: x['assigned_cmp_count'], alignment: 'center' })
      tbl2.table.body.push(arr);
    })
    let arr2 = []
    arr2.push({ text: this.total_count_print[0]['total'], bold: true, alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['public'], bold: true, alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['employee'], bold: true, alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['assigned'], bold: true, alignment: 'center' })
    tbl2.table.body.push(arr2);
    dd.content.push(tbl2);
    pdfMake.createPdf(dd).download('All Reports' + '.pdf');
  }



  print2() {
    console.log("i am work")
    let account_name = this.erpUser['account_name'];
    let account_short_name = this.erpUser['account_short_name'];
    let txt = account_name + "(" + account_short_name + ") \n";
    let dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        let arr = []
        let obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'potrait',
      pageMargins: [40, 60, 40, 60],
      content: []
    };
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 760, y2: 0, lineWidth: 0.2 }] }); //x2:760
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    let tbl1 = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          [{ colSpan: 8, text: 'Zone Wise ICMS Status', alignment: 'center', bold: true }, {}, {}, {}, {}, {}, {},{}],
          [{ rowSpan: 2, text: 'Zone', bold: true, alignment: 'center' }, { colSpan: 3, text: 'Vihit Pradhikari/ Zonal Officer', alignment: 'center', bold: true },  {},{}, { colSpan: 2, text: 'AE', alignment: 'center', bold: true }, {},  { colSpan: 2, text: 'JE', alignment: 'center', bold: true }, {}],
          // [{}, { text: 'From Public', alignment: 'center', bold: true }, { text: 'Staff Survey', alignment: 'center', bold: true }, {}],
          [{}, { text: 'Username', alignment: 'center', bold: true, }, { text: 'Total', alignment: 'center', bold: true}, { text: 'Pending', alignment: 'center', bold: true},  { text: 'Total', alignment: 'center', bold: true}, { text: 'Pending', alignment: 'center', bold: true}, { text: 'Total', alignment: 'center', bold: true}, { text: 'Pending', alignment: 'center', bold: true}]
        ],
      }
    };

    dd.content.push(tbl1);
    let tbl2:any = {
      fontSize: 10,
      table: {
        headerRows: 3,
        widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
        body: [],
      }
    };

    this.allreports.map((x:any) => {
      let arr = []
      arr.push({ text: x['zone_name'],bold: true,alignment: 'center'  })
      arr.push({ text: x['ZO_NAME'],alignment: 'center' })
      arr.push({ text: x['total_complaint'],alignment: 'center' })
      arr.push({ text: x['ZO_PENDING_CMP'],alignment: 'center' })
      arr.push({ text: x['AE_ASSI_CMP'],alignment: 'center' })
      arr.push({ text: x['AE_PENDING_CMP'],alignment: 'center' })
      arr.push({ text: x['JE_ASSI_CMP'],alignment: 'center' })
      arr.push({ text: x['PENDING_CMP'],alignment: 'center' })


      tbl2.table.body.push(arr);
    })
    let arr2 = []
    arr2.push({ text: this.total_count_print[0]['total'],bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['emp_n'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['zo_total'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['zo_PEND'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['ae_assi'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['ae_pend'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['je_ass'], bold: true,alignment: 'center' })
    arr2.push({ text: this.total_count_print[0]['je_pen'], bold: true,alignment: 'center' })


    tbl2.table.body.push(arr2);
    dd.content.push(tbl2);




    pdfMake.createPdf(dd).download('All Reports' + '.pdf');





  }
}
