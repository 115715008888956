import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  httpUrl;


  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/hr/setting";
  }
  //fields
  async getFields(obj:any){

    const res = await this.http.get<any>(this.main.httpUrl + '/metadata/fields/getfields' + obj).toPromise().then(res => {
      return res;

    });
    return res;

  }
  async createFields(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/fields/createField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateFields(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/fields/updateField', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  //codeValue
  async getCodeValue(b_acct_id:any) {
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getCodeValues' + b_acct_id).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getFieldCodeValues(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/metadata/codeValue/getFieldCodeValues' + JSON.stringify(obj)).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async insertCodeValue(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/metadata/codeValue/createCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async updateCodeValues(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/metadata/codeValue/updateCodeValue', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async deleteCodeValue(obj:any){
    const resp = await this.http.delete<any>(this.main.httpUrl + '/metadata/codeValue/deleteCodeValue' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  // *******************************************section map service***************//

  async sectionMap(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/inspection/sectionMap', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getSectionMap(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/inspection/getSectionMap' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteSectionMap(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/icms/inspection/deleteSectionMap', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  /***************************************************Recovery Calculation****************************************** */

  async getOrderDataOnCaseID(obj:any){
    let resp = await this.http.post<any>(this.main.httpUrl + '/icms/court-order/getOrderDataOnCaseID', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }

  async insertRecoveryCalculationData(obj:any){
    let resp = await this.http.post<any>(this.main.httpUrl + '/icms/court-order/insertRecoveryData', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  async getDocumentDetails(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getDocumentDetails' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  /***************************************************************Support Department Screen start****************************/

  async createDepartment(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/support-department/createDepartment', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  async getDepartmentData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/support-department/getDepartment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async delete(obj:any){
    const resp = await this.http.delete<any>(this.main.httpUrl + '/icms/support-department/deleteDepartment' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateDepartment(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/icms/support-department/updateDepartment', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


  /**********************************************************Support letter Screen start *************************************/
  async getOfficerName(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/support-department/getOfficerName' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getZoneWiseUser(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/support-department/getZoneUser', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getSupportLetter(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/support-department/getSupportLetter' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }



  async createSupportLetter(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/support-department/createSuppletter', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async updateSupportLetter(obj:any){
    const resp = await this.http.put<any>(this.main.httpUrl + '/icms/support-department/updateSupportLetter', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  /**********************************************************Support letter Screen end *************************************/
  // court order screen start

  async getOrderData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getOrderData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getCourtData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getCourtData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getExecutionData(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getExecutionData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async downloadexecutionImage(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/icms/court-order/downloadexecutionImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async viewDefendentImage(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/icms/court-order/viewDefendentImage', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }

  async submitReportSub(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/upload/uploadReport', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async forwordFile(obj:any){
    const resp = await this.http.post<any>(this.main.httpUrl + '/icms/court-order/forwordFile', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAttachedDocumnet(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/icms/court-order/getAttachedDoc', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }



  async downloadDocument(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/icms/court-order/downloadDocument', obj, { responseType: 'blob' }).toPromise().then(res => {
      return res;
    });
    if (resp) {
      return resp;
    }
    return
  }


  async defendentDocList(obj:any){
    const resp = await this.http.post(this.main.httpUrl + '/icms/court-order/defendentDocList', obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }
  // court order screen end
  // Assigned Order screen start

  async getAssignedOrder(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/court-order/getAssignedOrder' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  // Assigned order component end

  //Notification task


  async getPendingTask(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/icms/task/getPendingTask' + obj).toPromise().then(res => {
      return res;
    })
    return resp;
  }


}
