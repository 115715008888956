import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '../../../portal/service/profile.service';
import { MainService as portal_Mainservice } from '../../../portal/service/main.service';
import { MainService } from '../../service/main.service';
import { AuthenticationService } from '../../../authentication.service';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: '[app-emb-header]',
  templateUrl: './emb-header.component.html',
  styleUrls: ['./emb-header.component.css']
})
export class EmbHeaderComponent implements AfterViewInit {

  constructor(public auth:AuthenticationService,private router: Router, public portal_main: portal_Mainservice, public mainService: MainService, private profileService: ProfileService, private sanitizer: DomSanitizer, ) { }

  erpUser:any = {};
   action = [
    { id: 1, name: 'Profile', route: "profile" },
    { id: 2, name: 'Logout', route: "login" },

  ];
  @ViewChild('ngSelectElement') ngSelectElement!: NgSelectComponent;
  placeholderText:any
  selectedAction:any
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    await this.portal_main.getUserImage(this.erpUser['user_id']);
    await this.portal_main.getAccImage(this.erpUser['b_acct_id']);
    await this.portal_main.getAccountInfo(this.erpUser['b_acct_id']);
    await this.portal_main.getHeaderInfo(this.erpUser['b_acct_id']);
    await this.changeLanguage();
    this.placeholderText = this.erpUser['party_name']
  }

  onSelectionChange2(){
    const selectedVal = this.selectedAction.route
    this.router.navigate([selectedVal]);
  }

  toggleSelect(){
    this.ngSelectElement.toggle();
  }
  changeLanguage(){
    console.log("laungage Change");
    this.mainService.language_cd = this.auth.language_cd
  }
  home() {
    this.router.navigate(['/index'])
  }
  
  ngAfterViewInit() {
  }

}