import { Component, OnInit ,ViewChild} from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { ProfileService } from '../service/profile.service'
import { Router } from '@angular/router';
import { MainService } from '../../hrms/service/main.service';
import { EstablishmentService } from '../../hrms/service/establishment.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table"; 
import { MainService as md_mainService } from '../../md/service/main.service';
import { MainService as MD_mainService } from '../../md/service/main.service';
import { RoleService as MD_RoleService } from '../../md/service/role.service';
import { DataAccessService } from '../../hrms/service/data-access.service';
import { SettingService } from '../../hrms/service/setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComplaintWorkflowService } from '../service/complaint-workflow.service';
import { EstablishService } from '../../hrms/service/establish.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-complaint-workflow',
  templateUrl: './complaint-workflow.component.html',
  styleUrls: ['./complaint-workflow.component.css']
})
export class ComplaintWorkflowComponent implements OnInit {

  constructor(private router: Router,private sanitizer: DomSanitizer,private establishService: EstablishService, private complaintWorkflowService: ComplaintWorkflowService ,private profileService: ProfileService,public mainService: MainService,private establishmentService: EstablishmentService,
    private dataAccessService:DataAccessService,private MD_mainService: MD_mainService,private settingService: SettingService,private MD_RoleService: MD_RoleService,
    private spinner: NgxSpinnerService,private mainServiceMD: md_mainService,private snackBar: MatSnackBar) { }
   
  erpUser:any;
  b_acct_id:any;
  datasource:any;
  codeValueTechObj:any={};
  emp_name:any
  allEmplyees:any = [];
  selectEmpObj:any = {};
  complaintObj = {};
  updateComplaintObj={};
  arr_id:any;
  newallEmplyees:any = []
  allComplaintArray:any = [];
  empIdToName:any={}
  allModule = [];
  moduleToRole = {};
  roleToModule = {};
  allUsers = [];
  allRole = [];
  current_role:any = [];
  current_LE = [];
  ind_current_LE = [];
  org_current_LE = [];
  moduleObj = {};
  forObj:any = {};
  closeObj:any = {};
  approveObj:any = {};
  allModuleWiseUser:any = [];
  suspensionCompObj:any = {};
  payCompObj:any = {};
  dedCompObj:any = {};
  payCompArr:any = [];
  dedCompArr:any = [];
  payCompCount = 0;
  dedCompCount = 0;
  allActiveEmplyees = [];
  allPayDetails = [];
  activeEmpArr:any = [];
  inputPayCompArr:any = [];
  inputDedCompArr:any = [];
  assignedEmp = {};
  ArrIdToWOrkIDObj = {};
  UserIdToArrIdObj = {};
  complaintFile = [];
  forwardEmpNameId:any;
  imgURL:any;
  fileURL:any;
  filename:any;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['id', 'complaint_type_code', 'complaint_desc', 'complaint_dt','emp_id','emp_name','complaint_status','action','view_details'];

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getCurrentData(this.b_acct_id,this.erpUser.user_id)
    await this.getComplaintFile();
    await this.getAllActiveEmployees();
    await this.getCurrentLegalEntity();
    await this.getComplaintStatus();
    await this.getAllAccountModule();
  }
   async   getCurrentData(b_acct_id:any,user_id:any){
     await this.complaintWorkflowService.getCurrentDataAccess(b_acct_id,user_id)
     }

  async getComplaintFile() {
        let resp1 = await this.establishmentService.getComplaintFile(this.b_acct_id);
        if (resp1['error'] == false) {
          this.spinner.hide()
          this.complaintFile = [];
          this.complaintFile = resp1['data'];
        } else {
          this.spinner.hide()
          this.snackBar.open("Error while getting Complain File", 'Error', {
            duration: 5000
          });
        }
      }

  async view(element:any){
    var obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = element.upload_id
    obj['filename'] = element.document_name
    this.filename = element.document_name
    this.spinner.show()
    const res = await this.establishmentService.getUploadedFileData_N(obj);
    if (res) {
     
     var docname = element.document_name;
     var ext = docname.split('.');
     
      if(ext[1].toLowerCase() == 'png' || ext[1].toLowerCase() == 'jpeg' || ext[1].toLowerCase() == 'jpg'){
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      }else{
        let file = new Blob([res], { type: 'application/pdf' });            
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      this.spinner.hide()

    }
  }

  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    var resp = await this.mainServiceMD.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.current_LE = resp['data']
      let ind:any = []
      let org:any = []
      for (let i = 0; i < this.current_LE.length; i++) {
        if (this.current_LE[i]['party_type'] == 'IND') {
          ind.push(this.current_LE[i])
        } else {
          org.push(this.current_LE[i])
        }
      }
      this.ind_current_LE = ind
      this.org_current_LE = org
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }
  
  async getFixedPayDetails(emp_id:any){
    var obj:any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id']=  emp_id;//this.suspensionCompObj['emp_id']
    for(let i = 0;i<this.activeEmpArr.length;i++)
      {
          if(obj['emp_id'] == this.activeEmpArr[i]['emp_id']){
              obj['arr_id'] = this.activeEmpArr[i]['arr_id'];
              break;
          }
      }
    var resp = await this.settingService.getFixedPayDetails(obj);
    if(resp['error'] == false){
      this.allPayDetails = resp.data;
      for(let i=0;i<this.allPayDetails.length;i++)
      {
        if(this.allPayDetails[i]['pay_code'] == 'PAY'){
          this.payCompArr.push(this.allPayDetails[i]) 
          this.payCompCount++;
        }
        else{
          this.dedCompArr.push(this.allPayDetails[i]) 
          this.dedCompCount++;
        }
      }
      for (let i = 0; i < this.payCompCount; i++) {
        this.payCompObj[i] = 0;
      }
      for (let i = 0; i < this.dedCompCount; i++) {
        this.dedCompObj[i] = 0;
      }
    }
  else{
    this.spinner.hide()
    Swal.fire('Error','Error while getting employee salary','error')
    }
   }

  /* Module Name*/
  async getAllAccountModule() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  /*For Roles */
  async getcurrentroleswithresources(module_cd:any) {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = module_cd;
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
     this.current_role = []
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
          this.current_role.push(resp['data'][i])
        // }
      }
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  /*For All Users Mapped with Module wise */
  async getCurrentUsers(role_desc:any,module_cd:any) {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    
    obj['module_cd'] = module_cd;
    obj['role_desc'] = role_desc;
    var resp = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));
  
    if (resp['error'] == false) {
      this.spinner.hide();
      this.allUsers = resp['data']
      var emp_id:any = new Object();
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.allRole.length; j++) {
          if (this.allRole[j]['role_cd'] == resp['data'][i]['role_cd']) {
            if (emp_id[resp['data'][i]['user_id']] == undefined) {
              resp['data'][i]['role_name'] = this.allRole[j]['role_name']
              emp_id[resp['data'][i]['user_id']] = resp['data'][i]
            } else {
              emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.allRole[j]['role_name']
            }
          }
        }
      }
      console.log(emp_id)
      var data:any = Object.values(emp_id);

      console.log(data)
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.current_LE.length; j++) {
          if (this.current_LE[j]['le_id'] == data[i]['user_id']) {
            data[i]['party_name'] = this.current_LE[j]['party_name']
            data[i]['contact_email'] = this.current_LE[j]['contact_email']
            data[i]['phone_no'] = this.current_LE[j]['phone_no']
          }
        }
      }
      // data=await this.dataAccessService.filterEmployeeData(data)
      var temp_data=await this.complaintWorkflowService.filterEmployeeDataByUserId(data)
      this.allModuleWiseUser = temp_data;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }



   /*For Current Role */
  async getCurrentRole(module_cd:any) {
    this.spinner.show()
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = module_cd;
    console.log(obj)
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.current_role = [];
      this.allRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
          this.current_role.push(resp['data'][i])
        // }
      }
      console.log(this.current_role)
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }

  async getAllActiveEmployees() {
    //await this.getAllComplaint();
    var obj:any=new Object();
     obj['b_acct_id']=this.b_acct_id;
     obj['emp_status_code'] = ['ACTIVE','INACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
  //this.allActiveEmplyees=[]

    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      this.activeEmpArr = []
      let compStatusArray = [];

      for(let j=0;j<this.allComplaintArray.length;j++){
        if(this.allComplaintArray[j]['complaint_status'] == 'APPROVED' || this.allComplaintArray[j]['complaint_status'] == 'FORWARD' || this.allComplaintArray[j]['complaint_status'] == 'APPROVED' ){
          compStatusArray.push(this.allComplaintArray[j]['emp_id']);
          } 
        }
     
      for (let i = 0; i < this.allEmplyees.length; i++) {
        if(this.allEmplyees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmplyees[i])
         }
      }
      this.newallEmplyees = [];
      for(let i=0;i<this.activeEmpArr.length;i++){
        var obj:any=new Object();
        obj=Object.assign({},this.activeEmpArr[i]);
        obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
        this.newallEmplyees.push(obj)
      }
      for(let i=0;i<this.allEmplyees.length;i++){
        this.empIdToName[this.allEmplyees[i].emp_id]=this.allEmplyees[i].emp_id;
        this.empIdToName[this.allEmplyees[i].emp_name]=this.allEmplyees[i].emp_name;
      }
      }
     else {
      this.snackBar.open("Error while getting employee list", 'Error', {
        duration: 5000
      });
    }
  }

  async getComplaintStatus(){
    this.spinner.show()
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['forward_to_user_id'] = this.erpUser.user_id;
    var resp = await this.establishmentService.getComplaintStatus(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allComplaintArray=[];
      this.allComplaintArray = resp.data;
      for(let j=0;j<this.allEmplyees.length;j++){
         for(let i=0;i<this.allComplaintArray.length;i++)
          {
          if(this.allComplaintArray[i]['emp_id'] == this.allEmplyees[j]['emp_id'] ){
            this.allComplaintArray[i]['emp_name'] = this.allEmplyees[j]['emp_name']
          }
        }
     }
     for(let i=0;i<this.allComplaintArray.length;i++)
     {
        for(let j= 0;j<this.complaintFile.length;j++){
            if(this.allComplaintArray[i]['id'] == this.complaintFile[j]['complaint_id']){
              this.allComplaintArray[i]['document_name'] = this.complaintFile[j]['document_name']
              this.allComplaintArray[i]['document_type_code'] = this.complaintFile[j]['document_type_code']
              this.allComplaintArray[i]['upload_id'] = this.complaintFile[j]['upload_id']
            }
        }
     }
      this.datasource = new MatTableDataSource(this.allComplaintArray)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting employee all complaint status list", 'Error', {
        duration: 5000
      });
    }
  }

  async openUpdate(element:any){
    this.updateComplaintObj=Object.assign({},element);
    $('.nav-tabs a[href="#tab-3"]').tab('show');
  //   for (let i = 0; i < this.allEmplyees.length; i++) {
      
      
   
  //   if(this.allEmplyees[i]['emp_id'] == element['emp_id'] ){
  //     this.updateComplaintObj['emp_name'] = this.allEmplyees[i]['emp_name']
     
  //   }
  // }
  

  }

  // async getAllComplaint() {
  //   this.spinner.show()
  //   var resp = await this.establishmentService.getAllComplaints(this.b_acct_id);
  //   if (resp['error'] == false) {
  //     this.spinner.hide()
  //     this.allComplaintArray=[];
  //     this.allComplaintArray = resp.data;
  //     for(let i=0;i<this.allComplaintArray.length;i++)
  //     {
  //       for(let j=0;j<this.allEmplyees.length;j++){
  //         if(this.allComplaintArray[i]['emp_id'] == this.allEmplyees[j]['emp_id']){
  //           this.allComplaintArray[i]['emp_name'] = this.allEmplyees[j]['emp_name']
  //         }
  //       }
  //     }
  //     this.datasource = new MatTableDataSource(this.allComplaintArray)
  //     this.datasource.paginator = this.paginator;
  //     this.datasource.sort = this.sort;
  //   } else {
  //     this.spinner.hide()
  //     this.snackBar.open("Error while getting employee all complaint list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }

  selectUser(id:any){
    let userId = id;
    for(let i=0;i<this.allModuleWiseUser.length;i++)
    {
      if(this.allModuleWiseUser[i]['user_id'] == userId){
        this.forwardEmpNameId = this.allModuleWiseUser[i]['party_name'];
      }
    }
  }
 
  async changeEmployee() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = this.selectEmpObj['emp_id']
    
  }

  //Deepankar
  open_forward(element:any){
    this.forObj=Object.assign({},element);
    $('#FORWARD').modal('show');
  }
  open_close(element:any){
    this.closeObj=Object.assign({},element);
    $('#CLOSE').modal('show');
  }

  async approve(element:any){
    this.approveObj = Object.assign({},element);
    let approveObj1:any = new Object();
    approveObj1['b_acct_id'] = this.b_acct_id;
    approveObj1['user_id_by'] = this.erpUser.user_id;
    approveObj1['update_user_id'] = this.erpUser.user_id;
    approveObj1['complaint_id'] = this.approveObj['id'];
    approveObj1['complaint_workflow_status'] = 'APPROVED';
    var resp = await this.establishmentService.approveComplaint(approveObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
     await this.getComplaintStatus();
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      swal.fire('Success', 'Complaint Approved Successfully!','success')
      // this.snackBar.open("Complaint Approved Successfully!", 'Success', {
      //   duration: 5000
      // });
   } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Approving Complaint  Of Employee','error');
      // this.snackBar.open("Error while Approving Complaint  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async open_suspend(element:any){
    this.suspensionCompObj = Object.assign({},element);
    $('.nav-tabs a[href="#tab-7-2"]').tab('show');
    let currDate = new Date().toISOString().slice(0, 10);
    this.suspensionCompObj['suspension_start_dt'] = currDate;
    await this.getFixedPayDetails(this.suspensionCompObj['emp_id']);
  }

  popup_cancel(){
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
  }

  async forwardComplaint(){
    if(this.forObj['user_id']==null || this.forObj['user_id']==undefined || this.forObj['user_id']==''){
      Swal.fire("Error",'User Id  can not be null','error')
        return
    }
    let forObj1:any = new Object();
    forObj1['b_acct_id'] = this.b_acct_id;
    forObj1['user_id_by'] = this.erpUser.user_id;
    forObj1['user_id_to'] = this.forObj['user_id']
    forObj1['complaint_id'] = this.forObj['id'];
    forObj1['complaint_workflow_status'] = 'FORWARD';
    var resp = await this.establishmentService.forwardComplaint(forObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      
      $('#FORWARD').modal('hide');
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    await this.getComplaintStatus();
      this.snackBar.open("Complaint Forward Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Forwarding Complaint  Of Employee", 'Error', {
        duration: 5000
      });
    }
  }
  async closeComplaint(){
    let closeObj1:any = new Object();
    closeObj1['b_acct_id'] = this.b_acct_id;
    closeObj1['update_user_id'] = this.erpUser.user_id;
    closeObj1['complaint_id'] = this.closeObj['id'];
    closeObj1['complaint_status'] = 'CLOSED'
    closeObj1['close_by_user_id'] = this.erpUser.user_id;
    closeObj1['closing_note'] = this.closeObj['closing_note'];
    closeObj1['user_id_by'] = this.erpUser.user_id;
    var resp = await this.establishmentService.closeComplaint(closeObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
     await this.getComplaintStatus();
      $('#CLOSE').modal('hide');
      $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      this.snackBar.open("Complaint Closed Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Closing Complaint  Of Employee", 'Error', {
        duration: 5000
      });
    }
    }  
async selectedModule(module_cd:any){
    await this.getcurrentroleswithresources(module_cd);
    await this.getCurrentRole(module_cd);
     
  }

  async selectedRole(role_desc:any,module_cd:any){
    await this.getCurrentUsers(role_desc,module_cd); 
  }

 async submitCompSuspension(){
    if(this.suspensionCompObj['emp_id']==null || this.suspensionCompObj['emp_id']==undefined || this.suspensionCompObj['emp_id']==''){
      Swal.fire("Error",'Employee Name can not be null','error')
        return
    }
    if(this.suspensionCompObj['order_id']==null || this.suspensionCompObj['order_id']==undefined || this.suspensionCompObj['order_id']==''){
      Swal.fire("Error",'Order can not be null','error')
        return
    }
    if(this.suspensionCompObj['suspension_start_dt']==null || this.suspensionCompObj['suspension_start_dt']==undefined || this.suspensionCompObj['suspension_start_dt']==''){
      Swal.fire("Error",'Suspension Start Date can not be null','error')
        return
    }
    if(this.suspensionCompObj['suspension_note']==null || this.suspensionCompObj['suspension_note']==undefined || this.suspensionCompObj['suspension_note']==''){
      Swal.fire("Error",'Suspension Note can not be null','error')
        return
    }
    for (let i = 0; i < this.payCompCount; i++) {
      if(this.payCompObj[i] <= 100 && this.payCompObj[i]>=0){
      if(this.payCompObj[i] == 0 || this.payCompObj[i] == null || this.payCompObj[i]==undefined){
        this.inputPayCompArr.push({
          pay_component_code: this.payCompArr[i]['pay_component_code'],
          arr_id: this.payCompArr[i]['arr_id'],
          pay_id: this.payCompArr[i]['pay_id'],
          pay_code: this.payCompArr[i]['pay_code'],
          fraction_per: this.payCompObj[i],
          old_rate: this.payCompArr[i]['rate'],
          new_rate: this.payCompArr[i]['rate'],
          create_user_id: this.erpUser.user_id,
          employee_current_type_code: 'SUSPENDED',
          emp_id : this.suspensionCompObj['emp_id'],
          order_id: this.suspensionCompObj['order_id'],
          suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
          suspension_note: this.suspensionCompObj['suspension_note'],
        });
      }
      else if(this.payCompObj[i] == 100)
      {
        this.inputPayCompArr.push({
          pay_component_code: this.payCompArr[i]['pay_component_code'],
          arr_id: this.payCompArr[i]['arr_id'],
          pay_id: this.payCompArr[i]['pay_id'],
          pay_code: this.payCompArr[i]['pay_code'],
          fraction_per: this.payCompObj[i],
          old_rate: this.payCompArr[i]['rate'],
          new_rate: 0,
          create_user_id: this.erpUser.user_id,
          employee_current_type_code: 'SUSPENDED',
          emp_id : this.suspensionCompObj['emp_id'],
          order_id: this.suspensionCompObj['order_id'],
          suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
          suspension_note: this.suspensionCompObj['suspension_note'],
        });
      }
      else{
      this.inputPayCompArr.push({
        pay_component_code: this.payCompArr[i]['pay_component_code'],
        arr_id: this.payCompArr[i]['arr_id'],
        pay_id: this.payCompArr[i]['pay_id'],
        pay_code: this.payCompArr[i]['pay_code'],
        fraction_per: this.payCompObj[i],
        old_rate: this.payCompArr[i]['rate'],
        new_rate: ((this.payCompArr[i]['rate']*this.payCompObj[i])/100).toFixed(2),
        create_user_id: this.erpUser.user_id,
        employee_current_type_code: 'SUSPENDED',
        emp_id : this.suspensionCompObj['emp_id'],
        order_id: this.suspensionCompObj['order_id'],
        suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
        suspension_note: this.suspensionCompObj['suspension_note'],
      });
    }
  }
  else{
    Swal.fire("Error",'Pay Component Fraction can not greater than 100 or less than 0','error')
      return
  }
    }
    for (let i = 0; i < this.dedCompCount; i++) {
      if(this.payCompObj[i] <= 100 && this.payCompObj[i]>=0){
      if(this.dedCompObj[i] == 0 || this.dedCompObj[i] == null || this.dedCompObj[i]==undefined){
        this.inputDedCompArr.push({
          pay_component_code: this.dedCompArr[i]['pay_component_code'],
          arr_id: this.dedCompArr[i]['arr_id'],
          pay_id: this.dedCompArr[i]['pay_id'],
          pay_code: this.dedCompArr[i]['pay_code'],
          fraction_per: this.dedCompObj[i],
          old_rate: this.dedCompArr[i]['rate'],
          new_rate: this.dedCompArr[i]['rate'],
          create_user_id: this.erpUser.user_id,
          employee_current_type_code: 'SUSPENDED',
          emp_id : this.suspensionCompObj['emp_id'],
          order_id: this.suspensionCompObj['order_id'],
          suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
          suspension_note: this.suspensionCompObj['suspension_note'],
        });
      }
      else if(this.dedCompObj[i] == 100){
        this.inputDedCompArr.push({
          pay_component_code: this.dedCompArr[i]['pay_component_code'],
          arr_id: this.dedCompArr[i]['arr_id'],
          pay_id: this.dedCompArr[i]['pay_id'],
          pay_code: this.dedCompArr[i]['pay_code'],
          fraction_per: this.dedCompObj[i],
          old_rate: this.dedCompArr[i]['rate'],
          new_rate: 0,
          create_user_id: this.erpUser.user_id,
          employee_current_type_code: 'SUSPENDED',
          emp_id : this.suspensionCompObj['emp_id'],
          order_id: this.suspensionCompObj['order_id'],
          suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
          suspension_note: this.suspensionCompObj['suspension_note'],
        });
      }
      else{
      this.inputDedCompArr.push({
        pay_component_code: this.dedCompArr[i]['pay_component_code'],
        arr_id: this.dedCompArr[i]['arr_id'],
        pay_id: this.dedCompArr[i]['pay_id'],
        pay_code: this.dedCompArr[i]['pay_code'],
        fraction_per: this.dedCompObj[i],
        old_rate: this.dedCompArr[i]['rate'],
        new_rate: ((this.dedCompArr[i]['rate']*this.dedCompObj[i])/100).toFixed(2),
        create_user_id: this.erpUser.user_id,
        employee_current_type_code: 'SUSPENDED',
        emp_id : this.suspensionCompObj['emp_id'],
        order_id: this.suspensionCompObj['order_id'],
        suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
        suspension_note: this.suspensionCompObj['suspension_note'],
      });
    }
  }
  else{
    Swal.fire("Error",'Pay Component Fraction can not greater than 100 or less than 0','error')
      return
  }
  }
    //deepankar
    this.spinner.show()
    let suspensionCompObj1:any = new Object();
    suspensionCompObj1['b_acct_id'] = this.b_acct_id;
    suspensionCompObj1['complaint_id'] = this.suspensionCompObj['id'];
    suspensionCompObj1['complaint_status'] = 'SUSPENDED';
    suspensionCompObj1['update_user_id'] = this.erpUser.user_id;
    suspensionCompObj1['suspend_by_user_id'] = this.erpUser.user_id;
    suspensionCompObj1['user_id_by'] = this.erpUser.user_id;
    suspensionCompObj1['pay'] = this.inputPayCompArr;
    suspensionCompObj1['ded'] = this.inputDedCompArr;
  // this.suspensionObj['b_acct_id'] = this.b_acct_id;
  // this.suspensionObj['create_user_id'] = this.erpUser.user_id;
  // this.suspensionObj['employee_current_type_code'] = 'SUSPENDED'  
  var resp = await this.establishmentService.suspendCompEmployee(suspensionCompObj1);
  if (resp['error'] == false) {
  //  await this.getAllComplaint();
    this.spinner.hide();
    this.suspensionCompObj = {};
    this.payCompArr = [];
    this.dedCompArr = [];
    this.snackBar.open("Suspension  Added Successfully!", 'Success', {
      duration: 5000
    });
  } else {
    this.spinner.hide();
    this.snackBar.open("Error while Adding Suspension  Of Employee", 'Error', {
      duration: 5000
    });
  }
  }

  back() {
    this.router.navigate(['/index'])
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
