import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { MainService } from "../../service/main.service";
import { SettingService } from "../../service/setting.service";
import { WorkService } from "../../service/work.service";
import { HierarchyService } from "../../service/hierarchy.service";
import { DataControlService } from '../../service/data-control.service'
import { LedgerService } from '../../service/ledger.service';
import { async } from "@angular/core/testing";
declare var $: any;

@Component({
  selector: "app-work",
  templateUrl: "./work.component.html",
  styleUrls: ["./work.component.css"],
})
export class WorkComponent implements OnInit {
  sortCol: any;
  constructor(
    private hierarchyService: HierarchyService,
    private settingService: SettingService,
    private workS: WorkService,
    public mainService: MainService,
    private spinner: NgxSpinnerService,
    private dataControlService: DataControlService,
    private ledgerService: LedgerService
  ) {
    this.obj = { prod_cd: "", act_cd: "", proj_cd: "", bud_cd: "" };
  }
  @ViewChild('paginator', { static: false }) paginator !: MatPaginator;
  @ViewChild('sort', { static: false }) sort!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;



  datasource:any;
  obj :any= { prod_cd: "", act_cd: "", proj_cd: "", bud_cd: "" };
  displayedColumns:any = [
    "work_id",
    "work_no",
    "work_desc",
    "start_dt",
    "end_dt",
    "amt",
    "action",
  ];
  allWorkdisplayedColumns:any = [
    "work_id",
    "work_no",
    "work_desc",
    "start_dt",
    "end_dt",
    "amt",
  ];
  erpUser:any;
  user_id:any;
  b_acct_id:any;
  allSal:any = [];

  allProjectHier :any= [];
  allProductHier :any= [];
  allActivityHier :any= [];
  allBudgetHier :any= [];

  allBudget :any= [];
  allProject :any= [];
  allProduct:any = [];
  allActivity:any = [];

  techToBusNameObj:any = {};
  create_flag :any= false;
  allWork_flage :any= false;
  list_flag :any= true;
  update_flag :any= false;
  max_amt_fg: boolean = false;
  datasourceWork:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;

    await this.getrepresentative();
    await this.getparty();
    await this.getActiveFinYear();
    await this.getAllWork()
    await this.getAllBudget();
    await this.getAllProject();
    await this.getAllProduct();
    await this.getAllActivity();
    await this.currentDate()
  }

  current_date:any;
  async currentDate() {
    let date = new Date();
    let d = date.getDate()
    let d1;
    if (d < 10) {
      d1 = '0' + d;
    } else {
      d1 = d;
    }

    let m = date.getMonth() + 1;
    let m1;
    if (m < 10) {
      m1 = '0' + m;
    } else {
      m1 = m;
    }

    let y = date.getFullYear();

    this.current_date = y + '-' + m1 + '-' + d1;
  }


  async createNew() {
    this.create_flag = true;
    this.list_flag = false;
    this.update_flag = false;
    this.allWork_flage = false;
    document.getElementById('hiereset')!.click();
  }
  async allWorkList() {
    this.refresh();
    this.create_flag = false;
    this.list_flag = false;
    this.update_flag = false;
    this.allWork_flage = true;
  }
  //****************************************************************************************** */
  reperesentative:any = {};
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(
      JSON.stringify({ b_acct_id: this.b_acct_id })
    );
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        this.reperesentative[resp["data"][i]["rep_le_id"]] =
          resp["data"][i]["party_leagal_name"];
      }
    }
  }
  all_party = [];
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp["error"] == false) {
      for (let i = 0; i < resp["data"].length; i++) {
        if (resp["data"][i]["party_type"] == "ORG") {
          resp["data"][i]["party_leagal_name"] =
            resp["data"][i]["party_leagal_name"] +
            " ( " +
            this.reperesentative[resp["data"][i]["rep_le_id"]] +
            ")";
        }
      }
      this.all_party = resp["data"];
      this.obj["party_type"];
    }
  }

  setPartyDetails() {
    for (let i = 0; i < this.all_party.length; i++) {
      if (this.obj["party_le_id"] == this.all_party[i]["le_id"]) {
        this.obj["party_phone_no"] = this.all_party[i]["party_phone_no"];
        this.obj["party_gstin_no"] = this.all_party[i]["party_gstin_no"];
        this.obj["party_email"] = this.all_party[i]["party_email"];
        break;
      }
    }
  }

  async addWorkInProjectHier(work_id:any) {
    let level = 0;
    let obb:any = new Object();

    this.allProjectHier.map((x:any) => {
      if (this.obj["proj_cd"] == x["leaf_cd"]) {
        for (let i = 1; i < 8; i++) {
          if (x["lvl" + i + "_cd"] == x["leaf_cd"]) {
            level = i;
          }
        }
        obb = Object.assign({}, x);
      }
    });

    obb["b_acct_id"] = this.b_acct_id;

    obb["lvl" + (level + 1) + "_user_cd"] = "ACCOUNTWORK" + work_id;
    obb["lvl" + (level + 1) + "_node_type"] = "ACCOUNTWORK";
    obb["lvl" + (level + 1) + "_value"] = this.obj["work_desc"];
    obb["lvl" + (level + 1) + "_type"] = "text";

    obb["leaf_user_cd"] = "ACCOUNTWORK" + work_id;
    obb["leaf_node_type"] = "ACCOUNTWORK";
    obb["leaf_value"] = this.obj["work_desc"];
    obb["module_cd"] = "ACCOUNT";

    var resp = await this.hierarchyService.createHierarchy(obb);
    if (resp["error"] == false) {
      console.log("Project Hier Added ....!");
    }
  }

  assingedWork :any= [];
  allWork:any;

  async getAllWork() {
    this.spinner.show();
    let assigned_work_ids: any = [];
    assigned_work_ids = await this.dataControlService.getWork();
    console.log("Your Work IS : ", assigned_work_ids)
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    let resp = await this.workS.getWorkList(obj);
    if (resp["error"] == false) {
      this.allWork = resp.data;
      this.assingedWork = [];
      this.allWork.map((x:any) => {
        if (assigned_work_ids.includes(x['work_id'])) {
          this.assingedWork.push(x)
        }
      })

      this.datasource = new MatTableDataSource(this.assingedWork);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;


      this.datasourceWork = new MatTableDataSource(this.allWork)
      this.datasourceWork.paginator = this.paginator2;
      this.datasourceWork.sort = this.sortCol2;

      this.spinner.hide();
      this.refresh();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting  all sal list!", "error");
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue1:any) {
    this.datasourceWork.filter = filterValue1.trim().toLowerCase();
  }

  checkDate() {
    if (this.obj["work_start_dt"] > this.obj["work_end_dt"]) {
      // swal.fire("ERROR", "Please Enter Correct End Date", 'info');
      // this.obj["work_start_dt"] = '';
      // this.obj["work_end_dt"]='';
      return;
    }
    // if (this.obj["work_order_amt"] < 0) {
    //   this.spinner.hide();
    //   // swal.fire("ERROR", "Please Amount Should Be Greater Than Zero", 'info');
    //   // this.obj["work_order_amt"]=0;
    //   return;
    // }
  }

  async submit() {
 console.log(this.obj['work_end_dt'])
    if (!this.budDesc[this.obj['bud_cd']] || !this.projectObj[this.obj['proj_cd']] ||
      !this.productObj[this.obj['prod_cd']] || !this.activityObj[this.obj['act_cd']] || !this.obj['work_order_amt']) return;

  if(this.obj['work_start_dt'] >= this.obj['work_end_dt']){
    swal.fire('info','Start date and End date should not be equal or less then start date','info');
    return
  }



    if (this.obj['work_order_amt'] > this.budAmtObj[this.obj['bud_cd']]) {
      this.max_amt_fg = true;
      console.log(" maxAmt ", this.max_amt_fg);
      swal.fire('WARNING', `Work Order Amount is greater Than Proposed Amount ( ${this.budAmtObj[this.obj['bud_cd']]} ) Of Selected Budget Hierarchy`, 'warning')
      return;
    }

    this.spinner.show();
    this.obj["create_user_id"] = this.user_id;
    this.obj["b_acct_id"] = this.erpUser.b_acct_id;
    this.obj["runnig_bill_no"] = 0;
    var resp = await this.workS.createWork(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.addWorkInProjectHier(resp.data);
      this.getAllWork();
      $('.nav-tabs a[href="#tab-1"]').tab("show");
      document.getElementById('resetSub')!.click();
      document.getElementById('hiereset')!.click();
      this.max_amt_fg = false
      swal.fire("Success...", resp.data, "success");
    } else {
      this.spinner.hide();
      swal.fire("Error", resp.data, "error");
    }
  }
  async update() {
    if (this.obj["work_start_dt"] > this.obj["work_end_dt"]) {
      // this.spinner.hide();
      // swal.fire("ERROR", "Please Enter Correct End Date", 'error');
      return;
    }

    this.spinner.show();
    this.obj["update_user_id"] = this.user_id;
    this.obj["b_acct_id"] = this.b_acct_id;
    var resp = await this.workS.updateWork(this.obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.getAllWork();
      swal.fire("Success...", "Work Updated Successfully", "success");
      document.getElementById('reset2')!.click();
      $('.nav-tabs a[href="#tab-1"]').tab("show");
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error While Updating", "error");
    }
  }
  refresh() {
    this.obj = { prod_cd: "", act_cd: "", proj_cd: "", bud_cd: "" };
  }
  async delete(data:any) {

    swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.obj["work_id"] = data["work_id"];
        this.obj["b_acct_id"] = this.b_acct_id;
        var resp = await this.workS.deleteWork(this.obj);
        if (resp["error"] == false) {
          this.spinner.hide();
          this.getAllWork();
          swal.fire("Success...", "Work Deleted Successfully", "success");
        } else {
          this.spinner.hide();
          // swal.fire("Error", "...Error While Deleting", "error");
          swal.fire("Success...", resp.data, "error");
        }
      }
    })
  }
  async listfunc() {
    this.create_flag = false;
    this.list_flag = true;
    this.allWork_flage = false;
    this.update_flag = false;
    this.refresh();
  }
  open_update(element:any) {
    this.create_flag = false;
    this.list_flag = false;
    this.allWork_flage = false;
    this.update_flag = true;
    console.log(element)
    console.log(this.HierSelectObj)
    this.obj = Object.assign({}, element);
    this.setPartyDetails();
    $('.nav-tabs a[href="#tab-3"]').tab("show");
  }

  //******************************************************************************************************************* */
  budDesc:any = {};
  budAmtObj :any= {}
  hierobj :any= { budget: {}, activity: {}, product: {}, project: {} };

  async getAllBudget() {
    this.spinner.show();
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "bud_hier";
    var resp = await this.hierarchyService.getBudgetHierarchy(obj);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allBudgetHier = resp.data.filter((x:any) => { return x['fin_year'] == this.fin_year });

      for (let i = 0; i < this.allBudgetHier.length; i++) {
        this.budAmtObj[this.allBudgetHier[i]["leaf_cd"]] = this.allBudgetHier[i]["remaining_amount"]
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (this.allBudgetHier[i]["lvl" + j + "_cd"] != null && this.allBudgetHier[i]["lvl" + j + "_cd"] != "") {
            str += this.allBudgetHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allBudgetHier[i]["desc"] = str;
        this.budDesc[this.allBudgetHier[i]["leaf_cd"]] = str;
        this.hierobj["budget"][this.allBudgetHier[i]["leaf_cd"]] =
          this.allBudgetHier[i];
      }
      console.log("budget description : ", this.budDesc)
      console.log("Budget Amount : ", this.budAmtObj);

    } else {
      this.spinner.hide();
    }
  }

  projectObj:any = {};
  async getAllProject() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "proj_hier";
    var resp = await this.hierarchyService.getProjectHierarchy(obj);
    if (resp["error"] == false) {
      this.allProjectHier = resp.data;
      let proj:any = [];
      await this.allProjectHier.map((x:any) => {
        if (
          x["leaf_node_type"] == "HREMP" ||
          x["leaf_node_type"] == "ACCOUNTWORK" ||
          x["leaf_node_type"] == "EMBWORK" ||
          x["leaf_node_type"] == "PROPERTY"
        ) {
        } else {
          proj.push(x);
        }
      });
      this.allProjectHier = [];
      this.allProjectHier = proj;

      for (let i = 0; i < this.allProjectHier.length; i++) {
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProjectHier[i]["lvl" + j + "_cd"] != null &&
            this.allProjectHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProjectHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProjectHier[i]["desc"] = str;
        this.projectObj[this.allProjectHier[i]["leaf_cd"]] = str;
        this.hierobj["project"][this.allProjectHier[i]["leaf_cd"]] =
          this.allProjectHier[i];
      }
    } else {
    }
  }

  productObj :any= {};
  async getAllProduct() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "prod_hier";
    var resp = await this.hierarchyService.getProductHierarchy(obj);
    if (resp["error"] == false) {
      this.allProductHier = resp.data;
      for (let i = 0; i < this.allProductHier.length; i++) {
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allProductHier[i]["lvl" + j + "_cd"] != null &&
            this.allProductHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allProductHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allProductHier[i]["desc"] = str;
        this.productObj[this.allProductHier[i]["leaf_cd"]] = str;
        this.hierobj["product"][this.allProductHier[i]["leaf_cd"]] =
          this.allProductHier[i];
      }
    } else {
    }
  }

  fin_year:any
  async getActiveFinYear() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getActiveFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp.data.length == 0) {
        // swal.fire("Warning", "..Active Financial Year Not set!", 'warning');
      } else {
        this.fin_year = resp.data[0].fin_year;
      }
    } else {
      // swal.fire("Error", "..Error while getting active  fin year!", 'error');
    }
  }


  activityObj:any = {};
  async getAllActivity() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["table_name"] = "activity_hier";
    var resp = await this.hierarchyService.getActivityHierarchy(obj);
    if (resp["error"] == false) {
      this.allActivityHier = resp.data;
      for (let i = 0; i < this.allActivityHier.length; i++) {
        let str = "";
        for (let j = 1; j < 8; j++) {
          if (
            this.allActivityHier[i]["lvl" + j + "_cd"] != null &&
            this.allActivityHier[i]["lvl" + j + "_cd"] != ""
          ) {
            str += this.allActivityHier[i]["lvl" + j + "_value"] + " > ";
          }
        }

        str = str.substring(0, str.length - 2);
        this.allActivityHier[i]["desc"] = str;
        this.activityObj[this.allActivityHier[i]["leaf_cd"]] = str;
        this.hierobj["activity"][this.allActivityHier[i]["leaf_cd"]] =
          this.allActivityHier[i];
      }
    } else {
    }
  }

  level1:any = [];
  level2:any = [];
  level3 :any= [];
  level4 :any= [];
  level5:any = [];
  level6:any = [];
  level7:any = [];
  Hier:any = [];
  hier_type:any;
  HierSelectObj:any = {};
  selectObj :any= {};
  HierSelectobj:any;

  async Select(type:any) {
    $("#select").modal("show");
    this.hier_type = type;
    let ob = {};

    if (type == "budget") {
      this.Hier = this.allBudgetHier;
      if (this.selectObj["bud_cd"] != null && this.selectObj["bud_cd"] != "") {
        ob = this.hierobj["budget"][this.selectObj["bud_cd"]];
      }
    } else if (type == "activity") {
      this.Hier = this.allActivityHier;
      if (this.selectObj["act_cd"] != null && this.selectObj["act_cd"] != "") {
        ob = this.hierobj["activity"][this.selectObj["act_cd"]];
      }
    } else if (type == "project") {
      this.Hier = this.allProjectHier;
      if (
        this.selectObj["proj_cd"] != null &&
        this.selectObj["proj_cd"] != ""
      ) {
        ob = this.hierobj["project"][this.selectObj["proj_cd"]];
      }
    } else if (type == "product") {
      this.Hier = this.allProductHier;
      if (
        this.selectObj["prod_cd"] != null &&
        this.selectObj["prod_cd"] != ""
      ) {
        ob = this.hierobj["product"][this.selectObj["prod_cd"]];
      }
    }
    this.HierSelectObj = {};
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob);
      await this.onChangeLvl1();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl2();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl3();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl4();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl5();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl6();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl7();
    }
  }

  async selectCreate(type:any) {
    console.log(this.HierSelectObj)
    document.getElementById('hiereset')!.click();
    $("#selectCreate").modal("show");
    this.hier_type = type;
    let ob = {};
    if (type == "budget") {
      this.Hier = this.allBudgetHier;
      if (this.obj["bud_cd"] != null && this.obj["bud_cd"] != "") {
        ob = this.hierobj["budget"][this.obj["bud_cd"]];
      }
    } else if (type == "activity") {
      this.Hier = this.allActivityHier;
      if (this.obj["act_cd"] != null && this.obj["act_cd"] != "") {
        ob = this.hierobj["activity"][this.obj["act_cd"]];
      }
    } else if (type == "project") {
      this.Hier = this.allProjectHier;
      if (this.obj["proj_cd"] != null && this.obj["proj_cd"] != "") {
        ob = this.hierobj["project"][this.obj["proj_cd"]];
      }
    } else if (type == "product") {
      this.Hier = this.allProductHier;
      if (this.obj["prod_cd"] != null && this.obj["prod_cd"] != "") {
        ob = this.hierobj["product"][this.obj["prod_cd"]];
      }
    }
    this.HierSelectObj = {};
    await this.getLevel1();
    if (Object.keys(ob).length != 0) {
      this.HierSelectObj = Object.assign({}, ob);
      await this.onChangeLvl1();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl2();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl3();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl4();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl5();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl6();
      this.HierSelectObj = Object.assign({}, ob);

      await this.onChangeLvl7();
    }
  }
  async getLevel1() {
    this.level1 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]["lvl1_cd"] == this.Hier[i]["leaf_cd"]) {
        let ob:any = new Object();
        ob["lvl1_cd"] = this.Hier[i]["lvl1_cd"];
        ob["lvl1_value"] = this.Hier[i]["lvl1_value"];
        ob["is_leaf"] = this.Hier[i]["is_leaf"];

        this.level1.push(ob);
      }
    }

    this.level2 = [];
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];
    console.log(this.level1);
  }

  async onChangeLvl1() {
    if (
      this.HierSelectObj["lvl1_cd"] == "" ||
      this.HierSelectObj["lvl1_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level1.length; i++) {
      if (this.level1[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"]) {
        this.HierSelectObj["lvl1_value"] = this.level1[i]["lvl1_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level2 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (this.Hier[i]["lvl1_cd"] == this.HierSelectObj["lvl1_cd"] && this.Hier[i]["lvl2_cd"] != null) {
        if (this.Hier[i]["lvl2_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl2_cd"] = this.Hier[i]["lvl2_cd"];
          ob["lvl2_value"] = this.Hier[i]["lvl2_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level2.push(ob);
        }
      }
    }
    this.level3 = [];
    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 2; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }
  async onChangeLvl2() {
    if (
      this.HierSelectObj["lvl2_cd"] == "" ||
      this.HierSelectObj["lvl2_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    console.log(this.HierSelectObj)
    console.log(this.level2)
    for (let i = 0; i < this.level2.length; i++) {
      if (this.level2[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"]) {
        this.HierSelectObj["lvl2_value"] = this.level2[i]["lvl2_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level3 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl2_cd"] == this.HierSelectObj["lvl2_cd"] &&
        this.Hier[i]["lvl3_cd"] != null
      ) {
        if (this.Hier[i]["lvl3_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl3_cd"] = this.Hier[i]["lvl3_cd"];
          ob["lvl3_value"] = this.Hier[i]["lvl3_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level3.push(ob);
        }
      }
    }

    this.level4 = [];
    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 3; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl3() {
    if (
      this.HierSelectObj["lvl3_cd"] == "" ||
      this.HierSelectObj["lvl3_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level3.length; i++) {
      if (this.level3[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"]) {
        this.HierSelectObj["lvl3_value"] = this.level3[i]["lvl3_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level4 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl3_cd"] == this.HierSelectObj["lvl3_cd"] &&
        this.Hier[i]["lvl4_cd"] != null
      ) {
        if (this.Hier[i]["lvl4_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob:any = new Object();
          ob["lvl4_cd"] = this.Hier[i]["lvl4_cd"];
          ob["lvl4_value"] = this.Hier[i]["lvl4_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level4.push(ob);
        }
      }
    }

    this.level5 = [];
    this.level6 = [];
    this.level7 = [];

    for (let i = 4; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl4() {
    if (
      this.HierSelectObj["lvl4_cd"] == "" ||
      this.HierSelectObj["lvl4_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level4.length; i++) {
      if (this.level4[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"]) {
        this.HierSelectObj["lvl4_value"] = this.level4[i]["lvl4_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level5 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl4_cd"] == this.HierSelectObj["lvl4_cd"] &&
        this.Hier[i]["lvl5_cd"] != null
      ) {
        if (this.Hier[i]["lvl5_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob:any = new Object();
          ob["lvl5_cd"] = this.Hier[i]["lvl5_cd"];
          ob["lvl5_value"] = this.Hier[i]["lvl5_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level5.push(ob);
        }
      }
    }
    this.level6 = [];
    this.level7 = [];

    for (let i = 5; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl5() {
    if (
      this.HierSelectObj["lvl5_cd"] == "" ||
      this.HierSelectObj["lvl5_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level5.length; i++) {
      if (this.level5[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"]) {
        this.HierSelectObj["lvl5_value"] = this.level5[i]["lvl5_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level6 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl5_cd"] == this.HierSelectObj["lvl5_cd"] &&
        this.Hier[i]["lvl6_cd"] != null
      ) {
        if (this.Hier[i]["lvl6_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob:any = new Object();
          ob["lvl6_cd"] = this.Hier[i]["lvl6_cd"];
          ob["lvl6_value"] = this.Hier[i]["lvl6_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level6.push(ob);
        }
      }
    }
    this.level7 = [];

    for (let i = 6; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl6() {
    if (
      this.HierSelectObj["lvl7_cd"] == "" ||
      this.HierSelectObj["lvl7_cd"] == null
    ) {
      this.HierSelectObj["leaf_cd"] = "";
      this.HierSelectObj["leaf_value"] = "";
    }
    for (let i = 0; i < this.level6.length; i++) {
      if (this.level6[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"]) {
        this.HierSelectObj["lvl6_value"] = this.level6[i]["lvl6_value"];
        await this.makingLeafValues();
      }
    }
    let temp = [];
    this.level7 = [];
    for (let i = 0; i < this.Hier.length; i++) {
      if (
        this.Hier[i]["lvl6_cd"] == this.HierSelectObj["lvl6_cd"] &&
        this.Hier[i]["lvl7_cd"] != null
      ) {
        if (this.Hier[i]["lvl7_cd"] == this.Hier[i]["leaf_cd"]) {
          let ob :any= new Object();
          ob["lvl7_cd"] = this.Hier[i]["lvl7_cd"];
          ob["lvl7_value"] = this.Hier[i]["lvl7_value"];
          ob["is_leaf"] = this.Hier[i]["is_leaf"];

          this.level7.push(ob);
        }
      }
    }

    for (let i = 7; i < 8; i++) {
      this.HierSelectObj["lvl" + i + "_cd"] = null;
      this.HierSelectObj["lvl" + i + "_value"] = null;
    }
  }

  async onChangeLvl7() {
    for (let i = 0; i < this.level7.length; i++) {
      if (this.level7[i]["lvl7_cd"] == this.HierSelectObj["lvl7_cd"]) {
        this.HierSelectObj["lvl7_value"] = this.level7[i]["lvl7_value"];
      }
    }

    await this.makingLeafValues();
  }

  async makingLeafValues() {
    this.leaf_msg = false;
    if (
      this.HierSelectObj["lvl7_cd"] != undefined &&
      this.HierSelectObj["lvl7_cd"] != "" &&
      this.HierSelectObj["lvl7_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl7_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl7_value"];
    } else if (
      this.HierSelectObj["lvl6_cd"] != undefined &&
      this.HierSelectObj["lvl6_cd"] != "" &&
      this.HierSelectObj["lvl6_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl6_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl6_value"];
    } else if (
      this.HierSelectObj["lvl5_cd"] != undefined &&
      this.HierSelectObj["lvl5_cd"] != "" &&
      this.HierSelectObj["lvl5_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl5_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl5_value"];
    } else if (
      this.HierSelectObj["lvl4_cd"] != undefined &&
      this.HierSelectObj["lvl4_cd"] != "" &&
      this.HierSelectObj["lvl4_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl4_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl4_value"];
    } else if (
      this.HierSelectObj["lvl3_cd"] != undefined &&
      this.HierSelectObj["lvl3_cd"] != "" &&
      this.HierSelectObj["lvl3_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl3_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl3_value"];
    } else if (
      this.HierSelectObj["lvl2_cd"] != undefined &&
      this.HierSelectObj["lvl2_cd"] != "" &&
      this.HierSelectObj["lvl2_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl2_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl2_value"];
    } else if (
      this.HierSelectObj["lvl1_cd"] != undefined &&
      this.HierSelectObj["lvl1_cd"] != "" &&
      this.HierSelectObj["lvl1_cd"] != null
    ) {
      this.HierSelectObj["leaf_cd"] = this.HierSelectObj["lvl1_cd"];
      this.HierSelectObj["leaf_value"] = this.HierSelectObj["lvl1_value"];
    }
  }
  async SubmitSelectedListHier() {
    let levelnum = 0;
    for (let i = 1; i < 8; i++) {
      if (
        this.HierSelectObj["lvl" + i + "_cd"] != null &&
        this.HierSelectObj["lvl" + i + "_cd"] != ""
      ) {
        levelnum = i;
      }
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (
          this.allBudgetHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.selectObj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.selectObj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.selectObj["bud_obj"] = this.allBudgetHier[i];
        }
      }
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["act_desc"] = this.allActivityHier[i]["desc"];
          this.selectObj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.selectObj["act_lvl"] = this.allActivityHier[i]["level"];
          this.selectObj["act_obj"] = this.allActivityHier[i];
        }
      }
    } else if (this.hier_type == "project") {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (
          this.allProjectHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["proj_desc"] = this.allProjectHier[i]["desc"];
          this.selectObj["proj_cd"] = this.allProjectHier[i]["leaf_cd"];
          this.selectObj["proj_lvl"] = this.allProjectHier[i]["level"];
          this.selectObj["proj_obj"] = this.allProjectHier[i];
        }
      }
    } else if (this.hier_type == "product") {
      for (let i = 0; i < this.allProductHier.length; i++) {
        if (
          this.allProductHier[i]["leaf_cd"] ==
          this.HierSelectObj["lvl" + levelnum + "_cd"]
        ) {
          this.selectObj["prod_cd"] = this.allProductHier[i]["leaf_cd"];
          this.selectObj["prod_desc"] = this.allProductHier[i]["desc"];
          this.selectObj["prod_lvl"] = this.allProductHier[i]["level"];
          this.selectObj["prod_obj"] = this.allProductHier[i];
        }
      }
    }

    $("#select").modal("hide");
  }
  leaf_msg = false;
  SubmitListHierforCreate() {
    if (
      this.HierSelectObj["leaf_cd"] == "" ||
      this.HierSelectObj["leaf_cd"] == null
    ) {
      this.leaf_msg = true;      // swal.fire("Error", "Please select till leaf.");
      return;
    }
    if (this.hier_type == "budget") {
      for (let i = 0; i < this.allBudgetHier.length; i++) {
        if (this.allBudgetHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]) {
          this.obj["bud_desc"] = this.allBudgetHier[i]["desc"];
          this.obj["bud_cd"] = this.allBudgetHier[i]["leaf_cd"];
          this.obj["bud_lvl"] = this.allBudgetHier[i]["level"];
          this.obj["bud_obj"] = this.allBudgetHier[i];
        }
      }
    } else if (this.hier_type == "activity") {
      for (let i = 0; i < this.allActivityHier.length; i++) {
        if (
          this.allActivityHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["act_desc"] = this.allActivityHier[i]["desc"];
          this.obj["act_cd"] = this.allActivityHier[i]["leaf_cd"];
          this.obj["act_lvl"] = this.allActivityHier[i]["level"];
          this.obj["act_obj"] = this.allActivityHier[i];
        }
      }
    } else if (this.hier_type == "project") {
      for (let i = 0; i < this.allProjectHier.length; i++) {
        if (
          this.allProjectHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["proj_desc"] = this.allProjectHier[i]["desc"];
          this.obj["proj_cd"] = this.allProjectHier[i]["leaf_cd"];
          this.obj["proj_lvl"] = this.allProjectHier[i]["level"];
          this.obj["proj_obj"] = this.allProjectHier[i];
        }
      }
    } else if (this.hier_type == "product") {
      for (let i = 0; i < this.allProductHier.length; i++) {
        if (
          this.allProductHier[i]["leaf_cd"] == this.HierSelectObj["leaf_cd"]
        ) {
          this.obj["prod_cd"] = this.allProductHier[i]["leaf_cd"];
          this.obj["prod_desc"] = this.allProductHier[i]["desc"];
          this.obj["prod_lvl"] = this.allProductHier[i]["level"];
          this.obj["prod_obj"] = this.allProductHier[i];
        }
      }
    }

    $("#selectCreate").modal("hide");
  }
}
