<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Support Department</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                                Support Department List</a>
                        </li>

                        <li class="nav-item" *ngIf="create">
                            <a class="nav-link active" href="#tab-2" data-toggle="tab"><i class="ti-announcement"></i>
                                Create Department
                            </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" *ngIf="list" id="tab-1">
                            <div class=" table-container ">
                                <br>
                                <div class="row">
                                    <div class="col-12 text-right">
                                        <button class="btn btn-outline-primary" (click)="openCreate()">Create
                                            Department</button>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)"
                                                placeholder="Filter">
                                        </mat-form-field>
                                    </div>
                                </div>


                                <div class="example-container">
                                    <table mat-table [dataSource]="dataSource" matSort>
                                        <ng-container matColumnDef="dep_code">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>Department
                                                    Code</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.dep_code}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="dep_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Department Name </b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.dep_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="branch_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Branch Office</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.branch_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="branch_address">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Address of Branch</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.branch_address }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="officer_name">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Concerned Officer Name</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.officer_name }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="officer_desig">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                <b>Concerned Officer Designation</b>
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.officer_desig }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Action
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <button (click)="open_upadate(element)" class="btn btn-outline-info">Update</button> &nbsp;&nbsp;

                                                <button (click)="delete(element)"
                                                    class="btn btn-outline-danger">Delete</button>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedcolumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedcolumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator #paginator2 [pageSizeOptions]="[2,10, 20]" showFirstLastButtons>
                                    </mat-paginator>
                                </div>
                            </div>

                        </div>

                        <div class="tab-pane fade show active" *ngIf="create || update" id="tab-2">
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-success" (click)="goToList()">Back to
                                        list</button>
                                </div>
                            </div>

                            <br><br>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"> <h6> Select Department: <span class="text-danger">*</span>  </h6></div>
                                    <div class="col-3">
                                        <ng-select [items]="codeValueTechObj['ICMS005']" bindLabel="value" bindValue="code"
                                            [multiple]="false" placeholder="Select Department"
                                            [(ngModel)]="depdata['dep_name']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                        </ng-select>
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"><h6> Enter Department Code: <span class="text-danger">*</span> </h6></div>
                                    <div class="col-3">
                                        <input type="text" [(ngModel)]="depdata['dep_code']"  class="form-control" placeholder="Code for support department ">
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>


                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"> <h6> Select Branch Office: <span class="text-danger">*</span> </h6></div>
                                    <div class="col-3">
                                        <!-- <input type="text" [(ngModel)]="depdata['branch_name']"  class="form-control" placeholder="Branch office Name"> -->
                                        <ng-select [items]="codeValueTechObj['ICMS012']" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder="Select Branch Office"
                                        [(ngModel)]="depdata['branch_name']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>

                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"><h6>Enter Branch Office Address: <span class="text-danger">*</span> </h6></div>
                                    <div class="col-3">
                                        <input type="text" [(ngModel)]="depdata['branch_address']"  class="form-control" placeholder="Branch office address">
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>


                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"><h6> Enter Concerned Officer Name: <span class="text-danger">*</span> </h6> </div>
                                    <div class="col-3">
                                        <input type="text" [(ngModel)]="depdata['officer_name']" class="form-control" placeholder="Department's Concerned officer Name">
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-3"> </div>
                                    <div class="col-3"> <h6>Enter Concerned Officer Designation: <span class="text-danger">*</span></h6></div>
                                    <div class="col-3">
                                        <input type="text" [(ngModel)]="depdata['officer_desig']" class="form-control" placeholder="Concerned Officer's Designation">
                                    </div>
                                    <div class="col-3"></div>
                                </div>
                                <br>
                                <br>

                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-outline-danger" (click)="goToList()">Cancel</button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-outline-primary" *ngIf="create" (click)="submit()">Submit</button>

                                        <button *ngIf="update" class="btn btn-outline-primary" (click)="updateDepartment()">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
