import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-portal-footer]',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.css']
})
export class PortalFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
