<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report1']}}
                        <!-- Report Manage -->

                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf="list_flag || show_report_flag" class="nav-link {{ad_tab_all_list}}" href="#tab-1"
                                data-toggle="tab" (click)="listbtab()"><i class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'emb_report2']}}
                                <!-- All Report List -->
                            </a><!-- ad_tab_all_list variable for to show active link--->
                        </li>

                        <li class="nav-item">
                            <a *ngIf="create_flag" class="nav-link" href="#tab-2" data-toggle="tab"
                                (click)="refresh()"><i class="fa fa-plus-circle" aria-hidden="true"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'emb_report3']}}
                                <!-- Create Report -->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="update_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'emb_report4']}}
                                <!-- View Report -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="list_flag ||show_report_flag" class="nav-link {{ad_tab_file_report}}"
                                href="#tab-4" data-toggle="tab" (click)="file_report_tab()"><i class="ti-bar-chart"></i>
                                View File Report
                            </a><!-- ad_tab_file_report variable for to show active link--->
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list_flag">
                            <br>
                            <div class="row">
                                <div class="col-12 d-flex justify-content-end">
                                    <div style="margin-right:1%">
                                        <button mat-button mat-raised-button class="btn btn-info"
                                            (click)="file_report_tab()">
                                            View FIle Report
                                        </button>
                                    </div>
                                    <div>
                                        <button mat-button mat-raised-button class="btn btn-success"
                                            (click)="createtab()">
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report5']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <button class="btn btn-danger" (click)="deleteAll()">Delete All</button> -->
                                </div>
                            </div>

                            <div class="mat-elevation-z8">

                                <table mat-table [dataSource]="datasource" matSort #sort="matSort">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'emb_report6']}}
                                            <!-- ID -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="report_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'emb_report7']}}
                                            <!-- Report Name -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.report_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="report_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'emb_report8']}}
                                            <!-- Report Description -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.report_desc}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'emb_report9']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-success" (click)="viewreport(element)">
                                                {{mainService.allLableShowObj[mainService.language_cd+'emb_report10']}}
                                                <!-- View -->

                                            </button>&nbsp;
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" aria-label="Select page of users">
                                </mat-paginator>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="show_report_flag">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button mat-button mat-raised-button class="btn btn-info" (click)="listbtab()">
                                        Go-Back
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="padding-left:6%; text-align: center;">
                                    <h6>
                                        Report Date :
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <input type="text" disabled class="form-control" [(ngModel)]="obj['date']"
                                        name="date">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="padding-left:6%; text-align: center;">
                                    <h6>
                                        Select Type :
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <ng-select [items]="doc_type" bindLabel="fileType" bindValue="value"
                                        [multiple]="false" placeholder="Select Type" [(ngModel)]="obj['doc_type']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="padding-left:6%; text-align: center;">
                                    <h6>
                                        File Status :
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <ng-select [items]="status" bindLabel="statusCode" bindValue="value"
                                        [multiple]="false" placeholder="Select Type" [(ngModel)]="obj['status']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="padding-left:6%; text-align: center;">
                                    <h6>
                                        Select Role :
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <ng-select [multiple]="true" placeholder="select Role" [items]="roles"
                                        bindLabel="role_name" bindValue="role_cd" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="roles"
                                        [(ngModel)]="obj['role_cd']">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="padding-left:6%; text-align: center;">
                                    <h6>
                                        Select User :
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-5">
                                    <ng-select [multiple]="true" placeholder="Select User" [items]="users1"
                                        bindLabel="desc" bindValue="user_id" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="users"
                                        [(ngModel)]="obj['user_id']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <!-- <div class="col-3"></div> -->
                                <div class="col-4" *ngIf="i==0">
                                    <h6 style="margin-left: 6%; text-align: center;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span
                                            style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']">Level&nbsp;-&nbsp;</span>{{item['level']}}
                                    <!-- Level -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                        bindValue="node_cd" [multiple]="false"
                                        placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                        [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                        (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                            aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <!-- <div class="col-3"></div> -->
                                <div class="col-4 ">
                                    <h6 style="margin-left: 6%; text-align: center;">
                                        {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span
                                            style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-6">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-11 text-center ">
                                     
                                    <button class="btn btn-success"
                                        (click)=" submitFileReport()">{{mainService.allLableShowObj[mainService.language_cd+'emb_report16']}}</button>&nbsp;&nbsp;
                                    <button class="btn btn-danger" (click)="resetButton()">Reset</button>
                                     
                                       </div>
                                        <div class="col-1">
                                            <button class="btn btn-primary" (click)="printRp()">Print</button>
                                        </div>
                                
                            </div>
                            <br>
                            <hr>
                            <br>
                            <div class="row">
                                <div class="col-1 p-0  text-right" style="font-weight: bold;">
                                    Date&nbsp;&nbsp;&nbsp;:
                                </div>
                                <div class="col-2">
                                    {{date}}

                                </div>
                                <div class="col-3 text-right p-0" style="font-weight: bold;">
                                    Selected Hierarchy &nbsp;&nbsp;:
                                </div>
                                <div class="col-5 p-0 text-left">
                                    &nbsp;&nbsp;&nbsp;{{hierchy}}
                                </div>
                            </div>
                            <br>
                            
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter2($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report5']}}">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mat-elevation-z8">
                                <table mat-table [dataSource]="datasource2" matSort #sort2="matSort">

                                    <ng-container matColumnDef="s_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            S No.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element;let i = index "
                                            class="right">
                                            {{ (paginator2 .pageIndex * paginator2 .pageSize) + (i + 1) }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="file_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            File ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i=index; "
                                            class="right">
                                            {{ workObj[element.sys_work]['work_order_no']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Work_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>

                                            Work

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{workObj[element.sys_work]['work_order_name']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="document_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Document ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.doc_local_no}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="user">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            User

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ userObj[element.user_id]}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="role">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Roles

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ RoleObj[element.role_cd]}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">


                                            {{ element.file_eff_date}}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                    </tr>
                                </table>
                                <mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>
                        <br>


                        <div *ngIf="create_flag">
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                    Go-Back
                                </button>

                            </div>
                            <br>



                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:6%; text-align: center;">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report11']}}
                                        <!-- Select Activity Type : -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="activity_type" bindLabel="code" bindValue="code"
                                        [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report11']}} "
                                        [(ngModel)]="obj['activity_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:6%; text-align: center;">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report12']}}
                                        <!-- Select Activity Period  :  -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="activity_period" bindLabel="code" bindValue="code"
                                        [multiple]="false"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report13']}}"
                                        [(ngModel)]="obj['activity_period']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
                                </div>


                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:6%; text-align: center;">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report7']}}
                                        <!-- Report Name :  -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" [(ngModel)]="obj['report_name']" type="text"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report7']}}" />

                                    <!-- <input type="text" placeholder="Enter Field Code" class="form-control" [(ngModel)]="obj['code']"> -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4" style="margin-left:6%; text-align: center;">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report8']}}
                                        <!-- Report Description : -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <!-- <input class="form-control"  [(ngModel)]="obj['value']"   onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Value" /> -->
                                    <input type="text"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report8']}}"
                                        class="form-control" [(ngModel)]="obj['report_desc']">
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="obj['activity_period']=='Custom'">
                                <div class="col-3  text-right">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report13']}}
                                        <!-- Start Date : -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <!-- <input class="form-control"  [(ngModel)]="obj['value']"   onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Value" /> -->
                                    <input type="date"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report14']}}"
                                        class="form-control" [(ngModel)]="obj['start_date']">
                                </div>
                                <div class="col-3  text-right">
                                    <h6>
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report15']}}
                                        <!-- End Date : -->
                                        <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <!-- <input class="form-control"  [(ngModel)]="obj['value']"   onkeypress='return (event.charCode > 96 && event.charCode < 123) || 
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Field Value" /> -->
                                    <input type="date"
                                        placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report14']}}"
                                        class="form-control" [(ngModel)]="obj['end_date']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-success"
                                        (click)="submit()">{{mainService.allLableShowObj[mainService.language_cd+'emb_report16']}}</button>

                                </div>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="update_flag">
                            <div style="text-align: right;">
                                <button mat-button mat-raised-button class="btn btn-primary" (click)="listbtab()">
                                    Go-Back
                                </button>

                            </div>
                            <br>


                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter1($any($event.target).value)"
                                            placeholder="{{mainService.allLableShowObj[mainService.language_cd+'emb_report5']}}">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                </div>
                                <div class="col-4 text-right">
                                    <button class="btn btn-success" (click)="print()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report17']}}
                                        <!-- Print -->
                                    </button>&nbsp;
                                    <button class="btn btn-success" (click)="export()">
                                        {{mainService.allLableShowObj[mainService.language_cd+'emb_report18']}}
                                        <!-- Export -->
                                    </button>
                                </div>
                            </div>
                            <br>
                            <div class=" mat-elevation-z8">
                                <table mat-table [dataSource]="datasource1" matSort id="myHeader" #sortCol2="matSort">
                                    <ng-container matColumnDef="{{col}}"
                                        *ngFor="let col of displayedColumns_temp; let i = index">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{techToBusNameObj[col]}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            <p *ngIf="col=='user'">{{ this.userObj[element[techToBusNameObjshow[col]]]}}
                                            </p>
                                            <p *ngIf="col=='current'">{{
                                                this.useridtoroleobj[element[techToBusNameObjshow[col]]]}}</p>
                                            <p *ngIf="col!='current' && col!='user'"> {{
                                                element[techToBusNameObjshow[col]]}} </p>
                                        </td>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-warning" (click)="openUpdate(element)">UPDATE</button>

                                        </td>
                                    </ng-container> -->

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                            <br>
                        </div>
                        <!-- <div class="modal" id="ACTHIER">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">

                                   
                                    <div class="modal-header">
                                        <h4 class="modal-title">Select Hierarchy</h4>
                                        <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                                            <div class="col-3"></div>
                                            <div class="col-3" *ngIf="i==0">
                                                <h6 style="margin-left: 2%;">
                                                    {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                                    Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                            </div>
                                            <div class="col-3" *ngIf="i!=0"></div>
                                            <div class="col-1">

                                                <span
                                                    *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB
                                                    Hierarchy17']}} </span>{{item['level']}}
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc"
                                                    bindValue="node_cd" [multiple]="false"
                                                    placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                                    [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                                    (change)="setHierarchyNodeitem(item,i)"
                                                    [selectableGroupAsModel]="true" [closeOnSelect]="true">
                                                </ng-select>
                                            </div>
                                            <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                                                <button (click)="down(item,i)" class="btn btn-primary"><i
                                                        class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                                &nbsp;&nbsp;
                                                <button (click)="remove(item,i)" class="btn btn-danger">
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;">
                                                    {{mainService.allLableShowObj[mainService.language_cd+'EMB
                                                    Hierarchy19']}} <span style="color: red;">*</span>
                                                </h6>
                                            </div>
                                            <div class="col-6">
                                                {{Obj['path_desc']}}
                                            </div>

                                        </div>
                                        <br>


                                        <div class="modal-footer">
                                            <button class="btn btn-primary" (click)="submit_act()">Submit</button>
                                            <button type="button" class="btn btn-danger"data-bs-dismiss="modal"
                                                (click)="close()">CLOSE</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> -->


                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom">{{mainService.allLableShowObj[mainService.language_cd+'emb_report19']}} </ngx-spinner>