<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Role-print</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a  *ngIf=" list_flage" class="nav-link active" href="#tab-7-1" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-line-chart"></i>&nbsp; List</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf=" create_flage"  class="nav-link" href="#tab-7-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i> Create
                                
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf=" update_flage"  class="nav-link" href="#tab-7-3" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings" aria-hidden="true"></i> Update
                                
                            </a>
                        </li>
                       
                    </ul>
                    <div class="tab-content">
                        <div *ngIf=" list_flage" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="Create()"> Create</button>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="datasource" matSort style="width: 100%;">
                                   
                                    <ng-container matColumnDef="module_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Module Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.module_code}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="doc_type">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Document Type
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.doc_type}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="role">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Role
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element ; " class="right" >
                                            <!-- {{element.role}} -->
                                            <label *ngFor="let r of element.role">
                                                {{roleObj[r]}},
                                            </label>
                                          </td>
                                    </ng-container>
                                   


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                          
                                            <button (click)="open_update(element)"
                                                class="btn btn-success">Update</button>&nbsp;&nbsp;
                                        
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">

                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
                            </div>

                        </div>


                        <div *ngIf=" create_flage" >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Go to LIST</button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Module <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]=" module_name" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="Obj['module_code']" (change)="findmodul_roles()"   [closeOnSelect]="true" name="module_name" >
                                    </ng-select>
                                </div>
                               
                            </div><br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Document type : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="doc_type" bindLabel="doc_type" bindValue="doc_type" [multiple]="false" placeholder="Document type"  [(ngModel)]="Obj['doc_type']"  [closeOnSelect]="true" name="chart_of_account0" >
                                    </ng-select>
                                </div>
                               
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select role: <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="Roles" bindLabel="role_name" bindValue="role_cd" [multiple]="true" placeholder="Select role" [(ngModel)]="Obj['role']"  [selectableGroup]="true" [selectableGroupAsModel]="true"  [closeOnSelect]="false" name="role"  >
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox  [(ngModel)]="checkBoxValue"
                                    [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">Select All Role
                                </mat-checkbox>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Description : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['desc']" placeholder="Description" class="form-control" name="desc" id="" >
                                </div>
                                <div class="col-3">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                       Instructions for Desc
                                    </button>
                                </div>
                            </div>
                            
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select field : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="Bill_amount" bindLabel="billamount" bindValue="billamount" [multiple]="false" placeholder="bill amount" [(ngModel)]="Obj['field']"  [closeOnSelect]="true" name="field" >
                                    </ng-select>
                                </div>
                                
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid from : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_from']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Valid Upto : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="Obj['valid_upto']" class="form-control" name="" id="">
                                </div>
                                <div class="col-3"></div>
                            </div> -->
                           
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="submit()">Submit</button>
                                </div>

                            </div>
                        </div>

                        <div *ngIf=" update_flage"  >
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Go to LIST</button>
                            </div>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select Module <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]=" module_name" bindLabel="module_name" bindValue="module_cd" [multiple]="false" placeholder="Select Module" [(ngModel)]="Obj['module_code']"   [closeOnSelect]="true" name="module_name" disabled >
                                    </ng-select>
                                </div>
                               
                            </div><br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Document type : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="doc_type" bindLabel="doc_type" bindValue="doc_type" [multiple]="false" placeholder="Document type"  [(ngModel)]="Obj['doc_type']"  [closeOnSelect]="true" name="chart_of_account0">
                                    </ng-select>
                                </div>
                               
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select role: <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="Roles" bindLabel="role_name" bindValue="role_cd" [multiple]="true" placeholder="Select role" [(ngModel)]="Obj['role']"  [selectableGroup]="true" [selectableGroupAsModel]="true"  [closeOnSelect]="false" name="role" >
                                    </ng-select>
                                </div>
                                <div class="col-3">
                                    <mat-checkbox  [(ngModel)]="checkBoxValue"
                                    [ngModelOptions]="{standalone: true}" (click)="checkCheckBoxvalue()">Select All Role
                                </mat-checkbox>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6> Description : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="Obj['desc']" placeholder="Description" class="form-control" name="desc" id="">
                                </div>
                               
                                <div class="col-3">
                                    <button type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                       Instructions for Desc
                                    </button>
                                </div>
                            </div>
                            
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6>Select field : <span style="color: red;"> *</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="Bill_amount" bindLabel="billamount" bindValue="billamount" [multiple]="false" placeholder="bill amount" [(ngModel)]="Obj['field']"  [closeOnSelect]="true" name="field">
                                    </ng-select>
                                </div>
                                
                            </div>
                            
                            
                            <br><br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>

                            </div>
                        </div>
                     
                     
                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row">
                    <div class="col-12">
                        <h3 style="background-color: rgb(223, 223, 223); font:bold;">Instructions </h3>
                        <br>
                        <p>
                        Enter  short Description about  selected Role 
                        </p>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"data-bs-dismiss="modal"> Close </button>
            </div>
        </div>
    </div>
</div>