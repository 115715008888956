<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Pension Contribution Manage</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                All Pension Contribution List</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i> Create Pension Contribution</a>
                        </li>


                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">
                            <br>





                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.id }}</td>
                                    </ng-container>


                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Employee id

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{mainService.accInfo['account_short_name']}}{{getNumberFormat(element.emp_id)}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="value">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Employee Name

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.emp_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Designation

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.designation }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Status

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ statusarr[element.status] }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <!--  <button class="btn btn-primary" (click)="open_update(element)">Update</button> -->
                                            <button class="btn btn-danger" (click)="delete(element)">Delete</button>

                                            <!-- <button class="btn btn-outline-danger" (click)="delete(element)">Delete</button> -->

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>

                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Employee:
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="allArr" bindLabel="emp_name" bindValue="emp_id" [multiple]="true" placeholder="Select Employee" [(ngModel)]="obj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false" name="field_name">
                                    </ng-select>
                                </div>
                            </div>



                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="save()">Submit</button>

                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
