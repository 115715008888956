import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AdvanceService } from '../../service/advance.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer } from '@angular/platform-browser';
import { PropHierarchyService } from '../../service/prop-hierarchy.service';
import Swal from 'sweetalert2';
import { MainService } from '../../service/main.service';
import { LandInputService } from '../../service/land-input.service';
import { last, max } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileUploader } from 'ng2-file-upload';
declare var $: any;
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import { DocumentService } from '../../service/document.service';
// import { MatStepper } from '@angular/material';
import { PropCostingService } from '../../service/prop-costing.service';
//
import { DefaulterService } from "../../service/defaulter.service";
import { DataSource } from '@angular/cdk/collections';
import { MainService as mainService_MD } from '../../../md/service/main.service'


@Component({
  selector: 'app-possession',
  templateUrl: './possession.component.html',
  styleUrls: ['./possession.component.css']
})
export class PossessionComponent implements OnInit {
  allOffer: any;
  allOffer2: any;
  obj:any={}
  ebillUser: any;
  b_acct_id: any;
  user_id: any;
  offer_code_to_value: any;
  dataSource: any;
  dataSource2: any;

  details_flag2:boolean = false
  upload_flag:boolean = false
  //
  pro_type: any;
  pro_category: any;
  //
  show_flag=true;
  show_flag2 = true;
  displayedColumns: string[] = ['property_id', 'Scheme', 'property_type', 'property_no','allottee_name','Father_Name',  'mobile_no', 'action'];
  Authoritieobj: any={}

  //
  allSearchableEmp = []



  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  Details_obj: any;
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }


  constructor( private mainServiceMD: mainService_MD, private spinner : NgxSpinnerService, private sale_service: SaleService, private adv_ser : AdvanceService) { }

  ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id = this.ebillUser.le_id;
    console.log(this.b_acct_id);
    this.getofferdata();
    this.getAllEmployees()

  }

  async getofferdata() {
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id

    var resp = await this.sale_service.getAllOffers(this.obj);


      if (resp['error'] == false) {
        this.allOffer = resp['data']
        console.log(resp['data'], 'fetched data')
        let dummy:any = []

        console.log(this.allOffer, " before loop ")

        // for (let i = 0; i < this.allOffer.length; i++) {
        //   this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
        //   this.allOffer[i]['offer_name'] = this.allOffer[i]['offer_cd'] + '-' + this.allOffer[i]['offer_name']
        //   dummy.push(this.allOffer[i])
        //   console.log(this.allOffer[i]['offer_cd'])
        // }
        console.log(this.allOffer, " after loop ")
       console.log(dummy, "dummy test")
      }
   this.spinner.hide()
 }

 async getallPossessionList(){

  console.log("function called")

  this.spinner.show()
  this.obj['b_acct_id'] = this.b_acct_id
  this.obj['offer_cd'] = this.obj['offer_cd'];
  console.log(this.obj)
  var resp:any = await this.adv_ser.getAllPossessionRequest(JSON.stringify(this.obj));
      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.paginator = this.paginator.toArray()[0];
      console.log(resp['data'])
  this.spinner.hide()


 }

 flag(){

 }

 Details_flag(){
   this.show_flag = !this.show_flag
 }

 show_Details(element:any){
  this.show_flag = !this.show_flag
  this.Details_obj = element;
 }

 show_Details2(element:any){
  this.details_flag2 = true
  this.show_flag2 = false
  this.Details_obj = element;
 }

 //

 async getAllEmployees() {

  console.log('all emp')
  this.spinner.show()
  var arr:any = []
  var obj:any = new Object();
  obj['b_acct_id'] = this.b_acct_id;
  var resp:any = await this.adv_ser.getAllAuthorities(JSON.stringify(obj));


  if (resp['error'] == false) {
    console.log(resp)
    this.spinner.hide()

    arr = resp['data'];

    console.log(resp, 'users')
    for (let i = 0; i < arr.length; i++) {
      this.obj[arr[i]['user_id']] = arr[i]
      arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['party_name']
    }
    this.allSearchableEmp = arr

  } else {
    this.spinner.hide()
  }
}

//genrate possession
 async GeneratePossession(){
   console.log(this.obj)
   if(this.Authoritieobj['data']==null || this.obj['possession_date'] == null || this.obj['possession_letter_date']==null){
     Swal.fire('fill all details', 'some fields are empty', 'warning')
     return
   }


  this.obj['possession_req_id'] = this.Details_obj['possession_req_id']
  this.obj['party_id'] =this.Details_obj['party_id']
  this.obj['arr_id'] = this.Details_obj['arr_id']
  this.obj['status'] = 'approved'
  this.obj['life_cycle_status'] = 'ACTIVE'
  this.obj['data'] = []
  console.log(this.obj)


  this.Authoritieobj['data'].forEach((element:any) => {
    this.obj['data'].push({authority_id:element})
  })
  // this.obj['data'] = this.Authoritieobj['data']

  var resp:any = await this.adv_ser.GeneratePossession(this.obj);
  if(resp['error'] == true){
    Swal.fire('error', resp['data'], 'info')
  }else{
    Swal.fire('success', resp['data'], 'success')

  }
}

async getpropertytype(){


  // let obj :any= {}
   this.obj['b_acct_id'] = this.b_acct_id
   this.obj['offer_cd'] =this.obj['offer_cd']

   console.log(this.obj)
   var resp:any = await this.adv_ser.getpropertytype(JSON.stringify(this.obj));
   //console.log(resp)
   if (resp['error'] == false) {
     this.pro_type = resp['data']
     console.log(this.pro_type, 'fetched property typedata')
   }
   this.getpropertyCategory()
}
async getpropertyCategory(){
  this.obj['b_acct_id'] = this.b_acct_id
  this.obj['offer_cd'] =this.obj['offer_cd']
  console.log(this.obj)
  var resp:any = await this.adv_ser.getpropertyCategory(JSON.stringify(this.obj));
  if (resp['error'] == false) {
    this.pro_category = resp['data']
    console.log(this.pro_type, 'fetched property typedata')
  }

}

//

 async getPossessionData(){

  this.obj['b_acct_id'] = this.b_acct_id
  this.obj['offer_cd'] =this.obj['offer_cd']
  this.obj['user_id'] = this.user_id
  console.log(this.obj)
  var resp:any = await this.adv_ser.getApprovedPossessionData(JSON.stringify(this.obj));

  if (resp['error'] == false) {

    this.dataSource2 = new MatTableDataSource(resp['data']);
    this.dataSource2.sort = this.sort.toArray()[0];
    this.dataSource2.paginator = this.paginator.toArray()[0];
    console.log(resp['data'])
  }
}
close(){
  this.show_flag2 = true;
  this.details_flag2 = false;
  this.upload_flag  = false;
}


//
show_upload(element:any){






  console.log("xyz")
  this.upload_flag = true
  this.show_flag2 = false
  this.Details_obj = element;
}

}
