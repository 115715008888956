import { Component, OnInit, ViewChild } from '@angular/core';
import {   MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MainService } from '../service/main.service'
import { VendorService } from '../service/vendor.service'
declare var $: any;
import * as  pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  ebillUser: any;
  b_acct_id: any;
  venderInfo: any;
  vendocacct: any;
  venderAccountInfo: any;
  vender_emp_info: any;
  degree_doc: any = [];
  all_dir: any;
  vender_director_info_table: any;
  progress: any = [];
  ongoing: any = [];
  machine: any = [];
  completion: any = [];
  register_vender : boolean = true
  constructor(public mainService: MainService, private vendorService: VendorService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) { }
  uploader1 :any

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  electrical_view = false;



  async ngOnInit() {

    this.ebillUser = JSON.parse(atob(localStorage.getItem("erpUser") as any))
    console.log(this.ebillUser.b_acct_id)
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.uploader1 = new FileUploader({ url: this.mainService.httpUrl, itemAlias: 'image' });
    this.uploader1.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    await this.getVendor()
    // this.valid_Date()
    console.log(this.mainService.codeValueTechObj['VMS001'])
    console.log(this.mainService.codeValueTechObj['VMS002'])
  }
  isLinear = false;
  first:any;
  second:any;
  third:any;
  vendorObj:any = {}
  currDate = this.currentDate()
  dataSource!: MatTableDataSource<any>;
  displayedColumns = ['s.no', 'vendor_id', 'type', 'name', 'mobile_no', 'status', 'action']
  partyType = [
    { code: "IND", value: "Individual" },
    { code: "ORG", value: "Organization" },
    { code: "PRT", value: "Propritorship" },
  ];

  list: boolean = true;
  create: boolean = false;
  view_details: boolean = false;
  update_flag: boolean = false;
  isDisabled: boolean = false;
  async backToList() {
    this.isDisabled = false;
    this.list = true;
    this.create = false;
    this.view_details = false;
    this.update_flag = false;
    this.selectedVendorId = 0;
    await this.getVendor();
  }

  openCreate() {
    this.list = false;
    this.create = true;
    this.view_details = false;
    this.update_flag = false;
    this.vendorObj = {}
    this.isError = false;
  }

  openUpdate(element:any) {
    this.isError = false;
    this.list = false;
    this.create = false;
    this.view_details = false;
    this.update_flag = false;
    this.update_flag = true;
    this.vendorObj = element;
    console.log(this.vendorObj)
    this.isDisabled = true;
  }

  openInactive(element:any) {
    this.vendorObj = element;
    $('#inactive').modal('show');

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectedFile:any = {}

  async onFileUpload(event: any, files: any, identity:any) {

    if (!(event.target.files[0]['type'] == 'application/pdf')) {
      Swal.fire("Info", "Upload PDF Files Only", "info")
    }

    if (identity == 'pan') {
      this.selectedFile['pan_doc'] = event.target.files[0];
      this.selectedFile['pan_name'] = event.target.files[0]['name'];
    } else if (identity == 'gstin') {
      this.selectedFile['gstin_doc'] = event.target.files[0];
      this.selectedFile['gstin_name'] = event.target.files[0]['name'];
    }

  }


  async search(stepper:any) {
    this.spinner.show()
    if (!this.vendorObj['mobile_no']) {
      Swal.fire('INFO', 'Please Enter Mobile Number', 'info')
      this.spinner.hide()
      return;
    }
    let obj:any = {}
    obj['mobile_number'] = this.vendorObj['mobile_no'];
    let resp = await this.vendorService.searchVendor(JSON.stringify(obj))
    if (resp['error'] == false) {
      if (resp['data'].length > 0) {
        Swal.fire('INFO', 'You Are Already Registered', 'info');
        this.spinner.hide()
        return;
      }
      this.spinner.hide()
      stepper.next()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'ERROR While Getting Vendor', 'error');
    }
  }


  old_ifsc: any;
  ifs_flag: boolean = false;
  ifs_mes: any;
  async fetchBankDtl() {

    this.old_ifsc = this.vendorObj['ifsc'];
    let regx = "^[A-Z]{4}0[A-Z0-9]{6}$"

    if (!this.old_ifsc.match(regx)) {
      this.ifs_flag = false;
      Swal.fire('INFO', 'Please Enter Correct IFSC', 'info')
      this.ifs_mes = "NOT Verified"
      this.vendorObj['branch_name'] = null;
      this.vendorObj['bank_name'] = null;
      this.vendorObj['bank_address'] = null;
      this.ifs_mes = "Not Verified"
      this.ifs_flag = false;
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    let resp = await this.vendorService.getBankDtlByIfsc(JSON.stringify(this.old_ifsc));
    if (resp['error'] == false) {

      this.vendorObj['branch_name'] = resp['data']['BRANCH'];
      this.vendorObj['bank_name'] = resp['data']['BANK'];
      this.vendorObj['bank_address'] = resp['data']['ADDRESS'];
      this.vendorObj['ifsc'] = resp['data']['IFSC'];
      this.ifs_mes = "Verified"
      this.ifs_flag = true;
      this.spinner.hide();
    } else {
      this.vendorObj['branch_name'] = null;
      this.vendorObj['bank_name'] = null;
      this.vendorObj['bank_address'] = null;
      this.ifs_mes = "Not Verified"
      this.ifs_flag = false;
      this.spinner.hide();
      Swal.fire('INFO', 'Please Enter Correct IFSC', 'info')
    }
  }

  all_vender : any;
  async getVendor() {
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    let resp = await this.vendorService.getVendor(JSON.stringify(obj))
    console.log(resp)
    if (resp['error'] == false) {
      let allvender:any = [];
      this.all_vender = resp['data'];
      this.all_vender.map((x:any,i:any) =>{
           if(x.life_cycle_status == 'ACTIVE'){
            allvender.push(x)
           }
      })
      this.dataSource = new MatTableDataSource(allvender);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner.hide();
    } else {
      Swal.fire('ERROR', 'ERROR While Getting Vendor', 'error');
      this.spinner.hide();
      return
    }
  }

  registere(){
    this.spinner.show();
    let allvender :any= [];
    this.all_vender.map((x:any,i:any) =>{
      if(x.life_cycle_status == 'REGISTERED'){
        allvender.push(x)
      }
    })
    if(allvender.length <= 0){
      Swal.fire('info','No data Found of registered vender','info');
      allvender = [];
    }
    this.dataSource = new MatTableDataSource(allvender);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.spinner.hide();
  }



  isError: boolean = false;


  invalid_msg: any
  invalid_phone = false
  async onKeyUpPhone(number:any) {
    if (!number) {
      this.invalid_phone = false
    }
    else if (!this.validatePhone(number)) {
      Swal.fire("Info", "Invalid Phone Number", "info")
      this.invalid_phone = true
      this.invalid_msg = "Invalid"
    } else {
      this.invalid_phone = false
    }
  }

  validatePhone(contact_phone:any) {
    var reg = /^[\d]{10}$/;
    if (reg.test(contact_phone) == false) {
      return (false);
    } else {
      this.invalid_phone = false
      return true
    }
  }

  invalid_email = false
  email_msg: any
  async checkEmail(email:any) {

    if (!email) {
      Swal.fire("Info", "Enter Email", "info");
      this.invalid_email = true
      this.email_msg = "Enter Email!"
    }
    else if (!this.validate(email)) {
      Swal.fire("Info", "Invalid Email", "info");
      this.invalid_email = true
      this.email_msg = "Invalid"
    }
    else {
      this.invalid_email = false
      this.email_msg = "Verified"
    }

  }

  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      this.invalid_email = false
      this.email_msg = "Verified"
      return true
    }
  }








  async validation() {
    this.isError = false;
    this.spinner.show();
    if (this.vendorObj['account_no'] != this.vendorObj['conform_account_no']) {
      Swal.fire('Info', 'Account Number does not match  ', 'info')
      this.isError = true;
      this.spinner.hide()
      return;
    } else if (this.old_ifsc != this.vendorObj['ifsc']) {
      Swal.fire('Info', 'Please Enter Correct ifsc', 'info')
      this.ifs_mes = "Not Verified"
      this.spinner.hide()

      this.isError = true;
      return;
    } else if (!this.vendorObj['bank_address'] || !this.vendorObj['branch_name'] ||
      !this.vendorObj['bank_name'] || !this.vendorObj['ifsc'] || !this.vendorObj['account_holder_name'] || !this.vendorObj['account_no']) {
      Swal.fire("Error", "Please Fill All Mandatory Fields", "error");
      this.isError = true;
      this.spinner.hide()

      return;
    }

  }


  async next(stepper:any) {
    this.isError = false;
    this.spinner.show()
    if (!this.vendorObj['vendor_name'] || !this.vendorObj['type'] || !this.vendorObj['email'] || !this.vendorObj['address']
      || !this.vendorObj['district'] || !this.vendorObj['state'] || !this.vendorObj['pan'] || !this.vendorObj['gstin']) {
      Swal.fire("Error", "Please Fill All Mandatory Fields", "error");
      this.isError = true;
      this.spinner.hide()
      return;
    } else if (!(this.vendorObj["pan"])) {
      Swal.fire("Error", "PAN can not be blank...", "error");
      this.isError = true;
      this.spinner.hide()

      return;
    }
    else if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(this.vendorObj["pan"])) {
      Swal.fire("Error", "Invalid PAN", "error");
      this.isError = true;
      this.spinner.hide()

      return;
    } else if (!(this.vendorObj["gstin"])) {
      Swal.fire("Error", "GISTIN can not be blank...", "error");
      this.isError = true;
      this.spinner.hide()

      return;
    }
    else if (!/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(this.vendorObj["gstin"])) {
      Swal.fire("Error", "Invalid GISTIN", "error");
      this.isError = true;
      this.spinner.hide()

      return;
    } else if (this.vendorObj["gstin"].substring(2, 12) != (this.vendorObj["pan"])) {
      Swal.fire("Error", "Invalid PAN", "error");
      this.isError = true;
      this.spinner.hide()
      return;
    }
    // else if (this.vendorObj['type'] != 'IND') {
    //   if (!this.vendorObj['rep_dob'] || !this.vendorObj['rep_email'] || !this.vendorObj['rep_designation'] || !this.vendorObj['rep_name']) {
    //     Swal.fire("Error", "Please Fill All Mandatory Fields", "error");
    //     this.isError = true;
    //     this.spinner.hide()
    //     return;
    //   }
    // }
    this.vendorObj['account_holder_name'] = this.vendorObj['vendor_name']
    if (!this.isError) {
      this.spinner.hide()
      stepper.next();
    }
  }

  async sumbitVendor() {
    this.spinner.show()
    await this.validation();
    if (!this.isError) {
      let formData = new FormData();
      let doc_name = []
      doc_name.push({ name: this.selectedFile['pan_name'], type: 'PAN_DOC' });
      doc_name.push({ name: this.selectedFile['gstin_name'], type: 'GST_DOC' });
      formData.append('image', this.selectedFile['pan_doc']);
      formData.append('image', this.selectedFile['gstin_doc']);
      this.vendorObj['doc_name'] = doc_name;
      formData.append('data', JSON.stringify(this.vendorObj));
      let resp = await this.vendorService.submitVendor(formData)
      if (resp['error'] == false) {
        this.spinner.hide()
        this.backToList();
        this.vendorObj = new Object()
        this.ifs_mes = ''
        this.isError = false;
        Swal.fire('Success', 'Vendor Added Successfully', 'success');
      } else {
        this.spinner.hide();
        Swal.fire('ERROR', 'ERROR While Adding Vendor', 'error');
      }
    }
  }

  async update() {

  }

  currentDate() {
    let n = new Date();
    let y: any = n.getFullYear();
    let m: any = n.getMonth() + 1;
    let d: any = n.getDate();
    if (m < 10) {
      m = "0" + m
    }
    if (d < 10) {
      d = "0" + d
    }
    return y + "-" + m + "-" + d;
  }


  validDate(date:any) {
    let n = new Date();
    let y: any = n.getFullYear();

    let userYear = parseInt(date.split("-")[0])
    if ((y - userYear) < 18) {
      Swal.fire('Info', "Your age needs to be 18+", 'info')
    }
  }

  async inactive() {

    if (!this.vendorObj['inactive_reason'] || !this.vendorObj['inactive_date'] || !this.vendorObj['vendor_id']) {
      Swal.fire('ERROR', 'Please fill all field', 'error');
      return;
    }

    this.spinner.show()
    this.vendorObj['life_cycle_status'] = 'INACTIVE'
    let resp = await this.vendorService.inactiveVendor(this.vendorObj)
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.spinner.hide()
      Swal.fire('SUCCESS', 'Successfully inactivated', 'success');
      $('#inactive').modal('hide');
      this.vendorObj = {}
      await this.getVendor();
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'ERROR While Inactivating Vendor', 'error');
    }


  }

  vendorDetail: any;
  vendocDoc: Array<object> = [];
  selectedVendorId:any;
  vendor_id :any= {}

  async viewDetails(element:any) {
    this.selectedVendorId = 0;
    this.list = false;
    this.create = false;
    this.view_details = true;
    this.update_flag = false;
    this.venderInfo = element
    console.log(this.venderInfo)
    this.spinner.show()
    this.vendor_id['vendor_id'] = element.vendor_id;
    this.selectedVendorId = element.vendor_id;
    let resp = await this.vendorService.getvendorDetails(JSON.stringify(this.vendor_id))
    console.log(resp)
    if (resp['error'] == false) {
      console.log(resp['data']);
      this.venderInfo = resp['data']['vendor_info'];
      this.venderAccountInfo = resp['data']['vendorAccountInfo'];
      if (this.venderInfo['electrical_cert_on_firm_name']) {
        this.electrical_view = true;
      } else {
        this.electrical_view = false;
      }
      console.log(this.venderInfo)
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire('ERROR', 'ERROR While Getting Vendor', 'error');
    }
  }

  imgURL: any;
  async viewDoc(doc_type:any) {
    console.log(doc_type)
    let obj:any = new Object();
    obj['vendor_id'] = this.selectedVendorId;
    obj['doc_type'] = doc_type;
    console.log(obj)
    const res :any = await this.vendorService.viewDoc(obj);
    console.log(res)
    this.spinner.show()
    if (res) {
      if (res['data1']) {
        Swal.fire('info', 'document not found', 'info');
        this.spinner.hide()
        return
      }

      if (doc_type == 'dir_image') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        return
      }
      console.log(res)
      let file = new Blob([res], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.spinner.hide()
    } else {

      Swal.fire('error', 'server side error', 'error')
      this.spinner.hide()
    }

  }
  // ////////////////////////
  async saveVenderInfo(stepper: MatStepper) {
    await this.getAllEmpData();
    await this.getAllDirData();
    await this.getAllMacData();
    await this.getAllBankData();
    stepper.next();
  }

  async next_step(stepper: MatStepper) {
    // Move to the next step
    stepper.next();
  }

  all_emp:any ;
  vender_emp_info_table :any = [];

  async getAllEmpData() {
    this.spinner.show()
    let obj:any = {};
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = this.vendor_id['vendor_id'];
    let resp = await this.vendorService.getSavedEmpData(obj)
    if (resp['error'] == false) {
      console.log(resp.data)
      this.all_emp = resp.data;
      this.vender_emp_info_table = [];
      let obj123:any  = {}
      this.vender_emp_info_table = [];
      for (let i = 0; i < resp.data.length; i++) {
        obj123[resp['data'][i]['emp_id']] = resp['data'][i];
      }
      console.log("Unique ", obj123)
      let key_arr = Object.keys(obj123);
      console.log(key_arr)
      key_arr.map(x => {
        this.vender_emp_info_table.push(obj123[x])
      })

      console.log(this.vender_emp_info_table, "unik")
      console.log(this.vender_emp_info_table)

      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire("error", "Error while getting empolyee information ", 'error');

    }
  }

  diploma_doc:any
  OpenUpdateEmpData(v:any , emp_id:any ) {
    this.vender_emp_info = v;
    console.log(v)
    console.log(emp_id)
    this.degree_doc = [];
    this.diploma_doc = [];
    for (let j = 0; j < this.all_emp.length; j++) {
      if (this.all_emp[j]['emp_id'] == emp_id) {
        if (this.all_emp[j]['course_type'] == 'degree') {
          this.degree_doc.push(this.all_emp[j])
        }
        if (this.all_emp[j]['course_type'] == 'diploma') {
          this.diploma_doc.push(this.all_emp[j])
        }
      }
    }
    console.log(this.degree_doc)
    console.log(this.diploma_doc)
    console.log(this.vender_emp_info)
    $("#EMPLOYEE").modal("show");
  }

  // //////////get director data
  vender_director_info: any;
  async getAllDirData() {
    let obj:any = {};
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = this.vendor_id['vendor_id'];
    let resp = await this.vendorService.getAllDirData(obj)
    if (resp['error'] == false) {
      this.all_dir = resp.data
      this.vender_director_info_table = []
      let obj123:any  = {};
      console.log(resp.data)
      resp['data'].map((x:any ) => {
        obj123[x['director_id']] = x;
      })
      console.log("Unique ", obj123)
      let key_arr = Object.keys(obj123);
      key_arr.map(x => {
        this.vender_director_info_table.push(obj123[x])
      })

    }
  }
  familyDD :any = [];
  nomiP :any = [];

  async updateDirOpen(d:any , director_id:any ) {

    let obj:any = {}
    obj['vendor_id'] = d.vendor_id;
    obj['director_id'] = director_id;
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    this.spinner.show();
    let resp = await this.vendorService.getAllDirDataoneByOne(obj)
    console.log(resp.data)
    if (resp['error'] == false) {
      this.spinner.hide()
      $("#ACTHIER").modal("show");
      this.vender_director_info = resp.data['vender_director_info']
      this.familyDD = resp.data['familyDD']
      this.nomiP = resp.data['nomiP']
    } else {
      Swal.fire('error', 'server side error', 'error');
      this.spinner.hide();
      return
    }
    // console.log(this.familyDD)
    // console.log(this.nomiP)
    // console.log(this.vender_director_info)
    // $("#ACTHIER").modal("show");
  }

  // ///////get all machine details
  async getAllMacData() {
    let obj:any = {};
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = this.vendor_id['vendor_id'];

    this.spinner.show();
    let resp = await this.vendorService.getAllMacData(obj)
    console.log(resp.data)
    if (resp['error'] == false) {
      console.log(resp.data['progress'].length)
      if (resp.data['progress'].length != 0) {
        this.progress = resp['data']['progress']
      }
      if (resp.data['ongoing'].length != 0) {
        this.ongoing = resp['data']['ongoing']
      }
      if (resp.data['machine'].length != 0) {
        this.machine = resp['data']['machine']
      }
      if (resp.data['completion'].length != 0) {
        this.completion = resp['data']['completion']
      }
      this.spinner.hide();


    } else {
      this.spinner.hide();
      Swal.fire("error", "Error while machine and experiencr details ", 'error');
    }
  }

  // //////bank details
  bank_details: any;
  async getAllBankData() {
    let obj:any = {};
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = this.vendor_id['vendor_id'];
    this.spinner.show();
    let resp = await this.vendorService.getAllBankData(obj)
    if (resp['error'] == false) {
      this.spinner.hide()
      console.log(resp.data)
      if (resp.data != undefined) {
        this.bank_details = resp.data
      }
      // this.controlViewButton()
    } else {
      Swal.fire("error", "Error while geting Bank information ", 'error');
    }


  }


  async print() {
    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['vendor_id'] = this.vendor_id['vendor_id'];
    console.log("while Printing ", obj)
    let resp = await this.vendorService.printApi(JSON.stringify(obj));
    if (resp['error'] == false) {
      pdfMake.createPdf(resp['data']).download('Application');
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('ERROR', 'Error While Printing ', 'error');
    }
  }

// //////////register vebder//////

async register(element:any ){

  Swal.fire({
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Register it!'
  }).then(async (result) => {
    if (result.value) {
      this.vendor_id['vendor_id'] = element.vendor_id;
      let resp = await this.vendorService.getvendorDetails(JSON.stringify(this.vendor_id))
      if (resp['error'] == false) {
        console.log(resp['data']);
        this.venderInfo = resp['data']['vendor_info'];
      }else{
        Swal.fire('error','server side error','error');
        return
      }
      this.venderInfo['dob'] = ''
      this.venderInfo['nick_name'] = ''
      this.venderInfo['b_acct_id'] = this.b_acct_id
      this.venderInfo['vendor_id'] = element.vendor_id
      let res = await this.vendorService.registervender(this.venderInfo);
      console.log(res)
      if(res['error'] == false){
        Swal.fire('success',res['data'],'success');
        this.getVendor()
        return
      }else{
        Swal.fire('error','server side error','error');
        return
      }
    }
  })

}






}
