import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {MainService} from './main.service';
import { JsonPipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl =  this.main.httpUrl+ "/property/auction";
  }
  async createAuction(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/addauction', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllBids(obj:any){

    const resp = this.http.get<any>(this.httpUrl + '/getallBid' + obj).toPromise().then(res => {
      return res
    });
    return resp
  }
  // http://localhost:3000/property/auction/get-auction-bid-rule

  async createAuctionBidCoffRule(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/insert-auction-bid-rule', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async createTermAndCondition(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/insert-term-condtion', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getTermAndCondition(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/get-term-condtion', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async delTermAndCondition(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/del-term-condtion', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getAuctionBidRules(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/get-auction-bid-rule/', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async deleteAuctionBidRule(obj:any){
    const resp = await this.http.post<any>(this.httpUrl + '/del-auction-bid-rule', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async updateAuction(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/updateauction', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllAuction(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getAuctiondata' + obj).toPromise().then(res => {

      return res
    });
    return resp
  }

  async deleteauction(obj:any){
    const resp = await this.http.put<any>(this.httpUrl + '/inactiveauction', obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
  async getAllAuctionApplication(obj:any){
    const resp = this.http.get<any>(this.httpUrl + '/getapplication' + obj).toPromise().then(res => {

      return res
    });
    return resp
  }

  async getAuctionProperty(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getAuctionProperty'+obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async getPropertyTypeByOffer(obj:any){
    const resp = await this.http.get<any>(this.main.httpUrl + '/property/offers/getPropertyTypeByOffer'+obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp
  }

  async getAllCastingData(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getAllCostingData' + obj).toPromise().then(res => {
      console.log(res)
      return res
    });
    return resp

  }
  async getAllRequiredDocumentsForUpload(obj:any){
    const resp = await this.http.get<any>(this.httpUrl + '/getAuctionDocData' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
