import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MainService as md_mainService } from '../../../md/service/main.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MainService } from '../../service/main.service'

declare var $: any;
@Component({
  selector: 'app-hr-roles',
  templateUrl: './hr-roles.component.html',
  styleUrls: ['./hr-roles.component.css']
})
export class HrRolesComponent implements OnInit {

  checkBoxValue: any = false;
  displayedColumns :any = ['role_cd', 'role_name', 'role_desc', 'role_type', 'res', 'action'];
  type_data:any  = []
  party :any = [];
  datasource:any ;
  user_id:any ;
  index:any ;
  Obj :any = {};
  b_acct_id:any  = 0;
  erpUser:any ;
  le_type:any  = []
  allCodeValue:any  = [];

  constructor(private spinner: NgxSpinnerService, public mainService: MainService, public toastr: ToastrService, private mainServiceMD: md_mainService) { }
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }
  data = [{ id: 1, acct_short_name: 'AC', acct_full_name: 'Account', name: "suraj kumar", email: "suraj@infiniseasoft.com", phone_no: "9984904063" }]
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    await this.getCurrentResource()
    await this.getcurrentroleswithresources()
  }


  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.Obj={};
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }


  checkCheckBoxvalue() {
    let dummy :any = []
    if (this.checkBoxValue == false) {
      for (let i = 0; i < this.resource.length; i++) {
        dummy.push(this.resource[i]['resource_cd'])
      }
      this.Obj['res'] = dummy
    } else {
      this.Obj['res'] = null
    }
    console.log(this.checkBoxValue, this.Obj)
  }
  async getcurrentroleswithresources() {
    this.spinner.show()
    let obj :any  = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'HR'
    console.log(obj)
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      var current_role = []
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
          current_role.push(resp['data'][i])
        // }
      }
      this.datasource = new MatTableDataSource(current_role);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  async getAllModules() {
    this.spinner.show()
    let obj :any  = {}
    obj['b_acct_id'] = this.b_acct_id
    console.log(obj)
    var resp = await this.mainServiceMD.getMdModules(JSON.stringify(obj));
    console.log(resp, 'modules');
    if (resp['error'] == false) {
      this.spinner.hide()
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting modules", 'error');
    }
  }
  resource :any = []
  async getCurrentResource() {
    let obj :any  = {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.mainServiceMD.getMdResource(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      let dummy = []
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['module_cd'] == 'HR') {
          resp['data'][i]['desc'] = resp['data'][i]['module_cd'] + " - " + resp['data'][i]['resource_desc']
          dummy.push(resp['data'][i])
        }
      }
      this.resource = dummy
    } else {
      Swal.fire("Error", "..Error while getting  resource", 'error');
    }
  }


  viewObj:any  = {}
  viewDetails(element:any ) {
    this.viewObj = Object.assign({}, element)
    console.log(element)
    $('#B').modal('show');
  }

  changeparty() {
    for (let i = 0; i < this.party.length; i++) {
      if (this.party[i]['party_id'] == this.Obj['party_id']) {
        this.Obj['party_leagal_name'] = this.party[i]['party_leagal_name']
      }
    }
  }

  async refresh() {
    this.Obj = {}
  }

  back() {
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    this.Obj = {};
  }

  async open_update(element:any ) {
    console.log(element)
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    if (element['resource_cd']) {
      this.Obj['res'] = element['resource_cd'].split(',')
    }
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    // $('.nav-tabs a[href="#tab-7-3"]').tab('show');
    this.spinner.hide();
  }



  async submit() {
    if (!this.Obj['role_cd']) {
      Swal.fire("Error", 'Please Enter Role Code', 'error')
    } else if (!this.Obj['role_name']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    }
    else if (!this.Obj['role_desc']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    }
    else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    }
    else {
      let dummy = []
      for (let i = 0; i < this.resource.length; i++) {
        for (let j = 0; j < this.Obj['res'].length; j++) {
          if (this.Obj['res'][j] == this.resource[i]['resource_cd']) {
            dummy.push(this.resource[i])
          }
        }
      }
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'GENERATED'
      this.Obj['user_id'] = this.erpUser.le_id
      this.Obj['role_type'] = 'Module Role'
      this.Obj['data'] = dummy
      this.Obj['module_cd'] = 'HR'
      console.log(this.Obj)
      this.spinner.show()
      var resp = await this.mainServiceMD.createMdRole(this.Obj);
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(() => {
          this.spinner.hide()
          Swal.fire('Success...', 'Created Successfully', 'success')
          this.getcurrentroleswithresources()
        }, 500);
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Error While Creating', 'error')
      }
    }
  }
  async update() {
    if (!this.Obj['role_cd']) {
      Swal.fire("Error", 'Please Enter Role Code', 'error')
    } else if (!this.Obj['role_name']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    }
    else if (!this.Obj['role_desc']) {
      Swal.fire('Error', 'Please Enter Role Description', 'error')
    }
    else if (!this.Obj['res']) {
      Swal.fire('Error', 'Please Select Resource', 'error')
    }
    else {
      let dummy = []
      for (let i = 0; i < this.resource.length; i++) {
        for (let j = 0; j < this.Obj['res'].length; j++) {
          if (this.Obj['res'][j] == this.resource[i]['resource_cd']) {
            dummy.push(this.resource[i])
          }
        }
      }
      this.Obj['b_acct_id'] = this.b_acct_id
      this.Obj['life_cycle_status'] = 'GENERATED'
      this.Obj['user_id'] = this.erpUser.le_id
      this.Obj['role_type'] = 'Module Role'
      this.Obj['data'] = dummy
      this.Obj['module_cd'] = 'HR'
      console.log(this.Obj)
      this.spinner.show()
      var resp = await this.mainServiceMD.updateRole(this.Obj);
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(() => {
          this.spinner.hide()
          Swal.fire('Success...', 'Created Successfully', 'success')
          this.getcurrentroleswithresources()
        }, 500);
      } else {
        this.spinner.hide()
        Swal.fire('Error', 'Error While Creating', 'error')
      }
    }
  }

  validate(email:any ) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      return true
    }
  }


  async delete(element:any ) {
    console.log(element)
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async finaldelete(element:any ) {
    let obj :any  = {}
    obj = Object.assign({}, element)
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    var resp = await this.mainServiceMD.deleteRole(obj)
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      await this.getcurrentroleswithresources()
      Swal.fire('Success', resp['data'], 'success')
    } else {
      this.spinner.hide()
      Swal.fire('Error', resp['data'], 'error')
    }
  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
