<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"></div>
                </div>
                <div class="ibox-body">


                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class='nav-link {{ad_tab_list}}' data-toggle="tab" (click)="list()" href="#tab-7-1"
                                data-toggle="tab"><i class="fa fa-line-chart"></i>&nbsp;Chapter Fields List745
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link {{ad_tab_c}}" data-toggle="tab" (click)="chaptermaping()" href="#tab-7-2"
                                data-toggle="tab"><i class="fa fa-plus-circle" data-toggle="tab" aria-hidden="true">
                                </i>&nbsp;Chapter
                                Fields Mapping
                            </a>
                        </li>

                        <li class="nav-item" *ngIf="update_flag">
                            <a class="nav-link {{ad_tab_u}}" href="#tab-7-3" data-toggle="tab"><i
                                    class="fa fa-plus-circle" aria-hidden="true"> </i>&nbsp;
                                Update Chapter Fields
                            </a>
                        </li>
                    </ul>



                    <div class="tab-content">

                        <div *ngIf="l_flag">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-success" style="margin-right:0.5%"
                                    (click)="chaptermaping()">Chapter Fields
                                    Mapping</button>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3">
                                    Costing Rate Standard<span class="text-danger">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['EN055']" bindLabel="value"
                                        bindValue="code" [multiple]="false" placeholder="Select Costing Rate Standard"
                                        [(ngModel)]="obj['costing_rate_std']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-3">
                                    Year of Costing Rate Standard<span class="text-danger">*</span>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="mainService.codeValueTechObj['EN056']" bindLabel="value"
                                        bindValue="code" [multiple]="false"
                                        placeholder="Select Year of Costing Rate Standard" [(ngModel)]="obj['fin_year']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="work_order_id">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-primary" (click)="fetch()">FETCH</button>
                                </div>
                            </div>
                            <br>


                            <mat-form-field>
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>


                            <!-- <div class="example-container"> -->
                                <table mat-table [dataSource]="datasource" matSort style="border: 2px solid #ddd;">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <div class="showonhover">
                                                <div style="color: blue;"> {{ element.id}}
                                                </div>
                                                <span class="hovertext">
                                                    <div class="row">
                                                        <div class="col-4 text-right">
                                                            <h6>Selected Feilds : </h6>
                                                        </div>
                                                        <div class="col-3 text-left">
                                                            <span *ngFor="let item of element.field_data | keyvalue">
                                                                <span *ngIf="item.value != 0"> {{item.key | Split}} , </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="Chapter_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Chapter No
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <span *ngFor="let il of mainService.codeValueTechObj['EN057']">
                                                <span *ngIf="il['code'] == element.chapter_no">
                                                   {{il['value']}}  
                                                </span>
                                            </span> 
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Chapter_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Chapter Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.chapter_desc}} </td>
                                    </ng-container>





                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-info"
                                                (click)="openupdate(element)">Update</button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        <!-- </div> -->
                        <br>

                        <div *ngIf="c_flag || u_flag">
                            <div *ngIf="c_flag">
                                <div style="text-align: right;">
                                    <button class=" btn btn-outline-success" style="margin-right:0.5%"
                                        (click)="list()">Chapter Fields list</button>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Costing Rate Standard : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN055']" bindLabel="value"
                                            bindValue="code" [multiple]="false"
                                            placeholder="Select Costing Rate Standard"
                                            [(ngModel)]="obj['costing_rate_std']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Year of Costing Rate Standard : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN056']" bindLabel="value"
                                            bindValue="code" [multiple]="false"
                                            placeholder="Select Year of Costing Rate Standard"
                                            [(ngModel)]="obj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Select Chapter No : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN057']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select Chapter No"
                                            [(ngModel)]="obj['chapter_no']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                            name="work_order_id">
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Chapter Name : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['chapter_desc']"
                                            placeholder="Enter Chapter Name">

                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                            </div>
                            <div *ngIf="u_flag">

                                <div style="text-align: right;">
                                    <button class=" btn btn-outline-primary" style="margin-right:0.5%"
                                        (click)="list()">Chapter Fields list</button>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Costing Rate Standard : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN055']" bindLabel="value"
                                            bindValue="code" [multiple]="false"
                                            placeholder="Select Costing Rate Standard"
                                            [(ngModel)]="obj['costing_rate_std']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Year of Costing Rate Standard : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN056']" bindLabel="value"
                                            bindValue="code" [multiple]="false"
                                            placeholder="Select Year of Costing Rate Standard"
                                            [(ngModel)]="obj['fin_year']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Select Chapter No : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [items]="mainService.codeValueTechObj['EN057']" bindLabel="value"
                                            bindValue="code" [multiple]="false" placeholder="Select Chapter No "
                                            [(ngModel)]="obj['chapter_no']" [selectableGroup]="true"
                                            [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id"
                                            disabled>
                                        </ng-select>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div class="col-4 text-left">
                                        Chapter Name : <span class="text-danger">*</span>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control" [(ngModel)]="obj['chapter_desc']"
                                            placeholder="Enter Chapter Name" disabled>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                                <br>

                            </div>

                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input"
                                        (change)="item['category'] = $any($event.target).checked ? 1: 0"
                                        name="item['category'] " id="exampleCheck1" [(ngModel)]="item['category']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Category
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input"
                                        (change)="item['sed_item_no'] = $any($event.target).checked ? 1: 0"
                                        name="item['sed_item_no']" id="exampleCheck1" [(ngModel)]="item['sed_item_no']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">Schedule Item Number</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input"
                                        (change)="item['code_no'] = $any($event.target).checked ? 1: 0" name="item['code_no']"
                                        id="exampleCheck1" [(ngModel)]="item['code_no']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Code No.
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['ref_to_dar'] = $any($event.target).checked ? 1: 0"
                                        name="item['ref_to_dar']" [(ngModel)]="item['ref_to_dar']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">Ref. to DAR</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['group'] = $any($event.target).checked ? 1: 0" name="item['group']"
                                        [(ngModel)]="item['group']" style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Group
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['pwd_sch'] = $any($event.target).checked ? 1: 0" name="item['pwd_sch']"
                                        [(ngModel)]="item['pwd_sch']" style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">PWD SCH</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['sub_group'] = $any($event.target).checked ? 1: 0"
                                        name="item['sub_group']" [(ngModel)]="item['sub_group']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Sub-Group
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['item_desc'] = $any($event.target).checked ? 1: 0"
                                        name="item['item_desc']" [(ngModel)]="item['item_desc']"
                                        style="width: 22px; height:22px;" disabled>
                                </div>
                                <div class="col-2 p-0 pt-1">Item
                                    Description <span class="text-danger">*</span></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['item_qty'] = $any($event.target).checked ? 1: 0"
                                        name="item['item_qty']" [(ngModel)]="item['item_qty']"
                                        style="width: 22px; height:22px;" disabled>
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Item Quantity <span class="text-danger">*</span>
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['qty_unit'] = $any($event.target).checked ? 1: 0"
                                        name="item['qty_unit']" [(ngModel)]="item['qty_unit']"
                                        style="width: 22px; height:22px;" disabled>

                                </div>
                                <div class="col-2 p-0 pt-1">Quantity Unit
                                    <span class="text-danger">*</span>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['Costing Rate'] = $any($event.target).checked ? 1: 0"
                                        name="item['Costing Rate']" [(ngModel)]="item['costing_rate']"
                                        style="width: 22px; height:22px;" disabled>
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Costing Rate <span class="text-danger">*</span>
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['costing_rate_unit'] = $any($event.target).checked ? 1: 0"
                                        name="item['costing_rate_unit']" [(ngModel)]="item['costing_rate_unit']"
                                        style="width: 22px; height:22px;" disabled>
                                </div>
                                <div class="col-2 p-0 pt-1">Costing Rate
                                    Unit <span class="text-danger">*</span>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['distance'] = $any($event.target).checked ? 1: 0"
                                        name="item['distance']" [(ngModel)]="item['distance']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Distance
                                </div>
                                <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['distance_unit'] = $any($event.target).checked ? 1: 0"
                                        name="item['distance_unit']" [(ngModel)]="item['distance_unit']"
                                        style="width: 22px; height:22px;">
                                </div>
                                <div class="col-2 p-0 pt-1">Distance Unit</div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-1 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['laber_rate'] = $any($event.target).checked ? 1: 0"
                                        name="item['laber_rate']" [(ngModel)]="item['laber_rate']"
                                        style="width: 22px; height:22px;">

                                </div>
                                <div class="col-2 p-0 pt-1">
                                    Labour Rate
                                </div>
                                <!-- <div class="col-2 text-right">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                        (change)="item['effective_date'] = $event.target.checked ? 1: 0"
                                        name="item['effective_date']" [(ngModel)]="item['effective_date']"
                                        style="width: 22px; height:22px;" disabled>

                                </div>
                                <div class="col-2 p-0 pt-1">Effective
                                    Date <span class="text-danger">*</span></div>
                            </div> -->
                            <br>


                            <div class="row">
                                <div class="col-12 text-center" *ngIf="c_flag">
                                    <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center" *ngIf="u_flag">
                                    <button class="btn btn-outline-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                            <br>



                        </div>





                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>