<!-- START HEADER-->
<header class="header" app-admin-header></header>
<!-- END HEADER-->
<!-- START SIDEBAR-->
<nav class="page-sidebar" id="sidebar" app-admin-sidebar></nav>
<!-- END SIDEBAR-->

<!-- START PAGE CONTENT-->
<div class="content-wrapper">
    <router-outlet></router-outlet>
    <!-- <div app-banner></div> -->
    <footer class="page-footer" app-admin-footer></footer>
</div>
