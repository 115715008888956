import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ProfileService } from '../../service/profile.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from '../../../authentication.service'

@Component({
  selector: '[app-icms-sidebar]',
  templateUrl: './icms-sidebar.component.html',
  styleUrls: ['./icms-sidebar.component.css']
})
export class IcmsSidebarComponent implements OnInit {

  user_id:any;
  imgURL:any;
  erpUser:any;
  accInfo:any;
  //allLableShowObjHR={}
  sidebar:any = new Object();


  userInfo = { emp_name: 'Ram Prasad', designation: 'Accountant' }
  constructor(public auth: AuthenticationService, private router: Router, private spinner: NgxSpinnerService
    , private profileService: ProfileService, private sanitizer: DomSanitizer, public mainService: MainService) {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.user_id = this.erpUser.user_id;
    this.assigned_compoment = this.erpUser.assigned_component;

    //Dashboard
    this.sidebar['dashbord'] = !this.assigned_compoment.includes('ICMS-R1')
    //Complaints Start
    this.sidebar['complaints'] = !this.assigned_compoment.includes('ICMS-R24')
    this.sidebar['user_complain'] = !this.assigned_compoment.includes('ICMS-R10')
    this.sidebar['public_complain'] = !this.assigned_compoment.includes('ICMS-R13')
    this.sidebar['assigned_complain'] = !this.assigned_compoment.includes('ICMS-R14')
    //Complaints End

    //inspection Start
    this.sidebar['inspection_report'] = !this.assigned_compoment.includes('ICMS-R15')
    //inspection End

    //E-Court Start
    this.sidebar['e_court'] = !this.assigned_compoment.includes('ICMS-R27') //case list
    this.sidebar['litigation'] = !this.assigned_compoment.includes('ICMS-R11') //case list
    this.sidebar['cause_list'] = !this.assigned_compoment.includes('ICMS-R17')
    this.sidebar['request'] = !this.assigned_compoment.includes('ICMS-R26')
    // E-court End

    //Court Ordeer Start
    this.sidebar['court_order'] = !this.assigned_compoment.includes('ICMS-R12') // Total access of court order
    this.sidebar['order'] = !this.assigned_compoment.includes('ICMS-R28')
    this.sidebar['assigned_order'] = !this.assigned_compoment.includes('ICMS-R29')
    this.sidebar['support_letter'] = !this.assigned_compoment.includes('ICMS-R30')
    this.sidebar['compounding_process'] = !this.assigned_compoment.includes('ICMS-R31')
    this.sidebar['recovery_calculation'] = !this.assigned_compoment.includes('ICMS-R32')
    //Court Order End

    // All report Start
    this.sidebar['all_report'] = !this.assigned_compoment.includes('ICMS-R34')
    // All report End

    // Payments Start
    this.sidebar['payment'] = !this.assigned_compoment.includes('ICMS-R33')
    this.sidebar['penality'] = !this.assigned_compoment.includes('ICMS-R33')
    this.sidebar['demolition_charges'] = !this.assigned_compoment.includes('ICMS-R33')
    this.sidebar['compounding'] = !this.assigned_compoment.includes('ICMS-R33')
    //payment End


    this.sidebar['administration'] = !this.assigned_compoment.includes('ICMS-R7')
    //Master screen Start

    this.sidebar['master'] = !this.assigned_compoment.includes('ICMS-R2')
    this.sidebar['code_value'] = !this.assigned_compoment.includes('ICMS-R6')
    this.sidebar['field'] = !this.assigned_compoment.includes('ICMS-R18')
    this.sidebar['section_map'] = !this.assigned_compoment.includes('ICMS-R19')
    this.sidebar['boundry_map'] = !this.assigned_compoment.includes('ICMS-R9')
    this.sidebar['hierarchy'] = !this.assigned_compoment.includes('ICMS-R20')
    this.sidebar['support_deparment'] = !this.assigned_compoment.includes('ICMS-R21')
    this.sidebar['camp_boundry_map'] = !this.assigned_compoment.includes('ICMS-R35')
    //MasterScreen End

    // Security Screen Start
    this.sidebar['security'] = !this.assigned_compoment.includes('ICMS-R23')
    this.sidebar['role'] = !this.assigned_compoment.includes('ICMS-R4')
    this.sidebar['user'] = !this.assigned_compoment.includes('ICMS-R3')
    this.sidebar['data_assignment'] = !this.assigned_compoment.includes('ICMS-R22')
    this.sidebar['workflow'] = !this.assigned_compoment.includes('ICMS-R5')
    this.sidebar['payment_gatway'] = !this.assigned_compoment.includes('ICMS-R16')
    //Security Screen End







  }
  hidden = true;
  assigned_compoment:any= []
  async ngOnInit() {

    if (this.erpUser.is_admin == 0) {
      this.hidden = true;
    } else {
      this.hidden = false
    }
    this.imgURL = './assets/img/admin-avatar.png';
    await this.getUserImage();
    // await this.getUserInfo();
    await this.getAccImage();
    // await this.getAccInfo();
  }

  /*************************************************Assigned Componenent to the Current*************************** */


















  /*************************************************Assigned Componenent to the Current*************************** */

  home() {
    this.router.navigate(['/index'])
  }


  async getUserImage() {
    const res = await this.profileService.getImage(this.user_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.profileImageUrl = this.imgURL;

    }
  }
  async getUserInfo() {
    this.spinner.show()
    var resp = await this.profileService.getUserProfileInfo(this.user_id);
    if (resp['error'] == false) {
      this.spinner.hide()

      this.userInfo = resp.data[0];
    } else {

      this.spinner.hide()
    }
  }
  async getAccImage() {
    const res = await this.profileService.getAccImage(this.erpUser.b_acct_id);
    if (res) {
      const unsafeImageUrl = window.URL.createObjectURL(res); // URL.createObjectURL(res);
      this.imgURL = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      this.mainService.accountImageUrl = this.imgURL;

    }
  }
  async getAccInfo() {
    this.spinner.show()
    var resp = await this.profileService.getAccProfileInfo(this.erpUser.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.accInfo = resp.data[0];
      this.mainService.accInfo = this.accInfo;
    } else {
      this.spinner.hide()

    }

  }
  refreshCol() {
    var col = document.getElementsByClassName('collapse')

    for (let i = 0; i <= col.length; i++) {
      if (col[i].classList.contains('show')) {
        col[i].classList.remove('show');
      }
    }

  }
}


