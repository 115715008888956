<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- LPC Report --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC1']}}</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                    
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i><!-- LPC FOR RETIREMENT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC2']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i> <!-- LPC FOR TRANSFER -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC3']}}</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-settings"></i> <!-- LPC FOR DEATH -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC4']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content"><br><br>
                        <div class="tab-pane fade show active" id="tab-1">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC5']}} :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
        
                                </div>
        
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Reason of Retirement --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC6']}} :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="text" [(ngModel)]="selectObj['reason_of_retirement']">
                                </div>
        
                            </div>
        
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Retirement Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC7']}} :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="date" [(ngModel)]="selectObj['retirement_date']">
        
        
                                </div>
        
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Posting Point --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC8']}}:
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="text" [(ngModel)]="selectObj['posting_point']">
        
        
                                </div>
        
                            </div>
                            <br>
                            
        
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getLpcretire()"><!-- SUBMIT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC9']}}</button>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="print1()">PRINT</button>
                                </div>
                            </div> -->
                            <br>
                            <hr>
                        
                           
                           








                           

                            
                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC5']}} :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
        
                                </div>
        
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Govt Order --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC10']}}  :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="text" [(ngModel)]="selectObj['reason_of_retirement']">
                                </div>
        
                            </div>
        
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Transfer Date -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC11']}} :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="date" [(ngModel)]="selectObj['retirement_date']">
        
        
                                </div>
        
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Posting Point -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC8']}} :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="text" [(ngModel)]="selectObj['posting_point']">
        
        
                                </div>
        
                            </div>
                            <br>
                            
        
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getLpctransfer()"><!-- SUBMIT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC9']}}</button>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="print2()">PRINT</button>
                                </div>
                            </div> -->
                            <br>
                            <hr>
                     
                           
                           








                           

                            
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Select Employee -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC5']}} :
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allEmplyees_new" bindLabel="emp_name" bindValue="emp_id" [multiple]="false" placeholder="" [(ngModel)]="selectObj['emp_id']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                                    </ng-select>
        
                                </div>
        
                            </div>
                            <br>
                            
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Date of Death -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC12']}} :
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="date" [(ngModel)]="selectObj['retirement_date']">
        
        
                                </div>
        
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <!-- Posting Point --> {{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC8']}}:
                                </div>
                                <div class="col-4">
        
                                    <input class="form-control" type="text" [(ngModel)]="selectObj['posting_point']">
        
        
                                </div>
        
                            </div>
                            <br>
                           
        
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="getLpcdeath()"><!-- SUBMIT -->{{mainService.allLableShowObjHR[mainService.language_cd+'HR-LPC9']}}</button>
                                </div>
                            </div>
                            <br>
                            <!-- <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-primary" (click)="print3()">PRINT</button>
                                </div>
                            </div> -->
                            <br>
                            <hr>
                       
                           
                           








                           

                            
                        </div>

                        

                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>

    <ngx-spinner>Loading....</ngx-spinner>