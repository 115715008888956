import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { EstablishmentService } from '../../service/establishment.service';
import { MainService } from '../../service/main.service';
import { FileUploader } from 'ng2-file-upload';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { MainService as md_mainService } from '../../../md/service/main.service';
import { MainService as MD_mainService } from '../../../md/service/main.service';
import { RoleService as MD_RoleService } from '../../../md/service/role.service';
import { DataAccessService } from '../../service/data-access.service';
import { SettingService } from '../../service/setting.service';

declare var $: any
@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {


  constructor(private dataAccessService: DataAccessService, private MD_mainService: MD_mainService, private settingService: SettingService, public mainService: MainService, private router: Router, private MD_RoleService: MD_RoleService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private establishmentService: EstablishmentService, private mainServiceMD: md_mainService) { }
  erpUser: any;
  b_acct_id: any;

  codeValueTechObj: any = {};
  emp_name: any
  allEmplyees: any = [];
  selectEmpObj: any = {};
  complaintObj: any = {};
  updateComplaintObj: any = {};
  arr_id: any;
  newallEmplyees: any = []
  allComplaintArray: any = [];
  empIdToName: any = {}
  allModule: any = [];
  moduleToRole: any = {};
  roleToModule: any = {};
  allUsers: any = [];
  allRole: any = [];
  current_role: any = [];
  current_LE: any = [];
  ind_current_LE: any = [];
  org_current_LE: any = [];
  moduleObj: any = {};
  forObj: any = {};
  closeObj: any = {};
  approveObj: any = {};
  allModuleWiseUser: any = [];
  suspensionCompObj: any = {};
  payCompObj: any = {};
  dedCompObj: any = {};
  payCompArr: any = [];
  dedCompArr: any = [];
  payCompCount: any = 0;
  dedCompCount: any = 0;
  allActiveEmplyees: any = [];
  allPayDetails: any = [];
  activeEmpArr: any = [];
  inputPayCompArr: any = [];
  inputDedCompArr: any = [];
  compStatusArray: any = [];
  forwardEmpNameId: any;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  displayedColumns = ['id', 'complaint_type_code', 'complaint_desc', 'complaint_dt', 'emp_id', 'emp_name', 'complaint_status', 'action'];
  datasource: any;
  selectedFile: any = null;
  isUpload: any;
  public imagePath: any;
  httpUrl: any;
  uploader: any;

  async ngOnInit() {
    this.codeValueTechObj = this.mainService.codeValueTechObj;
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.httpUrl = this.mainService.httpUrl;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file: any) => { file.withCredentials = false; };

    // await this.getAllEmployees();

    await this.getAllActiveEmployees();
    await this.getCurrentLegalEntity();
    await this.getAllComplaint();
    await this.getAllAccountModule();

    //await this.getCurrentUsers();
    let currDate = new Date().toISOString().slice(0, 10);
    this.complaintObj['complaint_dt'] = currDate;
  }
  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    var resp = await this.mainServiceMD.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.current_LE = resp['data']
      let ind = []
      let org = []
      for (let i = 0; i < this.current_LE.length; i++) {
        if (this.current_LE[i]['party_type'] == 'IND') {
          ind.push(this.current_LE[i])
        } else {
          org.push(this.current_LE[i])
        }
      }
      this.ind_current_LE = ind
      this.org_current_LE = org
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }

  async getFixedPayDetails(emp_id: any) {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_id'] = emp_id;//this.suspensionCompObj['emp_id']
    for (let i = 0; i < this.activeEmpArr.length; i++) {
      if (obj['emp_id'] == this.activeEmpArr[i]['emp_id']) {
        obj['arr_id'] = this.activeEmpArr[i]['arr_id'];
        break;
      }
    }
    var resp = await this.settingService.getFixedPayDetails(obj);
    if (resp['error'] == false) {
      this.allPayDetails = resp.data;
      for (let i = 0; i < this.allPayDetails.length; i++) {
        if (this.allPayDetails[i]['pay_code'] == 'PAY') {
          this.payCompArr.push(this.allPayDetails[i])
          this.payCompCount++;
        }
        else {
          this.dedCompArr.push(this.allPayDetails[i])
          this.dedCompCount++;
        }
      }
      for (let i = 0; i < this.payCompCount; i++) {
        this.payCompObj[i] = 0;
      }
      for (let i = 0; i < this.dedCompCount; i++) {
        this.dedCompObj[i] = 0;
      }
    }
    else {
      this.spinner.hide()
      Swal.fire('Error', 'Error while getting employee salary', 'error')
    }
  }

  /* Module Name*/
  async getAllAccountModule() {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.MD_mainService.getAccountModules(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allModule = resp.data;

      for (let i = 0; i < this.allModule.length; i++) {
        this.moduleObj[this.allModule[i]['module_cd']] = this.allModule[i]['module_name']
      }

      for (let i = 0; i < this.allModule.length; i++) {
        if (this.allModule[i]['module_cd'] == 'ENG') {
          this.allModule.splice(i, 1)
        }
      }
    } else {
      swal.fire('Error', 'Some Error Occured', 'error')
    }
  }

  /*For Roles */
  async getcurrentroleswithresources(module_cd: any) {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = module_cd;
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.current_role = []
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
        this.current_role.push(resp['data'][i])
        // }
      }
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  /*For All Users Mapped with Module wise */
  async getCurrentUsers(role_desc: any, module_cd: any) {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id;

    obj['module_cd'] = module_cd;
    obj['role_desc'] = role_desc;
    var resp = await this.mainServiceMD.getCurrentMdUser(JSON.stringify(obj));

    if (resp['error'] == false) {
      this.spinner.hide();
      this.allUsers = resp['data']
      var emp_id: any = new Object();
      for (let i = 0; i < resp['data'].length; i++) {
        for (let j = 0; j < this.allRole.length; j++) {
          if (this.allRole[j]['role_cd'] == resp['data'][i]['role_cd']) {
            if (emp_id[resp['data'][i]['user_id']] == undefined) {
              resp['data'][i]['role_name'] = this.allRole[j]['role_name']
              emp_id[resp['data'][i]['user_id']] = resp['data'][i]
            } else {
              emp_id[resp['data'][i]['user_id']]['role_name'] = emp_id[resp['data'][i]['user_id']]['role_name'] + " , " + this.allRole[j]['role_name']
            }
          }
        }
      }
      console.log(emp_id)
      var data: any = Object.values(emp_id);

      console.log(data)
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.current_LE.length; j++) {
          if (this.current_LE[j]['le_id'] == data[i]['user_id']) {
            data[i]['party_name'] = this.current_LE[j]['party_name']
            data[i]['contact_email'] = this.current_LE[j]['contact_email']
            data[i]['phone_no'] = this.current_LE[j]['phone_no']
          }
        }
      }
      // data=await this.dataAccessService.filterEmployeeData(data)
      var temp_data = await this.dataAccessService.filterEmployeeDataByUserId(data)
      this.allModuleWiseUser = temp_data;
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }
  /*For Current Role */
  async getCurrentRole(module_cd: any) {
    this.spinner.show()
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = module_cd;
    console.log(obj)
    var resp = await this.mainServiceMD.getcurrentroleswithresources(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.current_role = [];
      this.allRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        // if (resp.data[i]['is_system_role'] == 0) {
        this.current_role.push(resp['data'][i])
        // }
      }
      console.log(this.current_role)
    } else {
      this.spinner.hide()
      Swal.fire("Error", "..Error while getting  roles", 'error');
    }
  }

  async getAllActiveEmployees() {
    await this.getAllComplaint();
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status_code'] = ['ACTIVE', 'INACTIVE']
    var resp = await this.establishmentService.getArrayAllCurrentEstablishementInfo(obj);
    //this.allActiveEmplyees=[]

    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      this.activeEmpArr = []
      this.compStatusArray = [];

      for (let j = 0; j < this.allComplaintArray.length; j++) {
        if (this.allComplaintArray[j]['complaint_status'] == 'APPROVED' || this.allComplaintArray[j]['complaint_status'] == 'FORWARD' || this.allComplaintArray[j]['complaint_status'] == 'REGISTERED') {
          this.compStatusArray.push(this.allComplaintArray[j]['emp_id']);
        }
      }

      for (let i = 0; i < this.allEmplyees.length; i++) {
        if (this.allEmplyees[i].emp_status_code == "ACTIVE") {
          this.activeEmpArr.push(this.allEmplyees[i])
        }
      }
      this.newallEmplyees = [];
      for (let i = 0; i < this.activeEmpArr.length; i++) {
        var obj: any = new Object();
        obj = Object.assign({}, this.activeEmpArr[i]);
        // for(let j = 0;j<compStatusArray.length;j++){
        //   if(this.activeEmpArr[i]['emp_id'] != compStatusArray[j])
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.newallEmplyees.push(obj)
        // }
      }
      for (let i = 0; i < this.allEmplyees.length; i++) {
        this.empIdToName[this.allEmplyees[i].emp_id] = this.allEmplyees[i].emp_id;
        this.empIdToName[this.allEmplyees[i].emp_name] = this.allEmplyees[i].emp_name;
      }
    }
    else {
      swal.fire("Error", "Error while getting employee list", 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  // enquiry(element) {
  // this.establishmentService.complaint_detail=element;
  // this.router.navigate(['/hrms/arr/enq']);
  // }
  refrace() {
    this.updateComplaintObj = '';
    $("#uploadFile").val("")
  }
  async openUpdate(element: any) {
    this.updateComplaintObj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show');
    //   for (let i = 0; i < this.allEmplyees.length; i++) {

    //   if(this.allEmplyees[i]['emp_id'] == element['emp_id'] ){
    //     this.updateComplaintObj['emp_name'] = this.allEmplyees[i]['emp_name']

    //   }
    // }


  }

  async getAllComplaint() {
    this.spinner.show()
    var resp = await this.establishmentService.getAllComplaints(this.b_acct_id);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.allComplaintArray = [];
      this.allComplaintArray = resp.data;
      for (let i = 0; i < this.allComplaintArray.length; i++) {
        for (let j = 0; j < this.allEmplyees.length; j++) {
          if (this.allComplaintArray[i]['emp_id'] == this.allEmplyees[j]['emp_id']) {
            this.allComplaintArray[i]['emp_name'] = this.allEmplyees[j]['emp_name']
          }
        }
      }
      this.datasource = new MatTableDataSource(this.allComplaintArray)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee all complaint list', 'error')
      // this.snackBar.open("Error while getting employee all complaint list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  getNumberFormat(num: any) {
    return num.toString().padStart(3, "0")
  }
  // async getAllEmployees() {
  //   var obj=new Object();
  //    obj['b_acct_id']=this.b_acct_id;
  //    obj['emp_status_code'] = ['JOINING','JOINED','LEFT']
  //   var resp = await this.establishmentService.getEmployeeMasterData(JSON.stringify(obj));
  //   if (resp['error'] == false) {
  //     this.allEmplyees = resp.data;
  //     this.newallEmplyees = []
  //     for(let i=0;i<resp.data.length;i++){
  //       var obj=new Object();
  //       obj=Object.assign({},resp.data[i]);
  //       obj['emp_name']=/* this.mainService.accInfo['account_short_name']+ */this.getNumberFormat(obj['emp_id'])+"-"+obj['emp_name']
  //       this.newallEmplyees.push(obj)
  //     }
  //     for(let i=0;i<this.allEmplyees.length;i++){
  //       this.empIdToName[this.allEmplyees[i].emp_id]=this.allEmplyees[i].emp_name;
  //       this.empIdToName[this.allEmplyees[i].emp_id]=this.allEmplyees[i].emp_id;
  //     }

  //   } else {
  //     this.snackBar.open("Error while getting employee list", 'Error', {
  //       duration: 5000
  //     });
  //   }
  // }
  async checkEmployee() {
    for (let i = 0; i < this.compStatusArray.length; i++) {
      if (this.compStatusArray[i] == this.complaintObj['emp_id']) {
        Swal.fire("Error", 'Already Filed Complaint and Progressing', 'error');
        return
      }
    }

  }
  selectUser(id: any) {
    let userId = id;
    for (let i = 0; i < this.allModuleWiseUser.length; i++) {
      if (this.allModuleWiseUser[i]['user_id'] == userId) {
        this.forwardEmpNameId = this.allModuleWiseUser[i]['party_name'];
      }
    }
  }

  // Avinash
  async aviMatch(t: any) {

    let ext = t.split(".");

    for (let i = 0; i < ext.length; i++) {
      if (ext[i] == "exe" || ext[i] == "mp4" || ext[i] == "MKV" || ext[i] == "AVI" || ext[i] == "MOV" || ext[i] == 'apk') {
        return 0;
      }

    }
    return
  }

  //for Upload data
  // Avinash

  async onFileUpload(event:any, files:any) {
    this.selectedFile = <File>event.target.files[0];

    //----------Avniash Mishra----------
    for (let i = 0; i < event.target.files.length; i++) {
      let text = event.target.files[i]["name"]
      console.log("this is doc name length--", text.length);
      let res = await this.aviMatch(text);

      if (res == 0) {
        $('#docUpload').val('');
        $('#uploadFile').val('');
        swal.fire('Info', `You Enter the .exe File / Video File / Apk, Remove This First..!`, 'info');
        return;
      }
    }

    //---------------

    if (files.length === 0) {
      return;
    }

    // const mimeType = files[0].type;
    // if (mimeType.match(/image\/*/) == null) {
    //   return;
    // }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

  }

  //for pop_up
  open_popup(element:any) {
    this.forObj = Object.assign({}, element);
    this.closeObj = Object.assign({}, element);
    this.approveObj = Object.assign({}, element);
    this.suspensionCompObj = Object.assign({}, element);
    $('#PROCESS').modal('show');
    setTimeout(function () { $('#PROCESS').modal('hide'); }, 5000);
  }
  open_forward() {
    $('#FORWARD').modal('show');
  }
  open_close() {
    $('#CLOSE').modal('show');
  }

  async approve() {
    let approveObj1 :any= new Object();
    approveObj1['b_acct_id'] = this.b_acct_id;
    approveObj1['user_id_by'] = this.erpUser.user_id;
    approveObj1['update_user_id'] = this.erpUser.user_id;
    approveObj1['complaint_id'] = this.approveObj['id'];
    approveObj1['complaint_workflow_status'] = 'APPROVED';
    var resp = await this.establishmentService.approveComplaint(approveObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllComplaint();
      $('#PROCESS').modal('hide');
      $('.nav-tabs a[href="#tab-2"]').tab('show');
      swal.fire('Success', 'Complaint Approved Successfully!', 'success')
      // this.snackBar.open("Complaint Approved Successfully!", 'Success', {
      //   duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Approving Complaint  Of Employee', 'error');
      // this.snackBar.open("Error while Approving Complaint  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async open_suspend() {
    // this.establishmentService.complaint_detail=element;
    // this.router.navigate(['/hrms/arr/suspension']);
    $('#PROCESS').modal('hide');
    $('.nav-tabs a[href="#tab-4"]').tab('show');
    let currDate = new Date().toISOString().slice(0, 10);
    this.suspensionCompObj['suspension_start_dt'] = currDate;
    await this.getFixedPayDetails(this.suspensionCompObj['emp_id']);
  }

  popup_cancel() {
    $('.nav-tabs a[href="#tab-1"]').tab('show');
  }

  async forwardComplaint() {

    if (
      this.forObj['note'] == undefined || this.forObj['note'] == null || this.forObj['note'] == '' ||
      this.forObj['user_id'] == undefined || this.forObj['user_id'] == null || this.forObj['user_id'] == '' ||
      this.forObj['role_desc'] == undefined || this.forObj['role_desc'] == null || this.forObj['role_desc'] == '' ||
      this.forObj['module_cd'] == undefined || this.forObj['module_cd'] == null || this.forObj['module_cd'] == '' ||
      this.forObj['id'] == undefined || this.forObj['id'] == null || this.forObj['id'] == ''
    ) {
      swal.fire("Warning", "* Field Required", 'warning')
      return;
    }

    if (this.forObj['user_id'] == null || this.forObj['user_id'] == undefined || this.forObj['user_id'] == '') {
      Swal.fire("Info", 'User Id  can not be null', 'warning')
      return
    }
    let forObj1 :any= new Object();
    forObj1['b_acct_id'] = this.b_acct_id;
    forObj1['user_id_by'] = this.erpUser.user_id;
    forObj1['user_id_to'] = this.forObj['user_id']
    forObj1['complaint_id'] = this.forObj['id'];
    forObj1['complaint_workflow_status'] = 'FORWARD';
    // forObj1['forward_to'] = this.forObj['emp_id']+'-'+this.forwardEmpNameId;
    // change cause of the emp id not show in the list
    forObj1['forward_to'] = this.forwardEmpNameId;

    var resp = await this.establishmentService.forwardComplaint(forObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllComplaint();
      $('#FORWARD').modal('hide');
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire("Success", "Complaint Forward Successfully!", "success");
      // this.snackBar.open("Complaint Forward Successfully!", 'Success', {
      //   duration: 5000
      // });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Forwarding Complaint  Of Employee", 'Error', {
        duration: 5000
      });
    }
  }
  async closeComplaint() {
    let closeObj1 :any= new Object();
    closeObj1['b_acct_id'] = this.b_acct_id;
    closeObj1['update_user_id'] = this.erpUser.user_id;
    closeObj1['complaint_id'] = this.closeObj['id'];
    closeObj1['complaint_status'] = 'CLOSED'
    closeObj1['close_by_user_id'] = this.erpUser.user_id;
    closeObj1['closing_note'] = this.closeObj['closing_note'];
    closeObj1['user_id_by'] = this.erpUser.user_id;
    var resp = await this.establishmentService.closeComplaint(closeObj1);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllComplaint();
      $('#CLOSE').modal('hide');
      $('.nav-tabs a[href="#tab-1"]').tab('show');
      swal.fire('Success', "Complaint Closed Successfully! ", 'success');
      // this.snackBar.open("Complaint Closed Successfully!", 'Success', {
      // duration: 5000
      // });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Closing Complaint  Of Employee", 'Error', {
        duration: 5000
      });
    }
  }
  async selectedModule(module_cd:any) {
    await this.getcurrentroleswithresources(module_cd);
    await this.getCurrentRole(module_cd);

  }

  async selectedRole(role_desc:any, module_cd:any) {
    await this.getCurrentUsers(role_desc, module_cd);
  }

  async submitComplainMessage() {
    swal.fire({
      title: 'Are you Sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      // cancelButtonText: 'No, Take it!'
    }).then(async (result) => {
      if (result.value) {
        await this.submitComplaint()
      }
    })
  }

  async submitComplaint() {
    if (this.complaintObj['emp_id'] == null || this.complaintObj['emp_id'] == undefined || this.complaintObj['emp_id'] == '') {
      Swal.fire("Info", 'Employee Name can not be null', 'warning')
      return
    }
    if (this.complaintObj['complaint_type_code'] == null || this.complaintObj['complaint_type_code'] == undefined || this.complaintObj['complaint_type_code'] == '') {
      Swal.fire("Info", 'Complaint Type  can not be null', 'warning')
      return
    }
    if (this.complaintObj['complaint_dt'] == null || this.complaintObj['complaint_dt'] == undefined || this.complaintObj['complaint_dt'] == '') {
      Swal.fire("Info", 'Complaint Date can not be null', 'warning')
      return
    }
    if (this.complaintObj['nature_of_complaint'] == null || this.complaintObj['nature_of_complaint'] == undefined || this.complaintObj['nature_of_complaint'] == '') {
      Swal.fire("Info", 'Nature of Complaint can not be null', 'warning')
      return
    }
    if (this.complaintObj['complainer_name'] == null || this.complaintObj['complainer_name'] == undefined || this.complaintObj['complainer_name'] == '') {
      Swal.fire("Info", 'Complainer Name can not be null', 'warning')
      return
    }
    if (this.complaintObj['complainer_mob_no'] == null || this.complaintObj['complainer_mob_no'] == undefined || this.complaintObj['complainer_mob_no'] == '') {
      Swal.fire("Info", 'Complainer Mobile Number can not be null', 'warning')
      return
    }
    // var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // if (phoneno.test(this.complaintObj['complainer_mob_no'])){
    //   Swal.fire("Info",'Complainer Mobile Number in 10 digit','warning')
    //     return
    // }

    if (this.complaintObj['complaint_desc'] == null || this.complaintObj['complaint_desc'] == undefined || this.complaintObj['complaint_desc'] == '') {
      Swal.fire("Info", 'Complaint Description can not be null', 'warning')
      return
    }
    if (this.complaintObj['support_doc_type'] == null || this.complaintObj['support_doc_type'] == undefined || this.complaintObj['support_doc_type'] == '') {
      Swal.fire("Info", 'Support Document can not be null', 'warning')
      return
    }

    this.complaintObj['b_acct_id'] = this.b_acct_id;
    this.complaintObj['complaint_status'] = 'REGISTERED';
    this.complaintObj['create_user_id'] = this.erpUser.user_id;

    for (let i = 0; i < this.allEmplyees.length; i++) {
      if (this.complaintObj['emp_id'] == this.allEmplyees[i]['emp_id']) {
        this.complaintObj['arr_id'] = this.allEmplyees[i]['arr_id'];
        break;
      }
    }

    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      this.complaintObj['document_name'] = this.uploader.queue[0].some.name;
      this.complaintObj['document_type_code'] = 'COMPLAINT DOC';
      const params = JSON.stringify(this.complaintObj);
      this.uploader.queue[0].url = this.httpUrl + '/hr/establishment_info/complaint/createComplaint' + params;
      this.uploader.queue[0].upload();

      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        if (!response.error) {
          this.uploader.queue = [];
          await this.getAllComplaint();
          this.spinner.hide();
          this.complaintObj = {};
          $("#docUpload").val("");
          this.mobile_no_validation = false;
          this.mo_no_len = ''
          swal.fire('Success', 'Complaint Added Successfully', 'success')
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Applying Leave', 'error');
        }
      };
    }
    else {
      this.spinner.show();
      var resp = await this.establishmentService.createCompWithoutdoc(this.complaintObj);
      if (resp['error'] == false) {
        await this.getAllComplaint();
        $("#docUpload").val("");
        this.complaintObj = {};
        this.mobile_no_validation = false;
        this.mo_no_len = ''
        swal.fire('Success', 'Complaint Added Successfully', 'success')
      } else {
        this.spinner.hide()
        swal.fire('Error', 'Error while Applying Leave', 'error');

      }
    }
  }
  async updateComplaint() {
    if (this.updateComplaintObj['complaint_type_code'] == null || this.updateComplaintObj['complaint_type_code'] == undefined || this.updateComplaintObj['complaint_type_code'] == '') {
      Swal.fire("Info", 'Complaint Type can not be null', 'warning')
      return
    }
    if (this.updateComplaintObj['nature_of_complaint'] == null || this.updateComplaintObj['nature_of_complaint'] == undefined || this.updateComplaintObj['nature_of_complaint'] == '') {
      Swal.fire("Info", 'Nature of Complaint can not be null', 'warning')
      return
    }
    if (this.updateComplaintObj['complainer_name'] == null || this.updateComplaintObj['complainer_name'] == undefined || this.updateComplaintObj['complainer_name'] == '') {
      Swal.fire("Info", 'Complainer Name can not be null', 'warning')
      return
    }
    // var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // if (phoneno.test(this.updateComplaintObj['complainer_mob_no'])){
    //   Swal.fire("Info",'Complainer Mobile Number in 10 digit','warning')
    //     return
    // }
    if (this.updateComplaintObj['complainer_mob_no'] == null || this.updateComplaintObj['complainer_mob_no'] == undefined || this.updateComplaintObj['complainer_mob_no'] == '') {
      Swal.fire("Info", 'Complainer Mobile No can not be null or greater tha 10 digit', 'warning')
      return
    }
    if (this.updateComplaintObj['complaint_desc'] == null || this.updateComplaintObj['complaint_desc'] == undefined || this.updateComplaintObj['complaint_desc'] == '') {
      Swal.fire("Info", 'Complaint Description can not be null', 'warning')
      return
    }
    if (this.updateComplaintObj['support_doc_type'] == null || this.updateComplaintObj['support_doc_type'] == undefined || this.updateComplaintObj['support_doc_type'] == '') {
      Swal.fire("Info", 'Support Document Type can not be null', 'warning')
      return
    }
    this.updateComplaintObj['b_acct_id'] = this.b_acct_id;

    this.updateComplaintObj['update_user_id'] = this.erpUser.user_id;

    this.spinner.show();
    if (this.uploader.queue.length > 0) {
      const formData = new FormData();
      formData.append('image', this.selectedFile, this.selectedFile.name);
      this.updateComplaintObj['document_name'] = this.uploader.queue[0].some.name;
      this.updateComplaintObj['document_type_code'] = 'COMPLAINT DOC';
      const params = JSON.stringify(this.updateComplaintObj);
      this.uploader.queue[0].url = this.httpUrl + '/hr/establishment_info/complaint/updateComplaintDetail' + params;
      this.uploader.queue[0].upload();

      this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
        if (!response.error) {
          this.uploader.queue = [];
          await this.getAllComplaint();
          this.spinner.hide();
          this.updateComplaintObj = {};
          $('.nav-tabs a[href="#tab-1"]').tab('show');
          swal.fire('Success', 'Complaint Updated Successfully', 'success')
        } else {
          this.spinner.hide()
          swal.fire('Error', 'Error while Applying Leave', 'error');
        }
      };
    }
    else {
      var resp = await this.establishmentService.updateComplaintDetail1(this.updateComplaintObj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllComplaint();
        this.updateComplaintObj = {}
        $('.nav-tabs a[href="#tab-1"]').tab('show');
        swal.fire("Success", " Complaint Updated Successfully!", 'success');
        return;
      } else {
        this.spinner.hide();
        swal.fire("Error", "Error while Adding Complaint  Of Employee", 'error');
        return;


      }
    }
  }
  async changeStatus(element:any) {
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element['id'];
    obj['complaint_status'] = "UNDER ENQUIRY";


    this.spinner.show();
    var resp = await this.establishmentService.changeComplaintStatus(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllComplaint();
      swal.fire("Success", 'Complaint Updated Successfully!', 'success');
      //
      // this.snackBar.open("Complaint Updated Successfully!", 'Success', {
      // duration: 5000
      // });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while Adding Complaint  Of Employee", 'Error', {
        duration: 5000
      });
    }
  }

  async submitCompSuspension() {
    if (this.suspensionCompObj['emp_id'] == null || this.suspensionCompObj['emp_id'] == undefined || this.suspensionCompObj['emp_id'] == '') {
      Swal.fire("Info", 'Employee Name can not be null', 'warning')
      return
    }
    if (this.suspensionCompObj['order_id'] == null || this.suspensionCompObj['order_id'] == undefined || this.suspensionCompObj['order_id'] == '') {
      Swal.fire("Info", 'Order can not be null', 'warning')
      return
    }
    if (this.suspensionCompObj['suspension_start_dt'] == null || this.suspensionCompObj['suspension_start_dt'] == undefined || this.suspensionCompObj['suspension_start_dt'] == '') {
      Swal.fire("Info", 'Suspension Start Date can not be null', 'warning')
      return
    }
    if (this.suspensionCompObj['suspension_start_dt'] < this.suspensionCompObj['complaint_dt']) {
      Swal.fire("Info", 'Suspension Start Date greater than Complaint Date', 'warning')
      return
    }
    if (this.suspensionCompObj['suspension_note'] == null || this.suspensionCompObj['suspension_note'] == undefined || this.suspensionCompObj['suspension_note'] == '') {
      Swal.fire("Info", 'Suspension Note can not be null', 'warning')
      return
    }
    for (let i = 0; i < this.payCompCount; i++) {
      if (this.payCompObj[i] <= 100 && this.payCompObj[i] >= 0) {
        if (this.payCompObj[i] == 0 || this.payCompObj[i] == null || this.payCompObj[i] == undefined) {
          this.inputPayCompArr.push({
            pay_component_code: this.payCompArr[i]['pay_component_code'],
            arr_id: this.payCompArr[i]['arr_id'],
            pay_id: this.payCompArr[i]['pay_id'],
            pay_code: this.payCompArr[i]['pay_code'],
            fraction_per: this.payCompObj[i],
            old_rate: this.payCompArr[i]['rate'],
            new_rate: this.payCompArr[i]['rate'],
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
        else if (this.payCompObj[i] == 100) {
          this.inputPayCompArr.push({
            pay_component_code: this.payCompArr[i]['pay_component_code'],
            arr_id: this.payCompArr[i]['arr_id'],
            pay_id: this.payCompArr[i]['pay_id'],
            pay_code: this.payCompArr[i]['pay_code'],
            fraction_per: this.payCompObj[i],
            old_rate: this.payCompArr[i]['rate'],
            new_rate: 0,
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
        else {
          this.inputPayCompArr.push({
            pay_component_code: this.payCompArr[i]['pay_component_code'],
            arr_id: this.payCompArr[i]['arr_id'],
            pay_id: this.payCompArr[i]['pay_id'],
            pay_code: this.payCompArr[i]['pay_code'],
            fraction_per: this.payCompObj[i],
            old_rate: this.payCompArr[i]['rate'],
            new_rate: ((this.payCompArr[i]['rate'] * this.payCompObj[i]) / 100).toFixed(2),
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    for (let i = 0; i < this.dedCompCount; i++) {
      if (this.payCompObj[i] <= 100 && this.payCompObj[i] >= 0) {
        if (this.dedCompObj[i] == 0 || this.dedCompObj[i] == null || this.dedCompObj[i] == undefined) {
          this.inputDedCompArr.push({
            pay_component_code: this.dedCompArr[i]['pay_component_code'],
            arr_id: this.dedCompArr[i]['arr_id'],
            pay_id: this.dedCompArr[i]['pay_id'],
            pay_code: this.dedCompArr[i]['pay_code'],
            fraction_per: this.dedCompObj[i],
            old_rate: this.dedCompArr[i]['rate'],
            new_rate: this.dedCompArr[i]['rate'],
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
        else if (this.dedCompObj[i] == 100) {
          this.inputDedCompArr.push({
            pay_component_code: this.dedCompArr[i]['pay_component_code'],
            arr_id: this.dedCompArr[i]['arr_id'],
            pay_id: this.dedCompArr[i]['pay_id'],
            pay_code: this.dedCompArr[i]['pay_code'],
            fraction_per: this.dedCompObj[i],
            old_rate: this.dedCompArr[i]['rate'],
            new_rate: 0,
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
        else {
          this.inputDedCompArr.push({
            pay_component_code: this.dedCompArr[i]['pay_component_code'],
            arr_id: this.dedCompArr[i]['arr_id'],
            pay_id: this.dedCompArr[i]['pay_id'],
            pay_code: this.dedCompArr[i]['pay_code'],
            fraction_per: this.dedCompObj[i],
            old_rate: this.dedCompArr[i]['rate'],
            new_rate: ((this.dedCompArr[i]['rate'] * this.dedCompObj[i]) / 100).toFixed(2),
            create_user_id: this.erpUser.user_id,
            employee_current_type_code: 'SUSPENDED',
            emp_id: this.suspensionCompObj['emp_id'],
            order_id: this.suspensionCompObj['order_id'],
            suspension_start_dt: this.suspensionCompObj['suspension_start_dt'],
            suspension_note: this.suspensionCompObj['suspension_note'],
          });
        }
      }
      else {
        Swal.fire("Error", 'Pay Component Fraction can not greater than 100 or less than 0', 'error')
        return
      }
    }
    //deepankar
    this.spinner.show()
    let suspensionCompObj1:any = new Object();
    suspensionCompObj1['b_acct_id'] = this.b_acct_id;
    suspensionCompObj1['complaint_id'] = this.suspensionCompObj['id'];
    suspensionCompObj1['complaint_status'] = 'SUSPENDED';
    suspensionCompObj1['update_user_id'] = this.erpUser.user_id;
    suspensionCompObj1['suspend_by_user_id'] = this.erpUser.user_id;
    suspensionCompObj1['user_id_by'] = this.erpUser.user_id;
    suspensionCompObj1['pay'] = this.inputPayCompArr;
    suspensionCompObj1['ded'] = this.inputDedCompArr;
    // this.suspensionObj['b_acct_id'] = this.b_acct_id;
    // this.suspensionObj['create_user_id'] = this.erpUser.user_id;
    // this.suspensionObj['employee_current_type_code'] = 'SUSPENDED'
    var resp = await this.establishmentService.suspendCompEmployee(suspensionCompObj1);
    if (resp['error'] == false) {
      await this.getAllComplaint();
      this.spinner.hide();
      this.suspensionCompObj = {};
      this.payCompArr = [];
      this.dedCompArr = [];
      swal.fire("Success", "Suspension  Added Successfully!", 'success');

      // this.snackBar.open("Suspension  Added Successfully!", 'Success', {
      // duration: 5000
      // });
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Adding Suspension  Of Employee', 'error')
      // this.snackBar.open("Error while Adding Suspension  Of Employee", 'Error', {
      //   duration: 5000
      // });
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  async addEmp() {
    this.mobile_no_validation = false;
    this.mo_no_len = '';
    this.complaintObj['complainer_mob_no'] = ''
  }



  mobile_no_validation = false
  mo_no_len = ''



  async chekMo(value:any) {
    // console.log("fun call--",value)
    //   console.log("fun before assign ",this.complaintObj['complainer_mob_no']);
    this.complaintObj['complainer_mob_no'] = value;
    //   console.log("after assign ",this.complaintObj['complainer_mob_no']);
    if (value.length < 10) {
      this.mobile_no_validation = false
      this.mo_no_len = '[' + value.length + ']'
    }
    if (value.length == 10) {
      this.mo_no_len = value.length

      this.mobile_no_validation = true;
      // console.log(" ng value = ", this.complaintObj['complainer_mob_no'].slice(0, 10))
      // console.log(" this isth getting value ", value.slice(0,10))
    }
    else if (value.length > 10) {
      this.mo_no_len = value.length
      this.complaintObj['complainer_mob_no'] = this.complaintObj['complainer_mob_no'].slice(0, 10)
      value.slice(0, 10)
      swal.fire("Info", '10 Digit Complite', 'info');
      return
    }
  }



}
