import { NgxSpinnerService } from 'ngx-spinner'
import { MasterDataService } from '../service/master-data.service'
import { TenderService } from '../service/tender.service'
import { Component, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MainService } from '../service/main.service';
import { DeviationService } from '../service/deviation.service'
import { ApproveService } from '../service/approve.service'
import { FilterService } from '../service/filter.service';
import * as pdfMake from "pdfmake/build/pdfmake";
declare var $: any;
import Swal from 'sweetalert2';
import { EmbService } from '../service/emb.service'
import { CommonDataService } from '../common _services/common-data.service'
@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.css']
})
export class DevComponent implements OnInit {


  constructor(private embService:EmbService,public CommonDataService:CommonDataService,public filterService: FilterService, private DeviationService: DeviationService, private approveService: ApproveService, private spinner: NgxSpinnerService, public mainService: MainService,
    private MSS: MasterDataService, private tenderService: TenderService) { }
  ebillUser:any;

  b_acct_id:any;
  allWork:any = []
nonsoritem = [{code:'DSR Item'},{code:'Local item'}]
  boqItems = [];
  lastEmb = [];
  lastEmbDetails:any = {}
  stepperIndex = 0;
  datasource:any;
  datasource1:any ;
  datasource2:any ;
  datasource3L:any; 
  datasource3:any

  l_dev_flag=false;
  p_dev_flag=true;
  pp_dev_flag=true;
  
  c_dev_flag=false;
  u_dev_flag=false;

  displayedColumns: string[] = ['id', 'sno', 'dev_dt', 'dev_type',
    'dev_quantity']
  displayedColumns1: string[] = ['sno', 'desc', 'quantity', 'curr_quantity', 'new_quantity', 'less', 'excess', 'remark']
  displayedColumns2:string[]=['sno', 'desc','boq_qty','deviation_change','Final_qty','action']
  displayedColumns3: string[] = ['sno', 'desc', 'quantity', 'curr_quantity', 'new_quantity', 'less', 'excess', 'remark']

  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild('sortCol3', { static: false }) sortCol3!: MatSort;
  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol2', { static: false }) sortCol2!: MatSort;
  @ViewChild('paginator3', { static: false }) paginator3!: MatPaginator;
  @ViewChild('sortCol4', { static: false }) sortCol4!: MatSort;
  @ViewChild('paginator4', { static: false }) paginator4!: MatPaginator;
  @ViewChild('sortCol5', { static: false }) sortCol5!: MatSort;

  allProject:any;
  allUnit:any;
  today:any
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.allProject = this.mainService.codeValueTechObj['EMB001'];
    this.allUnit = this.mainService.codeValueTechObj['EMB009'];
    await this.getAllHeadRecord()
    this.today = new Date().toISOString().slice(0, 10);
    await this.getAllWorkInfo();
    this.changeProject()
    // await this.getAllApprovalHier()
    // await this.getAllApprovalStatus()
  }
  selectedTender:any;
  selectedProject:any;
  selectedWork:any;
  async getTenderId() {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork }
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'][resp['data'].length - 1] != undefined) {
        this.selectedTender = resp['data'][resp['data'].length - 1];
      }
      else {
        this.selectedTender = {}
      }
      await this.getItems();
      await this.getAllDEVList(this.selectedTender['tender_id']);
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error")
    }
  }
  

  async getTenderIdtab2() {
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, work_id: this.selectedWork }
    var resp = await this.tenderService.getBoq(JSON.stringify(obj));
    if (resp['error'] == false) {
      if (resp['data'][resp['data'].length - 1] != undefined) {
        this.selectedTender = resp['data'][resp['data'].length - 1];
      }
      else {
        this.selectedTender = {}
      }
      await this.getItems();
      await this.getpurposedDEVList(this.selectedTender['tender_id']);
    } else {
      Swal.fire("Oops", "Error while getting tender information", "error")
    }
  }
 async gotolist(){
    this.l_dev_flag=true;
    this.p_dev_flag=false;
    this.pp_dev_flag=false;
    this.c_dev_flag=false;
    this.u_dev_flag=false;
    this.allProject = this.mainService.codeValueTechObj['EMB001'];
    this.allUnit = this.mainService.codeValueTechObj['EMB009'];
    await this.getAllHeadRecord()
    this.today = new Date().toISOString().slice(0, 10);
    await this.getAllWorkInfo();
    this.changeProject()


  }
  gotocrete()
  {
    this.refresh();
    this.l_dev_flag=false;
    this.p_dev_flag=false;
    this.pp_dev_flag=false;
    this.c_dev_flag=true;
    this.u_dev_flag=false;

  }


  aprroved(){
    this.refresh();
    this.l_dev_flag=true;
    this.p_dev_flag=true;
    this.pp_dev_flag=false;
    this.c_dev_flag=false;
    this.u_dev_flag=false;
  }


  propos(){
    this.refresh();
    this.l_dev_flag=false;
    this.p_dev_flag=true;
    this.pp_dev_flag=true;
    this.c_dev_flag=false;
    this.u_dev_flag=false;
  }

 

  changeitem(){
    if(this.sorObj['item_type'] == 'Local item' ){
      this.sorObj['over_under'] = 'ATPR'
      this.sorObj['over_under_per'] = 0
    }
    
  }
  changeoverunder(){
    if(this.sorObj['over_under'] == 'ATPR' ){
      this.sorObj['over_under_per'] = 0
    }
  }

  updatesoritem(element:any){
    this.sorObj = element
  }
  updatenonsoritem(element:any){
    this.sorObj = element
  }
  updatetab4SorItem(){
for (let i = 0; i < this.sorItemList.length; i++) {
  if(this.sorObj['sno'] == this.sorItemList[i]['sno']){
    this.sorItemList[i] = this.sorObj
    this.sorObj = {item_type:'DSR Item'}
    break;
  }
  
}
  }
  updatetab4nonSorItem(){
    for (let i = 0; i < this.sorItemList.length; i++) {
      if(this.sorObj['sno'] == this.sorItemList[i]['sno']){
        this.sorItemList[i] = this.sorObj
        this.sorObj = {item_type:'DSR Item'}
        break;
      }
      
    }
      }
async updatedeviate(){
        let idarr = []
        for (let i = 0; i < this.deviationList.length; i++) {
         idarr.push(this.deviationList[i]['id'])
          
        }
        let data = []
        for (let i = 0; i < this.devupdate_arr.length; i++) {
          var obj = {
            sno: this.devupdate_arr[i]['sno'],
            tender_id: this.selectedTender['tender_id'],
            dev_dt: this.today,
            dev_type: 'A',
            dev_id: this.devupdate_arr[i]['dev_id'],
            dev_quantity: 0.0,
            is_comp: 0,
            create_user_id: this.ebillUser['user_id'],
            status:'GENERATED',
            b_acct_id: this.b_acct_id,
            remark: this.table2_data[i]['remark']
          }
          if (this.devupdate_arr[i]['less'] != 0) {
            obj['dev_type'] = 'S';
            obj['dev_quantity'] = this.devupdate_arr[i]['less'];
          } else {
            obj['dev_type'] = 'A';
            obj['dev_quantity'] = this.devupdate_arr[i]['excess'];
          }
  
          data.push(obj)
        }
        this.last_sno = 0;
        for (let i = 0; i < this.AllItem.length; i++) {
          if (this.last_sno < this.AllItem[i]['sno']) {
            this.last_sno = this.AllItem[i]['sno']
          }
        }
        let sor_data = []
        for (let i = 0; i < this.nonsorItemList.length; i++) {
          
          var obj2=Object.assign({},this.nonsorItemList[i]);
          obj2['tender_id']=this.selectedTender['tender_id']
          obj2['curr'] = 'INR';
          obj2['sortype'] = 'N';
          obj2['sno'] = this.last_sno+i+1;
          this.last_sno = this.last_sno+i+1;
          obj2['b_acct_id'] = this.b_acct_id;
          obj2['is_extra_item'] = 1;
          obj2['create_user_id'] = this.ebillUser['user_id'];
          obj2['status'] = 'GENERATED';
          sor_data.push(obj2)
        }
        for (let i = 0; i < this.sorItemList.length; i++) {
          
          var obj2=Object.assign({},this.sorItemList[i]);
          obj2['tender_id']=this.selectedTender['tender_id']
          obj2['curr'] = 'INR';
          obj2['sortype'] = 'S';
          obj2['sno'] = this.last_sno+i+1;
          this.last_sno = this.last_sno+i+1;
          obj2['b_acct_id'] = this.b_acct_id;
          obj2['is_extra_item'] = 1;
          obj2['create_user_id'] = this.ebillUser['user_id'];
          obj2['status'] = 'GENERATED';
          sor_data.push(obj2)
        }
        
        let obj_dev = {b_acct_id:this.b_acct_id,id:idarr,data:data,data_sor:sor_data}
        console.log(obj_dev,this.nonsorItemList,this.devupdate_arr)
        var resp = await this.DeviationService.updatedeviationnew(obj_dev);
        if (resp['error'] == false) {
          await this.getItems();
          await this.getAllDEVList(this.selectedTender['tender_id']);
          Swal.fire("Success", "Deviation Updated", "success");
          this.propos();
        } else {
          Swal.fire("Oops", "Some Error occurred during deviation", "error");
        }
      }
  assignedWorks:any = {}
  async getAllHeadRecord() {
    this.spinner.show();
    var work_ids:any=[]
    work_ids=Object.values(this.CommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }
  }
  async getAllWorkInfo() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    var resp = await this.MSS.getWorkInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      var dt = [];
      var temp = resp['data'];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]['id']] != undefined) {
          dt.push(temp[i])
        }

      }
      this.allWork = dt;
      for (var i = 0; i < this.allWork.length; i++) {
        this.allWork[i]['work_des'] = this.allWork[i]['work_order_no'] + " - " + this.allWork[i]['work_order_name'];
        this.workObj[this.allWork[i]['id']] = this.allWork[i]
      }
      this.spinner.hide()

    } else {
      this.spinner.hide()
    }
  }
  allWList:any = []
  changeProject() {
    this.allWList = Object.assign([], [])
    for (let i = 0; i < this.allWork.length; i++) {
        this.allWList.push(this.allWork[i])
    }
  }
  deviationList:any = []
  devidarr:any = []
  selecteddevid:any
  async getAllDEVList(tender_id:any) {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['tender_id'] = tender_id;
    var resp = await this.DeviationService.getdev(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.deviationList = resp['data']
      var arr:any = []
      this.devidarr = []
      console.log(this.deviationList)
      for (let i = 0; i < this.deviationList.length; i++) {
        if (!arr.includes(this.deviationList[i]['dev_dt'])) {
          this.devidarr.push({ dev_id: this.deviationList[i]['dev_dt'] })
          arr.push(this.deviationList[i]['dev_dt'])
        }
      }

      this.devidarr.sort().reverse();
      this.spinner.hide()

    } else {
      this.spinner.hide()
    }
  }
  devlist:any = {}
  async getAllDEVListprint(tender_id:any) {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['tender_id'] = tender_id;
    var resp = await this.DeviationService.getdev(JSON.stringify(obj));
    if (resp['error'] == false) {
      for (let i = 0; i <  resp['data'].length; i++) {
        this.devlist[resp['data'][i]['sno']] =  resp['data'][i]['sortype']
        
      }
      this.spinner.hide()

    } else {
      this.spinner.hide()
    }
  }
  dev_list:any =[]
  dev_proposedarr:any = []
  async getpurposedDEVList(tender_id:any) {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['tender_id'] = tender_id;
    var resp = await this.DeviationService.getgendev(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.deviationList = resp['data']
      this.dev_list = resp['data']
      var arr = []
      this.devidarr = []
      console.log(this.deviationList)
      this.dev_proposedarr = []
      for (let i = 0; i < this.dev_list.length; i++) {
        this.dev_proposedarr.push(this.dev_list[i]['sno'])
      }
      this.datasource2 = new MatTableDataSource(this.deviationList);
      this.datasource2.sort = this.sortCol4;
      this.datasource2.paginator = this.paginator3;

      // this.devidarr.sort().reverse();data:
      this.spinner.hide()

    } else {
      this.spinner.hide()
    }
  }

  async Approved(){
    this.spinner.show()
    let data=[];
    let sor_data=[]
    let id =[]
    let obj={}
    for( let i=0;i<this.deviationList.length;i++){
        if(this.deviationList[i].sortype== null)
        {
          let obj1=this.deviationList[i].data
          obj1= JSON.parse(obj1)
          
          data.push(obj1)
        }
        else {
          let obj2=this.deviationList[i].data
          obj2= JSON.parse(obj2)
         
          sor_data.push(obj2)

        }
      id.push(this.deviationList[i].id);
      
    }

    obj={"b_acct_id":this.b_acct_id,"data":data,"sor_data":sor_data,"id":id, emb_mode:this.workObj[this.selectedWork]['emb_mode']}
  console.log(obj);
  let resp = await this.DeviationService.Approvedev(obj)
  if(resp['error']==false){
    this.spinner.hide()
    Swal.fire("Success", "Approved Successfully", "success");
    await this.getTenderIdtab2()
  }
else{
  this.spinner.hide()
  Swal.fire('Error',resp['error'], 'error')
}


    
  }

  changedevid() {
    var arr = []
    for (let i = 0; i < this.deviationList.length; i++) {
      if (this.deviationList[i]['dev_dt'] == this.selecteddevid) {
        arr.push(this.deviationList[i])
      }

    }
    this.datasource = new MatTableDataSource(arr);
    this.datasource.sort = this.sortCol3;
    this.datasource.paginator = this.paginator2;
  }
  refresh() {
    this.selectedWork = undefined
    this.datasource1 = new MatTableDataSource([]);
      this.datasource1.sort = this.sortCol2;
      this.datasource1.paginator = this.paginator1;
      this.datasource = new MatTableDataSource([]);
    this.datasource.sort = this.sortCol3;
    this.datasource.paginator = this.paginator2;
    this.datasource2 = new MatTableDataSource([]);
    this.datasource2.sort = this.sortCol4;
    this.datasource2.paginator = this.paginator3;
    this.datasource3 = new MatTableDataSource([]);
    this.datasource3.sort = this.sortCol5;
    this.datasource3.paginator = this.paginator4;
  }
  async changeWorkInTab2() {
    await this.getTenderId();
    await this.getTenderIdtab2()
    await this.getItems();
    
  }

  async open_update() {
    // await this.getTenderIdtab2();
    await this.getItemstab4();
    this.l_dev_flag=false;
    this.p_dev_flag=false;
    this.pp_dev_flag=false;
    this.c_dev_flag=false;
    this.u_dev_flag=true;
  }

  AllItem:any = []
  boqObj:any = {};
  table2_data:any = [];

  async getItems() {
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    this.spinner.show();
    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.AllItem = resp.data;
      console.log(this.AllItem)
      this.table2_data = [];
      for (var i = 0; i < this.AllItem.length; i++) {
        if(!this.dev_proposedarr.includes(this.AllItem[i]['sno'])){
        var obj1:any = new Object();
        obj1['sno'] = this.AllItem[i]['sno'];
        obj1['item_desc'] = this.AllItem[i]['item_desc'];
        obj1['quantity'] = Number((this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']).toFixed(2));
        obj1['curr_quantity'] = this.AllItem[i]['already_measured'];
        obj1['remark'] = '';
        this.table2_data.push(obj1);
        }
        this.boqObj[this.AllItem[i]['sno']] = this.AllItem[i];

      }
      var resp1 = await this.tenderService.getLastEMB(JSON.stringify(obj));
      console.log(resp1.data);
      if (resp1['error'] == false) {
        for (let i = 0; i < resp1.data.length; i++) {
          for (let j = 0; j < this.table2_data.length; j++) {
            if (resp1.data[i]['sno'] == this.table2_data[j]['sno']) {
              this.table2_data[j]['curr_quantity'] = this.table2_data[j]['curr_quantity'] + resp1.data[i]['q']
            }
          }
        }
      }
      
      for (let j = 0; j < this.table2_data.length; j++) {
        let flag = false
        if(this.table2_data[j]['quantity'] < 0){
          flag = true
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
        }
        if (this.table2_data[j]['quantity'] < this.table2_data[j]['curr_quantity']) {
          this.table2_data[j]['new_quantity'] = this.table2_data[j]['curr_quantity'];
        } else {
          this.table2_data[j]['new_quantity'] = this.table2_data[j]['quantity'];
        }
        if(flag){
          this.table2_data[j]['new_quantity'] = (-1)*(this.table2_data[j]['new_quantity'])
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
        }
      }



      for (let j = 0; j < this.table2_data.length; j++) {
        if (this.table2_data[j]['quantity'] < this.table2_data[j]['new_quantity']) {
          this.table2_data[j]['less'] = 0;
          this.table2_data[j]['excess'] = this.table2_data[j]['new_quantity'] - this.table2_data[j]['quantity'];
        } else {
          this.table2_data[j]['less'] = 0;
          this.table2_data[j]['excess'] = 0;
        }
      }


      console.log(this.AllItem);
      this.datasource1 = new MatTableDataSource(this.table2_data);
      this.datasource1.sort = this.sortCol2;
      this.datasource1.paginator = this.paginator1;
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting BOQ Items", "error");
    }


  }
  devupdate_arr:any = []
  async getItemstab4() {
    var obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    this.spinner.show();
    var resp = await this.tenderService.getItem(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.AllItem = resp.data;
      console.log(this.AllItem)
      this.table2_data = [];
      for (var i = 0; i < this.AllItem.length; i++) {
        var obj1:any = new Object();
        obj1['sno'] = this.AllItem[i]['sno'];
        obj1['item_desc'] = this.AllItem[i]['item_desc'];
        obj1['quantity'] = Number((this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']).toFixed(2));
        obj1['curr_quantity'] = this.AllItem[i]['already_measured'];
        obj1['remark'] = '';
        this.table2_data.push(obj1);
        this.boqObj[this.AllItem[i]['sno']] = this.AllItem[i];
      }
      var resp1 = await this.tenderService.getLastEMB(JSON.stringify(obj));
      console.log(resp1.data);
      if (resp1['error'] == false) {
        for (let i = 0; i < resp1.data.length; i++) {
          for (let j = 0; j < this.table2_data.length; j++) {
            if (resp1.data[i]['sno'] == this.table2_data[j]['sno']) {
              this.table2_data[j]['curr_quantity'] = this.table2_data[j]['curr_quantity'] + resp1.data[i]['q']
            }
          }
        }
      }
      
      for (let j = 0; j < this.table2_data.length; j++) {
        let flag = false
        if(this.table2_data[j]['quantity'] < 0){
          flag = true
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
        }
        if (this.table2_data[j]['quantity'] < this.table2_data[j]['curr_quantity']) {
          this.table2_data[j]['new_quantity'] = this.table2_data[j]['curr_quantity'];
        } else {
          this.table2_data[j]['new_quantity'] = this.table2_data[j]['quantity'];
        }
        if(flag){
          this.table2_data[j]['new_quantity'] = (-1)*(this.table2_data[j]['new_quantity'])
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
        }
      }



      for (let j = 0; j < this.table2_data.length; j++) {
        if (this.table2_data[j]['quantity'] < this.table2_data[j]['new_quantity']) {
          this.table2_data[j]['less'] = 0;
          this.table2_data[j]['excess'] = this.table2_data[j]['new_quantity'] - this.table2_data[j]['quantity'];
        } else {
          this.table2_data[j]['less'] = 0;
          this.table2_data[j]['excess'] = 0;
        }
      }
      let arr = []
      this.sorItemList = []
      this.nonsorItemList = []
      for (let i = 0; i < this.deviationList.length; i++) {
        let obj = Object.assign({},this.deviationList[i])
        if(obj['sortype'] == 'S'){
            this.sorItemList.push(JSON.parse(obj['data']))
            continue;
        }else if(obj['sortype'] == 'N'){
          this.nonsorItemList.push(JSON.parse(obj['data']))
          continue;
        }
        obj['curr_quantity']  = 0
        var obj2 = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
        var resp1 = await this.tenderService.getLastEMB(JSON.stringify(obj2));
        console.log(resp1.data);
        if (resp1['error'] == false) {
          for (let i = 0; i < resp1.data.length; i++) {
           
              if (resp1.data[i]['sno'] == obj['sno']) {
                obj['curr_quantity'] = obj['curr_quantity'] + resp1.data[i]['q']
              }
            
          }
        }
        obj['item_desc'] = this.boqObj[obj['sno']]['item_desc']
        obj['quantity'] = this.boqObj[obj['sno']]['eff_quantity']+this.boqObj[obj['sno']]['already_measured']
        if(obj.dev_type=='A') {
          obj['excess'] = obj.dev_quantity
          obj['less'] = 0
          obj['new_quantity'] = this.boqObj[obj['sno']]['eff_quantity']+this.boqObj[obj['sno']]['already_measured']+obj.dev_quantity
      }
        else {
          obj['less'] = obj.dev_quantity
          obj['excess'] = 0
          obj['new_quantity'] = this.boqObj[obj['sno']]['eff_quantity']+this.boqObj[obj['sno']]['already_measured']-obj.dev_quantity
        }
        
        arr.push(obj)
        
      }
      console.log(this.AllItem);
      this.devupdate_arr = arr
      this.datasource3 = new MatTableDataSource(arr);
      this.datasource3.sort = this.sortCol5;
      this.datasource3.paginator = this.paginator4;
      $('.nav-tabs a[href="#tab-7-4"]').tab('show');
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting BOQ Items", "error");
    }


  }
  applyFilter2(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }

  selectedElement:any = {}
  openPopup(element:any) {
    this.selectedElement = element;
    this.selectedElement['less'] = 0;
    this.selectedElement['excess'] = 0;
    $('#A').modal('show');
  }

  async deviate() {
    let max_dev_no = 0;
    for (let i = 0; i < this.deviationList.length; i++) {
      if (this.deviationList[i]['dev_id'] >= max_dev_no && this.deviationList[i]['dev_id'] != null) {
        max_dev_no = this.deviationList[i]['dev_id']
      }
    }

    var final_obj:any = new Object();
    final_obj['data'] = [];
    final_obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.table2_data.length; i++) {
      if (this.table2_data[i]['less'] != 0 || this.table2_data[i]['excess'] != 0) {

        var obj = {
          sno: this.table2_data[i]['sno'],
          tender_id: this.selectedTender['tender_id'],
          dev_dt: this.today,
          dev_type: 'A',
          dev_id: max_dev_no + 1,
          dev_quantity: 0.0,
          is_comp: 0,
          create_user_id: this.ebillUser['user_id'],
          status:'GENERATED',
          b_acct_id: this.b_acct_id,
          remark: this.table2_data[i]['remark']
        }
        if (this.table2_data[i]['less'] != 0) {
          obj['dev_type'] = 'S';
          obj['dev_quantity'] = this.table2_data[i]['less'];
        } else {
          obj['dev_type'] = 'A';
          obj['dev_quantity'] = this.table2_data[i]['excess'];
        }

        final_obj['data'].push(obj)
      }

    }
    console.log(final_obj);

    if (final_obj['data'].length == 0) {
      return;
    }


    var resp = await this.DeviationService.createMultipledev(final_obj);
    if (resp['error'] == false) {
      this.changeWorkInTab2()
      Swal.fire("Success", "Deviation Added", "success");
      this.propos();
    } else {
      Swal.fire("Oops", "Some Error occurred during deviation", "error");
    }
  }


  //*******************************************SOR*********************************************** */
  sorObj:any = { item_type:'DSR Item'};
  last_sno=0;

  addsor() {
    this.last_sno = 0;
    for (let i = 0; i < this.AllItem.length; i++) {
      if (this.last_sno < this.AllItem[i]['sno']) {
        this.last_sno = this.AllItem[i]['sno']
      }
    }
    for (let i = 0; i < this.dev_list.length; i++) {
      if (this.last_sno < this.dev_list[i]['sno']) {
        this.last_sno = this.dev_list[i]['sno']
      }
      
    }
    this.last_sno=this.last_sno+1;
    console.log(this.last_sno);

    if (this.selectedTender['tender_id'] != undefined) {
      this.sorObj['tender_id'] = this.selectedTender['tender_id'];
      $('#SOR').modal('show');
    } else {
      Swal.fire("Oops", "Tender Id Not Selected!", "warning");
    }
  }
 updatesortab4() {
    
    if (this.selectedTender['tender_id'] != undefined) {
      this.sorObj['tender_id'] = this.selectedTender['tender_id'];
      $('#SORUPDATE').modal('show');
    } else {
      Swal.fire("Oops", "Tender Id Not Selected!", "warning");
    }
  }

  sorItemList:any=[];

  preGST = [{ code: 0, value: 'No' }, { code: 1, value: 'Yes' }];
  checkarr = ['item_desc','unit','rate','quantity','already_measured','part_rate','item_cd','is_pre_gst','over_under','over_under_per']
  checkobj = {'item_desc':'Item Description','unit':'Unit','rate':'Rate','quantity':'Quantity','already_measured':'Paid Last Quantity','part_rate':'Last Paid Rate','item_cd':'Item Code','is_pre_gst':'IS Pre GST Deduction Applicable','over_under':'Over Under','over_under_per':'Over Under Percentage (%)'}
async check(){
for (let i = 0; i < this.checkarr.length; i++) {
  if(this.sorObj[this.checkarr[i]] == null || this.sorObj[this.checkarr[i]] == undefined){
    Swal.fire("Oops", "Field marked with asterix are required to add this item", "error");
    return true;
    
  }
}
return true;
}

  async addSorItem(){
    
    if(await this.check()){
      return
    }
    console.log(this.sorObj)
    var obj=Object.assign({},this.sorObj);
    this.sorItemList.push(obj);
    this.sorObj={ item_type:'DSR Item'};
  }
  deleteSorItem(i:any){
    this.sorItemList.splice(i,1)
  }


  async submitsor() {
    let max_dev_no = 0;
    for (let i = 0; i < this.deviationList.length; i++) {
      if (this.deviationList[i]['dev_id'] >= max_dev_no && this.deviationList[i]['dev_id'] != null) {
        max_dev_no = this.deviationList[i]['dev_id']
      }
    }


    var final_obj:any=new Object();
    final_obj['b_acct_id']=this.b_acct_id;
    final_obj['data']=[]

    for(let i=0;i<this.sorItemList.length;i++){
      var obj=Object.assign({},this.sorItemList[i]);
      obj['tender_id']=this.selectedTender['tender_id']
      obj['curr'] = 'INR';
      obj['sortype'] = 'S';
      obj['sno'] = this.last_sno+i;
      obj['dev_id'] = max_dev_no + 1
      obj['b_acct_id'] = this.b_acct_id;
      obj['is_extra_item'] = 1;
      obj['create_user_id'] = this.ebillUser['user_id'];
      obj['status'] = 'GENERATED';
      final_obj['data'].push(obj)
    }
    
    console.log(final_obj)
    var resp = await this.DeviationService.createSOR(final_obj);
    if (resp['error'] == false) {
      this.changeWorkInTab2()
     /*  await this.getItems();
      await this.getAllDEVList(this.selectedTender['tender_id']); */

      this.sorItemList = []
      //await this.insertForApproval(this.sorObj['dev_id']);
      //await this.getAllApprovalStatus();
      this.sorObj = { item_type:'DSR Item'};
      $('#SOR').modal('hide');
      Swal.fire("Success", "SOR Item Added", "success");
    } else {
      Swal.fire("Oops", "Some Error occurred during sor item add", "error");
    }
  }



  ///**************************************Non Sor************************************** */
  addnonsor() {
    this.last_sno = 0;
    for (let i = 0; i < this.AllItem.length; i++) {
      if (this.last_sno < this.AllItem[i]['sno']) {
        this.last_sno = this.AllItem[i]['sno']
      }
    }
    for (let i = 0; i < this.dev_list.length; i++) {
      if (this.last_sno < this.dev_list[i]['sno']) {
        this.last_sno = this.dev_list[i]['sno']
      }
      
    }
    this.last_sno=this.last_sno+1;

    console.log(this.last_sno);
    // this.sorObj['sno'] = sno + 1;
    if (this.selectedTender['tender_id'] != undefined) {
      this.sorObj['tender_id'] = this.selectedTender['tender_id'];
      $('#NON-SOR').modal('show');
    } else {
      Swal.fire("Oops", "Tender Id Not Selected!", "warning");
    }
  }
  addnonsoruppdate() {
  
    // this.sorObj['sno'] = sno + 1;
    if (this.selectedTender['tender_id'] != undefined) {
      this.sorObj['tender_id'] = this.selectedTender['tender_id'];
      $('#NON-SORUPDATE').modal('show');
    } else {
      Swal.fire("Oops", "Tender Id Not Selected!", "warning");
    }
  }

  nonsorItemList:any=[];



  async addnonSorItem(){
    if(await this.check()){
      return
    }
    console.log(this.sorObj)
    var obj=Object.assign({},this.sorObj);
    this.nonsorItemList.push(obj);
    this.sorObj={ item_type:'DSR Item'};
  }
  deleteNonSorItem(i:any){
    this.nonsorItemList.splice(i,1)
  }
  
  async submitnonsor() {
    let max_dev_no = 0;
    for (let i = 0; i < this.deviationList.length; i++) {
      if (this.deviationList[i]['dev_id'] >= max_dev_no && this.deviationList[i]['dev_id'] != null) {
        max_dev_no = this.deviationList[i]['dev_id']
      }
    }
    var final_obj:any=new Object();
    final_obj['b_acct_id']=this.b_acct_id;
    final_obj['data']=[]

    for(let i=0;i<this.nonsorItemList.length;i++){
      var obj=Object.assign({},this.nonsorItemList[i]);
      obj['tender_id']=this.selectedTender['tender_id']
      obj['curr'] = 'INR';
      obj['sortype'] = 'N';
      obj['sno'] = this.last_sno+i;
      obj['dev_id'] = max_dev_no + 1
      obj['b_acct_id'] = this.b_acct_id;
      obj['is_extra_item'] = 1;
      obj['create_user_id'] = this.ebillUser['user_id'];
      obj['status'] = 'GENERATED';
      final_obj['data'].push(obj)
    }
    
    console.log(final_obj)
    var resp = await this.DeviationService.createSOR(final_obj);
    if (resp['error'] == false) {
      this.changeWorkInTab2()
      /* await this.getItems();
      await this.getAllDEVList(this.selectedTender['tender_id']); */
      this.nonsorItemList = []
      //await this.insertForApproval(this.sorObj['dev_id']);
      //await this.getAllApprovalStatus();
      this.sorObj = { item_type:'DSR Item'};
      $('#NON-SOR').modal('hide');
      Swal.fire("Success", "NON SOR Item Added", "success");
    } else {
      Swal.fire("Oops", "Some Error occurred during non sor item add", "error");
    }
  }

  changeNewQuantity(sno:any) {
    for (let j = 0; j < this.table2_data.length; j++) {
      if (sno == this.table2_data[j]['sno']) {
        let flag = false
        if(this.table2_data[j]['quantity'] < 0){
          flag = true
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
          this.table2_data[j]['new_quantity'] = (-1)*(this.table2_data[j]['new_quantity'])
        }
        if (this.table2_data[j]['new_quantity'] < this.table2_data[j]['curr_quantity']) {
          Swal.fire("warning", "You can not enter new Quantity less then current Quantity...", "warning");
          if (this.table2_data[j]['curr_quantity'] > this.table2_data[j]['quantity']) {
            this.table2_data[j]['new_quantity'] = this.table2_data[j]['curr_quantity']
          } else {
            this.table2_data[j]['new_quantity'] = this.table2_data[j]['quantity']
          }
        }
        
        if (this.table2_data[j]['quantity'] <= this.table2_data[j]['new_quantity']) {
          this.table2_data[j]['less'] = 0;
          this.table2_data[j]['excess'] = this.table2_data[j]['new_quantity'] - this.table2_data[j]['quantity'];
        } else {
          this.table2_data[j]['less'] = this.table2_data[j]['quantity'] - this.table2_data[j]['new_quantity'];
          this.table2_data[j]['excess'] = 0;
        }
        if(flag){
          this.table2_data[j]['new_quantity'] = (-1)*(this.table2_data[j]['new_quantity'])
          this.table2_data[j]['quantity'] = (this.table2_data[j]['quantity'])*(-1)
          this.table2_data[j]['curr_quantity'] = (this.table2_data[j]['curr_quantity'])*(-1)
          this.table2_data[j]['excess'] = (-1)*(this.table2_data[j]['excess'])
          this.table2_data[j]['less'] = (-1)*(this.table2_data[j]['less'])
        }
      }
    }
  }


  changeNewQuantitytab4(sno:any) {
    for (let j = 0; j < this.devupdate_arr.length; j++) {
      if (sno == this.devupdate_arr[j]['sno']) {
        let flag = false
        if(this.devupdate_arr[j]['quantity'] < 0){
          flag = true
          this.devupdate_arr[j]['quantity'] = (this.devupdate_arr[j]['quantity'])*(-1)
          this.devupdate_arr[j]['curr_quantity'] = (this.devupdate_arr[j]['curr_quantity'])*(-1)
          this.devupdate_arr[j]['new_quantity'] = (-1)*(this.devupdate_arr[j]['new_quantity'])
        }
        if (this.devupdate_arr[j]['new_quantity'] < this.devupdate_arr[j]['curr_quantity']) {
          Swal.fire("warning", "You can not enter new Quantity less then current Quantity...", "warning");
          if (this.devupdate_arr[j]['curr_quantity'] > this.devupdate_arr[j]['quantity']) {
            this.devupdate_arr[j]['new_quantity'] = this.devupdate_arr[j]['curr_quantity']
          } else {
            this.devupdate_arr[j]['new_quantity'] = this.devupdate_arr[j]['quantity']
          }
        }
        
        if (this.devupdate_arr[j]['quantity'] <= this.devupdate_arr[j]['new_quantity']) {
          this.devupdate_arr[j]['less'] = 0;
          this.devupdate_arr[j]['excess'] = this.devupdate_arr[j]['new_quantity'] - this.devupdate_arr[j]['quantity'];
        } else {
          this.devupdate_arr[j]['less'] = this.devupdate_arr[j]['quantity'] - this.devupdate_arr[j]['new_quantity'];
          this.devupdate_arr[j]['excess'] = 0;
        }
        if(flag){
          this.devupdate_arr[j]['new_quantity'] = (-1)*(this.devupdate_arr[j]['new_quantity'])
          this.devupdate_arr[j]['quantity'] = (this.devupdate_arr[j]['quantity'])*(-1)
          this.devupdate_arr[j]['curr_quantity'] = (this.devupdate_arr[j]['curr_quantity'])*(-1)
          this.devupdate_arr[j]['excess'] = (-1)*(this.devupdate_arr[j]['excess'])
          this.devupdate_arr[j]['less'] = (-1)*(this.devupdate_arr[j]['less'])
        }
      }
    }
  }
  updatesorObj:any = {};
  async updatesor() {
    console.log(this.updatesorObj);
    this.spinner.show();

    if (this.updatesorObj['dev_type'] == 'A') {
      console.log('eff_quantity - dev_quantity + new_dev_quantity');
      this.updatesorObj['new_eff_quantity'] = this.updatesorObj['eff_quantity'] -
        this.updatesorObj['old_dev_quantity'] + this.updatesorObj['new_dev_quantity']
    } else {
      this.updatesorObj['new_eff_quantity'] = this.updatesorObj['eff_quantity'] +
        this.updatesorObj['old_dev_quantity'] - this.updatesorObj['new_dev_quantity']
    }
    this.updatesorObj['b_acct_id'] = this.ebillUser.b_acct_id;
    this.updatesorObj['dev_quantity'] = this.updatesorObj['new_dev_quantity']
    console.log(this.updatesorObj);

    var resp = await this.DeviationService.updateSORItem(this.updatesorObj);
    if (resp['error'] == false) {
      await this.getItems();
      await this.getAllDEVList(this.selectedTender['tender_id']);
      // await this.insertForApproval(this.sorObj['dev_id']);
      // await this.getAllApprovalStatus();
      $('#update_SOR').modal('hide');
      this.spinner.hide();
      Swal.fire("Success", "Item Updated", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Some Error occurred during  item update", "error");
    }
  }





  //*****************************************************Print Data Cretion Start********************************************************* */
  print_data:any = []
  printextra_data:any = []
  printsor:any = []
  printnonsor:any = []
  async createprintdata() {
    this.print_data = []
    this.printextra_data = []
    for (let i = 0; i < this.AllItem.length; i++) {
      var obj = Object()
      obj['prev_quan'] = this.AllItem[i]['quantity']
      obj['new_quan'] = this.AllItem[i]['quantity']
      obj['sno'] = this.AllItem[i]['sno']
      obj['item_desc'] = this.AllItem[i]['item_desc']
      obj['rate'] = this.AllItem[i]['rate']
      obj['unit'] = this.AllItem[i]['unit']
      obj['over_under_per'] = this.AllItem[i]['over_under_per']
      obj['over_under'] = this.AllItem[i]['over_under']
      obj['sorflag'] = 0
      obj['excess'] = 0
      obj['saving'] = 0
      for (let j = 0; j < this.deviationList.length; j++) {

        if (this.AllItem[i]['sno'] == this.deviationList[j]['sno']) {
          if (this.deviationList[j]['dev_dt'] < this.selecteddevid) {
            if (this.deviationList[j]['dev_type'] == 'A') {
              obj['perv_quan'] = obj['prev_quan'] + this.deviationList[j]['dev_quantity']
              if(this.deviationList[j]['dev_quantity'] == this.AllItem[i]['quantity'] && this.deviationList[j]['dev_type'] == 'A'){
                obj['new_quan'] =  this.deviationList[j]['dev_quantity']
                }else{
                  obj['new_quan'] =obj['new_quan'] + this.deviationList[j]['dev_quantity']
                }

            } else if (this.deviationList[j]['dev_type'] == 'S') {
              obj['perv_quan'] = obj['prev_quan'] - this.deviationList[j]['dev_quantity']
              obj['new_quan'] = obj['new_quan'] - this.deviationList[j]['dev_quantity']

            }
          }
          if (this.deviationList[j]['dev_dt'] == this.selecteddevid) {
            obj['remark'] = this.deviationList[j]['remark'];
            if (this.deviationList[j]['dev_quantity'] == this.AllItem[i]['quantity'] && this.deviationList[j]['dev_type'] == 'A') {
              obj['sorflag'] = 1;
              obj['excess'] = this.deviationList[j]['dev_quantity']
            } else {
              if (this.deviationList[j]['dev_type'] == 'A') {
                if (this.deviationList[j]['dev_quantity'] == this.AllItem[i]['quantity'] && this.deviationList[j]['dev_type'] == 'A') {
                  obj['new_quan'] = obj['new_quan'] + this.deviationList[j]['dev_quantity']
                } else {
                  obj['new_quan'] = obj['new_quan'] + this.deviationList[j]['dev_quantity']
                }

                obj['excess'] = this.deviationList[j]['dev_quantity']

              } else if (this.deviationList[j]['dev_type'] == 'S') {
                obj['new_quan'] = obj['new_quan'] - this.deviationList[j]['dev_quantity']
                obj['saving'] = this.deviationList[j]['dev_quantity']

              }
            }

          }
          if (this.deviationList[j]['dev_dt'] > this.selecteddevid) {
            if (this.deviationList[j]['dev_quantity'] == this.AllItem[i]['quantity'] && this.deviationList[j]['dev_type'] == 'A') {
              obj['sorflag'] = -1
            }
          }
          // break;
        }

      }
      if (obj['sorflag'] == 1) {
        this.printextra_data.push(obj)
      } else if (obj['sorflag'] == 0) {
        this.print_data.push(obj)
      }
    }
  }
 
  async createprintdatatab2() {
    await this.getAllDEVListprint(this.selectedTender['tender_id']);

    this.print_data = []
    this.printextra_data = []
    let arr = []
    for (let i = 0; i < this.AllItem.length; i++) {
      var obj = Object()
      obj['prev_quan'] = this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']
      obj['new_quan'] = this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']
      obj['sno'] = this.AllItem[i]['sno']
      obj['item_desc'] = this.AllItem[i]['item_desc']
      obj['rate'] = this.AllItem[i]['rate']
      obj['unit'] = this.AllItem[i]['unit']
      obj['over_under_per'] = this.AllItem[i]['over_under_per']
      obj['over_under'] = this.AllItem[i]['over_under']
      obj['sorflag'] = 0
      obj['excess'] = 0
      obj['saving'] = 0
      for (let j = 0; j < this.deviationList.length; j++) {

        if (this.AllItem[i]['sno'] == this.deviationList[j]['sno']) {
          arr.push(this.AllItem[i]['sno'])
          obj['sortype'] = this.deviationList[j]['sortype'];
            obj['remark'] = this.deviationList[j]['remark'];
            if (this.deviationList[j]['sortype'] != null && this.deviationList[j]['dev_type'] == 'A') {
              obj['sorflag'] = 1;
              obj['excess'] = this.deviationList[j]['dev_quantity']
            } else {
              if (this.deviationList[j]['dev_type'] == 'A') {
                if (this.deviationList[j]['sortype'] == null && this.deviationList[j]['dev_type'] == 'A') {
                  obj['new_quan'] = obj['new_quan'] + this.deviationList[j]['dev_quantity']
                } else {
                  obj['new_quan'] = obj['new_quan'] + this.deviationList[j]['dev_quantity']
                }

                obj['excess'] = this.deviationList[j]['dev_quantity']

              } else if (this.deviationList[j]['dev_type'] == 'S') {
                obj['new_quan'] = obj['new_quan'] - this.deviationList[j]['dev_quantity']
                obj['saving'] = this.deviationList[j]['dev_quantity']

              }
            }

          
         
          // break;
        }

      }
      if (obj['sorflag'] == 1 || this.AllItem[i]['is_extra_item'] == 1) {
        console.log(obj)
        if(obj['sortype']== 'S' || this.devlist[obj['sno']] == 'S') this.printsor.push(obj) 
        else this.printnonsor.push(obj)
       // this.printextra_data.push(obj)
      } else if (obj['sorflag'] == 0) {
        this.print_data.push(obj)
      }
    }
  console.log(arr,this.deviationList)
    for (let i = 0; i < this.deviationList.length; i++) {
      if(!arr.includes(this.deviationList[i]['sno'])){
        var obj2 = Object()
        console.log(this.deviationList[i])
        let data = JSON.parse(this.deviationList[i]['data'])
        console.log(data)
        obj2['prev_quan'] = 0 //this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']
        obj2['new_quan'] =this.deviationList[i]['dev_quantity'] //this.AllItem[i]['eff_quantity'] + this.AllItem[i]['already_measured']
        obj2['sno'] = this.deviationList[i]['sno']
        obj2['item_desc'] = this.deviationList[i]['sordesc']
        obj2['rate'] = data['rate']
        obj2['unit'] = data['unit']
        obj2['over_under_per'] = data['over_under_per']
        obj2['over_under'] = data['over_under']
        obj2['sorflag'] = 0
        obj2['excess'] = 0
        obj2['saving'] = 0
        obj2['remark'] = this.deviationList[i]['remark'];
        if (this.deviationList[i]['sortype'] == 'S' && this.deviationList[i]['dev_type'] == 'A') {
          obj2['sorflag'] = 1;
          obj2['excess'] = this.deviationList[i]['dev_quantity']
          this.printsor.push(obj2) 
          console.log(obj2)
        }else if (this.deviationList[i]['sortype'] == 'N' && this.deviationList[i]['dev_type'] == 'A') {
          obj2['sorflag'] = 1;
          obj2['excess'] = this.deviationList[i]['dev_quantity']
          this.printnonsor.push(obj2)
          console.log(obj2)
        }
       
      }
        
      }
      
    
  }
  workArr:any = [];
  workObj:any = {}
  partyObj:any = {};
  partyIdObj:any = {};
  party:any = []
  async getparty() {
    this.spinner.show();
    var resp = await this.MSS.getparty(this.b_acct_id);
    if (resp['error'] == false) {
      this.party = resp.data;
      this.partyObj = new Object
      for (let i = 0; i < this.party.length; i++) {
        this.partyObj[this.party[i]['rep_le_id']] = this.party[i]['party_leagal_name']
        this.partyIdObj[this.party[i]['rep_le_id']] = this.party[i]['party_id']
      }
      this.spinner.hide()
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting Vendor Info", "error")
    }
    
  }



  //*****************************************************print of devition report***************************************************************** */
  runningBill = [];
  async printtab2() {
    await this.createprintdatatab2();
    await this.getparty();

    var txt = "LUCKNOW DEVELOPMENT AUTHORITY" + "(" + "LDA" + ") , LUCKNOW\n" + "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };




    var header1 = {
      columns: [
        {
          width: '*',
          text: ' DEVEATION STATEMENT',
          bold: true,
          alignment: 'center'
        }

      ],

    }
    dd.content.push({ text: " " });
    dd.content.push(header1);

    var tbl:any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 375],

        body: [



        ]
      }
    };
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    var resp = await this.embService.getAllEmbList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.lastEmbDetails = {}
      console.log(resp)
      if (resp['data'][resp['data'].length - 1] != undefined) {
        this.lastEmbDetails = resp['data'][resp['data'].length - 1]

      }
      //this.embData = resp['data'];
    }

    var workObj = this.workObj[this.selectedTender['work_id']];
    var partyObj = this.partyObj[this.selectedTender['party_id']];
    var arr = [];
    arr.push({ text: "File No-", bold: true });
    arr.push('');
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Agreement No.-", bold: true });
    arr.push(workObj['agr_no'] + ',DATED ' + workObj['agr_dt']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Name Of Contractor ", bold: true });
    arr.push(partyObj);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj['work_order_name']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "E-MB No", bold: true });
    arr.push(this.lastEmbDetails['emb_no']);
    tbl.table.body.push(arr);
    


    var arr = [];
    arr.push({ text: "Date Of Start:-", bold: true });
    arr.push(workObj['start_date']);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Date of Completion:-", bold: true });
    arr.push(workObj['end_date']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Date of Extension:-", bold: true });
    arr.push(this.selectedTender['ext_date']);
    tbl.table.body.push(arr);
    dd.content.push(tbl);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var tbl1 = {

      fontSize: 10,
      table: {
        headerRows: 2,
        widths: [30, 150, 40, 40, 20, 30, 40, 40, 40, 20, 30, 50, 40, 40, 40],
        body: [
          [{ rowSpan: 2, text: 'SNO' }, { rowSpan: 2, text: 'ITEM OF WORK', alignment: 'Right' }, { colSpan: 5, text: 'AS PER BOQ', alignment: 'center' }, {}, {}, {}, {}, { colSpan: 5, text: 'AS PER DEVIATION', alignment: 'center' }, {}, {}, {}, {}, { rowSpan: 2, text: 'SAVING' }, { rowSpan: 2, text: 'EXCESS' }, { rowSpan: 2, text: 'REMARK' }],
          ['', {}, { text: 'Qty' }, { text: 'Unit' }, { text: 'Rs' }, 'Rate', { text: 'Amount', alignment: 'center' }, { text: 'Qty' }, { text: 'Unit' }, { text: 'Rs' }, 'Rate', { text: 'Amount', alignment: 'center' }, {}, {}, {}],
        ],
      }
    };
    dd.content.push(tbl1);
    var printobj:any = { new_amt: 0, savingnewamt: 0, excessant: 0, extraitemamtsor: 0,extraitemamtnonsor: 0 }
    for (var i = 0; i < this.print_data.length; i++) {


      var arr = []
      arr.push(this.print_data[i]['sno']);
      arr.push({ text: this.print_data[i]['item_desc'], alignment: 'right' });
      arr.push((this.print_data[i]['prev_quan']).toFixed(2));
      arr.push({ text: this.print_data[i]['unit'], alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.print_data[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.print_data[i]['prev_quan'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.print_data[i]['new_quan']).toFixed(2)), alignment: 'right' });
      arr.push({ text: this.print_data[i]['unit'], alignment: 'right' });
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.print_data[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.print_data[i]['new_quan'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['new_amt'] = printobj['new_amt'] + Number((this.print_data[i]['new_quan'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: Number((this.print_data[i]['saving'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });//
      printobj['savingnewamt'] = printobj['savingnewamt'] + Number((this.print_data[i]['saving'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: Number((this.print_data[i]['excess'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['excessant'] = printobj['excessant'] + Number((this.print_data[i]['excess'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: this.print_data[i]['remark'], alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['new_amt'].toFixed(2), alignment: 'right' });
    arr.push({ text: printobj['savingnewamt'].toFixed(2), alignment: 'right' });//
    arr.push({ text: printobj['excessant'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    if (this.selectedTender['over_under'] == 'EXC') {
      printobj['c1Over'] = Number((printobj['new_amt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c2Over'] = Number((printobj['savingnewamt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c3Over'] = Number((printobj['excessant'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c1AfterOver'] = Number((printobj['new_amt'] + printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] + printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] + printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: 'Add @ ' + this.selectedTender['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);



    } else if (this.selectedTender['over_under'] == 'LS') {
      printobj['c1Over'] = Number((printobj['new_amt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c2Over'] = Number((printobj['savingnewamt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c3Over'] = Number((printobj['excessant'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c1AfterOver'] = Number((printobj['new_amt'] - printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] - printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] - printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: 'Sub @ ' + this.selectedTender['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);

    } else {
      printobj['c1Over'] = 0
      printobj['c2Over'] = 0
      printobj['c3Over'] = 0
      printobj['c1AfterOver'] = Number((printobj['new_amt'] + printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] + printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] + printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: ' At Par @  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: ' TOTAL ', alignment: 'right', bold: true, colSpan: 3 });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['c1AfterOver'], alignment: 'right' });
    arr.push({ text: printobj['c2AfterOver'], alignment: 'right' });//
    arr.push({ text: printobj['c3AfterOver'], alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('EXTRA ITEMS');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('SOR ITEMS');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);

    //sor Item
    for (var i = 0; i < this.printsor.length; i++) {


      var arr = []
      arr.push(this.printsor[i]['sno']);
      arr.push({ text: this.printsor[i]['item_desc'], alignment: 'right' });
      arr.push((this.printsor[i]['prev_quan']).toFixed(2));
      arr.push({ text: this.printsor[i]['unit'], alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.printsor[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.printsor[i]['prev_quan'] * this.printsor[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.printsor[i]['new_quan']).toFixed(2)), alignment: 'right' });
      arr.push({ text: this.printsor[i]['unit'], alignment: 'right' });
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.printsor[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.printsor[i]['saving'] * this.printsor[i]['rate']).toFixed(2)), alignment: 'right' });//
      arr.push({ text: Number((this.printsor[i]['excess'] * this.printsor[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['extraitemamtsor'] = printobj['extraitemamtsor'] + Number((this.printsor[i]['excess'] * this.printsor[i]['rate']).toFixed(2))
      arr.push({ text: '', alignment: 'right' });//

      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['extraitemamtsor'].toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['extraitemamtsor'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    printobj['c1AfterOverextrasor'] = printobj['extraitemamtsor']
    printobj['c2AfterOverextrasor'] = printobj['extraitemamtsor']
    printobj['c3AfterOverextrasor'] = printobj['extraitemamtsor']
    for (let i = 0; i < this.printsor.length; i++) {
      if (this.printsor[i]['over_under'] == 'EXC') {
        printobj['c1Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextrasor'] = Number((printobj['c1AfterOverextrasor'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextrasor'] = Number((printobj['c2AfterOverextrasor'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextrasor'] = Number((printobj['c3AfterOverextrasor'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Add @ ' + this.printsor[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);



      } else if (this.printsor[i]['over_under'] == 'LS') {
        printobj['c1Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printsor[i]['new_quan'] * this.printsor[i]['rate']).toFixed(2)) * this.printsor['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextrasor'] = Number((printobj['c1AfterOverextrasor'] - printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextrasor'] = Number((printobj['c2AfterOverextrasor'] - printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextrasor'] = Number((printobj['c3AfterOverextrasor'] - printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Sub @ ' + this.printsor[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      } else {
        printobj['c1Overextra'] = 0
        printobj['c2Overextra'] = 0
        printobj['c3Overextra'] = 0
        printobj['c1AfterOverextrasor'] = Number((printobj['c1AfterOverextrasor'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextrasor'] = Number((printobj['c2AfterOverextrasor'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextrasor'] = Number((printobj['c3AfterOverextrasor'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: ' At Par @  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      }

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL ', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: (printobj['c1AfterOverextrasor']).toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['c3AfterOverextrasor'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('NOR-SOR ITEMS');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    //nonsor item print
    for (var i = 0; i < this.printnonsor.length; i++) {


      var arr = []
      arr.push(this.printnonsor[i]['sno']);
      arr.push({ text: this.printnonsor[i]['item_desc'], alignment: 'right' });
      arr.push({ text: this.printnonsor[i]['item_desc'], alignment: 'right' });
      arr.push((this.printnonsor[i]['prev_quan']).toFixed(2));
      arr.push({ text: this.printnonsor[i]['unit'], alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.printnonsor[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.printnonsor[i]['prev_quan'] * this.printnonsor[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.printnonsor[i]['new_quan']).toFixed(2)), alignment: 'right' });
      arr.push({ text: this.printnonsor[i]['unit'], alignment: 'right' });
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.printnonsor[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.printnonsor[i]['saving'] * this.printnonsor[i]['rate']).toFixed(2)), alignment: 'right' });//
      arr.push({ text: Number((this.printnonsor[i]['excess'] * this.printnonsor[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['extraitemamtnonsor'] = printobj['extraitemamtnonsor'] + Number((this.printnonsor[i]['excess'] * this.printnonsor[i]['rate']).toFixed(2))
      arr.push({ text: '', alignment: 'right' });//

      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['extraitemamtnonsor'].toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['extraitemamtnonsor'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    printobj['c1AfterOverextranonsor'] = printobj['extraitemamtnonsor']
    printobj['c2AfterOverextranonsor'] = printobj['extraitemamtnonsor']
    printobj['c3AfterOverextranonsor'] = printobj['extraitemamtnonsor']
    for (let i = 0; i < this.printnonsor.length; i++) {
      if (this.printnonsor[i]['over_under'] == 'EXC') {
        printobj['c1Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.printnonsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.printnonsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.printnonsor[i]['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextranonsor'] = Number((printobj['c1AfterOverextranonsor'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextranonsor'] = Number((printobj['c2AfterOverextranonsor'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextranonsor'] = Number((printobj['c3AfterOverextranonsor'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Add @ ' + this.printnonsor[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);



      } else if (this.printnonsor[i]['over_under'] == 'LS') {
        printobj['c1Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printnonsor[i]['new_quan'] * this.printnonsor[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextranonsor'] = Number((printobj['c1AfterOverextranonsor'] - printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextranonsor'] = Number((printobj['c2AfterOverextranonsor'] - printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextranonsor'] = Number((printobj['c3AfterOverextranonsor'] - printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Sub @ ' + this.printnonsor[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      } else {
        printobj['c1Overextra'] = 0
        printobj['c2Overextra'] = 0
        printobj['c3Overextra'] = 0
        printobj['c1AfterOverextranonsor'] = Number((printobj['c1AfterOverextranonsor'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextranonsor'] = Number((printobj['c2AfterOverextranonsor'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextranonsor'] = Number((printobj['c3AfterOverextranonsor'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: ' At Par @  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      }

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL ', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: (printobj['c1AfterOverextranonsor']).toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['c3AfterOverextranonsor'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);

    //G total
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'G.TOTAL ', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: (printobj['c1AfterOverextranonsor'] + printobj['c1AfterOverextrasor'] + printobj['c1AfterOver']).toFixed(2), alignment: 'right' });
    arr.push({ text: printobj['savingnewamt'], alignment: 'right' });//
    arr.push({ text: (printobj['c3AfterOverextranonsor']+printobj['c3AfterOverextrasor'] + printobj['excessant']).toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var tbl2:any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [150, 150, 150, 150, 145],

        body: [



        ]
      }
    };
    let amt = this.selectedTender['amount']
    if (this.selectedTender['over_under'] == 'LS') {
      amt = amt - ((amt+this.selectedTender['over_under_per']) / 100)
    }else if (this.selectedTender['over_under'] == 'EXC') {
      amt = amt + ((amt+this.selectedTender['over_under_per']) / 100)
    }
console.log(printobj)
    var arr = [];
    arr.push({ text: "SANCTION AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: amt, bold: true });
    arr.push('');
    arr.push({ text: "", bold: true });
    tbl2.table.body.push(arr);
    var arr = [];
    arr.push({ text: "DEVIATION AMOUNT ", bold: true });
    arr.push('Rs');
    arr.push({ text: (printobj['extraitemamtsor'] + printobj['extraitemamtnonsor']+ printobj['c1AfterOver']).toFixed(2), bold: true });
    arr.push('');
    arr.push({ text: "", bold: true });
    tbl2.table.body.push(arr);
    var arr = [];
    arr.push({ text: "EXTRA  ITEMS AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['extraitemamtsor'] + printobj['extraitemamtnonsor'], bold: true });
    arr.push({ text: this.printnonsor.length + this.printsor.length, bold: true });
    arr.push({ text: "nos", bold: true });
    tbl2.table.body.push(arr);



    var arr = [];
    arr.push({ text: "EXCESS  AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['excessant'], bold: true });
    arr.push(((printobj['excessant'] * 100) / ((printobj['extraitemamtsor'] + printobj['extraitemamtnonsor'] + printobj['new_amt']) - printobj['excessant'] + printobj['savingnewamt'])).toFixed(2));
    arr.push({ text: "%", bold: true });
    tbl2.table.body.push(arr);

    var arr = [];
    arr.push({ text: "SAVING AMOUNT ", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['savingnewamt'], bold: true });
    arr.push(((printobj['savingnewamt'] * 100) / ((printobj['extraitemamtsor'] + printobj['extraitemamtnonsor'] + printobj['new_amt']) - printobj['excessant'] + printobj['savingnewamt'])).toFixed(2));
    arr.push({ text: "%", bold: true });
    tbl2.table.body.push(arr);
    dd.content.push(tbl2);
    pdfMake.createPdf(dd).download('Deviation' + '.pdf');


  }
  async print1() {
    await this.createprintdata();
    await this.getparty();
    var account_name=this.ebillUser['account_name'];
    var account_short_name=this.ebillUser['account_short_name'];
    var txt = account_name + "(" +account_short_name+ ") \n";
    //var txt = "LUCKNOW DEVELOPMENT AUTHORITY" + "(" + "LDA" + ") , LUCKNOW\n" + "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var arr = []
        var obj = { text: txt, alignment: 'center', margin: [72, 40], fontSize: 15, bold: true };
        arr.push(obj);
        return arr;
      },
      pageOrientation: 'portrait',
      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };




    var header1 = {
      columns: [
        {
          width: '*',
          text: ' DEVEATION STATEMENT',
          bold: true,
          alignment: 'center'
        }

      ],

    }
    dd.content.push({ text: " " });
    dd.content.push(header1);

    var tbl:any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [370, 375],

        body: [



        ]
      }
    };

    var workObj = this.workObj[this.selectedTender['work_id']];
    var partyObj = this.partyObj[this.selectedTender['party_id']];
    var obj = new Object();
    obj = { b_acct_id: this.b_acct_id, tender_id: this.selectedTender['tender_id'] }
    var resp = await this.embService.getAllEmbList(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.lastEmbDetails = {}
      console.log(resp)
      if (resp['data'][resp['data'].length - 1] != undefined) {
        this.lastEmbDetails = resp['data'][resp['data'].length - 1]

      }
      //this.embData = resp['data'];
    }

    var workObj = this.workObj[this.selectedTender['work_id']];
    var partyObj = this.partyObj[this.selectedTender['party_id']];
    var arr = [];
    arr.push({ text: "File No-", bold: true });
    arr.push('');
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Agreement No.-", bold: true });
    arr.push(workObj['agr_no'] + ',DATED ' + workObj['agr_dt']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Name Of Contractor ", bold: true });
    arr.push(partyObj);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj['work_order_name']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "E-MB No", bold: true });
    arr.push(this.lastEmbDetails['emb_no']);
    tbl.table.body.push(arr);
    


    var arr = [];
    arr.push({ text: "Date Of Start:-", bold: true });
    arr.push(workObj['start_date']);
    tbl.table.body.push(arr);

    var arr = [];
    arr.push({ text: "Date of Completion:-", bold: true });
    arr.push(workObj['end_date']);
    tbl.table.body.push(arr);
    var arr = [];
    arr.push({ text: "Date of Extension:-", bold: true });
    arr.push(this.selectedTender['ext_date']);
    tbl.table.body.push(arr);
    dd.content.push(tbl);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var tbl1 = {

      fontSize: 10,
      table: {
        headerRows: 2,
        widths: [30, 150, 40, 40, 20, 30, 40, 40, 40, 20, 30, 50, 40, 40, 40],
        body: [
          [{ rowSpan: 2, text: 'SNO' }, { rowSpan: 2, text: 'ITEM OF WORK', alignment: 'Right' }, { colSpan: 5, text: 'AS PER BOQ', alignment: 'center' }, {}, {}, {}, {}, { colSpan: 5, text: 'AS PER DEVIATION', alignment: 'center' }, {}, {}, {}, {}, { rowSpan: 2, text: 'SAVING' }, { rowSpan: 2, text: 'EXCESS' }, { rowSpan: 2, text: 'REMARK' }],
          ['', {}, { text: 'Qty' }, { text: 'Unit' }, { text: 'Rs' }, 'Rate', { text: 'Amount', alignment: 'center' }, { text: 'Qty' }, { text: 'Unit' }, { text: 'Rs' }, 'Rate', { text: 'Amount', alignment: 'center' }, {}, {}, {}],
        ],
      }
    };
    dd.content.push(tbl1);
    var printobj:any = { new_amt: 0, savingnewamt: 0, excessant: 0, extraitemamt: 0 }
    for (var i = 0; i < this.print_data.length; i++) {


      var arr = []
      arr.push(this.print_data[i]['sno']);
      arr.push({ text: this.print_data[i]['item_desc'], alignment: 'right' });
      arr.push((this.print_data[i]['prev_quan']).toFixed(2));
      arr.push({ text: this.print_data[i]['unit'], alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.print_data[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.print_data[i]['prev_quan'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.print_data[i]['new_quan']).toFixed(2)), alignment: 'right' });
      arr.push({ text: this.print_data[i]['unit'], alignment: 'right' });
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.print_data[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.print_data[i]['new_quan'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['new_amt'] = printobj['new_amt'] + Number((this.print_data[i]['new_quan'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: Number((this.print_data[i]['saving'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });//
      printobj['savingnewamt'] = printobj['savingnewamt'] + Number((this.print_data[i]['saving'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: Number((this.print_data[i]['excess'] * this.print_data[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['excessant'] = printobj['excessant'] + Number((this.print_data[i]['excess'] * this.print_data[i]['rate']).toFixed(2))
      arr.push({ text: this.print_data[i]['remark'], alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['new_amt'].toFixed(2), alignment: 'right' });
    arr.push({ text: printobj['savingnewamt'].toFixed(2), alignment: 'right' });//
    arr.push({ text: printobj['excessant'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    if (this.selectedTender['over_under'] == 'EXC') {
      printobj['c1Over'] = Number((printobj['new_amt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c2Over'] = Number((printobj['savingnewamt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c3Over'] = Number((printobj['excessant'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c1AfterOver'] = Number((printobj['new_amt'] + printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] + printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] + printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: 'Add @ ' + this.selectedTender['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);



    } else if (this.selectedTender['over_under'] == 'LS') {
      printobj['c1Over'] = Number((printobj['new_amt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c2Over'] = Number((printobj['savingnewamt'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c3Over'] = Number((printobj['excessant'] * this.selectedTender['over_under_per'] / 100).toFixed(2));
      printobj['c1AfterOver'] = Number((printobj['new_amt'] - printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] - printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] - printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: 'Sub @ ' + this.selectedTender['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);

    } else {
      printobj['c1Over'] = 0
      printobj['c2Over'] = 0
      printobj['c3Over'] = 0
      printobj['c1AfterOver'] = Number((printobj['new_amt'] + printobj['c1Over']).toFixed(2));
      printobj['c2AfterOver'] = Number((printobj['savingnewamt'] + printobj['c2Over']).toFixed(2));
      printobj['c3AfterOver'] = Number((printobj['excessant'] + printobj['c3Over']).toFixed(2));
      var arr = []
      arr.push('');
      arr.push({ text: ' ', alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: ' At Par @  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: printobj['c1Over'], alignment: 'right' });
      arr.push({ text: printobj['c2Over'], alignment: 'right' });//
      arr.push({ text: printobj['c3Over'], alignment: 'right' });
      arr.push({ text: '', alignment: 'right' });//
      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: ' TOTAL ', alignment: 'right', bold: true, colSpan: 3 });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['c1AfterOver'], alignment: 'right' });
    arr.push({ text: printobj['c2AfterOver'], alignment: 'right' });//
    arr.push({ text: printobj['c3AfterOver'], alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('EXTRA ITEMS');
    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    for (var i = 0; i < this.printextra_data.length; i++) {


      var arr = []
      arr.push(this.printextra_data[i]['sno']);
      arr.push({ text: this.printextra_data[i]['item_desc'], alignment: 'right' });
      arr.push('');
      arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
      arr.push({ text: '', alignment: 'right' });//
      arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: '', alignment: 'right' });
      arr.push({ text: Number((this.printextra_data[i]['new_quan']).toFixed(2)), alignment: 'right' });
      arr.push({ text: this.printextra_data[i]['unit'], alignment: 'right' });
      arr.push({ text: 'Rs', alignment: 'right' });//
      arr.push({ text: this.printextra_data[i]['rate'], alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
      arr.push({ text: Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)), alignment: 'right' });
      arr.push({ text: Number((this.printextra_data[i]['saving'] * this.printextra_data[i]['rate']).toFixed(2)), alignment: 'right' });//
      arr.push({ text: Number((this.printextra_data[i]['excess'] * this.printextra_data[i]['rate']).toFixed(2)), alignment: 'right' });
      printobj['extraitemamt'] = printobj['extraitemamt'] + Number((this.printextra_data[i]['excess'] * this.printextra_data[i]['rate']).toFixed(2))
      arr.push({ text: '', alignment: 'right' });//

      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: printobj['extraitemamt'].toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['extraitemamt'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    printobj['c1AfterOverextra'] = printobj['extraitemamt']
    printobj['c2AfterOverextra'] = printobj['extraitemamt']
    printobj['c3AfterOverextra'] = printobj['extraitemamt']
    for (let i = 0; i < this.printextra_data.length; i++) {
      if (this.printextra_data[i]['over_under'] == 'EXC') {
        printobj['c1Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.printextra_data[i]['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.printextra_data[i]['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.printextra_data[i]['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextra'] = Number((printobj['c1AfterOverextra'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextra'] = Number((printobj['c2AfterOverextra'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextra'] = Number((printobj['c3AfterOverextra'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Add @ ' + this.printextra_data[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);



      } else if (this.printextra_data[i]['over_under'] == 'LS') {
        printobj['c1Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c2Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c3Overextra'] = Number((Number((this.printextra_data[i]['new_quan'] * this.printextra_data[i]['rate']).toFixed(2)) * this.selectedTender['over_under_per'] / 100).toFixed(2));
        printobj['c1AfterOverextra'] = Number((printobj['c1AfterOverextra'] - printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextra'] = Number((printobj['c2AfterOverextra'] - printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextra'] = Number((printobj['c3AfterOverextra'] - printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: 'Sub @ ' + this.printextra_data[i]['over_under_per'] + ' %  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      } else {
        printobj['c1Overextra'] = 0
        printobj['c2Overextra'] = 0
        printobj['c3Overextra'] = 0
        printobj['c1AfterOverextra'] = Number((printobj['c1AfterOverextra'] + printobj['c1Overextra']).toFixed(2));
        printobj['c2AfterOverextra'] = Number((printobj['c2AfterOverextra'] + printobj['c2Overextra']).toFixed(2));
        printobj['c3AfterOverextra'] = Number((printobj['c3AfterOverextra'] + printobj['c3Overextra']).toFixed(2));
        var arr = []
        arr.push('');
        arr.push({ text: ' ', alignment: 'right' });
        arr.push('');
        arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });
        arr.push({ text: ' At Par @  as per Contract Bond', alignment: 'right', bold: true, colSpan: 3 });
        arr.push({ text: '', alignment: 'right' });//
        arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
        arr.push({ text: printobj['c1Overextra'], alignment: 'right' });
        arr.push({ text: 0, alignment: 'right' });//
        arr.push({ text: printobj['c3Overextra'], alignment: 'right' });
        arr.push({ text: '', alignment: 'right' });//
        dd.content[dd.content.length - 1].table.body.push(arr);

      }

    }
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'TOTAL ', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: (printobj['c1AfterOverextra']).toFixed(2), alignment: 'right' });
    arr.push({ text: 0, alignment: 'right' });//
    arr.push({ text: printobj['c3AfterOverextra'].toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    var arr = []
    arr.push('');
    arr.push({ text: ' ', alignment: 'right' });
    arr.push('');

    arr.push({ text: '', alignment: 'left' });//this.runningBill[i]['quantity']
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: '', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    arr.push({ text: 'G.TOTAL ', alignment: 'right' });//this.runningBill[i]['billPrev'] + this.runningBill[i]['billCurrent']
    arr.push({ text: (printobj['c1AfterOverextra'] + printobj['new_amt']).toFixed(2), alignment: 'right' });
    arr.push({ text: printobj['savingnewamt'], alignment: 'right' });//
    arr.push({ text: (printobj['c3AfterOverextra'] + printobj['excessant']).toFixed(2), alignment: 'right' });
    arr.push({ text: '', alignment: 'right' });//
    dd.content[dd.content.length - 1].table.body.push(arr);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var tbl2:any = {

      fontSize: 10,
      table: {

        headerRows: 0,
        widths: [150, 150, 150, 150, 145],

        body: [



        ]
      }
    };

    var arr = [];
    arr.push({ text: "SANCTION AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: workObj['agr_amt'], bold: true });
    arr.push('');
    arr.push({ text: "", bold: true });
    tbl2.table.body.push(arr);
    var arr = [];
    arr.push({ text: "DEVIATION AMOUNT ", bold: true });
    arr.push('Rs');
    arr.push({ text: (printobj['c1AfterOverextra'] + printobj['new_amt']).toFixed(2), bold: true });
    arr.push('');
    arr.push({ text: "", bold: true });
    tbl2.table.body.push(arr);
    var arr = [];
    arr.push({ text: "EXTRA  ITEMS AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['extraitemamt'], bold: true });
    arr.push({ text: this.printextra_data.length, bold: true });
    arr.push({ text: "nos", bold: true });
    tbl2.table.body.push(arr);



    var arr = [];
    arr.push({ text: "EXCESS  AMOUNT", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['excessant'], bold: true });
    arr.push(((printobj['excessant'] * 100) / ((printobj['c1AfterOverextra'] + printobj['new_amt']) - printobj['excessant'] + printobj['savingnewamt'])).toFixed(2));
    arr.push({ text: "%", bold: true });
    tbl2.table.body.push(arr);

    var arr = [];
    arr.push({ text: "SAVING AMOUNT ", bold: true });
    arr.push('Rs');
    arr.push({ text: printobj['savingnewamt'], bold: true });
    arr.push(((printobj['savingnewamt'] * 100) / ((printobj['c1AfterOverextra'] + printobj['new_amt']) - printobj['excessant'] + printobj['savingnewamt'])).toFixed(2));
    arr.push({ text: "%", bold: true });
    tbl2.table.body.push(arr);
    dd.content.push(tbl2);
    pdfMake.createPdf(dd).download('Deviation' + '.pdf');


  }


  async delete(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.value) {
        this.finaldelete1(element)
      }
    })
  }
  async finaldelete1(element:any) {
    this.spinner.show()
    var obj = { b_acct_id: this.b_acct_id, id: element['id'], tender_id: this.selectedTender['tender_id'] };
    console.log(obj)
    var resp = await this.DeviationService.deletedeviation(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.getTenderIdtab2();
      this.spinner.hide()
      Swal.fire("Success", "Deviation Deleted", "success");

    } else {
      this.spinner.hide()
      Swal.fire("Error", "Error while deleting Deviation", "error");
    }

  }


}
