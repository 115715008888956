<div *ngIf="false"> 
<div id="sidebar-collapse">
    <div class="admin-block d-flex">
        <div>
            <img [src]="imgURL" class="img-circle" width="53px" />

        </div>
        <div class="admin-info">
            <div class="font-strong">{{userInfo['party_name']}}</div><small></small></div>
    </div>
    <ul class="side-menu metismenu">
        <li>
            <a routerLinkActive="active" routerLink="/index"><i class="sidebar-item-icon fa fa-th-large"></i>
                <span class="nav-label">Dashboard</span>
            </a>
        </li>
        <li [hidden]="hidden">
            <a routerLinkActive="active" routerLink="/yourproducts"><i class="sidebar-item-icon fa fa-product-hunt"></i>
                <span class="nav-label">Your Products</span>
            </a>
        </li>
        <li [hidden]="hidden">
            <a routerLinkActive="active" routerLink="/users"><i class="sidebar-item-icon fa fa-users"></i>
                <span class="nav-label">Users</span>
            </a>
        </li>
        <li class="heading">Personal</li>
        <li>
            <a routerLink="/leave" routerLinkActive="active"><i class="sidebar-item-icon fa fa-truck"></i>
                <span class="nav-label">Leave</span>
            </a>
        </li>
        <!-- <li>
            <a routerLink="/salary" routerLinkActive="active"><i class="sidebar-item-icon fa fa-calendar"></i>
                <span class="nav-label">Salary</span>
            </a>
        </li>
        <li>
            <a routerLink="/task" routerLinkActive="active"><i class="sidebar-item-icon fa fa-tasks"></i>
                <span class="nav-label">Task</span>
            </a>
        </li> -->
        <li>
            <a routerLink="/profile" routerLinkActive="active"><i class="sidebar-item-icon fa fa-user"></i>
                <span class="nav-label">Profile</span>
            </a>
        </li>

        <!-- <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-money"></i>
                <span class="nav-label">Accounts Payables</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/forms/form_basic" routerLinkActive="active">Basic Forms</a>
                </li>
                <li>
                    <a routerLink="/forms/form_advanced" routerLinkActive="active">Advanced Plugins</a>
                </li>
                <li>
                    <a routerLink="/forms/form_masks" routerLinkActive="active">Form input masks</a>
                </li>
                <li>
                    <a routerLink="/forms/form_validation" routerLinkActive="active">Form Validation</a>
                </li>
                <li>
                    <a routerLink="/forms/text_editors" routerLinkActive="active">Text Editors</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-get-pocket"></i>
                <span class="nav-label">Accounts Recievables</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/tables/basic" routerLinkActive="active">Basic Tables</a>
                </li>
                <li>
                    <a routerLink="/tables/datatables" routerLinkActive="active">Datatables</a>
                </li>
            </ul>
        </li>
        <li class="heading">FEATURES</li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-bookmark"></i>
                <span class="nav-label">Basic UI</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/ui/colors" routerLinkActive="active">Colors</a>
                </li>
                <li>
                    <a routerLink="/ui/typography" routerLinkActive="active">Typography</a>
                </li>
                <li>
                    <a routerLink="/ui/panels" routerLinkActive="active">Panels</a>
                </li>
                <li>
                    <a routerLink="/ui/buttons" routerLinkActive="active">Buttons</a>
                </li>
                <li>
                    <a routerLink="/ui/tabs" routerLinkActive="active">Tabs</a>
                </li>
                <li>
                    <a routerLink="/ui/alerts" routerLinkActive="active">Alerts &amp; Tooltips</a>
                </li>
                <li>
                    <a routerLink="/ui/badges_progress" routerLinkActive="active">Badges &amp; Progress</a>
                </li>
                <li>
                    <a routerLink="/ui/lists" routerLinkActive="active">List</a>
                </li>
                <li>
                    <a routerLink="/ui/cards" routerLinkActive="active">Card</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-edit"></i>
                <span class="nav-label">Forms</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/forms/form_basic" routerLinkActive="active">Basic Forms</a>
                </li>
                <li>
                    <a routerLink="/forms/form_advanced" routerLinkActive="active">Advanced Plugins</a>
                </li>
                <li>
                    <a routerLink="/forms/form_masks" routerLinkActive="active">Form input masks</a>
                </li>
                <li>
                    <a routerLink="/forms/form_validation" routerLinkActive="active">Form Validation</a>
                </li>
                <li>
                    <a routerLink="/forms/text_editors" routerLinkActive="active">Text Editors</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-table"></i>
                <span class="nav-label">Tables</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/tables/basic" routerLinkActive="active">Basic Tables</a>
                </li>
                <li>
                    <a routerLink="/tables/datatables" routerLinkActive="active">Datatables</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-bar-chart"></i>
                <span class="nav-label">Charts</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/charts/charts_morris" routerLinkActive="active">Morris Charts</a>
                </li>
                <li>
                    <a routerLink="/charts/chartjs" routerLinkActive="active">Chart.js</a>
                </li>
                <li>
                    <a routerLink="/charts/charts_sparkline" routerLinkActive="active">Sparkline Charts</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-map"></i>
                <span class="nav-label">Maps</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/maps_vector" routerLinkActive="active">Vector maps</a>
                </li>
            </ul>
        </li>
        <li>
            <a routerLink="/ui/icons" routerLinkActive="active"><i class="sidebar-item-icon fa fa-smile-o"></i>
                <span class="nav-label">Icons</span>
            </a>
        </li>
        <li class="heading">PAGES</li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-envelope"></i>
                <span class="nav-label">Mailbox</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/mailbox/mailbox" routerLinkActive="active">Inbox</a>
                </li>
                <li>
                    <a routerLink="/mailbox/mail_view" routerLinkActive="active">Mail view</a>
                </li>
                <li>
                    <a routerLink="/mailbox/mail_compose" routerLinkActive="active">Compose mail</a>
                </li>
            </ul>
        </li>
        <li>
            <a routerLink="/calendar" routerLinkActive="active"><i class="sidebar-item-icon fa fa-calendar"></i>
                <span class="nav-label">Calendar</span>
            </a>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-file-text"></i>
                <span class="nav-label">Pages</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a routerLink="/invoice" routerLinkActive="active">Invoice</a>
                </li>
                <li>
                    <a routerLink="/profile" routerLinkActive="active">Profile</a>
                </li>
                <li>
                    <a routerLink="/login" routerLinkActive="active">Login</a>
                </li>
                <li>
                    <a routerLink="/register" routerLinkActive="active">Register</a>
                </li>
                <li>
                    <a routerLink="/lockscreen" routerLinkActive="active">Lockscreen</a>
                </li>
                <li>
                    <a routerLink="/forgot_password" routerLinkActive="active">Forgot password</a>
                </li>
                <li>
                    <a routerLink="/error_404" routerLinkActive="active">404 error</a>
                </li>
                <li>
                    <a routerLink="/error_500" routerLinkActive="active">500 error</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="javascript:;"><i class="sidebar-item-icon fa fa-sitemap"></i>
                <span class="nav-label">Menu Levels</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse">
                <li>
                    <a href="javascript:;">Level 2</a>
                </li>
                <li>
                    <a href="javascript:;">
                        <span class="nav-label">Level 2</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse">
                        <li>
                            <a href="javascript:;">Level 3</a>
                        </li>
                        <li>
                            <a href="javascript:;">Level 3</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li> -->
    </ul>
</div>
</div>