import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-accounts-footer]',
  templateUrl: './accounts-footer.component.html',
  styleUrls: ['./accounts-footer.component.css']
})
export class AccountsFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
