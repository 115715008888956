<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">{{mainService.allLableShowObj[mainService.language_cd+'GST1']}}
                        <!-- HSN Account Manage -->
                    </div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                {{mainService.allLableShowObj[mainService.language_cd+'GST2']}}
                                <!-- All HSN List -->
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle"></i>{{mainService.allLableShowObj[mainService.language_cd+'GST3']}}
                                <!-- Create HSN -->
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#tab-3" data-toggle="tab"><i
                                    class="ti-settings"></i>{{mainService.allLableShowObj[mainService.language_cd+'GST9']}}
                                <!-- Update HSN -->
                            </a>
                        </li>

                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-1">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)"
                                    placeholder="{{mainService.allLableShowObj[mainService.language_cd+'GST15']}}">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST5']}}
                                            <!-- ID -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; "
                                            class="right">
                                            {{ element.id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="effective_from_date">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Effective
                                            From Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.effective_from_date | date:'dd/MM/yyyy' }} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="hsn_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST6']}}
                                            <!-- HSN Code -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.hsn_code }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="hsn_desc">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST7']}}
                                            <!-- HSN Description -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.hsn_desc }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="cgst">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST8']}}
                                            <!-- CGST -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.cgst }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="sgst">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST10']}}
                                            <!-- SGST -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.sgst }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="igst">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST11']}}
                                            <!-- IGST -->

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.igst }} </td>
                                    </ng-container>



                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST12']}}
                                            <!-- Action -->
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-outline-primary"
                                                (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'GST13']}}
                                                <!-- Update -->
                                            </button>&nbsp;&nbsp;
                                            <button class="btn btn-outline-danger"
                                                (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'GST14']}}
                                                <!-- Delete -->
                                            </button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">

                            <form name="form" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>

                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'GST6']}}
                                        <!-- HSN Code: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['hsn_code']"  onkeypress="return /[0-9:.]/i.test(event.key)"
                                            name="hsn_code" #hsn_code="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="hsn_code.dirty && hsn_code.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && hsn_code.invalid" class="error-message">
                                            <div *ngIf="hsn_code.errors?.['required']">Please enter HSN Code
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'GST7']}}
                                        <!-- HSN Description : -->:
                                    </div>
                                    <div class="col-4">
                                        <textarea style="width: 100%;" id="re" name="re" class="form-control"
                                            [(ngModel)]="Obj['hsn_desc']" name="hsn_desc" #hsn_desc="ngModel"
                                            required></textarea>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="hsn_desc.dirty && hsn_desc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && hsn_desc.invalid" class="error-message">
                                            <div *ngIf="hsn_desc.errors?.['required']">Please enter HSN description
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        CGST In
                                        <!-- CGST In %: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['cgst']" name="cgst"
                                            #cgst="ngModel" required>

                                            <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="cgst.dirty && cgst.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && cgst.invalid" class="error-message">
                                            <div *ngIf="cgst.errors?.['required']">Please enter CGST In
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        SGST In
                                        <!-- SGST in %: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['sgst']" name="sgst"
                                            #sgst="ngModel" required>
                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="sgst.dirty && sgst.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && sgst.invalid" class="error-message">
                                            <div *ngIf="sgst.errors?.['required']">Please enter SGST In
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        IGST In
                                        <!-- IGST in %: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['igst']" name="igst"
                                            #igst="ngModel" required>


                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="igst.dirty && igst.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && igst.invalid" class="error-message">
                                            <div *ngIf="igst.errors?.['required']">Please enter IGST In
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->

                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Effective From Date:
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control" [(ngModel)]="Obj['effective_from_date']"
                                            name="date" #date="ngModel" required>


                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="date.dirty && date.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f.submitted && date.invalid" class="error-message">
                                            <div *ngIf="date.errors?.['required']">Enter Effective From Date
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary"
                                            type="submit">{{mainService.allLableShowObj[mainService.language_cd+'GST19']}}
                                            <!-- Submit -->
                                        </button>

                                        <button class="btn btn-success d-none" type="reset" id="subreset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="tab-3">
                            <form name="form" (ngSubmit)="f2.form.valid && update()" #f2="ngForm" novalidate>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'GST6']}}
                                        <!-- HSN Code: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['hsn_code']"
                                        name="hsn_code" #hsn_code="ngModel" required>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="hsn_code.dirty && hsn_code.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && hsn_code.invalid" class="error-message">
                                            <div *ngIf="hsn_code.errors?.['required']">Please enter HSN Code
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        {{mainService.allLableShowObj[mainService.language_cd+'GST7']}}
                                        <!-- HSN Description : -->:
                                    </div>
                                    <div class="col-4">
                                        <textarea style="width: 100%;" id="re" name="re" class="form-control"
                                            [(ngModel)]="Obj['hsn_desc']" name="hsn_desc" #hsn_desc="ngModel"
                                            required></textarea>

                                        <!---------------- validation start-----------------------------  -->
                                        <!-- border color green -->
                                        <div [class.border_green]="hsn_desc.dirty && hsn_desc.valid">
                                        </div>

                                        <!-- error handling  -->
                                        <div *ngIf="f2.submitted && hsn_desc.invalid" class="error-message">
                                            <div *ngIf="hsn_desc.errors?.['required']">Please enter HSN description
                                            </div>
                                        </div>
                                        <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        CGST In
                                        <!-- CGST In %: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['cgst']" name="cgst"
                                        #cgst="ngModel" required>
                                    <!---------------- validation start-----------------------------  -->
                                    <!-- border color green -->
                                    <div [class.border_green]="cgst.dirty && cgst.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && cgst.invalid" class="error-message">
                                        <div *ngIf="cgst.errors?.['required']">Please enter CGST In
                                        </div>
                                    </div>
                                    <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-4 text-right">
                                        SGST In
                                        <!-- SGST in %: -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['sgst']" name="sgst"
                                        #sgst="ngModel" required>
                                    <!---------------- validation start-----------------------------  -->
                                    <!-- border color green -->
                                    <div [class.border_green]="sgst.dirty && sgst.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && sgst.invalid" class="error-message">
                                        <div *ngIf="sgst.errors?.['required']">Please enter SGST In
                                        </div>
                                    </div>
                                    <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        IGST In
                                        <!-- IGST in % -->:
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['igst']" name="igst"
                                        #igst="ngModel" required>


                                    <!---------------- validation start-----------------------------  -->
                                    <!-- border color green -->
                                    <div [class.border_green]="igst.dirty && igst.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f2.submitted && igst.invalid" class="error-message">
                                        <div *ngIf="igst.errors?.['required']">Please enter IGST In
                                        </div>
                                    </div>
                                    <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>

                                <br>
                                <div class="row">
                                    <div class="col-4 text-right">
                                        Effective From Date:
                                    </div>
                                    <div class="col-4">
                                        <input type="date" class="form-control"
                                            [(ngModel)]="Obj['effective_from_date']" name="date" #date="ngModel" required>


                                            <!---------------- validation start-----------------------------  -->
                                            <!-- border color green -->
                                            <div [class.border_green]="date.dirty && date.valid">
                                            </div>

                                            <!-- error handling  -->
                                            <div *ngIf="f2.submitted && date.invalid" class="error-message">
                                                <div *ngIf="date.errors?.['required']">Please enter  Effective From Date
                                                </div>
                                            </div>
                                            <!---------------- validation end-----------------------------  -->
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <button class="btn btn-primary" type="submit">
                                            {{mainService.allLableShowObj[mainService.language_cd+'GST13']}}
                                            <!-- Update -->
                                        </button>
                                        <button class="btn btn-success d-none" id="upreset">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
