<div class="page-content fade-in-up">
  <div class="row">

    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title"><!-- Employee Report -->
            ANNUAL CONFIDENTIAL REPORT</div>
        </div>

        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item" *ngIf="firstLabel==true">
              <a class="nav-link active" href="#tab-2.1" data-toggle="tab" (click)="getCashierList()"><i
                  class="ti-bar-chart"></i>
                Your ACR-List</a>
            </li>
            <li class="nav-item" *ngIf="secondLabel==true">
              <a class="nav-link active" href="#tab-2" data-toggle="tab" (click)="specificACR()"><i
                  class="ti-bar-chart"></i>
                List For Send ACR To Upper officer</a>
            </li>
            <li class="nav-item" *ngIf="secondLabel==true">
              <a class="nav-link " href="#tab-3" data-toggle="tab" (click)="getPendingAcr()"><i
                  class="ti-bar-chart"></i>
                Pending ACR-List</a>
            </li>
            <li class="nav-item" *ngIf="historyView_flag_h!=true">
              <a class="nav-link " href="#tab-1" data-toggle="tab" (click)="getTheForm()">
                ACR Form
              </a>
            </li>
            <li class="nav-item " *ngIf="historyView_flag_h==true">
              <a class="nav-link active " href="#tab-4" data-toggle="tab" (click)="historyView()"><i
                  class="ti-bar-chart"></i>
                History</a>
            </li>
          </ul>


          <div class="tab-content">

            <div class="tab-pane fade show active " id="tab-1" *ngIf="historyView_flag==true">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-11 text-right ">
                  <button class="btn btn-info" (click)="backToHistory()">Go back</button>

                </div>
                <div class="col-1"></div>
              </div>
            </div>

            <!-- Cashir Start -->

            <div class="tab-pane fade show active " id="tab-1" *ngIf="casier_flag">

              <div class="row">
                <div class="col-4"></div>
                <div class="col-4"></div>
                <div class="col-4 text-right">मूल /दूसरी प्रति</div>
              </div>

              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <h1> <strong><b> उत्तर प्रदेश राज्य सेतु निगम लिमिटेड</b></strong></h1>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  गोपनीय आख्या :- लेखाकार/सहायक लेखाकार एवं कैशियर/लिपिक वर्गीय कर्मचारी/संगणक
                  /नक्शानवीस/अनुरेखक
                </div>
              </div>
              <br />
              <div class="row">
                <!-- <div class="col-1"></div> -->
                <div class="col-4 text-right">आख्या की अवधि</div>
                <div class="col-2 text-center">
                  <input type="date" class="form-control" [(ngModel)]="CasrObj['from_time']"
                    [disabled]="casierDisabled" />
                </div>
                <div class="col-1" style="max-width: 2.333333%;">से</div>
                <div class="col-2 text-center">
                  <input type="date" class="form-control" [(ngModel)]="CasrObj['to_time']"
                    [disabled]="casierDisabled" />
                </div>

                <div class="col-1">तक</div>
                <div class="col-1"></div>
              </div>
              <br />

              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 1.&nbsp; पूरा नाम </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObj['full_name']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 2.&nbsp; पिता का नाम </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObj['father_name']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 3.&nbsp; पद </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObj['post']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 4.&nbsp;जन्म-तिथि</div>
                <div class="col-4"><input type="date" class="form-control" [(ngModel)]="CasrObj['dob']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 5.&nbsp;स0 नि0 विभाग /निगम में प्रथम नियुक्ति की तिथि</div>
                <div class="col-4"><input type="date" class="form-control" [(ngModel)]="CasrObj['first_nyukti_date']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 6.&nbsp;निगम में पदोन्नति की तिथि, यदि कोई है</div>
                <div class="col-4"><input type="date" class="form-control" [(ngModel)]="CasrObj['panoti_date']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 7.&nbsp;वर्तमान पद पर नियुक्ति की तिथि</div>
                <div class="col-4"><input type="date" class="form-control" [(ngModel)]="CasrObj['curr_post_date']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 8.&nbsp;अस्थाई अथवा स्थाई</div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObj['stbl_unstabl']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 9.&nbsp;इकाइयों के नाम जिनमें आख्या की अवधि में स्थापित
                </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObj['unit_name']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <br>
              <div class="row">
                <div class="col-1 text-right"> (अ)</div>
                <div class="col-2 text-left"> <input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_date_1']" [disabled]="casierDisabled"></div>
                <div class="col-1 text-center"> से </div>
                <div class="col-3 text-left"><input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_to_date_1']" [disabled]="casierDisabled"></div>
                <div class="col-1">तक </div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left"><input type="text" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_office_1']" [disabled]="casierDisabled"></div>
                <!-- <div class="col-1"></div> -->

              </div>
              <br>
              <div class="row">
                <div class="col-1 text-right">(ब) </div>
                <div class="col-2 text-left"> <input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_date_2']" [disabled]="casierDisabled"></div>
                <div class="col-1 text-center"> से </div>
                <div class="col-3 text-left"><input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_to_date_2']" [disabled]="casierDisabled"></div>
                <div class="col-1">तक </div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left"><input type="text" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_office_2']" [disabled]="casierDisabled"></div>
                <!-- <div class="col-1"></div> -->

              </div>
              <br>
              <div class="row">
                <div class="col-1 text-right"> (स) </div>
                <div class="col-2 text-left"> <input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_date_3']" [disabled]="casierDisabled"></div>
                <div class="col-1 text-center"> से </div>
                <div class="col-3 text-left"><input type="date" class="form-control"
                    [(ngModel)]="CasrObj['unit_to_date_3']" [disabled]="casierDisabled"></div>
                <div class="col-1">तक </div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left"><input type="text" class="form-control"
                    [(ngModel)]="CasrObj['unit_from_office_3']" [disabled]="casierDisabled"></div>
                <!-- <div class="col-1"></div> -->

              </div>
              <br>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 10.&nbsp;वर्ष में ली गई छुट्टियाँं</div>
                <div class="col-4">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> आकस्मिक अवकाश</div>
                <div class="col-4"><input type="number" class="form-control" [(ngModel)]="CasrObj['unexpected_holiday']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> उपार्जित अवकाश </div>
                <div class="col-4"><input type="number" class="form-control" [(ngModel)]="CasrObj['accur_holiday']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> चिकित्सा अवकाश</div>
                <div class="col-4"><input type="number" class="form-control" [(ngModel)]="CasrObj['medical_holiday']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> अन्य अवकाश</div>
                <div class="col-4"><input type="number" class="form-control" [(ngModel)]="CasrObj['other_holiday']"
                    [disabled]="casierDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row" *ngIf="emp_role=='ACR_11'  && updateFlag!=true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="cashierSelf()"> SAVE </button>
                </div>
              </div>
              <div class="row" *ngIf="emp_role=='ACR_11' && updateFlag==true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="self_Update()"> Update </button>
                </div>
              </div>




              <!-- <div class="row">
                                <div class="col-12">
                                    <hr class="hrline">
                                </div>

                            </div>
                            <br> -->



            </div>

            <div class="tab-pane  show active " id="tab-1" *ngIf="casier_flag_admin">

              <div *ngIf="cashierAdminDisabled==false && updateFlag!=true">

                <div class="row">
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>
                  <div class="col-6">
                    <hr class="hrline">
                  </div>
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>

                </div>
                <br>

                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-danger" (click)="navigateToNextPage_forReject(rejectData)">Reject</button>
                  </div>

                </div>
                <br>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>

              <div class="row">
                <div class="col-12 text-center">
                  <h4> <strong> सत्यनिष्ठा का प्रमाण पत्र</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" style="width: 20px;height: 20px;" class="form-check-input" name="check"
                      [(ngModel)]="positive" (click)="checkCheckbox()" id="myCheckbox1"
                      [disabled]="cashierAdminDisabled" />
                  </h4>
                </div>
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" style="width: 20px;height: 20px;" class="form-check-input" name="check"
                      [(ngModel)]="negative" (click)="checkCheckbox()" id="myCheckbox2"
                      [disabled]="cashierAdminDisabled" />
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-10 text-left">मेरी जानकारी में कोई ऐसा तथ्य नहीं आया जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="CasrObjadmin['p_name']"
                        [disabled]="!positive">
                    </div>
                    <!-- <div class="col-4 text-left">श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-12 text-left"> की सत्य निष्ठा पर सन्देह उत्पन्न करे। ईमानदारी के
                      लिए सामान्यता उनकी प्रसिद्धि अच्छी है। मैं उनकी सत्यनिष्ठा प्रमाणित करता
                      हूँ।</div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="CasrObjadmin['p_sign']" [disabled]="!positive ">
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-1 vertical-line" style="max-width: 2.333333%;">
                  <!-- <hr class="vertical-line"> -->
                </div>
                <div class="col-5 text-left">
                  <div class="row">
                    <div class="col-11 text-left">निम्नलिखित तथ्य मेरे सामने आये हैं जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="CasrObjadmin['n_name']"
                        [disabled]="!negative ">
                    </div>
                    <!-- <div class="col-4 text-left"> श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-6 text-left"> की सत्यनिष्ठा पर सन्देह उत्पन्न करते हैं

                    </div>
                    <!-- <div class="col-4 text-left"><input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-11 text-left">
                      <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['n_text']"
                        [disabled]="!negative ">
                    </div>
                  </div>
                  <br>
                  <!-- <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" [(ngModel)]="CasrObjadmin['n_sign']" [disabled]="!negative "> </div>
                      </div>


                    </div>

                  </div> -->
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="CasrObjadmin['n_sign']" [disabled]="!negative ">
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </div>


              <br>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 1.&nbsp; चरित्र एवं स्वभाव</div>
                <div class="col-4">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (क) &nbsp;उपस्थिति </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option1_a']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (ख) &nbsp;अनुशासन के प्रति उत्तरदायित्व</div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option1_b']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 2.&nbsp; विभागीय प्रवीणता</div>
                <div class="col-4">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (क) &nbsp;आलेख लिखने की क्षमता </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option2_a']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (ख) &nbsp;नियम, विनियम, कार्य विधि का ज्ञान</div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option2_b']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 3.&nbsp; केवल लेखाकार,सहायक लेखाकार एवं कैशियर के लिये
                </div>
                <div class="col-4">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (क) &nbsp;आख्या की अवधि वे अवशेष कार्य /बकाया मदों को कम
                  करने में सफल रहे </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option3_a']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (ख) &nbsp;क्या उन्होने कोई उत्कृष्ट /प्रशंसात्मक कार्य किया
                  है ? </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option3_b']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (ग) &nbsp;पदोन्नति के लिये उपयुक्त </div>
                <div class="col-4"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option3_c']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> (घ) &nbsp;क्या वे इकाई का स्वतंत्र रूप से भलीभांति प्रबन्ध
                  करने में समर्थ हैं ? </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option3_d']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> 4.&nbsp; केवल संगणक/नक्शा नवीस/अनुरेखक टंकण/टंकण के लिये
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option4_a']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>

              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left"> निष्पादन में परिशुद्धता एवं स्वच्छता</div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option4_b']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">5 &nbsp; केवल लिपिक वर्गीय कर्मचारियों के लिये</div>
                <div class="col-4">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">(क) &nbsp; क्या वह कार्यालय की पत्रावलियाँ भलीभॉंति रखते
                  हैं ? </div>
                <div class="col-4">
                  <ng-select [items]="t_f" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                    [(ngModel)]="CasrObjadmin['option5_a']" [disabled]="cashierAdminDisabled" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">(ख)&nbsp; क्या वह कार्य का निस्तारण शीघ्र करते हैं ?</div>
                <div class="col-4">
                  <ng-select [items]="t_f" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                    [(ngModel)]="CasrObjadmin['option5_b']" [disabled]="cashierAdminDisabled" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">6 &nbsp; क्या वे वर्तमान पद/स्थाईकरण पदोन्नति हेतु योग्य है
                  ?
                </div>
                <div class="col-4">
                  <ng-select [items]="t_f" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                    [(ngModel)]="CasrObjadmin['option6']" [disabled]="cashierAdminDisabled" [selectableGroup]="true"
                    [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name">
                  </ng-select>
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">7 &nbsp;
                  कोई विशेष टिप्पणी/क्या उन्होने कोई प्रशंसात्मक कार्य किया है
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option7']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">8 &nbsp;
                  कोई प्रतिकूल प्रविष्टि मय दण्ड, चेतावनी, अप्रसन्नता जो उन्हें लिखित दी गयी हो ?

                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option8']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5 text-left">9 &nbsp;
                  सामान्य निर्धारण
                </div>
                <div class="col-5">
                  अधिकारी/कर्मचारी के हस्ताक्षर जिन्होने आख्या लिखी</div>
                <!-- <div class="col-3"></div> -->
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5 text-left">

                </div>
                <div class="col-5">
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option9_a']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <!-- <div class="col-3"></div> -->
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-2 text-left">
                  स्थान
                </div>
                <div class="col-3"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option9_b']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-2 text-left">
                  पद
                </div>
                <div class="col-3"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option9_c']"
                    [disabled]="cashierAdminDisabled"></div>
                <!-- <div class="col-3"></div> -->
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-2 text-left">
                  तिथि
                </div>
                <div class="col-3"><input type="date" class="form-control" [(ngModel)]="CasrObjadmin['option9_d']"
                    [disabled]="cashierAdminDisabled"></div>
                <div class="col-2 text-left">
                  पद
                </div>
                <div class="col-3"><input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option9_e']"
                    [disabled]="cashierAdminDisabled"></div>
                <!-- <div class="col-3"></div> -->
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5 text-left">नोट: चिन्हित के सम्मुख निम्नलिखित श्रेणियों में कोई एक श्रेणी का उल्लेख
                  करें।

                </div>
                <div class="col-2">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-3 text-left">
                  उत्कृष्ट/अति उत्तम/उत्तम/अच्छा/खराब
                </div>
                <div class="col-4">
                  <!-- <ng-select [items]="check_best" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [(ngModel)]="CasrObjadmin['option10']"
                    [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="field_name" [disabled]="cashierAdminDisabled">
                  </ng-select> -->
                  <input type="text" class="form-control" [(ngModel)]="CasrObjadmin['option10']"
                    [disabled]="cashierAdminDisabled">
                </div>
                <div class="col-3"></div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>


              <!-- <div class="row" *ngIf="cashierAdminDisabled!=true && updateFlag==false"> -->
              <div class="row" *ngIf="emp_role=='off1' && cashierAdminDisabled!=true && updateFlag!=true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="casadmin_admin_save()">Save</button>
                </div>
              </div>
              <!-- <div class="row" *ngIf="cashierAdminDisabled!=true && updateFlag==true"> -->
              <div class="row" *ngIf="emp_role=='off1' && cashierAdminDisabled!=true && updateFlag==true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="casadmin_admin_Update()">Update</button>
                </div>
              </div>
              <div *ngIf="cashierAdminDisabled!=true" class="row">
                <div class="col-12 text-center">अन्य अधिकारियों की प्रविष्टियाँ उनके पद सहित</div>
              </div>
            </div>

            <!-- Caishir End-->


            <!-- m_avinash -->
            <!-- gairtakniki Strat -->

            <div class="tab-pane fade show active" *ngIf="gairtakniki_flag" id="tab-1">
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4"></div>
                <div class="col-4 text-right">मूल /दूसरी प्रति</div>
              </div>

              <br />
              <div class="row">
                <div class="col-12 text-center">
                  <h1> <strong><b> उत्तर प्रदेश राज्य सेतु निगम लिमिटेड</b></strong></h1>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12 text-center">
                  <h4> <b>गैर तकनीकी अधिकारियों की गोपनीय आख्या</b></h4>
                </div>
              </div>
              <br>


              <div class="row">
                <div class="col-12 text-center">
                  वार्षिक गोपनीय आख्या श्री :&nbsp;<input type="text" [(ngModel)]="gtFormObj['acr_name']"
                    [disabled]='gtDisabled'>
                </div>
              </div>
              <br />
              <div class="row">
                <!-- <div class="col-1"></div> -->
                <div class="col-4 text-right">आख्या की अवधि</div>
                <div class="col-2 text-center">
                  <input type="date" [(ngModel)]="gtFormObj['from_time']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1" style="max-width: 2.333333%;">से</div>
                <div class="col-2 text-center">
                  <input type="date" [(ngModel)]="gtFormObj['to_time']" class="form-control" [disabled]='gtDisabled' />
                </div>

                <div class="col-1">तक</div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">1.&nbsp; पूरा नाम</div>
                <div class="col-4">
                  <input type="text" [(ngModel)]="gtFormObj['full_name']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">2.&nbsp; पिता का नाम</div>
                <div class="col-4">
                  <input type="text" [(ngModel)]="gtFormObj['father_name']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">3.&nbsp;पद</div>
                <div class="col-4">
                  <input type="text" [(ngModel)]="gtFormObj['post']" class="form-control" [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">4.&nbsp;जन्म-तिथि</div>
                <div class="col-4">
                  <input type="date" [(ngModel)]="gtFormObj['dob']" class="form-control" [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  5.&nbsp;पैत्रिक विभाग/निगम में प्रथम नियुक्ति की तिथि
                </div>
                <div class="col-4">
                  <input type="date" [(ngModel)]="gtFormObj['first_nyukti_date']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  6.&nbsp;निगम में पदोन्नति की तिथि, यदि कोई है
                </div>
                <div class="col-4">
                  <input type="date" [(ngModel)]="gtFormObj['panoti_date']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  7.&nbsp;वर्तमान पद पर नियुक्ति की तिथि
                </div>
                <div class="col-4">
                  <input type="date" [(ngModel)]="gtFormObj['curr_post_date']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />


              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">8.&nbsp;अस्थाई अथवा स्थाई</div>
                <div class="col-4">
                  <input type="text" [(ngModel)]="gtFormObj['stbl_unstabl']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  9.&nbsp;मुख्यालय के वर्ग/इकाईयों के नाम जिनमें आख्या की अवधि
                  में स्थापित रहे
                </div>
                <div class="col-4">
                  <input type="text" [(ngModel)]="gtFormObj['unit_name']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />

              <br />
              <div class="row">
                <div class="col-1 text-right">(अ)</div>
                <div class="col-2 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_from_date_1']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_to_date_1']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" [(ngModel)]="gtFormObj['unit_from_office_1']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />
              <div class="row">
                <div class="col-1 text-right">(ब)</div>
                <div class="col-2 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_from_date_2']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_to_date_2']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" [(ngModel)]="gtFormObj['unit_from_office_2']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />
              <div class="row">
                <div class="col-1 text-right">(स)</div>
                <div class="col-2 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_from_date_3']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" [(ngModel)]="gtFormObj['unit_to_date_3']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" [(ngModel)]="gtFormObj['unit_from_office_3']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                  10.&nbsp;आख्या की अवधि में कार्यों पर स्थापित रहे, उनका विवरण
                  एवं स्वयं का विश्लेषण (सम्बन्धित अधिकारियों द्वारा दिया
                  जायेगा)
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-9">
                  <input type="text" [(ngModel)]="gtFormObj['unexpected_holiday']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-9">
                  <input type="text" [(ngModel)]="gtFormObj['accur_holiday']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-9">
                  <input type="text" [(ngModel)]="gtFormObj['medical_holiday']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-9">
                  <input type="text" [(ngModel)]="gtFormObj['other_holiday']" class="form-control"
                    [disabled]='gtDisabled' />
                </div>
              </div>
              <br />
              <br />
              <!-- <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span style="border-bottom: 1px black">
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div> -->
              <br />
              <div class="row">
                <div class="col-12 text-center" *ngIf="emp_role=='ACR_22' && updateFlag!=true">
                  <button class="btn btn-info" (click)="saveGtForm()">Save</button>
                </div>
                <div class="col-12 text-center" *ngIf="updateFlag==true && emp_role=='ACR_22'">
                  <button class="btn btn-info" (click)="updateGtForm()">Update Record</button>
                </div>


              </div>
              <br />


              <!-- anudesh data add -->

              <!-- anudesh  end -->

            </div>

            <!-- gairtakniki end here -->

            <!-- gairtakniki  admin Start code -->
            <div class="tab-pane fade show active" *ngIf="gairtakniki_admin" id="tab-1">

              <div *ngIf="gtAdminDisabled==false && updateFlag!=true">

                <div class="row">
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>
                  <div class="col-6">
                    <hr class="hrline">
                  </div>
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>

                </div>
                <br>

                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-danger" (click)="navigateToNextPage_forReject(rejectData)">Reject</button>
                  </div>

                </div>
                <br>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>

              <div class="row">
                <div class="col-12 text-center">
                  <h4> <strong> सत्यनिष्ठा का प्रमाण पत्र</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" #check1 style="width: 20px;height: 20px;" class="form-check-input"
                      name="check" [(ngModel)]="positive" (click)="checkCheckbox()" id="myCheckbox1"
                      [disabled]="gtAdminDisabled" />
                  </h4>
                </div>
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" style="width: 20px;height: 20px;" class="form-check-input" name="check"
                      [(ngModel)]="negative" (click)="checkCheckbox()" id="myCheckbox2" [disabled]="gtAdminDisabled" />
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-10 text-left">मेरी जानकारी में कोई ऐसा तथ्य नहीं आया जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="gtadminObj['p_name']"
                        [disabled]="!positive">
                    </div>
                    <!-- <div class="col-4 text-left">श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-12 text-left"> की सत्य निष्ठा पर सन्देह उत्पन्न करे। ईमानदारी के
                      लिए सामान्यता उनकी प्रसिद्धि अच्छी है। मैं उनकी सत्यनिष्ठा प्रमाणित करता
                      हूँ।</div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="gtadminObj['p_sign']" [disabled]="!positive">
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-1 vertical-line" style="max-width: 2.333333%;">
                  <!-- <hr class="vertical-line"> -->
                </div>
                <div class="col-5 text-left">
                  <div class="row">
                    <div class="col-11 text-left">निम्नलिखित तथ्य मेरे सामने आये हैं जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="gtadminObj['n_name']"
                        [disabled]="!negative">
                    </div>
                    <!-- <div class="col-4 text-left"> श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-6 text-left"> की सत्यनिष्ठा पर सन्देह उत्पन्न करते हैं

                    </div>
                    <!-- <div class="col-4 text-left"><input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-11 text-left">
                      <input type="text" class="form-control" [(ngModel)]="gtadminObj['n_text']"
                        [disabled]="!negative ">
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" [(ngModel)]="gtadminObj['n_sign']"
                            [disabled]="!negative"> </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div>


              <br>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>

              <!-- satynistha -->
              <br />

              <div class="row">
                <div class="col-1 text-center">1. &nbsp;</div>
                <div class="col-3"><b>व्यवसायिक कौशल</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  पद से सम्बन्धित नियम, विनियम, कार्य विधि का ज्ञान एवं उसके अधावधिक रखने का प्रयास
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option1_a']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  अग्रसरता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option1_b']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  संगठन करने की क्षमता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option1_c']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-5">
                  कर्मचारियों के प्रति व्यवहार एवं नियंत्रण
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option1_d']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(च) </div>
                <div class="col-5">
                  दौरा करने की क्षमता (यदि लागू हो)
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option1_e']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <!-- vayakigat kausal  -->

              <div class="row">
                <div class="col-1 text-center">2. &nbsp;</div>
                <div class="col-3"><b> व्यक्तिगत कौशल</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  आचरण
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option2_a']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  निर्णय
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option2_b']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  चतुराई
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option2_c']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-5">
                  स्वास्थ्य
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option2_d']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(च) </div>
                <div class="col-5">
                  कर्मनिष्ठा
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option2_e']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <!-- office Work -->


              <div class="row">
                <div class="col-1 text-center">3. &nbsp;</div>
                <div class="col-3"><b>आफिस वर्क</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  लेखा स्थिति (यदि लागू हो)
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option3_a']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  कार्यालय की दशा
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option3_b']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  सरकारी कार्य तथा पत्रों के निस्तारण की क्षमता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option3_c']"
                    [disabled]="gtAdminDisabled" />
                </div>
              </div>
              <br />

              <!-- other worrd -->


              <div class="row">
                <div class="col-1 text-center">4. &nbsp;</div>
                <div class="col-4"> <b>कोई अन्य टिप्पणी यदि उच्च अधिकारी लिखना चाहें</b>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                  <textarea cols="100" rows="5" placeholder=" यहाँ पर लिखे -" [(ngModel)]="gtadminObj['option4']"
                    [disabled]="gtAdminDisabled"></textarea>
                </div>
                <div class="col-2"></div>
              </div>
              <br />

              <!-- satynistha  -->

              <!-- <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br> -->

              <!-- <div class="row">
                <div class="col-1 text-right"> 5.</div>
                <div class="col-11 text-center">
                  <h4> <strong> सत्यनिष्ठा का प्रमाण पत्र</strong></h4>
                </div>
              </div> -->

              <!-- <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-10 text-left">मेरी जानकारी में कोई ऐसा तथ्य नहीं आया जो श्री &nbsp;<input
                        type="text" class="form-control" [(ngModel)]="gtadminObj['']">
                    </div [disabled]="gtAdminDisabled">
                    -->

              <!-- </div>
                  <div class="row">
                    <div class="col-12 text-left"> की सत्य निष्ठा पर सन्देह उत्पन्न करे। ईमानदारी के
                      लिए सामान्यता उनकी प्रसिद्धि अच्छी है। मैं उनकी सत्यनिष्ठा प्रमाणित करता
                      हूॅं।</div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text"> </div>
                      </div>


                    </div>

                  </div>

                </div>
                <div class="col-1 vertical-line" style="max-width: 2.333333%;"> -->
              <!-- <hr class="vertical-line"> -->
              <!-- </div>
                <div class="col-5 text-left">
                  <div class="row">
                    <div class="col-10 text-left">निम्नलिखित तथ्य मेरे सामने आये हैं जो श्री &nbsp;<input type="text"
                        class="form-control">
                    </div>


                  </div>
                  <div class="row">
                    <div class="col-6 text-left"> की सत्यनिष्ठा पर सन्देह उत्पन्न करते हैं:-

                    </div> -->
              <!-- <div class="col-4 text-left"><input type="text" class="form-control" [(ngModel)]="gtadminObj['']"></div> [disabled]="gtAdminDisabled" -->

              <!-- </div>
                  <div class="row">
                    <div class="col-11 text-left">
                      <input type="text" class="form-control" [(ngModel)]="gtadminObj['']">
                    </div [disabled]="gtAdminDisabled">
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text"> </div>
                      </div>


                    </div>

                  </div>
                </div>
              </div> -->


              <br>
              <div class="row">
                <div class="col-1 text-center">5. &nbsp;</div>
                <div class="col-3"><b>श्रेणी ( उत्कृष्ट/अति उत्तम/उत्तम/अच्छा/खराब )</b>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-6 text-center">
                  <!-- <select class="form-control text-center" [(ngModel)]="shreniObj">
                    <option *ngFor="let item of shreni" [value]="item">{{item}}</option>
                  </select> -->
                  <input type="text" class="form-control" [(ngModel)]="gtadminObj['option5_a']"
                    [disabled]="gtAdminDisabled">
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6 text-right">हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="gtadminObj['option5_b']" [disabled]="gtAdminDisabled"> </div>
                      </div>
                    </div>
                    <div class="col-6 ">

                    </div>

                  </div>

                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span>
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br>
              <br>

              <div class="row">

                <div class="col-12 text-center" *ngIf="updateFlag!=true && emp_role=='ACR_22_OFFICER'">
                  <button class="btn btn-info" (click)="save_GT_officer1()">Save</button>
                </div>
                <div class="col-12 text-center" *ngIf="updateFlag==true && emp_role=='ACR_22_OFFICER'">
                  <button class="btn btn-info" (click)="save_GT_officer1_Update()">Update</button>
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span>
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">

                <div class="col-12 text-center">
                  <h4> <strong>अनुदेश</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  1. तकनीकी योग्यता केवल शैक्षिक उपलब्धियों तक ही सीमित नहीं है अपितु यह भी कि व तकनीकी में आधुनिक विकास
                  की जानकारी भी रखते हैं। क्या उनकी कोई विशेष योग्यतायें हैं अथवा उन्हें किसी विशेष कार्य में निश्चित
                  प्रवीणता प्राप्त की है। क्या उक्त अवधि में उन्होंने इंगित करने योग्य कोई विशेष तकनीकी योगदान किया।
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  2. इस बात को भी विशेष रूप से इंगित किया जाना चाहिए कि वह आदेशों का तुरन्त तथा व्यवस्थपित रूप से
                  अनुपालन तथा समस्याओं का तत्कालिक रूप निवारण करते हैं। क्या उनके द्वारा प्रस्ताव सावधानी पूर्वक विचार
                  के पश्चात दिये जाते हैं। क्या वह मितव्ययी है। क्या व नियमित रूप से दौरा करते हैं और दौरे को अधिकतम लाभ
                  के लिए उपभोग करते हैं। क्या उनके अथवा कार्यालय के विरूद्ध गम्भीर आपत्तियाँ हैं।
                </div>
              </div> -->

            </div>

            <!-- gairtakniki  admin end code -->

            <!-- adhikar / Upadhikari Start -->

            <div class="tab-pane fade show active" *ngIf="officer" id="tab-1">
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4"></div>
                <div class="col-4 text-right">मूल /दूसरी प्रति</div>
              </div>

              <br />
              <div class="row">
                <div class="col-12 text-center">
                  <h1> <strong><b> उत्तर प्रदेश राज्य सेतु निगम लिमिटेड</b></strong></h1>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12 text-center">
                  <h4> <b>अधिकारियों/उप अभियन्ताओं की गोपनीय आख्या</b></h4>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  वार्षिक गोपनीय आख्या श्री :<input type="text" [(ngModel)]="upOffObj['acr_name']"
                    [disabled]="upoffDisable">
                </div>
              </div>
              <br />
              <div class="row">
                <!-- <div class="col-1"></div> -->
                <div class="col-4 text-right">आख्या की अवधि</div>
                <div class="col-2 text-center">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['from_time']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1" style="max-width: 2.333333%;">से</div>
                <div class="col-2 text-center">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['to_time']" [disabled]="upoffDisable" />
                </div>

                <div class="col-1">तक</div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">1.&nbsp; पूरा नाम</div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['full_name']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">2.&nbsp; पिता का नाम</div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['father_name']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">3.&nbsp;पद</div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['post']" [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">4.&nbsp;जन्म-तिथि</div>
                <div class="col-4">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['dob']" [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  5.&nbsp;ले0 नि0 विभाग /निगम में प्रथम नियुक्ति की तिथि
                </div>
                <div class="col-4">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['first_nyukti_date']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  6.&nbsp;निगम में पदोन्नति की तिथि, यदि कोई है
                </div>
                <div class="col-4">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['panoti_date']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  7.&nbsp;वर्तमान पद पर नियुक्ति की तिथि
                </div>
                <div class="col-4">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['curr_post_date']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />


              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">8.&nbsp;अस्थाई अथवा स्थाई</div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['stbl_unstabl']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-3 text-left">
                  9.&nbsp;इकाईयों के नाम जिनमें आख्या की अवधि
                  में स्थापित रहे
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['unit_name']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-2"></div>
                <div class="col-1"></div>
              </div>
              <br />

              <br />
              <div class="row">
                <div class="col-1 text-right">(अ)</div>
                <div class="col-2 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_from_date_1']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_to_date_1']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['unit_from_office_1']"
                    [disabled]="upoffDisable" />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />
              <div class="row">
                <div class="col-1 text-right">(ब)</div>
                <div class="col-2 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_from_date_2']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_to_date_2']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['unit_from_office_2']"
                    [disabled]="upoffDisable" />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />
              <div class="row">
                <div class="col-1 text-right">(स)</div>
                <div class="col-2 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_from_date_3']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1 text-center">से</div>
                <div class="col-3 text-left">
                  <input type="date" class="form-control" [(ngModel)]="upOffObj['unit_to_date_3']"
                    [disabled]="upoffDisable" />
                </div>
                <div class="col-1">तक</div>
                <div class="col-1 text-left">कार्यालय</div>
                <div class="col-3 text-left">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['unit_from_office_3']"
                    [disabled]="upoffDisable" />
                </div>
                <!-- <div class="col-1"></div> -->
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                  10.&nbsp;आख्या की अवधि में कार्यों पर स्थापित रहे, उनका विवरण
                  एवं स्वयं का विश्लेषण (सम्बन्धित अधिकारियों द्वारा दिया
                  जायेगा)
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-9">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['unexpected_holiday']"
                    [disabled]="upoffDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-9">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['accur_holiday']"
                    [disabled]="upoffDisable" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-9">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['medical_holiday']"
                    [disabled]="upoffDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-9">
                  <input type="text" class="form-control" [(ngModel)]="upOffObj['other_holiday']"
                    [disabled]="upoffDisable" />
                </div>
              </div>
              <br />
              <br />
              <!-- <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span style="border-bottom: 1px black">
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br /> -->
              <!-- officer_admin code start -->

              <div class="row">

                <div class="col-12 text-center" *ngIf="updateFlag!=true && emp_role=='ACR_33'">
                  <button class="btn btn-info" (click)="save_Upoff_self()">Save</button>
                </div>
                <div class="col-12 text-center" *ngIf="updateFlag==true && emp_role=='ACR_33'">
                  <button class="btn btn-info" (click)="update_Upoff_self()">Update</button>
                </div>
              </div>

              <!--  officer admin code end -->
              <!-- <div class="row">

                <div class="col-12 text-center">
                  <h4> <strong>अनुदेश</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  1. तकनीकी योग्यता केवल शैक्षिक उपलब्धियों तक ही सीमित नहीं है अपितु यह भी कि व तकनीकी में आधुनिक विकास
                  की जानकारी भी रखते हैं। क्या उनकी कोई विशेष योग्यतायें हैं अथवा उन्हें किसी विशेष कार्य में निश्चित
                  प्रवीणता प्राप्त की है। क्या उक्त अवधि में उन्होंने इंगित करने योग्य कोई विशेष तकनीकी योगदान किया।
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  2. इस बात को भी विशेष रूप से इंगित किया जाना चाहिए कि वह आदेशों का तुरन्त तथा व्यवस्थपित रूप से
                  अनुपालन तथा समस्याओं का तत्कालिक रूप निवारण करते हैं। क्या उनके द्वारा प्रस्ताव सावधानी पूर्वक विचार
                  के पश्चात दिये जाते हैं। क्या वह मितव्ययी है। क्या व नियमित रूप से दौरा करते हैं और दौरे को अधिकतम लाभ
                  के लिए उपभोग करते हैं। क्या उनके अथवा कार्यालय के विरूद्ध गम्भीर आपत्तियाँ हैं।
                </div>
              </div> -->



            </div>

            <div class="tab-pane fade show active" *ngIf="officer_admin" id="tab-1">

              <div *ngIf="upoffAdminDisable==false && updateFlag!=true">

                <div class="row">
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>
                  <div class="col-6">
                    <hr class="hrline">
                  </div>
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>

                </div>
                <br>

                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-danger" (click)="navigateToNextPage_forReject(rejectData)">Reject</button>
                  </div>

                </div>
                <br>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br>

              <div class="row">
                <div class="col-12 text-center">
                  <h4> <strong> सत्यनिष्ठा का प्रमाण पत्र</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" #check1 style="width: 20px;height: 20px;" class="form-check-input"
                      name="check" [(ngModel)]="positive" (click)="checkCheckbox()" id="myCheckbox1"
                      [disabled]="upoffAdminDisable" />
                  </h4>
                </div>
                <div class="col-6 text-center">
                  <h4>
                    <input type="checkbox" style="width: 20px;height: 20px;" class="form-check-input" name="check"
                      [(ngModel)]="negative" (click)="checkCheckbox()" id="myCheckbox2"
                      [disabled]="upoffAdminDisable" />
                  </h4>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-10 text-left">मेरी जानकारी में कोई ऐसा तथ्य नहीं आया जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="upOffAdmin['p_name']"
                        [disabled]="!positive">
                    </div>
                    <!-- <div class="col-4 text-left">श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-12 text-left"> की सत्य निष्ठा पर सन्देह उत्पन्न करे। ईमानदारी के
                      लिए सामान्यता उनकी प्रसिद्धि अच्छी है। मैं उनकी सत्यनिष्ठा प्रमाणित करता
                      हूँ।</div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="upOffAdmin['p_sign']" [disabled]="!positive">
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
                <div class="col-1 vertical-line" style="max-width: 2.333333%;">
                  <!-- <hr class="vertical-line"> -->
                </div>
                <div class="col-5 text-left">
                  <div class="row">
                    <div class="col-11 text-left">निम्नलिखित तथ्य मेरे सामने आये हैं जो श्री
                      &nbsp;<input type="text" class="form-control" [(ngModel)]="upOffAdmin['n_name']"
                        [disabled]="!negative">
                    </div>
                    <!-- <div class="col-4 text-left"> श्री &nbsp;<input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-6 text-left"> की सत्यनिष्ठा पर सन्देह उत्पन्न करते हैं

                    </div>
                    <!-- <div class="col-4 text-left"><input type="text" class="form-control"></div> -->

                  </div>
                  <div class="row">
                    <div class="col-11 text-left">
                      <input type="text" class="form-control" [(ngModel)]="upOffAdmin['n_text']"
                        [disabled]="!negative ">
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6 text-right"> हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="upOffAdmin['n_sign']" [disabled]="!negative">
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </div>


              <br>
              <div class="row">
                <div class="col-12">
                  <hr class="hrline">
                </div>

              </div>
              <br />
              <!-- satynistha end -->

              <div class="row">
                <div class="col-1 text-center">1. &nbsp;</div>
                <div class="col-3"><b>व्यवसायिक कौशल</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  तकनीकी ज्ञान को अधावधिक रखने का प्रयास
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option1_a']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  अग्रसरता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option1_b']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  संगठन करने की क्षमता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option1_c']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-5">
                  कर्मचारियों के प्रति व्यवहार एवं नियंत्रण
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option1_d']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(च) </div>
                <div class="col-5">
                  दौरा करने की क्षमता (यदि लागू हो)
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option1_e']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <!-- vayakigat kausal  -->

              <div class="row">
                <div class="col-1 text-center">2. &nbsp;</div>
                <div class="col-3"><b> व्यक्तिगत कौशल</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  आचरण
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option2_a']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  निर्णय
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option2_b']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  चतुराई
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option2_c']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(द)</div>
                <div class="col-5">
                  स्वास्थ्य
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option2_d']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(च) </div>
                <div class="col-5">
                  कर्मनिष्ठता
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option2_e']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <!-- office Work -->


              <div class="row">
                <div class="col-1 text-center">3. &nbsp;</div>
                <div class="col-3"> <b>आफिस वर्क </b></div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(अ)</div>
                <div class="col-5">
                  लेखा स्थिति
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option3_a']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(ब)</div>
                <div class="col-5">
                  कार्यालय की दशा
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option3_b']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-1 text-center">(स)</div>
                <div class="col-5">
                  सरकारी कार्य तथा पत्रों का निस्तारण
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option3_c']"
                    [disabled]="upoffAdminDisable" />
                </div>
              </div>
              <br />

              <!-- other worrd -->


              <div class="row">
                <div class="col-1 text-center">4. &nbsp;</div>
                <div class="col-3"><b>कोई अन्य टिप्पणी यदि उच्च अधिकारी लिखना चाहें</b>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                  <textarea cols="100" rows="15" placeholder="- Enter Text Hear -" [(ngModel)]="upOffAdmin['option4']"
                    [disabled]="upoffAdminDisable"></textarea>
                </div>
                <div class="col-2"></div>
              </div>
              <br />

              <!-- satynistha  -->



              <div class="row">
                <div class="col-1 text-center">5. &nbsp;</div>
                <div class="col-3"><b>श्रेणी</b>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-2"></div>
                <div class="col-6 text-center">
                  <input type="text" class="form-control" [(ngModel)]="upOffAdmin['option5_a']"
                    [disabled]="upoffAdminDisable">
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-6"></div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6 text-right">हस्ताक्षर
                      (सील सहित)
                      <div class="row">
                        <div class="col-12 text-right"> <input type="text" class="form-control"
                            [(ngModel)]="upOffAdmin['option5_b']" [disabled]="upoffAdminDisable"> </div>
                      </div>
                    </div>
                    <div class="col-6 ">

                    </div>

                  </div>

                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span>
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row" *ngIf="updateFlag!=true && emp_role=='ACR_33_OFFICER_1'">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="save_UpOff_officer1()"> Save </button>
                </div>

              </div>
              <div class="row" *ngIf="updateFlag==true && emp_role=='ACR_33_OFFICER_1'">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="update_UpOff_officer1()">Update</button>
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span>
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br>
              <div class="row">

                <div class="col-12 text-center">
                  <h4> <strong>अनुदेश</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  1. तकनीकी योग्यता केवल शैक्षिक उपलब्धियों तक ही सीमित नहीं है अपितु यह भी कि व तकनीकी में आधुनिक विकास
                  की जानकारी भी रखते हैं। क्या उनकी कोई विशेष योग्यतायें हैं अथवा उन्हें किसी विशेष कार्य में निश्चित
                  प्रवीणता प्राप्त की है। क्या उक्त अवधि में उन्होंने इंगित करने योग्य कोई विशेष तकनीकी योगदान किया।
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  2. इस बात को भी विशेष रूप से इंगित किया जाना चाहिए कि वह आदेशों का तुरन्त तथा व्यवस्थपित रूप से
                  अनुपालन तथा समस्याओं का तत्कालिक रूप निवारण करते हैं। क्या उनके द्वारा प्रस्ताव सावधानी पूर्वक विचार
                  के पश्चात दिये जाते हैं। क्या वह मितव्ययी है। क्या व नियमित रूप से दौरा करते हैं और दौरे को अधिकतम लाभ
                  के लिए उपभोग करते हैं। क्या उनके अथवा कार्यालय के विरूद्ध गम्भीर आपत्तियाँ हैं।
                </div>
              </div>
               -->
            </div>



            <!-- reviw add screen Start -->


            <div class="tab-pane fade show active" *ngIf="review_screen_1" id="tab-1">
              <div *ngFor="let data of review_screen_1_data">
                <div class="row">
                  <div calss="col-12"> <strong>
                      <hr class="hrline">
                    </strong> </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-5 text-left"> तिथि &nbsp;&nbsp; <input type="date" [(ngModel)]="data.date"
                      [disabled]="reviewDisabled"></div>
                  <!-- <div class="col-2 text-left"></div> -->
                  <!-- <div clsdd="col-2"></div>
    <div clsdd="col-"></div>
    <div clsdd="col-2"></div> -->
                </div>
                <br>
                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <textarea cols="100" rows="5" [(ngModel)]="data.review" placeholder="- यहाँ पर लिखे -"
                      [disabled]="reviewDisabled"></textarea>
                  </div>
                  <div class="col-2"></div>
                </div>
                <br>
                <div class="row">
                  <div class="col-10 text-right"> हस्ताक्षर (सील सहित) </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-11 text-right"> <input type="text" [(ngModel)]="data.sign"
                      [disabled]='reviewDisabled'></div>
                </div>
                <br>
                <!-- <div *ngIf="reviewDisabled==false" class="row">
    <div class="col-12 text-center">
      <button class="btn btn-info"(click)="saveReview()">save</button>
    </div>
  </div> -->

              </div>

            </div>


            <div class="tab-pane fade show active" *ngIf="review_screen" id="tab-1">

              <div *ngIf="updateFlag!=true">
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-danger" (click)="navigateToNextPage_forReject(rejectData)">Reject</button>
                  </div>

                </div>

                <div class="row">
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>
                  <div class="col-6">
                    <hr class="hrline">
                  </div>
                  <div class="col-3">
                    <!-- <hr class="hrline"> -->
                  </div>
                </div>


                <br>
              </div>
              <div class="row">
                <div calss="col-12"> <strong>
                    <hr class="hrline">
                  </strong> </div>
              </div>
              <br>
              <div class="row">
                <div class="col-3"></div>
                <div class="col-6 text-left"> तिथि : &nbsp;&nbsp; <input type="date" [(ngModel)]="reviewObj['date']">
                </div>
                <!-- <div class="col-2 text-left"></div> -->
                <!-- <div clsdd="col-2"></div>
    <div clsdd="col-"></div>
    <div clsdd="col-2"></div> -->
              </div>
              <br>
              <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                  <textarea cols="100" rows="5" [(ngModel)]="reviewObj['message']"
                    placeholder="- यहाँ पर लिखे -"></textarea>
                </div>
                <div class="col-2"></div>
              </div>
              <br>
              <div class="row">
                <div class="col-10 text-right"> हस्ताक्षर (सील सहित) </div>
              </div>
              <br>
              <div class="row">
                <div class="col-11 text-right"> <input type="text" [(ngModel)]="reviewObj['sign']"></div>
              </div>
              <br>
              <div class="row" *ngIf="updateFlag!=true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="saveReview()">Save</button>
                </div>
              </div>
              <div class="row" *ngIf="updateFlag==true">
                <div class="col-12 text-center">
                  <button class="btn btn-info" (click)="saveReview_Update()">Update</button>
                </div>
              </div>
            </div>


            <!-- review add screen end -->

            <div class="tab-pane fade show active" *ngIf="list_flag" id="tab-2">
              <br>

              <div class="row">
                <div class="col-6">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>
                </div>
              </div>

              <div class=" example-container">
                <table mat-table [dataSource]="dataSource1" matSort #sortCol2="matSort">
                  <ng-container matColumnDef="sr_no">
                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>क्रमांक

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                      {{ i+1 }}</td>
                  </ng-container>

                  <ng-container matColumnDef="emp_name">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      नाम

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <!-- {{ element.emp_name }}  -->
                      <label> {{element.name}}</label>
                    </td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Purpose

                </th>
                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                    {{ element.purpose }} </td>
            </ng-container> -->

                  <ng-container matColumnDef="post">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>पद

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <label>{{element.post}} </label>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="akh_from">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>आख्या की अवधि से

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ mainService.dateformatchange(element.akh_from_date)}} </td>
                  </ng-container>
                  <ng-container matColumnDef="akh_to">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>आख्या की अवधि तक

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ mainService.dateformatchange(element.akh_to_date)}} </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> स्थिति

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ element.status}}</td>
                    <!-- <input disabled type="time"  [(ngModel)]="element.start_time"> </td> -->
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">




                      <button *ngIf="element.status=='GENERATED' && firstLabel==true " class="btn btn-info"
                        (click)="Appr(element)">Send TO Approval </button>

                      <button *ngIf="element.status != 'GENERATED' && firstLabel==true" class="btn btn-info"
                        disabled>Send TO Approval </button>

                      <button *ngIf="element.status == 'GENERATED'   && firstLabel==true && element.reject==1  "
                        class="btn btn-warning" (click)="viewRejectReason(element)">View Reject Reason</button>


                      <button *ngIf="element.status == 'Report Added' && secondLabel==true " class="btn btn-info"
                        (click)="navigateToNextPage(element)">Send TO Approval </button>

                      <!-- after reject  -->
                      <button *ngIf="element.status == 'Report Added'  && secondLabel==true && element.reject==1  "
                        class="btn btn-warning" (click)="viewRejectReason(element)">View Reject Reason</button>

                      <!-- update button  -->

                      <button
                        *ngIf="(element.status == 'Report Added' || element.status=='GENERATED' ) && historyView_flag_h!=true "
                        class="btn btn-info" (click)="updateRecord(element)">Update/View Report</button>



                      <button *ngIf="historyView_flag_h==true " class="btn btn-info"
                        (click)="getRecordOfForm(element)">See Record</button>




                    </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                  </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                </mat-paginator>
              </div>


            </div>

            <!-- pending Acr list  -->

            <div class="tab-pane fade show active" *ngIf="pending_acr" id="tab-3">
              <br>

              <div class="row">
                <div class="col-6">
                  <mat-form-field style="margin-left: 2%">
                    <input matInput (keyup)="applyFilter1($any($event.target).value)" placeholder="Filter">
                  </mat-form-field>
                </div>
              </div>

              <div class=" example-container">
                <table mat-table [dataSource]="dataSource2" matSort #sortCol3="matSort">
                  <ng-container matColumnDef="sr_no1">
                    <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>क्रमांक

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                      {{ i+1 }}</td>
                  </ng-container>

                  <ng-container matColumnDef="emp_name1">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                      नाम

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <!-- {{ element.emp_name }}  -->
                      <label> {{element.name_from}}</label>
                    </td>
                  </ng-container>




                  <ng-container matColumnDef="post1">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>पद

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      <label>{{element.post}} </label>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="akh_from1">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>आख्या की अवधि से

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ mainService.dateformatchange(element.akh_from_date)}} </td>
                  </ng-container>
                  <ng-container matColumnDef="akh_to1">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>आख्या की अवधि तक

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">
                      {{ mainService.dateformatchange(element.akh_to_date)}} </td>
                  </ng-container>


                  <ng-container matColumnDef="action1">
                    <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action

                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element" class="right">

                      <button class="btn btn-info" (click)="addReportCondition(element)"> <span
                          style="color: #770cff;">Add</span> / <span
                          style="color: #f4ff00;">Reject</span>-Report</button>
                      <!-- <button *ngIf="element.status != 'GENERATED'" class="btn btn-info"
                        disabled>Send TO Approval</button> -->



                    </td>
                  </ng-container>



                  <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                  </tr>
                </table>
                <mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons>
                </mat-paginator>
              </div>


            </div>

            <!-- anudesh start  -->
            <div class="tab-pane fade show active" *ngIf="anudesh_flag">

              <div class="row">
                <div class="col-1"></div>
                <div class="col-10">
                  <span style="border-bottom: 1px black">
                    <hr style="border-top: 1px solid rgb(105 38 40)" />
                  </span>
                </div>
                <div class="col-1"></div>
              </div>
              <br />
              <br>
              <div class="row">
                <div class="col-12 text-center">
                  <h4> <strong>अनुदेश</strong></h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  1. तकनीकी योग्यता केवल शैक्षिक उपलब्धियों तक ही सीमित नहीं है अपितु यह भी कि वह तकनीकी में आधुनिक
                  विकास
                  की जानकारी भी रखते हैं। क्या उनकी कोई विशेष योग्यतायें हैं अथवा उन्हें किसी विशेष कार्य में निश्चित
                  प्रवीणता प्राप्त की है। क्या उक्त अवधि में उन्होंने इंगित करने योग्य कोई विशेष तकनीकी योगदान किया।
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-12">
                  2. इस बात को भी विशेष रूप से इंगित किया जाना चाहिए कि वह आदेशों का तुरन्त तथा व्यवस्थापित रूप से
                  अनुपालन तथा समस्याओं का तत्कालिक रूप निवारण करते हैं। क्या उनके द्वारा प्रस्ताव सावधानी पूर्वक विचार
                  के पश्चात दिये जाते हैं। क्या वह मितव्ययी है। क्या व नियमित रूप से दौरा करते हैं और दौरे को अधिकतम लाभ
                  के लिए उपभोग करते हैं। क्या उनके अथवा कार्यालय के विरूद्ध गम्भीर आपत्तियाँ हैं।
                </div>
              </div>

            </div>
            <!-- anudesh end -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- workflow -->
<!-- aproval data  -->
<div class="modal" id="myModal3">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Approval</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">

        <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
          <div class="col-4 text-right">
            <h6>Select Forwarded To :</h6>
          </div>
          <div class="col-4">
            <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
              [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true"
              [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
            </ng-select>
          </div>


        </div>
        <br>
        <div class="row">
          <div class="col-4 text-right">
            <h6>Remark :</h6>
          </div>
          <div class="col-4">
            <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
              placeholder="Enter Your Remark" class="form-control"></textarea>

          </div>


        </div>
        <br>


        <div class="row" style="border: black;">
          <div class="col-4 text-right">
            <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
          </div>
          <div class="col-4">
            Notify Them
          </div>
        </div>
        <br>
        <!-- <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit1

                        </button>
                    </div>
                </div> -->
        <div class="row" *ngIf="ApprovalUserService.vendorflag==false &&  ApprovalUserService.approvalflag==false">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit2

            </button>
          </div>
        </div>
        <br>
        <div *ngIf="ApprovalUserService.vendorflag==false  && ApprovalUserService.approvalflag==true" class="row">
          <div class="col-12 text-center">
            <button class="btn btn-primary" data-dismiss="modal" (click)="approvedocment()">Approve
            </button>
          </div>
        </div>







        <br>
        <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
        <!-- <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div> -->
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</div>

<div class='modal' id="myModal4">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Reason</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body text-center">
        <textarea cols="84" rows="10" [value]="reason" disabled>

          </textarea>

      </div>
      <div class="modal-footer " style="justify-content: center;">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
