<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Assign Tender</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item" *ngIf="list">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i
                                    class="ti-bar-chart"></i>TENDER ASSIGNMENT LIST</a>
                        </li>

                        <li class="nav-item" *ngIf="create">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="fa fa-plus-circle"></i>TENDER
                                ASSIGNMENT </a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf="list" class="active" id="tab-1">
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="openAssignment()">TENDER ASSIGNMENT
                                    </button>
                                </div>
                            </div>

                            <div class="col-4">
                                <mat-form-field style="margin-left: 2%">
                                    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                                </mat-form-field>
                            </div>
                            <br>

                            <div class="example-container">
                                <table mat-table [dataSource]="dataSource" matSort>


                                    <ng-container matColumnDef="Sr.No">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Sr No.
                                        </th>
                                        <td mat-cell *matCellDef="let element let i = index"> {{i+1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_id">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Tender ID
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.nic_tender_id}}

                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tender_ref_no">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Ref.No.
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.tender_ref_no}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="work_name">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Work Title
                                            / Tender Name
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.work_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="bid_opening_date">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Bid Opening
                                            Date
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.bid_opening_date}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="vendor_id">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header>Assigned Vender ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.tender_assigned_id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vender_name">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Assigned
                                            Vender Name</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="sale_end_date">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Tender
                                            Allotment Date </th>
                                        <td mat-cell *matCellDef="let element"> {{element.allotment_date  | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Status </th>
                                        <td mat-cell *matCellDef="let element"> {{element.life_cycle_status}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="center" mat-sort-header> Action
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="width: 20%;">

                                            <button type="button" class="btn btn-outline-success "
                                                (click)="openUpdate(element)">Update
                                            </button>
                                            <button class="btn btn-outline-danger"
                                                (click)="deleteAssign(element)">Delete
                                            </button>

                                            <button class="btn btn-outline-info"
                                                *ngIf="element.life_cycle_status==='ACTIVE' "
                                                (click)="Appr(element)">Add
                                                Approval</button>
                                            <button class="btn btn-outline-warning" type="button"
                                                style="margin-left: 2px; margin-top:2px;"
                                                *ngIf="element.life_cycle_status==='UNDERAPPROVAL' ||  element.life_cycle_status==='APPROVED' "
                                                (click)="viewStatus(element)"> View Status </button>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[5,10, 15,20,50]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>


                        <div *ngIf="create || update_flag" class="active" id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-12 text-right">
                                    <button class="btn btn-outline-primary" (click)="backtoList()">Back To List
                                    </button>
                                </div>
                            </div>
                            <br><br>


                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Select Tender : <span class="text-danger">*</span></h6>
                                </div>
                                <div class="col-3">

                                    <ng-select [items]="tender_data" bindLabel="desc" bindValue="tender_id"
                                        [multiple]="false" placeholder="Select Tender" [(ngModel)]="tender_id"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        (change)="getDetails()">
                                    </ng-select>
                                </div>

                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Tender ID : </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="assignmentObj['tender_id']" class="form-control"
                                        disabled>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Tender Reference No. : </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="assignmentObj['nic_tender_id']" class="form-control"
                                        disabled>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Work Tittle / Tender Name : </h6>
                                </div>
                                <div class="col-3">
                                    <input type="text" [(ngModel)]="assignmentObj['work_name']" class="form-control"
                                        disabled>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Select Vender : <span class="text-danger">*</span> </h6>
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="vendor_data" bindLabel="vendor_name" bindValue="vendor_id"
                                        [multiple]="false" placeholder="Select Module"
                                        [(ngModel)]="assignmentObj['vendor_id']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true">
                                    </ng-select>

                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <h6>Tender Allotment Date : <span class="text-danger">*</span> </h6>
                                </div>
                                <div class="col-3">
                                    <input type="date" [(ngModel)]="assignmentObj['allotment_date']"
                                        class="form-control">
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-outline-danger" (click)="backtoList()">Cancel</button> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button *ngIf="create" class="btn btn-outline-primary" (click)="submit()">Submit</button>
                                    <button *ngIf="update_flag" class="btn btn-outline-primary" (click)="update()">update</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- WORKFLOW MODEL -->
<div class="modal fade" id="workflowmodel">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                {{ApprovalUserService.vendorflag}}
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr"
                            [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id"
                            [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                            name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark"
                            placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered"
                        style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">

                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right">
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Submit
                        </button>
                    </div>
                </div>
                <br>

                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary" data-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- VIEW WORKFLOW STATUS -->

<div class="modal" id="workflowStatus">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    <!-- Status -->Approval
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <a *ngFor="let ob of statusArr1;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;"
                                    *ngIf="i <statusArr1.length-1"></div>
                            </div>

                            <div class="content">
                                {{statusArr1.length-i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                        <div class="step"
                            *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'"
                            style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circles"></div>
                            </div>
                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})

                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'" style="width: 200%;">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr1.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{statusArr1.length- i}} - {{ob.role_cd}} - {{ob.name}} - {{ob.status}}
                                ({{ob.user_name}})
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">Close</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
