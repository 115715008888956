
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { MainService } from '../../service/main.service';
import { ComplainServiceService } from '../../service/complain-service.service';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService as mainService_MD } from '../../../md/service/main.service'
// import { element } from 'protractor';
import { async } from '@angular/core/testing';


declare var $: any;
@Component({
  selector: 'app-assign-complaint',
  templateUrl: './assign-complaint.component.html',
  styleUrls: ['./assign-complaint.component.css']
})
export class AssignComplaintComponent implements OnInit {
  datasource: any;
  displayedColumns = ['s.no', 'Complaint_ID', 'zone_cd', 'Complaint_type', 'Complaint_Date', 'Complaint_Assign_Date', 'Complaint_Status', 'action'];
  erpUser: any;
  b_acct_id: any;
  complaint_detail: any = {};
  complaint_image = [];
  imgURL1: any;
  assign_Obj:any = {}
  user_id:any
  formBuilder: any;
  description: any = [];
  inspection_image: any;
  section_detail: any = [];
  memo_image: any = [];
  imgURL3: any;
  memoimageflag: boolean = false;
  imgURL: any;
  feedbackimage: boolean = false;
  imgURL4: any;
  constructor(public ComplainService: ComplainServiceService, private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService, private mainServiceMD: mainService_MD,

  ) { }

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    // //console.log(this.erpUser)
    this.getAllEmployees()
    this.getcomplaindata()
    // await this.getAllModules()
  }

  complaint = true;
  complaint_inspection = false
  imageflag = false


  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  allEmplyees_new = []
  allEmployees = []
  allSearchableEmp = []
  userObj:any = {}


  view_memo_img() {
    this.memoimageflag = false;

    $('#memo_photo').modal('show');
    this.view_memo()
  }
  async getmemophoto(element:any) {
    this.complaint_detail = element
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Memo";
    // //console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.memo_image = resp['data']
      // //console.log(this.memo_image)
    }
  }
  public_photo:any = {}
  async getphotodetail() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Public";

    // //console.log(obj)
    var resp:any = await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.complaint_image = resp['data']
      // this.public_photo = this.complaint_image[0];
      this.public_photo = Object.assign({}, this.complaint_image[0]);
      // //console.log(this.public_photo);
    }
  }

  view_inspection_photo() {
    this.imageflag = false;
    $('#inspection_photo').modal('show');

  }

  view_photo() {
    this.imageflag = false;
    $('#public_photo').modal('show');
  }




  async getinspectionphotodetail(element:any) {
    this.complaint_detail = element

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    obj['doc_type'] = "Inspection";
    // //console.log(obj)
    var resp :any= await this.ComplainService.getUploaddocumentrData(JSON.stringify(obj));
    // //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.inspection_image = resp['data']
      // //console.log(this.inspection_image)
    }
  }

  async viewDetails(element:any) {
    this.complaint_detail = element

    $('#myModal1').modal('show');
    this.getphotodetail();
    this.getuserinfo();
    this.getinspectionphotodetail(element);
    this.getmemophoto(element);
    this.getfeedbackdetails()
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    }
    else {

      this.inspection_detail = Object.assign({}, ...resp['data'][0]);
      this.section_detail = resp['data'][1]
      // //console.log(this.section_detail, this.inspection_detail)
      var resp:any = await this.ComplainService.getinspectionDescription(JSON.stringify(obj));
      // //console.log(resp);
      if (resp['error'] == true) {
        Swal.fire('error', "server side error", 'error');
        return
      } else {
        this.description = resp['data'];
        // //console.log(this.description);
      }
    }







  }

  ADD_DESCRIPTION() {
    this.view_memo()
    $('#description').modal('show');

  }

  forward(element:any) {
    // this.complaint_detail = {};
    this.complaint_detail = element
    $('#staticBackdrop2').modal('show');
    this.condtion = "BY_ZONE"
    this.getuserinfobyzone();


  }
  inspection_req = [
    { id: 1, name: 'yes' },
    { id: 2, name: 'no' },


  ]


  same = false;
  condtion = 'BY_ZONE'
  Inzone: boolean = true;
  OuterZone: boolean = false;
  userbyzone = []
  userbynotzone = []
  // selected_user=[]
  sameAddress() {
    if (!this.same) {
      this.Inzone = false;
      this.OuterZone = true;
      this.same = true;
      this.condtion = "NOT_BY_ZONE"

      this.getuserinfobyzone();
    } else {
      this.Inzone = true;
      this.OuterZone = false;
      this.same = false;
      this.condtion = "BY_ZONE"

      this.getuserinfobyzone();

    }

  }

  async getuserinfobyzone() {
    let arr = []
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['condition'] = this.condtion;
    obj['node_cd'] = this.complaint_detail['zone_cd']
    //console.log(this.complaint_detail)
    var resp :any= await this.ComplainService.getuserinfoByzone(JSON.stringify(obj));
    // //console.log(resp)
    if (resp['error'] == false) {

      arr = resp['data'];
      let userarr:any = []
      resp['data'].map(async (x:any, i:any, data:any) => {
        // //console.log(x, data)
        if (x['user_id'] == this.user_id) {
          // resp['data'].splice(i,1);

        } else {
          await userarr.push(x)
          userarr[i]['desc'] = await x['user_id'] + ' - ' + x['emp_name'] + ' - ' + x['emp_phone_no'] + ' ' + x['zone_name']
        }
      })
      resp['data'] = await userarr
      if (this.condtion == "BY_ZONE") {
        this.userbyzone = resp['data'];
        // //console.log(this.userbyzone);
        return
      } else {
        this.userbynotzone = resp['data'];
      }
      //  //console.log(this.userbyzone);
    } else {
      Swal.fire('error', 'server side error', 'error');
    }


  }


  async forward2() {
    this.assign_Obj['b_acct_id'] = this.b_acct_id
    this.assign_Obj['life_cycle_status'] = "ASSIGNED"
    this.assign_Obj['complain_id'] = this.complaint_detail['complain_id']
    this.assign_Obj['status'] = "UNDER-INSPECTION "
    this.assign_Obj['type'] = "complaint"
    this.assign_Obj['assigned_By'] = this.user_id

    // //console.log(this.assign_Obj);
    var resp:any = await this.ComplainService.insertassigncomplain(this.assign_Obj)
    // //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {
      Swal.fire('success', 'compalint forward successfully', 'success');
      this.getcomplaindata();
      $('#staticBackdrop2').modal('hide');
    }
    this.assign_Obj = {};


  }
  inspection_detail:any = {};
  async inspection(element:any) {
    // this.complaint_detail = {}
    this.complaint_detail = element
    // //console.log(this.complaint_detail)
    this.getinspectionphotodetail(element);
    this.getmemophoto(element);
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getinspectionData(JSON.stringify(obj))
    // //console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    }
    else {
      if (resp['data'][0].length <= 0) {
        Swal.fire('info', "No Inspection Data Found", 'info');
        return;
      }
      this.complaint_detail = Object.assign({}, ...resp['data'][0]);
      this.section_detail = resp['data'][1]

      // //console.log(this.complaint_detail);
      this.complaint = false;
      this.complaint_inspection = true;


    }

  }

  async getcomplaindata() {
    const obj:any = {}
    // //console.log(this.b_acct_id)
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id
    obj['type'] = 'complaint'
    var resp :any= await this.ComplainService.allAssignComplain(JSON.stringify(obj));
    // //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      // this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }

  }




  async public_view(i:any) {
    const obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.complaint_image[i]['doc_id']
    obj['file_name'] = this.complaint_image[i]['file_name']
    obj['complain_id'] = this.complaint_image[i]['complain_id']
    //console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true

      this.imgURL = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();

        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      }


    } else {
      Swal.fire('error', 'server side error', 'error');

    }


  }






  async view(i:any) {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.inspection_image[i]['doc_id']
    obj['file_name'] = this.inspection_image[i]['file_name']
    obj['complain_id'] = this.inspection_image[i]['complain_id']
    // //console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.imageflag = true
      // //console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL1 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL1 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }
      // //console.log(this.imgURL1, 'imgURL1')

    }


  }
  async view_memo() {

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.memo_image[0]['doc_id']
    obj['file_name'] = this.memo_image[0]['file_name']
    obj['complain_id'] = this.memo_image[0]['complain_id']
    // //console.log(obj)
    let resp: any = await this.ComplainService.getUploaddocumentrimg(obj);

    if (resp) {
      this.memoimageflag = true;
      // //console.log(resp, 'res')
      // this.spinner.hide();
      this.imgURL3 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL3 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }


    }



  }
  open_list() {
    this.complaint = true;
    this.complaint_inspection = false;
  }

  // cancel_insp(){
  //   this.complaint = true;
  //   this.complaint_inspection = false;
  // }

  close() {
    $('#myModal1').modal('hide');

  }

  async getAllEmployees() {
    // //console.log('all emp', this.b_acct_id)
    this.spinner.show()
    var arr = [];
    const obj:any = {};
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    // //console.log(obj)
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    // //console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      // //console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    // //console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

  // get user info
  userdetail:any = []
  async getuserinfo() {
    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.complaint_detail['complain_id']
    var resp:any = await this.ComplainService.getuserinfo(JSON.stringify(obj));
    // //console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', 'server side error', 'error');
      return
    } else {

      this.userdetail = resp['data']
      this.complaint_detail['party_name'] = this.userdetail[0]['party_name']
      for (let i = 0; i < this.userdetail.length; i++) {
        if (this.userdetail[i]['role_cd'] == "ICMS_EMP") {
          this.userdetail[i]['role_name'] = undefined

        }

      }
      // //console.log(this.userdetail);
    }


  }


  descriptionForm = [
    {
      description: '',
      // b_acct_id:'',
      // complain_id:'',
      // comp_assign_id:''


    }
  ];

  addForm() {


    this.descriptionForm.push({
      description: '',
      // b_acct_id:this.b_acct_id,
      // complain_id: this.complaint_detail['complain_id'] ,
      // comp_assign_id:this.complaint_detail['comp_assign_id']

    });
    // //console.log(this.descriptionForm);
  }
  remove() {
    if (this.descriptionForm.length == 1) {
      return;
    }
    this.descriptionForm.pop();

  }

  async FinalSubmit() {
    // //console.log(this.b_acct_id)
    // //console.log(this.complaint_detail)
    const obj:any = {};
    obj['b_acct_id'] = this.b_acct_id
    obj['description'] = this.descriptionForm
    obj['complain_id'] = this.complaint_detail['complain_id'];
    obj['inspection_id'] = this.complaint_detail['inspection_id'];
    // //console.log(obj);
    for (let i = 0; i < this.descriptionForm.length; i++) {
      if (!this.descriptionForm[i]['description']) {
        Swal.fire('info', 'please fill description', 'info');
        return
      }
    }



    var resp:any = await this.ComplainService.insertinspectiondesc(obj);
    // //console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('info', 'server side error', 'error');
      return;
    } else {
      this.getcomplaindata();
      this.complaint = true;
      this.complaint_inspection = false;
      Swal.fire('sucess', "Inspection Report Submitted successfully", 'success');
      $('#description').modal('hide');

      return;
    }

  }

  // get feedback details
  view_feedback() {
    $('#feedback_img').modal('show');

  }
  feedbackdetail: any = {}
  async getfeedbackdetails() {

    //console.log(this.complaint_detail)
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complaint_id'] = this.complaint_detail['complain_id']
    var resp :any= await this.ComplainService.getfeedbackdetails(JSON.stringify(obj));
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error');
      return
    } else {
      this.feedbackdetail = resp['data'];
      //console.log(this.feedbackdetail);
    }
    this.getfeedbackdocument();

  }
  feedbackimg: any = [];
  async getfeedbackdocument() {
    //console.log(this.feedbackdetail)
    //console.log(this.complaint_detail)
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['complain_id'] = this.feedbackdetail[0]['feedback_id']
    var resp:any = await this.ComplainService.getfeedbackdocuments(JSON.stringify(obj));
    //console.log(resp)
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error');
      return
    } else {
      this.feedbackimg = resp['data'];
      // //console.log(this.description);
    }


  }

  async view_feedback_photo(i:any) {
    this.feedbackimage = false;

    const obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_id'] = this.feedbackimg[i]['doc_id']
    obj['file_name'] = this.feedbackimg[i]['file_name']

    //console.log(obj)
    this.spinner.show();
    let resp: any = await this.ComplainService.feedback(obj);
    //console.log(resp);
    if (resp) {
      this.feedbackimage = true
      //console.log(resp, 'res')
      this.spinner.hide();
      this.imgURL4 = resp;
      var docname = obj['file_name'];
      var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.spinner.hide();
        this.imgURL4 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        const unsafeImageUrl = window.URL.createObjectURL(resp); // URL.createObjectURL(res);
        this.imgURL4 = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
      }

      //console.log(this.imgURL4)
    }



  }



}

