import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from '../../role-guard.service';
import { AdvanceServicesComponent } from '../advance-services/advance-services.component';
import { DefaulterPartyComponent } from '../advance-services/defaulter-party/defaulter-party.component';
import { PossessionComponent } from '../advance-services/possession/possession.component';
import { PropertyCancellationComponent } from '../advance-services/property-cancellation/property-cancellation.component';
import { RefundRequestComponent } from '../advance-services/refund-request/refund-request.component';
import { SeizedPropertyComponent } from '../advance-services/seized-property/seized-property.component';
import { AuctionApplicationComponent } from '../auction-application/auction-application.component';
import { AuctionBidRuleComponent } from '../auction-bid-rule/auction-bid-rule.component';
import { AuctionComponent } from '../auction/auction.component';
import { BidDetailsComponent } from '../bid-details/bid-details.component';
import { NewDashboardComponent } from '../new-dashboard/new-dashboard.component';
import { OfflineBookletPurchaseComponent } from '../offline-booklet-purchase/offline-booklet-purchase.component';
import { LandInputComponent } from '../prop-inventory/land-input/land-input.component';
import { LeasePropertyComponent } from '../prop-inventory/lease-property/lease-property.component';
import { LeaseComponent } from '../prop-inventory/lease/lease.component';
import { PlanLayoutComponent } from '../prop-inventory/plan-layout/plan-layout.component';
import { PropCostingComponent } from '../prop-inventory/prop-costing/prop-costing.component';
import { PropImagesComponent } from '../prop-inventory/prop-images/prop-images.component';
import { PropInventoryComponent } from '../prop-inventory/prop-inventory.component';
import { PropertyInventoryComponent } from '../prop-inventory/property-inventory/property-inventory.component';
import { PropertyFaqComponent } from '../property-help/property-faq/property-faq.component';
import { PropertyHelpComponent } from '../property-help/property-help.component';
import { PropertyOnlineSupportComponent } from '../property-help/property-online-support/property-online-support.component';
import { PropertyTutorialComponent } from '../property-help/property-tutorial/property-tutorial.component';
import { PropertyUserManualComponent } from '../property-help/property-user-manual/property-user-manual.component';
import { LeaseAllotmentComponent } from '../property-lease/lease-allotment/lease-allotment.component';
import { LeaseFreeholdComponent } from '../property-lease/lease-freehold/lease-freehold.component';
import { LeaseInspectionComponent } from '../property-lease/lease-inspection/lease-inspection.component';
import { LeaseLitigationComponent } from '../property-lease/lease-litigation/lease-litigation.component';
import { LeaseMutationComponent } from '../property-lease/lease-mutation/lease-mutation.component';
import { LeaseRenewalComponent } from '../property-lease/lease-renewal/lease-renewal.component';
import { LeaseSubDivisionComponent } from '../property-lease/lease-sub-division/lease-sub-division.component';
import { PropertyLeaseComponent } from '../property-lease/property-lease.component';
import { GeneralLedgerComponent } from '../property-ledger/general-ledger/general-ledger.component';
import { PropertyLedgerComponent } from '../property-ledger/property-ledger.component';
import { AllotmentResultComponent } from '../property-party/allotment-result/allotment-result.component';
import { AllotmentComponent } from '../property-party/allotment/allotment.component';
import { ApplicationRefundComponent } from '../property-party/application-refund/application-refund.component';
import { ApplicationReportComponent } from '../property-party/application-report/application-report.component';
import { ApplicationsComponent } from '../property-party/applications/applications.component';
import { BookletPurchaseComponent } from '../property-party/booklet-purchase/booklet-purchase.component';
import { InstallmentPaymentComponent } from '../property-party/installment-payment/installment-payment.component';
import { LotterySlipComponent } from '../property-party/lottery-slip/lottery-slip.component';
import { PartyAccountsComponent } from '../property-party/party-accounts/party-accounts.component';
import { PartyInfoComponent } from '../property-party/party-info/party-info.component';
import { PartyNomineeComponent } from '../property-party/party-nominee/party-nominee.component';
import { PaymentScheduleComponent } from '../property-party/payment-schedule/payment-schedule.component';
import { PropertyPartyComponent } from '../property-party/property-party.component';
import { RegistryComponent } from '../property-party/registry/registry.component';
import { RestoreComponent } from '../property-party/restore/restore.component';
import { TransferPropertyComponent } from '../property-party/transfer-property/transfer-property.component';
import { PropertyCodeValueComponent } from '../property-setting/property-code-value/property-code-value.component';
import { PropertyDefinitionComponent } from '../property-setting/property-definition/property-definition.component';
import { PropertyFieldComponent } from '../property-setting/property-field/property-field.component';
import { PropertyInfoComponent } from '../property-setting/property-info/property-info.component';
import { PropertySettingComponent } from '../property-setting/property-setting.component';
import { PublicConfigComponent } from '../property-setting/public-config/public-config.component';
import { SchemeComponent } from '../property-setting/scheme/scheme.component';
import { SubschemeComponent } from '../property-setting/subscheme/subscheme.component';
import { DefaulterMethodComponent } from '../proterty-administration/defaulter-method/defaulter-method.component';
import { LetterConfigurationComponent } from '../proterty-administration/letter-configuration/letter-configuration.component';
import { MutationRuleComponent } from '../proterty-administration/mutation-rule/mutation-rule.component';
import { PaymentOptionComponent } from '../proterty-administration/payment-option/payment-option.component';
import { PossessionRuleComponent } from '../proterty-administration/possession-rule/possession-rule.component';
import { PropDataAssignmentComponent } from '../proterty-administration/prop-data-assignment/prop-data-assignment.component';
import { PropGeometryComponent } from '../proterty-administration/prop-geometry/prop-geometry.component';
import { PropHeirarchyComponent } from '../proterty-administration/prop-heirarchy/prop-heirarchy.component';
import { PropLocationComponent } from '../proterty-administration/prop-location/prop-location.component';
import { PropRolesComponent } from '../proterty-administration/prop-roles/prop-roles.component';
import { PropUsersComponent } from '../proterty-administration/prop-users/prop-users.component';
import { PropWorkflowComponent } from '../proterty-administration/prop-workflow/prop-workflow.component';
import { PropertyFeatureComponent } from '../proterty-administration/property-feature/property-feature.component';
import { ProtertyAdministrationComponent } from '../proterty-administration/proterty-administration.component';
import { RegularHierarchyComponent } from '../proterty-administration/regular-hierarchy/regular-hierarchy.component';
import { TermAndConditionComponent } from '../proterty-administration/term-and-condition/term-and-condition.component';
import { FcfsComponent } from '../sale/fcfs/fcfs.component';
import { LotteryComponent } from '../sale/lottery/lottery.component';
import { SaleComponent } from '../sale/sale.component';
import { AuctionAllotmentComponent } from '../property-party/auction-allotment/auction-allotment.component';



const routes: Routes = [

{
    path: "property-index",
    component: NewDashboardComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R1' }
},
{
    path: "activity",
    component: PropertyPartyComponent,
    "children": [
        {
            path: "party-info",
            component: PartyInfoComponent , data: { ComponentCode: 'PROPERTY-R7' }

        },
        {
            path: "bid-details",
            component: BidDetailsComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R32' }

        },
        {
            path:"offline-booklet",
            component:OfflineBookletPurchaseComponent , data: { ComponentCode: 'PROPERTY-R28' }
        },
        {
            path: "party-accounts",
            component: PartyAccountsComponent ,data: { ComponentCode: 'PROPERTY-R7' }

        },
        {
            path: "party-nominee",
            component: PartyNomineeComponent ,data: { ComponentCode: 'PROPERTY-R7' }

        },
        {
            path: "applications",
            component: ApplicationsComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R29' }

        },
        {
            path: "lottery-slip",
            component: LotterySlipComponent
            , canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R30' }

        },
        {
            path: "booklet-purchase",
            component: BookletPurchaseComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R2' }

        },
        {
            path: "auction",
            component: AuctionComponent, data: { ComponentCode: 'PROPERTY-R26' }
            // component: AuctionComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R26' }

        },
        {
            path: "auction-application",
            component: AuctionApplicationComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R31' }

        },
        {
            path: "all-applications",
            component: ApplicationReportComponent ,data: { ComponentCode: 'PROPERTY-R7' }

        },
        {
            path: "restore",
            component: RestoreComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R8' }

        },
        {
            path: "applications-refund",
            component: ApplicationRefundComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R5' }

        },
        {
            path: "allotment",
            component: AllotmentComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R34' }

        },
        {
            path: "allotment-result",
            component: AllotmentResultComponent
            , canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R35' }

        },
        {
            path: "payment-schedule",
            component: PaymentScheduleComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R6' }

        },
        {
            path: "installment",
            component: InstallmentPaymentComponent , data: { ComponentCode: 'PROPERTY-R7' }

        },
        {
            path: "registry",
            component: RegistryComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R7' }

        },
        
        {
            path: "mutation",
            component: TransferPropertyComponent , data: { ComponentCode: 'PROPERTY-R43' }

        },
        {
            path: "auction-allotment",
            component: AuctionAllotmentComponent
        },
    ]

},
{
    "path": "administration",
    "component": ProtertyAdministrationComponent ,
    "children": [

        {
            path: "term-and-cond",
            component: TermAndConditionComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R50' }
        },
        {
            path: "roles",
            component: PropRolesComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R48' }
        },
        {
            path: "users",
            component: PropUsersComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R49' }
        },
        {
            path: "hierarchy",
            component: PropHeirarchyComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R7' }
        },
        {
            path: "standered-hierarchy",
            component: RegularHierarchyComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R9' }
        },
        {
            path: "data-assignment",
            component: PropDataAssignmentComponent , data: { ComponentCode: 'PROPERTY-R50' }
        },
        {
            path: "geometry",
            component: PropGeometryComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R9' }
        },
        {
            path: "workflow",
            component: PropWorkflowComponent , data: { ComponentCode: 'PROPERTY-R51' }
        },
        {
            path: "location",
            component: PropLocationComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R12' }
        },
        {
            path: "feature",
            component: PropertyFeatureComponent,canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R16' }
        },
        {
            path: "payment-option",
            component: PaymentOptionComponent,data: { ComponentCode: 'PROPERTY-R11' }
        },
        {
            path: "defaulter-method",
            component: DefaulterMethodComponent,data: { ComponentCode: 'PROPERTY-R13' }
        },
        {
            path: "possession-rule",
            component: PossessionRuleComponent,data: { ComponentCode: 'PROPERTY-R7' }
        },
        {
            path: "mutation-rule",
            component: MutationRuleComponent,data: { ComponentCode: 'PROPERTY-R7' }
        },
        {
            path:'allotment-configuration',
            component:LetterConfigurationComponent ,data: { ComponentCode: 'PROPERTY-R17' }
        },
        {
            path:'auction-bid-rule',
            component:AuctionBidRuleComponent ,data: { ComponentCode: 'PROPERTY-R15' }
        },
        {
            path: 'public-config', component: PublicConfigComponent
        },
    ]
},
{
    path: 'help', component:PropertyHelpComponent,
    children: [
        { path: 'doc', component: PropertyUserManualComponent ,data: { ComponentCode: 'PROPERTY-R7' } },
        { path: 'faq', component: PropertyFaqComponent ,data: { ComponentCode: 'PROPERTY-R7' }},
        { path: 'videos', component: PropertyTutorialComponent ,data: { ComponentCode: 'PROPERTY-R7' }},
        { path: 'online-support', component: PropertyOnlineSupportComponent ,data: { ComponentCode: 'PROPERTY-R7' }},
    ]
},

{
    path: 'inventory', component:PropInventoryComponent,
    children: [
        { path: 'land-input', component: LandInputComponent ,data: { ComponentCode: 'PROPERTY-R7' }},
        { path: 'plan-layout', component: PlanLayoutComponent ,data: { ComponentCode: 'PROPERTY-R19' }},
        { path: 'property', component: PropertyInventoryComponent ,data: { ComponentCode: 'PROPERTY-R14' }},
        { path: 'property-costing', component: PropCostingComponent ,data: { ComponentCode: 'PROPERTY-R20' }},
        { path: 'property-images', component: PropImagesComponent ,data: { ComponentCode: 'PROPERTY-R21' }},
        { path: 'lease', component: LeaseComponent},
        { path: 'lease-property', component: LeasePropertyComponent}
        // { path: 'faq', component: PropertyFaqComponent },
        // { path: 'videos', component: PropertyTutorialComponent },
        // { path: 'online-support', component: PropertyOnlineSupportComponent },
    ]
},
{
    path: 'sale', component:SaleComponent,
    children: [
        { path: 'lottery', component: LotteryComponent  ,data: { ComponentCode: 'PROPERTY-R24' }},
        { path: 'fcfs', component: FcfsComponent  ,data: { ComponentCode: 'PROPERTY-R25' }},
    ]
},

{
    path: 'property-ledger', component:PropertyLedgerComponent,
    children: [
        { path: 'general-ledger', component: GeneralLedgerComponent  ,data: { ComponentCode: 'PROPERTY-R45' }},
    ]
},
{
    path: 'advance-services', component:AdvanceServicesComponent,
    children: [
        { path: 'defaulter-party', component: DefaulterPartyComponent ,data: { ComponentCode: 'PROPERTY-R37' } },
        {
            path: "property-cancellation",
            component: PropertyCancellationComponent ,data: { ComponentCode: 'PROPERTY-R38' }
        },
        
        {
            path: "seized-property",
            component: SeizedPropertyComponent ,data: { ComponentCode: 'PROPERTY-R8' }
        },
        {
            path: "Possession",
            component: PossessionComponent ,data: { ComponentCode: 'PROPERTY-R39' }
        },
        {
            path: "party-nominee",
            component: PartyNomineeComponent ,data: { ComponentCode: 'PROPERTY-R40' }

        },
        {
            path: "refund-request",
            component: RefundRequestComponent ,data: { ComponentCode: 'PROPERTY-R41' }

        },
       
    ]
},

{
    path: 'prop-lease', component:PropertyLeaseComponent,
    children: [
        { path: 'lease-allotment', component: LeaseAllotmentComponent },
        { path: 'lease-sub-division', component: LeaseSubDivisionComponent },
        { path: 'lease-mutation', component: LeaseMutationComponent },
        { path: 'lease-renewal', component: LeaseRenewalComponent },
        { path: 'lease-inspection', component: LeaseInspectionComponent },
        { path: 'lease-litigation', component: LeaseLitigationComponent },
        { path: 'lease-freehold', component: LeaseFreeholdComponent }
        
    ]
},
{
    path: "property-setting",
    component: PropertySettingComponent,
    "children": [
        {
            path: "property-info",
            component: PropertyInfoComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R3' }
        },
        {
            path: "property-def",
            component: PropertyDefinitionComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R14' }
        },

        {
            path: "prop-code-value",
            component: PropertyCodeValueComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R3' }
        },
        {
            path: "prop-field",
            component: PropertyFieldComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R5' }
        },
        {
            path: "scheme",
            component: SchemeComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R11' }
        },
         {
            path: "subscheme",
            component: SubschemeComponent, canActivate: [RoleGuardService], data: { ComponentCode: 'PROPERTY-R12' }
        },
        {
            path: "payment-option",
            component: PaymentOptionComponent, data: { ComponentCode: 'PROPERTY-R7' }
        }  

    ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertyLayoutsRoutingModule { }
