import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AllreportService } from '../../service/allreport.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-penality',
  templateUrl: './penality.component.html',
  styleUrls: ['./penality.component.css']
})
export class PenalityComponent implements OnInit {
  erpUser: any;
  ebillUser: any;
  b_acct_id: any;
  user_id: any;
  datasource: any;
  displayedColumns = ['s.no', 'case_ID', 'Payment_type', 'Amount', 'Due_date', 'Payment_date', 'Payment_status'];


  constructor(public allreport: AllreportService, private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    // this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')));
    this.b_acct_id = this.erpUser.b_acct_id
    // this.user_id = this.erpUser.le_id
    this.getpenality();
  }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }




async  getpenality(){
  let obj:any = {}
  obj['b_acct_id'] = this.b_acct_id
  obj['pay_for'] = 'Penalty'
  var resp:any = await this.allreport.getpenalitydata(JSON.stringify(obj));
  console.log(resp);
  if (resp['error'] == true) {
    Swal.fire('error', "server side error", 'error')
    return
  } else {
    this.datasource = resp['data']
    this.datasource = new MatTableDataSource(resp['data'])
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];
  }
}


}
