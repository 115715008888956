<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">HR Hierarchy</div>

                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf=" list_flage" class="nav-link active" (click)="reset()" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>&nbsp;HR Hierarchy List</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf=" create_flage" class="nav-link" (click)="reset()" href="#tab-7-2" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                    Create Hierarchy Node
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf=" update_flage" class="nav-link" (click)="reset()" href="#tab-7-3" data-toggle="tab"><i
                                    class="ti-settings"></i>
                                    Update Hierarchy Node
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div *ngIf=" list_flage">
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="createnode()"> Create Hierarchy Node </button>
                            </div>
                            <div class=" table-container ">

                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field style="margin-left: 2%">
                                            <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="">
                                            <!-- Filter  -->
                                        </mat-form-field>
                                    </div>

                                </div>

                                <div class=" example-container">
                                    <table mat-table [dataSource]="dataSource" matSort>
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header>ID

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                                {{ element.id }}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="lvl1_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 1 Code

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl1_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl1_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header> Level 1 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl1_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl1_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 1 Value

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl1_value }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl2_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 2 Code

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl2_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl2_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 2 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl2_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl2_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 2 Value

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl2_value }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl3_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 3 Code

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl3_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl3_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 3 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl3_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl3_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 3 Value

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl3_value }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl4_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 4 Code

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl4_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl4_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 4 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl4_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl4_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 4 Value

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl4_value }} </td>
                                        </ng-container>



                                        <ng-container matColumnDef="lvl5_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 5 Code
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl5_user_cd}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl5_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 5 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl5_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl5_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 5 Value
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl5_value }} </td>
                                        </ng-container>




                                        <ng-container matColumnDef="lvl6_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 6 Code
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl6_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl6_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 6 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl6_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl6_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 6 Value
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl6_value }} </td>
                                        </ng-container>




                                        <ng-container matColumnDef="lvl7_user_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 7 Code
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl7_user_cd }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl7_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 7 Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl7_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="lvl7_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Level 7 Value
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.lvl7_value }} </td>
                                        </ng-container>


                                        <ng-container matColumnDef="is_leaf">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Is_leaf
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <p *ngIf="element['is_leaf'] ==1">LEAF</p>
                                                <p *ngIf="element['is_leaf'] ==0">NODE</p>

                                            </td>

                                        </ng-container>



                                        <ng-container matColumnDef="leaf_cd">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Leaf Code
                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <p *ngIf="element['is_leaf'] ==1"> {{ element.leaf_cd }} </p>
                                                <p *ngIf="element['is_leaf'] ==0"></p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="leaf_node_type">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Leaf Node Type

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                {{ element.leaf_node_type }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="leaf_value">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Leaf Value

                                            </th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                <p *ngIf="element['is_leaf'] ==1"> {{ element.leaf_value }} </p>
                                                <p *ngIf="element['is_leaf'] ==0"></p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>Action</th>
                                            <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                                <button *ngIf="element['leaf_node_type']!='HREMP' && element['lvl3_cd'] !=null" class="btn btn-outline-primary" (click)="open_update(element)" href="#tab-7-3" data-toggle="tab">Update<!-- Update --></button>
                                                <button *ngIf="element['leaf_node_type']!='HREMP' && element['lvl3_cd'] !=null" class="btn btn-outline-danger" (click)="delete(element)">Delete<!-- Delete --></button>

                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                    </table>
                                    <mat-paginator [pageSizeOptions]="[5,10, 20]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" create_flage">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()"> HR Hierarchy List</button>
                            </div>
                            <br><br>

                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;"> Select Node : <span style="color: red;"> *</span></h6>

                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']">Level  </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder="Select  Node " [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6 style="margin-left: 2%;"> Current Node Path <span style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-6">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">Select Node Type </h6>
                                    <!-- Select Node Type : -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0051']" bindLabel="value" bindValue="code" [multiple]="false" placeholder=" " [(ngModel)]="Obj['node_type']" [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true" name="chart_of_account0">
                                    </ng-select>
                                    <!-- Select Node Type -->
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;"> Node Code : <span style="color: red;">*</span> </h6>
                                    <!-- Node Code : -->
                                </div>
                                <div class="col-3">
                                    <input class="form-control" [(ngModel)]="Obj['node_cd']" onkeypress='return (event.charCode > 96 && event.charCode < 123) ||
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder="Enter Node Code " />
                                    <!-- Enter Node Code -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;"> Node Description : <span style="color: red;">*</span>
                                        <!-- Node Description : -->
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['node_desc']" placeholder="  Enter Node Description " class="form-control"></textarea>
                                    <!-- Enter Node Description -->
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div> -->
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" [disabled]="check" (click)="submithier()"> Submit</button>
                                    <!-- Submit -->
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" update_flage">
                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()"> HR Hierarchy List</button>
                            </div>
                            <!-- <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Node : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="ActiveNode" [multiple]="false" bindLabel="desc"
                                     bindValue="node_cd" [selectableGroup]="true" (change)="ParentNodeChange()"
                                     placeholder="Select Node"
                                     [selectableGroupAsModel]="false" [closeOnSelect]="true" name="hierarchy"
                                     [(ngModel)]="Obj['parent_node_cd']">
                                    </ng-select>

                                </div>
                            </div> -->
                            <div class="row" *ngFor="let item of planing_arr,let i =index">
                                <div class="col-3"></div>
                                <div class="col-3" *ngIf="i==0">
                                    <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                                    <!-- Select Node : -->
                                </div>
                                <div class="col-3" *ngIf="i!=0"></div>
                                <div class="col-1">

                                    <span *ngIf="item['level']"> Level  </span>{{item['level']}}
                                    <!-- Level -  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" " [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNode(item,i)" [selectableGroupAsModel]="true"
                                        [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- Select  Node -->
                                </div>
                                <div class="col-2" *ngIf="i==planing_arr.length-1">
                                    <button (click)="down(item,i)" class="btn btn-primary"><i
                                            class="fa fa-arrow-down" aria-hidden="true"></i></button> &nbsp;&nbsp;
                                    <button (click)="remove(item,i)" class="btn btn-danger">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3 ">
                                    <h6 style="margin-left: 2%;"> Current Node Path : <span style="color: red;">*</span>
                                        <!-- Current Node Path : -->
                                    </h6>
                                </div>
                                <div class="col-6">
                                    {{Obj['path_desc']}}
                                </div>
                            </div>
                            <br>
                            <hr>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">Select Node Type </h6>
                                    <!-- Select Node Type :  -->
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="mainService.codeValueTechObj['HR0051']" bindLabel="value" bindValue="code" [multiple]="false" placeholder=" Select Node Type" [(ngModel)]="Obj['node_type']" [selectableGroup]="true" [selectableGroupAsModel]="true" [closeOnSelect]="true"
                                        name="chart_of_account0">
                                    </ng-select>
                                    <!-- Select Node Type -->
                                </div>
                                <div class="col-3"></div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;"> Node Code : <span style="color: red;">*</span> </h6>
                                    <!-- Node Code : -->
                                </div>
                                <div class="col-3">
                                    <input class="form-control" [(ngModel)]="Obj['node_cd']" onkeypress='return (event.charCode > 96 && event.charCode < 123) ||
                                    (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48
                                    && event.charCode <= 57) || event.charCode <= 31' type="text" placeholder=" " />
                                    <!-- Enter Node Code -->
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3"></div>
                                <div class="col-3">
                                    <h6 style="margin-left: 2%;">Node Description : <span style="color: red;">*</span>
                                        <!-- Node Description : -->
                                    </h6>
                                </div>
                                <div class="col-3">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['node_desc']" placeholder=" Enter Node Description " class="form-control"></textarea>
                                    <!-- Enter Node Description -->
                                </div>
                            </div>
                            <!-- <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div> -->
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"> Update </button>
                                    <!-- Update -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="tab-7-4">
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Code : <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Role Code" class="form-control" [(ngModel)]="Obj['role_cd']" disabled>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Name : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input type="email" placeholder="Enter Name" class="form-control" [(ngModel)]="Obj['role_name']">


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Role Description : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="Obj['role_desc']" placeholder="Enter Role Description" class="form-control"></textarea>


                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin-left: 2%;"> Select Access : <span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [multiple]="false" placeholder="Select Access " [items]="access" bindLabel="value" bindValue="code" [selectableGroup]="true" [selectableGroupAsModel]="false" aria-placeholder="Select Access" [closeOnSelect]="false" name="hierarchy" [(ngModel)]="Obj['access']">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <h6 style="margin-left: 2%;">Select Resource :<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4" *ngIf="flag == false">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">
                                    </ng-select>
                                </div>
                                <div class="col-4" *ngIf="flag == true">
                                    <ng-select [items]="allResources" bindLabel="res_desc" bindValue="res_cd" [multiple]="true" placeholder="Select Resource" [(ngModel)]="Obj['res_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role" disabled>
                                    </ng-select>
                                </div>
                                <div class="col-4">

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()">Update</button>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <br>
                </div>
            </div>

        </div>
    </div>

</div>
<ngx-spinner> Loading.... </ngx-spinner>
<!-- Loading.... -->

<!-- <div class="modal" id="createRole">
    <div class="modal-dialog  modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Create Role</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Select
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Whats is Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-info" (click)="addNewRole()">Add</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div> -->


<ngx-spinner type="ball-atom">
    <p style="color: white"> Loading.... </p>
</ngx-spinner>
<!-- Loading...  -->

<!-- <div class="modal" id="updateRole">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">


            <div class="modal-header">
                <h4 class="modal-title">Update Role</h4>
            </div>


            <div class="modal-body" style="padding-left: 15%">
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b>Selected
                            Components:</b></label>
                    <div class="col-lg-8">

                        <ng-select [items]="allComp" bindLabel="interface_name" bindValue="id" [multiple]="true" placeholder="Select Components" [(ngModel)]="selectedComp" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="role">



                        </ng-select>

                    </div>
                </div>
                <br>
                <div class="row">
                    <label class="col-lg-2 control-label" style="font-size: 15px ;padding-top: 1%"><b> Role
                            Name:</b></label>
                    <div class="col-lg-8">

                        <input class="form-control" [(ngModel)]="role_name">
                    </div>
                </div>
                <br>


            </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="updateRole()">Update</button>
                <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div> -->
