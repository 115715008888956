import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';
import { ProfileService } from '../service/profile.service'
import { Router } from '@angular/router';
import { MainService } from '../service/main.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
//oc
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
//ec
import { TaskService } from '../service/task.service';
import { UserAddService } from '../service/user-add.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EbillPrintService } from '../service/ebill-print.service';
import { PrintSalBillService } from '../service/print-sal-bill.service';
import { ApprovalUserService } from '../service/approval-user.service';
import { TenderService } from './../../emb/service/tender.service';
import { MasterDataService } from './../../emb/service/master-data.service'
import { EbillService } from './../../emb/service/ebill.service';
import { MainService as EmbMainService } from './../../emb/service/main.service'
// import {EbillMainService} from './../../emb/service/main.service';
import { EmbPrintService } from './../../emb/common _services/emb-print.service';
import { CommonDataService as EmbCommonDataService } from './../../emb/common _services/common-data.service';
import { AllEmpService } from '../../hrms/service/all-emp.service';
import { EstablishmentService } from '../../hrms/service/establishment.service';
//oc
// import { LitigationService } from '../../icms/service/litigation.service';
// import { ReportService } from '../../icms/service/report.service';
// private liti: LitigationService, private ICMS_ReportService: ReportService,
//ec
import { AcrService } from '../../hrms/service/acr.service';
import Swal from 'sweetalert2';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
//oc
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
//ec
declare var $: any
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {

  constructor(private embPrintService: EmbPrintService, private ebillService: EbillService,
    private printSalBillService: PrintSalBillService, private tenderService: TenderService,
    private ebillPrintService: EbillPrintService, public ApprovalUserService: ApprovalUserService,
    private allEmpService: AllEmpService, private sanitizer: DomSanitizer, private payableService: EstablishmentService,
    private userAdd: UserAddService, private spinner: NgxSpinnerService, private router: Router, private profileService: ProfileService,
    public mainService: MainService, private taskService: TaskService, private embMainService: EmbMainService,
    private masterDataService: MasterDataService, private embCommonDataService: EmbCommonDataService,
    
    private acrService: AcrService) { }
  erpUser:any;
  b_acct_id:any;
  allEmplyees:any = []
  allCurrentArrangements:any = []
  currentBillObj:any = { header: {}, allEmployees: [], data: {}, sums: {} };
  monthObj:any = { '1': 'January', '2': 'February', '3': 'March', '4': 'April', '5': 'May', '6': 'June', '7': 'July', '8': 'August', '9': 'September', '10': 'October', '11': 'November', '12': 'December' }
  monthEnd:any = { '1': 31, '2': 28, '3': 31, '4': 30, '5': 31, '6': 30, '7': 31, '8': 31, '9': 30, '10': 31, '11': 30, '12': 31 }
  salaryObj:any = { accrual_date: '', b_acct_id: '', fin_year: '', month: '', section_code: '', post_info: {}, emp_info: {}, employement_info: {}, bank_info: {}, att_info: {}, fixed_pay_info: {}, variable_pay_info: {}, total_pay: {} }
  allBillData:any = []
  allApprovalStatus:any = [];
  allApproval:any = []
  approvalObj:any = {};
  allTask:any = {};
  allTaskArr:any = [];
  taskStatus:any = [];
  mxApprovl:any = {};
  payArr:any = []
  dedArr:any = [];
  gross = 0;
  net = 0;
  netded = 0;
  codeVal = { 'SALBILL': 'Salary Bill' }

  statusArr:any = []

// m_avinash (doing it for Acr)
// before approve acr validation check 

acrValid:boolean=false
  async ngOnInit() {

    console.log(" This is the get daat for  ACR ---", history.state)
    if(history.state['key1']!=undefined)
    {
      this.acrValid=true;
      swal.fire('Info',` You Are Hear For Approve ACR Document No ${history.state['key1']}`)
    }
    else{
      this.acrValid=false;
    }
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.approvalObj = this.taskService.approvalObj;
    await this.getAllTaskOfUser();
    await this.getAllHeadRecord();
    await this.getAllWorkInfo();
    await this.getparty();
  }


  async download(element:any) {
    if (element['doc_type'] == 'EBILL') {
      await this.ebillPrintService.printEbill(element, this.erpUser)
    } else if (element['doc_type'] == 'EMB') {
      await this.ebillPrintService.printEmb(element, this.erpUser)
    } else if (element['doc_type'] == 'CB') {
      await this.ebillPrintService.printcb(element, this.erpUser)
    } else if (element['doc_type'] == "INSPECTION REPORT") {

      console.log("task component 89  ",element)
      // await this.ICMS_ReportService.printInspectionRep({ b_acct_id: this.erpUser.b_acct_id, user_id: this.erpUser.user_id, complain_id: element.complain_id });
      //oc
      // await this.ICMS_ReportService.printInspectionRep({ b_acct_id: this.erpUser.b_acct_id, user_id: this.erpUser.user_id, doc_local_no: element.doc_local_no });
      //lc
    } else if (element['doc_type'] == 'SAL_BILL') {
      await this.printSalBillService.printSalbill({ bill_id: element['doc_local_no'] }, this.erpUser)
    }
  }

  // Ng modal callc 

  async getAllTaskOfUser() {
    this.allTaskArr = []
    var obj = Object()
    obj['b_acct_id'] = this.erpUser.b_acct_id
    obj['user_id'] = this.erpUser.user_id
    obj["status"] = 'PENDING'
    console.log(obj)
    var resp = await this.mainService.getAllTaskOfUser(JSON.stringify(obj))
    console.log('this is the getallTask Of User---------', resp)
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.allTaskArr = resp['data']
      this.mainService.count = this.allTaskArr.length
    } else {
      swal.fire("Error", resp['data'], 'error');
    }
  }
  assignedWorks:any = {};
  async getAllHeadRecord() {
    console.log("called")
    this.spinner.show();
    let work_ids:any = []
    work_ids = Object.values(this.embCommonDataService.assignedWork);
    for (var i = 0; i < work_ids.length; i++) {
      this.assignedWorks[work_ids[i]] = 1;
    }
  }
  workArr:any = [];
  workObj:any = {}
  async getAllWorkInfo() {

    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    this.spinner.show()
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    console.log('this is the getWork Flow Info Of User---------', resp)
    if (resp['error'] == false) {
      console.log(resp['data'])
      var dt = [];
      var temp = resp['data'];
      for (var i = 0; i < temp.length; i++) {
        if (this.assignedWorks[temp[i]['id']] != undefined) {
          dt.push(temp[i])
        }
      }
      console.log(dt)
      this.workArr = dt;
      this.workObj = new Object()
      for (let i = 0; i < this.workArr.length; i++) {
        this.workArr[i]['desc'] = this.workArr[i]['work_order_no'] + " - " + this.workArr[i]['work_order_name'];
        this.workObj[this.workArr[i]['id']] = this.workArr[i]
      }
      this.spinner.hide();
      console.log('this is the workObj---------', this.workObj)
    } else {
      this.spinner.hide();
      Swal.fire("Oops", "Error while getting work info");
    }

  }

  party = [];
  partyObj:any = {};
  async getparty() {
    this.spinner.show();
    var resp = await this.masterDataService.getparty(this.b_acct_id);
    console.log('this is the getparty ---------', resp)
    if (resp["error"] == false) {
      this.party = resp.data;
      this.partyObj = new Object();
      for (let i = 0; i < this.party.length; i++) {
        this.partyObj[this.party[i]["rep_le_id"]] =
          this.party[i]["party_leagal_name"];
      }

      this.spinner.hide();

    } else {
      this.spinner.hide();
      Swal.fire("Error", "Error while getting Vendor Info", "error");
    }
  }



  // --- end ng modal 



  //reject 
  rejectdata:any = {}
  backrowdata:any = {}
  async reject(element:any) {
    this.rejectdata = Object.assign({}, element)
    var obj = Object.assign({}, {})
    console.log(element)

    await this.ApprovalUserService.getWorkflowlogbydocno(this.erpUser, element)
    console.log(obj, this.ApprovalUserService.workflowlogall)
    if (this.ApprovalUserService.workflowlogall[1]['status'] != 'REJECTED') {
      if (this.ApprovalUserService.workflowlogall[1]['status'] == 'REVOKED' || this.ApprovalUserService.workflowlogall[1]['user_id'] == -1) {
        for (let i = 0; i < this.ApprovalUserService.workflowlogall.length; i++) {
          if (this.ApprovalUserService.workflowlogall[i]['user_id'] == -1) continue;
          if (this.ApprovalUserService.workflowlogall[i]['level'] <= element['level'] && this.ApprovalUserService.workflowlogall[i]['branch'] <= element['branch'] && (this.ApprovalUserService.workflowlogall[i]['status'] == 'APPROVED')) {

            obj = this.ApprovalUserService.workflowlogall[i]
            console.log(this.ApprovalUserService.workflowlogall[i])
            break;


          }


        }
      } else {
        obj = this.ApprovalUserService.workflowlogall[1]
      }

    } else {
      for (let i = 0; i < this.ApprovalUserService.workflowlogall.length; i++) {
        if (this.ApprovalUserService.workflowlogall[i]['user_id'] == -1) continue;
        if (this.ApprovalUserService.workflowlogall[i]['level'] <= element['level'] && this.ApprovalUserService.workflowlogall[i]['branch'] <= element['branch'] && (this.ApprovalUserService.workflowlogall[i]['status'] == 'APPROVED')) {
          if (this.ApprovalUserService.workflowlogall[i]['user_id'] == element['user_id'] && this.ApprovalUserService.workflowlogall[i]['role_cd'] == element['role_cd']) {

            if (this.ApprovalUserService.workflowlogall[i + 1] == undefined && this.rejectdata['doc_type'] == "DEFENDANT DOCS" && this.rejectdata['module_cd'] == 'ICMS') {
              await this.finalreject()
              return;
            }
            if (this.ApprovalUserService.workflowlogall[i + 1]['user_id'] == -1) {
              obj = this.ApprovalUserService.workflowlogall[i + 2]
              console.log(this.ApprovalUserService.workflowlogall[i])
              break;
            } else {
              obj = this.ApprovalUserService.workflowlogall[i + 1]
              console.log(this.ApprovalUserService.workflowlogall[i])
              break;
            }

          }

        }


      }
    }
    console.log(obj)
    this.backrowdata = Object.assign({}, obj)
    $('#myModal3').modal('show');


  }

  async finalreject() {
    console.log(this.rejectdata)
    let obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['module_cd'] = this.rejectdata['module_cd']
    obb['status'] = 'REJECTED'
    obb['doc_type'] = this.rejectdata['doc_type']
    obb['id'] = this.rejectdata['id']
    console.log(obb)
    var resp = await this.mainService.updatetask(obb)
    if (resp['error'] == false) {
      await this.updatestatus(this.rejectdata['doc_local_no'], 'REJECTED')
      await this.getAllTaskOfUser()
      Swal.fire("Success", "Rejected Successfully!!", 'success');

    }
    else {
      Swal.fire('ERROR', resp['data'], 'error');
    }



  }

  async updatestatus(id:any, status:any) {
    console.log(status)
    let obb:any = new Object()
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['id'] = id
    obb['update_user_id'] = this.erpUser.user_id
    obb['status'] = status
    console.log(obb)
    this.spinner.show();
    //oc
    // let resp = await this.liti.updateevidancesList(obb);
//ec
//rc
let resp:any=[]
//lc
    if (resp['error'] == false) {

      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire('ERROR', resp['data'], 'error');


    }
  }



  async rejectdoc() {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_local_no'] = this.rejectdata['doc_local_no']
    var resp = await this.mainService.getembxrefappr(JSON.stringify(obj))
    console.log(resp, obj)
    if (resp['error'] == false) {
      console.log(resp['data'])
      await this.rejectdocstatus(JSON.parse(resp['data'][0]['data']))
    } else {
      swal.fire("Error", resp['data'], 'error');
    }
  }

  async print_emb_for_bill(element:any) {
    this.spinner.show();
    console.log(element);
    var obj = Object();
    obj["b_acct_id"] = this.erpUser.b_acct_id;
    obj["id"] = element.doc_local_no;
    console.log(obj);
    var ebill = await this.ebillService.getBill(JSON.stringify(obj));
    if (ebill['error'] == false) {
      if (ebill.data.length != 0) {
        await this.embPrintService.print1(ebill.data[0]['emb_no'], element['node_cd'])
      } else {
        swal.fire("warning", "EMB not Found", 'warning')
      }
    } else {
      swal.fire("warning", "EMB not Found", 'warning')
    }
    this.spinner.hide();
  }
  async rejectdocstatus(idarr:any) {
    var obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = idarr
    obj['update_user_id'] = this.erpUser.user_id
    obj['status'] = 'REJECTED'
    var resp = await this.mainService.changestatus(obj)
    if (resp['error'] == false) {
      console.log(resp['data'])

    } else {
      swal.fire("Error", resp['data'], 'error');
    }
  }

 // Avinash 
 async rejectExtraWorkStatus()
 {
 let obj:any= new Object();
 obj['b_acct_id']=this.b_acct_id;
 obj['update_user_id'] = this.erpUser.user_id
 obj['doc_local_no']=this.rejectdata['doc_local_no']
 obj['status']='REJECTED'
 let resp= await this.mainService.rejectExtraWork(obj)
 console.log(' reject resp0', resp);
 if (resp['error'] == false) {
  console.log("reject extra work ", resp['data'])

} else {
  swal.fire("Error", resp['data'], 'error');
}
 }





  async rejectedapproval() {

    await this.rejectnewrowapproval(this.backrowdata, this.rejectdata)
  }
  async rejectnewrowapproval(element:any, obj:any) {
    this.spinner.show()
    console.log(element)
    var obb = Object()
    obb['user_id'] = element['user_id']


    obb["user_name"] = element['user_name'];
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['forwarded_by'] = this.erpUser.user_id
    obb['forward_msg'] = this.appr_remark
    obb['remark'] = ""
    obb['status'] = 'PENDING'
    obb['doc_type'] = element['doc_type']//'EBILL'
    obb['doc_local_no'] = element['doc_local_no']
    obb['doc_desc'] = element['doc_desc']
    obb['role_cd'] = element['role_cd']
    obb['module_cd'] = element['module_cd'] //'EMB'
    obb['node_cd'] = element['node_cd']
    obb['level'] = element['level']
    obb['branch'] = element['branch']
    obb['field_value'] = element['field_value']

    var resp = await this.mainService.insertTask(obb)
    if (resp['error'] == false) {
      await this.rejectapproval(obj)
      if(element['doc_type']=='EXTRA_WORK')
       {
         await this.rejectExtraWorkStatus();
         
       }
       else{

         if (element['doc_type'] == 'EMB') await this.rejectdoc()
         else await this.rejectdocment(obj)
       }
      await this.getAllTaskOfUser()
      if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])
      this.spinner.hide();
      swal.fire("Success", "Rejected Successfully!!", 'success');

    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');

    }

  }
  async rejectapproval(element:any) {
    this.spinner.show()
    var obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['remark'] = this.appr_remark
    obb['status'] = 'REJECTED'
    obb['id'] = element['id']
    var resp = await this.mainService.updatetask(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }

  }
  async rejectdocment(element:any) {
    this.spinner.show()
    console.log('thi sis the rejectv 3 functjion ', element)
    let obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['module_cd'] = element['module_cd']
    obb['status'] = 'REJECTED'
    obb['doc_type'] = element['doc_type']
    obb['doc_local_no'] = element['doc_local_no']
    obb['remark'] = this.appr_remark

    var resp = await this.mainService.updateWorkflowDocumentStatus(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }

  }
  selectedTender:any = {}
  async Appr(element:any) {
    console.log("approve button click",element)
    this.tender_apprdata = Object.assign({}, element)
    console.log('data for first di object ',this.tender_apprdata);

if(element.doc_type=='ACR' && this.acrValid==false)
{
 
    swal.fire('Info','Go to ACR Component Add Your Report and then Approve ','info');
    return;
  
}
if(element.doc_type=='ACR' && this.acrValid==true && element.doc_local_no!=history.state['key1'] )
{
  swal.fire('Info', ` You Can Approve only in ACR Document No ${history.state['key1']} at this time`);
  return
}




    let obj:any = {}
    obj['id'] = element['doc_local_no']
    obj['b_acct_id'] = this.erpUser.b_acct_id;
    obj['work_id'] = element['node_cd'];
    await this.getundertakings(element)
    await this.ApprovalUserService.getWorkflowlog(this.erpUser, element['doc_type'], element['module_cd'])
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.erpUser, this.tender_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.erpUser, this.tender_apprdata)
    var selectedTender = await this.tenderService.getBoq(JSON.stringify(obj));
    this.selectedTender = selectedTender['data'][0]
    // console.log(this.selectedTender)
    // console.log(this.ApprovalUserService.workuser)
    $('#myModal2').modal('show');
  }



  //udertakings************************************//
  async getundertakings(element:any) {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = element['module_cd'] // 'EMB'
    obj['doc_type'] = element['doc_type'] // 'EBILL'
    obj['role_cd'] = element['role_cd']
    console.log(obj)
    var resp = await this.mainService.getundertakingsWithWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp['data'].length > 0) {
        this.undertakings = JSON.parse(resp['data'][0]['data'])
      }
    } else {
      this.spinner.hide();
    }

  }
  forwarded_user_id:any
  tender_apprdata:any = {}
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        obb['user_id'] = -1
        if (this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined) {
          swal.fire("Error", "Please Map Verdor with Work..!", 'error');
          return
        }
        obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }
      if (obb["user_id"] == -1) {
        let userData:any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }
 



      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.erpUser.b_acct_id
      obb['forwarded_by'] = this.erpUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = this.tender_apprdata['doc_type']//'EBILL'
      obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
      obb['doc_desc'] = this.tender_apprdata['doc_desc']

      obb['module_cd'] = this.tender_apprdata['module_cd'] //'EMB'
      obb['node_cd'] = this.tender_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.tender_apprdata['level']
        obb['branch'] = this.tender_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }

      obb['field_value'] = this.tender_apprdata['field_value']

      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        if (this.tender_apprdata['doc_type'] == 'EMB') await this.embdoc('UNDERAPPROVAL')
        else await this.underapprovalapproval(this.tender_apprdata)
        if (this.ApprovalUserService.conditionflag == true) await this.approvedocmentwithcondition()
        await this.getAllTaskOfUser()
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          if (this.message_flag == true) await this.getle(this.selectedTender['party_id'])
        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.spinner.hide();
        if (this.ApprovalUserService.conditionflag == false) 
        {
          // m_avinash 
            if(this.acrValid==true)
            {
              await this.updateStatusOfACR()
              console.log('this is hte name where we send data obj chato bino  ', obb)
              await this.acrTrackInsert(obb)
            }
          swal.fire("Success", "Approval Forwarded Successfully!!", 'success'); 

        } 

      } else {
        this.spinner.hide();
        swal.fire("Error", resp['data'], 'error');

      }
    } else {
      swal.fire("Error", "Please Select Any User..!", 'error');

      this.spinner.hide();
    }
  }
  message_flag = false
  async getle(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainService.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      await this.sendmessage(resp['data'][0]['phone_no'])
      this.spinner.hide();
      this.message_flag = false
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');

    }
  }
  async sendmessage(mobile:any) {
    console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainService.sendMsg(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }
  undertakings:any = []
  async pendingapproval() {
    this.spinner.show()
    let obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['remark'] = this.appr_remark
    obb['status'] = 'APPROVED'
    obb['id'] = this.tender_apprdata['id']
    obb['undertaking'] = JSON.stringify(this.undertakings)
  console.log("this is the pending approval of task component", obb)
    var resp = await this.mainService.updatetask(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }



  async underapprovalapproval(element:any) {
    this.spinner.show()
    let obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['status'] = 'UNDERAPPROVAL'
    obb['module_cd'] = this.tender_apprdata['module_cd']
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
    var resp = await this.mainService.updateWorkflowDocumentStatus(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');

    }

  }
// M_AVINASH 

// change the status of first acr
async updateStatusOfACR()
{
  let obj:any=new Object();
  obj['b_acct_id']=this.b_acct_id
  if(history.state['role']=='off1') // second rank officer
  {

    obj['first_id']=history.state['key2']
    let resp= await this.acrService.changeStatusOfACRFirst(obj)
    console.log(" this is hte update first acr off1 responce--", resp);
  }
  if(history.state['role']=='D11' || history.state['role']=='E11'  ) 
  {
    obj['first_id']=history.state['key2']
    // obj['work_id']=history.state['key1'];
    let resp= await this.acrService.changeStatusOfAcrOfficer(obj)
  }
 
}


// CHANge status in acr self final approval 
async acrDocStatus(status:any)
{
  let obj= Object();
  obj['b_acct_id']= this.b_acct_id;
  obj['doc_loc_no']= this.tender_apprdata['doc_local_no']
  obj['role_approve']= 'Approved'
  let resp= await this.acrService.changeStatusOfAcrSelf(obj)
  if(resp['error']==false)
  {
    // await this.approvedocment
    console.log("this is the approved acr form", resp)
  }
  else{
    swal.fire("Error",'Acr Status Not Change', 'error');
  }
}


// insert in acr track 
async  acrTrackInsert(obb:any)
   {
     let obj:any= new Object();
     obj['b_acct_id'] = this.b_acct_id;
     obj['work_id']= obb.doc_local_no // its same work_id
     obj['user_id_from']= obb.forwarded_by
     obj['name_from']=   this.erpUser.party_name 
     obj['user_id_to']=  obb.user_id // where we send file
     obj['name_to']=   obb.user_name
     obj['direction']='forward'; 
     obj['flag']=1; 
    obj['form_no']=history.state['key3'];  
    obj['first_id']=history.state['key2']
    obj['self_id']=history.state['self_id']
    if(history.state['review_id']!=undefined)
    {
    obj['review_id']=history.state['review_id']
    }
     let resp= await this.acrService.insertAcrTrack(obj);
     console.log(" this is the  acr Track Insert ", resp);

   }



//--- m_avinash
  async embdoc(status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['doc_local_no'] = this.tender_apprdata['doc_local_no']
    var resp = await this.mainService.getembxrefappr(JSON.stringify(obj))
    if (resp['error'] == false) {
      await this.docstatus(JSON.parse(resp['data'][0]['data']), status)
    } else {
      swal.fire("Error", resp['data'], 'error');
    }
  }
  async docstatus(idarr:any, status:any) {
    var obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = idarr
    obj['update_user_id'] = this.erpUser.user_id
    obj['status'] = status
    var resp = await this.mainService.changestatus(obj)
    if (resp['error'] == false) {
      console.log(resp['data'])
    } else {
      swal.fire("Error", resp['data'], 'error');
    }
  }


  async approvedocmentwithcondition() {
    this.spinner.show()
    let obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['module_cd'] = this.tender_apprdata['module_cd']
    obb['status'] = 'APPROVED'
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']

    var resp = await this.mainService.updateWorkflowDocumentStatus(obb)
    if (resp['error'] == false) {
      this.spinner.hide();
      swal.fire("Success", "Approved Successfully!!", 'success');
    } else {
      this.spinner.hide();

      swal.fire("Error", resp['data'], 'error');

    }

  }


  async approvedocment() {
    this.spinner.show()
    var obb:any = new Object();
    obb['b_acct_id'] = this.erpUser.b_acct_id
    obb['module_cd'] = this.tender_apprdata['module_cd']
    obb['status'] = 'APPROVED'
    obb['doc_type'] = this.tender_apprdata['doc_type']
    obb['doc_local_no'] = this.tender_apprdata['doc_local_no']
   
    if (this.tender_apprdata['doc_type'] == 'EMB') {
      await this.embdoc('APPROVED')
      await this.pendingapproval()
      await this.getAllTaskOfUser()
      swal.fire("Success", "EMB Approved Successfully!!", 'success');
      return
    }

  // M_AVINASH
  if(this.tender_apprdata['doc_type']=='ACR')
  {
    await this.acrDocStatus('APPROVED');
    await this.updateStatusOfACR()
    await this.acrTrackApprove()
  
  }
    this.ebillPrintService.processflag = false
    if (this.tender_apprdata['doc_type'] == 'EBILL') {
      let res = await this.ebillPrintService.processebill(this.tender_apprdata, this.erpUser)
    }
  
    if (this.ebillPrintService.processflag) {
      this.spinner.hide();
      return;
    }

    var resp = await this.mainService.updateWorkflowDocumentStatus(obb)
    if (resp['error'] == false) {
      await this.pendingapproval()
      await this.getAllTaskOfUser()
      this.spinner.hide();
      swal.fire("Success", "Approved Successfully!!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Error", resp['data'], 'error');
    }
  }

 async acrTrackApprove()
 {
  let obj:any=new Object();
  obj['b_acct_id']=this.b_acct_id;
  obj['name_from']=this.erpUser.party_name;
  obj['user_id_from']=this.erpUser.user_id;
  obj['form_no']=history.state['key3']
  obj['work_id']=history.state['key1']
  obj['self_id']=history.state['self_id'];
  obj['first_id']=history.state['key2'];
  obj['review_id']=history.state['review_id'];
  obj['direction']='Final Approval'
  let resp= await this.acrService.saveLastApproval(obj);
  console.log(" final approval acr track ---", resp);
 }




  allUser:any = [];
  UserObj:any = {};
  async getAllUsersInfo() {
    var obj = { b_acct_id: this.b_acct_id };
    let resp:any = await this.userAdd.getAllUsersInfo(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allUser = resp['data'];
      for (let i = 0; i < this.allUser.length; i++) {
        this.UserObj[this.allUser[i]['user_id']] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
        this.allUser[i]['name'] = this.allUser[i]['first_name'] + " " + this.allUser[i]['last_name']
      }
    } else {

    }
  }

  lastLevelOfApprovel:any = {};
  async getAllRule() {
    var resp = await this.profileService.getAllApproval(this.b_acct_id);
    if (resp['error'] == false) {
      this.allApproval = resp['data'];
      for (var i = 0; i < this.allApproval.length; i++) {
        this.lastLevelOfApprovel[this.allApproval[i]['doc_type']] = this.allApproval[i]['level_of_approval']
      }
    } else {
    }

    console.log(this.lastLevelOfApprovel)
  }

  stepStatus:any = {};
  async getAllApprovalStatus() {

    this.mainService.tasks = 0;
    this.stepStatus = {};
    this.allTask = {};
    this.allTaskArr = [];
    var ob:any = new Object();
    ob['b_acct_id'] = this.b_acct_id;
    var resp = await this.profileService.getAllApprovalStatus(ob);
    var unique_local_no:any = [];

    if (resp['error'] == false) {
      this.allApprovalStatus = resp['data'];

      var temp:any = [];
      for (let i = 0; i < this.allApprovalStatus.length; i++) {
        this.stepStatus[this.allApprovalStatus[i]['doc_local_no'] + this.allApprovalStatus[i]['level_of_approval']]
          = this.allApprovalStatus[i]['status']
        if (this.allApprovalStatus[i]['user_id'] == this.erpUser['user_id']) {
          temp.push(this.allApprovalStatus[i]);
          unique_local_no.push(this.allApprovalStatus[i]['doc_local_no']);
        }
      }

      this.allApprovalStatus = [];
      this.allApprovalStatus = temp;
      let unique = unique_local_no.filter((item:any, i:any, ar:any) => ar.indexOf(item) === i);
      var tableObj:any = {}
      for (let j = 0; j < unique.length; j++) {
        for (var i = 0; i < this.allApprovalStatus.length; i++) {
          if (this.allApprovalStatus[i]['status'] == 'APPROVED' || this.allApprovalStatus[i]['status'] == 'REJECTED') {
            var obj1:any = Object.assign({}, this.allApprovalStatus[i]);
            obj1['action'] = 0;
            obj1['last_approval_level'] = this.lastLevelOfApprovel[this.allApprovalStatus[i]['doc_type']];
            tableObj[this.allApprovalStatus[i]['doc_local_no'] + this.allApprovalStatus[i]['level_of_approval']] = obj1;
          } else {
            var obj1:any = Object.assign({}, this.allApprovalStatus[i]);
            if (this.allApprovalStatus[i]['level_of_approval'] == 1) {
              obj1['action'] = 1;
              obj1['last_approval_level'] = this.lastLevelOfApprovel[this.allApprovalStatus[i]['doc_type']];
              tableObj[this.allApprovalStatus[i]['doc_local_no'] + this.allApprovalStatus[i]['level_of_approval']] = obj1;
            }
            else if (this.stepStatus[this.allApprovalStatus[i]['doc_local_no'] + ((this.allApprovalStatus[i]['level_of_approval']) - 1)] == 'PENDING') {
              obj1['action'] = 0;
              obj1['last_approval_level'] = this.lastLevelOfApprovel[this.allApprovalStatus[i]['doc_type']];
              tableObj[this.allApprovalStatus[i]['doc_local_no'] + this.allApprovalStatus[i]['level_of_approval']] = obj1;
            } else {
              obj1['action'] = 1;
              obj1['last_approval_level'] = this.lastLevelOfApprovel[this.allApprovalStatus[i]['doc_type']];
              tableObj[this.allApprovalStatus[i]['doc_local_no'] + this.allApprovalStatus[i]['level_of_approval']] = obj1;
            }
          }
        }
      }



      var keys = Object.keys(tableObj);
      var tt = [];
      for (let i = 0; i < keys.length; i++) {
        tt.push(tableObj[keys[i]])
      }

      for (let j = 0; j < unique.length; j++) {
        var tt1;
        for (let i = 0; i < tt.length; i++) {
          if (tt[i]['doc_local_no'] == unique[j]) {
            if (tt[i]['action'] == 1) {
              tt1 = tt[i];
              this.allTaskArr.push(tt1)

              break;
            } else {
            }

          }
        }
      }
      console.log(this.allTaskArr)
      console.log(this.allTaskArr);
      this.mainService.tasks = this.allTaskArr.length;

    } else {
      swal.fire("Error", "Error while getting Tasks");
    }
  }

  async ViewLeave(obj:any) {
    console.log(obj);
    for (let i = 0; i < this.allLeaves.length; i++) {
      if (this.allLeaves[i]['id'] == obj['doc_local_no']) {
        console.log(this.allLeaves[i])
        await this.view(this.allLeaves[i]['document_id'], this.allLeaves[i]['document_name'])
      }
    }
  }
  imgURL:any;
  filename:any
  async view(document_id:any, document_name:any) {
    var obj:any = new Object
    obj['b_acct_id'] = this.b_acct_id
    obj['upload_id'] = document_id
    obj['filename'] = document_name;
    this.filename = document_name;
    this.spinner.show()
    const res = await this.allEmpService.getUploadedFileData(obj);
    if (res) {

      var docname = document_name;
      var ext = docname.split('.');

      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(res);
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.spinner.hide()
    }
  }

  allLeaves = [];
  async allEmployeeLeaveLadger() {
    this.spinner.show();
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.payableService.getAllLeaveLedger(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      this.allLeaves = resp.data;
    } else {
      this.spinner.hide()
      swal.fire("Error", "Error while getting Leaves", 'error');
    }
  }
  async taskStatusChange(s:any) {
    this.taskStatus = s;
    this.taskStatus['status'] = 'APPROVED';
    this.taskStatus['level_of_approval'] = s['level_of_approval'];
    this.taskStatus['user_id'] = this.erpUser.user_id;
    this.taskStatus['b_acct_id'] = this.erpUser.b_acct_id;
    this.taskStatus['update_user_id'] = this.erpUser.user_id;
    console.log(this.taskStatus);
    this.spinner.show();
    var resp = await this.taskService.changeStatus(this.taskStatus);
    console.log(resp);
    if (resp['error'] == false) {
      if (s['last_approval_level'] == s['level_of_approval']) {
        if (s['doc_type'] == 'SALBILL') {
          await this.statusChangeForSALBILL(s, 'APPROVED');
        }
        if (s['doc_type'] == 'BILL') {
          await this.changeCBStatus(s['doc_local_no'], 'APPROVED');
        }
        if (s['doc_type'] == 'CONTRA') {
          await this.changeContraStatus(s['doc_local_no'], 'APPROVED');
        }
        if (s['doc_type'] == 'BP') {
          await this.changeBPStatus(s['doc_local_no'], 'APPROVED');
        }
        if (s['doc_type'] == 'MJ') {
          await this.changeMJStatus(s['doc_local_no'], 'APPROVED');
        }
        if (s['doc_type'] == 'LEAVE') {
          await this.changeLeaveStatus(s['doc_local_no'], 'APPROVED');
        }
      }
      await this.getAllApprovalStatus();
      this.spinner.hide();
      swal.fire('Success', 'Task APPROVED Successfully', 'success');

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while Approving Bill')
    }

  }

  async taskStatusChange_Reject(s:any) {
    this.taskStatus = s;
    this.taskStatus['status'] = 'REJECTED';
    this.taskStatus['b_acct_id'] = this.erpUser.b_acct_id;
    this.taskStatus['level_of_approval'] = s['level_of_approval'];
    this.taskStatus['user_id'] = this.erpUser.user_id;
    this.taskStatus['update_user_id'] = this.erpUser.user_id;
    console.log(this.taskStatus);
    this.spinner.show();
    var resp = await this.taskService.changeStatus(this.taskStatus);
    if (resp['error'] == false) {
      if (s['doc_type'] == 'SALBILL') {
        await this.statusChangeForSALBILL(s, 'REJECTED');
      }
      if (s['doc_type'] == 'BILL') {
        await this.changeCBStatus(s['doc_local_no'], 'REJECTED');
      }
      if (s['doc_type'] == 'CONTRA') {
        await this.changeContraStatus(s['doc_local_no'], 'REJECTED');
      }
      if (s['doc_type'] == 'BP') {
        await this.changeBPStatus(s['doc_local_no'], 'REJECTED');
      }
      if (s['doc_type'] == 'MJ') {
        await this.changeMJStatus(s['doc_local_no'], 'REJECTED');
      }
      if (s['doc_type'] == 'LEAVE') {
        await this.changeLeaveStatus(s['doc_local_no'], 'REJECTED');
      }
      await this.getAllApprovalStatus();
      this.spinner.hide();
      swal.fire('Success', 'Task REJECTED Successfully', 'success');

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while rejecting Bill')
    }

  }
  async changeLeaveStatus(id:any, status:any) {
    var dd:any;
    for (let i = 0; i < this.allLeaves.length; i++) {
      if (this.allLeaves[i]['id'] == id) {
        console.log(this.allLeaves[i]);
        dd = this.allLeaves[i]
      }
    }
    this.spinner.show();
    let obj:any = Object.assign({}, dd);
    obj['b_acct_id'] = this.b_acct_id
    obj['emp_id'] = dd['emp_id']
    obj['leave_status_code'] = status;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['approval_user_id'] = this.erpUser.user_id;
    console.log(obj);
    var resp = await this.payableService.approveLeave(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
    }


  }
  async changeMJStatus(id:any, status:any) {
    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = id
    obj['status'] = status
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.taskService.updateUnpostedJournalStatus(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  async changeBPStatus(id:any, status:any) {
    this.spinner.show();
    let obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = id
    obj['status'] = status
    obj['update_user_id'] = this.erpUser.user_id;
    var resp = await this.taskService.updateStatus(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }


  async changeContraStatus(id:any, status:any) {
    var obj1:any = new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['voucher_no'] = id;
    obj1['status'] = status;
    obj1['update_user_id'] = this.erpUser.user_id;

    console.log(obj1)
    var resp = await this.taskService.UpdateStatusContra(obj1);
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  async changeCBStatus(cb_id:any, status:any) {
    var obj1:any = new Object();
    obj1['b_acct_id'] = this.b_acct_id;
    obj1['cb_id'] = [cb_id];
    obj1['status'] = status;
    obj1['update_user_id'] = this.erpUser.user_id;

    console.log(obj1)
    var resp = await this.taskService.changeCbStatus(obj1);
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }
  async status(element:any) {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["doc_local_no"] = element.doc_local_no;
    obj['module_cd'] = element.module_cd
    obj['doc_type'] = element.doc_type
    this.spinner.show();
    var resp = await this.mainService.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      let obj:any = {};
      obj["id"] = element["doc_local_no"];
      obj["b_acct_id"] = this.b_acct_id;
      obj["work_id"] = element["node_cd"];
      //var ebill = await this.ebillService.getBill(JSON.stringify(obj))
      let tender = await this.tenderService.getBoq(JSON.stringify(obj));
      this.selectedTender = tender["data"][0];
      console.log(this.selectedTender);
      await this.ApprovalUserService.getWorkflowloguser(this.erpUser, element)
      for (let i = 0; i < this.ApprovalUserService.workflowuser.length; i++) {
        this.UserObj[this.ApprovalUserService.workflowuser[i]['user_id']] = this.ApprovalUserService.workflowuser[i]['emp_name']

      }
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["user_id"] == -1) {
          resp.data[i]["name"] = "Contractor"; //this.partyObj[resp['data'][i]['vendor_id']]
          resp.data[i]["role_cd"] = "Contractor";
          resp.data[i]["user_id"] = resp["data"][i]["vendor_id"];
          resp.data[i]["is_vendor"] = true;
          continue;
        }
        for (let j = 0; j < this.ApprovalUserService.workflowuser.length; j++) {
          if (
            resp.data[i]["user_id"] ==
            this.ApprovalUserService.workflowuser[j]["user_id"] &&
            resp.data[i]["role_cd"] ==
            this.ApprovalUserService.workflowuser[j]["role_cd"]
          ) {
            resp.data[i]["name"] =
              this.ApprovalUserService.workflowuser[j]["emp_name"];
            //console.log(this.ApprovalUserService.workflowuser[j]);
            resp.data[i]["role_cd"] =
              this.ApprovalUserService.workflowuser[j]["role_cd"];
          }
        }
        if (resp.data[i]["name"] == undefined) {
          resp.data[i]["name"] = "";
        }
      }
      this.statusArr = resp['data'];
      console.log("where is name : ", this.statusArr)
      $('#myModal').modal('show');
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while getting status");
    }
  }


  ////****************************************************************************************** */
  back() {
    // this.router.navigate(['/index'])
    // this.router.navigate(['/icms/icms-index'])
    window.history.go(-1)
  }
  async getAllEmployees() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.taskService.getEmployeeMasterData(obj);
    if (resp['error'] == false) {
      this.allEmplyees = resp.data;
      for (var i = 0; i < this.allEmplyees.length; i++) {
        this.salaryObj.emp_info[this.allEmplyees[i].emp_id] = this.allEmplyees[i];
      }
    } else {
      Swal.fire('Error', "Error while getting Employee Info")
    }
  }

  async getAllActiveEmployees() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.taskService.getArrayAllCurrentEstablishementInfo(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.allCurrentArrangements = resp['data'];
      for (var i = 0; i < this.allCurrentArrangements.length; i++) {
        this.salaryObj.employement_info[this.allCurrentArrangements[i].emp_id] = this.allCurrentArrangements[i];

      }

    } else {
      Swal.fire('Error', "Error while getting Employement Info")

    }
  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }




  async getAllBill(ob:any, type:any) {
    this.spinner.show()
    console.log(ob);
    await this.getAllEmployees();
    await this.getAllActiveEmployees();
    this.currentBillObj = { header: {}, allEmployees: [], data: {}, sums: {} };

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['bill_id'] = ob['doc_local_no'];
    var resp = await this.taskService.getAllBill(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      var billObj:any = {};
      var header = "";
      var dt = resp['data'];
      if (dt.length > 0) {
        header = dt[0];
      }
      var grand:any = undefined;
      var month = "";
      var fin_year = "";
      for (var i = 0; i < dt.length; i++) {
        //header = dt[0];
        if (billObj[dt[i]['section_code']] == undefined) {
          month = dt[i]['month'];
          fin_year = dt[i]['fin_year'];
          billObj[dt[i]['section_code']] = {};
          billObj[dt[i]['section_code']]['data'] = {};//{'BASIC':0.00,'DA':0.00,'DEP':0.00,'HRA':0.00,'MA':0.00,'VA':0.00,'WA':0.00,'miscpay':[],'LIC1':0.00,LIC2:0.00,LIC3:0.00,LIC4:0.00,LIC5:0.00,LIC6:0.00,LIC7:0.00,PF:0.00,GIS:0.00,IT:0.00,HRR:0.00,VD:0.00,VADV:0.00,BLDADV1:0.00,BLDADV2:0.00,BLDADV3:0.00,PFADV:0.00,PFADV1:0.00,PFADV2:0.00,BADV:0.00,EWF:0.00,miscded:[]};
          billObj[dt[i]['section_code']]['total'] = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          if (grand == undefined) {
            grand = { 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, GIS: 0.00, NPS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          }
        }
        if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] == undefined) {
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']] = { emp_id: '', emp_name: '', designation_code: '', grade_pay_code: '', pay_band: '', sal_acc: '', pf: '', pf_ifsc: '', 'BASIC': 0.00, 'DA': 0.00, 'DEP': 0.00, 'HRA': 0.00, 'MA': 0.00, 'VA': 0.00, 'WA': 0.00, 'miscpay': [], 'LIC1': 0.00, LIC2: 0.00, LIC3: 0.00, LIC4: 0.00, LIC5: 0.00, LIC6: 0.00, LIC7: 0.00, PF: 0.00, NPS: 0.00, GIS: 0.00, IT: 0.00, HRR: 0.00, VD: 0.00, VADV: 0.00, BLDADV1: 0.00, BLDADV2: 0.00, BLDADV3: 0.00, PFADV: 0.00, PFADV1: 0.00, PFADV2: 0.00, BADV: 0.00, EWF: 0.00, miscded: [], total: 0.00, net: 0.00 };
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_id'] = "VDA" + this.getNumberFormat(dt[i]['emp_id']);
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['emp_name'] = this.salaryObj.emp_info[dt[i].emp_id]['emp_name'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['designation_code'] = this.salaryObj.employement_info[dt[i].emp_id]['designation_code'];;
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['grade_pay_code'] = "GP " + this.salaryObj.employement_info[dt[i].emp_id]['grade_pay_code'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pay_band'] = 'PB ' + '(' + this.salaryObj.employement_info[dt[i].emp_id]['pay_scale_code'] + ')';
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['sal_acc'] = this.salaryObj.emp_info[dt[i].emp_id]['acct_no'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_acct_no'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['pf_ifsc'] = this.salaryObj.emp_info[dt[i].emp_id]['pf_ifsc_code'];
        }
        if (dt[i]['pay_code'] == 'PAY') {
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['total'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['total']['total'] += dt[i]['pay_component_amt'];
          billObj[dt[i]['section_code']]['total']['net'] += dt[i]['pay_component_amt'];
          grand['total'] += dt[i]['pay_component_amt'];
          grand['net'] += dt[i]['pay_component_amt'];

          if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
            billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
            grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

            billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          } else {
            billObj[dt[i]['section_code']]['total']['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
            grand['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

            billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscpay'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          }

        } else {
          billObj[dt[i]['section_code']]['total']['net'] -= dt[i]['pay_component_amt'];
          grand['net'] -= dt[i]['pay_component_amt'];

          billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['net'] -= dt[i]['pay_component_amt'];
          if (billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] != undefined) {
            billObj[dt[i]['section_code']]['total'][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
            grand[dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];

            billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']][dt[i]['pay_component_code']] += dt[i]['pay_component_amt'];
          } else {
            billObj[dt[i]['section_code']]['total']['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
            grand['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });

            billObj[dt[i]['section_code']]['data'][dt[i]['emp_id']]['miscded'].push({ code: dt[i]['pay_component_code'], amount: dt[i]['pay_component_amt'] });
          }

        }
      }
      if (type == 'bill') {
        this.print(billObj, header, grand, month, fin_year);

      }
      else {
        this.print1(billObj, header, grand, month, fin_year);
      }
      this.spinner.hide()
    } else {
      this.spinner.hide();
      swal.fire("Error", "Error while printing pay bill")
    }
  }
  async statusChangeForSALBILL(element:any, bill_status_code:any) {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.erpUser.user_id;
    obj['bill_id'] = element.doc_local_no;
    obj['bill_status_code'] = bill_status_code;
    console.log(obj);
    this.spinner.show();
    var resp = await this.taskService.changeStatusOfBill(obj);
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error in Bill Generation');
    }

  }

  async sendBillToAccount() {
    var billObj:any = new Object();
    billObj["b_acct_id"] = this.b_acct_id;
    billObj['data'] = [];
    var amt = 0;
    var cb_description = '';
    var cb_date = "";
    console.log(this.allBillData);
    for (var i = 0; i < this.allBillData.length; i++) {
      amt += this.allBillData[i].pay_component_amt;
      cb_date = this.allBillData[i]['bill_date'];
      cb_description = this.allBillData[i]['bill_desc'];
      var ob:any = new Object();
      ob['account_amount'] = this.allBillData[i].pay_component_amt
      ob['account_code'] = this.allBillData[i].pay_component_code
      ob['party_id'] = "HR" + this.allBillData[i]['emp_id'];
      ob['cb_description'] = cb_description;
      ob['cb_date'] = cb_date;
      ob['source_code'] = "HR";
      billObj['source_local_no'] = "HR" + this.allBillData[i]['bill_id'];

      ob['source_local_no'] = "HR" + this.allBillData[i]['bill_id'];

      billObj['data'].push(JSON.stringify(ob));
    }

    billObj["cb_description"] = cb_description;
    billObj['cb_amount'] = amt;
    billObj['cb_date'] = cb_date;
    billObj['cb_status'] = "PENDING"
    billObj['source_code'] = "HR"

    billObj['create_user_id'] = this.erpUser.user_id;
    console.log(billObj);
    var resp = await this.taskService.createContingentBill(billObj);
    console.log(resp);
    if (resp['error'] == false) {

    } else {
      this.spinner.hide();
      swal.fire('Error', 'Error while sending the bill to accounts');
    }
  }



  print(billObj:any, header:any, grand:any, month:any, fin_year:any) {
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "   " + header['bill_desc'] + "   Date: " + header['accrual_date'];
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };
    var sections = Object.keys(billObj);
    var count = 0;
    for (var i = 0; i < sections.length; i++) {
      var data = billObj[sections[i]];
      console.log(data);

      var sectionText:any = { text: 'Section : ' + sections[i], fontSize: 8 };
      if (i != 0) {
        sectionText['pageBreak'] = 'before'
      }
      dd.content.push(sectionText);
      var tbl = {

        layout: 'lightHorizontalLines',
        fontSize: 10,
        table: {

          headerRows: 1,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],

          body: [
            ['Emp\nDetail', 'Basic\nPay', 'Dep.\nAllow', 'DA/Relief', 'Med\nAllow', 'Veh\nAllow', 'HRA', 'WA', 'Misc\nAllow', 'Total', 'LIC', 'PF\nDed', 'NPS', 'Group\nIns.', 'IT', 'House\nRent', 'Veh\nDed', 'Veh\nAdv.', 'Bld\nAdv.', 'PF\nAdv.', 'Bank\nAdv.', 'EWF', 'Misc\nDed', 'Net.\nSal.']



          ]
        }
      };
      dd.content.push(tbl);
      var emps = Object.keys(data['data']);
      count = count + emps.length;
      for (var j = 0; j < emps.length; j++) {
        var obj = data['data'][emps[j]];
        var arr = [];
        var str = obj['emp_id'] + "\n" + obj['emp_name'] + "\n" + obj['designation_code'] + "\n" + obj['grade_pay_code'] + "\n" + obj['pay_band'] + "\n" + "SAL A/C - " + obj['sal_acc'];
        if (obj['pf'] != undefined && obj['pf'] != null && obj['pf'] != 0) {
          str += "\n" + "PF A/C - " + obj['pf']
        }
        if (obj['pf_ifsc'] != undefined && obj['pf_ifsc'] != null && obj['pf_ifsc'] != 0) {
          str += "\n" + "PF Ifsc - " + obj['pf_ifsc']
        }
        arr.push(str);
        arr.push(obj['BASIC']);
        arr.push(obj['DEP']);
        arr.push(obj['DA']);
        arr.push(obj['MA']);
        arr.push(obj['VA']);
        arr.push(obj['HRA']);
        arr.push(obj['WA']);
        var miscpay = obj['miscpay'];
        var str1 = "";
        for (var k = 0; k < miscpay.length; k++) {
          if (k == 0) {
            str1 += miscpay[k]['code'] + " - " + miscpay[k]['amount'];
          } else {
            str1 += "\n" + miscpay[k]['code'] + " - " + miscpay[k]['amount'];
          }

        }
        if (str1 != "") {
          arr.push(str1);
        }
        else {
          arr.push(0.00);
        }
        arr.push(obj['total']);
        var licstr = obj['LIC1'] + "\n" + obj['LIC2'] + "\n" + obj['LIC3'] + "\n" + obj['LIC4'] + "\n" + obj['LIC5'] + "\n" + obj['LIC6'] + "\n" + obj['LIC7'];

        arr.push(licstr);
        arr.push(obj['PF']);
        arr.push(obj['NPS']);
        arr.push(obj['GIS']);
        arr.push(obj['IT']);
        arr.push(obj['HRR']);
        arr.push(obj['VD']);
        arr.push(obj['VADV']);
        var bldstr = obj['BLDADV1'] + "\n" + obj['BLDADV2'] + "\n" + obj['BLDADV3']
        arr.push(bldstr);
        var pfstr = obj['PFADV'] + "\n" + obj['PFADV1'] + "\n" + obj['PFADV2']
        arr.push(pfstr);
        arr.push(obj['BADV']);
        arr.push(obj['EWF']);
        var miscded = obj['miscded'];
        var str2 = "";
        for (var k = 0; k < miscded.length; k++) {
          if (k == 0) {
            str2 += miscded[k]['code'] + " - " + miscded[k]['amount'];
          } else {
            str2 += "\n" + miscded[k]['code'] + " - " + miscded[k]['amount'];
          }

        }
        if (str2 != "") {
          arr.push({ text: str2, fontSize: 8 });
        }
        else {
          arr.push(0.00);
        }
        //console.log(arr)

        arr.push(obj['net']);

        dd.content[dd.content.length - 1].table.body.push(arr);
      }
      var obj = data['total'];
      var arr = [];
      var str = "Section : " + sections[i] + "\n";
      str += "Total Employees : " + emps.length;

      arr.push(str);
      arr.push(obj['BASIC']);
      arr.push(obj['DEP']);
      arr.push(obj['DA']);
      arr.push(obj['MA']);
      arr.push(obj['VA']);
      arr.push(obj['HRA']);
      arr.push(obj['WA']);
      var miscpay = obj['miscpay'];
      var miscpayObj:any = {};
      for (var k = 0; k < miscpay.length; k++) {
        if (miscpayObj[miscpay[k]['code']] == undefined) {
          miscpayObj[miscpay[k]['code']] = 0;
        }
        miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
      }
      var str2 = "";
      var keys = Object.keys(miscpayObj);
      for (var k = 0; k < keys.length; k++) {
        if (k == 0) {
          str2 += keys[k] + " - " + miscpayObj[keys[k]];
        } else {
          str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
        }

      }
      if (str2 != "") {
        arr.push({ text: str2, fontSize: 8 });
      }
      else {
        arr.push(0.00);
      }
      arr.push(obj['total']);
      var licstr = obj['LIC1'] + "\n" + obj['LIC2'] + "\n" + obj['LIC3'] + "\n" + obj['LIC4'] + "\n" + obj['LIC5'] + "\n" + obj['LIC6'] + "\n" + obj['LIC7'];

      arr.push(licstr);
      arr.push(obj['PF']);
      arr.push(obj['NPS']);
      arr.push(obj['GIS']);
      arr.push(obj['IT']);
      arr.push(obj['HRR']);
      arr.push(obj['VD']);
      arr.push(obj['VADV']);
      var bldstr = obj['BLDADV1'] + "\n" + obj['BLDADV2'] + "\n" + obj['BLDADV3']
      arr.push(bldstr);
      var pfstr = obj['PFADV'] + "\n" + obj['PFADV1'] + "\n" + obj['PFADV2']
      arr.push(pfstr);
      arr.push(obj['BADV']);
      arr.push(obj['EWF']);
      var miscded = obj['miscded'];
      var miscdedObj:any = {};
      for (var k = 0; k < miscded.length; k++) {
        if (miscdedObj[miscded[k]['code']] == undefined) {
          miscdedObj[miscded[k]['code']] = 0;
        }
        miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
      }
      var str2 = "";
      var keys = Object.keys(miscdedObj);
      for (var k = 0; k < keys.length; k++) {
        if (k == 0) {
          str2 += keys[k] + " - " + miscdedObj[keys[k]];
        } else {
          str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
        }

      }
      if (str2 != "") {
        arr.push({ text: str2, fontSize: 8 });
      }
      else {
        arr.push(0.00);
      }
      //console.log(arr)

      arr.push(obj['net']);

      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var totalText = { text: 'Grand Total' + "\nTotal Employees : " + count, fontSize: 10, bold: true };

    var obj = grand;
    var arr = []
    arr.push(totalText);
    arr.push(obj['BASIC']);
    arr.push(obj['DEP']);
    arr.push(obj['DA']);
    arr.push(obj['MA']);
    arr.push(obj['VA']);
    arr.push(obj['HRA']);
    arr.push(obj['WA']);
    var miscpay = obj['miscpay'];
    var miscpayObj:any = {};
    for (var k = 0; k < miscpay.length; k++) {
      if (miscpayObj[miscpay[k]['code']] == undefined) {
        miscpayObj[miscpay[k]['code']] = 0;
      }
      miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
    }
    var str2 = "";
    var keys = Object.keys(miscpayObj);
    for (var k = 0; k < keys.length; k++) {
      if (k == 0) {
        str2 += keys[k] + " - " + miscpayObj[keys[k]];
      } else {
        str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
      }

    }
    if (str2 != "") {
      arr.push({ text: str2, fontSize: 8 });
    }
    else {
      arr.push(0.00);
    }
    arr.push({ text: obj['total'], bold: true });
    var amt = obj['LIC1'] + obj['LIC2'] + obj['LIC3'] + obj['LIC4'] + obj['LIC5'] + obj['LIC6'] + obj['LIC7']
    //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
    //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
    arr.push(amt);
    arr.push(obj['PF']);
    arr.push(obj['NPS']);
    arr.push(obj['GIS']);
    arr.push(obj['IT']);
    arr.push(obj['HRR']);
    arr.push(obj['VD']);
    arr.push(obj['VADV']);
    amt = obj['BLDADV1'] + obj['BLDADV2'] + obj['BLDADV3'];
    //var bldstr=obj['BLDADV1']+"\n"+obj['BLDADV2']+"\n"+obj['BLDADV3']
    arr.push(amt);
    amt = obj['PFADV'] + obj['PFADV1'] + obj['PFADV2'];
    //var pfstr=obj['PFADV']+"\n"+obj['PFADV1']+"\n"+obj['PFADV2']
    arr.push(amt);
    arr.push(obj['BADV']);
    arr.push(obj['EWF']);
    var miscded = obj['miscded'];
    var miscdedObj:any = {};
    for (var k = 0; k < miscded.length; k++) {
      if (miscdedObj[miscded[k]['code']] == undefined) {
        miscdedObj[miscded[k]['code']] = 0;
      }
      miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
    }
    var str2 = "";
    var keys = Object.keys(miscdedObj);
    for (var k = 0; k < keys.length; k++) {
      if (k == 0) {
        str2 += keys[k] + " - " + miscdedObj[keys[k]];
      } else {
        str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
      }

    }
    if (str2 != "") {
      arr.push({ text: str2, fontSize: 8 });
    }
    else {
      arr.push(0.00);
    }
    //console.log(arr)

    arr.push({ text: obj['net'], bold: true });

    dd.content[dd.content.length - 1].table.body.push(arr);
    dd.content.push("\n\n");
    var sign = {
      columns: [
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'PREPARED BY:',
          bold: true,
          fontSize: 10
        },
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'CHECKED BY:',
          bold: true,
          fontSize: 10
        },
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'SIGNED BY:',
          bold: true,
          fontSize: 10
        },


      ]
    }
    dd.content.push("\n\n\n");
    dd.content.push("PREPARED BY:                               CHECKED BY:                           SIGNED BY:                         ");
    dd.content.push("\n\n");
    dd.content.push("CERTIFIED:")
    dd.content.push("\n\n");
    dd.content.push({ text: "1. That I have satisfied myself that all the salaries included in the bills drawn in the month of " + this.monthObj[month] + "/" + fin_year + " [the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned." })
    dd.content.push("\n")
    dd.content.push({ text: "2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month." })
    dd.content.push("\n");

    dd.content.push({ text: "3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions." })
    dd.content.push("\n");

    dd.content.push({ text: "4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them." })
    dd.content.push("\n");

    dd.content.push({ text: "5. That the bill has been checked with the sanctioned in the scale register." })
    dd.content.push("\n");

    dd.content.push({ text: "Date :                                                    Signature Of Drawing Officer:" })
    dd.content.push("\n");

    dd.content.push({ text: "Pay Rs. ....................................." })




    pdfMake.createPdf(dd).download();
  }

  print1(billObj:any, header:any, grand:any, month:any, fin_year:any) {
    if (month == 1 || month == 2 || month == 3) {
      fin_year = fin_year + 1;
    }
    console.log(month);
    console.log(fin_year);
    //var txt = "VARANASASI DEVELOPMENT AUTHORITY(VDA)   Officers/THIRD/FOURTH Category EMPLOYEES STATEMENT FOR THE MONTH OF June,2020   PIRNT DATE: 2020-10-10"
    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ")" + "   " + header['bill_desc'] + "   Date: " + header['accrual_date'];
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      //footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      //pageMargins: [ 40, 20, 20, 20 ],
      content: [

      ]
    };
    var sections = Object.keys(billObj);
    var count = 0;
    var tbl = {

      layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],

        body: [
          ['Section\nDetail', 'Basic\nPay', 'Dep.\nAllow', 'DA/Relief', 'Med\nAllow', 'Veh\nAllow', 'HRA', 'WA', 'Misc\nAllow', 'Total', 'LIC', 'PF\nDed', 'NPS', 'Group\nIns.', 'IT', 'House\n Rent', 'Veh\nDed', 'Veh\nAdv.', 'Bld\nAdv.', 'PF\nAdv.', 'Bank\nAdv.', 'EWF', 'Misc\nDed', 'Net.\nSal.']
        ]
      }
    };
    dd.content.push(tbl);
    for (var i = 0; i < sections.length; i++) {
      var data = billObj[sections[i]];
      console.log(data);
      var emps = Object.keys(data['data']);
      count += emps.length;
      var obj = data['total'];
      var arr = [];
      var sectionText = { text: 'Section : ' + sections[i] + "\nTotal Employees : " + emps.length, fontSize: 10, bold: true };



      arr.push(sectionText);
      arr.push(obj['BASIC']);
      arr.push(obj['DEP']);
      arr.push(obj['DA']);
      arr.push(obj['MA']);
      arr.push(obj['VA']);
      arr.push(obj['HRA']);
      arr.push(obj['WA']);
      var miscpay = obj['miscpay'];
      var miscpayObj:any = {};
      for (var k = 0; k < miscpay.length; k++) {
        if (miscpayObj[miscpay[k]['code']] == undefined) {
          miscpayObj[miscpay[k]['code']] = 0;
        }
        miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
      }
      var str2 = "";
      var keys = Object.keys(miscpayObj);
      for (var k = 0; k < keys.length; k++) {
        if (k == 0) {
          str2 += keys[k] + " - " + miscpayObj[keys[k]];
        } else {
          str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
        }

      }
      if (str2 != "") {
        arr.push({ text: str2, fontSize: 8 });
      }
      else {
        arr.push(0.00);
      }
      arr.push({ text: obj['total'], bold: true });
      var amt = obj['LIC1'] + obj['LIC2'] + obj['LIC3'] + obj['LIC4'] + obj['LIC5'] + obj['LIC6'] + obj['LIC7']
      //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
      //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
      arr.push(amt);
      arr.push(obj['PF']);
      arr.push(obj['NPS']);
      arr.push(obj['GIS']);
      arr.push(obj['IT']);
      arr.push(obj['HRR']);
      arr.push(obj['VD']);
      arr.push(obj['VADV']);
      amt = obj['BLDADV1'] + obj['BLDADV2'] + obj['BLDADV3'];
      //var bldstr=obj['BLDADV1']+"\n"+obj['BLDADV2']+"\n"+obj['BLDADV3']
      arr.push(amt);
      amt = obj['PFADV'] + obj['PFADV1'] + obj['PFADV2'];
      //var pfstr=obj['PFADV']+"\n"+obj['PFADV1']+"\n"+obj['PFADV2']
      arr.push(amt);
      arr.push(obj['BADV']);
      arr.push(obj['EWF']);
      var miscded = obj['miscded'];
      var miscdedObj:any = {};
      for (var k = 0; k < miscded.length; k++) {
        if (miscdedObj[miscded[k]['code']] == undefined) {
          miscdedObj[miscded[k]['code']] = 0;
        }
        miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
      }
      var str2 = "";
      var keys = Object.keys(miscdedObj);
      for (var k = 0; k < keys.length; k++) {
        if (k == 0) {
          str2 += keys[k] + " - " + miscdedObj[keys[k]];
        } else {
          str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
        }

      }
      if (str2 != "") {
        arr.push({ text: str2, fontSize: 8 });
      }
      else {
        arr.push(0.00);
      }

      arr.push({ text: obj['net'], bold: true });

      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var sectionText = { text: 'Grand Total' + "\nTotal Employees : " + count, fontSize: 10, bold: true };

    var obj = grand;
    var arr = []
    arr.push(sectionText);
    arr.push(obj['BASIC']);
    arr.push(obj['DEP']);
    arr.push(obj['DA']);
    arr.push(obj['MA']);
    arr.push(obj['VA']);
    arr.push(obj['HRA']);
    arr.push(obj['WA']);
    var miscpay = obj['miscpay'];
    var miscpayObj:any = {};
    for (var k = 0; k < miscpay.length; k++) {
      if (miscpayObj[miscpay[k]['code']] == undefined) {
        miscpayObj[miscpay[k]['code']] = 0;
      }
      miscpayObj[miscpay[k]['code']] += miscpay[k]['amount'];
    }
    var str2 = "";
    var keys = Object.keys(miscpayObj);
    for (var k = 0; k < keys.length; k++) {
      if (k == 0) {
        str2 += keys[k] + " - " + miscpayObj[keys[k]];
      } else {
        str2 += "\n" + keys[k] + " - " + miscpayObj[keys[k]];;
      }

    }
    if (str2 != "") {
      arr.push({ text: str2, fontSize: 8 });
    }
    else {
      arr.push(0.00);
    }
    arr.push({ text: obj['total'], bold: true });
    var amt = obj['LIC1'] + obj['LIC2'] + obj['LIC3'] + obj['LIC4'] + obj['LIC5'] + obj['LIC6'] + obj['LIC7']
    //var licstr=obj['LIC1']+"\n"+obj['LIC2']+"\n"+obj['LIC3']+"\n"+obj['LIC4']+"\n"+obj['LIC5']+"\n"+obj['LIC6']+"\n"+obj['LIC7'];
    //var licstr={ text: 'Section : '+sections[i]+"\nTotal Employees : "+emps.length, fontSize: 8};
    arr.push(amt);
    arr.push(obj['PF']);
    arr.push(obj['NPS']);
    arr.push(obj['GIS']);
    arr.push(obj['IT']);
    arr.push(obj['HRR']);
    arr.push(obj['VD']);
    arr.push(obj['VADV']);
    amt = obj['BLDADV1'] + obj['BLDADV2'] + obj['BLDADV3'];
    //var bldstr=obj['BLDADV1']+"\n"+obj['BLDADV2']+"\n"+obj['BLDADV3']
    arr.push(amt);
    amt = obj['PFADV'] + obj['PFADV1'] + obj['PFADV2'];
    //var pfstr=obj['PFADV']+"\n"+obj['PFADV1']+"\n"+obj['PFADV2']
    arr.push(amt);
    arr.push(obj['BADV']);
    arr.push(obj['EWF']);
    var miscded = obj['miscded'];
    var miscdedObj:any = {};
    for (var k = 0; k < miscded.length; k++) {
      if (miscdedObj[miscded[k]['code']] == undefined) {
        miscdedObj[miscded[k]['code']] = 0;
      }
      miscdedObj[miscded[k]['code']] += miscded[k]['amount'];
    }
    var str2 = "";
    var keys = Object.keys(miscdedObj);
    for (var k = 0; k < keys.length; k++) {
      if (k == 0) {
        str2 += keys[k] + " - " + miscdedObj[keys[k]];
      } else {
        str2 += "\n" + keys[k] + " - " + miscdedObj[keys[k]];;
      }

    }
    if (str2 != "") {
      arr.push({ text: str2, fontSize: 8 });
    }
    else {
      arr.push(0.00);
    }
    //console.log(arr)

    arr.push({ text: obj['net'], bold: true });

    dd.content[dd.content.length - 1].table.body.push(arr);
    dd.content.push("\n\n");
    var sign = {
      columns: [
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'PREPARED BY:',
          bold: true,
          fontSize: 10
        },
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'CHECKED BY:',
          bold: true,
          fontSize: 10
        },
        {
          // auto-sized columns have their widths based on their content
          width: 'auto',
          text: 'SIGNED BY:',
          bold: true,
          fontSize: 10
        },


      ]
    }
    dd.content.push("\n\n\n");
    dd.content.push("PREPARED BY:                               CHECKED BY:                           SIGNED BY:                         ");
    dd.content.push("\n\n");
    dd.content.push("CERTIFIED:")
    dd.content.push("\n\n");
    dd.content.push({ text: "1. That I have satisfied myself that all the salaries included in the bills drawn in the month of " + this.monthObj[month] + "/" + fin_year + " [the last preceding month] with the exception of those detailed below of which total has been refunded by deduction from the bill has been distributed to the proper persons and their receipts have been taken in acquittance rolls field in my office with reciept-stamp dully cancelled for every payment in access of Rs. 20 and that all leaves and promotions etc have been in the service book of the official concerned." })
    dd.content.push("\n");
    dd.content.push({ text: "2. That all persons for whom pay has been drawn in this bill have actually been entertained during the month." })
    dd.content.push("\n");

    dd.content.push({ text: "3. That all the persons for whom house-rent allowance has been shown in this bill actually occupied a rented house for which they paid rent as shown in this bill and are entitled to the allowance under the standing instructions." })
    dd.content.push("\n");

    dd.content.push({ text: "4. That all the persons in respect of whom conveyance allowance has been drawn in the bill have satisfied me that they have actually maintained the conveyance in a workable condition and have been using them." })
    dd.content.push("\n");

    dd.content.push({ text: "5. That the bill has been checked with the sanctioned in the scale register." })
    dd.content.push("\n");

    dd.content.push({ text: "Date :                                                    Signature Of Drawing Officer:" })
    dd.content.push("\n");

    dd.content.push({ text: "Pay Rs. ....................................." })


    pdfMake.createPdf(dd).download();
  }

 
  certificate() {
    var account_name = this.erpUser["account_name"];
    var account_short_name = this.erpUser["account_short_name"];
    var txt = account_name + "(" + account_short_name + ") \n";
    //var txt = "LUCKNOW DEVELOPMENT AUTHORITY" + "(" + "LDA" + ") , LUCKNOW\n" + "VIPIN KHAND,GOMTI NAGAR , LUCKNOW - 226010";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var arr = [];
        var obj = {
          text: txt,
          alignment: "center",
          margin: [72, 40],
          fontSize: 15,
          bold: true,
        };
        arr.push(obj);
        return arr;
      },
      pageOrientation: "portrait",
      pageMargins: [40, 60, 40, 60],
      content: [],
    };
    var header1 = {
      columns: [
        {
          width: "*",
          text:
            "APPROVAL LOG  FOR FILE/EMB NO - " +
            this.selectedTender["already_emb"],
          bold: true,
          alignment: "center",
        },
      ],
    };
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    var tbl:any = {
      fontSize: 10,
      table: {
        headerRows: 0,
        widths: [370, 375],
        body: [],
      },
    };
    var workObj = this.workObj[this.selectedTender["work_id"]];
    var partyObj = this.partyObj[this.selectedTender["party_id"]];
    //console.log(workObj, partyObj);
    var arr = [];
    arr.push({ text: "Name Of Contractor ", bold: true });
    arr.push(partyObj);
    tbl.table.body.push(arr);
    arr = [];
    arr.push({ text: "Name Of Work ", bold: true });
    arr.push(workObj["work_order_name"]);
    tbl.table.body.push(arr);
    arr = [];
    arr.push({ text: "Estimate No ", bold: true });
    arr.push("");
    tbl.table.body.push(arr);
    arr = [];
    arr.push({ text: "Work Order No ", bold: true });
    arr.push(workObj["work_order_no"]);
    tbl.table.body.push(arr);
    arr = [];
    arr.push({ text: "Agreement Dated ", bold: true });
    arr.push(workObj["agr_dt"]);
    tbl.table.body.push(arr);
    dd.content.push(tbl);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    var tbl1 = {
      fontSize: 15,
      table: {
        headerRows: 1,
        widths: [70, 124, 124, 124, 144, 125],
        body: [
          ["User ID", "User Name", "Designation", "Action", "Date", "Remark"],
        ],
      },
    };
    for (var i = 0; i < this.statusArr.length; i++) {
      var arr = [];
      arr.push(this.statusArr[i]["user_id"]);
      arr.push(this.statusArr[i]["user_name"]);
      if (this.statusArr[i]["role_cd"] != "Contractor") {
        arr.push(this.embMainService.roleCodeToRoleDescription[this.statusArr[i]["role_cd"]]);
      } else {
        arr.push(this.statusArr[i]["role_cd"]);
      }
      arr.push(this.statusArr[i]["status"]);
      arr.push(this.statusArr[i]["timestamp"]);
      arr.push(this.statusArr[i]["remark"]);
      tbl1.table.body.push(arr);
    }
    dd.content.push(tbl1);
    pdfMake.createPdf(dd).download("running-bill-cert" + ".pdf");

  }

}
