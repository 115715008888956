import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  httpUrl;

  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/icms";
  }

  async createLocation(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/zone/createZonelocation' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
//////////////////////campus boundry///////////
  async create_campus_boundry(obj:any) {
    console.log(obj,"from service")
    const resp = await this.http.post(this.httpUrl + '/campus_boundary/create_campus_boundry' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }

  async getcampus_boundry(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/campus_boundary/get_campus_boundry' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async getcampus_coordinate(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/campus_boundary/getall_campus_cordinate' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async deletecampus(obj:any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/campus_boundary/deletecampus' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
// /////////////////campus boundry end ///////////////////
  async getLocationData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/zone/getZonedata' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
 async getcordinateLocationData(obj:any) {
    const resp = await this.http.get(this.httpUrl + '/zone/getallzonecordinate' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }

  async deletezone(obj:any) {
    const resp = await this.http.delete<any>(this.httpUrl + '/zone/deletezone' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


}


