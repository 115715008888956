<div id="sidebar-collapse">

    <div class="admin-block d-flex" style="align-items: center;">
        <div>
            <img src="./../../../assets/img/dash/md.png" class="img-circle" width="45px" />
        </div>
        <div class="admin-info">
            <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['AA']}}</div>
        </div>
    </div>
    <ul class="side-menu metismenu">
        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/md-index"><i class="sidebar-item-icon fa fa-th-large"></i>
                    <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar1']}}</span>
                </a>
        </li>

        <!-- <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/md-legal-entity"><i class="sidebar-item-icon fa fa-object-ungroup"></i>
                <span class="nav-label">Legal Entity</span>
            </a>
        </li> -->
        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/md-create-role"><i
                        class="sidebar-item-icon fa  fa-gavel"></i>
                    <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar2']}}</span>
                </a>
        </li>
        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/md-user"><i
                        class="sidebar-item-icon fa fa-user-circle-o"></i>
                    <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar3']}}</span>
                </a>
        </li>

        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/account-config"><i
                        class="sidebar-item-icon fa fa-header"></i>
                    <span class="nav-label">{{mainService.allLableShowObj[mainService.language_cd+'md_sidebar4']}} </span>
                </a>
        </li>
        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/vendor_tc"><i
                        class="sidebar-item-icon fa fa-user-circle-o"></i>
                    <span class="nav-label">Vendor T&C</span>
                </a>
        </li>

        <li>
            <a class = "text-decoration-none "  routerLinkActive="active" routerLink="/md/vendor_fee"><i
                        class="sidebar-item-icon fa fa-header"></i>
                    <span class="nav-label">Vendor Fee </span>
                </a>
        </li>
        <!-- <li routerLinkActive="active">
            <a class = "text-decoration-none "  href="javascript:;"><i class="sidebar-item-icon fa fa-question-circle"></i>
                <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">

                <li>
                    <a class = "text-decoration-none "  routerLink="/solution-admin/administration/login-info" routerLinkActive="active">FAQ</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/solution-admin/administration/login-info" routerLinkActive="active">User Manual</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/solution-admin/administration/login-info" routerLinkActive="active">Tutorial Videos</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/solution-admin/administration/login-info" routerLinkActive="active">Online Support</a>
                </li>
            </ul>
        </li> -->
        <!-- <li routerLinkActive="active">
            <a class = "text-decoration-none "  href="javascript:;"><i class="sidebar-item-icon fa fa-building-o"></i>
                <span class="nav-label"> Activity</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/booklet-purchase" routerLinkActive="active">Booklet Purchase</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/applications" routerLinkActive="active">Applicants</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/allotment" routerLinkActive="active">Allotment</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/applications-refund" routerLinkActive="active">Refund</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/payment-schedule" routerLinkActive="active">Payment Schedule</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/registry" routerLinkActive="active">Registry</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/activity/restore" routerLinkActive="active">Restore</a>
                </li>
            </ul>
        </li>
        <li routerLinkActive="active">
            <a class = "text-decoration-none "  href="javascript:;"><i class="sidebar-item-icon fa fa-cogs"></i>
                <span class="nav-label">Setting</span><i class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse" routerLinkActive="in">
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/prop-code-value" routerLinkActive="active">Code Value</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/prop-field" routerLinkActive="active">Field</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/scheme" routerLinkActive="active">Scheme</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/subscheme" routerLinkActive="active">Sub Scheme</a>
                </li>
                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/property-info" routerLinkActive="active">Property Type</a>
                </li>

                <li>
                    <a class = "text-decoration-none "  routerLink="/property/property-setting/property-def" routerLinkActive="active">Enter Property</a>
                </li>
                <li routerLinkActive="active">
                    <a class = "text-decoration-none "  href="javascript:;"><i class="sidebar-item-icon fa fa-gavel"></i>
                        <span class="nav-label"> Auction</span><i class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse" routerLinkActive="in">
                        <li>
                            <a class = "text-decoration-none "  routerLink="/property/activity/auction" routerLinkActive="active">Auction</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none "  routerLink="/property/activity/auction-application" routerLinkActive="active">Auction Applicants</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none "  routerLink="/property/activity/bid-details" routerLinkActive="active">Auction Bid Detsils</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li> -->
    </ul>
</div>
