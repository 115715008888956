import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service'
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from '../../../service/main.service'
declare var $: any
import Swal from 'sweetalert2';

@Component({
  selector: 'app-geometry',
  templateUrl: './geometry.component.html',
  styleUrls: ['./geometry.component.css']
})
export class GeometryComponent implements OnInit {

  constructor(private router: Router, private spinner: NgxSpinnerService, public mainService: MainService, private referenceDataS: ReferenceDataService, private snackBar: MatSnackBar) { }
  erpUser:any;
  b_acct_id:any;
  dedduction_dropdown:any = [{ id: 'length', value: 'length' }, { id: 'width', value: 'width' }, { id: "height", value: 'height' }, { id: 'radious', value: 'radious' }]
  allFields:any = [];
  allUnit:any = [{ unit: 'kg', value: 'kg' }, { unit: 'cum', value: 'cum' }, { unit: 'per sqm/Month', value: 'per sqm/Month' }]
  allDataTyeps = [];
  field:any = {};
  geo:any = {}
  
  l_geo_flag=true;
  c_geo_flag=false;
  u_geo_flag=false;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = ['id', 'geo', 'unit', 'meas', 'formula', 'action'];
  datasource:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFields();
  }
  refresh() {
    this.geo = {}
  }

  async gotolist(){
    this.l_geo_flag=true;
    this.c_geo_flag=false;
    this.u_geo_flag=false;
    await this.getAllFields();
    

  }
  gotocrete(){
    this.refresh();
    this.l_geo_flag=false;
    this.c_geo_flag=true;
    this.u_geo_flag=false;
  }

  async getAllFields() {
    this.spinner.show()
    let obj = {}
    var resp = await this.referenceDataS.getGeometry(JSON.stringify(this.b_acct_id));
    if (resp['error'] == false) {
      this.allFields = resp.data;
      this.datasource = new MatTableDataSource(this.allFields)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }
  get_role() {
    this.geo['measurement'] = this.geo['meas'].join()
  }

  async create() {
    let validate = 0
    if (this.geo['unit'] && this.geo['geometry'] && this.geo['meas'] && this.geo['formual']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.geo
      obj['b_acct_id'] = this.b_acct_id
      obj['create_user_id'] = this.erpUser.user_id
      var resp = await this.referenceDataS.createGeometry(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllFields()
        this.snackBar.open("Geometry Added Successfully!", 'Success', {
          duration: 5000
        });
       this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Adding Geometry", 'Error', {
          duration: 5000
        });
      }
    } else {
      Swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }

  }
  async update() {
    let validate = 0
    if (this.geo['unit'] && this.geo['geometry'] && this.geo['meas'] && this.geo['formual']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();
      let obj:any = new Object();
      obj = this.geo
      obj['b_acct_id'] = this.b_acct_id
      obj['update_user_id'] = this.erpUser.user_id
      var resp = await this.referenceDataS.upddateGeometry(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllFields()
        this.snackBar.open(" Geometry Updated Successfully!", 'Success', {
          duration: 5000
        });
     this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error While Updating  Geometry", 'Error', {
          duration: 5000
        });
      }
    } else {
      Swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }
  }
  open_update(element:any) {
    this.l_geo_flag=false;
    this.c_geo_flag=false;
    this.u_geo_flag=true;

    this.geo = Object.assign({}, element);
    this.geo['meas'] = element['measurement'].split(",")
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async  delete(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }

  async finaldelete(e:any) {
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['id'] = e['id']
    var resp = await this.referenceDataS.deleteGeometry(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllFields();
      this.snackBar.open("Geometry Deleted Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error While Deleting Geometry", 'Error', {
        duration: 5000
      });
    }
  }
}

