<div class="page-content fade-in-up">
  <div class="row">
    <div class="col-12">
      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">Property Possession</div>
        </div>

        <div class="ibox-body">

          <ul class="nav nav-tabs tabs-line">
            <!-- <li class="nav-item">
              <a class="nav-link active" data-toggle="tab"><i class="fa
                  fa-clock-o"> &nbsp; </i>Possession</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                Possession Request </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings mr-1"></i>Possession List</a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab-1">
              <br>
              <div *ngIf="show_flag">

                <div class="row mt-2">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6 class="pt-2">Select Scheme / Offer :*</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="allOffer" bindLabel="offer_name" bindValue="offer_cd" [multiple]="false"
                      [(ngModel)]="obj['offer_cd']" [closeOnSelect]="true" placeholder="Select Scheme / Offer"
                      (change)="getallPossessionList()">
                    </ng-select>
                  </div>
                </div>


                <!-- <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-3 text-right">
                          <h6>Search Option :*</h6>
                        </div>
                        <div class="col-3 text-left">
                          <ng-select [items]="cars" bindLabel="name" bindValue="id"
                            [(ngModel)]="obj['search_option']" placeholder="Select Search Option">
                          </ng-select>
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-3 text-right">
                          <h6>Enter Detail :*</h6>
                        </div>
                        <div class="col-3 text-left">
                          <input type="text" class="form-control" placeholder="Enter Detail" [(ngModel)]="obj['search_detail']" />
                        </div>
                      </div>
                      <br />
                      <div class="row">
                        <div class="col-5"></div>
                        <div class="col-3 text-center">
                          <button class="btn btn-outline-primary"
                            (click)="getPossessionList()">SEARCH</button>
                        </div>
                      </div> -->
                <br>
                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter($any($event.target).value)"
                        placeholder="search">
                    </mat-form-field>
                  </div>
                  <div class="col-8 text-right">
                    <button class="btn btn-outline-success">Add approvel</button>
                    &nbsp;&nbsp;
                    <button class="btn btn-outline-primary">Download</button>
                  </div>
                </div>

                <br>
                <!-- mat table list for possition -->
                <div class="example-container">

                  <table mat-table matSort [dataSource]="dataSource" style="width:
                    100%">
                    <!-- property id -->
                    <ng-container matColumnDef="property_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property ID</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.alloted_property_id}}
                      </td>
                    </ng-container>



                    <!--  Scheme -->
                    <ng-container matColumnDef="Scheme">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Offer Code</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{obj['offer_cd']}}
                      </td>
                    </ng-container>


                    <!--  Property Type -->
                    <ng-container matColumnDef="property_type">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property Type</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.property_type}}
                      </td>
                    </ng-container>

                    <!--  Property Type -->
                    <ng-container matColumnDef="property_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property
                          No.</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.assigned_property_number}}
                      </td>
                    </ng-container>


                    <!--  Allottee Name -->
                    <ng-container matColumnDef="allottee_name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Allottee Name</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_name}}
                      </td>
                    </ng-container>

                    <!--  Father_Name -->
                    <ng-container matColumnDef="Father_Name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Father Name</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_father_or_husband_name}}
                      </td>
                    </ng-container>


                    <!--  Mobile No -->
                    <ng-container matColumnDef="mobile_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Mobile Number</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_phone_no}}
                      </td>
                    </ng-container>


                    <!--  Action -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Action</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        <button class="btn btn-outline-success but-w mt-1 btn1" (click)="show_Details(element)">Get
                          Possession</button><br>
                        <button class="btn btn-outline-primary but-w mt-1 btn1"
                          >Download</button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

                </div>

              </div>
              <!-- for flag    -->
              <!-- mat table end  -->



              <!-- INFO TABLE GOES HERE  -->
              <div *ngIf="!show_flag">

                <div class="col-12 text-right">
                  <button class="btn btn-outline-primary but-w mt-1" (click)="Details_flag()">Go To List</button>
                </div>




                <div class="card mt-5">
                  <div style="margin: 2%;">
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                <h6>Offer Descreption: {{Details_obj['offer_desc']}}</h6><br>

                        <div class="row mt-5">

                        </div>
                        <div class="row">
                          <div class="col">
                            <h6>Allotment ID</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.allotement_id}}

                          </div>
                          <div class="col">
                            <h6>Property ID</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.alloted_property_id}}

                          </div>
                          <div class="col">
                            <h6>Allottee Name</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.party_name}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Category</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.property_type}}
                          </div>
                          <div class="col">
                            <h6>allotteement Date</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.allotment_date}}

                          </div>
                          <div class="col">
                            <h6>Property Type</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.property_type}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Payment Mode</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.payment_option}}
                          </div>
                          <div class="col">
                            <h6>Property Number</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.assigned_property_number}}
                          </div>
                          <div class="col">
                            <h6>Total Paid Amount</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.total_paid_amount}}

                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Cost(Rs.)</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.pro_rate}}

                          </div>
                          <div class="col">
                            <h6>Amount Paid %</h6>
                          </div>
                          <div class="col">
                            <!-- *null -->
                          </div>
                          <div class="col">

                          </div>
                          <div class="col">

                          </div>
                        </div>

                        <br />
                      </div>
                    </div>


                  </div>
                </div>

                <!-- INFO TANLE END -->
                <BR>

                <div class="card mt-3">
                  <div style="margin: 2%;">
                    <div>
                      <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-3 text-right">
                          <h6>Possession Letter Date:*</h6>
                        </div>
                        <div class="col-3 text-left">
                          <input type="date" class="form-control" [(ngModel)]="obj['possession_letter_date']">

                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-3 text-right">
                          <h6>Possession Date:*</h6>
                        </div>
                        <div class="col-3 text-left">
                          <input type="date" class="form-control" [(ngModel)]="obj['possession_date']">
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-3 text-right">
                          <h6>Signatory Authorities:*</h6>
                        </div>
                        <div class="col-3 text-left">
                          <ng-select [items]="allSearchableEmp" placeholder="Select User" bindLabel="desc"
                            bindValue="user_id" [selectableGroup]="true" [selectableGroupAsModel]="false"
                            [closeOnSelect]="true" name="hierarchy" [(ngModel)]="Authoritieobj['data']"
                            [multiple]="true">
                          </ng-select>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <!-- BTN START  -->
                <div class="row mt-5">
                  <div class="col-5"></div>
                  <div class="col-3 text-center">
                    <button class="btn btn-outline-primary mt-2" (click)="GeneratePossession()">Generate
                      Possession </button>


                  </div>
                </div>





              </div>



            </div>


            <div class="tab-pane fade show" id="tab-2">


              <div *ngIf="show_flag2">
                <div class="row mt-5">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Scheme / Offer :*</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="allOffer" bindLabel="offer_name" bindValue="offer_cd" [multiple]="false"
                      placeholder="Select Offer" [(ngModel)]="obj['offer_cd']" [selectableGroup]="true"
                      (click)="getpropertytype()" [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Category :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_category" bindLabel="property_category" bindValue="property_category"
                      placeholder="Select property Category" [(ngModel)]="obj['pro_category']">
                    </ng-select>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-3 text-right">
                    <h6>Property Type :</h6>
                  </div>
                  <div class="col-3 text-left">
                    <ng-select [items]="pro_type" bindLabel="pro_type" bindValue="pro_type" [multiple]="false"
                      placeholder="Select property type" [(ngModel)]="obj['pro_type']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true">
                    </ng-select>
                  </div>
                </div>

                <br />
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-3 text-center">
                    <button class="btn btn-outline-primary" (click)="getPossessionData()">
                      Search
                    </button>
                  </div>
                </div>
                <br>

                <div class="row">
                  <div class="col-4">
                    <mat-form-field style="margin-left: 2%">
                      <input matInput (keyup)="applyFilter2($any($event.target).value)"
                        placeholder="search">
                    </mat-form-field>
                  </div>
                  <div class="col-8 text-right">
                    <button class="btn btn-outline-success">Add approvel</button>
                    &nbsp;&nbsp;
                    <button class="btn btn-outline-primary">Download</button>
                  </div>
                </div>

                <br>

                <div class="example-container">
                  <table mat-table matSort [dataSource]="dataSource2" style="width:
                    100%">
                    <!-- property id -->
                    <ng-container matColumnDef="property_id">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property ID</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.alloted_property_id}}
                      </td>
                    </ng-container>



                    <!--  Scheme -->
                    <ng-container matColumnDef="Scheme">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Scheme</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        null*/
                      </td>
                    </ng-container>


                    <!--  Property Type -->
                    <ng-container matColumnDef="property_type">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property Type</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.property_type}}
                      </td>
                    </ng-container>

                    <!--  Property Type -->
                    <ng-container matColumnDef="property_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Property
                          No.</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.assigned_property_number}}
                      </td>
                    </ng-container>


                    <!--  Allottee Name -->
                    <ng-container matColumnDef="allottee_name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Allottee Name</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_name}}
                      </td>
                    </ng-container>

                    <!--  Father_Name -->
                    <ng-container matColumnDef="Father_Name">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Father Name</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_father_or_husband_name}}
                      </td>
                    </ng-container>


                    <!--  Mobile No -->
                    <ng-container matColumnDef="mobile_no">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Mobile Number</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        {{element.party_phone_no}}
                      </td>
                    </ng-container>


                    <!--  Action -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        <h6>Action</h6>
                      </th>
                      <td mat-cell flex-stretch *matCellDef="let element" class="right">
                        <button class="btn btn-outline-primary but-w mt-1 btn1" (click)="show_Details2(element)">View
                          Details</button>
                        <br>

                        <button class="btn btn-outline-danger but-w mt-1 btn1">Download
                          Poss.</button>
                        <br>

                        <button class="btn btn-outline-primary but-w mt-1 btn1" (click)="show_upload(element)">Upload
                          Signed Poss.</button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                  <mat-paginator [pageSizeOptions]="[4, 10]" showFirstLastButtons></mat-paginator>
                </div>

              </div>

              <!-- datails -->
              <div *ngIf="details_flag2">


                <div class="col-12 text-right">
                  <button class="btn btn-outline-primary but-w mt-1" (click)="close()">Go To List</button>
                </div>



                <div class="card mt-5">
                  <div style="margin: 2%;">
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                        <h6>Offer Descreption: {{Details_obj['offer_desc']}}</h6><br>

                        <div class="row mt-4">
                        </div>
                        <div class="row">
                          <div class="col">
                            <h6>Allotment ID</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.allotement_id}}

                          </div>
                          <div class="col">
                            <h6>Property ID</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.alloted_property_id}}

                          </div>
                          <div class="col">
                            <h6>Allottee Name</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.party_name}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Category</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.property_type}}
                          </div>
                          <div class="col">
                            <h6>allotteement Date</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.allotment_date}}

                          </div>
                          <div class="col">
                            <h6>Property Type</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.property_type}}
                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Payment Mode</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.payment_option}}
                          </div>
                          <div class="col">
                            <h6>Property Number</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.assigned_property_number}}
                          </div>
                          <div class="col">
                            <h6>Total Paid Amount</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.total_paid_amount}}

                          </div>
                        </div>
                        <br />

                        <div class="row">
                          <div class="col">
                            <h6>Property Cost(Rs.)</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.pro_rate}}

                          </div>
                          <div class="col">
                            <h6>Amount Paid %</h6>
                          </div>
                          <div class="col">

                          </div>
                          <div class="col">

                          </div>
                          <div class="col">

                          </div>
                        </div>

                        <br />
                      </div>
                    </div>


                  </div>
                </div>


                <div class="card mt-5">
                  <div style="margin: 2%;">
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                        <h6>Property Possession Details:</h6>
                        <div class="row mt-3">
                        </div>
                        <div class="row">
                          <div class="col">
                            <h6>Possession Letter Date</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.possession_letter_date | date}}

                          </div>
                          <div class="col">
                            <h6>Possession Date</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.possession_date | date}}

                          </div>
                          <div class="col">
                            <h6>Possession Status</h6>
                          </div>
                          <div class="col">
                            {{Details_obj.status}}
                          </div>
                        </div>
                        <br />
                      </div>

                    </div>
                  </div>

                </div>

                <!-- uplod -->




              </div>
              <div *ngIf="upload_flag">
                <br>
                <div class="col-12 text-right">
                  <button class="btn btn-outline-primary but-w mt-1" (click)="close()">Go to List</button>
                </div>


                <div class="card mt-5">
                  <div style="margin: 2%;">
                    <div class="container">
                      <div class="m-b-5" style="margin: 2%">
                        <div class="row">
                          <div class="col-6 text-right">
                            <h6>Select File :*</h6>

                          </div>
                          <div class="col-6 text-left">
                            <input type="file" id="myFile" name="filename" accept="application/pdf" />

                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col text-right">
                            <button class="btn btn-outline-primary but-w mt-1">CANCLE</button>
                          </div>
                          <div class="col text-left">
                            <button class="btn btn-outline-primary but-w mt-1">UPLOAD</button>
                          </div>

                        </div>

                      </div>
                    </div>


                  </div>
                </div>
              </div>



            </div>





            <hr class="hrline" />
            <br />
            <br />


          </div>

        </div>

      </div>

      <br />
    </div>
  </div>
</div>
