import { Pipe, PipeTransform } from '@angular/core';
import { AnyPtrRecord } from 'dns';

@Pipe({
  name: 'Split'
})
export class SplitPipe implements PipeTransform {

  transform(value:any) {
    let valuearr=value.split("_");
    let column= valuearr.join(" ")
     return column;
  }

}
