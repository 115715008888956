<div class="page-content fade-in-up">
  <div class="row">

    <div class="col-lg-12 col-md-12">

      <div class="ibox">
        <div class="ibox-head">
          <div class="ibox-title">
            Closing Stock
          </div>
        </div>

        <div class="ibox-body">
          <ul class="nav nav-tabs tabs-line">
            <li class="nav-item">
              <a class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                  class="ti-bar-chart"></i>
                All Closing Stock List
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i class="fa fa-plus-circle"></i>
                Create Closing Stock
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i>
                Update Closing Stock
              </a>
            </li>

          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab-1">

              <br>


              <mat-form-field style="margin-left: 2%">
                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
              </mat-form-field>

              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="datasource" matSort>
                  <ng-container matColumnDef="fin_year">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Fin Year
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element ">
                      {{ element.fin_year }}</td>
                  </ng-container>


                  <ng-container matColumnDef="final_stock_amt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Toatl Amount
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{ element.final_stock_amt }} </td>
                  </ng-container>


                  <!-- <ng-container matColumnDef="balancesheet_coa">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            BAL. COA
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element">
                                            {{ allChartOfAccountobj[element.balancesheet_coa] }} </td>
                                    </ng-container> -->

                  <ng-container matColumnDef="type_of_accounting">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      TYPE
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{ element.type_of_accounting }} </td>
                  </ng-container>
                  <ng-container matColumnDef="stock_cat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Stock Cat.
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{ allChartOfAccountobj[element.stock_cat] }} </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Status
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element">
                      {{ element.status }} </td>
                  </ng-container>





                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>
                      {{mainService.allLableShowObj[mainService.language_cd+'Contra10']}}
                      <!--Action-->
                    </th>
                    <td mat-cell flex-stretch *matCellDef="let element;let i = index">

                      <button class="btn btn-primary" *ngIf="element.status=='GENERATED'"
                        (click)="open_update(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra11']}}
                        <!--Update-->
                      </button>&nbsp;&nbsp;
                      <button *ngIf="element.status=='GENERATED'" class="btn btn-danger"
                        (click)="delete(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra12']}}
                        <!--Delete-->
                      </button>&nbsp;&nbsp;

                      <!-- <button class="btn btn-info"
                                                (click)="print1(element)">{{mainService.allLableShowObj[mainService.language_cd+'Contra13']}} -->
                      <!--Print-->
                      <!-- </button>&nbsp;&nbsp; -->
                      <button *ngIf="element.status=='GENERATED'" class="btn btn-success"
                        (click)="changeContraStatus(element)"> APPROVE
                      </button>&nbsp;&nbsp;
                      <!-- <button *ngIf="element.status=='APPROVED'" class="btn btn-success"
                                                (click)="process(element)"> Process
                                            </button>&nbsp;&nbsp; -->

                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                  </tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
              </div>


            </div>
            <div class="tab-pane fade" id="tab-2">
              <form name="form" (ngSubmit)=" f.form.valid && submit()" #f="ngForm">
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    <h6> Select Financial Year :</h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="allFinYear" bindLabel="desc" bindValue="fin_year" [multiple]="false"
                      placeholder="Select Fiscal Calendar" [(ngModel)]="obj['fin_year']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year" #fin_year="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && fin_year.invalid }" required>
                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && fin_year.invalid" class="error-message">
                      <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->





                  </div>
                  <!-- <div class="col-3 text-right">
                                    Select Balance Sheet COA:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allChartOfAccount" bindLabel="leaf_value" bindValue="leaf_code"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['balancesheet_coa']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="balancesheet_coa">
                                    </ng-select>
                                </div> -->

                  <div class="col-3 text-right">
                    Select Type:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="type" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['type_of_accounting']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="type_of_accounting" #type_of_accounting="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && type_of_accounting.invalid }" required>
                    </ng-select>




                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="type_of_accounting.dirty && type_of_accounting.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && type_of_accounting.invalid" class="error-message">
                      <div *ngIf="type_of_accounting.errors?.['required']">Please Select Type
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->
                  </div>

                </div>
                <br>
                <div class="row">
                  <!-- <div class="col-3 text-right">
                                    Select Type:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="obj['type_of_accounting']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="type_of_accounting">
                                    </ng-select>
                                </div> -->
                  <div class="col-3 text-right">
                    Select As On Date:
                  </div>
                  <div class="col-3">
                    <input type="date" class="form-control" [(ngModel)]="obj['as_on_date']" name="as_on_date"
                      #as_on_date="ngModel" [ngClass]="{ 'is-invalid': f.submitted && as_on_date.invalid }" required>


                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.border_green]="as_on_date.dirty && as_on_date.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && as_on_date.invalid" class="error-message">
                      <div *ngIf="as_on_date.errors?.['required']">Please Select As On Date
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>

                  <div class="col-3 text-right">
                    Select Stock Category:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="stock_cat" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['stock_cat']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="stock_cat_1" #stock_cat_1="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && stock_cat_1.invalid }" required>
                    </ng-select>


                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="stock_cat_1.dirty && stock_cat_1.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && stock_cat_1.invalid" class="error-message">
                      <div *ngIf="stock_cat_1.errors?.['required']">Please Select Stock Category </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    Select Valution Base:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="valution_base" bindLabel="value" bindValue="code" [multiple]="false"
                      placeholder="" [(ngModel)]="obj['valution_base']" (change)="changeValutionBase()"
                      [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                      name="valution_base1" #v_base="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && v_base.invalid }" required>

                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="v_base.dirty && v_base.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && v_base.invalid" class="error-message">
                      <div *ngIf="v_base.errors?.['required']">Please Select Valution Base
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->
                  </div>
                  <!-- <div class="col-3 text-right">
                                    Select As On Date:
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="obj['as_on_date']" />
                                </div> -->
                </div>
                <br>
                <hr>
                <br>
                <div class="row">

                  <div class="col-8 text-right">
                    <button class="btn btn-primary" type="button" (click)="addRow()"><i class="fa fa-plus"
                        aria-hidden="true"></i></button>

                  </div>
                </div>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th>
                        Sr. No.
                      </th>
                      <th style="width: 25%;">
                        Closing stock Description
                      </th>
                      <th style="width: 10%;">
                        Qty
                      </th>
                      <th style="width: 10%;">
                        Rate
                      </th>
                      <th style="width: 10%;">
                        Cost Amount
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of obj['data']; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="obj['data'][i]['item_desc']"
                          name="item_desc{{i}}"></textarea>

                      </td>
                      <td>

                        <input class="form-control" type="number" *ngIf="!qty_flag"
                          [(ngModel)]="obj['data'][i]['act_qty']" name="item_qty" #item_qty="ngModel"
                          [ngClass]="{ 'is-invalid': f.submitted && item_qty.invalid }" required>


                        <!-- {{"Valuation base value is : "+ v_base.value + " And acct qty value is :" + item_qty.value }} -->

                        <!-- validation start  -->
                        <!-- <div [class.selection-box]="v_base.dirty && v_base.valid">
                                                </div> -->

                        <!-- error handling  -->
                        <!-- <div *ngIf="f.submitted && item_qty.invalid && v_base.value == 'Quantity' " class="error-message">
                                                    <div *ngIf="item_qty.errors?.['required']">*Required
                                                    </div>
                                                </div> -->

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['act_qty']" (change)="Change_act_qty(i)" name="act_qty{{i}}">

                      </td>

                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['act_rate']" (change)="ChangeAmt(i)" name="act_rate{{i}}"
                          #acct_rate="ngModel" [ngClass]="{ 'is-invalid': f.submitted && acct_rate.invalid }" required>


                        <!-- <div *ngIf="v_base.value == 'Quantity' "> -->

                        <!--------------------------------------------- validation Start ------------------- -->

                        <!-- border color green -->
                        <!-- <div [class.border_green]="acct_rate.dirty && acct_rate.valid">
                                                    </div> -->

                        <!-- error handling  -->
                        <!-- <div *ngIf="f.submitted && acct_rate.invalid" class="error-message">
                                                        <div *ngIf="acct_rate.errors?.['required']">*Required
                                                        </div>
                                                    </div> -->

                        <!--------------------------------------------- validation End ------------------- -->

                        <!-- </div> -->

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['act_rate']" (change)="Change_act_qty(i)" name="act_rate{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag"
                          [(ngModel)]="obj['data'][i]['cost_amt']" (change)="Change_cost_amt(i)" name="cost_amt{{i}}">
                        <input class="form-control" type="number" *ngIf="qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['cost_amt']" name="cost_amt1{{i}}">

                      </td>
                      <td>
                        <button class="btn btn-danger" type="button" (click)="deleteRow(i)"><i class="fa fa-trash"
                            aria-hidden="true"></i></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th>
                        Sr. No.
                      </th>
                      <th style="width: 25%;">
                        Closing stock Description
                      </th>
                      <th style="width: 10%;">
                        Qty
                      </th>
                      <th style="width: 10%;">
                        Realizable Rate
                      </th>
                      <th style="width: 10%;">
                        Amount
                      </th>

                      <th style="width: 10%;">
                        Cost upto Sal
                      </th>
                      <th style="width: 10%;">
                        Realizable Value
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of obj['data']; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="obj['data'][i]['item_desc']" name="item_desc{{i}}"
                          disabled></textarea>

                      </td>
                      <td>
                        <input class="form-control" type="number" [(ngModel)]="obj['data'][i]['real_qty']" disabled
                          (change)="ChangeAmt(i)" name="real_qty{{i}}">

                      </td>

                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['real_rate']" name="real_rate{{i}}">

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['real_rate']" (change)="Change_real_rate(i)"
                          name="real_rate{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" (change)="Change_real_amt(i)"
                          [(ngModel)]="obj['data'][i]['real_amt']" name="real_amt{{i}}">

                        <input class="form-control" type="number" *ngIf="qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['real_amt']" name="real_amt{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" (change)="Change_cost_upto_sale(i)"
                          [(ngModel)]="obj['data'][i]['cost_upto_sale']" name="cost_upto_sale{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" disabled [(ngModel)]="obj['data'][i]['real_value']"
                          name="real_value{{i}}">

                      </td>

                    </tr>
                  </tbody>
                </table>

                <br>
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" type="button" (click)="Calculate()">
                      Calculate
                    </button>
                  </div>
                </div>
                <hr>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th style="width: 20%;">
                        <h6> Sr. No. </h6>
                      </th>
                      <th style="width: 75%;">
                        <h6>Closing stock Description: </h6>
                      </th>

                      <th style="width: 25%;">
                        <h6> Amount: </h6>
                      </th>



                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of stock_item; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="ob['item_desc']" name="item_desc{{i}}"
                          disabled></textarea>

                      </td>
                      <td>
                        <input class="form-control" type="number" [(ngModel)]="ob['stock_amt']" name="stock_amt{{i}}"
                          disabled>

                      </td>



                    </tr>
                  </tbody>
                </table>
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    <h6> Less Depletion: </h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="depletion" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['depletion_type']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="depletion_type" #depletion_type="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && depletion_type.invalid }" required>
                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="depletion_type.dirty && depletion_type.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f.submitted && depletion_type.invalid" class="error-message">
                      <div *ngIf="depletion_type.errors?.['required']">
                        <h6> Please Select Financial Year </h6>
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>
                  <div class="col-3 text-right">
                    <h6> Amount/Percentage: </h6>
                  </div>
                  <div class="col-3">
                    <input type="number" class="form-control" [(ngModel)]="obj['depletion_amt']" name="depletion_amt">
                  </div>
                </div>
                <br>

                <div class="row">
                  <div class="col-6 text-right">
                    <h6>Final Stock Amount: </h6>
                  </div>

                  <div class="col-3">
                    <input type="number" class="form-control" disabled [(ngModel)]="obj['final_stock_amt']"
                      name="final_stock_amt">
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-12 text-center">

                    <button type="button" class="btn btn-success" (click)="cheak()">Cheak</button>&nbsp;
                    <button class="btn btn-primary"
                      type="submit">{{mainService.allLableShowObj[mainService.language_cd+'Contra23']}}
                      <!--Submit-->
                    </button>

                    <button class="btn btn-outline-success d-none" type="reset" id="reset">Reset</button>
                  </div>
                </div>
              </form>
            </div>

            <div class="tab-pane fade" id="tab-3">
              <form name="form" (ngSubmit)=" f2.form.valid && update()" #f2="ngForm">
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    <h6> Select Financial Year :</h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="allFinYear" bindLabel="desc" bindValue="fin_year" [multiple]="false"
                      placeholder="Select Fiscal Calendar" [(ngModel)]="obj['fin_year']" [selectableGroup]="true"
                      [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year" #fin_year="ngModel"
                      [ngClass]="{ 'is-invalid': f2.submitted && fin_year.invalid }" required>
                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && fin_year.invalid" class="error-message">
                      <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->





                  </div>
                  <!-- <div class="col-3 text-right">
                                    Select Balance Sheet COA:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="allChartOfAccount" bindLabel="leaf_value" bindValue="leaf_code"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['balancesheet_coa']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="balancesheet_coa">
                                    </ng-select>
                                </div> -->

                  <div class="col-3 text-right">
                    Select Type:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="type" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['type_of_accounting']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="type_of_accounting" #type_of_accounting="ngModel"
                      [ngClass]="{ 'is-invalid': f2.submitted && type_of_accounting.invalid }" required>
                    </ng-select>




                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="type_of_accounting.dirty && type_of_accounting.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && type_of_accounting.invalid" class="error-message">
                      <div *ngIf="type_of_accounting.errors?.['required']">Please Select Type
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->
                  </div>

                </div>
                <br>
                <div class="row">
                  <!-- <div class="col-3 text-right">
                                    Select Type:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="obj['type_of_accounting']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="type_of_accounting">
                                    </ng-select>
                                </div> -->
                  <div class="col-3 text-right">
                    Select As On Date:
                  </div>
                  <div class="col-3">
                    <input type="date" class="form-control" [(ngModel)]="obj['as_on_date']" name="as_on_date"
                      #as_on_date="ngModel" [ngClass]="{ 'is-invalid': f2.submitted && as_on_date.invalid }" required>


                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.border_green]="as_on_date.dirty && as_on_date.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && as_on_date.invalid" class="error-message">
                      <div *ngIf="as_on_date.errors?.['required']">Please Select As On Date
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>

                  <div class="col-3 text-right">
                    Select Stock Category:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="stock_cat" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['stock_cat']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="stock_cat_1" #stock_cat_1="ngModel"
                      [ngClass]="{ 'is-invalid': f2.submitted && stock_cat_1.invalid }" required>
                    </ng-select>


                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="stock_cat_1.dirty && stock_cat_1.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && stock_cat_1.invalid" class="error-message">
                      <div *ngIf="stock_cat_1.errors?.['required']">Please Select Stock Category </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    Select Valution Base:
                  </div>
                  <div class="col-3">
                    <ng-select [items]="valution_base" bindLabel="value" bindValue="code" [multiple]="false"
                      placeholder="" [(ngModel)]="obj['valution_base']" (change)="changeValutionBase()"
                      [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                      name="valution_base1" #v_base="ngModel"
                      [ngClass]="{ 'is-invalid': f2.submitted && v_base.invalid }" required>

                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="v_base.dirty && v_base.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && v_base.invalid" class="error-message">
                      <div *ngIf="v_base.errors?.['required']">Please Select Valution Base
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->
                  </div>
                  <!-- <div class="col-3 text-right">
                                    Select As On Date:
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="obj['as_on_date']" />
                                </div> -->
                </div>
                <br>
                <hr>
                <br>
                <div class="row">

                  <div class="col-8 text-right">
                    <button class="btn btn-primary" type="button" (click)="addRow()"><i class="fa fa-plus"
                        aria-hidden="true"></i></button>

                  </div>
                </div>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th>
                        Sr. No.
                      </th>
                      <th style="width: 25%;">
                        Closing stock Description
                      </th>
                      <th style="width: 10%;">
                        Qty
                      </th>
                      <th style="width: 10%;">
                        Rate
                      </th>
                      <th style="width: 10%;">
                        Cost Amount
                      </th>
                      <th>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of obj['data']; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="obj['data'][i]['item_desc']"
                          name="item_desc{{i}}"></textarea>

                      </td>
                      <td>

                        <input class="form-control" type="number" *ngIf="!qty_flag"
                          [(ngModel)]="obj['data'][i]['act_qty']" name="item_qty" #item_qty="ngModel"
                          [ngClass]="{ 'is-invalid': f2.submitted && item_qty.invalid }" required>


                        <!-- {{"Valuation base value is : "+ v_base.value + " And acct qty value is :" + item_qty.value }} -->

                        <!-- validation start  -->
                        <!-- <div [class.selection-box]="v_base.dirty && v_base.valid">
                                                </div> -->

                        <!-- error handling  -->
                        <!-- <div *ngIf="f.submitted && item_qty.invalid && v_base.value == 'Quantity' " class="error-message">
                                                    <div *ngIf="item_qty.errors?.['required']">*Required
                                                    </div>
                                                </div> -->

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['act_qty']" (change)="Change_act_qty(i)" name="act_qty{{i}}">

                      </td>

                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['act_rate']" (change)="ChangeAmt(i)" name="act_rate{{i}}"
                          #acct_rate="ngModel" [ngClass]="{ 'is-invalid': f2.submitted && acct_rate.invalid }" required>


                        <!-- <div *ngIf="v_base.value == 'Quantity' "> -->

                        <!--------------------------------------------- validation Start ------------------- -->

                        <!-- border color green -->
                        <!-- <div [class.border_green]="acct_rate.dirty && acct_rate.valid">
                                                    </div> -->

                        <!-- error handling  -->
                        <!-- <div *ngIf="f.submitted && acct_rate.invalid" class="error-message">
                                                        <div *ngIf="acct_rate.errors?.['required']">*Required
                                                        </div>
                                                    </div> -->

                        <!--------------------------------------------- validation End ------------------- -->

                        <!-- </div> -->

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['act_rate']" (change)="Change_act_qty(i)" name="act_rate{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag"
                          [(ngModel)]="obj['data'][i]['cost_amt']" (change)="Change_cost_amt(i)" name="cost_amt{{i}}">
                        <input class="form-control" type="number" *ngIf="qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['cost_amt']" name="cost_amt1{{i}}">

                      </td>
                      <td>
                        <button class="btn btn-danger" type="button" (click)="deleteRow(i)"><i class="fa fa-trash"
                            aria-hidden="true"></i></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th>
                        Sr. No.
                      </th>
                      <th style="width: 25%;">
                        Closing stock Description
                      </th>
                      <th style="width: 10%;">
                        Qty
                      </th>
                      <th style="width: 10%;">
                        Realizable Rate
                      </th>
                      <th style="width: 10%;">
                        Amount
                      </th>

                      <th style="width: 10%;">
                        Cost upto Sal
                      </th>
                      <th style="width: 10%;">
                        Realizable Value
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of obj['data']; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="obj['data'][i]['item_desc']" name="item_desc{{i}}"
                          disabled></textarea>

                      </td>
                      <td>
                        <input class="form-control" type="number" [(ngModel)]="obj['data'][i]['real_qty']" disabled
                          (change)="ChangeAmt(i)" name="real_qty{{i}}">

                      </td>

                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['real_rate']" name="real_rate{{i}}">

                        <input class="form-control" type="number" *ngIf="qty_flag"
                          [(ngModel)]="obj['data'][i]['real_rate']" (change)="Change_real_rate(i)"
                          name="real_rate{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!qty_flag" (change)="Change_real_amt(i)"
                          [(ngModel)]="obj['data'][i]['real_amt']" name="real_amt{{i}}">

                        <input class="form-control" type="number" *ngIf="qty_flag" disabled
                          [(ngModel)]="obj['data'][i]['real_amt']" name="real_amt{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" (change)="Change_cost_upto_sale(i)"
                          [(ngModel)]="obj['data'][i]['cost_upto_sale']" name="cost_upto_sale{{i}}">

                      </td>
                      <td>
                        <input class="form-control" type="number" disabled [(ngModel)]="obj['data'][i]['real_value']"
                          name="real_value{{i}}">

                      </td>

                    </tr>
                  </tbody>
                </table>

                <br>
                <div class="row">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" type="button" (click)="Calculate()">
                      Calculate
                    </button>
                  </div>
                </div>
                <hr>
                <br>
                <table class="mat-elevation-z8">
                  <thead>
                    <tr>
                      <th style="width: 20%;">
                        <h6> Sr. No. </h6>
                      </th>
                      <th style="width: 75%;">
                        <h6>Closing stock Description: </h6>
                      </th>

                      <th style="width: 25%;">
                        <h6> Amount: </h6>
                      </th>



                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ob of stock_item; let i = index">
                      <td>{{i+1}}</td>



                      <td>
                        <textarea class="form-control" [(ngModel)]="ob['item_desc']" name="item_desc{{i}}"
                          disabled></textarea>

                      </td>
                      <td>
                        <input class="form-control" type="number" [(ngModel)]="ob['stock_amt']" name="stock_amt{{i}}"
                          disabled>

                      </td>



                    </tr>
                  </tbody>
                </table>
                <br>
                <div class="row">
                  <div class="col-3 text-right">
                    <h6> Less Depletion: </h6>
                  </div>
                  <div class="col-3">
                    <ng-select [items]="depletion" bindLabel="value" bindValue="code" [multiple]="false" placeholder=""
                      [(ngModel)]="obj['depletion_type']" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="true" name="depletion_type" #depletion_type="ngModel"
                      [ngClass]="{ 'is-invalid': f2.submitted && depletion_type.invalid }" required>
                    </ng-select>

                    <!--------------------------------------------- validation Start ------------------- -->

                    <!-- border color green -->
                    <div [class.selection-box]="depletion_type.dirty && depletion_type.valid">
                    </div>

                    <!-- error handling  -->
                    <div *ngIf="f2.submitted && depletion_type.invalid" class="error-message">
                      <div *ngIf="depletion_type.errors?.['required']">
                        <h6> Please Select Financial Year </h6>
                      </div>
                    </div>

                    <!--------------------------------------------- validation End ------------------- -->

                  </div>
                  <div class="col-3 text-right">
                    <h6> Amount/Percentage: </h6>
                  </div>
                  <div class="col-3">
                    <input type="number" class="form-control" [(ngModel)]="obj['depletion_amt']" name="depletion_amt">
                  </div>
                </div>
                <br>

                <div class="row">
                  <div class="col-6 text-right">
                    <h6>Final Stock Amount: </h6>
                  </div>

                  <div class="col-3">
                    <input type="number" class="form-control" disabled [(ngModel)]="obj['final_stock_amt']"
                      name="final_stock_amt">
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-12 text-center">

                    <button type="button" class="btn btn-success" (click)="cheak()">Cheak</button>&nbsp;
                    <button class="btn btn-primary" type="submit">Update
                    </button>

                    <button class="btn btn-outline-success d-none" type="reset" id="reset2">Reset</button>
                  </div>
                </div>
              </form>
            </div>


          </div>

        </div>
      </div>
    </div>

  </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>






<!--  ----------------------------- BEFORE VALIDATION -------------------------------------------------------


<div class="tab-pane fade" id="tab-3">
                            <form name="form" (ngSubmit)=" f2.form.valid && update()" #f2="ngForm">
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    <h6> Financial Year :</h6>
                                </div>
                                <div class="col-3">
                                    {{obj['fin_year_desc']}}
                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-3 text-right">
                                    Select Type:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="type" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="obj['type_of_accounting']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="type_of_accounting">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    Select Stock Category:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="stock_cat" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="obj['stock_cat']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="stock_cat1">
                                    </ng-select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    Select Valution Base:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="valution_base" bindLabel="value" bindValue="code"
                                        [multiple]="false" placeholder="" [(ngModel)]="obj['valution_base']"
                                        [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="valution_base">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    Select As On Date:
                                </div>
                                <div class="col-3">
                                    <input type="date" class="form-control" [(ngModel)]="obj['as_on_date']" disabled>
                                </div>
                            </div>
                            <br>

                            <hr>
                            <br>
                            <div class="row">

                                <div class="col-8 text-right">
                                    <button type="button" class="btn btn-primary" (click)="addRow()"><i class="fa fa-plus"
                                            aria-hidden="true"></i></button>

                                </div>
                            </div>
                            <br>
                            <table class="mat-elevation-z8">
                                <thead>
                                    <tr>
                                        <th>
                                            Sr. No.
                                        </th>
                                        <th style="width: 25%;">
                                            Closing stock Description
                                        </th>
                                        <th style="width: 10%;">
                                            Qty
                                        </th>
                                        <th style="width: 10%;">
                                            Rate
                                        </th>
                                        <th style="width: 10%;">
                                            Cost Amount
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ob of obj['data']; let i = index">
                                        <td>{{i+1}}</td>



                                        <td>
                                            <textarea class="form-control"
                                                [(ngModel)]="obj['data'][i]['item_desc']"></textarea>

                                        </td>
                                        <td>

                                            <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                                                [(ngModel)]="obj['data'][i]['act_qty']">

                                            <input class="form-control" type="number" *ngIf="qty_flag"
                                                [(ngModel)]="obj['data'][i]['act_qty']" (change)="Change_act_qty(i)">

                                        </td>

                                        <td>
                                            <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                                                [(ngModel)]="obj['data'][i]['act_rate']" (change)="ChangeAmt(i)">
                                            <input class="form-control" type="number" *ngIf="qty_flag"
                                                [(ngModel)]="obj['data'][i]['act_rate']" (change)="Change_act_qty(i)">

                                        </td>
                                        <td>
                                            <input class="form-control" type="number" *ngIf="!qty_flag"
                                                [(ngModel)]="obj['data'][i]['cost_amt']" (change)="Change_cost_amt(i)">
                                            <input class="form-control" type="number" *ngIf="qty_flag" disabled
                                                [(ngModel)]="obj['data'][i]['cost_amt']">

                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger" (click)="deleteRow(i)"><i class="fa fa-trash"
                                                    aria-hidden="true"></i></button>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr>
                            <br>
                            <table class="mat-elevation-z8">
                                <thead>
                                    <tr>
                                        <th>
                                            Sr. No.
                                        </th>
                                        <th style="width: 25%;">
                                            Closing stock Description
                                        </th>
                                        <th style="width: 10%;">
                                            Qty
                                        </th>
                                        <th style="width: 10%;">
                                            Realizable Rate
                                        </th>
                                        <th style="width: 10%;">
                                            Amount
                                        </th>

                                        <th style="width: 10%;">
                                            Cost upto Sal
                                        </th>
                                        <th style="width: 10%;">
                                            Realizable Value
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ob of obj['data']; let i = index">
                                        <td>{{i+1}}</td>



                                        <td>
                                            <textarea class="form-control" [(ngModel)]="obj['data'][i]['item_desc']"
                                                disabled></textarea>

                                        </td>
                                        <td>
                                            <input class="form-control" type="number"
                                                [(ngModel)]="obj['data'][i]['real_qty']" disabled
                                                (change)="ChangeAmt(i)">

                                        </td>

                                        <td>
                                            <input class="form-control" type="number" *ngIf="!qty_flag" disabled
                                                [(ngModel)]="obj['data'][i]['real_rate']">

                                            <input class="form-control" type="number" *ngIf="qty_flag"
                                                [(ngModel)]="obj['data'][i]['real_rate']"
                                                (change)="Change_real_rate(i)">

                                        </td>
                                        <td>
                                            <input class="form-control" type="number" *ngIf="!qty_flag"
                                                (change)="Change_real_amt(i)" [(ngModel)]="obj['data'][i]['real_amt']">

                                            <input class="form-control" type="number" *ngIf="qty_flag" disabled
                                                [(ngModel)]="obj['data'][i]['real_amt']">

                                        </td>
                                        <td>
                                            <input class="form-control" type="number"
                                                (change)="Change_cost_upto_sale(i)"
                                                [(ngModel)]="obj['data'][i]['cost_upto_sale']">

                                        </td>
                                        <td>
                                            <input class="form-control" type="number" disabled
                                                [(ngModel)]="obj['data'][i]['real_value']">

                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn btn-primary" (click)="Calculate()">
                                        Calculate
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <br>
                            <table class="mat-elevation-z8">
                                <thead>
                                    <tr>
                                        <th style="width: 20%;">
                                            Sr. No.
                                        </th>
                                        <th style="width: 75%;">
                                            Closing stock Description
                                        </th>

                                        <th style="width: 25%;">
                                            Amount
                                        </th>



                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ob of stock_item; let i = index">
                                        <td>{{i+1}}</td>



                                        <td>
                                            <textarea class="form-control" [(ngModel)]="ob['item_desc']"
                                                disabled></textarea>

                                        </td>
                                        <td>
                                            <input class="form-control" type="number" [(ngModel)]="ob['stock_amt']"
                                                disabled>

                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                            <br>
                            <div class="row">
                                <div class="col-3 text-right">
                                    Less Depletion:
                                </div>
                                <div class="col-3">
                                    <ng-select [items]="depletion" bindLabel="value" bindValue="code" [multiple]="false"
                                        placeholder="" [(ngModel)]="obj['depletion_type']" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="depletion_type">
                                    </ng-select>
                                </div>
                                <div class="col-3 text-right">
                                    Amount/Percentage:
                                </div>
                                <div class="col-3">
                                    <input type="number" class="form-control" [(ngModel)]="obj['depletion_amt']" />
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-6 text-right">
                                    Final Stock Amount:
                                </div>

                                <div class="col-3">
                                    <input type="number" class="form-control" disabled
                                        [(ngModel)]="obj['final_stock_amt']" />
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button type="button" class="btn btn-success" (click)="cheak()">Cheak</button>&nbsp;
                                    <button class="btn btn-primary" type="submit" (click)="update()">Update
                                    </button>
                                </div>
                            </div>

                        </div>

 -->
