import { Component, OnInit, ViewChild } from '@angular/core';
 import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { BudgetService } from '../../service/budget.service';
import { ProjectBankAccService } from '../../../accounts/service/project-bank-acc.service'
import { MainService } from '../../service/main.service';
import swal from 'sweetalert2';

declare var $: any

@Component({
  selector: 'app-project-bank-acc',
  templateUrl: './project-bank-acc.component.html',
  styleUrls: ['./project-bank-acc.component.css']
})
export class ProjectBankAccComponent implements OnInit {

  constructor(private budgetService: BudgetService, private project_bank_acc: ProjectBankAccService, public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  hierCodeValue:any;
  table_data:any;
  datasource:any;
  allProjectHier:any = [];
  b_acct_id:any;
  allBankAccounts:any;
  erpUser:any;
  projectObject:any = {};
  bankDetailsObject:any = {};
  displayedColumns :any= ['SR No', 'Project_Code', 'Project_Description', 'Bank_Account_No', 'IFSC_Code', 'Branch_Name',
    'Bank_Name', 'Bank_Description', 'action'];
  bankObj :any= {}

  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sort1', { static: false }) sort1!: MatSort;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getBankAccount();
    await this.getProjectHier();
    await this.getProjectBAnk();
  }
  refresh() {

  }
  async getProjectHier() {
    this.spinner.show();

    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['table_name'] = 'proj_hier';
    var resp = await this.budgetService.getHierarchy(obj);
    if (resp['error'] == false) {
      this.allProjectHier = resp.data;
      this.projectObject = new Object;
      for (let i = 0; i < this.allProjectHier.length; i++) {
        this.projectObject[this.allProjectHier[i]['leaf_cd']] = this.allProjectHier[i]['leaf_value']
      }
      this.spinner.hide();

    } else {
      this.spinner.hide();

      swal.fire("Error", "...Error while getting hierchy list", 'error');
    }
  }

  async getProjectBAnk() {
    this.spinner.show();
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.project_bank_acc.getProjectBank(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();

      this.table_data = resp['data']
      this.datasource = new MatTableDataSource(this.table_data);
      this.datasource.sort = this.sort1;
      this.datasource.paginator = this.paginator1;
    }
    else {
      this.spinner.hide();

      swal.fire('error occured')
    }

  }
  async getBankAccount() {
    this.spinner.show()
    var resp = await this.settingService.getBankAccounts(this.b_acct_id);
    if (resp['error'] == false) {
      this.allBankAccounts = resp.data;
      this.bankDetailsObject = new Object();
      for (let i = 0; i < this.allBankAccounts.length; i++) {
        this.bankDetailsObject[this.allBankAccounts[i]['id']] = this.allBankAccounts[i]
        this.allBankAccounts[i]['bank_acct_desc'] = resp['data'][i]['bank_acct_desc'] + ' - ' + resp['data'][i]['branch_code'] + ' - ' + resp['data'][i]['bank_acct_no'] + ' - ' + resp['data'][i]['ifsc_code']
      }
      this.spinner.hide();
    } else {
      this.spinner.hide();
      swal.fire("Error", "...Error while getting list!", 'error');
    }
  }

  async submit() {
    this.spinner.show()
    let obj:any = this.bankObj
    obj['b_acct_id'] = this.b_acct_id
    obj['create_user_id'] = this.erpUser.user_id
    var resp = await this.project_bank_acc.create(obj)
    if (resp['error'] == false) {
      this.spinner.hide();
      this.getProjectBAnk()
      $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      document.getElementById('reset')!.click();
      swal.fire('Successfully created', '', 'success')
    }
    else {
      this.spinner.hide();

      swal.fire('error occured', '', 'error')
    }

  }
  async delete(e:any) {
    this.spinner.show();
    let obj :any= {}
    obj['id'] = e['id']
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.project_bank_acc.delete(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      this.getProjectBAnk()
      swal.fire('Deleted Successfully', '', 'success')
    }
    else {
      this.spinner.hide();

      swal.fire('some error occured', '', 'error')
    }

  }

  open_update(element:any) {
    this.bankObj = Object.assign({}, element);
    this.bankObj['project_code'] = Number(this.bankObj['project_code'])
    this.bankObj['bank_acct_no'] = Number(this.bankObj['bank_acct_no'])
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');
  }
  async update() {
    let obj = this.bankObj
    obj['update_user_id'] = this.erpUser.user_id
    obj['b_acct_id'] = this.b_acct_id
    var resp = await this.project_bank_acc.update(obj);
    if (resp['error'] == false) {
      $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      document.getElementById('reset2')!.click();
      swal.fire('Success', 'Successfully Updated', 'success')
    }
    else {
      swal.fire('some error occured', '', 'error')
    }

  }
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}

