<div class="sidebar">
  <div id="sidebar-collapse">

    <div class="admin-block d-flex" style="align-items: center;">
      <div>
        <img src="./../../../assets/img/dash/property.png" class="img-circle" width="45px" />
      </div>
      <div class="admin-info">
        <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['PROPERTY']}}</div>
      </div>
    </div>
    <ul class="side-menu metismenu">
      <li>
        <a class="text-decoration-none"  (click)="refreshCol()" [hidden]="sidebar['dashboard']"
          routerLink="/property/property-index"><i class="sidebar-item-icon fa fa-th-large"></i>
          <span class="nav-label"> {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar1']}} </span>
        </a>
      </li>
      <li >
        <a class="text-decoration-none" href="#inven" data-bs-toggle="collapse" [hidden]="sidebar['inventory']"><i
            class="sidebar-item-icon fa fa-archive"></i>
          <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar35']}}</span><i
            class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="inven" data-bs-parent="#sidebar-collapse">
          <!-- <li>
                    <a class = "text-decoration-none" routerLink="/property/inventory/land-input"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar36']}}</a>
                </li> -->
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/plan-layout"
              [hidden]="sidebar['plan_layout']"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar37']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/property" [hidden]="sidebar['property']"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar38']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/property-costing"
              [hidden]="sidebar['property_costing']" >Property Costing</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/property-images"
              [hidden]="sidebar['property_images']" >Property Images & Video</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/lease" >Lease
              Layout</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/inventory/lease-property"
              >Lease Property</a>
          </li>
        </ul>
      </li>

      <li >
        <a class="text-decoration-none" href="#offer" data-bs-toggle="collapse" [hidden]="sidebar['offer']"><i
            class="sidebar-item-icon fa fa-shopping-cart"></i>
          <span class="nav-label">Offer</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="offer" data-bs-parent="#sidebar-collapse">
          <div id="offerChild">

            <li>

              <a class="text-decoration-none" href="#sale" data-bs-toggle="collapse" [hidden]="sidebar['sale']"
                aria-expanded="false" aria-controls="sale"><i class="sidebar-item-icon fa fa-scribd"></i>
                <span class="nav-label">Sale</span><i class="fa fa-angle-left arrow"></i>
              </a>

              <ul class="nav-2-level collapse" id="sale" data-bs-parent="#offerChild">
                <li>
                  <a class="text-decoration-none" routerLink="/property/sale/lottery" [hidden]="sidebar['lottery']"
                    >Lottery</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/sale/fcfs" [hidden]="sidebar['fcfs']"
                    >FCFS</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/auction" [hidden]="sidebar['auction']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar17']}}</a>
                </li>
              </ul>

            </li>

            <li>

              <a class="text-decoration-none" href="#prog" data-bs-toggle="collapse" [hidden]="sidebar['progress']"><i
                  class="sidebar-item-icon fa fa-spinner" aria-expanded="false" aria-controls="prog"></i>
                <span class="nav-label">Progress</span><i class="fa fa-angle-left arrow"></i>
              </a>
              <ul class="nav-2-level collapse" id="prog" data-bs-parent="#offerChild">

                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/booklet-purchase"
                    [hidden]="sidebar['booklet_purchase']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar3']}}</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/offline-booklet"
                    [hidden]="sidebar['offline_booklet']" > Offline
                    Booklet</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/applications"
                    [hidden]="sidebar['aplicants']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar4']}}</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/lottery-slip"
                    [hidden]="sidebar['lottery_processing']" >Lottery
                    Processing</a>
                </li>

                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/auction-application"
                    [hidden]="sidebar['aution_application']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar18']}}</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/activity/bid-details"
                    [hidden]="sidebar['auction_bid_dtl']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar33']}}</a>
                </li>
              </ul>

            </li>
          </div>

        </ul>
      </li>
      <li >
        <a class="text-decoration-none" href="#progL" data-bs-toggle="collapse"><i
            class="sidebar-item-icon fa fa-balance-scale"></i>
          <span class="nav-label">Property Lease</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="progL" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-allotment"
              >Lease Allotment</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-sub-division"
              >Lease Sub Division</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-mutation"
              >Lease Mutation</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-renewal"
              >Lease Renewal</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-inspection"
              >Lease Ispection</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-litigation"
              >Lease Litigation</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/prop-lease/lease-freehold"
              >Lease Freehold</a>
          </li>

        </ul>
      </li>
      <li >
        <a class="text-decoration-none" href="#Service" data-bs-toggle="collapse" [hidden]="sidebar['service']"><i
            class="sidebar-item-icon fa fa-balance-scale"></i>
          <span class="nav-label">Service</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="Service" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/activity/allotment" [hidden]="sidebar['allotment']"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar5']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/activity/allotment-result"
              [hidden]="sidebar['allotment_preporation']" >Allotment
              Preparation</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/activity/auction-allotment"
              >Auction Allotment</a>
          </li>
        </ul>
      </li>
      <!-- advance serveices hide  -->
      <li >
        <a class="text-decoration-none" href="#adService" data-bs-toggle="collapse"
          [hidden]="sidebar['advanced_services']"><i class="sidebar-item-icon fa fa-cogs"></i>
          <span class="nav-label">Advance Services</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="adService" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/defaulter-party"
              [hidden]="sidebar['default_party']" >Defaulter Party
            </a>

          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/property-cancellation"
              [hidden]="sidebar['property_cancilation']" >Property
              Cancellation
            </a>

          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/seized-property"
              [hidden]="sidebar['restoration']" >Restoration
            </a>

          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/Possession"
              [hidden]="sidebar['procession']" >Possession
            </a>

          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/party-nominee"
              [hidden]="sidebar['nominee']" >Nominee / Partner
            </a>
          </li>

          <li>
            <a class="text-decoration-none" routerLink="/property/advance-services/refund-request"
              [hidden]="sidebar['refund_request']" >Refund Request
            </a>
          </li>

        </ul>
      </li>
      <li >
        <a class="text-decoration-none" href="#Mutation" data-bs-toggle="collapse" [hidden]="sidebar['mutation']"><i
            class="sidebar-item-icon fa fa-exchange"></i>
          <span class="nav-label">Mutation</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="Mutation" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/activity/mutation"
              [hidden]="sidebar['mut_appication']" >Application
            </a>
          </li>

        </ul>
      </li>

      <!-- PROPERTY LADEGER -->

      <li >
        <a class="text-decoration-none" href="#propReport" data-bs-toggle="collapse" [hidden]="sidebar['pro_report']"><i
            class="sidebar-item-icon fa fa-file"></i>
          <span class="nav-label">Property Report</span><i class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="propReport" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/property-ledger/general-ledger"
              [hidden]="sidebar['general_ladger']" >General
              Ledger</a>

          </li>

        </ul>
      </li>

      <!-- <li >
            <a class = "text-decoration-none" href="javascript:;"><i class="sidebar-item-icon fa fa-building-o"></i>
                <span class="nav-label"> {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar2']}}</span><i
                    class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse">
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/booklet-purchase"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar3']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/applications"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar4']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/allotment"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar5']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/applications-refund"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar6']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/payment-schedule"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar7']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/registry"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar8']}}</a>
                </li>
                <li>
                    <a class = "text-decoration-none" routerLink="/property/activity/restore"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar9']}}</a>
                </li>
            </ul>
        </li> -->
      <!-- <li >
            <a class = "text-decoration-none" href="javascript:;"><i class="sidebar-item-icon fa fa-cogs"></i>
                <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar10']}}</span><i
                    class="fa fa-angle-left arrow"></i></a>
            <ul class="nav-2-level collapse">


                <li>
                    <a class = "text-decoration-none" routerLink="/property/property-setting/property-info"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar15']}}</a>
                </li>

                <li>
                    <a class = "text-decoration-none" routerLink="/property/property-setting/property-def"
                        >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar16']}}</a>
                </li>
                <li >
                    <a class = "text-decoration-none" href="javascript:;"><i class="sidebar-item-icon fa fa-gavel"></i>
                        <span class="nav-label">
                            {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar17']}}</span><i
                            class="fa fa-angle-left arrow"></i></a>
                    <ul class="nav-3-level collapse">

                        <li>
                            <a class = "text-decoration-none" routerLink="/property/activity/auction-application"
                                >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar18']}}</a>
                        </li>
                        <li>
                            <a class = "text-decoration-none" routerLink="/property/activity/bid-details"
                                >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar33']}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li> -->



      <li >
        <a class="text-decoration-none" href="#admin" data-bs-toggle="collapse" [hidden]="sidebar['administration']"><i
            class="sidebar-item-icon fa fa-database"></i>
          <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar39']}}</span><i
            class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="admin" data-bs-parent="#sidebar-collapse">
          <div id="adminChild">

            <li>
              <a class="text-decoration-none"  href="#masterData"  data-bs-toggle="collapse"
                      [hidden]="sidebar['master']">
                <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar40']}}</span><i
                  class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-3-level collapse" id="masterData"  data-bs-parent="#adminChild">
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/property-setting/prop-code-value"
                    [hidden]="sidebar['code_value']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar11']}}</a>
                </li>

                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/property-setting/prop-field"
                    [hidden]="sidebar['field']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar12']}}</a>
                </li>
                <!-- <li>
                              <a class = "text-decoration-none" routerLink="/property/administration/geometry" >
                                  Geometry
                              </a>
                          </li> -->
                <!-- <li>
                              <a class = "text-decoration-none" routerLink="/property/administration/hierarchy" >
                              </a>
                          </li> -->
                <li >
                  <a class="text-decoration-none" href="#hier" data-bs-toggle="collapse" [hidden]="sidebar['hierarchy']">
                    <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar41']}}</span><i
                      class="fa fa-angle-left arrow"></i></a>
                  <ul class="nav-3-level collapse collapse2" id="hier" >
                    <li>
                      <a class="text-decoration-none" routerLink="/property/administration/hierarchy"
                        [hidden]="sidebar['location_hierarchy']" > <i class="fa fa-sitemap"
                          aria-hidden="true"></i>
                        {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar42']}}</a>
                    </li>
                    <!-- <li>
                                      <a class = "text-decoration-none" routerLink="/property/administration/standered-hierarchy"
                                          > <i class="fa fa-sitemap" aria-hidden="true"></i>
                                          {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar43']}}</a>
                                  </li> -->

                  </ul>
                </li>
                <!-- <li>
                              <a class = "text-decoration-none" routerLink="/property/administration/workflow" >
                                  Workflow
                              </a>
                          </li> -->
                <!-- LOCATION URL -->
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/location"
                    [hidden]="sidebar['location']" >
                    {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar44']}}
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/payment-option"
                    [hidden]="sidebar['payment_option']" >
                    Payment Option
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/defaulter-method"
                    [hidden]="sidebar['default_method']" >
                    Defaulter Method
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/auction-bid-rule"
                    [hidden]="sidebar['auction_bid_rule']" >
                    Auction Bid Rule
                  </a>
                </li>
                <li>
                  <!-- <a class = "text-decoration-none" routerLink="/property/administration/term-and-cond" [hidden]="sidebar['term-and-cond']" > -->
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/term-and-cond"
                    >
                    Term And Condition
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/possession-rule"
                    >
                    Possession Rule
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/mutation-rule"
                    >
                    Mutation Rule
                  </a>
                </li>

                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/feature"
                    [hidden]="sidebar['property_feature']" >
                    Property Feature
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" (click)="refreshCol2()" routerLink="/property/administration/allotment-configuration"
                    [hidden]="sidebar['slip_header_config']" >
                    Slip Header Configuration
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <a class="text-decoration-none" href="#secColapse" data-bs-toggle="collapse" [hidden]="sidebar['security']">
                <span class="nav-label">Security</span><i class="fa fa-angle-left arrow"></i></a>
              <ul class="nav-3-level collapse" id="secColapse" data-bs-parent="#adminChild">
                <li>
                  <a class="text-decoration-none" routerLink="/property/administration/roles" [hidden]="sidebar['role']"
                    >
                    {{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar46']}}
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/administration/users" [hidden]="sidebar['user']"
                    >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar47']}}</a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/administration/data-assignment"
                    [hidden]="sidebar['data_auth']" >
                    Data Assignment
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/administration/workflow"
                    [hidden]="sidebar['workflow']" >
                    Workflow
                  </a>
                </li>
                <li>
                  <a class="text-decoration-none" routerLink="/property/administration/public-config"
                    [hidden]="sidebar['pub-portal-config']" >
                    Public-Portal Config
                  </a>
                </li>
              </ul>
            </li>

          </div>

        </ul>
      </li>

      <li >
        <a class="text-decoration-none" href="#help" data-bs-toggle="collapse"><i
            class="sidebar-item-icon fa fa-question-circle"></i>
          <span class="nav-label">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar19']}}</span><i
            class="fa fa-angle-left arrow"></i></a>
        <ul class="nav-2-level collapse" id="help" data-bs-parent="#sidebar-collapse">
          <li>
            <a class="text-decoration-none" routerLink="/property/help/faq"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar20']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/help/doc"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar21']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/help/videos"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar22']}}</a>
          </li>
          <li>
            <a class="text-decoration-none" routerLink="/property/help/online-support"
              >{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar23']}}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
