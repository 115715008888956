<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Cause List</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-1" data-toggle="tab"><i class="ti-bar-chart"></i>
                             Cause List</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab"><i class="ti-settings"></i>
                                {{mainService.allLableShowObjHR[mainService.language_cd+'hr_death13']}} </a>
                        </li> -->


                    </ul>

                    <div class="tab-content" style="margin-top: 4%;">
                        <div class="tab-pane fade show active" id="tab-1">
                            <div class="row"></div>
                           <div class="row">
                            <div class="col-4 text-right">
                              Select Zone
                            </div>
                            <div class="col-4">
                              <ng-select [items]="courtArr" bindLabel="lvl3_value" bindValue="lvl3_cd"
                              [multiple]="false" placeholder="" [(ngModel)]="causeListObj['zone_cd']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="court_detail">
                                </ng-select>
                             </div>
                           </div>
                           <br>
                           <div class="row">
                            <div class="col-4 text-right">
                            Calender(Date)
                            </div>
                            <div class="col-4">
                                <input type="date" class="form-control" [(ngModel)]="causeListObj['date']">
                             </div>
                           </div>
                           <br>
                           <div class="row">
                            <div class="col-12 text-center">
                                <button class="btn btn-primary" (click)="submitCause()">
                                        Submit
                                </button>
                               </div>
                            </div>
                        <!--
                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="">
                            </mat-form-field>
                          -->
                            <div class="row">
                                <h5 class="text center"> {{causeListDate}}</h5>
                             </div>
                            <div class=" example-container">
                                <table mat-table [dataSource]="dataSource" matSort>


                                    <ng-container matColumnDef="s.no.">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                         S.No.
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element ; let i = index" class="right">
                                            {{i+1}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="case_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Case Id

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element " class="right">
                                        {{element.case_id_f}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="petitioner">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                           Petitioner

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.petitioner}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="defendent">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                           Defendant

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.def_name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="defendent_counsel">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                           Defendant Counsel

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.def_counsel}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="hearing_time">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                          Hearing time

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.time}} </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>





                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner>Loading....</ngx-spinner>
