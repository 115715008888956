import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ReportService } from "../service/report.service";
import { ExcelService } from "../service/file-export.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import { MainService as mainServiceMD } from "../../md/service/main.service";
import { MainService } from "../service/main.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";

import { MasterDataService } from "../service/master-data.service";
// import { EmbHierService } from "../../../service/emb-hier.service";
import { EmbHierService } from '../service/emb-hier.service';
declare var $: any;
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  datasource:any;
  datasource1:any;
  datasource2:any;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort1!: MatSort;
  displayedColumns = ["id", "report_name", "report_desc", "action"];
  displayedColumns1 = ["user", "current", "zone", "EMB"];
  displayedColumns2 = ["s_no", "file_id", "Work_name","document_id", "user", "role", "date"];
  @ViewChild("paginator1", { static: false }) paginator1!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;
  @ViewChild("paginator2", { static: false }) paginator2!: MatPaginator;
  @ViewChild("sort2", { static: false }) sort2!: MatSort;
  constructor(
    private mainServiceMD: mainServiceMD,
    public mainService: MainService,
    private excl: ExcelService,
    private ReportService: ReportService,
    private spinner: NgxSpinnerService,
    private embHierService: EmbHierService,
    private masterDataService: MasterDataService
  ) { }
  allFields = [];
  selectField = "";
  obj:any = {};
  activity_period = [
    { code: "Today" },
    { code: "This Week" },
    { code: "This Month" },
    { code: " This quarter" },
    { code: "Last six month" },
    { code: "This fiscal year" },
    { code: "Custom" },
  ];
  activity_type = [
    /* {code:'Project'}, */ { code: "Work" },
    { code: "Measurement" },
    { code: "EMB" },
    { code: "EBill" },
  ];
  columnobj:any = {
    project: ["user", "current", "zone", "Projects"],
    Work: ["user", "current", "zone", "Work"],
    Measurement: ["user", "current", "zone", "Measurement"],
    EMB: ["user", "current", "zone", "EMB"],
    EBill: ["user", "current", "zone", "EBill"],
  };
  techToBusNameObj:any = {
    user: "User name",
    current: "Current Roles",
    zone: "Node",
    Projects: "Number of Projects",
    Work: "Number of Work",
    Measurement: "Number of Measurement",
    EMB: "Number of EMB",
    EBill: "Number of EBill",
  };
  techToBusNameObjshow:any = {
    user: "create_user_id",
    current: "create_user_id",
    zone: "node_cd",
    Projects: "count",
    Work: "count",
    Measurement: "count",
    EMB: "count",
    EBill: "count",
  };

  displayedColumns_temp:any = [];
  activity_type_api:any = {
    EBill: "getBillDataReport",
    EMB: "getEMBDataReport",
    Work: "getEMBWorkReport",
    Measurement: "getEMBDataReport",
  };


  erpUser:any = {};
  b_acct_id:any;
  create_flag: boolean = false;
  update_flag: boolean = false;
  list_flag: boolean = true;
  show_report_flag: boolean = false;
  currentDate:any;
  roles:any = [];
  ad_tab_all_list = 'Active'
  ad_tab_file_report = "Deactive"
  createtab() {
    this.create_flag = true;
    this.list_flag = false;
    this.update_flag = false;
    this.show_report_flag = false
  }
  file_report_tab() {
    this.obj = {};
    this.datasource2 = new MatTableDataSource([]);
    this.create_flag = false;
    this.list_flag = false;
    this.update_flag = false;
    this.show_report_flag = true;
    this.ad_tab_all_list = 'Deactive'
    this.ad_tab_file_report = 'active'
    this.setFileReport()
  }
  async listbtab() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.show_report_flag = false;
    this.ad_tab_all_list = 'active'
    this.ad_tab_file_report = 'Deactive'
    await this.getallroles();
    await this.getAllReport();
    await this.getAccountUserInfo();
  }

  async ngOnInit() {
    this.ad_tab_all_list = 'active'
    this.erpUser =  JSON.parse(atob(localStorage.getItem("erpUser")as any));
    this.b_acct_id = this.erpUser["b_acct_id"];
    this.displayedColumns_temp = this.displayedColumns1;
    /*---- current Date -----*/
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    this.currentDate = dd + '-' + mm + '-' + yyyy;
    // this.displayedColumns1 = this.project
    await this.getallroles();
    await this.getAllReport();
    await this.getAccountUserInfo();
    await this.getAllWorkInfo()
    await this.getProjectHierFromAccount()
  }
  refresh() {
    this.obj = {};
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {
    this.datasource1.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }

  useridtoroleobj:any = {};
  async getallroles() {
    var obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.ReportService.getAlluserWithAllRoles(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      console.log(resp["data"]);
      for (let i = 0; i < resp["data"].length; i++) {
        this.useridtoroleobj[resp["data"][i]["user_id"]] =
          resp["data"][i]["role_name"];
      }
    } else {
      this.spinner.hide();
    }
  }
  async getAllReport() {
    var obj = Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.ReportService.getallReports(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.datasource = new MatTableDataSource(resp["data"]);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort1;
    } else {
      this.spinner.hide();
    }
  }
  reportdata = [];
  activity_typereport:any;
  async viewreport(element:any) {
    this.create_flag = false;
    this.update_flag = true;
    this.list_flag = false;
    this.show_report_flag = false;
    var obj = Object();
    obj["b_acct_id"] = this.b_acct_id;

    if (element["activity_period"] == "Today") {
      var d = new Date();
      console.log(d.toISOString());
      obj["start_date"] = d.toISOString().split("T")[0];

      d.setDate(d.getDate() + 1);

      console.log(d);
      obj["end_date"] = d.toISOString().split("T")[0];
    } else if (element["activity_period"] == "This Week") {
      var d = new Date();
      console.log(d.toISOString());
      obj["end_date"] = d.toISOString().split("T")[0];

      d.setDate(d.getDate() - 7);

      console.log(d);
      obj["start_date"] = d.toISOString().split("T")[0];
    } else if (element["activity_period"] == "This Month") {
      var d = new Date();
      console.log(d.toISOString());
      obj["end_date"] = d.toISOString().split("T")[0];

      d.setDate(0);

      console.log(d);
      obj["start_date"] = new Date(d.setMonth(d.getMonth() - 1))
        .toISOString()
        .split("T")[0];
    } else if (element["activity_period"] == "This quarter") {
      var d = new Date();
      console.log(d.toISOString());
      obj["end_date"] = d.toISOString().split("T")[0];

      d.setDate(0);

      console.log(d);
      obj["start_date"] = new Date(d.setMonth(d.getMonth() - 3))
        .toISOString()
        .split("T")[0];
    } else if (element["activity_period"] == "Last six month") {
      var d = new Date();
      console.log(d.toISOString());
      obj["end_date"] = d.toISOString().split("T")[0];

      d.setDate(0);

      console.log(d);
      obj["start_date"] = new Date(d.setMonth(d.getMonth() - 6))
        .toISOString()
        .split("T")[0];
    } else if (element["activity_period"] == "This fiscal year") {
      var d = new Date();
      console.log(d.toISOString());
      obj["end_date"] = d.toISOString().split("T")[0];

      d.setDate(0);

      console.log(d);
      obj["start_date"] = new Date(d.setMonth(3)).toISOString().split("T")[0];
    } else if (element["activity_period"] == "Custom") {
      obj["end_date"] = element["end_date"];

      obj["start_date"] = element["start_date"];
    }

    console.log(obj);
    var path = this.activity_type_api[element["activity_type"]];
    this.activity_typereport = element["activity_type"];
    this.spinner.show();
    var resp = await this.ReportService.getBillDataReport(
      JSON.stringify(obj),
      path
    );
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      this.reportdata = resp["data"];
      var arr:any = [];
      if (element["activity_type"] == "EMB") {
        for (let i = 0; i < this.reportdata.length; i++) {
          if (this.reportdata[i]["status"] != "GENERATED")
            arr.push(this.reportdata[i]);
        }
        this.reportdata = arr;
      }

      this.displayedColumns_temp = this.columnobj[element["activity_type"]];
      this.displayedColumns1 = this.columnobj[element["activity_type"]];
      this.datasource1 = new MatTableDataSource(this.reportdata);
      this.datasource1.paginator = this.paginator1;
      this.datasource1.sort = this.sortCol2;
      $('.nav-tabs a[href="#tab-3"]').tab("show");
    } else {
      this.spinner.hide();
    }
  }
  async submit() {
    this.obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.ReportService.createreports(this.obj);
    console.log(resp);
    if (resp["error"] == false) {
      this.spinner.hide();
      await this.getAllReport();
      Swal.fire("Success", "Report Added", "success");
    } else {
      this.spinner.hide();
    }
  }
  export() {
    var exp = [];
    for (var i = 0; i < this.reportdata.length; i++) {
      var obj:any = new Object();
      obj["SNO"] = i + 1;
      for (let j = 0; j < this.displayedColumns1.length; j++) {
        if (this.displayedColumns1[j] == "current") {
          obj[this.techToBusNameObj[this.displayedColumns1[j]]] =
            this.useridtoroleobj[
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ]
            ];
        } else if (this.displayedColumns1[j] == "user") {
          obj[this.techToBusNameObj[this.displayedColumns1[j]]] =
            this.userObj[
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ]
            ];
        } else {
          obj[this.techToBusNameObj[this.displayedColumns1[j]]] =
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ];
        }
      }
      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, "report");
  }
  userObj:any = {};
  users:any = [];
  async getAccountUserInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    console.log(obj);
    this.spinner.show();
    var resp = await this.mainServiceMD.getuserforrolemapping(
      JSON.stringify(obj)
    );
    if (resp["error"] == false) {
      this.spinner.hide();
      this.users = resp.data;
      console.log(this.users);
      for (let i = 0; i < this.users.length; i++) {
        this.userObj[this.users[i]["user_id"]] = this.users[i]["emp_name"];
        this.users[i]["desc"] =
          this.users[i]["user_id"] +
          " - " +
          this.users[i]["emp_name"] +
          " - " +
          this.users[i]["emp_phone_no"];
      }
    }
    else {
      this.spinner.hide();
    }
    console.log(this.users)
  }
  async print() {

    var txt = this.activity_typereport + " Report";
    var dd:any = {
      pageSize: "A3",
      header: function (currentPage:any, pageCount:any) {
        var obj = {
          text: txt + "     Page No. - " + currentPage,
          alignment: "center",
          margin: [72, 40],
        };
        return obj;
      },

      pageOrientation: "landscape",

      pageMargins: [40, 60, 40, 60],
      content: [],
    };

    dd.content.push({
      canvas: [
        { type: "line", x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 },
      ],
    });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });

    var tbl = {
      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*"],
        body: [["S NO."]],
      },
    };

    dd.content.push(tbl);
    console.log(dd.content[dd.content.length - 1].table.body);
    for (let i = 0; i < this.displayedColumns1.length; i++) {
      dd.content[dd.content.length - 1].table.body[0].push(
        this.techToBusNameObj[this.displayedColumns1[i]]
      );
    }
    for (var i = 0; i < this.reportdata.length; i++) {
      var arr = [];
      arr.push(i + 1);
      for (let j = 0; j < this.displayedColumns1.length; j++) {
        if (this.displayedColumns1[j] == "current") {
          arr.push(
            this.useridtoroleobj[
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ]
            ]
          );
        } else if (this.displayedColumns1[j] == "user") {
          arr.push(
            this.userObj[
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ]
            ]
          );
        } else {
          arr.push(
            this.reportdata[i][
            this.techToBusNameObjshow[this.displayedColumns1[j]]
            ]
          );
        }
      }

      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    pdfMake.createPdf(dd).download("report");
  }

  /*---------- Show Repoert -----------*/

  allEMBRole:any = [];
  doc_type:any = [];
  status:any = [];
  hier:any = [];
  planing_arrproj:any = [];
  check:any;
  users1:any = [];
  projhierobjisleaf:any = {};
  Obj:any = {};
  workArr:any = [];
  assignedWorks:any = {};
  allwork:any = [];
  planing_arr:any = [{}];
  workObj:any = {};
  allEmbProjectHier:any = [];
  projecthier:any = [];
  projhierobj:any = {};
  projhierobjtype:any = {};
  projhierobjtypecd:any = {};
  allCurrentRole:any = [];
  RoleObj:any = {};
  work_id:any = [];
  file_report_data:any = [];
  workData:any = [];
  date:any = null;
  hierchy:any = null
  async getAllWorkInfo() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.masterDataService.getWorkInfo(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.allwork = resp.data
      var dt = [];
      var temp = resp["data"];
      for (var i = 0; i < temp.length; i++) {
        this.workObj[temp[i]['id']] = temp[i];
        if (this.assignedWorks[temp[i]["id"]] != undefined) {
          dt.push(temp[i]);
        }
      }
      this.workArr = dt;
      for (let i = 0; i < this.workArr.length; i++) {
        this.workArr[i]["desc"] =
          this.workArr[i]["work_order_no"] +
          " - " +
          this.workArr[i]["work_order_name"];
      }
    } else {
      this.spinner.hide();
    }
  }
  async getProjectHierFromAccount() {
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    this.spinner.show();
    let resp = await this.embHierService.getprojectHierarchy(obj);
    this.allEmbProjectHier = resp.data;
    for (let i = 0; i < resp.data.length; i++) {
      this.spinner.hide();
      if (resp.data[i]["lvl1_cd"] == 1) {
        resp.data[i]["lvl1_value"] = "EMB-root";
      }
    }
    if (resp["error"] == false) {
      this.projecthier = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_value"];
          this.projhierobjtype[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_node_type"];
          this.projhierobjtypecd[resp["data"][i]["lvl" + j + "_user_cd"]] =
            resp["data"][i]["lvl" + j + "_cd"];
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }
  resetButton(){
    this.date = null;
    this.hierchy = null;
    this.obj['doc_type']={}
    this.obj['status']={}
    this.obj['role_cd']=[];
    this.obj['user_id']=[];
    this.planing_arr=[];
    this.datasource2 = new MatTableDataSource([]);
    this.reset();
  }
  async setFileReport() {
    this.date = null;
    this.hierchy = null;
    /*---------- currnt Date------------*/
    this.obj['date'] = this.currentDate;
    /*---------- Document Type----------*/
    this.doc_type = [ { fileType: 'EMB', value: 'EMB' },{ fileType: 'E BILL', value: 'EBILL' }, { fileType: 'CB', value: 'CB' }]
    /*---------- File status ----------*/
    this.status = [{ statusCode: 'APPROVED', value: 'APPROVED' },{statusCode: 'PENDING', value: 'PENDING' }]
    /*-------- hierchy -----------*/
    this.reset();
    /*-------- All users ----------*/
    this.users1 = [{ user_id: -1, desc: 'All Employee' }];
    for (let i = 0; i < this.users.length; i++) {
      this.users1.push(this.users[i]);
    }
    /*------- All roles -----------*/
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    this.spinner.show();
    var resp = await this.mainServiceMD.getCurrentMdRole(JSON.stringify(obj));
    if (resp["error"] == false) {
      this.spinner.hide();
      this.roles = [];
      this.allCurrentRole = resp.data;
      console.log(this.allCurrentRole);
      this.roles = [{ role_cd: -1, role_name: 'All Role' }];
      for (let i = 0; i < resp.data.length; i++) {
        this.RoleObj[resp.data[i]['role_cd']] = resp.data[i]['role_name']
        if (resp.data[i]["is_system_role"] == 0) {
          this.roles.push(resp.data[i]);
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Server Side Error While getting Currnt Role", "error");
      return;
    }
  }
  reset() {
    this.Obj = {};
    this.planing_arr = [];
    this.down({}, -1);
  }
  setHierarchyNode(item:any, i:any) {
    console.log(this.planing_arr);
    console.log(this.projhierobj);
    if (this.planing_arr.length >= 2) {
      this.check = false;
    } else {
      this.check = true;
    }
    let dummy2 = [];
    for (let i = 0; i < this.planing_arr.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
    }
    console.log(dummy2)
    this.Obj["path_desc"] = dummy2.join(" --> ");
    this.set_work_id();
  }
  down(item:any, k:any) {
    this.check = true;
    let patharr = [];

    for (let i = 0; i < this.planing_arr.length; i++) {
      patharr.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
    }
    console.log(patharr);
    this.Obj["path_desc"] = patharr.join("-->");
    this.planing_arr.push({ level: k + 2, existing_heirarchy_nodes: [] });
    let arr:any = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (
        !arr.includes(this.projecthier[i]["lvl" + (k + 2) + "_user_cd"]) &&
        this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] != null &&
        this.projecthier[i]["lvl" + (k + 2) + "_node_type"] != "WORK" &&
        this.projecthier[i]["lvl" + (k + 2) + "_node_type"] != "Work"
      ) {
        if (
          (this.projecthier[i]["lvl" + (k + 1) + "_user_cd"] ==
            item["hierarchy_type"] ||
            k == -1) &&
          this.projecthier[i]["leaf_node_type"] != "WORK"
        ) {
          this.planing_arr[k + 1]["existing_heirarchy_nodes"].push({
            node_cd: this.projecthier[i]["lvl" + (k + 2) + "_user_cd"],
            desc:
              this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_value"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_node_type"],
          });
          arr.push(this.projecthier[i]["lvl" + (k + 2) + "_user_cd"]);
        }
      }
    }

  }
  remove(item:any, i:any) {
    if (this.planing_arr.length > 1) {
      this.planing_arr.pop();
      let patharr = [];
      for (let i = 0; i < this.planing_arr.length; i++) {
        patharr.push(this.projhierobj[this.planing_arr[i]["hierarchy_type"]]);
      }
      this.Obj["path_desc"] = patharr.join("-->");
    } else {
    }
    if (
      this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] != null ||
      this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] !=
      undefined
    ) {
      this.check = false;
    }
    this.set_work_id();
  }
  close() {
    this.Obj['path_desc'] = undefined;
    this.planing_arrproj = [];
  }
  set_work_id() {
    let node_code;
    node_code = this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"];
    let level = this.planing_arr[this.planing_arr.length - 1]["level"];
    let a = '';
    let b = '';
    this.work_id = [];
    for (let i = 0; i < this.allEmbProjectHier.length; i++) {
      if (this.allEmbProjectHier[i]['leaf_node_type'] == 'WORK') {
        if (node_code == this.allEmbProjectHier[i]["lvl" + level + "_user_cd"]) {
          a = this.allEmbProjectHier[i]['leaf_user_cd']
          b = a.substring(7);
          this.work_id.push(Number(b));
        }
      }
    }
  }
  async submitFileReport() {
    if ((!this.obj['doc_type']) || (!this.obj['status']) || this.obj['user_id'] == undefined || this.obj['role_cd'] == undefined || this.planing_arr[this.planing_arr.length - 1]["hierarchy_type"] == undefined) {
      Swal.fire("Oops!!!", "Please fill all required fields", "warning");
      return;
    }
    if (this.obj['role_cd'].includes(-1)) {
      let role_cd:any = [];
      this.allCurrentRole.map((x:any) => {
        if (x['role_cd'] != -1) {
          role_cd.push(x['role_cd'])
        }
      })
      this.obj['role_cd'] = role_cd;
    }
    if (this.obj['user_id'].includes(-1)) {
      let users_id:any = [];
      this.users1.map((x:any) => {
        if (x['user_id'] != -1) {
          users_id.push(x['user_id'])
        }
      })
      this.obj['user_id'] = users_id;
    }
    this.obj['work_id'] = this.work_id
    this.obj["b_acct_id"] = this.b_acct_id
    console.log(this.obj)
    this.spinner.show();
    var resp = await this.ReportService.showReport(this.obj);
    if (resp["error"] == false) {
      this.file_report_data = resp.data;
      this.spinner.hide();
      console.log(this.file_report_data)
      if ((this.file_report_data.length) != 0) {
        this.date = this.currentDate
        this.hierchy = this.Obj['path_desc']
      }
      let status = this.obj['status'].toLowerCase();
      let doc_type = this.obj['doc_type'].toLowerCase()
      if ((this.file_report_data.length == 0)) {
        Swal.fire("Oops!!!", "There are no" + " " + status + " " + doc_type + " " + "available the information given", "warning");
        this.datasource2 = new MatTableDataSource([]);
        this.datasource2.paginator = this.paginator2;
        this.datasource2.sort = this.sort2
        this.date = null
        this.hierchy = null
        return;
      }
      this.datasource2 = new MatTableDataSource(this.file_report_data);
      this.datasource2.paginator = this.paginator2;
      this.datasource2.sort = this.sort2
      console.log(this.file_report_data)
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Server Side Error While getting file report", "error");
      return;
    }
  }
  printRp()
  {
    var txt = ' Report'
    var dd:any = {
      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 1110, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
 
    var tb1:any = {
      fontSize: 10,
      table: {
 
        headerRows: 1,
        widths: [ '*','*' ],
        body: [
                   
        ],
      }
    };
    let arr1=[]
    arr1.push({text:"Report Date" ,bold:true})
    arr1.push({text:this.obj['date']})
    tb1.table.body.push(arr1);
    arr1=[]
    arr1.push({text:"Select File Status" , bold:true})
    arr1.push({text:this.obj['status']})
    tb1.table.body.push(arr1);
    dd.content.push(tb1);
    dd.content.push({ text: " " });
    dd.content.push({ text: " " });
    
    var tb2 = {

      fontSize: 10,
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*','*','*'],
        body: [
          ['Sr.No','FIle ID','Work Name', 'document Id','User','Role','Date']

        ],
      }
    };
    dd.content.push(tb2);
    let data=this.file_report_data;
    for(let i=0; i<data.length;i++){
    let arr=[];
    arr.push({text:i+1})
    arr.push({text:this.workObj[data[i].sys_work]['work_order_no']})
    arr.push({text:this.workObj[data[i].sys_work]['work_order_name']})
    arr.push({text:data[i].doc_local_no})
    arr.push({text:this.userObj[data[i].user_id]})
    arr.push({text:this.RoleObj[data[i].role_cd]})
    arr.push({text:data[i].file_eff_date})
    dd.content[dd.content.length - 1].table.body.push(arr);
    }
    var win = window.open('', '_blank');
  pdfMake.createPdf(dd).open({},win);
  }
}