import { Component, OnInit, ViewChild,ViewChildren,QueryList } from '@angular/core';
// import { MatTableDataSource } from "@angular/material/table";
// import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
// import { MatPaginator } from "@angular/material/paginator";
// import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MainService } from '../../../service/main.service'; // service outside
import { InceptionService } from '../../../depricated/service/inception.service';
// import { throwMatDuplicatedDrawerError } from '@angular/material';
import { threadId } from 'worker_threads';
import { EmbHierService } from "../../../service/emb-hier.service";
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApprovalUserService } from '../../../../portal/service/approval-user.service';
// import { MainService as mainServiceMD } from "../../../../md/service/main.service";
import { MainService as mainServiceMD } from "../../../../md/service/main.service";
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any

@Component({
  selector: 'app-inception',
  templateUrl: './inception.component.html',
  styleUrls: ['./inception.component.css']
})
export class InceptionComponent implements OnInit {

  constructor(public toastr: ToastrService, private inception: InceptionService, public mainService: MainService, private embHierService: EmbHierService,
    private spinner: NgxSpinnerService, public ApprovalUserService: ApprovalUserService, private mainServiceMD: mainServiceMD, private sanitizer: DomSanitizer) { }

    @ViewChild(MatPaginator, { static:false }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  list_fg = 'deactive';
  l_flag = false;
  create_fg = 'deactive';
  c_flag = false;
  upload_fg = 'deactive';
  upload_flag = false;
  uploadFile:any = []
  dataSource:any;
  datasource1:any;
  displayedColumns = [
    "id",
    "project_name",
    "duration",
    "est_bud",
    "prop_meth",
    "status",
    "action",

  ];

  displayedColumns1 = [
    "sno",
    "role",
    "resp",
    "action",
  ];
  displayedColumns2 = [
    "sno",
    "code",
    "del_item",
    "action",
  ];
  displayedColumns3 = [
    "sno",
    "code",
    "activity",
    "start_date",
    "end_date",
    "duration",
    "comment",
    "action"
  ];

  displayedColumns4 = [
    "sno",
    "doc_type",
    "file_name",
    "action"
  ];
  displayedColumns6 = [
    "sno",
    "doc_type",
    "doc_name",
    "action"
  ];



  // page variabel
  hirarchy:any;
  p_name:any;
  obj_pro:any;
  scope:any;
  duration:any;
  est_bud:any;
  metho:any;
  resp:any;
  est_time:any;
  ebillUser:any;
  create_flag:any;
  b_acct_id:any;
  Obj:any = {
    data: []
  };
  field:any = {};
  role_cd = ''
  uploader:any;
  httpUrl:any;

  async ngOnInit() {
    this.spinner.show();
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.role_cd = this.ebillUser.role_cd;
    this.httpUrl = this.mainService.httpUrl2;
    this.uploader = new FileUploader({ url: this.httpUrl, itemAlias: 'file' });
    this.uploader.onAfterAddingFile = (file:any) => { file.withCredentials = false; };
    await this.getAccountProjectHier();
    // await this.addRowFiles(true)
    await this.getWorkflowlog()
    if (this.level1['role_cd'] == this.role_cd) {
      this.apprflag = true
    }
    await this.getAllRoles()
    await this.list()
    this.spinner.hide();
  }
  undertakings:any = []
  est_time_arr = [{ code: 'YEAR', value: 'YEAR' }, { code: 'MONTH', value: 'MONTH' }, { code: 'DAY', value: 'DAY' }]
  async list() {
    this.referace();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['doc_type'] = 'INCEPTION'
    this.spinner.show();
    let res = await this.inception.getinception(JSON.stringify(obj));
    if (res["error"] == false) {
      var data = res["data"];
      console.log("this is the length of the responce length ", data.length);
      console.log("this is the length of the responce daata ", data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // console.log(this.paginator.toArray())
      // this.datasource.sort = this.sort.toArray()[0];
      // this.datasource.paginator = this.paginator.toArray()[0];
      // this.list_fg = 'active';
      this.l_flag = true;
      // this.create_fg = 'deactive';
      this.create_flag = false;
      this.upload_flag = false;
      // this.upload_fg = 'deactive';
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error while getting data", "error");
    }

  }

  role_len :any;
  role_responcibility:any;
  role_typ:any;
  role_index :any;
  async createinception() {
    this.planing_arrproj=[]
    await this.getAccountProjectHier()
    this.spinner.show();
    this.uploadFile = []
    this.uploader.queue = [];
    this.datasource4 = new MatTableDataSource([])


    this.Obj['path_desc'] = null
    // this.create_fg = 'active';
    // this.list_fg = 'deactive';
    // this.upload_fg = 'deactive';
    // this.c_flag = true;
    this.l_flag = false;
    this.create_flag = true;
    this.upload_flag = false;
    this.referace();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['inc_id_f'] = 0
    obj['submit_status'] = 'PENDING'
    let resp = await this.inception.deleteunuseimage(obj);

    if (resp['error'] == false) {
      this.spinner.hide();
      this.datasource4 = new MatTableDataSource([])
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Server side error", "error");
    }
    this.Obj['pro_name']=undefined
    this.Obj['obj']=undefined
    this.Obj['pro_scope']=undefined
    this.Obj['est_bud']=undefined
    this.Obj['method']=undefined
    this.Obj['pro_duration']=undefined
    this.Obj['pro_dur_time']=undefined
    this.role_resp=[]
    this.Deliverables=[]
    this.act_sch=[]
    this.uploadFile=[]
    this.datasource1 = new MatTableDataSource([])
    this.datasource2 = new MatTableDataSource([])
    this.datasource3 = new MatTableDataSource([])
    this.datasource4 = new MatTableDataSource([])
    this.tab='tab-7-2'
    $('.nav-tabs a[href="#tab-7-2"]').tab('show')

  }
  async updateinception() {
    await this.validationproj()
    if(this.confirmflag){
      return;
    }
    await this.validationrole()
    if(this.confirmflag){
      return;
    }
    await this.validationdel()
    if(this.confirmflag){
      return;
    }
    await this.validationactsch()
    if(this.confirmflag){
      return;
    }
    await this.validationdoc()
    if(this.confirmflag){
      return;
    }
    // this.res_role = [];
    // for (let i = 0; i < this.uploadFile.length; i++) {
    //   this.res_role.push(this.uploadFile[i]);
    // }
    // console.log("this is the role", this.res_role);

    // let main_obj = {};
    // main_obj['first_obj'] = this.field;
    // main_obj['sec_obj'] = this.res_role;
    // main_obj['b_acct_id'] = this.b_acct_id;
    // console.log("main obj is to be send", main_obj);
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.Obj['role'] = this.role_resp
    this.Obj['del'] = this.Deliverables
    this.Obj['act_sch'] = this.act_sch
    let resp = await this.inception.updateinception(this.Obj);
    if (resp['error'] == false) {
      await this.updateprojhier(this.Obj['inc_id_p'])
      // Swal.fire("Success", "Uploaded Successfully", "success");
      await this.list()
      await this.getAccountProjectHier();
      $('.nav-tabs a[href="#tab-7-1"]').tab('show')
    } else {
      Swal.fire("Error...", "Server side error", "error");
    }
  }

  async updateprojhier(id:any) {
    let level = 0;
    let obb:any = new Object();
    obb["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb["lvl" + this.planing_arrproj[i]["level"] + "_cd"] =
        this.planing_arrproj[i]["hierarchy_type"];
      if (this.planing_arrproj[i]["level"] == 1)
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          "All Projects";
      else
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"] =
        this.projhierobjtypecd[this.planing_arrproj[i]["hierarchy_type"]];
      console.log(
        this.planing_arrproj[i]["level"],
        obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"]
      );
      level = this.planing_arrproj[i]["level"];
    }

    obb["lvl" + (level + 1) + "_user_cd"] = "INCEPTION" + id;
    obb["lvl" + (level + 1) + "_node_type"] = "INCEPTION";
    obb["lvl" + (level + 1) + "_value"] = this.Obj["pro_name"];
    obb["lvl" + (level + 1) + "_type"] = "text";
    obb["leaf_user_cd"] = "INCEPTION" + id;
    obb["leaf_node_type"] = "INCEPTION";
    obb["leaf_value"] = this.Obj["pro_name"];
    obb["module_cd"] = "EMB";
    obb['update_user_id']=this.ebillUser.le_id
    this.spinner.show();
    var resp = await this.embHierService.projectIEupdateHierarchy(obb);
    if (resp["error"] == false) {
      // await this.update();
      Swal.fire("Success", "Updated Successfully", "success");
      this.spinner.hide();
    } else {
      this.spinner.hide();
      Swal.fire("Error...", resp["data"], "error");
    }
  }
tab:any;
  async open_update(element:any) {
    this.Obj = Object.assign({}, element);
    this.upload_flag = true;
    this.l_flag = false;
    this.create_flag = false;
    // this.upload_fg = 'active';
    // this.create_fg = 'deactive';
    // this.list_fg = 'deactive';

    let ele_data:any = {}
    ele_data['b_acct_id'] = this.b_acct_id;
    ele_data['inc_id_p'] = element['inc_id_p']

    let data:any = {};
    for (let i = 0; i < this.projecthier.length; i++) {
      if (this.projecthier[i]["leaf_user_cd"] == "INCEPTION" + element['inc_id_p']) {
        data = this.projecthier[i];
        break;
      }
    }
    console.log(data)
    this.planing_arrproj = [];
    for (let i = 1; i < 8; i++) {
      if (
        data["lvl" + i + "_user_cd"] != null &&
        data["leaf_user_cd"] != data["lvl" + i + "_user_cd"]
      ) {
        if (i == 1) {
          this.down({ hierarchy_type: data["lvl" + i + "_cd"] }, -1);
          this.planing_arrproj[this.planing_arrproj.length - 1][
            "hierarchy_type"
          ] = data["lvl" + i + "_cd"];
          console.log(this.planing_arrproj);
        } else {
          this.down({ hierarchy_type: data["lvl" + (i - 1) + "_cd"] }, i - 2);
          this.planing_arrproj[this.planing_arrproj.length - 1][
            "hierarchy_type"
          ] = data["lvl" + i + "_cd"];
          this.setHierarchyNodeitem(
            this.planing_arrproj[this.planing_arrproj.length - 1],
            i - 1
          );
        }
      }
      this.Obj["node_type"] = data["leaf_node_type"];
      this.Obj["node_cd"] = data["leaf_user_cd"];
      this.Obj["node_desc"] = data["leaf_value"];
    }


    let resp = await this.inception.getupdateinc(ele_data);
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp)
      this.role_resp = resp['role']
      this.datasource1 = new MatTableDataSource(this.role_resp)
      this.Deliverables = resp['del']
      this.datasource2 = new MatTableDataSource(this.Deliverables)
      this.act_sch = resp['act_sch']
      this.datasource3 = new MatTableDataSource(this.act_sch)
      this.uploadFile = resp['doc']
      this.uploadFile.map((x:any, k:any) => {
        if (this.uploadFile[k]) {
          x['iconflag'] = true
        } else {

        }
      })
      this.datasource4 = new MatTableDataSource(this.uploadFile)

    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Server side error", "error");
    }
    this.tab='tab-7-3'
    $('.nav-tabs a[href="#tab-7-3"]').tab('show')
  }


  async deleteinception(element:any) {
    this.field = {};
    this.field = Object.assign({}, element);
    let obj:any = {};
    obj['inc_id'] = this.field['inc_id_p'];

    obj['b_acct_id'] = this.b_acct_id
    Swal.fire({

      title: "Are you sure?",
      // imageUrl: 'https://unsplash.it/400/200',
      //  imageWidth: 400,
      //  imageHeight: 200,
      // imageAlt: 'Custom image',
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.value) {
this.spinner.show()
        let res = await this.inception.deleteincept(obj);
        if (res["error"] == false) {
          // Swal.fire("Success", "Deleted Successfully", "success");
          this.spinner.hide()
          Swal.fire(
            'Deleted!',
            'Deleted Successfully',
            'success'
          )
          await this.list();
        }
        else {
          this.spinner.hide()
          Swal.fire("Error...", "Server side error", "error");
        }


      }

    });




  }


  referace() {
    this.datasource4 = []
    this.uploadFile = [];
    this.index = 0;
    this.role_index = 0;
    this.role_len = 0;
    this.role_responcibility = "";
    this.role_typ = "";
    this.res_role = [];

  }

  index: any;
  add: boolean = false;
  role_resp:any = []
  addRowRoleResp() {

    this.role_resp.push({ role_name: undefined, role_resp: undefined })
    this.datasource1 = new MatTableDataSource(this.role_resp);

    // let up_len=this.uploadFile.length +1;
    // if(obj==true){
    //                     if(this.upload_fg=='active')
    //                     {
    //                       console.log("this is before", this.role_len, this.index);
    //                      this.index=this.role_len + 1;
    //                      this.role_len++;
    //                      console.log("this is after ", this.role_len, this.index);
    //                     }
    //                     else{
    //                              this.index=up_len;
    //                              up_len++;
    //                              console.log("leng of this.uploadFile.length ",this.uploadFile.length )
    //                             }
    //                           console.log("add sign hit ");
    //                              this.uploadFile.push({
    //                               item_id: this.index,
    //                               role_name: "",
    //                               role_resp: "",
    //                             });

    //                    }
    // else if(obj==false){
    //         this.index=this.role_index;
    //         this.uploadFile.push({
    //           item_id: this.index,
    //           role_name: this.role_typ,
    //           role_resp: this.role_responcibility,
    //         });
    //         this.role_index++;
    //      }


  }
  datasource2:any;
  Deliverables:any = []
  addRowDel() {

    this.Deliverables.push({code:undefined,del_item:undefined})
    this.datasource2 = new MatTableDataSource(this.Deliverables);
  }
  datasource4:any;

  addRowFiles() {
    console.log(this.uploadFile)
    if (this.uploadFile.length >= 1) {
      if (this.uploadFile[this.uploadFile.length - 1]['iconflag'] != true) {
        Swal.fire("Error...", "Please first upload previous level then go for another", "error");
        return;
      }
    }
    this.uploadFile.push({ doc_type: undefined,doc_name:undefined, iconflag: false })
    this.datasource4 = new MatTableDataSource(this.uploadFile);

  }

  datasource3:any;
  act_sch:any = []
  addRowActSch() {
    this.act_sch.push({code:undefined,activity:undefined,start_date:undefined,end_date:undefined,duration:undefined})
    this.datasource3 = new MatTableDataSource(this.act_sch);
  }

  deleteRowRoleResp(i:any) {

    this.role_resp.splice(i, 1);
    this.datasource1 = new MatTableDataSource(this.role_resp);

  }
  deleteRowDel(i:any) {
    this.Deliverables.splice(i, 1);
    this.datasource2 = new MatTableDataSource(this.Deliverables);

  }

  deleteRowActSch(i:any) {
    this.act_sch.splice(i, 1);
    this.datasource3 = new MatTableDataSource(this.act_sch);

  }

  async deleteRowFiles(i:any) {

    Swal.fire({

      title: "Are you sure?",
      // imageUrl: 'https://unsplash.it/400/200',
      //  imageWidth: 400,
      //  imageHeight: 200,
      // imageAlt: 'Custom image',
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        if (this.uploadFile[i]['inc_doc_id']) {
          let obj:any = {}
          obj['b_acct_id'] = this.b_acct_id
          obj['inc_doc_id'] = this.uploadFile[i]['inc_doc_id']
          let resp = await this.inception.deleteimage(obj);
          if (resp['error'] == false) {
            this.spinner.hide();

          } else {
            this.spinner.hide();
            Swal.fire("Error...", "Server side error", "error");
          }
        }
        this.uploadFile.splice(i, 1);
        this.datasource4 = new MatTableDataSource(this.uploadFile);
        this.uploader.queue.splice(i, 1)
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }

    });


  }

  res_role = [];
  obj_detail = {}
  async submit() {
    await this.validationproj()
    if(this.confirmflag){
      return;
    }
    await this.validationrole()
    if(this.confirmflag){
      return;
    }
    await this.validationdel()
    if(this.confirmflag){
      return;
    }
    await this.validationactsch()
    if(this.confirmflag){
      return;
    }
    await this.validationdoc()
    if(this.confirmflag){
      return;
    }
    this.Obj['b_acct_id'] = this.b_acct_id;
    this.Obj['role'] = this.role_resp
    this.Obj['del'] = this.Deliverables
    this.Obj['act_sch'] = this.act_sch
    let responce = await this.inception.createInception(this.Obj);
    if (responce['error'] == false) {
      await this.submithier(responce['id'])
      // Swal.fire("Success", "Created Successfully", "success");

      await this.list()
      $('.nav-tabs a[href="#tab-7-1"]').tab('show')
    } else {
      Swal.fire("Error...", "Server side error", "error");
    }
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  updateinception1() {

  }

  submit_act() {
    if (
      this.planing_arrproj.length < 2 ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null ||
      this.planing_arrproj[this.planing_arrproj.length - 1]["hierarchy_type"] ==
      null
    ) {
      Swal.fire("Warning", "Please Select at least two level", "warning");
    } else {
      $("#ACTHIER").modal("hide");
    }
  }
  // project herarchy
  planing_arrproj:any = [];
  projecthier:any = [];
  projhierobj:any = {};
  projhierobjtype:any = {};
  projhierobjtypecd:any = {};
  projhierobjisleaf:any = {};

  async getAccountProjectHier() {
    this.projhierobj = []
    this.projecthier = []
    let obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    let resp = await this.embHierService.getprojectHierarchy(obj);
    if (resp["error"] == false) {
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["lvl1_cd"] == 1) {
          resp.data[i]["lvl1_value"] = "EMB-root";
        }
      }
      this.projecthier = resp["data"];
      for (let i = 0; i < resp["data"].length; i++) {
        for (let j = 1; j < 8; j++) {
          this.projhierobj[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_value"];
          this.projhierobjtype[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_node_type"];
          this.projhierobjtypecd[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["lvl" + j + "_user_cd"];
          this.projhierobjisleaf[resp["data"][i]["lvl" + j + "_cd"]] =
            resp["data"][i]["is_leaf"];
        }
      }
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Getting Cost Center", "error");
    }
  }


  selectProjectPopUp() {
    if (this.planing_arrproj.length == 0) {
      this.down({}, -1);
    }
    $("#ACTHIER").modal("show");
  }
  check:any;
  setHierarchyNodeitem(item:any, i:any) {
    console.log(item, i);
    this.check = item["hierarchy_type"];
    console.log(this.check);
    item["is_leaf"] = this.projhierobjisleaf[item["hierarchy_type"]];
    let dummy2 = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      dummy2.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = dummy2.join(" --> ");

  }
  down(item:any, k:any) {
    console.log(this.planing_arrproj);
    let patharr = [];
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      patharr.push(this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]);
    }
    this.Obj["path_desc"] = patharr.join("-->");
    this.planing_arrproj.push({ level: k + 2, existing_heirarchy_nodes: [] });
    console.log(this.planing_arrproj, item);
    let arr:any = [];
    for (let i = 0; i < this.projecthier.length; i++) {
      if (
        !arr.includes(this.projecthier[i]["lvl" + (k + 2) + "_cd"]) &&
        this.projecthier[i]["lvl" + (k + 2) + "_cd"] != null
      ) {
        if (
          (this.projecthier[i]["lvl" + (k + 1) + "_cd"] ==
            item["hierarchy_type"] ||
            k == -1) &&
          this.projecthier[i]["leaf_node_type"] != "WORK" && this.projecthier[i]["leaf_node_type"] != "INCEPTION" &&this.projecthier[i]["leaf_node_type"] != "ESTIMATE"
        ) {
          this.planing_arrproj[k + 1]["existing_heirarchy_nodes"].push({
            node_cd: this.projecthier[i]["lvl" + (k + 2) + "_cd"],
            desc:
              this.projecthier[i]["lvl" + (k + 2) + "_user_cd"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_value"] +
              " - " +
              this.projecthier[i]["lvl" + (k + 2) + "_node_type"],
            is_leaf: this.projecthier[i]["is_leaf"],
          });
          arr.push(this.projecthier[i]["lvl" + (k + 2) + "_cd"]);
        }
      }
    }
  }
  remove(item:any, i:any) {
    if (this.planing_arrproj.length > 1) {
      this.planing_arrproj.pop();
      let patharr:any = [];
      for (let i = 0; i < this.planing_arrproj.length; i++) {
        console.log(this.planing_arrproj[i]);

        patharr.push(
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]]
        );
      }
      this.Obj["path_desc"] = patharr.join("-->");
    }
  }

  close(){
    if(this.create_flag){
    this.planing_arrproj=[]
    this.Obj['path_desc']=''
    }
  }

  /*--------------------approval-------------------------------*/
  //workflow*********************************************//
  level_for_approval = []
  workflow_level1:any = {}
  apprflag = false;
  async getWorkflowlog() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'INCEPTION'
    var resp = await this.mainService.getWorkflows(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      if (resp['data'].length != 0) {
        this.workflow_level1 = resp['data'][0]
        this.level_for_approval = resp['data']
        var currentuser_role = this.ebillUser.role_cd
        for (let i = 0; i < currentuser_role.length; i++) {
          if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
            this.apprflag = true
          }
        }
        this.level1 = this.workflow_level1
      }

      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  inc_apprdata:any = {}
  async Appr(element:any) {
    this.inc_apprdata = Object.assign({}, element)
    this.inc_apprdata['level'] = 1
    this.inc_apprdata['branch'] = 1
    this.inc_apprdata['doc_local_no'] = element['inc_id_p']
    this.inc_apprdata['doc_type'] = 'INCEPTION'//this.tender_apprdata['id']
    this.inc_apprdata['node_cd'] = element['inc_id_p']
    this.inc_apprdata['module_cd'] = 'EMB'
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'INCEPTION', 'EMB')
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.inc_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.inc_apprdata)
    $('#myModal2').modal('show');
  }
  forwarded_user_id:any;
  appr_remark:any;
  level1:any = {}
  message_flag = false
  async Approvedapproval() {
    console.log("called")
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();


      obb['user_id'] = this.ebillUser.user_id

      obb['user_name'] = this.ebillUser.party_name;
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'INCEPTION'
      obb['doc_local_no'] = this.inc_apprdata['inc_id_p']
      obb['doc_desc'] = this.inc_apprdata['obj']
      obb['role_cd'] = this.level1['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.inc_apprdata['node_cd']
      obb['level'] = this.level1['level']
      obb['undertaking'] = ''
      obb['branch'] = this.level1['branch']
      obb['field_value'] = 0
      console.log(this.inc_apprdata)
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.updatestatus(this.inc_apprdata['inc_id_p'])
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {

          //  if(this.message_flag == true) await this.getle(this.selectedTender['party_id'])
        } else {
          //  if(this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }
        this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb:any = new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          Swal.fire("Error", "Vender concept are not allow ", 'error')
          return;
        }
        if (this.inc_apprdata['party_id'] == null || this.inc_apprdata['party_id'] == undefined) {
          Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
          return
        }
        obb['user_id'] = -1
        obb['vendor_id'] = this.inc_apprdata['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }


      if (obb["user_id"] == -1) {
        let userData:any = {};
        userData['b_acct_id'] = this.b_acct_id;
        userData['user_id'] = obb["vendor_id"];
        let venderInfo = await this.mainService.getVenderName(JSON.stringify(obb));
        if (venderInfo["error"] == false) {
          obb['user_name'] = venderInfo["data"]['party_leagal_name']
        }
      } else {
        for (let i = 0; i < this.ApprovalUserService.workuser.length; i++) {
          if (this.ApprovalUserService.workuser[i]['user_id'] == obb["user_id"]) {
            let h = this.ApprovalUserService.workuser[i]['desc'].split("-");
            obb['user_name'] = h[1];
          }
        }
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'INCEPTION'
      obb['doc_local_no'] = this.inc_apprdata['inc_id_p']
      obb['doc_desc'] = this.inc_apprdata['obj']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'EMB'
      obb['node_cd'] = this.inc_apprdata['inc_id_p']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.inc_apprdata['level']
        obb['branch'] = this.inc_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      obb['field_value'] = 0
      var resp = await this.mainService.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.success("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        this.toastr.error(resp['data'])
      }
    } else {
      this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  // ********************************************re assign start************************************///
  // venderid
  // async getdocumentstatus(element) {
  //   let obj = Object()
  //   obj['b_acct_id'] = this.b_acct_id
  //   obj['doc_local_no'] = element['inc_id_p']
  //   obj['doc_type'] = 'INCEPTION'
  //   obj['module_cd'] = 'EMB'
  //   obj['status'] = 'PENDING'
  //   var resp = await this.portalmain.getWorkflowlog(JSON.stringify(obj))
  //   console.log(resp)
  //   if (resp['error'] == false) {

  //     if (resp['data'].length > 0) {
  //       return resp['data'][0]
  //     }
  //   }
  // }
  async updatestatus(id:any) {
    var obb:any = new Object()
    obb['b_acct_id'] = this.ebillUser.b_acct_id
    obb['id'] = id
    obb['update_user_id'] = this.ebillUser.user_id
    obb['status'] = 'UNDERAPPROVAL'
    var resp = await this.inception.updateinctStatus(obb)
    if (resp['error'] == false) {
      await this.list()
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }
  }

  workflowuser = []
  rolecdtolevelobj = {}
  async getWorkflowloguser() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'EMB'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.inc_apprdata['inc_id_p']
    console.log(obj, this.inc_apprdata)
    var resp = await this.mainService.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();
      this.toastr.error(resp['data'])
    }

  }

  statusArr:any = [];
  selectedEle:any;
  async viewStatus(element:any) {
    this.selectedEle = element;
    var obj:any = new Object();
    obj['doc_local_no'] = element.inc_id_p;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'INCEPTION';
    obj['module_cd'] = 'EMB'
    var resp = await this.mainService.getWorkflowlog(JSON.stringify(obj));
    if (resp['error'] == false) {
      await this.getWorkflowloguser()
      console.log(resp.data)
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = ''
          resp.data[i]['role_cd'] = "Contractor"
          resp.data[i]['user_id'] = resp['data'][i]['vendor_id'];
          resp.data[i]['is_vendor'] = true
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            console.log(this.workflowuser[j])
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }

        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }

      this.statusArr = resp.data
      console.log(this.statusArr)

      $('#myModal1').modal('show');
    } else {
      this.toastr.error(resp['data'])
    }
  }

  async submithier(id:any) {
    let level = 0;
    let obb:any = new Object();
    obb["b_acct_id"] = this.b_acct_id;
    for (let i = 0; i < this.planing_arrproj.length; i++) {
      obb["lvl" + this.planing_arrproj[i]["level"] + "_cd"] =
        this.planing_arrproj[i]["hierarchy_type"];
      if (this.planing_arrproj[i]["level"] == 1)
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          "All Projects";
      else
        obb["lvl" + this.planing_arrproj[i]["level"] + "_value"] =
          this.projhierobj[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_node_type"] =
        this.projhierobjtype[this.planing_arrproj[i]["hierarchy_type"]];
      obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"] =
        this.projhierobjtypecd[this.planing_arrproj[i]["hierarchy_type"]];
      console.log(
        this.planing_arrproj[i]["level"],
        obb["lvl" + this.planing_arrproj[i]["level"] + "_user_cd"]
      );
      level = this.planing_arrproj[i]["level"];
    }

    obb["lvl" + (level + 1) + "_user_cd"] = "INCEPTION" + id;
    obb["lvl" + (level + 1) + "_node_type"] = "INCEPTION";
    obb["lvl" + (level + 1) + "_value"] = this.Obj["pro_name"];
    obb["lvl" + (level + 1) + "_type"] = "text";
    obb["leaf_user_cd"] = "INCEPTION" + id;
    obb["leaf_node_type"] = "INCEPTION";
    obb["leaf_value"] = this.Obj["pro_name"];
    obb["module_cd"] = "EMB";
    obb['create_user_id']=this.ebillUser.le_id
    var resp = await this.embHierService.createProjectHierarchyOnAccount(obb);
    if (resp["error"] == false) {
      this.spinner.hide();
      // let proObj = {};
      // proObj["b_acct_id"] = this.b_acct_id;
      // proObj["id"] = id;
      // proObj["project_cd"] = resp["data"];
      // let project_res = this.masterDataService.updateProjectcode(proObj);
      // if (project_res["error"] == false) {
      //   Swal.fire("Success...", "Successfuly Created", "success");
      // } else {
      //   Swal.fire("Error...", "Error While Project Code Creation..", "error");
      // }
      // await this.getAccountProjectHier();
      // await this.reset();
      await this.getAccountProjectHier();
      Swal.fire("Success...", "Successfuly Created", "success");
    } else {
      this.spinner.hide();
      Swal.fire("Error...", "Error While Create A New Node..", "error");
    }
  }

  // users=[]
  // async getAccountUserInfo() {
  //   var obj = new Object();
  //   obj["b_acct_id"] = this.b_acct_id;
  //   obj["module_cd"] = "EMB";
  //   console.log(obj);
  //   var resp = await this.mainServiceMD.getuserformodulerolemapping(
  //     JSON.stringify(obj)
  //   );
  //   if (resp["error"] == false) {
  //     this.users = resp.data;
  //   }
  // }


  allEMBRole = [];
  roles:any = []
  async getAllRoles() {
    var obj:any = new Object();
    obj["b_acct_id"] = this.b_acct_id;
    obj["module_cd"] = "EMB";
    var resp = await this.mainServiceMD.getCurrentMdRole(JSON.stringify(obj));
    console.log(resp);
    if (resp["error"] == false) {
      this.roles = [];
      this.allEMBRole = resp.data;
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]["is_system_role"] == 0) {
          this.roles.push(resp.data[i]);
        }
      }
      console.log(this.roles);
    }
  }


  onFileUpdate(i:any) {
    // this.uploadFile[i]['iconflag']=true

  }


  /*------------------file Uplaod---------------*/
  selectedFile :any = null;
  public imagePath :any;
  async refresh() {
    this.selectedFile = null
    // this.imagePath = ''
    // this.uploader.queue = [];
  }

  onFileUpload(event:any, files:any, i:any) {
    console.log(this.uploader.queue)
    console.log(<File>event.target.files[0])
    if (!<File>event.target.files[0]) {
      this.uploadFile[i]['iconflag'] = false
      this.uploader.queue.splice(i, 1)
      return;
    }
    if (<File>event.target.files[0]) {
      this.uploadFile[i]['iconflag'] = false
    }
    this.selectedFile = <File>event.target.files[0];
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
    };

    console.log(this.selectedFile)

  }
  async docUpload(doc_type:any, i:any) {
    this.spinner.show();
    const doc_obj:any = new Object();
    doc_obj['updateflag'] = false
    if (!this.uploadFile[i]["doc_name"]) {
      Swal.fire("Error...", "Please select document", "warning");
    }
    if (!this.uploadFile[i]["doc_type"]) {
      Swal.fire("Error...", "Please select document type", "warning");
    }
    console.log(this.uploadFile, "this.uploadFile")
    if (this.uploadFile[i]['inc_doc_id']) {
      doc_obj['inc_doc_id'] = this.uploadFile[i]['inc_doc_id']
      doc_obj['updateflag'] = true
    }

    const formData = new FormData();
    formData.append('image', this.selectedFile, this.selectedFile.name);

    console.log(this.uploader.queue, i)
    console.log(this.uploader.queue[i], i)

    var file_name = this.uploader.queue[0].some.name;
    console.log(file_name)
    var temp = file_name.split('.');
    var ext = temp[temp.length - 1];
    doc_obj['b_acct_id'] = this.b_acct_id;
    doc_obj['doc_name'] = file_name;
    doc_obj['inc_id_f'] = 0
    doc_obj['doc_type'] = this.uploadFile[0]['doc_type'];
    const params = JSON.stringify(doc_obj);
    console.log(params)
    this.uploader.queue[0].url = this.httpUrl + '/emb/depricated/estimation/inception/incDocUpload' + params;
    this.uploader.queue[0].upload();
    this.uploader.onCompleteItem = async (item: any, response: any, status: any, headers: any) => {
      var resp = JSON.parse(response)
      if (resp.error == false) {

        this.uploadFile[i]['iconflag'] = true
        this.spinner.hide();
        let resp = await this.inception.getdocslist(JSON.stringify(doc_obj));
        if (resp['error'] == false) {
          this.uploader.queue = []
          let uploaddata = resp['data']
          this.uploadFile.map((x:any, k:any) => {
            if (this.uploadFile[k]) {
              x['inc_doc_id'] = uploaddata[k]['inc_doc_id']
              x['inc_id_f'] = uploaddata[k]['inc_id_f']
              x['iconflag'] = true
            } else {

            }
          })
          console.log(this.uploadFile)
          this.datasource4 = new MatTableDataSource(this.uploadFile)
        }
        else {

        }
        Swal.fire("Success", "File Uploaded Successfully", "success");

        await this.refresh()
      } else {
        this.spinner.hide();
        Swal.fire("Oops", "Error occurred", "error");
      }
    };
  }

  imgURL5:any;
  async viewdocs(element:any) {
    console.log(element)
    var obj:any = new Object()
    obj["b_acct_id"] = this.b_acct_id
    obj["inc_doc_id"] = element['inc_doc_id']
    let doc_name = element['doc_name'].split('\\')
    obj["doc_name"] = doc_name[doc_name.length - 1]
    this.spinner.show();
    let resp = await this.inception.viewdocs(obj)
    this.spinner.hide();
    console.log("this is all document ", resp)
    if (resp) {
      var ext = element['doc_name'].split('.');
      console.log(ext[1])
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg') {
        const unsafeImageUrl = window.URL.createObjectURL(resp);
        this.imgURL5 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
        if (!this.l_flag) {
          $('#inc_docs').modal('show');
        }
      } else {
        let file = new Blob([resp], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    }

  }
  datasource6:any
  async open_docs(element:any) {
    this.imgURL5=''
    let data:any = {}
    data['b_acct_id'] = this.b_acct_id;
    data['inc_id_p'] = element['inc_id_p']
    let resp = await this.inception.getupdateinc(data);
    if (resp['error'] == false) {
      let doc = resp['doc']
      console.log(doc)
      this.datasource6 = new MatTableDataSource(doc)
      console.log(this.datasource6)
    } else {

    }
    $('#view_docs').modal('show');
  }
  /*-------------validation------------------------*/
  confirmflag = false
  async validationproj() {
    console.log(this.Obj)
    if (this.Obj['path_desc'] == undefined || this.Obj['path_desc'] == ' ' || this.Obj['pro_name'] == undefined ||  this.Obj['pro_duration'] == undefined || this.Obj['pro_dur_time'] == undefined || this.Obj['obj'] == undefined || this.Obj['pro_scope'] ==undefined || this.Obj['est_bud'] ==undefined || this.Obj['method'] == undefined) {
      Swal.fire("Error...", "Please fill all the required fields ( Project Info )", "error");
      this.confirmflag = true
      return;
    }
    this.confirmflag = false
  }

  async validationrole() {
    this.role_resp.map((x:any) => {
      if (x['role_name'] == undefined || x['role_resp'] == undefined) {
        Swal.fire("Error...", "Please fill all the required fields ( Roles & Responsibilities )", "error");
        this.confirmflag = true
        return;
      }
      this.confirmflag = false
    })

  }
  async validationdel() {
    this.Deliverables.map((x:any) => {
      if (x['code'] == undefined || x['del_item'] == undefined) {
        Swal.fire("Error...", "Please fill all the required fields ( Deliverables )", "error");
        this.confirmflag = true
        return;
      }
      this.confirmflag = false
    }

    )

  }
  async validationactsch() {
    this.act_sch.map((x:any) => {
      if (x['duration'] == undefined || x['end_date'] == undefined || x['start_date'] == undefined || x['activity'] == undefined || x['code'] == undefined) {
        Swal.fire("Error...", "Please fill all the required fields ( Activity Schedule )", "error");
        this.confirmflag = true
        return;
      }
      this.confirmflag = false
    })

  }

  async validationdoc() {
    this.uploadFile.map((x:any) => {
      if (x['doc_type'] == undefined || x['iconflag']==false) {
        Swal.fire("Error...", "Please fill document type ( Upload Documenrts )", "error");
        this.confirmflag = true
        return;

      }
      this.confirmflag = false
    })
  }

  async durday(i:any){
    if(this.act_sch[i]['start_date']!=undefined && this.act_sch[i]['end_date']!=undefined && (this.act_sch[i]['start_date']) && (this.act_sch[i]['end_date'])){
      let start_date:any=new Date(this.act_sch[i]['start_date'])
      let end_date:any=new Date(this.act_sch[i]['end_date'])
      const diffTime = Math.abs(end_date - start_date);
      this.act_sch[i]['duration']= Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }else{
      this.act_sch[i]['duration']=undefined
    }

  }

}
