import { Component, OnInit } from '@angular/core';
import { MainService as mainServiceMd } from '../../../md/service/main.service';

@Component({
  selector: 'app-account-def-approval',
  templateUrl: './account-def-approval.component.html',
  styleUrls: ['./account-def-approval.component.css']
})



export class AccountDefApprovalComponent implements OnInit {
  constructor(private mainServiceMd:mainServiceMd) { }

  ngOnInit() {
    this.mainServiceMd.selectedModuleForCommonWorkFlow='ACCOUNT'
  }
}
