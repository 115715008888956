<div class="sidebar">
    <div id="sidebar-collapse">

        <div class="admin-block d-flex" style="align-items: center;">
            <div>
                <img src="./../../../assets/img/dash/emd.png" class="img-circle" width="45px" />
            </div>
            <div class="admin-info">
                <div class="font-strong" (click)="home()">{{auth.allSystemModuleObj['EMD']}}</div>
            </div>
        </div>
        <ul class="side-menu metismenu">
            <li [hidden]="this.sidebar['dashbord']">
                <a class="text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/emd/emd-index"><i class="sidebar-item-icon fa fa-th-large"></i>
                    <span class="nav-label"> Dashboard </span>
                </a>
            </li>

            <li [hidden]="this.sidebar['tender_management']">
                <a class="text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/emd/tender"><i class="sidebar-item-icon fa fa-file-text"
                        aria-hidden="true"></i>
                    <span class="nav-label"> Tender </span>
                </a>
            </li>

            <li [hidden]="this.sidebar['vendor_management']">
                <a class="text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/emd/vendor"><i class="sidebar-item-icon fa fa-user"
                        aria-hidden="true"></i>
                    <span class="nav-label"> Vendor </span>
                </a>
            </li>

            <li [hidden]="this.sidebar['payment_management']">
                <a class="text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/emd/payment"><i class="sidebar-item-icon fa fa-money"
                        aria-hidden="true"></i>
                    <span class="nav-label"> Payment </span>
                </a>
            </li>

            <li [hidden]="this.sidebar['assign_tender']">
                <a class="text-decoration-none" routerLinkActive="active" (click)="refreshCol()" routerLink="/emd/assign-tender"><i class="sidebar-item-icon fa fa-handshake-o"
                        aria-hidden="true"></i>
                    <span class="nav-label"> Assign Tender </span>
                </a>
            </li>

            <li>
                <a class="text-decoration-none" (click)="refreshCol()" [hidden]="this.sidebar['emd_refund']" routerLinkActive="active" routerLink="/emd/emd-refund"><i
                        class="sidebar-item-icon fa fa-exchange" aria-hidden="true"></i>
                    <span class="nav-label"> EMD Refund </span>
                </a>
            </li>

            <!-- <li routerLinkActive="active">
                <a class="text-decoration-none" href="javascript:;"><i class="sidebar-item-icon fa fa-archive"></i>
                    <span class="nav-label">Tracking</span><i class="fa fa-angle-left arrow"></i></a>

                <ul class="nav-2-level collapse" routerLinkActive="in">

                    <li>
                        <a class="text-decoration-none" routerLink="/emp-tracking" routerLinkActive="active">Emp Tracking</a>
                    </li>

                </ul>
            </li> -->



            <!-- ////////////////////////////////////////////////////////////////////////////////////////////// -->

            <li routerLinkActive="active">
                <a class="text-decoration-none"  href="#adminCol"  data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="adminCol" [hidden]="this.sidebar['administration']"><i
                        class="sidebar-item-icon fa fa-database"></i>
                    <span class="nav-label">Administration</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="adminCol" data-bs-parent="#sidebar-collapse" routerLinkActive="in">
                  <div id="adminChild">
                    <li>
                        <a class="text-decoration-none" href="#md" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="md" [hidden]="this.sidebar['master_data']">
                            <span class="nav-label">Master Data</span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse" id="md"  data-bs-parent="#adminChild">

                            <li [hidden]="this.sidebar['code_value']">
                                <a class="text-decoration-none" routerLink="/emd/emd-setting/code-value" routerLinkActive="active">Code Value</a>
                            </li>
                            <li [hidden]="this.sidebar['field']">
                                <a class="text-decoration-none" routerLink="/emd/emd-setting/field" routerLinkActive="active">Field</a>
                            </li>


                            <li [hidden]="this.sidebar['hierarchy']">
                                <a class="text-decoration-none" routerLink="/emd/emd-setting/hierarchy" routerLinkActive="active">
                                    Hierachy
                                </a>
                            </li>

                        </ul>
                    </li>

                    <li>
                        <a class="text-decoration-none" href="#sc" data-bs-toggle="collapse" aria-expanded="false"
                        aria-controls="sc" [hidden]="this.sidebar['security']">
                            <span class="nav-label">Security</span><i class="fa fa-angle-left arrow"></i></a>
                        <ul class="nav-3-level collapse"  id="sc"  data-bs-parent="#adminChild">
                            <li [hidden]="this.sidebar['role']">
                                <a class="text-decoration-none" routerLink="/emd/administration/roles" routerLinkActive="active">
                                    Role
                                </a>
                            </li>
                            <li [hidden]="this.sidebar['user']">
                                <a class="text-decoration-none" routerLink="/emd/administration/users" routerLinkActive="active">User</a>
                            </li>
                            <li [hidden]="this.sidebar['data_assignment']">
                                <a class="text-decoration-none" routerLink="/emd/administration/data-assignment" routerLinkActive="active">
                                    Data Assignment
                                </a>
                            </li>
                            <li [hidden]="this.sidebar['work_flow']">
                                <a class="text-decoration-none" routerLink="/emd/emd-setting/workflow" routerLinkActive="active">
                                    Workflow
                                </a>
                            </li>
                        </ul>
                    </li>
                  </div>
                </ul>
            </li>

            <li routerLinkActive="active">
                <a class="text-decoration-none" href="#helpCol"  data-bs-toggle="collapse" aria-expanded="false"
                aria-controls="helpCol"><i class="sidebar-item-icon fa fa-question-circle"></i>
                    <span class="nav-label">Help</span><i class="fa fa-angle-left arrow"></i></a>
                <ul class="nav-2-level collapse" id="helpCol" data-bs-parent="#sidebar-collapse" routerLinkActive="in">
                    <!-- <li>
                        <a class="text-decoration-none" routerLink="/tracking/help/faq"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar20']}}</a>
                    </li>
                    <li>
                        <a class="text-decoration-none" routerLink="/tracking/help/user-manual"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar21']}}</a>
                    </li>
                    <li>
                        <a class="text-decoration-none" routerLink="/tracking/help/tutorial"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar22']}}</a>
                    </li>
                    <li>
                        <a class="text-decoration-none" routerLink="/tracking/help/online-support"
                            routerLinkActive="active">{{auth.allLableShowObjProp[auth.language_cd+'Prop_sidebar23']}}</a>
                    </li> -->
                </ul>
            </li>
        </ul>
    </div>
</div>
