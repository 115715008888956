     <!-- backup -->
<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Inception Report</div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a class="nav-link active"   (click)="list()" href="#tab-7-1" data-toggle="tab"><i
                                    class="fa fa-line-chart"></i>
                                Inception Report 
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"    (click)="createinception()" href="#tab-7-2"
                                data-toggle="tab"><i class="fa fa-plus-circle" aria-hidden="true"> </i>
                                Create Inception Report
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="upload_flag">
                            <a class="nav-link"    (click)="updateinception1()" href="#tab-7-3"
                                data-toggle="tab"><i class="ti-settings" aria-hidden="true"> </i>
                                Update Inception Report
                            </a>
                        </li>
                    </ul>

                    <div  >
                        <div *ngIf="l_flag" id="tab-7-1">
                            <br>
                           
                            <div class="row">
                                <!-- <div class="col-4">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter( $any($event.target).value)" placeholder="Filter">
                                    </mat-form-field>
                                </div> -->

                            </div>


                            <div class="example-container">

                                <table mat-table [dataSource]="dataSource" matSort>

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            ID
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.inc_id_p}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="project_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Name of Project/Work 
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.pro_name}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="duration">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Duration of Project/Work
                                            
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.pro_duration }} {{element.pro_dur_time }} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="est_bud">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Estimated Budget (Rs)
                                            
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.est_bud}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="prop_meth">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Proposed Methodology
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.method}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status 
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.status}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-primary" (click)="open_update(element)" *ngIf="element['status'] == 'GENERATED'">
                                                Update
                                            </button>
                                            <button class="btn btn-primary" (click)="viewStatus(element)" *ngIf="element['status'] != 'GENERATED'"> View Status </button>
                                            <button class="btn btn-primary" (click)="open_docs(element)">View Documents </button>
                                            <button class="btn btn-danger" (click)="deleteinception(element)" *ngIf="element['status'] == 'GENERATED'">
                                                Delete
                                            </button>
                                            <button class="btn btn-primary" (click)="Appr(element)" *ngIf="element['status'] == 'GENERATED' && apprflag == true">
                                                Add Approval
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <!-- <mat-paginator  [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                            </div>

                                    
                        </div>
                        <div *ngIf="create_flag || upload_flag" id="{{tab}}" class="active">
                            <br>
                            <div >
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Hierarchy Info
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    
                                    <div class="col-3 h6-top-margin" >
                                        <h6>
                                            Select Hierarchy<span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3 text-center" >
                                         
                                            <button class="btn btn-primary" (click)="selectProjectPopUp()">Select
                                                Hierarchy</button>
                                            </div>
                                     
                                
                                    
                                    <div class="col-3 h6-top-margin"> <h6> Selected Hierarchy Path </h6></div>
                                    <div class="col-3 text-left">
                                        {{Obj['path_desc']}}
                                    </div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            <!-- Project-Budget Info -->
                                            {{mainService.allLableShowObj[mainService.language_cd+'EMBWORK16']}}
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                              
                                <div class="row">
                                     
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Name of Project/Work<span class="text-danger">*</span>
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                       <input type="text" class="form-control" [(ngModel)]="Obj['pro_name']" placeholder="Enter Name of Project/Work">
                                    </div>
                                
                                     
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Objective of Project/Work
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="text"class="form-control" [(ngModel)]="Obj['obj']" placeholder="Enter Objective of Project/Work">
                                    </div>
                                 
                                </div>
                                <br>
                                <div class="row">
                                    
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Scope of Project/Work
                                        </h6>
                                    </div>
                                    <div class="col-3 h6-top-margin">
                                        <input type="text" class="form-control" [(ngModel)]="Obj['pro_scope']" placeholder="Enter Scope of Project/Work">
                                    </div>
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Estimated Budget (Rs) 
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="number" class="form-control" [(ngModel)]="Obj['est_bud']" placeholder="Enter Estimated Budget">
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Prosposed Methodology
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <ng-select [multiple]="false" placeholder="Select Prosposed Methodology"
                                        [items]="mainService.codeValueTechObj['EMB020']"
                                        bindLabel="value" bindValue="code" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="hierarchy" [(ngModel)]="Obj['method']"> </ng-select>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-3 h6-top-margin">
                                        <h6>
                                            Duration of Project/Work
                                        </h6>
                                    </div>
                                    <div class="col-3">
                                        <input type="number" class="form-control" [(ngModel)]="Obj['pro_duration']" placeholder="Enter Duration of Project/Work">
                                    </div>
                                    <div class="col-2">
                                        <ng-select [multiple]="false" placeholder="Select Duration of Project/Work"
                                        [items]="mainService.codeValueTechObj['EMB027']"
                                        bindLabel="value" bindValue="code" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="hierarchy" [(ngModel)]="Obj['pro_dur_time']"> </ng-select>
                                    </div>
                                </div>
                                <br>
                                
                               
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Roles & Responsibilities
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <fieldset>
                                    <legend>
                                        <h6> Roles & Responsibilities </h6>
                                    </legend>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-primary" (click)="addRowRoleResp()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <!-- <div class="col-4">
                                            <mat-form-field style="margin-left: 2%">
                                                <input matInput (keyup)="applyFilter( $any($event.target).value)" placeholder="Filter">
                                            </mat-form-field>
                                        </div> -->
        
                                    </div>
                                        <table mat-table [dataSource]="datasource1" matSort>
                                                <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header >
                                                    Sr No.
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right" style="width: 15px;">
                                                    <p style="display: block;
                                                    margin: auto;
                                                    width: 10px;">{{i+1}} </p></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="role">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Role
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" style="min-width: 80px;">
                                                    <p style="display: block;
                                                    margin: auto;
                                                    min-width: 110px;"><ng-select [multiple]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS11']}}" [items]="roles" bindLabel="role_name"
                                                    bindValue="role_cd" [selectableGroup]="true" [selectableGroupAsModel]="false" placeholder="{{mainService.allLableShowObj[mainService.language_cd+'EMB-USERS11']}}"
                                                    [closeOnSelect]="true" name="hierarchy" [(ngModel)]="role_resp[i]['role_name']">
                                                </ng-select></p>
                                                </td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="resp">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Responsibility
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="text" class="form-control" placeholder="Enter Responsibility"
                                                    [(ngModel)]="role_resp[i]['role_resp']"  ></td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Action
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    <button class="btn btn-danger" >
                                                        Delete
                                                    </button>
                                                </td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns1;">
                                            </tr>
                                        </table>
                                        <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                                     
                                    <br>

                                </fieldset>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Deliverables 
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                
                                <fieldset>
                                    <legend>
                                        <h6> Deliverables  </h6>
                                    </legend>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-primary" (click)="addRowDel()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    
                                        <table mat-table [dataSource]="datasource2" matSort>
                                                <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header style="width: 10px;">
                                                    Sr No.
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <p style="display: block;
                                                    margin: auto;
                                                    width: 10px;">{{i+1}} </p></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="code">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Code
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" >
                                                    <input type="text" class="form-control" placeholder="Enter Code"
                                                    [(ngModel)]="Deliverables[i]['code']" >
                                                </td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="del_item">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Deliverable Item
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="text" class="form-control" placeholder="Enter Deliverable Item"
                                                    [(ngModel)]="Deliverables[i]['del_item']"  ></td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Action
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    <button class="btn btn-danger"  >
                                                        Delete
                                                    </button>
                                                </td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;">
                                            </tr>
                                        </table>
                                        <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                                     
                                    <br>

                                </fieldset>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Activity Schedule 
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>

                                <fieldset>
                                    <legend>
                                        <h6> Activity Schedule  </h6>
                                    </legend>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-primary" (click)="addRowActSch()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    
                                        <table mat-table [dataSource]="datasource3" matSort>
                                                <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header >
                                                    Sr No.
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <p style="display: block;
                                                    margin: auto;
                                                    width: 10px;">{{i+1}} </p></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="code">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Code
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" >
                                                    <input type="text" class="form-control" placeholder="Enter Code"
                                                    [(ngModel)]="act_sch[i]['code']" >
                                                </td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="activity">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Activity
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="text" class="form-control" placeholder="Enter Activity"
                                                    [(ngModel)]="act_sch[i]['activity']"  ></td>
                                            </ng-container>
                                            <ng-container matColumnDef="start_date">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Start Date
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="date" class="form-control" placeholder="Enter Start Date"
                                                    [(ngModel)]="act_sch[i]['start_date']"  (change)="durday(i)"></td>
                                            </ng-container>
                                            <ng-container matColumnDef="end_date">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    End Date
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="date" class="form-control" placeholder="Enter End Date"
                                                    [(ngModel)]="act_sch[i]['end_date']"  (change)="durday(i)"></td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="duration">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Duration (Days)
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <!-- <input type="number" class="form-control" placeholder="Enter Duration"
                                                    [(ngModel)]="act_sch[i]['duration']"  > -->
                                                    {{act_sch[i]['duration']}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="comment">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                Comment
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <input type="text" class="form-control" placeholder="Enter Comment"
                                                    [(ngModel)]="act_sch[i]['comment']"  >
                                                </td>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Action
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                                    <!-- <button class="btn btn-danger" (click)="deleteRowActSch(i)">
                                                        Delete
                                                    </button> -->
                                                    <button class="btn btn-danger">
                                                        Delete
                                                    </button>
                                                </td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns3;">
                                            </tr>
                                        </table>
                                        <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                                     
                                    <br>

                                </fieldset>
                                <br>
                                <div class="row">
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                    <div class="col-2 text-center">
                                        <h6 id="h" style="color: brown; min-height: 20px;">
                                            Upload Documents
                                        </h6>
                                    </div>
                                    <div class="col-5">
                                        <hr class="hrline">
                                    </div>
                                </div>
                                <br>
                                <fieldset>
                                    <legend>
                                        <h6>Upload Documents  </h6>
                                    </legend>
                                    <div class="row">
                                        <div class="col-12 text-right">
                                            <button class="btn btn-primary" (click)="addRowFiles()"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <br>
                                    
                                        <table mat-table [dataSource]="datasource4" matSort>
                                                <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header >
                                                    Sr No.
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <p style="display: block;
                                                    margin: auto;
                                                    width: 10px;">{{i+1}} </p></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="doc_type">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Document Type 
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" >
                                                     <ng-select [multiple]="false" placeholder="Select Document Type"
                                                    [items]="mainService.codeValueTechObj['EMB028']"
                                                    bindLabel="value" bindValue="code" [selectableGroup]="true"
                                                    [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                                    name="hierarchy" [(ngModel)]="uploadFile[i]['doc_type']"> </ng-select> 
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="file_name">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    File Name
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" style="display: flex;">
                                                    <!-- <input type="file" class="form-control" placeholder="Select File"
                                                    [(ngModel)]="uploadFile[i]['file_name']" style="width:467px" (change)="onFileUpload($event,file.files)"> -->
                                                    <input #file type="file" name="pimage" ng2FileSelect
                                                        [uploader]="uploader" class="fileupload"
                                                        (change)="onFileUpload($event,file.files,i)" [(ngModel)]="uploadFile[i]['doc_name']" >
                                                     <h1 *ngIf="!uploadFile[i]['iconflag'] && uploadFile[i]['doc_name']!=null"><i class="fa fa-times text-danger" aria-hidden="true"></i>  </h1 > <h1 *ngIf="uploadFile[i]['iconflag']"> <i class="fa fa-check text-success" aria-hidden="true"></i> </h1>
                                                    <button  *ngIf="uploadFile[i]['iconflag']"   class=" btn1 btn-primary" (click)="viewdocs(uploadFile[i])" style="margin-right:4px;"> View</button>
                                                    <button class="btn1 btn-primary" [disabled]="uploadFile[i]['iconflag']" (click)="docUpload(uploadFile[i]['doc_type'],i)" >
                                                        Upload
                                                    </button>
                                                </td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                                    Action
                                                </th>
                                                <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                                                    <button class="btn btn-danger" (click)="deleteRowFiles(i)">
                                                        Delete
                                                    </button>
                                                    
                                                </td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns4;">
                                            </tr>
                                        </table>
                                        <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
                                     
                                    <br>

                                </fieldset>


                                <br>
                            </div>
                            </div>
                          
                           <!-- pop-up for the project herarchi -->
                           <div class="modal" id="ACTHIER">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                        
                                    <!-- Modal Header -->
                                    <div class="modal-header">
                                        <h4 class="modal-title">Select Hierarchy</h4>
                                        <button type="button" class="close"data-bs-dismiss="modal" (click)="close()">&times;</button>
                                    </div>
                        
                                    <div class="modal-body">
                                        <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                                            <div class="col-3"></div>
                                            <div class="col-3" *ngIf="i==0">
                                                <h6 style="margin-left: 2%;">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy16']}} <span style="color: red;"> *</span></h6>
                                                <!-- Select Node : -->
                                            </div>
                                            <div class="col-3" *ngIf="i!=0"></div>
                                            <div class="col-1">
                        
                                                <span *ngIf="item['level']">{{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy17']}} </span>{{item['level']}}
                                                <!-- Level -  -->
                                            </div>
                                            <div class="col-3">
                                                <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd"
                                                    [multiple]="false"
                                                    placeholder=" {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy18']}}"
                                                    [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true"
                                                    (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                                                    [closeOnSelect]="true">
                                                </ng-select>
                                                <!-- Select  Node -->
                                            </div>
                                            <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                                                <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                                        aria-hidden="true"></i></button> &nbsp;&nbsp;
                                                <button (click)="remove(item,i)" class="btn btn-danger">
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            </div>
                                            <div class="col-3"></div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-3"></div>
                                            <div class="col-3 ">
                                                <h6 style="margin-left: 2%;"> {{mainService.allLableShowObj[mainService.language_cd+'EMB Hierarchy19']}} <span style="color: red;">*</span>
                                                    <!-- Current Node Path : -->
                                                </h6>
                                            </div>
                                            <div class="col-6">
                                                {{Obj['path_desc']}}
                                            </div>
                        
                                        </div>
                                        <br>
                        
                        
                                        <!-- Modal footer -->
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" (click)="submit_act()">Submit</button>
                                            <button type="button" class="btn btn-danger"data-bs-dismiss="modal" (click)="close()">CLOSE</button>
                                        </div>
                        
                                    </div>
                                </div>
                            </div> 
                        </div>
                                
                            </div> 
                            <br>
                            <div class="row">
                                <div class="col-12 text-center" *ngIf="create_flag">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="submit()">Submit</button>
                                </div>
                            </div>
                            <br>
                            <div class="row" *ngIf="upload_flag">
                                <div class="col-12 text-center">
                                    <button mat-button mat-raised-button class="btn btn-primary"
                                        (click)="updateinception()">Update</button>
                                </div>
                            </div>
                        <!-- </div>
                        <br>
                    </div> -->
                </div>  
                <br> 
            </div> 
   </div>
   </div> 
   </div> 
   <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "red" type = "ball-circus"  ><p style="color: green" > Loading Please Wait .... </p></ngx-spinner>
   <!------------------------workflow------------------------------------->
   <div class="modal" id="myModal2">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Approval</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-4 text-right">
                        <h6>Select Forwarded To :</h6>
                    </div>
                    <div class="col-4">
                        <ng-select [items]="ApprovalUserService.workuser" bindLabel="desc" bindValue="user_id_appr" [multiple]="false" placeholder="Select User" [(ngModel)]="forwarded_user_id" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true" name="work_order_id">
                        </ng-select>
                    </div>


                </div>
                <br>
                <div class="row">
                    <div class="col-4 text-right">
                        <h6>Remark :</h6>
                    </div>
                    <div class="col-4">
                        <textarea style="width: 100%; height: 80px;" id="re" name="re" [(ngModel)]="appr_remark" placeholder="Enter Your Remark" class="form-control"></textarea>

                    </div>


                </div>
                <br>

                <div>
                    <table class="table table-bordered" style="width: 90%; margin-left: 5%;overflow-x:scroll;scroll-margin-block: 1; ">
                        <thead>
                            <tr style="background-color:  white;">
                                <th style="width: 20%;">S No.</th>
                                <th style="width: 20%;">Decription</th>



                                <th style="width: 60%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ln of undertakings;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ln['desc']}}</td>
                                <td style="width: 60%;">
                                    <input type="checkbox" [(ngModel)]="ln['check']" style="color: green;">
                                    <!--  <button class="btn btn-success" (click)="deleteLine(i)"><i class="fa fa-check"
                                        style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i></button>
                                <button class="btn btn-danger" (click)="copy(ln)">
                                     <i class="fa fa-times"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> 
                                           
                                        </button> -->
                                    <!-- <button class="btn btn-warning" (click)="paste(i)"> -->
                                    <!-- <i class="fas fa-paste"
                                            style="font-size: 20px;margin-left: 10%;" aria-hidden="true"></i> -->

                                    <!-- </button>  -->
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <br>
                <div class="row" style="border: black;">
                    <div class="col-4 text-right" >
                        <input type="checkbox" [(ngModel)]="message_flag" id="vehicle1" name="vehicle1" value="Bike">
                    </div>
                    <div class="col-4">
                        Notify Them
                    </div>
                </div>
                <br>
                <div class="row" *ngIf="ApprovalUserService.vendorflag==false">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Submit

                        </button>
                    </div>
                </div>
                <br>
                <!-- <div *ngIf="vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <h6>OR</h6>
                    </div>
                </div> -->
                <div *ngIf="ApprovalUserService.vendorflag==true" class="row">
                    <div class="col-12 text-center">
                        <button class="btn btn-primary"data-bs-dismiss="modal" (click)="Approvedapproval()">Forward To
                            Vendor</button>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Status</h4>
                <button type="button" class="close"data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">

                <div class="container">
                    <a *ngFor="let ob of statusArr;let i=index">
                        <div class="step completed" *ngIf="ob.status=='APPROVED'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="barblue" style="color: blue;margin-left: 40%;" *ngIf="i <statusArr.length-1"></div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='REJECTED' || ob.status=='REVOKED' || ob.status == 'SYSTEM REJECTED'">
                            <div class="v-stepper">
                                <div class="circles"></div>

                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <!-- active -->
                        <div class="step" *ngIf="ob.status=='UNDERAPPROVAL'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                        <div class="step" *ngIf="ob.status=='PENDING'">
                            <div class="v-stepper">
                                <div class="circle"></div>
                                <div class="bar" style="color: black;margin-left: 40%;" *ngIf="i <statusArr.length-1">
                                </div>
                            </div>

                            <div class="content">
                                {{i+1}} - {{this.mainService.roleCodeToRoleDescription[ob.role_cd]}} - {{ob.user_name}} - {{ob.status}}

                                <!-- {{levelOfApproval[ob.level_of_approval]['designation_code']}} -
                                {{levelOfApproval[ob.level_of_approval]['section_code']}} -->
                            </div>
                        </div>
                    </a>


                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-success"data-bs-dismiss="modal" (click)="certificate()">Download
                        Certificate</button> -->

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="inc_docs" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Inception document</h6>
          </h5>
          <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
  
            
  
  
          </div>
  
  
  
  
          <div class="row justify-content-center" >
  
            <img [src]="imgURL5" height="500px" width="700px" />
  
  
          </div>
  
  
  
  
  
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger"data-bs-dismiss="modal">Close</button>
  
        </div>
      </div>
    </div>
  </div>
<div class="modal fade" id="view_docs" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 70%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            <h6>Inception documents</h6>
          </h5>
          <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           
          <div class="row">  
          <table mat-table [dataSource]="datasource6" matSort>
            <!-- {{datasource6 | json}} -->
            <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header >
                Sr No.
            </th>
            <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                <p style="display: block;
                margin: auto;
                width: 10px;">{{i+1}} </p></td>
        </ng-container>

        <ng-container matColumnDef="doc_type">
            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                Document Type 
            </th>
            <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" >
                {{element['doc_type']}}
            </td>
        </ng-container>
        <ng-container matColumnDef="doc_name">
            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                Document Name
            </th>
            <td mat-cell flex-stretch *matCellDef="let element  let i=index" class="right" >
               {{element['doc_name']}}
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                Action
            </th>
            <td mat-cell flex-stretch *matCellDef="let element let i=index" class="right">
                <button class="btn btn-primary" (click)="viewdocs(element)">
                    View
                </button>
                
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns6"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns6;">
        </tr>
    </table>
  
  
  
  </div>
  <br>
  <div class="row justify-content-center" *ngIf="imgURL5">
  
    <img [src]="imgURL5" height="550px" width="800px" style="border:2px solid black;padding:4px" />


</div>
  
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger"data-bs-dismiss="modal">Close</button>
  
        </div>
      </div>
    </div>
  </div>




