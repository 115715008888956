import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { ZoneService } from '../service/zone.service';
import { EmpTrackingService } from '../service/emp-tracking.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { ThemeService } from 'ng2-charts';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
// import {
//   TravelMarker,
//   TravelMarkerOptions,
//   TravelData,
//   TravelEvents,
//   EventType,
// } from 'travel-marker'
import { SocketHandleService } from '../service/socket-handle.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';



declare var $: any;
declare const google: any;

@Component({
  selector: 'app-self-emp-tracking',
  templateUrl: './self-emp-tracking.component.html',
  styleUrls: ['./self-emp-tracking.component.css']
})
export class SelfEmpTrackingComponent implements OnInit {
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  allzone: any = [];
  userlist_z: any = {};
  location_area: any;
  Obj: any = {};
  Date_Obj: any = {}
  zone_arr1: any;
  selectObj: any = {}
  // options: any;

  options:any = {
  } as Options;

  zone_arr: {
    zone_id: number,
  }[] = []
  user_arr: {
    zone_cd: number,
    user_id: number,

  }[] = []
  all_user_arr: {
    zone_cd: number,
    user_id: number,

  }[] = []
  paths: any = []
  zoom: any;
  lat: number = 26.850000;
  lng: number = 80.949997;
  mapType = 'satellite';
  markers: {
    lat: String,
    lng: string,
    label: string
  }[] = []
  start: any = {
    lat: String,
    lng: String,
    label: String,
    currentdate: Date,
    currenttime: Date,
    location: String
  };
  end: {
    lat: String,
    lng: string,
    label: string,
    currentdate: Date,
    currenttime: Date,
    location: string
  }[] = []
  usertrack: any = []
  myDate: Date = new Date();

  specfic_zone_key: {
    all_check: string
  }[] = []
  time_Obj: any = {}
  time_hide: boolean = true;
  live: boolean = true;
  Offline: boolean = false;
  intialMarker: any;
  i: any
  center!: google.maps.LatLngLiteral;
  markerPositions: google.maps.LatLngLiteral[] = [];
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  @ViewChild("placesRef", { static: true }) placesRef !: GooglePlaceDirective;
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;
  addressMarker: any;
  private map: any;
  constructor(public router: Router,
    public zone: ZoneService,
    private spinner: NgxSpinnerService,
    private employeeservice: EmpTrackingService,
    private datePipe: DatePipe,
    private socketServer: SocketHandleService,
    private sanitizer: DomSanitizer
  ) {
  }
  selectedIndex = [
    {
      check: 0
    }
  ]
  check_socket_data: any = []
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id
    this.user_id = this.erpUser.le_id
    this.getzonedata();
    this.all_user_track();
    // this.view_on_map();
    // this.toggleVisibility2(e)


    // console.log(new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false }));
    this.center = {
      lat: this.lat, lng: this.lng
    };



  }
  address_marker: any = []
  // map: any;

  public handleAddressChange(address: any) {

    if (this.address_marker.length > 0) {
      for (let i = 0; i < this.address_marker.length; i++) {
        this.address_marker[i].setMap(null);
      }
    }
    this.lat = address.geometry.location.lat()
    this.lng = address.geometry.location.lng()
    console.log("lat long", this.lat, this.lng)
    const mapOptions = {
      center: { lat: this.lat, lng: this.lng },
      zoom: 10
    };

    this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

    const newMarker = new google.maps.Marker({
      position: { lat: this.lat, lng: this.lng },
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: {
        url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
      },
      title: address.name,
      // label: address.name

    });


    this.markerPositions.push(newMarker);

  }

  // addMarker(event: google.maps.MapMouseEvent){
  //   console.log("event--->",  event)
  //   console.log("eventLat--->", event.latLng!.toJSON())
  //   console.log("markerPositions2--->", this.markerPositions)

  //   this.markerPositions.push(event.latLng!.toJSON());
  // }

  marker!: MapMarker;

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow != undefined)
      this.infoWindow.open(marker);
  }

  dashboard1() {
    this.router.navigate(['/tracking/tracking-index'])

  }
  onPolyClick($event: any) {
    //console.log("polyClicked: ", $event.latLng.lat(), $event.latLng.lng());
  }
  ////////get zone data//
  async getzonedata() {

    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    // obj['zone_cd'] = this.accessed_zone;
    this.spinner.show()
    var resp: any = await this.zone.getLocationData(JSON.stringify(obj));
    // console.log(resp, 'list')
    if (resp['error'] == false) {
      this.allzone = resp['data']
      let color = ['#FF0000', '#0000FF', '#006400', '#FFA500', '#A020F0', '#008080', '#B7410E']
      for (let i = 0; i < this.allzone.length; i++) {
        this.allzone[i]['color'] = color[i]
      }
      this.spinner.hide();
    } else {
      this.spinner.hide()
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }

  }

  ////////////////////live tracking mangement system////////////

  // for (let i = 0; i < this.allzone.length; i++) {
  //   if (this.usertrack[j][0].ZONE_CD == this.allzone[i].zone_cd) {
  //     this.usertrack[j]['color'] = this.allzone[i]['color']
  //   }
  // }

  temp = []
  user_tracking_arr: any = {}
  live_all_user_deatil: any = {}
  live_start_marker: any = {}
  live_remover_start_marker: any = {}
  intial_remover_start_marker: any = {}
  subscription: any
  live_color_detect: any;
  intial_point_user: any = {}

  async Go_live() {
    this.Offline = true;
    this.live = false;
    this.reset();
    this.end = [];
    // console.log(this.user_arr)
  }
  async live_track() {
    if (this.user_arr.length <= 0) {
      Swal.fire('info', 'Please Select at least one user', 'info')
      return
    }
    this.loctionData = []
    this.end = [];

    for (const property of Object.keys(this.live_remover_start_marker)) {
      if (this.live_remover_start_marker[property].length > 0) {
        this.live_remover_start_marker[property][this.live_remover_start_marker[property].length - 1].setMap(null)
      }
    }
    for (const property of Object.keys(this.intial_remover_start_marker)) {
      if (this.intial_remover_start_marker[property].length > 0) {

        for (let i = 0; i < this.intial_remover_start_marker[property].length; i++) {
          this.intial_remover_start_marker[property][i].setMap(null)
        }

      }
    }
    this.ueser_arrrrr();
    await this.get_user_image();
    this.live_color_detect = '#38f53f'
    this.reset();
    this.subscription = this.socketServer.listen('locations').subscribe(data => {
      // console.log(data)
      this.check_socket_data.push(data);
      // console.log(this.check_socket_data);
      this.call_specfic();
    })
  }
  async Go_offline() {
    this.Offline = false;
    this.live = true;
    this.reset();
    this.loctionData = [];
    this.subscription.unsubscribe()
    for (const property of Object.keys(this.live_remover_start_marker)) {
      if (this.live_remover_start_marker[property].length > 0) {
        this.live_remover_start_marker[property][this.live_remover_start_marker[property].length - 1].setMap(null)
      }
    }
    for (const property of Object.keys(this.intial_remover_start_marker)) {
      if (this.intial_remover_start_marker[property].length > 0) {

        for (let i = 0; i < this.intial_remover_start_marker[property].length; i++) {
          this.intial_remover_start_marker[property][i].setMap(null)
        }

      }
    }


  }

  ueser_arrrrr() {
    this.user_tracking_arr = {};
    this.live_start_marker = {};
    this.live_remover_start_marker = {};
    this.live_all_user_deatil = {};
    this.intial_point_user = {};
    this.intial_remover_start_marker = {};
    for (let i = 0; i < this.user_arr.length; i++) {
      this.user_tracking_arr[this.user_arr[i]['user_id']] = [];
      this.live_start_marker[this.user_arr[i]['user_id']] = [];
      this.live_remover_start_marker[this.user_arr[i]['user_id']] = [];
      this.live_all_user_deatil[this.user_arr[i]['user_id']] = [];
      this.intial_point_user[this.user_arr[i]['user_id']] = [];
      this.intial_remover_start_marker[this.user_arr[i]['user_id']] = [];
    }
  }


  call_specfic() {
    this.start = []
    for (const property of Object.keys(this.user_tracking_arr)) {
      // console.log(`${property}`, this.check_socket_data[this.check_socket_data.length - 1]['user_id']);

      if (`${property}` == this.check_socket_data[this.check_socket_data.length - 1]['user_id']) {

        // this.user_tracking_arr[property].push([this.check_socket_data[this.check_socket_data.length - 1]['latitude'],
        // this.check_socket_data[this.check_socket_data.length - 1]['longitude']])

        this.live_start_marker[property] = []

        this.live_start_marker[property].push({
          lat: this.check_socket_data[this.check_socket_data.length - 1]['latitude'],
          lng: this.check_socket_data[this.check_socket_data.length - 1]['longitude'],
          label: this.check_socket_data[this.check_socket_data.length - 1]['user_id'],
          currenttime: this.check_socket_data[this.check_socket_data.length - 1]['currenttime'],
          currentdate: this.check_socket_data[this.check_socket_data.length - 1]['current_date'].split('T')[0],
          location: this.check_socket_data[this.check_socket_data.length - 1]['emp_name']

        })


        // this.live_all_user_deatil[property].push({
        //   lat: this.check_socket_data[this.check_socket_data.length - 1]['latitude'],
        //   lng: this.check_socket_data[this.check_socket_data.length - 1]['longitude'],
        //   zone_cd: this.check_socket_data[this.check_socket_data.length - 1]['zone_cd'],
        //   user_id: this.check_socket_data[this.check_socket_data.length - 1]['user_id'],
        //   user_status: this.check_socket_data[this.check_socket_data.length - 1]['user_status'],
        //   currenttime: this.check_socket_data[this.check_socket_data.length - 1]['currenttime'],
        // })


        // this.intial_point_user[property].push({
        //   lat: this.check_socket_data[this.check_socket_data.length - 1]['latitude'],
        //   lng: this.check_socket_data[this.check_socket_data.length - 1]['longitude'],
        //   label: this.check_socket_data[this.check_socket_data.length - 1]['user_id'],
        //   currenttime: this.check_socket_data[this.check_socket_data.length - 1]['currenttime'],
        //   currentdate: this.check_socket_data[this.check_socket_data.length - 1]['current_date'].split('T')[0],
        //   location: this.check_socket_data[this.check_socket_data.length - 1]['emp_name']

        // })
        /////////////////////////////////////////////////intial marker//////////
        // const start = new google.maps.LatLng(this.intial_point_user[property][0]['lat'], this.intial_point_user[property][0]['lng']);
        // this.intialMarker = new google.maps.Marker({
        //   position: start,
        //   map: this.map,
        //   label: this.intial_point_user[property][0]['location'] + " (" + this.intial_point_user[property][0]['label'] + ") ",
        //   title: this.intial_point_user[property][0]['location'] + " (" + this.intial_point_user[property][0]['label'] + ") " + "Date : " + this.intial_point_user[property][0]['currentdate'] + " , " + "Time : " + this.intial_point_user[property][0]['currenttime'],
        //   // icon: { url: this.live_start_marker[property][0]['img']['changingThisBreaksApplicationSecurity'], scaledSize: { height: 40, width: 40 } }
        // });
        // this.intial_remover_start_marker[property].push(this.intialMarker)

        // ////////////////////////////////////////////////////////////////////////////marker start////////////////////
        // console.log(this.live_start_marker);
        for (const property of Object.keys(this.live_start_marker)) {
          for (let i = 0; i < this.img_url.length; i++) {
            if (`${property}` == this.img_url[i]['user_id']) {
              if (this.live_remover_start_marker[property].length > 0) {
                this.live_remover_start_marker[property][this.live_remover_start_marker[property].length - 1].setMap(null)
              }
              if (this.live_start_marker[property].length > 0) {
                this.live_start_marker[property][0]['img'] = this.img_url[i]['img_url']
                const start = new google.maps.LatLng(this.live_start_marker[property][0]['lat'], this.live_start_marker[property][0]['lng']);
                this.startMarker = new google.maps.Marker({
                  position: start,
                  map: this.map,
                  label: this.live_start_marker[property][0]['location'],
                  // animation: google.maps.Animation.DROP,
                  title: this.live_start_marker[property][0]['location'] + " (" + this.live_start_marker[property][0]['label'] + ") " + "Date : " + this.live_start_marker[property][0]['currentdate'] + " , " + "Time : " + this.live_start_marker[property][0]['currenttime'],
                  icon: { url: this.live_start_marker[property][0]['img']['changingThisBreaksApplicationSecurity'], scaledSize: { height: 40, width: 40 } }
                });
                this.live_remover_start_marker[property] = []
                this.live_remover_start_marker[property].push(this.startMarker)
                // console.log(this.live_remover_start_marker)
              } else {
                break
              }
            }
          }
        }


        ////////////////////////////////////////////end mareker/////////////////////
        // console.log(this.live_start_marker);
        // console.log(this.live_all_user_deatil);
        // console.log(this.intial_point_user)


      }
    }


    // /////////////for creating polyline//////////////////////////
    // console.log(this.user_tracking_arr)
    // for (const property of Object.keys(this.user_tracking_arr)) {
    //   this.loctionData.push(this.user_tracking_arr[property])

    // }
    // this.temp.push([this.check_socket_data[this.check_socket_data.length - 1]['latitude'], this.check_socket_data[this.check_socket_data.length - 1]['longitude']]);
    // this.loctionData.push(arr)
    // this.draw_socket_polyline();
    ////////////////////////////////////////
  }
  locationArray = []

  // draw_socket_polyline() {

  //   // this.loctionData = []
  //   // console.log(this.loctionData);
  //   for (let i = 0; i < this.loctionData.length; i++) {
  //     let element = this.loctionData[i];
  //     this.locationArray = element.map((l) => new google.maps.LatLng(l[0], l[1]));
  //     this.line = new google.maps.Polyline({
  //       strokeOpacity: 5.0,
  //       // strokeOpacity:'#ffffff',
  //       strokeColor: '#0d0101',
  //       // this.usertrack[i]['color']
  //       path: [],
  //       map: this.map,
  //     });
  //     // Polyline.setOptions({ strokeColor: '#ffffff' })
  //     this.locationArray.forEach((l) => this.line.getPath().push(l));
  //     // console.log(element)
  //     // this.initRoute();
  //     // this.play();
  //     this.line2.push(this.line)
  //   }



  // }

  /////////////////////////////end live tracking managment system

  // ///////////////get user by zone
  binding1: any;
  toggleVisibility2(e: any, i: any) {
    for (let i of Object.keys(this.selectObj)) {
      this.selectObj[i] = false
    }
    if (e.target.checked == true) {
      this.user_arr = []
      this.start = [];
      this.end = [];
      this.usertrack = [];
      this.zone_arr = []
      this.reset();
      for (let i = 0; i < this.userlist_z.length; i++) {
        this.all_user_arr.push({
          zone_cd: this.userlist_z[i].zone_cd,
          user_id: this.userlist_z[i].user_id,
        })
      }

      this.specfic_zone_key.push({
        all_check: 'CHECKED'
      })
      this.Date_Obj['To_date'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')

      this.Date_Obj['From_date'] = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')

      this.time_Obj['to_time'] = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
      this.time_Obj['From_time'] = new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false })
      this.chektime();

    } else {
      this.usertrack = []
      this.start = []
      this.end = []
      this.all_user_arr = []
      this.zone_arr = []
      this.specfic_zone_key = []

    }
  }



  toggleVisibilitySub(e: any, i: any) {
    // this.start = []
    // this.end = []
    this.Date_Obj = {}
    this.time_Obj = {}
    // this.usertrack = []
    this.binding1 = false
    // console.log(this.user_arr)
    this.all_user_arr = []
    if (e.target.checked == true) {
      this.selectObj[i] = e.target.checked
      // console.log(this.userlist_z)
      this.user_arr.push({
        zone_cd: this.userlist_z[i].zone_cd,
        user_id: this.userlist_z[i].user_id,
      })
      // console.log(this.user_arr)
    } else {

      this.user_arr = this.user_arr.filter(object => {
        return object.user_id !== this.userlist_z[i].user_id;
      });

    }
  }











  async all_user_track() {

    const obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show()
    // this.zone_arr = [];
    var res: any = await this.employeeservice.getalluser(JSON.stringify(obj));
    if (res['error'] == true) {
      Swal.fire('error', 'server side error on userid', 'error');
      return
    } else {
      this.userlist_z = res['data']

    }
  }

  chektime() {
    // console.log(this.Date_Obj)
    if (this.Date_Obj['From_date'] == this.Date_Obj['To_date']) {
      this.time_hide = true
    } else {
      this.time_hide = false
    }
  }

  img_url: {
    user_id: any,
    img_url: any
  }[] = []
  async get_user_image() {
    // console.log(this.user_arr)
    let user_arr: {
      user_id: number
    }[] = []
    if (this.specfic_zone_key.length > 0) {
      for (let i = 0; i < this.all_user_arr.length; i++) {
        user_arr.push({
          user_id: this.all_user_arr[i]['user_id']
        })
      }
    } else {
      for (let i = 0; i < this.user_arr.length; i++) {
        user_arr.push({
          user_id: this.user_arr[i]['user_id']
        })
      }
    }
    console.log(user_arr);
    let obj: any = {}
      ;

    for (let i = 0; i < user_arr.length; i++) {
      obj['user_arr'] = user_arr[i]['user_id']
      this.spinner.show();
      let resp: any = await this.employeeservice.getUploaddocumentrimg(obj)
      // console.log(resp);
      const unsafeImageUrl = window.URL.createObjectURL(resp);
      let imgURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeImageUrl);
      this.spinner.hide();
      this.img_url.push({
        user_id: user_arr[i]['user_id'],
        img_url: imgURL1
      })
    }
    // console.log(this.img_url)

  }



  async Apply_track() {
    this.get_user_image()
    // console.log(this.Date_Obj)
    if (Object.keys(this.Date_Obj).length <= 0) {
      return
    }

    if (!this.Date_Obj['From_date']) {
      Swal.fire('info', 'please select..... From date', 'info');
      return
    }

    if (!this.Date_Obj['To_date']) {
      Swal.fire('info', 'please select.... To date ', 'info');
      return
    }
    this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    let a: any = this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    if (this.Date_Obj['To_date'] > a) {
      Swal.fire('info', 'please select To date less then or equal to current date', 'info');
      return
    }
    if (this.Date_Obj['From_date'] == this.Date_Obj['To_date']) {
      if (!this.time_Obj['From_time']) {
        Swal.fire('info', 'please select..... From time', 'info');
        return
      }
      if (!this.time_Obj['to_time']) {
        Swal.fire('info', 'please select.... To Time', 'info');
        return
      }
    }
    this.time_Obj['to_time'] = this.time_Obj['to_time'] + ':00'
    this.time_Obj['From_time'] = this.time_Obj['From_time'] + ':00'
    if (!(this.Date_Obj['From_date'] == this.Date_Obj['To_date'])) {
      this.time_Obj = {}
      return
    }
    this.markers = []
    let obj: any = {}
    let user_arr: any = {}
    for (let i = 0; i < this.user_arr.length; i++) {
      user_arr[i] = this.user_arr[i]['user_id']
    }
    for (let i = 0; i < this.all_user_arr.length; i++) {
      user_arr[i] = this.all_user_arr[i]['user_id']
    }
    this.start = [];
    this.end = [];
    obj['b_acct_id'] = this.b_acct_id
    obj['date'] = this.Date_Obj
    obj['time'] = this.time_Obj
    obj['user_id'] = user_arr
    // console.log(obj);
    if (Object.keys(obj['user_id']).length <= 0) {
      Swal.fire('info', 'please select at least one user', 'info');
      return
    }
    this.spinner.show();
    var resp: any = await this.employeeservice.getuser_coordinates(JSON.stringify(obj));
    if (resp['error'] == false) {

      // if (Object.keys(obj['user_id']).length == 1) {
      //   this.usertrack[0] = resp['data']
      // } else {

      this.usertrack = resp['data']
      if (this.usertrack.length <= 0) {
        this.reset();
        Swal.fire('info', 'No data found of selected user on selected date and time', 'info')
        this.spinner.hide();
        return this.user_arr;
      }

      // }

      for (let i = 0; i < this.usertrack.length; i++) {
        if (this.usertrack[i].length == 0) {
          this.usertrack.splice(i, 1)
        }
      }
      // console.log(this.usertrack);
      for (let i = 0; i < this.usertrack.length; i++) {
        this.start.push({
          lat: this.usertrack[i][0]['latitude'],
          lng: this.usertrack[i][0]['longitude'],
          label: this.usertrack[i][0]['user_id'],
          currenttime: this.usertrack[i][0]['currenttime'],
          currentdate: this.usertrack[i][0]['currentdate'].split('T')[0],
          location: this.usertrack[i][0]['emp_name']

        })

        this.end.push({
          lat: this.usertrack[i][this.usertrack[i].length - 1]['latitude'],
          lng: this.usertrack[i][this.usertrack[i].length - 1]['longitude'],
          label: 'End' + this.usertrack[i][this.usertrack[i].length - 1]['user_id'],
          currenttime: this.usertrack[i][this.usertrack[i].length - 1]['currenttime'],
          currentdate: this.usertrack[i][this.usertrack[i].length - 1]['currentdate'].split('T')[0],
          location: this.usertrack[i][this.usertrack[i].length - 1]['emp_name']
        })
      }
      // console.log(this.allzone)
      // for (let j = 0; j < this.usertrack.length; j++) {
      //   let randNumber = '#' + Math.floor((Math.random() * 10000) + 1);
      //   this.usertrack[j]['color'] = randNumber
      // }
      // console.log(this.usertrack)
      this.reset();
      this.mockDirections();

      this.locationArray = [];

      // if (this.specfic_zone_key[0]['all_check'] == 'CHECKED') {
      //   this.zone_arr = [];
      //   this.specfic_zone_key = []
      // }

      this.spinner.hide();



    } else {
      Swal.fire('Error', 'Error While Getting Data', 'error')
    }
    return

  }



  //////////////////////////////new implementation////////////////////
  line: any;
  directionsService: any;
  // marker: TravelMarker = null;
  loctionData: any = []
  // speedMultiplier to control animation speed
  speedMultiplier = 1;

  onMapReady(map: any) {
    // console.log(map);
    this.map = map;

    // this.calcRoute();
    // this.mockDirections();
    // this.initEvents();
  }

  // get locations from direction service

  // mock directions api
  // locationArray = []
  line2: any = []
  startMarker: any;
  endmarker: any;
  removemarker: any = [];
  mapMarkers: any = [];
  // infoWindow: google.maps.InfoWindow;
  // marker: google.maps.Marker;
  mockDirections() {
    // this.loctionData=[]
    let arr2 = []
    let locationArray = []
    this.loctionData = []
    // console.log(this.usertrack.length)
    for (let j = 0; j < this.usertrack.length; j++) {
      let temp = []
      for (let i = 0; i < this.usertrack[j].length; i++) {
        temp.push([this.usertrack[j][i]['latitude'], this.usertrack[j][i]['longitude']])
      }
      // console.log(temp)
      this.loctionData.push(temp)
    }

    for (let i = 0; i < this.loctionData.length; i++) {
      let element = this.loctionData[i];
      locationArray = element.map((l: any) => new google.maps.LatLng(l[0], l[1]));
      // console.log(locationArray);
      // console.log(this.usertrack[i]['color'])
      this.line = new google.maps.Polyline({
        strokeOpacity: 5.0,
        // strokeOpacity:'#ffffff',
        strokeColor: '#FF0000',
        path: [],
        map: this.map,
      });
      // Polyline.setOptions({ strokeColor: '#ffffff' })
      locationArray.forEach((l: any) => this.line.getPath().push(l));
      // console.log(element)
      let user_info = this.start[i]['location'] + "_" + this.start[i]['label']
      google.maps.event.addListener(this.line, 'mouseover', (e: any) => {
        infoWindow.setPosition(e.latLng);
        infoWindow.setContent(user_info + e.latLng);
        infoWindow.open(this.map);
      });
      google.maps.event.addListener(this.line, 'mouseout', function () {
        infoWindow.close();
      });
      this.line2.push(this.line)
    }
    ///////////////////

    // ///////////////////////////// image marker
    for (let i = 0; i < this.start.length; i++) {
      for (let j = 0; j < this.img_url.length; j++) {
        if (this.start[i]['label'] == this.img_url[j]['user_id']) {
          this.start[i]['img_url'] = this.img_url[j]['img_url']
        }
      }
    }
    /////////
    // console.log(this.start);
    const infoWindow: any = new google.maps.InfoWindow()

    for (let i = 0; i < this.start.length; i++) {

      const start = new google.maps.LatLng(this.start[i]['lat'], this.start[i]['lng']);
      // console.log(this.start[i]['img_url']['changingThisBreaksApplicationSecurity']);

      this.startMarker = new google.maps.Marker({
        position: start,
        map: this.map,
        // label: this.start[i]['location'],
        title: this.start[i]['location'] + "_" + this.start[i]['label'] + "_" + "Date : " + this.start[i]['currentdate'] + "_" + "Time : " + this.start[i]['currenttime'],
        icon: { url: this.start[i]['img_url']['changingThisBreaksApplicationSecurity'], scaledSize: { height: 40, width: 40 } }
      });

      // console.log(this.startMarker)
      this.removemarker.push(this.startMarker)


    }

    ////////////////image marker end

    // this.startMarker.addListener("click", () => {
    //   infoWindow.close();
    //   infoWindow.setContent(this.startMarker.getTitle());
    //   infoWindow.open(this.startMarker.getMap(), this.startMarker);
    // });

  }



  reset() {
    // this.marker.reset();
    for (let i = 0; i < this.line2.length; i++) {
      this.line2[i].setMap(null);
    }

    for (let i = 0; i < this.removemarker.length; i++) {
      this.removemarker[i].setMap(null);
    }

  }






  ngOnDestroy() {
    if (!this.subscription) {
      // console.log("destroy",this.subscription)

    } else {
      this.subscription.unsubscribe()
    }
  }










}


