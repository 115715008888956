<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">Income Expence Report </div>
                </div>
                <div class="tab-pane fade show active" id="tab-1">
                    <br>

                    <div class="ibox-body">
                        <br>
                        <form name="form" (ngSubmit)="f.form.valid && incomeExpenseReport()" #f="ngForm">

                            <div class="row">
                                <div class="col-3 text-center">
                                    <h6> Select Year : </h6>
                                </div>

                                <div class="col-3">
                                    <ng-select [items]="allFinYear" bindLabel="fin_year_desc" bindValue="fin_year"
                                        (change)="setFinYear()" [multiple]="false" placeholder="Select Year"
                                        [(ngModel)]="selectedFinYear" [selectableGroup]="true"
                                        [selectableGroupAsModel]="false" [closeOnSelect]="true" name="fin_year"
                                        #fin_year="ngModel"
                                        [ngClass]="{ 'is-invalid': f.submitted && fin_year.invalid }" required>
                                    </ng-select>

                                    <!--------------------------------------------- validation Start ------------------- -->

                                    <!-- border color green -->
                                    <div [class.selection-box]="fin_year.dirty && fin_year.valid">
                                    </div>

                                    <!-- error handling  -->
                                    <div *ngIf="f.submitted && fin_year.invalid" class="error-message">
                                        <div *ngIf="fin_year.errors?.['required']">Please Select Financial Year </div>
                                    </div>

                                    <!--------------------------------------------- validation End ------------------- -->


                                </div>
                                <div class="col-3 text-center">
                                    <h6> Strat Date : {{mainObj['start_date']}} </h6>
                                </div>

                                <div class="col-3 text-center">
                                    <h6> End Date : {{mainObj['end_date']}} </h6>
                                </div>

                            </div>
                            <br>
                            <div class="row">
                                <div class="col-6 text-right">
                                    <!-- <button class="btn btn-primary" (click)="incomeExpenseReport()">Submit</button> -->
                                    <button type="submit" class="btn btn-primary" >Submit</button>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button class="btn btn-primary" (click)="print()">Print</button>&nbsp;&nbsp;
                                <button class="btn btn-success" (click)="export()">Export</button>

                            </div>
                        </div>
                        <br>
                        <div>
                            <table class="table table-striped card">
                                <tbody>
                                    <tr style="background-color:#188bff;color: #fff;">
                                        <th *ngFor="let col of displayedColumns" scope="col">{{col}}</th>
                                    </tr>

                                    <tr>
                                        <td>
                                            <!-- Expense code  -->
                                            <table class="table table-border">
                                                <tr style="background-color:#1589ff;color: #fff;">
                                                    <th *ngFor="let header of innerTableHeader"> {{header}} </th>
                                                </tr>
                                                <tr class="text-white bg-success">
                                                    <td>0</td>
                                                    <td> <b> To Cost of Property Stock Sold [As per properties Stock
                                                            Account] </b></td>
                                                    <!-- <td><b> COGS</b></td> -->
                                                    <td> <b>{{first_line}}</b></td>
                                                </tr>
                                                <ng-container *ngFor="let exp of expence">
                                                    <tr>
                                                        <td>{{exp['opening'] | number:'1.2-2'}}</td>

                                                        <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">
                                                                    <b>{{exp['lvl4_value']}}</b>
                                                                    <b *ngIf="exp['lvl5_code']!=null">-</b>
                                                                    {{exp['lvl5_value']}}
                                                                </h5>
                                                            </b>
                                                        </td>
                                                        <!-- <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">

                                                                    <b>{{exp['lvl4_code']}}</b>

                                                                    <b *ngIf="exp['lvl5_code']!=null">-</b>
                                                                    {{exp['lvl5_code']}}
                                                                </h5>
                                                            </b>
                                                        </td> -->

                                                        <td> <label class="text-primary"> {{exp['txn_amt'] |
                                                                number:'1.2-2'}} </label></td>
                                                        <!-- <td *ngIf="exp['txn_amt'] < 0"> <label class="text-primary"> {{ (-1 * exp['txn_amt'])}} </label></td> -->

                                                    </tr>

                                                </ng-container>
                                                <tr *ngIf="exp_total < inc_total" class="text-white bg-success">
                                                    <td>0</td>
                                                    <td>To Excess of Income Over Expenditure</td>
                                                    <!-- <td></td> -->
                                                    <td>{{(inc_total-exp_total)| number:'1.2-2'}}</td>
                                                </tr>
                                                <tr>
                                                    <td> {{exp_op_total | number:'1.2-2'}} </td>
                                                    <td> TOTAL </td>
                                                    <!-- <td> </td> -->
                                                    <td> {{(exp_total+exp_prop + first_line) | number:'1.2-2'}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>

                                            <!-- Following is for Income --------Start------------------------------- -->
                                            <table class="table">
                                                <tr style="background-color:#1589ff;color: #fff;">
                                                    <th *ngFor="let header of innerTableHeader">{{header}}</th>
                                                </tr>

                                                <ng-container *ngFor="let inc of income">
                                                    <tr>
                                                        <td>{{inc['opening'] | number:'1.2-2'}}</td>

                                                        <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">
                                                                    <b>{{inc['lvl4_value']}}</b>
                                                                    <b *ngIf="inc['lvl5_code']!=null">-</b>
                                                                    {{inc['lvl5_value']}}
                                                                </h5>
                                                            </b>
                                                        </td>
                                                        <!-- <td>
                                                            <b>
                                                                <h5 class="text-primary" style="padding: 0px 20px;">

                                                                    <b>{{inc['lvl4_code']}}</b>

                                                                    <b *ngIf="inc['lvl5_code']!=null">-</b>
                                                                    {{inc['lvl5_code']}}
                                                                </h5>
                                                            </b>
                                                        </td> -->
                                                        <td> <label class="text-primary"> {{inc['txn_amt'] |
                                                                number:'1.2-2'}} </label></td>
                                                        <!-- <td *ngIf="inc['txn_amt'] < 0"> <label class="text-primary"> {{ ( -1*inc['txn_amt'])}} </label></td> -->

                                                    </tr>
                                                </ng-container>
                                                <tr *ngIf="exp_total > inc_total" class="text-white bg-success">
                                                    <td></td>
                                                    <td>By Excess of Expenditure Over Income </td>
                                                    <!-- <td></td> -->
                                                    <td>{{(exp_total-inc_total) | number:'1.2-2'}}</td>
                                                </tr>

                                                <tr>
                                                    <td>{{inc_op_total | number:'1.2-2'}} </td>
                                                    <td> TOTAL </td>
                                                    <!-- <td>  </td> -->
                                                    <td> {{(inc_total+inc_prop) | number:'1.2-2'}} </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner>
    <p style="color: white"> Loading... </p>
</ngx-spinner>
