import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountProfileComponent } from '../account-profile/account-profile.component';
import { ApprovalComponent } from '../approval/approval.component';
import { ComplaintWorkflowComponent } from '../complaint-workflow/complaint-workflow.component';
import { LeaveComponent } from '../leave/leave.component';
import { PortalDashComponent } from '../portal-dash/portal-dash.component';
import { PortalProductsComponent } from '../portal-products/portal-products.component';
import { PortalUserProfileComponent } from '../portal-user-profile/portal-user-profile.component';
import { PortalUsersComponent } from '../portal-users/portal-users.component';
import { SalaryComponent } from '../salary/salary.component';
import { TaskComponent } from '../task/task.component';


const routes: Routes = [
  {path: "index",component: PortalDashComponent},
  {path: "leave",component: LeaveComponent},
  {path: "yourproducts",component: PortalProductsComponent},
  {path: "task",component: TaskComponent},
  {path: "workflow",component: ComplaintWorkflowComponent},
  {path: "salary",component: SalaryComponent},
  {path: "users",component: PortalUsersComponent},
  {path: 'acc',component: AccountProfileComponent},
  {path: "profile",component: PortalUserProfileComponent},
  {path: "appr",component: ApprovalComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalLayoutsRoutingModule { }
