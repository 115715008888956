<div class="page-content fade-in-up">
    <div class="row">

        <div class="col-lg-12 col-md-12">

            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title"><!-- Fields Manage -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD1']}}</div>
                </div>

                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <li class="nav-item">
                            <a *ngIf=" l_term_flag" class="nav-link active" href="#tab-1" data-toggle="tab" (click)="refresh()"><i
                                    class="ti-bar-chart"></i>
                                <!-- All Fields List -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD2']}}</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#tab-2" data-toggle="tab" (click)="refresh()"><i
                                    class="fa fa-plus-circle" aria-hidden="true"></i> Create Field</a>
                        </li> -->

                        <li  class="nav-item">
                            <a *ngIf=" u_term_flag" class="nav-link" href="#tab-3" data-toggle="tab"><i class="ti-settings"></i><!--  Update
                                Field -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD3']}}</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div *ngIf=" l_term_flag">

                            <br>


                            <mat-form-field style="margin-left: 2%">
                                <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter">
                            </mat-form-field>

                            <div class=" example-container">
                                <table mat-table [dataSource]="datasource" matSort>
                                    <ng-container matColumnDef="field_id">
                                        <th mat-header-cell *matHeaderCellDef class="left" mat-sort-header><!-- Field ID -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD4']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element; let i = index; " class="right">
                                            {{ element.field_id }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="field_business_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Field Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD5']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_business_name }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="field_code">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Field Code -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD6']}}

                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{ element.field_code }} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header><!-- Action -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD7']}}</th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">

                                            <button class="btn btn-primary" (click)="open_update(element)"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD8']}}</button>
                                            <!-- <button class="btn btn-danger" (click)="delete(element)">Delete</button> -->

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
                            </div>


                        </div>
                        <div class="tab-pane fade" id="tab-2">
                            <br>
                            
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin: 2%;"><!-- Field Business Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD9']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="enter field name" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin: 2%;"><!-- Field Code --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD6']}}: <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="enter field code" [(ngModel)]="field['field_code']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin: 2%;"><!-- Field Technical Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD10']}} : <span style="color: red;">*</span></h6>
                                </div>
                                <div class="col-4">
                                    <input class="form-control" placeholder="enter field technical name" [(ngModel)]="field['field_technical_name']">
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin: 2%;"><!-- Field DataType Name --> {{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD11']}}: <span style="color: red;">*</span> </h6>
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allDataTyeps" bindLabel="bus_datatype_name" bindValue="datatype_code" [multiple]="false" placeholder="Select Field DataType" [(ngModel)]="field['datatype_code']" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="true"
                                        name="field_name">
                                    </ng-select>

                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="create()"><!-- Submit -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD12']}}</button>
                                </div>
                            </div>
                        </div>

                        <div *ngIf=" u_term_flag" >

                            <br>
                            <div style="text-align: right;">
                                <button class=" btn btn-outline-primary" (click)="gotolist()">Fields List</button>
                            </div>
                            <div class="row">
                                <div class="col-4 ">
                                    <h6 style="margin: 2%;"> <!-- Field Name -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD5']}}:<span style="color: red;">*</span>
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-control" placeholder="enter field name" [(ngModel)]="field['field_business_name']">
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" (click)="update()"><!-- Update -->{{mainService.allLableShowObj[mainService.language_cd+'EMB-FIELD8']}}</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
<ngx-spinner type="ball-atom">Loading....</ngx-spinner>