import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { MainService } from '../service/main.service';
import { CostCenterService } from '../service/cost-center.service';
import { OrgHierService } from '../service/org-hier.service';
import { AuthenticationService } from '../../authentication.service';
import { HierarchyService } from '../service/hierarchy.service';

declare var $: any;

import Swal from 'sweetalert2';
@Component({
    selector: 'app-cost-center',
    templateUrl: './cost-center.component.html',
    styleUrls: ['./cost-center.component.css']
})
export class CostCenterComponent implements OnInit {
    @ViewChild('paginator', { static: false }) paginator!: MatPaginator;
    @ViewChild('sort', { static: false }) sort!: MatSort;
    datasource:any;
    costObject:any = {};
    b_acct_id: any;
    erpUser: any;
    flag: any;
    constructor(private MatSnackBar: MatSnackBar, private hierarchyService: HierarchyService, public AuthenticationService: AuthenticationService, public OrgHierService: OrgHierService, public costCenterService: CostCenterService, public mainService: MainService, private router: Router, private spinner: NgxSpinnerService,) { }
    displayedColumns = ['cc_code', 'cc_name', 'cc_func', 'org_hier_code', 'action'];
    level1:any = [];
    level2:any = []
    level3:any = []
    level4:any = []
    level5:any = []
    level6:any = []
    level7:any = []
    unique_leaf:any = []
    obj:any = {}
    allOrgHier:any = []
    orgHierarchy:any = [];
    create_flag:any = false;
    list_flag:any = true;
    update_flag :any= false;
    unique:any = [];


    async ngOnInit() {
        this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
        this.b_acct_id = this.erpUser.b_acct_id;
        await this.getList()
        await this.getHier()
        await this.getEmployees()
        await this.getAccountModule()
        await this.getAllProjectHier()
    }


    allProjectHier:any = []
    async getAllProjectHier() {
        this.spinner.show()
        var obj :any= new Object();
        obj['b_acct_id'] = this.b_acct_id;
        obj['table_name'] = 'proj_hier';
        var resp = await this.hierarchyService.getProjectHierarchy(obj);
        console.log(resp)
        if (resp['error'] == false) {
            this.allProjectHier = resp.data;
            this.spinner.hide();
        } else {
            this.spinner.hide()
        }
    }


    moduleArr:any = []
    async getAccountModule() {
        var obj :any= new Object();
        obj['b_acct_id'] = this.b_acct_id;
        console.log(obj)
        var resp = await this.AuthenticationService.getaccountmodule(JSON.stringify(obj));
        console.log(resp);
        if (resp['error'] == false) {
            this.moduleArr = []
            let arr = resp['data'][0]['module_cd'].split(",");
            for (let i = 0; i < arr.length; i++) {
                let ob:any = Object.assign({}, {})
                ob['module_cd'] = arr[i]
                this.moduleArr.push(ob)
            }
        } else {

        }
    }

    async module(element:any) {
        $('#myModal4').modal('show');
        this.obj = Object.assign({}, element);
        this.obj['b_acct_id'] = this.b_acct_id
    }


    async updateCostCenterModule() {
        var resp = await this.hierarchyService.updateHierarchyModule(this.obj);
        console.log(resp)
        if (resp['error'] == false) {
            if (this.obj['cc_func'] != null) {
                await this.updateCost()
            }
        } else {
            Swal.fire('Error', 'Error While Updating', 'error');
            this.spinner.hide();
        }
    }



    allEmp = []
    async getEmployees() {
        this.spinner.show()
        let ob:any = {}
        ob['b_acct_id'] = this.b_acct_id
        var resp = await this.costCenterService.getEmployees(JSON.stringify(ob));
        if (resp['error'] == false) {
            this.allEmp = resp['data'];
            this.spinner.hide()
        } else {
            this.spinner.hide()
            Swal.fire("Error", "Error While Getting Employee", 'error');
        }
    }


    async listfunc() {
        this.create_flag = false;
        this.list_flag = true;
        this.update_flag = false
    }

    applyFilter(filterValue: string) {
        this.datasource.filter = filterValue.trim().toLowerCase();
    }

    async update(element:any) {
        this.create_flag = false;
        this.list_flag = false;
        this.update_flag = true;
        this.currentHierarchy = Object.assign({}, element)
        this.obj = Object.assign({}, element);

    }


    hierCd = []
    // async createCostCenter() {
    //     this.spinner.show()

    //     this.obj['create_user_id'] = this.erpUser.user_id;
    //     this.obj['b_acct_id'] = this.b_acct_id
    //     this.obj['status'] = 'ACTIVE'
    //     await this.createOrgHier()
    //     await this.createProjectHier()
    //     var resp = await this.costCenterService.createCost(this.obj);
    //     if (resp['error'] == false) {
    //         this.spinner.hide()
    //         await this.getList()
    //         await this.getHier()
    //         Swal.fire("Success", "Successfully Created",'success');
    //         this.refresh();
    //     } else {
    //         this.spinner.hide()
    //         Swal.fire("Error", "Error While Creating ", 'error');
    //     }

    // }

    // async createOrgHier() {
    //     let ob = this.orgHierCodeObject[this.obj['org_hier_leaf_code']]
    //     let arr = Object.keys(ob)
    //     let num = 0
    //     for (let i = 1; i < 8; i++) {
    //         if (ob['lvl' + i + "_cd"] == ob['leaf_cd']) {
    //             num = i + 1
    //         }
    //     }
    //     ob['lvl' + num + "_cd"] = this.obj['cc_code']
    //     ob['lvl' + num + "_value"] = this.obj['cc_name']
    //     ob['lvl' + num + "_node_type"] = 'Cost Center'
    //     ob['lvl' + num + "_type"] = 'text'
    //     ob['leaf_cd'] = this.obj['cc_code']
    //     ob['leaf_value'] = this.obj['cc_name']
    //     ob['leaf_node_type'] = 'Cost Center'
    //     ob['b_acct_id'] = this.b_acct_id
    //     ob['create_user_id'] = this.erpUser.user_id
    //     ob['org_hier_code'] = 'FIN'
    //     var resp = await this.OrgHierService.createHierarchy(ob);
    //     console.log(resp)
    //     if (resp['error'] == false) {
    //         this.spinner.hide()
    //     } else {
    //         this.spinner.hide()
    //         Swal.fire("Error", "...", resp['error']);
    //     }
    // }


    // async createProjectHier() {
    //    var ob :any= new Object
    //     ob['b_acct_id'] = this.b_acct_id
    //     ob['create_user_id'] = this.erpUser.user_id
    //     if (this.allProjectHier.length > 0) {
    //         ob['lvl1_cd'] = this.allProjectHier[0]['lvl1_cd']
    //         ob['lvl1_value'] = this.allProjectHier[0]['lvl1_value']

    //     } else {
    //         ob['lvl1_cd'] = 'P1'
    //         ob['lvl1_value'] = 'All Projects'
    //         ob['lvl1_type'] = 'text'

    //     }
    //     ob['lvl2_cd'] = this.obj['cc_code']
    //     ob['lvl2_value'] = this.obj['cc_name']
    //     ob['lvl2_type'] = 'text'
    //     ob['leaf_cd'] = this.obj['cc_code']
    //     ob['leaf_value'] = this.obj['cc_name']
    //     ob['table_name'] = 'proj_hier';

    //     var resp = await this.hierarchyService.createProjectHierarchy(ob);
    //     console.log(resp)
    //     if (resp['error'] == false) {
    //         this.allHier = resp.data;

    //     } else {
    //     }
    // }


    allHier:any = []
    orgHierObject:any = {}
    orgHierCodeObject:any = {}
    async getHier() {

        this.obj['b_acc_id'] = this.b_acct_id
        this.obj['org_hier_code'] = 'FIN'
        var resp = await this.OrgHierService.getHierarchy(this.obj);
        this.orgHierarchy = resp.data
        this.orgHierObject = new Object
        console.log('all Hier', this.allOrgHier)
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            console.log(this.orgHierarchy[i])
            let str = ""
            for (let j = 1; j < 8; j++) {
                if (this.orgHierarchy[i]['lvl' + j + "_cd"] != null && this.orgHierarchy[i]['lvl' + j + "_cd"] != "") {
                    str += this.orgHierarchy[i]['lvl' + j + "_value"] + " > "
                }
            }
            str = str.substring(0, str.length - 2)
            this.orgHierarchy[i]['desc'] = str;
            this.orgHierObject[this.orgHierarchy[i]['leaf_cd']] = str
            this.orgHierCodeObject[this.orgHierarchy[i]['leaf_cd']] = this.orgHierarchy[i]

        }

        console.log(this.orgHierObject)

    }
    flags = 0
    updateLevel = 0
    currentHierarchy:any = {}
    async updateHier() {
        this.currentHierarchy['b_acct_id'] = this.b_acct_id
        this.currentHierarchy['update_user_id'] = this.erpUser.user_id
        if (this.currentHierarchy['lvl' + this.updateLevel + "_type"] == 'text') {
            for (let i = 1; i < 8; i++) {

                if (this.currentHierarchy['lvl' + i + '_type'] != undefined) {
                    if (this.currentHierarchy['lvl' + i + '_type'] != '') {

                        if (this.unique.includes(this.currentHierarchy['lvl' + i + '_cd'])) {

                            Swal.fire('Error', "Duplicate value of " + this.currentHierarchy['lvl' + i + '_cd']);
                            return;

                        }


                    }
                }
            }
        }
        var resp = await this.OrgHierService.updateHierarchy(this.currentHierarchy);
        console.log(resp)
        if (resp['error'] == false) {
            this.spinner.hide();
            Swal.fire('Success', "Update Successfully!");
        } else {
            Swal.fire('Error', 'Error While Updating', 'error');
            this.spinner.hide();
        }
    }

    async makingLeafValues1() {

        if (this.currentHierarchy['lvl7_cd'] != undefined && this.currentHierarchy['lvl7_cd'] != '' && this.currentHierarchy['lvl7_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl7_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl7_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl6_cd'] != undefined && this.currentHierarchy['lvl6_cd'] != '' && this.currentHierarchy['lvl6_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl6_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl6_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl5_cd'] != undefined && this.currentHierarchy['lvl5_cd'] != '' && this.currentHierarchy['lvl5_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl5_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl5_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl4_cd'] != undefined && this.currentHierarchy['lvl4_cd'] != '' && this.currentHierarchy['lvl4_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl4_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl4_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl3_cd'] != undefined && this.currentHierarchy['lvl3_cd'] != '' && this.currentHierarchy['lvl3_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl3_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl3_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl2_cd'] != undefined && this.currentHierarchy['lvl2_cd'] != '' && this.currentHierarchy['lvl2_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl2_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl2_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        } else if (this.currentHierarchy['lvl1_cd'] != undefined && this.currentHierarchy['lvl1_cd'] != '' && this.currentHierarchy['lvl1_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl1_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl1_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['leaf_node_type']
        }


    }

    async delete(element:any) {
        this.spinner.show()
        let obj:any = {}
        obj['b_acct_id'] = this.b_acct_id
        obj['id'] = element['id']
        var resp = await this.costCenterService.deleteCost(obj);
        if (resp['error'] == false) {
            this.spinner.hide()
            Swal.fire('success', 'Record Deleted Successfully', 'success')
            await this.getList()
        } else {
            this.spinner.hide()
            Swal.fire('Error', 'Some Error Occured', 'error')
        }
    }

    async updateCost() {
        this.spinner.show();
        this.obj['b_acct_id'] = this.b_acct_id;
        this.obj['update_user_id'] = this.erpUser.user_id;
        // let mob_valid = [0,1,2,3,4,5,6,7,8,9]
        // let mobile_no = this.obj['cc_mob_no'];
        // let mobile_arr = mobile_no.split(" ");
        // if(!mobile_arr.includes(mob_valid)){
        //     this.spinner.hide();
        //     Swal.fire('Error','Mobile Number Should be in number','error')
        //     return;
        // }

        if (this.obj['cc_email']) {
            if (!/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.obj['cc_email'])) {
                Swal.fire("Error", 'Invalid Email', 'error')
                return
            }
        }
        if (this.obj['cc_mob_no']) {
            if (this.obj['cc_mob_no'].toString().length != 10) {
                Swal.fire("Info", 'Enter valid number', 'info')
                return
            }
        }
        var resp = await this.costCenterService.updateCost(this.obj);
        if (resp['error'] == false) {
            this.spinner.hide();
            Swal.fire('Success', 'Updated Successfully', 'success')
            await this.getList();
            this.create_flag = false;
            this.list_flag = true;
            this.update_flag = false;
        } else {
            Swal.fire('Error', resp.data)
            this.spinner.hide();
        }
    }

    async onChangeLvl1() {
        for (let i = 0; i < this.level1.length; i++) {
            if (this.level1[i]['lvl1_cd'] == this.currentHierarchy['lvl1_cd']) {
                this.currentHierarchy['lvl1_value'] = this.level1[i]['lvl1_value']
                this.currentHierarchy['lvl1_node_type'] = this.level1[i]['lvl1_node_type']
            }
        }
        let temp:any = []
        this.level2 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl1_cd'] == this.currentHierarchy['lvl1_cd']) {

                let hier_row = this.orgHierarchy[i]
                let keys = Object.keys(hier_row)
                for (let j = 0; j < keys.length; j++) {
                    if (!this.unique.includes(hier_row[keys[j]])) {
                        this.unique.push(hier_row[keys[j]])
                    }

                } this.unique_leaf.push(hier_row['leaf_cd'])
                if (this.orgHierarchy[i]['lvl2_cd'] != null && this.orgHierarchy[i]['lvl2_cd'] != '') {
                    if (!temp.includes(this.orgHierarchy[i]['lvl2_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl2_cd'])
                       var ob :any= new Object()
                        ob['lvl2_cd'] = this.orgHierarchy[i]['lvl2_cd']
                        ob['lvl2_value'] = this.orgHierarchy[i]['lvl2_value']
                        ob['lvl2_node_type'] = this.orgHierarchy[i]['lvl2_node_type']
                        this.level2.push(ob)
                    }
                }
            }

        }
        this.level3 = []
        this.level4 = []
        this.level5 = []
        this.level6 = []
        this.level7 = []
        console.log(this.level2)
        for (let i = 2; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }

        await this.makingLeafValues()


    }

    async onChangeLvl2() {
        for (let i = 0; i < this.level2.length; i++) {
            if (this.level2[i]['lvl2_cd'] == this.currentHierarchy['lvl2_cd']) {
                this.currentHierarchy['lvl2_value'] = this.level2[i]['lvl2_value']
                this.currentHierarchy['lvl2_node_type'] = this.level2[i]['lvl2_node_type']
            }
        }
        let temp:any = []
        this.level3 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl2_cd'] == this.currentHierarchy['lvl2_cd']) {
                if (this.orgHierarchy[i]['lvl3_cd'] != null && this.orgHierarchy[i]['lvl3_cd'] != '') {
                    if (!temp.includes(this.orgHierarchy[i]['lvl3_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl3_cd'])
                       var ob :any= new Object()
                        ob['lvl3_cd'] = this.orgHierarchy[i]['lvl3_cd']
                        ob['lvl3_value'] = this.orgHierarchy[i]['lvl3_value']
                        ob['lvl3_node_type'] = this.orgHierarchy[i]['lvl3_node_type']
                        this.level3.push(ob)
                    }
                }
            }
        }
        this.level4 = []
        this.level5 = []
        this.level6 = []
        this.level7 = []

        for (let i = 3; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }

        await this.makingLeafValues()


    }


    async onChangeLvl3() {
        for (let i = 0; i < this.level3.length; i++) {
            if (this.level3[i]['lvl3_cd'] == this.currentHierarchy['lvl3_cd']) {
                this.currentHierarchy['lvl3_value'] = this.level3[i]['lvl3_value']
                this.currentHierarchy['lvl3_node_type'] = this.level3[i]['lvl3_node_type']

            }
        }
        let temp:any = []
        this.level4 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl3_cd'] == this.currentHierarchy['lvl3_cd']) {
                if (this.orgHierarchy[i]['lvl4_cd'] != null && this.orgHierarchy[i]['lvl4_cd'] != '') {

                    if (!temp.includes(this.orgHierarchy[i]['lvl4_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl4_cd'])
                       var ob :any= new Object()
                        ob['lvl4_cd'] = this.orgHierarchy[i]['lvl4_cd']
                        ob['lvl4_value'] = this.orgHierarchy[i]['lvl4_value']
                        ob['lvl4_node_type'] = this.orgHierarchy[i]['lvl4_node_type']
                        this.level4.push(ob)
                    }
                }
            }
        }
        this.level5 = []
        this.level6 = []
        this.level7 = []

        for (let i = 4; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }


        await this.makingLeafValues()

    }

    async onChangeLvl4() {
        for (let i = 0; i < this.level4.length; i++) {
            if (this.level4[i]['lvl4_cd'] == this.currentHierarchy['lvl4_cd']) {
                this.currentHierarchy['lvl4_value'] = this.level4[i]['lvl4_value']
                this.currentHierarchy['lvl4_node_type'] = this.level4[i]['lvl4_node_type']

            }
        }
        let temp:any = []
        this.level5 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl4_cd'] == this.currentHierarchy['lvl4_cd']) {
                if (this.orgHierarchy[i]['lvl5_cd'] != null && this.orgHierarchy[i]['lvl5_cd'] != '') {

                    if (!temp.includes(this.orgHierarchy[i]['lvl5_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl5_cd'])
                       var ob :any= new Object()
                        ob['lvl5_cd'] = this.orgHierarchy[i]['lvl5_cd']
                        ob['lvl5_value'] = this.orgHierarchy[i]['lvl5_value']
                        ob['lvl5_node_type'] = this.orgHierarchy[i]['lvl5_node_type']
                        this.level5.push(ob)
                    }
                }
            }
        }
        this.level6 = []
        this.level7 = []

        for (let i = 5; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }

        await this.makingLeafValues()


    }

    async onChangeLvl5() {
        for (let i = 0; i < this.level5.length; i++) {
            if (this.level5[i]['lvl5_cd'] == this.currentHierarchy['lvl5_cd']) {
                this.currentHierarchy['lvl5_value'] = this.level5[i]['lvl5_value']
                this.currentHierarchy['lvl5_node_type'] = this.level5[i]['lvl5_node_type']

            }
        }
        let temp:any = []
        this.level6 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl5_cd'] == this.currentHierarchy['lvl5_cd']) {
                if (this.orgHierarchy[i]['lvl6_cd'] != null && this.orgHierarchy[i]['lvl6_cd'] != '') {

                    if (!temp.includes(this.orgHierarchy[i]['lvl6_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl6_cd'])
                       var ob :any= new Object()
                        ob['lvl6_cd'] = this.orgHierarchy[i]['lvl6_cd']
                        ob['lvl6_value'] = this.orgHierarchy[i]['lvl6_value']
                        ob['lvl6_node_type'] = this.orgHierarchy[i]['lvl6_node_type']
                        this.level6.push(ob)
                    }
                }
            }
        }
        this.level7 = []

        for (let i = 6; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }

        await this.makingLeafValues()


    }
    async onChangeLvl6() {
        for (let i = 0; i < this.level6.length; i++) {
            if (this.level6[i]['lvl6_cd'] == this.currentHierarchy['lvl6_cd']) {
                this.currentHierarchy['lvl6_value'] = this.level6[i]['lvl6_value']
                this.currentHierarchy['lvl6_node_type'] = this.level6[i]['lvl6_node_type']

            }
        }
        let temp:any = []
        this.level7 = []
        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (this.orgHierarchy[i]['lvl6_cd'] == this.currentHierarchy['lvl6_cd']) {
                if (this.orgHierarchy[i]['lvl7_cd'] != null && this.orgHierarchy[i]['lvl7_cd'] != '') {

                    if (!temp.includes(this.orgHierarchy[i]['lvl7_cd'])) {
                        temp.push(this.orgHierarchy[i]['lvl7_cd'])
                       var ob :any= new Object()
                        ob['lvl7_cd'] = this.orgHierarchy[i]['lvl7_cd']
                        ob['lvl7_value'] = this.orgHierarchy[i]['lvl7_value']
                        ob['lvl7_node_type'] = this.orgHierarchy[i]['lvl7_node_type']
                        this.level7.push(ob)
                    }
                }
            }
        }

        for (let i = 7; i < 8; i++) {
            this.currentHierarchy['lvl' + i + '_cd'] = null
            this.currentHierarchy['lvl' + i + '_value'] = null
            this.currentHierarchy['lvl' + i + '_node_type'] = null
        }

        await this.makingLeafValues()


    }

    async addNew(i:any) {
        if (this.currentHierarchy['lvl' + i + '_type'] == 'text') {
            this.currentHierarchy['lvl' + i + '_type'] = ''

        } else {
            this.currentHierarchy['lvl' + i + '_type'] = 'text'

        }
        this.currentHierarchy['lvl' + i + '_cd'] = null
        this.currentHierarchy['lvl' + i + '_value'] = null
        this.currentHierarchy['lvl' + i + '_node_type'] = null
        for (let j = i; j < 8; j++) {
            if (this.currentHierarchy['lvl' + j + '_type'] != 'text') {
                this.currentHierarchy['lvl' + j + '_cd'] = null
                this.currentHierarchy['lvl' + j + '_value'] = null
                this.currentHierarchy['lvl' + i + '_node_type'] = null
            }

        }
        await this.makingLeafValues()

    }
    async makingLeafValues() {

        if (this.currentHierarchy['lvl7_cd'] != undefined && this.currentHierarchy['lvl7_cd'] != '' && this.currentHierarchy['lvl7_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl7_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl7_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl7_node_type']
        } else if (this.currentHierarchy['lvl6_cd'] != undefined && this.currentHierarchy['lvl6_cd'] != '' && this.currentHierarchy['lvl6_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl6_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl6_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl6_node_type']
        } else if (this.currentHierarchy['lvl5_cd'] != undefined && this.currentHierarchy['lvl5_cd'] != '' && this.currentHierarchy['lvl5_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl5_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl5_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl5_node_type']
        } else if (this.currentHierarchy['lvl4_cd'] != undefined && this.currentHierarchy['lvl4_cd'] != '' && this.currentHierarchy['lvl4_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl4_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl4_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl4_node_type']
        } else if (this.currentHierarchy['lvl3_cd'] != undefined && this.currentHierarchy['lvl3_cd'] != '' && this.currentHierarchy['lvl3_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl3_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl3_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl3_node_type']
        } else if (this.currentHierarchy['lvl2_cd'] != undefined && this.currentHierarchy['lvl2_cd'] != '' && this.currentHierarchy['lvl2_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl2_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl2_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl2_node_type']
        } else if (this.currentHierarchy['lvl1_cd'] != undefined && this.currentHierarchy['lvl1_cd'] != '' && this.currentHierarchy['lvl1_cd'] != null) {
            this.currentHierarchy['leaf_cd'] = this.currentHierarchy['lvl1_cd']
            this.currentHierarchy['leaf_value'] = this.currentHierarchy['lvl1_value']
            this.currentHierarchy['leaf_node_type'] = this.currentHierarchy['lvl1_node_type']
        }


    }


    async refresh() {
        let temp:any = []
        this.level1 = []

        for (let i = 0; i < this.orgHierarchy.length; i++) {
            if (!temp.includes(this.orgHierarchy[i]['lvl1_cd'])) {
                temp.push(this.orgHierarchy[i]['lvl1_cd'])
               var ob :any= new Object()
                ob['lvl1_cd'] = this.orgHierarchy[i]['lvl1_cd']
                ob['lvl1_value'] = this.orgHierarchy[i]['lvl1_value']
                ob['lvl1_node_type'] = this.orgHierarchy[i]['lvl1_node_type']


                this.level1.push(ob)
            }
        }
        console.log(this.level1)

        this.obj = {}
        this.level2 = []

        this.level3 = []
        this.level4 = []
        this.level5 = []
        this.level6 = []
        this.level7 = []


    }


    async open_hier() {

        $('#myModal3').modal('show');

    }

    allList = []
    async getList() {
        this.spinner.show();
        this.obj['b_acct_id'] = this.b_acct_id;
        var resp = await this.costCenterService.getCost(this.obj);
        if (resp['error'] == false) {
            this.allList = resp.data
            this.datasource = new MatTableDataSource(this.allList);
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            this.spinner.hide();

        }
    }

    orgObj :any = {};
    viewCostCenter:any  = {};
    view(element:any) {
        this.viewCostCenter = Object.assign({}, element);
        $('#myModal5').modal('show');
    }

}
