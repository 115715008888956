import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MainService } from '../service/main.service';

declare var $: any;
@Component({
  selector: 'app-md-legal-entity',
  templateUrl: './md-legal-entity.component.html',
  styleUrls: ['./md-legal-entity.component.css']
})
export class MdLegalEntityComponent implements OnInit {


  displayedColumns = ['le_id', 'login_user_id', 'nick_name', 'legal_name', 'contact_email', 'phone_no'];
  displayedColumns2 = ['le_id', 'legal_name', 'contact_email', 'phone_no'];
  type_data = []
  party = [];
  datasource:any;
  datasource2:any;
  user_id:any;
  index:any;
  Obj:any = {};
  b_acct_id:any;
  erpUser:any;
  le_type = []
  allCodeValue = [];

  constructor(private spinner: NgxSpinnerService, public toastr: ToastrService, private mainService: MainService) { }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  data = [{ id: 1, acct_short_name: 'AC', acct_full_name: 'Account', name: "suraj kumar", email: "suraj@infiniseasoft.com", phone_no: "9984904063" }]
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser['b_acct_id']
    await this.getAdminCodeValues();
    await this.getCurrentLegalEntity();
  }

  async getAdminCodeValues() {
    var obj:any = new Object();
    obj['b_acct_id'] = 0;
    var resp = await this.mainService.getAdminCodeValue(JSON.stringify(obj));
    console.log(resp, 'code value')
    if (resp['error'] == false) {
      this.allCodeValue = resp['data']
      let dummy:any = []
      for (let i = 0; i < this.allCodeValue.length; i++) {
        if (this.allCodeValue[i]['field_code'] == 'SYS004') {
          dummy.push(this.allCodeValue[i])
        }
      }
      this.le_type = dummy
      console.log(this.le_type, 'le_type')
    } else {
      Swal.fire("Error", "..Error while getting resources", 'error');
    }
    console.log(this.allCodeValue, 'code value')
  }

  current_LE = []
  ind_current_LE = []
  org_current_LE = []
  async getCurrentLegalEntity() {
    this.current_LE = []
    this.ind_current_LE = []
    this.org_current_LE = []
    var resp = await this.mainService.getCurrentLegalEntity(this.b_acct_id);
    console.log(resp);
    if (resp['error'] == false) {
      console.log(resp);
      this.current_LE = resp['data']
      for (let i = 0; i < this.current_LE.length; i++) {
        if (this.current_LE[i]['party_type'] == 'IND') {
          this.ind_current_LE.push(this.current_LE[i])
        } else {
          this.org_current_LE.push(this.current_LE[i])
        }
      }
    } else {
      Swal.fire("Error", "..Error while getting current Legal Entity", 'error');
    }
  }

  changeList() {
    if (this.Obj['list_party_type'] == 'IND') {
      this.datasource = new MatTableDataSource(this.ind_current_LE);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    } else if (this.Obj['list_party_type'] == 'ORG') {
      this.datasource = new MatTableDataSource(this.org_current_LE);
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
    }
  }
  viewObj = {}
  viewDetails(element:any) {
    this.viewObj = Object.assign({}, element)
    console.log(element)
    $('#B').modal('show');
  }

  changeparty() {
    for (let i = 0; i < this.party.length; i++) {
      if (this.party[i]['party_id'] == this.Obj['party_id']) {
        this.Obj['party_leagal_name'] = this.party[i]['party_leagal_name']
      }
    }
  }

  refresh() {
    this.Obj = {}
    this.datasource=[]
    let dummy:any=[]
    this.datasource = new MatTableDataSource(dummy);
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  back() {
    $('.nav-tabs a[href="#tab-7-1"]').tab('show');
    this.Obj = {};
  }

  open_update(element:any) {
    this.spinner.show();
    this.Obj = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-7-3"]').tab('show');
    this.spinner.hide();
  }

  async update() {
    let flag = true
    if (!this.Obj['acct_short_name']) {
      flag = false
      Swal.fire('Warning...', 'Please Enter Account Short Name', 'error')
    } else if (!this.Obj['acct_full_name']) {
      flag = false
      Swal.fire('Warning...', 'Please Enter Account Full Name', 'error')
    }
    else if (!this.Obj['name']) {
      flag = false
      Swal.fire('Warning...', 'Please Enter Name', 'error')
    }
    else if (!this.Obj['email']) {
      flag = false
      Swal.fire('Warning...', 'Please Enter Email', 'error')
    }
    else if (!this.Obj['phone_no']) {
      flag = false
      Swal.fire('Warning...', 'Please Enter Phone number', 'error')
    }
    console.log(this.Obj);
  }

  async submit() {
    this.Obj['valid_upto'] = '2030-10-22'
    console.log(this.Obj);
    console.log(this.validate(this.Obj['contact_email']))

    if (!this.Obj['party_type']) {
      Swal.fire("Error", 'Please Select Legal Entity Type', 'error')
    } else if (!this.Obj['contact_email']) {
      Swal.fire('Error', 'Please Enter Email', 'error')
    }
    else if (!this.validate(this.Obj['contact_email'])) {
      Swal.fire('Error', 'Please Enter A Valid Email', 'error')
    } else if (!this.Obj['contact_phone']) {
      Swal.fire('Error', 'Please Enter Mobile Number', 'error')
    } else if (!this.Obj['dob'] && this.Obj['party_type']=='IND') {
      Swal.fire('Error', 'Please Enter Date Of Birth', 'error')
    } else if (!this.Obj['ident_verify_secret']) {
      Swal.fire('Error', 'Please Enter Password', 'error')
    }
    else {
      var obj = Object.assign({}, this.Obj);
      obj['login_user_id'] = obj['contact_phone'] + "-" + obj['contact_email'];
      obj['user_id'] = this.erpUser['le_id'];

      console.log(obj)
      this.spinner.show()
      var resp = await this.mainService.createLegalEntity(obj);
      console.log(resp)
      if (resp['error'] == false) {
        setTimeout(()=>{
          this.spinner.hide()
          this.getCurrentLegalEntity()
          this.datasource=[]
          let dummy:any=[]
          this.datasource = new MatTableDataSource(dummy);
          this.datasource.paginator = this.paginator;
          this.datasource.sort = this.sort;
          this.changeList()
          Swal.fire('Success...', 'Created Successfully', 'success')
        }, 1000);
      } else {
        this.spinner.hide()
        if (resp['data'] == "Duplicate entry") {
          Swal.fire('Error...', 'Either Mobile Or Email Is Already Exists.', 'error')
        } else {
          Swal.fire('Error', 'Error While Creating', 'error')
        }
      }
    }
  }

  validate(email:any) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return (false);
    } else {
      return true
    }
  }
  delete() {
    Swal.fire({
      title: 'Warning...',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      text: 'You Are Abount To Delete An Account. Please Review The Details Of Account To Be Deleted And Confirm',
      confirmButtonText: "Confirm"
    }).then((result) => {
      if (result.value) {
        $('.nav-tabs a[href="#tab-7-1"]').tab('show');
      }
    })
  };

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.datasource2.filter = filterValue.trim().toLowerCase();
  }

}
