import { Component, OnInit, ViewChild } from '@angular/core';
import { LedgerService } from '../../service/ledger.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ChartOfAccountService } from '../../service/chart-of-account.service';
import { MainService } from '../../service/main.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JvService } from '../../service/jv.service'
import Swal from 'sweetalert2';
import * as  pdfMake from  "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { MatSnackBar } from '@angular/material/snack-bar';
import { idText } from 'typescript';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
declare var $: any;

@Component({
  selector: 'app-jv',
  templateUrl: './jv.component.html',
  styleUrls: ['./jv.component.css']
})
export class JvComponent implements OnInit {
  datasource:any;

  constructor(private snackBar: MatSnackBar, private ledgerService: LedgerService, public mainS: MainService,
    private ch_acc_S: ChartOfAccountService, private spinner: NgxSpinnerService, private jvService: JvService) { }
  erpUser:any;
  obj:any = {}
  q :any= 1;
  dataSource1:any;
  status: any = [{ id: 'UNPOSTED' }, { id: 'APPROVED' }, { id: 'REJECTED' }, { id: 'PROCESSED' }]
  b_acct_id:any;
  acount_name:any
  systemDate:any;
  displayedColumns:any = ['jrnl_id', 'db_amount', 'cr_amount', /* 'ld_jrnl_entry_cd', */ /* 'prep_id', 'create_timestamp', */ 'status', 'action']
  displayedcolumns1 :any= ['jrnl_id', 'ar_actvty_tcd', 'db_amount', 'cr_amount', /* 'ld_jrnl_entry_cd', */ /* 'prep_id', 'create_timestamp', */ 'action']
  @ViewChild(MatPaginator, { static: true }) paginator !: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  @ViewChild('paginator1', { static: false }) paginator1!: MatPaginator;
  @ViewChild('sortCol1', { static: false }) sortCol1!: MatSort;
  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild("sortCol2", { static: false }) sortCol2!: MatSort;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    var resp:any = await this.mainS.getSystemDate();
    this.systemDate = resp.data
    await this.getFinYear()
    await this.allChartOfAccount()
    await this.getAllUnPostedJournalInfo();
    this.acount_name = this.mainS.accInfo['account_name']
  }

  fin_year:any = []
  async getFinYear() {
    this.spinner.show()
    var obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ledgerService.getAllFinYear(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      this.fin_year = resp['data']
    } else {
      this.spinner.hide()
    }
  }
  chartOfAcc = []
  async allChartOfAccount() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    var resp = await this.ch_acc_S.getchartofaccount(obj);
    if (resp['error'] == false) {
      this.spinner.hide()
      this.chartOfAcc = resp['data']
    } else {
      this.spinner.hide()
    }
  }
  async post(element:any) {
    this.spinner.show()
    let new_obj:any = new Object()
    new_obj['b_acct_id'] = this.b_acct_id
    new_obj['jrnl_id'] = element['jrnl_id']
    new_obj['ld_jrnl_lfcycl_st_cd'] = 'POSTED'

    var resp = await this.jvService.UpdateStatus(new_obj)

    if (resp['error'] == false) {


      await this.getAllUnPostedJournalInfo()

    } else {
      this.spinner.hide()
    }


  }

  postedJournals:any = []
  unpostedJournals:any = []
  async getAllUnPostedJournalInfo() {
    var obj = Object.assign({}, this.obj);
    obj['b_acct_id'] = this.b_acct_id;


    var resp :any= await this.ledgerService.getJournalList(JSON.stringify(obj));
    console.log(resp)

    if (resp['error'] == false) {
      this.postedJournals = []
      this.unpostedJournals = []
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['ld_jrnl_lfcycl_st_cd'] == 'POSTED') {
          this.postedJournals.push(resp['data'][i])
        } else {
          this.unpostedJournals.push(resp['data'][i])
        }
      }
      console.log('unpostedJournals : ', this.unpostedJournals)
      console.log('postedJournals : ', this.postedJournals)
      this.datasource = new MatTableDataSource(this.unpostedJournals)
      this.datasource.paginator = this.paginator1;
      this.datasource.sort = this.sortCol1;
      this.dataSource1 = new MatTableDataSource(this.postedJournals);
      this.dataSource1.sort = this.sortCol2;
      this.dataSource1.paginator = this.paginator2;
      this.spinner.hide();
      await this.refresh();

    } else {
      this.spinner.hide()
      // this.snackBar.open("Error while getting  all Unposted Journal info list", 'Error', {
      //   duration: 5000
      // });
      Swal.fire('ERROR',"Error while getting  all Unposted Journal info list",'error');
    }

  }




  applyFilter(filterValue: string) {

    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  applyFilter1(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  async reject(element:any) {
    this.spinner.show()
    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['ld_jrnl_lfcycl_st_cd'] = 'REJECTED'
    obj['jrnl_id'] = element['jrnl_id']

    var resp = await this.jvService.UpdateStatus(obj)
    if (resp['error'] == false) {
      // await this.postedJV()
      this.spinner.hide()
      await this.getAllUnPostedJournalInfo();

      Swal.fire('This Record Successfully Rejected', '', 'success')
    }
    else {
      this.spinner.hide()
      Swal.fire('Some Error Occured While Rejecting', '', 'error')

    }
  }
  async delete(element:any) {
    let new_obj:any = new Object()
    new_obj['b_acct_id'] = this.b_acct_id
    new_obj['jrnl_id'] = element['jrnl_id']
    var resp = await this.jvService.deleteJV(JSON.stringify(new_obj))
    if (resp['error'] == false) {
      await this.getAllUnPostedJournalInfo()
      this.spinner.hide()
      Swal.fire('Journal deleted Successfully', '', 'success')
    }
    else {
      this.spinner.hide()
      Swal.fire('Error Occured', '', 'error')
    }
  }

  async approveJV(element:any) {
    this.spinner.show()
   let new_obj :any= new Object()
    new_obj['b_acct_id'] = this.b_acct_id
    new_obj['jrnl_id'] = element['jrnl_id']
    new_obj['ld_jrnl_lfcycl_st_cd'] = 'APPROVED'



    var resp = await this.jvService.UpdateStatus(new_obj)

    if (resp['error'] == false) {

      // for (let i = 0; i < resp['data'].length; i++) {
      //   let ob = new Object()
      //   if (resp['data'][i]['acct_dt']) {
      //     ob['acct_dt'] = resp['data'][i]['acct_dt'].split('T')[0]
      //   }
      //   ob['ld_jrnl_lfcycl_st_cd']=resp['data'][i]['ld_jrnl_lfcycl_st_cd']
      //   ob['arr_id'] = resp['data'][i]['arr_id']
      //   ob['chart_of_account'] = resp['data'][i]['chart_of_account']
      //   ob['create_timestamp'] = resp['data'][i]['create_timestamp']
      //   ob['db_cd_ind'] = resp['data'][i]['db_cd_ind']
      //   ob['event_code'] = resp['data'][i]['event_code']
      //   ob['event_id'] = resp['data'][i]['event_id']
      //   ob['event_ln_id'] = resp['data'][i]['event_ln_id']
      //   ob['fin_year'] = resp['data'][i]['fin_year']
      //   ob['jrnl_desc'] = resp['data'][i]['jrnl_desc']
      //   ob['appr_id'] = resp['data'][i]['appr_id']
      //   ob['jrnl_desc'] = resp['data'][i]['jrnl_desc']
      //   ob['jrnl_dtl_ln_id'] = resp['data'][i]['jrnl_dtl_ln_id']
      //   ob['jrnl_id'] = resp['data'][i]['jrnl_id']
      //   ob['jrnl_line_desc'] = resp['data'][i]['jrnl_line_desc']
      //   ob['jrnl_ln_id'] = resp['data'][i]['jrnl_ln_id']
      //   ob['jrnl_type'] = resp['data'][i]['jrnl_type']
      //   ob['ledger_type'] = resp['data'][i]['ledger_type']
      //   ob['org_unit_cd'] = resp['data'][i]['org_unit_cd']
      //   ob['prep_id'] = resp['data'][i]['prep_id']
      //   if (resp['data'][i]['proc_dt']) {
      //     ob['proc_dt'] = resp['data'][i]['proc_dt'].split('T')[0]
      //   }
      //   ob['tgt_curr_cd'] = resp['data'][i]['tgt_curr_cd']
      //   ob['txn_amt'] = resp['data'][i]['txn_amt']
      //   console.log('ob',ob)
      //   console.log('id',resp['data'][i]['id'])
      //   new_obj['jrnl'].push(ob)
      //   new_obj['id'].push(resp['data'][i]['id'])
      //   console.log('new_obj',new_obj)
      // }
      await this.getAllUnPostedJournalInfo()

    } else {
      this.spinner.hide()
    }


  }
  async schedule(element:any) {
    this.spinner.show()
    let obj :any= new Object();
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'UNDER APPROVAL'
    obj['jrnl_id'] = element['jrnl_id']
    var resp = await this.jvService.UpdateStatus(obj)
    if (resp['error'] == false) {
      await this.getAllUnPostedJournalInfo()
      this.spinner.hide()
      Swal.fire('This Record Successfully Scheduled', '', 'success')
    }
    else {
      this.spinner.hide()
      Swal.fire('Some Error Occured While Scheduling', '', 'error')
    }
  }

  table_header = [{ key: 'chart_acct', value: 'Account Head', width: 20 }, { key: 'jrnl_line_desc', value: 'Perticulars', width: 10 }, { key: 'db', value: 'Debit(DB)', width: 10 }, { key: 'cr', value: 'Credit(CR)', width: 10 }];
  emb_desc:any;
  emb_no:any;
  emb_date:any
  work_order_no:any
  project_cd:any
  print_data:any = []
  view_header :any= {}
  new_data:any = []
  async view(element:any) {
    this.new_data = []
    this.view_header = Object.assign({}, element)
    let acct_date = '';
    this.print_data = []
    this.spinner.show()
   let new_obj :any= new Object()
    new_obj['b_acct_id'] = this.b_acct_id
    new_obj['jrnl_id'] = element['jrnl_id']
    new_obj['ar_actvty_tcd'] = element.ar_actvty_tcd;
    new_obj['ar_actvty_lcl_num'] = element.ar_actvty_lcl_num;
    // new_obj['filter_status'] = element.ld_jrnl_lfcycl_st_cd
    let status = ''
    if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'UNPOSTED') {
      status = 'Un-Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'POSTED') {
      status = 'Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'UNDER APPROVAL') {
      status = 'Under Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'REJECTED') {
      status = 'Rejected'
    }
    else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'APPROVED') {
      status = 'Approved'
    }

    this.view_header['status'] = status
    console.log("Data is On click of view button ", new_obj)
    var resp = await this.jvService.get_Jrnl_Id_Full_Info(JSON.stringify(new_obj))
    if (resp['error'] == false) {
      this.spinner.hide()
    } else {
      this.spinner.hide()
    }

    for (let i = 0; i < resp['data'].length; i++) {
      for (let j = 0; j < this.chartOfAcc.length; j++) {
        if (resp['data'][i]['chart_of_account'] == this.chartOfAcc[j]['leaf_code']) {
          resp['data'][i]['chart_acct'] = this.chartOfAcc[j]['leaf_value'] + ' (' + this.chartOfAcc[j]['leaf_code'] + ')'
          if (!acct_date) {
            acct_date = resp['data'][i]['acct_dt'].split('T')[0]
          }
          resp['data'][i]['ld_jrnl_ln_desc'] = ''
          // if (resp['data'][i]['ld_jrnl_ln_desc']) {
          //   resp['data'][i]['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
          // } else {
          //   resp['data'][i]['ld_jrnl_ln_desc'] = ''
          // }
        }
      }
    }
    this.view_header['ref_dt'] = acct_date
    for (let i = 0; i < resp['data'].length; i++) {
      let obj3:any = {}

      if (resp['data'][i]['db_cd_ind'] == 'DB') {
        obj3['db'] = -1 * resp['data'][i]['txn_amt']
        obj3['cr'] = 0
        obj3['chart_acct'] = resp['data'][i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
      }
      else if (resp['data'][i]['db_cd_ind'] == 'CR') {
        obj3['cr'] = resp['data'][i]['txn_amt']
        obj3['db'] = 0
        obj3['chart_acct'] = resp['data'][i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
      }
      this.print_data.push(obj3)
    }
    let ar:any = [];

    for (let i = 0; i < this.print_data.length; i++) {
      let db:any = 0
      let cr :any= 0
      let obj:any = {};


      for (let j = 0; j < this.print_data.length; j++) {

        if (this.print_data[i]['chart_acct'] == this.print_data[j]['chart_acct'] && this.print_data[i]['ld_jrnl_ln_desc'] == this.print_data[j]['ld_jrnl_ln_desc']) {
          db = this.print_data[j]['db'] + db
          cr = this.print_data[j]['cr'] + cr
          obj['db'] = db
          obj['cr'] = cr
          obj['chart_acct'] = this.print_data[i]['chart_acct']
          obj['ld_jrnl_ln_desc'] = this.print_data[i]['ld_jrnl_ln_desc']
        }
      }
      if (!ar.includes(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])) {
        this.new_data.push(obj);
      }
      ar.push(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])


    }
    this.new_data.map((x:any) => {
      if (x['db'] > x['cr']) {
        x['db'] = x['db'] - x['cr']
        x['cr'] = 0
      } else {
        x['cr'] = x['cr'] - x['db']
        x['db'] = 0
      }
    })

    let db2 = 0;
    let cr2 = 0;
    for (let i = 0; i < this.new_data.length; i++) {
      db2 = db2 + this.new_data[i]['db']
      cr2 = cr2 + this.new_data[i]['cr']
    }


    let modified_data :any= []
    this.new_data.map((x:any) => {
      if (x['db'] > 0) {
        modified_data.push(x);
      }
    })
    this.new_data.map((x:any) => {
      if (x['cr'] > 0) {
        modified_data.push(x);
      }
    })


    this.new_data = modified_data;
    console.log(modified_data)
    let no:any = {}
    no['db'] = db2
    no['cr'] = cr2
    no['chart_acct'] = ''
    no['ld_jrnl_ln_desc'] = 'Total Amount'
    this.new_data.push(no)
    $('#exampleModal').modal('show');
  }

  async refresh() {
   let new_obj :any= new Object()
    new_obj = {}

  }

  async show(element:any) {

    this.new_data = []
    this.view_header = Object.assign({}, element)
    let acct_date = '';
    this.print_data = []
    this.spinner.show()
   let new_obj :any= new Object()
    new_obj['b_acct_id'] = this.b_acct_id
    new_obj['jrnl_id'] = element['jrnl_id']
    let status = ''
    if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'UNPOSTED') {
      status = 'Un-Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'POSTED') {
      status = 'Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'UNDER APPROVAL') {
      status = 'Under Posted'
    } else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'REJECTED') {
      status = 'Rejected'
    }
    else if (this.obj['ld_jrnl_lfcycl_st_cd'] == 'APPROVED') {
      status = 'Approved'
    }

    this.view_header['status'] = status
    var resp = await this.jvService.get_Jrnl_Id_Full_Info(JSON.stringify(new_obj))
    if (resp['error'] == false) {
      this.spinner.hide()
    } else {
      this.spinner.hide()
    }

    for (let i = 0; i < resp['data'].length; i++) {
      for (let j = 0; j < this.chartOfAcc.length; j++) {
        if (resp['data'][i]['chart_of_account'] == this.chartOfAcc[j]['leaf_code']) {
          resp['data'][i]['chart_acct'] = this.chartOfAcc[j]['leaf_value'] + ' (' + this.chartOfAcc[j]['leaf_code'] + ')'
          if (!acct_date) {
            acct_date = resp['data'][i]['acct_dt'].split('T')[0]
          }
          resp['data'][i]['ld_jrnl_ln_desc'] = ''
          // if (resp['data'][i]['ld_jrnl_ln_desc']) {
          //   resp['data'][i]['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
          // } else {
          //   resp['data'][i]['ld_jrnl_ln_desc'] = ''
          // }
        }
      }
    }
    this.view_header['ref_dt'] = acct_date
    for (let i = 0; i < resp['data'].length; i++) {
      let obj3:any = {}

      if (resp['data'][i]['db_cd_ind'] == 'DB') {
        obj3['db'] = resp['data'][i]['txn_amt']
        obj3['cr'] = 0
        obj3['chart_acct'] = resp['data'][i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
      }
      else if (resp['data'][i]['db_cd_ind'] == 'CR') {
        obj3['cr'] = resp['data'][i]['txn_amt']
        obj3['db'] = 0
        obj3['chart_acct'] = resp['data'][i]['chart_acct']
        obj3['ld_jrnl_ln_desc'] = resp['data'][i]['ld_jrnl_ln_desc']
      }
      this.print_data.push(obj3)
    }
    // let ar = []
    // for (let i = 0; i < this.print_data.length; i++) {
    //   let db = 0
    //   let cr = 0
    //   let obj :any= {}
    //   ar.push(this.print_data[i]['chart_acct'])
    //   for (let j = 0; j < this.print_data.length; j++) {
    //     if (this.print_data[i]['chart_acct'] == this.print_data[j]['chart_acct'] && this.print_data[i]['cr'] == this.print_data[j]['cr'] && this.print_data[i]['db'] == this.print_data[j]['db'] && this.print_data[i]['ld_jrnl_ln_desc'] == this.print_data[j]['ld_jrnl_ln_desc'] && !ar.includes(this.print_data[i]['chart_acct'])) {
    //       obj['db'] = this.print_data[j]['db'] + db
    //       obj['cr'] = this.print_data[j]['cr'] + cr
    //       obj['chart_acct'] = this.print_data[i]['chart_acct']
    //       obj['ld_jrnl_ln_desc'] = this.print_data[i]['ld_jrnl_ln_desc']
    //     } else {
    //       obj['db'] = this.print_data[i]['db']
    //       obj['cr'] = this.print_data[i]['cr']
    //       obj['chart_acct'] = this.print_data[i]['chart_acct']
    //       obj['ld_jrnl_ln_desc'] = this.print_data[i]['ld_jrnl_ln_desc']
    //     }
    //   }
    //   this.new_data.push(obj)
    // }


    let ar:any = [];
    for (let i = 0; i < this.print_data.length; i++) {
      let db :any= 0
      let cr:any = 0
      let obj :any= {};
      for (let j = 0; j < this.print_data.length; j++) {

        if (this.print_data[i]['chart_acct'] == this.print_data[j]['chart_acct'] && this.print_data[i]['ld_jrnl_ln_desc'] == this.print_data[j]['ld_jrnl_ln_desc']) {
          db = this.print_data[j]['db'] + db
          cr = this.print_data[j]['cr'] + cr
          obj['db'] = db
          obj['cr'] = cr
          obj['chart_acct'] = this.print_data[i]['chart_acct']
          obj['ld_jrnl_ln_desc'] = this.print_data[i]['ld_jrnl_ln_desc']
        }
      }
      if (!ar.includes(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])) {
        this.new_data.push(obj);
      }
      ar.push(this.print_data[i]['chart_acct'] + this.print_data[i]['ld_jrnl_ln_desc'])
    }

    this.new_data.map((x:any) => {
      if (x['db'] > x['cr']) {
        x['db'] = x['db'] - x['cr']
        x['cr'] = 0
      } else {
        x['cr'] = x['cr'] - x['db']
        x['db'] = 0
      }
    })

    let db2:any = 0;
    let cr2:any = 0
    for (let i = 0; i < this.new_data.length; i++) {
      db2 = db2 + this.new_data[i]['db']
      cr2 = cr2 + this.new_data[i]['cr']
    }
    let no :any= {}
    no['db'] = db2
    no['cr'] = cr2
    no['chart_acct'] = ''
    no['ld_jrnl_ln_desc'] = 'Total Amount'
    this.new_data.push(no)
    // ------------
    var txt = this.mainS.accInfo['account_name'] + '(' + this.mainS.accInfo['account_short_name'] + ')'
    var dd:any = {
      pageSize: 'A4',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },

      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [
      ]
    };
    var header0 = {
      columns: [
        {
          width: '*',
          text: 'JOURNAL VOUCHER',
          bold: true,
          alignment: 'center'
        }
      ],

    }

    var header1 = {
      columns: [
        {
          width: '*',
          text: 'Ref Voucher :',
          bold: true
        },

        {
          width: '*',
          text: 'JV -' + element['jrnl_id']
        },
        {
          width: '*',
          text: 'Ref. Voucher :',
          bold: true
        },

        {
          width: '*',
          text: acct_date
        },
        {
          width: '*',
          text: 'Print Date :',
          bold: true
        },

        {
          width: '*',
          text: this.mainS.dateFormatChange(this.systemDate)
        }
      ],
    }
    var header2 = {
      columns: [
        {
          width: '*',
          text: 'Particular :',
          bold: true

        },
        {
          width: '*',
          text: element['jrnl_desc'],


        },
        {
          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        }
      ],
    }
    var header3 = {
      columns: [

        {
          width: '*',
          text: 'JV Status :',
          bold: true
        },

        {
          width: '*',
          text: status
        },
        {
          width: '*',
          text: '',
          bold: true
        },

        {
          width: '*',
          text: ''
        }
      ],
    }
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header0);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    dd.content.push({ text: " " });
    dd.content.push(header1);
    dd.content.push({ text: " " });
    dd.content.push(header2);
    dd.content.push({ text: " " });
    dd.content.push(header3);
    dd.content.push({ text: " " });
    dd.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.05 }] });
    var tbl = {

      layout: 'lightHorizontalLines',
      fontSize: 10,
      table: {

        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: [
          ['Chart Of Account', 'Perticulars', 'Debit', 'Credit']
        ]
      }
    };
    dd.content.push(tbl);
    for (var i = 0; i < this.new_data.length; i++) {
      var arr = []
      arr.push(this.new_data[i]['chart_acct']);
      arr.push(this.new_data[i]['ld_jrnl_ln_desc']);
      arr.push({ text: this.new_data[i]['db'], alignment: 'right' });
      arr.push({ text: this.new_data[i]['cr'], alignment: 'right' });
      dd.content[dd.content.length - 1].table.body.push(arr);
    }
    pdfMake.createPdf(dd).download("jv");
  }
}
