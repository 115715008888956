import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../service/setting.service';
import { MainService } from '../../service/main.service';
declare var $: any
import swal from 'sweetalert2';

@Component({
  selector: 'app-city-matrix',
  templateUrl: './city-matrix.component.html',
  styleUrls: ['./city-matrix.component.css']
})
export class CityMatrixComponent implements OnInit {

  constructor(public mainService: MainService, private settingService: SettingService, private router: Router, private spinner: NgxSpinnerService, private snackBar: MatSnackBar) { }
  erpUser: any;
  b_acct_id: any;
  errorMsg: any = ''

  allMatrix: any = [];
  cityMatrix: any = {};


  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.datasource) {
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.datasource) {
      this.datasource.sort = value;
    }
  }

  displayedColumns :any = ['id', 'city_group', 'city', 'action'];
  datasource:any ;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllCityMatrix();
  }
  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
  }
  open_create() {
    this.cityMatrix = {}
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }
  open_update(element:any ) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    this.cityMatrix = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.cityMatrix = {};
    this.errorMsg = ''
  }

  async getAllCityMatrix() {
    this.spinner.show()
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    var resp = await this.settingService.getCityMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.allMatrix = resp.data;
      this.datasource = new MatTableDataSource(this.allMatrix)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();

    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting  all fields list ', 'error')
      // this.snackBar.open("Error while getting  all fields list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async save() {
    this.errorMsg = ''
    if (
      this.cityMatrix['city_group'] == "" || this.cityMatrix['city_group'] == undefined
      || this.cityMatrix['city'] == "" || this.cityMatrix['city'] == undefined
    ) {
      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsave()
        }
      })


    }
  }
  async finalsave() {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['b_acct_id'] = this.b_acct_id;

    this.cityMatrix['create_user_id'] = this.erpUser.user_id;
    this.cityMatrix['b_acct_id'] = this.b_acct_id;
    obj['matrix_data'] = [this.cityMatrix];
    var resp = await this.settingService.addCityMatrix(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCityMatrix();
      swal.fire("Success", "City Matrix Added Successfully!", 'success');
    } else {
      this.spinner.hide();

      swal.fire("Sorry", "..Error while Adding City Matrix!", 'error');

    }
  }

  async update() {
    this.errorMsg = ''
    if (
      this.cityMatrix['city_group'] == "" || this.cityMatrix['city_group'] == undefined
      || this.cityMatrix['city'] == "" || this.cityMatrix['city'] == undefined
    ) {

      this.errorMsg = "* fields required."
    }

    else {

      swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdate()
        }
      })


    }
  }


  async finalupdate() {
    this.spinner.show();
    this.cityMatrix['update_user_id'] = this.erpUser.user_id;
    this.cityMatrix['b_acct_id'] = this.b_acct_id;
    var resp = await this.settingService.updateCityMatrix(this.cityMatrix);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCityMatrix();
      swal.fire("Success", "City Matrix Update Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Updating City Matrix!", 'error');

    }
  }

  async delete(element:any ) {
    this.spinner.show();
    var obj :any  = new  Object();
    obj['id'] = element.id;
    obj['b_acct_id'] = this.b_acct_id;
    console.log(obj)
    var resp = await this.settingService.deleteCityMatrix(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllCityMatrix();
      swal.fire("Success", "City Matrix Delete Successfully!", 'success');
    } else {
      this.spinner.hide();
      swal.fire("Sorry", "..Error while Deleting City Matrix!", 'error');
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }
}
