<div class="page-content fade-in-up">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="ibox">
                <div class="ibox-head">
                    <div class="ibox-title">
                      SHOW ALL DATA
                    </div>
                </div>
                <div class="ibox-body">
                    <ul class="nav nav-tabs tabs-line">
                        <!-- <li class="nav-item">
                            <a class="nav-link active" href="#tab-1"  (click)="refres()"
                                data-toggle="tab"><i class="ti-bar-chart"></i>
                                SHOW ALL DATA
                            </a>
                        </li> -->
                        <br>
                        <br>
                        <li class="nav-item">
                            <a class="nav-link active" href="#tab-3"  (click)="refres()"
                                data-toggle="tab"><i class="ti-bar-chart"></i>
                                DOWNLOAD EXCEL FORMAT
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="#tab-2"  (click)="refres()"
                                data-toggle="tab"><i class="ti-bar-chart"></i>
                                UPLOAD DATA
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade "  id="tab-1">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    <h6>
                                        Select Authority

                                    </h6>
                                </div>
                                <div class="col-4">

                                    <ng-select [items]="AllAccountData" bindLabel="acct_desc" bindValue="b_acct_id" [multiple]="false" placeholder="{{auth.allLableShowObj[auth.language_cd+'LOGIN14']}}"  [selectableGroup]="true" [selectableGroupAsModel]="false"
                                    [closeOnSelect]="true" name="b_acct_id"  [(ngModel)]="objauth['auth']">
                                </ng-select>

<!--
                                    <ng-select [items]="auth"  bindLabel="code"
                                        bindValue="value" [multiple]="false" [(ngModel)]="objauth['auth']"
                                         [selectableGroup]="true" [selectableGroupAsModel]="false"
                                        [closeOnSelect]="true" name="field_name">
                                    </ng-select> -->
                                </div>
                            </div>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Component
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allcomp" (change)="changecomp()" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [selectableGroup]="true" [(ngModel)]="objcomp['comp']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                </div>
                            <!-- <input type="file" style="display: inline-block;" (change)="onFileChange($event)" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location107']}}" accept=".xls"> -->
                            </div><br>

                            <div class="row " style="margin-left: 50%;" >
                                <button class="btn btn-primary" *ngIf="join_flag" (click)="submit()">Submit</button>&nbsp;&nbsp;
                                <button class="btn btn-primary" *ngIf="establish_flag" (click)="submitestablish()">Submit</button>&nbsp;&nbsp;

                                <button class="btn btn-primary" *ngIf="join_flag" (click)="excl()">DOWNLOAD EXCEL</button>
                                <button class="btn btn-primary" *ngIf="establish_flag" (click)="exclestablish()">DOWNLOAD EXCEL</button>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12 text-left">
                                    <mat-form-field style="margin-left: 2%">
                                        <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="filter">
                                    </mat-form-field>
                                </div>
                            </div>



                            <div class="example-container" style="text-size-adjust: 2;">
                                <table mat-table [dataSource]="datasource" matSort>

                                    <!-- <ng-container matColumnDef="le_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Person Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.le_id}}
                                        </td>
                                    </ng-container> -->

                                    <ng-container matColumnDef="emp_id">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Employee Id
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element["emp_id"]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_name">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Employee Name
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element["emp_name"]}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_dob">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Employee Dob
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <!-- {{element['emp_dob']}} -->

                                            {{element.emp_dob | date: 'dd/MM/yyyy'}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_email">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Email
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['emp_email']}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="emp_phone_no">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Phone
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element['emp_phone_no']}}
                                        </td>
                                    </ng-container>
                                    <!-- <ng-container matColumnDef="emp_fun">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Assigned Function Area
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdAssignedModule[element.arr_id]}}
                                        </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="cost_center">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Assigned Cost Center
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{empIdPost[element.arr_id]}}
                                        </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.emp_status}}
                                        </td>
                                    </ng-container> -->
                                    <!-- <ng-container matColumnDef="effective_timestamp" style="width: 10%;">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Status Date
                                        </th>
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            {{element.valid_from | date: 'dd/MM/yyyy'}}
                                        </td>
                                    </ng-container> -->
                                    <ng-container matColumnDef="action" style="width: 5%;">
                                        <th mat-header-cell *matHeaderCellDef class="right" mat-sort-header>
                                            Action</th>
                                        <!--Action-->
                                        <td mat-cell flex-stretch *matCellDef="let element" class="right">
                                            <button class="btn btn-outline-primary" (click)="open_modal(element)">Add Employee</button>

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

                            </div>
<!--
                            <div class="row " style="margin-left: 50%;" >
                                <button class="btn btn-primary" (click)="submitalldata()">Submit All Data</button>&nbsp;&nbsp;

                            </div> -->
                            <br>
                        </div>

                        <div class="tab-pane fade show active"  id="tab-3">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Component
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allcomp" (change)="changecomp()" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [selectableGroup]="true" [(ngModel)]="objcomp['comp']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col-lg-12 text-center">
                                    <button class="btn btn-primary" *ngIf="join_flag"  (click)="DOWNLOADjoin()">DOWNLOAD FORMAT</button>&nbsp;&nbsp;
                                    <button class="btn btn-primary" *ngIf="establish_flag"  (click)="DOWNLOADest()">DOWNLOAD FORMAT</button>&nbsp;&nbsp;

                                </div>

                            </div><br>
                        </div>


                        <div class="tab-pane fade "  id="tab-2">
                            <br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Select Component
                                </div>
                                <div class="col-4">
                                    <ng-select [items]="allcomp" (change)="changecomp()" bindLabel="value" bindValue="code" [multiple]="false" placeholder="" [selectableGroup]="true" [(ngModel)]="objcomp['comp']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="true" name="field_name">
                                            </ng-select>
                                </div>
                            <!-- <input type="file" style="display: inline-block;" (change)="onFileChange($event)" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location107']}}" accept=".xls"> -->
                            </div><br>
                            <div class="row">
                                <div class="col-4 text-right">
                                    Upload Excel
                                </div>
                                <div class="col-4">
                                    <input type="file" style="display: inline-block;" (change)="onFileChange($event)" accept=".xls">
                                </div>
                            <!-- <input type="file" style="display: inline-block;" (change)="onFileChange($event)" placeholder="{{auth.allLableShowObjProp[auth.language_cd+'Location107']}}" accept=".xls"> -->
                            </div>

                            <br>
                            <div class="row ">
                                <div class="col-12 text-center">
                                <button class="btn btn-primary" *ngIf="join_flag" (click)="submitalldata()">Submit</button>&nbsp;&nbsp;
                                <button class="btn btn-primary" data-dismiss="modal" *ngIf="establish_flag" (click)="allDone()">Submit All Data </button>&nbsp;&nbsp;
                                <!-- <button class="btn btn-primary" *ngIf="join_flag" (click)="submitdata()">Submit</button>&nbsp;&nbsp; -->
                                </div>
                            </div>
                            <br>




                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- MODAL -->
<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <!-- <div class="modal-header">
                <h4 class="modal-title">Challan</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div> -->

            <!-- Modal body -->
            <div class="modal-body">

                <br>


                <div class="row">
                    <div class="col-12 text-center">
                        An employee with searched email or phone no. already exists,Please search with a different email and phone no. to add a new employee.
                    </div>
                </div>

                <br>
                <br>
                                    <!-- <div class="row">
                                        <div class="col-3 text-left">
                                            <h6 style="margin-left: 2%;">
                                                Functional Area :
                                            </h6>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="allModule" (change)="selectedModule(joiningObj['module_cd'])" bindLabel="module_name" bindValue="module_cd" [multiple]="true" placeholder="" [selectableGroup]="true" [(ngModel)]="joiningObj['module_cd']" [selectableGroupAsModel]="false"
                                                [closeOnSelect]="false" name="field_name">
                                            </ng-select>
                                        </div>
                                        <br>
                                    </div> -->
                                    <br>
                <br>
                <div class="row">
                    <div class="col-3 text-left">
                        <h6 style="margin-left: 2%;">
                            Select Node :
                        </h6>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary" (click)="open_activitypopup()">Select Employee Node</button>
                    </div>
                    <br>
                    <div class="col-3 text-left">
                        <h6 style="margin-left: 2%;">
                            Selected Node Path:
                        </h6>
                    </div>
                    <div class="col-3 text-left">
                        {{Obj['path_desc']}}
                    </div>
                </div>
                <br>
                <br>
                <br>



                <div class="modal-footer">
                    <!-- <button class="btn btn-primary" data-dismiss="modal"  (click)="submitnodedata()">Submit All Data</button>&nbsp;&nbsp; -->

                     <button class="btn btn-primary" data-dismiss="modal" *ngIf="join_flag" (click)="submitalldata()">Submit All Data</button>&nbsp;&nbsp;
                    <!-- <button class="btn btn-primary" data-dismiss="modal" *ngIf="establish_flag" (click)="submitestablish()">Submit All Data </button>&nbsp;&nbsp; -->

                    <button type="button" class="btn btn-danger" data-dismiss="modal">CANCEL</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="ACTHIER">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Select Employee Node</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <div class="row" *ngFor="let item of planing_arrproj,let i =index">
                    <div class="col-3"></div>
                    <div class="col-3" *ngIf="i==0">
                        <h6 style="margin-left: 2%;">Select Node : <span style="color: red;"> *</span></h6>
                        <!-- -->
                    </div>
                    <div class="col-3" *ngIf="i!=0"></div>
                    <div class="col-1">

                        <span *ngIf="item['level']"> Level </span>{{item['level']}}
                        <!---  -->
                    </div>
                    <div class="col-3">
                        <ng-select [items]="item['existing_heirarchy_nodes']" bindLabel="desc" bindValue="node_cd" [multiple]="false" placeholder=" Select  Node" [(ngModel)]="item['hierarchy_type']" [selectableGroup]="true" (change)="setHierarchyNodeitem(item,i)" [selectableGroupAsModel]="true"
                            [closeOnSelect]="true">
                        </ng-select>

                    </div>
                    <div class="col-2" *ngIf="i==planing_arrproj.length-1">
                        <button (click)="down(item,i)" class="btn btn-primary"><i class="fa fa-arrow-down"
                                aria-hidden="true"></i></button> &nbsp;&nbsp;
                        <button (click)="remove(item,i)" class="btn btn-danger">
                            <i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-3"></div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-3 ">
                        <h6 style="margin-left: 2%;"> <span style="color: red;">*</span> Current Node Path :
                        </h6>
                    </div>
                    <div class="col-6">
                        {{Obj['path_desc']}}
                    </div>

                </div>
                <br>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="submit_act()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div>



<!-- <div class="modal" id="ACTHIER1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">


            <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <br>
                <br>
                <div class="row" >
                    <br>

                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                            Currently Active Pay Component :
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline">
                    </div>

                    <br>
                    <div class="col-12 text-center">
                        <div class="row">
                            <div class="col-1 r1 text-center">
                                <h6>
                                    S.No

                                </h6>
                            </div>
                            <div class="col-2 r1 text-center">
                                <h6>
                                    Salary Component

                                </h6>
                            </div>
                            <div class="col-1 r1 text-center">
                                <h6>
                                    Amount

                                </h6>
                            </div>
                            <div class="col-1 r1 text-center">
                                <h6>
                                    Rate
                                </h6>
                            </div>
                            <div class="col-1 r1 text-center">
                                <h6>
                                    Currency
                                </h6>
                            </div>
                            <div class="col-1 r1 text-center">
                                <h6>
                                    Type

                                </h6>
                            </div>


                            <div class="col-2 r1 text-center">
                                <h6>
                                    Effective Start Date
                                </h6>
                            </div>
                            <div class="col-2 r1 text-center">
                                <h6>
                                    Status
                                </h6>
                            </div>
                        </div>
                        <div class="row" *ngFor="let ob of allSalComp; let i = index">

                            <div class="col-1 r1 text-center">
                                {{i+1}}
                            </div>
                            <div class="col-2 r1 text-center">

                                {{ob.pay_comp}}
                            </div>
                            <div class="col-1 r1 text-center">
                                {{ob.rate}}
                            </div>

                            <div class="col-1 r1 text-center">
                                Monthly
                            </div>
                            <div class="col-1 r1 text-center">
                                INR
                            </div>
                            <div class="col-1 r1 text-center">
                                {{ob.pay_code}}
                            </div>

                            <div class="col-2 r1 text-center">
                                {{ob.valid_from}}
                            </div>

                            <div class="col-2 r1 text-center">

                                {{state[ob.status]}}
                            </div>
                        </div>
                    </div>
                </div>

                <br>
                <hr>
                <br>
                <br>
                <div class="row">
                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                    <div class="col-2 text-center">
                        <h6 id="h" style="color: brown; min-height: 20px;">
                            New Proposed Pay Component :
                        </h6>
                    </div>
                    <div class="col-5">
                        <hr class="hrline">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-1 r1 text-center">
                        <h6>
                            S.No

                        </h6>
                    </div>
                    <div class="col-2 r1 text-center">
                        <h6>
                            Salary Component

                        </h6>
                    </div>
                    <div class="col-1 r1 text-center">
                        <h6>
                            Amount

                        </h6>
                    </div>
                    <div class="col-1 r1 text-center">
                        <h6>
                            Rate
                        </h6>
                    </div>
                    <div class="col-1 r1 text-center">
                        <h6>
                            Currency
                        </h6>
                    </div>
                    <div class="col-1 r1 text-center">
                        <h6>
                            Type

                        </h6>
                    </div>


                    <div class="col-2 r1 text-center">
                        <h6>
                            Effective Start Date
                        </h6>
                    </div>
                    <div class="col-2 r1 text-center">
                        <h6>
                            Status
                        </h6>
                    </div>
                </div>
                <div class="row" *ngFor="let ob of AllFixPay; let i = index">

                    <div class="col-1 r1 text-center">
                        {{i+1}}
                    </div>
                    <div class="col-2 r1 text-center">
                        {{ob.pay_comp}}
                    </div>
                    <div class="col-1 r1 text-center">
                        {{ob.rate}}
                    </div>

                    <div class="col-1 r1 text-center">
                        Monthly
                    </div>
                    <div class="col-1 r1 text-center">
                        INR
                    </div>
                    <div class="col-1 r1 text-center">
                        {{ob.pay_code}}
                    </div>

                    <div class="col-2 r1 text-center">
                        {{addEstObj['start_dt']}}
                    </div>
                    <div class="col-2 r1 text-center">
                        <input class="form-control" type="checkbox" [(ngModel)]="ob['status']">
                    </div>

                </div>
                <br>

                <div class="modal-footer">
                    <button class="btn btn-primary" (click)="allDone()">Submit</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">CLOSE</button>
                </div>

            </div>
        </div>
    </div>
</div> -->
<ngx-spinner>Loading....</ngx-spinner>
