import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { AllEmpService } from '../../service/all-emp.service';
import { MainService } from '../../service/main.service';
import { DataAccessService } from '../../service/data-access.service';
declare var $: any
import swal from 'sweetalert2';


@Component({
  selector: 'app-dependent-info',
  templateUrl: './dependent-info.component.html',
  styleUrls: ['./dependent-info.component.css']
})
export class DependentInfoComponent implements OnInit {

  constructor(private dataAccessService: DataAccessService, public mainService: MainService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, private allEmpService: AllEmpService) { }
  personalInfoObj :any= {};
  errorMsg :any= ''
  erpUser:any;
  user_id:any;
  b_acct_id:any;
  allEmployees :any= [];
  selectEmpObj :any= {};
  selectedEmpEducation :any= [];
  eductionObj :any= {};
  dependentObj  :any ={};
  allEmplyees_new :any= []
  selectedEmpDependent:any = [];
  delEl:any;
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.datasource){
      this.datasource.paginator = value;
    }
  }
  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.datasource){
      this.datasource.sort = value;
    }
  }

  displayedColumns :any= ['dependent_name', 'relation_code', 'action'];
  datasource:any;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    await this.dataAccessService.getAssignedEmpInfo1(this.b_acct_id,this.user_id,true);
    await this.getAllEmployees();
    // this.selectEmpObj['le_id'] = this.erpUser.user_id;
    await this.changeEmployee();

  }
  getNumberFormat(num:any) {
    return num.toString().padStart(3, "0")
  }
  async getAllEmployees1() {
    var arr = []
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getEmployeeMasterData(JSON.stringify(obj));
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      this.allEmployees = []


      var assigned_emp = this.dataAccessService.assignedEmp2;
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id'])
        if (assigned_emp[arr[i]['emp_id']] != undefined) {
          this.allEmployees.push(obj)
        }
      }
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] =/* this.mainService.accInfo['account_short_name']+ */ this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }
    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }
  async getAllEmployees() {
    this.spinner.show()
    var arr = []
    this.allEmployees = []
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['emp_status'] = 'ACTIVE';

    var resp = await this.allEmpService.getAllPartyFields(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      // this.allEmployees = arr;
      this.allEmployees=[];
      var assignedEmp= this.dataAccessService.assignedEmp2;
      console.log(assignedEmp)
      for (let i = 0; i < arr.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, arr[i]);
        obj['tempid'] = this.getNumberFormat(obj['emp_id']);
        if(assignedEmp[arr[i]['arr_id']]!=undefined){
          this.allEmployees.push(obj)
        }
      }
      this.allEmplyees_new = [];
      for (let i = 0; i < this.allEmployees.length; i++) {
        var obj:any = new Object();
        obj = Object.assign({}, this.allEmployees[i]);
        obj['emp_name'] = this.getNumberFormat(obj['emp_id']) + "-" + obj['emp_name']
        this.allEmplyees_new.push(obj)
      }

    }
  }
  list_flag = true;
  create_flag = false;
  update_flag = false;
  open_list() {
    this.list_flag = true;
    this.create_flag = false;
    this.update_flag = false;
    this.dependentObj={};
    this.errorMsg = '';
  }
  open_create() {

    if(this.selectEmpObj['le_id'] == null || this.selectEmpObj['le_id'] == undefined || this.selectEmpObj['le_id']=="")
    {
      swal.fire('Warning', "Please Select At Least One Employee", 'warning')
     return;
    }
    this.list_flag = false;
    this.create_flag = true;
    this.update_flag = false;
  }
  async changeEmployee() {
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        obj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }
    this.spinner.show();
    var resp = await this.allEmpService.getDependentInfo(obj);
    this.selectedEmpDependent = [];
    this.eductionObj = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      this.selectedEmpDependent = resp.data;
      this.datasource = new MatTableDataSource(this.selectedEmpDependent)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort


    } else {
      this.spinner.hide()
      swal.fire('Error', 'Error while getting employee list ', 'error')
      // this.snackBar.open("Error while getting employee list", 'Error', {
      //   duration: 5000
      // });
    }
  }

  openUpdate(element:any) {
    this.list_flag = false;
    this.create_flag = false;
    this.update_flag = true;
    this.errorMsg = ''
    //  $('.nav-tabs a[href="#tab-3"]').tab('show');
    this.dependentObj = Object.assign({}, element);

  }
  async submitDependentInfo() {
    let nameRegx = /[A-Za-z]$/;
    this.errorMsg = ''
    if (
      this.dependentObj['dependent_name'] == "" || this.dependentObj['dependent_name'] == undefined
      || this.dependentObj['relation_code'] == "" || this.dependentObj['relation_code'] == undefined
    ) {
  swal.fire('Warning', " * Field Required !", 'warning');
      this.errorMsg = "* fields required."
    }

   else if (!nameRegx.test((this.dependentObj['dependent_name']))){
      swal.fire("Error",'Dependent name in Alphabetic Format','error')
        return
    }

    else {

      swal.fire({
        title:'Are you Sure?',
        // text: 'Do You Want To Add',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Add it!'
      }).then((result) => {
        if (result.value) {
          this.finalsubmitDependentInfo()
        }
      })


    }
  }
  async finalsubmitDependentInfo() {
    this.dependentObj['b_acct_id'] = this.b_acct_id;
    this.dependentObj['create_user_id'] = this.user_id;
    for (let i = 0; i < this.allEmplyees_new.length; i++) {
      if (this.allEmplyees_new[i]['le_id'] == this.selectEmpObj['le_id']) {
        this.dependentObj['le_id'] = this.allEmplyees_new[i]['le_id']
      }
    }

    this.spinner.show();
    var resp = await this.allEmpService.addDependentInfo(this.dependentObj);
    if (resp['error'] == false) {
      this.dependentObj = {}
      this.spinner.hide();
      await this.changeEmployee();

      swal.fire("Success", "Dependent Info Added", 'success');

    } else {
      this.spinner.hide();

      swal.fire("Sorry", "...Some Error Occured!", 'error');
    }
  }

  async updateDependentInfo() {
    let nameRegx = /[A-Za-z]$/;
    this.errorMsg = ''
    if (
      this.dependentObj['dependent_name'] == "" || this.dependentObj['dependent_name'] == undefined
      || this.dependentObj['relation_code'] == "" || this.dependentObj['relation_code'] == undefined
    ) {
         swal.fire('Warning', '* Fields Required !')
      this.errorMsg = "* fields required."
    }
    else if (!nameRegx.test((this.dependentObj['dependent_name']))){
      swal.fire("Error",'Dependent name in Alphabetic Format','error')
        return
    }

    else {

      swal.fire({
        title:'Are you Sure?',
        text: 'Do You Want To Update',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Update it!'
      }).then((result) => {
        if (result.value) {
          this.finalupdateDependentInfo()
        }
      })
}

  }
  async finalupdateDependentInfo() {

    var obj:any = new Object();
    obj = Object.assign({}, this.dependentObj)
    obj['b_acct_id'] = this.b_acct_id;
    obj['update_user_id'] = this.user_id;

    this.spinner.show();
    var resp = await this.allEmpService.updateDependentInfo(obj);
    if (resp['error'] == false) {
      this.dependentObj = {}
      // this.spinner.hide();
      await this.changeEmployee();

      swal.fire("Success", "Dependent Info Updated", 'success');
  this.update_flag=false;
  this.list_flag=true;

    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }


  async deleteDependent(element:any) {
    this.delEl = element
    swal.fire({
      title:'Are you Sure?',
      // text: 'Do You Want To Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.finaldeleteDependentInfo()
      }
    })

  }

  async finaldeleteDependentInfo()
  {
    var obj:any = new Object();

    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = this.delEl['id'];
    obj['dep_seq_no'] = this.delEl['dep_seq_no']
    obj['le_id'] = this.delEl['le_id']




    this.spinner.show();
    var resp = await this.allEmpService.deleteDependentInfo((obj));
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.changeEmployee();
      swal.fire("Success", "Dependent Info Deleted", 'success');


    } else {
      this.spinner.hide();
      swal.fire("Sorry", "...Some Error Occured!", 'error');

    }
  }
  applyFilter(filterValue: string) {

    this.datasource.filter = filterValue.trim().toLowerCase();
  }
  chamgeTab() {
    this.errorMsg = ''
    this.dependentObj = {}
  }
}
