import { HttpClient, HttpEventType } from '@angular/common/http';
import { MainService } from './main.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  httpUrl;
  constructor(private http: HttpClient, private main: MainService) {
    this.httpUrl = this.main.httpUrl + "/hr";
  }
  async createLocation(obj :any ) {
    console.log(obj,"from service")
    console.log(this.httpUrl,"this.httpUrl---")
    const resp = await this.http.post(this.httpUrl + '/zone/createZonelocation' , obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }


  async getLocationData(obj :any ) {
    const resp = await this.http.get(this.httpUrl + '/zone/getZonedata' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async getcordinateLocationData(obj :any ) {
    const resp = await this.http.get(this.httpUrl + '/zone/getallzonecordinate' + obj).toPromise().then(res => {
      return res;
    });
   return resp;
  }
  async deletezone(obj :any ) {
    const resp = await this.http.delete<any>(this.httpUrl + '/zone/deletezone' + obj).toPromise().then(res => {
      return res;
    });
    return resp;
  }
}
