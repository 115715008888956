import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../service/reference-data.service';
//oc
// import { SettingService } from '../../../../property/service/setting.service';
// private settingService:SettingService,
//ec
import {MainService} from '../../../service/main.service';
declare var $: any
import Swal from 'sweetalert2';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {

  constructor(private router: Router, private spinner: NgxSpinnerService, private referenceDataS: ReferenceDataService, private snackBar: MatSnackBar,public mainService:MainService) { }
  erpUser:any;
  b_acct_id:any;
  allFields = [];
  allDataTyeps = [];
  field:any = {};
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  displayedColumns = ['field_id', 'field_business_name', 'field_code', 'action'];
  datasource:any;
  l_term_flag=true;

  u_term_flag=false;
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    await this.getAllFields();
  }

  open_update(element:any) {
    this.l_term_flag=false;
   this.u_term_flag=true;
    this.field = Object.assign({}, element);
    $('.nav-tabs a[href="#tab-3"]').tab('show')
  }

  refresh() {
    this.field = {};
  }

  async getAllFields() {
    this.spinner.show()
    var obj:any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['domain_code'] = 'EMB';
    //oc
    // var resp = await this.settingService.getFields(JSON.stringify(obj));
    //ec
    //rc
    let resp:any=[]
    //lc
    if (resp['error'] == false) {
      this.allFields = resp.data;
      this.datasource = new MatTableDataSource(this.allFields)
      this.datasource.paginator = this.paginator;
      this.datasource.sort = this.sort;
      this.spinner.hide();
    } else {
      this.spinner.hide()
      this.snackBar.open("Error while getting  all fields list", 'Error', {
        duration: 5000
      });
    }
  }
  async create() {
    let validate = 0
    let obj:any = new Object();
    obj['field_code'] = this.field['field_code']
    obj['field_business_name'] = this.field['field_business_name']
    obj['field_technical_name'] = this.field['field_technical_name']
    obj['field_logical_id'] = 0;
    obj['b_acct_id'] = this.b_acct_id;
    obj['is_code_value_present'] = 1;
    obj['is_code_values_present'] = 0;
    obj['is_hierarchy_present'] = 0;
    obj['datatype_code'] = 'STR'
    if (this.field['field_business_name'] && this.field['field_technical_name'] && this.field['field_code']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();

      var resp = await this.referenceDataS.createField(obj);
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllFields()
        this.snackBar.open("Field Added Successfully!", 'Success', {
          duration: 5000
        });
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while Adding Field", 'Error', {
          duration: 5000
        });
      }
    } else {
      Swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }

  }

  async update() {
    let obj:any = {}
    let validate = 0
    obj = Object.assign({}, this.field)
    obj['update_user_id'] = this.erpUser.user_id;
    obj['b_acct_id'] = this.b_acct_id;
    if (this.field['field_business_name']) {
      validate = 1
    }
    if (validate == 1) {
      this.spinner.show();
      //oc
      // var resp = await this.settingService.updateFields(obj);
      //ec
      //rc
      let resp:any=[]
      //lc
      if (resp['error'] == false) {
        this.spinner.hide();
        await this.getAllFields();
        this.snackBar.open("Field Update Successfully!", 'Success', {
          duration: 5000
        });
        this.gotolist();
      } else {
        this.spinner.hide();
        this.snackBar.open("Error while updating Field", 'Error', {
          duration: 5000
        });
      }
    } else {
      Swal.fire('Warning...', 'Please Enter All Details Marked As *', 'warning')
    }

  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }

  async  delete(element:any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.finaldelete(element)
      }
    })
  }
  async gotolist(){
    this.l_term_flag=true;

    this.u_term_flag=false;
    await this.getAllFields();

  }
  async finaldelete(e:any) {
    this.spinner.show();
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['field_code'] = e['field_code']
    var resp = await this.referenceDataS.deleteFields(obj);
    if (resp['error'] == false) {
      this.spinner.hide();
      await this.getAllFields();
      this.snackBar.open("Field Deleted Successfully!", 'Success', {
        duration: 5000
      });

    } else {
      this.spinner.hide();
      this.snackBar.open("Error while deleting Field", 'Error', {
        duration: 5000
      });
    }
  }

}
