import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SettingService } from '../../service/setting.service';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';



@Component({
  selector: 'app-support-department',
  templateUrl: './support-department.component.html',
  styleUrls: ['./support-department.component.css']
})



export class SupportDepartmentComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private settingService: SettingService) { }


  @ViewChild('paginator2', { static: false }) paginator2!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;


  ebillUser:any;
  b_acct_id:any;
  async ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    await this.getCodeValue_obj();
    await this.getDepartmentData();
  }

  depdata :any= {}

  dataSource: any;
  displayedcolumns = ['dep_code', 'dep_name', 'branch_name', 'branch_address', 'officer_name', 'officer_desig', 'action']


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  list: boolean = true;
  create: boolean = false;
  update: boolean = false;

  async goToList() {
    this.list = true;
    this.create = false;
    this.update = false;
    await this.getDepartmentData();
  }

  openCreate() {
    this.list = false;
    this.create = true;
    this.update = false;
  }

  open_upadate(data:any) {
    this.depdata = data;
    this.list = false;
    this.create = false;
    this.update = true;
  }


  async submit() {
    this.depdata['b_acct_id'] = this.b_acct_id;
    this.depdata['create_user_id'] = this.ebillUser.user_id
    this.depdata['update_user_id'] = this.ebillUser.user_id

    if (!this.depdata['branch_address'] || !this.depdata['branch_name'] || !this.depdata['dep_code'] || !this.depdata['dep_name'] || !this.depdata['officer_desig'] || !this.depdata['officer_name']) {
      swal.fire('INFO', 'Please Fill All mandatory Field', 'info');
      return;
    }
    let resp = await this.settingService.createDepartment(this.depdata);
    if (resp['error'] == false) {
      swal.fire('Success', 'Submitted Successfully', 'success')
      this.depdata = {}
      await this.getDepartmentData();
      this.goToList();
    } else {
      swal.fire('ERROR', resp.data, 'error');
    }

  }

  async getDepartmentData() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    let resp = await this.settingService.getDepartmentData(JSON.stringify(obj));

    if (resp['error'] == false) {

      console.log("department data : ", resp['data'])

      this.dataSource = new MatTableDataSource(resp['data']);
      this.dataSource.paginator = this.paginator2;
      this.dataSource.sort = this.sort;

    } else {
      swal.fire('ERROR', 'Error Occurred While Getting Department', 'error');
    }
  }


  delete(element:any) {
    swal.fire({
      title:
        "Are You Sure?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.value) {
        this.deleteSupporteDepartment(element)
      }
    });
  }

  async deleteSupporteDepartment(element:any) {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id;
    obj['id'] = element.id;
    obj['dep_name'] = element.dep_name;

    let resp = await this.settingService.delete(JSON.stringify(obj));
    if (resp['error'] == false) {
      swal.fire('Success', `${resp['data']}`, 'success')
      await this.getDepartmentData();
    } else {
      swal.fire('ERROR', `${resp['data']}`, 'error');
    }





  }

  async updateDepartment() {
    this.depdata['b_acct_id'] = this.b_acct_id;
    this.depdata['create_user_id'] = this.ebillUser.user_id
    this.depdata['update_user_id'] = this.ebillUser.user_id

    if (!this.depdata['branch_address'] || !this.depdata['branch_name'] || !this.depdata['dep_code'] || !this.depdata['dep_name'] || !this.depdata['officer_desig'] || !this.depdata['officer_name']) {
      swal.fire('INFO', 'Please Fill All mandatory Field', 'info');
      return;
    }

    let resp = await this.settingService.updateDepartment(this.depdata);
    if (resp['error'] == false) {
      swal.fire('Success', 'Update Successfully', 'success')
      this.depdata = {}
      await this.getDepartmentData();
      this.goToList();
    } else {
      swal.fire('ERROR', 'Error Occurred While Creating Department', 'error');
    }
  }

  codeValueTechObj:any = {}
  codeValueObj = {}
  codeValueShowObj :any= {}
  async getCodeValue_obj() {
    this.spinner.show()
    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any = {}
    var codeValueShowTempObj:any = {};
    if (resp['error'] == false) {
      this.spinner.hide()
      for (var i = 0; i < resp.data.length; i++) {
        if (codeValueTempObj[resp.data[i]['field_code']] == undefined) {
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] = {}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.codeValueTechObj = this.codeValueObj;
      console.log(this.codeValueTechObj, 'uuuu')
      this.codeValueShowObj = this.codeValueShowObj;
    } else {
      this.spinner.hide()
    }
  }

}
