import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
// import { element } from 'protractor';
import { SaleService } from '../../service/sale.service';

import { TransferPropertyService } from '../../service/transfer-property.service';
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-transfer-property',
  templateUrl: './transfer-property.component.html',
  styleUrls: ['./transfer-property.component.css']
})
export class TransferPropertyComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  constructor(private transferService: TransferPropertyService, private sale_service: SaleService, private spinner: NgxSpinnerService) { }
  erpUser: any;
  b_acct_id: any;
  user_id: any;
  Obj: any = {}
  flagdata: any;
  detail_data: any;
  transfer_data: any;



  displayedColumns: string[] = ['Allotmentid', 'AllotteeName', 'PropertyType', 'Property_number', 'AppliedDate', 'Applicaent_name', 'reason_transfer', 'status', 'Action'];
  dataSource: any;

  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.user_id = this.erpUser.user_id;
    // await this.getAllSchemes();
    await this.getAllOffers()
    await this.getpartyData()
  }

  // flag
  flag1: boolean = true;
  flag2: boolean = false;
  flag3: boolean = false;
  flag4: boolean = false;
  search() {

    this.getpartyData();

  }
  // tabel
  viewDetail(element: any) {
    console.log(element)
    this.detail_data = element

    this.getpartyData();
    this.flag1 = false;
    this.flag2 = true;
    this.flag4 = false;
  }
  Transfer(element: any) {
    this.transfer_data = element
    this.getpartyData();
    this.flag1 = false;
    this.flag2 = false;
    this.flag3 = true;
    this.flag4 = false;
  }
  close() {
    this.flag1 = true;
    this.flag2 = false;
    this.flag3 = false;
    this.flag4 = false;
  }
  update() {
    this.flag1 = true;
    this.flag2 = false;
    this.flag3 = false;
    this.flag4 = false;

  }
  cancel() {
    this.flag1 = true;
    this.flag2 = false;
    this.flag3 = false;
    this.flag4 = false;

  }
  dropdown(id: string) {
    if (id == 'yes') {
      this.flag4 = true;


    }
    if (id == 'no') {

      this.flag4 = false;

    }
  }

  allOffer: any = []
  async getAllOffers() {

    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id

    var resp = await this.sale_service.getAllOffers(obj);
    console.log(resp['data'])
    this.allOffer = resp['data']
    console.log(this.allOffer)

  }
  allData = []
  async getpartyData() {
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['offer_cd'] = this.Obj['offer_cd']

    var resp = await this.transferService.getpartyData(JSON.stringify(obj));
    console.log(resp['data'])
    this.allData = resp['data'];

    this.dataSource = new MatTableDataSource(this.allData);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }




  selectedMutation: any;

  select_Mutation = [
    { id: 'yes', name: 'YES' },
    { id: 'no', name: 'NO' },

  ];
  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
