import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { CompoundingService } from '../../service/compounding.service';
import { ApprovalUserService } from '../../../portal/service/approval-user.service';
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { ToastrService } from 'ngx-toastr';
import { MainService as Portalmain } from '../../../portal/service/main.service';
import { stringify } from 'querystring';
declare var $: any;

@Component({
  selector: 'app-compounding-process',
  templateUrl: './compounding-process.component.html',
  styleUrls: ['./compounding-process.component.css']
})
export class CompoundingProcessComponent implements OnInit {
  // erpUser: any;
  ebillUser: any;
  b_acct_id: any;
  user_id: any;
  datasource: any;
  assign: any = {};
  displayedColumns = ['s.no', 'order_id', 'order_date', 'compound_area', 'compound_fee', 'payment_status', 'doc_status', 'action'];
  decision = [
    {
      desc: 'Verified ',
      obj: 'VERIFIED'
    },
    {
      desc: "Not Verified",
      obj: "NOT-VERIFIEd"
    }
  ]

  constructor(private spinner: NgxSpinnerService, private compounding: CompoundingService,
    public ApprovalUserService: ApprovalUserService, public mainserviceEmb: mainserviceEmb,
      private mainServiceMD: mainService_MD, private portalmain: Portalmain , public toastr: ToastrService) { }

  async ngOnInit() {

    // this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')));
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id
    this.user_id = this.ebillUser.le_id
    this.getcompounding();




    await this.getAllEmployees()
    await this.getWorkflowlog();
  }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
  }




  async getcompounding() {
    let obj:any = {}
    obj['b_acct_id'] = this.b_acct_id
    this.spinner.show();
    var resp :any= await this.compounding.getcompoundingdata(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.datasource = resp['data']
      this.datasource = new MatTableDataSource(resp['data'])
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasource.sort = this.sort.toArray()[0];
    }
  }


  compounding_details = {'order_id':'' , 'case_id':'','co_ord_date':'','comp_date':'','comp_area':'','comp_fee':'',
  'arr_status_code':'','life_cycle_status':''}
  view_details(element :any) {
    console.log(element)
    this.compounding_details = element
    $('#details').modal('show');

  }

  Processing(element :any) {
    $('#map_processing').modal('show');
    this.assign['order_id'] = element.order_id
  }

  // which_id = 'ACCEPTED'
  which_id_type(item:any) {
    console.log(item)
    this.assign['map_status'] = item
  }


  async submit() {
    this.assign['b_acct_id'] = this.b_acct_id
    this.assign['life_cycle_status'] = 'ACTIVE'
    this.assign['user_id'] = this.user_id
    this.spinner.show()
    let resp :any= await this.compounding.insert(this.assign)
    // console.log(resp);
    if (resp['error'] == true) {
      this.spinner.hide();
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.getcompounding();
      this.spinner.hide();
      $('#map_processing').modal('hide');
      Swal.fire('Success', resp['data'], 'success')
      this.assign = {}
    }
  }
def_doc_details = []
 async view_document(element:any){
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id
    obj['order_id'] = element.order_id
    this.spinner.show();
    let resp :any= await this.compounding.getdefenderdocumentdetails(JSON.stringify(obj));
    // console.log(resp);
    if (resp['error'] == true) {
      Swal.fire('error', "server side error", 'error')
      return
    } else {
      this.spinner.hide();
      this.def_doc_details = resp['data']
    $('#def_details').modal('show');

    }
  }


 async view_doc_def(i:any){
  let obj :any= {}
  obj['b_acct_id'] = this.b_acct_id
  obj['doc_id'] = this.def_doc_details[i]['doc_id']
  obj['file_name'] = this.def_doc_details[i]['file_name']
  // console.log(obj);
  var res:any = await this.compounding.view_Doc(obj);
  // console.log(res);
  if (res['error'] == true) {
    Swal.fire('error', "server side error", 'error')
    return
  }else{
    this.spinner.hide();
     var docname :any= this.def_doc_details[i]['file_name']
     var ext = docname.split('.');
      if (ext[1] == 'png' || ext[1] == 'jpeg' || ext[1] == 'jpg' || ext[1] == 'PNG' || ext[1] == 'JPEG' || ext[1] == 'JPG') {
        let file = new Blob([res], { type: 'image/png' });
        var fileURL = window.URL.createObjectURL(file);
        this.spinner.hide()
        window.open(fileURL, '_blank');
      } else if (ext[1].toLowerCase() == 'xlsx') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinner.hide()
      } else if (ext[1].toLowerCase() == 'docx' || ext[1].toLowerCase() == 'doc') {
        let file = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinner.hide()
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinner.hide()

      }
  }


  }




  undertakings:any
  salBill_apprdata:any
  async Appr(element :any) {
    console.log(element)
    this.salBill_apprdata = Object.assign({}, element)
    this.salBill_apprdata['bill_desc'] = 'Approval for ' + element['id']
    this.salBill_apprdata['level'] = 1
    this.salBill_apprdata['branch'] = 1
    this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
    this.salBill_apprdata['doc_type'] = 'MAP DOCUMENT'
    this.salBill_apprdata['node_cd'] = element['zone_cd']
    this.salBill_apprdata['module_cd'] = 'ICMS';
    await this.ApprovalUserService.getWorkflowlog(this.ebillUser, 'MAP DOCUMENT', 'ICMS');
    if (this.ApprovalUserService.workflow_level1 == undefined) {
      // await this.ChangestatusSalBill(this.salBill_apprdata['doc_local_no'], 'APPROVED')
      return;
    }
    await this.ApprovalUserService.getMostLatestWorkflowlog(this.ebillUser, this.salBill_apprdata)
    await this.ApprovalUserService.getWorkflowloguser(this.ebillUser, this.salBill_apprdata)
    /* await this.getAllRule();
    await this.getundertakings(element); */
    $('#workflow').modal('show');
  }

  message_flag = false
  async getle(user_id:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['le_id'] = user_id
    let resp = await this.mainserviceEmb.getcurrentlegalentity(JSON.stringify(obj))
    console.log(resp['data'])
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      await this.sendmessage(resp['data'][0]['phone_no'], resp['data'][0]['party_name'])
      this.spinner.hide();
      this.message_flag = false
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();
      Swal.fire("Error", resp['data'], 'error');
    }
  }

  async sendmessage(mobile:any, user_name:any) {
    //console.log(mobile, this.tender_apprdata['doc_type'] + ' Number ' + this.tender_apprdata['doc_local_no'])
    let resp = await this.mainserviceEmb.sendMsg(mobile, this.salBill_apprdata['doc_type'], this.salBill_apprdata['doc_local_no'], user_name)
    if (resp['error'] == false) {
      //await this.updatepending(this.tender_apprdata)
      this.spinner.hide();
      //this.toastr.success("Approval Forwarded Successfully!!")
    } else {
      this.spinner.hide();

      Swal.fire("Error", resp['data'], 'error');

    }
  }
  forwarded_user_id:any;
  appr_remark:any
  async Approvedapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();
      obb['user_id'] = this.ebillUser.user_id
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'APPROVED'
      obb['doc_type'] = 'MAP DOCUMENT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      obb['role_cd'] = this.ApprovalUserService.level1['role_cd']//this.level1['role_cd']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      obb['level'] = this.ApprovalUserService.level1['level']
      obb['undertaking'] = JSON.stringify([])
      obb['branch'] = this.ApprovalUserService.level1['branch']
      obb['user_name'] = this.ebillUser.party_name;
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {
        await this.pendingapproval()
        await this.ChangeComplainStatus(this.salBill_apprdata['doc_local_no'], 'UNDERAPPROVAL')
        await this.getcompounding();
        this.spinner.hide();
        if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
          // obb['user_id'] = -1
          // obb['vendor_id'] = this.selectedTender['party_id']
          if (this.message_flag == true) {
            /* await this.getle(this.selectedTender['party_id']) */
          }

        } else {
          if (this.message_flag == true) await this.getle(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id'])

        }

        this.toastr.success("Approval Forwarded Successfully!!")

        console.log("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        console.log(resp['data'])
        // this.toastr.error(resp['data'])
      }
    } else {
      // this.toastr.error("Please Select Any User..!")
      this.spinner.hide();
    }
  }
  async pendingapproval() {
    this.spinner.show()
    if (this.forwarded_user_id != null || this.ApprovalUserService.vendorflag == true) {
      var obb :any= new Object();

      // var obb = new Object();
      if (this.forwarded_user_id == null && this.ApprovalUserService.vendorflag == true) {
        // if(this.selectedTender['party_id'] == null || this.selectedTender['party_id'] == undefined){
        //   Swal.fire("Error", "Please Map Verdor with Work..!", 'error');
        //   return
        // }
        // obb['user_id'] = -1
        // obb['vendor_id'] = this.selectedTender['party_id']
      } else {
        obb['user_id'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['user_id']
      }

      console.log(this.forwarded_user_id)
      obb['b_acct_id'] = this.ebillUser.b_acct_id
      obb['forwarded_by'] = this.ebillUser.user_id
      obb['forward_msg'] = this.appr_remark
      obb['remark'] = ""
      obb['status'] = 'PENDING'
      obb['doc_type'] = 'MAP DOCUMENT'
      obb['doc_local_no'] = this.salBill_apprdata['doc_local_no']
      obb['doc_desc'] = this.salBill_apprdata['bill_desc']
      // obb['user_name'] = this.ebillUser.party_name;
      obb['user_name'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['emp_name']
      // if(this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd'] == undefined) obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
      obb['module_cd'] = 'ICMS'
      obb['node_cd'] = this.salBill_apprdata['node_cd']
      if (this.ApprovalUserService.vendorflag == true) {
        obb['level'] = this.salBill_apprdata['level']
        obb['branch'] = this.salBill_apprdata['branch']
      }
      else {
        obb['role_cd'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['role_cd']
        obb['level'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['level']
        obb['branch'] = this.ApprovalUserService.rolebyid[this.forwarded_user_id]['branch']
      }
      // obb['field_value'] = this.salBill_apprdata['bill_amount']
      obb['field_value'] = 0
      var resp = await this.mainserviceEmb.insertTask(obb)
      if (resp['error'] == false) {

        //await this.updatepending(this.tender_apprdata)
        this.spinner.hide();
        //this.toastr.successToastr("Approval Forwarded Successfully!!")
      } else {
        this.spinner.hide();
        // this.toastr.errorToastr(resp['data'])
      }
    } else {
      // this.toastr.errorToastr("Please Select Any User..!")
      this.spinner.hide();
    }
  }

  selectedTender:any = {}
  workflowuser:any = []
  rolecdtolevelobj :any= {}
  async getWorkflowloguser() {
    var obj :any= new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    obj['status'] = 'ACTIVE'
    obj['node_cd'] = this.salBill_apprdata['node_cd']
    console.log(obj, this.selectedTender)
    var resp = await this.mainserviceEmb.getUsersForModuleWorkflow(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      this.workflowuser = resp['data']

    } else {
      this.spinner.hide();

      this.toastr.error(resp['data'])

    }
  }
  statusArr1 :any= [];
  selectedEle:any;
  userRoles:any = []
  allSearchableEmp = []
  userObj:any = {}
  async viewStatus(element :any) {
    this.selectedEle = Object.assign({}, element);
    var obj:any = new Object();
    obj['doc_local_no'] = element.id;
    obj['b_acct_id'] = this.ebillUser.b_acct_id;
    obj['doc_type'] = 'MAP DOCUMENT';
    obj['module_cd'] = 'ICMS'
    var resp = await this.mainserviceEmb.getWorkflowlog(JSON.stringify(obj));
    console.log(resp)
    if (resp['error'] == false) {
      this.salBill_apprdata = Object.assign({}, element)
      this.salBill_apprdata['level'] = 1
      this.salBill_apprdata['branch'] = 1
      this.salBill_apprdata['doc_local_no'] = this.salBill_apprdata['id']
      this.salBill_apprdata['doc_type'] = 'MAP DOCUMENT'//this.salBill_apprdata['id']
      this.salBill_apprdata['node_cd'] = element['project_cd']
      this.salBill_apprdata['module_cd'] = 'ICMS';
      await this.getWorkflowloguser()
      console.log(this.userRoles, resp.data, 'user')
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i]['user_id'] == -1) {
          resp.data[i]['name'] = "Contractor"
          resp.data[i]['role_cd'] = "Contractor"
          continue;
        }
        for (let j = 0; j < this.workflowuser.length; j++) {
          if (resp.data[i]['user_id'] == this.workflowuser[j]['user_id'] && resp.data[i]['role_cd'] == this.workflowuser[j]['role_cd']) {
            resp.data[i]['name'] = this.workflowuser[j]['emp_name']
            resp.data[i]['role_cd'] = this.workflowuser[j]['role_cd']
          }
        }
        if (resp.data[i]['name'] == undefined) {
          resp.data[i]['name'] = ''

        }
      }
      this.statusArr1 = resp.data
      console.log(this.statusArr1);
      $('#workflowStatus').modal('show');
    } else {
      this.toastr.error(resp['data'])

    }
  }

  async getAllEmployees() {
    console.log('all emp', this.b_acct_id)
    this.spinner.show()
    var arr = [];
    const obj:any = {};
    obj['b_acct_id'] = this.b_acct_id
    obj['module_cd'] = 'ICMS'
    console.log(obj)
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    console.log(resp);
    if (resp['error'] == false) {
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    // console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }


  //workflow*********************************************//
  apprflag = false;
  level_for_approval = []
  workflow_level1:any = {}
  async getWorkflowlog() {
    var obj:any = new Object()
    obj['b_acct_id'] = this.b_acct_id
    obj['status'] = 'ACTIVE'
    obj['doc_type'] = 'MAP DOCUMENT';
    this.apprflag = false;
    var resp = await this.portalmain.getWorkflows(JSON.stringify(obj))
    if (resp['error'] == false) {
      this.spinner.hide();
      console.log(resp['data'])
      if (resp.data.length == 0) {
        return;
      }
      this.workflow_level1 = resp['data'][0]
      this.level_for_approval = resp['data']
      let currentuser_role = this.ebillUser.role_cd
      for (let i = 0; i < currentuser_role.length; i++) {
        if (this.workflow_level1['role_cd'] == currentuser_role[i]) {
          this.apprflag = true
        }

      }
    } else {
      this.spinner.hide();

      this.toastr.error(resp['data'])

    }

  }
  async ChangeComplainStatus(data:any, status:any) {
    let obj = Object()
    obj['b_acct_id'] = this.b_acct_id;
    obj['status'] = status;
    obj['id'] = data
    this.spinner.show();
    var resp:any = await this.compounding.updateMapStatus(obj)

    if (resp['error'] == false) {
      this.spinner.hide();
      console.log('Successfully Chaged to ' + status)
    }
    else {
      this.spinner.hide();

      this.toastr.error(resp['data']);

    }
  }
}
