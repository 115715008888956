import { Component, OnInit } from '@angular/core';

import { ChartOfAccountService } from '../../../service/chart-of-account.service';
import { ExcelService } from '../../../service/file-export.service';
import { LedgerService } from '../../../service/ledger.service'
import { MainService } from '../../../service/main.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { SettingService } from '../../../service/setting.service'
import Swal from 'sweetalert2';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as  pdfMake from  "pdfmake/build/pdfmake";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-all-ded-report',
  templateUrl: './all-ded-report.component.html',
  styleUrls: ['./all-ded-report.component.css']
})
export class AllDedReportComponent implements OnInit {

  list = ['Sr_n', 'ded_code', 'ded_type', 'amount', 'ded_date', 'gov_rule', 'action'];

  erpUser:any;
  b_acct_id:any;
  allParty :any= []
  obj :any= {};
  deduction_type:any = [];
  constructor(private ChartOfAccountService: ChartOfAccountService, public mainService: MainService, private excl: ExcelService, private ledgerService: LedgerService, private spinner: NgxSpinnerService, private settingService: SettingService) { }
  acount_name = ''
  async ngOnInit() {
    this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.erpUser.b_acct_id;
    this.acount_name = this.mainService.accInfo['account_name']
    await this.getrepresentative();
    await this.getparty();
    await this.getAllDeduction();
  }



  /*********************************This is new code strat************************************************************************ */
  reperesentative:any = {}
  async getrepresentative() {
    var resp = await this.settingService.getAllrepresentative(JSON.stringify({ b_acct_id: this.b_acct_id }));
    if (resp['error'] == false) {
      for (let i = 0; i < resp['data'].length; i++) {
        this.reperesentative[resp['data'][i]['rep_le_id']] = resp['data'][i]['party_leagal_name']

      }
    }
  }


  all_party :any= [];
  partyNameObj:any = {};
  partyPANObj :any= {};
  async getparty() {
    var resp = await this.settingService.getAllparties(this.b_acct_id);
    if (resp['error'] == false) {
      this.partyNameObj = {};
      this.partyPANObj = {};
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['party_type'] == 'ORG') {
          resp['data'][i]['party_leagal_name'] = resp['data'][i]['party_leagal_name'] + ' ( ' + this.reperesentative[resp['data'][i]['rep_le_id']] + ')'
        }
        this.partyNameObj[resp['data'][i]['le_id']] = resp['data'][i]['party_leagal_name']
        this.partyPANObj[resp['data'][i]['le_id']] = resp['data'][i]['party_pan_no']
      }
      this.all_party = [{ le_id: null, party_leagal_name: 'All' }];
      resp['data'].map((x:any) => {
        this.all_party.push(x);
      });
      this.obj['party_type']
    }
  }

  reportdata :any= []


  // async check_validation(){
  //   if(!this.obj['from_dt']){
  //     Swal.fire("Opps..","Please Select From Date",'info');
  //     this.spinner.hide();
  //     return;
  //   }
  //   if(!this.obj['to_dt']){
  //     Swal.fire("Opps..","Please Select To Date",'info');
  //     this.spinner.hide();
  //     return;
  //   }
  //   // if(!this.obj['le_id']){
  //   //   Swal.fire("ERROR","Please Select Party",'error');
  //   //   this.spinner.hide();
  //   //   return;
  //   // }
  //   if(!this.obj['ded_code']){
  //     Swal.fire("Opps..","Please Select Deduction",'info');
  //     this.spinner.hide();
  //     return;
  //   }
  // }

  async getreportoutput() {
    // await this.check_validation();
    if(this.obj['from_dt'] > this.obj['to_dt']){
      return;
    }
    this.obj['b_acct_id'] = this.b_acct_id;
    let all_ded :any= []
    if (this.obj['ded_code'] == "ALL") {
      this.deduction_type.map((x:any) => {
        if (x['ded_code'] != "ALL")
          all_ded.push(x['ded_code']);
      })
      this.obj['all_ded_code'] = all_ded;
    }
    console.log(this.obj)
    var resp = await this.ledgerService.getAllDeduction(JSON.stringify(this.obj))
    if (resp['error'] == false) {
      // document.getElementById('output2').click();
      let arr:any = resp['data']
      this.reportdata = [];
      arr.map((x:any) => {
        // if (x['ev_grp_amt'] != x['ev_ln_amt']) {
        let gst_line:any = new Object();
        gst_line['pan'] = this.partyPANObj[x['cp_num_in_src']]
        gst_line['party_name'] = x['cp_nm']
        gst_line['amount'] = x['ev_grp_amt']
        gst_line['deduction_amount'] = x['ev_ln_amt']
        gst_line['ded_code'] = x['ev_ln_id']
        gst_line['ded_date'] = x['update_timestamp'].split("T")[0]
        this.reportdata.push(gst_line)
        // }
      })

    }
    console.log(this.reportdata)
  }

  async getAllDeduction() {
    let obj :any= {}
    obj['b_acct_id'] = this.b_acct_id;
    let ded = [{ ded_code: 'ALL' }]
    var resp = await this.settingService.getDeductionMappingList(this.b_acct_id);
    if (resp['error'] == false) {
      console.log("ALL DED", resp['data'])
      for (let i = 0; i < resp['data'].length; i++) {
        if (resp['data'][i]['ded_code'] == 'TDSONSGSTINPUT' || resp['data'][i]['ded_code'] == 'TDSONCGSTINPUT' || resp['data'][i]['ded_code'] == 'TDSONIGSTINPUT' || resp['data'][i]['ded_code'] == 'CGSTINPUT' || resp['data'][i]['ded_code'] == 'CGSTOUTPUT'
          || resp['data'][i]['ded_code'] == 'IGSTINPUT' || resp['data'][i]['ded_code'] == 'IGSTOUTPUT' || resp['data'][i]['ded_code'] == 'SGSTINPUT' || resp['data'][i]['ded_code'] == 'SGSTOUTPUT' || resp['data'][i]['ded_code'] == 'IT') {
        }
        else {
          ded.push(resp['data'][i]);
        }
      }
      // console.log(ded);
      this.deduction_type = ded;
    }
    else {
      Swal.fire("Error", "...Error while getting list!", 'error');
    }
  }


  export() {
    var exp = []
    for (var i = 0; i < this.reportdata.length; i++) {
      var obj:any = new Object();
      obj['S. NO.'] = i + 1;
      if (this.reportdata[i]['ded_date']) {
        obj['Date of Deduction'] = this.mainService.dateFormatChange(this.reportdata[i]['ded_date'])

      } else {
        obj['Date of Deduction'] = this.reportdata[i]['ded_date']

      }
      obj['Party Name'] = this.reportdata[i]['party_name']
      obj['Type of Deduction'] = this.reportdata[i]['ded_code']
      obj['PAN of Party'] = this.reportdata[i]['pan']
      obj['Gross Amount (Event Value)'] = this.reportdata[i]['amount']
      obj['Deduction Amount'] = this.reportdata[i]['deduction_amount']

      exp.push(obj);
    }
    this.excl.exportAsExcelFile(exp, 'deduction_report')
  }
  print() {

    var txt = this.mainService.accInfo['account_name'] + "(" + this.mainService.accInfo['account_short_name'] + ") " + this.mainService.codeValueShowObj['ACC0057'][this.deduction_type] + "  REPORT";
    var dd :any= {

      pageSize: 'A3',
      header: function (currentPage:any, pageCount:any) {
        var obj = { text: txt + "     Page No. - " + currentPage, alignment: 'center', margin: [72, 40] };
        return obj;
      },


      pageOrientation: 'portrait',

      pageMargins: [40, 60, 40, 60],
      content: [

      ]
    };
    var header2 =
      [
        {

          text: 'From Date :',
          bold: true,
          colSpan: 2, alignment: 'center'

        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['from_dt']), colSpan: 1, alignment: 'center'
        },
        {

          text: 'To Date :',
          bold: true, colSpan: 2, alignment: 'center'
        }, {},
        {

          text: this.mainService.dateFormatChange(this.obj['to_dt']), colSpan: 2, alignment: 'center'
        }, {}
      ]
    var tbl = {

      fontSize: 10,
      table: {

        headerRows: 2,
        widths: ['*', '*', '*', '*', '*', '*', '*'],

        body: [header2,
          ['SNO', 'Date of Deduction', 'Party Name',

            { text: 'Type of Deduction', alignment: 'center' },
            { text: 'PAN of Party', alignment: 'center' },
            { text: 'Gross Amount (Event Value)', alignment: 'center' },
            { text: 'Deduction Amount', alignment: 'center' }]


        ]
      }
    };
    dd.content.push(tbl);
    var deduction_amount = 0

    var amount = 0
    for (var i = 0; i < this.reportdata.length; i++) {
      var arr = []
      arr.push(i + 1);
      if (this.reportdata[i]['ded_date']) {
        arr.push(this.mainService.dateFormatChange(this.reportdata[i]['ded_date']));

      } else {
        arr.push(this.reportdata[i]['ded_date']);

      }
      arr.push(this.reportdata[i]['party_name']);

      arr.push({ text: this.reportdata[i]['ded_code'], alignment: 'right' });
      arr.push({ text: this.reportdata[i]['pan'], alignment: 'right' })
      arr.push({ text: this.reportdata[i]['amount'].toFixed(2), alignment: 'right' })
      arr.push({ text: this.reportdata[i]['deduction_amount'].toFixed(2), alignment: 'right' })

      amount = amount + this.reportdata[i]['amount']

      deduction_amount = deduction_amount + this.reportdata[i]['deduction_amount']


      dd.content[dd.content.length - 1].table.body.push(arr);

    }
    var totalObjRow =
      ['', '', '', '', 'Total : Rs.', amount.toFixed(2), deduction_amount.toFixed(2)]


    dd.content[dd.content.length - 1].table.body.push(totalObjRow);




    pdfMake.createPdf(dd).download("Deduction-Report");
  }

}
