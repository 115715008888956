import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { IcmsHeaderComponent } from './icms-header/icms-header.component';
import { IcmsFooterComponent } from './icms-footer/icms-footer.component';
import { IcmsSidebarComponent } from './icms-sidebar/icms-sidebar.component';
import { IcmsDashboradComponent } from '../icms-dashborad/icms-dashborad.component';
import { IcmsLayoutRoutingModule } from './icms-layout-routing.module';
import { IcmsLayoutComponent } from './icms-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChartsModule } from 'ng2-charts';
import { NgChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { GoogleMapsModule } from '@angular/google-maps';
// import { MatCheckboxModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
// import { NgtreegridModule } from 'ngtreegrid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgtreegridModule } from 'ngtreegrid';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
// import { NgxEchartsModule } from 'ngx-echarts';
import { IcmsAdministrationComponent } from '../icms-administration/icms-administration.component';
import { IcmsRolesComponent } from '../icms-administration/icms-roles/icms-roles.component';
import { IcmsUserComponent } from '../icms-administration/icms-user/icms-user.component';
import { IcmsDataAssigmentComponent } from '../icms-administration/icms-data-assigment/icms-data-assigment.component';
import { IcmsWorkflowComponent } from '../icms-administration/icms-workflow/icms-workflow.component';
import { IcmsSettingComponent } from '../icms-setting/icms-setting.component';
import { IcmsCodeValueComponent } from '../icms-setting/icms-code-value/icms-code-value.component';
import { IcmsFieldComponent } from '../icms-setting/icms-field/icms-field.component';
import { ComplaintComponent } from '../complaint/complaint.component';
import { PublicComplaintComponent } from '../complaint/public-complaint/public-complaint.component';
import { IcmsZoneComponent } from '../icms-setting/icms-zone/icms-zone.component';
// import { AgmCoreModule } from '@agm/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IcmsHierachyComponent } from '../icms-setting/icms-hierachy/icms-hierachy.component';
import { IcmsHelpComponent } from '../icms-help/icms-help.component';
import { IcmsFaqComponent } from '../icms-help/icms-faq/icms-faq.component';
import { IcmsOnlineSupportComponent } from '../icms-help/icms-online-support/icms-online-support.component';
import { IcmsTutorialComponent } from '../icms-help/icms-tutorial/icms-tutorial.component';
import { IcmsUserManualComponent } from '../icms-help/icms-user-manual/icms-user-manual.component';
import { LitigationComponent } from '../litigation/litigation.component';
import { UserComplaintComponent } from '../complaint/user-complaint/user-complaint.component';
import { IcmsSectionComponent } from '../icms-setting/icms-section/icms-section.component';
import { AssignComplaintComponent } from '../complaint/assign-complaint/assign-complaint.component';
import { ReportsComponent } from '../reports/reports.component';
import { InspectionReportComponent } from '../reports/inspection-report/inspection-report.component';
import { CourtOrderComponent } from '../court/court-order/court-order.component'
import { SupportDepartmentComponent } from '../icms-setting/support-department/support-department.component';
import { PaymentComponent } from '../payment/payment.component';
import { PenalityComponent } from '../payment/penality/penality.component';
import { DemolitionChargesComponent } from '../payment/demolition-charges/demolition-charges.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssignedOrderComponent } from '../court/assigned-order/assigned-order.component';
import { NotificationComponent } from './notification/notification.component';
import { CauseListComponent } from '../cause-list/cause-list.component';
import{SupportLettersComponent} from '../court/support-letters/support-letters.component'
import {LetterEditorComponent} from'../letter-editor/letter-editor.component'
// import { CKEditorModule } from 'ckeditor4-angular';
import { PaymentGatewayComponent } from '../icms-administration/payment-gateway/payment-gateway.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RecoveryCalculationComponent } from '../court/recovery-calculation/recovery-calculation.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AllreportsComponent } from '../reports/allreports/allreports.component';
import { CompundingComponent } from '../payment/compunding/compunding.component';
import { RequestComponent } from '../request/request.component';
import { CompoundingProcessComponent } from '../court/compounding-process/compounding-process.component';
import { CourtComponent } from '../court/court.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CampusBoundaryComponent } from '../icms-setting/campus-boundary/campus-boundary.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [

    ReportsComponent,
    InspectionReportComponent,
    UserComplaintComponent,
    IcmsLayoutComponent,
    IcmsHeaderComponent,
    IcmsFooterComponent,
    IcmsSidebarComponent,
    IcmsDashboradComponent,
    IcmsAdministrationComponent,
    LitigationComponent,
    IcmsRolesComponent,
    IcmsUserComponent,
    IcmsDataAssigmentComponent,
    IcmsWorkflowComponent,
    IcmsSettingComponent,
    IcmsCodeValueComponent,
    IcmsFieldComponent,
    PaymentGatewayComponent,
    ComplaintComponent,
    PublicComplaintComponent,
    IcmsZoneComponent,
    IcmsHierachyComponent,
    IcmsHelpComponent,
    IcmsFaqComponent,
    IcmsOnlineSupportComponent,
    IcmsTutorialComponent,
    IcmsUserManualComponent,
    IcmsSectionComponent,
    AssignComplaintComponent,
    CourtOrderComponent,
    AssignedOrderComponent,
    SupportDepartmentComponent,
    SupportLettersComponent,
    PaymentComponent,
    PenalityComponent,
    DemolitionChargesComponent,
    NotificationComponent,
    CauseListComponent,
    LetterEditorComponent,

    RecoveryCalculationComponent,
    AllreportsComponent,
    CompundingComponent,
    RequestComponent,
    CompoundingProcessComponent,
    CourtComponent,
    CampusBoundaryComponent,


  ],
  exports: [
    IcmsLayoutComponent,
    IcmsHeaderComponent,
    IcmsFooterComponent,
    IcmsSidebarComponent,
    NotificationComponent

],
  imports: [
    SharedModule,
    CommonModule,
    // NgtreegridModule,
    GoogleMapsModule,
    IcmsLayoutRoutingModule,
    NgSelectModule,
    NgChartsModule,
    FileUploadModule,
    MatInputModule,
    // NgbModule,
    MatSlideToggleModule,
    FormsModule,
    MatStepperModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    // NgxEchartsModule,
    // NgxQRCodeModule
    // NgxQRCodeModule,
   // AngularEditorModule,
    // RichTextEditorModule,
    //  CKEditorModule,
    // AgmCoreModule.forRoot({
    //   // please get your own API key here:
    //   // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
    //   // apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    //   apiKey: 'AIzaSyAfJTVKnpLl0ULuuwDuix-9ANpyQhP6mfc'
    // }),
    // AgmCoreModule
  ]
})
export class IcmsLayoutModule { }
