import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { MainService } from './../../service/main.service';
import { MainService as mainService_MD } from '../../../md/service/main.service'
import { MainService as AdminMainService } from '../../../admin/service/main.service'
import { MainService as mainserviceEmb } from '../../../emb/service/main.service';
import { AuthenticationService } from '../../../authentication.service';
import { SaleService } from '../../service/sale.service';
import Swal from 'sweetalert2';
import { AdvanceService } from '../../service/advance.service';
//

@Component({
  selector: 'app-defaulter-party',
  templateUrl: './defaulter-party.component.html',
  styleUrls: ['./defaulter-party.component.css']
})
export class DefaulterPartyComponent implements OnInit {

  ebillUser: any;
  b_acct_id: any;
  user_id: any
  obj : any= {};
  details1: any;
  details: any;
  allOffer: any;
  allData: any;
  offer_code_to_value: any = {}
  pro_type: any;
  selected_auth: any;

  //flag values//
  body1: boolean = true;
  body2: boolean = false;
  body3: boolean = false;
  body4: boolean = false;
  body5: boolean = false;
  allSearchableEmp: any = []
  flagdata: any;
  dfemidata: any;
  displayedColumns: string[] = ['select', 'party_id', 'property_type', 'assigned_property_number', 'party_name', 'rem_count', 'Status', 'Action'];
  dataSource: any;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();



  constructor(public auth: AuthenticationService, public mainService: MainService, private adminMainService: AdminMainService, private mainServiceMD: mainService_MD, public mainserviceEmb: mainserviceEmb, private spinner: NgxSpinnerService, private sale_service: SaleService, private adv_ser: AdvanceService) { }

  ngOnInit() {
    this.ebillUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
    this.b_acct_id = this.ebillUser.b_acct_id;
    this.user_id = this.ebillUser.le_id;
    console.log(this.b_acct_id);
    this.getofferdata()
    this.getAllEmployees()
    console.log()
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  allEmplyees_new: any
  allEmployees: any
  userObj: any = []
  async getAllEmployees() {
    console.log('all emp')
    this.spinner.show()
    var arr: any = []
    var obj: any = new Object();
    obj['b_acct_id'] = this.b_acct_id;
    obj['module_cd'] = 'PROPERTY'
    var resp = await this.mainServiceMD.getuserformodulerolemapping(JSON.stringify(obj));
    if (resp['error'] == false) {
      console.log(resp)
      this.spinner.hide()
      arr = resp.data;
      console.log(resp, 'users')
      for (let i = 0; i < arr.length; i++) {
        this.userObj[arr[i]['user_id']] = arr[i]
        arr[i]['desc'] = arr[i]['user_id'] + " - " + arr[i]['emp_name'] + " - " + arr[i]['emp_phone_no']
      }
      this.allSearchableEmp = arr

    } else {
      this.spinner.hide()
    }
    console.log(this.allEmployees, this.allEmplyees_new, 'employees')
  }

  async getofferdata() {
    this.spinner.show()
    this.obj['b_acct_id'] = this.b_acct_id

    var resp = await this.sale_service.getAllOffers(this.obj);
    console.log(resp)

    if (resp['error'] == false) {
      this.allOffer = resp['data']
      console.log(resp['data'], 'fetched data')
      let dummy = []

      console.log(this.allOffer, " before loop ")

      for (let i = 0; i < this.allOffer.length; i++) {
        this.offer_code_to_value[this.allOffer[i]['offer_cd']] = this.allOffer[i]['offer_name']
        this.allOffer[i]['offer_name'] = this.allOffer[i]['offer_cd'] + '-' + this.allOffer[i]['offer_name']
        dummy.push(this.allOffer[i])
        console.log(this.allOffer[i]['offer_cd'])
      }
      console.log(this.allOffer, " after loop ")
      console.log(dummy, "dummy test")
    }
    this.spinner.hide()
  }


  async getpropertytype() {
    // let obj :any= {}
    this.obj['b_acct_id'] = this.b_acct_id
    this.obj['offer_cd'] = this.obj['offer_cd']
    console.log(this.obj)
    var resp: any = await this.adv_ser.getpropertytype(JSON.stringify(this.obj));
    //console.log(resp)
    if (resp['error'] == false) {
      this.pro_type = resp['data']
      console.log(this.pro_type, 'fetched property typedata')

    }

  }

  tempNotice: any;
  priviousNoticeData: any;
  currentNoticeData: any = {};
  noticeDataObj: any;

  async getdefaulterdata() {
    //let obj :any= {}
    this.obj['b_acct_id'] = this.b_acct_id
    this.obj['property_type'] = this.obj['pro_type']

    this.allData = null
    if (this.obj['offer_cd']) {

      var resp1: any = await this.adv_ser.getAllDefaulter(JSON.stringify(this.obj));

      if (resp1['error'] == false) {
        this.allData = null
        this.allData = resp1['data']
        console.log(this.allData);
        // this.dataSource = this.allData[0];
        this.dataSource = new MatTableDataSource(this.allData[0]);
        this.dfemidata = this.allData[1];


        this.dataSource.sort = this.sort.toArray()[0];
        this.dataSource.paginator = this.paginator.toArray()[0];
      } else {
        Swal.fire('Server Error', resp1['data'], 'error')
      }
    } else {
      Swal.fire('Information', 'Offer not selected', 'info')
    }

  }


  async flag1(index: any) {
    this.flagdata = this.allData[0][index]
    console.log(this.dfemidata, this.flagdata)
    let data: any = this.dfemidata.filter((ele:any) => ele['party_id'] == this.flagdata['party_id']
    )
    console.log(data)
    this.flagdata['emi_amount'] = data[0]['emi_amount']

    this.body1 = false;
    this.body2 = true;
    this.body3 = false;
    this.body4 = false;
    this.body5 = false;

  }
  async flag2(ele: any) {
    this.flagdata = ele
    let cur_date = new Date()
    if (ele['no_of_failed_inst_default'] > ele['no_paid_count']) {
      Swal.fire('Information', "Not Elligble For Notice " + ele['no_paid_count'] + "/" + ele['no_of_failed_inst_default'], 'info')
      return
    }
    let data: any = this.dfemidata.filter((ele:any) => ele['party_id'] == this.flagdata['party_id']
    )
    this.flagdata['emi_amount'] = data[0]['emi_amount']
    //
    console.log(ele)
    this.obj['offer_cd'] = ele.offer_cd
    this.obj['party_id'] = ele.party_id
    this.obj['no_of_pay'] = ele.no_of_pay
    this.obj['defualt_method_id'] = ele.defualt_method_id
    this.spinner.show()
    let resp: any = await this.adv_ser.getEmiDataForNotice(JSON.stringify(this.obj));
    let data1: any = resp['data'][0]
    let data2: any = resp['data'][1]
    let data3: any = resp['data'][2]

    console.log(resp);
    if (resp['error'] == false) {
      this.noticeDataObj = data1[0]
      this.spinner.hide()
      let lst_emi_end_date = new Date(this.noticeDataObj['emi_end_date'])
      if (cur_date > lst_emi_end_date) {
        this.priviousNoticeData = data3[0]
        if (!this.priviousNoticeData) {
          this.currentNoticeData['notice_no'] = 1
          this.tempNotice = data2.filter((ele: any) => ele['no_of_notice'] == 1)[0]
          let peried = this.tempNotice.notice_period
          this.currentNoticeData['period'] = peried
          console.log(peried)
        } else {
          let req_date = new Date(this.priviousNoticeData['notice_date'])
          //  console.log(req_date,this.priviousNoticeData['notice_date'])
          //  console.log(data2,this.priviousNoticeData)

          this.tempNotice = data2.filter((ele: any) => ele['no_of_notice'] == this.priviousNoticeData['notice_no'])[0]
          let period = this.tempNotice['notice_period']
          console.log(period)
          req_date.setDate(req_date.getDate() + period);
          this.tempNotice['req_date'] = req_date
          console.log(req_date)
          if (req_date > cur_date) {
            this.currentNoticeData['notice_date'] = this.priviousNoticeData['notice_date']
            this.currentNoticeData['notice_no'] = this.priviousNoticeData['notice_no']
            this.currentNoticeData['period'] = period
          }
          else {
            this.tempNotice = data2.filter((ele: any) => ele['no_of_notice'] == (this.priviousNoticeData['notice_no'] + 1))[0]
            console.log(this.tempNotice)
            this.currentNoticeData['notice_no'] = this.tempNotice['notice_no']
            this.currentNoticeData['period'] = this.tempNotice['notice_period']
          }
        }

        this.body1 = false;
        this.body2 = false;
        this.body3 = true;
        this.body4 = false;
        this.body5 = false;
      } else {
        Swal.fire('Information', 'Not Elligable For Notice', 'info')
        return
      }
    } else {
      this.spinner.hide()
      Swal.fire('Error', "Server Error", 'error')
    }
  }

  flag3(index: any) {
    this.flagdata = this.allData[index]
    this.body1 = false;
    this.body2 = false;
    this.body3 = false;
    this.body4 = true;
    this.body5 = false;

  }


  flag4(index: any) {
    this.flagdata = this.allData[index]
    this.body1 = false;
    this.body2 = false;
    this.body3 = false;
    this.body4 = false;
    this.body5 = true;

  }

  close() {
    this.body1 = true;
    this.body2 = false;
    this.body3 = false;
    this.body4 = false;
    this.body5 = false;
  }


  async createGenerateNotice() {
    let flagPrevious = false

    console.log(this.tempNotice)
    let obj: any = {}
    obj['b_acct_id'] = this.b_acct_id
    obj['user_id'] = this.user_id
    obj['party_id'] = this.flagdata['party_id']
    obj['notice_no'] = this.currentNoticeData['notice_no'] // first time automatically will be 1
    obj['defualt_method_id'] = this.flagdata['defualt_method_id']
    obj['offer_cd'] = this.flagdata['offer_cd']
    obj['status'] = 'Generated'
    obj['life_cycle_status'] = 'ACTIVE'

    console.log(this.noticeDataObj)
    if (this.priviousNoticeData) {
      let cur_date = new Date()
      let req_date = this.tempNotice['req_date']
      if (cur_date < req_date) {
        Swal.fire('Already Genrated', 'After Few day Next Notice Will Be Available To Genrate', 'info')
        return
      }
    }
    //done
    if (this.flagdata['date']) {  //for date validation
      obj['notice_date'] = this.flagdata['date']

    } else {
      Swal.fire('Select Date', '', 'info')
      return
    }
    if (this.flagdata['selected_auth']) {
      obj['data'] = []
      this.flagdata['selected_auth'].forEach((element:any) => {
        obj['data'].push({ 'authorized_person': element })
      });
    } else {
      Swal.fire('Select Autorities', '', 'info')
      return
    }

    console.log("createGenerateNotice", obj)
    this.spinner.show()
    var resp: any = await this.adv_ser.createGenerateNotice(obj);

    if (resp['error'] == false) {
      this.spinner.hide()
      Swal.fire('Succues', resp['data'], 'success')
      console.log(resp)
    }
    else {
      console.log(resp)
      Swal.fire('Error', 'Server side error', 'error')
    }
  }
  selectedCar : any;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

}
