import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from "./helpers";
import { AuthenticationService } from "./authentication.service"
import Swal from 'sweetalert2';
import * as moment from 'moment';
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'app';
  constructor(private _router: Router, private AuthenticationService: AuthenticationService) {
    this.getSystemModule();
    this.getAllLanguage();
    this.getAllLabel();
    try {
		let  erpUser:any = JSON.parse(localStorage.getItem('erpUser') as any);
    } catch (err) {
    //   console.log(err, "this is error")
    //   localStorage.clear();
      this._router.navigate(['./index']);
      return;
    }


  }
  async ngOnInit() {

		if (localStorage.getItem('erpUser') == undefined || localStorage.getItem('erpUser') == null) {
			this._router.navigate(['/login']);
		} else {
			var erpUser = JSON.parse(atob(localStorage.getItem('erpUser') as any));
			if (erpUser['b_acct_id'] == 0) {
				this._router.navigate(['./solution-admin/index']);
			} else {
				this._router.navigate(['./index']);
			}
		}
		this._router.events.subscribe((route) => {
			if (route instanceof NavigationStart) {
				Helpers.setLoading(true);
				Helpers.bodyClass('fixed-navbar');
			}
			if (route instanceof NavigationEnd) {
				window.scrollTo(0, 0);
				Helpers.setLoading(false);

				// Initialize page: handlers ...
				// Helpers.initPage();
			}

		});
	}


	allLanguage = []
	async getAllLanguage() {
		var obj = new Object();
		var resp = await this.AuthenticationService.getAllLanguages(JSON.stringify(obj));
		if (resp['error'] == false) {
			this.AuthenticationService.allLanguage = resp.data;
			this.AuthenticationService.language_cd = resp.data[0]['language_cd']
		} else {

		}
	}

	async getSystemModule() {
		let obj:any = new Object();
		obj['b_acct_id'] = 0;
		var resp = await this.AuthenticationService.getModules(JSON.stringify(obj));
		if (resp['error'] == false) {
			  obj = new Object();
			for (let i = 0; i < resp.data.length; i++) {
				obj[resp.data[i]['module_cd']] = resp.data[i]['module_name']
			}
			this.AuthenticationService.allSystemModuleObj = obj;

		} else {

		}
	}
	allLabel = []
	async getAllLabel() {
		var obj = new Object();

		var resp = await this.AuthenticationService.getcomponentLabelvalueswithLanguage(JSON.stringify(obj));
		if (resp['error'] == false) {
			this.AuthenticationService.allLabel = resp.data;
		 let  obj:any = new Object();
			for (let i = 0; i < resp.data.length; i++) {
				obj[resp.data[i]['language_cd'] + resp.data[i]['component_cd'] + resp.data[i]['label_no']] = resp.data[i]['value_to_show'];
			}
			this.AuthenticationService.allLableShowObj = obj;

		} else {

		}
	}

	/********************************************** auto logout code start ************************************************ */
	// @HostListener('document:mousemove', ['$event']) MouseMove() {this.resetTimer()}
	// @HostListener('document:keypress', ['$event']) KeyBord() {this.resetTimer()}
	@HostListener('window:beforeunload', ['$event']) windowBeforeUnload() {this.logOut()}

	timer:any
	time_out_time = 30; // in minutes
	// resetTimer() {
	// 	clearTimeout(this.timer);
	// 	this.timer = setTimeout(async () => {
	// 		await this.logOut()
	// 	}, this.time_out_time * 60 * 1000); // 30 minutes in milliseconds
	// }

	async logOut() {
		if (window.location.hash != '#/login' && !this.AuthenticationService.uiHost.startsWith('localhost')
		    && !this.AuthenticationService.uiHost.startsWith('tracking.upda.co.in')) {
			Swal.fire('Opps..', 'Your session is expired please login again', 'info')
			localStorage.removeItem('erpUser')
			localStorage.removeItem('data_i_t')
			this._router.navigate(['/login']);
		}
	}



	/********************************************** auto logout code end *****************************************************/
}
