
import { Component, OnInit,AfterViewInit, ViewEncapsulation } from '@angular/core';
import {Helpers} from "../../helpers";
import {Router} from '@angular/router'
import {MainService} from '../service/main.service';
import {SettingService} from '../service/setting.service';
import {AuthenticationService} from '../../authentication.service';
@Component({
  selector: '.page-wrapper',
  templateUrl: './accounts-layouts.component.html',
  styleUrls: ['./accounts-layouts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountsLayoutsComponent implements AfterViewInit {

  constructor(public auth:AuthenticationService,private settingService:SettingService,private mainService: MainService,private router : Router) { }
  b_acct_id=-1;
  erpUser:any;
  allFields=[];
  allCodeValue=[];
  codeValueObj={};
  codeValueTechObj={};
  codeValueShowObj={};
  async ngOnInit() {
    if(localStorage.getItem('erpUser')==undefined || localStorage.getItem('erpUser')==null){
      this.router.navigate(['/login']);
    }else{
      this.erpUser = JSON.parse(atob(localStorage.getItem('erpUser')as any));
      this.b_acct_id = this.erpUser.b_acct_id;
      await this.getCodeValue();

    }
    await this.getAllTaskOfUser()
    await this.mainService.getAllLanguage()
    await this.mainService.getAllLabel()
  }
  async getAllTaskOfUser() {
    var obj = Object()
    obj['b_acct_id'] = this.erpUser.b_acct_id
    obj['user_id'] = this.erpUser.user_id
    obj["status"] = 'PENDING'
    console.log(obj)
    var resp = await this.mainService.getAllTaskOfUser(JSON.stringify(obj))
    if (resp['error'] == false) {
      console.log(resp['data'])
      this.mainService.count = resp['data'].length
    } else {
    }
  }
  async getCodeValue(){

    var resp = await this.settingService.getCodeValue(this.b_acct_id);
    var codeValueTempObj:any={}
    var codeValueShowTempObj:any={};
    if(resp['error']==false){
      for(var i=0;i<resp.data.length;i++){
        if(codeValueTempObj[resp.data[i]['field_code']]== undefined){
          codeValueTempObj[resp.data[i]['field_code']] = [];
          codeValueShowTempObj[resp.data[i]['field_code']] ={}
        }
        codeValueShowTempObj[resp.data[i]['field_code']][resp.data[i].code] = resp.data[i].value;
        codeValueTempObj[resp.data[i]['field_code']].push(resp.data[i])
      }
      this.codeValueObj = codeValueTempObj;
      this.codeValueShowObj = codeValueShowTempObj;
      this.mainService.codeValueTechObj = this.codeValueObj;
      this.mainService.codeValueShowObj = this.codeValueShowObj;

    }else{

    }

  }
  ngAfterViewInit() {
    Helpers.initLayout();
  }

}


